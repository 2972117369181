<template>
	<div
		v-data-cy="'live-tutor-card'"
		v-jest="'liveTutorCard'"
		class="liveTutorCard"
		:class="{
			'liveTutorCard--redTopBorder': isNotPunchedInAndLate,
			'liveTutorCard--yellowTopBorder': isShiftAlmostOver,
		}"
	>
		<ServiceDashboardTutorCardHeaderLiveTutor
			:shift="shift"
			:user="user"
		/>
		<ServiceDashboardListSession
			:resources="sessions"
			:total-sessions="totalSessions"
			:time-since-last-activity="cardData.last_session_ended_at"
		/>
		<ServiceDashboardListEssay
			v-if="isEssayReviewer"
			:resources="essays"
			:total-essays="totalEssays"
			:time-since-last-activity="cardData.last_essay_reviewed_at"
		/>
	</div>
</template>

<script>
import { isAfter, subMinutes } from "date-fns";

import ServiceDashboardListSession from "./ServiceDashboardListSession.vue";
import ServiceDashboardListEssay from "./ServiceDashboardListEssay.vue";
import ServiceDashboardTutorCardHeaderLiveTutor from "./ServiceDashboardTutorCardHeaderLiveTutor.vue";

export default {
	components: {
		ServiceDashboardListSession,
		ServiceDashboardListEssay,
		ServiceDashboardTutorCardHeaderLiveTutor,
	},
	props: {
		cardData: {
			type: Object,
			required: true,
			validator(value) {
				return (
					value.hasOwnProperty("user") &&
					value.hasOwnProperty("sessions") &&
					value.hasOwnProperty("essay") &&
					value.hasOwnProperty("shift") &&
					value.hasOwnProperty("total_essays") &&
					value.hasOwnProperty("total_sessions") &&
					value.hasOwnProperty("last_session_ended_at") &&
					value.hasOwnProperty("last_essay_reviewed_at") &&
					value.hasOwnProperty("is_essay_reviewer")
				);
			},
		},
	},
	data() {
		return {
			currentDate: new Date(),
		};
	},
	computed: {
		/**
		 * @returns {Boolean}
		 */
		isNotPunchedInAndLate() {
			if (!this.isPunchedIn) {
				return isAfter(this.currentDate, new Date(this.shift.start_time * 1000));
			} else {
				return false;
			}
		},
		/**
		 * @returns {Boolean}
		 */
		isPunchedIn() {
			return this.shift.punch_in !== null;
		},
		/**
		 * @returns {Boolean}
		 */
		isShiftAlmostOver() {
			const shiftEnd = new Date(this.shift.end_time * 1000);
			return isAfter(this.currentDate, subMinutes(shiftEnd, 15));
		},
		/**
		 * @returns {Boolean}
		 */
		isEssayReviewer() {
			return this.cardData.is_essay_reviewer;
		},
		/**
		 * @returns {Object}
		 */
		user() {
			return this.cardData.user;
		},
		/**
		 * @returns {Object}
		 */
		shift() {
			return this.cardData.shift;
		},
		/**
		 * @returns {Object}
		 */
		sessions() {
			return this.cardData.sessions;
		},
		/**
		 * @returns {number}
		 */
		totalSessions() {
			return this.cardData.total_sessions;
		},
		/**
		 * @returns {number}
		 */
		totalEssays() {
			return this.cardData.total_essays;
		},
		/**
		 * @returns {Array}
		 */
		essays() {
			let essays = [];
			if (this.cardData.essay !== null) {
				essays = [this.cardData.essay];
			}
			return essays;
		},
		selectedSchedule() {
			return this.$store.state.PlatformManager.ServiceSummaryDashboard.selectedTutorSchedule ?? "";
		},
	},
	beforeUpdate() {
		this.currentDate = new Date();
	},
};
</script>

<style scoped>
.liveTutorCard {
	border-top: solid transparent 4px;
	padding-top: 12px;
}
.liveTutorCard--redTopBorder {
	border-top: solid var(--c-orange) 4px;
}
.liveTutorCard--yellowTopBorder {
	border-top: solid var(--c-orange) 4px;
}
</style>
