var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "summaryCard" }, [
    _c("div", { staticClass: "summaryCard__titleRow" }, [
      _c("h3", { staticClass: "summaryCard__title" }, [_vm._t("cardTitle")], 2),
      _vm._v(" "),
      _c("div", { staticClass: "summaryCard__badges" }, [_vm._t("badges")], 2),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "summaryCardContent" }, [
      _c("div", { staticClass: "summaryCardContent__container" }, [
        _c(
          "h4",
          { staticClass: "summaryCardContent__title" },
          [_vm._t("leftTitle")],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "summaryCardContent__inner" },
          [
            _vm._t("leftNumber", function () {
              return [_vm._v("\n\t\t\t\t\t0\n\t\t\t\t")]
            }),
            _vm._v(" "),
            _vm._t("leftDetails"),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "summaryCardContent__container" }, [
        _c(
          "h4",
          { staticClass: "summaryCardContent__title" },
          [_vm._t("rightTitle")],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "summaryCardContent__inner" },
          [
            _vm._t("rightNumber", function () {
              return [_vm._v("\n\t\t\t\t\t0\n\t\t\t\t")]
            }),
            _vm._v(" "),
            _vm._t("rightDetails"),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }