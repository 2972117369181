var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tw-pt-4" }, [
    _c("label", { staticClass: "tw-text-sm tw-font-bold tw-leading-7" }, [
      _vm._v(
        "\n\t\t" +
          _vm._s(_vm.$t("whatTeacherInstructionsAndExpectations")) +
          "\n\t"
      ),
    ]),
    _vm._v(" "),
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.writtenInstructions,
          expression: "writtenInstructions",
        },
        {
          name: "jest",
          rawName: "v-jest",
          value: "instruction-text",
          expression: "'instruction-text'",
        },
        {
          name: "data-cy",
          rawName: "v-data-cy",
          value: "instruction-text",
          expression: "'instruction-text'",
        },
      ],
      staticClass:
        "tw-border-b tw-rounded-b-none tw-rounded-t-md tw-border-2 tw-border-solid tw-border-origami-grey-200 tw-min-h-[10rem] tw-py-4 tw-px-3.5 tw-w-full placeholder:tw-text-xs",
      attrs: { placeholder: _vm.$t("instructions") },
      domProps: { value: _vm.writtenInstructions },
      on: {
        change: _vm.handleWrittenInput,
        input: function ($event) {
          if ($event.target.composing) return
          _vm.writtenInstructions = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "tw-bg-grey-pale tw-border-origami-grey-200 tw-border-solid tw-border-2 tw-border-t tw-rounded-t-none tw-rounded-b-md tw-pr-1.5 tw-pl-3 tw-py-3 tw--mt-1.5",
      },
      [
        _c("input", {
          directives: [
            {
              name: "data-cy",
              rawName: "v-data-cy",
              value: "instruction-file",
              expression: "'instruction-file'",
            },
          ],
          ref: "instructionFiles",
          staticClass: "tw-hidden",
          attrs: {
            type: "file",
            accept: _vm.instructionFileMimeTypes.join(),
            multiple: "",
          },
          on: {
            change: function ($event) {
              _vm.handleInitialFileUpload(Array.from($event.target.files))
            },
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.instructionFiles.length < _vm.fileLimit,
                expression: "instructionFiles.length < fileLimit",
              },
              {
                name: "jest",
                rawName: "v-jest",
                value: "main-upload-buttons",
                expression: "'main-upload-buttons'",
              },
            ],
            staticClass: "tw-gap-2",
          },
          [
            _c("div", { staticClass: "tw-font-bold tw-text-xs" }, [
              _vm._v(
                "\n\t\t\t\t" + _vm._s(_vm.$t("instructionsCTA")) + ":\n\t\t\t"
              ),
            ]),
            _vm._v(" "),
            _c(
              "OrigamiButton",
              {
                attrs: { variant: "secondary", "leading-icon": "computer" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.openFilePicker.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n\t\t\t\t" + _vm._s(_vm.$t("browseFiles")) + "\n\t\t\t"
                ),
              ]
            ),
            _vm._v(" "),
            _c("GooglePicker", {
              staticClass: "tw-relative",
              attrs: {
                "doc-validator": _vm.docValidator,
                "mime-types": _vm.instructionFileMimeTypes,
                multiple: "",
              },
              on: {
                change: _vm.handleInitialFileUpload,
                "change-start": function ($event) {
                  _vm.isLoadingGooglePickerFile = true
                },
                "change-end": function ($event) {
                  _vm.isLoadingGooglePickerFile = false
                },
                error: _vm.handleGooglePickerError,
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ trigger: triggerGooglePicker, isLoaded }) {
                    return [
                      _c(
                        "OrigamiButton",
                        {
                          attrs: {
                            variant: "secondary",
                            "leading-icon": "google",
                            "is-loading": !isLoaded,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.openGooglePicker(triggerGooglePicker)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.$t("googleDrive")) +
                              "\n\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.isLoadingGooglePickerFile
          ? _c("BaseLoader", {
              staticClass: "tw-w-4 tw-h-4 tw-blue-regular tw-m-2",
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.instructionFiles, function (instructionFile, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass:
                "tw-flex tw-flex-row hover:tw-bg-grey-slightly tw-border-2 tw-border-dashed tw-border-grey-dark tw-overflow-hidden tw-mt-2",
            },
            [
              _c("input", {
                ref: "instructionFile",
                refInFor: true,
                staticClass: "tw-hidden",
                attrs: {
                  type: "file",
                  accept: _vm.instructionFileMimeTypes.join(),
                  multiple: "",
                },
                on: {
                  change: function ($event) {
                    return _vm.handleFileChange($event.target.files[0], index)
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "tw-flex-grow tw-flex tw-items-center tw-overflow-hidden tw-text-origami-blue-300",
                },
                [
                  _c("OrigamiIcon", {
                    staticClass: "tw-mx-2",
                    attrs: {
                      name: "essay-outlined",
                      width: "16",
                      height: "16",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "tw-text-sm tw-font-bold tw-overflow-hidden tw-overflow-ellipsis",
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(instructionFile.name) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "BDropdown",
                {
                  staticClass: "ChangeFileDropdown tw-relative",
                  attrs: {
                    right: "",
                    size: "sm",
                    "no-caret": "",
                    variant: "link",
                    "popper-opts": { positionFixed: true },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "button-content",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "tw-text-origami-blue-300 tw-font-bold",
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(_vm.$t("changeFile")) +
                                    "\n\t\t\t\t\t\t"
                                ),
                                _c("OrigamiIcon", { attrs: { name: "caret" } }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _vm._v(" "),
                  _vm.embedded
                    ? _c("BDropdownText", [
                        _c("span", { staticClass: "tw-font-bold tw-text-sm" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.$t("changeFile")) +
                              "\n\t\t\t\t\t"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "BDropdownItem",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$refs.instructionFile[index].click()
                        },
                      },
                    },
                    [
                      _c("OrigamiIcon", { attrs: { name: "computer" } }),
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.$t("browseFiles")) +
                          "\n\t\t\t\t"
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("GooglePicker", {
                    staticClass: "tw-relative",
                    attrs: {
                      "doc-validator": _vm.docValidator,
                      "mime-types": _vm.instructionFileMimeTypes,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.handleFileChange($event, index)
                      },
                      "change-start": function ($event) {
                        _vm.isLoadingGooglePickerFile = true
                      },
                      "change-end": function ($event) {
                        _vm.isLoadingGooglePickerFile = false
                      },
                      error: _vm.handleGooglePickerError,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({
                            trigger: triggerGooglePicker,
                            isLoaded,
                          }) {
                            return [
                              isLoaded
                                ? _c(
                                    "BDropdownItem",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.openGooglePicker(
                                            triggerGooglePicker
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("OrigamiIcon", {
                                        attrs: { name: "google" },
                                      }),
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.$t("googleDrive")) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _vm._v(" "),
                  _vm.embedded
                    ? [
                        _c("BDropdownDivider"),
                        _vm._v(" "),
                        _c(
                          "BDropdownItem",
                          [
                            _c(
                              "OrigamiButton",
                              {
                                attrs: { "leading-icon": "close-full" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.clearInstructionFile(
                                      instructionFile
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.$t("removeFile")) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              !_vm.embedded
                ? _c(
                    "OrigamiIconButton",
                    {
                      attrs: {
                        "icon-name": "close-full",
                        "aria-hidden": "true",
                        title: _vm.$t("removeFile"),
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.clearInstructionFile(instructionFile)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "slds-assistive-text" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.$t("removeFile")) +
                            "\n\t\t\t\t"
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        }),
      ],
      2
    ),
    _vm._v(" "),
    _vm.fileErrors.length
      ? _c("div", [
          _c(
            "ul",
            _vm._l(_vm.fileErrors, function (error, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass:
                    "tw-text-sm tw-font-bold tw-text-origami-red-400 tw-mt-4",
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(error == null ? "" : _vm.$t(`errors.${error}`)) +
                      "\n\t\t\t"
                  ),
                ]
              )
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }