export const SHIFT_UPCOMING_ID = 1;
export const SHIFT_SEGMENT_IN_PROGRESS_ID = 2;
export const SHIFT_SEGMENT_IN_TRANSITION_ID = 3;
export const TUTOR_MIA_ID = 4;
export const TUTOR_NEW_ACTIVITY_HOLD = 5;
export const BIO_BREAK_IN_PROGRESS_ID = 6;
export const SHIFT_ENDED_ID = 7;

export const PUNCHED_IN_STATES = [
	SHIFT_SEGMENT_IN_PROGRESS_ID,
	SHIFT_SEGMENT_IN_TRANSITION_ID,
	TUTOR_MIA_ID,
	TUTOR_NEW_ACTIVITY_HOLD,
	BIO_BREAK_IN_PROGRESS_ID,
];
