var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ScheduledBreakError", {
    directives: [
      {
        name: "jest",
        rawName: "v-jest",
        value: "rest-break-error",
        expression: "'rest-break-error'",
      },
    ],
    attrs: { header: "Schedule rest break" },
    on: { "break-error-try-again": _vm.confirmRestBreakReschedule },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }