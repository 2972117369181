import { merge } from "lodash";

import { generalTranslations } from "../general.js";
import { modalsEssay } from "../modals.js";

const trans = {
	en: {
		navbar: {
			classroom: "Chat",
			dashboard: "Dashboard",
			essay_review: "Essay Review",
			inbox: "Notifications",
		},
		emptyStateTitle: {
			all: "No 'submitted' work",
			pending: "No 'pending' work",
			inProgress: "No 'in progress' work",
			reviewed: "No 'reviewed' work",
		},
		emptyStateDesc: {
			all: "All submitted work will appear here",
			pending: "Work waiting to be viewed by a tutor will appear here",
			inProgress: "You'll see work your tutor is currently reviewing here",
			reviewed: "You'll see work your tutor is finished reviewing here",
		},
		uploadWork: "Upload work for review",
		highDemand1: "Heads up! Due to high demand, it may take up to",
		highDemand2: " for submissions to be reviewed.",
		show: "Show:",
		all: "All submissions",
		pending: "Pending",
		inProgress: "In Progress",
		reviewed: "Reviewed",
		downloaded: "Downloaded",
		reviewedSingular: "Reviewed",
		inProgressSingular: "In Progress",
		pendingSingular: "Pending",
		downloadedSingular: "Downloaded",
		upload: "Upload Your Essay",
		download: "Download",
		yourComments: "Your comments",
		or: "or",
		clickHere: "click here",
		processingFile: "Processing your file",
		processingFilePopup: "Processing your file. Please do not close this window.",
		essayTitle: "Essay Title",
		titleOfYourUploadedWork: "Title of your work",
		essayTitlePlaceholder: "Give your work a title",
		teacherInstructions: "Instructions",
		comments: "Comments",
		essayWillBeReviewed: "Your essay will be reviewed by an educator",
		withinTheNext: "within the next",
		hours: "hours",
		reviewMyEssay: "Submit for review",
		submit: "Submit",
		yourEssays: "Your submissions",
		yourFile: "Your file:",
		expandCollapse: "Expand/Collapse",
		titleSelectionRequired: "Add a title",
		essayCommentsRequired: "Add submission details",
		essay: "Essay",
		creativeWriting: "Creative writing",
		scientificWriting: "Scientific writing",
		collegeAdmissionsWriting: "College admissions essay",
		otherWritingProject: "Other writing project",
		resume: "Resume",
		weReviewAll: "We always review essays for grammar, content, structure, and references.",
		anyQuestionsSoFar: "Let your tutor know what you need help with. What question(s) do you have? What have you tried so far?",
		anythingElse: "Is there anything else you would like for us to focus on?",
		additionalComments: "The more you tell your tutor, the better they’ll be able to help you.",
		instructions: "Add written materials, and/or upload a file.",
		instructionsCTA: "Choose a file to upload",
		viewOlderEssays: "View Older Essays",
		viewAllSubmissions: "View all submissions",
		noMoreEssays: "No Older Essays Available",
		tutorComments: ":name's comments",
		chooseSection: "Which class is this for?",
		chooseSubmissionType: "What type of submission is this?",
		submissionType: "Submission type",
		didNotUploadEssayYet: "You did not upload an essay yet",
		uploadedEssaysAppearHere: "Uploaded essays will appear here",
		essayHelp: "Essay Writing Help",
		orChooseAFileToUpload: "or choose a file to upload",
		work: "New work",
		newWork: "Submission details",
		submissionDetailsTitle: "A tutor will review your submission and leave comments to help you improve your draft.",
		submissionDetailsText: "Their comments will guide you toward improving your content, argument, structure, and flow, as well as your grammar and spelling, and will also let you know if you’ve cited your sources properly.",
		acceptedWork: "Our reviewers accept essays, creative writing, scientific writing, resumes, college admissions essays, and other writing projects.",
		cancel: "Cancel",
		uploading: "Uploading...",
		original: "Original",
		uploadedOn: "Uploaded on",
		tutorsComments: "Tutor's Comments",
		review: "Review",
		at: "at",
		language: "Language",
		selectLanguage: "What language is your submission in?",
		section: "Section",
		sureCancel: "Are you sure you want to cancel?",
		yes: "Yes",
		no: "No",
		dropInstructionFile: "Drop a file of your instructions",
		typeInstructions: "Or type the instructions here",
		uploadHeader: "Upload Your Essay for Review",
		essayFile: "Essay File",
		browse: "browse",
		orTypeBelow: "Or type the instructions below",
		dropFile: "Drop file to attach, or",
		success: "Success",
		successfulUpload: "You have successfully uploaded your essay:",
		close: "Close",
		browseFiles: "Browse Files",
		googleDrive: "Google Drive",
		googleDriveInstructions: "Click here to access your Google Drive",
		dropHere: "Drag & drop file here",
		uploadNewWork: "Upload new work",
		supportedFiles: "All Google Docs, Google Spreadsheets, .pdf, .doc, .docx, .rtf, and .txt file types are supported.",
		howEssayReviewWorks: "How does Essay Review work?",
		uploadFileAbove: "Upload your essay file above.",
		giveInformationEssay: "Answer a few questions about your essay so the reviewer can give you the best help possible.",
		receiveReviewWithin: "Receive your essay review within 24 hours.",
		helpImprovePlagiarism:
			"Our tutors also check for anything that could look like plagiarism.",
		submitNewWork: "Submit new work",
		yourEssay: "Your Essay",
		yourWork: "Your Work",
		changeFile: "Change File",
		removeFile: "Remove File",
		spellingGrammarPunctuation: "My spelling, grammar, punctuation",
		contentArgumentsStructure: "My content, arguments, structure",
		citationsSources: "My citations and sources",
		whatTeacherInstructions: "What are your teacher's instructions?",
		whatTeacherInstructionsAndExpectations: "Add any instructions or additional materials here that might help your tutor better understand your submission. This might be an essay prompt, instructions from your teacher, a rubric or outline you’re using, or a description of a job you’re applying for.",
		anythingTutorFocus: "Is there anything you would like the tutor to focus on?",
		selectAsMany: "Select as many as you need",
		submittedOn: "Submitted on",
		selectFile: "Upload from your computer",
		reviewedOn: "Reviewed on",
		downloadedOn: "Downloaded on",
		downloadReviewedFile: "Download reviewed file with tutor comments",
		tutorsFeedback: "Your tutor's feedback",
		plagiareyes: "Plagiar-eyes",
		teachersInstructions: "Teacher's instructions",
		noteToTutor: "Your notes:",
		pleaseCheck: "Please check my:",
		dateSubmitted: "Date Submitted",
		hideOriginalSubmission: "Hide submission details",
		originalSubmission: "View submission details",
		submissionReviewETA: "Your review will be available in about  ",
		timeLeft: " {hours} hours",
		reviewTimeLapsed: "Your work will be reviewed soon",
		estimatedReviewTime: "Your tutor’s comments will be ready to download in about",
		studentReviewTimeLapsed: "Your work was successfully submitted. It will be reviewed soon.",
		successfullySubmitted: "Your work was successfully submitted to our tutors. It will be reviewed within {countdown}.",
		feedbackSummary: "Feedback Summary",
		originalFile: "Your original file",
		essay_submitted: "Your essay has been submitted",
		done: "Done",
		next: "Next",
		errors: {
			invalid: "This file format is not supported. Please try again.",
			access_denied: "You must authorize access to your account in order to upload files from Google Drive",
			no_file_selected: "Select a file",
			too_large: "This file is too large. Please upload a smaller file.",
			popup_blocked_by_browser: "Your browser blocked the popup necessary to use this feature. Try disabling your popup blocker and/or trying again.",
			too_many_files: "You may only upload three files",
			other: "There was a problem. Please try again, or upload from your computer directly",
		},
		filterTab: "Filter Tab",
		feedback: {
			isReviewHelpful: "Was this tutor review helpful?",
			submittedAnonymously: "Your feedback will be submitted anonymously.",
			reviewCenterFeedback: "Review Center Feedback",
			helpfulGeneralComment: "How helpful was the Feedback Summary?",
			helpfulHighlightedComment: "How helpful were the comments on your file?",
			notHelpful: "Not helpful",
			veryHelpful: "Very helpful",
			anythingElse: "Thanks! Anything else you want to tell us?",
			improvementSuggestions: "Let us know what we can do to improve for next time!",
			thanksForFeedback: "Thank you for your feedback!",
		},
	},
	fr: {
		navbar: {
			classroom: "Chat",
			dashboard: "Tableau de bord",
			essay_review: "Rédactions",
			inbox: "Notifications",
		},
		emptyStateTitle: {
			all: "Aucun travail 'soumis'",
			pending: "Aucun travail 'en attente'",
			inProgress: "Aucun travail 'en brouillon'",
			reviewed: "Aucun travail 'révisé'",
		},
		emptyStateDesc: {
			all: "Tous les travaux soumis s'afficheront ici",
			pending: "Les travaux en attente de révision s'afficheront ici",
			inProgress: "Les travaux actuellement en révision s'afficheront ici",
			reviewed: "Les travaux révisés s'afficheront ici",
		},
		uploadWork: "Téléverser un travail pour révision",
		highDemand1: "Attention! En raison d’une forte demande, cela peut prendre jusqu'à",
		highDemand2: " pour que les soumissions soient examinées",
		show: "Voir par:",
		all: "Tous les travaux soumis",
		pending: "En attente",
		inProgress: "En révision",
		reviewed: "Révisée",
		downloaded: "Téléchargée",
		reviewedSingular: "Révisée",
		inProgressSingular: "En révision",
		pendingSingular: "En attente",
		downloadedSingular: "Téléchargée",
		upload: "Téléverser votre rédaction",
		work: "Nouveau travail",
		submissionDetailsTitle: "Un tuteur examinera ton travail et laissera des commentaires pour t'aider à améliorer ton brouillon.",
		submissionDetailsText: "Leurs commentaires te permettront d'améliorer ton contenu, ton argumentation, ta structure ainsi que de ta grammaire et ton orthographe, te permettant ainsi de savoir si tu as correctement cité tes sources.",
		yourFile: "Ton fichier:",
		titleOfYourUploadedWork: "Titre de ton travail",
		essayTitlePlaceholder: "Donne un titre à ton travail",
		whatTeacherInstructionsAndExpectations: "Ajoute ici des instructions ou des documents supplémentaires qui pourraient aider ton tuteur à mieux comprendre ton travail. Il peut s'agir d'instructions, d'une rubrique ou d'un plan que tu utilises, ou d'une description d'un emploi pour lequel tu postules.",
		anyQuestionsSoFar: "Fais savoir à ton tuteur ce pour quoi tu as besoin d'aide. Quelle(s) question(s) as-tu? Qu'as-tu essayé jusqu'à présent?",
		download: "Téléverser",
		yourComments: "Vos commentaires",
		or: "ou",
		newWork: "Détails de la soumission",
		acceptedWork: "Nos examinateurs acceptent les essais, les rédactions créatives, les rédactions scientifiques, les CV, les lettres d'admission à l'université et autres projets d'écriture.",
		clickHere: "cliquez ici",
		processingFile: "Nous traitons votre fichier",
		essayTitle: "Titre de la rédaction",
		teacherInstructions: "Directives",
		comments: "Commentaires",
		essayWillBeReviewed: "Votre rédaction sera révisée par un tuteur",
		withinTheNext: "dans les prochaines",
		hours: "heures",
		reviewMyEssay: "Soumettre pour révision",
		submit: "Soumettre",
		yourEssays: "Vos rédactions",
		expandCollapse: "Agrandir/Réduire",
		titleSelectionRequired: "Ajouter un titre",
		essayCommentsRequired: "Ajouter des détails de soumission",
		essay: "Rédaction",
		creativeWriting: "Rédaction créative",
		scientificWriting: "Rédaction scientifique",
		collegeAdmissionsWriting: "Rédaction d'admission à l'université",
		otherWritingProject: "Autres travaux écrits",
		resume: "CV",
		weReviewAll: "Toutes les rédactions sont révisées pour l'orthographe, la grammaire, le contenu, la structure et les références.",
		anythingElse: "Souhaitez-vous que nous portions attention à quelque chose en particulier?",
		additionalComments: "Plus tu en diras à ton tuteur, mieux il pourra t’aider.",
		instructions: "Ajoute un travail et/ou téléverse un fichier.",
		instructionsCTA: "Choisis un fichier a téléverser",
		viewOlderEssays: "Récupérez plus de rédactions",
		viewAllSubmissions: "Voir toutes les rédactions",
		noMoreEssays: "Liste de rédactions vide",
		tutorComments: "Commentaires laissés par :name",
		chooseSection: "Pour quelle classe est-ce?",
		chooseSubmissionType: "Quel type de soumission est-ce?",
		submissionType: "Type de soumission",
		didNotUploadEssayYet: "Vous n'avez pas téléverser une rédaction",
		uploadedEssaysAppearHere: "Les rédactions téléversées vont apparaître ici.",
		essayHelp: "Rédactions",
		orChooseAFileToUpload: "Ou choisis un fichier à téléverser",
		cancel: "Annuler",
		uploading: "Téléchargement...",
		original: "Originale",
		uploadedOn: "Téléverser le",
		tutorsComments: "Commentaires du tuteur",
		review: "Révisé",
		at: "à",
		language: "Langue",
		selectLanguage: "Dans quelle langue est ta soumission ?",
		section: "Section",
		sureCancel: "Êtes-vous sûr de vouloir annuler?",
		yes: "Oui",
		no: "Non",
		dropInstructionFile: "Déposez le fichier des directives ici",
		typeInstructions: "Ou entrez les instructions ci-dessous",
		uploadHeader: "Télécharger une rédaction",
		essayFile: "Fichier pour la rédaction",
		browse: "naviguer",
		orTypeBelow: "Ou inscrire les instructions ci-dessous",
		dropFile: "Déposez un fichier, ou",
		success: "Succèss",
		successfulUpload: "Vous avez télécharger votre redaction:",
		close: "Fermer",
		browseFiles: "Parcourir les fichiers",
		dropHere: "Déposez vos fichiers içi",
		uploadNewWork: "Téléverser un nouveau travail",
		supportedFiles: "Tous les types de fichiers Google docs, feuilles de calcul Google, .pdf, .doc, .rtf et .txt sont pris en charge.",
		selectFile: "Sélectionnez un fichier",
		howEssayReviewWorks: "Comment ça fonctionne?",
		uploadFileAbove: "Téléchargez votre fichier de rédaction ci-haut",
		giveInformationEssay: "Donnez quelques informations sur votre rédaction",
		receiveReviewWithin:
            "La révision de votre rédaction vous sera retournée dans les prochaines 24 heures",
		helpImprovePlagiarism:
			"Pour vous aidez à vous améliorer, nos tuteurs vous aviseront si des parties de votre rédaction s'apparentent à du plagiat.",
		submitNewWork: "Soumettre un nouveau travail",
		yourEssay: "Votre rédaction",
		changeFile: "Choisir un autre fichier",
		spellingGrammarPunctuation: "Orthographe, grammaire, ponctuation",
		contentArgumentsStructure: "Contenu, arguments, structure",
		citationsSources: "Citations et sources bibliographiques",
		whatTeacherInstructions: "Quelles sont les instructions de votre professeur",
		anythingTutorFocus:
            "Souhaitez-vous que le tuteur porte une attention particulière à quelque chose?",
		selectAsMany:
            "Vous pouvez selectionner plus d'un choix ou écrire un commentaire pour le tuteur",
		submittedOn: "Soumis le",
		reviewedOn: "Révisé le",
		downloadedOn: "Téléchargé le",
		downloadReviewedFile: "Télécharges le fichier révisé avec les commentaires du tuteur",
		tutorsFeedback: "Travail révisé",
		plagiareyes: "Plagi-vérifié",
		teachersInstructions: "Consignes du professeur",
		noteToTutor: "Tes notes:",
		pleaseCheck: "S.V.P. Portez attention à:",
		dateSubmitted: "Date de soumission",
		yourEssaySuccessfullySubmitted:
            "Votre rédaction à bien été reçue. Elle sera révisée par un de nos tuteur dans les prochaines",
		hideOriginalSubmission: "Cacher les détails de la soumission",
		originalSubmission: "Voir les détails de la soumission",
		submissionReviewETA: "Ta révision sera disponible dans environ ",
		timeLeft: " {hours} heures",
		submissionPending1: "Le travail de ",
		submissionPending2: " a été reçu et sera examiné dans les 24 heures suivant sa soumission.",
		reviewTimeLapsed: "Ton travail sera bientôt examiné",
		estimatedReviewTime: "Les commentaires de ton tuteur seront prêts à être téléchargés dans environ",
		studentReviewTimeLapsed: "Ton travail a été soumis avec succès à nos tuteurs. Il sera bientôt examiné.",
		successfullySubmitted: "Ton travail a été soumis avec succès à nos tuteurs. Il sera examiné dans moins de {countdown}.",
		feedbackSummary: "Un tuteur t’a envoyé un message:",
		originalFile: "Ton fichier d'origine",
		essay_submitted: "Ta rédaction a été soumise",
		done: "Fini",
		next: "Suivant",
		errors: {
			invalid: "Ce format de fichier n'est pas compatible. Essaye encore.",
			too_large: "Ce fichier est trop volumineux. Téléverse un fichier plus petit.",
			no_file_selected: "Sélectionne un fichier",
			access_denied: "Vous devez autoriser l'accès à votre compte afin de pouvoir téléverser des fichiers de votre Google Drive",
			popup_blocked_by_browser: "Votre navigateur a bloqué la fenêtre popup nécessaire à l'utilisation de cette fonctionnalité. Essayez de désactiver votre bloqueur de fenêtres popup et/ou réessayez.",
			other: "Un problème est survenu. Veuillez réessayer à nouveau ou téléverser à partir de votre ordinateur",
		},
		filterTab: "Onglet filtre",
		feedback: {
			isReviewHelpful: "As-tu trouvé le commentaire de ce tuteur utile?",
			submittedAnonymously: "Tes commentaires seront soumis de manière anonyme.",
			reviewCenterFeedback: "Commentaires du Centre de Révision",
			helpfulGeneralComment: "As-tu trouvé le resumé des commentaires utiles?",
			helpfulHighlightedComment: "As-tu trouvé les commentaires de ton travail utiles?",
			notHelpful: "Inutiles",
			veryHelpful: "Très utiles",
			anythingElse: "Merci! As-tu autre chose à ajouter?",
			improvementSuggestions: "Comment pourrions-nous améliorer ton expérience la prochaine fois?",
			thanksForFeedback: "Merci pour tes commentaires!",
		},
	},
	es: {
		navbar: {
			classroom: "Chat",
			dashboard: "Tablero",
			essay_review: "Revisión de ensayos",
			inbox: "Notificaciones",
		},
		emptyStateTitle: {
			all: "No trabajo 'enviado'",
			pending: "No trabajo 'pendiente'",
			inProgress: "No trabajo 'en progreso'",
			reviewed: "No trabajo 'revisados'",
		},
		emptyStateDesc: {
			all: "Todo el trabajo enviado aparecerá aquí",
			pending: "El trabajo esperando a ser revisado por un tutor aparecerá aquí",
			inProgress: "Aquí encontrarás el trabajo en revisión",
			reviewed: "Aquí encontrarás el trabajo revisado",
		},
		uploadWork: "Cargar el trabajo para revisión",
		yourFile: "Tu archivo:",
		titleOfYourUploadedWork: "Título de tu trabajo",
		essayTitlePlaceholder: "Ponle un título a tu trabajo",
		whatTeacherInstructionsAndExpectations: "Agrega cualquier instrucción o material adicional que permita a tu tutor entender mejor tu envío. Esto puede ser una sugerencia de ensayo, instrucciones de tu maestro, una rúbrica o algún esquema, o alguna vacante de trabajo a la que estés aplicando.",
		anyQuestionsSoFar: "Hazle saber a tu tutor con qué necesitas ayuda. ¿Qué pregunta(s) tienes? ¿Qué has intentado hasta ahora? ",
		highDemand1: "¡Atención! Debido a alta demanda, puede tomar hasta",
		highDemand2: " para que se revisen los trabajos.",
		show: "Mostrar:",
		all: "Todos los envíos",
		pending: "Pendiente",
		inProgress: "En revisión",
		reviewed: "Revisado",
		downloaded: "Descargado",
		reviewedSingular: "Revisado",
		inProgressSingular: "En revisión",
		pendingSingular: "Pendiente",
		downloadedSingular: "Descargado",
		upload: "Sube tu ensayo",
		download: "Descarga",
		yourComments: "Tus comentarios",
		or: "o",
		newWork: "Detalles de envío",
		submissionDetailsTitle: "Un tutor revisará tu envío y dejará comentarios para ayudarte a mejorar tu borrador.",
		submissionDetailsText: "Sus comentarios te ayudarán a mejorar el contenido, argumentos, estructura y narrativa de tus escritos así como tu gramática y ortografía. También te ayudarán a saber si citaste tus referencias de la manera correcta.",
		acceptedWork: "Nuestros tutores aceptan ensayos, escritura creativa, escritura científica, los CV's, ensayos de admisión a la universidad, y otros proyectos de escritura.",
		clickHere: "haz clic aquí",
		processingFile: "Estamos procesando tu expediente",
		essayTitle: "Título del ensayo",
		teacherInstructions: "Instrucciones",
		comments: "Comentarios",
		essayWillBeReviewed: "Tu ensayo será revisado por un tutor",
		withinTheNext: "dentro de las siguientes",
		hours: "horas",
		reviewMyEssay: "Enviar para revisión",
		submit: "Enviar",
		yourEssays: "Tus ensayos",
		expandCollapse: "Ampliar/Reducir",
		titleSelectionRequired: "Ingresa un título",
		essayCommentsRequired: "Agregar detalles de envío",
		essay: "Ensayo",
		creativeWriting: "Escritora creativa",
		scientificWriting: "Escritora científica",
		collegeAdmissionsWriting: "Ensayo de admisión a la universidad",
		otherWritingProject: "Otra proyecto de escritura",
		resume: "CV",
		weReviewAll: "Siempre revisamos la gramática, el contenido, la estructura y las referencias en los ensayos",
		anythingElse: "¿Hay algo más en lo que te gustaría que pongamos atención especial?",
		additionalComments: "Cuanto más detalles le des al tutor,  mejor podrá ayudarte ",
		instructions: "Agrega las instrucciones o agrega un archivo de instrucciones.",
		instructionsCTA: "Selecciona el archivo a cargar",
		viewOlderEssays: "Ver ensayos anteriores",
		viewAllSubmissions: "Ver todos los envíos",
		noMoreEssays: "No hay ensayos anteriores",
		tutorComments: "Comentarios de :name",
		chooseSection: "¿Para qué clase es?",
		chooseSubmissionType: "¿Qué tipo de envío es este?",
		submissionType: "Tipo de envío",
		didNotUploadEssayYet: "Aún no has subido tu ensayo",
		uploadedEssaysAppearHere: "Ensayos cargados aparecerán aquí",
		essayHelp: "Ayuda para redacción de ensayos",
		orChooseAFileToUpload: "O elige un archivo para cargar",
		cancel: "Cancelar",
		uploading: "Cargando...",
		original: "Original",
		uploadedOn: "Cargado en",
		tutorsComments: "Comentarios del tutor",
		review: "Revisar",
		at: "a",
		language: "Idioma",
		selectLanguage: "¿En qué idioma está su envío?",
		section: "Sección",
		sureCancel: "¿Estás seguro de que deseas cancelar?",
		yes: "Sí",
		no: "No",
		dropInstructionFile: "Arrastra y coloca un archivo de las instrucciones",
		typeInstructions: "O escribe las instrucciones aquí",
		uploadHeader: "Sube tu ensayo",
		essayFile: "Archivo del ensayo",
		browse: "navegar",
		orTypeBelow: "O ingresa las instrucciones aquí",
		dropFile: "Arrastra y coloca el archivo o",
		success: "Éxito",
		successfulUpload: "Has subido tu ensayo:",
		close: "Cerrar",
		browseFiles: "Busca tus archivos",
		dropHere: "Arrastra y suelta tu archivo aquí",
		uploadNewWork: "Subir trabajo nuevo",
		supportedFiles: "Se admiten todo tipo de documentos y hojas de cálculo de Google y archivos .pdf, .doc, .rtf y .txt.",
		selectFile: "Selecciona tu archivo",
		howEssayReviewWorks: "¿Cómo funciona Revisión de Ensayos?",
		uploadFileAbove: "Sube tu ensayo arriba",
		giveInformationEssay: "Comparte información acerca de tu ensayo",
		receiveReviewWithin: "Recibe tu revisión dentro de 24 horas",
		helpImprovePlagiarism:
			"Para ayudarte a mejorar tu escritura, los tutores te notificarán si partes de tu ensayo contienen plagio.",
		submitNewWork: "Envía trabajos nuevos",
		yourEssay: "Tu ensayo",
		changeFile: "Elige otro archivo",
		spellingGrammarPunctuation: "Ortografía, gramática y puntuación",
		contentArgumentsStructure: "Contenido, argumentos y estructura",
		citationsSources: "Citas y fuentes bibliográficas",
		whatTeacherInstructions: "¿Cuáles son las instrucciones de tu maestro?",
		anythingTutorFocus: "¿Hay algo en lo que te gustaría que el tutor ponga atención especial?",
		selectAsMany: "Puedes seleccionar mas de una opción o escribir un comentario para el tutor",
		submittedOn: "Enviado el",
		reviewedOn: "Revisado el",
		downloadedOn: "Descargado el",
		downloadReviewedFile: "Descarga el archivo revisado con los comentarios del tutor",
		tutorsFeedback: "Trabajo revisador",
		plagiareyes: "Verificación de plagio",
		teachersInstructions: "Instrucciones del maestro",
		noteToTutor: "Tus notas:",
		pleaseCheck: "Por favor, revisa mi:",
		dateSubmitted: "Fecha de envío",
		yourEssaySuccessfullySubmitted:
            "Hemos recibido tu ensayo. Será revisado por un tutor dentro de",
		hideOriginalSubmission: "Ocultar detalles del envío",
		originalSubmission: "Ver detalles del envío",
		submissionReviewETA: "Tu revisión estará disponible en aproximadamente",
		timeLeft: " {hours} horas",
		reviewTimeLapsed: "Tu trabajo será revisado pronto",
		estimatedReviewTime: "Los comentarios de tu tutor estarán listos para descargardentro de",
		studentReviewTimeLapsed: "Tu trabajo ha sido enviado con éxito a nuestros tutores. Será revisado pronto.",
		successfullySubmitted: "Tu trabajo ha sido enviado con éxito a nuestros tutores. Será revisado antes de {countdown}.",
		feedbackSummary: "Mensaje de tu tutor:",
		originalFile: "Tu archivo original",
		essay_submitted: "Tu ensayo ha sido enviado",
		done: "Terminado",
		next: "Próxima",
		errors: {
			invalid: "Este formato de archivo no es compatible. Por favor inténtelo de nuevo.",
			no_file_selected: "Selecciona el archivo",
			access_denied: "La autorización del acceso a su cuenta es necesaria para cargar archivos desde su Google Drive",
			too_large: "Este archivo es demasiado grande. Por favor suba un archivo más pequeño.",
			popup_blocked_by_browser: "Su navegador bloqueó la ventana emergente necesaria para utilizar esta función. Intente deshabilitar su bloqueador de ventanas emergentes y / o vuelva a intentarlo.",
			other: "Un problema ocurrió. Vuelva a intentarlo o cárguelo directamente desde su computadora",
		},
		filterTab: "Pestaña de filtro",
		feedback: {
			isReviewHelpful: "¿Fueron útiles estos comentarios del tutor?",
			submittedAnonymously: "Sus comentarios se enviarán de forma anónima.",
			reviewCenterFeedback: "Comentarios sobre el Centro de Revisión",
			helpfulGeneralComment: "¿Qué tan útil fue el resumen de comentarios?",
			helpfulHighlightedComment: "¿Qué tan útiles fueron los comentarios sobre tu trabajo?",
			notHelpful: "No útiles",
			veryHelpful: "Muy útiles",
			anythingElse: "¡Gracias! ¿Algo más que nos quieras decir?",
			improvementSuggestions: "¡Háganos saber qué podemos hacer para mejorar para la próxima vez!",
			thanksForFeedback: "¡Gracias por tus comentarios!",
		},
	},
};

export default merge(trans, generalTranslations, modalsEssay);
