var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass:
        "tw-flex tw-flex-col tw-items-start tw-w-full tw-p-4 tw-bg-origami-cyan-100 tw-rounded-lg tw-gap-4",
    },
    [
      _c(
        "h5",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "notification-heading",
              expression: "'notification-heading'",
            },
          ],
          staticClass: "tw-font-bold",
        },
        [
          _c("div", { staticClass: "tw-mb-2" }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.headingText) + "\n\t\t\t"),
            _c("span", { staticClass: "tw-text-origami-blue-300" }, [
              _vm._v(
                _vm._s(
                  _vm.$t("achievements.challenge.days", {
                    num: _vm.daysToEndOfChallenge,
                  })
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [_vm._v(_vm._s(_vm.$t("achievements.challenge.lets_go")))]),
        ]
      ),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "goalList" },
        _vm._l(_vm.challenge.goals, function (goal) {
          return _c(
            "li",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "challenge-goal",
                  expression: "'challenge-goal'",
                },
              ],
              key: goal.type,
              staticClass: "goal",
            },
            [
              goal.completed >= goal.target
                ? _c("OrigamiIcon", {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: `${goal.type}_checkmark`,
                        expression: "`${goal.type}_checkmark`",
                      },
                    ],
                    staticClass: "tw-text-origami-blue-300 tw-pr-1",
                    attrs: { name: "check-filled" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: `${goal.type}_text`,
                      expression: "`${goal.type}_text`",
                    },
                  ],
                  staticClass: "tw-truncate tw-leading-6",
                  class: {
                    "tw-text-origami-blue-300": goal.completed >= goal.target,
                    "tw-pl-6": goal.completed < goal.target,
                  },
                },
                [
                  _c("span", { staticClass: "tw-font-bold" }, [
                    _vm._v(_vm._s(_vm.$t("goals.of", goal))),
                  ]),
                  _vm._v(
                    " " + _vm._s(_vm.$t(_vm.text[goal.type])) + "\n\t\t\t"
                  ),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.item.possible_points
        ? _c("Points", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "challenge-points",
                expression: "'challenge-points'",
              },
            ],
            staticClass: "points",
            attrs: {
              points: _vm.item.possible_points,
              label: _vm.$t("trophies.points.points_short"),
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "prepend",
                  fn: function () {
                    return [
                      _vm._v(
                        "\n\t\t\t" +
                          _vm._s(_vm.$t("achievements.challenge.earn")) +
                          "\n\t\t"
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1322163742
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }