import { merge } from "lodash";

import { generalTranslations } from "@/i18n/general.js";

const trans = {
	en: {
		are_you_a_student: "Are you a student?",
		sign_in: "Sign In",
		enter: "Enter",
		password: "Password",
		username: "Username",
		email: "Email",
		sign_in_with: "Sign in with",
		sign_in_with_my_school_or_team: "Sign in with my school or team instead",
		username_or_email_address: "Username or email address",
		"email/username": "Email/Username",
		forgot_password: "Forgot password?",
		for_help_email: "For help, email",
		or: "OR",
		sign_in_with_email_or_sso: "Sign in with email or SSO instead",
		type_your_school_name_or_sports_team: "Type your school name or sports team",
		click_here: "Click here",
		for_more_info: "for more info.",
		cant_find_school: "Can't find your school?",
		TDSB_login: "your TDSB Login",
		errors: {
			google_login: {
				title: "Google Login Error",
			},
			modal: {
				reload: {
					title: "Page did not load correctly",
					subtitle:
						"Try loading the page again. If the problem continues, email support@paper.co.",
					buttonText: "Reload page",
				},
				auth0_login: {
					title: "Sign in with email, username, or SSO error",
					message: "The sign in with email or SSO page is not loading. Try the link again. If the problem continues, email support@paper.co.",
					buttonText: "OK",
				},
			},
			login_general: "Oops! Something went wrong, please try again.",
			login_empty_fields: "Please enter a username and password",
			146: "Looks like your account is expired, please contact your school administrator.",
			401: "Oops! Something isn’t right with your login details.",
			400: "Oops! Something went wrong, please try again.",
			106: "Oops! Something went wrong, please try again.",
			500: `Your username or password is incorrect.<br/>
			<a href="/trouble-logging-in" style="cursor: pointer; font-weight: 800;">I forgot my password</a>`,
			144:
				"You have been suspended for violating the <a href=\"https://paper.co/conduct\">code of conduct</a>. Contact your school admin for details.",
			classlink_failed: {
				title: "",
				message: "Classlink login failed",
			},
			clever_failed: {
				title: "",
				message: "Clever login failed",
			},
		},
		terms_of_use: "Terms of use",
		privacy_policy: "Privacy Policy",
		back_to_sign_in: "Back to sign in",
		reset: {
			email_address: "Email",
			enter_email_password_reset:
				"Enter your school email address below, and we'll send you a link to reset your password.",
			enter_email_password_student:
				"Enter your school email address below, and we'll send you a link to reset your password.",
			send_password_resent_link: "Send me a link",
			password_link_sent: "A password reset link has been sent to your email.",
		},
		terms_of_use: "Terms of use",
		privacy_policy: "Privacy Policy",
		your_school_uses: "Your school uses {SSO} to access Paper.",
		to_login_with: "To login with {SSO}:",
		click_on_login_button: "Click on the {SSO} Login button",
		SSO_login_appear: "A {SSO} login pop-up will appear",
		login_same_email: "Login using the same email and password that you use at school",
		access_paper_portal:
			"You may also be able to access Paper directly in your <b>{SSO} {accessPortal}</b> by clicking on the Paper App icon.",
		SSO_popup_not_appear: "{SSO} pop-up not appearing?",
		popup_not_appear: "Pop-up not appearing?",
		make_sure_popup_blockers:
			"Make sure you’ve disabled any “Pop-up Blockers” or “AdBlockers” you may have in your browser.",
		no_remember_school_login: "You don’t remember your school login information?",
		contact_teacher_or_school_admin: "Contact a teacher or your school administrator for help.",
		didnt_receive_email: "Didn't receive an email?",
		check_junk_spam: "Check your junk/spam folder.",
		be_sure_to_use_paper_email:
			"Be sure to use the email associated with your Paper account (your school email).",
		logging_out: "Logging out...",
	},
	fr: {
		are_you_a_student: "Es-tu un étudiant?",
		sign_in: "Identifie toi",
		enter: "Entrer",
		password: "Mot de passe",
		username: "Nom d’utilisateur",
		email: "Courriel",
		sign_in_with: "Connecte toi avec",
		sign_in_with_my_school_or_team: "Connecte toi avec ton école ou ton équipe sportive.",
		username_or_email_address: "Nom d'utilisateur ou adresse courriel",
		"email/username": "Courriel/Nom d’utilisateur",
		forgot_password: "Mot de passe oublié ?",
		for_help_email: "Problèmes de connexion?",
		or: "OU",
		sign_in_with_email_or_sso: "Identifie toi avec un courriel ou via SSO",
		type_your_school_name_or_sports_team: "Écris le nom de ton école ou équipe sportive",
		click_here: "Cliquez ici",
		for_more_info: "pour plus d'infos.",
		cant_find_school: "École introuvable?",
		errors: {
			google_login: {
				title: "Erreur connexion Google",
			},
			modal: {
				reload: {
					title: "La page ne s'est pas chargée correctement",
					subtitle:
						"Essayez de charger à nouveau la page. Si le problème persiste, envoyez un e-mail à support@paper.co.",
					buttonText: "Recharger la page",
				},
				auth0_login: {
					title: "Se connecter avec une adresse e-mail, un nom d'utilisateur ou une erreur SSO",
					message: "La page de connexion par e-mail ou SSO ne se charge pas. Essayez à nouveau le lien. Si le problème persiste, envoyez un e-mail à support@paper.co.",
					buttonText: "OK",
				},
			},
			login_general: "Oups! Un problème est survenu, essayez à nouveau.",
			login_empty_fields: "Please enter a username and password",
			146: "Ce compte n’est plus actif. Contactez l’administration de votre école.",
			401: "Oups! Il y a un problème avec ces informations de compte.",
			400: "Oups! Un problème est survenu, essayez à nouveau.",
			500: `Ton nom d'utilisateur ou ton mot de passe est incorrect.<br />
				<a href="/trouble-logging-in" style="cursor: pointer; font-weight: 800;">J'ai oublié mon mot de passe.</a>`,
			106: "Oups! Un problème est survenu, essayez à nouveau.",
			144:
				"Ce compte a été suspendu pour violation du <a href=\"https://paper.co/conduct\">code de conduite</a>. Contactez l’administration de votre école pour plus de détails.",
			classlink_failed: {
				title: "",
				message: "Erreur de connexion Classlink",
			},
			clever_failed: {
				title: "",
				message: "Erreur de connexion Clever",
			},
		},
		terms_of_use: "Conditions d’utilisation",
		privacy_policy: "Politique de confidentialité",
		back_to_sign_in: "Retour à la page d'identification.",
		reset: {
			email_address: "Courriel",
			send_password_resent_link: "Envoyer le lien",
			enter_email_password_reset:
				"Entrez votre courriel ici, et un lien de réinitialisation du mot de passe vous sera envoyé.",
			enter_email_password_student:
				"Inscris ton courriel d'école ici et un lien de réinitialisation du mot de passe te sera envoyé",
			password_link_sent:
				"Un lien de réinitialisation pour ton mot de passe a été envoyer à ton e-mail.",
		},
		terms_of_use: "Conditions d’utilisation",
		privacy_policy: "Politique de confidentialité",
		your_school_uses: "Ton école utilise {SSO} pour accéder à Paper.",
		to_login_with: "Pour te connecter:",
		click_on_login_button: "Cliques sur le bouton \"Connexion {SSO}\"",
		SSO_login_appear: "Une nouvel fenêtre {SSO} s'ouvrira",
		login_same_email:
			"Connecte-toi en utilisant le même courriel et mot de passe que tu utilises à l'école",
		access_paper_portal:
			"Tu peux aussi accéder directement à Paper en cliquant sur l’application Paper sur <b>ton tableau de bord {SSO}</b>",
		SSO_popup_not_appear: "La fenêtre {SSO} ne s’ouvre pas?",
		popup_not_appear: "La fenêtre ne s’ouvre pas?",
		make_sure_popup_blockers:
			"Si tu utilises un bloqueur de fenêtres-pub, assure-toi qu’il est désactivé.",
		no_remember_school_login: "Tu as oublié les infos du compte que tu utilises à l'école?",
		contact_teacher_or_school_admin:
			"Communiques avec un prof ou avec l’administration de ton école pour de l’aide.",
		didnt_receive_email: "As-tu reçu un courriel ?",
		not_receiving_link: "Vous ne recevez pas le lien de réinitialisation par courriel?",
		not_receiving_link_student: "Tu ne reçois pas de courriel?",
		check_spam: "Assurez-vous de vérifier vos courriers indésirables",
		check_spam_student: "Assures-toi de vérifier tes courriers indésirables",
		check_junk_spam: "Assures-toi de vérifier tes courriers indésirables",
		use_paper_email:
			"Assurez-vous que le courriel utilisé soit celui associé à votre compte Paper (courriel d'école).",
		use_paper_email_student:
			"Assures-toi d’utiliser le courriel associé à ton compte Paper (courriel d'école).",
		be_sure_to_use_paper_email:
			"Assure toi d'utiliser un courriel associé à ton compte Paper (ton courriel d'école)",
		logging_out: "Déconnecter...",
	},
	es: {
		are_you_a_student: "¿Eres un estudiante?",
		sign_in: "Acceder",
		enter: "Siguiente",
		password: "Contraseña",
		username: "Nombre de usuario",
		email: "Correo electrónico",
		sign_in_with: "Accede usando",
		sign_in_with_my_school_or_team: "Accede usando la cuenta de la escuela o equipo deportivo",
		username_or_email_address: "Nombre de usuario o correo electrónico",
		"email/username": "Correo electrónico/Nombre de usuario",
		forgot_password: "¿Olvidaste la contraseña?",
		for_help_email: "Para más asistencia, escríbenos a support@paper.co",
		or: "O",
		sign_in_with_email_or_sso: "Accede con correo electrónico o inicio de sesión único (SSO)",
		type_your_school_name_or_sports_team: "Ingresa el nombre de tu escuela o equipo deportivo",
		click_here: "Haz clic aquí",
		for_more_info: "para más información.",
		cant_find_school: "¿No encuentras tu escuela?",
		errors: {
			google_login: {
				title: "Error de acceso con Google",
			},
			modal: {
				reload: {
					title: "La página no se cargó correctamente",
					subtitle:
						"Intenta cargar la página de nuevo. Si el problema continúa, envíe un correo electrónico a support@paper.co.",
					buttonText: "Recargar página",
				},
				auth0_login: {
					title: "Iniciar sesión con correo electrónico, nombre de usuario o error de SSO",
					message: "La página de inicio de sesión con correo electrónico o SSO no se carga. Intente el enlace de nuevo. Si el problema continúa, envíe un correo electrónico a support@paper.co.",
					buttonText: "Aceptar",
				},
			},
			login_general: "¡Oh oh! Algo salió mal. Inténtalo de nuevo.",
			login_empty_fields: "Please enter a username and password",
			146: "Esta cuenta ya no está activa. Comunícate con la administración de tu escuela.",
			401: "¡Oh oh! Hay un problema con la información de esta cuenta.",
			400: "¡Oh oh! Algo salió mal. Inténtalo de nuevo.",
			106: "¡Oh oh! Algo salió mal. Inténtalo de nuevo.",
			500: `El nombre de usuario o contraseña es incorrecto.<br />
				<a href="/trouble-logging-in" style="cursor: pointer; font-weight: 800;">Olvidé la contraseña.</a>`,
			144:
				"Tu cuenta ha sido suspendida por violar el <a href=\"https://paper.co/conduct\">código de conducta</a>. Comunícate con los administradores de tu escuela para más detalles.",
			classlink_failed: {
				title: "",
				message: "Error de conexión a Classlink",
			},
			clever_failed: {
				title: "",
				message: "Error de conexión a Clever",
			},
		},
		terms_of_use: "Términos de uso",
		privacy_policy: "Poliza de privacidad",
		back_to_sign_in: "Volver al sitio de registro",
		reset: {
			email_address: "Correo electrónico",
			enter_email_password_reset:
				"Ingresa tu correo electrónico escolar aquí y se te enviará un enlace para crear una nueva contraseña.",
			enter_email_password_student:
				"Ingresa tu correo electrónico escolar aquí y se te enviará un enlace para crear una nueva contraseña.",
			send_password_resent_link: "Enviar enlace",
			password_link_sent: "Se ha enviado un enlace a tu correo electrónico para crear una nueva contraseña.",
		},
		terms_of_use: "Términos de uso",
		privacy_policy: "Poliza de privacidad",
		your_school_uses: "Tu escuela usa {SSO} para ingresar a Paper.",
		to_login_with: "Para ingresar con {SSO}:",
		click_on_login_button: "Haz clic en el botón de Acceso {SSO}",
		SSO_login_appear: "Se abrirá una nueva ventana {SSO}",
		login_same_email: "Ingresa con el mismo correo electrónico y contraseña que usas en la escuela",
		access_paper_portal:
			"También puedes acceder a Paper directamente haciendo clic en la ícono de la aplicación Paper en tu <b>{SSO},{portal}</b>",
		SSO_popup_not_appear: "¿No abre la ventana {SSO}?",
		popup_not_appear: "¿No abre la ventana?",
		make_sure_popup_blockers:
			"Asegúrate de desactivar cualquier “bloqueador de ventanas” o “bloqueador de anuncios” en tu navegador.",
		no_remember_school_login: "¿Olvidaste la información de cuenta que usas en la escuela?",
		contact_teacher_or_school_admin: "Comunícate con un maestro o administrador de tu escuela para recibir ayuda.",
		check_spam_student: "Asegúrate de revisar tu folder de basura/spam",
		didnt_receive_email: "¿No recibiste un correo electrónico?",
		not_receiving_link: "¿No recibiste el enlace para crear nueva contraseña por correo electrónico?",
		not_receiving_link_student: "¿No recibiste el enlace para crear nueva contraseña por correo electrónico?",
		check_spam: "Asegúrate de revisar tu folder de basura/spam.",
		check_junk_spam: "Asegúrate de revisar tu folder de basura/spam.",
		use_paper_email:
			"Asegúrate de usar el correo electrónico asociado con tu cuenta de Paper (correo electrónico escolar).",
		use_paper_email_student:
			"Asegúrate de usar el correo electrónico asociado con tu cuenta de Paper (correo electrónico escolar).",
		be_sure_to_use_paper_email:
			"Asegúrate de utilizar el correo electrónico asociado con tu cuenta de Paper (correo electrónico de la escuela)",
		logging_out: "Cerrando la sesión...",
	},
};

export default merge(generalTranslations, trans);
