var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "nav",
    {
      staticClass: "sidebar",
      class: { toggled: _setup.props.isToggled },
      attrs: { id: "sidebar" },
    },
    [
      _c(_setup.SkipToMainContent, {
        staticClass:
          "skipToMain tw-border-b-2 tw-border-solid tw-border-origami-grey-100 hover:tw-shadow-origami-xl",
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tw-sticky tw-top-0" },
        [
          _c(_setup.ChallengesWidget, {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_setup.props.isToggled,
                expression: "!props.isToggled",
              },
              {
                name: "jest",
                rawName: "v-jest",
                value: "challenges-widget",
                expression: "'challenges-widget'",
              },
            ],
            staticClass: "tw-py-4",
          }),
          _vm._v(" "),
          _c("span", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_setup.props.isToggled,
                expression: "!props.isToggled",
              },
            ],
            staticClass:
              "tw-absolute tw-left-0 tw-w-full tw-border-b-2 tw-border-solid tw-border-origami-grey-100 tw-inline-block",
          }),
          _vm._v(" "),
          _c(
            _setup.SuspensePattern,
            {
              staticClass: "tw-mb-4",
              attrs: { "is-showing-fallback": _setup.isLoading },
            },
            [
              _c(_setup.Links, {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "nav-links",
                    expression: "'nav-links'",
                  },
                ],
                staticClass: "tw-mt-4",
                attrs: {
                  "nav-links": _setup.reactiveNavLinks,
                  "is-toggled": _setup.props.isToggled,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("span", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_setup.props.isToggled,
                expression: "!props.isToggled",
              },
            ],
            staticClass:
              "tw-absolute tw-left-0 tw-w-full tw-border-b-2 tw-border-solid tw-border-origami-grey-100 tw-inline-block",
          }),
          _vm._v(" "),
          _setup.featureFlagGradeLevelToggleIsEnabled
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_setup.props.isToggled,
                      expression: "!props.isToggled",
                    },
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "grade-level",
                      expression: "'grade-level'",
                    },
                  ],
                  staticClass: "tw-p-4",
                },
                _vm._l(_setup.gradeLevels, function (level) {
                  return _c("div", { key: level }, [
                    _c("input", {
                      class: "tw-mr-2" + _setup.educationLevel,
                      attrs: { id: level, type: "radio", name: "school" },
                      domProps: { checked: level === _setup.educationLevel },
                      on: {
                        change: function ($event) {
                          return _setup.publishToMicroFrontEnds(
                            _setup.MICRO_FRONTENDS_HOST_EVENTS
                              .GRADE_LEVEL_UPDATE,
                            { gradeLevel: level }
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "tw-mb-0", attrs: { for: level } },
                      [_vm._v(_vm._s(level))]
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }