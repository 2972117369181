<template>
	<TutorBioBreakCard
		:break="currentBreak"
	>
		<template #metaDataIcon>
			<component
				:is="getScheduleTypeId() ? 'IconSession' : 'IconEssay'"
				icon-color="currentColor"
				class="tw-mr-2 tw-align-middle tw-text-black-high"
			/>
		</template>
		<template #metadata>
			{{ getScheduleTypeId() ? "Live Help" : "Writing Review" }}
		</template>
		<template #breakDetailsIcon>
			<OrigamiIcon name="bio-break" />
		</template>
		<template #breakDetails>
			Back at {{ formatShiftTime() }}
		</template>
	</TutorBioBreakCard>
</template>

<script>
import { OrigamiIcon } from "@origami/vue2";

import IconSession from "@/components/icons/IconSession.vue";
import IconEssay from "@/components/icons/IconEssay.vue";
import { formatIsoDateToHM } from "@/utilities/dateHelpers.js";

import { SHIFT_TYPE_ID } from "../utilities/ShiftTypeIDs.js";

import TutorBioBreakCard from "./TutorBioBreakCard.vue";

export default {
	components: {
		TutorBioBreakCard,
		IconSession,
		IconEssay,
		OrigamiIcon,
	},
	props: {
		currentBreak: {
			type: Object,
			required: true,
		},
	},
	methods: {
		getScheduleTypeId() {
			return this.currentBreak.state.current_segment.type.id === SHIFT_TYPE_ID.CLASSROOM;
		},
		formatShiftTime() {
			return formatIsoDateToHM(this.currentBreak.state.current_modifier.end_time);
		},
	},
};
</script>
