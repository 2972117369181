<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		:width="size"
		:height="size"
		viewBox="0 0 20 20"
	>
		<defs>
			<path
				:id="`a${randomId}`"
				d="M17.64 10.98c.042-.32.073-.64.073-.98 0-.34-.031-.66-.072-.98l2.169-1.65a.495.495 0 0 0 .123-.64l-2.056-3.46c-.123-.22-.4-.3-.627-.22l-2.56 1c-.534-.4-1.11-.73-1.737-.98l-.39-2.65a.497.497 0 0 0-.505-.42H7.946a.497.497 0 0 0-.504.42l-.39 2.65c-.627.25-1.203.59-1.738.98l-2.56-1a.508.508 0 0 0-.627.22L.071 6.73a.484.484 0 0 0 .124.64l2.169 1.65c-.041.32-.072.65-.072.98 0 .33.03.66.072.98l-2.17 1.65a.495.495 0 0 0-.123.64l2.056 3.46c.124.22.401.3.627.22l2.56-1c.535.4 1.11.73 1.738.98l.39 2.65c.031.24.247.42.504.42h4.112c.257 0 .473-.18.504-.42l.39-2.65a7.955 7.955 0 0 0 1.738-.98l2.56 1c.236.09.504 0 .627-.22l2.056-3.46a.495.495 0 0 0-.123-.64l-2.17-1.65zm-7.638 2.52c-1.984 0-3.598-1.57-3.598-3.5s1.614-3.5 3.598-3.5c1.984 0 3.598 1.57 3.598 3.5s-1.614 3.5-3.598 3.5z"
			/>
		</defs>
		<use
			:fill="iconColor"
			fill-rule="evenodd"
			:xlink:href="`#a${randomId}`"
		/>
	</svg>
</template>

<script>
import Colors from "@/utilities/Colors.js";
import IconId from "@/components/icons/IconId.js";

export default {
	extends: IconId,
	props: {
		iconColor: {
			type: String,
			required: false,
			default: Colors.C_BLUE,
		},
		size: {
			type: Number,
			required: false,
			default: 14,
		},
	},
};
</script>
