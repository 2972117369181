/* eslint-disable */
import rootInstance from "@/services/root.js";

import { orderBy, pick } from "lodash";

import {uploadFiles, getImageUrl} from './contentCatalog.js';

const baseUrl = process.env.MATH_PROBLEM_API_URL + '/api/v1/missions';

const mathApi  = (url = baseUrl) => {
	rootInstance.defaults.baseURL = url;
	return rootInstance;
};

export const getProblems = (params = {}) =>
	mathApi().get('/', {params})
		.then(({data}) => {
			const listData = params.paged ? data.data : data;
			const convertedData = listData.map( item => ({
				...item,
				comments: orderBy(item.comments, ['created_at']),
				json_configure: JSON.parse(item.json_configure)
			}));

			const pagination = pick(data.meta, ['current_page', 'last_page', 'total', 'per_page']);

			return {
				pagination,
				data: orderBy( 
					convertedData, 
					['subject_id', 'topic_id', 'created_at'],
					['asc', 'asc', 'desc'],
				)
			}
		}
	)

const extractData = ({data}) => data;

export const getProblem = (problem_id) => 
	mathApi().get(`/${problem_id}`)
	.then(extractData)
	.then( data => {
		const tags = data.tags.map( tag =>
			typeof tag === 'object' ? tag.id : tag
		)
		return {...data, tags, active: Boolean(data.active), training: Boolean(data.training)};
	});
	;
;

export const createProblem = (problem) => {
	const payload = {
		...problem, 
		json_configure: JSON.stringify(problem.json_configure)
	};

	return mathApi().post('/', payload).then(extractData);
};

export const saveProblem = (problem) => {
	const payload = {
		...problem, 
		json_configure: JSON.stringify(problem.json_configure)
	};

	return mathApi().put(`/${payload.id}`, payload).then(extractData);
};

export const addComment = (problemId, commentText) =>
	mathApi().post(`/${problemId}/comment`, commentText).then(extractData);

export const deleteProblem = (problemId) =>
	mathApi().delete(`/${payload.id}`, payload).then(extractData);

export const setProblemListOrder = (payload) =>
	mathApi().put(`/mission-order`, payload).then(extractData);

export {uploadFiles, getImageUrl};
