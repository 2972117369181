var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `
		tw-inline-flex tw-items-center tw-justify-center tw-flex-shrink-0
		tw-bg-white-high tw-text-black-high tw-align-middle
		tw-font-bold tw-rounded-full tw-overflow-hidden
	`,
      style: { width: `${_vm.size}px`, height: `${_vm.size}px` },
    },
    [
      _vm.avatarName ||
      _vm.isShowDefaultAvatar ||
      _vm.$store.state.Student.Profile.isLoadingProfile
        ? _c("Avatar", {
            class: `${
              _vm.variant === "cover"
                ? "tw-self-start tw-w-full tw-h-auto"
                : "tw-w-auto tw-h-2/3"
            }`,
            attrs: {
              name: _vm.avatarName || "default",
              colors: _vm.avatarColors,
            },
          })
        : [_vm._v("\n\t\t" + _vm._s(_vm.initials) + "\n\t")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }