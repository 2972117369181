//APIS
import { UsersAPI } from "@/modules/Customers/index.js";
import { LanguagesAPI } from "@/modules/Languages/index.js";
import ScheduleTypeAPI from "@/services/api/ScheduleTypes.js";

import SchedulesAPI from "../../../services/api/Schedules.js";
import SubjectsAPI from "../../../services/api/Subjects.js";
import TutorsAPI from "../../../services/api/Tutors.js";
import {
	DEFAULT,
	CREATING_SHIFT,
	EDITING_SHIFT,
	EXTENDING_SHIFT,
	DELETING_SHIFT,
	FILTERING_SHIFTS,
} from "../utilities/TutorQuickEditSteps.js";

const getDefaultState = () => ({
	currentWeek: "",
	loadedWeeks: [],
	currentEvent: {},
	shifts: [],
	isEssayShift: false,
	navbarMainTab: "Service",
	subjectsList: [],
	tutorsList: [],
	currentTutor: {},
	currentShiftType: {},
	dateRange: {},
	languageList: [],
	scheduleTypesList: [],
	currentTutorQuickEditStep: DEFAULT,
});

export const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_TUTORS(state, payload) {
		state.tutorsList = payload.tutors;
	},
	SET_CURRENT_TUTOR(state, payload) {
		state.currentTutor = payload.currentTutor;
	},
	SET_CURRENT_SHIFT_TYPE(state, payload) {
		state.currentShiftType = payload.currentShiftType;
	},
	SET_DATE_RANGE(state, payload) {
		state.dateRange = payload.dateRange;
	},
	SET_SUBJECTS(state, payload) {
		state.subjectsList = payload.subjects;
	},
	SET_SHIFT_IN_SHIFTS_ARRAY(state, payload) {
		state.shifts.push(payload.currentEvent);
	},
	SET_SHIFTS_ARRAY(state, payload) {
		state.shifts = payload.shifts;
	},
	SET_LANGUAGE_LIST(state, payload) {
		state.languageList = payload.languages;
	},
	UPDATE_SHIFT_IN_SHIFTS_ARRAY(state, payload) {
		state.currentEvent = payload.currentEvent;
	},
	DELETE_SHIFT_IN_SHIFTS_ARRAY(state, payload) {
		state.shifts = state.shifts.filter((shift) => {
			return shift.id !== payload.id;
		});
	},
	SET_IS_ESSAY_SHIFT(state, payload) {
		state.isEssayShift = payload.isEssayShift;
	},
	PUSH_LOADED_WEEK_IN_LOADED_WEEKS(state, payload) {
		state.loadedWeeks.push(payload.loadedWeek);
	},
	SET_CURRENT_START_OF_WEEK_IN_SCHEDULER(state, payload) {
		state.currentWeek = payload.currentWeek;
	},
	UPDATE_SHIFTS_IN_SHIFTS_ARRAY(state, payload) {
		state.shifts.find((shift) => {
			if (
				shift.id == payload.shiftId &&
				payload.time.start_time !== undefined &&
				payload.time.end_time !== undefined
			) {
				shift.start_time = payload.time.start_time;
				shift.end_time = payload.time.end_time;
			}
		});
	},
	SET_SCHEDULE_TYPES(state, payload) {
		state.scheduleTypesList = payload.scheduleTypesList;
	},
	SET_CURRENT_TUTOR_QUICK_EDIT_STEP(state, payload) {
		state.currentTutorQuickEditStep = payload;
	},
};

export const actions = {
	async getSubjects({ commit }, payload) {
		try {
			const subjects = await SubjectsAPI.list(payload);
			commit("SET_SUBJECTS", {
				subjects: subjects.data.data,
			});
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response);
		}
	},
	async createShift({}, payload) {
		try {
			const response = await SchedulesAPI.create(payload);
			return Promise.resolve(response.data);
		} catch (e) {
			Sentry.captureException(e);
			throw e;
		}
	},
	async updateShift({ commit }, payload) {
		try {
			const response = await SchedulesAPI.update(payload.id, payload.data);
			const shiftId = payload.id;
			const time = payload.data;
			commit("UPDATE_SHIFTS_IN_SHIFTS_ARRAY", {
				shiftId,
				time,
			});
			return Promise.resolve(response);
		} catch (e) {
			Sentry.captureException(e);
			throw e;
		}
	},
	async deleteShift({ commit }, payload) {
		try {
			await SchedulesAPI.delete(payload.id);
			commit("DELETE_SHIFT_IN_SHIFTS_ARRAY", {
				id: payload.id,
			});
		} catch (e) {
			Sentry.captureException(e);
			throw e;
		}
	},
	async getShifts({ commit }, payload) {
		try {
			const response = await SchedulesAPI.list(payload);
			const shifts = response.data.data;
			commit("SET_SHIFTS_ARRAY", {
				shifts,
			});
			commit("PUSH_LOADED_WEEK_IN_LOADED_WEEKS", {
				loadedWeek: payload.from,
			});
		} catch (e) {
			Sentry.captureException(e);
			throw e;
		}
	},
	async getAvailabilityTutors({ commit }) {
		try {
			const response = await TutorsAPI.list({
				showAll: 1,
			});
			commit("SET_TUTORS", {
				tutors: response.data.data,
			});
			return Promise.resolve(response.data);
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	async getLanguageList({ commit }) {
		try {
			const response = await LanguagesAPI.list();
			const languagesList = response.data.data.filter((language) => language.tutorable);

			commit("SET_LANGUAGE_LIST", {
				languages: languagesList,
			});
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	async getTutor({}, payload) {
		try {
			const response = await UsersAPI.show(payload.id, payload.data);
			return Promise.resolve(response.data);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	async getScheduleTypes({ commit }) {
		try {
			const response = await ScheduleTypeAPI.get();
			commit("SET_SCHEDULE_TYPES", {
				scheduleTypesList: response.data.data,
			});
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	setCurrentTutor({ commit }, payload) {
		commit("SET_CURRENT_TUTOR", {
			currentTutor: payload.currentTutor,
		});
	},
	setDateRange({ commit }, payload) {
		commit("SET_DATE_RANGE", {
			dateRange: payload.dateRange,
		});
	},
	setCurrentShiftType({ commit }, payload) {
		commit("SET_CURRENT_SHIFT_TYPE", {
			currentShiftType: payload.currentShiftType,
		});
	},
	setScheduleTypes({ commit }, payload) {
		commit("SET_SCHEDULE_TYPES", {
			scheduleTypesList: payload.scheduleTypesList,
		});
	},
	setShiftsArray({ commit }, payload) {
		commit("SET_SHIFTS_ARRAY", {
			shifts: payload.shifts,
		});
	},
	setIsEssayShift({ commit }, payload) {
		commit("SET_IS_ESSAY_SHIFT", {
			isEssayShift: payload.isEssayShift,
		});
	},
	resetTutorQuickEditStep({ commit }) {
		commit("SET_CURRENT_TUTOR_QUICK_EDIT_STEP", DEFAULT);
	},
	resetTutorAndShiftType({ commit }) {
		commit("SET_CURRENT_TUTOR", {
			currentTutor: {},
		});
		commit("SET_CURRENT_SHIFT_TYPE", {
			currentShiftType: {},
		});
	},
	startCreateShiftProcess({ commit }) {
		commit("SET_CURRENT_TUTOR_QUICK_EDIT_STEP", CREATING_SHIFT);
	},
	startEditShiftProcess({ commit }) {
		commit("SET_CURRENT_TUTOR_QUICK_EDIT_STEP", EDITING_SHIFT);
	},
	startExtendShiftProcess({ commit }) {
		commit("SET_CURRENT_TUTOR_QUICK_EDIT_STEP", EXTENDING_SHIFT);
	},
	startDeleteShiftProcess({ commit }) {
		commit("SET_CURRENT_TUTOR_QUICK_EDIT_STEP", DELETING_SHIFT);
	},
	startFilteringShiftProcess({ commit }) {
		commit("SET_CURRENT_TUTOR_QUICK_EDIT_STEP", FILTERING_SHIFTS);
	},
};

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations,
	actions,
};
