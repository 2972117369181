var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "span",
      {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "title",
            expression: "'title'",
          },
        ],
        staticClass: "commentBox_title",
      },
      [
        _vm._v(
          "\n\t\t" +
            _vm._s(
              _vm.featureFlagUpdateHandoffFlowIsEnabled
                ? "Leave a comment (required):"
                : "Leave a comment (optional):"
            ) +
            "\n\t"
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", [
      _c("textarea", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "handoff-comment",
            expression: "'handoff-comment'",
          },
        ],
        staticClass: "handoffModal__commentBox tw-p-2",
        class: {
          "!tw-border-origami-red-400":
            _vm.featureFlagUpdateHandoffFlowIsEnabled && _vm.isNoCommentError,
        },
        attrs: { placeholder: "Add comment" },
        domProps: { value: _vm.value },
        on: { input: _vm.handleInput },
      }),
    ]),
    _vm._v(" "),
    _vm.featureFlagUpdateHandoffFlowIsEnabled && _vm.isNoCommentError
      ? _c(
          "div",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "no-comment-error",
                expression: "'no-comment-error'",
              },
            ],
            staticClass: "tw-flex tw-mb-2",
          },
          [
            _c("OrigamiIcon", {
              staticClass: "tw-mr-2 tw-text-origami-red-400",
              attrs: { name: "warning" },
            }),
            _vm._v(" "),
            _c("p", { staticClass: "tw-text-base tw-text-origami-red-400" }, [
              _vm._v(
                "\n\t\t\tPlease provide additional details for the next tutor. This is required.\n\t\t"
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "handOff__attachment__items",
        on: { click: _vm.redirectClickToInput },
      },
      [
        _c(
          "span",
          { staticClass: "handOff__attachment__icon" },
          [_c("IconAttachment", { staticClass: "tw-text-blue-regular" })],
          1
        ),
        _vm._v("\n\t\tSelect a file to attach\n\t"),
      ]
    ),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("input", {
      ref: "fileInput",
      staticClass: "attachmentFile__input",
      attrs: {
        id: "fileInput",
        type: "file",
        accept: ".pdf, .png, .jpeg, .jpg, .doc, .docx, .xlsx, .zip",
      },
      on: { change: _vm.handleNewFile },
    }),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "attachmentFile__list" },
      _vm._l(_vm.files, function (file, index) {
        return _c(
          "li",
          { key: index, staticClass: "attachmentFile", attrs: { file: file } },
          [
            _vm._v("\n\t\t\t" + _vm._s(file.name) + "\n\t\t\t"),
            _c(
              "span",
              {
                staticClass: "attachmentFile__removalLink",
                on: {
                  click: function ($event) {
                    return _vm.removeFile(file)
                  },
                },
              },
              [_vm._v("\n\t\t\t\tRemove file\n\t\t\t")]
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }