<template>
	<div
		class="tw-my-4"
		:class="{ embedded }"
	>
		<div
			v-show="isLoadingGoogleFile"
			class="
				tw-flex
				tw-justify-center
				tw-items-center
				tw-w-full
				tw-h-52
				tw-bg-grey-pale
				tw-border-dashed
				tw-border-origami-grey-300
				tw-border-2
				tw-mb-6
				tw-flex
				tw-flex-col
			"
		>
			<BaseLoader class="tw-h-20 tw-w-20 tw-text-blue-regular">
				<IconEssay
					icon-color="currentColor"
					class="tw-w-5 tw-h-7"
					aria-hidden="true"
					focusable="false"
				/>
			</BaseLoader>
			<span class="tw-mt-4 tw-font-bold">{{ $t("processingFile") }}</span>
		</div>
		<FileDropZone
			v-show="!isLoadingGoogleFile"
			clickable
			dropzone-click-disabled
			class="tw-flex tw-flex-col tw-items-center tw-w-full tw-px-4 tw-py-12 tw-justify-center"
			@change="$emit('handle-essay-file-input', $event)"
		>
			<template #default="{ trigger, isDraggingOver, inputZIndex }">
				<div class="tw-font-bold">
					{{ $t("dropHere") }}
				</div>
				<div class="dropArea__text--large dropArea__text--lightGrey tw-pb-3">
					{{ $t("orChooseAFileToUpload") }}
				</div>
				<div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2">
					<BaseButton
						id="uploadButton"
						class="tw-relative tw-m-1"
						:style="{ zIndex: isDraggingOver ? 'auto' : inputZIndex + 1 }"
						@click.native="trigger"
					>
						<template #prepend>
							<IconComputer
								class="tw-mr-2"
								aria-hidden="true"
								focusable="false"
							/>
						</template>
						{{ $t("browseFiles") }}
					</BaseButton>
					<GooglePicker
						:doc-validator="docValidator"
						:mime-types="mimeTypes"
						class="tw-relative tw-m-1"
						:style="{ zIndex: isDraggingOver ? 'auto' : inputZIndex + 1 }"
						@click.native.stop
						@change="$emit('handle-essay-file-input-from-google-drive', $event)"
						@error="$emit('error', $event)"
						@change-start="isLoadingGoogleFile = true"
						@change-end="isLoadingGoogleFile = false"
					>
						<template #default="{ trigger: triggerGooglePicker, isLoaded }">
							<BaseButton
								:disabled="!isLoaded"
								@click.native.prevent.stop="openGooglePicker(triggerGooglePicker)"
							>
								<template #prepend>
									<IconGoogleDrive
										class="tw-mr-2"
										aria-hidden="true"
										focusable="false"
									/>
								</template>
								{{ $t("googleDrive") }}
							</BaseButton>
						</template>
					</GooglePicker>
				</div>
				<div class="tw-text-center tw-text-sm tw-mt-1">
					{{ $t("supportedFiles") }}
				</div>
			</template>
			<template #input="{ bind, on }">
				<input
					v-data-cy="'essay-file-instruction-step'"
					v-bind="bind"
					:accept="mimeTypes.join()"
					v-on="on"
				>
			</template>
		</FileDropZone>
		<span
			v-show="fileUploadErrorMessage"
			class="tw-text-sm tw-font-bold tw-text-origami-red-400 tw-mt-4"
			:class="{ 'tw-px-3': embedded }"
		>
			{{ fileUploadErrorMessage }}
		</span>
		<a
			v-if="embedded"
			class="tw-text-blue-regular tw-text-center tw-block tw-font-bold tw-my-4"
			target="_blank"
			href="/student/review-center"
		>{{ $t("viewAllSubmissions") }}</a>
	</div>
</template>

<script>
import { pickerPopupDimensions } from "../../../pages/default/googlePicker.vue";
import FileDropZone from "../../../components/patterns/FileDropZone.vue";
import BaseButton from "../../../components/elements/BaseButton.vue";
import GooglePicker from "../../../components/GooglePicker.vue";
import IconComputer from "../../../components/elements/icons/Computer.vue";
import IconGoogleDrive from "../../../components/elements/icons/GoogleDrive.vue";
import IconEssay from "../../../components/icons/IconEssay.vue";
import BaseLoader from "../../../components/elements/BaseLoader.vue";
import { openLinkInPopup } from "../../../utilities/links.js";

export default {
	components: {
		FileDropZone,
		BaseButton,
		GooglePicker,
		IconComputer,
		IconGoogleDrive,
		IconEssay,
		BaseLoader,
	},
	props: {
		docValidator: {
			type: Function,
			default: () => {},
		},
		mimeTypes: {
			type: Array,
			default: () => [],
		},
		fileUploadErrorMessage: {
			type: String,
			default: null,
		},
		embedded: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isLoadingGoogleFile: false,
		};
	},
	methods: {
		async openGooglePicker(triggerGooglePicker) {
			if (this.embedded) {
				try {
					const { file, error } = await openLinkInPopup(
						"/google-picker",
						pickerPopupDimensions,
					);
					if (error) {
						this.$emit("error", error);
						throw error;
					} else if (file) {
						this.$emit("handle-essay-file-input-from-google-drive", file);
					}
				} catch (e) {
					Sentry.captureException(e);
				}
			} else {
				triggerGooglePicker();
			}
		},
	},
};
</script>

<style scoped>
.instructions__listNumber {
	border-radius: 17px;
	line-height: 17px;
	min-height: 17px;
	min-width: 17px;
	height: 17px;
	width: 17px;
	color: var(--origami-white);
	background-color: var(--origami-blue-300);
	text-align: center;
	font-weight: 600;
	display: inline-block;
	font-size: 10px;
	margin-right: 12px;
	vertical-align: text-bottom;
}

.instructions__subheader {
	font-size: 16px;
	margin-bottom: 20px;
}

.instructions__container {
	border-radius: 5px;
	background-color: var(--c-lighter-gray);
	margin-top: 10px;
	padding: 16px 50px;
	width: 100%;
}
.embedded .instructions__container {
	background-color: transparent;
	padding: 1rem 0;
}

.instructions {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.instructions__container--google {
	text-align: center;
}

.errorContainer {
	border-radius: 0.3125rem;
	min-height: 4rem;
}

.instructions__iconEssayReview {
	min-width: 174px;
	margin-right: 20px;
}

.icon__plagiarEyes {
	overflow: visible;
	margin-right: 6px;
}

.instructions__plagiarismText {
	font-size: 12px;
	color: var(--c-black);
	vertical-align: super;
}
@media screen and (max-width: 450px) {
	.instructions__iconEssayReview {
		display: none;
	}
}
</style>
<style>
ol li {
	padding-bottom: 1rem;
}
</style>
