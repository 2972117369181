var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "none",
      attrs: {
        viewBox: "0 0 16 16",
        width: "16",
        height: "16",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        staticClass: "fill-current",
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M13.6991 8.96712L9.62363 13.0426C7.85825 14.808 5.00862 14.8306 3.27102 13.093C1.53346 11.3554 1.55603 8.50574 3.32141 6.74036L7.66363 2.39814C8.91977 1.142 10.947 1.12596 12.1834 2.36233C13.4197 3.59869 13.4041 5.62629 12.1479 6.88243L12.1493 6.88386L7.90036 11.1328L7.89861 11.1311C7.14905 11.8427 5.97023 11.8398 5.24684 11.1164C4.51202 10.3816 4.52123 9.1757 5.26819 8.42873L5.26784 8.42837L8.97527 4.72094L9.97551 5.72117L6.26808 9.42861L6.26843 9.42897C6.08188 9.61552 6.07949 9.91662 6.26307 10.1002C6.44708 10.2842 6.74811 10.2818 6.93466 10.0952L6.93609 10.0966L11.1491 5.88362L11.1477 5.88219C11.8434 5.18643 11.852 4.0634 11.1671 3.37856C10.4823 2.69375 9.35962 2.70262 8.66387 3.39838L4.32165 7.7406C3.11669 8.94556 3.10126 10.8907 4.28726 12.0767C5.47325 13.2627 7.41843 13.2473 8.62339 12.0423L12.6988 7.96688L13.6991 8.96712Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }