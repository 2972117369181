import api from "@/services/api/Api.js";

export default {
	/**
	 * Create new teacher content
	 * @param {Object} params
	 * @return {AxiosPromise}
	 */
	create(params) {
		return api().post("teacher-content", params);
	},
	/**

	 * Get individual teacher content
	 * @param {Number} teacherContentId
	 * @return {AxiosPromise}
	 */
	show(teacherContentId, params) {
		return api().get(`teacher-content/${teacherContentId}`, { params });
	},
	/**
	 * Update existing teacher content
	 * @param {Number} teacherContentCategoryId
	 * @param {Object} params
	 * @return {AxiosPromise}
	 */
	update(teacherContentId, params) {
		return api().post(`teacher-content/${teacherContentId}`, params);
	},

	/**
	 * @param params
	 * @returns {Promise}
	 */
	list(params = {}) {
		return api().get("teacher-content", {
			params: params,
		});
	},

	/**
	 *
	 * @param {*} teacherContentId
	 * @returns {Promise}
	 */
	delete(teacherContentId) {
		return api().delete(`/teacher-content/${teacherContentId}`);
	},
};
