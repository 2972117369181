var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M8.75781 14.3008L15.1484 3.25H8.85156L2.46094 14.3008H8.75781ZM14.1289 15.2227L17.2773 20.75H5.14844L2 15.2227H14.1289ZM15.9805 4.63281L22 15.2227L18.8516 20.75L12.7422 10.1562L15.9805 4.63281Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }