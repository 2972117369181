var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tutorImage",
      style: { height: _vm.dimensions + "px", width: _vm.dimensions + "px" },
    },
    [
      !_vm.isShowingDefaultImage
        ? _c("img", {
            staticClass: "tutorImage__picture tw-text-xs",
            attrs: {
              alt: _vm.$t("tutor_profile_picture", {
                fullTutorName: _vm.fullTutorName,
              }),
              src: _vm.profilePath,
              height: _vm.dimensions,
              width: _vm.dimensions,
            },
            on: {
              error: function ($event) {
                return _vm.showDefaultImage()
              },
            },
          })
        : _c("IconDefaultTutorPicture", {
            staticClass: "defaultTutorImage",
            attrs: { width: _vm.dimensions, height: _vm.dimensions },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }