import { omit, isNil, isEmpty } from "lodash";

import MessageText from "@/modules/Classroom/classes/MessageText.js";
import ReceivedMessage from "@/modules/Classroom/classes/ReceivedUserMessageDecorator.js";
import MessageMedia from "@/modules/Classroom/classes/MessageMedia.js";
import ChatIntro from "@/modules/Classroom/classes/ChatIntro.js";
import MessageSystem from "@/modules/Classroom/classes/MessageSystem.js";
import MessageQuestionMatcher from "@/modules/Classroom/classes/MessageQuestionMatcher.js";
import MessageMediaEmpty from "@/modules/Classroom/classes/MessageMediaEmpty.js";
import AssignmentInstructionsMessage from "@/modules/Classroom/classes/AssignmentInstructionsMessage.js";
import TutorHandoffCommentMessage from "@/modules/Classroom/classes/TutorHandoffCommentMessage.js";
import MessageFactory from "@/services/factories/MessageFactory.js";

import { getChatIntro } from "./ChatIntroHelpers.js";
import { formatTimestampToDate } from "./dateHelpers.js";
import { hasRole } from "./user.js";
import { pascalCase } from "./strings.js";

export const MESSAGE_CLASSES = [
	MessageText,
	MessageMedia,
	MessageMediaEmpty,
	MessageSystem,
	ChatIntro,
	MessageQuestionMatcher,
	AssignmentInstructionsMessage,
	TutorHandoffCommentMessage,
];

/**
 * @param {Object} message
 * @return {Object}
 */
export const getMessageDataFromMessageClass = (message) => {
	let messageData = null;
	if (message instanceof ReceivedMessage) {
		messageData = message.message;
	} else if (MESSAGE_CLASSES.some((msgClass) => message instanceof msgClass)) {
		messageData = message;
	}
	return messageData;
};

/**
 * @param {Array} messages
 * @return {Array}
 */
export const filterDeletedMediaMessages = (messages) => {
	return messages.filter((message) => {
		const isReceivedMessageNotDeleted = message instanceof ReceivedMessage && !message.message.isDeleted;
		const isMessageMediaNotDeleted = message instanceof MessageMedia && !message.isDeleted;
		if (isReceivedMessageNotDeleted || isMessageMediaNotDeleted) {
			return message;
		}
		return false;
	});
};

/**
 * @param {Object} lastMessage
 * @param {Number} user_id
 * @returns {String}
 */
const determineSequencePositionOfMessage = (lastMessage, user_id) => {
	let sequencePosition = "bottom";

	const lastMessageData = getMessageDataFromMessageClass(lastMessage);

	if (lastMessage === null || (lastMessageData && lastMessageData.userId !== user_id)) {
		sequencePosition = "top";
	}
	return sequencePosition;
};

const transformUserMessageObject = (data, lastMessageInSession) => {
	const messageData = {
		type: data.type,
		isLastMessage: true,
		created_at: data.created_at,
		id: data.id || data.messageId || data.pseudo_guid,
		justification: data.justification,
		userReactions: data.userReactions?.data || data.userReactions || [],
		voiceNoteMetadata: data.voiceNoteMetadata?.data || data.voiceNoteMetadata,
		isQuestion: data.is_question,
		isDeleted: data.is_deleted,
		isQuarantined: data.is_quarantined,
		user_id: data.user_id,
		bookmarkedAt: data.bookmarked_at,
		sequencePosition: determineSequencePositionOfMessage(
			lastMessageInSession,
			data.user_id,
		) || null,
	};
	if (data.justification === "left") {
		messageData.user = {
			data: {
				first_name: data.firstName,
				last_name: data.lastName,
				profile: data.profile,
				avatar: data.avatar || (data.user && data.user.data && data.user.data.avatar),
			},
		};
		messageData.isTutor = data.isTutor;
	}
	return messageData;
};

const messageObjectTransformationDict = {
	welcome: (data) => data,
	system: (data) => data,
	questionMatcher: (data) => data,
	media: (data, lastMessageInSession) => {
		const messageData = transformUserMessageObject(data, lastMessageInSession);
		messageData.fileData = {
			file_path: data.file.filepath,
			basename: data.file.basename,
			file_type: data.file.filetype,
			file_thumbnail: data.file.thumbnail,
		};
		return messageData;
	},
	text(data, lastMessageInSession) {
		const messageData = transformUserMessageObject(data, lastMessageInSession);
		messageData.message = data.message;
		messageData.json = data.message_json;
		return messageData;
	},
};

/**
 * @param data.sessionMessagesArray {Array}
 * @param data.localHandoffs {Array}
 * @param data.message
 * @param data.session {Object}
 * @param data.currentUser {Object}
 */
const compareNextHandoffToNextMessage = (data) => {

	const { sessionMessagesArray, localHandoffs, nextMessage, session, currentUser } = data;

	if (
		!isEmpty(localHandoffs) &&
		(
			nextMessage === null ||
			localHandoffs[0].created_at <= nextMessage.created_at
		)
	) {
		const localSession = { ...session };
		if (currentUser.role === "student") {
			const updatedSessionOtherUsers = localSession.otherUsers;
			const indexOfTutor = updatedSessionOtherUsers.findIndex(({ roles }) =>
				roles?.data?.some(({ name }) => name === "tutor") ||
				roles.some(({ name }) => name === "tutor"),
			);
			updatedSessionOtherUsers[indexOfTutor] = {
				...localSession.otherUsers[indexOfTutor],
				...localHandoffs[0].incoming_tutor,
			};
			localSession.otherUsers = updatedSessionOtherUsers;
		}

		const handoffMessage = getChatIntro(localSession, currentUser, "handoff");
		if (handoffMessage) {
			sessionMessagesArray.push(handoffMessage);
		}

		if (
			currentUser.role === "tutor" &&
			(
				!isEmpty(localHandoffs[0].files) ||
				!isEmpty(localHandoffs[0].comment)
			)
		) {
			sessionMessagesArray.push(new TutorHandoffCommentMessage({
				text: localHandoffs[0].comment,
				files: localHandoffs[0].files,
			}));
		}

		localHandoffs.shift();
		compareNextHandoffToNextMessage(data);
	}
};

/**
 * 
 * @param {Object} session 
 * @returns {Object}
 */
export const transformSessionTopicAndSubject = (session) => {
	return {
		...omit(session, ["requestedSubject", "requestedTopic"]),
		requested_subject: session.requestedSubject?.data || session.requestedSubject,
		requested_topic: session.requestedTopic?.data || session.requestedTopic,
	};
};

export default {
	/**
	 *
	 * @param {Object} session
	 * @returns {Object}
	 */
	transformSessionForClassroom(session) {
		const formattedSession = transformSessionTopicAndSubject(session);
		["requested_subject", "requested_topic"].forEach((item) => {
			if (isEmpty(formattedSession[item])) {
				formattedSession[item] = null;
			}
		});
		this.transformSessionForStore(formattedSession);
		formattedSession.users = formattedSession.users.data;
		formattedSession.whiteboard = {
			isOpen: false,
		};
		formattedSession.isLoaded = false;
		formattedSession.users.forEach((user) => {
			if (!user.profile_image) {
				user.profile_image = "/";
			}
		});
		formattedSession.created_at = formatTimestampToDate(formattedSession.created_at);
		formattedSession.messages = [];

		return formattedSession;
	},
	transformSessionForStore(session) {
		session.disableChatInput = false;

		["requested_topic", "requested_subject"].forEach((key) => {
			if (!isNil(session[key])) {
				session[key].name = pascalCase(session[key].name);
			}
		});
	},
	/**
	 *
	 * @param {Object} session
	 * @param {Object} currentUser
	 * @returns {Array}
	 */
	getSessionIntroMessages(session, currentUser) {
		const sessionIntroMessages = [];
		const chatIntro = getChatIntro(
			session,
			currentUser,
			"new",
		);
		if (chatIntro) {
			sessionIntroMessages.push(chatIntro);
		}
		if (session.type === "assignment") {
			const instructions = new AssignmentInstructionsMessage({
				files: session.assignment.files,
				text: session.assignment.instructions,
			});
			sessionIntroMessages.push(instructions);
		}
		return sessionIntroMessages;
	},
	/**
	 *
	 * @param sessionMessagesArray
	 * @param message
	 * @param sessionId
	 * @param currentUser
	 * @returns {*}
	 */
	prepareMessageForSessionMessagesArray({ sessionMessagesArray, message, sessionId, currentUser, session }) {
		const preparedMessageData = this.normalizeApiMessage(
			message,
			sessionId,
			currentUser,
			session,
		);
		const lastMessageInSession = sessionMessagesArray[sessionMessagesArray.length - 1];
		this.updateLastSessionMessageInArray(
			lastMessageInSession,
			message.user_id,
		);
		return this.getMessageFactoryMessage({
			data: preparedMessageData,
			lastMessageInSession,
		});
	},
	/**
	 *
	 * @param {Array} messagesArray
	 * @param {Object} session
	 * @param {Array} session.otherUsers
	 * @param {Array} session.id
	 * @param {Object} currentUser
	 * @param {Number} currentUser.id
	 * @param {String} currentUser.role
	 * @returns {[]}
	 */
	prepareMessagesArrayForStore({ messagesArray, session, currentUser }) {
		const sessionMessagesArray = this.getSessionIntroMessages(
			session,
			currentUser,
		);
		const localHandoffs = session.handoffs
			? session.handoffs.map((handoff) => ({ ...handoff, type: "welcome" }))
			: [];

		const compareNextHandoffParams = {
			sessionMessagesArray,
			localHandoffs,
			session,
			currentUser,
		};

		messagesArray.forEach((message) => {
			const messageForArray = this.prepareMessageForSessionMessagesArray({
				sessionMessagesArray,
				message,
				sessionId: session.id,
				currentUser,
				session,
			});

			compareNextHandoffToNextMessage({
				...compareNextHandoffParams,
				nextMessage: message,
			});

			sessionMessagesArray.push(messageForArray);
		});
		compareNextHandoffToNextMessage({
			...compareNextHandoffParams,
			nextMessage: null,
		});
		return sessionMessagesArray;
	},
	/**
	 * for message in array from messagesAPI request
	 * set IsLastMessage property of last message in to false, and conditionally update sequencePosition.
	 *
	 * @param {Object} lastMessageInSession
	 * @param {Number} currentMessageUserId
	 * @returns {Void}
	 */
	updateLastSessionMessageInArray(lastMessageInSession, currentMessageUserId) {
		const lastMessageData = getMessageDataFromMessageClass(lastMessageInSession);

		const isUserIdMatches =
			!isNil(lastMessageData) &&
			currentMessageUserId === lastMessageData.userId;

		if (isUserIdMatches && lastMessageData.sequencePosition === "bottom") {
			lastMessageData.sequencePosition = "middle";
		}
		if (isUserIdMatches) {
			lastMessageData.isLastMessage = false;
		}
	},

	/**
	 * for message in store:
	 * set IsLastMessage property of last message to false, and conditionally update sequencePosition
	 *
	 * @param {Function} commit
	 * @param {Object} sessionId
	 * @returns {void}
	 */
	updateLastSessionMessageInStore(commit, { data, lastMessageInSession }) {
		const lastMessageData = getMessageDataFromMessageClass(lastMessageInSession);
		const isUserIdMatches =
			!isNil(lastMessageData.userId) &&
			data.user_id === lastMessageData.userId;

		if (!isUserIdMatches) {
			return;
		}

		if (!data.message) {
			commit("Classroom/DELETE_MESSAGE_MEDIA_EMPTY_IN_MESSAGES_IN_SESSION", {
				sessionId: data.sessionId,
			}, { root: true });
		}

		commit("TOGGLE_IS_LAST_MESSAGE_IN_SESSIONS", {
			sessionId: data.sessionId,
		});

		if (lastMessageData.sequencePosition === "bottom") {
			commit("SET_SEQUENCE_POSITION_OF_LAST_MESSAGE_IN_SESSIONS", {
				sequencePosition: "middle",
				sessionId: data.sessionId,
			});
		}
	},
	/**
	 * transforms a message received from the API to a normalized object used in the classroom.
	 *
	 * @param {Object} message
	 * @param {Number} sessionId
	 * @param {Object} currentUser
	 * @return {Object}
	 */
	normalizeApiMessage(message, sessionId, currentUser, session = null) {
		const preparedMessage = {
			...message,
			sessionId,
		};

		if (message.is_question_matcher) {
			preparedMessage.type = "questionMatcher";
			delete preparedMessage.user;
			delete preparedMessage.user_id;
		}

		if (
			message.user !== undefined &&
			message.user !== null &&
			!(message.user.data instanceof Array)
		) {
			this.handleUserMessageNormalization(preparedMessage, message, currentUser);
		}

		if (message.is_file) {
			preparedMessage.file = {
				filetype: message.fileData?.file_type,
				filepath: message.fileData?.file_path,
				basename: message.fileData?.basename,
				thumbnail: message.fileData?.thumbnail_file_path,
			};
			delete preparedMessage.fileData;
		}

		if (session?.users && preparedMessage.user?.data) {
			const sessionUsers = session.users?.data || session.users;
			const userInSession = sessionUsers.find(({ id }) => id === message.user.data.id);
			preparedMessage.user.data.avatar = userInSession && userInSession.avatar;
		}

		return preparedMessage;
	},

	handleUserMessageNormalization(preparedMessage, message, currentUser) {
		const messageUserRoleIsTutor = hasRole(message.user.data, "tutor");
		const currentUserRoleIsTutor = hasRole(currentUser, "tutor");
		const isMessageFromCurrentUser = message.user_id === currentUser.id;

		if (
			(messageUserRoleIsTutor && currentUserRoleIsTutor) ||
			isMessageFromCurrentUser
		) {
			preparedMessage.justification = "right";
		} else {
			preparedMessage.justification = "left";
		}

		preparedMessage.firstName = message.user.data.first_name;
		preparedMessage.lastName = message.user.data.last_name;
		preparedMessage.isTutor = messageUserRoleIsTutor;
	},

	/**
	 * call message factory to transform message object into desired message class
	 *
	 * @param {Object} payload
	 * @return {Object}
	 */
	getMessageFactoryMessage({ data, lastMessageInSession }) {
		if (data.type === undefined) {
			const isFile = data.is_file || (data.hasOwnProperty("file") && !(data.file instanceof Array));
			data.type = isFile ? "media" : "text";
		}
		const messageData = messageObjectTransformationDict[data.type](data, lastMessageInSession);
		return MessageFactory.create(messageData);
	},
};
