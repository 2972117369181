<template>
	<div class="col-md-12 section__divider tw-p-0 tw-pt-6 tw-mb-14">
		<h3 class="tw-font-extrabold tw-text-black-high tw-text-2xl tw-mb-6">
			Tutor Personal Details
		</h3>
		<form
			v-jest="'profile-info-form'"
			class="form-horizontal"
			role="form"
			@submit.prevent="submitTutorProfileDetails()"
		>
			<BaseFormField
				id="legal_name"
				v-model="tutorProfile.legal_first_name"
				v-data-cy="'tutorDetails-legal-name'"
				class="tw-mb-6"
				type="text"
				label="Legal Name"
				placeholder="Legal Name"
			/>
			<BaseFormField
				id="preferred_first_name"
				v-model="tutorProfile.first_name"
				v-data-cy="'tutorDetails-preferred-name'"
				class="tw-mb-6"
				type="text"
				label="Preferred First Name"
				placeholder="Preferred First Name"
			/>
			<BaseFormField
				id="last_name"
				v-model="tutorProfile.last_name"
				v-data-cy="'tutorDetails-last-name'"
				class="tw-mb-6"
				type="text"
				label="Last Name"
				placeholder="Last Name"
			/>
			<BaseFormField
				id="employee_id"
				v-model="tutorProfile.employee_id"
				v-data-cy="'tutorDetails-employee-id'"
				class="tw-mb-6"
				type="text"
				name="employee_id"
				label="Employee ID"
				placeholder="Enter the employee ID"
			/>
			<BaseFormField
				id="email"
				v-model="tutorProfile.email"
				v-data-cy="'tutorDetails-email'"
				class="tw-mb-6"
				type="text"
				name="email"
				label="Email"
				placeholder="Email"
			/>
			<div class="active_radioButton radioButtons__maxWidth tw-mb-9 tw-flex">
				<p
					class="tw-text-sm tw-leading-6 tw-pr-9 tw-flex-grow"
				>
					Active
				</p>
				<div class="tw-flex tw-gap-x-9">
					<BaseRadio
						v-data-cy="'radio-tutor-active-yes'"
						class="tw-text-sm tw-w-14"
						:class="tutorProfile.active ? 'tw-font-bold' : ''"
						radio-id="active-yes"
						radio-name="active-yes"
						:radio-value="true"
						:value="tutorProfile.active"
						@click.native="
							setTutorProfileProperty({ active: true })
						"
					>
						<template slot="text">
							Yes
						</template>
					</BaseRadio>
					<BaseRadio
						v-data-cy="'radio-tutor-active-no'"
						class="tw-text-sm tw-w-14"
						:class="tutorProfile.active ? '' : 'tw-font-bold'"
						radio-id="active-no"
						radio-name="active-no"
						:radio-value="false"
						:value="tutorProfile.active"
						@click.native="
							setTutorProfileProperty({ active: false })
						"
					>
						<template slot="text">
							No
						</template>
					</BaseRadio>
				</div>
			</div>
			<div class="tw-mb-9 radioButtons__maxWidth tw-flex">
				<p
					v-data-cy="'text-platform-manager'"
					class="tw-text-sm tw-leading-6 tw-pr-9 tw-flex-grow"
				>
					Platform Manager
				</p>
				<div class="tw-flex tw-gap-x-9">
					<BaseRadio
						v-data-cy="'radio-platform-manager-yes'"
						class="tw-text-sm tw-w-14"
						:class="tutorProfile.is_platform_manager ? 'tw-font-bold' : ''"
						radio-id="isTutorManager-yes"
						radio-name="isTutorManager-yes"
						:radio-value="true"
						:value="tutorProfile.is_platform_manager"
						@click.native="
							setTutorProfileProperty({ is_platform_manager: true })
						"
					>
						<template slot="text">
							Yes
						</template>
					</BaseRadio>
					<BaseRadio
						v-data-cy="'radio-platform-manager-no'"
						class="tw-text-sm tw-w-14"
						:class="tutorProfile.is_platform_manager ? '' : 'tw-font-bold'"
						radio-id="isTutorManager-no"
						radio-name="isTutorManager-no"
						:radio-value="false"
						:value="tutorProfile.is_platform_manager"
						@click.native="
							setTutorProfileProperty({ is_platform_manager: false })
						"
					>
						<template slot="text">
							No
						</template>
					</BaseRadio>
				</div>
			</div>
			<div class="tw-mb-9 tw-flex radioButtons__maxWidth">
				<p
					class="tw-text-sm tw-leading-6 tw-pr-9 tw-flex-grow"
				>
					Test Account
				</p>
				<div class="tw-flex tw-gap-x-9">
					<BaseRadio
						v-data-cy="'radio-test-account-yes'"
						class="tw-text-sm tw-w-14"
						:class="tutorProfile.is_test_account ? 'tw-font-bold' : ''"
						radio-id="isTestAccount-yes"
						radio-name="isTestAccount-yes"
						:radio-value="true"
						:value="tutorProfile.is_test_account"
						@click.native="
							setTutorProfileProperty({ is_test_account: true })
						"
					>
						<template slot="text">
							Yes
						</template>
					</BaseRadio>
					<BaseRadio
						v-data-cy="'radio-test-account-no'"
						class="tw-text-sm tw-w-14"
						:class="tutorProfile.is_test_account ? '' : 'tw-font-bold'"
						radio-id="isTestAccount-no"
						radio-name="isTestAccount-no"
						:radio-value="false"
						:value="tutorProfile.is_test_account"
						@click.native="
							setTutorProfileProperty({ is_test_account: false })
						"
					>
						<template slot="text">
							No
						</template>
					</BaseRadio>
				</div>
			</div>

			<div class="form-group tw-overflow-auto">
				<BaseButton
					v-jest="'submit'"
					v-data-cy="'btn-submit-personal-details'"
					type="SUBMIT"
					class="pull-right tw-w-48"
					:disabled="isUserInfoSubmitButtonDisabled"
				>
					<template
						slot="text"
					>
						<LoadingRectangles
							v-if="isUserSubmittingPersonalInfo"
							class="tw-text-blue-regular"
						/>
						<span
							v-else
						>
							Submit Personal Details
						</span>
					</template>
				</BaseButton>
			</div>
			<div
				v-if="isShowingUserInfoSuccess"
				v-jest="'success-submission-message'"
				v-data-cy="'profile-success-submission-message'"
				class="alert alert-success"
			>
				User profile was saved
			</div>
			<div
				v-else-if="isShowingUserInfoError"
				v-jest="'error-submission-message'"
				class="alert alert-danger"
			>
				<li
					v-for="(error, index) in userInfoErrorItems"
					:key="index"
				>
					{{ error[0] }}
				</li>
			</div>
		</form>
	</div>
</template>


<script>
import { mapState } from "vuex";
import { isEqual } from "lodash";

import LoadingRectangles from "@/components/LoadingRectangles.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseFormField from "@/components/BaseFormField.vue";
import BaseRadio from "@/components/BaseRadio.vue";

import ServiceTutorDropdownMixins from "../mixins/ServiceTutorDropdownMixins.js";

const containsNumbers = (str) => {
	return /\d/.test(str);
};

export default {
	components: {
		LoadingRectangles,
		BaseButton,
		BaseFormField,
		BaseRadio,
	},
	mixins: [ServiceTutorDropdownMixins],
	data() {
		return {
			tutorProfile: {},
			isUserSubmittingPersonalInfo: false,
			isShowingUserInfoError: false,
			isShowingUserInfoSuccess: false,
			userInfoErrorItems: [],
			originalInfo: {},
		};
	},
	computed: {
		...mapState([
			"PlatformManager",
		]),
		isUserInfoSubmitButtonDisabled() {
			const isDisabled = this.isUserSubmittingPersonalInfo ||
				this.isPersonalInfoUnchanged ||
				this.tutorProfile.first_name === "" ||
				this.tutorProfile.last_name === "" ||
				this.tutorProfile.employee_id === "" ||
				this.tutorProfile.email === "";
			this.$emit("on-personal-submit", { isEnabled: !isDisabled });
			return isDisabled;
		},
		isPersonalInfoUnchanged() {
			return isEqual(this.originalInfo, this.tutorProfile);
		},
	},
	mounted() {
		this.setTutorProfile();
		this.setIsTutorManager();
	},
	methods: {
		setTutorProfile() {
			const { first_name, last_name, email } = this.PlatformManager.TutorsEdit.tutor;
			const { legal_first_name, employee_id, active, is_test_account } =
				this.PlatformManager.TutorsEdit.tutor.tutor.data;
			this.tutorProfile =
				{ legal_first_name, first_name, last_name, employee_id, email, active, is_test_account };
			this.originalInfo =
				{ legal_first_name, first_name, last_name, employee_id, email, active, is_test_account };
			this.convertToString(this.tutorProfile);
			this.convertToString(this.originalInfo);
		},
		convertToString(tutorProfile) {
			Object.entries(tutorProfile).forEach(([key]) => {
				if (tutorProfile[key] === null) {
					tutorProfile[key] = "";
				}
				if (typeof tutorProfile[key] === "number") {
					tutorProfile[key] = tutorProfile[key].toString();
				}
			});
		},
		async submitTutorProfileDetails() {
			if (containsNumbers(this.tutorProfile.legal_first_name)) {
				this.isShowingUserInfoError = true;
				this.isShowingUserInfoSuccess = false;
				this.userInfoErrorItems = [{ 0: "The legal name field cannot contain numbers." }];
			} else {
				try {
					this.isUserSubmittingPersonalInfo = true;
					const userProfileToSubmit = {
						first_name: this.tutorProfile.first_name,
						last_name: this.tutorProfile.last_name,
					};
					const tutorProfileToUpdate = {
						legal_first_name: this.tutorProfile.legal_first_name,
						employee_id: this.tutorProfile.employee_id,
						active: this.tutorProfile.active,
						is_tutor_manager: this.tutorProfile.is_platform_manager,
						is_test_account: this.tutorProfile.is_test_account,
					};

					if (this.PlatformManager.TutorsEdit.tutor.email !== this.tutorProfile.email) {
						userProfileToSubmit.email = this.tutorProfile.email;
					}

					await this.$store.dispatch("PlatformManager/TutorsEdit/submitUserInfo", userProfileToSubmit);
					await this.$store.dispatch("PlatformManager/TutorsEdit/updateTutor", tutorProfileToUpdate);

					this.isShowingUserInfoError = false;
					this.isShowingUserInfoSuccess = true;
					this.originalInfo = { ...this.tutorProfile };
				} catch (e) {
					this.isShowingUserInfoSuccess = false;
					this.isShowingUserInfoError = true;
					this.userInfoErrorItems = [{ 0: "There was an issue updating the tutor's profile details. Please try again." }];
					switch (e?.response?.status) {
						case 422:
							this.userInfoErrorItems = Object.values(e.response.data.error_description);
							break;
						case 500:
							Sentry.captureException(e);
							this.showErrorModal();
							break;
						default:
							Sentry.captureException(e);
							this.showErrorModal();
					}
				} finally {
					this.isUserSubmittingPersonalInfo = false;
				}
			}
		},
		closeOnSelect(refName) {
			this.$refs[refName].showDropdown = false;
		},
		setIsTutorManager() {
			this.tutorProfile.is_platform_manager =
				this.PlatformManager.TutorsEdit.tutor.roles.data.find((role) => role.id === 9) !== undefined;
			this.originalInfo.is_platform_manager = this.tutorProfile.is_platform_manager;
		},
		setTutorProfileProperty(property) {
			this.tutorProfile = {
				...this.tutorProfile,
				...property,
			};
		},
		showErrorModal() {
			const modalConfig = {
				title: "oops",
				message: "error_user",
				buttonText: "okay",
			};
			this.$store.dispatch("SimpleModal/configureSimpleModal", modalConfig);
			this.$bvModal.show("simpleModal");
		},
	},
};
</script>
<style scoped>
.section__divider {
	border-top: 1px solid var(--black-high);
}
.active_radioButton {
	padding-left: 75;
}
.radioButtons__maxWidth {
	max-width: 18.5rem;
}
</style>
