<template>
	<div class="form-group">
		<img
			v-if="isShowingIcon"
			v-cloak
			v-jest="'show-icon'"
			:src="imgPath"
		>
		<label
			v-if="isShowingLabel"
			v-jest="'label'"
			:for="id"
		>{{ label }}</label>
		<input
			:id="id"
			v-jest="'input-form'"
			class="form-control"
			:class="{
				inputField_disabled: isDisabled,
				inputField_error: isError,
				inputField_success: isSuccess,
				paddingIcon: isShowingIcon,
			}"
			:type="type"
			:disabled="isDisabled"
			:placeholder="placeholder"
			:value="value"
			:aria-label="ariaLabel"
			@input="handleInput"
		>
	</div>
</template>
<script>
export default {
	props: {
		label: {
			type: String,
			default: "",
		},
		placeholder: {
			type: String,
			default: "",
		},
		id: {
			type: String,
			default: "",
		},
		type: {
			type: String,
			default: "text",
		},
		imgPath: {
			type: String,
			default: "",
		},
		// eslint-disable-next-line vue/require-default-prop
		value: [String, Number],
		isDisabled: {
			type: Boolean,
			default: false,
		},
		isError: {
			type: Boolean,
			default: false,
		},
		isSuccess: {
			type: Boolean,
			default: false,
		},
		ariaLabel: {
			type: String,
			default: "Form field",
		},
	},

	computed: {
		/**
		 * computed property to know
		 * if the label is showing
		 * @returns {Boolean}
		 */
		isShowingLabel() {
			return this.id.length && this.label.length;
		},
		/**
		 * computed property to know
		 * if the icon is showing
		 * @returns {Boolean}
		 */
		isShowingIcon() {
			return this.imgPath.length > 0;
		},
	},

	methods: {
		/**
		 * method to handle input
		 * @returns {undefined}
		 */
		handleInput(e) {
			this.$emit("input", e.target.value);
		},
	},
};
</script>

<style scoped>
input {
	height: 40px;
	border-radius: 5px;
	border: 2px solid var(--c-gray);
	outline: none;
	padding: 6px 16px;
	font-size: 12px;
	color: var(--c-black);
}

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	font-size: 12px;
}
::-moz-placeholder {
	/* Firefox 19+ */
	font-size: 12px;
}
:-ms-input-placeholder {
	/* IE 10+ */
	font-size: 12px;
}
:-moz-placeholder {
	/* Firefox 18- */
	font-size: 12px;
}

input:focus {
	border-color: var(--c-blue);
}

input:disabled {
	color: var(--c-black);
}

input.inputField_error {
	border-color: var(--c-orange);
}

input.inputField_success {
	border-color: var(--c-green);
}

input.inputField_disabled {
	border-color: var(--c-gray);
	background-color: var(--c-gray);
}

label {
	font-size: 12px;
	font-weight: 600;
	font-style: normal;
	line-height: normal;
}

img {
	position: absolute;
	padding: 12px 10px;
}

.paddingIcon {
	padding-left: 33px;
}
</style>
