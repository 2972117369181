<template>
	<div
		class="tw-pl-5 tw-pt-6 tw-pb-0"
	>
		<p class="tw-font-bold tw-text-base">
			Subjects
		</p>
		<p class="tw-flex tw-flex-row tw-flex-wrap tw-text-xs tw-pt-2 tw-b-0 tw-px-0">
			<span
				v-for="(subject, i) in subjects"
				:key="i"
				v-jest="'subject-items'"
				class="tw-flex tw-mr-10 tw-pl-0 tw-pb-2 tw-pt-0 tw-w-24"
			>
				{{ subject.name }}
			</span>
		</p>
	</div>
</template>
<script>
export default {
	computed: {
		subjects() {
			return this.$store.state.PlatformManager.ServiceSummaryDashboard.selectedTutor.subjects || [];
		},
	},
};
</script>
