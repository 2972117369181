import { addWeeks, isAfter, isBefore, isEqual } from "date-fns";
import { differenceWith, isEqual as isObjectEqual } from "lodash";

import TutorsAPI from "@/services/api/Tutors.js";
import { getErrorText } from "@/utilities/errorHandlingHelpers.js";
import { formatDateToTimestamp, formatTimestampToDate } from "@/utilities/dateHelpers.js";
import { UsersAPI } from "@/modules/Customers/index.js";

import AvailabilitiesAPI from "../services/Availabilities.js";

const availabilityTransformer = (availability) => ({
	id: availability.id?.toString(),
	start_time: availability.start_time,
	end_time: availability.end_time,
	is_confirmed: !!availability.is_confirmed,
	is_deleted: !!availability.is_deleted,
});

const isNewAvailability = (availability) => availability.id?.includes("-");

export default {
	namespaced: true,
	state: {
		availabilities: {},
		availabilitiesToSubmit: [],
		currentWeek: null,
		shiftBlocks: {},
		weeksCounter: 0,
		loadedWeeks: [],
		openWeek: {
			startDate: null,
			endDate: null,
		},
		startOfOpenWeek: null,
		max_weekly_hours: null,
		preferred_weekly_hours: null,
		canEditAvailabilities: false,
		isPromptingCancelWarning: false,
		cancelConfirmedCallback: () => {},
	},
	mutations: {
		SET_AVAILABILITIES_IN_AVAILABILITIES_TO_SUBMIT(state, payload) {
			state.availabilitiesToSubmit = payload.availabilities;
		},
		ADD_AVAILABILITY_TO_SUBMIT(state, payload) {
			state.availabilitiesToSubmit = [...state.availabilitiesToSubmit, payload.availability];
		},
		EDIT_AVAILABILITY_TO_SUBMIT(state, { availability }) {
			const availabilityToEditIndex = state.availabilitiesToSubmit
				.findIndex((availabilityToSubmit) => availabilityToSubmit.id.toString() === availability.id);
			state.availabilitiesToSubmit.splice(availabilityToEditIndex, 1, availability);
		},
		DELETE_AVAILABILITY_TO_SUBMIT(state, { availability }) {
			const availabilityToDeleteIndex = state.availabilitiesToSubmit
				.findIndex((availabilityToSubmit) => availabilityToSubmit.id.toString() === availability.id);
			state.availabilitiesToSubmit.splice(availabilityToDeleteIndex, 1);
		},
		RESET_AVAILABILITIES_TO_SUBMIT(state) {
			state.availabilitiesToSubmit = [];
		},
		SET_AVAILABILITIES_IN_AVAILABILITIES(state, payload) {
			state.availabilities = { ...state.availabilities, ...payload.availabilities };
		},
		PUSH_LOADED_WEEK_IN_LOADED_WEEKS_IN_AVAILABILITIES(state, payload) {
			state.loadedWeeks.push(payload.loadedWeek);
		},
		SET_CURRENT_START_OF_WEEK_IN_AVAILABILITIES(state, payload) {
			state.currentWeek = payload.currentWeek;
		},
		SET_AVAILABILITY_BLOCK_SHIFTS(state, payload) {
			state.shiftBlocks = payload.shiftBlocks;
		},
		SET_WEEK_COUNTER(state, payload) {
			state.weeksCounter = payload.weeksCounter;
		},
		SET_USER_MAX_HOURS(state, payload) {
			state.max_weekly_hours = payload.max_weekly_hours;
		},
		SET_USER_PREFERRED_HOURS(state, payload) {
			state.preferred_weekly_hours = payload.preferred_weekly_hours;
		},
		SET_OPEN_WEEK(state, payload) {
			state.openWeek = { ...state.openWeek, ...payload.openWeek };
		},
		SET_IS_PROMPTING_CANCEL_WARNING(state, payload) {
			state.isPromptingCancelWarning = payload.isPromptingCancelWarning;
		},
		SET_CANCEL_CONFIRMED_CALLBACK(state, payload) {
			state.cancelConfirmedCallback = payload.cancelConfirmedCallback;
		},
		SET_CAN_EDIT_AVAILABILITIES(state, payload) {
			state.canEditAvailabilities = payload.canEditAvailabilities;
		},
	},
	getters: {
		isCurrentWeekOpen(state) {
			const { startDate, endDate } = state.openWeek;
			return isEqual(state.currentWeek, startDate) ||
				(isAfter(state.currentWeek, startDate) && isBefore(state.currentWeek, endDate));
		},
		availabilitiesToSubmitForWeek(state) {
			const startOfWeekTimestamp = state.currentWeek;
			const endOfWeekTimestamp = addWeeks(startOfWeekTimestamp, 1);
			return state.availabilitiesToSubmit.filter((availability) => {
				return (
					(isBefore(startOfWeekTimestamp, new Date(availability.start_time * 1000)) ||
						isEqual(startOfWeekTimestamp, new Date(availability.start_time * 1000))) &&
					(isBefore(new Date(availability.end_time * 1000), endOfWeekTimestamp) ||
						isEqual(new Date(availability.end_time * 1000), endOfWeekTimestamp))
				) && !availability.is_deleted;
			});
		},
		totalHoursToSubmitForWeek(state, getters) {
			let totalSecondsForWeek = 0;
			getters.availabilitiesToSubmitForWeek.forEach((availability) => {
				totalSecondsForWeek += availability.end_time - availability.start_time;
			});
			return totalSecondsForWeek / 3600;
		},
		availabilitiesDifferences(state) {
			const availabilities = Object.values(state.availabilities).map(availabilityTransformer);
			return differenceWith(
				state.availabilitiesToSubmit,
				availabilities,
				isObjectEqual,
			);
		},
		availabilitiesToCreate(state, getters) {
			return getters.availabilitiesDifferences.filter((availability) =>
				!availability.is_confirmed && !availability.is_deleted,
			);
		},
		availabilitiesToUpdate(state, getters) {
			return getters.availabilitiesDifferences.filter((availability) =>
				availability.is_confirmed || !isNewAvailability(availability),
			);
		},
		areAvailabilitiesUpdated(state, getters) {
			return getters.availabilitiesToCreate.length > 0 || getters.availabilitiesToUpdate.length > 0;
		},
	},
	actions: {
		setCanEditAvailabilities({ commit }, canEditAvailabilities) {
			commit("SET_CAN_EDIT_AVAILABILITIES", {
				canEditAvailabilities,
			});
		},
		addAvailabilityToSubmit({ commit }, { availability }) {
			commit("ADD_AVAILABILITY_TO_SUBMIT", {
				availability: {
					...availability,
					id: availability.id || `${availability.start_time}-${availability.end_time}`,
				},
			});
		},
		editAvailabilityToSubmit({ state, commit, dispatch }, availability) {
			const existingAvailability = state.availabilitiesToSubmit
				.find((availabilityToSubmit) =>
					availabilityToSubmit.id?.toString() === availability.id ||
					(availabilityToSubmit.start_time === availability.start_time &&
						availabilityToSubmit.end_time === availability.end_time),
				);

			const availabilityToSubmit = availabilityTransformer({ ...existingAvailability, ...availability });

			if (existingAvailability) {
				if (isNewAvailability(availabilityToSubmit) && availabilityToSubmit.is_deleted) {
					commit("DELETE_AVAILABILITY_TO_SUBMIT", {
						availability: availabilityToSubmit,
					});
				} else {
					commit("EDIT_AVAILABILITY_TO_SUBMIT", {
						availability: availabilityToSubmit,
					});
				}
			} else {
				dispatch("addAvailabilityToSubmit", {
					availability: availabilityToSubmit,
				});
			}
		},
		async resetAvailabilitiesToSubmit({ rootState, state, dispatch }) {
			await dispatch("getAvailabilities", {
				currentUserId: rootState.currentUser.id,
				params: {
					from: formatDateToTimestamp(state.currentWeek).toString(),
					to: formatDateToTimestamp(addWeeks(state.currentWeek, 1)).toString(),
				},
			});
		},
		async submitBatchAvailabilities({ dispatch }) {
			await dispatch("updateBatchAvailabilities");
			await dispatch("createBatchAvailabilities");
			await dispatch("resetAvailabilitiesToSubmit");
			await dispatch("setCanEditAvailabilities", false);
		},
		async updateBatchAvailabilities({ dispatch, getters }) {
			try {
				await AvailabilitiesAPI.updateBatchAvailabilities(
					getters.availabilitiesToUpdate.map((availability) => ({
						id: Number(availability.id),
						start_time: availability.start_time,
						end_time: availability.end_time,
						is_deleted: !!availability.is_deleted,
					})),
				);
			} catch (e) {
				Sentry.captureException(e);
				dispatch("Snackbar/showSnackbar", {
					snackbarType: "error",
					snackbarText: getErrorText("updating your availabilities"),
				}, { root: true });
			}
		},
		async createBatchAvailabilities({ dispatch, getters }) {
			try {
				await AvailabilitiesAPI.createBatchAvailabilities(
					getters.availabilitiesToCreate
						.filter((availability) => !availability.is_deleted)
						.map((availability) => ({
							start_time: availability.start_time,
							end_time: availability.end_time,
						})),
				);
			} catch (e) {
				Sentry.captureException(e);
				dispatch("Snackbar/showSnackbar", {
					snackbarType: "error",
					snackbarText: getErrorText("creating your availabilities"),
				}, { root: true });
			}
		},
		async cancelAvailabilitiesEdition({ commit, getters }, { callback }) {
			if (getters.areAvailabilitiesUpdated) {
				commit("SET_IS_PROMPTING_CANCEL_WARNING", { isPromptingCancelWarning: true });
				commit("SET_CANCEL_CONFIRMED_CALLBACK", { cancelConfirmedCallback: callback });
			} else {
				await callback();
			}
		},
		async closeCancelAvailabilitiesEdition({ commit }) {
			commit("SET_CANCEL_CONFIRMED_CALLBACK", { cancelConfirmedCallback: () => {} });
			commit("SET_IS_PROMPTING_CANCEL_WARNING", { isPromptingCancelWarning: false });
		},
		/**
		 * Get open week range (it can be more than a week) from backend
		 */
		async getTutorOpenWeek({ commit, dispatch }, { tutorId }) {
			try {
				const response = await AvailabilitiesAPI.getOpenWeekForTutor(tutorId);

				commit("SET_OPEN_WEEK", {
					openWeek: {
						startDate: formatTimestampToDate(response.data.data.start_time),
						endDate: formatTimestampToDate(response.data.data.end_time),
					},
				});
			} catch (e) {
				Sentry.captureException(e);
				dispatch("Snackbar/showSnackbar", {
					snackbarType: "error",
					snackbarText: getErrorText("getting your open week"),
				}, { root: true });
			}
		},
		async getUserMaxHours({ commit, dispatch }, payload) {
			try {
				const response = await UsersAPI.show(payload, {
					include: "tutor",
				});
				commit("SET_USER_MAX_HOURS", {
					max_weekly_hours: response.data.data.tutor.data.max_weekly_hours,
				});
			} catch (e) {
				Sentry.captureException(e);
				dispatch("Snackbar/showSnackbar", {
					snackbarType: "error",
					snackbarText: getErrorText("getting your max hours for the week"),
				}, { root: true });
			}
		},
		async getUserMaxAndPreferredHours({ commit, dispatch }, payload) {
			try {
				const response = await UsersAPI.show(payload, {
					include: "tutor",
				});
				commit("SET_USER_MAX_HOURS", {
					max_weekly_hours: response.data.data.tutor.data.max_weekly_hours,
				});
				commit("SET_USER_PREFERRED_HOURS", {
					preferred_weekly_hours: response.data.data.tutor.data.preferred_weekly_hours,
				});
			} catch (e) {
				Sentry.captureException(e);
				dispatch("Snackbar/showSnackbar", {
					snackbarType: "error",
					snackbarText: getErrorText("getting your max and preferred hours for the week"),
				}, { root: true });
			}
		},
		/**
		 * Updates Tutor Max Hours
		 * @param { Object } param
		 * @param { Object } payload
		 */
		async updateTutorMaxHours({ commit, dispatch }, payload) {
			try {
				await TutorsAPI.updateTutor(payload.currentUserId, payload.params);
				commit("SET_USER_MAX_HOURS", {
					max_weekly_hours: payload.params.max_weekly_hours,
				});
			} catch (e) {
				Sentry.captureException(e);
				dispatch("Snackbar/showSnackbar", {
					snackbarType: "error",
					snackbarText: getErrorText("updating your max hours for the week"),
				}, { root: true });
			}
		},
		async updateTutorMaxAndPreferredHours({ commit, dispatch }, payload) {
			try {
				await TutorsAPI.updateTutor(payload.currentUserId, payload.params);
				commit("SET_USER_MAX_HOURS", {
					max_weekly_hours: payload.params.max_weekly_hours,
				});
				commit("SET_USER_PREFERRED_HOURS", {
					preferred_weekly_hours: payload.params.preferred_weekly_hours,
				});
			} catch (e) {
				Sentry.captureException(e);
				dispatch("Snackbar/showSnackbar", {
					snackbarType: "error",
					snackbarText: getErrorText("updating your max and preferred hours for the week"),
				}, { root: true });
			}
		},
		/**
		 * Gets all availabilities for current user
		 * @param {Object} payload
		 */
		async getAvailabilities({ commit, dispatch }, payload) {
			try {
				//check if week loaded, if not:
				const response = await AvailabilitiesAPI.getAvailabilitiesForTutor(payload.currentUserId, {
					params: payload.params,
				});
				const availabilities = {};

				response.data.data.forEach((availability) => {
					availabilities[availability.id] = availability;
				});
				commit("SET_AVAILABILITIES_IN_AVAILABILITIES", {
					availabilities,
				});
				commit("SET_AVAILABILITIES_IN_AVAILABILITIES_TO_SUBMIT", {
					availabilities: response.data.data.map(availabilityTransformer),
				});

				commit("PUSH_LOADED_WEEK_IN_LOADED_WEEKS_IN_AVAILABILITIES", {
					loadedWeek: payload.params.from,
				});

				//commit to set the week as loaded
			} catch (e) {
				Sentry.captureException(e);
				dispatch("Snackbar/showSnackbar", {
					snackbarType: "error",
					snackbarText: getErrorText("retrieving the availabilities"),
				}, { root: true });
			}
		},
		/**
		 * Get blocked shifts of a tutor
		 * @param {*} param0
		 * @param {*} payload
		 */
		async getShiftBlocks({ commit }, payload) {
			try {
				const response = await AvailabilitiesAPI.getShiftBlocksForTutor(payload.userId);
				commit("SET_AVAILABILITY_BLOCK_SHIFTS", {
					shiftBlocks: response.data.data,
				});
			} catch (e) {
				Sentry.captureException(e);
			}
		},
		/**
		 * Add weeks
		 * @param {*} data
		 */
		addOrSubtractWeeks({ commit }, data) {
			commit("SET_WEEK_COUNTER", {
				weeksCounter: data,
			});
		},
	},
};
