export default {
	en: {
		major_clarity: {
			future_pathway: "Future Pathway",
			enter_major_clarity: "Enter MajorClarity",
			find_your_future_career: "Find your future career",
			major_calrity_description: "Whether it's a career in mechanics, a degree in psychology or a future in the military, we can help you plan your path.",
		},
	},
	fr: {
		major_clarity: {
			future_pathway: "Voie future",
			enter_major_clarity: "Aller à MajorClarity",
			find_your_future_career: "Trouvez votre future carrière",
			major_calrity_description: "Qu'il s'agisse d'une carrière dans la mécanique, d'un diplôme en psychologie ou d'un avenir dans l'armée, nous pouvons vous aider à planifier votre parcours.",
		},
	},
	es: {
		major_clarity: {
			future_pathway: "Camino futuro",
			enter_major_clarity: "Ir a MajorClarity",
			find_your_future_career: "Encuentra tu futuro carrera",
			major_clarity_description: "Ya sea una carrera en mecánica, un título en psicología o un futuro en el ejército, podemos ayudarlo a planificar su camino.",
		},
	},
};
