var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "14",
        height: "14",
        viewBox: "0 0 14 14",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M11.4149 11.4888L12.4312 10.413L9.13022 6.91112L12.4312 3.40922L11.4149 2.33348L7.09056 6.91112L11.4149 11.4888Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c(
        "mask",
        {
          attrs: {
            id: "mask0",
            "mask-type": "alpha",
            maskUnits: "userSpaceOnUse",
            x: "7",
            y: "2",
            width: "6",
            height: "10",
          },
        },
        [
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M11.4149 11.4888L12.4312 10.413L9.13022 6.91112L12.4312 3.40922L11.4149 2.33348L7.09056 6.91112L11.4149 11.4888Z",
              fill: "white",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("g", { attrs: { mask: "url(#mask0)" } }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M6.07435 11.667L7.09058 10.5912L3.78965 7.08935L7.09058 3.58745L6.07435 2.5117L1.74999 7.08935L6.07435 11.667Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c(
        "mask",
        {
          attrs: {
            id: "mask1",
            "mask-type": "alpha",
            maskUnits: "userSpaceOnUse",
            x: "1",
            y: "2",
            width: "7",
            height: "10",
          },
        },
        [
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M6.07435 11.667L7.09058 10.5912L3.78965 7.08935L7.09058 3.58745L6.07435 2.5117L1.74999 7.08935L6.07435 11.667Z",
              fill: "white",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("g", { attrs: { mask: "url(#mask1)" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }