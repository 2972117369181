var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tw-flex tw-items-start tw-pt-6" },
    [
      _c("ShiftSummaryShiftTypeIcon", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "shift-summary-icon",
            expression: "'shift-summary-icon'",
          },
        ],
        attrs: { title: _vm.shiftSegment.type.name, dimension: 48 },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "shiftSummaryItem__detail tw-relative" }, [
        _c("div", { staticClass: "tw-flex tw-items-center tw-mb-1" }, [
          _c(
            "span",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "shift-summary-shift-type",
                  expression: "'shift-summary-shift-type'",
                },
              ],
              staticClass: "tw-font-bold tw-text-xl tw-leading-none",
            },
            [
              _vm._v(
                "\n\t\t\t\t" + _vm._s(_vm.shiftSegment.type.name) + "\n\t\t\t"
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "shift-summary-label",
                  expression: "'shift-summary-label'",
                },
              ],
              staticClass: "shiftSummaryItem__label",
              class: [
                {
                  "tw-bg-blue-dark": _vm.shiftStatus === "Upcoming",
                  "tw-bg-green-dark": _vm.shiftStatus === "Ongoing",
                  "tw-bg-orange-dark": _vm.shiftStatus === "Late",
                  "tw-bg-grey-dark": _vm.shiftStatus === "Completed",
                },
              ],
            },
            [_vm._v("\n\t\t\t\t" + _vm._s(_vm.shiftStatus) + "\n\t\t\t")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "shift-summary-date",
                expression: "'shift-summary-date'",
              },
            ],
            staticClass: "tw-text-xs",
          },
          [_vm._v("\n\t\t\t" + _vm._s(_vm.shiftDate) + "\n\t\t")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }