var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "23",
        height: "23",
        viewBox: "0 0 23 23",
      },
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            fill: "#FFF",
            "fill-rule": "nonzero",
            d: "M16.438 14.465h-1.039l-.368-.355a8.51 8.51 0 0 0 2.064-5.562A8.547 8.547 0 0 0 8.548 0 8.547 8.547 0 0 0 0 8.548a8.547 8.547 0 0 0 8.548 8.547 8.51 8.51 0 0 0 5.562-2.064l.355.368v1.039L21.041 23 23 21.04l-6.562-6.575zm-7.89 0A5.91 5.91 0 0 1 2.63 8.548 5.91 5.91 0 0 1 8.548 2.63a5.91 5.91 0 0 1 5.917 5.918 5.91 5.91 0 0 1-5.917 5.917z",
          },
        }),
        _vm._v(" "),
        _c("path", { attrs: { d: "M-4-4h32v32H-4z" } }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            fill: "#FFF",
            "fill-rule": "nonzero",
            d: "M12 9.2H9.2V12H7.8V9.2H5V7.8h2.8V5h1.4v2.8H12z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }