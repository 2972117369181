var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BModal",
    {
      attrs: {
        id: "tutorDashboardExpiredShiftTransferModal",
        "content-class": "bootstrapFourModalContent",
        "body-class": "bootstrapFourModalBody",
        "footer-class": "bootstrapFourModalFooter",
        "hide-header": true,
      },
    },
    [
      _c(
        "button",
        {
          staticClass: "bootstrapFourModalCloseButton",
          on: {
            click: function ($event) {
              return _vm.hideModal()
            },
          },
        },
        [_vm._v("\n\t\t×\n\t")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "expiredShiftTransferModal__header" },
        [
          _c("IconCircleX", { staticClass: "xSvg" }),
          _vm._v(" "),
          _c("div", { staticClass: "expiredShiftTransferModal__title" }, [
            _vm._v("\n\t\t\tShift transfer expired\n\t\t"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "expiredShiftTransferModal__content" }, [
        _c("div", { staticClass: "expiredShiftTransferModal__text" }, [
          _vm._v("\n\t\t\tThe transfer request has expired.\n\t\t"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "expiredShiftTransferModal__text" }, [
          _c("strong", [
            _vm._v(
              "The following shift is no longer available to be transferred."
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "expiredShifteTransferModal__text" }, [
          _vm._v(
            "\n\t\t\tPlease note that shift transfer requests expire when they are accepted by one of your peers\n\t\t\tor 24 hours before the scheduled time.\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "expiredShiftTransferModal__shiftDetails" }, [
          _c(
            "div",
            {
              staticClass:
                "expiredShiftTransferModal__text expiredShiftTransferModal__text--redSmaller",
            },
            [_vm._v("\n\t\t\t\t" + _vm._s(_vm.shiftDate) + "\n\t\t\t")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "expiredShiftTransferModal__text expiredShiftTransferModal__text--redSmaller",
            },
            [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(_vm.shiftStartTime) +
                  " to " +
                  _vm._s(_vm.shiftEndTime) +
                  "\n\t\t\t"
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "modal-footer" },
        [
          _c(
            "BaseButton",
            {
              attrs: { type: "CANCEL" },
              nativeOn: {
                click: function ($event) {
                  return _vm.hideModal()
                },
              },
            },
            [
              _c("template", { slot: "text" }, [
                _vm._v("\n\t\t\t\tClose\n\t\t\t"),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }