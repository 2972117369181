var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", [
        _c("p", {
          staticClass: "assessment__text assessment__text--bold",
          domProps: {
            innerHTML: _vm._s(
              _vm.$t("right_sidebar.assessment.tutor_to_professor_feedback", {
                name: _vm.studentName,
              })
            ),
          },
        }),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            { staticClass: "assessment__feedbackLabel" },
            [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(_vm.$t("right_sidebar.assessment.concerns")) +
                  "\n\t\t\t\t"
              ),
              _c("IconExclamationMarkStroke"),
            ],
            1
          ),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "data-cy",
                rawName: "v-data-cy",
                value: "assessment-concerns",
                expression: "'assessment-concerns'",
              },
            ],
            staticClass: "assessment__concern",
            attrs: {
              placeholder: _vm.feedbackPlaceholder,
              maxlength: _vm.maxFeedbackLength,
            },
            domProps: { value: _vm.feedback },
            on: {
              input: function ($event) {
                return _vm.$emit("feedback-update", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "assessment__charactersLeft" }, [
            _vm.isCharacterLimitReachedForConcern
              ? _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "feedback-character-limit",
                        expression: "'feedback-character-limit'",
                      },
                    ],
                    staticClass: "assessment__charactersLeft--alert",
                  },
                  [_vm._v("\n\t\t\t\t\tCharacter limit reached.\n\t\t\t\t")]
                )
              : _vm._e(),
            _vm._v(
              "\n\t\t\t\t" +
                _vm._s(
                  `${_vm.charactersLeftForConcern}/${_vm.maxFeedbackLength}`
                ) +
                "\n\t\t\t"
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("StringWithEmojis", {
              staticClass: "assessment__feedbackLabel",
              attrs: { value: _vm.$t("right_sidebar.assessment.shout_out") },
            }),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "data-cy",
                  rawName: "v-data-cy",
                  value: "assessment-shoutout",
                  expression: "'assessment-shoutout'",
                },
              ],
              staticClass: "assessment__shoutout",
              attrs: {
                placeholder: _vm.shoutOutPlaceholder,
                maxlength: _vm.maxShoutOutLength,
              },
              domProps: { value: _vm.shoutout },
              on: {
                input: function ($event) {
                  return _vm.$emit("shoutout-update", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _c("p", { staticClass: "assessment__charactersLeft" }, [
              _vm.isCharacterLimitReachedForShoutOut
                ? _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "shoutout-character-limit",
                          expression: "'shoutout-character-limit'",
                        },
                      ],
                      staticClass: "assessment__charactersLeft--alert",
                    },
                    [_vm._v("\n\t\t\t\t\tCharacter limit reached.\n\t\t\t\t")]
                  )
                : _vm._e(),
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(
                    `${_vm.charactersLeftForShoutOut}/${_vm.maxShoutOutLength}`
                  ) +
                  "\n\t\t\t"
              ),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("Comment", {
        attrs: {
          "is-submitting": _vm.isSubmitting,
          "student-id": _vm.studentId,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }