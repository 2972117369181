import { parseISO, isAfter, differenceInSeconds } from "date-fns";

import { manageLocalStorage, STORAGE_KEYS } from "./localStorage.js";

export const SHIFT_SEGMENT_MODIFIER_TYPES = {
	TRANSITION: 1,
	MIA: 2,
	BIO_BREAK: 3,
};

export const MIN_SEARCH_CHARS_NUMBER = 3;

export function setTutorReviewSummaryToStorage(essayId, comment) {
	manageLocalStorage(essayId, STORAGE_KEYS.REVIEW_COMMENT).setItem(comment);
}

export function setXFDFToStorage(essayId, xfdfString) {
	manageLocalStorage(essayId, STORAGE_KEYS.XFDF_ANNOTATIONS)
		.setItem(xfdfString);
}

export function getTutorReviewSummaryFromStorage(essayId) {
	return manageLocalStorage(essayId, STORAGE_KEYS.REVIEW_COMMENT)
		.getRawItem();
}

export function getXFDFFromStorage(essayId) {
	return manageLocalStorage(essayId, STORAGE_KEYS.XFDF_ANNOTATIONS)
		.getRawItem();
}

export function getSummaryRephraseCommentsFromStorage(essayId) {
	return manageLocalStorage(essayId, STORAGE_KEYS.SUMMARY_REPHRASE_COMMENTS).getItem();
}

export function setSummaryRephraseCommentsToStorage(essayId, comments) {
	manageLocalStorage(essayId, STORAGE_KEYS.SUMMARY_REPHRASE_COMMENTS).setItem(comments);
}

export function clearStorage(id) {
	manageLocalStorage(id, STORAGE_KEYS.REVIEW_COMMENT).removeItem();
	manageLocalStorage(id, STORAGE_KEYS.XFDF_ANNOTATIONS).removeItem();
	localStorage.removeItem(`essay-${id}-review-time-spent`);
}

export function getActiveReviewDurationInMS({ startTime, shiftSegments }) {
	let now = new Date();

	const startDate = new Date(startTime * 1000);
	const breaks = [];

	shiftSegments.forEach((shiftSegment) => {
		shiftSegment.modifiers.forEach((modifier) => {
			if (
				modifier.type.id === SHIFT_SEGMENT_MODIFIER_TYPES.MIA ||
				modifier.type.id === SHIFT_SEGMENT_MODIFIER_TYPES.BIO_BREAK
			) {
				breaks.push({
					start: modifier.start_time,
					end: modifier.end_time,
				});
			}
		});
	});

	const activeBreaks = breaks.filter((breakItem) => {
		const breakStart = parseISO(breakItem.start);
		const breakEnd = parseISO(breakItem.end);

		const isBreakFromPreviousReview = isAfter(startDate, breakStart);
		const isBreakDone = isAfter(now, breakEnd);

		if (!isBreakFromPreviousReview && isBreakDone) {
			return breakItem;
		}

		if (!isBreakDone) {
			now = breakStart;
		}
	});

	const totalBreakDuration = activeBreaks.reduce((total, breakItem) => {
		const breakStart = parseISO(breakItem.start);
		const breakEnd = parseISO(breakItem.end);

		return total + differenceInSeconds(breakEnd, breakStart);
	}, 0);

	const totalDurationInSec = differenceInSeconds(
		new Date(now),
		new Date(startDate),
	);
	const activeDurationInMS = (totalDurationInSec - totalBreakDuration) * 1000;

	return activeDurationInMS;
}
