<template>
	<div>
		<TutorDashboardScheduleInfo />
		<TutorDashboardScheduleCalendar />
		<TutorDashboardScheduleLegend />
	</div>
</template>

<script>
import TutorDashboardScheduleInfo from "./TutorDashboardScheduleInfo.vue";
import TutorDashboardScheduleLegend from "./TutorDashboardScheduleLegend.vue";

export default {
	components: {
		TutorDashboardScheduleInfo,
		TutorDashboardScheduleLegend,
		TutorDashboardScheduleCalendar: () => import("./TutorDashboardScheduleCalendar.vue"),
	},
};
</script>
