<template>
	<div
		class="
			bannersContainer
			tw-max-w-full
			tw-fixed
			tw-bottom-0
			md:tw-bottom-5
			tw-left-0
			md:tw-left-1/2
			md:tw-transform
			md:tw--translate-x-1/2
			tw-p-4
			tw-pointer-events-none
		"
	>
		<TransitionGroup
			name="banner"
			tag="div"
			class="tw-relative tw-flex tw-flex-col tw-gap-3 md:tw-gap-5"
		>
			<component
				:is="component"
				v-for="({ component, ...props }, index) in Banner.activeBanners"
				:key="index"
				v-jest="'banner'"
				v-bind="props"
				class="banner-item tw-pointer-events-auto"
				v-on="$listeners"
				@main-action="onMainAction(index)"
				@close="onClose(index)"
			/>
		</TransitionGroup>
	</div>
</template>

<script>
import { mapState } from "vuex";

export default {
	name: "BannersContainer",
	computed: {
		...mapState(["Banner"]),
	},
	methods: {
		onMainAction(index) {
			const { redirect } = this.Banner.activeBanners[index];
			if (redirect && redirect !== this.$route.fullPath) {
				this.$router.push(redirect);
			}
			this.onClose(index);
		},
		onClose(index) {
			this.$store.commit("Banner/CLOSE_BANNER", { index });
		},
	},
};
</script>

<style scoped>
.bannersContainer {
	z-index: 1041; /* layered right on top of BVModal */
	width: 750px;
}

.banner-item {
	transition: all 300ms;
}

.banner-enter, .banner-leave-to {
	opacity: 0;
	transform: translateY(30px);
}

.banner-leave-active {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}
</style>
