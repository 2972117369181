var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "nav",
    {
      staticClass: "navbar",
      class: [
        _setup.featureFlagSidebarRedesignIsEnabled
          ? "md:tw-ml-[80px] tw-left-0 tw-right-0 tw-fixed"
          : "tw-relative",
      ],
    },
    [
      _c(
        "div",
        { staticClass: "navbar__container" },
        [
          !_setup.featureFlagSidebarRedesignIsEnabled
            ? [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "button-toggle-sidebar",
                        expression: "'button-toggle-sidebar'",
                      },
                    ],
                    staticClass: "navbar-toggle__button tw-hidden md:tw-block",
                    attrs: {
                      "aria-label": _setup.i18n.t(
                        "student_navigation.navbar.side_nav"
                      ),
                      "aria-expanded": (!_setup.props
                        .isSidebarToggled).toString(),
                      "aria-controls": "sidebar",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("toggle-sidebar")
                      },
                    },
                  },
                  [
                    _c(_setup.OrigamiIcon, {
                      attrs: { name: "mobile-menu", width: 32, height: 32 },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "button-toggle-mobile-menu",
                        expression: "'button-toggle-mobile-menu'",
                      },
                    ],
                    ref: "mobileMenuButton",
                    staticClass: "navbar-toggle__button tw-block md:tw-hidden",
                    attrs: {
                      "aria-label": _setup.i18n.t(
                        "student_navigation.navbar.menu"
                      ),
                      "aria-expanded":
                        _setup.props.isMobileMenuToggled.toString(),
                      "aria-controls": "mobile-nav",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("toggle-mobile-menu")
                      },
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "esc", 27, $event.key, [
                            "Esc",
                            "Escape",
                          ])
                        )
                          return null
                        return _vm.$emit("close-mobile-menu")
                      },
                    },
                  },
                  [
                    _c(_setup.OrigamiIcon, {
                      attrs: {
                        name: _setup.toggleMobileMenuIcon,
                        width: 32,
                        height: 32,
                      },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("router-link", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "dashboard-link",
                expression: "'dashboard-link'",
              },
            ],
            class: [
              _setup.featureFlagSidebarRedesignIsEnabled
                ? "tw-mr-8 tw-ml-0 md:tw-mr-auto"
                : "tw-mr-auto tw-ml-8",
            ],
            attrs: {
              custom: "",
              to: "/dashboard",
              "aria-label": _setup.i18n.t("student_navigation.navbar.home"),
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ href, navigate }) {
                  return [
                    _c(
                      "a",
                      {
                        attrs: { href: href },
                        on: {
                          click: function ($event) {
                            return _setup.emitAndRedirect(navigate, $event)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "/images/logo-paper.svg",
                            width: "171",
                            height: "48",
                            alt: "PAPER",
                          },
                        }),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-3",
            },
            [_vm._t("beforeAvatar")],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _setup.closeDropdown,
                  expression: "closeDropdown",
                },
              ],
              staticClass: "navbar__dropdown",
            },
            [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "avatar-button",
                      expression: "'avatar-button'",
                    },
                  ],
                  ref: "navDropdownButton",
                  attrs: {
                    "aria-expanded": _setup.isDropdownToggled.toString(),
                    "aria-controls": "dropdown-menu-content",
                    "aria-label": _setup.ariaLabelUserProfile,
                    "aria-describedby": _setup.avatarLabelIds.desc,
                  },
                  on: {
                    click: function ($event) {
                      _setup.isDropdownToggled = !_setup.isDropdownToggled
                    },
                    keydown: [
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "esc", 27, $event.key, [
                            "Esc",
                            "Escape",
                          ])
                        )
                          return null
                        _setup.isDropdownToggled = false
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                        )
                          return null
                        if (!$event.shiftKey) return null
                        _setup.isDropdownToggled = false
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "down", 40, $event.key, [
                            "Down",
                            "ArrowDown",
                          ])
                        )
                          return null
                        $event.preventDefault()
                        return _setup.focusLink(0)
                      },
                    ],
                  },
                },
                [
                  !_setup.isLoadingNavbar
                    ? _c(_setup.Avatar, {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "avatar-icon",
                            expression: "'avatar-icon'",
                          },
                        ],
                        attrs: { user: _setup.userData, size: 56 },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ul",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _setup.isDropdownToggled,
                      expression: "isDropdownToggled",
                    },
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "dropdown-menu-content",
                      expression: "'dropdown-menu-content'",
                    },
                  ],
                  staticClass: "dropdown__content",
                  attrs: { id: "dropdown-menu-content" },
                },
                [
                  _c(
                    "li",
                    [
                      _c(_setup.LearnerProfileLink, {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "learner-profile-link",
                            expression: "'learner-profile-link'",
                          },
                        ],
                        on: {
                          redirect: function ($event) {
                            _setup.isDropdownToggled = false
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_setup.props.dropdownLinks, function (link, index) {
                    return _c("li", { key: index }, [
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: "dropdown-link",
                              expression: "'dropdown-link'",
                            },
                          ],
                          ref: "dropdownLink",
                          refInFor: true,
                          attrs: { href: "javascript:void(0);" },
                          on: {
                            click: function ($event) {
                              return _setup.handleDropdownLinkClick(link.target)
                            },
                            keydown: [
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "down",
                                    40,
                                    $event.key,
                                    ["Down", "ArrowDown"]
                                  )
                                )
                                  return null
                                $event.preventDefault()
                                return _setup.focusLink(index + 1)
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k($event.keyCode, "up", 38, $event.key, [
                                    "Up",
                                    "ArrowUp",
                                  ])
                                )
                                  return null
                                $event.preventDefault()
                                return _setup.focusLink(index - 1)
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "esc",
                                    27,
                                    $event.key,
                                    ["Esc", "Escape"]
                                  )
                                )
                                  return null
                                return _setup.focusDropdownClose.apply(
                                  null,
                                  arguments
                                )
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "tab",
                                    9,
                                    $event.key,
                                    "Tab"
                                  )
                                )
                                  return null
                                if (
                                  $event.ctrlKey ||
                                  $event.shiftKey ||
                                  $event.altKey ||
                                  $event.metaKey
                                )
                                  return null
                                return _setup.handleDropdownTab(index)
                              },
                            ],
                          },
                        },
                        [
                          _c(_setup.OrigamiIcon, {
                            staticClass: "tw-mr-4",
                            attrs: { name: link.icon },
                          }),
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_setup.i18n.t(link.title)) +
                              "\n\t\t\t\t\t"
                          ),
                        ],
                        1
                      ),
                    ])
                  }),
                ],
                2
              ),
            ]
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }