var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BreaksConfirmation", {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "p",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "scheduled-break-cancelled-header",
                    expression: "'scheduled-break-cancelled-header'",
                  },
                ],
              },
              [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.breakTypeName) +
                    " break cancelled\n\t\t"
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "bodyIcon",
        fn: function () {
          return [
            _c("OrigamiIcon", {
              staticClass: "tw-text-origami-red-400",
              attrs: { name: "block", width: "32", height: "32" },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "bodyText",
        fn: function () {
          return [
            _vm.formattedBreakTimes
              ? _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "scheduled-break-cancelled-details",
                        expression: "'scheduled-break-cancelled-details'",
                      },
                    ],
                  },
                  [
                    _vm._v(
                      "\n\t\t\tYour scheduled break has been cancelled by a Platform Manager for " +
                        _vm._s(_vm.formattedBreakTimes.startTime) +
                        " to " +
                        _vm._s(_vm.formattedBreakTimes.endTime) +
                        "\n\t\t"
                    ),
                  ]
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function () {
          return [
            _c(
              "OrigamiButton",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "scheduled-break-cancelled-try-again-button",
                    expression: "'scheduled-break-cancelled-try-again-button'",
                  },
                ],
                staticClass: "tw-w-full tw-justify-center",
                attrs: { variant: "primary" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.requestBreak.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\tTry for another time\n\t\t")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }