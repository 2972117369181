<template>
	<div class="tw-flex tw-flex-col tw-items-center tw-max-w-sm tw-text-center tw-p-6">
		<MissingErrorIllustration
			v-jest="'illustration'"
			:width="small ? 144 : 160"
			:height="small ? 144 : 160"
			class="tw-mb-4"
		/>
		<p
			v-if="text"
			v-jest="'text'"
			class="tw-mb-6"
			:class="small ? 'tw-text-base' : 'tw-text-md'"
		>
			{{ text }}
		</p>
		<BaseButton
			v-if="buttonText"
			v-jest="'button'"
			@click.native.prevent="$emit('button-click', $event)"
		>
			{{ buttonText }}
		</BaseButton>
	</div>
</template>

<script>
import BaseButton from "@/components/elements/BaseButton.vue";

import MissingErrorIllustration from "../elements/illustrations/MissingError.vue";

export default {
	components: {
		BaseButton,
		MissingErrorIllustration,
	},
	props: {
		text: {
			type: String,
			default: "",
		},
		buttonText: {
			type: String,
			default: "",
		},
		small: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
