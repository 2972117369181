var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "OrigamiCard",
    { staticClass: "tw-h-full", attrs: { tag: "div" } },
    [
      _c(
        "div",
        { staticClass: "tw-flex tw-flex-col tw-px-5 tw-py-4 tw-h-full" },
        [
          _c(
            "div",
            {
              staticClass:
                "tw-flex tw-items-center tw-gap-x-1 tw-mb-2 md:tw-mb-5",
            },
            [
              _c(
                "h2",
                { staticClass: "tw-text-sm tw-font-bold" },
                [_vm._t("cardTitle")],
                2
              ),
              _vm._v(" "),
              _vm._t("cardTooltip"),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "essay-detail",
                  expression: "'essay-detail'",
                },
              ],
              staticClass:
                "tw-text-origami-blue-300 tw-font-bold tw-text-2xl tw-text-center md:tw-text-left",
            },
            [_vm._t("essayDetail")],
            2
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "essay-misc",
                  expression: "'essay-misc'",
                },
              ],
              staticClass:
                "tw-flex tw-justify-between tw-mt-auto tw-text-sm tw-text-origami-grey-300 tw-font-bold tw-truncate md:tw-column",
            },
            [_vm._t("essayMiscOne"), _vm._v(" "), _vm._t("essayMiscTwo")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }