<template>
	<div>
		<BreaksNotification
			v-jest="'meal-break-upcoming-notification'"
			notification-type="info"
			class="tw-mb-4"
		>
			<p
				v-jest="'meal-break-upcoming-time'"
				class="tw-font-bold tw-text-sm"
			>
				{{ timeText }}
			</p>
		</BreaksNotification>
	</div>
</template>

<script>
import { mapState } from "vuex";

import { getMinutesBetweenDates } from "@/utilities/dateHelpers.js";

import BreaksNotification from "../templates/BreaksNotification.vue";

export default {
	components: {
		BreaksNotification,
	},
	computed: {
		...mapState("BreaksManagementWidget", ["currentTime"]),
		timeText() {
			const upcomingBreak = this.$store.getters["TutorSchedule/getUpcomingMealBreak"];
			const minutesToBreak = getMinutesBetweenDates(new Date(upcomingBreak.start_time), this.currentTime);
			const minutesToBreakText = minutesToBreak > 1 ?
				`${minutesToBreak} mins` :
				"less than 1 min";
			return `Break starting in ${minutesToBreakText}`;
		},
	},
};
</script>
