import InfoBlock from "./InfoBlock.js";

/**
 *
 * @param {String}  text
 * @param {Array}  	files
 */
function TutorHandoffCommentMessage({ text, files }) {
	this.id = Math.random()
		.toString(36)
		.replace(/[^a-z]+/g, "")
		.substr(0, 100);
	InfoBlock.call(this, {
		text,
		files,
	});
}
TutorHandoffCommentMessage.prototype = Object.create(InfoBlock.prototype);

export default TutorHandoffCommentMessage;
