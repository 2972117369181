<script setup>
import { computed } from "vue";
import { format, subMinutes } from "date-fns";

import useStore from "@/composables/useStore.js";

const store = useStore();

const props = defineProps({
	subject: {
		type: Object,
		required: true,
		validator: (subject) => {
			return [
				"subject",
				"score",
				"total_ongoing_tutors",
			].every((key) => Object.keys(subject).includes(key));
		},
	},
	riskLevel: {
		type: String,
		required: true,
	},
});

const getScoreColor = computed(() => ({
	"tw-text-origami-red-400": props.riskLevel === "high",
	"tw-text-origami-yellow-400": props.riskLevel === "moderate",
	"tw-text-origami-green-400": props.riskLevel === "low",
}));

const currentUser = computed(() => store.state.currentUser);

const formattedLastUpdate = computed(() => {
	const serverDate = new Date(store.state.ServiceAnalytics.lastUpdateTime);
	const localizedDate = subMinutes(serverDate, serverDate.getTimezoneOffset());
	return format(localizedDate, "h:mmaaa", { timeZone: currentUser.value.timezone });
});

</script>

<template>
	<div
		v-jest="'filter-tag'"
		data-cy="filter-tag"
		class="tw-flex tw-items-center tw-w-full tw-justify-evenly"
	>
		<h3
			v-jest="'subject'"
			class="tw-font-origami-bold tw-text-origami-base tw-w-1/4 tw-ml-10"
		>
			{{ props.subject.subject }}
		</h3>
		<div class="tw-flex tw-w-2/3 tw-m-auto tw-justify-between">
			<p class="tw-flex tw-items-center">
				<span
					v-jest="'load-score'"
					class="tw-mr-2 tw-font-origami-bold tw-text-origami-md"
					:class="getScoreColor"
				>
					{{ props.subject.score.toFixed(1) }}
				</span>
				Current Average Tutor Load
			</p>
			<p
				v-jest="'total-tutors'"
				class="tw-flex tw-items-center"
			>
				<span class="tw-mr-2 tw-font-origami-bold tw-text-origami-md tw-text-blue-darker">{{ props.subject.total_ongoing_tutors }}</span> Total Tutors
			</p>
		</div>
		<p
			v-jest="'time-stamp'"
			class="tw-text-origami-grey-300 tw-w-1/3 tw-text-right"
		>
			As of {{ formattedLastUpdate }}
		</p>
	</div>
</template>
