import hasRole from "@/middleware/hasRole.js";
import middleware from "@/middleware/index.js";
import initializePendo from "@/middleware/initializePendo.js";
import initializeZendesk from "@/middleware/initializeZendesk.js";
import initFullStory from "@/middleware/initFullStory.js";
import isConnected from "@/middleware/isConnected.js";
import redirectWhenFeatureFlagIsDisabled from "@/middleware/redirectWhenFeatureFlagIsDisabled.js";
import withNavbar from "@/middleware/withNavbar.js";
import { MICRO_FRONTENDS_NAMES } from "@/modules/MicroFrontend/utilities/index.js";

export const editorRoutes = [
	{
		path: "/teaching-assets/admin*",
		component: () => import("@/modules/MicroFrontend/components/MicroFrontend.vue"),
		beforeEnter: middleware([
			isConnected,
			hasRole("paper-clips/editor"),
			redirectWhenFeatureFlagIsDisabled(
				"PE-20174-teaching-assets-admin-mfe",
				`${window.location.hostname === "localhost" ? "http://localhost:3000" : ""}/dashboard`,
				true,
			),
			initializePendo,
			initializeZendesk,
			initFullStory,
			withNavbar(),
		]),
		props: {
			microFrontendName: MICRO_FRONTENDS_NAMES.TEACHING_ASSETS,
			containerClass: "",
			options: {
				routePrefix: "/teaching-assets",
			},
		},
	},
];
