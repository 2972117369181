<template>
	<div class="form-group">
		<input
			:id="id"
			v-model="value"
			class="form-control"
			:class="{ 'datePicker--active': isValueSelected }"
			type="text"
			onfocus="(this.type='date')"
			onblur="(this.type='text')"
			:placeholder="placeholder"
			:disabled="isDisabled"
			@change="handleInput"
		>
	</div>
</template>
<script>
export default {
	props: {
		isDisabled: {
			type: Boolean,
			default: false,
		},
		id: {
			type: [String, Number],
			required: true,
		},
		placeholder: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			value: "",
		};
	},
	computed: {
		/**
		 * @returns {Boolean}
		 */
		isValueSelected() {
			return this.value !== "";
		},
	},
	methods: {
		handleInput: function(e) {
			this.$emit("input", e.target.value);
		},
	},
};
</script>
<style scoped>
input {
	height: 40px;
	border-radius: 5px;
	border: 2px solid var(--c-gray);
	outline: none;
	padding: 6px 16px;
	font-family: "MonumentGrotesk", "Open Sans", sans-serif;
	font-size: 12px;
	color: var(--c-black);
	cursor: pointer;
}
input:hover {
	background-color: rgba(88, 108, 178, 0.2);
}
.datePicker--active {
	border: 2px solid var(--c-blue);
	font-weight: 600;
	color: var(--c-blue);
}

::placeholder {
	/* Chrome/Opera/Safari */
	font-family: "MonumentGrotesk", "Open Sans", sans-serif;
	font-size: 12px;
	color: var(--c-black);
	opacity: 1;
	font-weight: normal;
}

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	font-family: "MonumentGrotesk", "Open Sans", sans-serif;
	font-size: 12px;
	color: var(--c-black);
	opacity: 1;
	font-weight: normal;
}
::-moz-placeholder {
	/* Firefox 19+ */
	font-family: "MonumentGrotesk", "Open Sans", sans-serif;
	font-size: 12px;
	color: var(--c-black);
	opacity: 1;
	font-weight: normal;
}
:-ms-input-placeholder {
	/* IE 10+ */
	font-family: "MonumentGrotesk", "Open Sans", sans-serif;
	font-size: 12px;
	color: var(--c-black);
	opacity: 1;
	font-weight: normal;
}
:-moz-placeholder {
	/* Firefox 18- */
	font-family: "MonumentGrotesk", "Open Sans", sans-serif;
	font-size: 12px;
	color: var(--c-black);
	opacity: 1;
	font-weight: normal;
}

input:focus {
	border-color: var(--c-blue);
}

input:disabled {
	color: var(--c-black) 99;
}

input.inputField_error {
	border-color: var(--c-orange);
}

input.inputField_success {
	border-color: var(--c-green);
}

input.inputField_disabled {
	border-color: var(--c-gray);
	background-color: var(--c-gray);
}

label {
	font-family: "MonumentGrotesk", "Open Sans", sans-serif;
	font-size: 12px;
	font-weight: 600;
	font-style: normal;
	line-height: normal;
}
</style>
