<script>
import { isBefore } from "date-fns";

import ServiceDashboardTutorCardHeaderAbstract from "./ServiceDashboardTutorCardHeaderAbstract.vue";

export default {
	extends: ServiceDashboardTutorCardHeaderAbstract,
	data() {
		return {
			canImpersonate: false,
		};
	},
	computed: {
		/**
		 * We do not show disconnection decoration for tutor managers.
		 * @returns {Boolean}
		 */
		isDisconnectedAndPunchedIn() {
			return false;
		},
		/**
		 * @returns {Boolean}
		 */
		isFutureShift() {
			return isBefore(this.currentDate, new Date(this.shift.start_time * 1000));
		},
		/**
		 * @returns {Boolean}
		 */
		isGreyedOut() {
			return this.isFutureShift;
		},
		/**
		 * Tutor manager's shift start should never show red.
		 * @returns {Boolean}
		 */
		isShiftStartRed() {
			return false;
		},
		/**
		 * @returns {String}
		 */
		tutorShiftStartTime() {
			return this.shiftStartTime;
		},
		/**
		 * @returns {String}
		 */
		timeElapsedSincePunchIn() {
			return "";
		},
	},
};
</script>
