var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tw-w-full" }, [
    _c("img", {
      staticClass: "tw-max-w-80 tw-max-h-80 tw-mx-auto",
      attrs: {
        "v-jest": _vm.milestoneNumbers.includes(_vm.essayNumber)
          ? `milestone-${_vm.essayNumber}`
          : "normal",
        src: `/images/tutor/${_vm.currentFeedbackPicture}.png`,
      },
    }),
    _vm._v(" "),
    _c(
      "h2",
      {
        staticClass:
          "tw-w-full tw-break-words tw-text-2xl tw-text-center tw-font-bold tw-flex-col",
      },
      [
        _vm.milestoneNumbers.includes(_vm.essayNumber)
          ? _c("div", [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "top-text",
                      expression: "'top-text'",
                    },
                  ],
                },
                [_vm._v("\n\t\t\t\tCongratulations!\n\t\t\t")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "bottom-text",
                      expression: "'bottom-text'",
                    },
                  ],
                  staticClass: "tw-whitespace-nowrap",
                },
                [
                  _vm._v(
                    "\n\t\t\t\tYou've uploaded your " +
                      _vm._s(_vm.essayNumber) +
                      "th essay!\n\t\t\t"
                  ),
                ]
              ),
            ])
          : _c("div", [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "top-text",
                      expression: "'top-text'",
                    },
                  ],
                },
                [_vm._v("\n\t\t\t\tYou've done it!\n\t\t\t")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "bottom-text",
                      expression: "'bottom-text'",
                    },
                  ],
                  staticClass: "tw-whitespace-nowrap",
                },
                [_vm._v("\n\t\t\t\tThank you for your hard work.\n\t\t\t")]
              ),
            ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }