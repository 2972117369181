import api from "./Api.js";

export default {
	/**
	 * Creates a schedule
	 *
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	create(data) {
		return api().post(`schedules`, data);
	},

	/**
	 * Updates a schedule
	 *
	 * @param {number} scheduleId
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	update(scheduleId, data) {
		return api().post(`schedules/${scheduleId}`, data);
	},

	/**
	 * Gets schedules for tutor, filtered with from/to
	 *
	 * @param userId
	 * @param data
	 * @returns {AxiosPromise<any>}
	 */
	getSchedulesforTutor(userId, params) {
		return api().get(`tutors/${userId}/schedules`, {
			params: { ...params, include: "scheduleType,shiftTransfer,pendingScheduleBreakRequest" },
		});
	},
	/**
	 * Deletes a schedule
	 *
	 * @param {number} scheduleId
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	delete(scheduleId, data) {
		return api().delete(`schedules/${scheduleId}`, data);
	},

	/**
	 * Find a schedule by id
	 *
	 * @arg {number} scheduleId
	 * @arg {object} params
	 * @returns {AxiosPromise}
	 */
	show(scheduleId, params) {
		return api().get(`schedules/${scheduleId}`, {
			params: params,
		});
	},

	/**
	 * Get all schedules
	 *
	 * @arg {object} params
	 * @returns {AxiosPromise}
	 */
	list(params) {
		return api().get(`schedules`, {
			params: params,
		});
	},

	/**
	 * Gets current shift of the current tutor
	 *
	 * @deprecated this endpoint is being deprecated in GS-API, please contact @paper-co/squad-service if you need to rely on tutor current shift data.
	 * @returns {AxiosPromise}
	 */
	getCurrentShift(params) {
		if (process.env.MIX_APP_ENV === "development") {
			// eslint-disable-next-line
			console.log(
				"%c⚠️ DEPRECATION WARNING",
				"background: black; color: orange",
				"/me.current-shift endpoint is being deprecated, please contact @paper-co/squad-service if you need to rely on tutor current shift data.",
			);
		}
		return api().get(`me.current-shift`, {
			params: params,
		});
	},

	/**
	 * Punches-in the current tutor to the next punch-in shift
	 * @param {Object} params - i.e 'include' NOT the shiftId
	 * @returns {AxiosPromise}
	 */
	startShift(params = {}) {
		return api().post(`schedules.start`, params);
	},

	/**
	 * Punches-out the current tutor
	 *
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	endShift(data) {
		return api().post(`schedules.end`, data);
	},

	/**
	 * Gets current session availabilities for the current student
	 *
	 * @arg {object} params
	 * @returns {AxiosPromise}
	 */
	getStudentSessionAvailabilities(params) {
		return api().get(`me.dashboard-tiles`, {
			params: params,
		});
	},

	cancelBreak(breakId) {
		return api().post(`schedules/${breakId}/cancel-break`);
	},

	/**
	 * Gets shift statistics of the current tutor
	 *
	 * @returns {AxiosPromise}
	 */
	getShiftStatistics(params) {
		return api().get(`me.shift-statistics`, {
			params: params,
		});
	},
};
