<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		viewBox="0 0 8 5"
	>
		<path
			:fill="iconColor"
			fill-rule="evenodd"
			d="M1.3 0C-.015 0-.394.82.447 1.825l2.125 2.538c.441.527 1.544.527 1.985 0L6.68 1.825C7.525.817 7.143 0 5.83 0h-4.53z"
		/>
	</svg>
</template>
<script>
import Colors from "../../utilities/Colors.js";

export default {
	props: {
		height: {
			type: Number,
			required: false,
			default: 5,
		},
		width: {
			type: Number,
			required: false,
			default: 8,
		},
		iconColor: {
			type: String,
			required: false,
			default: Colors.C_BLUE,
		},
	},
};
</script>
