<template>
	<button
		class="button"
		:disabled="isDisabled"
		:class="[typeClasses[type], { mini: isMini }, { right: willFloatRight }]"
	>
		<div class="icon">
			<slot name="icon" />
		</div>
		<div><slot name="text" /></div>
		<div class="icon--right">
			<slot name="icon-right" />
		</div>
	</button>
</template>

<script>
export default {
	props: {
		isDisabled: {
			type: Boolean,
			default: false,
		},
		isMini: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			validator: function(params) {
				return (
					["PRIMARY", "SECONDARY", "OUTLINE", "SUBMIT", "NEGATIVE", "CANCEL", "BREAK"].indexOf(params) !== -1
				);
			},
			default: "PRIMARY",
		},
		willFloatRight: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			typeClasses: {
				PRIMARY: "primary",
				SECONDARY: "secondary",
				OUTLINE: "outline",
				SUBMIT: "submit",
				NEGATIVE: "negative",
				CANCEL: "cancel",
				BREAK: "break",
			},
		};
	},
};
</script>

<style scoped>
.button {
	height: 40px;
	font-size: 14px;
	font-weight: 600;
	cursor: pointer;
	border: 0px;
	color: var(--origami-white);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	padding: 0 20px;
	outline: none;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.primary {
	background-color: var(--c-blue);
}

.primary:hover {
	background-color: var(--c-dark-blue);
}

.primary:active {
	background-color: var(--c-dark-blue);
}

.break {
	background-color: var(--c-light-blue);
}

.secondary {
	background-color: var(--c-blue);
}

.secondary:hover {
	background-color: var(--c-dark-blue);
}

.secondary:active {
	background-color: var(--c-dark-blue);
}

.submit {
	background-color: var(--c-green);
}

.submit:hover {
	background-color: var(--c-dark-green);
}

.submit:active {
	background-color: var(--c-dark-green);
}

.negative {
	background-color: var(--c-orange);
}

.negative:hover {
	background-color: var(--c-red);
}

.negative:active {
	background-color: var(--c-red);
}

.outline {
	background-color: transparent;
	border: 2px solid var(--c-blue);
	color: var(--c-blue);
}

.outline:hover {
	background-color: var(--c-blue);
	color: var(--c-white);
}

.outline:active {
	background-color: var(--c-blue);
	color: var(--c-white);
}

.cancel {
	background-color: var(--c-white);
	color: var(--c-black);
	border: var(--c-gray) solid 2px;
}

.cancel:hover {
	background-color: var(--c-light-gray);
}

.cancel:active {
	background-color: var(--c-light-gray);
}

:disabled {
	background-color: var(--c-light-gray);
	cursor: not-allowed;
}

:disabled:hover {
	background-color: var(--c-light-gray);
}

.mini {
	height: 30px;
	font-size: 12px;
}

.right {
	float: right;
}

.icon {
	margin-right: 9px;
	text-align: center;
	width: auto;
	display: flex;
}
.icon--right {
	margin-left: 9px;
	text-align: center;
	width: auto;
	display: flex;
}

.icon img {
	height: auto;
	width: auto;
}

.icon svg {
	height: auto;
	width: auto;
}

.icon:empty,
.icon--right:empty {
	display: none;
}

.mini .icon {
	height: 12px;
}

.text {
	font-size: 14px;
	font-weight: 600;
}

@media (max-width: 768px) {
	.button {
		padding: 0px 8px;
	}
}

.primary:focus-visible, .secondary:focus-visible,
.submit:focus-visible, .negative:focus-visible,
.outline:focus-visible, .cancel:focus-visible {
	outline: 2px solid var(--origami-blue-200);
}
</style>
