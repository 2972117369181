/**
 *
 * @param {Object} message
 * @param {String} senderFirstName
 * @param {String} senderLastName
 * @param senderImage
 * @param {Boolean} isTutor
 * @param {Array} userReactions
 * @param {Object} userAvatar
 * @param {Number} bookmarkedAt
 * @constructor
 */
function ReceivedUserMessageDecorator({
	message,
	senderFirstName,
	senderLastName,
	senderImage,
	isTutor,
	userReactions,
	userAvatar,
	bookmarkedAt = null,
}) {
	this.message = message;
	this.id = message.id;
	this.senderFirstName = senderFirstName;
	this.senderLastName = senderLastName;
	this.senderImage = senderImage;
	this.isTutor = isTutor;
	this.userReactions = userReactions;
	this.userAvatar = userAvatar || { firstName: senderFirstName, lastName: senderLastName };
	this.bookmarkedAt = bookmarkedAt;
}

export default ReceivedUserMessageDecorator;
