<template>
	<div class="userSettings tw-flex tw-flex-col tw-my-5 tw-mx-auto tw-w-[1000px] tw-max-w-full">
		<h1
			v-jest="'settings-title'"
			class="tw-font-xl tw-pl-5 tw-mb-5 tw-text-origami-blue-300"
		>
			{{ $t("sidebar.my_account") }}
		</h1>
		<div class="tw-flex tw-flex-col md:tw-flex-row md:tw-items-start">
			<UserSettingsSidebar
				v-jest="'settings-sidebar'"
				:tabs="tabs"
			/>
			<component :is="settingsComponent" />
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";

import IconComputer from "@/components/icons/IconComputer.vue";

import UserSettingsSidebar from "./UserSettingsSidebar.vue";
import IconNotifications from "./icons/IconNotifications.vue";
import IconSettings from "./icons/IconSettings.vue";

export default {
	components: {
		UserSettingsSidebar,
	},
	computed: {
		...mapState([
			"Settings",
			"currentUser",
		]),
		tabs() {
			const tabs = [{ name: "account", component: IconSettings }];
			if (this.displaySettingsComponent) {
				tabs.push({ name: "notifications", component: IconNotifications });
			} else if (this.currentUser.role === "administrator") {
				tabs.push({ name: "features", component: IconComputer });
			}
			return tabs;
		},
		settingsComponent() {
			let component;
			switch (this.Settings.activeTab) {
				default:
				case "account":
					component = () => import("./AccountSettings.vue");
					break;
				case "notifications":
					component = () => this.currentUser.role === "tutor" ?
						import("@/modules/Notifications/components/TutorNotificationsSettings.vue") :
						import("@/modules/Notifications/components/NotificationsSettings.vue");
					break;
				case "features":
					component = () => import("./UserSettingsFeatures.vue");
					break;
			}
			return component;
		},
		displaySettingsComponent() {
			return this.currentUser.role === "student" ||
				this.currentUser.role === "tutor";
		},
	},
};
</script>

<style scoped>
:deep(*) {
	font-family: "MonumentGrotesk", Arial, sans-serif;
}
.userSettings {
	background: var(--c-lighter-gray);
	min-height: calc(100vh - 142px - 2.5rem);
}
.userSettings :deep(input[type=password]) {
	box-shadow: none;
	background: var(--c-white);
	height: 46px;
	line-height: 25px;
	border: 2px solid #d7dada;
	color: var(--c-black);
	font-size: 16px;
	font-weight: 400;
	border-radius: 5px;
}
</style>
