import moment from "moment";

export default {
	/**
	 * Transform user objects into sanitized list objects
	 *
	 * @param {object} data
	 * @returns {Object}
	 */
	transformUser(data) {
		const hasSchool = data.schools.data.length;
		let hasDistrict = false;
		if (hasSchool) {
			hasDistrict = Object.values(data.schools.data[0].district.data).length;
		}
		return {
			synced_with: data.synced_with,
			id: data.id,
			role: Object.values(data.roles.data).length
				? `${data.roles.data[0].name} (${data.roles.data.length})`
				: "N/A",
			fullName: !data.first_name && !data.last_name ? "N/A" : data.first_name + " " + data.last_name,
			username: data.username ? data.username : "N/A",
			email: data.email ? data.email : "N/A",
			district: hasDistrict ? data.schools.data[0].district.data.name : "N/A",
			school: hasSchool ? data.schools.data[0].name : "N/A",
			license:
				data.license_expiry_date != 0
					? moment.unix(data.license_expiry_date).format("YYYY-MM-DD")
					: "N/A",
		};
	},

	/**
	 * Transform section objects into sanitized list objects
	 *
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	transformSection(data) {
		const teachers = data.teachers.data;
		let teacherNames = "";
		let counter = 0;

		const hasTeachers = Object.values(teachers).length;

		// generate teacher list
		if (hasTeachers) {
			teachers.forEach(function(teacher) {
				counter = counter + 1;
				const teacherName = teacher.first_name + " " + teacher.last_name;
				teacherNames += teacherName;
				if (counter < hasTeachers) {
					teacherNames += ", ";
				}
			});
		}

		const topics = data.topics.data;
		const topicNames = [];
		let displayTopics = "";

		// sort topics by subject
		topics.forEach(function(topic) {
			topicNames.push(topic.name);
		});

		displayTopics = data.topics.data.length
			? topicNames[0] + " (" + topicNames.length + ")"
			: "N/A";

		return {
			id: data.id,
			name: data.name,
			displayName: data.display_name,
			teacher: hasTeachers ? teacherNames : "N/A",
			school: data.school.data.name,
			status: data.status,
			startDate: data.active_from ? data.active_from : "N/A",
			endDate: data.active_to ? data.active_to : "N/A",
			students: data.students_count,
			topics: displayTopics,
		};
	},

	/**
	 * Transform school objects into sanitized list objects
	 *
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	transformSchool(data) {
		const hasDistrict = Object.values(data.district.data).length;
		const hasManager = Object.entries(data.manager.data).length;
		const hasLoginType = Object.entries(data.loginType.data).length;

		return {
			synced_with: data.synced_with,
			id: data.id,
			name: data.name,
			district: hasDistrict ? data.district.data.name : "N/A",
			country: data.country_code ? data.country_code : "N/A",
			region: data.region_code ? data.region_code : "N/A",
			students: data.count.students_count,
			license:
				data.license_ends_at != 0 ? moment.unix(data.license_ends_at).format("YYYY-MM-DD") : "N/A",
			loginType: hasLoginType ? data.loginType.data.name : "N/A",
			csManager: hasManager ? data.manager.data.first_name : "N/A",
		};
	},

	/**
	 * Transform district objects into sanitized list objects
	 *
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	transformDistrict(data) {
		return {
			synced_with: data.synced_with,
			id: data.id,
			name: data.name,
			country: data.country_code ? data.country_code : "N/A",
			region: data.region_code ? data.region_code : "N/A",
			timezone: data.timezone ? data.timezone : "N/A",
			schools: data.schools_count,
			students: data.student_count,
		};
	},
};
