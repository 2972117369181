import rootInstance from "@/services/root.js";

const baseUrl = process.env.MATH_PROBLEM_API_URL + "/api/v1/";

const mathApi = () => {
	rootInstance.defaults.baseURL = baseUrl;
	return rootInstance;
};

export const createComment = (problemId, comment) => {
	return mathApi().post("/${problemId}/comment", comment)
		.then(({ data }) => data);
};
