<template>
	<div>
		<BreaksNotification
			v-if="timeText || promptText"
			v-jest="'rest-break-end-prompt-notification'"
			:notification-type="notificationType"
			class="tw-mb-4"
		>
			<p
				v-jest="'rest-break-end-prompt-time'"
				class="tw-font-bold tw-text-sm"
			>
				{{ timeText }}
			</p>
			<p
				v-if="promptText"
				v-jest="'rest-break-end-prompt-text'"
				class="tw-text-sm"
			>
				{{ promptText }}
			</p>
		</BreaksNotification>
		<OrigamiButton
			v-if="isOnRestBreak || isLateFromRestBreak && !isLatePastThreshold"
			v-jest="'rest-break-end-button'"
			variant="primary"
			class="tw-w-full tw-justify-center"
			:is-loading="$store.state.BreaksManagementWidget.isRequestLoading"
			@click.native="endRestBreak"
		>
			End break
		</OrigamiButton>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { differenceInMinutes, differenceInSeconds } from "date-fns";
import { OrigamiButton } from "@origami/vue2";

import { formatPlurality } from "@/utilities/strings.js";

import BreaksNotification from "../templates/BreaksNotification.vue";
import {
	REST_BREAK_LENGTH_MINUTES,
	BREAK_VERY_LATE_THRESHOLD_MINUTES,
} from "../../utilities/BreakConfiguration.js";


export default {
	name: "RestBreakInProgress",
	components: {
		BreaksNotification,
		OrigamiButton,
	},
	computed: {
		...mapState("BreaksManagementWidget", ["currentTime"]),
		timeText() {
			if (this.isOnRestBreak) {
				return this.getOnRestBreakTimeMessage();
			} else if (this.hasUpcomingRestBreak) {
				return this.getUpcomingBreakTimeMessage();
			} else if (this.isLateFromRestBreak) {
				return this.getLateFromBreakTimeMessage();
			}

			return "";
		},
		promptText() {
			if (this.isOnRestBreak) {
				return "You may end now or at the scheduled time.";
			}

			if (this.isLateFromRestBreak) {
				return this.isLatePastThreshold ?
					"You are currently way over the time limit of your break, please contact a Platform Manager as soon as possible." :
					`You are over the ${REST_BREAK_LENGTH_MINUTES} min limit of your break, please end your break now.`;
			}

			return "";
		},
		hasUpcomingRestBreak() {
			return this.$store.getters["BreaksManagementWidget/hasUpcomingRestBreak"];
		},
		isOnRestBreak() {
			return this.$store.getters["TutorSchedule/isOnRestBreak"];
		},
		isLateFromRestBreak() {
			return this.$store.getters["TutorSchedule/getLateRestBreak"];
		},
		isLatePastThreshold() {
			if (!this.isLateFromRestBreak) {
				return;
			}

			const startTime = new Date(this.currentSegment.start_time);
			const minutesLate = differenceInMinutes(this.currentTime, startTime);

			return minutesLate >= BREAK_VERY_LATE_THRESHOLD_MINUTES;
		},
		notificationType() {
			return this.isLateFromRestBreak ? "error" : "info";
		},
		currentSegment() {
			return this.$store.getters["TutorSchedule/getCurrentShiftSegment"];
		},
	},
	methods: {
		getMinutesUntilTime(time) {
			return Math.ceil(
				differenceInSeconds(time, this.currentTime) / 60,
			);
		},
		getOnRestBreakTimeMessage() {
			const minutesRemaining = this.getMinutesUntilTime(new Date(this.currentSegment.end_time));
			return minutesRemaining > 1 ?
				`${minutesRemaining} mins left on your rest break` :
				"Less than 1 min left on your rest break";
		},
		getUpcomingBreakTimeMessage() {
			const upcomingBreak = this.$store.getters["TutorSchedule/getUpcomingRestBreak"];
			const minutesToBreak = this.getMinutesUntilTime(new Date(upcomingBreak.start_time));
			const minutesToBreakText = minutesToBreak > 1 ?
				`${minutesToBreak} mins` :
				"less than 1 min";
			return `Break starting in ${minutesToBreakText}`;
		},
		getLateFromBreakTimeMessage() {
			const lateBreak = this.$store.getters["TutorSchedule/getLateRestBreak"];
			const minutesLate = differenceInMinutes(this.currentTime, new Date(lateBreak.end_time));
			return minutesLate > 0 ? `${formatPlurality(minutesLate, "min")} late` : "";
		},
		endRestBreak() {
			this.$store.dispatch("BreaksManagementWidget/endRestBreak");
		},
	},
};
</script>
