<template>
	<NoFilterBanner>
		<template #icon>
			<Calendar class="tw-w-11 tw-h-12" />
		</template>
		Select at least one of the filters above to view the scheduled shifts
	</NoFilterBanner>
</template>
<script>
import { NoFilterBanner } from "@/modules/Customers/index.js";

import { Calendar } from "../../../components/elements/icons/index.js";

export default {
	components: {
		NoFilterBanner,
		Calendar,
	},
};
</script>
