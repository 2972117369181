var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "assessment__text assessment__text--bold" }, [
        _vm._v(
          "\n\t\t" +
            _vm._s(
              _vm.$t("right_sidebar.assessment.tag_the_question", {
                name: _vm.studentName,
              })
            ) +
            "\n\t"
        ),
      ]),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass:
            "assessment__text assessment__text--blue assessment__text--question",
        },
        [
          _vm._v(
            "\n\t\t" +
              _vm._s(_vm.$t("right_sidebar.assessment.tag_questions_by")) +
              "\n\t"
          ),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.assessmentQuestions, function (question, index) {
        return _c(
          "div",
          { key: index, staticClass: "assessment__question" },
          [
            _c(
              "div",
              { staticClass: "assessment__text assessment__text--bold" },
              [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(
                      _vm.$t("right_sidebar.assessment.question", {
                        number: index + 1,
                      })
                    ) +
                    "\n\t\t"
                ),
              ]
            ),
            _vm._v(" "),
            _vm.isQuestionAnInstanceOfMessageText(question)
              ? [
                  _c("StringWithEmojis", {
                    staticClass: "assessment__questionText",
                    attrs: { value: question.message.text },
                  }),
                ]
              : [
                  _vm.isQuestionMediaTypeImage(question.message)
                    ? [
                        _c("div", [
                          _c("img", {
                            staticClass: "questionTagImage__image",
                            attrs: { src: question.message.url },
                          }),
                        ]),
                      ]
                    : _vm.isQuestionMediaTypeFile(question.message)
                    ? [
                        _c("div", { staticClass: "assessment__questionText" }, [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(question.message.fileName) +
                              "\n\t\t\t\t"
                          ),
                        ]),
                      ]
                    : [
                        _c("div", { staticClass: "assessment__questionText" }, [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(question.message.url) +
                              "\n\t\t\t\t"
                          ),
                        ]),
                      ],
                ],
          ],
          2
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("div", { staticClass: "assessment__nonAcademicTitle" }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(
                  _vm.$t("right_sidebar.assessment.is_session_non_academic")
                ) +
                "\n\t\t"
            ),
          ]),
          _vm._v(" "),
          _c(
            "BaseCheckbox",
            {
              staticClass: "assessment__nonAcademicCheckbox",
              attrs: {
                value: _vm.isNonAcademic,
                "checkbox-id": 1,
                "checkbox-value": "nonAcademic",
              },
              on: {
                input: function ($event) {
                  return _vm.$emit("non-academic-check", $event)
                },
              },
            },
            [
              _c("template", { slot: "text" }, [
                _c("span", { staticClass: "assessment__nonAcademicText" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.$t("right_sidebar.assessment.it_is")) +
                      "\n\t\t\t\t"
                  ),
                ]),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }