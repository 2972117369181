<template>
	<BreaksManagementWidgetWrapper>
		<template #status>
			<ShiftStatus />
		</template>
		<template #shiftDetails>
			<CurrentShiftDetails />
		</template>
		<template #breakDetails>
			<RestBreakDetails />
			<MealBreakDetails />
		</template>
		<template #breakButtons>
			<MealBreakButton
				v-if="isPunchedIntoShift"
				v-jest="'meal-break-button'"
			/>
			<RestBreakButton
				v-if="isPunchedIntoShift"
				v-jest="'rest-break-button'"
			/>
			<BioBreakButton
				v-if="isPunchedIntoShift"
				v-jest="'bio-break-button'"
			/>
		</template>
		<template #content>
			<RestBreak />
			<MealBreak />
			<BioBreak
				v-if="isPunchedIntoShift"
				v-jest="'bio-break'"
			/>
		</template>
	</BreaksManagementWidgetWrapper>
</template>
<script>
import { mapState } from "vuex";
import { differenceInMinutes, differenceInSeconds } from "date-fns";

import { tutorScheduleListener } from "@/modules/TutorSchedule/index.js";
import { getMinutesBetweenDates } from "@/utilities/dateHelpers.js";

import breaksManagementWidgetListener from "../listeners/BreaksManagementWidgetListener.js";
import {
	BREAK_VERY_LATE_THRESHOLD_MINUTES,
	UPCOMING_BREAK_THRESHOLD_MINUTES,
} from "../utilities/BreakConfiguration.js";

import BreaksManagementWidgetWrapper from "./templates/BreaksManagementWidgetWrapper.vue";
import ShiftStatus from "./ShiftStatus.vue";
import CurrentShiftDetails from "./CurrentShiftDetails.vue";
import BioBreak from "./BioBreak/BioBreak.vue";
import RestBreak from "./RestBreak/RestBreak.vue";
import MealBreak from "./MealBreak/MealBreak.vue";
import BioBreakButton from "./BioBreak/BioBreakButton.vue";
import RestBreakButton from "./RestBreak/RestBreakButton.vue";
import MealBreakButton from "./MealBreak/MealBreakButton.vue";
import RestBreakDetails from "./RestBreak/RestBreakDetails.vue";
import MealBreakDetails from "./MealBreak/MealBreakDetails.vue";


const ONE_SECOND_IN_MS = 1000;
const ONE_MINUTE_IN_MS = 60 * ONE_SECOND_IN_MS;
const FORTY_FIVE_SECONDS_IN_MS = 45 * ONE_SECOND_IN_MS;

export default {
	components: {
		BreaksManagementWidgetWrapper,
		ShiftStatus,
		CurrentShiftDetails,
		BioBreak,
		RestBreak,
		RestBreakDetails,
		BioBreakButton,
		RestBreakButton,
		MealBreak,
		MealBreakButton,
		MealBreakDetails,
	},
	sockets: {
		...breaksManagementWidgetListener,
		...tutorScheduleListener,
	},
	data() {
		return {
			currentTimeRefreshInterval: null,
			currentStateRefreshInterval: null,
			currentShiftRefreshInterval: null,
			audioRestBreakChime: new Audio("/service/audio/rest_break_bell_chime.webm"),
			audioMealBreakChime: new Audio("/service/audio/meal_break_bell_chime.webm"),
			featureFlagBMWCurrentShiftPollingInterval: 1,
		};
	},
	computed: {
		...mapState(["BreaksManagementWidget", "UserPreferences", "currentUser"]),
		isPunchedIntoShift() {
			return this.$store.getters["TutorSchedule/isPunchedIntoShift"];
		},
		isOnRestBreak() {
			return this.$store.getters["TutorSchedule/isOnRestBreak"] &&
				!this.$store.getters["BreaksManagementWidget/isBioBreakInProgress"];
		},
		isOnBioBreakOrMIA() {
			return this.$store.getters["TutorSchedule/isOnBioBreakOrMIA"];
		},
		isOnMealBreak() {
			return this.$store.getters["TutorSchedule/isOnMealBreak"] &&
				!this.$store.getters["BreaksManagementWidget/isBioBreakInProgress"];
		},
		isInTransitionPeriodAndOnRestBreakWorkflow() {
			return this.$store.getters["BreaksManagementWidget/isOnRestBreakWorkflow"]
					&& this.$store.getters["BreaksManagementWidget/isInRestBreakTransitionPeriod"];
		},
		isInTransitionPeriodAndOnBioBreakWorkflow() {
			return this.$store.getters["BreaksManagementWidget/isOnBioBreakWorkflow"]
					&& this.$store.getters["BreaksManagementWidget/isInBioBreakTransitionPeriod"];
		},
		isPastRestBreakLateThreshold() {
			const lateBreak = this.$store.getters["TutorSchedule/getLateRestBreak"];
			return lateBreak &&
				differenceInMinutes(this.BreaksManagementWidget.currentTime, new Date(lateBreak.end_time)) >=
				BREAK_VERY_LATE_THRESHOLD_MINUTES;
		},
		isLateFromRestBreak() {
			const lateBreak = this.$store.getters["TutorSchedule/getLateRestBreak"];
			return !!lateBreak &&
				!this.isPastRestBreakLateThreshold &&
				!this.$store.getters["BreaksManagementWidget/isBioBreakInProgress"];
		},
		hasActivityOnHold() {
			return this.$store.getters["TutorSchedule/hasActivityOnHold"];
		},
		hasShiftSegmentInProgress() {
			return this.$store.getters["TutorSchedule/shiftSegmentIsInProgress"];
		},
		hasRejectedBreakRequest() {
			return !!this.$store.state.TutorSchedule.rejectedBreakRequest;
		},
		isMealBreakStartingSoon() {
			const upcomingMealBreak = this.$store.getters["TutorSchedule/getUpcomingMealBreak"];

			if (this.$store.getters["BreaksManagementWidget/isBioBreakInProgress"] ||
				!upcomingMealBreak) {
				return false;
			}

			return getMinutesBetweenDates(
				new Date(upcomingMealBreak.start_time),
				this.BreaksManagementWidget.currentTime) <=
			UPCOMING_BREAK_THRESHOLD_MINUTES;
		},
		hasLateMealBreak() {
			return !!this.$store.getters["TutorSchedule/getLateMealBreak"] &&
				!this.$store.getters["BreaksManagementWidget/isBioBreakInProgress"];
		},
		hasUpcomingRestBreak() {
			return this.$store.getters["BreaksManagementWidget/hasUpcomingRestBreak"] &&
				!this.$store.getters["BreaksManagementWidget/isBioBreakInProgress"];
		},
		isOnNewActivityHold() {
			return this.$store.getters["TutorSchedule/isOnNewActivityHold"];
		},
		currentSegment() {
			return this.$store.getters["TutorSchedule/getCurrentShiftSegment"];
		},
	},
	watch: {
		isOnBioBreakOrMIA(newValue) {
			if (newValue) {
				this.$store.dispatch("BreaksManagementWidget/setCurrentBioBreakStep");
			}
		},
		isOnRestBreak(newValue) {
			if (newValue) {
				this.$store.dispatch("BreaksManagementWidget/setRestBreakInProgress");
				if (this.UserPreferences.isBreakChimesEnabled) {
					const secondsPassed = this.getSecondsSinceBreakStartTime(new Date(this.currentSegment.start_time));
					this.audioRestBreakChime.currentTime = secondsPassed < ONE_MINUTE_IN_MS ? secondsPassed : 0;
					this.audioRestBreakChime.play();
				}
			}
		},
		isOnMealBreak(newValue) {
			if (newValue) {
				this.$store.dispatch("BreaksManagementWidget/setMealBreakInProgress");
				if (this.UserPreferences.isBreakChimesEnabled) {
					const secondsPassed = this.getSecondsSinceBreakStartTime(new Date(this.currentSegment.start_time));
					this.audioMealBreakChime.currentTime = secondsPassed < ONE_MINUTE_IN_MS ? secondsPassed : 0;
					this.audioMealBreakChime.play();
				}
			}
		},
		isPastRestBreakLateThreshold(newValue) {
			if (newValue) {
				this.$store.dispatch("BreaksManagementWidget/setRestBreakLocked");
			}
		},
		isLateFromRestBreak(newValue) {
			if (newValue) {
				this.$store.dispatch("BreaksManagementWidget/setRestBreakInProgress");
			}
		},
		isInTransitionPeriodAndOnBioBreakWorkflow(newValue) {
			if (newValue) {
				this.$store.dispatch("BreaksManagementWidget/resetWorkflow");
			}
		},
		isInTransitionPeriodAndOnRestBreakWorkflow(newValue) {
			if (newValue) {
				this.$store.dispatch("BreaksManagementWidget/resetWorkflow");
			}
		},
		hasShiftSegmentInProgress(newValue) {
			if (newValue && this.$store.getters["BreaksManagementWidget/isOnBioBreakWorkflow"]) {
				this.$store.dispatch("BreaksManagementWidget/resetWorkflow");
			}
		},
		hasActivityOnHold(newValue) {
			if (newValue) {
				this.$store.dispatch("BreaksManagementWidget/startBioBreakInActiveClassroom");
			}
		},
		hasRejectedBreakRequest(newValue) {
			if (newValue) {
				this.$store.dispatch("BreaksManagementWidget/setBreakRequestRejected");
			}
		},
		isMealBreakStartingSoon(newValue) {
			if (newValue) {
				this.$store.dispatch("BreaksManagementWidget/setMealBreakUpcoming");
			}
		},
		hasLateMealBreak(newValue) {
			if (newValue) {
				this.$store.dispatch("BreaksManagementWidget/setMealBreakLate");
			}
		},
		hasUpcomingRestBreak(newValue) {
			if (newValue) {
				this.$store.dispatch("BreaksManagementWidget/setRestBreakInProgress");
			}
		},
		isOnNewActivityHold(newValue) {
			if (newValue) {
				this.$store.dispatch("BreaksManagementWidget/holdNewActivityForBio");
			}
		},
	},
	async created() {
		await this.$store.dispatch("UserPreferences/getUserPreferences");
		await this.$store.dispatch("BreaksManagementWidget/resetWorkflow");

		this.featureFlagBmwToastNotifications = await this.$getFlag("core-2216-bmw-toast-notifications");
		if (!this.featureFlagBmwToastNotifications) {
			// Polling/fetching is handled in BreaksNotifier when FF on
			await this.$store.dispatch("TutorSchedule/getCurrentShift");
			await this.$store.dispatch("TutorSchedule/getShiftState");

			this.featureFlagBMWCurrentShiftPollingInterval = await this.$getFlag("pe-18154-bmw-current-shift-polling-interval");

			this.currentTimeRefreshInterval = setInterval(() => {
				this.$store.dispatch("BreaksManagementWidget/setCurrentTime");
			}, ONE_SECOND_IN_MS);
			this.currentStateRefreshInterval = setInterval(() => {
				this.$store.dispatch("TutorSchedule/getShiftState");
			}, FORTY_FIVE_SECONDS_IN_MS);
			this.currentShiftRefreshInterval = setInterval(() => {
				this.$store.dispatch("TutorSchedule/getCurrentShift");
			}, this.featureFlagBMWCurrentShiftPollingInterval * ONE_MINUTE_IN_MS);
		}
	},
	beforeDestroy() {
		window.clearInterval(this.currentTimeRefreshInterval);
		window.clearInterval(this.currentStateRefreshInterval);
		window.clearInterval(this.currentShiftRefreshInterval);
	},
	methods: {
		getSecondsSinceBreakStartTime(time) {
			return differenceInSeconds(this.BreaksManagementWidget.currentTime, time);
		},
	},
};
</script>
