/**
 *
 * @param image
 * @param header
 * @param senderFirstName
 * @param subHeader
 * @param funFact
 * @constructor
 */
function ChatIntro({ header, senderFirstName, badge }) {
	this.id = Math.random()
		.toString(36)
		.replace(/[^a-z]+/g, "")
		.substr(0, 100);
	this.header = header;
	this.senderFirstName = senderFirstName;
	this.badge = badge;
}

export default ChatIntro;
