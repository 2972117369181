<template>
	<div
		v-jest="'item'"
		class="item"
		:class="{
			'item--greyBackground': isGreyedOut,
			'item--blink': isBlinking,
		}"
	>
		<div class="tw-flex tw-items-center tw-h-full">
			<component
				:is="iconComponent"
				v-jest="'activity-icon'"
				data-cy="go-to-activity-icon"
				class="item__icon hover:tw-cursor-pointer"
				:icon-color="iconColor"
				:height="17"
				:width="14"
				@click.native="goToActivity()"
			/>
		</div>

		<div class="item__content tw-flex tw-flex-row tw-items-start tw-justify-between tw-grow-[10]">
			<div class="item__sessionTitleContainer tw-pl-2">
				<div
					v-jest="'left-top-text'"
					class="item__name tw-max-w-[10rem]"
				>
					{{ leftTopText }}
				</div>
				<div
					v-jest="'left-bottom-text'"
					class="item__school tw-text-origami-grey-300 tw-max-w-[10rem]"
					:class="[isDemo ? 'item__greenTextColor' : 'item__school']"
				>
					{{ leftBottomText }}
				</div>
			</div>
			<div class="item__sessionInfoContainer tw-mt-0.5 tw-text-origami-grey-300">
				<div
					v-if="middleTopText"
					v-jest="'middle-top-text'"
					class="item__middleTop"
				>
					{{ middleTopText }}
				</div>
				<div
					v-if="middleBottomText"
					v-jest="'middle-bottom-text'"
					class="item__middleBottom tw-flex tw-items-bottom"
					:class="{
						'item__middleBottom--red': isEssayCriticalOrLate,
						'item__middleBottom--orange': isEssayAtRisk,
					}"
				>
					{{ middleBottomText }}
					<span>
						<OrigamiIcon
							:id="`copy-resource-id-${resource.id}`"
							v-jest="'copy-resource-id'"
							data-cy="copy-resource-icon"
							name="copy"
							color="black"
							width="12"
							height="12"
							class="hover:tw-cursor-pointer tw-ml-1"
							@click.native="copyResourceId()"
						/>
					</span>
				</div>
			</div>
			<div
				class="item__sessionTimeContainer tw-mt-[2px] tw-text-right"
				@mouseenter="setIsHovering(true)"
				@mouseleave="setIsHovering(false)"
			>
				<div class="item__rightTop">
					<span
						v-jest="'time-elapsed'"
						class="item__timeElapsed"
					>
						{{ timeElapsed }}
					</span>
					<IconArrowShare
						v-if="!isEssay"
						v-jest="'transfer-icon'"
						data-cy="transfer-icon"
						class="item__shareIcon tw-ml-1"
						icon-color="#484756"
						@click.stop.native="showTransferModal()"
					/>
				</div>
				<div
					v-if="isStudentDisconnected"
					v-jest="'student-disconnected'"
					class="item__disconnected"
				>
					Disconnected for {{ studentIsDisconnectedFor }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { OrigamiIcon } from "@origami/vue2";
import tippy from "tippy.js";

import IconSession from "../../../components/icons/IconSession.vue";
import IconArrowShare from "../../../components/icons/IconArrowShare.vue";
import IconEssay from "../../../components/icons/IconEssay.vue";
import Colors from "../../../utilities/Colors.js";

export default {
	components: {
		IconSession,
		IconEssay,
		IconArrowShare,
		OrigamiIcon,
	},
	props: {
		resource: {
			required: true,
			type: [Object, Array],
		},
	},
	data() {
		return {
			isHovering: false,
			currentDate: new Date(),
			copyTooltip: null,
		};
	},
	computed: {
		...mapState(["currentUser"]),
		/**
		 * @returns {Boolean}
		 */
		isBlinking() {
			return false;
		},
		/**
		 * @returns {Boolean}
		 */
		isGreyedOut() {
			return false;
		},
		/**
		 * Checks if essay is critical or late
		 * @returns {Boolean}
		 */
		isEssayCriticalOrLate() {
			return this.essayOverdueType === "late" || this.essayOverdueType === "critical";
		},
		/**
		 * Checks if essay is at risk
		 * @returns {Boolean}
		 */
		isEssayAtRisk() {
			return this.essayOverdueType === "risk";
		},
		/**
		 *  Checks if current resource is an essay
		 *  @returns {Boolean}
		 */
		isEssay() {
			return false;
		},

		/**
		 *  Checks if student disconnected from session
		 * @returns {Boolean}
		 */
		isStudentDisconnected() {
			return false;
		},

		/**
		 *  Returns the time since the student is disconnected
		 * @returns {String}
		 */
		studentIsDisconnectedFor() {
			return "";
		},

		/**
		 * Check if session is individual
		 * @returns {Boolean}
		 */
		isIndividualSession() {
			return false;
		},
		/**
		 * Check if session is a demo
		 * @returns {Boolean}
		 */
		isDemo() {
			return false;
		},
		/**
		 * @returns {String}
		 */
		// eslint-disable-next-line vue/return-in-computed-property
		iconColor() {
			if (this.isEssay) {
				if (this.essayOverdueType === "late" || this.essayOverdueType === "critical") {
					return Colors.C_ORANGE;
				} else if (this.essayOverdueType === "risk") {
					return Colors.C_PEACH;
				}
			} else {
				if (this.isDemo) {
					return Colors.C_GREEN;
				} else if (this.isStudentDisconnected) {
					return Colors.C_ORANGE;
				} else {
					return Colors.C_BLACK;
				}
			}
		},
		/**
		 *  Gets current icon
		 * @returns {String}
		 */
		iconComponent() {
			return "";
		},
		/**
		 * Returns School
		 * @returns {String}
		 */
		leftBottomText() {
			return "";
		},

		/**
		 * Returns Student Name or Group and Group count
		 * @returns {String}
		 */
		leftTopText() {
			return "";
		},
		/**
		 * Return topic if session and title if essay
		 * @returns {String}
		 */
		middleTopText() {
			return "";
		},

		/**
		 * Return middle bottom text (late hours)
		 * @returns {Number/String}
		 */
		middleBottomText() {
			return "";
		},

		/**
		 * Return elapsed time on essay or session
		 * @returns {Number}
		 */
		timeElapsed() {
			return 0;
		},
		/**
		 * @returns {Boolean}
		 */
		isShowingTimeElapsed() {
			return true;
		},
	},
	mounted() {
		this.copyTooltip = tippy(`#copy-resource-id-${this.resource.id}`, {
			appendTo: "parent",
			arrow: true,
			placement: "top",
			content: "Copied to clipboard",
			trigger: "click",
			onShow(instance) {
				setTimeout(() => {
					instance.hide();
				}, 3000);
			},
		});
	},
	beforeUpdate() {
		this.currentDate = new Date();
	},
	methods: {
		/**
		 * onClick of the transfer button, show Transfer modal
		 */
		showTransferModal() {},
		/**
		 * @param isHovering
		 */
		setIsHovering(isHovering) {
			this.isHovering = isHovering;
		},
		goToActivity() {},
		copyResourceId() {
			navigator.clipboard.writeText(this.resource.id);
		},
	},
};
</script>

<style scoped>
:deep(.tippy-box) {
	border-radius: 0.25rem;
	background-color: var(--origami-black) !important;
}

:deep(.tippy-box > .tippy-arrow) {
	color: var(--origami-black) !important;
}

.item {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.1);
	padding: 8px;
	height: 50px;
	background-color: var(--c-white);
	border: solid 2px transparent;
	border-radius: 2px;
}
.item__content {
	flex-grow: 2;
}
.item--greyBackground {
	background-color: var(--c-lighter-gray);
}
.item--blink {
	animation: blink 1.5s linear infinite;
}
.item__sessionTimeContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	height: 33px;
}
.item__timeElapsed {
	max-width: 50px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.item__name {
	font-size: 12px;
	font-weight: 600;
	line-height: 1.5;
}
.item__disconnected {
	color: var(--c-orange);
	font-style: italic;
}
.item__middleBottom--red {
	color: var(--c-orange);
}
.item__middleBottom--orange {
	color: var(--c-orange);
}
.item__middleTop,
.item__middleBottom--red,
.item__middleBottom--orange,
.item__school,
.item__name {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.item__school,
.item__disconnected,
.item__middleTop,
.item__middleBottom,
.item__rightTop {
	font-size: 10px;
	line-height: 1.5;
}
.item__shareIcon {
	cursor: pointer;
}
@keyframes blink {
	50% {
		background-color: var(--c-lighter-gray);
	}
}
</style>
