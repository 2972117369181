var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "skip-to-main-content",
          expression: "'skip-to-main-content'",
        },
      ],
      staticClass:
        "skip-to-main-content tw-block tw-text-center tw-py-4 tw-text-base tw-font-medium tw-bg-white-high tw-text-black-high tw--top-14 tw-absolute tw-w-full",
      attrs: { href: "#main-content", tabindex: "0" },
    },
    [_vm._v("\n\tSkip to main content\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }