import api from "./Api.js";

export default {
	/**
	 * Updates a message receipt
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	updateMessageReceipt(data) {
		return api().post(`message-receipts-update`, data);
	},

	/**
	 * Gets the last message read for each user in a session
	 * get a user
	 * @arg {number} sessionId
	 * @arg {object} params
	 * @returns {AxiosPromise}
	 */
	getLastMessageReadForEachUser(sessionId, params) {
		return api().get(`message-receipts/${sessionId}`, {
			params: params,
		});
	},

	/**
	 *
	 * Get messages current user has not yet read in a session
	 * @arg {number} sessionId
	 * @arg {object} params
	 * @returns {AxiosPromise}
	 */
	getMessagesNotRead(sessionId, params) {
		return api().get(`message-receipts-not-read/${sessionId}`, {
			params: params,
		});
	},

	/**
	 * @param 	{Number} sessionId
	 * @returns {Promise}
	 */
	updateAllUnreadMessagesToRead(sessionId) {
		return api().post(`message-receipts-update-all/${sessionId}`);
	},
};
