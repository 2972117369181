import SubjectsAPI from "@/services/api/Subjects.js";
import TutorsAPI from "@/services/api/Tutors.js";

const DEMO_SUBJECT_ID = 30;
const TRIAGE_SUBJECT_ID = 31;

const getDefaultState = () => ({
	subjects: [],
	scheduledTutors: [],
});

const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_SUBJECTS(state, payload) {
		state.subjects = payload.subjects;
	},
	SET_SCHEDULED_TUTORS(state, payload) {
		state.scheduledTutors = payload.scheduledTutors;
	},
};

export const actions = {
	async getSubjects({ commit }, payload) {
		try {
			const response = await SubjectsAPI.list(payload);
			commit("SET_SUBJECTS", {
				subjects: response.data.data,
			});
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	async getScheduledTutors({ commit }, payload) {
		try {
			const response = await TutorsAPI.getTutorSearch(payload);
			commit("SET_SCHEDULED_TUTORS", {
				scheduledTutors: response.data.data,
			});
		} catch (e) {
			Sentry.captureException(e);
		}
	},
};

export const getters = {
	filteredSubjects(state) {
		return state.subjects.filter((subject) => {
			return subject.id !== DEMO_SUBJECT_ID && subject.id !== TRIAGE_SUBJECT_ID;
		});
	},
	filteredScheduledTutors(state) {
		const scheduledTutorsCopy = [...state.scheduledTutors];
		scheduledTutorsCopy.forEach((tutor) => {
			tutor.subjects.data = tutor.subjects.data.filter((subject) => {
				return Number(subject.id) !== DEMO_SUBJECT_ID && Number(subject.id) !== TRIAGE_SUBJECT_ID;
			});
		});
		return scheduledTutorsCopy;
	},
};

export default {
	namespaced: true,
	mutations,
	state: getDefaultState(),
	actions,
	getters,
};
