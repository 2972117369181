<template>
	<component
		:is="isLabelWrapped ? 'label' : 'div'"
		class="vue-checkbox"
		:class="[typeClasses[type]]"
	>
		<input
			:id="checkboxId"
			v-model="checked"
			v-jest="'input'"
			type="checkbox"
			:value="checkboxValue"
			:checked="isChecked"
			:disabled="isDisabled"
			@change="handleInput"
		>
		<component
			:is="isLabelWrapped ? 'span' : 'label'"
			class="checkbox"
			:for="checkboxId"
		/>
		<component
			:is="isLabelWrapped ? 'span' : 'label'"
			class="text"
			:for="checkboxId"
		>
			<p><slot name="text" /></p>
		</component>
	</component>
</template>

<script>
export default {
	props: {
		type: {
			type: String,
			validator: function(params) {
				return ["PRIMARY", "WARNING", "SUBMIT", "NEGATIVE"].indexOf(params) !== -1;
			},
			default: "PRIMARY",
		},
		isDisabled: {
			type: Boolean,
			default: false,
		},
		isChecked: {
			type: Boolean,
			default: false,
		},
		isLabelWrapped: {
			type: Boolean,
			default: false,
		},
		checkboxValue: {
			type: [String, Number, Object, Boolean],
			required: true,
		},
		checkboxId: {
			type: [String, Number],
			required: true,
		},
		value: {
			type: [Array, Boolean],
			default: false,
		},
	},

	data() {
		return {
			typeClasses: {
				PRIMARY: "primary",
				WARNING: "warning",
				SUBMIT: "submit",
				NEGATIVE: "negative",
			},
			checkboxEmitValue: false,
		};
	},

	computed: {
		checked: {
			get() {
				return this.value;
			},
			set(checkboxValue) {
				this.checkboxEmitValue = checkboxValue;
			},
		},
	},

	methods: {
		handleInput: function() {
			this.$emit("input", this.checkboxEmitValue);
		},
	},
};
</script>

<style scoped>
.vue-checkbox {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 0;
}

.checkbox {
	background-color: transparent;
	border: 2px solid;
	border-radius: 3px;
	cursor: pointer;
	width: 18px;
	height: 18px;
	min-width: 18px;
	min-height: 18px;
	margin: 0 10px 0 0;
	position: relative;
}

.checkbox:after {
	border: 2px solid var(--c-white);
	border-top: none;
	border-right: none;
	content: "";
	height: 6px;
	opacity: 0;
	position: absolute;
	top: 2px;
	left: 2px;
	transform: rotate(-45deg);
	width: 10px;
}

input[type="checkbox"] {
	opacity: 0;
	height: 20px;
	margin: 0;
	width: 0;
}

.text {
	margin-bottom: 0;
	width: 100%;
}

.text p {
	font-size: 14px;
	font-weight: normal;
	margin-bottom: 0;
}

.primary .checkbox {
	border-color: var(--c-blue);
}
.warning .checkbox {
	border-color: var(--c-peach);
}
.submit .checkbox {
	border-color: var(--c-green);
}
.negative .checkbox {
	border-color: var(--c-orange);
}

input[type="checkbox"]:checked + .checkbox:after {
	opacity: 1;
}
input[type="checkbox"]:disabled:not(:checked) + .checkbox {
	border-color: var(--c-lighter-gray);
}

.primary input[type="checkbox"]:checked + .checkbox {
	background-color: var(--c-blue);
}
.warning input[type="checkbox"]:checked + .checkbox {
	background-color: var(--c-peach);
}
.submit input[type="checkbox"]:checked + .checkbox {
	background-color: var(--c-green);
}
.negative input[type="checkbox"]:checked + .checkbox {
	background-color: var(--c-orange);
}
</style>
