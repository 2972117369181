import rootInstance from "@/services/root.js";

const summaryApi = () => {
	rootInstance.defaults.baseURL =
		process.env.MIX_REVIEW_CENTER_DOCUMENT_API_BASE_URI + "/api/v1.0";
	return rootInstance;
};

export default {
	/**
	 * Get essay document summary
	 * @returns {AxiosPromise} - returns essay summary
	 */
	getSummary: (essayId) =>
		summaryApi()
			.get(`/essays/${essayId}/summary`)
			.then(({ data: { data } }) => data),
};
