<template>
	<div>
		<ServiceDashboardTutorCardHeaderSkeleton />
		<ServiceDashboardListSkeleton v-if="isSectionWithSessions">
			<template
				#left
			>
				Ongoing Sessions
			</template>
			<template
				#right
			>
				Total Sessions
			</template>
		</ServiceDashboardListSkeleton>
		<ServiceDashboardListSkeleton
			v-if="isSectionWithEssays"
			resource="essay"
		>
			<template
				#left
			>
				Essay
			</template>
			<template
				#right
			>
				Total Reviewed Essays
			</template>
		</ServiceDashboardListSkeleton>
	</div>
</template>

<script>
import ServiceDashboardTutorCardHeaderSkeleton from "./ServiceDashboardTutorCardHeaderSkeleton.vue";
import ServiceDashboardListSkeleton from "./ServiceDashboardListSkeleton.vue";
export default {
	components: {
		ServiceDashboardTutorCardHeaderSkeleton,
		ServiceDashboardListSkeleton,
	},
	props: {
		sectionTitle: {
			type: String,
			required: true,
		},
	},
	computed: {
		isSectionWithSessions() {
			return this.sectionTitle === "Live Tutors";
		},
		isSectionWithEssays() {
			return this.sectionTitle === "Live Tutors" || this.sectionTitle === "Review Center Tutors";
		},
	},
};
</script>
