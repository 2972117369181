<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		:width="width"
		:height="height"
		viewBox="0 0 12 9"
	>
		<defs>
			<path
				:id="randomId"
				d="M11.618 2.25c-.005.005-.012.007-.017.012l.003.002-5.807 5.899a1.37 1.37 0 0 1-.968.406c-.353.01-.709-.11-.984-.368-.004-.004-.006-.01-.011-.015l-.002.002L.419 4.995a1.307 1.307 0 0 1-.039-1.88 1.39 1.39 0 0 1 1.928-.039l2.454 2.298L9.637.423l.002.003c.005-.005.006-.012.011-.017A1.388 1.388 0 0 1 11.578.37c.544.51.562 1.35.04 1.88"
			/>
		</defs>
		<g
			fill="none"
			fill-rule="evenodd"
		>
			<mask
				:id="`a${randomId}`"
				fill="#fff"
			>
				<use :xlink:href="`#${randomId}`" />
			</mask>
			<use
				:fill="iconColor"
				:xlink:href="`#${randomId}`"
			/>
			<g :mask="`url(#a${randomId})`">
				<path d="M-3.428-4.285h18.854v17.14H-3.428z" />
			</g>
		</g>
	</svg>
</template>

<script>
import IconId from "./IconId.js";
export default {
	extends: IconId,
	props: {
		width: {
			type: [Number, String],
			default: 12,
		},
		height: {
			type: [Number, String],
			default: 9,
		},
		iconColor: {
			type: String,
			default: "#6B6A80",
		},
	},
};
</script>
