var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "58",
        height: "22",
        viewBox: "0 0 58 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M48 0H10C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10V12C0 13.3132 0.258658 14.6136 0.761205 15.8268C1.26375 17.0401 2.00035 18.1425 2.92893 19.0711C4.8043 20.9464 7.34784 22 10 22H48C49.3132 22 50.6136 21.7413 51.8268 21.2388C53.0401 20.7362 54.1425 19.9997 55.0711 19.0711C55.9997 18.1425 56.7362 17.0401 57.2388 15.8268C57.7413 14.6136 58 13.3132 58 12V10C58 7.34784 56.9464 4.8043 55.0711 2.92893C53.1957 1.05357 50.6522 0 48 0ZM10 2H48C50.1217 2 52.1566 2.84285 53.6569 4.34315C55.1571 5.84344 56 7.87827 56 10V12C56 14.1217 55.1571 16.1566 53.6569 17.6569C52.1566 19.1571 50.1217 20 48 20H10C7.87827 20 5.84344 19.1571 4.34315 17.6569C2.84285 16.1566 2 14.1217 2 12V10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2Z",
          fill: "white",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M7.17 5.1001H12.51C15.3 5.1001 17.59 5.8501 17.59 9.0401C17.59 12.3901 15.11 13.4601 11.64 13.4601H9.84L9.21 17.0001H5.08L7.17 5.1001ZM11.47 10.5701C12.69 10.5701 13.36 10.2701 13.36 9.1501C13.36 8.1501 12.76 8.0201 11.88 8.0201H10.79L10.35 10.5701H11.47Z",
          fill: "white",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M24.58 15.1301H20.89L20.06 17.0001H15.59L21.7 5.1001H27.11L29 17.0001H24.76L24.58 15.1301ZM24.29 12.1301L24.15 10.6301C24.08 10.0001 24 9.0001 24 8.1301L23 10.5901L22.34 12.0901L24.29 12.1301Z",
          fill: "white",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M29.58 5.1001H33.88L34.34 10.4901C34.45 11.9501 34.52 13.0401 34.56 13.5701C34.77 13.0701 35.36 11.5701 35.91 10.3901L38.22 5.1001H42.7L36.7 17.0001H31.36L29.58 5.1001Z",
          fill: "white",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M43.55 5.1001H53.38L52.85 8.1001H47.22L47 9.6101H52L51.49 12.4301H46.49L46.18 14.0001H51.79L51.27 17.0001H41.46L43.55 5.1001Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }