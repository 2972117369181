<template>
	<BModal
		id="tutorDashboardAcceptedShiftTransferModal"
		content-class="bootstrapFourModalContent"
		body-class="bootstrapFourModalBody"
		footer-class="bootstrapFourModalFooter"
		:hide-header="true"
	>
		<button
			class="bootstrapFourModalCloseButton"
			@click="hideModal()"
		>
			&times;
		</button>
		<div class="acceptedShiftTransferModal__header">
			<IconCircleCheckMark class="checkMarkSvg" />
			<div class="acceptedShiftTransferModal__title">
				Shift transfer accepted
			</div>
		</div>
		<div class="acceptedShiftTransferModal__content">
			<div class="acceptedShiftTransferModal__text">
				Your new shift was added to your schedule
			</div>
			<div class="acceptedShiftTransferModal__text">
				You have successfully accepted the following shift:
			</div>
			<div class="acceptedShiftTransferModal__shiftDetails">
				<div
					class="acceptedShiftTransferModal__text acceptedShiftTransferModal__text--greenSmaller"
				>
					{{ shiftDate }}
				</div>
				<div
					class="acceptedShiftTransferModal__text acceptedShiftTransferModal__text--greenSmaller"
				>
					{{ shiftStartTime }} to {{ shiftEndTime }}
				</div>
			</div>
		</div>
		<template slot="modal-footer">
			<BaseButton
				type="CANCEL"
				@click.native="hideModal()"
			>
				<template slot="text">
					View Schedule
				</template>
			</BaseButton>
		</template>
	</BModal>
</template>

<script>
import { format } from "date-fns";

import IconCircleCheckMark from "@/components/icons/IconCircleCheckMark.vue";
import BaseButton from "@/components/BaseButton.vue";

export default {
	components: {
		IconCircleCheckMark,
		BaseButton,
	},
	props: {
		shift: {
			type: Object,
			required: false,
			default: () => ({}),
		},
	},
	computed: {
		/**
		 * @returns {Boolean}
		 */
		isShiftPropPresent() {
			return this.shift !== null && this.shift.hasOwnProperty("start_time");
		},
		/**
		 * @returns {String}
		 */
		shiftDate() {
			if (this.isShiftPropPresent) {
				return format(new Date(this.shift.start_time * 1000), "EEEE, MMMM d, yyyy");
			}
			return "";
		},
		/**
		 * @returns {String}
		 */
		shiftStartTime() {
			if (this.isShiftPropPresent) {
				return format(new Date(this.shift.start_time * 1000), "h:mm a");
			}
			return "";
		},
		/**
		 * @returns {String}
		 */
		shiftEndTime() {
			if (this.isShiftPropPresent) {
				return format(new Date(this.shift.end_time * 1000), "h:mm a");
			}
			return "";
		},
	},
	methods: {
		hideModal() {
			this.$bvModal.hide("tutorDashboardAcceptedShiftTransferModal");
			this.$router.replace("/tutor");
		},
	},
};
</script>

<style scoped>
.acceptedShiftTransferModal__header {
	justify-content: flex-start;
	display: flex;
	align-items: center;
	border: none;
	padding: 0;
}
.checkMarkSvg {
	margin-right: 16px;
}
.acceptedShiftTransferModal__title {
	font-size: 20px;
	font-weight: 600;
	color: var(--c-blue);
}
.acceptedShiftTransferModal__content {
	font-size: 14px;
	color: var(--c-black);
	margin-top: 26px;
}
.acceptedShiftTransferModal__text {
	font-size: 16px;
	font-weight: normal;
	color: var(--c-black);
	margin: 6px 0;
}
.acceptedShiftTransferModal__text--greenSmaller {
	color: #8dca85;
	font-size: 14px;
	font-weight: 600;
}
.acceptedShiftTransferModal__shiftDetails {
	margin: auto;
	margin-top: 16px;
	padding: 10px;
	width: max-content;
	border-radius: 5px;
	border: solid 2px #8dca85;
	text-align: center;
	color: #8dca85;
}
.bootstrapFourModalCloseButton {
	border: none;
	padding: 0px;
	position: absolute;
	font-size: 30px;
	line-height: 15px;
	text-align: center;
	right: 24px;
	top: 24px;
	font-weight: 400;
	opacity: 0.6;
}
:deep(.bootstrapFourModalContent) {
	border-radius: 10px;
	background-color: var(--c-white);
}
:deep(.bootstrapFourModalBody) {
	padding: 48px;
	position: relative;
}
:deep(.bootstrapFourModalFooter) {
	padding: 20px 48px;
	background-color: var(--c-light-gray);
}

</style>
