import rootInstance from "@/services/root.js";

const reviewCenterApi = () => {
	rootInstance.defaults.baseURL =
		process.env.MIX_REVIEW_CENTER_API_BASE_URI + "/api/v1";
	return rootInstance;
};

export default {
	submitAnnotations: (essayId, annotationString) =>
		reviewCenterApi()
			.post(`/essays/${essayId}/comments`, {
				annotations: annotationString,
			}),
	getCategories: () =>
		reviewCenterApi()
			.get("categories")
			.then(({ data: { data } }) => data),
	getTutorSettings: () =>
		reviewCenterApi()
			.get("tutor-settings")
			.then(({ data: { data } }) => data),
	updateTutorSettings: (settings) =>
		reviewCenterApi()
			.patch("tutor-settings", settings)
			.then(({ data: { data } }) => data),
};
