import { isNil } from "lodash";

import { pascalCase } from "./strings.js";

export default {
	/**
	 * Changes all the topic.name strings in a session object to the format needed for translations.
	 * @param {Object} session
	 * @returns {Object}
	 */
	formatAllTopicStringsInASession(session) {
		const formattedSession = session;
		!isNil(formattedSession.requested_topic)
			? (formattedSession.requested_topic.name = pascalCase(
				formattedSession.requested_topic.name,
			))
			: null;

		!isNil(formattedSession.requested_subject)
			? (formattedSession.requested_subject.name = pascalCase(
				formattedSession.requested_subject.name,
			))
			: null;

		!isNil(formattedSession.topic)
			? (formattedSession.topic.name = pascalCase(
				formattedSession.topic.name,
			))
			: null;

		!isNil(formattedSession.topics)
			? formattedSession.topics.forEach(
				(topic, index) =>
					(formattedSession.topics[index].name = pascalCase(topic.name)),
			)
			: null;

		!isNil(formattedSession.subject)
			? (formattedSession.subject.name = pascalCase(
				formattedSession.subject.name,
			))
			: null;

		!isNil(formattedSession.subjects)
			? formattedSession.subjects.forEach(
				(subject, index) =>
					(formattedSession.subjects[index].name = pascalCase(
						subject.name,
					)),
			)
			: null;
		return formattedSession;
	},
};
