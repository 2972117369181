var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "serviceDashboard" },
    [
      _vm.PlatformManager.ServiceSummaryDashboard.summary
        ? _c(
            "ServiceDashboardSection",
            {
              attrs: { "section-title": "At a Glance" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "titleButtons",
                    fn: function () {
                      return [
                        _c(
                          "BasePill",
                          {
                            directives: [
                              {
                                name: "data-cy",
                                rawName: "v-data-cy",
                                value: "essay-review-24hour-toggle",
                                expression: "'essay-review-24hour-toggle'",
                              },
                            ],
                            staticClass: "summaryPeriodToggle",
                            attrs: {
                              "background-color": _vm.isSummaryPeriodToday
                                ? "transparent"
                                : _vm.$root.$colors.C_BLACK,
                              "text-color": _vm.isSummaryPeriodToday
                                ? _vm.$root.$colors.C_BLACK
                                : _vm.$root.$colors.C_WHITE,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.setSummaryPeriod("24hour")
                              },
                            },
                          },
                          [
                            _vm._t("default", function () {
                              return [_vm._v("Last 24h")]
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "BasePill",
                          {
                            directives: [
                              {
                                name: "data-cy",
                                rawName: "v-data-cy",
                                value: "essay-review-today-toggle",
                                expression: "'essay-review-today-toggle'",
                              },
                            ],
                            staticClass: "summaryPeriodToggle",
                            attrs: {
                              "background-color": _vm.isSummaryPeriodToday
                                ? _vm.$root.$colors.C_BLACK
                                : "transparent",
                              "text-color": _vm.isSummaryPeriodToday
                                ? _vm.$root.$colors.C_WHITE
                                : _vm.$root.$colors.C_BLACK,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.setSummaryPeriod("today")
                              },
                            },
                          },
                          [
                            _vm._t("default", function () {
                              return [_vm._v("Today (EST)")]
                            }),
                          ],
                          2
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            },
            [
              _vm._v(" "),
              _vm._t("default", function () {
                return [
                  _c("ServiceDashboardSummaryCardLiveHelp", {
                    staticClass:
                      "tw-px-6 tw-py-4 tw-w-[calc(50%-16px)] tw-m-2 tw-min-h-[50px] tw-bg-origami-white",
                  }),
                  _vm._v(" "),
                  _c("ServiceDashboardSummaryCardEssayReview", {
                    staticClass:
                      "tw-px-6 tw-py-4 tw-w-[calc(50%-16px)] tw-m-2 tw-min-h-[50px] tw-bg-origami-white",
                  }),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("ServiceDashboardSessionLoad", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "service-dashboard-tutor-load-graph",
            expression: "'service-dashboard-tutor-load-graph'",
          },
        ],
      }),
      _vm._v(" "),
      _vm._l(_vm.sections, function (section) {
        return _c(
          "ServiceDashboardSection",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: section.testId,
                expression: "section.testId",
              },
            ],
            key: section.title,
            attrs: { "section-title": section.title, cards: section.cards },
          },
          [
            section.isLoading
              ? _vm._t("default", function () {
                  return _vm._l([1, 2, 3, 4], function (num) {
                    return _c(
                      "div",
                      { key: num, staticClass: "card" },
                      [
                        _c("ServiceDashboardTutorCardSkeleton", {
                          attrs: { "section-title": section.title },
                        }),
                      ],
                      1
                    )
                  })
                })
              : _vm.checkIfSectionEmpty(section)
              ? _vm._t("default", function () {
                  return [
                    _c("ServiceDashboardSectionEmptyState", {
                      attrs: { "section-title": section.title },
                    }),
                  ]
                })
              : _vm._t("default", function () {
                  return _vm._l(section.cards, function (card) {
                    return _c(section.component, {
                      key: card.user.id,
                      tag: "component",
                      staticClass: "card",
                      attrs: { "card-data": card },
                    })
                  })
                }),
          ],
          2
        )
      }),
      _vm._v(" "),
      _c("TutorQuickEdit", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "service-dashboard-profile-details-drilldown",
            expression: "'service-dashboard-profile-details-drilldown'",
          },
        ],
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }