var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("TutorBreakCard", {
    directives: [
      {
        name: "jest",
        rawName: "v-jest",
        value: "break-request-card",
        expression: "'break-request-card'",
      },
    ],
    attrs: { break: _vm.breakRequest, "is-loading-state": _vm.isLoadingState },
    nativeOn: {
      click: function ($event) {
        $event.preventDefault()
        return _vm.getBreaksForSelectedTime(
          _vm.breakRequest.start_time,
          _vm.breakRequest.end_time
        )
      },
    },
    scopedSlots: _vm._u([
      {
        key: "metaDataIcon",
        fn: function () {
          return [
            _c(_vm.getOriginalScheduleType(), {
              directives: [
                {
                  name: "data-cy",
                  rawName: "v-data-cy",
                  value: _vm.getOriginalScheduleType(),
                  expression: "getOriginalScheduleType()",
                },
              ],
              tag: "component",
              staticClass: "tw-mr-2 tw-align-middle tw-text-black-high",
              attrs: { "icon-color": "currentColor" },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "metadata",
        fn: function () {
          return [
            _vm._v(
              "\n\t\t" +
                _vm._s(
                  _vm.getTimeRange(
                    _vm.breakRequest.originalSchedule.data.start_time,
                    _vm.breakRequest.originalSchedule.data.end_time
                  )
                ) +
                "\n\t\t"
            ),
            _c("span", { staticClass: "tw-mx-1" }, [_vm._v("|")]),
            _vm._v("\n\t\t" + _vm._s(_vm.getTimeElapsed()) + " elapsed\n\t"),
          ]
        },
        proxy: true,
      },
      {
        key: "breakDetailsIcon",
        fn: function () {
          return [_c("OrigamiIcon", { attrs: { name: "meal-break" } })]
        },
        proxy: true,
      },
      {
        key: "breakDetails",
        fn: function () {
          return [
            _vm._v(
              "\n\t\t" +
                _vm._s(
                  _vm.getTimeRange(
                    _vm.breakRequest.start_time,
                    _vm.breakRequest.end_time
                  )
                ) +
                "\n\t"
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function () {
          return [
            _c(
              "BaseButton",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "reject-break-request",
                    expression: "'reject-break-request'",
                  },
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "reject-break-request",
                    expression: "'reject-break-request'",
                  },
                ],
                staticClass: "tw-mr-2",
                attrs: {
                  variants: ["mini"],
                  color: "orange",
                  disabled: _vm.isLoadingState,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.rejectBreakRequest.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\tReject\n\t\t")]
            ),
            _vm._v(" "),
            _c(
              "BaseButton",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "accept-break-request",
                    expression: "'accept-break-request'",
                  },
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "accept-break-request",
                    expression: "'accept-break-request'",
                  },
                ],
                attrs: {
                  variants: ["mini"],
                  color: "green",
                  disabled: _vm.isLoadingState,
                },
                nativeOn: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.approveBreakRequest.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\tAccept\n\t\t")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }