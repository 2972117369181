<template>
	<nav
		id="sidebar"
		class="sidebar"
		:class="{ 'toggled': props.isToggled }"
	>
		<SkipToMainContent
			class="skipToMain tw-border-b-2 tw-border-solid tw-border-origami-grey-100 hover:tw-shadow-origami-xl"
		/>
		<div class="tw-sticky tw-top-0">
			<ChallengesWidget
				v-show="!props.isToggled"
				v-jest="'challenges-widget'"
				class="tw-py-4"
			/>
			<span
				v-show="!props.isToggled"
				class="tw-absolute tw-left-0 tw-w-full tw-border-b-2 tw-border-solid tw-border-origami-grey-100 tw-inline-block"
			/>
			<SuspensePattern
				:is-showing-fallback="isLoading"
				class="tw-mb-4"
			>
				<Links
					v-jest="'nav-links'"
					:nav-links="reactiveNavLinks"
					class="tw-mt-4"
					:is-toggled="props.isToggled"
				/>
			</SuspensePattern>
			<span
				v-show="!props.isToggled"
				class="tw-absolute tw-left-0 tw-w-full tw-border-b-2 tw-border-solid tw-border-origami-grey-100 tw-inline-block"
			/>
			<div
				v-show="!props.isToggled"
				v-if="featureFlagGradeLevelToggleIsEnabled"
				v-jest="'grade-level'"
				class="tw-p-4"
			>
				<div
					v-for="level in gradeLevels"
					:key="level"
				>
					<input
						:id="level"
						type="radio"
						name="school"
						:class="'tw-mr-2' + educationLevel"
						:checked="level === educationLevel"
						@change="publishToMicroFrontEnds(
							MICRO_FRONTENDS_HOST_EVENTS.GRADE_LEVEL_UPDATE,
							{ gradeLevel: level },
						)"
					>
					<label
						:for="level"
						class="tw-mb-0"
					>{{ level }}</label>
				</div>
			</div>
		</div>
	</nav>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { getFlag } from "@paper-co/web-toolkit";

import SkipToMainContent from "@/components/patterns/SkipToMainContent.vue";
import useStore from "@/composables/useStore.js";
import ChallengesWidget from "@/modules/Challenges/components/WidgetSidebar.vue";
import SuspensePattern from "@/components/patterns/SuspensePattern.vue";
import { publishToMicroFrontEnds, MICRO_FRONTENDS_HOST_EVENTS } from "@/modules/MicroFrontend/utilities/index.js";
import { getGradeLevelName } from "@/utilities/grade.js";

import useNavLinks from "../composables/useNavLinks.js";

import Links from "./Links.vue";


const gradeLevels = ["elementary", "middle-school", "high-school"];
const { reactiveNavLinks, checkNavLinksFlags } = useNavLinks();

const props = defineProps({
	isToggled: {
		type: Boolean,
		required: true,
		default: false,
	},
});

const store = useStore();

const isLoading = ref(false);
const educationLevel = ref("");
const featureFlagGradeLevelToggleIsEnabled = ref(false);
const gradesList = ref([]);

watch(
	() => store.state.Student.Profile.profile.grade?.name,
	(gradeName) => {
		educationLevel.value = getGradeLevelName(
			gradesList.value,
			gradeName,
		);
	},
	{ immediate: true },
);

onMounted(async() => {
	isLoading.value = true;

	try {
		featureFlagGradeLevelToggleIsEnabled.value = await getFlag("dashboard-v-2-grade-level-toggle");
		gradesList.value = await store.dispatch("Student/Profile/getGrades");
		educationLevel.value = getGradeLevelName(
			gradesList.value,
			store.state.Student.Profile.profile.grade?.name,
		);
		await checkNavLinksFlags();
	} catch (error) {
		Sentry.captureException(error);
	} finally {
		isLoading.value = false;
	}
});

defineExpose({
	checkNavLinksFlags,
});
</script>

<style scoped>
.sidebar {
	@apply tw-bg-origami-white tw-hidden md:tw-block tw-drop-shadow-xl tw-border-r tw-border-solid tw-border-origami-grey-200 tw-z-20 tw-relative;
	transition: all 0.3s ease;
	min-width: 250px;
	min-height: 100%;
}
.sidebar.toggled {
	transform: translateX(-170px);
	margin-right: -170px;
}
.sidebar:before {
	position: absolute;
	content: "";
	width: 100vw;
	height: 100%;
	left: -100vw;
	top: 0;
	background: var(--origami-white);
}
.sidebar.toggled .skipToMain:focus,
.sidebar.toggled .skipToMain:focus-visible {
	transform: translateX(170px);
}
</style>
