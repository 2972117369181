import api from "./Api.js";

export default {
	/**
	 * creates a shift transfer
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	create(params) {
		return api().post(`transfers`, params);
	},
	createAsync(params) {
		return api().post(`shift-segment-transfers`, params);
	},
	/**
	 * updates status of shift
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	update(id, params) {
		return api().post(`transfers/${id}`, params);
	},
};
