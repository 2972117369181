import { getTime } from "date-fns";

import { SectionsAPI } from "@/modules/Customers/index.js";

import EssaysAPI from "../../../services/api/Essays.js";

const getDefaultState = () => ({
	isShowingNewEssayForm: false,
	isEssayUploadedAndNotSubmitted: false,
	essayUploadProgress: 0,
	uploadedEssay: null,
	essays: [],
	filter: "all",
	sections: [],
	submissionTypes: [],
	toast: null,
	isShowingFeedbackCTA: true,
});

const state = getDefaultState();

const getters = {
	filteredEssays(state) {
		if (state.filter === "all") {
			return state.essays;
		}

		if (state.filter === "reviewed") {
			return state.essays.filter((item) => ["downloaded", "reviewed"].includes(item.status));
		}

		return state.essays.filter((item) => item.status === state.filter);
	},
};

const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_SECTIONS(state, payload) {
		state.sections = payload.sections;
	},
	SET_ESSAYS(state, payload) {
		state.essays = payload.essays;
	},
	SET_FILTER(state, payload) {
		state.filter = payload.filter;
	},
	SET_ESSAY_UPLOAD_PROGRESS(state, payload) {
		state.essayUploadProgress = payload.essayUploadProgress;
	},
	SET_IS_SHOWING_NEW_ESSAY_FORM(state, payload) {
		state.isShowingNewEssayForm = payload.isShowingNewEssayForm;
	},
	SET_UPLOADED_ESSAY(state, payload) {
		state.uploadedEssay = payload.uploadedEssay;
	},
	SET_IS_ESSAY_UPLOADED_AND_NOT_SUBMITTED(state, payload) {
		state.isEssayUploadedAndNotSubmitted = payload.isEssayUploadedAndNotSubmitted;
	},
	SET_SUBMISSION_TYPES(state, payload) {
		state.submissionTypes = payload;
	},
	ADD_TOAST(state, toast) {
		state.toast = toast;
	},
	SET_IS_SHOWING_FEEDBACK_CTA(state, payload) {
		state.isShowingFeedbackCTA = payload.isShowingFeedbackCTA;
	},
};

const actions = {
	async getStudentEssays({ commit }) {
		const params = {
			include: "student,reviewSubmissionType",
		};
		try {
			const { data } = await EssaysAPI.getStudentEssays(params);
			commit("SET_ESSAYS", { essays: data.data });
			return Promise.resolve(data.data);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	async createEssay({ commit }, payload) {
		try {
			const { data } = await EssaysAPI.create(payload, (p) => {
				if (p.lengthComputable) {
					commit("SET_ESSAY_UPLOAD_PROGRESS", {
						essayUploadProgress: (p.loaded / p.total) * 100,
					});
				}
			});
			commit("ADD_TOAST", { label: "Work submitted" });
			return Promise.resolve(data.data);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	async getEssay({ commit }, payload) {
		const params = {
			include: "student,language,section,reviewSubmissionType,teacherInstructionFiles,feedback",
		};
		try {
			const { data } = await EssaysAPI.show(payload.essayId, params);
			const updatedEssays = state.essays.map((essay) => essay.id === payload.essayId ? data.data : essay);
			commit("SET_ESSAYS", { essays: updatedEssays });
			return Promise.resolve(data.data);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	async updateEssayAfterDownloading({ commit, state }, payload) {
		try {
			const downloadedAt = Math.floor(getTime(new Date()) / 1000);
			const { data } = await EssaysAPI.update(payload.essayId, {
				downloaded_at: downloadedAt,
			});
			const updatedEssays = state.essays.map((essay) => {
				if (essay.id === payload.essayId) {
					return {
						...essay,
						downloaded_at: downloadedAt,
						status: "downloaded",
					};
				}

				return essay;
			});
			commit("SET_ESSAYS", { essays: updatedEssays });
			return Promise.resolve(data);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	async cancelEssay({ commit, state }, payload) {
		try {
			const { data } = await EssaysAPI.cancel(payload.essayId);
			const currentEssays = state.essays.filter(({ id }) => id !== payload.essayId);
			commit("SET_ESSAYS", { essays: currentEssays });
			return Promise.resolve(data);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	async getUserClassrooms({ rootState, commit }) {
		try {
			const params = {
				include: "course.subjects",
				show_all: true,
			};
			const { data } = await SectionsAPI.getUserSections(rootState.currentUser.id, params);
			let sections = [];

			if (data.data.length !== 0) {
				sections = [...data.data, { id: 0, name: "Other", course: { data: { name: "Other" } } }];
			}
			commit("SET_SECTIONS", {
				sections,
			});
			return Promise.resolve(sections);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	async getEstimatedReviewTime() {
		try {
			const { data } = await EssaysAPI.getEstimatedReviewTime();
			return Promise.resolve(data.data.estimated_review_time);
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	async getSubmissionTypes({ commit }) {
		try {
			const { data } = await EssaysAPI.getSubmissionTypes();
			commit("SET_SUBMISSION_TYPES", data.data);
			return Promise.resolve(data.data);
		} catch (error) {
			Sentry.captureException(error);
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
