var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "shift-status-background",
          expression: "'shift-status-background'",
        },
      ],
      staticClass:
        "tw-bg-origami-white tw-px-1 tw-inline-flex tw-rounded-[4px] tw-items-center",
      class: _vm.shiftStatus.backgroundColor,
    },
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "shift-status-text",
              expression: "'shift-status-text'",
            },
            {
              name: "data-cy",
              rawName: "v-data-cy",
              value: "shift-status-text",
              expression: "'shift-status-text'",
            },
          ],
          staticClass: "tw-font-bold tw-text-sm tw-mb-px",
          class: _vm.shiftStatus.color,
        },
        [_vm._v(_vm._s(_vm.shiftStatus.text))]
      ),
      _vm._v(" "),
      _c("span", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "shift-status-dot",
            expression: "'shift-status-dot'",
          },
        ],
        staticClass: "tw-w-2 tw-h-2 tw-rounded-full tw-ml-1",
        class: _vm.shiftStatus.dotColor,
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }