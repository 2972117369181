var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isPageLoading
        ? _c(
            "div",
            {
              staticClass:
                "tw-w-full tw-h-screen tw-flex tw-flex-col tw-items-center tw-p-8 tw-tracking-wide tw-border-b-2 tw-border-grey-pale",
            },
            [
              _c("BaseLoader", {
                staticClass: "tw-h-16 tw-w-16 tw-text-blue-regular tw-mb-4",
              }),
            ],
            1
          )
        : _c("OrigamiGrid", { staticClass: "tw-mx-4 lg:tw-mx-0" }, [
            _c(
              "div",
              {
                staticClass: "tw-w-full",
                attrs: {
                  "column-start": "lg:3",
                  "column-span": "4 md:12 lg:8",
                },
              },
              [
                _c("SubmissionFormHeader"),
                _vm._v(" "),
                _c("SubmissionFormUpload", {
                  attrs: {
                    "uploaded-essay": _vm.uploadedEssay,
                    "essay-file-doc-validator": _vm.essayFileDocValidator,
                    "essay-file-mime-types": _vm.essayFileMimeTypes,
                    "file-upload-error": _vm.fileUploadError,
                    embedded: _vm.embedded,
                    "handle-essay-file-input": _vm.handleEssayFileInput,
                    "handle-file-upload-error": _vm.handleFileUploadError,
                    "handle-google-picker-essay-file-input":
                      _vm.handleGooglePickerEssayFileInput,
                    "open-google-picker": _vm.openGooglePicker,
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "tw-text-lg tw-font-bold tw-my-4" }, [
                  _vm._v("\n\t\t\t\t" + _vm._s(_vm.$t("newWork")) + "\n\t\t\t"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "tw-mb-4" }, [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.$t("submissionDetailsTitle")) +
                      "\n\t\t\t"
                  ),
                ]),
                _vm._v(" "),
                !_vm.embedded
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "submissionDetailsText",
                            expression: "'submissionDetailsText'",
                          },
                        ],
                        staticClass: "tw-mb-4",
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t" +
                            _vm._s(_vm.$t("submissionDetailsText")) +
                            "\n\t\t\t"
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "form",
                  [
                    _c(
                      "label",
                      {
                        staticClass: "tw-text-sm tw-font-bold",
                        attrs: { for: "essayTitle" },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.$t("titleOfYourUploadedWork")) +
                            " *\n\t\t\t\t"
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("BaseInput", {
                      directives: [
                        {
                          name: "data-cy",
                          rawName: "v-data-cy",
                          value: "essay-title",
                          expression: "'essay-title'",
                        },
                      ],
                      attrs: {
                        type: "text",
                        variant: _vm.isTitleErrorShowing
                          ? "warning"
                          : "default",
                        placeholder: _vm.$t("essayTitlePlaceholder"),
                      },
                      model: {
                        value: _vm.essayTitle,
                        callback: function ($$v) {
                          _vm.essayTitle = $$v
                        },
                        expression: "essayTitle",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isTitleErrorShowing,
                            expression: "isTitleErrorShowing",
                          },
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "title-error",
                            expression: "'title-error'",
                          },
                        ],
                        staticClass:
                          "tw-text-sm tw-font-bold tw-text-origami-red-400",
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.$t("titleSelectionRequired")) +
                            "\n\t\t\t\t"
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "tw-items-end lg:tw-flex lg:tw-flex-row lg:tw-grid lg:tw-grid-cols-12 tw-gap-4 tw-mb-2 tw-pt-4",
                      },
                      [
                        _c("SubmissionFormSubmissionType", {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: "submission-type-dropdown",
                              expression: "'submission-type-dropdown'",
                            },
                          ],
                          staticClass: "lg:tw-flex-1 lg:tw-mb-0 tw-mb-4",
                          class: _vm.isClassroomsEmpty
                            ? "tw-col-span-6"
                            : "tw-col-span-4",
                          attrs: { error: _vm.isSubmissionTypeErrorShowing },
                          model: {
                            value: _vm.submissionType,
                            callback: function ($$v) {
                              _vm.submissionType = $$v
                            },
                            expression: "submissionType",
                          },
                        }),
                        _vm._v(" "),
                        !_vm.isClassroomsEmpty
                          ? _c("SubmissionFormClassroom", {
                              directives: [
                                {
                                  name: "jest",
                                  rawName: "v-jest",
                                  value: "classroom-dropdown",
                                  expression: "'classroom-dropdown'",
                                },
                              ],
                              staticClass:
                                "lg:tw-flex-1 lg:tw-mb-0 tw-mb-4 tw-col-span-4",
                              attrs: { error: _vm.isClassroomErrorShowing },
                              model: {
                                value: _vm.selectedClassroom,
                                callback: function ($$v) {
                                  _vm.selectedClassroom = $$v
                                },
                                expression: "selectedClassroom",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("SubmissionFormLanguage", {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: "language-dropdown",
                              expression: "'language-dropdown'",
                            },
                          ],
                          staticClass: "lg:tw-flex-1",
                          class: _vm.isClassroomsEmpty
                            ? "tw-col-span-6"
                            : "tw-col-span-4",
                          attrs: { error: _vm.isLanguageErrorShowing },
                          model: {
                            value: _vm.essayLanguage,
                            callback: function ($$v) {
                              _vm.essayLanguage = $$v
                            },
                            expression: "essayLanguage",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("InstructionFilesUpload", {
                      attrs: {
                        "doc-validator": _vm.essayFileDocValidator,
                        "instruction-files": _vm.teacherInstructionFiles,
                        "instruction-file-mime-types":
                          _vm.instructionsFileMimeTypes,
                        embedded: _vm.embedded,
                        "file-errors": _vm.instructionFilesErrors,
                      },
                      on: {
                        "set-instruction-files": _vm.setTeacherInstructionFiles,
                        "update-instructions": _vm.updateWrittenInstructions,
                        "set-errors": _vm.updateInstructionFilesErrors,
                        "clear-errors": function ($event) {
                          _vm.instructionErrors = []
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("SubmissionFormQuestions", {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "questions",
                          expression: "'questions'",
                        },
                      ],
                      attrs: {
                        "is-essay-comments-error-showing":
                          _vm.isEssayCommentsErrorShowing,
                      },
                      model: {
                        value: _vm.essayComments,
                        callback: function ($$v) {
                          _vm.essayComments = $$v
                        },
                        expression: "essayComments",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "essay-eta",
                        expression: "'essay-eta'",
                      },
                    ],
                    staticClass:
                      "essayReview__eta tw-w-full tw-text-sm tw-text-center tw-py-4 tw-px-5 tw-rounded-t-md tw-rounded-b-md tw-border-2 tw-border-blue-regular",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.$t("submissionReviewETA")) +
                        "\n\t\t\t\t"
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "essay-eta-string",
                            expression: "'essay-eta-string'",
                          },
                        ],
                        staticClass: "tw-font-bold",
                      },
                      [_vm._v(" " + _vm._s(_vm.estimatedReviewTimeString))]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isErrorsEmpty,
                        expression: "!isErrorsEmpty",
                      },
                    ],
                    staticClass: "alert alert-danger tw-mt-2",
                  },
                  [
                    _c(
                      "ul",
                      _vm._l(_vm.errors, function (error, index) {
                        return _c("li", { key: index }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t" + _vm._s(error) + "\n\t\t\t\t\t"
                          ),
                        ])
                      }),
                      0
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "submitButtons tw-flex tw-flex-row-reverse tw-h-10 tw-mt-10 tw-mb-6",
                  },
                  [
                    _c(
                      "OrigamiButton",
                      {
                        directives: [
                          {
                            name: "data-cy",
                            rawName: "v-data-cy",
                            value: "submit-essay-btn",
                            expression: "'submit-essay-btn'",
                          },
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "submit-essay-btn",
                            expression: "'submit-essay-btn'",
                          },
                        ],
                        staticClass: "tw-ml-2",
                        attrs: {
                          variant: "primary",
                          "is-loading": _vm.isSubmittingEssay,
                        },
                        nativeOn: {
                          click: function ($event) {
                            !_vm.isSubmittingEssay && _vm.submitEssayForReview()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              _vm.$t(_vm.embedded ? "submit" : "reviewMyEssay")
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "OrigamiButton",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "cancel-upload",
                            expression: "'cancel-upload'",
                          },
                        ],
                        attrs: { variant: "secondary" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.cancelNewEssay()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.$t(_vm.embedded ? "clear" : "cancel")) +
                            "\n\t\t\t\t"
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }