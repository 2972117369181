var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("label", { staticClass: "tw-text-sm tw-font-bold" }, [
        _vm._v("\n\t\t" + _vm._s(_vm.$t("chooseSection")) + " *\n\t"),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "BaseDropdown",
        {
          directives: [
            {
              name: "data-cy",
              rawName: "v-data-cy",
              value: "classroom-dropdown",
              expression: "'classroom-dropdown'",
            },
            {
              name: "jest",
              rawName: "v-jest",
              value: "classroom-dropdown",
              expression: "'classroom-dropdown'",
            },
          ],
          ref: "classroomsDropdown",
          staticClass: "essayReview__classroomDropdown",
          class: { warning: _vm.error },
          attrs: { items: _vm.sections },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ item }) {
                return [
                  _c(
                    "BaseRadio",
                    {
                      directives: [
                        {
                          name: "data-cy",
                          rawName: "v-data-cy",
                          value: `classroom-${item.id}`,
                          expression: "`classroom-${item.id}`",
                        },
                      ],
                      staticClass: "essayReview__classroomItem",
                      attrs: {
                        "radio-name": "classroom",
                        "radio-value": item.id,
                        "radio-id": `classroom-${item.id}`,
                      },
                      model: {
                        value: _vm.selectedClassroom,
                        callback: function ($$v) {
                          _vm.selectedClassroom = $$v
                        },
                        expression: "selectedClassroom",
                      },
                    },
                    [
                      _c("template", { slot: "text" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.getItemName(item)) +
                            "\n\t\t\t\t"
                        ),
                      ]),
                    ],
                    2
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "filters" }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.classroomDropdownText) + "\n\t\t"),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.error,
              expression: "error",
            },
            {
              name: "jest",
              rawName: "v-jest",
              value: "classroom-dropdown-error",
              expression: "'classroom-dropdown-error'",
            },
          ],
          staticClass: "tw-text-sm tw-font-bold tw-text-origami-red-400",
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.$t("chooseSection")) + "\n\t")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }