import SubjectsApi from "@/services/api/Subjects.js";

import TeacherContentAPI from "../../services/teacher-content.js";
import TeacherContentCategoriesAPI from "../../services/teacher-content-categories.js";
import TeacherContentTypesAPI from "../../services/teacher-content-types.js";

const INCLUDES = "subjects,category,type";

const getDefaultState = () => ({
	teacherContents: [],
	filters: [
		{
			value: "subject_ids",
			title: "Subject",
		},
		{
			value: "teacher_content_type_ids",
			title: "File Type",
		},
		{
			value: "teacher_content_category_ids",
			title: "Category",
		},
	],
	searchFilter: "",
	subjects: [],
	fileTypes: [],
	categories: [],
	lastSavedCategory: {},
	teacherContentsTableHeaders: [
		{
			value: "id",
			header: "ID",
			orderable: true,
			state: null,
		},
		{
			value: "title",
			header: "title",
			orderable: true,
			state: null,
		},
		{
			value: "subject_ids",
			header: "subject",
			orderable: true,
			state: null,
		},
		{
			value: "teacher_content_type_ids",
			header: "File Type",
			orderable: true,
			state: null,
		},
		{
			value: "priority",
			header: "priority",
			orderable: true,
			state: null,
		},
		{
			value: "teacher_content_category_ids",
			header: "category",
			orderable: true,
			state: null,
		},
		{
			value: "delete",
			header: "",
			orderable: false,
			state: null,
		},
	],
	pagination: {
		total_pages: 0,
		current_page: 1,
	},
	orderBy: "id.desc",
	selectedSubjects: [],
	selectedCategories: [],
	selectedFileTypes: [],
	appliedFilters: {
		teacher_content_category_id: [],
		teacher_content_type_id: [],
		subject_ids: [],
	},
	itemOnDelete: {},
	navbarMainTab: "Customers",
});

const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_TEACHER_CONTENTS(state, payload) {
		state.teacherContents = payload.teacherContents;
	},
	SET_SEARCH_FILTER(state, payload) {
		state.searchFilter = payload.searchFilter;
	},
	SPLICE_TEACHER_CONTENT(state, payload) {
		const indexOfItemToDelete = state.teacherContents.indexOf(
			payload.teacherContent,
		);
		state.teacherContents.splice(indexOfItemToDelete, 1);
	},
	UPDATE_HEADERS(state, payload) {
		state.teacherContentsTableHeaders = payload.teacherContentsTableHeaders;
	},
	SET_PAGINATION(state, payload) {
		state.pagination = payload.pagination;
	},
	RESET_PAGINATION(state) {
		state.pagination = { total_pages: 0, current_page: 0 };
	},
	SET_CURRENT_PAGE(state, payload) {
		state.pagination.current_page = payload.currentPage;
	},
	SET_ORDER_BY(state, payload) {
		state.orderBy = payload.orderBy;
	},
	SET_SUBJECTS(state, payload) {
		state.subjects = payload.subjects;
	},
	SET_FILE_TYPES(state, payload) {
		state.fileTypes = payload.fileTypes;
	},
	SET_CATEGORIES(state, payload) {
		state.categories = payload.categories;
	},
	SET_CATEGORY_NAME_IN_CATEGORIES(state, payload) {
		state.categories.forEach((category) => {
			if (category.id == payload.category.id) {
				category.name = payload.name;
			}
		});
	},
	SET_LAST_SAVED_CATEGORY(state, payload) {
		state.lastSavedCategory = payload.lastSavedCategory;
	},
	SET_CATEGORY_IN_CATEGORIES(state, payload) {
		state.categories.push(payload.category);
	},
	SET_SELECTED_SUBJECTS(state, payload) {
		state.selectedSubjects = payload.selectedSubjects;
	},
	SET_SELECTED_CATEGORIES(state, payload) {
		state.selectedCategories = payload.selectedCategories;
	},
	SET_SELECTED_FILE_TYPES(state, payload) {
		state.selectedFileTypes = payload.selectedFileTypes;
	},
	SET_APPLIED_FILTERS(state, payload) {
		state.appliedFilters = payload.appliedFilters;
	},
	SET_ITEM_ON_DELETE(state, payload) {
		state.itemOnDelete = payload.itemOnDelete;
	},
	SET_TABLE_HEADERS() {},
};

const actions = {
	async getTeacherContents({ commit }, payload) {
		const params = { ...payload, include: INCLUDES };
		const teacherContents = await TeacherContentAPI.list(params);
		commit("SET_TEACHER_CONTENTS", {
			teacherContents: teacherContents.data.data,
		});
		commit("SET_PAGINATION", {
			pagination: teacherContents.data.meta.pagination,
		});
	},

	async getSubjects({ commit }) {
		const response = await SubjectsApi.list();
		commit("SET_SUBJECTS", {
			subjects: response.data.data,
		});
	},

	async getFileTypes({ commit }) {
		const response = await TeacherContentTypesAPI.list();
		commit("SET_FILE_TYPES", {
			fileTypes: response.data.data,
		});
	},
	async setCurrentPage({ commit, state }, payload) {
		commit("SET_CURRENT_PAGE", {
			currentPage: payload.newPage,
		});

		const params = {
			include: INCLUDES,
			page: payload.newPage,
			orderBy: state.orderBy,
		};

		const response = await TeacherContentAPI.list(params);

		commit("SET_TEACHER_CONTENTS", {
			teacherContents: response.data.data,
		});
	},

	async getCategories({ commit }) {
		const response = await TeacherContentCategoriesAPI.list();
		commit("SET_CATEGORIES", {
			categories: response.data.data,
		});
	},
	async addCategory({ commit }, payload) {
		try {
			const response =
        await TeacherContentCategoriesAPI.createTeacherContentCategory(payload);
			commit("SET_CATEGORY_IN_CATEGORIES", {
				category: response.data.data,
			});
			Promise.resolve(response.data);
		} catch (e) {
			Promise.reject(e);
		}
	},
	async updateCategory({}, payload) {
		try {
			const response = await TeacherContentCategoriesAPI.update(
				payload.teacherContentCategoryId,
				payload.params,
			);
			return Promise.resolve(response);
		} catch (e) {
			return Promise.reject(e);
		}
	},

	async setOrderBy({ commit }, payload) {
		commit("SET_ORDER_BY", {
			orderBy: payload.orderBy,
		});

		commit("SET_CURRENT_PAGE", {
			currentPage: 1,
		});

		const params = { ...payload, include: INCLUDES };
		const response = await TeacherContentAPI.list(params);
		commit("SET_TEACHER_CONTENTS", {
			teacherContents: response.data.data,
		});
	},

	async deleteTeacherContent(_, payload) {
		try {
			const response = await TeacherContentAPI.delete(payload.id);
			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject(error);
		}
	},
};

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations,
	actions,
};
