<script>
import ServiceDashboardListAbstract from "./ServiceDashboardListAbstract.vue";
import ServiceDashboardListItemEssay from "./ServiceDashboardListItemEssay.vue";
export default {
	components: {
		ServiceDashboardListItemEssay,
	},
	extends: ServiceDashboardListAbstract,
	props: {
		totalEssays: {
			type: Number,
			required: true,
		},
		resources: {
			type: Array,
			required: true,
		},
	},
	computed: {
		totalResourcesCount() {
			return this.totalEssays;
		},
		componentType() {
			return "essay";
		},
	},
	methods: {
		/**
		 * @param {Object} item
		 * @returns {Component}
		 */
		getListComponent() {
			return ServiceDashboardListItemEssay;
		},
	},
};
</script>
