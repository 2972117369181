var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "preferredLanguage" }, [
    _c(
      "div",
      { staticClass: "preferredLanguage__header" },
      [
        _c("PreferredLanguageBubble", {
          staticClass: "languageIcon",
          attrs: { language: _vm.preferredLanguageKey },
        }),
        _vm._v(
          "\n\t\t" + _vm._s(_vm.studentPreferredLanguage) + " Speaker\n\t"
        ),
      ],
      1
    ),
    _vm._v(
      "\n\tStudent is more comfortable speaking " +
        _vm._s(_vm.studentPreferredLanguage) +
        ". Please adjust your approach\n\tto help them understand easily.\n"
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }