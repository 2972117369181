<script setup>
import { computed } from "vue";

const props = defineProps({
	filterTagName: {
		type: String,
		required: true,
	},
});

const isCountryTag = computed(() => props.filterTagName === "US");
</script>

<template>
	<div
		v-jest="'filter-tag'"
		data-cy="filter-tag"
		class="tw-rounded-md tw-py-0.5 tw-px-1 tw-mr-2 tw-whitespace-nowrap"
		:class="{
			'tw-bg-origami-blue-100': isCountryTag,
			'tw-bg-origami-pink-100': !isCountryTag,
		}"
	>
		<span
			v-jest="'filter-text'"
			class="tw-font-origami-bold tw-text-origami-sm tw-leading-none"
			:class="{
				'tw-text-origami-blue-300': isCountryTag,
				'tw-text-origami-pink-400': !isCountryTag,
			}"
		>
			{{ props.filterTagName }}
		</span>
	</div>
</template>
