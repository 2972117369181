var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "container hidden",
        style: { display: "block !important" },
      },
      [_vm._m(0)]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12", attrs: { id: "superuserDashboard" } },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c(
                "div",
                {
                  staticClass: "tw-mt-4",
                  attrs: { id: "superuserDashHeader" },
                },
                [
                  _c("h2", [_vm._v("Dashboard")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "timePeriods" }, [
                    _c("h5", [_vm._v("Period:")]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "selected",
                        attrs: { href: "#", "data-period": "crtWeek" },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\t\tThis Week\n\t\t\t\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      { attrs: { href: "#", "data-period": "lastWeek" } },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\t\tLast Week\n\t\t\t\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      { attrs: { href: "#", "data-period": "lastMonth" } },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\t\tLast Month\n\t\t\t\t\t\t\t\t"
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      { attrs: { href: "#", "data-period": "allTime" } },
                      [_vm._v("\n\t\t\t\t\t\t\t\t\tAll Time\n\t\t\t\t\t\t\t\t")]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "statbox" }, [
                _c("h3", { staticClass: "statsTitle" }, [
                  _vm._v("\n\t\t\t\t\t\t\t\tTotal Students\n\t\t\t\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("h2", { staticClass: "statsContent" }, [
                  _c("span", { attrs: { id: "totalStudents" } }, [
                    _vm._v("N/A"),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "statbox" }, [
                _c("h3", { staticClass: "statsTitle" }, [
                  _vm._v("\n\t\t\t\t\t\t\t\tNew Students\n\t\t\t\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("h2", { staticClass: "statsContent" }, [
                  _c("span", { attrs: { id: "newStudents" } }, [_vm._v("N/A")]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "statbox" }, [
                _c("h3", { staticClass: "statsTitle" }, [
                  _vm._v("\n\t\t\t\t\t\t\t\tNew Activations\n\t\t\t\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("h2", { staticClass: "statsContent" }, [
                  _c("span", { attrs: { id: "newActivations" } }, [
                    _vm._v("N/A"),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "statbox" }, [
                _c("h3", { staticClass: "statsTitle" }, [
                  _vm._v("\n\t\t\t\t\t\t\t\tSessions\n\t\t\t\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("h2", { staticClass: "statsContent" }, [
                  _c("span", { attrs: { id: "sessions" } }, [_vm._v("N/A")]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "statbox last" }, [
                _c("h3", { staticClass: "statsTitle" }, [
                  _vm._v("\n\t\t\t\t\t\t\t\tAverage Rating\n\t\t\t\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c("h2", { staticClass: "statsContent" }, [
                  _c("span", { attrs: { id: "avgRating" } }, [_vm._v("N/A")]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }