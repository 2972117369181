<template>
	<div :class="{ 'tw-shadow-elevation-4': !isFlat }">
		<div v-if="displayHeader">
			<h2 class="tw-font-bold tw-text-lg tw-pl-6 tw-pr-4 tw-py-4">
				Create New Shift
			</h2>
			<hr>
		</div>
		<div v-if="!newShift">
			<div class="tw-flex tw-items-center tw-px-6 tw-py-0">
				<ServiceScheduleTutorShiftForm
					:selected-tutor="selectedTutor"
					:is-editing="false"
				/>
			</div>
			<div class="tw-flex tw-justify-between tw-pb-4 tw-pt-0 tw-px-6">
				<OrigamiButton
					v-jest="'cancel-btn'"
					v-data-cy="'cancel-btn'"
					variant="secondary"
					@click.native="cancel()"
				>
					Cancel
				</OrigamiButton>
				<OrigamiButton
					v-jest="'create-btn'"
					v-data-cy="'create-btn'"
					variant="primary"
					@click.native="createShift()"
				>
					Save
				</OrigamiButton>
			</div>
			<ServiceScheduleTutorErrorMessage
				v-if="hasAnError"
				v-jest="'error-message'"
				:error="error"
			/>
		</div>
		<ServiceScheduleTutorSuccessfulAction
			v-else
			:shift="newShift"
			:success-message-in-sidebar="{
				message: 'You have successfully created a new shift.',
				buttonText: 'Complete',
			}"
			@cancel-edit="cancel()"
		/>
	</div>
</template>
<script>
import { OrigamiButton } from "@origami/vue2";
import { mapState } from "vuex";
import { isEmpty } from "lodash";

import { formatDateToTimestamp } from "@/utilities/dateHelpers.js";
import ScheduleTypeAPI from "@/services/api/ScheduleTypes.js";
import ServiceScheduleTutorSuccessfulAction from "@/modules/ServiceSchedule/components/ServiceScheduleTutorSuccessfulAction.vue";

import ServiceScheduleTutorShiftForm from "./ServiceScheduleTutorShiftForm.vue";
import ServiceScheduleTutorErrorMessage from "./ServiceScheduleTutorErrorMessage.vue";

export default {
	components: {
		ServiceScheduleTutorSuccessfulAction,
		OrigamiButton,
		ServiceScheduleTutorShiftForm,
		ServiceScheduleTutorErrorMessage,
	},
	props: {
		isFlat: {
			type: Boolean,
			default: false,
		},
		displayHeader: {
			type: Boolean,
			default: true,
		},
		selectedTutor: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			isSubmitting: false,
			error: null,
			newShift: null,
		};
	},
	computed: {
		...mapState(["PlatformManager"]),
		hasAnError() {
			return !isEmpty(this.error);
		},
	},
	async mounted() {
		this.getScheduleTypes();
	},
	methods: {
		async getScheduleTypes() {
			try {
				const response = await ScheduleTypeAPI.get();
				this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/setScheduleTypes", {
					scheduleTypesList: response.data.data,
				});
			} catch (e) {
				Sentry.captureException(e);
			}
		},
		getShiftData() {
			return {
				include: "scheduleType",
				start_time: formatDateToTimestamp(new Date(this.PlatformManager.ServiceTutorsSchedule.dateRange.start)),
				end_time: formatDateToTimestamp(new Date(this.PlatformManager.ServiceTutorsSchedule.dateRange.end)),
				user_id: this.selectedTutor.id,
				schedule_type_id: this.PlatformManager.ServiceTutorsSchedule.currentShiftType.id,
			};
		},
		async buildCurrentEvent(shift) {
			const response = await this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/getTutor", {
				id: this.selectedTutor.id,
				data: { include: "tutor.languages,tutor.subjects" },
			});
			return {
				...shift,
				user: { data: response.data },
			};
		},
		async buildShift(shiftData) {
			const response = await this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/createShift", shiftData);
			return this.buildCurrentEvent(response?.data);
		},
		cancel() {
			this.$emit("cancel-edit");
			this.error = null;
		},
		async createShift() {
			try {
				this.isSubmitting = true;
				const data = this.getShiftData();
				this.newShift = await this.buildShift(data);
				this.$emit("complete-creation");
			} catch (e) {
				this.error = e;
				Sentry.captureException(e);
			} finally {
				this.isSubmitting = false;
			}
		},
	},
};
</script>
