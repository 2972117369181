// Api
import { addHours, subHours } from "date-fns";
import { isNil } from "lodash";

import { ScheduleManagementApi, BIO_BREAK_IN_PROGRESS_ID, TUTOR_MIA_ID } from "@/modules/TutorSchedule/index.js";

import BreakRequestAPI from "../service/BreakRequest.js";
import SchedulesAPI from "../../../services/api/Schedules.js";
// Libraries
import { formatDateToTimestamp } from "../../../utilities/dateHelpers.js";

const REST_BREAK_ID = 11;
const MEAL_BREAK_ID = 12;

const getShiftGroupData = (schedule, segmentId) => {
	const shiftGroup = schedule.filter(({ segment_id }) => segment_id === segmentId);
	const workingBlocks = shiftGroup.filter(({ scheduleType }) =>
		![REST_BREAK_ID, MEAL_BREAK_ID].includes(scheduleType.data.id));

	return {
		...workingBlocks[0],
		end_time: workingBlocks.pop()?.end_time,
	};
};

const essayShiftBioBreaks = (state) => {
	const SCHEDULE_TYPE_IDS = {
		ESSAY: 2,
	};
	return state.currentBioBreaks.filter((essayBreakRequest) =>
		essayBreakRequest.state.current_segment.type.id === SCHEDULE_TYPE_IDS.ESSAY);
};

export const getScheduledBreaks = (schedule) => {
	return schedule
		.filter(({ scheduleType }) => [REST_BREAK_ID, MEAL_BREAK_ID].includes(scheduleType.data.id))
		.map((scheduledBreak) => ({
			...getShiftGroupData(schedule, scheduledBreak.segment_id),
			break: scheduledBreak,
		}));
};

const state = {
	breakRequests: [],
	approvedBreaks: [],
	currentBioBreaks: [],
	currentTime: formatDateToTimestamp(new Date()),
};

export const mutations = {
	SET_BREAK_REQUESTS(state, payload) {
		state.breakRequests = payload.breakRequests;
	},
	SET_APPROVED_BREAKS(state, payload) {
		state.approvedBreaks = payload.approvedBreaks;
	},
	REMOVE_REQUEST_FROM_LIST_OF_BREAK_REQUESTS(state, payload) {
		state.breakRequests = state.breakRequests.filter(
			(requestedBreak) => requestedBreak.id !== payload.breakId,
		);
	},
	REMOVE_REQUEST_FROM_LIST_OF_APPROVED_BREAK_REQUESTS(state, payload) {
		state.approvedBreaks = state.approvedBreaks.filter(
			(approvedBreak) => !payload.breakObject.deletedScheduleIds.includes(approvedBreak.break.id),
		);
	},
	ADD_NEW_BREAK_REQUEST(state, payload) {
		state.breakRequests = [...state.breakRequests, payload.breakRequest];
	},
	ADD_NEW_APPROVED_BREAK(state, payload) {
		state.approvedBreaks = [...state.approvedBreaks, payload.breakRequest];
	},
	SET_CURRENT_BIO_BREAKS(state, payload) {
		state.currentBioBreaks = payload.currentBioBreaks;
	},
	SET_CURRENT_TIME(state, payload) {
		state.currentTime = payload;
	},
};

const getters = {
	restBreakRequests(state) {
		return state.approvedBreaks.filter((shifts) =>
			shifts.break.schedule_type_id === REST_BREAK_ID || shifts.break.scheduleType?.data.id === REST_BREAK_ID,
		);
	},
	mealBreakRequests(state) {
		return state.breakRequests.filter((shifts) => shifts.schedule_type_id === MEAL_BREAK_ID);
	},
	numberOfMealBreaks(state, getters) {
		return getters.mealBreakRequests.length;
	},
	numberOfRestBreaks(state, getters) {
		return getters.restBreakRequests.filter((restBreak) => restBreak?.break?.end_time > state.currentTime)
			.length;
	},
	numberOfBioBreaks(state) {
		return state.currentBioBreaks.length;
	},
	currentRestBreaks(state, getters) {
		return getters.restBreakRequests.filter((restBreak) =>
			restBreak.break.start_time <= state.currentTime && restBreak.break.end_time > state.currentTime,
		);
	},
	currentMealBreaks(state) {
		return state.approvedBreaks.filter((approvedBreak) =>
			approvedBreak.break.start_time <= state.currentTime && approvedBreak.break.end_time > state.currentTime
				&& (approvedBreak.break.schedule_type_id === MEAL_BREAK_ID
				|| approvedBreak.break.scheduleType?.data.id === MEAL_BREAK_ID),
		);
	},
	upcomingMealBreaks(state) {
		return state.approvedBreaks.filter((approvedBreak) =>
			approvedBreak.break.start_time >= state.currentTime
			&& (approvedBreak.break.schedule_type_id === MEAL_BREAK_ID
			|| approvedBreak.break.scheduleType?.data.id === MEAL_BREAK_ID),
		);
	},
	upcomingRestBreaks(state, getters) {
		return getters.restBreakRequests.filter((restBreak) => restBreak.break.start_time >= state.currentTime);
	},
	isMealBreakRequestEmpty(state, getters) {
		return getters.mealBreakRequests.length === 0;
	},
	essayShiftMealBreakRequests(state) {
		const SCHEDULE_TYPE_IDS = {
			ESSAY: 2,
		};
		return state.breakRequests.filter((essayBreakRequest) =>
			essayBreakRequest.originalSchedule.data.scheduleType?.data.id === SCHEDULE_TYPE_IDS.ESSAY,
		);
	},
	lateBioBreaks(state) {
		return state.currentBioBreaks.filter((bioBreak) => bioBreak.state.id === TUTOR_MIA_ID);
	},
	activeBioBreaks(state) {
		return state.currentBioBreaks.filter((bioBreak) => bioBreak.state.id === BIO_BREAK_IN_PROGRESS_ID);
	},
	essayShiftLateBioBreaks(state) {
		return essayShiftBioBreaks(state).filter((bioBreak) => bioBreak.state.id === TUTOR_MIA_ID);
	},
	essayShiftActiveBioBreaks(state) {
		return essayShiftBioBreaks(state).filter((bioBreak) => bioBreak.state.id === BIO_BREAK_IN_PROGRESS_ID);
	},
};

export const actions = {
	setCurrentTime({ commit }) {
		commit("SET_CURRENT_TIME", formatDateToTimestamp(new Date()));
	},
	async getBreaksRequested({ commit }) {
		try {
			const response = await BreakRequestAPI.getBreakRequestsForTutors({
				params: {
					include: ["user", "originalSchedule"],
				},
			});
			commit("SET_BREAK_REQUESTS", {
				breakRequests: response.data.data,
			});
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	async getApprovedBreaks({ commit }) {
		try {
			const currentDate = new Date();
			const from = formatDateToTimestamp(subHours(currentDate, 2));
			const to = formatDateToTimestamp(addHours(currentDate, 10));
			const response = await SchedulesAPI.list({
				from,
				to,
				include: ["user"],
				segments: 1,
				schedule_type_ids: ["11", "12"],
			});
			commit("SET_APPROVED_BREAKS", {
				approvedBreaks: getScheduledBreaks(response.data.data),
			});
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	removeBreakRequest({ commit }, payload) {
		commit("REMOVE_REQUEST_FROM_LIST_OF_BREAK_REQUESTS", {
			breakId: payload.breakId,
		});
	},
	removeApprovedRequestedBreak({ commit }, payload) {
		commit("REMOVE_REQUEST_FROM_LIST_OF_APPROVED_BREAK_REQUESTS", {
			breakObject: payload.breakObject,
		});
	},
	showBreakRequest({ commit }, payload) {
		const breakRequest = {
			id: payload.breakRequest.id,
			schedule_type_id: payload.breakRequest.schedule_type_id,
			start_time: payload.breakRequest.start_time,
			end_time: payload.breakRequest.end_time,
			user: {
				data: payload.breakRequest.user,
			},
			originalSchedule: {
				data: {
					punch_in: payload.breakRequest.original_schedule.punch_in,
					start_time: payload.breakRequest.original_schedule.start_time,
					end_time: payload.breakRequest.original_schedule.end_time,
					scheduleType: {
						data: {
							id: payload.breakRequest.original_schedule.schedule_type.id,
						},
					},
				},
			},
		};
		commit("ADD_NEW_BREAK_REQUEST", {
			breakRequest: breakRequest,
		});
	},
	showApprovedBreakRequest({ commit }, payload) {
		const breakRequest = {
			break: payload.approvedBreak,
			user: {
				data: payload.approvedBreak?.user,
			},
			scheduleType: {
				data: {
					id: payload.scheduleTypeId,
				},
			},
			punch_in: payload.punch_in,
			start_time: payload.start_time,
			end_time: payload.end_time,
			segment_id: payload.approvedBreak?.segment_id,
		};
		commit("ADD_NEW_APPROVED_BREAK", {
			breakRequest: breakRequest,
		});
	},

	async getCurrentBioBreaks({ commit }) {
		try {
			const response = await ScheduleManagementApi.getCurrentBioBreaks();
			const shiftSegmentsWithABioBreak = response.filter((shift) => !isNil(shift.state?.current_modifier));
			commit("SET_CURRENT_BIO_BREAKS", {
				currentBioBreaks: shiftSegmentsWithABioBreak,
			});
		} catch (e) {
			Sentry.captureException(e);
		}
	},
};

export default { namespaced: true, state, mutations, getters, actions };
