<template>
	<div class="assessment">
		<SidebarTabs
			:tabs="[{ active: true, component: 'complete-assessment', name: 'complete_assessment' }]"
			font-color="#4a4a4a"
			sidebar-key="none"
			class="assessment__title--noPadding"
		/>
		<div
			class="assessment__contentContainer"
		>
			<RecognitionStep
				v-if="currentSession.assessment.step === ASSESSMENTS_STEPS.RECOGNITIONS"
				:key="currentSession.id"
				v-jest="'recognition-step'"
				:session="currentSession"
				@recognition-select="selectedRecognitionIds = $event"
				@input="recognitionsMessage = $event"
				@skip="skipRecognitionsStep()"
				@recognition-types-loading="isProcessingAssessment = true"
				@recognition-types-loaded="isProcessingAssessment = false"
			/>
			<QuestionStep
				v-else-if="currentSession.assessment.step === ASSESSMENTS_STEPS.QUESTION"
				v-jest="'assessment-question'"
				:is-non-academic="isNonAcademic"
				:session="currentSession"
				:student-name="studentName"
				:assessment-questions="$store.getters['Tutor/Session/assessmentQuestions']"
				@non-academic-check="isNonAcademic = $event"
			/>
			<TagsStep
				v-else-if="currentSession.assessment.step === ASSESSMENTS_STEPS.TAG"
				v-jest="'assessment-tags'"
				:session="currentSession"
				:subjects="subjectsArray"
				:tags="tagsArray"
				:session-tags="sessionTags"
				:search-text="searchText"
				@tags-update="tagsArray = $event"
				@subject-select="changeSubject"
				@tag-remove="removeTag"
				@search-text-input="searchText = $event"
				@tag-select="addTagToList"
				@subject-search-change="getAssessmentMap"
			/>
			<NonAcademicStep
				v-else-if="currentSession.assessment.step === ASSESSMENTS_STEPS.NON_ACADEMIC && isNonAcademic"
				:is-non-academic="isNonAcademic"
				:session="currentSession"
				@non-academic-check="isNonAcademic = $event"
			/>
			<FeedbackStep
				v-else-if="currentSession.assessment.step === ASSESSMENTS_STEPS.FEEDBACK"
				:student-name="studentName"
				:student-id="studentId"
				:feedback="feedback"
				:is-submitting="isSubmittingAssessment"
				@feedback-update="feedback = $event"
				@shoutout-update="shoutout = $event"
			/>
		</div>
		<div
			v-if="isButtonsShowing"
			class="assessment__buttonContainer"
		>
			<BaseButton
				v-jest="'submit-button'"
				v-data-cy="'assessment-submit-button'"
				type="PRIMARY"
				:will-float-right="true"
				:is-disabled="!isNextStepEnabled || isProcessingAssessment || isSubmittingAssessment"
				@click.native="advanceAssessment()"
			>
				<template slot="text">
					{{ $t("right_sidebar.assessment.next") }}
				</template>
				<template slot="icon-right">
					<IconChevronRight viewbox="-15 0 42 38" />
				</template>
			</BaseButton>
			<a
				v-if="isBackButtonAllowed"
				v-jest="'back-button'"
				v-data-cy="'assessment-back-button'"
				href="#"
				class="assessment__backButton"
				@click="goToPreviousStep"
			>
				<IconChevronLeft />
				{{ $t("right_sidebar.assessment.back") }}
			</a>
		</div>
	</div>
</template>

<script>
// Libraries
import { format } from "date-fns";
import { mapGetters, mapState } from "vuex";

import {
	RecognitionApi,
	TutorAssessmentRecognitionsStep as RecognitionStep,
} from "@/modules/RecognitionEvents/index.js";
import SidebarTabs from "@/modules/Classroom/components/SidebarTabs.vue";
import IconChevronLeft from "@/components/icons/IconChevronLeft.vue";
import IconChevronRight from "@/components/icons/IconChevronRight.vue";
import { showErrorModal } from "@/utilities/errorHandlingHelpers.js";
import BaseButton from "@/components/BaseButton.vue";

import { ASSESSMENTS_STEPS_ARRAY, ASSESSMENTS_STEPS } from "../utilities/index.js";
import TutorAssessmentApi from "../services/index.js";

import FeedbackStep from "./FeedbackStep.vue";
import NonAcademicStep from "./NonAcademicStep.vue";
import TagsStep from "./TagsStep.vue";
import QuestionStep from "./QuestionStep.vue";

export default {
	components: {
		IconChevronLeft,
		IconChevronRight,
		SidebarTabs,
		QuestionStep,
		TagsStep,
		NonAcademicStep,
		FeedbackStep,
		RecognitionStep,
		BaseButton,
	},
	data: function() {
		return {
			isValidTutorComment: null,
			isSubmittingAssessment: false,
			isProcessingAssessment: false,
			steps: ASSESSMENTS_STEPS_ARRAY,
			isNonAcademic: false,
			tagsArray: [],
			selectedRecognitionIds: [],
			recognitionsMessage: "",
			recognitionsSubmitted: false,
			ASSESSMENTS_STEPS,
		};
	},
	computed: {
		...mapState(["Classroom", "Student", "Tutor"]),
		...mapGetters("Assessment", ["sessionTags"]),
		currentSession() {
			return this.$store.getters["Classroom/currentSession"];
		},
		isButtonsShowing() {
			return this.Tutor.Session.numPendingAssessments > 0;
		},
		/**
		 * @returns {Boolean}
		 */
		isNextStepEnabled() {
			if (this.currentSession.assessment.step === "tag" && this.sessionTags !== undefined) {
				return (
					this.currentSession.subjectId !== null && this.currentSession.subjectId !== undefined
				);
			} else if (this.currentSession.assessment.step === "question") {
				return this.$store.getters["Tutor/Session/assessmentQuestions"].length > 0 || this.isNonAcademic;
			}
			return true;
		},
		/**
		 * @return {String}
		 */
		searchText: {
			set(value) {
				this.$store.commit("Tutor/Session/SET_SEARCH_TEXT", {
					searchText: value,
				});
			},
			get() {
				return this.Tutor.Session.searchText;
			},
		},
		/**
		 * @return {Array}
		 */
		subjectsArray() {
			return Object.values(this.Tutor.Session.subjects);
		},
		/**
		 * @return {String}
		 */
		feedback: {
			get() {
				return this.currentSession.assessment.feedback;
			},
			set(value) {
				this.$store.commit("Tutor/Session/EDIT_ASSESSMENT_IN_SESSION_IN_ASSESSMENTS", {
					id: this.Classroom.currentSessionId,
					key: "feedback",
					value: value,
				});
			},
		},
		/**
		 * @return {String}
		 */
		shoutout: {
			get() {
				return this.currentSession.assessment.shoutout;
			},
			set(value) {
				this.$store.commit("Tutor/Session/EDIT_ASSESSMENT_IN_SESSION_IN_ASSESSMENTS", {
					id: this.Classroom.currentSessionId,
					key: "shoutout",
					value: value,
				});
			},
		},
		currentSessionOtherUsers() {
			return this.$store.getters["Classroom/currentSessionOtherUsers"];
		},
		/**
		 * @return {String}
		 */
		studentName() {
			return this.currentSessionOtherUsers.length !== 0
				? this.currentSessionOtherUsers[0].first_name
				: "Student";
		},
		/**
		 * @return {Number}
		 */
		studentId() {
			return this.currentSessionOtherUsers.length !== 0
				? this.currentSessionOtherUsers[0].id
				: null;
		},
		/**
		 * @return {Object}
		 */
		grade() {
			return this.currentSessionOtherUsers.length !== 0
				? this.currentSessionOtherUsers[0].grade
				: null;
		},
		/**
		 * @return {Number}
		 */
		sessionId() {
			return this.currentSession.hasOwnProperty("currentSessionId")
				? this.currentSession.currentSessionId
				: null;
		},
		/**
		 * @returns {Boolean}
		 */
		isBackButtonAllowed() {
			const questionStepAndRecognitionsSubmitted =
				this.currentSession.assessment.step === ASSESSMENTS_STEPS.QUESTION &&
				this.recognitionsSubmitted;
			const recognitionsStep = this.currentSession.assessment.step === ASSESSMENTS_STEPS.RECOGNITIONS;
			return !(questionStepAndRecognitionsSubmitted || recognitionsStep);
		},
	},
	watch: {
		isNonAcademic() {
			if (this.isNonAcademic === true) {
				this.$store.commit("Tutor/Session/EDIT_ASSESSMENT_IN_SESSION_IN_ASSESSMENTS", {
					id: this.Classroom.currentSessionId,
					key: "nonAcademic",
					value: true,
				});
				this.$forceUpdate();
			} else {
				this.$store.commit("Tutor/Session/EDIT_ASSESSMENT_IN_SESSION_IN_ASSESSMENTS", {
					id: this.Classroom.currentSessionId,
					key: "nonAcademic",
					value: false,
				});
				this.$store.commit("Tutor/Session/EDIT_ASSESSMENT_IN_SESSION_IN_ASSESSMENTS", {
					id: this.Classroom.currentSessionId,
					key: "step",
					value: "question",
				});
				this.$forceUpdate();
			}
		},
	},
	methods: {
		/**
		 * @param {String} string
		 * @returns {Boolean}
		 */
		isStringEmpty(string) {
			return string === undefined || string === null || string.trim() === "";
		},
		addTagToList(tag) {
			this.addTag(tag);
			this.$store.commit("Tutor/Session/SET_SEARCH_TEXT", {
				searchText: "",
			});
		},
		/**
		 * Upon changing subject, empty list of selected tags,
		 * set value of selected subject, close subject dropdown
		 */
		changeSubject(subject) {
			this.$store.commit("Tutor/Session/SET_SEARCH_TEXT", {
				searchText: "",
			});
			this.sessionTags.forEach((tag, index) => {
				while (this.sessionTags.length !== 0) {
					this.$store.commit("Assessment/SPLICE_TAG_FROM_TAGS", {
						sessionId: this.Classroom.currentSessionId,
						index,
					});
				}
			});
			this.selectSubject(subject);
		},
		/**
		 * Add selected tag to tags list
		 */
		addTag(tag) {
			if (this.sessionTags && this.sessionTags.length > 0) {
				const index = this.sessionTags.indexOf(tag);
				if (index === -1) {
					this.$store.commit("Assessment/PUSH_TAG_IN_TAGS", {
						sessionId: this.Classroom.currentSessionId,
						tag: tag,
					});
					this.$store.commit("Tutor/Session/SET_SEARCH_TEXT", {
						searchText: "",
					});
					this.tagsArray = [...this.tagsArray, ...this.getAvailableConceptsForTopic(tag)];
				}
			} else {
				this.$store.commit("Assessment/PUSH_TAG_IN_TAGS", {
					sessionId: this.Classroom.currentSessionId,
					tag: tag,
				});
				this.$store.commit("Tutor/Session/SET_SEARCH_TEXT", {
					searchText: "",
				});
				this.tagsArray = [...this.tagsArray, ...this.getAvailableConceptsForTopic(tag)];
			}
		},
		updateAssessementBasedOnAcademicAnalysisResults(analysisResults) {
			const subject = this.subjectsArray.find((subject) => {
				return subject.id === analysisResults.subject_id;
			});
			this.$store.commit("Tutor/Session/EDIT_SESSION_IN_ASSESSMENTS", {
				id: this.Classroom.currentSessionId,
				key: "subjectId",
				value: analysisResults.subject_id,
			});
			this.$store.commit("Tutor/Session/EDIT_SESSION_IN_ASSESSMENTS", {
				id: this.Classroom.currentSessionId,
				key: "subject",
				value: subject,
			});

			if (this.sessionTags.length === 0) {
				analysisResults.academic_units.forEach((tag) => {
					this.$store.commit("Assessment/PUSH_TAG_IN_TAGS", {
						sessionId: this.Classroom.currentSessionId,
						tag: tag,
					});
				});
			}
		},
		async getAcademicAnalysisResults() {
			const questionsArray = [];

			this.$store.getters["Tutor/Session/assessmentQuestions"].forEach((question) => {
				questionsArray.push(question.message.id);
			});
			const params = {
				sessionId: this.Classroom.currentSessionId,
				data: {
					questions: questionsArray,
					student_id: this.studentId,
				},
			};
			if (this.grade !== null) {
				params.data.grade_id = this.grade.id;
			}

			const response = await this.$store.dispatch("Tutor/Session/getAcademicUnitAnalysis", { params });

			return response.data.body.data;
		},
		async advanceFromQuestionStep(step) {
			let nextStep = step;
			if (this.isNonAcademic) {
				nextStep = this.steps.indexOf(this.currentSession.assessment.step) + 2;
			} else {
				try {
					this.isProcessingAssessment = true;
					nextStep = this.steps.indexOf(this.currentSession.assessment.step) + 1;
					const analysisResults = await this.getAcademicAnalysisResults();
					this.updateAssessementBasedOnAcademicAnalysisResults(analysisResults);
				} catch (e) {
					Sentry.captureException(e);
				} finally {
					this.isProcessingAssessment = false;
				}
			}

			return nextStep;
		},
		skipRecognitionsStep() {
			this.recognitionsSubmitted = true;
			this.moveToStep(this.steps.indexOf("recognitions") + 1);
		},
		moveToStep(indexOfStep) {
			this.$store.commit("Tutor/Session/EDIT_ASSESSMENT_IN_SESSION_IN_ASSESSMENTS", {
				id: this.Classroom.currentSessionId,
				key: "step",
				value: this.steps[indexOfStep],
			});
			this.$forceUpdate();
		},
		/**
		 * @returns {String} the next step which is question
		 */
		async submitRecognition() {
			try {
				this.isProcessingAssessment = true;
				if (this.selectedRecognitionIds.length) {
					const payload = {
						recognition_type_ids: this.selectedRecognitionIds,
					};
					if (this.recognitionsMessage) {
						payload.message = this.recognitionsMessage;
					}
					await RecognitionApi.create(this.Classroom.currentSessionId, payload);
					this.$store.commit("Tutor/Session/SET_TOAST", {
						toast: { label: "Stickers sent!" },
					});
					this.recognitionsSubmitted = true;
				}
				return this.steps.indexOf(this.currentSession.assessment.step) + 1;
			} catch (e) {
				Sentry.captureException(e);
				showErrorModal(this);
			} finally {
				this.isProcessingAssessment = false;
			}
		},
		async advanceAssessment() {
			let nextStep;
			const currentStep = this.currentSession.assessment.step;
			const advanceFromTagStep = (nextStep) => {
				nextStep = this.steps.indexOf(this.currentSession.assessment.step) + 2;
				return nextStep;
			};
			const stepsData = {
				[ASSESSMENTS_STEPS.RECOGNITIONS]: { method: this.submitRecognition, shouldAwait: true },
				[ASSESSMENTS_STEPS.FEEDBACK]: { method: this.submitAssessment, shouldAwait: false },
				[ASSESSMENTS_STEPS.NON_ACADEMIC]: { method: this.submitAssessmentNonAcademic, shouldAwait: false },
				[ASSESSMENTS_STEPS.QUESTION]: {
					method: () => this.advanceFromQuestionStep(nextStep),
					shouldAwait: true,
				},
				[ASSESSMENTS_STEPS.TAG]: { method: () => advanceFromTagStep(nextStep), shouldAwait: true },
			};

			const step = stepsData[currentStep].shouldAwait ?
				await stepsData[currentStep].method() :
				stepsData[currentStep].method();
			if (step) {
				nextStep = step;
			}
			this.moveToStep(nextStep);
		},
		/**
		 * Upon clicking back button, if current step is non-academic or feedback
		 * go back two steps before. Otherwise, go back one step before. Set current
		 * step.
		 */
		goToPreviousStep() {
			let previousStep;
			const stepsArray = ["nonAcademic", "feedback"];

			if (stepsArray.includes(this.currentSession.assessment.step)) {
				previousStep = this.steps.indexOf(this.currentSession.assessment.step) - 2;
			} else {
				previousStep = this.steps.indexOf(this.currentSession.assessment.step) - 1;
			}

			this.$store.commit("Tutor/Session/EDIT_ASSESSMENT_IN_SESSION_IN_ASSESSMENTS", {
				id: this.Classroom.currentSessionId,
				key: "step",
				value: this.steps[previousStep],
			});
		},
		/**
		 * Remove session assessment from assessments
		 * @param {Number} id
		 */
		async removeAssessment(id) {
			await this.$store.commit("Tutor/Session/DELETE_ASSESSMENT_IN_ASSESSMENTS", { id });
			this.decrementNumPendingAssessments();
			if (this.$store.getters["Tutor/Session/firstAssessment"] !== null) {
				const newId = this.$store.getters["Tutor/Session/firstAssessment"].id;
				this.$store.dispatch("Tutor/Session/selectAssessment", newId);
			}
		},

		/**
		 * Commits new value to numPendingAssessments in store, new value is 1 less than previous.
		 */
		decrementNumPendingAssessments() {
			const newCount = this.Tutor.Session.numPendingAssessments - 1;
			this.$store.commit("Tutor/Session/SET_NUM_PENDING_ASSESSMENTS", {
				numPendingAssessments: newCount,
			});
		},

		/**
		 * Removes tag from the array
		 */
		removeTag(tag) {
			// remove tag from session tags
			const index = this.sessionTags.indexOf(tag);

			if (index !== -1) {
				this.$store.commit("Assessment/SPLICE_TAG_FROM_TAGS", {
					sessionId: this.Classroom.currentSessionId,
					index: index,
				});
			}
		},
		/**
		 * Selects the subject and sets
		 * it in the assessment
		 * @param {Number} subjectId
		 */
		selectSubject(subject) {
			if (this.currentSession.subjectId !== subject.id) {
				this.$store.commit("Tutor/Session/EDIT_SESSION_IN_ASSESSMENTS", {
					id: this.Classroom.currentSessionId,
					key: "subjectId",
					value: subject.id,
				});
				this.$store.commit("Tutor/Session/EDIT_SESSION_IN_ASSESSMENTS", {
					id: this.Classroom.currentSessionId,
					key: "subject",
					value: subject,
				});
			}

			this.$forceUpdate();
		},
		/**
		 * Submits the assessment to the api
		 * and removes it from the list
		 */
		async submitAssessment() {
			const topicsArray = [];
			const conceptsArray = [];
			let tagId;
			try {
				this.isSubmittingAssessment = true;
				const data = {};
				const assessmentData = {};

				//data to update assessment
				if (!this.isStringEmpty(this.currentSession.assessment.feedback)) {
					assessmentData["concern_comment"] = this.currentSession.assessment.feedback;
				}
				if (!this.isStringEmpty(this.currentSession.assessment.shoutout)) {
					assessmentData["shoutout_comment"] = this.currentSession.assessment.shoutout;
				}
				assessmentData["completed_at"] = format(new Date(), "t");

				//data to update session
				if (this.currentSession.subject !== null) {
					data["subjects"] = [this.currentSession.subject.id];
				}
				if (this.sessionTags.length !== 0) {
					this.sessionTags.forEach((tag) => {
						tagId = tag.id.toString();
						if (tagId.includes("topic-")) {
							tagId = tagId.replace("topic-", "");
							topicsArray.push(parseInt(tagId));
						} else if (tagId.includes("concept-")) {
							tagId = tagId.replace("concept-", "");
							conceptsArray.push(parseInt(tagId));
						}
					});
					if (topicsArray.length !== 0) {
						data["topics"] = topicsArray;
					}
					if (conceptsArray.length !== 0) {
						data["concepts"] = conceptsArray;
					}
				}
				const params = {
					id: this.currentSession.id,
					assessment_id: this.currentSession.assessment.id,
					data: data,
					assessmentData: assessmentData,
				};
				await Promise.all([
					this.$store.dispatch("Tutor/Session/updateSession", params),
					this.$store.dispatch("Tutor/Session/updateAssessment", params),
				]);

				this.$store.commit("Tutor/Session/EDIT_ASSESSMENT_IN_SESSION_IN_ASSESSMENTS", {
					id: this.Classroom.currentSessionId,
					key: "completed",
					value: true,
				});

				this.$store.commit("Tutor/Session/EDIT_ASSESSMENT_IN_SESSION_IN_ASSESSMENTS", {
					id: this.Classroom.currentSessionId,
					key: "step",
					value: "submit",
				});
				this.removeAssessment(this.Classroom.currentSessionId);
			} catch (error) {
				await this.$store.dispatch("SimpleModal/configureSimpleModal", {
					buttonText: "okay",
					message:
						"There was an error submitting the assessment. Please contact support if the problem persists.",
					title: "Error!",
					buttonType: "PRIMARY",
				});
				this.$bvModal.show("simpleModal");
				this.$store.commit("Tutor/Session/EDIT_ASSESSMENT_IN_SESSION_IN_ASSESSMENTS", {
					id: this.Classroom.currentSessionId,
					key: "step",
					value: "question",
				});
			} finally {
				this.isSubmittingAssessment = false;
			}
		},
		/**
		 * Submits a non academic assessment
		 * and removes it from the list
		 */
		async submitAssessmentNonAcademic() {
			try {
				this.isSubmittingAssessment = true;
				const params = {
					assessment_id: this.currentSession.assessment.id,
					assessmentData: {
						completed_at: format(new Date(), "t"),
					},
				};
				await this.$store.dispatch("Tutor/Session/updateAssessment", params);
				this.removeAssessment(this.currentSession.id);
			} catch (error) {
				await this.$store.dispatch("SimpleModal/configureSimpleModal", {
					buttonText: "okay",
					message:
						"There was an error submitting the assessment. Please contact support if the problem persists.",
					title: "Error!",
					buttonType: "PRIMARY",
				});
				this.$bvModal.show("simpleModal");
				this.$store.commit("Tutor/Session/EDIT_ASSESSMENT_IN_SESSION_IN_ASSESSMENTS", {
					id: this.Classroom.currentSessionId,
					key: "step",
					value: "question",
				});
			} finally {
				this.isSubmittingAssessment = false;
			}
		},
		getAvailableConceptsForTopic(tag) {
			return tag.concepts?.data.map((concept) => {
				return {
					...concept,
					id: `concept-${concept.id}`,
				};
			}) ?? [];
		},
		getAvailableTopicsForSubject(subject) {
			return subject[0].availableTopics?.data.map((topic) => {
				return {
					...topic,
					id: `topic-${topic.id}`,
				};
			});
		},
		async getAssessmentMap(subject) {
			const selectedSubjectName = subject?.name;
			try {
				this.tagsArray = [];
				const response = await TutorAssessmentApi.getAssessmentMap();
				const selectedSubjectMap = response.data.data.filter((subject) => {
					return subject.name === selectedSubjectName;
				});
				this.tagsArray = this.getAvailableTopicsForSubject(selectedSubjectMap);
			} catch (e) {
				Sentry.captureException(e);
			}
		},
	},
};
</script>

<style scoped>
.assessment {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 16px 20px;
	justify-content: space-between;
}

.assessment__title--noPadding {
	padding: 0;
	margin-bottom: 15px;
}

.assessment__contentContainer {
	overflow-y: auto;
	height: 100%;
}

.assessment__buttonContainer {
	margin-top: 16px;
}

.button .icon {
	margin: 0 -5px 0 5px;
}

.assessment__backButton {
	color: var(--c-blue);
	font-weight: 500;
	font-size: 14px;
	text-decoration: none !important;
	line-height: 40px;
	display: flex;
	align-items: center;
}

.assessment__backButton svg {
	width: 16px;
	height: 16px;
}

.button .icon svg {
	height: 16px;
	width: 16px;
}

.button .icon svg path {
	fill: #ffffff;
}

:deep(.assessment__backButton svg g g) {
	fill: var(--c-blue);
}
</style>
