import { merge } from "lodash";

import { i18n as whiteboardTranslations } from "@/modules/Whiteboard/index.js";

import { generalTranslations } from "../../general.js";
import { subjectsAndTopicsSeparate } from "../../subjectsAndTopics.js";
import studentProfileTranslations from "../../studentProfile.js";

const trans = {
	en: {
		hello: "Hello {person}!",
		ask_tutor_error_modal: {
			looking_for_a_tutor: "Looking for a tutor",
			looking_for_a_tutor_message:
				"Connecting you with a tutor is taking longer than usual, please go back and try again",
			no_tutor_found: "No tutor found",
			no_tutor_found_message:
				"We were unable to connect you with a tutor, please go back try again",
			go_back: "Go back",
		},
		already_in_session_modal: {
			title: "Whoops",
			go_to_session: "Go to session",
			go_to_classroom: "You are already in a session. Head over to the classroom to continue your previous session.",
		},
		connection_banner: {
			back_online: "Back Online!",
			disconnected: "You are disconnected",
		},
		chat: {
			student_end_session: {
				how_was_your_session: "How was your session with {name}",
				tutor: "Tutor",
				feedback_submitted_anonymously: "Your feedback will be submitted anonymously",
				why_end_chat: "Why did you end your chat?",
				skip_feedback: "Skip Feedback",
				submit: "Submit",
				feedback_submitted: "Hooray, your feedback has been submitted 🎉",
				anything_else: "Anything else you want to share about your session?",
				comment_shared_anonymously: "Your comment will be shared anonymously",
				send_comment_tutor_team: "Send a comment to the tutor team",
				end_the_session: "End the session",
				get_another_tutor: "Get another tutor",
				no_other_tutor_available: "Sorry, no other tutor is currently available.",
				got_it: "Got it!",
				you_ended_session_with: "You ended your session with {name}",
				ended_the_session: "{name} ended the session",
				are_you_sure_end_session: "Are you sure you want to end this session?",
				yes_end_session: "Yes, end the session",
				cancel: "Cancel",
				something_wrong: "Sorry, something went wrong!",
				session_not_ended_try_again: "Your session has not been ended, please try again.",
				end_session: "End session",
				give_tutor_chance: "Please be patient and give your tutor a chance to help you!",
				question: {
					was_session_helpful: "Was the session helpful?",
				},
				learned_status: {
					yes: "Yes",
					no: "No",
					not_sure: "Not sure",
				},
				reasons: {
					satisfied: "I got what I needed",
					tutor_issues: "I don’t like my tutor",
					time_issues: "I have to go",
					tech_issues: "Technical issues",
				},
				dialog_end_session_title: "Do you want to end your session with {name}?",
				dialog_end_session_description: "You will no longer be able to send messages or receive help from {name}. This action cannot be undone.",
			},
			find_tutor: "Connecting with your tutor",
			favorite_tutor: "You can add this tutor to your favourites by clicking this heart",
			chat_intro: {
				a_question: "a question",
				fun_fact: "Fun fact: {funFact}",
				help_shortly: "who can help you with {subject}",
				heres_your_student: "Here's your student, ",
				has_a_schedulded_session: "has a scheduled session",
				let_tutor_know: "Let {name} know what you need help with",
				need_help_with: "{name} needs help with {subject}",
				now_helping_you: "You are now being helped by ",
				say_hi: "You're talking to",
				cannot_see: "Your tutor cannot see your previous conversation",
				your_question: "your question",
				you_have_been_invited: "You've been invited to",
				by: "by {creator}",
				they_have_been_working_since:
					"They've been working on this assignment since {date}",
				here_to_help: " is now here to help",
				the_tutor: "The tutor",
				tutor_default_picture: "The default tutor profile picture",
				needs_mission_help: "{name} needs help with the following mission:",
				mission_name: "Mission Name:",
				mission_button: "View problem in Paper Missions",
			},
			handoff: {
				taking_over: " is taking over",
				dont_worry: "Don't worry, {name} can see your previous conversation",
			},
			input_area: {
				pick_your_emoji: "Pick your emoji",
				toggle_formatting: "{action} formatting options",
				toggle_emoji: "{action} emoji picker",
				open: "Open",
				close: "Close",
				send_message: "Send message",
				press: "Press",
				press_shift_enter: "Press shift enter to change line",
				share_file: "Send file",
				submit: "Submit",
				clear: "Clear",
				to_change_line: "to change line",
				type_message_placeholder: "Type your message...",
				type_file_upload_placeholder: "Drop your file here...",
				audio: {
					audio_message: {
						speak_now: "Speak now",
						recording_started: "Recording started...",
						record: "Record audio message",
						cancel_recording: "Recording canceled",
						approve_recording: "Approve recording",
						play: "Play audio message",
						pause: "Pause audio message",
						playback_speed: "Playback speed",
						normal_speed: "1x - Normal",
						transcription: {
							label: "Audio text",
							hide: "Hide audio text",
							show: "Show audio text",
						},
					},
					errors: {
						no_permission: "We could not access your microphone.",
						no_microphone: "The device you are using does not have a microphone.",
						microphone_disconnected: "Your microphone disconnected, please check the connection and try again.",
						invalid_audio_extension: "You cannot send mp3, mp4 and wav format files",
						recording_in_progress_failed: "An error occured while recording. Please try again or contact support if the problem persists.",
						recording_failed: "There was an error with your recording. Please try again or contact support if the problem persists.",
						waiting_for_permission: "Waiting for microphone permissions...",
						transcription: {
							failed: "Failed to load",
							unavailable: "Transcription is not available for this voice note",
						},
					},
				},
				voice_typing: {
					record: "Record voice typing",
					errors: {
						no_permission: "We could not access your microphone for voice typing.",
						no_speech: "No speech was detected.",
						voice_typing_failed: "An error with voice typing. Please try again or contact support if the problem persists.",
					},
				},
				camera: {
					take_photo: "Take photo",
					send_photo: "Send photo",
					info_remind: "Remember not to show faces or inappropriate content.",
					close_info_remind: "Close info message",
					verify_photo_title: "Verify and send photo",
					verify_photo_confirm: "There are no faces, personal information, or inappropriate content in this photo.",
					errors: {
						no_permission: "You currently don’t have permission to use your camera",
						no_camera: "The device you are using does not have a camera",
						camera_disconnected: "Your camera disconnected, please check the connection and try again.",
					},
				},
				errors: {
					file_type: "File type not supported",
					send_file_failed: "There was an error sending your file. Please try again or contact support if the problem persists.",
				},
				whiteboard: {
					delete_recording: "Delete recording",
					delete_successful: "Screen recording deleted",
					preview_deleted_dialog_title: "Do you want to delete your recording?",
					preview_deleted_dialog_description: "Your current whiteboard recording will be lost, and you will need to record again.",
					preview_close_dialog_title: "Closing the preview will delete your recording",
					preview_close_dialog_description: "If you don’t want to delete your recording,  click ‘Cancel’ to return to the preview. Otherwise, click ‘Delete recording’ to confirm.",
				},
			},
			infobar: {
				members_online: " member online |  members online",
				end_session: "End the session",
				transfer_tutor: "Transfer to another tutor",
				invite_tutor: "tutor",
				invite_student: "Invite new members",
				leave_convo: "Leave conversation",
				mute_convo: "Mute conversation",
				unmute_convo: "Unmute conversation",
				unspecified: "Unspecified",
				end: "End",
			},
			messages: {
				created_group_chat: "{name} created a group chat.",
				delivered: "Delivered",
				not_delivered: "This message was not delivered",
				resend: "Resend",
				has_ended_session: "{name} has ended the session.",
				invited_members: "{name} invited {members}",
				invited_tutor: "{name} invited a tutor.",
				is_drawing: "{name} is drawing...",
				is_typing: "{name} is typing...",
				is_recording: "{name} is recording an audio message...",
				is_voice_typing: "{name} is voice typing...",
				is_listening: "{name} is listening to an audio message...",
				left_group_chat: "{name} left the conversation",
				new_messages: "New Messages",
				jump_to_bottom: "Jump to bottom",
				okay_got_it: "Okay, got it",
				tutor_transfer: "You have been transferred to {name}.",
				your_student_ended: "The student ended the session.",
				force_transfer: "The session was transferred by {name}",
				uploaded_file_question: "You uploaded a file as your question.",
				tutor_transfer_error__message_when_student_ended_session:
					"The request to transfer has failed. The student has already ended the session.",
				tutor_transfer_error__message_when_student_has_transferred:
					"The request to transfer has failed. The student has already transferred the session to a different tutor.",
				student_transfer_error__message_when_tutor_ended_session:
					"The request to transfer has failed. The tutor has already ended the session.",
				has_ended_session_message_error:
					"{name} has ended the session. Your last message was not delivered.",
				your_student_ended_message_error:
					"The student ended the session. Your last message was not delivered.",
				click_to_preview: "Click to preview",
				message_text_removed: "This message has been removed. For more information, please contact support@paper.co",
				message_file_removed: "This file has been removed. For more information, please contact support@paper.co",
				message_image_removed: "This image has been removed. For more information, please contact support@paper.co",
				message_audio_removed: "This voice clip has been removed. For more information, please contact support@paper.co",
			},
			new_session: {
				go_to_dashboard: "Go to dashboard",
				ask_your_friends: "Ask your friend(s)",
				no_ongoing_conversations:
					"To get connected with a tutor, please start a new session from the Dashboard.",
				welcome: "Start a session",
			},
			reactions: {
				you: "You",
				and: "and",
			},
			embedded: {
				open_in_web: "Open in Paper Web",
				back_to_dashboard: "Back to Dashboard",
			},
		},
		empty_state: {
			chat_history_title: "View your chat history and bookmarks",
			select_history: "Select a chat from the <strong>'History'</strong> section on the left to view your conversations.",
			select_bookmarks: "You can also select <strong>'Bookmarks'</strong> to view your saved messages.",
		},
		errors: {
			allow_popups: "Please make sure your browser allows popups from PAPER!",
			error_title: "Error!",
			error_handoff_reason: "There was an error sending the handoff reason.",
			general: "An error occurred. Please contact support if the problem persists.",
			punching_out: "There was an error punching you out. Please refresh the page.",
			unable_connect_tutor:
				"Looks like we were unable to connect you to a tutor. Please try again or contact support.",
			whoops: "Whoops!",
			error_empty_field: "Whoops! This field cannot be empty",
			sending_feedback: "There was an error sending your session feedback. Please contact support if the problem persists.",
		},
		left_sidebar: {
			history: {
				no_recent: "You have no recent sessions.",
				bookmarks: "Bookmarks",
			},
			load_more_item_base: {
				load_more: "Load more",
			},
			sessions: {
				students: "Students",
				tutor: "Tutor Sessions",
				chats: "Chats",
				groups: "Groups",
				messages: "Messages",
				shared_file: "shared a file",
				shared_drawing: "shared a drawing",
				shared_paperclip: "shared a PaperClip",
				sessions: "Sessions",
				empty_sessions: "No active sessions to display",
			},
			assessments: {
				no_pending_assessments: "You have no pending assessments.",
			},
		},
		prompts: {
			chatWithTutor:
				"Your tutor is here to help! Say hello, hi or howdy :face_with_cowboy_hat:",
			gotIt: "Got it!",
			hideAllTips: "Hide all tips",
			history: "Psssssttt! Did you know all your past sessions are saved here?",
			leaveReview: "Useful tip: tutors don't see these comments! ",
			lightbox: "Click here to draw over this image and send it back to your tutor,",
			shareFiles: "You can send a file, a screenshot of your work or draw on the whiteboard",
			sharedFilesTab:
				"All shared files or whiteboard drawings are saved here so they are easy to find later",
			tutorResponseTime:
				"Tutors are real people :scream:, so they may take a moment to respond.",
		},
		toasts: {
			end_of_operating_hours: {
				title: "Warning",
				body: "Your school's operating hours end in {time_left} minutes",
			},
		},
		modals: {
			end_shift: {
				end: "End",
				end_shift: "End Shift",
				yes_end: "Yes, end my shift",
				would_you_end: "Would you like to end your shift?",
			},
			flag_student: {
				flag_a_student: "Flag a student",
				flag_insist_answer: "Insisting for an answer",
				flag_behaviour: "Inappropriate behaviour",
				flag_spamming: "Spamming",
				flag_topic_trouble: "Trouble in topic",
				reason: "Provide more details...",
				select_reason: "Select a reason",
				sending: "Sending...",
			},
			handoff: {
				handoff: "Handoff",
				handoff_to: "Handoff {name} to ...",
				no_tutors_available: "There are no tutors available",
				reason_other: "Other",
				reason_shift_ending: "My shift is ending",
				reason_not_tutors_subject: "This is not one of my tutoring subjects",
				reason_unable_to_answer_question:
					"This is one of my subjects, but I am unable to help with this topic/question",
				reason_overwhelmed: "I am overwhelmed with too many students",
				searching_for_tutor: "Searching for tutor...",
				why_handoff: "Please indicate why you are handing off the student:",
				view_availabilities: "View Availabilities",
			},
			request_tutor: {
				ask_any_tutor: "Ask any tutor",
				which_class: "Which class do you need help with?",
				requesting_tutor: "Requesting Tutor",
				request_tutor: "Request Tutor",
			},
			chat_disabled: {
				reloading_message: "Don't worry, this won't have any effect on your activity. Reloading will start in 5 seconds.",
				reloading_tutor_message: "You are only in an essay review shift at the moment. You will not receive students during this shift.",
				reloading_buttonText: "Reload now",
				reloading_page: "We need to reload your page!",
				shift_title: "Essay review shift",
			},
			start_shift: {
				hey_ready_help: "Hey! Ready to help?",
				not_currently_scheduled:
					"You are not currently scheduled. Feel free to roam around and complete assessments. Please make sure to refresh your page when your shift starts.",
				not_scheduled: "Not scheduled",
				please_confirm_ready:
					"Please confirm that you are ready to start helping students.",
				yes_im_ready: "Yes, I am ready",
			},
			end_of_shift_warning: {
				title: "Your shift is ending",
				body:
					"Please start ending your sessions or handing off your students. You will be forced out in 15 minutes.",
				buttonText: "Okay",
			},
			force_end_of_shift: {
				title: "Your shift is over",
				body: "You will now be redirected to the dashboard.",
				buttonText: "Ok",
			},
			student_acknowledge_end_session: {
				rate_session: "Rate your session with {name}:",
				tutor_ended_session: "Your tutor has ended the session.",
				what_think: "Tell us what you think.",
			},
			student_end_session: {
				back_to_session: "Back to session",
				end_session: "End session",
				got_the_help_that_i_need_thanks: "I got the help I need, thanks!",
				have_to_go_sorry: "I have to go, sorry!",
				leaving_already: "Leaving already?",
				looking_for_tutor: "Looking for another tutor...",
				tutor_not_helpful: "The tutor is not being helpful.",
				tutor_taking_too_long: "The tutor is taking too long to reply.",
				tutor_not_nice: "The tutor is not being nice.",
				dont_understand_explanation: "I don't understand the tutor's explanation.",
				end_the_session: "End the session",
				get_another_tutor: "Get another tutor",
			},
			end_of_operating_hours: {
				title: "End of operating hours",
				body:
					"Your school's operating hours are now over. You will be redirected to the dashboard.",
			},
			tutor_end_session: {
				end_session: "End Session",
				are_you_sure: "Are you sure you want to end this session?",
			},
			student_transfer_session: {
				transfer_title: "Get another tutor",
				select_reason: "Please select a reason for requesting a new tutor:",
				reason_placeholder: "Enter your reason*",
				confirm_subject: "Before we transfer you to a new tutor, let's confirm what subject you're needing help with:",
				reasons: {
					7: "Tutor didn't know how to help me",
					8: "Tutor was too slow",
					9: "Tutor wasn't nice",
					10: "Other",
				},
				error_reason_empty: "You must select a reason for requesting a new tutor. This is required.",
				error_other_empty: "Please enter a reason for requesting a new tutor. This is required.",
				error_subject_empty: "You must select a subject to transfer yourself to a new tutor.",
				go_back: "Go back",
				next: "Next",
				step_of: "Step {current} of {total}",
				select_subject: "Select a subject",
				error_dialog: {
					title: "There currently aren't any {language} speaking tutors available for {subject}",
					description: "We apologize for the inconvenience, but we are unable to transfer you to a new tutor at this time.",
				},
			},
		},
		dialog: {
			tutor_end_session:
			{
				end_session: "End session",
				title: "Do you want to end your session with {name}?",
				description: "You will no longer be able to send messages to {name} or help them with their problem. This action cannot be undone.",
			},
		},
		navbar: {
			classroom: "Chat",
			dashboard: "Dashboard",
			essay_review: "Essay Review",
			inbox: "Notifications",
		},
		right_sidebar: {
			assessment: {
				add: "Add",
				add_all_concepts: "Add all the concepts discussed during this session",
				after_each_concept: "after each concept",
				back: "Back",
				characters_left: "Characters left: {count}",
				content_not_school_related: "The content was not school related",
				examples: "Examples:",
				explain_understanding:
					"Write any concern you have about {name}'s understanding and approach.",
				hit_return: "Hit return/enter or click add",
				important: "Important",
				next: "Next",
				non_academic: "Non Academic",
				only_if: "only if: ",
				tutor_to_professor_feedback:
					"Is there anything <span class='assessment__studentName'>{name}'s professor</span> should know?<span  class='assessment__optional'>(optional)</span>",
				question: "Question {number}",
				radicals: "radicals, sine law, balancing chemicals equation",
				select_non_academic: "Select non academic ",
				tag_the_question: "Tag the question {name} needed help with",
				tag_questions_by: "Tag questions by clicking this icon beside the messages.",
				what_subject: "What was the subject of this session?",
				what_topic: "What was the topic of this session?",
				you_dont_know: "You don’t know the subject student needed help with",
				subject_and_tags:
					"Choose the main subject and related tags detected from student's questions",
				give_shout_out: "Give a shout-out to highlight {name}'s work",
				shout_out: "Shout-Out :thumbsup:",
				is_session_non_academic: "Is this session non-academic?",
				it_is: "Yes, it is",
				update_main_subject:
					"Update the main subject if it doesn't match the actual session.",
				reject_tags: "You can reject the tags above by clicking x.",
				related_tags: "Related tags",
				concerns: "Concerns",
			},
			group_session_member_list: {
				invite_new_member: "Invite a new member",
				no_other_members: "There are no other members left in this conversation.",
				online: "Online",
				offline: "Offline",
				school: "School",
			},
			shared_files_list: {
				all_files_here: "All files shared during this session will appear here",
			},
			student_profile: {
				class_name: "Class Name",
				grade_level: "Grade Level",
				mobile_app: "Mobile app",
				chrome_extension: "Chrome Extension",
				multiple_teachers: "Multiple teachers",
				no_class: "No class",
				no_teacher: "No teacher",
				resource_not_available: "Resource not yet available for this session",
				students_mobile_app: "Students using the mobile app have limited functionalities.",
				students_chrome_extension: "Students using the Chrome extension have limited functionalities.",
				teacher_name: "Teacher Name",
				view_solution: "View solution",
				ask_grade_level: "Ask {name}'s grade level and fill it in above",
				session_id: "Session ID",
			},
		},
		sidebar_tabs: {
			assessments: "Assessments",
			group_members: "Group Members",
			history: "History",
			sessions: "Sessions",
			shared_files: "Shared Files",
			student_profile: "Student Profile",
			complete_assessment: "Complete Assessment",
		},
		snackbar: {
			away: "Student is away.",
			disconnected: "You are disconnected.",
			user_disconnected: "Other user is disconnected.",
			error_reaction: "Sorry! Something went wrong, try sending your reaction again.",
		},
		session_intent: {
			title: "Session type:",
			list: {
				1: "Quick answer",
				2: "Assignment Check",
				3: "Step-by-step explanation",
				4: "I'm not sure",
			},
			list_V2: {
				1: "Daily answer",
				2: "Assignment Check",
				3: "Step-by-step explanation",
				4: "I'm not sure",
			},
			list_V3: {
				1: "Daily answer",
				2: "Assignment Check",
				3: "Step-by-step explanation",
				4: "I'm not sure",
				5: "Let's work together!",
				6: "Check my work",
				7: "Brainstorm and research",
				8: "Writing polish",
			},
		},
	},
	fr: {
		ask_tutor_error_modal: {
			looking_for_a_tutor: "Nous cherchons votre tuteur",
			looking_for_a_tutor_message:
				"Le procédé de vous connecter avec un tuteur prend plus de temps que d'habitude, veuillez retourner a la page précédente et réessayer",
			no_tutor_found: "Aucun tuteur trouvé",
			no_tutor_found_message:
				"Nous n'avons pas pu vous connecter avec un tuteur, veuillez retourner a la page précédente et réessayer",
			go_back: "Retourner",
		},
		already_in_session_modal: {
			title: "Oups",
			go_to_session: "Aller à la session",
			go_to_classroom: "Vous êtes déjà dans une session. Rendez-vous dans la salle de classe pour poursuivre votre session précédente.",
		},
		connection_banner: {
			back_online: "Retour en ligne!",
			disconnected: "Vous êtes déconnecté(e)",
		},
		chat: {
			student_end_session: {
				how_was_your_session: "Comment s'est passé votre session avec {name}",
				tutor: "Tuteur",
				feedback_submitted_anonymously: "Vos commentaires seront soumis de façon anonyme",
				why_end_chat: "Pourquoi avez-vous mis fin à votre chat?",
				skip_feedback: "Ignorer les commentaires",
				submit: "Soumettre",
				feedback_submitted: "Hourrah, vos commentaires ont été soumis 🎉",
				anything_else: "Avez-vous autre chose à partager sur votre session?",
				comment_shared_anonymously: "Votre commentaire sera partagé de manière anonyme",
				send_comment_tutor_team: "Envoyer un commentaire à l'équipe de tuteur",
				end_the_session: "Terminer la session",
				get_another_tutor: "Obtenir un autre tuteur",
				no_other_tutor_available: "Désolé, aucun autre tuteur n'est actuellement disponible.",
				got_it: "D'accord!",
				you_ended_session_with: "Vous avez terminé votre session avec {name}",
				ended_the_session: "{name} a terminé la session",
				are_you_sure_end_session: "Êtes-vous sûr de fermer la session?",
				yes_end_session: "Oui, terminer la session",
				cancel: "Annuler",
				something_wrong: "Désolé, quelque chose s'est mal passé!",
				session_not_ended_try_again: "Votre session n'est pas terminée, veuillez réessayer.",
				end_session: "Terminer la session",
				give_tutor_chance: "S'il vous plaît soyez patient et donnez à votre tuteur une chance de vous aider!",
				question: {
					was_session_helpful: "Est ce que la session a été utile?",
				},
				learned_status: {
					yes: "Oui",
					no: "Non",
					not_sure: "Pas certain",
				},
				reasons: {
					satisfied: "J'ai eu l'aide dont j'avais besoin",
					tutor_issues: "Je n'aime pas mon tuteur",
					time_issues: "Je dois y aller",
					tech_issues: "Problèmes techniques",
				},
			},
			find_tutor: "Nous vous connectons avec votre tuteur",
			favorite_tutor: "Tu peux ajouter ce tuteur à tes favoris en cliquant sur ce coeur",
			chat_intro: {
				a_question: "une question",
				fun_fact: "Anecdote: {funFact}",
				help_shortly: "Vous serez bientôt aidé avec {subject}",
				heres_your_student: "Voici votre étudiant, ",
				let_tutor_know: "Dites à {name} pourquoi vous avez besoin d'aide",
				need_help_with: "{name} a besoin d'aide avec {subject}",
				now_helping_you: "Vous serez maintenant aider par ",
				say_hi: "Dites bonjour à votre tuteur, ",
				cannot_see: "Votre tuteur ne peux pas voir l'historique de la conversation",
				your_question: "votre question",
				you_have_been_invited: "Tu es invité à",
				by: "par {creator}",
				they_have_been_working_since:
					"Les étudiants travaillent sur ce devoir depuis {date}",
				here_to_help: " est maintenant là pour vous aider",
				the_tutor: "Le tuteur",
				tutor_default_picture: "La photo de profil de tuteur par défaut",
			},
			handoff: {
				taking_over: " prend la relève",
				dont_worry: "Ne vous inquiétez pas, {name} peut voir votre conversation précédente",
			},
			input_area: {
				pick_your_emoji: "Choisissez vos emojis",
				press: "Cliquez",
				press_shift_enter: "Appuyez la shift enter pour changer de ligne",
				share_file: "Partager un fichier",
				submit: "Soumettre",
				clear: "Effacer",
				to_change_line: "pour changer de ligne",
				type_message_placeholder: "Tapez votre message...",
				type_file_upload_placeholder: "Déposez votre fichier ici",
				audio: {
					audio_message: {
						speak_now: "Parlez maintenant",
						recording_started: "L’enregistrement a commencé",
						record: "Vous ne pouvez pas envoyer de fichiers aux formats mp3, mp4 et wav.",
						cancel_recording: "Annuler l’enregistrement",
						approve_recording: "Approuver l’enregistrement",
						play: "Jouez le message audio",
						pause: "Mettre en pause le message audio",
						playback_speed: "Vitesse de lecture",
						normal_speed: "1x - Normal",
					},
					errors: {
						no_permission: "Nous n'avons pas pu accéder à votre microphone.",
						no_microphone: "L'appareil que vous utilisez n'a pas de microphone.",
						microphone_disconnected: "Votre microphone est déconnecté, veuillez vérifier la connexion et réessayer.",
						invalid_audio_extension: "Vous ne pouvez pas envoyer de fichiers aux formats mp3, mp4 et wav.",
						recording_in_progress_failed: "Une erreur s'est produite lors de l'enregistrement. Veuillez réessayer ou contacter l'assistance si le problème persiste.",
						recording_failed: "Il y a eu une erreur avec votre enregistrement. Veuillez réessayer ou contacter l'assistance si le problème persiste.",
					},
				},
				camera: {
					take_photo: "Prendre une photo",
					send_photo: "Envoyer la photo",
					info_remind: "Rappelle-toi de ne pas montrer ta face ou du contenu inapproprié.",
					close_info_remind: "Fermez le message d’information",
					verify_photo_title: "Verifier et envoyer la photo",
					verify_photo_confirm: "Il n’y a pas de faces, d’informations personnelles ou du contenu inapproprié dans cette photo.",
					errors: {
						no_camera: "L’appareil que vous utilisez n’a pas de caméra",
						no_permission: "Vous n’avez présentement pas la permission d’utiliser votre caméra",
						camera_disconnected: "Votre caméra est déconnecté, veuillez vérifier la connexion et réessayer.",
					},
				},
				errors: {
					send_file_failed: "Une erreur s'est produite lors de l'envoi de votre fichier. Veuillez réessayer ou contacter le support si le problème persiste.",
				},
			},
			infobar: {
				members_online: " membre en ligne |  membres en ligne",
				end_session: "Fermer la session",
				invite_tutor: "tuteur",
				invite_student: "Inviter des nouveaux membres",
				leave_convo: "Quitter la conversation",
				mute_convo: "Ignorer la conversation",
				unmute_convo: "Suivre la conversation",
				unspecified: "Non spécifié",
				end: "Fermer",
			},
			messages: {
				created_group_chat: "{name} a créé un groupe",
				delivered: "Délivré",
				not_delivered: "Ce message n’est pas livré",
				resend: "Renvoyer",
				has_ended_session: "{name} a terminé la session.",
				invited_members: "{name} a invité {members}",
				invited_tutor: "{name} a invité un tuteur.",
				is_drawing: "{name} dessine...",
				is_typing: "{name} écrit un message...",
				is_recording: "{name} enregistre un message audio...",
				is_listening: "{name} écoute un message audio...",
				left_group_chat: "{name} a quitté la conversation",
				new_messages: "Nouveaux messages",
				okay_got_it: "D'accords",
				tutor_transfer: "Vouz avez été transféré à {name}.",
				your_student_ended: "Votre étudiant à terminé la session.",
				force_transfer: "Cette session a été transférée par {name}.",
				uploaded_file_question: "Tu as téléchargé un fichier en tant que question.",
				tutor_transfer_error__message_when_student_ended_session:
					"La demande de transfert a échoué. L'élève a déjà terminé la session.",
				tutor_transfer_error__message_when_student_has_transferred:
					"La demande de transfert a échoué. L'élève a déjà transféré la session à un autre tuteur.",
				student_transfer_error__message_when_tutor_ended_session:
					"La demande de transfert a échoué. Le tuteur a déjà terminé la session.",
				has_ended_session_message_error: `{name} a terminé la session. 
				Votre dernier message n'a pas été livré`,
				your_student_ended_message_error: `Votre étudiant à terminé la session. Votre dernier message n'a pas été livré`,
				click_to_preview: "Cliquez pour voir la session",
				message_text_removed: "Ce message a été supprimé. Pour plus d'informations, veuillez contacter support@paper.co",
				message_file_removed: "Ce fichier a été supprimé. Pour plus d'informations, veuillez contacter support@paper.co",
				message_image_removed: "Cette image a été supprimée. Pour plus d'informations, veuillez contacter support@paper.co",
			},
			new_session: {
				go_to_dashboard: "Retournez au Tableau de bord",
				ask_your_friends: "Demandez à votre/vos ami(s)",
				no_ongoing_conversations:
					"Tu n'as aucune discussion en cour. Pour clavarder avec un tuteur, retournes au tableau de bord et soumets ta question ou selectionnes un sujet.",
				welcome: "Bienvenue à votre classe",
			},
			reactions: {
				you: "Toi",
				and: "et",
			},
			embedded: {
				open_in_web: "Ouvrir sur Paper Web",
				back_to_dashboard: "Retour au Tableau de bord",
			},
		},
		errors: {
			allow_popups:
				"Pour assurer le bon fonctionnement du site, autorisez les fenêtres pop-ups dans vos préférences de navigateur.",
			error_title: "Erreur!",
			error_handoff_reason: "Il ya eu un erreur durant l'envoi du motif du transfert.",
			general: "Il y a eu un erreur. S'il vous plaît contactez le support si .",
			punching_out:
				"Il y a eu un erreur durant la termination de votre quart de travail. S'il vous plaît, rafraîchissez la page.",
			unable_connect_tutor:
				"On ne pouvaient pas vous connecter avec un tuteur. Essayez encore ou contactez le support.",
			whoops: "Oups!",
			error_empty_field: "Oups! Ce champ ne peut pas être vide",
		},
		left_sidebar: {
			history: {
				no_recent: "Vous n'avez aucune session passée.",
				bookmarks: "Favoris",
			},
			load_more_item_base: {
				load_more: "Télécharger plus",
			},
			sessions: {
				students: "Étudiants",
				tutor: "Tuteur",
				chats: "Conversations",
				groups: "Groupes",
				messages: "Messages",
				shared_file: "a partagé un fichier",
				shared_drawing: "a partagé un dessin",
				sessions: "Séance",
			},
			assessments: {
				no_pending_assessments: "Vous n'avez aucune évaluation en attente.",
			},
		},
		prompts: {
			chatWithTutor:
				"Votre tuteur est ici pour vous aider! Dissez bonjour, salut ou allo :wave:",
			gotIt: "D'accord!",
			hideAllTips: "Masquer les conseils",
			history: "Hé-ho! Savez-vous que vous pouves retrouver vos conversations ici?",
			leaveReview: "Pst!! Les tuteurs ne voient pas ces commentaires! ",
			lightbox:
				"Cliquez ce bouton pour dessiner à partir de cette image et la renvoyer au tuteur.",
			shareFiles:
				"Vous pouvez envoyer un fichier, une cap6ture d'écran ou utiliser le tableau à dessin pour expliquer votre problème.",
			sharedFilesTab:
				"Vous pouvez rapidement retrouver tous les fichiers partagés et les dessins envoyés ici!",
			tutorResponseTime:
				"Les tuteurs ne sont pas des robots! :scream: Il est possible qu'ils aient besoin d'un moment pour répondre.",
		},
		toasts: {
			end_of_operating_hours: {
				title: "Attention!",
				body: "Vous allez perdre access a la classe dans {time_left} minutes.",
			},
		},
		modals: {
			end_shift: {
				end_shift: "Terminer mon quart",
				yes_end: "Oui, terminer mon quart",
				would_you_end: "Voulez-vous terminer votre quart?",
			},
			flag_student: {
				flag_a_student: "Signaler un étudiant",
				flag_insist_answer: "Insiste pour avoir une réponse",
				flag_behaviour: "Comportement inapproprié",
				flag_spamming: "Spamming",
				flag_topic_trouble: "Difficultés avec le sujet",
				reason: "Expliquez la situation...",
				select_reason: "Choisir une raison",
				sending: "Envoyer...",
			},
			handoff: {
				handoff: "Transferer",
				handoff_to: "Transferer {name} à ...",
				no_tutors_available: "Il n'y a aucun tuteur disponible",
				reason_other: "Autre",
				reason_shift_ending: "Mon quart se termine",
				reason_not_tutors_subject: "Ce n’est pas une matière que j’enseigne",
				reason_unable_to_answer_question:
					"J’enseigne cette matière mais je ne suis pas confortable avec ce sujet/cette question",
				reason_overwhelmed: "Je suis tros occupé",
				searching_for_tutor: "On cherche un tuteur...",
				why_handoff: "S'il vous plait indiquez pourquoi vous devez transferer l'étudiant:",
				view_availabilities: "Voir les disponibilités",
			},
			request_tutor: {
				ask_any_tutor: "Demander un tuteur",
				which_class: "Avec quelle classe avez-vous besoin d'aide?",
				requesting_tutor: "Demander un tuteur",
				request_tutor: "Demander un tuteur",
			},
			start_shift: {
				hey_ready_help: "Hey! Prêt à donner de l'aide?",
				not_currently_scheduled:
					"Vous n'êtes à l'horraire présentement. N'hésitez pas à explorer et compléter vos évaluations. Mais n'oubliez pas de rafraîchir la page lorsque votre quart de travail commence.",
				not_scheduled: "Pas cédulé",
				please_confirm_ready:
					"S'il vous plait, confirmez que vous êtes prêt à recevoir des étudiants.",
				yes_im_ready: "Oui, je suis prêt",
			},
			end_of_shift_warning: {
				title: "Votre quart se termine",
				body: "S'il vout plaît terminer vos sessions ou transférer vos étudiants.",
				buttonText: "Ok",
			},
			force_end_of_shift: {
				title: "Votre quart se termine",
				body: "S'il vout plaît terminer vos sessions ou transférer vos étudiants.",
				buttonText: "Ok",
			},
			student_acknowledge_end_session: {
				rate_session: "Évaluez votre session avec {name}:",
				tutor_ended_session: "Le tuteur a terminé la session.",
				what_think: "Donnez-nous votre avis.",
			},
			student_end_session: {
				back_to_session: "Retourner à la session",
				end_session: "Fermer la session",
				got_the_help_that_i_need_thanks: "J'ai eu l'aide dont j'avais besoin, merci!",
				have_to_go_sorry: "Je dois y aller, désolé!",
				leaving_already: "Vous quittez? Déjà?",
				looking_for_tutor: "Nous cherchons un tuteur...",
				tutor_not_helpful: "Le tuteur n'était d'aucune aide.",
				tutor_taking_too_long: "Le tuteur prends trop de temps pour répondre.",
				tutor_not_nice: "Le tuteur n'est pas très sympathique.",
				dont_understand_explanation: "Je ne comprends pas l'explication du tuteur.",
				end_the_session: "Fermer la session",
				get_another_tutor: "Trouver un autre tuteur",
			},
			end_of_operating_hours: {
				title: "Les heures d'opérations sont terminées.",
				body:
					"Les heures d'opérations de votre écoles sont terminées pour maintenant. Vous allez être redirigé vers votre tableau de bords.",
			},
		},
		navbar: {
			classroom: "Chat",
			dashboard: "Tableau de bord",
			essay_review: "Rédactions",
			inbox: "Notifications",
		},
		right_sidebar: {
			assessment: {
				add: "+",
				add_all_concepts: "Ajoutez tous les concepts discutés durant la session",
				after_each_concept: "aprés chaque concept",
				back: "Retourner",
				characters_left: "Caractères restants: {count}",
				content_not_school_related: "Le contenu de la session n'était pas académique",
				examples: "Exemples:",
				explain_understanding:
					"Écrivez toute préoccupation que vous avez concernant la compréhension et l'approche de {name}.",
				hit_return: "Tapez entrée ou cliquez ajouter",
				important: "Important",
				next: "Prochain",
				non_academic: "Non-académique",
				only_if: "seulement si: ",
				tutor_to_professor_feedback:
					"Quelque chose que le prof de <span class='assessment__studentName'>{name}</span> devrait savoir?<span class='assessment__optional'>(facultatif)</span>",
				question: "Question {number}",
				radicals: "les radicaux, la loi de sinus, les analyses chimiques",
				select_non_academic: "Sélectionnez non-académique ",
				tag_the_question:
					"Dans la conversation, selectionnez la/les question(s) de l'étudiant",
				tag_questions_by:
					"Pour selectionner, cliquez cet icone à coté du message correspondant.",
				what_subject: "Quelle était la matiére de la session?",
				what_topic: "Quelle était le sujet de la session?",
				you_dont_know:
					"Vous'n'avez aucune idée de la matiére pour laquelle l'étudiant avait besoin d'aide",
				subject_and_tags:
					"Voici le sujet principal et les balises associées détectées à partir des questions des étudiants",
				give_shout_out: "Faites mention des efforts ou succès de {name}.",
				shout_out: "Accolades :thumbsup:",
				is_session_non_academic: "Cette session est-elle non académique",
				it_is: "Oui, ça l'est",
				update_main_subject:
					"Mettez à jour le sujet principal s'il ne correspond pas à la session réelle.",
				reject_tags: "Vous pouvez rejeter les balises ci-dessus en cliquant sur x.",
				related_tags: "Tags associés",
				concerns: "Inquiétudes",
			},
			group_session_member_list: {
				invite_new_member: "Inviter un nouveau membre",
				no_other_members: "Aucun membre dans cette conversation.",
				online: "En ligne",
				offline: "Hors ligne",
				school: "École",
			},
			shared_files_list: {
				all_files_here: "Vous trouverez tous vos fichiers partagés ici",
			},
			student_profile: {
				class_name: "Nom de la classe",
				grade_level: "Niveau scolaire",
				mobile_app: "Mobile",
				chrome_extension: "Extension Chrome",
				multiple_teachers: "Plusieurs professeurs",
				no_class: "Aucune classe",
				no_teacher: "Aucun professeur",
				resource_not_available:
					"La ressource n'est pas encore disponible pour cette session",
				students_mobile_app: "Les utilisateurs mobiles ont des fonctionnalités limitées",
				students_chrome_extension: "Les utilisateurs de l'extension Chrome ont des fonctionnalités limitées.",
				teacher_name: "Nom du professeur",
				view_solution: "Voir la solution",
				ask_grade_level:
					"Demandez à {name} quel est son niveau scolaire et entrez le ci-dessus",
				session_id: "ID de sesión",
			},
		},
		sidebar_tabs: {
			assessments: "Évaluations",
			group_members: "Membres",
			history: "Histoire",
			sessions: "Sessions",
			shared_files: "Fichiers",
			student_profile: "Profil de l'étudiant",
			complete_assessment: "Complete Assessment",
		},
		snackbar: {
			away: "L'étudiant est absent.",
			disconnected: "Vous êtes déconnecté.",
			user_disconnected: "L'autre utilisateur est déconnecté.",
		},
		session_intent: {
			title: "Type de séance:",
			list: {
				1: "Réponse rapide",
				2: "Vérification des affectations",
				3: "Explication étape par étape",
				4: "Je ne suis pas sûr",
			},
			list_V2: {
				1: "Réponse quotidienne",
				2: "Vérification des affectations",
				3: "Explication étape par étape",
				4: "Je ne suis pas sûr",
			},
			list_V3: {
				1: "Réponse quotidienne",
				2: "Vérification des affectations",
				3: "Explication étape par étape",
				4: "Je ne suis pas sûr",
				5: "Travaillons ensemble !",
				6: "Vérifiez mes devoirs",
				7: "Remue-méninges et recherche",
				8: "Aide à l'écriture",
			},
		},
	},
	es: {
		hello: "¡Hola {person}!",
		ask_tutor_error_modal: {
			looking_for_a_tutor: "Buscando un tutor",
			looking_for_a_tutor_message:
				"Conectarte con un tutor está tardando más de lo usual, por favor vuelve a la página anterior e inténtalo de nuevo.",
			no_tutor_found: "No encontramos un tutor",
			no_tutor_found_message:
				"No pudimos conectarte con un tutor, por favor vuelve a la página anterior e inténtalo de nuevo",
			go_back: "Regresar",
		},
		already_in_session_modal: {
			title: "Ups",
			go_to_session: "Ir a sesión",
			go_to_classroom: "Ya estás en una sesión. Dirígete al salón de clases para continuar con tu sesión anterior.",
		},
		connection_banner: {
			back_online: "¡De nuevo en línea!",
			disconnected: "Estás desconectado(a)",
		},
		chat: {
			student_end_session: {
				how_was_your_session: "¿Cómo fue tu sesión con {name}?",
				tutor: "Tutor",
				feedback_submitted_anonymously: "Tus comentarios se enviarán de forma anónima.",
				why_end_chat: "¿Por qué terminaste tu chat?",
				skip_feedback: "Saltar comentarios",
				submit: "Entregar",
				feedback_submitted: "¡Hurra! Tus comentarios han sido enviados. 🎉",
				anything_else: "¿Algo más que quieras compartir sobre tu sesión?",
				comment_shared_anonymously: "Tu comentario será compartido de forma anónima.",
				send_comment_tutor_team: "Enviar un comentario al equipo de tutores",
				end_the_session: "Terminar la sesión",
				get_another_tutor: "Conseguir otro tutor",
				no_other_tutor_available: "Lo sentimos, no hay ningún otro tutor disponible actualmente.",
				got_it: "¡Entendido!",
				you_ended_session_with: "Terminaste tu sesión con {name}",
				ended_the_session: "{name} terminó la sesión",
				are_you_sure_end_session: "¿Estás seguro de que quieres terminar esta sesión?",
				yes_end_session: "Sí, terminar la sesión",
				cancel: "Cancelar",
				something_wrong: "¡Perdón, algo salió mal!",
				session_not_ended_try_again: "Su sesión no ha sido finalizada, inténtelo de nuevo.",
				end_session: "Terminar la sesión",
				give_tutor_chance: "¡Ten paciencia y dale a tu tutor la oportunidad de ayudarte!",
				question: {
					was_session_helpful: "¿Fue útil la sesión?",
				},
				learned_status: {
					yes: "Sí",
					no: "No",
					not_sure: "No estoy seguro",
				},
				reasons: {
					satisfied: "Tengo lo que necesitaba",
					tutor_issues: "No me gusta mi tutor",
					time_issues: "Tengo que ir",
					tech_issues: "Problemas técnicos",
				},
			},
			find_tutor: "Conectando con tu tutor",
			favorite_tutor: "Puedes agregar este tutor a tus favoritos haciendo clic en este corazón",
			chat_intro: {
				a_question: "una pregunta",
				fun_fact: "Dato curioso: {funFact}",
				help_shortly: "Tu tutor estará aquí en breve para ayudarte con {subject}",
				heres_your_student: "Di hola a tu estudiante,",
				let_tutor_know: "Dile a {name} en lo que necesitas ayuda",
				need_help_with: "{name} necesita ayuda con {subject}",
				now_helping_you: "Ahora te está ayudando",
				say_hi: "Di hola a tu tutor",
				cannot_see: "Tu tutor no puede ver tu conversación anterior",
				your_question: "tu pregunta",
				you_have_been_invited: "Has sido invitado",
				by: "por {creator}",
				they_have_been_working_since:
					"Han estado trabajando en esta tarea desde {date}",
				here_to_help: "está aquí para ayudar",
				the_tutor: "El tutor",
				tutor_default_picture: "La foto del perfil del tutor predeterminado",
			},
			handoff: {
				taking_over: "se hace cargo	",
				dont_worry: "No te preocupes, {name} puede ver tu conversación anterior.",
			},
			input_area: {
				pick_your_emoji: "Elige tu emoji",
				press: "Haz clic",
				press_shift_enter: "Presiona los botones Shift y Enter para cambiar de línea",
				share_file: "Enviar archivo",
				submit: "Enviar",
				clear: "Borrar",
				to_change_line: "para cambiar la línea",
				type_message_placeholder: "Escribe tu mensaje...",
				type_file_upload_placeholder: "Arrastra y coloca tu archivo aquí",
				audio: {
					audio_message: {
						speak_now: "Hable ahora",
						recording_started: "La grabación comenzó...",
						record: "Grabar mensaje de voz",
						cancel_recording: "La grabación comenzó",
						approve_recording: "Aprobar grabación",
						play: "Reproducir el mensaje de voz",
						pause: "Pausar el mensaje de voz",
						playback_speed: "Velocidad de reproducción",
						normal_speed: "1x - Normal",
					},
					errors: {
						no_permission: "No pudimos acceder a tu micrófono.",
						no_microphone: "El dispositivo que estás utilizando no tiene un micrófono.",
						microphone_disconnected: "Tu micrófono está desconectado, comprueba la conexión y vuelve a intentarlo.",
						invalid_audio_extension: "No se pueden enviar archivos en el formato mp3, mp4 o wav.",
						recording_in_progress_failed: "Ha ocurrido un error durante la grabación. Por favor intente de nuevo o pónganse en contacto con el grupo de soporte.",
						recording_failed: "Ha ocurrido un error durante la grabación. Por favor intente de nuevo o pónganse en contacto con el grupo de soporte.",
					},
				},
				camera: {
					take_photo: "Toma un foto",
					send_photo: "Enviar foto",
					info_remind: "Recuerda no mostrar caras ni contenidos inapropiados.",
					close_info_remind: "Cerrar mensaje de información",
					verify_photo_title: "Verificar y enviar foto",
					verify_photo_confirm: "En esta foto no hay caras, información personal ni contenido inapropiado.",
					errors: {
						no_camera: "El dispositivo que está utilizando no tiene una cámara",
						no_permission: "Al momento no tienes permiso de usar tu cámara",
						camera_disconnected: "Tu cámara está desconectada, comprueba la conexión y vuelve a intentarlo.",
					},
				},
				errors: {
					send_file_failed: "Ocurrió un error al enviar el archivo. Por favor, inténtalo de nuevo o comuníquese con el equipo de soporte si el problema persiste.",
				},
			},
			infobar: {
				members_online: "miembro en línea | miembros en línea",
				end_session: "Terminar la sesión",
				invite_tutor: "tutor",
				invite_student: "Invitar nuevos miembros",
				leave_convo: "Dejar la conversación",
				mute_convo: "Silenciar la conversación",
				unmute_convo: "Desactivar silencio en la conversación",
				unspecified: "No especificado",
				end: "Terminar",
			},
			messages: {
				created_group_chat: "{name} creó un chat en grupo.",
				delivered: "Enviado",
				has_ended_session: "{name} ha terminado la sesión.",
				invited_members: "{name} invitó a {members}",
				invited_tutor: "{name} invitó a un tutor.",
				is_drawing: "{name} está dibujando...",
				is_typing: "{name} está escribiendo...",
				is_recording: "{name} esta grabando un mensaje de voz...",
				is_listening: "{name} está escuchando una mensaje de voz...",
				left_group_chat: "{name} abandonó la conversación",
				new_messages: "Nuevos Mensajes",
				okay_got_it: "Ok, entiendo",
				tutor_transfer: "Has sido transferido a {name}.",
				your_student_ended: "El estudiante terminó la sesión.",
				force_transfer: "La sesión fue transferida por {name}",
				uploaded_file_question: "Subiste un archivo como tu pregunta.",
				tutor_transfer_error__message_when_student_ended_session:
					"La solicitud de transferencia falló. El estudiante ya ha terminado la sesión.",
				tutor_transfer_error__message_when_student_has_transferred:
					"La solicitud de transferencia falló. El estudiante ya ha transferido la sesión a otro tutor.",
				student_transfer_error__message_when_tutor_ended_session:
					"La solicitud de transferencia falló. El tutor ya ha terminado la sesión.",
				has_ended_session_message_error:
					"{name} ha terminado la sesión. Tu último mensaje no fue enviado.",
				your_student_ended_message_error:
					"El estudiante terminó la sesión. Tu último mensaje no fue enviado.",
				click_to_preview: "Haz clic para ver la sesión",
				message_text_removed: "Este mensaje ha sido removido. Por favor contáctese con support@paper.co para más información",
				message_file_removed: "Este archivo ha sido removido. Por favor contáctese con support@paper.co para más información",
				message_image_removed: "Esta imagen ha sido removida. Por favor contáctese con support@paper.co para más información",
			},
			new_session: {
				go_to_dashboard: "Ir al tablero",
				ask_your_friends: "Pregunta a tu(s) amigo(s)",
				no_ongoing_conversations:
					"No tienes sesiones abiertas. Para chatear con un tutor, regresa al tablero e ingresa tu pregunta o selecciona la materia con la que necesitas ayuda.",
				welcome: "Bienvenido a tus chats",
			},
			reactions: {
				you: "Tú",
				and: "y",
			},
			embedded: {
				open_in_web: "Abrir en Paper Web",
				back_to_dashboard: "Volver al Tablero",
			},
		},
		errors: {
			allow_popups: "¡Asegúrate de que tu navegador permita ventanas emergentes de Paper!",
			error_title: "¡Error!",
			error_handoff_reason: "Ocurrió un error al enviar el motivo de la transferencia.",
			general: "Ocurrió un error. Por favor, comunícate con el equipo de apoyo si el problema persiste.",
			punching_out: "Ocurrió un error al cerrar tu turno. Actualiza la página.",
			unable_connect_tutor:
				"Parece que no pudimos conectarte a un tutor. Por favor, inténtalo de nuevo o comunícate con el equipo de apoyo.",
			whoops: "¡Oh oh!",
			error_empty_field: "¡Oh oh! este campo no puede estar vacío",
		},
		left_sidebar: {
			history: {
				no_recent: "No tienes sesiones recientes.",
				bookmarks: "Marcadores",
			},
			load_more_item_base: {
				load_more: "Cargar más",
			},
			sessions: {
				students: "Estudiantes",
				tutor: "Tutor",
				chats: "Conversaciones",
				groups: "Grupos",
				messages: "Mensajes",
				shared_file: "compartió un archivo",
				shared_drawing: "compartió un dibujo",
				sessions: "Sesiones",
			},
			assessments: {
				no_pending_assessments: "No tienes evaluaciones pendientes.",
			},
		},
		prompts: {
			chatWithTutor:
				"¡Tu tutor está aquí para ayudar! Dí hola o ¡qué tal! :wave:",
			gotIt: "¡Ok!",
			hideAllTips: "Ocultar todos los consejos",
			history: "¡Hey! ¿Sabías que todas tus sesiones anteriores se guardan aquí?",
			leaveReview: "Nota: ¡Los tutores no ven estos comentarios!",
			lightbox: "Haz clic aquí para dibujar sobre esta imagen y enviarla a tu tutor.",
			shareFiles: "Puedes enviar archivos, capturas de pantalla de tu trabajo o dibujar en la pizarra virtual",
			sharedFilesTab:
				"Todos los archivos compartidos o dibujos en la pizarra son guardados aquí para volver a ellos fácilmente",
			tutorResponseTime:
				"Los tutores son personas reales :scream:, es posible que necesiten un momento para responder.",
		},
		toasts: {
			end_of_operating_hours: {
				title: "¡Atención!",
				body: "Las horas de funcionamiento de tu escuela terminan en {time_left} minutos",
			},
		},
		modals: {
			end_shift: {
				end: "Terminar",
				end_shift: "Terminar mi turno",
				yes_end: "Sí, termina mi turno",
				would_you_end: "¿Deseas terminar tu turno?",
			},
			flag_student: {
				flag_a_student: "Marcar a un estudiante",
				flag_insist_answer: "Insistinendo por la respuesta",
				flag_behaviour: "Comportamiento inapropiado",
				flag_spamming: "Spam",
				flag_topic_trouble: "Dificultades en el tema",
				reason: "Danos más detalles...",
				select_reason: "Selecciona un motivo",
				sending: "Enviando...",
			},
			handoff: {
				handoff: "Transferir",
				handoff_to: "Transfiere a {name} a ...",
				no_tutors_available: "No hay tutores disponibles",
				reason_other: "Otro",
				reason_shift_ending: "Mi turno está por teminar",
				reason_not_tutors_subject: "No es una de las materias que enseño",
				reason_unable_to_answer_question:
					"Enseño esta materia, pero no me siento cómodo con este tema/pregunta",
				reason_overwhelmed: "Tengo demasiados estudiantes",
				searching_for_tutor: "Buscanso un tutor...",
				why_handoff: "Por favor, indica por qué estás transferiendo al estudiante:",
				view_availabilities: "Ver disponibilidades",
			},
			request_tutor: {
				ask_any_tutor: "Pregunta a cualquier tutor",
				which_class: "¿Con qué clase necesitas ayuda?",
				requesting_tutor: "Conectando con un tutor",
				request_tutor: "Conectar con un tutor",
			},
			start_shift: {
				hey_ready_help: "¡Hola! ¿Listo para ayudar?",
				not_currently_scheduled:
					"Actualmente no estás en el horario. Puedes explorar y completar evaluaciones. Asegúrate de actualizar la página cuando comience tu turno.",
				not_scheduled: "No hay turno programado",
				please_confirm_ready:
					"Por favor confirma que estás listo para recibir a estudiantes.",
				yes_im_ready: "Sí, estoy listo",
			},
			end_of_shift_warning: {
				title: "Tu turno está por terminar",
				body:
					"Por favor finaliza tus sesiones o transfiere a tus estudiantes. Tu sesión se cerrará en 15 mimutos.",
				buttonText: "Ok",
			},
			force_end_of_shift: {
				title: "Tu turno ha terminado",
				body: "Ahora serás redirigido a tu tablero.",
				buttonText: "Ok",
			},
			student_acknowledge_end_session: {
				rate_session: "Califica tu sesión con {name}:",
				tutor_ended_session: "Tu tutor ha terminado la sesión.",
				what_think: "Dinos tu opinión.",
			},
			student_end_session: {
				back_to_session: "Regresar a la sesión",
				end_session: "Terminar la sesión",
				got_the_help_that_i_need_thanks: "Recibí la ayuda que necesitaba, ¡gracias!",
				have_to_go_sorry: "¡Lo siento, tengo que irme!",
				leaving_already: "¿Te vas?",
				looking_for_tutor: "Buscando otro tutor...",
				tutor_not_helpful: "El tutor no me ayudó como esperaba.",
				tutor_taking_too_long: "El tutor tarda en contestar.",
				tutor_not_nice: "El tutor no está siendo amable.",
				dont_understand_explanation: "No entiendo la explicación del tutor.",
				end_the_session: "Terminar la sesión",
				get_another_tutor: "Encuentra otro tutor",
			},
			end_of_operating_hours: {
				title: "Fin de horas de funcionamiento",
				body:
					"Las horas de funcionamiento de tu escuela terminaron por ahora. Serás redirigido a tu tablero.",
			},
		},
		navbar: {
			classroom: "Chat",
			dashboard: "Tablero",
			essay_review: "Revisión de ensayos",
			inbox: "Notificaciones",
		},
		right_sidebar: {
			assessment: {
				add: "Agregar",
				add_all_concepts: "Agrega todos los conceptos que se cubrieron durante esta sesión",
				after_each_concept: "después de cada concepto",
				back: "Regresar",
				characters_left: "Caracteres restantes: {count}",
				content_not_school_related: "El contenido no era académico",
				examples: "Ejemplos:",
				explain_understanding:
					"Escribe cualquier inquietud que tengas acerca del entendimiento y enfoque de {name}.",
				hit_return: "Presiona return/enter o haz clic en agregar",
				important: "Importante",
				next: "Siguiente",
				non_academic: "No académico",
				only_if: "sólo si: ",
				tutor_to_professor_feedback:
					"¿Hay algo que el maestro de  <span class='assessment__studentName'>{name}</span> deba saber? <span class='assessment__optional'>(optional)</span>",
				question: "Pregunta {number}",
				radicals: "radicales, ley de tangente, ecuaciones químicas",
				select_non_academic: "Selecciona no académico",
				tag_the_question: "Etiqueta la pregunta con la que {name} necesitaba ayuda",
				tag_questions_by: "Etiqueta las preguntas haciendo clic en este ícono junto a los mensajes.",
				what_subject: "¿Cuál fue la materia de la sesión?",
				what_topic: "¿Cuál fue el tema de la sesión?",
				you_dont_know: "No sabes la materia con el que el estudiante necesitaba ayuda",
				subject_and_tags:
					"Elige la materia principal y las etiquetas detectadas en la preguntas del estudiante",
				give_shout_out: "Envia un reconocimiento para destacar el trabajo de {name}",
				shout_out: "Reconocimiento: :thumbsup:",
				is_session_non_academic: "¿Esta sesión es no académica?",
				it_is: "Sí, lo es",
				update_main_subject:
					"Actualiza la materia principal si no coincide con la sesión.",
				reject_tags: "Puedes remover las etiquetas arriba haciendo clic en x.",
				related_tags: "Etiquetas relacionadas",
				concerns: "Inquietudes",
			},
			group_session_member_list: {
				invite_new_member: "Invitar a un nuevo miembro",
				no_other_members: "No hay miembros en esta conversación.",
				online: "En línea",
				offline: "Fuera de línea",
				school: "Escuela",
			},
			shared_files_list: {
				all_files_here: "Todos tus archivos compartidos aparecerán aquí",
			},
			student_profile: {
				class_name: "Nombre de la clase",
				grade_level: "Grado",
				mobile_app: "Aplicación móvil",
				chrome_extension: "Extensión de Chrome",
				multiple_teachers: "Varios maestros",
				no_class: "Sin clase",
				no_teacher: "Sin maestro",
				resource_not_available: "Este recurso aún no está dispobible para esta sesión",
				students_mobile_app: "Usuarios de la aplicación móvil tienen funcionalidades limitadas.	",
				students_chrome_extension: "Los usuarios de la extensión de Chrome tienen funcionalidades limitadas.",
				teacher_name: "Nombre del maestro",
				view_solution: "Ver solución",
				ask_grade_level: "Pregunta a {name} su grado y completalo arriba",
				session_id: "ID de session",
			},
		},
		sidebar_tabs: {
			assessments: "Evaluaciones",
			group_members: "Miembros del grupo",
			history: "Historia",
			sessions: "Sesiones",
			shared_files: "Archivos compartidos",
			student_profile: "Perfil de estudiante",
			complete_assessment: "Completa la evaluación",
		},
		snackbar: {
			away: "El estudiante está ausente.",
			disconnected: "Estás desconectado.",
			user_disconnected: "El otro usuario está desconectado.",
		},
		session_intent: {
			title: "Tipo de sesión:",
			list: {
				1: "Respuesta rápida",
				2: "Chequear un trabajo",
				3: "Explicación paso a paso",
				4: "No estoy seguro/a",
			},
			list_V2: {
				1: "Respuesta diaria",
				2: "Chequear un trabajo",
				3: "Explicación paso a paso",
				4: "No estoy seguro/a",
			},
			list_V3: {
				1: "Respuesta diaria",
				2: "Chequear un trabajo",
				3: "Explicación paso a paso",
				4: "No estoy seguro/a",
				5: "¡Vamos a trabajar juntos!",
				6: "Revisa mi trabajo",
				7: "Lluvia de ideas e investigación",
				8: "Escritura pulida",
			},
		},
	},
};

export default merge(
	trans,
	generalTranslations,
	subjectsAndTopicsSeparate,
	studentProfileTranslations,
	whiteboardTranslations,
);
