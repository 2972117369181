var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "toaster-bottom" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.Toaster.isVisible,
            expression: "Toaster.isVisible",
          },
          {
            name: "jest",
            rawName: "v-jest",
            value: "toaster",
            expression: "'toaster'",
          },
        ],
        staticClass:
          "tw-fixed tw-z-50 tw-bottom-0 tw-left-0 tw-m-4 tw-bg-origami-grey-400 tw-rounded tw-right-0 sm:tw-right-auto",
      },
      [
        _c(
          "p",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "toaster-text",
                expression: "'toaster-text'",
              },
            ],
            staticClass: "tw-py-3 tw-px-4 tw-text-white-high",
          },
          [_vm._v("\n\t\t\t" + _vm._s(_vm.Toaster.text) + "\n\t\t")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }