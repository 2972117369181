var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "tw-bg-origami-page-bg tw-border tw-border-origami-grey-200 tw-rounded-2xl tw-px-4 tw-py-2 tw-pb-4 tw-bg-origami-white",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "tw-flex tw-justify-between tw-border-b tw-border-origami-grey-200 tw-pb-2 tw-items-center",
        },
        [
          _c(
            "h2",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "student-essay-name",
                  expression: "'student-essay-name'",
                },
              ],
              staticClass:
                "tw-font-bold tw-text-origami-text-black tw-truncate tw-flex-grow",
            },
            [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(
                    `${_vm.student.first_name}'s submission: ${_vm.essay.title}`
                  ) +
                  "\n\t\t"
              ),
            ]
          ),
          _vm._v(" "),
          _vm.isEssayPickedUp
            ? _c(
                "div",
                { staticClass: "tw-flex tw-flex-row tw-items-center" },
                [
                  _c(
                    "h2",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "student-essay-file-name",
                          expression: "'student-essay-file-name'",
                        },
                      ],
                      staticClass:
                        "tw-font-bold tw-text-origami-text-black tw-truncate",
                    },
                    [_vm._v("\n\t\t\t\t#" + _vm._s(_vm.essay.id) + "\n\t\t\t")]
                  ),
                  _vm._v(" "),
                  _c("OrigamiIconButton", {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "copy-button",
                        expression: "'copy-button'",
                      },
                    ],
                    attrs: { "aria-label": "copy", "icon-name": "copy" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.copyFileNameToClipboard.apply(
                          null,
                          arguments
                        )
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isEssayPickedUp
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "info-circle",
                      expression: "'info-circle'",
                    },
                  ],
                  ref: "infoCircle",
                },
                [
                  _c("InfoToolTip", {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "info-hover",
                        expression: "'info-hover'",
                      },
                    ],
                    attrs: {
                      width: "20",
                      height: "20",
                      options: _vm.tooltipOptions,
                      "icon-color": "#0E0FE0",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "tw-flex tw-flex-col tw-justify-start tw-items-stretch tw-gap-y-8 tw-py-8",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-4 tw-w-full tw-gap-x-4 tw-gap-y-4 tw-flex-wrap",
            },
            [
              _c("div", { staticClass: "contentHeading__large" }, [
                _c(
                  "h5",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "grade-heading",
                        expression: "'grade-heading'",
                      },
                    ],
                    staticClass:
                      "tw-text-origami-text-black tw-text-sm tw-font-bold",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.student.first_name) +
                        "'s Grade Level\n\t\t\t\t"
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "grade-level",
                        expression: "'grade-level'",
                      },
                    ],
                    staticClass: "tw-text-2xl tw-font-bold tw-truncate",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" + _vm._s(_vm.gradeText) + "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "contentHeading__large" }, [
                _c(
                  "h5",
                  {
                    staticClass:
                      "tw-text-origami-text-black tw-text-sm tw-font-bold",
                  },
                  [_vm._v("\n\t\t\t\t\tSubmission language\n\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "student-language",
                        expression: "'student-language'",
                      },
                    ],
                    staticClass: "tw-text-2xl tw-font-bold",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" + _vm._s(_vm.languageText) + "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "contentHeading__large" }, [
                _c(
                  "h5",
                  {
                    staticClass:
                      "tw-text-origami-text-black tw-text-sm tw-font-bold",
                  },
                  [_vm._v("\n\t\t\t\t\tSubmission type\n\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "submission-type",
                        expression: "'submission-type'",
                      },
                    ],
                    staticClass: "tw-text-2xl tw-font-bold",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" + _vm._s(_vm.submissionType) + "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "tw-w-full tw-flex tw-justify-between tw-gap-x-4 tw-gap-y-4 tw-flex-wrap",
            },
            [
              _c("div", { staticClass: "commentContainer tw-min-w-0" }, [
                _c(
                  "h5",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "teacher-instructions-header",
                        expression: "'teacher-instructions-header'",
                      },
                    ],
                    staticClass:
                      "tw-text-origami-text-black tw-text-sm tw-font-bold",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.student.first_name) +
                        "'s Teacher's Instructions\n\t\t\t\t"
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "teacher-instructions-text",
                        expression: "'teacher-instructions-text'",
                      },
                    ],
                    staticClass: "tw-text-origami-text-black word-wrap",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.essay.teacher_instruction) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.essay.teacherInstructionFiles?.data.length
                  ? _c(
                      "div",
                      _vm._l(
                        _vm.essay.teacherInstructionFiles.data,
                        function (file, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass:
                                "tw-text-sm tw-text-origami-blue-300",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "tw-underline word-wrap",
                                  attrs: { href: file.filepath, download: "" },
                                },
                                [
                                  _c("OrigamiIcon", {
                                    attrs: {
                                      name: "essay-outlined",
                                      width: "14",
                                      height: "14",
                                    },
                                  }),
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(file.basename) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "commentContainer tw-min-w-0" }, [
                _c(
                  "h5",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "student-comment-header",
                        expression: "'student-comment-header'",
                      },
                    ],
                    staticClass:
                      "tw-text-origami-text-black tw-text-sm tw-font-bold",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.student.first_name) +
                        "'s comments\n\t\t\t\t"
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "student-comments",
                        expression: "'student-comments'",
                      },
                    ],
                    staticClass: "tw-text-origami-text-black word-wrap",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.essay.student_comment) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "tw-w-full tw-flex tw-justify-end tw-items-stretch tw-gap-x-4",
            },
            [
              _c("div", { staticClass: "commentContainer tw-min-w-0" }, [
                _vm.isShowingTriggerWarning
                  ? _c("p", {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "trigger-warning",
                          expression: "'trigger-warning'",
                        },
                      ],
                      staticClass:
                        "tw-text-origami-text-low-emphasis tw-text-base tw-hidden",
                    })
                  : _vm._e(),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "previewFooter tw-flex tw-justify-between tw-items-stretch tw-gap-x-4 tw-gap-y-4 tw-pt-4 tw-flex-nowrap tw-w-full tw-flex-col md:tw-flex-row",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "previewFooter__info tw-flex tw-justify-center tw-items-center tw-gap-x-2 tw-gap-y-2 tw-flex-wrap tw-max-w-full md:tw-justify-start lg:tw-justify-between lg:tw-flex-nowrap",
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "essay-id",
                      expression: "'essay-id'",
                    },
                  ],
                  staticClass:
                    "tw-text-origami-text-low-emphasis tw-text-xs tw-flex-shrink-0",
                },
                [_vm._v("#" + _vm._s(_vm.essay.id))]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "essay-created-date",
                      expression: "'essay-created-date'",
                    },
                  ],
                  staticClass:
                    "tw-text-origami-text-low-emphasis tw-text-xs tw-truncate tw-flex-shrink-0",
                },
                [_vm._v(_vm._s(_vm.essayCreatedDate))]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "essay-file-name",
                      expression: "'essay-file-name'",
                    },
                  ],
                  staticClass:
                    "tw-text-origami-text-low-emphasis tw-text-xs tw-flex-grow tw-overflow-hidden tw-text-center tw-p-1md:tw-text-left lg:tw-p-3",
                },
                [
                  _c(
                    "BaseLink",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "download-essay-file",
                          expression: "'download-essay-file'",
                        },
                      ],
                      staticClass:
                        "word-wrap tw-flex tw-flex-row tw-items-center tw-gap-x-1 tw-text-origami-blue-300 tw-justify-center md:tw-justify-start",
                      attrs: { to: _vm.downloadEssayLink },
                    },
                    [
                      _c("IconDownload", {
                        attrs: {
                          width: "13",
                          height: "13",
                          "icon-color": "currentColor",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "tw-underline" }, [
                        _vm._v(_vm._s(_vm.essay.filename)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          !_vm.isEssayPickedUp
            ? _c(
                "div",
                {
                  staticClass:
                    "previewFooter__buttons tw-flex tw-flex-row tw-justify-center md:tw-justify-end tw-items-center tw-gap-x-2 tw-gap-y-2 tw-flex-nowrap tw-flex-shrink-0",
                },
                [
                  _c("BaseButton", {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "pass-button",
                        expression: "'pass-button'",
                      },
                    ],
                    staticClass: "tw-w-28 tw-shadow-elevation-2",
                    attrs: {
                      "is-disabled": _vm.isPickingUpEssay,
                      type: "CANCEL",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.rejectEssay.apply(null, arguments)
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "text",
                          fn: function () {
                            return [_vm._v("\n\t\t\t\t\tPass on\n\t\t\t\t")]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      4150931480
                    ),
                  }),
                  _vm._v(" "),
                  _c("BaseButton", {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "pick-up",
                        expression: "'pick-up'",
                      },
                    ],
                    staticClass: "tw-w-28 tw-shadow-elevation-2",
                    attrs: { "is-disabled": _vm.isPickingUpEssay },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.pickUpEssay.apply(null, arguments)
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "text",
                          fn: function () {
                            return [
                              _vm.isPickingUpEssay
                                ? _c("LoadingRectangles", {
                                    directives: [
                                      {
                                        name: "jest",
                                        rawName: "v-jest",
                                        value: "loading-rectangles",
                                        expression: "'loading-rectangles'",
                                      },
                                    ],
                                  })
                                : _c("span", [_vm._v("Pick up")]),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3019175022
                    ),
                  }),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("BaseButton", {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "re-download-btn",
                        expression: "'re-download-btn'",
                      },
                    ],
                    staticClass: "tw-w-28 tw-shadow-elevation-2",
                    nativeOn: {
                      click: function ($event) {
                        return _vm.downloadEssay.apply(null, arguments)
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "text",
                        fn: function () {
                          return [_vm._v("\n\t\t\t\t\tRe-download\n\t\t\t\t")]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }