<template>
	<div
		id="tutorDashboardScheduleLegend"
		class="tw-my-5"
	>
		<ul class="tw-grid tw-grid-cols-2 tw-w-full tw-gap-y-4 tw-gap-x-12">
			<li
				v-for="(legend, index) in scheduleTypes"
				:key="index"
				v-jest="'legend-item'"
				class="tw-w-full"
			>
				<div class="tw-inline-flex tw-text-base tw-my-1.5">
					<div
						:class="legend.color"
						class="tw-mt-1 tw-mr-2.5 tw-w-5 tw-h-5 tw-rounded-md"
					/>
					<p class="tw-font-bold">
						{{ legend.title }}
					</p>
				</div>
				<p class="tw-text-sm tw-font-normal tw-m0">
					<strong> {{ legend.emphasis }} {{ insertScheduleEndDateIfLiveSessionShift(index) }} </strong>
					{{ legend.description }}
				</p>
			</li>
		</ul>
	</div>
</template>
<script>
import { addWeeks, format, startOfWeek } from "date-fns";

export default {
	data() {
		return {
			scheduleTypes: [
				{
					title: "Shift Transfer Pending",
					color: "tw-bg-orange-regular",
					emphasis:
						"Your shift transfer request will expire 6 hours before the shift starts.",
					description:
						"Your request will be sent to all available tutors that teach the same subject. The shift will disappear from your schedule as soon as the request is accepted.",
				},
				{
					title: "Writing Review Shift",
					color: "tw-bg-blue-light",
					emphasis: "You have been scheduled for a writing review shift.",
					description: "You will not receive any students during this time. You are only expected to review writing submissions.",
				},
				{
					title: "Live Session Shift",
					color: "tw-bg-green-light",
					emphasis: "These are your scheduled shifts until",
					description: "Make sure you have set your future availability in order to be scheduled. Your schedule will be released every 2 weeks as always.",
				},
				{
					title: "Assessment Shift",
					color: "tw-bg-blue-pastel",
				},
				{
					title: "Misc. Pay Adjustment Shift",
					color: "tw-bg-pink-pastel",
				},
				{
					title: "Test Session Shift",
					color: "tw-bg-green-pastel",
				},
				{
					title: "Onboarding Shift",
					color: "tw-bg-orange-pastel",
				},
				{
					title: "Special Project Shift",
					color: "tw-bg-yellow-light",
				},
				{
					title: "Meeting",
					color: "tw-bg-beige-regular",
				},
				{
					title: "Training",
					color: "tw-bg-orange-light",
				},
			],
		};
	},
	computed: {
		scheduleEndDate() {
			const twoWeeksFromNow = addWeeks(new Date(), 2);
			const scheduleEndDate = startOfWeek(twoWeeksFromNow, { weekStartsOn: 1 });
			return format(scheduleEndDate, "MMMM do");
		},
	},
	methods: {
		insertScheduleEndDateIfLiveSessionShift(index) {
			const LIVE_SESSION_SHIFT = 2;
			return index === LIVE_SESSION_SHIFT ? this.scheduleEndDate + "." : "";
		},
	},
};
</script>
