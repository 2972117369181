<template>
	<div class="sectionEmptyState">
		<div class="sectionEmptyState__text">
			{{ emptyStateText }}
		</div>
	</div>
</template>
<script>
export default {
	props: {
		sectionTitle: {
			type: String,
			required: false,
			default: "",
		},
	},
	computed: {
		emptyStateText() {
			switch (this.sectionTitle) {
				case "Upcoming Tutors":
					return "No Upcoming Tutors In The Next Hour";
				case "":
					return "";
				default:
					return `No ${this.sectionTitle} At The Moment`;
			}
		},
	},
};
</script>
<style scoped>
.sectionEmptyState {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 160px;
	width: 100%;
	margin: 8px;
	padding: 37px 0;
	background-color: #ffffff;
}
.sectionEmptyState__text {
	width: 376px;
	text-align: center;
	font-size: 32px;
	font-weight: bold;
	line-height: normal;
	color: var(--c-black);
}
</style>
