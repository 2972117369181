var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BModal",
    {
      ref: _vm.modalRef,
      attrs: {
        id: _vm.id,
        "body-class": "tw-pt-5 tw-px-5 tw-pb-9",
        "content-class": "tw-rounded-lg",
        "hide-header": "",
        "hide-footer": "",
        centered: "",
      },
      on: {
        show: function ($event) {
          return _vm.$emit("show", $event)
        },
        shown: function ($event) {
          return _vm.$emit("shown", $event)
        },
        hide: function ($event) {
          return _vm.$emit("hide", $event)
        },
        hidden: function ($event) {
          return _vm.$emit("hidden", $event)
        },
      },
      model: {
        value: _vm.isShowingModal,
        callback: function ($$v) {
          _vm.isShowingModal = $$v
        },
        expression: "isShowingModal",
      },
    },
    [
      _c(
        "div",
        { staticClass: "tw-flex tw-justify-end" },
        [
          _c(
            "BaseIconButton",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "close-modal",
                  expression: "'close-modal'",
                },
              ],
              attrs: {
                "aria-label": _vm.$te("accessibilty.close_modal")
                  ? _vm.$t("accessibilty.close_modal")
                  : "Close modal",
              },
              nativeOn: {
                click: function ($event) {
                  _vm.isShowingModal = false
                },
              },
            },
            [_c("IconClose")],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "tw-pl-3.5 tw-pr-9" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }