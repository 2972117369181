var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 7 9",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M2.10152 6.32022H3.78767V6.04213C3.78767 5.52388 4.1352 5.19522 4.67579 4.75281C5.46095 4.12079 6.02729 3.58989 6.02729 2.47753C6.02729 0.948034 5.01045 0 3.1441 0C1.20052 0 0.0807143 1.02388 0.0163574 2.73034L1.89558 3.02107C1.85696 2.06039 2.20449 1.45365 3.09261 1.45365C3.82628 1.45365 4.10945 1.88343 4.10945 2.50281C4.10945 3.10955 3.67183 3.46348 3.15697 3.91854C2.50053 4.48736 2.10152 4.89185 2.10152 5.78933V6.32022ZM1.99855 9H3.94213V7.00281H1.99855V9Z",
          fill: _vm.iconColor,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }