import {
	getTopics,
	getSubjects,
	getStatusList,
} from "../service/problemCreatorFilters.js";

const filterConfig = [
	{
		key: "mission_type_id",
		title: "Mission Type",
		type: "radio",
		options: [{ id: 1, name: "Math" }, { id: 2, name: "Yes/No" }, { id: 3, name: "Multiple Choice" }, { id: 4, name: "Inputs" }],
	},
	{
		key: "subject_id",
		title: "Subject",
		type: "radio",
		apiOptions: getSubjects,
	},
	{
		key: "topic_id",
		parentKey: "subject_id",
		title: "Topic",
		type: "radio",
		apiOptions: getTopics,
	},
	{
		key: "problem_status_id",
		title: "Status",
		type: "radio",
		defaultSelection: 1,
		apiOptions: getStatusList,
	},
];

export default filterConfig;
