import rootInstance from "@/services/root.js";

const V1 = "1.0";
const V2 = "2.0";

const scheduleManagementApi = (version) => {
	rootInstance.defaults.baseURL = process.env.MIX_SCHEDULE_MANAGEMENT_API_BASE_URI + `/api/v${version || V1}`;
	return rootInstance;
};

export default {
	getCurrentShift: () =>
		scheduleManagementApi(V1).get("/schedules/shifts/current?metadata=1")
			.then(({ data }) => data.data),

	getShiftState: (shiftId) =>
		scheduleManagementApi(V1).get(`/schedules/shifts/${shiftId}/state`)
			.then(({ data }) => data.data),

	startBioBreak: (shiftId, params) =>
		scheduleManagementApi(V1).post(`/schedules/shifts/${shiftId}/state`, params)
			.then(({ data }) => data.data),

	endBioBreak: (shiftId, params) =>
		scheduleManagementApi(V1).post(`/schedules/shifts/${shiftId}/state`, params)
			.then(({ data }) => data.data),

	getCurrentBioBreaks: () =>
		scheduleManagementApi(V1).get("/schedules/shifts/current/summary?state_id[]=4&state_id[]=6")
			.then(({ data }) => data.data),

	getBioBreakSummary: (params) =>
		scheduleManagementApi(V1).get("/schedules/summaries/bio-breaks", params)
			.then(({ data }) => data),

	getUserShifts: (userId, startDate, endDate) =>
		scheduleManagementApi(V2).get(
			`/schedules/shifts?user_id=${userId}&start_date=${startDate.getTime()}&end_date=${endDate.getTime()}`,
		).then(({ data }) => data.data),

	extendShift: (shiftId) =>
		scheduleManagementApi(V2).post(`/schedules/shifts/${shiftId}/extend`)
			.then(({ data }) => data.data),
	punchIn: () =>
		scheduleManagementApi(V1).post("/schedules/shifts/punch-clock/in")
			.then(({ data }) => data.data),
	punchOut: () =>
		scheduleManagementApi(V1).post("/schedules/shifts/punch-clock/out")
			.then(({ data }) => data.data),
};
