<template>
	<div class="tagsTable">
		<table class="tw-w-full tw-max-w-full">
			<tr class="tagsTable__headersContainer tw-min-w-full tw-w-full tw-max-w-full tw-flex tw-justify-between">
				<th class="tagsTable__headers tw-text-xs tw-font-bold tw-flex-grow tw-w-1/3">
					{{ headers[0].name }}
				</th>
				<th class="tagsTable__headers tw-text-xs tw-font-bold tw-flex-grow tw-w-1/3">
					{{ headers[1].name }}
				</th>
				<th class="tagsTable__headers tw-text-xs tw-font-bold tw-flex-grow tw-w-1/3">
					{{ headers[2].name }}
				</th>
			</tr>
			<tr
				v-for="item in tags"
				:key="item.id"
				class="tw-w-full tw-flex tw-justify-start"
			>
				<td
					valign="top"
					class="tw-w-1/3"
				>
					<Tag
						:is-clearable="false"
						:item="item"
						class="tw-ml-0 tw-mr-3 tw-my-1"
					>
						<template slot="text">
							{{ item.name }}
						</template>
					</Tag>
				</td>
				<td
					valign="top"
					class="tagsTable__topicsCell"
				>
					<table class="tw-w-full">
						<tr
							v-for="secondLevelItem in item.topics"
							:key="secondLevelItem.id"
							class="tw-flex tw-justify-start tw-gap-x-px"
						>
							<td
								class="tagsTable__topicsTableItem tw-flex-grow tw-flex-shrink-0 tw-p-0"
							>
								<Tag
									:is-clearable="false"
									:item="item"
									class="tw-ml-0 tw-mr-3 tw-my-1"
								>
									<template slot="text">
										{{ secondLevelItem.name }}
									</template>
								</Tag>
							</td>
							<td
								class="tagsTable__topicsTableItem tw-flex-grow tw-flex-shrink-0 tw-p-0"
							>
								<table class="tw-w-full">
									<tr
										v-for="thirdLevelItem in secondLevelItem.concepts"
										:key="thirdLevelItem.id"
										class="tw-w-full"
									>
										<td class="tw-w-full">
											<Tag
												:is-clearable="false"
												:item="item"
												class="tw-ml-0 tw-mr-3 tw-my-1"
											>
												<template slot="text">
													{{ thirdLevelItem.name }}
												</template>
											</Tag>
										</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>
				</td>
			</tr>
			<tr
				v-show="isTagsEmpty"
				v-cloak
				id="noResults"
			>
				<td :colspan="headers.length">
					<BaseEmptyState
						class="emptyState"
						header="No tags to display"
						sub-header="Add tags by clicking 'Edit Tag'"
					/>
				</td>
			</tr>
		</table>
	</div>
</template>
<script>
//Components
import Tag from "./Tag.vue";
import BaseEmptyState from "./BaseEmptyState.vue";

export default {
	components: {
		Tag,
		BaseEmptyState,
	},
	data() {
		return {
			headers: [
				{
					value: "subject",
					name: "Subject",
					orderable: true,
					state: null,
				},
				{
					value: "topic",
					name: "Topic",
					orderable: true,
					state: null,
				},
				{
					value: "concept",
					name: "Concept",
					orderable: true,
					state: null,
				},
			],
			tags: [],
		};
	},
	computed: {
		/**
		 * @return {Boolean}
		 *
		 */
		isTagsEmpty() {
			return true;
		},
	},
	methods: {
		setUpTags() {},
	},
};
</script>
<style>
.tagsTable .tag,
.tagsTable .tag__text {
	width: 100%;
}
.tagsTable__headers {
	flex-basis: calc(100% / 3);
}
.tagsTable__topicsCell {
	width: calc(100% / 3 * 2);
}
.tagsTable__topicsTableItem {
	flex-basis: 50%;
}
.tagsTable__headersContainer {
	border-bottom: 2px solid var(--c-light-gray);
}
</style>
