var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M12.4148 1.33325H4.79578C3.95769 1.33325 3.27959 1.99992 3.27959 2.81473L3.27197 14.6666L8.60531 12.4444L13.9386 14.6666V2.81473C13.9386 1.99992 13.2529 1.33325 12.4148 1.33325Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }