var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabs", attrs: { role: "tablist" } },
    [
      _vm._l(_vm.tabs, function (tab) {
        return [
          _c(
            "button",
            {
              directives: [
                {
                  name: "data-cy",
                  rawName: "v-data-cy",
                  value: tab.name,
                  expression: "tab.name",
                },
              ],
              key: tab.name,
              staticClass: "tabs__item",
              class: { "tabs__item--active": _vm.isTabActive(tab) },
              style: { color: _vm.fontColor },
              attrs: {
                id: tab.name,
                role: "tab",
                tabindex: _vm.isTabActive(tab) ? 0 : -1,
                "aria-selected": _vm.isTabActive(tab) ? "true" : "false",
                "aria-controls": `${tab.name}-panel`,
              },
              on: {
                click: function ($event) {
                  return _vm.changeTab(tab)
                },
                keydown: function ($event) {
                  return _vm.handleKeydown($event)
                },
              },
            },
            [
              tab.name == "sessions"
                ? _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isNotificationDotVisible,
                        expression: "isNotificationDotVisible",
                      },
                    ],
                    staticClass: "tabs__dot",
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("h2", { staticClass: "tabs__name" }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.$t("sidebar_tabs." + tab.name)) +
                    "\n\t\t\t\t"
                ),
                _vm.checkIfSessionsTab(tab.name)
                  ? _c("span", { staticClass: "tabs__name" }, [
                      _vm._v("\n\t\t\t\t\t" + _vm._s(_vm.numUnreadMessages)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.checkIfAssessmentsTab(tab.name)
                  ? _c("span", { staticClass: "tabs__name" }, [
                      _vm._v(
                        "\n\t\t\t\t\t(" +
                          _vm._s(_vm.numPendingAssessments) +
                          ")"
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isTabActive(tab),
                    expression: "isTabActive(tab)",
                  },
                ],
                staticClass: "tabs__redBar",
              }),
            ]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }