var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tw-flex tw-flex-col" }, [
    _vm.isTitleShowing
      ? _c(
          "span",
          {
            directives: [
              {
                name: "data-cy",
                rawName: "v-data-cy",
                value: "tutor-shift-summary",
                expression: "'tutor-shift-summary'",
              },
            ],
            staticClass: "tw-font-bold tw-text-base",
          },
          [_vm._v("\n\t\t24hr Shift summary\n\t")]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "tw-bg-origami-white tw-text-origami-black tw-rounded-md tw-px-2 tw-pb-6 tw-mt-2 tw-flex-wrap shiftSummary__content overflow-auto",
      },
      [
        _vm.isLoadingTheUpcomingShifts
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "shift-summary-loading",
                    expression: "'shift-summary-loading'",
                  },
                ],
                staticClass: "shiftSummary__loadingState tw-mt-4",
              },
              [
                _c("div", { staticClass: "shiftSummary__loadingAnimation" }),
                _vm._v(" "),
                _c("div", { staticClass: "shiftSummary__loadingAnimation" }),
              ]
            )
          : _vm.isNoUpcomingShift
          ? _c("TutorDashboardShiftSummaryNoShift", {
              directives: [
                {
                  name: "data-cy",
                  rawName: "v-data-cy",
                  value: "tutor-dashboard-shift-summary-no-shift",
                  expression: "'tutor-dashboard-shift-summary-no-shift'",
                },
              ],
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          _vm._l(_vm.upcomingSegments, function (shiftSegment) {
            return _c("ShiftSummaryItem", {
              key: shiftSegment.id,
              attrs: { "shift-segment": shiftSegment },
            })
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }