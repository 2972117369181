var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { class: _vm.containerClass }, [
    _c("div", {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "micro-front-end",
          expression: "'micro-front-end'",
        },
      ],
      attrs: { id: _setup.mfeConfig.id },
    }),
    _vm._v(" "),
    _setup.isLoading || _vm.isAlwaysLoading || _setup.isShowingError
      ? _c(
          "div",
          { class: { fallback__container: !_vm.isWidget } },
          [
            _setup.isShowingError
              ? _vm._t("error", function () {
                  return [
                    _c(_setup.ErrorState, {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "error-state",
                          expression: "'error-state'",
                        },
                      ],
                      staticClass:
                        "tw-bg-white-high tw-p-5 tw-h-72 tw-shadow-elevation-2 tw-rounded",
                      on: {
                        "button-clicked": function ($event) {
                          return _setup.reload()
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "main",
                            fn: function () {
                              return [
                                _c("p", { staticClass: "tw-text-center" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.$t("errors.modal.reload.subtitle")
                                      ) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        69260941
                      ),
                    }),
                  ]
                })
              : _vm._t("loader", function () {
                  return [
                    _c(_setup.BaseLoader, {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "loader",
                          expression: "'loader'",
                        },
                      ],
                      staticClass:
                        "loader tw-w-16 tw-h-16 tw-text-blue-regular",
                    }),
                  ]
                }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }