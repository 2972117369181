<template>
	<section
		aria-label="tutor list"
		class="tutorsList tw-flex tw-flex-col"
	>
		<header class="tw-flex tw-flex-row tw-justify-between tw-align-middle">
			<h1
				v-jest="'title'"
				class="tw-text-xl tw-font-semibold tw-text-origami-black"
			>
				Tutors
			</h1>
			<div class="tw-flex tw-flex-row">
				<BaseButton
					v-data-cy="'btn-create-new-tutor'"
					@click.native="goToCreateNewTutorPage()"
				>
					<template slot="text">
						Create New Tutor
					</template>
				</BaseButton>
			</div>
		</header>
		<TutorsListFilterContainer
			class="tw-mx-5"
			:filters="PlatformManager.TutorsList.filterKeys"
		/>
		<p
			class="tutorsList__totalResults"
			:class="{
				'tutorsList__totalResults--opacity':
					PlatformManager.TutorsList.isLoadingTutors,
			}"
		>
			<strong>{{ PlatformManager.TutorsList.totalUsersFound }}</strong> users found
		</p>
		<TutorsListTableContainer />
		<div class="tw-flex tw-justify-center tw-mb-10">
			<Pagination
				:total-pages="PlatformManager.TutorsList.pagination.total_pages"
				:current-page="PlatformManager.TutorsList.pagination.current_page"
				@change="handleChangePage"
			/>
		</div>
	</section>
</template>
<script>
import { mapState } from "vuex";

import Pagination from "@/components/patterns/Pagination.vue";
import BaseButton from "@/components/BaseButton.vue";

import TutorsListTableContainer from "./TutorsListTableContainer.vue";
import TutorsListFilterContainer from "./TutorsListFilterContainer.vue";

export default {
	components: {
		BaseButton,
		Pagination,
		TutorsListFilterContainer,
		TutorsListTableContainer,
	},
	computed: {
		...mapState(["PlatformManager", "currentUser"]),
	},
	methods: {
		async handleChangePage(pageNumber) {
			await this.$store.dispatch("PlatformManager/TutorsList/setTutorsCurrentPageList", { newPage: pageNumber });
		},
		goToCreateNewTutorPage() {
			this.$router.push(`/${this.currentUser.role}/services/tutors/create`);
		},
		goToTutorResources() {
			this.$router.push(`/${this.currentUser.role}/services/tutors/resources`);
		},
	},
};
</script>
<style scoped>
.tutorsList {
	padding: 24px 20vw;
	background: transparent;
	transition: ease 0.2s;
}
.tutorsList__totalResults {
	letter-spacing: -0.02px;
}
.tutorsList__totalResults--opacity {
	opacity: 0.6;
}

@media only screen and (min-width: 1500px) {
	.tutorsList {
		padding: 24px 25vw;
	}
}
</style>
