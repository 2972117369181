var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.component, {
    tag: "component",
    attrs: { "shift-segment": _vm.shiftSegment },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }