<script>
import TableAbstract from "@/components/TableAbstract.vue";

export default {
	extends: TableAbstract,
	methods: {
		/**
		 * @param {Object} headerObject
		 */
		async handleOrderByEvent(headerObject) {
			let newStateString = this.determineNewOrderByState(headerObject.state);
			this.resetHeaderStates(headerObject.value, newStateString);
			newStateString = newStateString !== null ? `.${newStateString}` : "";
			await this.$store.commit(
				"PlatformManager/TutorsList/SET_ORDER_BY",
				{
					orderBy: `${headerObject.value}${newStateString}`,
				},
			);
			try {
				this.$store.dispatch("PlatformManager/TutorsList/getTutorAvailabilityFilteredTutorData");
			} catch (error) {
				Sentry.captureException(error);
				this.$bvModal.show(`tutorsListErrorModal`);
			}
		},
		/**
		 * Determines the
		 * @param {String} prevState
		 * @returns {String}
		 */
		determineNewOrderByState(prevState) {
			switch (prevState) {
				case null:
				case "desc":
					return "asc";
				case "asc":
					return "desc";
				default:
					return null;
			}
		},
		/**
		 * Reset all other headers' state
		 * @param {String} changedHeaderValue
		 * @param {String} newState
		 */
		resetHeaderStates(changedHeaderValue, newState) {
			const newHeaders = [...this.headers];
			newHeaders.forEach(function(header) {
				if (header.value !== changedHeaderValue) {
					header.state = null;
				} else {
					header.state = newState;
				}
			});
			this.$store.commit("PlatformManager/TutorsList/SET_TABLE_HEADERS", {
				tableHeaders: newHeaders,
			});
		},
	},
};
</script>
