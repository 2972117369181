<template>
	<Drilldown
		v-jest="'service-dashboard-tutor-sidebar'"
		title="Tutor Quick Edit"
		:is-drilldown-open="isTutorQuickEditOpen"
		@close-drilldown="closeTutorProfile"
	>
		<div
			v-if="isTutorQuickEditOpen"
			class="tw-flex tw-flex-col"
		>
			<TutorProfileDetails
				v-jest="'service-dashboard-tutor-current-shift'"
				class="tw-pl-6 tw-pr-10"
			/>
			<TutorSubjectsList
				v-jest="'service-dashboard-tutor-subjects-list'"
				class="tw-pl-6 tw-pr-10"
			/>
			<section class="tw-px-5 tw-pt-8 tw-pb-0">
				<span class="tw-font-bold tw-text-base">1-Day Shift Schedule</span>
			</section>
			<ServiceScheduleTutorScheduleEdit
				v-if="isSelectedTutorScheduleLoaded"
				v-jest="'service-dashboard-tutor-schedule'"
				class="tw-pl-3 tw-pr-10 tw-pb-8 tw-pt-0"
			/>
		</div>
	</Drilldown>
</template>
<script>
import { mapState } from "vuex";
import { isEmpty } from "lodash";
import { startOfDay, endOfDay } from "date-fns";

import Drilldown from "@/components/patterns/Drilldown.vue";
import { ServiceScheduleTutorScheduleEdit } from "@/modules/ServiceSchedule/index.js";

import TutorSubjectsList from "./TutorSubjectsList.vue";
import TutorProfileDetails from "./TutorProfileDetails.vue";

export default {
	components: {
		Drilldown,
		TutorProfileDetails,
		TutorSubjectsList,
		ServiceScheduleTutorScheduleEdit,
	},
	computed: {
		...mapState([
			"PlatformManager",
		]),
		isTutorQuickEditOpen() {
			return this.PlatformManager.ServiceSummaryDashboard.isTutorQuickEditOpen;
		},
		isSelectedTutorScheduleLoaded() {
			return !isEmpty(this.PlatformManager.ServiceSummaryDashboard.selectedTutorSchedule);
		},
	},
	watch: {
		async isTutorQuickEditOpen(newValue, oldValue) {
			if (newValue && !oldValue) {
				await this.$store.dispatch("PlatformManager/ServiceSummaryDashboard/getSelectedTutorSchedule");

				const now = new Date();
				await this.$store.dispatch("TutorSchedule/getShifts", {
					userId: this.PlatformManager.ServiceSummaryDashboard.selectedTutor.id,
					startDate: startOfDay(now),
					endDate: endOfDay(now),
				});
			}
		},
	},
	methods: {
		closeTutorProfile() {
			this.$store.dispatch("PlatformManager/ServiceSummaryDashboard/resetSelectedTutorSchedule");
			this.$store.dispatch("PlatformManager/ServiceSummaryDashboard/setTutorQuickEditOpen", false);
		},
	},
};
</script>
