<template>
	<WidgetWrapper
		v-jest="'wrapper'"
		:is-loading="isLoading"
	>
		<template #header>
			<WidgetHeader
				v-jest="'achievements-list'"
				:is-loading-points="$store.state.Achievements.isLoading"
				:points="$store.getters['Achievements/points']"
			/>
		</template>
		<template
			v-if="isLoading"
			#loading
		>
			<BaseLoadingState />
		</template>
		<template
			v-if="!isLoading"
			#circle
		>
			<BaseCircle
				class="tw-h-full"
				:progress="challengeProgress"
				:total="challengeTotal"
			>
				<IconChallengeCompleted
					v-if="isChallengeCompleted"
					v-jest="'completion-icon'"
					class="tw-min-w-full tw-min-h-full tw-p-2"
				/>
				<AvatarIcon
					v-else
					v-jest="'avatar'"
					:user="profile"
					class="tw-min-w-full tw-min-h-full"
				/>
			</BaseCircle>
		</template>
		<template
			v-if="!isLoading"
			#goals
		>
			<div
				v-if="hasError"
				v-jest="'error-state'"
				class="tw-flex tw-items-center tw-justify-center tw-flex-col tw-gap-y-4 tw-text-center tw-h-full"
			>
				<p class="tw-font-bold tw-text-md">
					{{ $t("challenges.could_not_load") }}
				</p>
				<OrigamiButton
					v-jest="'try-again-button'"
					variant="secondary"
					:is-disabled="isLoading"
					@click.native="getData"
				>
					<span>{{ $t("challenges.try_again") }}</span>
				</OrigamiButton>
			</div>
			<Challenge
				v-else
				v-jest="'list'"
				:challenge-completed="isChallengeCompleted"
				:challenge="Challenges.challenge"
				:goals="goals"
			/>
		</template>
	</WidgetWrapper>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { OrigamiButton } from "@origami/vue2";
import { mapState } from "vuex";

import AvatarIcon from "@/modules/Avatar/components/AvatarIcon.vue";
import BaseLoadingState from "@/components/BaseLoadingState.vue";
import IconChallengeCompleted from "@/components/icons/IconChallengeCompleted.vue";

import Challenge from "./Challenge.vue";
import WidgetWrapper from "./WidgetWrapper.vue";
import WidgetHeader from "./WidgetHeader.vue";

export const DEFAULT_TOTAL_SEGMENTS = 4;
export const DEFAULT_AVATAR = {
	avatar: {
		name: "Elephant",
	},
};

const BaseCircle = defineAsyncComponent(() => import("./BaseCircle.vue"));

export default {
	components: {
		AvatarIcon,
		BaseCircle,
		BaseLoadingState,
		Challenge,
		WidgetWrapper,
		WidgetHeader,
		IconChallengeCompleted,
		OrigamiButton,
	},
	data() {
		return {
			isLoading: false,
		};
	},
	computed: {
		...mapState("Student", ["Profile", "Challenges"]),
		goals() {
			return this.Challenges.challenge?.goals || [];
		},
		challengeProgress() {
			return this.goals.reduce((acc, current) => {
				return acc += current.completed > current.target ?
					current.target :
					current.completed;
			}, 0);
		},
		challengeTotal() {
			if (!this.goals.length) {
				return DEFAULT_TOTAL_SEGMENTS;
			}
			return this.goals.reduce((acc, current) => current.target + acc, 0);
		},
		profile() {
			return this.Profile.profile.avatar ? this.Profile.profile : DEFAULT_AVATAR;
		},
		isChallengeCompleted() {
			return this.goals.length > 0 && this.challengeProgress === this.challengeTotal;
		},
		hasError() {
			return !this.Challenges.challenge || !Boolean(this.goals.length);
		},
	},
	async created() {
		await this.getData();
	},
	methods: {
		async getData() {
			this.isLoading = true;
			await this.$store.dispatch("Student/Challenges/getChallengesData");
			this.isLoading = false;
		},
	},
};
</script>
