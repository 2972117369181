import confetti from "canvas-confetti";

export function randomInRange(min, max) {
	return Math.random() * (max - min) + min;
}

export function originateFromElement(el) {
	const coords = el?.getBoundingClientRect() || {};
	const x = (coords.left + coords.width / 2) / window.innerWidth || undefined;
	const y = (coords.top + coords.height / 2) / window.innerHeight || undefined;
	return {
		origin: { x, y },
	};
}

export function celebrate(options = {}) {
	return confetti({
		disableForReducedMotion: true,
		zIndex: 999999,
		...options,
	});
}
export default celebrate;
