<template>
	<div>
		<p
			v-jest="'message-text'"
			class="tw-text-base tw-mt-4 tw-pl-6 tw-pr-0 tw-py-0"
		>
			{{ successMessage.message }}
		</p>
		<div
			v-if="shift"
			v-jest="'shift-details-container'"
		>
			<p
				v-jest="'shift-type-name'"
				:style="shiftScheduleTypeColor"
				class="tw-flex tw-text-center tw-w-max tw-ml-6 tw-my-4 tw-px-1 tw-py-0 tw-rounded-md tw-font-bold tw-text-sm"
			>
				{{ shiftTypeName }}
			</p>
			<div class="tw-pl-6 tw-pr-4 tw-py-0 tw-text-sm">
				<p class="tw-pb-2 tw-pt-0 tw-px-0 tw-font-bold">
					{{ name }} [{{ shift.id }}]
				</p>
				<p
					v-jest="'shift-start-time'"
					class="tw-pb-2 tw-pt-0 tw-px-0"
				>
					{{ formattedShiftStartTime }}
				</p>
				<p v-jest="'shift-end-time'">
					{{ formattedShiftEndTime }}
				</p>
			</div>
		</div>
		<div
			v-if="updatedShift"
			v-jest="'updated-shift-details-container'"
		>
			<p class="tw-flex tw-pt-4 tw-pb-2 tw-ml-6 tw-text-base">
				to:
			</p>
			<p
				v-jest="'updated-shift-type-name'"
				:style="updatedShiftScheduleTypeColor"
				class="tw-flex tw-text-center tw-w-max tw-ml-6 tw-my-4 tw-px-1 tw-py-0 tw-rounded-md tw-font-bold tw-text-sm"
			>
				{{ updatedShiftTypeName }}
			</p>
			<div class="tw-pl-6 tw-pr-4 tw-py-0 tw-text-sm">
				<p class="tw-pb-2 tw-pt-0 tw-px-0 tw-font-bold">
					{{ name }} [{{ shift.id }}]
				</p>
				<p
					v-jest="'updated-shift-start-time'"
					class="tw-pb-2 tw-pt-0 tw-px-0"
				>
					{{ formattedUpdatedShiftStartTime }}
				</p>
				<p v-jest="'updated-shift-end-time'">
					{{ formattedUpdatedShiftEndTime }}
				</p>
			</div>
		</div>
		<div class="tw-flex tw-pt-6 tw-pb-4 tw-px-6">
			<OrigamiButton
				v-jest="'return-btn'"
				v-data-cy="'return-btn'"
				variant="primary"
				class="tw-w-full tw-justify-center"
				@click.native="$emit('cancel-edit')"
			>
				{{ successMessage.buttonText }}
			</OrigamiButton>
		</div>
	</div>
</template>

<script>
import { format } from "date-fns";
import { isNil } from "lodash";
import { OrigamiButton } from "@origami/vue2";

import { getShiftTypeNameFromShiftTypeId, getSlotColorFromShiftType } from "@/utilities/ScheduleHelpers.js";
import { formatTimestampToDate } from "@/utilities/dateHelpers.js";

export default {
	components: {
		OrigamiButton,
	},
	props: {
		shift: {
			type: Object,
			required: true,
		},
		updatedShift: {
			type: Object,
			required: false,
			default: () => {},
		},
		successMessageInSidebar: {
			type: Object,
			required: true,
		},
	},
	computed: {
		successMessage() {
			return this.successMessageInSidebar;
		},
		name() {
			return isNil(this.shift.user) ? this.shift.name
				: `${this.shift.user.data.first_name} ${this.shift.user.data.last_name}`;
		},
		formattedShiftStartTime() {
			return this.formatShiftStartTime(this.shift);
		},
		formattedShiftEndTime() {
			return this.formatShiftEndTime(this.shift);
		},
		formattedUpdatedShiftStartTime() {
			return this.formatShiftStartTime(this.updatedShift);
		},
		formattedUpdatedShiftEndTime() {
			return this.formatShiftEndTime(this.updatedShift);
		},
		shiftTypeName() {
			const scheduleTypeId = isNil(this.shift.scheduleType) ?
				this.shift.shiftType.data.id :
				this.shift.scheduleType.data.id;

			return getShiftTypeNameFromShiftTypeId(scheduleTypeId);
		},
		updatedShiftTypeName() {
			return this.updatedShift ? getShiftTypeNameFromShiftTypeId(this.updatedShift.schedule_type_id) : "";
		},
		shiftScheduleTypeColor() {
			return "background: " + getSlotColorFromShiftType(this.shiftTypeName);
		},
		updatedShiftScheduleTypeColor() {
			return this.updatedShift ? "background: " + getSlotColorFromShiftType(this.updatedShiftTypeName) : "";
		},
	},
	methods: {
		formatShiftStartTime(shift) {
			if (!shift) {
				return "";
			}

			const shiftStartTime = isNil(shift?.start_time) ? new Date(shift.start)
				: formatTimestampToDate(shift.start_time);
			return "Start: " + format(shiftStartTime, "PPpp");
		},
		formatShiftEndTime(shift) {
			if (!shift) {
				return "";
			}

			const shiftEndTime = isNil(shift?.end_time) ? new Date(shift.end)
				: formatTimestampToDate(shift.end_time);
			return "End: " + format(shiftEndTime, "PPpp");
		},
	},
};
</script>
