import { omit } from "lodash";

import RecognitionAPI from "../services/api/index.js";

const getDefaultState = () => ({
	recognitionTypes: [],
	sessionsWithRecognitions: [],
});

export const state = getDefaultState();

export const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_RECOGNITION_TYPES(state, { recognitionTypes }) {
		state.recognitionTypes = recognitionTypes;
	},
	SET_SESSIONS_WITH_RECOGNITIONS(state, { sessionsWithRecognitions }) {
		state.sessionsWithRecognitions = sessionsWithRecognitions;
	},
};

export const getters = {
	recognitionsList({ sessionsWithRecognitions }) {
		const recognitionsList = [];
		sessionsWithRecognitions.forEach((session) => {
			session.recognitions.data.forEach((recognition) => {
				recognitionsList.push({
					...recognition,
					session: omit(session, "recognitions"),
				});
			});
		});
		return recognitionsList;
	},
};

export const actions = {
	async getActiveRecognitionTypes({ state, commit }, { force = false } = {}) {
		if (state.recognitionTypes?.length && !force) {
			return;
		}
		const response = await RecognitionAPI.types({
			params: {
				is_active: true,
			},
		});
		commit("SET_RECOGNITION_TYPES", {
			recognitionTypes: response.data.data,
		});
	},
	async getRecognitions({ commit }) {
		const sessionsWithRecognitions = (await RecognitionAPI.list({
			params: {
				include: ["subject"],
			},
		})).data.data;
		commit("SET_SESSIONS_WITH_RECOGNITIONS", {
			sessionsWithRecognitions,
		});
	},
};


export default {
	namespaced: true,
	mutations,
	state,
	getters,
	actions,
};
