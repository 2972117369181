<template>
	<ActionItemWrapper
		v-jest="'action-wrapper'"
		:action-item="actionItem"
		:actions="filteredActions"
		:is-action-pending="isActionPending"
		:is-currently-inspecting="isCurrentlyInspecting"
		@handle-inspect="handleInspect"
		@handle-uninspect="handleUninspect"
		@handle-false-alarm="handleFalseAlarm"
	>
		<template #flagged-message>
			<span v-jest="'flagged-message'">
				<span
					v-if="!itemContentMessage.hasKeywords"
					v-jest="'unformatted-message'"
				>
					{{ actionItem.content.data.message }}
				</span>
				<span
					v-if="itemContentMessage.hasKeyword"
					v-jest="'keyword-message'"
					class="tw-block"
				>
					{{ itemContentMessage.message[0] }} <span class="tw-font-bold">{{ itemContentMessage.message[1] }}</span> {{ itemContentMessage.message[2] }}
				</span>
				<span
					v-if="itemContentMessage.hasEmail"
					v-jest="'email-message'"
					class="tw-block"
				>
					{{ itemContentMessage.emailMessage[0] }} <span class="tw-font-bold">{{ itemContentMessage.emailMessage[1] }}</span>
				</span>
				<span
					v-if="itemContentMessage.hasPhone"
					v-jest="'phone-message'"
					class="tw-block"
				>
					{{ itemContentMessage.phoneMessage[0] }} <span class="tw-font-bold">{{ itemContentMessage.phoneMessage[1] }}</span>
				</span>
			</span>
		</template>
		<template #flagged-user-info>
			<span
				v-jest="'message-type'"
				class="tw-font-bold tw-mr-4"
			>
				{{ messageType }}
			</span>
			<span
				v-jest="'role'"
				class="tw-font-bold"
			>
				{{ role }}:
			</span>
			<span v-jest="'sender-name'">{{ name }}</span>
			<RouterLink
				v-jest="'action-item-link'"
				:to="itemLinkPath"
				target="_blank"
				class="tw-mx-2 tw-text-blue-regular tw-underline tw-font-bold"
			>
				{{ itemLinkPathText }}
			</RouterLink>
			{{ convertedDate }}
		</template>
		<template #status-text>
			<span v-jest="'status-text'">
				{{ statusText }}
			</span>
		</template>
	</ActionItemWrapper>
</template>

<script>
import { mapState } from "vuex";

import { formatDateToTimestampYMD, formatTimestampToHM } from "@/utilities/dateHelpers.js";
import { checkIsObjectKeysPresentAndRightType } from "@/utilities/propValidationHelpers.js";
import { getErrorText, showErrorModal } from "@/utilities/errorHandlingHelpers.js";
import { getStatusText, SELF_TRANSFER_ACTION_ID, ESCALATE_ACTION_ID, RESOLVE_ACTION_ID, HIDE_ACTION_ID, formatMessageArray, formatMessage, formatEmailMessage, formatPhoneMessage } from "@/modules/ActionCenter/index.js";

import ActionItemWrapper from "./ActionItemWrapper.vue";

const requiredKeys = [
	{ property: "action_type_id", type: ["number", null] },
	{ property: "actionable_id", type: ["number"] },
	{ property: "created_at", type: ["number"] },
	{ property: "content", type: ["object"], subKeys: [
		{ property: "data", type: ["object"], subKeys: [
			{ property: "session_id", type: ["number"] },
			{ property: "session_url", type: ["string"] },
			{ property: "message_sender_role_id", type: ["number"] },
			{ property: "message_sender_user_first_name", type: ["string"] },
			{ property: "message", type: ["string"] },
			{ property: "message_formatted", type: ["string"] },
			{ property: "message_content_type_id", type: ["number"] },
		] },
	] },
	{ property: "picked_up_user_id", type: ["number", null] },
	{ property: "picked_up_at", type: ["number", null] },
	{ property: "resolved_at", type: ["number", null] },
];

const ROLE_TUTOR_ID = 2;
const ROLE_STUDENT_ID = 1;

const MESSAGE_TYPE_TEXT_ID = 1;
const MESSAGE_TYPE_AUDIO_ID = 2;

export default {
	components: {
		ActionItemWrapper,
	},
	props: {
		actionItem: {
			type: Object,
			required: true,
			validator: (item) => checkIsObjectKeysPresentAndRightType(item, requiredKeys),
		},
	},
	data() {
		return {
			actions: [
				{ id: ESCALATE_ACTION_ID, name: `Escalate`, icon: "warning-outlined", handleEvent: this.handleEscalate },
				{ id: SELF_TRANSFER_ACTION_ID, name: `Transfer`, icon: "transfer", handleEvent: this.handleTransfer },
				{ id: RESOLVE_ACTION_ID, name: `Resolve`, icon: "check-outlined", handleEvent: this.handleResolve },
				{
					id: HIDE_ACTION_ID,
					name: `Hide`,
					icon: "hide",
					color: "tw-text-origami-black",
					handleEvent: this.handleHide,
				},
			],
			isCurrentlyInspecting: false,
			isActionPending: false,
		};
	},
	computed: {
		...mapState(["currentUser", "PlatformManager"]),
		itemContentMessage() {
			const messageArray = formatMessageArray(this.actionItem.content.data.message_formatted);

			const messageContent = {
				hasEmail: messageArray.some((str) => str.includes("The following emails were shared in the classroom: ")),
				hasKeyword: messageArray.some((str) => str.includes("Message: ")),
				hasPhone: messageArray.some((str) => str.includes("The following phone numbers were shared in the classroom: ")),
				message: formatMessage(messageArray),
				phoneMessage: formatPhoneMessage(messageArray),
				emailMessage: formatEmailMessage(messageArray),
			};
			messageContent.hasKeywords = messageContent.hasEmail ||
				messageContent.hasKeyword ||
				messageContent.hasPhone;

			return messageContent;
		},
		itemLinkPathText() {
			return `Session ID #${this.actionItem.content.data.session_id}`;
		},
		convertedDate() {
			return `${formatDateToTimestampYMD(this.actionItem.created_at)} ${formatTimestampToHM(this.actionItem.created_at)}`;
		},
		itemLinkPath() {
			return `/${this.currentUser.role}/activity?sessionId=[${this.actionItem.content.data.session_id}]&tab=Sessions`;
		},
		statusText() {
			return getStatusText(this.actionItem, this.isPickedUpByCurrentUser);
		},
		messageType() {
			const messageTypesMap = {
				[MESSAGE_TYPE_TEXT_ID]: "Text",
				[MESSAGE_TYPE_AUDIO_ID]: "Audio",
			};
			return messageTypesMap[this.actionItem.content.data.message_content_type_id] || "Text";
		},
		role() {
			const rolesMap = {
				[ROLE_STUDENT_ID]: "Student",
				[ROLE_TUTOR_ID]: "Tutor",
			};
			return rolesMap[this.actionItem.content.data.message_sender_role_id] || "Student";
		},
		name() {
			return this.actionItem.content.data.message_sender_user_first_name;
		},
		isPickedUpByCurrentUser() {
			return this.actionItem.picked_up_user_id === this.currentUser.id;
		},
		filteredActions() {
			return this.actions.filter((action) => action.id !== (this.isPickedUpByCurrentUser ? 0 : -1));
		},
	},
	async created() {
		this.actions.sort((segmentA, segmentB) => segmentA.id - segmentB.id);
		this.isCurrentlyInspecting = this.isPickedUpByCurrentUser;
	},
	methods: {
		async handleInspect() {
			this.isActionPending = true;
			const payload = {
				id: this.actionItem.id,
				params: {
					picked_up_user_id: this.currentUser.id,
				},
			};
			try {
				await this.$store.dispatch("PlatformManager/ActionCenter/updateActionItem", payload);
				this.isCurrentlyInspecting = true;
			} catch (error) {
				const message = getErrorText("updating the action item");
				showErrorModal(this, {
					message,
				});
			} finally {
				this.isActionPending = false;
			}
		},
		async handleUninspect() {
			this.isActionPending = true;
			const payload = {
				id: this.actionItem.id,
				params: {
					unassign: true,
				},
			};
			try {
				await this.$store.dispatch("PlatformManager/ActionCenter/updateActionItem", payload);
				this.isCurrentlyInspecting = false;
			} catch (error) {
				const message = getErrorText("uninspecting the action item");
				showErrorModal(this, {
					message,
				});
			} finally {
				this.isActionPending = false;
			}
		},
		async handleEscalate() {
			this.isActionPending = true;
			const payload = {
				id: this.actionItem.id,
				params: {
					action_type_id: ESCALATE_ACTION_ID,
					picked_up_user_id: this.currentUser.id,
				},
			};
			try {
				await this.$store.dispatch("PlatformManager/ActionCenter/updateActionItem", payload);
			} catch (error) {
				const message = getErrorText("updating the action item");
				showErrorModal(this, {
					message,
				});
			} finally {
				this.isActionPending = false;
			}
		},
		handleTransfer(payload) {
			const transferSessionData = {
				sessionId: payload.sessionId,
				sessionName: "",
				outgoingTutorId: this.actionItem.content.data.user_id,
				schoolRegionCode: null,
			};
			this.$store.commit("PlatformManager/ServiceDashboardSummary/SET_TRANSFER_SESSION_DATA", {
				transferSessionData,
			});
			this.$store.commit("PlatformManager/ActionCenter/SELECT_ACTION_ITEM", this.actionItem);
			this.$bvModal.show("transfer-action-item");
		},
		handleFalseAlarm() {
			this.$store.commit("PlatformManager/ActionCenter/SELECT_ACTION_ITEM", this.actionItem);
			this.$store.commit("PlatformManager/ActionCenter/OPEN_FALSE_ALARM_DIALOG");
		},
		handleResolve() {
			this.$store.commit("PlatformManager/ActionCenter/SELECT_ACTION_ITEM", this.actionItem);
			this.$store.commit("PlatformManager/ActionCenter/OPEN_RESOLVE_DIALOG");
		},
		handleHide() {
			this.$store.commit("PlatformManager/ActionCenter/SELECT_ACTION_ITEM", this.actionItem);
			this.$store.commit("PlatformManager/ActionCenter/OPEN_HIDE_DIALOG");
		},

	},
};
</script>
