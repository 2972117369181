<script>
import { ESSAY_REVIEW } from "../utilities/constants/schedulesTypeIDs.js";

import ServiceDashboardTutorCardHeaderLiveTutor from "./ServiceDashboardTutorCardHeaderLiveTutor.vue";

export default {
	extends: ServiceDashboardTutorCardHeaderLiveTutor,
	computed: {
		isGreyedOut() {
			return false;
		},
		shiftType() {
			return this.shift.schedule_type_id === ESSAY_REVIEW ? "Review Center" : "Live Help";
		},
		extraInfo() {
			return {
				class: "tutorCardInfo__shiftInfo--shiftEndingColor",
				text: `Late ${this.shiftType}`,
			};
		},
	},
};
</script>
