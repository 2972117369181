<template>
	<div
		v-jest="'insight-card-container'"
		class="insightCard tw-w-full tw-border tw-border-solid tw-border-grey-dark tw-pt-4 tw-bg-white-high"
		:class="isExpanded ? 'tw-h-auto tw-order-first tw-pb-2' : 'insightCard__collapsed tw-w-2/5 tw-h-56 tw-flex-grow'"
	>
		<div
			class="tw-px-6 tw-w-full"
			:class="isExpanded ? 'tw-pb-4' : ''"
		>
			<div class="tw-flex tw-justify-between tw-w-full tw-gap-x-3 tw-border-b-2 tw-border-solid tw-border-grey-regular tw-mb-2 tw-py-2 tw-items-center">
				<div class="tw-flex tw-flex-grow tw-justify-start tw-items-center  tw-flex-shrink tw-truncate">
					<h4
						v-jest="'card-title'"
						class="tw-text-lg tw-font-bold tw-leading-normal tw-truncate tw-text-black-high"
					>
						{{ card.title }} ({{ card.number }})
					</h4>
				</div>

				<BaseIconButton
					v-if="isExpanded"
					id="collapse"
					ref="collapse-card-button"
					v-jest="'collapse-card-button'"
					class="insightCard__collapseButton tw-flex-shrink-0"
					@click="handleCollapse"
				>
					<template #default>
						<span class="tw-text-base tw-leading-6 tw-text-blue-regular tw-font-bold tw-mb-0 tw-flex tw-items-center tw-gap-x-3">
							Close
							<IconCircleX
								class="tw-w-5 tw-h-5 tw-text-blue-regular"
								:icon-color="'currentColor'"
								:width="20"
								:height="20"
							/>
						</span>
					</template>
				</BaseIconButton>
			</div>
			<p
				v-jest="'card-description'"
				class="tw-text-base tw-leading-normal tw-mb-5 tw-truncate tw-whitespace-normal tw-text-black-high"
				:class="isExpanded ? '' : 'insightCard__description'"
			>
				{{ card.description }}
			</p>
			<div
				v-if="isExpanded"
				class="card__behaviour tw-border tw-border-solid tw-border-grey-dark tw-p-6"
			>
				<div
					class="card__filtersContainer tw-flex tw-gap-x-4 tw-border-b-2 tw-border-grey-regular tw-border-solid tw-pb-6 tw-items-center tw-mr-6"
				>
					<div>
						<BaseCheckbox
							v-model="isShowingOwnedItemsOnly"
							v-jest="'show-owned-items-filter'"
							name="ownedFilter"
							:checkbox-value="isShowingOwnedItemsOnly"
							checkbox-id="ownedFilter"
						>
							<template
								slot="text"
							>
								Show my action items only
							</template>
						</BaseCheckbox>
					</div>
					<template
						v-if="isShowingFilters"
					>
						<p class="tw-text-xs tw-text-black-high tw-font-bold">
							Filters:
						</p>
						<BaseDropdown
							class="insightCard__dropdown tw-w-24 tw-rounded-full tw-self-start"
							:items="statusItems"
							:close-on-select="true"
							:is-disabled="true"
						>
							<template #filters>
								Status
							</template>
							<template slot-scope="{ item }">
								{{ item.name }}
							</template>
						</BaseDropdown>
						<BaseDropdown
							class="insightCard__dropdown tw-w-24 tw-rounded-full tw-self-start"
							:items="userItems"
							:close-on-select="true"
							:is-disabled="true"
						>
							<template #filters>
								Users
							</template>
							<template slot-scope="{ item }">
								{{ item.name }}
							</template>
						</BaseDropdown>
					</template>
				</div>
				<div
					v-if="hasNoActionItems"
					v-jest="'empty-card-state'"
					class="items__container tw-w-full tw-flex tw-justify-center tw-items-center tw-px-4"
				>
					<BaseEmptyState
						class="tw-flex tw-items-center tw-justify-center tw-flex-col tw-gap-y-3"
						:header="'Sorry, no results were found'"
						:sub-header="'Adjust your filter options and try again'"
					>
						<template #icon>
							<OrigamiIcon
								class="tw-text-origami-grey-200"
								name="flag"
								width="39"
								height="44"
							/>
						</template>
						<template #cta>
							<OrigamiButton
								v-jest="'try-again-button'"
								variant="secondary"
								:is-disabled="isLoading"
								class="tw-h-12"
								@click.native="handleTryAgain"
							>
								<LoadingRectangles v-if="isLoading" />
								<span v-else>Try Again</span>
							</OrigamiButton>
						</template>
					</BaseEmptyState>
				</div>
				<div
					v-else
					v-jest="'card-with-actions'"
					class="items__container items__container--actions tw-overflow-y-scroll tw-overflow-x-hidden"
				>
					<slot
						v-if="isExpanded"
						name="content"
					/>
				</div>
			</div>
		</div>
		<button
			v-if="!isExpanded"
			ref="expandButton"
			v-jest="'expand-card-button'"
			class="insightCard__button tw-border-t tw-border-solid tw-border-grey-regular tw-w-full tw-py-4 tw-text-center tw-text-blue-regular tw-text-base tw-font-bold tw-truncate"
			@click="handleExpand"
		>
			See full list
		</button>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { OrigamiButton, OrigamiIcon } from "@origami/vue2";

import BaseCheckbox from "@/components/BaseCheckbox.vue";
import BaseEmptyState from "@/components/BaseEmptyState.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";

import IconCircleX from "../../../components/icons/IconCircleX.vue";
import BaseIconButton from "../../../components/elements/BaseIconButton.vue";
import LoadingRectangles from "../../../components/LoadingRectangles.vue";
import { checkIsObjectKeysPresentAndRightType } from "../../../utilities/propValidationHelpers.js";
import DispatchErrorHandlingMixin from "../../../mixins/DispatchErrorHandlingMixin.js";
import { ActionItemActionsMapping } from "../utilities/actionCenterHelpers.js";

const requiredKeys = [
	{ property: "title", type: ["string"] },
	{ property: "description", type: ["string"] },
	{ property: "number", type: ["number"] },
];

export default {
	components: {
		BaseIconButton,
		IconCircleX,
		LoadingRectangles,
		OrigamiButton,
		OrigamiIcon,
		BaseCheckbox,
		BaseEmptyState,
		BaseDropdown,
	},
	mixins: [DispatchErrorHandlingMixin],
	props: {
		card: {
			type: Object,
			required: true,
			validator: (card) => checkIsObjectKeysPresentAndRightType(card, requiredKeys),
		},
		actionItems: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			isLoading: false,
			isShowingFilters: false,
			isShowingOwnedItemsOnly: false,
			statusItems: [
				{ name: "Open", id: 1 },
				{ name: "Being inspected", id: 2 },
				{ name: "Escalated", id: 13 },
			],
			userItems: [],
		};
	},
	computed: {
		...mapState(["PlatformManager"]),
		isExpanded() {
			return this.expandedCards[this.currentActionCenterTab] === this.card.title;
		},
		currentActionCenterTab() {
			return this.PlatformManager.ActionCenter.currentActionCenterTab;
		},
		expandedCards() {
			return this.PlatformManager.ActionCenter.expandedCards;
		},
		hasNoActionItems() {
			return this.actionItems.length === 0;
		},
	},
	watch: {
		isShowingOwnedItemsOnly(val) {
			this.$store.dispatch("PlatformManager/ActionCenter/showOnlyOwnedActionItems", val);
		},
	},
	methods: {
		async handleCollapse() {
			this.$store.dispatch("PlatformManager/ActionCenter/collapseCardInCurrentTab");
			this.isShowingOwnedItemsOnly = false;
			await this.$nextTick();
			await this.$nextTick();
			this.$refs.expandButton.focus();
		},
		async handleExpand() {
			this.$store.dispatch("PlatformManager/ActionCenter/expandCard", this.card.title);
			await this.$nextTick();
			await this.$nextTick();
			this.$refs["collapse-card-button"].$el.focus();
		},
		async handleTryAgain() {
			this.isLoading = true;
			const actionName = ActionItemActionsMapping[this.card.id];
			const requestFilters = {
				is_resolved: 0,
				order_by: "id.asc",
			};
			await this.handleDispatch(`PlatformManager/ActionCenter/${actionName}`, requestFilters, "retrieving action center items");
			this.isLoading = false;
		},
	},
};
</script>

<style scoped>
.insightCard {
	border-radius: 1.25rem;
	box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.insightCard__collapsed {
	min-width: 15rem;
}

.insightCard .insightCard__collapseButton {
	height: auto;
	width: auto;
}
.insightCard__description {
	height: 4.5rem;
}
.insightCard__button {
	border-radius: 0 0 1.25rem 1.25rem;
}

.card__behaviour {
	height: 45rem;
	position: relative;
}
.insightCard__dropdown :deep(.mainDropdown) {
	border-radius: 9999px !important;
	display: flex;
}
.card__filtersContainer {
	width: calc(100% - 2rem);
}
.items__container {
	height: 38rem;
	max-height: 38rem;
}
.items__container--actions {
	scrollbar-gutter: stable;
	scrollbar-color: var(--grey-dark) var(--grey-pale);
	scrollbar-width: thin;
	padding-right: 1.5rem;
}
.items__container::-webkit-scrollbar {
	background-color: var(--white-high);
	width: 0.5rem;
}
.items__container::-webkit-scrollbar-thumb {
	background-color: var(--grey-dark);
	border-radius: 999px;
}
.items__container .emptyState .outline {
	border-color: var(--origami-grey-300);
	color: var(--origami-black);
}
.items__container .emptyState .outline:hover,
.items__container .emptyState .outline:active,
.items__container .emptyState .outline:focus {
	border-color: var(--origami-grey-400);
	background-color: var(--origami-grey-400);
	color: var(--origami-white);
}
</style>

<style>
.items__container .emptyState h1,
.items__container .emptyState p {
	font-size: 1rem;
	line-height: 1.5;
	margin-bottom: 0;
	padding: 0;
}
.items__container .emptyState h1 {
	font-weight: 700;
}
.items__container .emptyState p {
	color: var(--origami-black);
	font-weight: 400;
}
.items__container .emptyState .icon {
	margin-bottom: 0;
	width: 44px;
}
</style>
