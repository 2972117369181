var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 16 20",
      },
    },
    [
      _c("defs", [
        _c("path", {
          attrs: {
            id: `a${_vm.randomId}`,
            d: "M16 7.059h-4.571V0H4.57v7.059H0l8 8.235 8-8.235zM0 17.647V20h16v-2.353H0z",
          },
        }),
      ]),
      _vm._v(" "),
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("mask", { attrs: { id: _vm.randomId, fill: `${_vm.iconColor}` } }, [
          _c("use", { attrs: { "xlink:href": `#a${_vm.randomId}` } }),
        ]),
        _vm._v(" "),
        _c("use", {
          attrs: {
            fill: `${_vm.iconColor}`,
            "xlink:href": `#a${_vm.randomId}`,
          },
        }),
        _vm._v(" "),
        _c(
          "g",
          {
            attrs: { fill: `${_vm.iconColor}`, mask: `url(#${_vm.randomId})` },
          },
          [_c("path", { attrs: { d: "M-3.429-1.176h23v24h-23z" } })]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }