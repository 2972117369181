var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "tw-w-full tw-p-4 tw-flex tw-flex-col tw-gap-4 tw-bg-origami-grey-100 tw-rounded-2xl tw-px-6 tw-pt-6 tw-pb-10",
      class: { "tw-items-center": _vm.isLoadingRecognitionTypes },
    },
    [
      _vm.isLoadingRecognitionTypes
        ? _c("BaseLoader", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "loader",
                expression: "'loader'",
              },
            ],
            staticClass: "tw-h-16 tw-w-16 tw-text-blue-regular",
          })
        : [
            _c("p", { staticClass: "tw-font-origami-bold" }, [
              _vm._v("\n\t\t\tSelect stickers\n\t\t"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tw-flex tw-gap-4 tw-flex-wrap" },
              _vm._l(_vm.recognitionTypes, function (recognitionType) {
                return _c(
                  "OrigamiCard",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "recognition-card",
                        expression: "'recognition-card'",
                      },
                    ],
                    key: recognitionType.id,
                    staticClass:
                      "tw-flex tw-gap-4 tw-p-4 tw-w-44 tw-items-center",
                    attrs: {
                      "is-selected": recognitionType.selected,
                      "is-interactive": "",
                    },
                    nativeOn: {
                      click: function ($event) {
                        recognitionType.selected = !recognitionType.selected
                      },
                    },
                  },
                  [
                    _c("Emoji", {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "recognition-emoji",
                          expression: "'recognition-emoji'",
                        },
                      ],
                      staticClass: "tw-text-2xl",
                      attrs: {
                        emoji:
                          _vm.RECOGNITION_ICON_COMPONENTS[recognitionType.id] ||
                          _vm.RECOGNITION_ICON_COMPONENTS[1],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "recognition-name",
                            expression: "'recognition-name'",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.upperFirst(recognitionType.name)) +
                            "\n\t\t\t\t"
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              1
            ),
            _vm._v(" "),
            _vm.isShowingErrorCard
              ? _c(
                  "OrigamiCard",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "error-card",
                        expression: "'error-card'",
                      },
                    ],
                    staticClass:
                      "tw-p-4 tw-bg-origami-red-100 tw-text-origami-red-400 tw-text-center",
                  },
                  [_c("p", [_vm._v("At least one sticker must be selected")])]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("p", [
              _c("span", { staticClass: "tw-font-origami-bold" }, [
                _vm._v("Add a message "),
              ]),
              _vm._v("(optional)\n\t\t"),
            ]),
            _vm._v(" "),
            _c("BaseTextArea", {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "recognitions-message",
                  expression: "'recognitions-message'",
                },
              ],
              staticClass: "tw-h-20",
              attrs: {
                placeholder: _vm.textAreaPlaceHolder,
                "auto-resize": "",
                disabled: !_vm.selectedRecognitions.length,
              },
              on: {
                input: function ($event) {
                  _vm.message = $event
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tw-flex tw-justify-end tw-gap-x-2" },
              [
                _c(
                  "OrigamiButton",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "cancel-button",
                        expression: "'cancel-button'",
                      },
                    ],
                    attrs: { variant: "secondary" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.$emit("cancel")
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\tCancel\n\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "OrigamiButton",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "submit-button",
                        expression: "'submit-button'",
                      },
                    ],
                    attrs: { "is-loading": _vm.isSubmittingRecognitions },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.submitRecognitions()
                      },
                    },
                  },
                  [_vm._v("\n\t\t\t\tSend to student\n\t\t\t")]
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }