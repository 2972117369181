import CoursesCreate from "./courses/courses-create.js";
import CoursesEdit from "./courses/courses-edit.js";
import CoursesList from "./courses/courses-list.js";
import DistrictsCreate from "./districts/districts-create.js";
import DistrictsEdit from "./districts/districts-edit.js";
import DistrictsList from "./districts/districts.list.js";
import SchoolsCreate from "./schools/schools-create.js";
import SchoolsEdit from "./schools/schools-edit.js";
import SchoolsList from "./schools/schools-list.js";
import SectionsCreate from "./sections/sections-create.js";
import SectionsEdit from "./sections/sections-edit.js";
import SectionsList from "./sections/sections-list.js";
import TeacherContentCreate from "./teacher-content/teacher-content-create.js";
import TeacherContentEdit from "./teacher-content/teacher-content-edit.js";
import TeacherContentList from "./teacher-content/teacher-content-list.js";
import UserEdit from "./users/users-edit.js";

export default {
	namespaced: true,
	modules: {
		CoursesCreate,
		CoursesEdit,
		CoursesList,
		DistrictsCreate,
		DistrictsEdit,
		DistrictsList,
		SchoolsCreate,
		SchoolsEdit,
		SchoolsList,
		SectionsCreate,
		SectionsEdit,
		SectionsList,
		TeacherContentCreate,
		TeacherContentEdit,
		TeacherContentList,
		UserEdit,
	},
};
