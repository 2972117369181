var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "tw-pb-9 tw-pl-12" },
    [
      _c("div", { staticClass: "xxl:tw-flex tw-justify-between" }, [
        _c(
          "div",
          { staticClass: "tw-flex tw-items-center xxl:tw-pb-8 tw-pb-1" },
          [
            _c(
              "h2",
              { staticClass: "tw-font-bold xxl:tw-text-lg tw-text-md" },
              [_vm._v("\n\t\t\t\tCurrent Tutor Session Breakdown\n\t\t\t")]
            ),
            _vm._v(" "),
            _c(_setup.InfoToolTip, {
              staticClass: "tw-ml-3 tw-leading-6",
              attrs: {
                width: "15px",
                height: "15px",
                options: {
                  content: _setup.CURRENT_TUTOR_SESSION_BREAKDOWN_TOOLTIP,
                  placement: "top",
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass:
              "xxl:tw-text-sm xxl:tw-pb-auto tw-pb-2 tw-text-xs tw-mr-16 tw-mt-0.5 tw-text-origami-grey-300",
          },
          [_vm._v("\n\t\t\tList shows only 6 tutors at a time\n\t\t")]
        ),
      ]),
      _vm._v(" "),
      _setup.props.isLeaderboardLoaded
        ? _c(
            "table",
            { staticClass: "tw-text-sm tw-h-fit tw-table-auto tw-mr-12" },
            [
              _c(
                "thead",
                { staticClass: "tw-font-bold tw-bg-origami-grey-100" },
                [
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "tw-w-1/5 tw-flex" },
                      [
                        _c(
                          _setup.OrigamiButton,
                          {
                            directives: [
                              {
                                name: "jest",
                                rawName: "v-jest",
                                value: "sort-load-score-button",
                                expression: "'sort-load-score-button'",
                              },
                              {
                                name: "data-cy",
                                rawName: "v-data-cy",
                                value: "sort-load-score-button",
                                expression: "'sort-load-score-button'",
                              },
                            ],
                            attrs: { variant: "tertiary" },
                            nativeOn: {
                              click: function ($event) {
                                return _setup.sortByTutorLoadScore.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "tw-flex tw-text-origami-black tw-text-sm",
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\tSessions\n\t\t\t\t\t\t\t"
                                ),
                                _c(_setup.OrigamiIcon, {
                                  directives: [
                                    {
                                      name: "jest",
                                      rawName: "v-jest",
                                      value: "sort-icon",
                                      expression: "'sort-icon'",
                                    },
                                  ],
                                  staticClass:
                                    "tw-text-origami-black tw-mt-1.5 tw-ml-2",
                                  attrs: {
                                    width: "12",
                                    height: "12",
                                    name: _setup.sortIcon,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("th", { staticClass: "tw-w-4/5" }, [
                      _vm._v("\n\t\t\t\t\tTutor Name\n\t\t\t\t"),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "tbody",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "leaderboard-container",
                      expression: "'leaderboard-container'",
                    },
                  ],
                },
                _vm._l(_setup.tutorLeaderboard, function (tutor) {
                  return _c(
                    "tr",
                    {
                      key: tutor.id,
                      staticClass: "tw-border-2 tw-border-origami-grey-100",
                    },
                    [
                      _c("td", { staticClass: "tw-w-1/5 tw-px-4 tw-py-2" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" + _vm._s(tutor.sessions) + "\n\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "tw-w-4/5" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" + _vm._s(tutor.name) + "\n\t\t\t\t"
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]
          )
        : _c(_setup.BaseLoader, {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "analytics-loader",
                expression: "'analytics-loader'",
              },
            ],
            staticClass:
              "tw-h-12 tw-w-12 tw-my-20 tw-mx-auto tw-text-blue-regular",
            attrs: { thickness: "8px" },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }