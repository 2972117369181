export default {
	data() {
		return {
			randomId: "A",
		};
	},
	created() {
		this.randomId = Math.random()
			.toString(27)
			.substring(2);
	},
};
