import api from "@/services/api/Api.js";

export default {
	/**
	 * Create section
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	create(data) {
		return api().post(`/sections`, data);
	},

	/**
	 * Get section
	 * @param {number} sectionId
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	show(sectionId, params) {
		return api().get(`sections/${sectionId}`, {
			params: params,
		});
	},
	/**
	 * Get filtered sections
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	filter(params) {
		return api().get(`sections.filter`, {
			params: params,
		});
	},

	/**
	 * Update section
	 * @param {number} sectionId
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	update(sectionId, data) {
		return api().post(`sections/${sectionId}`, data);
	},

	/**
	 * Get sections associated with a user id
	 * @param {number} userId
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	getUserSections(userId, params) {
		return api().get(`/users/${userId}/sections`, {
			params: params,
		});
	},
};
