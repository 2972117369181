<template>
	<div class="BaseSkeletonLoader tw-inline-flex tw-relative tw-overflow-hidden tw-rounded tw-align-middle">
		<slot>&nbsp;</slot>
	</div>
</template>

<script>
export default {
};
</script>

<style scoped>
.BaseSkeletonLoader {
	line-height: inherit;
	background-color: rgba(0, 0, 0, 0.1);
}
.BaseSkeletonLoader::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	background: linear-gradient(-45deg, transparent 25%, white, transparent 75%);
	transform: translateX(-100%);
	animation: loading 1.5s infinite;
	opacity: 0.5;
}
@keyframes loading {
	to {
		transform: translateX(100%);
	}
}
</style>
