import rootInstance from "@/services/root.js";

const liveHelpTutorAssistantAPI = () => {
	rootInstance.defaults.baseURL =
		process.env.MIX_LIVE_HELP_TUTOR_ASSISTANT_API_BASE_URI + "/api/v1";
	return rootInstance;
};

export default {
	getSuggestion({ type, session_id }) {
		return liveHelpTutorAssistantAPI().get(
			`/suggestions?type=${type}&session_id=${session_id}`,
		);
	},
};
