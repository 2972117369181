<template>
	<div class="tw-flex tw-flex-col">
		<span
			v-if="isTitleShowing"
			v-data-cy="'tutor-shift-summary'"
			class="tw-font-bold	tw-text-base"
		>
			24hr Shift summary
		</span>
		<div class="tw-bg-origami-white tw-text-origami-black tw-rounded-md tw-px-2 tw-pb-6 tw-mt-2 tw-flex-wrap shiftSummary__content overflow-auto">
			<div
				v-if="isLoadingTheUpcomingShifts"
				v-jest="'shift-summary-loading'"
				class="shiftSummary__loadingState tw-mt-4"
			>
				<div class="shiftSummary__loadingAnimation" />
				<div class="shiftSummary__loadingAnimation" />
			</div>
			<TutorDashboardShiftSummaryNoShift
				v-else-if="isNoUpcomingShift"
				v-data-cy="'tutor-dashboard-shift-summary-no-shift'"
			/>
			<div>
				<ShiftSummaryItem
					v-for="shiftSegment in upcomingSegments"
					:key="shiftSegment.id"
					:shift-segment="shiftSegment"
				/>
			</div>
		</div>
	</div>
</template>
<script>
import { mapState } from "vuex";
import { isEmpty } from "lodash-es";

import ShiftSummaryItem from "./ShiftSummaryItem.vue";
import TutorDashboardShiftSummaryNoShift from "./TutorDashboardShiftSummaryNoShift.vue";

export default {
	components: {
		ShiftSummaryItem,
		TutorDashboardShiftSummaryNoShift,
	},
	props: {
		isTitleShowing: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		...mapState(["currentUser", "TutorShiftSummary", "TutorSchedule"]),
		isNoUpcomingShift() {
			return isEmpty(this.upcomingSegments);
		},
		isLoadingTheUpcomingShifts() {
			return this.TutorSchedule.isLoadingShifts && isEmpty(this.upcomingSegments);
		},
		upcomingSegments() {
			return this.TutorSchedule.shifts.flatMap((shift) => shift.shift_segments);
		},
	},
	async created() {
		await this.$store.dispatch("TutorSchedule/getCurrentUserUpcomingShifts");
	},
};
</script>
<style scoped>
.shiftSummary__content {
	max-height: 11.5rem;
}
.shiftSummary__loadingState {
	height: 100%;
	justify-content: space-around;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.shiftSummary__loadingAnimation {
	height: 40px;
	width: 80%;
	animation-name: placeholderAnimation;
	animation-duration: 8.1s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	background: linear-gradient(to right, #eee, #fff, #eee);
	background-size: 800px 104px;
	position: relative;
}
@keyframes placeholderAnimation {
	from {
		background-position: 0;
	}
	to {
		background-position: 468px;
	}
}
</style>
