var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "insight-card-container",
          expression: "'insight-card-container'",
        },
      ],
      staticClass:
        "insightCard tw-w-full tw-border tw-border-solid tw-border-grey-dark tw-pt-4 tw-bg-white-high",
      class: _vm.isExpanded
        ? "tw-h-auto tw-order-first tw-pb-2"
        : "insightCard__collapsed tw-w-2/5 tw-h-56 tw-flex-grow",
    },
    [
      _c(
        "div",
        {
          staticClass: "tw-px-6 tw-w-full",
          class: _vm.isExpanded ? "tw-pb-4" : "",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "tw-flex tw-justify-between tw-w-full tw-gap-x-3 tw-border-b-2 tw-border-solid tw-border-grey-regular tw-mb-2 tw-py-2 tw-items-center",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "tw-flex tw-flex-grow tw-justify-start tw-items-center tw-flex-shrink tw-truncate",
                },
                [
                  _c(
                    "h4",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "card-title",
                          expression: "'card-title'",
                        },
                      ],
                      staticClass:
                        "tw-text-lg tw-font-bold tw-leading-normal tw-truncate tw-text-black-high",
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.card.title) +
                          " (" +
                          _vm._s(_vm.card.number) +
                          ")\n\t\t\t\t"
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.isExpanded
                ? _c("BaseIconButton", {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "collapse-card-button",
                        expression: "'collapse-card-button'",
                      },
                    ],
                    ref: "collapse-card-button",
                    staticClass: "insightCard__collapseButton tw-flex-shrink-0",
                    attrs: { id: "collapse" },
                    on: { click: _vm.handleCollapse },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "tw-text-base tw-leading-6 tw-text-blue-regular tw-font-bold tw-mb-0 tw-flex tw-items-center tw-gap-x-3",
                                },
                                [
                                  _vm._v("\n\t\t\t\t\t\tClose\n\t\t\t\t\t\t"),
                                  _c("IconCircleX", {
                                    staticClass:
                                      "tw-w-5 tw-h-5 tw-text-blue-regular",
                                    attrs: {
                                      "icon-color": "currentColor",
                                      width: 20,
                                      height: 20,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      561517802
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "card-description",
                  expression: "'card-description'",
                },
              ],
              staticClass:
                "tw-text-base tw-leading-normal tw-mb-5 tw-truncate tw-whitespace-normal tw-text-black-high",
              class: _vm.isExpanded ? "" : "insightCard__description",
            },
            [_vm._v("\n\t\t\t" + _vm._s(_vm.card.description) + "\n\t\t")]
          ),
          _vm._v(" "),
          _vm.isExpanded
            ? _c(
                "div",
                {
                  staticClass:
                    "card__behaviour tw-border tw-border-solid tw-border-grey-dark tw-p-6",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card__filtersContainer tw-flex tw-gap-x-4 tw-border-b-2 tw-border-grey-regular tw-border-solid tw-pb-6 tw-items-center tw-mr-6",
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "BaseCheckbox",
                            {
                              directives: [
                                {
                                  name: "jest",
                                  rawName: "v-jest",
                                  value: "show-owned-items-filter",
                                  expression: "'show-owned-items-filter'",
                                },
                              ],
                              attrs: {
                                name: "ownedFilter",
                                "checkbox-value": _vm.isShowingOwnedItemsOnly,
                                "checkbox-id": "ownedFilter",
                              },
                              model: {
                                value: _vm.isShowingOwnedItemsOnly,
                                callback: function ($$v) {
                                  _vm.isShowingOwnedItemsOnly = $$v
                                },
                                expression: "isShowingOwnedItemsOnly",
                              },
                            },
                            [
                              _c("template", { slot: "text" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\tShow my action items only\n\t\t\t\t\t\t"
                                ),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isShowingFilters
                        ? [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "tw-text-xs tw-text-black-high tw-font-bold",
                              },
                              [_vm._v("\n\t\t\t\t\t\tFilters:\n\t\t\t\t\t")]
                            ),
                            _vm._v(" "),
                            _c("BaseDropdown", {
                              staticClass:
                                "insightCard__dropdown tw-w-24 tw-rounded-full tw-self-start",
                              attrs: {
                                items: _vm.statusItems,
                                "close-on-select": true,
                                "is-disabled": true,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "filters",
                                    fn: function () {
                                      return [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\tStatus\n\t\t\t\t\t\t"
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "default",
                                    fn: function ({ item }) {
                                      return [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t" +
                                            _vm._s(item.name) +
                                            "\n\t\t\t\t\t\t"
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1663044203
                              ),
                            }),
                            _vm._v(" "),
                            _c("BaseDropdown", {
                              staticClass:
                                "insightCard__dropdown tw-w-24 tw-rounded-full tw-self-start",
                              attrs: {
                                items: _vm.userItems,
                                "close-on-select": true,
                                "is-disabled": true,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "filters",
                                    fn: function () {
                                      return [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\tUsers\n\t\t\t\t\t\t"
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "default",
                                    fn: function ({ item }) {
                                      return [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t" +
                                            _vm._s(item.name) +
                                            "\n\t\t\t\t\t\t"
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2157623645
                              ),
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.hasNoActionItems
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: "empty-card-state",
                              expression: "'empty-card-state'",
                            },
                          ],
                          staticClass:
                            "items__container tw-w-full tw-flex tw-justify-center tw-items-center tw-px-4",
                        },
                        [
                          _c("BaseEmptyState", {
                            staticClass:
                              "tw-flex tw-items-center tw-justify-center tw-flex-col tw-gap-y-3",
                            attrs: {
                              header: "Sorry, no results were found",
                              "sub-header":
                                "Adjust your filter options and try again",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "icon",
                                  fn: function () {
                                    return [
                                      _c("OrigamiIcon", {
                                        staticClass: "tw-text-origami-grey-200",
                                        attrs: {
                                          name: "flag",
                                          width: "39",
                                          height: "44",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "cta",
                                  fn: function () {
                                    return [
                                      _c(
                                        "OrigamiButton",
                                        {
                                          directives: [
                                            {
                                              name: "jest",
                                              rawName: "v-jest",
                                              value: "try-again-button",
                                              expression: "'try-again-button'",
                                            },
                                          ],
                                          staticClass: "tw-h-12",
                                          attrs: {
                                            variant: "secondary",
                                            "is-disabled": _vm.isLoading,
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.handleTryAgain.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm.isLoading
                                            ? _c("LoadingRectangles")
                                            : _c("span", [_vm._v("Try Again")]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3057124483
                            ),
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: "card-with-actions",
                              expression: "'card-with-actions'",
                            },
                          ],
                          staticClass:
                            "items__container items__container--actions tw-overflow-y-scroll tw-overflow-x-hidden",
                        },
                        [_vm.isExpanded ? _vm._t("content") : _vm._e()],
                        2
                      ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      !_vm.isExpanded
        ? _c(
            "button",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "expand-card-button",
                  expression: "'expand-card-button'",
                },
              ],
              ref: "expandButton",
              staticClass:
                "insightCard__button tw-border-t tw-border-solid tw-border-grey-regular tw-w-full tw-py-4 tw-text-center tw-text-blue-regular tw-text-base tw-font-bold tw-truncate",
              on: { click: _vm.handleExpand },
            },
            [_vm._v("\n\t\tSee full list\n\t")]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }