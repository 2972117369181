<template>
	<div class="tw-max-w-screen-lg tw-mx-auto tw-pb-24">
		<section
			v-data-cy="'late-bio-breaks'"
			class="tw-pb-12"
			aria-label="Late Bio Breaks"
		>
			<h2 class="tw-text-3xl tw-font-extrabold tw-mb-5">
				Late Bio Breaks ({{ filteredLateBioBreaks.length }})
			</h2>
			<BaseCheckbox
				v-model="isOnlyEssayReviewBreakRequests"
				v-data-cy="'essay-break-request'"
				:checkbox-value="'essayBreakRequest'"
				:checkbox-id="'essayBreakRequest'"
			>
				<template slot="text">
					Writing Review Shifts Only
				</template>
			</BaseCheckbox>
			<div class="tw-pt-4 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-mb-12 tw-gap-x-4">
				<TutorBreakCardLateBioBreak
					v-for="lateBioBreak in filteredLateBioBreaks"
					:key="lateBioBreak.id"
					v-jest="'late-bio-break'"
					:bio-break="lateBioBreak"
				/>
			</div>
		</section>
		<section
			v-data-cy="'active-bio-breaks'"
			aria-label="Active Bio Breaks"
		>
			<h2 class="tw-text-3xl tw-font-extrabold tw-mb-5">
				Active Bio Breaks ({{ filteredActiveBioBreaks.length }})
			</h2>
			<div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-mb-12 tw-gap-x-4">
				<TutorBreakCardActiveBioBreak
					v-for="activeBioBreak in filteredActiveBioBreaks"
					:key="activeBioBreak.id"
					v-jest="'active-bio-break'"
					:current-break="activeBioBreak"
				/>
			</div>
		</section>
	</div>
</template>

<script>
import BaseCheckbox from "@/components/BaseCheckbox.vue";

import TutorBreakCardActiveBioBreak from "./TutorBreakCardActiveBioBreak.vue";
import TutorBreakCardLateBioBreak from "./TutorBreakCardLateBioBreak.vue";

export default {
	components: {
		TutorBreakCardActiveBioBreak,
		TutorBreakCardLateBioBreak,
		BaseCheckbox,
	},
	data() {
		return {
			isOnlyEssayReviewBreakRequests: false,
		};
	},
	computed: {
		filteredActiveBioBreaks() {
			const activeBioBreaks = this.$store.getters["BreaksCenter/activeBioBreaks"];
			const essayShiftActiveBioBreaks = this.$store.getters["BreaksCenter/essayShiftActiveBioBreaks"];
			return this.isOnlyEssayReviewBreakRequests ? essayShiftActiveBioBreaks : activeBioBreaks;
		},
		filteredLateBioBreaks() {
			const lateBioBreaks = this.$store.getters["BreaksCenter/lateBioBreaks"];
			const essayShiftLateBioBreaks = this.$store.getters["BreaksCenter/essayShiftLateBioBreaks"];
			return this.isOnlyEssayReviewBreakRequests ? essayShiftLateBioBreaks : lateBioBreaks;
		},
	},
};
</script>

<style scoped>
.breaks {
	height: 45rem;
}
.tutorsOnBreakFrom {
	height: 33rem;
}
</style>
