var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "tw-flex tw-items-center" },
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "week-info",
              expression: "'week-info'",
            },
          ],
          staticClass: "tw-font-bold tw-pb-2 tw-text-lg tw-text-left",
        },
        [_vm._v("\n\t\tShift hours (" + _vm._s(_vm.currentWeek) + ")\n\t")]
      ),
      _vm._v(" "),
      _c("InfoToolTip", {
        class: "tw-mb-2",
        attrs: {
          width: "16px",
          height: "16px",
          options: _vm.tooltipWeekDetails,
          "icon-color": "#696B73",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }