<template>
	<div
		v-jest="'shift-status-background'"
		class="tw-bg-origami-white tw-px-1 tw-inline-flex tw-rounded-[4px] tw-items-center"
		:class="shiftStatus.backgroundColor"
	>
		<span
			v-jest="'shift-status-text'"
			v-data-cy="'shift-status-text'"
			class="tw-font-bold tw-text-sm tw-mb-px"
			:class="shiftStatus.color"
		>{{ shiftStatus.text }}</span>
		<span
			v-jest="'shift-status-dot'"
			class="tw-w-2 tw-h-2 tw-rounded-full tw-ml-1"
			:class="shiftStatus.dotColor"
		/>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { isEmpty } from "lodash";
import { differenceInMinutes } from "date-fns";

import {
	SHIFT_SEGMENT_IN_PROGRESS_ID,
	SHIFT_SEGMENT_IN_TRANSITION_ID,
	TUTOR_MIA_ID,
	BIO_BREAK_IN_PROGRESS_ID,
	TUTOR_NEW_ACTIVITY_HOLD,
} from "@/modules/TutorSchedule/index.js";

export default {
	computed: {
		...mapState(["TutorSchedule", "BreaksManagementWidget"]),
		shiftStatus() {
			const breakStatus = {
				color: "tw-text-origami-black",
				dotColor: "tw-bg-origami-yellow-400",
				backgroundColor: "tw-bg-origami-yellow-200",
			};
			const online = {
				text: "Online",
				color: "tw-text-origami-white",
				dotColor: "tw-bg-origami-white",
				backgroundColor: "tw-bg-origami-green-400",
			};

			const status = {
				[SHIFT_SEGMENT_IN_PROGRESS_ID]: online,
				[SHIFT_SEGMENT_IN_TRANSITION_ID]: online,
				[TUTOR_NEW_ACTIVITY_HOLD]: online,
				[BIO_BREAK_IN_PROGRESS_ID]: {
					text: "Bio break",
					...breakStatus,
				},
				[TUTOR_MIA_ID]: {
					text: "Late",
					color: "tw-text-origami-white",
					dotColor: "tw-bg-origami-white",
					backgroundColor: "tw-bg-origami-red-400",
				},
				REST_BREAK_IN_PROGRESS: {
					text: "Rest break",
					...breakStatus,
				},
				MEAL_BREAK_IN_PROGRESS: {
					text: "Meal break",
					...breakStatus,
				},
				OFFLINE: {
					text: "Offline",
					color: "tw-text-origami-text-low-emphasis",
					dotColor: "tw-bg-origami-text-low-emphasis",
					backgroundColor: "tw-bg-origami-grey-100",
				},
			};

			if (this.isMIA) {
				return status[TUTOR_MIA_ID];
			} else if (this.isOnRestBreak) {
				return status.REST_BREAK_IN_PROGRESS;
			} else if (this.isOnMealBreak || this.isMealBreakOver) {
				return status.MEAL_BREAK_IN_PROGRESS;
			} else if (this.isLate) {
				return status[TUTOR_MIA_ID];
			}

			return status[this.TutorSchedule.shiftState.state_id] || status.OFFLINE;
		},
		isOnRestBreak() {
			return this.$store.getters["TutorSchedule/isOnRestBreak"];
		},
		isOnMealBreak() {
			return this.$store.getters["TutorSchedule/isOnMealBreak"];
		},
		isMealBreakOver() {
			const currentSegment = this.$store.getters["TutorSchedule/getCurrentShiftSegment"];
			if (isEmpty(this.$store.getters["TutorSchedule/getLateMealBreak"]) || isEmpty(currentSegment)) {
				return false;
			}

			return differenceInMinutes(
				this.BreaksManagementWidget.currentTime,
				new Date(currentSegment.start_time),
			) === 0;
		},
		isLate() {
			return !isEmpty(this.$store.getters["TutorSchedule/getLateRestBreak"]) ||
				!isEmpty(this.$store.getters["TutorSchedule/getLateMealBreak"]);
		},
		isMIA() {
			return this.$store.getters["TutorSchedule/isMIA"];
		},
	},
};
</script>
