<template>
	<div
		class="FileDropZone tw-relative"
		:class="{
			'hover:tw-bg-grey-slightly tw-border-2 tw-border-dashed tw-border-grey-dark tw-overflow-hidden': clickable,
			'tw-bg-grey-slightly': clickable && isDraggingOver,
			focused,
		}"
		@dragenter.prevent="isDraggingOver = true"
		@dragover.prevent="isDraggingOver = true"
		@dragleave.prevent="isDraggingOver = false"
		@drop.prevent="handleChange"
	>
		<slot v-bind="defaultProps" />

		<slot
			v-if="clickable"
			name="input"
			v-bind="inputProps"
		>
			<input
				v-jest="'input'"
				v-bind="inputProps.bind"
				v-on="inputProps.on"
			>
		</slot>
		<slot
			v-else
			name="overlay"
		>
			<div
				v-show="isDraggingOver"
				v-jest="'overlay'"
				class="tw-absolute tw-inset-0 tw-bg-black-overlay tw-border-2 tw-border-dashed tw-border-grey-dark tw-pointer-events-none tw-z-10"
			/>
		</slot>
	</div>
</template>

<script>
export default {
	props: {
		clickable: {
			type: Boolean,
			default: false,
		},
		inputZIndex: {
			type: [Number, String],
			default: 1,
		},
		dropzoneClickDisabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isDraggingOver: false,
			focused: false,
		};
	},
	computed: {
		defaultProps() {
			return {
				...this.$props,
				...this.$attrs,
				...this.$data,
				trigger: this.trigger,
			};
		},
		inputProps() {
			return {
				...this.defaultProps,
				bind: {
					type: "file",
					style: {
						zIndex: this.inputZIndex,
					},
				},
				on: {
					change: this.handleChange,
					focus: this.handleFocus,
					blur: this.handleBlur,
				},
			};
		},
	},
	methods: {
		trigger() {
			if (this.dropzoneClickDisabled) {
				this.$el.querySelector("input[type=file]").disabled = false;
			}
			this.$el.querySelector("input[type=file]")?.click();
		},
		handleChange(event) {
			this.isDraggingOver = false;

			this.$emit("change", event);
		},
		handleFocus() {
			if (this.dropzoneClickDisabled) {
				this.$el.querySelector("input[type=file]").disabled = true;
			} else {
				this.focused = true;
			}
		},
		handleBlur() {
			this.focused = false;
		},
	},
};
</script>

<style scoped>
.FileDropZone :deep(input[type="file"]) {
	position: absolute;
	top: 0;
	left: 0;
	font-size: 9999px;
	opacity: 0;
}
.FileDropZone :deep(input[type="file"]),
.FileDropZone :deep(input[type="file"]::-webkit-file-upload-button) {
	/* respect cursor in Chrome; see: https://stackoverflow.com/a/23860302/888928 */
	cursor: pointer;
}
.focused {
	outline: 2px solid var(--origami-blue-200);
}
</style>

