import { setPostLoginRedirectUri } from "@/modules/Authentication/index.js";

export default async function(to, from, next) {
	if (this.state.currentUser.accepted_agreement) {
		return next();
	} else {
		setPostLoginRedirectUri(to.fullPath);
		return next("/student/dashboard");
	}
}
