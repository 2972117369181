<template>
	<div
		class="tw-inline-flex tw-relative tw-rounded-full tw-p-4 tw-flex-col tw-items-center tw-z-10"
		:class="color ? `colored ${badgeBackgroundColor}` : ''"
	>
		<IconSparkles
			v-if="isSparkling"
			v-jest="'sparkles'"
			aria-hidden="true"
			class="tw-absolute tw-w-full tw-h-full tw-inset-0"
		/>
		<Emoji
			v-jest="'emoji'"
			:emoji="emoji"
			class="tw-align-middle"
			:class="{ 'achievementCenter__animation': isAnimatingCheer }"
		/>
	</div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";

import { Emoji } from "@/modules/Emoji/index.js";

import IconSparkles from "./illustrations/Sparkles.vue";

export default defineComponent({
	components: {
		IconSparkles,
		Emoji,
	},
	props: {
		emoji: {
			type: String,
			required: true,
		},
		color: {
			type: String,
			default: null,
			/**
			 * this comment enables the following classes via tailwinds JIT mode:
			 * tw-text-origami-purple-100 tw-text-origami-green-100 tw-text-origami-pink-100 tw-text-origami-cyan-100 tw-text-origami-grey-100
			*/
			validator: (value) => ["purple", "green", "pink", "cyan", "grey"].includes(value),
		},
		isSparkling: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const isAnimatingCheer = ref(false);
		const cheer = () => {
			isAnimatingCheer.value = true;
			setTimeout(() => isAnimatingCheer.value = false, 500);
		};

		const badgeBackgroundColor = computed(() => `tw-text-origami-${props.color}-100`);

		return {
			isAnimatingCheer,
			cheer,
			badgeBackgroundColor,
		};
	},
});
</script>

<style scoped>
.colored {
	background-image: radial-gradient(50% 50% at 50% 50%, transparent, currentColor);
}
.achievementCenter__animation {
	animation: rotate 0.5s linear;
}
@keyframes rotate {
	0% {
		z-index: 9999;
		transform: rotate(0turn) scale(1.25, 1.25) translateY(-0.2rem);
	}
	10% {
		transform: rotate(0.05turn) scale(1.5, 1.5) translateY(-0.3rem);
	}
	20% {
		transform: rotate(0turn) scale(2, 2) translateY(-0.5rem);
	}
	30% {
		transform: rotate(-0.05turn) scale(2, 2) translateY(-0.5rem);
	}
	40% {
		transform: rotate(0turn) scale(2, 2) translateY(-0.5rem);
	}
	50% {
		transform: rotate(0.05turn) scale(2, 2) translateY(-0.5rem);
	}
	60% {
		transform: rotate(0turn) scale(2, 2) translateY(-0.4rem);
	}
	70% {
		transform: rotate(-0.05turn) scale(1.75, 1.75) translateY(-0.2rem);
	}
	80% {
		transform: rotate(0turn) scale(1.5, 1.5) translateY(-0.2rem);
	}
	90% {
		transform: rotate(0.05turn) scale(1.25, 1.25) translateY(-0.1rem);
	}
	100% {
		transform: rotate(0turn) scale(1, 1) translateY(0rem);
	}
}
@media (prefers-reduced-motion) {
	.animate-cheer .achievementCenter__animation {
		animation: none;
	}
}
</style>
