import store from "@/store/index.js";

export default function(to, _, next) {
	const role = store.getters.currentUser.role;

	if (role === "tutor" && (to.path.startsWith("/missions") || to.path.startsWith("/practice"))) {
		return next("/tutor" + to.path);
	}

	if (role === "student" && (to.path.startsWith("/tutor/missions") || to.path.startsWith("/tutor/practice"))) {
		return next(to.path.replace("/tutor", ""));
	}
	return next();
}
