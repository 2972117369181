<template>
	<button
		v-jest="'trophy-notification'"
		class="tw-flex tw-gap-4 tw-mb-0"
		role="listitem"
		@mouseenter="isBadgeZoomed = true"
		@mouseleave="isBadgeZoomed = false"
		@focus="isBadgeZoomed = true"
		@blur="isBadgeZoomed = false"
		@mouseup="isBadgeZoomed = true"
		@mousedown="isBadgeZoomed = false"
		@click="handleCelebrate"
		@keyup.enter="handleCelebrate"
	>
		<div class="badge__container tw-relative tw-w-20 tw-h-20">
			<Badge
				ref="badge"
				v-jest="'badge'"
				:emoji="emoji"
				:color="color"
				class="emoji"
				:class="{ 'zoom': isBadgeZoomed }"
			/>
		</div>

		<div class="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-1 tw-text-left">
			<h5
				v-jest="'trophy-name'"
				class="tw-font-bold tw-text-md"
			>
				{{ name }}
			</h5>
			<p v-jest="'trophy-desc'">
				{{ description }}
			</p>
			<Points
				v-if="item.points"
				v-jest="'points'"
				class="tw-border tw-border-origami-grey-200 tw-rounded-full tw-py-1 tw-px-2 tw-mt-1"
				:points="item.points"
			/>
			<CustomizeAvatarButton
				v-if="trophy.name === 'avatar-customization'"
				v-jest="'customize-now'"
				class="tw-mt-1"
			/>
		</div>
	</button>
</template>

<script>
import { ACHIEVEMENT_TYPE_IDS, ACHIEVEMENT_TYPES } from "../../utilities/index.js";
import Badge from "../Badge.vue";
import { celebrate, originateFromElement, randomInRange } from "../../utilities/celebrate.js";
import Points from "../Points.vue";
import CustomizeAvatarButton from "../CustomizeAvatarButton.vue";

export default {
	components: {
		Badge,
		Points,
		CustomizeAvatarButton,
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		achievementType: ACHIEVEMENT_TYPES.find((type) => type.id === ACHIEVEMENT_TYPE_IDS.TROPHIES),
		isBadgeZoomed: false,
	}),
	computed: {
		trophy() {
			return this.item.trophy;
		},
		emoji() {
			const key = `trophies.metadata.${this.trophy.name}.emoji`;
			return this.$te(key)
				? this.$t(key)
				: this.achievementType.emoji;
		},
		color() {
			return this.achievementType.color;
		},
		name() {
			const key = `trophies.metadata.${this.trophy.name}.name`;
			return this.$te(key)
				? this.$t(key)
				: this.$t("achievements.trophy.completed");
		},
		description() {
			const key = `trophies.metadata.${this.trophy.name}.completed_description`;
			return this.$te(key)
				? this.$t(key)
				: null;
		},
	},
	methods: {
		handleCelebrate() {
			celebrate({
				spread: 270,
				angle: randomInRange(0, 360),
				...originateFromElement(this.$refs.badge.$el),
			});
		},
	},
};
</script>

<style scoped>
.badge__container {
	min-width: 5rem;
	min-height: 5rem;
}
.emoji {
	@apply tw-w-20 tw-h-20 tw-text-4xl tw-items-center tw-justify-center;
}
.zoom {
	@apply tw-w-24 tw-h-24 tw-absolute tw--top-2 tw--left-2;
	font-size: 4rem;
}
</style>
