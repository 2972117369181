var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full",
    },
    [
      _c("IconCalendar", {
        attrs: { "icon-color": _vm.colorGray, width: 64, height: 64 },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "noShifts__text" }, [
        _vm._v("You have no upcoming shift for the next 24 hrs"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }