<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		viewBox="0 0 16 16"
	>
		<g
			fill="none"
			fill-rule="evenodd"
			transform="translate(1 -1)"
		>
			<circle
				cx="7"
				cy="9"
				r="7"
				:stroke="iconColor"
			/>
			<text
				:fill="iconColor"
				font-family="OpenSans-ExtraBold, Open Sans"
				font-size="12"
				font-weight="600"
			>
				<tspan
					x="5"
					y="13"
				>!</tspan>
			</text>
		</g>
	</svg>
</template>
<script>
export default {
	props: {
		width: {
			type: Number,
			default: 16,
		},
		height: {
			type: Number,
			default: 16,
		},
		iconColor: {
			type: String,
			default: "#FF793D",
		},
	},
};
</script>
