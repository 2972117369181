import Vue from "vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import BAlert from "bootstrap-vue/es/components/alert/index.js";
import BCollapse from "bootstrap-vue/es/components/collapse/index.js";
import BDropdown from "bootstrap-vue/es/components/dropdown/index.js";
import BFormTextarea from "bootstrap-vue/es/components/form-textarea/index.js";
import BModal from "bootstrap-vue/es/components/modal/index.js";
import BTooltipDirective from "bootstrap-vue/es/directives/tooltip/index.js";
import BModalDirective from "bootstrap-vue/es/directives/modal/index.js";

Vue.use(BAlert);
Vue.use(BCollapse);
Vue.use(BDropdown);
Vue.use(BFormTextarea);
Vue.use(BModal);

Vue.use(BTooltipDirective);
Vue.use(BModalDirective);
