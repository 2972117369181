var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("OrigamiCard", { staticClass: "tw-pt-6 tw-pb-4" }, [
    _c(
      "div",
      { staticClass: "tw-mx-6 tw-border-b-2 tw-border-origami-grey-100" },
      [_vm._t("weekDetails")],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "tw-mx-6 tw-mb-1" }, [_vm._t("totalHours")], 2),
    _vm._v(" "),
    _vm.detailsVisible
      ? _c(
          "div",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "hours-breakdown",
                expression: "'hours-breakdown'",
              },
            ],
            staticClass: "tw-mx-6",
          },
          [_vm._t("tutorHoursBreakdown")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "tw-flex tw-justify-center tw-border-t tw-border-origami-grey-100",
      },
      [
        _c(
          "OrigamiButton",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "tutor-scheduled-hours-details-button",
                expression: "'tutor-scheduled-hours-details-button'",
              },
            ],
            staticClass: "tw-mt-3",
            attrs: { variant: "tertiary", "is-disabled": _vm.hasNoShifts },
            nativeOn: {
              click: function ($event) {
                _vm.detailsVisible = !_vm.detailsVisible
              },
            },
          },
          [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(
                  _vm.detailsVisible ? "Hide details" : "Show more details"
                ) +
                "\n\t\t"
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }