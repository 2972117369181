<template>
	<li class="tw-flex tw-flex-col tw-justify-between tw-items-start tw-gap-2 tw-text-base tw-pb-0">
		<p
			v-jest="'message'"
			class="tw-font-bold"
		>
			{{ message }}
		</p>
		<Points
			v-jest="'activity-points'"
			:points="points"
			class="tw-border tw-border-origami-grey-200 tw-rounded-full tw-py-1 tw-px-2"
		/>
	</li>
</template>

<script>
import Points from "../Points.vue";
import { ACTIVITY_MESSAGE_TRANSLATION_KEYS, ACTIVITY_POINTS } from "../../utilities/index.js";

export default {
	components: {
		Points,
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	computed: {
		activity() {
			return this.item.activity;
		},
		message() {
			return this.$te(`achievements.${ACTIVITY_MESSAGE_TRANSLATION_KEYS[this.activity.id]}`)
				? this.$t(`achievements.${ACTIVITY_MESSAGE_TRANSLATION_KEYS[this.activity.id]}`)
				: this.$t("achievements.activity.completed");
		},
		points() {
			return ACTIVITY_POINTS[this.activity.id] ?? ACTIVITY_POINTS["default"];
		},
	},
};
</script>
