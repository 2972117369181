export default {
	en: {
		student_navigation: {
			navbar: {
				side_nav: "Side navigation",
				menu: "Menu",
				home: "Paper homepage",
				profile_menu: "{user}'s Profile Menu",
			},
			dropdown_links: {
				view_my_profile: "View my profile",
				achievements: "Achievements",
				settings: "Settings",
				logout: "Logout",
				stop_impersonating: "Stop Impersonation",
			},
			view_as_links: {
				superuser: "View as Superuser",
				district_manager: "View as District Manager",
				administrator: "View as Administrator",
				teacher: "View as Teacher",
				tutor: "View as Tutor",
				platform_manager: "View as Platform Manager",
				student: "View as Student",
				problem_creator: "View as Problem Creator",
			},
			nav_links: {
				home: "Home",
				chat: "Chat with a tutor",
				chat_history: "View chat history", // DEPRECATE GROW-1473-update-sidebar-links
				review_center: "See reviewed writing", // DEPRECATE GROW-1473-update-sidebar-links
				upload_work: "Submit writing for review",
				question: "Ask a question",
				schedule: "Schedule a chat",
				paperlive: "Watch videos",
				math: "Practice with games", // DEPRECATE GROW-1473-update-sidebar-links
				referrals: "Refer a friend",
				future_path: "Future Path",
				reading: "Reading",
				chat_history_new: "Chat History",
				review_center_new: "Review Center",
				math_new: "Missions",
			},
			sidebar_links: {
				home: "Home",
				live_help: "Live Help",
				review_center: "Review Center",
				future_path: "Future Path",
				practice: "Practice",
				ask_tutor: "Ask a tutor",
				chat_history: "View chat history",
				saved_messages: "View saved messages",
				submit_work_review: "Submit work for review",
				view_tutor_feedback: "View tutor's feedback",
				math_mission: "Start a math mission",
				reading_mission: "Start a reading mission",
			},
			footer: {
				privacy_policy: "Privacy Policy",
			},
		},
	},
	fr: {
		challenges: {
			title: "Complétez le cercle",
			time_left: "{days} jour{jours_plural} restant{jours_plural} !",
			of: "{completed} sur {target}",
			tutoring: "Sessions de tutorat",
			feedback: "Soumissions de commentaires",
			reviews: "Commentaires demandés",
		},
		student_navigation: {
			navbar: {
				side_nav: "Navigation latérale",
				menu: "Menu",
				home: "Page d'accueil de Paper",
				profile_menu: "Menu de profil de {user}",
			},
			dropdown_links: {
				view_my_profile: "Voir mon profil",
				achievements: "Accomplissements",
				settings: "Paramètres",
				logout: "Se déconnecter",
				stop_impersonating: "Arrêter l'imitation",
			},
			nav_links: {
				home: "Accueil",
				chat: "Parler à un tuteur",
				chat_history: "Voir l'historique de chat", // DEPRECATE GROW-1473-update-sidebar-links
				review_center: "Voir la révision des écrits",
				upload_work: "Soumet un écrit pour révision",
				question: "Poser une question",
				schedule: "Planifier une session",
				paperlive: "Regarder des vidéos",
				math: "Jouer à des jeux de maths",
				referrals: "Référer un ami",
				future_path: "Voie Future",
				reading: "Lecture",
				chat_history_new: "Historique de chat",
				review_center_new: "Révision des écrits",
				math_new: "Missions",
			},
			footer: {
				privacy_policy: "Politique de confidentialité",
			},
		},
	},
	es: {
		challenges: {
			title: "Completa el círculo",
			time_left: "{days} día{days_plural} quedan!",
			of: "{completed} de {target}",
			tutoring: "Sesiones de tutoría",
			feedback: "Envíos de comentarios",
			reviews: "Comentarios solicitados",
		},
		student_navigation: {
			navbar: {
				side_nav: "Navegación lateral",
				menu: "Menú",
				home: "Página de inicio de Paper",
				profile_menu: "Menú de perfil de {user}",
			},
			nav_links: {
				home: "Inicio",
				chat: "Chatear con un tutor",
				chat_history: "Ver historial de chat", // DEPRECATE GROW-1473-update-sidebar-links
				review_center: "Ver revisión de la escritura",
				upload_work: "Enviar escrito para revisión",
				question: "Hacer una pregunta",
				schedule: "Programar una charla",
				paperlive: "Ver videos",
				math: "Juega juegos de matemáticas",
				referrals: "Recomendar a un amigo",
				future_path: "Camino Futuro",
				reading: "Lectura",
				chat_history_new: "Historial de chat",
				review_center_new: "Revisión de la escritura",
				math_new: "Misiones",
			},
			dropdown_links: {
				view_my_profile: "Ver mi perfil",
				achievements: "Logros",
				settings: "Ajustes",
				logout: "Cerrar sesión",
				stop_impersonating: "Detener la suplantación",
			},
			footer: {
				privacy_policy: "Poliza de privacidad",
			},
		},
	},
};
