<script>
import { isAfter, isBefore, format, subMinutes } from "date-fns";

import ServiceDashboardTutorCardHeaderAbstract from "./ServiceDashboardTutorCardHeaderAbstract.vue";
export default {
	extends: ServiceDashboardTutorCardHeaderAbstract,
	computed: {
		/**
		 * Essay tutors are always disconnected from the classroom.
		 * @returns {Boolean}
		 */
		isDisconnectedAndPunchedIn() {
			return false;
		},
		/**
		 * @returns {Boolean}
		 */
		isFutureShift() {
			return isBefore(this.currentDate, new Date(this.shift.start_time * 1000));
		},
		/**
		 * @returns {Boolean}
		 */
		isGreyedOut() {
			return this.isNotPunchedInAndLate;
		},
		/**
		 * @returns {Boolean}
		 */
		isLateForShift() {
			if (!this.isPunchedIn) {
				return !this.isFutureShift;
			}
			return this.shift.start_time < this.shift.punch_in;
		},
		/**
		 * @returns {Boolean}
		 */
		isNotPunchedInAndLate() {
			return !this.isPunchedIn && !this.isFutureShift;
		},
		/**
		 * @returns {Boolean}
		 */
		isPunchedIn() {
			return this.shift.punch_in !== null;
		},
		/**
		 * @returns {Boolean}
		 */
		isShiftAlmostOver() {
			const shiftEnd = new Date(this.shift.end_time * 1000);
			return isAfter(this.currentDate, subMinutes(shiftEnd, 15));
		},
		/**
		 * @returns {Boolean}
		 */
		isShiftOver() {
			return isAfter(this.currentDate, new Date(this.shift.end_time * 1000));
		},
		/**
		 * @returns {Boolean}
		 */
		isShiftStartRed() {
			return this.isLateForShift;
		},
		/**
		 * Formatted punch in time
		 * @returns {String}
		 */
		punchInTime() {
			if (this.isPunchedIn) {
				return format(new Date(this.shift.punch_in * 1000), "hh:mm a");
			} else {
				return this.shiftStartTime;
			}
		},
		/**
		 * @returns {String}
		 */
		shiftStartTime() {
			return format(new Date(this.shift.start_time * 1000), "hh:mm a");
		},
		/**
		 * @returns {String}
		 */
		shiftEndTime() {
			return format(new Date(this.shift.end_time * 1000), "hh:mm a");
		},
		/**
		 * Determines based on a flag whether to show the shift start time or the tutor punch in time
		 * @returns {String}
		 */
		tutorShiftStartTime() {
			if (this.isShowingPunchInTime) {
				return this.punchInTime;
			} else {
				return this.shiftStartTime;
			}
		},
		/**
		 * Determines which class and text to show in the shift info
		 * @returns {Object} with keys: 'class', 'text'
		 */
		extraInfo() {
			const extraInfo = {
				class: "",
				text: "",
			};
			if (this.isShiftOver) {
				extraInfo.class = "tutorCardInfo__shiftInfo--shiftEndingColor";
				extraInfo.text = "Past end of shift";
			} else if (this.isShiftAlmostOver) {
				extraInfo.class = "tutorCardInfo__shiftInfo--shiftEndingColor";
				extraInfo.text = "Shift ends soon";
			} else if (this.isPunchedIn) {
				extraInfo.class = "";
				extraInfo.text = `${this.timeElapsedSincePunchIn} elapsed`;
			} else if (this.isNotPunchedInAndLate) {
				extraInfo.class = "tutorCardInfo__shiftInfo--lateColor";
				extraInfo.text = "Late";
			}
			return extraInfo;
		},
	},
};
</script>
