<template>
	<div class="emptyState">
		<div class="icon">
			<slot name="icon" />
		</div>
		<h1 v-if="header.length">
			{{ header }}
		</h1>
		<p v-if="subHeader.length">
			{{ subHeader }}
		</p>
		<div class="cta">
			<slot name="cta" />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		header: {
			type: String,
			default: "",
		},
		subHeader: {
			type: String,
			default: "",
		},
	},
};
</script>

<style scoped>
.emptyState .icon {
	width: 32px;
	height: 32px;
	display: block;
	margin: 0 auto 32px;
}

.emptyState .icon img {
	width: 100%;
	height: 100%;
}

.emptyState .icon:empty {
	visibility: hidden;
}
.emptyState h1 {
	height: 24px;
	text-align: center !important;
	font-family: "MonumentGrotesk", "Open Sans", sans-serif;
	margin: 0px 0px 16px;
	font-size: 18px;
	font-weight: 600;
	line-height: normal;
	padding-bottom: 10px;
}

.emptyState p {
	font-family: "MonumentGrotesk", "Open Sans", sans-serif;
	opacity: 0.8;
	margin: 0px 0px 32px;
	text-align: center;
	font-size: 12px;
	font-weight: 600;
	font-style: normal;
	line-height: normal;
	color: var(--origami-black);
}

.emptyState .cta {
	display: flex;
	justify-content: center;
	margin: 0 auto;
}

.emptyState .cta:empty {
	visibility: hidden;
}
</style>
