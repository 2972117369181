import TeachingAssetsApi from "../services/teachingAssets.js";
import ContentCatalogApi from "../services/contentCatalog.js";

export const ASSET_TYPE_IDS = {
	PAPER_CLIPS: 1,
};
export const ASSET_PATHS = {
	TEACHING_ASSETS_API: "teaching-assets-api",
};
export const VIDEO_ASSETS_TYPE_IDS = [
	ASSET_TYPE_IDS.PAPER_CLIPS,
];

export function getImagesByType(images, imageType) {
	if (!images) {
		return [];
	}
	return images?.filter(({ name }) => name === imageType).map(({ href }) => href);
}
export function getImageByType(images, imageType) {
	return getImagesByType(images, imageType)[0];
}


export async function getContentData(asset, messageId) {
	const responses = await Promise.all([
		ContentCatalogApi.getVideo(asset.content_id),
		ContentCatalogApi.getVideoContentUrl(asset.content_id),
	]);
	const [video, content] = responses.map((response) => response.data.data);
	return {
		...asset,
		...video,
		url: content.url,
		messageId: messageId || 0,
		asset_id: asset.id,
		asset_type_id: asset.type_id,
	};
}

export async function getAssetWithContentData(assetId, messageId) {
	const teachingAsset = (await TeachingAssetsApi.getAsset(assetId)).data.data;
	if (VIDEO_ASSETS_TYPE_IDS.includes(teachingAsset.type_id)) {
		return getContentData(teachingAsset, messageId);
	}
}
