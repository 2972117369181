import { openLinkInPopup } from "@/utilities/links.js";

export const AUTO_LOGIN_KEY_NAME = "autoLogin";
export const AUTO_LOGIN_VALUE = "true";
export const SSO_TYPES = {
	GOOGLE: "Google",
	CLEVER: "Clever",
	CLASSLINK: "Classlink",
};

export const POST_LOGIN_REDIRECT_URI_KEY_NAME = "postLoginRedirectUri";
export const POST_LOGIN_REDIRECT_URI_HOOK_KEY_NAME = "postLoginRedirectUri:hooked";
export function setPostLoginRedirectUri(redirect_uri) {
	if (redirect_uri) {
		window.sessionStorage.setItem(POST_LOGIN_REDIRECT_URI_KEY_NAME, redirect_uri);
	}
}
export function redirectWithHookedPostLoginRedirectUri(redirect) {
	if (redirect) {
		window.sessionStorage.setItem(POST_LOGIN_REDIRECT_URI_HOOK_KEY_NAME, true);
		window.location.href = redirect;
	}
}
export async function getAndConsumePostLoginRedirectUri(onlyIfHooked = false) {
	const isHooked = window.sessionStorage.getItem(POST_LOGIN_REDIRECT_URI_HOOK_KEY_NAME);
	if (onlyIfHooked && !isHooked) {
		return;
	}
	window.sessionStorage.removeItem(POST_LOGIN_REDIRECT_URI_HOOK_KEY_NAME);

	const redirect_uri = window.sessionStorage.getItem(POST_LOGIN_REDIRECT_URI_KEY_NAME);
	if (!redirect_uri) {
		return;
	}

	// clear it, now that we've read it
	window.sessionStorage.removeItem(POST_LOGIN_REDIRECT_URI_KEY_NAME);

	const isLocalUrl = !/^((.+):)?\/\//i.test(redirect_uri); // doesn't start with "<protocol>://" or "//"
	if (isLocalUrl) {
		return redirect_uri;
	}

	// fallback to falsy
	return;
}
export async function redirectToPostLoginRedirectUri() {
	const redirect_uri = await getAndConsumePostLoginRedirectUri();
	window.location.href = redirect_uri || "/";
}

export function redirectInPopupHandler(to, from, next) {
	try {
		if (window.opener) {
			const query = new URLSearchParams(window.location.search);
			const error = query.get("error") || null;
			window.opener.postMessage({ error }, window.location.origin);
		}
	} catch (error) {
		Sentry.captureException(error);
	}
	next();
}
export async function redirectInPopupWithHookedPostLoginRedirectUri(redirect) {
	if (!redirect) {
		return;
	}
	window.sessionStorage.setItem(POST_LOGIN_REDIRECT_URI_HOOK_KEY_NAME, true);
	return openLinkInPopup(redirect);
}
