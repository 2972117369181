var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "tw-inline-flex tw-relative tw-rounded-full tw-p-4 tw-flex-col tw-items-center tw-z-10",
      class: _vm.color ? `colored ${_vm.badgeBackgroundColor}` : "",
    },
    [
      _vm.isSparkling
        ? _c("IconSparkles", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "sparkles",
                expression: "'sparkles'",
              },
            ],
            staticClass: "tw-absolute tw-w-full tw-h-full tw-inset-0",
            attrs: { "aria-hidden": "true" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("Emoji", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "emoji",
            expression: "'emoji'",
          },
        ],
        staticClass: "tw-align-middle",
        class: { achievementCenter__animation: _vm.isAnimatingCheer },
        attrs: { emoji: _vm.emoji },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }