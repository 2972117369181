<template>
	<BreaksConfirmation>
		<template #header>
			<h3 v-data-cy="'schedule-rest-break-header'">
				Schedule rest break
			</h3>
		</template>
		<template #bodyIcon>
			<OrigamiIcon
				v-data-cy="'check-icon'"
				name="check-filled"
				width="32"
				height="32"
				class="tw-text-origami-green-400"
			/>
		</template>
		<template #bodyText>
			<p
				v-jest="'rest-break-time'"
				v-data-cy="'rest-break-time-text'"
			>
				Your break has been scheduled
				<span
					v-if="formattedRestBreakTimes"
					v-jest="'rest-break-start-end'"
				>
					for {{ formattedRestBreakTimes.startTime }} to {{ formattedRestBreakTimes.endTime }}
				</span>
			</p>
		</template>
		<template #buttons>
			<OrigamiButton
				v-jest="'rest-break-confirmation-button'"
				v-data-cy="'rest-break-confirmation-button'"
				variant="primary"
				class="tw-w-full tw-justify-center"
				@click.native="confirmRestBreak"
			>
				Ok, got it!
			</OrigamiButton>
		</template>
	</BreaksConfirmation>
</template>
<script>
import { OrigamiButton, OrigamiIcon } from "@origami/vue2";

import { formatIsoDateToHM } from "@/utilities/dateHelpers.js";

import BreaksConfirmation from "../templates/BreaksConfirmation.vue";

export default {
	components: {
		BreaksConfirmation,
		OrigamiIcon,
		OrigamiButton,
	},
	data() {
		return {
			confirmationTimeout: null,
		};
	},
	computed: {
		formattedRestBreakTimes() {
			const upcomingRestBreak = this.$store.getters["TutorSchedule/getUpcomingRestBreak"];

			return upcomingRestBreak ? {
				startTime: formatIsoDateToHM(upcomingRestBreak.start_time),
				endTime: formatIsoDateToHM(upcomingRestBreak.end_time),
			} : null;
		},
	},
	created() {
		const ONE_MINUTE_IN_MS = 60 * 1000;
		this.confirmationTimeout = setTimeout(() => {
			this.$store.dispatch("BreaksManagementWidget/confirmRestBreak");
		}, ONE_MINUTE_IN_MS);
	},
	beforeDestroy() {
		clearTimeout(this.confirmationTimeout);
	},
	methods: {
		confirmRestBreak() {
			this.$store.dispatch("BreaksManagementWidget/resetWorkflow");
		},
	},
};
</script>
