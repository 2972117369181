import ReceivedMessage from "@/modules/Classroom/classes/ReceivedUserMessageDecorator.js";

export default {
	namespaced: true,
	state: {
		isShowing: false,
		isInstantiated: false,
		index: 0,
		array: [],
	},
	mutations: {
		SET_LIGHTBOX_INDEX(state, payload) {
			state.index = payload.index;
		},

		/**
		 * Load the lightbox array from currentSharedFiles
		 * (to be called on changes of currentSession)
		 *
		 * @param {*} state
		 *
		 */
		SET_LIGHTBOX_ARRAY(state, payload) {
			state.array = payload.array;
		},
		TOGGLE_LIGHTBOX_SHOWING(state) {
			state.isShowing = !state.isShowing;
		},

		TOGGLE_LIGHTBOX_IS_INSTANTIATED(state, payload) {
			state.isInstantiated = payload.isInstantiated;
		},
		/**
		 *
		 * @param {*} state
		 * @param {Object} payload
		 * @param {String} payload.type
		 * @param {Number} payload.value
		 */
		INCREMENT_CURRENT_ROTATION(state, payload) {
			state.array[state.index].rotation += payload.value;
		},

		SET_CURRENT_ZOOM(state, payload) {
			state.array[state.index].zoom = payload.zoom;
		},
	},
	actions: {
		/**
		 * Load images in the lightbox
		 * from the available shared files
		 *
		 * @param {*}		commit
		 * @param {*}		state
		 *
		 */
		loadLightbox({ commit, rootGetters }) {
			try {
				const files = [];
				rootGetters["Classroom/currentSessionSharedFiles"].forEach(function(file) {
					if (file instanceof ReceivedMessage) {
						if (file.message.mediaType === "image") {
							files.push({
								id: file.id,
								link: file.message.url,
								zoom: 0,
								rotation: 0,
							});
						}
					} else if (file.mediaType === "image") {
						files.push({
							id: file.id,
							link: file.url,
							zoom: 0,
							rotation: 0,
						});
					}
				});
				commit("SET_LIGHTBOX_ARRAY", {
					array: files,
				});
			} catch (e) {
				console.error(e);
			}
		},

		/**
		 * Show an image in the lightbox
		 *
		 * @param {*}		commit
		 * @param {*}		state
		 * @param {Number}	fileId
		 */
		showLightbox({ commit, state }, payload) {
			if (state.isInstantiated === false) {
				commit("TOGGLE_LIGHTBOX_IS_INSTANTIATED", {
					isInstantiated: true,
				});
			}
			state.array.forEach((file, index) => {
				if (file.id === payload.fileId) {
					commit("SET_LIGHTBOX_INDEX", {
						index,
					});
					commit("TOGGLE_LIGHTBOX_SHOWING");
				}
			});
		},
	},
	getters: {
		/**
		 * The current image's rotation amount, in degrees
		 *
		 * @returns {Number}
		 */
		currentRotation(state) {
			if (state.array.length > 0) {
				return state.array[state.index].rotation;
			}
			return 0;
		},
	},
};
