<script setup>
import { computed } from "vue";
import { isNil } from "lodash-es";

import BaseButton from "@/components/BaseButton.vue";
import useStore from "@/composables/useStore.js";
import { PUNCHED_IN_STATES, SHIFT_UPCOMING_ID } from "@/modules/TutorSchedule/index.js";
import { TUTORING_SESSION, ESSAY_REVIEW } from "@/modules/TutorSchedule/utilities/ShiftTypes.js";

import PunchClockCountdown from "./PunchClockCountdown.vue";

const shiftTypeRedirects = {
	[TUTORING_SESSION]: "/tutor/classroom",
	[ESSAY_REVIEW]: "/tutor/review-center",
};

const props = defineProps({
	currentDate: {
		type: Date,
		required: true,
	},
});

const store = useStore();

const shift = computed(() => store.getters["TutorSchedule/getCurrentShift"]);
const isLockedOutOfShift = computed(() => store.getters["TutorSchedule/isLockedOutOfShift"]);
const isShiftInProgress = computed(
	() => PUNCHED_IN_STATES.includes(shift.value.state_id));
const isShiftEnding = computed(() => store.getters["TutorSchedule/isShiftEnding"]);
const canPunchIn = computed(() => shift.value.state_id === SHIFT_UPCOMING_ID);
const buttonText = computed(() => {
	if (canPunchIn.value) {
		return "Punch In";
	}

	return isShiftEnding.value ? "Punch Out" : "Shift Started";
});
const showCountdown = computed(() => !isShiftInProgress.value || isShiftInProgress.value && isShiftEnding.value);

const punchIn = async() => {
	try {
		await store.dispatch("TutorSchedule/punchIn");
		const currentSegment = store.getters["TutorSchedule/getCurrentShiftSegment"];
		const redirectUrl = shiftTypeRedirects[currentSegment.type.id];

		if (!isNil(redirectUrl)) {
			window.location.assign(redirectUrl);
		}
	} catch (error) {
		await store.dispatch("Snackbar/showSnackbar", {
			snackbarType: "error",
			snackbarText: "You were not able to punch into your shift. Please refresh the page and try again.",
		});
	}
};

const punchOut = async() => {
	try {
		await store.dispatch("TutorSchedule/punchOut");
		window.location.assign("/");
	} catch (error) {
		await store.dispatch("Snackbar/showSnackbar", {
			snackbarType: "error",
			snackbarText: "You were not able to punch out of your shift. Please refresh the page and try again.",
		});
	}
};

const handlePunch = async() => {
	if (canPunchIn.value) {
		await punchIn();
		return;
	}

	if (isShiftEnding.value) {
		await punchOut();
	}
};
</script>

<template>
	<div class="tw-relative tw-ml-0.5">
		<BaseButton
			v-jest="'punch-clock-button'"
			data-cy="punch-button"
			class="tw-rounded-r !tw-rounded-l-none"
			:class="{
				'active !tw-cursor-default': isShiftInProgress && !isShiftEnding,
			}"
			type="SUBMIT"
			:is-disabled="isLockedOutOfShift"
			@click.native="handlePunch"
		>
			<template slot="text">
				<span v-jest="'punch-button-text'">{{ buttonText }}</span>
			</template>
		</BaseButton>
		<PunchClockCountdown
			v-if="showCountdown"
			v-jest="'punch-clock-countdown'"
			data-cy="punch-countdown"
			:current-date="props.currentDate"
			:shift="shift"
		/>
	</div>
</template>

<style scoped>
.active {
	background-color: var(--c-dark-green);
}
</style>
