import { find, intersection, map } from "lodash";

import filterUtils from "./filterUtils.js";

const filterAbstract = (config) => {
	const {
		getSelectedOptions,
		filterSelections,
		filterOptions,
		loadInitialOptions,
	} = filterUtils(config);

	const getChildKey = (key) => find(config, { parentKey: key })?.key;

	const getDefaultState = () => ({
		filterOptions,
		filterSelections,
		filterLoadError: false,
	});

	return {
		namespaced: true,
		state: getDefaultState(),
		mutations: {
			SET_FILTER_OPTIONS(state, payload) {
				state.filterOptions = { ...state.filterOptions, ...payload };
			},

			CLEAR_FILTER_SELECTIONS(state) {
				state.filterSelections = getDefaultState().filterSelections;
			},

			SET_FILTER_SELECTIONS(state, payload) {
				state.filterSelections = { ...state.filterSelections, ...payload };
			},

			SET_FILTER_LOAD_ERROR(state, payload) {
				state.filterLoadError = payload;
			},
		},
		actions: {
			async loadOptions({ commit }) {
				try {
					commit("SET_FILTER_OPTIONS", await loadInitialOptions());
				} catch (e) {
					Sentry.captureException(e);
					commit("SET_FILTER_LOAD_ERROR", true);
				}
			},

			async updateOptions({ commit, dispatch, state: { filterSelections } }, { key, selections }) {
				commit("SET_FILTER_LOAD_ERROR", false);
				commit("SET_FILTER_SELECTIONS", { [key]: selections });

				const childKey = getChildKey(key);

				if (childKey) {
					try {
						const childOptions = await getSelectedOptions(childKey, selections);

						const isSelectionAnArray = Array.isArray(filterSelections[childKey]);

						const childSelections = isSelectionAnArray
							? intersection(map(childOptions, "id"), filterSelections[childKey])
							: map(childOptions, "id").includes(filterSelections[childKey])
								? filterSelections[childKey]
								: null;

						commit("SET_FILTER_OPTIONS", { [childKey]: childOptions });

						dispatch("updateOptions", { key: childKey, selections: childSelections });
					} catch (e) {
						Sentry.captureException(e);
						commit("SET_FILTER_LOAD_ERROR", true);
					}
				}
			},

			loadAndSetSelections({ dispatch, commit, state }, allSelections) {
				config.forEach(({ key }) => {
					commit("SET_FILTER_SELECTIONS", { [key]: allSelections[key] });
				});

				config.forEach(async({ key, parentKey }) => {
					if (!parentKey) {
						await dispatch("updateOptions", { key, selections: state.filterSelections[key] });
					}
				});
			},
		},
	};
};

export default filterAbstract;
