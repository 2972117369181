function AbstractMessage({
	id,
	userId,
	createdAt,
	sequencePosition,
	status,
	isLastMessage,
	deliveredAt,
	isQuestion,
	isDeleted,
	isFailed = false,
	userReactions,
}) {
	this.id = id;
	this.userId = userId;
	this.createdAt = createdAt;
	this.sequencePosition = sequencePosition;
	this.status = status;
	this.isLastMessage = isLastMessage;
	this.deliveredAt = deliveredAt;
	this.isQuestion = isQuestion;
	this.isDeleted = isDeleted;
	this.isFailed = isFailed;
	this.userReactions = userReactions;

}

AbstractMessage.prototype.setStatus = (status) => {
	this.status = status;
};

AbstractMessage.prototype.setSequencePosition = (sequencePosition) => {
	this.sequencePosition = sequencePosition;
};

AbstractMessage.prototype.setIsLastMessage = (isLastBoolean) => {
	this.isLastMessage = isLastBoolean;
};

export default AbstractMessage;
