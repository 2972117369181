<template>
	<div>
		<PunchClock v-jest="'punch-clock'" />
	</div>
</template>
<script>
import PunchClock from "./PunchClock.vue";

export default {
	components: {
		PunchClock,
	},
};
</script>
