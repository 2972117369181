/**
 *
 * @param text
 * @param isEndedSession
 * @param name
 * @param members
 * @constructor
 */
function SystemMessage({ text, isEndedSession, name, members }) {
	this.id = Math.random()
		.toString(36)
		.replace(/[^a-z]+/g, "")
		.substr(0, 100);
	this.text = text;
	this.isEndedSession = isEndedSession;
	this.name = name;
	this.members = members;
}

export default SystemMessage;
