<template>
	<span class="tw-flex tw-items-center">
		<span
			v-jest="'week-info'"
			class="tw-font-bold tw-pb-2 tw-text-lg tw-text-left"
		>
			Shift hours ({{ currentWeek }})
		</span>
		<InfoToolTip
			:width="'16px'"
			:height="'16px'"
			:options="tooltipWeekDetails"
			:icon-color="'#696B73'"
			:class="'tw-mb-2'"
		/>
	</span>
</template>

<script>
import { format } from "date-fns";
import { mapState } from "vuex";

import InfoToolTip from "../../../components/patterns/InfoToolTip.vue";
import { formatTimestampToDate } from "../../../utilities/dateHelpers.js";

export default {
	components: {
		InfoToolTip,
	},
	data() {
		return {
			tooltipWeekDetails: {
				placement: "right",
				content: `This is only covering scheduled hours that are logged on the calendar and NOT logged hours worked.`,
			},
		};
	},
	computed: {
		...mapState(["TutorScheduledHours"]),
		currentWeek() {
			const startDate = formatTimestampToDate(this.TutorScheduledHours.startDate);
			const endDate = formatTimestampToDate(this.TutorScheduledHours.endDate);
			const startOfWeekMonth = format(startDate, "LLL");
			const startOfWeekDay = startDate.getDate();
			const endOfWeekMonth = format(endDate, "LLL");
			const endOfWeekDay = endDate.getDate();
			return startOfWeekMonth === endOfWeekMonth
				? `${startOfWeekMonth} ${startOfWeekDay}-${endOfWeekDay}`
				: `${startOfWeekMonth} ${startOfWeekDay} - ${endOfWeekMonth} ${endOfWeekDay}`;
		},
	},
};
</script>
