var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "nav",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _setup.closeDrawer,
          expression: "closeDrawer",
        },
        {
          name: "jest",
          rawName: "v-jest",
          value: "sidebar",
          expression: "'sidebar'",
        },
      ],
      staticClass: "sidebar",
      class: { opened: _setup.isDrawerOpen },
      attrs: { id: "sidebar" },
    },
    [
      _c(_setup.SkipToMainContent, {
        staticClass:
          "skip tw-border-b-2 tw-left-full focus:tw-left-0 md:tw-left-0 tw-border-solid tw-border-origami-grey-100 hover:tw-shadow-origami-xl tw-z-[22] tw--top-full",
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "md:tw-pt-2 tw-sticky tw-top-0 md:tw-top-20 tw-bg-origami-white tw-z-[21]",
        },
        [
          _c(
            _setup.SuspensePattern,
            {
              staticClass: "tw-mt-1 tw-flex tw-justify-around md:tw-block",
              attrs: { "is-showing-fallback": _setup.isLoading },
            },
            _vm._l(_setup.reactiveNavLinks, function (navLink) {
              return _c(_setup.SidebarLinks, {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: `${navLink.name}-link`,
                    expression: "`${navLink.name}-link`",
                  },
                ],
                key: navLink.name,
                attrs: {
                  title: navLink.title,
                  icon: navLink.icon,
                  target: `${navLink?.target}`,
                  name: navLink.name,
                  "opens-drawer": navLink.opensDrawer,
                  "is-external": navLink.isExternal,
                  "is-open": navLink.name === _setup.activeDrawer,
                },
                on: {
                  "open-drawer": function ($event) {
                    return _setup.handleDrawerOpening(navLink.name)
                  },
                  "close-drawer": function ($event) {
                    return _setup.closeDrawer()
                  },
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "drawer" }, [
        _c(
          "div",
          { staticClass: "tw-sticky tw-top-[106px]" },
          _vm._l(
            _setup.drawerLinks[_setup.activeDrawer],
            function (drawerLink) {
              return _c(
                "div",
                { key: drawerLink.name },
                [
                  !drawerLink.isExternal
                    ? _c(
                        "router-link",
                        {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: `${drawerLink.name}-drawer-link`,
                              expression: "`${drawerLink.name}-drawer-link`",
                            },
                          ],
                          staticClass: "drawer-link",
                          attrs: { to: drawerLink.target },
                          nativeOn: {
                            click: function ($event) {
                              return _setup.closeDrawer()
                            },
                          },
                        },
                        [
                          _c(_setup.OrigamiIcon, {
                            attrs: { name: drawerLink.icon },
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "drawer-label" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.$t(`${drawerLink.title}`)) +
                                "\n\t\t\t\t\t"
                            ),
                          ]),
                        ],
                        1
                      )
                    : _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: `${drawerLink.name}-drawer-link`,
                              expression: "`${drawerLink.name}-drawer-link`",
                            },
                          ],
                          staticClass: "drawer-link",
                          attrs: { href: drawerLink.target },
                        },
                        [
                          _c(_setup.OrigamiIcon, {
                            attrs: { name: drawerLink.icon },
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "drawer-label" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.$t(`${drawerLink.title}`)) +
                                "\n\t\t\t\t\t"
                            ),
                          ]),
                        ],
                        1
                      ),
                ],
                1
              )
            }
          ),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }