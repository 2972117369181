import { ref } from "vue";
import { getFlag } from "@paper-co/web-toolkit";

export default function useFeatureFlag(flagName) {
	const flag = ref(false);
	getFlag(flagName).then((value) => {
		flag.value = value;
	});
	return flag;
}
