var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "BaseSkeletonLoader tw-inline-flex tw-relative tw-overflow-hidden tw-rounded tw-align-middle",
    },
    [
      _vm._t("default", function () {
        return [_vm._v(" ")]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }