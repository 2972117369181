var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.handleClickOutside,
          expression: "handleClickOutside",
        },
        {
          name: "jest",
          rawName: "v-jest",
          value: "input-container",
          expression: "'input-container'",
        },
      ],
      staticClass:
        "tw-relative tw-flex tw-items-center tw-flex-nowrap tw-bg-white-high tw-text-black-high tw-rounded tw-border-2 tw-border-grey-dark tw-cursor-text",
      class: [
        _vm.variant,
        {
          disabled: _vm.disabled,
          focused: _vm.focused,
          "hover:tw-bg-grey-pale": !_vm.focused,
        },
      ],
      on: {
        click: function ($event) {
          return _vm.$refs.input.focus()
        },
      },
    },
    [
      _vm._t("prepend"),
      _vm._v(" "),
      _c(
        "input",
        _vm._b(
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "input",
                expression: "'input'",
              },
            ],
            ref: "input",
            staticClass:
              "tw-flex tw-h-9 tw-w-full tw-text-inherit tw-text-xs tw-m-0 tw-px-4 tw-py-2 tw-border-0 tw-rounded tw-outline-none",
            attrs: {
              type: _vm.type,
              disabled: _vm.disabled,
              "aria-label": _vm.ariaLabel,
              autofocus: _vm.autofocus,
              placeholder: _vm.placeholder,
            },
            domProps: { value: _vm.content },
            on: {
              input: _vm.handleInput,
              focus: function ($event) {
                _vm.focused = true
              },
              blur: function ($event) {
                _vm.focused = false
              },
            },
          },
          "input",
          _vm.$attrs,
          false
        )
      ),
      _vm._v(" "),
      _vm._t("append", function () {
        return [
          _vm.variant === "success"
            ? _c("Check", {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "icon-check",
                    expression: "'icon-check'",
                  },
                ],
                staticClass: "tw-text-green-regular tw-m-2",
              })
            : _vm.variant === "warning"
            ? _c("Warning", {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "icon-warning",
                    expression: "'icon-warning'",
                  },
                ],
                staticClass: "tw-text-origami-red-400 tw-m-2",
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }