var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { staticClass: "navbar tw-bg-blue-darker" },
    [
      _c(
        "div",
        {
          staticClass:
            "tw-max-w-screen-xxl tw-w-full tw-h-full tw-flex tw-items-center tw-justify-between tw-text-sm tw-px-6 sm:tw-px-0 tw-mx-auto",
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("NavbarUserLinks", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "user-links",
                expression: "'user-links'",
              },
            ],
            staticClass: "tw-hidden xl:tw-flex",
            attrs: {
              "user-links": _vm.userLinks.filter(
                (link) => link.title !== "navbar.resourceHub"
              ),
            },
          }),
          _vm._v(" "),
          _vm.currentUser?.role === "teacher"
            ? [
                _vm.featureFlagPaperAcademyLink
                  ? _c("ResourceHubDropdown", {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "resource-dropdown",
                          expression: "'resource-dropdown'",
                        },
                      ],
                    })
                  : _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "resource-hub-link",
                            expression: "'resource-hub-link'",
                          },
                        ],
                        staticClass:
                          "tw-font-bold tw-text-origami-white tw-hidden xl:tw-inline-block",
                        attrs: {
                          href: "https://sites.google.com/paper.co/resourcehub",
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t" +
                            _vm._s(_vm.$t("navbar.resourceHub")) +
                            "\n\t\t\t\t"
                        ),
                        _c("OrigamiIcon", { attrs: { name: "external" } }),
                      ],
                      1
                    ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "tw-ml-auto tw-hidden md:tw-block tw-text-origami-white",
            },
            [_vm._t("appendAfterNavLinks")],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "data-cy",
                  rawName: "v-data-cy",
                  value: "navbar-dropdown-mobile",
                  expression: "'navbar-dropdown-mobile'",
                },
              ],
              staticClass:
                "tw-relative tw-items-center tw-hidden xl:tw-flex tw-z-10 tw-pr-4 tw-border-r tw-border-origami-grey-200 tw-mr-4",
            },
            [
              _vm.isUserStudent
                ? _c("LanguagePicker", {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "language-picker",
                        expression: "'language-picker'",
                      },
                    ],
                    on: {
                      "select-language": (lang) =>
                        _vm.$store.dispatch("Lang/changeLanguage", lang),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "data-cy",
                  rawName: "v-data-cy",
                  value: "navbar-dropdown-desktop",
                  expression: "'navbar-dropdown-desktop'",
                },
              ],
              staticClass:
                "tw-relative tw-h-full tw-items-center tw-hidden xl:tw-flex tw-z-10 tw-text-origami-white",
            },
            [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "dropdown-button",
                      expression: "'dropdown-button'",
                    },
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.handleClickOutside,
                      expression: "handleClickOutside",
                    },
                  ],
                  staticClass:
                    "navDropdownBtn tw-font-bold tw-flex tw-items-center tw-cursor-pointer",
                  attrs: {
                    id: "navDropdownBtn",
                    "aria-controls": "navDropdownItemContainer",
                    "aria-expanded": _vm.isDropdownOpen.toString(),
                    "data-pendo-id": "nav-dropdown",
                  },
                  on: {
                    keydown: [
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        $event.preventDefault()
                        _vm.isDropdownOpen = !_vm.isDropdownOpen
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "esc", 27, $event.key, [
                            "Esc",
                            "Escape",
                          ])
                        )
                          return null
                        _vm.isDropdownOpen = false
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "down", 40, $event.key, [
                            "Down",
                            "ArrowDown",
                          ])
                        )
                          return null
                        $event.preventDefault()
                        return _vm.focusLink(0)
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                        )
                          return null
                        if (!$event.shiftKey) return null
                        _vm.isDropdownOpen = false
                      },
                    ],
                    click: function ($event) {
                      _vm.isDropdownOpen = !_vm.isDropdownOpen
                    },
                  },
                },
                [
                  _vm.isUserStudent
                    ? _c("AvatarIcon", {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "avatar-icon",
                            expression: "'avatar-icon'",
                          },
                        ],
                        staticClass: "tw-mr-2",
                        attrs: { user: _vm.userData },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "full-name",
                          expression: "'full-name'",
                        },
                      ],
                      staticClass: "tw-mr-3.5",
                    },
                    [_vm._v(_vm._s(_vm.fullName))]
                  ),
                  _vm._v(" "),
                  _c("OrigamiIcon", { attrs: { name: "caret" } }),
                ],
                1
              ),
              _vm._v(" "),
              _c("transition", { attrs: { name: "fade" } }, [
                _c(
                  "ul",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isDropdownOpen,
                        expression: "isDropdownOpen",
                      },
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "dropdown",
                        expression: "'dropdown'",
                      },
                    ],
                    staticClass:
                      "tw-absolute tw-top-full tw-right-0 tw-text-black-high tw-shadow-elevation-1 tw-bg-white-high tw-rounded-lg",
                    attrs: { id: "navDropdownItemContainer" },
                  },
                  _vm._l(_vm.dropdownLinks, function (link, index) {
                    return _c(
                      "li",
                      { key: `link-${index}` },
                      [
                        _c(
                          "BaseLink",
                          {
                            directives: [
                              {
                                name: "jest",
                                rawName: "v-jest",
                                value: "dropdown-link",
                                expression: "'dropdown-link'",
                              },
                              {
                                name: "data-cy",
                                rawName: "v-data-cy",
                                value: `navbar-dropdown-${link.target}`,
                                expression: "`navbar-dropdown-${link.target}`",
                              },
                            ],
                            key: link.target,
                            staticClass:
                              "navItemLink tw-flex tw-items-center tw-py-3 tw-px-4 tw-hover:tw-bg-blue-pastel tw-whitespace-nowrap tw-gap-4",
                            class: {
                              "tw-text-base": _vm.featureFlagPaperAcademyLink,
                              "tw-border-b tw-border-origami-grey-100":
                                index < _vm.dropdownLinks.length - 1 &&
                                !_vm.featureFlagPaperAcademyLink,
                            },
                            attrs: { to: link.target },
                            nativeOn: {
                              click: function ($event) {
                                _vm.isDropdownOpen = false
                              },
                              keydown: [
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "down",
                                      40,
                                      $event.key,
                                      ["Down", "ArrowDown"]
                                    )
                                  )
                                    return null
                                  $event.preventDefault()
                                  return _vm.focusLink(index + 1)
                                },
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "up",
                                      38,
                                      $event.key,
                                      ["Up", "ArrowUp"]
                                    )
                                  )
                                    return null
                                  $event.preventDefault()
                                  return _vm.focusLink(index - 1)
                                },
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "esc",
                                      27,
                                      $event.key,
                                      ["Esc", "Escape"]
                                    )
                                  )
                                    return null
                                  return _vm.focusDropdown()
                                },
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "tab",
                                      9,
                                      $event.key,
                                      "Tab"
                                    )
                                  )
                                    return null
                                  if (
                                    $event.ctrlKey ||
                                    $event.shiftKey ||
                                    $event.altKey ||
                                    $event.metaKey
                                  )
                                    return null
                                  return _vm.handleTab(index)
                                },
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "tab",
                                      9,
                                      $event.key,
                                      "Tab"
                                    )
                                  )
                                    return null
                                  if (!$event.shiftKey) return null
                                  $event.stopPropagation()
                                },
                              ],
                            },
                          },
                          [
                            (_vm.isUserStudent ||
                              _vm.featureFlagPaperAcademyLink) &&
                            _vm.getNavIcon(link.title)
                              ? _c("OrigamiIcon", {
                                  directives: [
                                    {
                                      name: "jest",
                                      rawName: "v-jest",
                                      value: "dropdown-icon",
                                      expression: "'dropdown-icon'",
                                    },
                                  ],
                                  attrs: { name: _vm.getNavIcon(link.title) },
                                })
                              : _vm._e(),
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.$t(link.title)) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("MobileMenu", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "mobile-menu",
                expression: "'mobile-menu'",
              },
            ],
            staticClass: "xl:tw-hidden",
            attrs: { "menu-items": _vm.mobileMenu },
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("appendToEnd"),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("a", { attrs: { href: "/" } }, [
      _c("img", {
        attrs: {
          src: "/images/logo-paper.svg",
          width: "171",
          height: "48",
          alt: "PAPER",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }