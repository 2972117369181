var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.variant, attrs: { id: "notificationSettings" } },
    [
      !_vm.isStudentProfileVariant
        ? _c("h2", { staticClass: "accountSettings__title" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.$t("sidebar.notifications")) + "\n\t"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "p",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "notifications-description",
              expression: "'notifications-description'",
            },
          ],
          staticClass: "tw-mt-2 tw-mb-4",
        },
        [
          _vm._v(
            "\n\t\t" + _vm._s(_vm.$t("notifications.description")) + "\n\t"
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "notificationsContainer" },
        [
          _c("transition", { attrs: { name: "notification-fadeout" } }, [
            _vm.isSuccess && _vm.isShowingMessage
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "success-text",
                        expression: "'success-text'",
                      },
                    ],
                    staticClass: "successMessage",
                  },
                  [
                    _c("IconCheckMark", {
                      attrs: { "icon-color": "currentColor" },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "successMessage__text" }, [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.$t("notifications.settings_updated")) +
                          "\n\t\t\t\t"
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.isError
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "error-text",
                      expression: "'error-text'",
                    },
                  ],
                  staticClass: "errorContainer",
                },
                [
                  _c("IconExclamationMark"),
                  _vm._v(" "),
                  _c("p", { staticClass: "infoText" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.$t("notifications.error")) +
                        "\n\t\t\t"
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("form", { staticClass: "accountSettingsNew" }, [
        _c("h3", { staticClass: "tw-text-lg tw-font-bold tw-mt-4" }, [
          _vm._v(
            "\n\t\t\t" +
              _vm._s(_vm.$t("notifications.notification_type_email")) +
              "\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group checkboxGroup" }, [
          _c("input", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "Email-checkbox",
                expression: "'Email-checkbox'",
              },
            ],
            attrs: {
              id: "statusNotifications",
              type: "checkbox",
              name: "statusNotifications",
            },
            domProps: { checked: _vm.Settings.isEmailNotificationsEnabled },
            on: {
              click: function ($event) {
                return _vm.toggleNotificationsCheckbox("Email")
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "grouped" }, [
            _c("label", { attrs: { for: "statusNotifications" } }, [
              _vm._v(
                "\n\t\t\t\t\t" +
                  _vm._s(_vm.$t("notifications.status_updates")) +
                  "\n\t\t\t\t"
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n\t\t\t\t\t" +
                  _vm._s(_vm.$t("notifications.receive_status_updates")) +
                  "\n\t\t\t\t"
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group checkboxGroup" }, [
          _c("input", {
            attrs: {
              id: "eventSettings",
              type: "checkbox",
              name: "eventSettings",
              disabled: !_vm.isPushManagerPresent,
            },
            domProps: { checked: _vm.Settings.isEventNotificationsEnabled },
            on: {
              click: function ($event) {
                return _vm.toggleNotificationsCheckbox("Event")
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "grouped" }, [
            _c(
              "label",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "Event-checkbox",
                    expression: "'Event-checkbox'",
                  },
                ],
                attrs: { for: "eventSettings" },
              },
              [
                _vm._v(
                  "\n\t\t\t\t\t" +
                    _vm._s(_vm.$t("notifications.event_updates")) +
                    "\n\t\t\t\t"
                ),
              ]
            ),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n\t\t\t\t\t" +
                  _vm._s(_vm.$t("notifications.receive_event_updates")) +
                  "\n\t\t\t\t"
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("h3", { staticClass: "tw-text-lg tw-font-bold tw-mt-4" }, [
          _vm._v(
            "\n\t\t\t" +
              _vm._s(_vm.$t("notifications.notification_type_app")) +
              "\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group checkboxGroup" }, [
          _c("input", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "Mobile-checkbox",
                expression: "'Mobile-checkbox'",
              },
            ],
            attrs: {
              id: "appSettings",
              type: "checkbox",
              name: "appSettings",
              disabled: !_vm.isPushManagerPresent,
            },
            domProps: { checked: _vm.Settings.isMobileNotificationsEnabled },
            on: {
              click: function ($event) {
                return _vm.toggleNotificationsCheckbox("Mobile")
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "grouped" }, [
            _c("label", { attrs: { for: "appSettings" } }, [
              _vm._v(
                "\n\t\t\t\t\t" +
                  _vm._s(_vm.$t("notifications.status_updates")) +
                  "\n\t\t\t\t"
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n\t\t\t\t\t" +
                  _vm._s(_vm.$t("notifications.receive_status_updates")) +
                  "\n\t\t\t\t"
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("h3", { staticClass: "tw-text-lg tw-font-bold tw-mt-4" }, [
          _vm._v(
            "\n\t\t\t" +
              _vm._s(_vm.$t("notifications.website_settings")) +
              "\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group checkboxGroup" }, [
          _c("input", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "AppSounds-checkbox",
                expression: "'AppSounds-checkbox'",
              },
            ],
            attrs: {
              id: "audioSettings",
              type: "checkbox",
              name: "audioSettings",
            },
            domProps: { checked: _vm.Settings.isAppSoundsEnabled },
            on: {
              click: function ($event) {
                return _vm.toggleNotificationsCheckbox("AppSounds")
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "grouped" }, [
            _c("label", { attrs: { for: "audioSettings" } }, [
              _vm._v(
                "\n\t\t\t\t\t" +
                  _vm._s(_vm.$t("notifications.app_sounds")) +
                  "\n\t\t\t\t"
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(_vm._s(_vm.$t("notifications.app_sounds_details"))),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }