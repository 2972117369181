<script>
import { differenceInHours, differenceInMinutes } from "date-fns";

import ServiceDashboardListItemAbstract from "./ServiceDashboardListItemAbstract.vue";
export default {
	extends: ServiceDashboardListItemAbstract,
	props: {
		resource: {
			type: Object,
			required: true,
		},
	},
	computed: {
		/**
		 * @return {Boolean}
		 */
		isEssay() {
			return true;
		},
		/**
		 * @return {String}
		 */
		iconComponent() {
			return "icon-essay";
		},
		/**
		 * @return {String}
		 */
		leftTopText() {
			return this.resource.student_name;
		},
		/**
		 * @return {String}
		 */
		middleTopText() {
			return this.getTimeRemaining();
		},
		/**
		 * @return {String}
		 */
		middleBottomText() {
			return `Essay ID ${this.resource.essay_id}`;
		},
		/**
		 * @return {String}
		 */
		essayOverdueType() {
			const currentTimeTimestamp = Math.floor(Date.now() / 1000);
			const timeRemaining = this.resource.due_at - currentTimeTimestamp;
			let essayOverdueType = "";

			if (timeRemaining < 14400 && timeRemaining >= 7200) {
				essayOverdueType = "risk";
			} else if (timeRemaining < 7200 && timeRemaining >= 0) {
				essayOverdueType = "critical";
			} else if (timeRemaining < 0) {
				essayOverdueType = "late";
			}
			return essayOverdueType;
		},
		/**
		 * @return {String}
		 */
		leftBottomText() {
			return this.resource.school;
		},
		/**
		 * @return {String}
		 */
		timeElapsed() {
			const essayPickedUpAt = new Date(this.resource.picked_up_at * 1000);
			const hours = differenceInHours(this.currentDate, essayPickedUpAt);
			let mins = differenceInMinutes(this.currentDate, essayPickedUpAt);
			mins = mins % 60;
			return `${hours}h ${mins}min`;
		},
	},
	methods: {
		/**
		 * @return {String}
		 */
		getTimeRemaining() {
			const minutesFromNow = differenceInMinutes(this.resource.due_at * 1000, new Date());

			const hours = Math.floor(Math.abs(minutesFromNow) / 60);
			const hrStr = hours === 1 ? "hr" : "hrs";

			const minutes = Math.abs(minutesFromNow) % 60;
			const minStr = minutes === 1 ? "min" : "mins";

			const temporality = minutesFromNow < 0 ? "late" : "left";

			return `${hours}${hrStr} ${minutes}${minStr} ${temporality}`;
		},
		goToActivity() {
			this.$router.push(`/${this.currentUser.role}/activity?search="${this.resource.essay_id}"&tab=Essays&from=${this.resource.created_at}`);
		},
		copyResourceId() {
			navigator.clipboard.writeText(this.resource.essay_id);
		},
	},
};
</script>
