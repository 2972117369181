<script setup>
import { OrigamiButton } from "@origami/vue2";

import useStore from "@/composables/useStore.js";

import { useLoading } from "../composables/loading.js";

const store = useStore();

const isLoading = useLoading();

const handleRefresh = async() => {
	try {
		store.dispatch("ServiceAnalytics/setLoadingState", true);
		await store.dispatch("ServiceAnalytics/getFilteredResults");
	} catch (e) {
		Sentry.captureException(e);
	} finally {
		store.dispatch("ServiceAnalytics/setLoadingState", false);
	}
};
</script>

<template>
	<header
		data-cy="page-header"
		class="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-max-w-[1440px] tw-m-auto tw-px-8 tw-py-4"
	>
		<div>
			<h1
				v-jest="'header-text'"
				class="tw-text-origami-lg tw-font-origami-bold"
				data-cy="page-header-title"
			>
				Platform Load Alerts
			</h1>
		</div>
		<div>
			<OrigamiButton
				v-jest="'header-button'"
				data-cy="page-header-primary-button"
				:is-disabled="isLoading"
				@click.native="handleRefresh"
			>
				<span>Refresh</span>
			</OrigamiButton>
		</div>
	</header>
</template>

