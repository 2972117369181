<template>
	<div>
		<BreaksNotification
			class="tw-mb-4"
			:notification-type="notificationType"
		>
			<BioBreakDetails />
			<p
				v-jest="'bio-break-end-prompt-text'"
				v-data-cy="'bio-break-end-prompt-text'"
				class="tw-text-sm"
			>
				{{ promptText }}
			</p>
		</BreaksNotification>
		<OrigamiButton
			v-jest="'end-bio-break-button'"
			v-data-cy="'end-bio-break-button'"
			variant="primary"
			class="tw-m-auto tw-mb-2 tw-w-full tw-justify-center"
			@click.native="endBioBreak"
		>
			I'm back! End break
		</OrigamiButton>
	</div>
</template>

<script>
import { OrigamiButton } from "@origami/vue2";

import BreaksNotification from "../templates/BreaksNotification.vue";

import BioBreakDetails from "./BioBreakDetails.vue";

export default {
	name: "BioBreakEndPrompt",
	components: {
		BreaksNotification,
		BioBreakDetails,
		OrigamiButton,
	},
	computed: {
		promptText() {
			return this.isLateFromBioBreak ?
				"You are currently over the 5 minutes limit of your break, please end your break now."
				: "Please end your break as soon as you are back.";
		},
		isLateFromBioBreak() {
			return this.$store.getters["BreaksManagementWidget/isLateFromBioBreak"];
		},
		notificationType() {
			return this.isLateFromBioBreak ? "error" : "info";
		},
	},
	methods: {
		endBioBreak() {
			this.$store.dispatch("BreaksManagementWidget/endBioBreak");
		},
	},
};
</script>
