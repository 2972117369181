var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "tw-relative tw-ml-0.5" },
    [
      _c(
        _setup.BaseButton,
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "punch-clock-button",
              expression: "'punch-clock-button'",
            },
          ],
          staticClass: "tw-rounded-r !tw-rounded-l-none",
          class: {
            "active !tw-cursor-default":
              _setup.isShiftInProgress && !_setup.isShiftEnding,
          },
          attrs: {
            "data-cy": "punch-button",
            type: "SUBMIT",
            "is-disabled": _setup.isLockedOutOfShift,
          },
          nativeOn: {
            click: function ($event) {
              return _setup.handlePunch.apply(null, arguments)
            },
          },
        },
        [
          _c("template", { slot: "text" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "punch-button-text",
                    expression: "'punch-button-text'",
                  },
                ],
              },
              [_vm._v(_vm._s(_setup.buttonText))]
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _setup.showCountdown
        ? _c(_setup.PunchClockCountdown, {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "punch-clock-countdown",
                expression: "'punch-clock-countdown'",
              },
            ],
            attrs: {
              "data-cy": "punch-countdown",
              "current-date": _setup.props.currentDate,
              shift: _setup.shift,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }