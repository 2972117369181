<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		viewBox="0 0 21 25"
	>
		<path
			fill="#FFF"
			fill-rule="nonzero"
			d="M15.226 6.009L9.228 0v4.054C4.034 4.702 0 9.14 0 14.527 0 19.915 4.02 24.353 9.228 25v-2.668a7.917 7.917 0 0 1-6.591-7.805 7.917 7.917 0 0 1 6.59-7.805v5.164l5.999-5.877zM21 13.207a10.451 10.451 0 0 0-2.136-5.138l-1.872 1.876a7.675 7.675 0 0 1 1.345 3.262H21zm-9.136 9.112v2.668a10.436 10.436 0 0 0 5.142-2.126l-1.899-1.902a7.62 7.62 0 0 1-3.243 1.36zm5.128-3.196l1.872 1.862A10.451 10.451 0 0 0 21 15.848h-2.663a7.796 7.796 0 0 1-1.345 3.275z"
		/>
	</svg>
</template>

<script>
import Colors from "@/utilities/Colors.js";
export default {
	props: {
		width: {
			type: [Number, String],
			default: 21,
		},
		height: {
			type: [Number, String],
			default: 25,
		},
		iconColor: {
			type: String,
			default: Colors["C_BLUE"],
		},
	},
};
</script>
