import { format } from "date-fns";

export default async function(to, from, next) {
	if (this.getters["Student/isUserInOperatingHours"]) {
		return next();
	} else {
		const { operatingHoursStart, operatingHoursEnd } = this.getters["Student/getSchoolOperatingHours"];
		const operatingHoursStartText = format(operatingHoursStart, "hh:mm a");
		const operatingHoursEndText = format(operatingHoursEnd, "hh:mm a");
		const text = `PAPER is only available to your school between ${operatingHoursStartText} and ${operatingHoursEndText}`;
		alert(text, "danger");
		return next("/outside-hours");
	}
}
