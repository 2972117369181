<script setup>
import { ref, computed } from "vue";
import { OrigamiButton } from "@origami/vue2";

import ServiceDashboardSessionLoadHeader from "./ServiceDashboardSessionLoadHeader.vue";
import ServiceDashboardSessionLoadBarGraph from "./ServiceDashboardSessionLoadBarGraph.vue";

const isGraphShowing = ref(false);

const handleShowGraph = () => isGraphShowing.value = !isGraphShowing.value;

const graphButtonText = computed(() => !isGraphShowing.value ? "Show graph" : "Hide graph");
</script>

<template>
	<div class="tw-w-full tw-max-w-[1000px] tw-mt-2">
		<div class="tw-bg-origami-white tw-px-6 tw-py-4 tw-mr-4 tw-flex tw-items-start tw-justify-between">
			<ServiceDashboardSessionLoadHeader
				data-cy="session-load-header"
			/>
			<OrigamiButton
				v-jest="'show-graph-button'"
				data-cy="show-graph-button"
				variant="tertiary"
				@click.native="handleShowGraph"
			>
				{{ graphButtonText }}
			</OrigamiButton>
		</div>
		<ServiceDashboardSessionLoadBarGraph
			v-show="isGraphShowing"
			v-jest="'bar-chart'"
			data-cy="bar-chart"
			class="tw-bg-origami-white tw-mr-4 tw-px-6 tw-py-2"
		/>
	</div>
</template>
