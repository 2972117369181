module.exports = new Promise((resolve, reject) => {
		window["_paperco_mfe_resolver_student-notifications-drawer-web"] = { resolve, reject };
		const script = document.createElement("script");
		script.type = "module";
		script.async = true;
		script.innerHTML = `
			import("https://student-notifications-drawer-web.paperstg.co/assets/remoteEntry.mjs")
				.then(remoteEntry => window["_paperco_mfe_resolver_student-notifications-drawer-web"].resolve(remoteEntry))
				.catch(() => window["_paperco_mfe_resolver_student-notifications-drawer-web"].reject("Failed to import _paperco_mfe_resolver_student-notifications-drawer-web"));
		`
		document.head.appendChild(script);
	});