<template>
	<div>
		<ServiceDashboard />
		<SuperuserTransferSessionModal />
		<SuperuserFailedTransferSessionModal />
		<ScrollToTop />
	</div>
</template>

<script>
import { mapState } from "vuex";

// Components
import { ScrollToTop } from "@/modules/Navigation/index.js";

import { callActionAtInterval } from "../../../utilities/callActionAtInterval.js";

import ServiceDashboard from "./ServiceDashboard.vue";
import SuperuserTransferSessionModal from "./SuperuserTransferSessionModal.vue";
import SuperuserFailedTransferSessionModal from "./SuperuserFailedTransferSessionModal.vue";

const ONE_SECOND_IN_MS = 1000;

export default {
	name: "TutorsSummaryWrapper",
	components: {
		ServiceDashboard,
		SuperuserTransferSessionModal,
		SuperuserFailedTransferSessionModal,
		ScrollToTop,
	},
	data: function() {
		return {
			isPageCreated: false,
			getSummary: null,
			getLiveTutors: null,
			getEssayTutors: null,
			refreshTimers: [],
			featureFlagLiveTutorsPollingIntervalSeconds: 10,
		};
	},
	computed: {
		...mapState([
			"currentUser",
			"PlatformManager",
		]),
		liveTutorsLength() {
			return this.$store.getters["PlatformManager/ServiceSummaryDashboard/liveTutorsLength"];
		},
		essayTutorsLength() {
			return this.$store.getters["PlatformManager/ServiceSummaryDashboard/essayTutorsLength"];
		},
	},
	watch: {
		liveTutorsLength: {
			handler: function() {
				if (this.PlatformManager.ServiceSummaryDashboard.isLoadingLiveTutors === false) {
					this.getResource("PlatformManager/ServiceSummaryDashboard/getUpcomingTutors");
				}
			},
		},
		essayTutorsLength: {
			handler: function() {
				if (this.PlatformManager.ServiceSummaryDashboard.isLoadingEssayTutors === false) {
					this.getResource("PlatformManager/ServiceSummaryDashboard/getUpcomingTutors");
				}
			},
		},
	},
	async created() {
		this.refreshTimers.push(callActionAtInterval(this.$store.dispatch, "PlatformManager/ServiceSummaryDashboard/getSummary", 60000));
		this.refreshTimers.push(callActionAtInterval(this.$store.dispatch, "PlatformManager/ServiceSummaryDashboard/getEssayTutors", 60000));

		this.$store.dispatch("PlatformManager/ServiceSummaryDashboard/getTutorManagers");
		this.$store.dispatch("PlatformManager/ServiceSummaryDashboard/getUpcomingTutors");
		this.$store.dispatch("TutorShiftSummary/getCurrentShift");

		this.featureFlagLiveTutorsPollingIntervalSeconds = await this.$getFlag("ser-929-service-summary-live-tutors-polling-interval");
		this.refreshTimers.push(
			callActionAtInterval(
				this.$store.dispatch,
				"PlatformManager/ServiceSummaryDashboard/getLiveTutors",
				this.featureFlagLiveTutorsPollingIntervalSeconds * ONE_SECOND_IN_MS));
	},
	beforeDestroy() {
		this.refreshTimers.forEach((clearTimer) => clearTimer());
	},
};
</script>
<style scoped>
.card.summaryCard {
	padding: 16px 24px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.summaryCard__titleRow {
	display: flex;
	justify-content: space-between;
}
.summaryCard__title {
	font-size: 20px;
	font-weight: bold;
	flex: 1 0 0;
	margin: 0;
}

.summaryCard__badges {
	min-height: 29px;
	display: flex;
	align-items: flex-end;
	flex-wrap: wrap;
	flex: 1 1 86px;
	justify-content: flex-end;
}

.summaryCard__badge {
	font-size: 12px;
	font-weight: bold;
	color: var(--c-white);
	height: 24px;
	padding: 0px 5px;
	white-space: nowrap;
}

.summaryCard__badge:not(:first-child) {
	margin-left: 8px;
}

.summaryCard__badge--red {
	background-color: #ff0117;
}

.summaryCard__badge--salmon {
	background-color: #ff5d6b;
}

.summaryCard__badge--yellow {
	background-color: #ffdd8c;
}

.summaryCardContent {
	display: flex;
	justify-content: space-between;
	height: 96px;
	margin-top: 24px;
}

.summaryCardContent__container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 2;
}

.summaryCardContent__container:nth-child(2) {
	border-left: 1px solid #d8d8d8;
	padding-left: 16px;
	flex: 1;
}

.summaryCardContent__left {
	font-size: 16px;
}

.summaryCardContent__title {
	font-size: 16px;
	margin: 0;
}
.summaryCardContent__leftNumber {
	font-size: 64px;
	font-weight: bold;
	line-height: 50px;
}
.summaryCardContent__inner {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
}

.summaryCardContent__details {
	display: flex;
	flex-direction: column;
	font-size: 12px;
	line-height: 12px;
	margin: 0 10px;
}
.summaryCardContent__rightNumber {
	font-size: 36px;
	font-weight: bold;
	line-height: 28px;
}

.loading {
	color: var(--c-lighter-gray);
}


:deep(svg) {
	vertical-align: unset;
}
</style>
