var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      class: { active: _vm.active, readonly: _vm.readonly },
      attrs: { type: "button" },
      on: {
        click: function ($event) {
          !_vm.readonly && _vm.$emit("click", $event)
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }