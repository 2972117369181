<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		:width="width"
		:height="height"
		viewBox="0 0 16 16"
	>
		<defs>
			<path
				:id="randomId"
				d="M0 12.667V16h3.333l9.83-9.83L9.83 2.837 0 12.667zm15.74-9.074a.885.885 0 0 0 0-1.253L13.66.26a.885.885 0 0 0-1.253 0l-1.626 1.626 3.333 3.333 1.626-1.626z"
			/>
		</defs>
		<use
			:fill="iconColor"
			fill-rule="evenodd"
			:xlink:href="`#${randomId}`"
		/>
	</svg>
</template>

<script>
import Colors from "../../utilities/Colors.js";

import IconId from "./IconId.js";
export default {
	extends: IconId,
	props: {
		width: {
			type: [Number, String],
			default: 16,
		},
		height: {
			type: [Number, String],
			default: 16,
		},
		iconColor: {
			type: String,
			default: Colors.C_BLUE,
		},
	},
};
</script>
