import AbstractMessageClass from "./AbstractMessageClass.js";

function MediaMessage(
	id,
	userId,
	userName,
	createdAt,
	sequencePosition,
	isLastMessage,
	isQuestion,
	isDeleted,
	isQuarantined,
	isFile,
	isTutor,
	url,
	thumbnailUrl,
	mediaType,
	voiceNoteMetadata,
) {
	AbstractMessageClass.call(
		this,
		id,
		userId,
		userName,
		createdAt,
		sequencePosition,
		isLastMessage,
		isQuestion,
		isDeleted,
		isFile,
		isTutor,
	);
	this.url = url;
	this.thumbnailUrl = thumbnailUrl;
	this.mediaType = mediaType;
	this.isQuarantined = isQuarantined;
	this.voiceNoteMetadata = voiceNoteMetadata;
}

MediaMessage.prototype = Object.create(AbstractMessageClass.prototype);

export default MediaMessage;
