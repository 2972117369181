import Vue from "vue";

export default {
	namespaced: true,
	state: {
		tags: {},
	},
	mutations: {
		PUSH_TAG_IN_TAGS(state, payload) {
			if (state.tags.hasOwnProperty(payload.sessionId)) {
				state.tags[payload.sessionId].push(payload.tag);
			} else {
				Vue.set(state.tags, payload.sessionId, [payload.tag]);
			}
		},
		SPLICE_TAG_FROM_TAGS(state, payload) {
			state.tags[payload.sessionId].splice(payload.index, 1);
		},
	},
	getters: {
		sessionTags(state, getters, rootState) {
			if (
				rootState.Classroom.currentSessionId !== null &&
				state.tags[rootState.Classroom.currentSessionId] !== undefined
			) {
				return state.tags[rootState.Classroom.currentSessionId];
			} else {
				return [];
			}
		},
	},
};
