var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "mobile-menu" } },
    [
      _c(
        "button",
        {
          ref: "mobile-menu-button",
          staticClass: "tw-z-50 tw-text-origami-white",
          attrs: {
            id: "mobile-menu-button",
            "data-pendo-id": "nav-dropdown-mobile",
            "aria-label": _vm.ariaLabel,
            "aria-expanded": _vm.ariaExpanded,
            "aria-controls": "mobile-menu-list",
          },
          on: {
            click: function ($event) {
              return _vm.setMenuVisibility(!_vm.isOpen)
            },
            keydown: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.preventDefault()
                return _vm.setMenuVisibility(!_vm.isOpen)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape",
                  ])
                )
                  return null
                return _vm.setMenuVisibility(false)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown",
                  ])
                )
                  return null
                $event.preventDefault()
                return _vm.focusListItem(0)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                )
                  return null
                if (!$event.shiftKey) return null
                return _vm.setMenuVisibility(false)
              },
            ],
          },
        },
        [
          !_vm.isOpen
            ? _c("Burger", {
                attrs: { "aria-hidden": "true", focusable: "false" },
              })
            : _c("Close", {
                staticClass: "tw-w-8 tw-h-8",
                attrs: { "aria-hidden": "true", focusable: "false" },
              }),
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOpen,
                expression: "isOpen",
              },
            ],
            staticClass:
              "tw-fixed tw-bottom-0 tw-inset-x-0 tw-bg-blue-darker tw-w-full tw-z-40 xl:tw-hidden tw-overflow-scroll",
            attrs: { id: "mobile-menu-list" },
          },
          _vm._l(_vm.menuItems, function (link, index) {
            return _c(
              "li",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: `list-link-${link.title}`,
                    expression: "`list-link-${link.title}`",
                  },
                ],
                key: `mobile-link-${index}`,
                class: { "user-links-end": _vm.isEndOfUserLinks(link.title) },
              },
              [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "mobile-nav-button",
                        expression: "'mobile-nav-button'",
                      },
                    ],
                    key: link.target,
                    ref: `mobile-menu-list-item-${index}`,
                    refInFor: true,
                    staticClass:
                      "tw-block tw-font-extrabold tw-text-origami-white tw-px-6 tw-py-3 mobileNavButton",
                    class: _vm.featureFlagPaperAcademyLink
                      ? "tw-text-lg"
                      : "tw-text-2xl",
                    attrs: { href: link.target, role: "link" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.handleGoToLink(link.target)
                      },
                      keydown: [
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "down", 40, $event.key, [
                              "Down",
                              "ArrowDown",
                            ])
                          )
                            return null
                          $event.preventDefault()
                          _vm.focusListItem(
                            Math.min(index + 1, _vm.menuItems.length)
                          )
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "up", 38, $event.key, [
                              "Up",
                              "ArrowUp",
                            ])
                          )
                            return null
                          $event.preventDefault()
                          _vm.focusListItem(Math.max(0, index - 1))
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "esc", 27, $event.key, [
                              "Esc",
                              "Escape",
                            ])
                          )
                            return null
                          return _vm.focusMenuButton()
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                          )
                            return null
                          if (
                            $event.ctrlKey ||
                            $event.shiftKey ||
                            $event.altKey ||
                            $event.metaKey
                          )
                            return null
                          return _vm.handleTab(index)
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                          )
                            return null
                          if (!$event.shiftKey) return null
                          $event.stopPropagation()
                        },
                      ],
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.$t(link.title)) +
                        "\n\n\t\t\t\t\t"
                    ),
                    _vm.showExternalIcon(link.title)
                      ? _c("OrigamiIcon", {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: `external-icon-${link.title}`,
                              expression: "`external-icon-${link.title}`",
                            },
                          ],
                          attrs: { name: "external" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }