<template>
	<header class="tw-flex tw-justify-between tw-gap-3 tw-mb-4">
		<h3
			v-jest="'achievement-title'"
			class="tw-text-lg tw-font-bold"
		>
			{{ $t("challenges.achievements") }}
		</h3>
		<BaseLink
			v-jest="'link'"
			:to="'/student/achievement-center'"
			class="tw-text-lg tw-font-bold tw-rounded-lg hover:tw-bg-origami-grey-100 focus-within:tw-bg-origami-grey-100"
		>
			<Points
				v-jest="'points'"
				:points="points"
				:is-loading="isLoadingPoints"
			/>
		</BaseLink>
	</header>
</template>

<script>
import { Points } from "@/modules/Achievements/index.js";
export default {
	components: {
		Points,
	},
	props: {
		isLoadingPoints: {
			type: Boolean,
			required: true,
		},
		points: {
			type: Number,
			required: true,
		},
	},
};
</script>
