var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ServiceDashboardSummaryCard", {
    scopedSlots: _vm._u(
      [
        {
          key: "cardTitle",
          fn: function () {
            return [_vm._v("\n\t\tReview Center\n\t")]
          },
          proxy: true,
        },
        _vm.badges.length > 0
          ? {
              key: "badges",
              fn: function () {
                return [
                  _vm._l(_vm.badgesFiltered, function (badge) {
                    return _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "data-cy",
                            rawName: "v-data-cy",
                            value: "essays-count-badge",
                            expression: "'essays-count-badge'",
                          },
                        ],
                        key: badge.color,
                        class: `summaryCard__badge tw-leading-loose ${badge.backgroundColorClass}`,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t" +
                            _vm._s(badge.num) +
                            " " +
                            _vm._s(badge.text) +
                            "\n\t\t"
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c("IconInformationCircle", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.focus",
                        value: _vm.tooltipOptions,
                        expression: "tooltipOptions",
                        modifiers: { hover: true, focus: true },
                      },
                    ],
                    staticClass: "summaryCard__icon",
                    attrs: { width: "1.25rem", height: "1.25rem" },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
        {
          key: "leftTitle",
          fn: function () {
            return [_vm._v("\n\t\tIn Queue\n\t")]
          },
          proxy: true,
        },
        {
          key: "leftNumber",
          fn: function () {
            return [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "data-cy",
                      rawName: "v-data-cy",
                      value: "essays-in-queue",
                      expression: "'essays-in-queue'",
                    },
                  ],
                  staticClass: "summaryCardContent__leftNumber",
                  class: { loading: _vm.isLoadingSummary },
                },
                [_vm._v("\n\t\t\t" + _vm._s(_vm.inQueueNum) + "\n\t\t")]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "leftDetails",
          fn: function () {
            return [
              _c("div", { staticClass: "summaryCardContent__details" }, [
                _c(
                  "span",
                  {
                    staticClass: "summaryCardContent__detailsItem",
                    attrs: { id: "essayReviewedNumber" },
                  },
                  [
                    _c(
                      "strong",
                      {
                        directives: [
                          {
                            name: "data-cy",
                            rawName: "v-data-cy",
                            value: "essays-fresh",
                            expression: "'essays-fresh'",
                          },
                        ],
                        class: { loading: _vm.isLoadingSummary },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.newInQueueNum) +
                            "\n\t\t\t\t"
                        ),
                      ]
                    ),
                    _vm._v("\n\t\t\t\t New\n\t\t\t"),
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "summaryCardContent__detailsItem" }, [
                  _c(
                    "strong",
                    {
                      directives: [
                        {
                          name: "data-cy",
                          rawName: "v-data-cy",
                          value: "essays-inProgress",
                          expression: "'essays-inProgress'",
                        },
                      ],
                      class: { loading: _vm.isLoadingSummary },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.inProgressInQueueNum) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                  _vm._v("\n\t\t\t\t In Progress\n\t\t\t"),
                ]),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "rightTitle",
          fn: function () {
            return [
              _c("span", { attrs: { id: "essayReviewedTitle" } }, [
                _vm._v("Reviewed " + _vm._s(_vm.summaryPeriodString)),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "rightNumber",
          fn: function () {
            return [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "data-cy",
                      rawName: "v-data-cy",
                      value: "essays-reviewed-today",
                      expression: "'essays-reviewed-today'",
                    },
                  ],
                  staticClass: "summaryCardContent__rightNumber",
                  class: { loading: _vm.isLoadingSummary },
                  attrs: { id: "essayReviewedNumber" },
                },
                [_vm._v("\n\t\t\t" + _vm._s(_vm.reviewedNum) + "\n\t\t")]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "rightDetails",
          fn: function () {
            return [
              _c("div", { staticClass: "summaryCardContent__details" }, [
                _c("span", { staticClass: "summaryCardContent__detailsItem" }, [
                  _c(
                    "strong",
                    {
                      directives: [
                        {
                          name: "data-cy",
                          rawName: "v-data-cy",
                          value: "essays-late",
                          expression: "'essays-late'",
                        },
                      ],
                      class: { loading: _vm.isLoadingSummary },
                      attrs: { id: "essayReviewedLateNumber" },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.reviewedLateNum) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                  _vm._v("\n\t\t\t\t Late\n\t\t\t"),
                ]),
              ]),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }