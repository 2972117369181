var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "emptyItem" },
    [
      _c(_vm.iconComponent, {
        tag: "component",
        staticClass: "emptyItem__icon",
        attrs: {
          "icon-color": "#c8c8cc",
          height: _vm.iconDimensions.height,
          width: _vm.iconDimensions.width,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "emptyItem__message" }, [
        _vm._v("\n\t\t" + _vm._s(_vm.noItemsText) + "\n\t"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }