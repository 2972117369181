import { uniq } from "lodash";

export const UNCATEGORIZED_GRADE_LEVEL_ID = -1;
export const UNCATEGORIZED_GRADE_LEVEL_NAME = "Uncategorized";

export const getGradeLabel = (grade) => {
	switch (grade) {
		case "PK":
			return "Pre-K";
		case "K":
			return "Kindergarten";
		case "U":
			return "University";
		case "Uncategorized":
			return "Not Specified";
		default:
			return `Grade ${grade}`;
	}
};

export const getGradeIds = (schoolOptions, schoolOptionsSelected) => {
	const schoolIds = schoolOptionsSelected?.length
		? schoolOptionsSelected
		: schoolOptions.map(({ id }) => id);

	return uniq(
		schoolOptions.reduce((gradeIdsAcc, { id, grade_ids }) =>
			schoolIds.includes(id)
				? gradeIdsAcc.concat(grade_ids)
				: gradeIdsAcc
		, []),
	).map((id) => id || UNCATEGORIZED_GRADE_LEVEL_ID);
};

export const getGradeLevelName = (gradesList, gradeName) => {
	return gradesList.find(
		(grade) => grade.name === gradeName,
	)?.gradeGroup?.data?.name?.split(" ").join("-").toLowerCase() || "elementary";
};
