import rootInstance from "@/services/root.js";

const baseUrl = process.env.MATH_PROBLEM_API_URL + "/api/v1/";

const mathApi = () => {
	rootInstance.defaults.baseURL = baseUrl;
	return rootInstance;
};

export default {
	studentStartedProblem(problemId, payload) {
		return mathApi().post(`/problem/${problemId}/start`, payload);
	},
	studentCompletedProblem(problemId, payload) {
		return mathApi().put(`/problem/${problemId}/complete`, payload);
	},
};
