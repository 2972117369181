<template>
	<transition name="toaster-bottom">
		<div
			v-show="Toaster.isVisible"
			v-jest="'toaster'"
			class="tw-fixed tw-z-50 tw-bottom-0 tw-left-0 tw-m-4 tw-bg-origami-grey-400 tw-rounded tw-right-0 sm:tw-right-auto"
		>
			<p
				v-jest="'toaster-text'"
				class="tw-py-3 tw-px-4 tw-text-white-high "
			>
				{{ Toaster.text }}
			</p>
		</div>
	</transition>
</template>

<script>
import { mapState } from "vuex";

export const AUTO_HIDE_DELAY = 5000;

export default {
	data() {
		return {
			autoHideTimer: null,
		};
	},
	computed: {
		...mapState(["Toaster"]),
	},
	watch: {
		"$store.state.Toaster.text": function() {
			this.destroyTimer();
			this.delayedHide();
		},
	},
	beforeDestroy() {
		this.destroyTimer();
		if (this.Toaster.isVisible) {
			this.hide();
		}
	},
	methods: {
		destroyTimer() {
			clearTimeout(this.autoHideTimer);
		},
		delayedHide() {
			this.autoHideTimer = setTimeout(this.hide, AUTO_HIDE_DELAY);
		},
		hide() {
			this.$store.commit("Toaster/SET_TOASTER_VISIBILITY", { isVisible: false });
		},
	},
};
</script>

<style scoped>
.toaster-bottom-enter-active,
.toaster-bottom-leave-active {
	transition: all 0.8s ease-out;
	transform-origin: bottom;
}
.toaster-bottom-enter,
.toaster-bottom-leave-to {
	transform: translateY(0);
	opacity: 0;
}
</style>
