<template>
	<OrigamiButton
		v-jest="'notification-button'"
		class="notificationButton"
		:class="{ active: isSelected }"
		@click.native="goToAchievementCenter()"
	>
		<div class="tw-flex tw-items-center tw-flex-nowrap tw-gap-2">
			<Points
				v-jest="'notification-points'"
				class="tw-font-normal tw-text-base"
				:label="''"
				:points="points"
			/>
			<span
				v-if="notificationCount"
				class="tw-flex tw-items-center tw-flex-nowrap tw-gap-2"
			>
				<Emoji
					emoji="Medal"
					class="medal"
				/>
				<span
					v-jest="'notificationCount'"
					class="tw-px-1 tw-flex-nowrap tw-text-base tw-font-normal"
				>
					{{ notificationCount }}
				</span>
			</span>
		</div>
	</OrigamiButton>
</template>

<script>
import { OrigamiButton } from "@origami/vue2";

import { Emoji } from "@/modules/Emoji/index.js";

import Points from "../Points.vue";

export default {
	components: {
		Emoji,
		OrigamiButton,
		Points,
	},
	props: {
		isSelected: {
			type: Boolean,
			default: false,
		},
		notificationCount: {
			type: Number,
			required: true,
		},
		points: {
			type: Number,
			required: true,
		},
	},
	methods: {
		goToAchievementCenter() {
			const path = "/student/achievement-center";
			if (this.$route.path !== path) {
				this.$router.push(path);
			}
		},
	},
};
</script>

<style scoped>
.notificationButton {
	@apply tw-rounded-full tw-relative tw-inline-block tw-py-2 tw-px-3;
	background: rgba(255, 255, 255, 0.15);
}
.notificationButton:hover,
.notificationButton:focus {
	background: rgba(255, 255, 255, 0.35);
}
.notificationButton:focus-visible {
	outline: none;
}
.notificationButton:focus-visible:before {
	@apply tw-absolute tw-rounded-full;
	content: "";
	inset: -2px;
	border: 1px solid var(--origami-white);
}
.notificationButton.active {
	@apply tw-text-origami-black tw-bg-origami-white;
}
</style>
