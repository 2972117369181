import { getFlag } from "@paper-co/web-toolkit";

import middleware from "@/middleware/index.js";
import { MICRO_FRONTENDS_NAMES } from "@/modules/MicroFrontend/utilities/index.js";
import { doesStudentNeedOnboarding } from "@/modules/StudentOnboarding/index.js";
import redirectWhenFeatureFlagIsDisabled from "@/middleware/redirectWhenFeatureFlagIsDisabled.js";
import redirectWhenFeatureFlagIsEnabled from "@/middleware/redirectWhenFeatureFlagIsEnabled.js";

const getMissionsMicroAppFeatureFlag = async() => {
	const featureFlagMissionsMicroIsEnabled = await getFlag("PE-19585-Missions-Micro");
	return featureFlagMissionsMicroIsEnabled;
};

export default [
	{
		path: "/missions*",
		name: "missionsStudent",
		component: async() => await getMissionsMicroAppFeatureFlag() ?
			import("@/modules/MicroFrontend/components/MicroFrontend.vue") :
			import("../pages/missions.vue"),
		beforeEnter: middleware([
			doesStudentNeedOnboarding,
			redirectWhenFeatureFlagIsEnabled("CORE-2031-Practice-Center", "/practice"),
		]),
		meta: {
			title: "Paper Missions",
		},
		props: {
			microFrontendName: MICRO_FRONTENDS_NAMES.MATH_MISSIONS,
		},
	},
	{
		path: "/practice*",
		name: "studentPracticeCenter",
		component: () => import("@/modules/MicroFrontend/components/MicroFrontend.vue"),
		beforeEnter: middleware([
			doesStudentNeedOnboarding,
			redirectWhenFeatureFlagIsDisabled("CORE-2031-Practice-Center", "/missions"),
		]),
		meta: {
			title: "Practice Center",
		},
		props: {
			microFrontendName: MICRO_FRONTENDS_NAMES.PRACTICE_CENTER,
		},
	},
];
