import EssaysApi from "../../../services/api/Essays.js";

const getDefaultState = () => ({
	essays: [],
	total: "Loading...",
	fresh: "Loading...",
	risk: "Loading...",
	critical: "Loading...",
	inProgress: "Loading...",
	navbarMainTab: "Service",
	cursorPagination: {
		count: 0,
		current: null,
		next: null,
		prev: null,
	},
});

const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_ESSAYS(state, payload) {
		state.essays = payload.essays;
	},
	SET_TOTAL(state, payload) {
		state.total = payload.total;
	},
	SET_IN_PROGRESS(state, payload) {
		state.inProgress = payload.inProgress;
	},
	SET_NEW(state, payload) {
		state.fresh = payload.new; //cant use `new` in javascript
	},
	SET_RISK(state, payload) {
		state.risk = payload.risk;
	},
	SET_CRITICAL(state, payload) {
		state.critical = payload.critical;
	},
	SET_CURSOR(state, payload) {
		state.cursorPagination = payload.cursor;
	},
};

const actions = {
	async getEssays({ state, commit }, params) {
		const response = await EssaysApi.list(params);
		const essays = params.limit === undefined ? state.essays.concat(response.data.data) : response.data.data;
		commit("SET_ESSAYS", {
			essays,
		});
		commit("SET_CURSOR", {
			cursor: response.data.meta?.cursor,
		});
	},
	async getEssayStatistics({ commit }) {
		const response = await EssaysApi.getSuperuserStatistics();

		commit("SET_TOTAL", {
			total: response.data.data.total,
		});
		commit("SET_RISK", {
			risk: response.data.data.risk,
		});
		commit("SET_IN_PROGRESS", {
			inProgress: response.data.data.in_progress,
		});
		commit("SET_CRITICAL", {
			critical: response.data.data.critical,
		});
		commit("SET_NEW", {
			new: response.data.data.new,
		});
	},
};
export default {
	namespaced: true,
	state: getDefaultState(),
	mutations,
	actions,
};
