<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		viewBox="0 0 4 22"
	>
		<g
			:fill="iconColor"
			fill-rule="nonzero"
		>
			<path d="M4 22H0v-4h4zM4 15H0V0h4z" />
		</g>
	</svg>
</template>

<script>
export default {
	props: {
		width: {
			type: Number,
			default: 4,
		},
		height: {
			type: Number,
			default: 22,
		},
		iconColor: {
			type: String,
			default: "#FFF",
		},
	},
};
</script>
