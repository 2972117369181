import { pick } from "lodash-es";

export const ANNOT_META_KEY_UNSAVED = "x-paper-annot-unsaved";
export const ANNOT_META_KEY_SOURCE = "x-paper-annot-source";

export const ANNOT_SOURCE_PAPER = "paper";

const categoryColorFactories = {
	1: () => new Annotations.Color(254, 180, 170),
	2: () => new Annotations.Color(254, 230, 178),
	3: () => new Annotations.Color(129, 228, 204),
	4: () => new Annotations.Color(134, 135, 239),
	5: () => new Annotations.Color(203, 183, 219),
	6: () => new Annotations.Color(236, 143, 181),
};

export const suggestionOpacity = 0.7;
export const suggestionSelectedOpacity = 1.0;

export const commentOpacity = 0.7;
export const commentSelectedOpacity = 1.0;

export const freehandOpacitySelected = 0.7;
export const freehandOpacity = 0.4;

export const underlineStrokeThickness = 3;
export const freehandStrokeThickess = 20;

const CAT_COLORS_ORIGAMI = {
	1: "tw-bg-origami-red-300",
	2: "tw-bg-origami-yellow-300",
	3: "tw-bg-origami-green-300",
	4: "tw-bg-origami-blue-300",
	5: "tw-bg-origami-purple-200",
	6: "tw-text-origami-pink-200",
};
const CAT_COLORS_ORIGAMI_FG = {
	1: "tw-text-origami-red-300",
	2: "tw-text-origami-yellow-300",
	3: "tw-text-origami-green-300",
	4: "tw-text-origami-blue-300",
	5: "tw-text-origami-purple-200",
	6: "tw-text-origami-pink-200",
};

export function isPaperPdfAnnotation(pdfAnnotation) {
	return pdfAnnotation.getCustomData(ANNOT_META_KEY_SOURCE) === ANNOT_SOURCE_PAPER;
}

export function markPaperPdfAnnotation(pdfAnnotation) {
	pdfAnnotation.setCustomData(ANNOT_META_KEY_SOURCE, ANNOT_SOURCE_PAPER);
}

export function assertPaperPdfAnnotation(pdfAnnotation) {
	if (!isPaperPdfAnnotation(pdfAnnotation)) {
		throw new Error("Unknown PDF annotation source");
	}
}

export function categoryBgColorClass(categoryId) {
	return CAT_COLORS_ORIGAMI[categoryId] || "tw-bg-blue-regular";
}

export function categoryColorClass(categoryId) {
	return CAT_COLORS_ORIGAMI_FG[categoryId] || "tw-text-blue-regular";
}

export function getCategoryAnnotationColor(categoryId) {
	return categoryColorFactories[categoryId]();
}

export function getSuggestionAnnotationColor() {
	return new Annotations.Color(254, 236, 198);
}

export function getCommentAnnotationColor() {
	return new Annotations.Color(183, 183, 246);
}

export function comparePaperAnnotationsPosition(annotA, annotB) {
	return (
		(annotA.pageNumber - annotB.pageNumber) ||
		(findMinQuadY(annotA.quads) - findMinQuadY(annotB.quads)) ||
		(findMinQuadX(annotA.quads) - findMinQuadX(annotB.quads)) ||
		(annotA.dateCreated - annotB.dateCreated)
	);
}

function findMinQuadY(quads) {
	return findMinQuadCoordinate(quads, (quad) => [quad.y1, quad.y2, quad.y3, quad.y4]);
}

function findMinQuadX(quads) {
	return findMinQuadCoordinate(quads, (quad) => [quad.x1, quad.x2, quad.x3, quad.x4]);
}

function findMinQuadCoordinate(quads, coordsCallback) {
	return quads.reduce((v, quad) => Math.min(v, ...coordsCallback(quad)), +Infinity);
}

export function serializeSuggestion(suggestion) {
	return JSON.stringify(pick(suggestion, ["id", "sentence", "display_comment", "category.id"]));
}
