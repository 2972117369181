var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.value,
          expression: "value",
        },
      ],
      staticClass: "form-control",
      class: { "datePicker--active": _vm.isValueSelected },
      attrs: {
        id: _vm.id,
        type: "text",
        onfocus: "(this.type='date')",
        onblur: "(this.type='text')",
        placeholder: _vm.placeholder,
        disabled: _vm.isDisabled,
      },
      domProps: { value: _vm.value },
      on: {
        change: _vm.handleInput,
        input: function ($event) {
          if ($event.target.composing) return
          _vm.value = $event.target.value
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }