<template>
	<div class="tw-flex tw-flex-col tw-h-full">
		<header class="tw-flex tw-p-4 tw-border-b tw-border-origami-grey-100">
			<h3 class="tw-text-origami-h3 tw-font-bold">
				PaperClips
			</h3>

			<OrigamiIconButton
				v-jest="'x-button'"
				v-data-cy="'teaching-assets-drawer-x-button'"
				icon-name="close"
				class="tw-ml-auto tw--mr-2 tw--my-2"
				@click.native="$emit('close')"
			/>
		</header>

		<div class="tw-flex tw-flex-col tw-flex-auto tw-overflow-hidden tw-overflow-y-auto">
			<slot>
				<header class="tw-flex tw-flex-wrap tw-gap-4 tw-px-4 tw-my-4">
					<OrigamiInputText
						v-model="assetSearchQuery"
						v-data-cy="'teaching-assets-drawer-search'"
						type="search"
						label="Search"
						leading-icon="search"
						class="tw-flex-auto"
					/>
					<!-- <OrigamiInputText
						v-data-cy="'teaching-assets-drawer-filter'"
						label="Filter by"
						trailing-icon="caret"
						class="tw-flex-auto"
					/> -->
				</header>
				<div class="tw-flex-auto tw-flex tw-flex-col tw-px-4 tw-overflow-hidden tw-overflow-y-auto tw-border-t tw-border-origami-grey-100">
					<ErrorState
						v-if="assetsLoadingError"
						v-jest="'error'"
						:is-show-button="false"
						message-info-class="tw-text-origami-text-black tw-mt-4 tw-mb-1"
						class="tw-text-origami-text-low-emphasis tw-m-auto tw-pb-12"
					>
						<template #main>
							<p class="tw-mt-1 tw-text-center tw-text-origami-grey-300">
								<span class="tw-block tw-whitespace-nowrap tw-font-normal">
									{{ $t("error_state.cannot_connect") }}
								</span>
								<span class="tw-block tw-whitespace-nowrap tw-font-normal">
									{{ $t("error_state.please_try_again") }}
								</span>
							</p>
						</template>
					</ErrorState>
					<NoDataState
						v-else-if="isLoadingAssets"
						v-jest="'loader'"
						:is-show-button="false"
						:message="{ instruction: 'Loading...' }"
						message-info-class="tw-text-origami-text-black tw-mt-4 tw-mb-1"
						class="tw-text-origami-text-low-emphasis tw-m-auto tw-pb-12"
					>
						<template #icon>
							<BaseLoader
								thickness="0.675rem"
								class="tw-w-20 tw-h-20 tw-text-origami-brand-blue"
							/>
						</template>
					</NoDataState>
					<NoDataState
						v-else-if="!filteredAssets.length"
						v-jest="'empty'"
						:is-show-button="false"
						message-info-class="tw-text-origami-text-black tw-mt-4 tw-mb-1"
						class="tw-text-origami-text-low-emphasis tw-m-auto tw-pb-12"
					>
						<template #icon>
							<MonochromeMouse />
						</template>
					</NoDataState>
					<TransitionExpand
						v-else
						group
					>
						<div
							v-for="assetGroup in groupedAssets"
							:key="assetGroup.name"
							class="tw-flex tw-flex-col"
						>
							<h4
								v-if="groupedAssets.length > 1"
								v-jest="'group-name'"
								class="tw-text-origami-h4 tw-font-bold tw-mt-4"
							>
								{{ assetGroup.name }}
							</h4>
							<ul
								v-jest="'assets'"
								class="tw-grid md:tw-grid-cols-2 tw-gap-4 tw-my-4"
							>
								<VideoCard
									v-for="asset in assetGroup.assets"
									:key="asset.id"
									v-jest="'video-card'"
									v-data-cy="`teaching-assets-drawer-video-card-${asset.id}`"
									:video="asset"
									:is-selected="selectedAssetId === asset.id"
									:is-bookmarked="isBookmarked(asset.id)"
									@click="selectedAssetId = asset.id"
									@thumbnail-click="showVideoModal(asset)"
									@bookmark-click="handleBookmarkToggle(asset.id)"
								/>
							</ul>
						</div>
					</TransitionExpand>
				</div>
			</slot>
		</div>

		<footer class="tw-flex tw-gap-2 tw-px-4 tw-py-3 tw-border-t tw-border-origami-grey-100">
			<OrigamiButton
				v-jest="'close-button'"
				v-data-cy="'teaching-assets-drawer-close-button'"
				variant="secondary"
				class="tw-ml-auto"
				@click.native="$emit('close')"
			>
				Close
			</OrigamiButton>
			<OrigamiButton
				v-jest="'send-button'"
				v-data-cy="'teaching-assets-drawer-send-button'"
				data-pendo-id="teaching-assets-drawer-send-button"
				@click.native="$emit('send', selectedAsset)"
			>
				Send
			</OrigamiButton>
		</footer>
	</div>
</template>

<script>
import { OrigamiButton, OrigamiIconButton, OrigamiInputText } from "@origami/vue2";

import BaseLoader from "@/components/elements/BaseLoader.vue";
import ErrorState from "@/components/patterns/ErrorState.vue";
import NoDataState from "@/components/patterns/NoDataState.vue";
import TransitionExpand from "@/components/elements/TransitionExpand.vue";

import TeachingAssetsApi from "../services/teachingAssets.js";
import { getContentData } from "../utilities/index.js";

import VideoCard from "./VideoCard.vue";

const BOOKMARKS_KEY = "paper-co.TeachingAssetsDrawer.bookmarks";

export default {
	components: {
		OrigamiButton,
		OrigamiIconButton,
		OrigamiInputText,
		BaseLoader,
		ErrorState,
		NoDataState,
		MonochromeMouse: () => import("@/components/elements/illustrations/MonochromeMouse.vue"),
		TransitionExpand,
		VideoCard,
	},
	data() {
		let bookmarks = {};
		try {
			bookmarks = JSON.parse(window.localStorage.getItem(BOOKMARKS_KEY)) || {};
		} catch (error) {
			Sentry.captureException(error);
		}
		return {
			isLoadingAssets: false,
			assetsLoadingError: undefined,
			assets: [],
			selectedAssetId: undefined,
			assetSearchQuery: "",
			bookmarks,
		};
	},
	computed: {
		filteredAssets() {
			return this.assets.filter((asset) => [asset.title, asset.description].some(
				(str) => str?.toLowerCase()?.match(this.assetSearchQuery?.toLowerCase()),
			));
		},
		selectedAsset() {
			return this.assets.find(({ id }) => id === this.selectedAssetId);
		},
		bookmarkedAssets() {
			return this.filteredAssets.filter((asset) => this.isBookmarked(asset.id));
		},
		groupedAssets() {
			return [
				{
					name: "Saved",
					assets: this.bookmarkedAssets,
				},
				{
					name: "All",
					assets: this.filteredAssets,
				},
			].filter(({ assets }) => assets?.length);
		},
	},
	watch: {
		"$store.state.Classroom.isTeachingAssetsDrawerOpen"() {
			this.selectedAssetId = null;
		},
		bookmarks: {
			handler(v) {
				window.localStorage.setItem(BOOKMARKS_KEY, JSON.stringify(v));
			},
			deep: true,
		},
	},
	async created() {
		await this.loadAssets();
	},
	methods: {
		async loadAssets() {
			this.isLoadingAssets = true;
			this.assetsLoadingError = null;
			try {
				this.assets = (await TeachingAssetsApi.getAssetsList()).data.data;
			} catch (error) {
				this.assetsLoadingError = error;

				Sentry.captureException(error);
			}
			this.isLoadingAssets = false;
		},
		async showVideoModal(asset) {
			const videoData = await getContentData(asset);
			this.$emit("video-thumbnail-click", {
				videoData,
			});
		},
		isBookmarked(assetId) {
			return Boolean(this.bookmarks[assetId]);
		},
		handleBookmarkToggle(assetId) {
			this.$set(this.bookmarks, assetId, !this.bookmarks[assetId]);
		},
	},
};
</script>
