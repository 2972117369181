var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseTooltip", {
    staticClass: "helpTooltip",
    scopedSlots: _vm._u([
      {
        key: "buttonContent",
        fn: function () {
          return [
            _c(
              "span",
              { staticClass: "icon" },
              [
                _c("IconQuestionMark", {
                  attrs: {
                    "icon-color": _vm.$root.$colors.C_WHITE,
                    width: "0.5rem",
                    height: "0.5rem",
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "tooltipContent",
        fn: function () {
          return [_vm._v("\n\t\t" + _vm._s(_vm.helpText) + "\n\t")]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }