var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BModal",
    {
      ref: "tutors-list-error-modal",
      attrs: {
        id: "tutorsListErrorModal",
        "content-class": "tutorsListErrorModal",
        "body-class": "tutorsListErrorModal__body",
        "footer-class": "tutorsListErrorModal__footer",
        "hide-header": true,
        "no-close-on-backdrop": true,
        "no-fade": true,
        centered: true,
      },
    },
    [
      _c(
        "button",
        {
          staticClass: "tutorsListErrorModal__closeButton",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.hideModal()
            },
          },
        },
        [_vm._v("\n\t\t×\n\t")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "tutorsListErrorModal__header" }, [
        _c(
          "div",
          { staticClass: "tutorsListErrorModal__icon" },
          [_c("IconWarning", { staticClass: "tutorsListErrorModal__svg" })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "tutorsListErrorModal__title" }, [
          _vm._v("\n\t\t\tSomething went wrong\n\t\t"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "tutorsListErrorModal__content" }, [
        _c("div", { staticClass: "tutorsListErrorModal__text" }, [
          _vm._v(
            "\n\t\t\tPlease try again, if the error persist, contact your support team.\n\t\t"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("template", { slot: "modal-footer" }, [
        _c(
          "div",
          { staticClass: "tutorsListErrorModal__footer" },
          [
            _c(
              "BaseButton",
              {
                attrs: { type: "SUBMIT" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.hideModal()
                  },
                },
              },
              [
                _c("template", { slot: "text" }, [
                  _vm._v("\n\t\t\t\t\tOkay\n\t\t\t\t"),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }