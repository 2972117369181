var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "filter-tag",
          expression: "'filter-tag'",
        },
      ],
      staticClass: "tw-flex tw-items-center tw-w-full tw-justify-evenly",
      attrs: { "data-cy": "filter-tag" },
    },
    [
      _c(
        "h3",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "subject",
              expression: "'subject'",
            },
          ],
          staticClass:
            "tw-font-origami-bold tw-text-origami-base tw-w-1/4 tw-ml-10",
        },
        [_vm._v("\n\t\t" + _vm._s(_setup.props.subject.subject) + "\n\t")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tw-flex tw-w-2/3 tw-m-auto tw-justify-between" },
        [
          _c("p", { staticClass: "tw-flex tw-items-center" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "load-score",
                    expression: "'load-score'",
                  },
                ],
                staticClass: "tw-mr-2 tw-font-origami-bold tw-text-origami-md",
                class: _setup.getScoreColor,
              },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_setup.props.subject.score.toFixed(1)) +
                    "\n\t\t\t"
                ),
              ]
            ),
            _vm._v("\n\t\t\tCurrent Average Tutor Load\n\t\t"),
          ]),
          _vm._v(" "),
          _c(
            "p",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "total-tutors",
                  expression: "'total-tutors'",
                },
              ],
              staticClass: "tw-flex tw-items-center",
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "tw-mr-2 tw-font-origami-bold tw-text-origami-md tw-text-blue-darker",
                },
                [_vm._v(_vm._s(_setup.props.subject.total_ongoing_tutors))]
              ),
              _vm._v(" Total Tutors\n\t\t"),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "p",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "time-stamp",
              expression: "'time-stamp'",
            },
          ],
          staticClass: "tw-text-origami-grey-300 tw-w-1/3 tw-text-right",
        },
        [_vm._v("\n\t\tAs of " + _vm._s(_setup.formattedLastUpdate) + "\n\t")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }