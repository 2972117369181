import { ANNOT_META_KEY_UNSAVED, assertPaperPdfAnnotation } from "../utilities/viewer.js";

class PaperAnnotation {
	id;
	comment;
	category;
	x;
	y;
	quads;
	pageNumber;
	dateCreated;
	hidden = false;
}

export class CommentAnnotation extends PaperAnnotation {
	fromSuggestion = undefined;
	unsavedComment = undefined;
	unsavedCategory = undefined;
}

export class TextCommentAnnotation extends CommentAnnotation {
}

export class FreeHandCommentAnnotation extends CommentAnnotation {
}

export class SuggestionAnnotation extends PaperAnnotation {
	suggestion;
}

export class PaperAnnotationFactory {
	static createFromPdfAnnotation(pdfAnnotation) {
		const paperAnnotation = this._createPaperAnnotation(pdfAnnotation);
		// Fill common props.
		paperAnnotation.id = pdfAnnotation.Id;
		paperAnnotation.comment = pdfAnnotation.getContents();
		paperAnnotation.x = pdfAnnotation.X;
		paperAnnotation.y = pdfAnnotation.Y;
		paperAnnotation.pageNumber = pdfAnnotation.PageNumber;
		paperAnnotation.dateCreated = pdfAnnotation.DateCreated?.getTime();
		paperAnnotation.hidden = pdfAnnotation.Hidden;
		paperAnnotation.category = this._decodeCategory(pdfAnnotation);

		this._setQuads(pdfAnnotation, paperAnnotation);

		if (paperAnnotation instanceof SuggestionAnnotation) {
			this._initSuggestionAnnotation(pdfAnnotation, paperAnnotation);
		}

		if (paperAnnotation instanceof CommentAnnotation) {
			this._initCommentAnnotation(pdfAnnotation, paperAnnotation);
		}

		return paperAnnotation;
	}

	static assignFrom(sourcePdfAnnotation, targetPaperAnnotation) {
		targetPaperAnnotation.x = sourcePdfAnnotation.X;
		targetPaperAnnotation.y = sourcePdfAnnotation.Y;
		targetPaperAnnotation.pageNumber = sourcePdfAnnotation.PageNumber;

		this._setQuads(sourcePdfAnnotation, targetPaperAnnotation);
	}

	static _setQuads(pdfAnnotation, paperAnnotation) {
		if (pdfAnnotation instanceof Annotations.TextMarkupAnnotation) {
			paperAnnotation.quads = pdfAnnotation.Quads;
		} else {
			paperAnnotation.quads = [pdfAnnotation.getRect().toQuad()];
		}
	}

	static _initCommentAnnotation(pdfAnnotation, paperAnnotation) {
		paperAnnotation.fromSuggestion = this._decodeFromSuggestion(pdfAnnotation);

		const changes = this._decodeCustomDataValue(pdfAnnotation, ANNOT_META_KEY_UNSAVED, {});
		paperAnnotation.unsavedComment = changes.comment ?? undefined;
		paperAnnotation.unsavedCategory = changes.category ?? undefined;
	}

	static _initSuggestionAnnotation(pdfAnnotation, paperAnnotation) {
		paperAnnotation.suggestion = this._decodeSuggestion(pdfAnnotation);
	}

	static _createPaperAnnotation(sourcePdfAnnotation) {
		assertPaperPdfAnnotation(sourcePdfAnnotation);

		const hasSuggestion = sourcePdfAnnotation.getCustomData("suggestion") !== "";
		if (hasSuggestion) {
			return new SuggestionAnnotation();
		}

		if (sourcePdfAnnotation instanceof Annotations.TextMarkupAnnotation) {
			return new TextCommentAnnotation();
		}

		if (sourcePdfAnnotation instanceof Annotations.FreeHandAnnotation) {
			return new FreeHandCommentAnnotation();
		}

		throw new Error("Unsupported PDF annotation type");
	}

	static _decodeCategory(fromPdfAnnotation) {
		const categoryId = this._decodeCustomDataValue(fromPdfAnnotation, "category");

		return !!categoryId ? Number(categoryId) : null;
	}

	static _decodeSuggestion(fromPdfAnnotation) {
		return this._decodeCustomDataValue(fromPdfAnnotation, "suggestion");
	}

	static _decodeFromSuggestion(fromPdfAnnotation) {
		return this._decodeCustomDataValue(fromPdfAnnotation, "fromSuggestion");
	}

	static _decodeCustomDataValue(fromPdfAnnotation, key, defaultValue = null) {
		const value = fromPdfAnnotation.getCustomData(key);

		if (value !== "") {
			return JSON.parse(value);
		}

		return defaultValue;
	}
}
