var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 16 16",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            fill: "none",
            "fill-rule": "evenodd",
            transform: "translate(1 -1)",
          },
        },
        [
          _c("circle", {
            attrs: { cx: "7", cy: "9", r: "7", stroke: _vm.iconColor },
          }),
          _vm._v(" "),
          _c(
            "text",
            {
              attrs: {
                fill: _vm.iconColor,
                "font-family": "OpenSans-ExtraBold, Open Sans",
                "font-size": "12",
                "font-weight": "600",
              },
            },
            [_c("tspan", { attrs: { x: "5", y: "13" } }, [_vm._v("!")])]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }