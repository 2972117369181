<template>
	<BreaksConfirmation>
		<template #header>
			<h3 v-data-cy="'meal-break-reques-pending-approval-header'">
				Meal break request
			</h3>
		</template>
		<template #bodyIcon>
			<BaseLoader
				v-data-cy="'base-loader-icon'"
				thickness="4px"
				class="tw-mt-0.5 tw-h-6 tw-w-6 tw-text-blue-regular"
			/>
		</template>
		<template #bodyText>
			<p
				v-data-cy="'meal-break-awaiting-approvel-text'"
			>
				Your break request is awaiting approval
			</p>
		</template>
		<template #buttons>
			<OrigamiButton
				v-jest="'cancel-meal-break-request-button'"
				v-data-cy="'cancel-meal-break-request-button'"
				variant="destructive"
				class="tw-m-auto tw-mb-2 tw-w-full tw-justify-center"
				:is-loading="$store.state.BreaksManagementWidget.isRequestLoading"
				@click.native="cancelMealBreakRequest"
			>
				Cancel request
			</OrigamiButton>
		</template>
	</BreaksConfirmation>
</template>

<script>
import { OrigamiButton } from "@origami/vue2";

import BaseLoader from "@/components/elements/BaseLoader.vue";

import BreaksConfirmation from "../templates/BreaksConfirmation.vue";

export default {
	components: {
		OrigamiButton,
		BreaksConfirmation,
		BaseLoader,
	},
	methods: {
		cancelMealBreakRequest() {
			this.$store.dispatch("BreaksManagementWidget/cancelPendingMealBreakRequest");
		},
	},
};
</script>
