import middleware from "@/middleware/index.js";
import hasStudentAcceptedAgreement from "@/middleware/hasStudentAcceptedAgreement.js";
import { doesStudentNeedOnboarding } from "@/modules/StudentOnboarding/index.js";

export default [
	{
		path: "student/achievement-center",
		component: () => import(
			"../pages/index.vue"
		),
		beforeEnter: middleware([
			hasStudentAcceptedAgreement,
			doesStudentNeedOnboarding,
		]),
		children: [
			{
				path: "",
				component: () => import(
					"../pages/achievement-center.vue"
				),
			},
			{
				path: ":achievementId",
				component: () => import(
					"../pages/achievements.vue"
				),
				props: true,
			},
		],
	},
];
