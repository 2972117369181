import { ref, computed } from "vue";
import { getFlag } from "@paper-co/web-toolkit";

import useStore from "@/composables/useStore.js";
import { getGradeLevelName } from "@/utilities/grade.js";

import { navLinks, dropdownLinks, stopImpersonatingLink, viewAsLinks, sidebarLinks } from "../utilities/index.js";

export default function useNavLinks() {
	const reactiveNavLinks = ref(navLinks);
	const store = useStore();
	if (!store.state.currentUser) {
		return {};
	}
	const { roles } = store.state.currentUser;

	const filteredViewAsLinks = viewAsLinks.filter((link) => roles.includes(link.role) && link.role !== "student");

	const handleMajorClarityLink = async() => {
		if (!await getFlag("GROW-1734-major-clarity-from-paper") ||
			store.state.currentUser?.school?.country_code === "CA" ||
			getGradeLevelName(
				store.state.Student?.Profile?.gradesList,
				store.state.Student?.Profile?.profile?.grade?.name,
			) === "elementary"
		) {
			reactiveNavLinks.value = reactiveNavLinks.value.filter(
				(navLink) => navLink.name !== "major-clarity",
			);
			return;
		}
		if (!store.state.Student.Profile.profile.has_clicked_major_clarity) {
			const majorClarityLink = reactiveNavLinks.value.find(
				(navLink) => navLink.name === "major-clarity",
			);
			majorClarityLink.isButton = true;
		}
	};

	const handleNavLinksUpdates = async() => {
		if (await getFlag("GROW-1473-update-sidebar-links")) {
			const chatHistoryLink = reactiveNavLinks.value.find((navLink) => navLink.name === "chat-history");
			chatHistoryLink.title = "student_navigation.nav_links.chat_history_new";
			chatHistoryLink.icon = "session";

			const reviewCenterLink = reactiveNavLinks.value.find((navLink) => navLink.name === "review-center");
			reviewCenterLink.title = "student_navigation.nav_links.review_center_new";
			reviewCenterLink.icon = "essay";

			const mathLink = reactiveNavLinks.value.find((navLink) => navLink.name === "math");
			mathLink.title = "student_navigation.nav_links.math_new";

			reactiveNavLinks.value = reactiveNavLinks.value.filter(
				(navLink) => navLink.name !== "upload-work" && navLink.name !== "paperlive",
			);
		}
	};

	const checkNavLinksFlags = async() => {
		try {
			if (await getFlag("GROW-1308-sidebar-secondary-tab-redesign")) {
				return reactiveNavLinks.value = sidebarLinks;
			}
			if (!await getFlag("GROW-414-referrals")) {
				reactiveNavLinks.value = reactiveNavLinks.value.filter((navLink) => navLink.name !== "referrals");
			}
			if (!await getFlag("GROW-414-referrals")) {
				reactiveNavLinks.value = reactiveNavLinks.value.filter((navLink) => navLink.name !== "referrals");
			}
			if (!await getFlag("GROW-1725-paper-reading-from-sidebar")) {
				reactiveNavLinks.value = reactiveNavLinks.value.filter((navLink) => navLink.name !== "reading");
			}

			await handleNavLinksUpdates();
			await handleMajorClarityLink();
			reactiveNavLinks.value[0].isExternal = false;
		} catch (error) {
			Sentry.captureException(error);
		}
	};

	const reactiveDropdownLinks = computed(() => [
		...filteredViewAsLinks,
		...dropdownLinks,
		...((store.state.isImpersonate && stopImpersonatingLink) || []),
	]);

	return {
		reactiveNavLinks,
		checkNavLinksFlags,
		reactiveDropdownLinks,
	};
}
