<template>
	<OrigamiButton
		class="tw-mt-1"
		@click.native.stop="handleClick"
	>
		{{ $t("trophies.profile.change_avatar_btn") }}
	</OrigamiButton>
</template>

<script>
import { OrigamiButton } from "@origami/vue2";

export default {
	components: {
		OrigamiButton,
	},
	methods: {
		handleClick() {
			this.$router.push({
				name: "studentSettings",
				query: { customize_avatar: true },
			});
		},
	},
};
</script>
