<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		viewBox="0 0 26 20"
	>
		<g
			fill="none"
			fill-rule="evenodd"
		>
			<g :fill="iconColor">
				<path
					d="M1025.625 99c2.21 0 4 1.79 4 4v7.837c0 2.21-1.79 4-4 4h-8.1l-6.691 4.018c-.156.094-.334.143-.515.143-.513 0-.936-.386-.993-.883l-.007-.117v-3.16H1008c-2.21 0-4-1.792-4-4V103c0-2.21 1.79-4 4-4h17.625zm-3.424 4.716c-.405 0-1.212.526-2.42 1.579-.257.21-.625.493-1.106.851-.473.367-.906.57-1.298.611l-.146.008h-.455c-.053-.927-.256-1.66-.61-2.199l-.112-.156-.922 1.373c.094.112.217.362.369.749s.254.727.306 1.02c.053.292.08.522.08.689 0 .184-.015.349-.044.493-.04.09-.272.22-.695.39-1.038.391-1.915.587-2.63.587-.68 0-1.172-.17-1.474-.51-.302-.34-.45-.781-.442-1.324-.007-.383.126-.912.399-1.587.273-.674.462-1.106.567-1.294-.156.051-.365.254-.627.61-.262.357-.479.795-.65 1.314-.173.519-.258 1.082-.258 1.69 0 .55.04.997.122 1.342.081.345.214.618.398.817.185.198.426.334.725.406.298.073.664.109 1.098.109 1.14-.01 2.344-.438 3.613-1.28.329-.248.557-.69.683-1.327l.039-.22h6.906c.401 0 .636-.06.705-.179.579-.853.871-1.589.879-2.207 0-.633-.579-1.313-1.736-2.04-.456-.21-.877-.315-1.264-.315zm.076 1.644c.228 0 .613.14 1.155.423.572.293.857.544.857.754 0 .268-.643.402-1.93.402-1.234 0-2.058-.01-2.472-.03-.414-.02-.645-.04-.692-.057 0-.16.252-.43.754-.814.666-.452 1.442-.678 2.328-.678z"
					transform="translate(-1004 -99)"
				/>
			</g>
		</g>
	</svg>
</template>

<script>
import Colors from "../../utilities/Colors.js";
export default {
	props: {
		width: {
			type: [Number, String],
			default: 26,
		},
		height: {
			type: [Number, String],
			default: 20,
		},
		iconColor: {
			type: String,
			default: Colors.C_LANGUAGE_BUBBLE_GREEN,
		},
	},
};
</script>
