<template>
	<footer class="footer">
		<div class="footer__container">
			<PaperAppQRCode
				v-jest="'paper-qr-code'"
				class="footer__qr-code"
			/>
			<div class="app-links">
				<h2	class="app-links__title">
					{{ $t("download_paper_app") }}
				</h2>
				<div class="app-links__container">
					<a
						v-jest="'google-store-link'"
						href="https://play.google.com/store/apps/details?id=com.gradeslam"
						class="tw-mr-2"
						:aria-label="$t('google_play_store')"
						target="_blank"
					>
						<BadgeGooglePlayStore
							width="125"
							height="38"
							aria-hidden="true"
							focusable="false"
						/>
					</a>
					<a
						v-jest="'apple-store-link'"
						href="https://apps.apple.com/ca/app/paper-education/id1360686296"
						:aria-label="$t('apple_app_store')"
						target="_blank"
					>
						<BadgeAppleStore
							width="124"
							height="38"
							aria-hidden="true"
							focusable="false"
						/>
					</a>
				</div>
			</div>
		</div>
		<div class="privacy-policy">
			<p class="privacy-policy__message">
				<span class="tw-font-normal">&copy;</span>
				Paper {{ currentYear }} {{ $t("rights_reserved") }}
			</p>
			<span class="privacy-policy__divider">&#x2022;</span>
			<span>
				<a
					v-jest="'privacy-policy-link'"
					href="https://paper.co/privacy-policy-services"
					target="_blank"
					class="privacy-policy__link"
				>
					{{ $t("student_navigation.footer.privacy_policy") }}
				</a>
			</span>
		</div>
	</footer>
</template>

<script setup>
import { defineAsyncComponent } from "vue";
const PaperAppQRCode = defineAsyncComponent(() => import("@/modules/Navigation/illustrations/PaperAppQRCode.vue"));
const BadgeGooglePlayStore = defineAsyncComponent(() => import("@/components/icons/IconGooglePlayStore.vue"));
const BadgeAppleStore = defineAsyncComponent(() => import("@/components/icons/IconAppleStore.vue"));
const currentYear = new Date().getFullYear();
</script>
<style scoped>
.footer {
	@apply tw-border-t tw-border-origami-grey-200 tw-bg-origami-grey-100 tw-w-full;
	z-index: 9999;
	padding-left: var(--origami-spacing-8);
	padding-right: var(--origami-spacing-8);
}

.footer__container {
	@apply tw-flex tw-py-4;
}

.footer__qr-code {
	@apply tw-mr-2 tw-h-16 tw-w-16 tw-border-solid tw-border-2 tw-border-origami-black tw-rounded-lg;
	padding: 0.25rem;
}

.app-links {
	@apply tw-flex tw-flex-col tw-justify-between;
}

.app-links__title {
	@apply tw-font-bold tw-text-base tw-leading-none;
}

.app-links__container {
	@apply tw-flex tw-mt-2;
}

.privacy-policy {
	@apply tw-border-t tw-border-origami-grey-200 tw-text-center xs:tw-flex xs:tw-justify-start tw-text-sm tw-leading-tight tw-py-4;
}

.privacy-policy__message {
	@apply tw-font-bold tw-mb-2 xs:tw-mb-0;
}

.privacy-policy__divider {
	@apply tw-hidden xs:tw-inline tw-mx-4;
}

.privacy-policy__link {
	@apply tw-text-origami-brand-blue tw-rounded tw-underline hover:tw-bg-origami-cyan-200 hover:tw-text-origami-brand-blue focus:tw-bg-origami-cyan-200 focus:tw-text-origami-brand-blue focus:tw-outline-none active:tw-bg-origami-cyan-200 active:tw-text-origami-brand-navy;
	text-decoration: underline !important;
}
</style>
