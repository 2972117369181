export default {
	en: {
		missions: {
			widget: {
				btn: "Paper Missions",
			},
		},
		potd: {
			widget: {
				title: "Paper Math Daily Challenge",
				new_tag: "New",
				body_description: "Freshen your math skills with a quick problem to solve every single day.",
				completed: "completed",
				streak: "day streak",
				btn: "Start Practicing Math",
				streakTip: "Days in a row you have solved problems",
				daysCompleteTip: "Total problems you have completed",
			},
		},
	},
	fr: {
		missions: {
			widget: {
				btn: "Missions de Paper",
			},
		},
		potd: {
			widget: {
				title: "Problème du jour",
				new_tag: "Nouveau",
				body_description: "Conserve ton niveau en maths avec un problème rapide à résoudre chaque jour.",
				completed: "complété",
				streak: "d'affilée",
				btn: "Commencer à pratiquer les maths",
				streakTip: "Nombres de jours d’affilés de problèmes résolus",
				daysCompleteTip: "Nombres totales de problèmes complétés",
			},
		},
	},
	es: {
		missions: {
			widget: {
				btn: "Misiones de Paper",
			},
		},
		potd: {
			widget: {
				title: "Problema del día",
				new_tag: "Nuevo",
				body_description: "Refresca tus habilidades matemáticas con un problema rápido para resolver cada día.",
				completed: "completado",
				streak: "racha",
				btn: "Empieza a practicar matemáticas",
				streakTip: "Días consecutivos que has resuelto problemas",
				daysCompleteTip: "Total de problemas que has completado",
			},
		},
	},
};
