<template>
	<div>
		<TutorsList />
		<TutorsListErrorModal />
	</div>
</template>

<script>
import { mapState } from "vuex";

import TutorsListErrorModal from "./TutorsListErrorModal.vue";
import TutorsList from "./TutorsList.vue";

export default {
	components: {
		TutorsList,
		TutorsListErrorModal,
	},
	computed: {
		...mapState(["currentUser"]),
	},
	async created() {
		this.$store.dispatch("PlatformManager/TutorsList/getTutorAvailabilityFilteredTutorData");
	},
};
</script>
