<template>
	<div>
		<!-- upload + uploaded file with error -->
		<UploadContainer
			v-if="!isEssayUploadedForReview"
			v-jest="'essay-upload'"
			:doc-validator="essayFileDocValidator"
			:mime-types="essayFileMimeTypes"
			:file-upload-error-message="fileUploadErrorMessage"
			:embedded="embedded"
			class="tw-mx-0"
			@handle-essay-file-input="handleEssayFileInput"
			@handle-essay-file-input-from-google-drive="handleGooglePickerEssayFileInput"
			@error="handleFileUploadError"
		/>
		<FileDropZone
			v-else
			v-show="!isLoadingGoogleFile"
			v-jest="'essay-file-dropzone'"
			clickable
			class="tw-flex tw-text-blue-regular tw-rounded tw-p-2 tw-my-4"
			@change="handleEssayFileInput"
		>
			<template #default="{ trigger, isDraggingOver, inputZIndex }">
				<div class="tw-flex-grow tw-flex tw-items-center tw-overflow-hidden">
					<IconEssayOutlined
						width="16"
						height="16"
						class="tw-flex-shrink-0 tw-m-2"
						aria-hidden="true"
						focusable="false"
					/>
					<span class="tw-text-sm tw-font-bold tw-overflow-hidden tw-overflow-ellipsis">{{ dropzoneText }}</span>
				</div>

				<BDropdown
					right
					no-caret
					size="sm"
					variant="link"
					:popper-opts="{ positionFixed: true }"
					class="ChangeFileDropdown tw-relative"
					:style="{ zIndex: isDraggingOver ? 'auto' : inputZIndex + 1 }"
					@click.native.prevent.stop
				>
					<template #button-content>
						<span
							v-if="!embedded"
							class="tw-font-bold"
						>{{ $t("changeFile") }}</span>
						<IconCaret
							class="tw-mt-0 tw-ml-2"
							aria-hidden="true"
							focusable="false"
						/>
					</template>
					<BDropdownText v-if="embedded">
						<span class="tw-font-bold tw-text-sm">{{ $t("changeFile") }}</span>
					</BDropdownText>
					<BDropdownItem @click="trigger">
						<IconComputer class="tw-mr-2" />
						{{ $t("browseFiles") }}
					</BDropdownItem>
					<GooglePicker
						:doc-validator="essayFileDocValidator"
						:mime-types="essayFileMimeTypes"
						@change="handleGooglePickerEssayFileInput"
						@change-start="isLoadingGoogleFile = true"
						@change-end="isLoadingGoogleFile = false"
						@error="handleFileUploadError"
					>
						<template #default="{ trigger: triggerGooglePicker, isLoaded }">
							<BDropdownItem
								v-if="isLoaded"
								@click="openGooglePicker(triggerGooglePicker, 'essay')"
							>
								<IconGoogleDrive
									class="tw-mr-2"
									aria-hidden="true"
									focusable="false"
								/>
								{{ $t("googleDrive") }}
							</BDropdownItem>
						</template>
					</GooglePicker>
				</BDropdown>
			</template>
		</FileDropZone>
	</div>
</template>

<script>
import FileDropZone from "@/components/patterns/FileDropZone.vue";
import GooglePicker from "@/components/GooglePicker.vue";
import IconCaret from "@/components/elements/icons/Caret.vue";
import IconComputer from "@/components/elements/icons/Computer.vue";
import IconEssayOutlined from "@/components/elements/icons/EssayOutlined.vue";
import IconGoogleDrive from "@/components/elements/icons/GoogleDrive.vue";

import UploadContainer from "../UploadContainer.vue";

export default {
	components: {
		FileDropZone,
		GooglePicker,
		IconCaret,
		IconComputer,
		IconEssayOutlined,
		IconGoogleDrive,
		UploadContainer,
	},
	props: {
		uploadedEssay: {
			default: null,
			required: true,
		},
		embedded: {
			type: Boolean,
			default: false,
			required: true,
		},
		essayFileDocValidator: {
			type: Function,
			default: () => {},
			required: true,
		},
		essayFileMimeTypes: {
			type: Array,
			default: () => [],
			required: true,
		},
		fileUploadError: {
			default: null,
			required: true,
		},
		handleEssayFileInput: {
			type: Function,
			default: () => {},
			required: true,
		},
		handleGooglePickerEssayFileInput: {
			type: Function,
			default: () => {},
			requred: true,
		},
		handleFileUploadError: {
			type: Function,
			default: () => {},
			required: true,
		},
		openGooglePicker: {
			type: Function,
			default: () => {},
			required: true,
		},
	},
	data() {
		return {
			isLoadingGoogleFile: false,
		};
	},
	computed: {
		dropzoneText() {
			return this.uploadedEssay != null ? this.uploadedEssay.name : "";
		},
		fileUploadErrorMessage() {
			if (!this.fileUploadError) {
				return null;
			}
			return this.$t(`errors.${this.fileUploadError}`);
		},
		isEssayUploadedForReview() {
			return this.uploadedEssay !== null;
		},
	},
	watch: {
		isEssayUploadedForReview(value) {
			this.$store.commit("Student/Essays/SET_IS_ESSAY_UPLOADED_AND_NOT_SUBMITTED", {
				isEssayUploadedAndNotSubmitted: value,
			});
		},
	},
};
</script>

<style>
.ChangeFileDropdown :deep(.btn-link) {
	color: inherit;
}
.ChangeFileDropdown :deep(.btn-link:hover),
.ChangeFileDropdown :deep(.btn-link:focus),
.ChangeFileDropdown :deep(.btn-link:active) {
	color: var(--blue-dark);
	text-decoration: none;
}
.ChangeFileDropdown :deep(.dropdown-menu-right) {
	top: 8px !important;
}
</style>
