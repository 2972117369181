export default {
	en: {
		StudentReviewCenter: {
			cancellation_essay_error: "There was an error cancelling your submission, please retry later.",
			cancellation_not_allowed: "Your submission has already been pick up by one of our tutor. You can not cancel it anymore.",
		},
	},
	fr: {
		StudentReviewCenter: {
			cancellation_essay_error: "Il y a eu une erreur lors de l'annulation de ta soumission, essaye plus tard.",
			cancellation_not_allowed: "Ta soumission a déjà été prise en charge par l'un de nos tuteurs. Tu ne peux plus l'annuler.",
		},
	},
	es: {
		StudentReviewCenter: {
			cancellation_essay_error: "Hubo un error al cancelar su envío, inténtelo de nuevo más tarde.",
			cancellation_not_allowed: "Su envío ya ha sido atendido por uno de nuestros tutores. Ya no puedes cancelarlo.",
		},
	},
};
