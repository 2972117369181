<script setup>
import { onMounted, computed } from "vue";
import { isUndefined } from "lodash";
import { useRoute } from "vue-router/composables";

import useStore from "@/composables/useStore.js";
import BaseLoader from "@/components/elements/BaseLoader.vue";

import { useLoading } from "../composables/loading.js";
import { REQUIREMENTS_FILTERS } from "../utilities/ConstantValues.js";

import ServiceAnalyticsHeader from "./ServiceAnalyticsHeader.vue";
import ServiceAnalyticsContent from "./ServiceAnalyticsContent.vue";


const store = useStore();
const route = useRoute();

const isLoading = useLoading();

const subjects = computed(() => store.state.ServiceAnalytics.subjects);
const isFiltersSidebarOpen = computed(() => store.state.ServiceAnalytics.isFiltersSidebarOpen);

const getQueryParams = (param) => {
	if (!isUndefined(param)) {
		return Array.isArray(param) ? param : [param];
	}

	return null;
};

const { filterChoices } = REQUIREMENTS_FILTERS;

const subjectQueryParams = getQueryParams(route.query.subjects);
const requirementsQueryParams = getQueryParams(route.query.special_requirements);

const subjectFilters = computed(() =>
	subjectQueryParams?.map((subjectParam) => subjects.value?.find(({ id }) => subjectParam == id)));

const requirementFilters = computed(() =>
	requirementsQueryParams?.map((requirementParam) => filterChoices.find(({ id }) => requirementParam == id)));

onMounted(async() => {
	try {
		await store.dispatch("ServiceAnalytics/getRiskLevels");
		await store.dispatch("ServiceAnalytics/getFilteredResults", {
			subjects: subjectQueryParams,
			special_requirements: requirementsQueryParams,
		});

		if (!isUndefined(subjectFilters.value)) {
			store.dispatch("ServiceAnalytics/addSelectedFilter", {
				name: "Subjects",
				selectedFilters: subjectFilters.value,
			});
		}

		if (!isUndefined(requirementFilters.value)) {
			store.dispatch("ServiceAnalytics/addSelectedFilter", {
				name: "Requirements",
				selectedFilters: requirementFilters.value,
			});
		}
	} catch (e) {
		Sentry.captureException(e);
	} finally {
		store.dispatch("ServiceAnalytics/setLoadingState", false);
	}
});
</script>
<template>
	<div
		class="tw-relative"
		:class="{ 'tw-z-0': !isFiltersSidebarOpen }"
	>
		<ServiceAnalyticsHeader
			class="tw-sticky tw-top-0 tw-pb-5 tw-z-10 tw-bg-origami-grey-100"
		/>
		<BaseLoader
			v-if="isLoading"
			v-jest="'analytics-loader'"
			thickness="8px"
			class="tw-h-12 tw-w-12 tw-mt-20 tw-text-blue-regular tw-m-auto"
		/>
		<ServiceAnalyticsContent
			v-else
			v-jest="'analytics-content'"
		/>
	</div>
</template>
