import { merge } from "lodash";

import { generalTranslations } from "../../general.js";
import { subjectsAndTopicsUnderTopics } from "../../subjectsAndTopics.js";
import studentProfileTranslations from "../../studentProfile.js";
import { modalsCommon, modalsChangePassword } from "../../modals.js";

const trans = {
	en: {
		snackbar: {
			thankYouForFeedback: "Thank you for your feedback!",
			you_ended_session_with: "You ended your session with {name}",
			ended_the_session: "{name} ended the session",
		},
		dashboard: {
			all_subjects: "All subjects",
			ask_any_tutor: "Ask any tutor",
			cant_find_what: "Can’t find what you need?",
			view_past_sessions: "View my past sessions",
			ongoing_session: "Ongoing Session",
			with_tutor: "with {tutorName}",
			errors: {
				live_help_not_available: "Sorry, Live Help is not available for you right now.",
			},
		},
		questionMatcher: {
			chat_with_a_tutor: "Chat with a tutor",
			click_here: "click here",
			error_detail: "Make sure you have provided all details of the question, that your question is academic and that the text in your file is legible. Please note that we cannot identify topics we don’t teach. If you have a question about our platform,",
			error_file_size: "Sorry, the file you were trying to upload is too large. The maximum file size allowed is 5 MB. Please re-upload your file or type your question above.",
			error_file_type: "<b>Sorry, the file you uploaded has the wrong file type.</b><br>The file types allowed are <b>JPEG, PNG, Doc and PDF.</b> Please re-upload your file or type your question above.",
			error_question_match: "Sorry, we couldn’t properly match your question. Please select your class below",
			error_question_subject: "Sorry, we can’t identify the subject of your question.",
			error_symbol: "Oops, we can’t recognize that symbol! Make sure you’re using symbols shown on your keyboard.",
			expired: "Oops, time ran out, please resubmit your question.",
			expired_button: "Resubmit my question",
			title: "What do you need help with?",
			question_placeholder: "Type your question here and we'll match you with a tutor",
			question_placeholder_embedded: "Type your question here",
			question_oops: "Oops...",
			question_initial: "Q",
			upload_file: "Upload file",
			incorrect_subject: "Not the right subject",
			clear_question: "Clear question",
		},
		errors: {
			pleaseRefresh: "An error occurred. Please refresh the page!",
		},
		navbar: {
			classroom: "Chat",
			dashboard: "Dashboard",
			essay_review: "Essay Review",
			inbox: "Notifications",
		},
		tile: {
			ask_a_tutor: "Ask a Tutor",
			ask_a_tutor_eng: "Ask a Tutor in English",
			available: "Available",
			lang_unavailable: "not available",
			available_in_eng: "English Tutor",
			finding_a_tutor: "Finding you a tutor...",
			unavailable: "Unavailable",
			sessionTypeModal: {
				title: "Ask a tutor",
				heading: "How would you like to chat?",
				error: "Select a chat type",
				textOption: "Text",
				audioOption: "Voice",
				secondaryButton: "Cancel",
				leaveMessage: "Leave a message for a tutor",
			},
		},
		agreement: {
			before_we_get_started: "Before we get started,",
			lets_go_over_some_rules: "let's go over some rules.",
			i_promise_to: "While using Paper, I promise to...",
			read_the_full: "Read the full",
			code_of_conduct_agreement: "Code of Conduct Agreement",
			i_have_read_agreement: "I have read and agreed to the Code of Conduct Agreement",
			error_message: "Please, check the checkbox before clicking the I Agree button.",
			i_refuse: "I refuse, log me out",
			i_agree: "I Agree",
			failure_to_abide_notify_school: "Please remember that failure to abide by the Code of Conduct may result in Paper notifying your school.",
			promises: [
				"Always be respectful.",
				"Remain focused on academic work.",
				"Never share my personal information",
				"Value the privacy of those helping me",
			],
		},
		accessibilty: {
			arrow_button_left: "Arrow button left",
			arrow_button_right: "Arrow button right",
			close_end_session_modal: "Close end of session modal",
			close_modal: "Close modal",
		},
		go_to_dashboard: "Go to dashboard",
	},
	fr: {
		snackbar: {
			thankYouForFeedback: "Merci pour vos commentaires!",
			you_ended_session_with: "Vous avez terminé votre session avec {name}",
			ended_the_session: "{name} a terminé la session",
		},
		dashboard: {
			all_subjects: "Toutes les matières",
			ask_any_tutor: "Demandez un tuteur",
			cant_find_what: "À la recherche de quelque chose d'autre?",
			view_past_sessions: "Voir mes sessions précédentes",
			ongoing_session: "Session en cours",
			with_tutor: "avec {tutorName}",
		},
		questionMatcher: {
			chat_with_a_tutor: "Parle à un tuteur",
			click_here: "Clique ici",
			error_detail: "Assure toi de fournir tous les détails de la question. La question doit être scolaire et le texte dans ton fichier doit être lisible. Prends note que nous ne pouvons pas identifier les sujets que nous n'enseignons pas. Si vous avez une question sur notre platforme",
			error_file_size: "Désolé, le fichier que tu essayes de téléverser est trop large. La taille maximale autorisée pour les fichiers est de 5MB. S'il te plait, réessaye de téléverser ton fichier ou écris ta question ci-dessus.",
			error_file_type: "<b>Désolé, le type de fichier que vous avez téléchargé n’est pas permis.</b><br>Les types de fichiers autorisés sont <b>JPEG, PNG, Doc et PDF.</b> Veuillez télécharger à nouveau votre fichier ou inscrire votre question ci-dessus.",
			error_question_match: "Désolé, nous n'avons pas pu trouver de tuteur adéquat pour ta question. Tu peux aussi choisir un de tes cours ci-dessous pour commencer une session.",
			error_question_subject: "Désolé, nous ne pouvons pas identifier le sujet de ta question",
			error_symbol: "Oops, nous n'arrivons pas à reconnaître ce symbole ! Vérifie que tu utilises bien des symboles qui apparaissent sur ton clavier",
			expired: "Oups, le temps est écoulé, veuillez renvoyer votre question.",
			expired_button: "Resoumettre ma question",
			title: "Comment peut-on t'aider?",
			question_placeholder: "Pose ta question et nous te mettrons en contact avec un tuteur",
			question_oops: "Oups...",
			question_initial: "Q",
			upload_file: "Télécharger un fichier",
			incorrect_subject: "Ceci n’est pas le bon sujet",
			clear_question: "Effacer la question",
		},
		errors: {
			pleaseRefresh: "Une erreur s'est produite. SVP rafraîchir la page!",
		},
		navbar: {
			classroom: "Chat",
			dashboard: "Tableau de bord",
			essay_review: "Rédactions",
			inbox: "Notifications",
		},
		tile: {
			ask_a_tutor: "Demander un tuteur",
			ask_a_tutor_eng: "Demander un tuteur en anglais",
			available: "Disponible",
			lang_unavailable: "pas disponible",
			available_in_eng: "Tuteur en anglais",
			finding_a_tutor: "Connexion à un tuteur...",
			unavailable: "Pas disponible",
			sessionTypeModal: {
				title: "Demander un tuteur",
				heading: "Comment voulez-vous discuter?",
				error: "Sélectionnez un type de conversation",
				textOption: "Message textuel",
				audioOption: "Messagerie audio",
				Annuler: "Fermer",
				leaveMessage: "Laisse un message à un tuteur",
			},
		},
		agreement: {
			before_we_get_started: "Avant de commencer,",
			lets_go_over_some_rules: "rappelons quelques règles.",
			i_promise_to: "En utilisant Paper, je promets de...",
			read_the_full: "Lisez le",
			code_of_conduct_agreement: "Code de Conduite au complet",
			i_have_read_agreement: "J'ai lu et j'accepte l'accord du Code de Conduite pour Étudiants",
			error_message: "S'il vous plaît, Veuillez cocher la case avant de cliquer sur le bouton J’accepte",
			i_refuse: "Je refuse, déconnecte moi",
			i_agree: "J'accepte",
			failure_to_abide_notify_school: "N'oubliez pas que lorsque le code de conduite n’est pas respecté, Paper peut contacter votre école.",
			promises: [
				"Toujours être respectueux.",
				"Rester concentré sur le travail académique",
				"Ne partagez jamais mes informations personnelles",
				"Valoriser la vie privée de ceux qui m'aident",
			],
		},
		go_to_dashboard: "Vers le tableau de bord",
	},
	es: {
		snackbar: {
			thankYouForFeedback: "¡Gracias por tu comentario!",
			you_ended_session_with: "Terminaste tu sesión con {name}",
			ended_the_session: "{name} ended the session",
		},
		dashboard: {
			all_subjects: "Todas las materias",
			ask_any_tutor: "Pregunta a cualquier tutor",
			cant_find_what: "¿No encuentras con lo que necesitas ayuda?",
			view_past_sessions: "Ver mis sesiones anteriores",
			ongoing_session: "Sesión en curso",
			with_tutor: "con {tutorName}",
		},
		questionMatcher: {
			chat_with_a_tutor: "Habla con un tutor",
			click_here: "Haz clic aquí",
			error_detail: "Asegúrate de proveer todos los detalles de la pregunta, que tu pregunta sea académica y que el texto en tu archivo sea legible. Por favor nota que no podemos identificar temas que no enseñamos. Si tienes alguna pregunta sobre nuestra plataforma",
			error_file_size: "Perdón, el archivo que estás tratando de subir es demasiado grande. El tamaño máximo permitido son 5 MB. Por favor vuelve a subir tu archivo o escribe tu pregunta arriba.",
			error_file_type: "<b>Lo sentimos, el tipo de archivo cargado no es permitido.</b><br>Los tipos de archivos permitidos son <b>JPEG, PNG, Doc y PDF.</b> Vuelve a cargar tu archivo o escribe tu pregunta arriba.",
			error_question_match: "Perdón, no pudimos encontrar un tutor adequado para tu pregunta. Por favor selecciona tu clase abajo",
			error_question_subject: "Perdón, no podemos reconocer la materia de tu pregunta",
			error_symbol: "Ups, no podemos reconocer ese símbolo! Asegúrate que estés usando los símbolos que aparecen en tu teclado",
			expired: "¡Oh oh!, se acabó el tiempo. Vuelve a enviar tu pregunta.",
			expired_button: "Vuelve a enviar tu pregunta",
			title: "Con qué necesitas ayuda?",
			question_placeholder: "Escribe tu pregunta aquí y te pondremos en contacto con un tutor",
			question_oops: "Oops...",
			question_initial: "P",
			upload_file: "Carga tu archivo",
			incorrect_subject: "No es la materia correcta",
			clear_question: "Borrar la pregunta",
		},
		errors: {
			pleaseRefresh: "Ocurrió un error. ¡Refresca la página!",
		},
		navbar: {
			classroom: "Chat",
			dashboard: "Tablero",
			essay_review: "Revisión de ensayos",
			inbox: "Notificaciones",
		},
		tile: {
			ask_a_tutor: "Pregunta a un tutor",
			ask_a_tutor_eng: "Pregunta a un tutor en inglés",
			available: "Disponible",
			lang_unavailable: "no disponible",
			available_in_eng: "Tutor en inglés",
			finding_a_tutor: "Buscando un tutor...",
			unavailable: "No disponible",
			sessionTypeModal: {
				title: "Pregunta a un tutor",
				heading: "Como le gustaría conversación?",
				error: "Seleccione un metodo de conversación",
				textOption: "Mensajes de texto",
				audioOption: "Mensajes de voz",
				secondaryButton: "Cancelar",
				leaveMessage: "Deja un mensaje para un tutor",
			},
		},
		agreement: {
			before_we_get_started: "Antes de empezar,",
			lets_go_over_some_rules: "repasemos algunas reglas.",
			i_promise_to: "Al usar Paper, prometo...",
			read_the_full: "Lee el",
			code_of_conduct_agreement: "Código de Conducta Estudiantil completo",
			i_have_read_agreement: "He leído y acepto el Código de Conducta",
			error_message: "Por favor, marque la casilla antes de hacer clic en el botón Acepto",
			i_refuse: "No acepto, cerrar la sesión",
			i_agree: "Acepto",
			failure_to_abide_notify_school: "Recuerda que el incumplimiento del Código de Conducta puede resultar en que Paper notifique a tu escuela",
			promises: [
				"Siempre tratar con respeto",
				"Mantener enfoque en el trabajo académico",
				"Nunca compartir mi información personal",
				"Valorar la privacidad de quienes me ayudan",
			],
		},
		go_to_dashboard: "Ir al tablero",
	},
};

export default merge(
	trans,
	generalTranslations,
	subjectsAndTopicsUnderTopics,
	studentProfileTranslations,
	modalsCommon,
	modalsChangePassword,
);
