import api from "@/services/api/Api.js";

export default {
	/**
	 * Creates a school
	 *
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	create(data) {
		return api().post(`schools`, data);
	},

	/**
	 * Updates a school
	 *
	 * @param {number} schoolId
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	update(schoolId, data) {
		return api().post(`schools/${schoolId}`, data);
	},

	/**
	 * Find a school by id
	 *
	 * @arg {number} schoolId
	 * @arg {object} params
	 * @returns {AxiosPromise}
	 */
	show(schoolId, params) {
		return api().get(`schools/${schoolId}`, {
			params: params,
		});
	},

	/**
	 * Get all schools
	 *
	 * @arg {object} params
	 * @returns {AxiosPromise}
	 */
	list(params) {
		return api().get(`schools`, {
			params: params,
		});
	},

	/**
	 * Same as list above, but only retrieves name
	 */
	listLite(params) {
		return api().get(`schools/search`, {
			params: params,
		});
	},

	/**
	 * Get filtered schools
	 *
	 * @arg {object} params
	 * @returns {AxiosPromise}
	 */
	filter(params) {
		return api().get(`schools.filter`, {
			params: params,
		});
	},

	/**
	 * Get aggregate stats of school
	 *
	 * @arg {number} schoolId
	 * @returns {AxiosPromise}
	 */
	aggregate(schoolId) {
		return api().get(`schools/${schoolId}/aggregate`);
	},

	/**
	 * update users schools
	 *
	 * @arg {number} userId
	 * @data {Number} school_id school id to be changed to
	 * @returns {AxiosPromise}
	 */
	updateUserSchool(userId, data) {
		return api().post(`users/${userId}/schools`, data);
	},
};
