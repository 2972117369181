import initializePendo from "@/middleware/initializePendo.js";
import initializeZendesk from "@/middleware/initializeZendesk.js";
import initFullStory from "@/middleware/initFullStory.js";
import isConnected from "@/middleware/isConnected.js";
import hasRole from "@/middleware/hasRole.js";
import hasSchool from "@/middleware/hasSchool.js";
import middleware from "@/middleware/index.js";
import store from "@/store/index.js";
import missionsAdmin from "@/modules/Math/router/missionsAdmin.js";

export default [
	{
		path: "/",
		component: () => import("../pages/problem-creator/index.vue"),
		beforeEnter: middleware([
			isConnected,
			hasRole("paper-math/problem-creator"),
			hasSchool,
			initializePendo,
			initializeZendesk,
			initFullStory,
			(to, from, next) => {
				store.commit("SET_SHOW_NAVBAR", true);
				next();
			},
		]),
		children: [
			...missionsAdmin,
		],
	},
];
