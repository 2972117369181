import { isEmpty, isEqual } from "lodash";

import { UsersAPI } from "@/modules/Customers/index.js";
import EssaysAPI from "@/services/api/Essays.js";

import TutorAutoReviewApi from "../services/TutorAutoReviewApi.js";
import TutorReviewCenterApi from "../services/TutorReviewCenterApi.js";
import TutorReviewCenterDocumentApi from "../services/TutorReviewCenterDocumentApi.js";
import {
	getActiveReviewDurationInMS,
	getSummaryRephraseCommentsFromStorage,
	getTutorReviewSummaryFromStorage,
	setSummaryRephraseCommentsToStorage,
	setTutorReviewSummaryToStorage,
} from "../utilities/index.js";
import { isSuggestion, isUnsavedComment } from "../utilities/types.js";

const getDefaultState = () => ({
	annotations: [],
	categories: [],
	essayAssigned: null,
	essayReserved: null,
	essays: [],
	isLoading: true,
	isEssayReviewer: null,
	isOpenPassEssayModal: false,
	isOpenRefreshPageModal: false,
	isOpenSubmitReviewModal: false,
	isOpenPanels: false,
	plagiarismStatus: null,
	selectedAnnotationId: null,
	summary: null,
	summaryComment: "",
	timeSpent: 0,
	timerInterval: null,
	total: 0,
	documentCreator: () => {
		throw new Error("Document creator is not available.");
	},
	refreshPageModalError: null,
	xfdfString: null,
	submitErrors: {
		emptyComments: [],
		emptySummary: false,
		unprocessedSuggestions: [],
	},

	// Tutor settings
	zoomLevel: null,
	summaryAIHintHidden: true,

	// AI suggestions
	suggestions: [],
	generalSuggestion: null,
	isAISummaryLoading: false,
	AISummaryError: null,
	wasSummaryCommentFromAI: false,
	lastSummaryCommentRephrase: null,
	summaryRephraseComments: [],
	showSuggestions: true,
});

const essayReservationInclude = "essay.student,essay.language,essay.student.student.grade,essay.reviewSubmissionType,essay.teacherInstructionFiles";
export const essayInclude = "student.preferredLanguage,language,student.student.grade,reviewSubmissionType,teacherInstructionFiles";

export const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_ANNOTATIONS(state, annotations) {
		state.annotations = annotations;
	},
	SET_CATEGORIES(state, payload) {
		state.categories = payload.categories;
	},
	SET_ESSAY_ASSIGNED(state, payload) {
		state.essayAssigned = payload.essay;
	},
	SET_ESSAY_RESERVED(state, payload) {
		state.essayReserved = payload.essay;
	},
	SET_ESSAYS(state, payload) {
		state.essays = payload.essays;
	},
	SET_TOTAL(state, payload) {
		state.total = payload.total;
	},
	SET_IS_LOADING(state, payload) {
		state.isLoading = payload.isLoading;
	},
	SET_IS_ESSAY_REVIEWER(state, payload) {
		state.isEssayReviewer = payload.isEssayReviewer;
	},
	SET_IS_OPEN_PASS_ESSAY_MODAL(state, payload) {
		state.isOpenPassEssayModal = payload.isOpenPassEssayModal;
	},
	SET_IS_OPEN_REFRESH_PAGE_MODAL(state, payload) {
		state.isOpenRefreshPageModal = payload.isOpenRefreshPageModal;
		state.refreshPageModalError = payload.refreshPageModalError;
	},
	SET_IS_OPEN_SUBMIT_REVIEW_MODAL(state, payload) {
		state.isOpenSubmitReviewModal = payload.isOpenSubmitReviewModal;
	},
	SET_IS_OPEN_PANELS(state, payload) {
		state.isOpenPanels = payload.isOpenPanels;
	},
	SET_PLAGIARISM_STATUS(state, payload) {
		state.plagiarismStatus = payload.plagiarismStatus;
	},
	SET_SUMMARY_COMMENT(state, payload) {
		state.summaryComment = payload.summaryComment;
	},
	SET_TIME_SPENT(state, payload) {
		state.timeSpent = payload.timeSpent;
	},
	SET_TIMER_INTERVAL(state, payload) {
		state.timerInterval = payload.timerInterval;
	},
	SET_ESSAY_SUMMARY(state, payload) {
		state.summary = payload.summary;
	},
	SET_SELECTED_ANNOTATION_ID(state, payload) {
		state.selectedAnnotationId = payload.annotationId;
	},
	SET_DOCUMENT_CREATOR(state, payload) {
		state.documentCreator = payload.documentCreator;
	},
	SET_XFDF_STRING(state, payload) {
		state.xfdfString = payload;
	},
	SET_ZOOM_LEVEL(state, payload) {
		state.zoomLevel = payload.zoomLevel;
	},
	SET_SUMMARY_AI_HINT_HIDDEN(state, payload) {
		state.summaryAIHintHidden = payload.hidden;
	},
	SET_ESSAY_SUGGESTIONS(state, payload) {
		state.suggestions = payload.suggestions;
	},
	SET_ESSAY_GENERAL_SUGGESTION(state, payload) {
		state.generalSuggestion = payload.generalSuggestion;
	},
	SET_ESSAY_AI_SUMMARY_LOADING(state, payload) {
		state.isAISummaryLoading = payload.isLoading;
	},
	SET_ESSAY_AI_SUMMARY_ERROR(state, payload) {
		state.AISummaryError = payload.error;
	},
	SET_WAS_SUMMARY_COMMENT_FROM_AI(state, payload) {
		state.wasSummaryCommentFromAI = payload.isFromAI;
	},
	SET_LAST_SUMMARY_COMMENT_REPHRASE(state, payload) {
		state.lastSummaryCommentRephrase = payload.rephrase;
	},
	SET_ESSAY_SUMMARY_REPHRASE_COMMENTS(state, { comments }) {
		state.summaryRephraseComments = comments;
	},
	SET_SUBMIT_ERRORS(state, payload) {
		const payloadKeys = Object.keys(payload);
		payloadKeys.forEach((key) => {
			state.submitErrors[key] = payload[key];
		});
	},
	SET_SHOW_SUGGESTIONS(state, payload) {
		state.showSuggestions = payload.showSuggestions;
	},
};

export const getters = {
	hasEssayReserved(state) {
		return !isEmpty(state.essayReserved);
	},
	hasEssayAssigned(state) {
		return !isEmpty(state.essayAssigned);
	},
	hasEmptyQueue(state) {
		return isEmpty(state.essayAssigned) && isEmpty(state.essayReserved);
	},
	rephraseSummaryAllowed(state) {
		return !isEqual(state.summaryRephraseComments, getSubmittedAnnotationComments(state))
			&& (state.summaryComment ?? "").trim() !== "";
	},
	lastSummaryFromAI(state) {
		if (state.lastSummaryCommentRephrase) {
			return {
				type: "rephrased",
				model: state.lastSummaryCommentRephrase,
			};
		}

		if (state.wasSummaryCommentFromAI) {
			return {
				type: "generated",
				model: state.generalSuggestion,
			};
		}

		return null;
	},
};

export const actions = {
	async getReviewerStatus({ rootState, commit }) {
		const {
			data: { data },
		} = await UsersAPI.show(rootState.currentUser.id, {
			include: "tutor",
		});

		commit({
			type: "SET_IS_ESSAY_REVIEWER",
			isEssayReviewer: data.tutor.data.essay_review,
		});
	},

	async getInProgressEssay({ commit, rootState, dispatch }) {
		const { data: { data: [essay] } } = await EssaysAPI.list({
			tutor_id: rootState.currentUser.id,
			include: essayInclude,
		});
		const hasEssay = !isEmpty(essay);
		if (hasEssay) {
			commit({ type: "SET_ESSAY_ASSIGNED", essay });
			commit({ type: "SET_IS_LOADING", isLoading: false });
		} else {
			dispatch("getUnreviewedEssays");
		}
	},

	async getUnreviewedEssays({ commit }) {
		const {
			data: {
				data,
				meta: {
					pagination: { total },
				},
			},
		} = await EssaysAPI.list({
			status: 0,
			include: essayInclude,
			page: 1,
		});

		commit({ type: "SET_ESSAYS", essays: data });
		commit({ type: "SET_TOTAL", total });
		commit({ type: "SET_IS_LOADING", isLoading: false });
	},

	async assignEssay({ rootState }, essay) {
		const {
			data: {
				data: { status },
			},
		} = await EssaysAPI.show(essay.id);

		if (status === "pending") {
			await EssaysAPI.update(essay.id, {
				tutor_id: rootState.currentUser.id,
				status: 1,
				picked_up_at: Math.floor(Date.now() / 1000),
			});
		}
	},

	async initTimer({ commit, dispatch, state, rootState }) {
		await dispatch("stopTimer");
		await dispatch("TutorSchedule/getCurrentShift", null, { root: true });

		const timeSpent = getActiveReviewDurationInMS({
			startTime: state.essayAssigned.picked_up_at,
			shiftSegments: rootState.TutorSchedule.currentShift.shift_segments,
		});

		commit({ type: "SET_TIME_SPENT", timeSpent });
	},

	async startTimer({ commit, dispatch, rootState, state }) {
		await dispatch("stopTimer");

		const timerInterval = setInterval(() => {
			const timeSpent = getActiveReviewDurationInMS({
				startTime: state.essayAssigned.picked_up_at,
				shiftSegments: rootState.TutorSchedule.currentShift.shift_segments,
			});

			commit({
				type: "SET_TIME_SPENT",
				timeSpent,
			});
		}, 1000);

		commit({
			type: "SET_TIMER_INTERVAL",
			timerInterval,
		});
	},

	async stopTimer({ commit, state }) {
		const { timerInterval } = state;

		if (timerInterval) {
			clearInterval(timerInterval);

			commit({
				type: "SET_TIMER_INTERVAL",
				timerInterval: null,
			});
		}
	},

	async sendReview({ state, commit, dispatch }, data) {
		commit({ type: "SET_IS_LOADING", isLoading: true });

		await EssaysAPI.update(state.essay.id, data);
		dispatch("goToEssaySelect");
	},

	async sendReviewSubmission({ state, commit }, data) {
		commit({ type: "SET_IS_LOADING", isLoading: true });
		await EssaysAPI.update(state.essay.id, data);
	},

	async goToEssaySelect({ commit, dispatch }) {
		commit({ type: "SET_ESSAY_ASSIGNED", essay: null });
		dispatch("getUnreviewedEssays");
	},

	async reserveEssay({ commit }) {
		try {
			const response = await EssaysAPI.reserveEssay({
				include: essayReservationInclude,
			});

			const hasEssayToReserve = !isEmpty(response.data.data);
			if (hasEssayToReserve) {
				commit({
					type: "SET_ESSAY_RESERVED",
					essay: response.data.data.essay.data,
				});
			}
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject();
		}
	},

	async getEssayReserved({ commit, dispatch }) {
		try {
			const response = await EssaysAPI.getReservedEssay({
				include: essayReservationInclude,
			});

			const hasEssayReserved = !isEmpty(response.data.data);
			if (hasEssayReserved) {
				commit({
					type: "SET_ESSAY_RESERVED",
					essay: response.data.data.essay.data,
				});
			} else {
				await dispatch("reserveEssay");
			}
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject();
		}
	},

	async skipEssayReserved({ commit }, params) {
		try {
			await EssaysAPI.skipReservedEssay(params);
			commit({ type: "SET_ESSAY_RESERVED", essay: null });
		} catch (error) {
			Sentry.captureException(error);
			throw error.response ? error.response.data : error;
		}
	},

	async getEssaySummary({ commit }, params) {
		try {
			const data = await TutorReviewCenterDocumentApi.getSummary(params);
			commit({ type: "SET_ESSAY_SUMMARY", summary: data });
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	async submitReview({ state }) {
		try {
			const data = new FormData();
			data.append("plagiarism_free", state.plagiarismStatus);
			data.append("file", await state.documentCreator());
			data.append("tutor_comment", state.summaryComment);
			await EssaysAPI.submitReview(state.essayAssigned.id, data);
		} catch (error) {
			Sentry.captureException(error);
			throw error.response ? error.response.data : error;
		}
	},
	async submitAnnotations({ state }) {
		await TutorReviewCenterApi.submitAnnotations(state.essayAssigned.id, state.xfdfString);
	},
	async getCategories({ commit }) {
		try {
			const categories = await TutorReviewCenterApi.getCategories();
			commit({ type: "SET_CATEGORIES", categories });
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	async getTutorSettings({ dispatch }) {
		try {
			const settings = await TutorReviewCenterApi.getTutorSettings();
			await dispatch("setTutorSettings", settings);
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	setTutorSettings({ commit }, settings) {
		commit({ type: "SET_ZOOM_LEVEL", zoomLevel: settings.zoom_preferred_level });
		commit({ type: "SET_SUMMARY_AI_HINT_HIDDEN", hidden: !!settings.summary_ai_hint_hidden });
	},
	async updateZoomLevel({ dispatch }, level) {
		await dispatch("updateTutorSettings", { zoom_preferred_level: level });
	},
	async updateSummaryAIHintHidden({ dispatch }, hidden) {
		await dispatch("updateTutorSettings", { summary_ai_hint_hidden: hidden });
	},
	async updateTutorSettings({ dispatch }, settings) {
		try {
			const updatedSettings = await TutorReviewCenterApi.updateTutorSettings(settings);
			await dispatch("setTutorSettings", updatedSettings);
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	async getSuggestions({ commit }, essayId) {
		try {
			const suggestions = await TutorAutoReviewApi.getEssaySuggestions(essayId);
			commit({ type: "SET_ESSAY_SUGGESTIONS", suggestions });
		} catch (error) {
			commit({ type: "SET_ESSAY_SUGGESTIONS", suggestions: [] });
			Sentry.captureException(error);
		}
	},
	async updateSuggestionStatus({ dispatch }, { suggestionId, status, reason = undefined }) {
		try {
			const updatedSuggestion = await TutorAutoReviewApi.updateSuggestionStatus(suggestionId, status, reason);
			await dispatch("updateSuggestionInList", { suggestion: updatedSuggestion });
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	async updateSuggestionInList({ commit, state }, { suggestion }) {
		const updatedIndex = state.suggestions.findIndex(({ id }) => id === suggestion.id);

		if (updatedIndex !== -1) {
			const suggestions = [...state.suggestions];
			suggestions.splice(updatedIndex, 1, suggestion);

			commit({ type: "SET_ESSAY_SUGGESTIONS", suggestions });
		}
	},
	async getGeneralSuggestion({ commit }, essayId) {
		try {
			commit({ type: "SET_ESSAY_AI_SUMMARY_LOADING", isLoading: true });
			commit({ type: "SET_ESSAY_AI_SUMMARY_ERROR", error: null });

			const generalSuggestion = await TutorAutoReviewApi.getGeneratedSummary(essayId);

			commit({ type: "SET_ESSAY_GENERAL_SUGGESTION", generalSuggestion });
		} catch (error) {
			if (error.response?.status !== 404) {
				commit({ type: "SET_ESSAY_AI_SUMMARY_ERROR", error });
				commit({ type: "SET_ESSAY_GENERAL_SUGGESTION", generalSuggestion: null });
				Sentry.captureException(error);
			}
		} finally {
			commit({ type: "SET_ESSAY_AI_SUMMARY_LOADING", isLoading: false });
		}
	},
	async loadSummaryCommentFromSuggestion({ dispatch, state }, { essayId }) {
		const suggestedComment = state.generalSuggestion?.comment;

		if (!suggestedComment) {
			return "";
		}

		await dispatch("updateSummaryRephraseComments", { essayId, comments: [] });

		return suggestedComment;
	},
	async initSummaryComment({ commit, dispatch, state }, { essayId, allowSuggestion }) {
		let summaryComment = getTutorReviewSummaryFromStorage(essayId) ?? "";

		if (!summaryComment && allowSuggestion) {
			summaryComment = await dispatch("loadSummaryCommentFromSuggestion", { essayId });
		} else {
			await dispatch("updateSummaryRephraseComments", {
				essayId,
				comments: getSummaryRephraseCommentsFromStorage(essayId) ?? [],
			});
		}

		await dispatch("updateSummaryComment", { essayId, summaryComment });
		commit({
			type: "SET_WAS_SUMMARY_COMMENT_FROM_AI",
			isFromAI: !!state.generalSuggestion?.comment && (summaryComment === state.generalSuggestion?.comment),
		});
	},
	updateSummaryComment({ commit }, { essayId, summaryComment }) {
		setTutorReviewSummaryToStorage(essayId, summaryComment);
		commit({ type: "SET_SUMMARY_COMMENT", summaryComment });
	},
	async initEssay({ commit, dispatch, rootGetters }, { essay, enableSuggestions }) {
		const defaultState = getDefaultState();

		commit("SET_XFDF_STRING", defaultState.xfdfString);
		commit("SET_ANNOTATIONS", defaultState.annotations);
		commit("SET_SUBMIT_ERRORS", defaultState.submitErrors);
		commit({ type: "SET_PLAGIARISM_STATUS", plagiarismStatus: defaultState.plagiarismStatus });
		commit({ type: "SET_SUMMARY_COMMENT", summaryComment: defaultState.summaryComment });
		commit({ type: "SET_WAS_SUMMARY_COMMENT_FROM_AI", isFromAI: defaultState.wasSummaryCommentFromAI });
		commit({ type: "SET_LAST_SUMMARY_COMMENT_REPHRASE", rephrase: defaultState.lastSummaryCommentRephrase });
		commit({ type: "SET_ESSAY_SUGGESTIONS", suggestions: defaultState.suggestions });
		commit({ type: "SET_ESSAY_GENERAL_SUGGESTION", generalSuggestion: defaultState.generalSuggestion });
		commit({ type: "SET_ESSAY_AI_SUMMARY_LOADING", isLoading: defaultState.isLoading });
		commit({ type: "SET_ESSAY_AI_SUMMARY_ERROR", error: defaultState.AISummaryError });
		commit({ type: "SET_ESSAY_SUMMARY_REPHRASE_COMMENTS", comments: defaultState.summaryRephraseComments });

		await dispatch("initTimer");

		if (enableSuggestions) {
			dispatch("getSuggestions", essay.id);
			dispatch("getGeneralSuggestion", essay.id).then(() => {
				dispatch("initSummaryComment", { essayId: essay.id, allowSuggestion: enableSuggestions });
			});
		}

		if (!rootGetters["BreaksManagementWidget/isOnBreak"]) {
			await dispatch("startTimer");
		}

		dispatch("initSummaryComment", { essayId: essay.id, allowSuggestion: enableSuggestions });
	},
	async rephraseSuggestion({ dispatch, state }, { suggestionId }) {
		let suggestion = state.suggestions.find(({ id }) => id === suggestionId);

		if (!suggestion) {
			return;
		}

		let updatedSuggestion = suggestion;
		if (!updatedSuggestion.rephrases?.length) {
			try {
				updatedSuggestion = await TutorAutoReviewApi.rephraseSuggestion(suggestionId);
			} catch (error) {
				Sentry.captureException(error);
				return;
			}
		}

		suggestion = {
			...updatedSuggestion,
			original_display_comment: updatedSuggestion.original_display_comment ?? updatedSuggestion.display_comment,
			display_comment: calculateNextRephraseText(suggestion, updatedSuggestion),
		};

		await dispatch("updateSuggestionInList", { suggestion });
	},
	updateSummaryRephraseComments({ commit }, { essayId, comments }) {
		commit({ type: "SET_ESSAY_SUMMARY_REPHRASE_COMMENTS", comments });
		setSummaryRephraseCommentsToStorage(essayId, comments);
	},
	async rephraseSummary({ state, getters, commit, dispatch }) {
		if (!getters.rephraseSummaryAllowed) {
			return;
		}

		commit({ type: "SET_ESSAY_AI_SUMMARY_LOADING", isLoading: true });
		try {
			const essayId = state.essayAssigned.id;
			const summary = state.summaryComment;
			const comments = getSubmittedAnnotationComments(state);

			const newSummary = await TutorAutoReviewApi.rephraseSummary({
				essayId,
				summary,
				comments,
				parentId: getters.lastSummaryFromAI?.model?.id,
			});

			commit({ type: "SET_LAST_SUMMARY_COMMENT_REPHRASE", rephrase: newSummary });
			await dispatch("updateSummaryComment", { essayId, summaryComment: newSummary.comment });
			await dispatch("updateSummaryRephraseComments", { essayId, comments });
		} catch (error) {
			Sentry.captureException(error);
		} finally {
			commit({ type: "SET_ESSAY_AI_SUMMARY_LOADING", isLoading: false });
		}
	},
	async rateAISummary({ commit, getters }, { rate }) {
		const lastSummary = getters.lastSummaryFromAI;
		if (!lastSummary) {
			return;
		}

		try {
			const updatedModel = await TutorAutoReviewApi.updateSummary(lastSummary.model.id, { rate });

			switch (lastSummary.type) {
				case "generated":
					commit({ type: "SET_ESSAY_GENERAL_SUGGESTION", generalSuggestion: updatedModel });
					break;
				case "rephrased":
					commit({ type: "SET_LAST_SUMMARY_COMMENT_REPHRASE", rephrase: updatedModel });
					break;
				default:
					break;
			}
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	validateEssayReview({ state, commit }) {
		const errors = {
			emptyComments: state.annotations.filter(isUnsavedComment).map((annot) => annot.id),
			emptySummary: isEmpty(state.summaryComment),
			unprocessedSuggestions: state.annotations.filter(isSuggestion).map((annot) => annot.id),
		};

		commit("SET_SUBMIT_ERRORS", errors);

		if (!isEmpty(errors.unprocessedSuggestions) && !state.showSuggestions) {
			commit({ type: "SET_SHOW_SUGGESTIONS", showSuggestions: true });
		}

		return isEmpty(errors.emptyComments) && isEmpty(errors.unprocessedSuggestions) && !errors.emptySummary;
	},
};

function calculateNextRephraseText(oldSuggestion, newSuggestion) {
	const rephrases = [
		oldSuggestion.original_display_comment ?? oldSuggestion.display_comment,
		...(newSuggestion.rephrases?.map((r) => r.display_comment) ?? []),
	];
	const currentIndex = rephrases.indexOf(oldSuggestion.display_comment);

	let nextIndex = currentIndex + 1;
	if (nextIndex >= rephrases.length) {
		nextIndex = 0;
	}

	return nextIndex === 0
		? (newSuggestion.original_display_comment ?? newSuggestion.display_comment)
		: rephrases[nextIndex];
}

function getSubmittedAnnotationComments(state) {
	return state.annotations
		.filter((annot) => !annot.suggestion && !!annot.comment)
		.map((annot) => annot.comment).sort();
}

export default {
	namespaced: true,
	state: getDefaultState(),
	getters,
	mutations,
	actions,
};
