var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tw-relative tw-flex tw-justify-center tw-items-center" },
    [
      _c("div", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "spinner",
            expression: "'spinner'",
          },
        ],
        staticClass:
          "spinner tw-w-full tw-h-full tw-absolute tw-animate-spin tw-border-grey-dark tw-rounded-full",
        style: { borderWidth: _vm.thickness },
      }),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }