<template>
	<TutorScheduledHoursWrapper>
		<template #weekDetails>
			<WeekDetails />
		</template>
		<template #totalHours>
			<TotalHoursScheduled />
		</template>
		<template #tutorHoursBreakdown>
			<TutorHoursBreakdown />
		</template>
	</TutorScheduledHoursWrapper>
</template>

<script>
import TutorScheduledHoursWrapper from "./TutorScheduledHoursWrapper.vue";
import WeekDetails from "./WeekDetails.vue";
import TotalHoursScheduled from "./TotalHoursScheduled.vue";
import TutorHoursBreakdown from "./TutorHoursBreakdown.vue";
export default {
	components: {
		TutorScheduledHoursWrapper,
		WeekDetails,
		TotalHoursScheduled,
		TutorHoursBreakdown,
	},
	async created() {
		try {
			this.$store.dispatch("TutorScheduledHours/getStartAndEndDates");
			await this.$store.dispatch("TutorScheduledHours/getShiftHoursStatistics");
		} catch (e) {
			Sentry.captureException(e);
		}
	},
};
</script>
