import { isEmpty } from "lodash";

import { UsersAPI } from "@/modules/Customers/index.js";
import EssaysAPI from "@/services/api/Essays.js";
import { store as EssayStatistics } from "@/modules/TutorEssayStatistics/index.js";

const getDefaultState = () => ({
	essay: null,
	reservedEssay: null,
	essays: [],
	total: 0,
	isLoading: true,
	isEssayReviewer: null,
	tutorCreatedAt: null,
});

const essayReservationInclude = "essay.student,essay.language,essay.student.student.grade,essay.reviewSubmissionType,essay.teacherInstructionFiles";
export const essayInclude = "student.preferredLanguage,language,student.student.grade,reviewSubmissionType,teacherInstructionFiles";

export const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_IS_LOADING(state, payload) {
		state.isLoading = payload.isLoading;
	},
	SET_ESSAY(state, payload) {
		state.essay = payload.essay;
	},
	SET_ESSAYS(state, payload) {
		state.essays = payload.essays;
	},
	SET_TOTAL(state, payload) {
		state.total = payload.total;
	},
	SET_IS_ESSAY_REVIEWER(state, payload) {
		state.isEssayReviewer = payload.isEssayReviewer;
	},
	SET_TUTOR_CREATED_AT(state, payload) {
		state.tutorCreatedAt = payload.tutorCreatedAt;
	},
	SET_RESERVED_ESSAY(state, payload) {
		state.reservedEssay = payload.essay;
	},
};

export const actions = {
	async getReviewerStatus({ rootState, commit }) {
		const {
			data: {
				data,
			},
		} = await UsersAPI.show(rootState.currentUser.id, {
			include: "tutor",
		});
		commit({
			type: "SET_IS_ESSAY_REVIEWER",
			isEssayReviewer: data.tutor.data.essay_review,
		});
		commit({
			type: "SET_TUTOR_CREATED_AT",
			tutorCreatedAt: data.created_at,
		});
	},

	async getInProgressEssay({ commit, rootState, dispatch }) {
		const {
			data: {
				data: [essay],
			},
		} = await EssaysAPI.list({
			tutor_id: rootState.currentUser.id,
			include: essayInclude,
		});
		if (essay != undefined) {
			commit({
				type: "SET_ESSAY",
				essay,
			});
			commit({
				type: "SET_IS_LOADING",
				isLoading: false,
			});
		} else {
			dispatch("getUnreviewedEssays");
		}
	},

	async getUnreviewedEssays({ commit }) {
		const {
			data: {
				data,
				meta: {
					pagination: { total },
				},
			},
		} = await EssaysAPI.list({
			status: 0,
			include: essayInclude,
			page: 1,
		});
		commit({
			type: "SET_ESSAYS",
			essays: data,
		});
		commit({
			type: "SET_TOTAL",
			total,
		});
		commit({
			type: "SET_IS_LOADING",
			isLoading: false,
		});
	},

	async pickupEssay({ rootState }, essay) {
		const {
			data: {
				data: { status },
			},
		} = await EssaysAPI.show(essay.id);

		if (status === "pending") {
			await EssaysAPI.update(essay.id, {
				tutor_id: rootState.currentUser.id,
				status: 1,
				picked_up_at: Math.floor(Date.now() / 1000),
			});
		}
	},

	async sendReview({ state, commit, dispatch }, data) {
		commit({
			type: "SET_IS_LOADING",
			isLoading: true,
		});
		await EssaysAPI.update(state.essay.id, data);
		dispatch("goToEssaySelect");
	},

	async sendReviewSubmission({ state, commit }, data) {
		commit({
			type: "SET_IS_LOADING",
			isLoading: true,
		});
		await EssaysAPI.update(state.essay.id, data);
	},

	async goToEssaySelect({ commit, dispatch }) {
		commit({
			type: "SET_ESSAY",
			essay: null,
		});
		dispatch("getUnreviewedEssays");
	},

	async reserveEssay({ commit }) {
		try {
			const response = await EssaysAPI.reserveEssay({
				include: essayReservationInclude,
			});
			const hasEssayToReserve = !isEmpty(response.data.data);

			if (hasEssayToReserve) {
				commit({
					type: "SET_RESERVED_ESSAY",
					essay: response.data.data.essay.data,
				});
			}
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject();
		}
	},

	async getReservedEssay({ commit, dispatch }) {
		try {
			const response = await EssaysAPI.getReservedEssay({
				include: essayReservationInclude,
			});
			const hasEssayReserved = !isEmpty(response.data.data);
			if (hasEssayReserved) {
				commit({
					type: "SET_RESERVED_ESSAY",
					essay: response.data.data.essay.data,
				});
			} else {
				await dispatch("reserveEssay");
			}
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject();
		}
	},

	async skipReservedEssay({ commit }, params) {
		try {
			await EssaysAPI.skipReservedEssay(params);
			commit({
				type: "SET_RESERVED_ESSAY",
				essay: null,
			});
		} catch (error) {
			Sentry.captureException(error);
		}
	},
};

const modules = {
	EssayStatistics,
};

export default {
	namespaced: true,
	state: getDefaultState(),
	getters: {},
	mutations,
	actions,
	modules,
};
