import CountryTransformer from "@/services/transformers/codeTransformer.js";
import ListTransformer from "@/services/transformers/listTransformer.js";

import DistrictsAPI from "../../services/districts.js";
import SchoolsAPI from "../../services/schools.js";

const getDefaultState = () => ({
	schools: [],
	districts: [],
	countries: [],
	regions: [],

	searchText: "",
	districtFilter: [],
	countryFilter: [],
	regionFilter: [],

	loading: false,
	isShowingNoFilterBanner: true,
	orderBy: "",
	pagination: {
		total_pages: 0,
		current_page: 0,
	},
	schoolsTableHeaders: [
		{
			value: "synced_with",
			header: "",
			orderable: false,
			state: null,
		},
		{
			value: "id",
			header: "ID",
			orderable: true,
			state: null,
		},
		{
			value: "name",
			header: "school",
			orderable: true,
			state: null,
		},
		{
			value: "district",
			header: "district",
			orderable: true,
			state: null,
		},
		{
			value: "country",
			header: "Country",
			orderable: true,
			state: null,
		},
		{
			value: "region",
			header: "State/Prov",
			orderable: true,
			state: null,
		},
		{
			value: "students",
			header: "Students",
			orderable: true,
			state: null,
		},
		{
			value: "license",
			header: "license",
			orderable: true,
			state: null,
		},
		{
			value: "loginType",
			header: "login type",
			orderable: false,
			state: null,
		},
		{
			value: "csManager",
			header: "CS manager",
			orderable: true,
			state: null,
		},
	],
	navbarMainTab: "Customers",
});

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations: {
		RESET_STATE(state) {
			Object.assign(state, getDefaultState());
		},
		SET_IS_SHOWING_NO_FILTER_BANNER(state, payload) {
			state.isShowingNoFilterBanner = payload.isShowingNoFilterBanner;
		},
		SET_SCHOOLS(state, payload) {
			state.schools = payload.schools;
		},
		SET_LOADING(state, payload) {
			state.loading = payload.loading;
		},
		SET_ORDER_BY(state, payload) {
			state.orderBy = payload.orderBy;
		},
		UPDATE_HEADERS(state, payload) {
			state.schoolsTableHeaders = payload.schoolsTableHeaders;
		},
		SET_PAGINATION(state, payload) {
			state.pagination = payload.pagination;
		},
		RESET_PAGINATION(state) {
			state.pagination = { total_pages: 0, current_page: 0 };
		},
		SET_CURRENT_PAGE(state, payload) {
			state.pagination.current_page = payload.currentPage;
		},
		SET_DISTRICTS(state, payload) {
			state.districts = payload.districts;
		},
		SET_REGIONS(state, payload) {
			state.regions = payload.regions;
		},
		SET_COUNTRIES(state, payload) {
			state.countries = payload.countries;
		},
		SET_FILTERS(state, payload) {
			state.searchText = payload.searchText;
			state.countryFilter = payload.countryFilter;
			state.regionFilter = payload.regionFilter;
			state.districtFilter = payload.districtFilter;
		},
		SET_TABLE_HEADERS() {},
	},
	getters: {
		loading(state) {
			return state.loading;
		},
		schoolsTableHeaders(state) {
			return state.schoolsTableHeaders;
		},
		orderBy(state) {
			return state.orderBy;
		},
		pagination(state) {
			return state.pagination;
		},
		districts(state) {
			return state.districts;
		},
		schools(state) {
			return state.schools;
		},
		regions(state) {
			return state.regions;
		},
		countries(state) {
			return state.countries;
		},
	},
	actions: {
		async setSchools({ commit }, payload) {
			payload["include"] = "district,manager,loginType";
			try {
				commit("SET_LOADING", {
					loading: true,
				});

				commit("SET_IS_SHOWING_NO_FILTER_BANNER", {
					isShowingNoFilterBanner: false,
				});

				commit("SET_FILTERS", {
					searchText: payload.search,
					districtFilter: payload.district,
					countryFilter: payload.country,
					regionFilter: payload.region,
				});

				commit("SET_ORDER_BY", {
					orderBy: payload.orderBy,
				});
				const schools = await SchoolsAPI.filter(payload);

				const transformedSchools = [];
				schools.data.data.forEach(function(school) {
					transformedSchools.push(ListTransformer.transformSchool(school));
				});

				commit("SET_SCHOOLS", {
					schools: transformedSchools,
				});

				commit("SET_PAGINATION", {
					pagination: schools.data.meta.pagination,
				});

				return Promise.resolve();
			} catch (error) {
				commit("SET_SCHOOLS", {
					schools: [],
				});
				commit("RESET_PAGINATION");
				return Promise.reject(error.response);
			} finally {
				commit("SET_LOADING", {
					loading: false,
				});
			}
		},

		async getDistricts({ commit }) {
			try {
				const districts = await DistrictsAPI.list();
				commit("SET_DISTRICTS", {
					districts: districts.data.data,
				});
				return Promise.resolve();
			} catch (error) {
				return Promise.reject(error.response);
			}
		},

		getRegions({ commit }, countries) {
			let regions = [];
			if (countries.length > 1) {
				regions = CountryTransformer.listRegions(CountryTransformer.encodeCountry(countries[0]));
				regions = regions.concat(
					CountryTransformer.listRegions(CountryTransformer.encodeCountry(countries[1])),
				);
			} else {
				regions = CountryTransformer.listRegions(CountryTransformer.encodeCountry(countries[0]));
			}
			commit("SET_REGIONS", {
				regions: regions,
			});
		},

		async setOrderBy({ commit, state }, payload) {
			commit("SET_LOADING", {
				loading: true,
			});

			commit("SET_ORDER_BY", {
				orderBy: payload,
			});

			commit("SET_CURRENT_PAGE", {
				currentPage: 1,
			});

			const params = {
				include: "district,manager,loginType",
				search: state.searchText,
				district: state.districtFilter,
				country: state.countryFilter,
				region: state.regionFilter,
				orderBy: payload,
			};

			try {
				const schools = await SchoolsAPI.filter(params);
				const transformedSchools = [];
				schools.data.data.forEach(function(school) {
					transformedSchools.push(ListTransformer.transformSchool(school));
				});

				commit("SET_SCHOOLS", {
					schools: transformedSchools,
				});

				commit("SET_LOADING", {
					loading: false,
				});

				return Promise.resolve();
			} catch (error) {
				return Promise.reject(error.response);
			}
		},

		async setCurrentPage({ commit, state, getters }, payload) {
			commit("SET_LOADING", {
				loading: true,
			});

			commit("SET_CURRENT_PAGE", {
				currentPage: payload.newPage,
			});

			const params = {
				include: "district,manager,loginType",
				search: state.searchText,
				district: state.districtFilter,
				country: state.countryFilter,
				region: state.regionFilter,
				orderBy: getters.orderBy,
				page: payload.newPage,
			};

			try {
				const schools = await SchoolsAPI.filter(params);

				const transformedSchools = [];
				schools.data.data.forEach(function(school) {
					transformedSchools.push(ListTransformer.transformSchool(school));
				});

				commit("SET_SCHOOLS", {
					schools: transformedSchools,
				});

				commit("SET_LOADING", {
					loading: false,
				});

				return Promise.resolve();
			} catch (error) {
				return Promise.reject(error.response);
			}
		},
	},
};
