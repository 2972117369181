<template>
	<div
		:class="{
			'tableContainer--translucent':
				PlatformManager.TutorsList.isLoadingTutors,
		}"
	>
		<TutorsListTable
			:headers="PlatformManager.TutorsList.tableHeaders"
			:items="PlatformManager.TutorsList.tutors"
		>
			<template slot-scope="{ item }">
				<template
					v-for="header in PlatformManager.TutorsList.tableHeaders"
				>
					<td
						v-if="header.value === 'impersonation'"
						:key="header.value + item.id"
						class="table__impersonationCell"
						@click="showImpersonationModal(item.email)"
					>
						<IconImpersonationEye
							class="impersonationIcon"
							icon-color="#1c3075"
						/>
					</td>
					<td
						v-else-if="header.value === 'tutor_since'"
						:key="header.value + item.id"
					>
						<BaseLink
							:to="`/${currentUser.role}/services/tutors/edit/${item.id}`"
						>
							{{ formatDate(item.tutor_since) }}
						</BaseLink>
					</td>
					<td
						v-else-if="header.value === 'status'"
						:key="header.value + item.id"
					>
						<BaseLink
							v-data-cy="'tutor-edit-button'"
							:to="`/${currentUser.role}/services/tutors/edit/${item.id}`"
						>
							<div
								class="table__statusDot"
								:class="computeStatusDotColor(item.status)"
							/>
							{{ computeStatusText(item.status) }}
						</BaseLink>
					</td>
					<td
						v-else-if="header.value === 'is_new'"
						:key="header.value + item.id"
						v-jest="'new-tutor-cell'"
					>
						<BaseLink
							:to="`/${currentUser.role}/services/tutors/edit/${item.id}`"
						>
							<IconCheckMark
								v-if="item.is_new"
								v-jest="'new-tutor-icon'"
								class="tw-w-5 tw-h-3 tw-ml-1 tw-text-black-high"
								:icon-color="'currentColor'"
							/>
						</BaseLink>
					</td>
					<td
						v-else
						:key="header.value"
					>
						<BaseLink
							:to="`/${currentUser.role}/services/tutors/edit/${item.id}`"
						>
							{{ item[header.value] }}
						</BaseLink>
					</td>
				</template>
			</template>
		</TutorsListTable>
		<ImpersonationModal
			v-show="isImpersonationModalVisible"
			:user-email="impersonationEmail"
			:token="impersonationToken"
			@close-modal="closeImpersonationModal()"
		/>
	</div>
</template>
<script>
import { mapState } from "vuex";
import { format } from "date-fns";

import IconImpersonationEye from "@/components/icons/IconImpersonationEye.vue";
import IconCheckMark from "@/components/icons/IconCheckMark.vue";
import { ImpersonationModal } from "@/modules/Customers/index.js";

import TutorsListTable from "./TutorsListTable.vue";

export default {
	components: {
		IconImpersonationEye,
		IconCheckMark,
		ImpersonationModal,
		TutorsListTable,
	},
	data() {
		return {
			isImpersonationModalVisible: false,
			impersonationEmail: "",
			impersonationToken: "",
		};
	},
	computed: {
		...mapState(["PlatformManager", "currentUser"]),
	},
	methods: {
		/**
	     * @param {String} email
	     */
		showImpersonationModal(email) {
			this.impersonationEmail = email;
			this.isImpersonationModalVisible = true;
		},
		closeImpersonationModal() {
			this.impersonationEmail = "";
			this.isImpersonationModalVisible = false;
		},
		/**
		 * @param {Number} status
		 * @returns {String}
		 */
		computeStatusText(status) {
			// eslint-disable-next-line default-case
			switch (status) {
				case 0:
					return "Inactive";
				case 1:
					return "Active";
			}
		},
		/**
	     * @param {Number} status
	     * @returns {String}
	     */
		computeStatusDotColor(status) {
			// eslint-disable-next-line default-case
			switch (status) {
				case 0:
					return "table__statusDot--red";
				case 1:
					return "table__statusDot--green";
			}
		},
		/**
	     * @param {Number} timestamp in s
	     * @returns {String}
	     */
		formatDate(timestamp) {
			return format(new Date(timestamp * 1000), "dd/MM/yyyy");
		},
	},
};
</script>
<style scoped>
.tableContainer--translucent {
	opacity: 0.6;
}
.table__link {
	font-size: 10px;
	font-weight: 600;
	color: #4a4a4a;
	text-decoration: none;
}
.table__link:hover {
	color: var(--c-blue);
}
.impersonationIcon {
	opacity: 0.75;
}
.table__impersonationCell:hover > .impersonationIcon {
	opacity: 1;
}
.table__statusDot {
	display: inline-flex;
	margin-right: 5px;
	height: 8px;
	width: 8px;
	border-radius: 100%;
}
.table__statusDot--red {
	background-color: var(--c-orange);
}
.table__statusDot--green {
	background-color: var(--c-green);
}
</style>
