import api from "./Api.js";

export default {
	/**
	 * Find a user by id
	 *
	 * @arg {number} userId
	 * @arg {object} params
	 * @returns {AxiosPromise}
	 */
	list(params) {
		return api().get("subjects", {
			params: params,
		});
	},
};
