var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "data-cy",
          rawName: "v-data-cy",
          value: "bmw-container",
          expression: "'bmw-container'",
        },
      ],
      staticClass: "tw-p-4 tw-bg-origami-white",
    },
    [
      _c(
        "h2",
        {
          directives: [
            {
              name: "data-cy",
              rawName: "v-data-cy",
              value: "bmw-heading",
              expression: "'bmw-heading'",
            },
          ],
          staticClass: "tw-text-sm tw-font-bold tw-mb-4",
        },
        [_vm._v("\n\t\tBreaks Manager\n\t")]
      ),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          { staticClass: "tw-flex tw-justify-between" },
          [_vm._t("status"), _vm._v(" "), _vm._t("shiftDetails")],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tw-flex tw-w-full tw-justify-start tw-mt-4" },
          [_vm._t("breakButtons")],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "tw-text-left" }, [_vm._t("breakDetails")], 2),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "tw-col-span-2 tw-justify-items-start tw-items-end tw-mt-4",
          },
          [_vm._t("content")],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }