<script>
import { differenceInMinutes } from "date-fns";

import ServiceDashboardTutorCardHeaderAbstract from "./ServiceDashboardTutorCardHeaderAbstract.vue";
export default {
	extends: ServiceDashboardTutorCardHeaderAbstract,
	computed: {
		/**
		 * We do not show disconnection decoration for upcoming tutors.
		 * @returns {Boolean}
		 */
		isDisconnectedAndPunchedIn() {
			return false;
		},
		/**
		 * @returns {Boolean}
		 */
		isEssayReviewShift() {
			return this.shift.schedule_type_id === 2;
		},
		/**
		 * Upcoming shifts will not be greyed out.
		 * @returns {Boolean}
		 */
		isGreyedOut() {
			return false;
		},
		/**
		 * Upcoming shifts are not late so the text should not be red.
		 * @returns {Boolean}
		 */
		isShiftStartRed() {
			return false;
		},
		/**
		 * @returns {Boolean}
		 */
		isUpcomingTutorShift() {
			return true;
		},
		/**
		 * @returns {String}
		 */
		tutorShiftStartTime() {
			return this.shiftStartTime;
		},
		/**
		 * @returns {Object}
		 */
		extraInfo() {
			const extraInfo = {
				class: "",
				text: "",
			};
			if (this.isEssayReviewShift) {
				extraInfo.text = "Writing Review Shift";
			}
			return extraInfo;
		},
		/**
		 * @returns {String}
		 */
		timeElapsedSincePunchIn() {
			return "";
		},
		/**
		 * @returns {String}
		 */
		timeUntilShiftStart() {
			const shiftStartDate = new Date(this.shift.start_time * 1000);
			const mins = differenceInMinutes(shiftStartDate, this.currentDate);
			return `In ${mins} m`;
		},
	},
};
</script>
