import api from "@/services/api/Api.js";

export default {
	/**
	 * updates a tutor's languages
	 * @param {number} tutorId
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	updateTutorLanguages(tutorId, data) {
		return api().post(`tutors/${tutorId}/languages`, data);
	},

	/**
	 * updates a tutor's essay languages
	 * @param {number} tutorId
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	updateEssayLanguages(tutorId, data) {
		return api().post(`tutors/${tutorId}/essay-languages`, data);
	},

	/**
	 * updates a tutor's audio message languages
	 * @param {number} tutorId
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	async updateAudioLanguages(tutorId, data) {
		return api().post(`tutors/${tutorId}/async-audio-session-languages`, data);
	},
	/**
	 *
	 * get all languages
	 *
	 * @arg {object} params
	 * @returns {AxiosPromise}
	 */
	list(params) {
		return api().get(`/languages`, {
			params: params,
		});
	},
};
