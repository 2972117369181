<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		:width="width"
		:height="height"
		viewBox="0 0 16 16"
	>
		<defs>
			<path
				:id="randomId"
				d="M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm2.263 11.394L8 9.131l-2.263 2.263-1.131-1.131L6.869 8 4.606 5.737l1.131-1.131L8 6.869l2.263-2.263 1.131 1.131L9.131 8l2.263 2.263-1.131 1.131z"
			/>
		</defs>
		<g
			:fill="iconColor"
			fill-rule="evenodd"
		>
			<mask
				:id="`a${randomId}`"
				fill="#fff"
			>
				<use :xlink:href="`#${randomId}`" />
			</mask>
			<g
				:fill="iconColor"
				:mask="`url(#a${randomId})`"
			>
				<path d="M0 0h16v16H0z" />
			</g>
		</g>
	</svg>
</template>

<script>
import Colors from "../../utilities/Colors.js";

import IconId from "./IconId.js";
export default {
	extends: IconId,
	props: {
		width: {
			type: [Number, String],
			default: 16,
		},
		height: {
			type: [Number, String],
			default: 16,
		},
		iconColor: {
			type: String,
			default: Colors["C_BLUE"],
		},
	},
};
</script>
