var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 20 15",
      },
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("g", { attrs: { fill: _vm.iconColor } }, [
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M2 0C0.895431 0 0 0.895431 0 2V10C0 11.1046 0.895431 12 2 12H4V15.0657C4 15.4651 4.44507 15.7033 4.77735 15.4818L10 12H18C19.1046 12 20 11.1046 20 10V2C20 0.895431 19.1046 0 18 0H2Z M6.32202 8.072C7.24602 8.072 7.94202 7.622 8.26602 6.794L7.33002 6.548C7.21002 7.028 6.88602 7.358 6.32202 7.358C5.86002 7.358 5.57202 7.226 5.38002 6.956C5.20002 6.698 5.15202 6.38 5.15202 5.9C5.15202 5.42 5.24202 5.03 5.43402 4.778C5.62602 4.526 5.93802 4.442 6.32202 4.442C6.89802 4.442 7.21602 4.784 7.31202 5.312L8.23602 5.138C8.00202 4.184 7.24602 3.728 6.32202 3.728C4.96602 3.728 4.21002 4.628 4.21002 5.9C4.21002 7.298 4.99002 8.072 6.32202 8.072Z",
            },
          }),
          _vm._v(" "),
          _c("path", { attrs: { d: "", fill: "white" } }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M11.9953 7.022C11.9953 6.566 11.8333 6.26 11.4313 6.11C11.8273 5.936 12.0973 5.588 12.0973 5.066C12.0973 3.992 11.3173 3.8 10.4113 3.8H8.77926V8H9.68526V6.41H10.3813C10.8553 6.41 11.0953 6.596 11.0953 7.064C11.0953 7.592 11.1313 7.778 11.2573 8H12.2293L12.2353 7.982C12.0493 7.712 11.9953 7.442 11.9953 7.022ZM10.3333 5.702H9.68526V4.508H10.3393C10.8313 4.508 11.2033 4.568 11.2033 5.102C11.2033 5.624 10.8313 5.702 10.3333 5.702Z",
              fill: "white",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M12.8105 8H15.7625V7.274H13.7165V6.242H15.5405V5.516H13.7165V4.526H15.7625V3.8H12.8105V8Z",
              fill: "white",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }