<template>
	<div>
		<component :is="mealBreakComponent" />
	</div>
</template>

<script>
import { mapState } from "vuex";

import {
	MEAL_BREAK_REQUEST,
	MEAL_BREAK_PENDING_APPROVAL,
	MEAL_BREAK_REQUEST_REJECTED,
	MEAL_BREAK_CANCELLED,
	MEAL_BREAK_REQUEST_APPROVED,
	MEAL_BREAK_ERROR,
	MEAL_BREAK_UPCOMING,
	MEAL_BREAK_CANCEL,
	MEAL_BREAK_CANCEL_CONFIRM,
	MEAL_BREAK_IN_PROGRESS,
	MEAL_BREAK_LATE,
} from "../../utilities/BreakWorkflowSteps.js";

import MealBreakScheduleRequest from "./MealBreakScheduleRequest.vue";
import MealBreakPendingApproval from "./MealBreakPendingApproval.vue";
import MealBreakRequestRejected from "./MealBreakRequestRejected.vue";
import MealBreakCancelled from "./MealBreakCancelled.vue";
import MealBreakRequestApproved from "./MealBreakRequestApproved.vue";
import MealBreakError from "./MealBreakError.vue";
import MealBreakUpcoming from "./MealBreakUpcoming.vue";
import MealBreakCancel from "./MealBreakCancel.vue";
import MealBreakCancelConfirm from "./MealBreakCancelConfirm.vue";
import MealBreakInProgress from "./MealBreakInProgress.vue";
import MealBreakLate from "./MealBreakLate.vue";

export default {
	components: {
		MealBreakScheduleRequest,
		MealBreakPendingApproval,
		MealBreakRequestRejected,
		MealBreakCancelled,
		MealBreakRequestApproved,
		MealBreakError,
		MealBreakUpcoming,
		MealBreakCancel,
		MealBreakCancelConfirm,
		MealBreakInProgress,
		MealBreakLate,
	},
	computed: {
		...mapState(["BreaksManagementWidget"]),
		mealBreakComponent() {
			const mealBreakSteps = {
				[MEAL_BREAK_REQUEST]: "MealBreakScheduleRequest",
				[MEAL_BREAK_PENDING_APPROVAL]: "MealBreakPendingApproval",
				[MEAL_BREAK_REQUEST_REJECTED]: "MealBreakRequestRejected",
				[MEAL_BREAK_CANCELLED]: "MealBreakCancelled",
				[MEAL_BREAK_REQUEST_APPROVED]: "MealBreakRequestApproved",
				[MEAL_BREAK_ERROR]: "MealBreakError",
				[MEAL_BREAK_UPCOMING]: "MealBreakUpcoming",
				[MEAL_BREAK_CANCEL]: "MealBreakCancel",
				[MEAL_BREAK_CANCEL_CONFIRM]: "MealBreakCancelConfirm",
				[MEAL_BREAK_IN_PROGRESS]: "MealBreakInProgress",
				[MEAL_BREAK_LATE]: "MealBreakLate",
			};

			return mealBreakSteps[this.BreaksManagementWidget.currentBreakStep] || "";
		},
	},
};
</script>;
