export default {
	encodeRegion(region) {
		let regionCode;
		const dict = {
			AB: "Alberta",
			BC: "British Columbia",
			MB: "Manitoba",
			NB: "New Brunswick",
			NL: "Newfoundland and Labrador",
			NT: "Northwest Territories",
			NS: "Nova Scotia",
			NU: "Nunavut",
			ON: "Ontario",
			PE: "Prince Edward Island",
			QC: "Quebec",
			SK: "Saskatchewan",
			YT: "Yukon",
			AL: "Alabama",
			AK: "Alaska",
			AS: "American Samoa",
			AZ: "Arizona",
			AR: "Arkansas",
			CA: "California",
			CO: "Colorado",
			CT: "Connecticut",
			DE: "Delaware",
			DC: "District of Columbia",
			FM: "Federated States of Micronesia",
			FL: "Florida",
			GA: "Georgia",
			GU: "Guam",
			HI: "Hawaii",
			ID: "Idaho",
			IL: "Illinois",
			IN: "Indiana",
			IA: "Iowa",
			KS: "Kansas",
			KY: "Kentucky",
			LA: "Louisiana",
			ME: "Maine",
			MH: "Marshall Islands",
			MD: "Maryland",
			MA: "Massachusetts",
			MI: "Michigan",
			MN: "Minnesota",
			MS: "Mississippi",
			MO: "Missouri",
			MT: "Montana",
			NE: "Nebraska",
			NV: "Nevada",
			NH: "New Hampshire",
			NJ: "New Jersey",
			NM: "New Mexico",
			NY: "New York",
			NC: "North Carolina",
			ND: "North Dakota",
			MP: "Northern Mariana Islands",
			OH: "Ohio",
			OK: "Oklahoma",
			OR: "Oregon",
			PW: "Palau",
			PA: "Pennsylvania",
			PR: "Puerto Rico",
			RI: "Rhode Island",
			SC: "South Carolina",
			SD: "South Dakota",
			TN: "Tennessee",
			TX: "Texas",
			UT: "Utah",
			VT: "Vermont",
			VI: "Virgin Islands",
			VA: "Virginia",
			WA: "Washington",
			WV: "West Virginia",
			WI: "Wisconsin",
			WY: "Wyoming",
		};
		Object.keys(dict).forEach(function(key) {
			if (dict[key] === region) {
				regionCode = key;
			}
		});
		return regionCode;
	},

	decodeRegion(code) {
		const dict = {
			AB: "Alberta",
			BC: "British Columbia",
			MB: "Manitoba",
			NB: "New Brunswick",
			NL: "Newfoundland and Labrador",
			NT: "Northwest Territories",
			NS: "Nova Scotia",
			NU: "Nunavut",
			ON: "Ontario",
			PE: "Prince Edward Island",
			QC: "Quebec",
			SK: "Saskatchewan",
			YT: "Yukon",
			AL: "Alabama",
			AK: "Alaska",
			AS: "American Samoa",
			AZ: "Arizona",
			AR: "Arkansas",
			CA: "California",
			CO: "Colorado",
			CT: "Connecticut",
			DE: "Delaware",
			DC: "District of Columbia",
			FM: "Federated States of Micronesia",
			FL: "Florida",
			GA: "Georgia",
			GU: "Guam",
			HI: "Hawaii",
			ID: "Idaho",
			IL: "Illinois",
			IN: "Indiana",
			IA: "Iowa",
			KS: "Kansas",
			KY: "Kentucky",
			LA: "Louisiana",
			ME: "Maine",
			MH: "Marshall Islands",
			MD: "Maryland",
			MA: "Massachusetts",
			MI: "Michigan",
			MN: "Minnesota",
			MS: "Mississippi",
			MO: "Missouri",
			MT: "Montana",
			NE: "Nebraska",
			NV: "Nevada",
			NH: "New Hampshire",
			NJ: "New Jersey",
			NM: "New Mexico",
			NY: "New York",
			NC: "North Carolina",
			ND: "North Dakota",
			MP: "Northern Mariana Islands",
			OH: "Ohio",
			OK: "Oklahoma",
			OR: "Oregon",
			PW: "Palau",
			PA: "Pennsylvania",
			PR: "Puerto Rico",
			RI: "Rhode Island",
			SC: "South Carolina",
			SD: "South Dakota",
			TN: "Tennessee",
			TX: "Texas",
			UT: "Utah",
			VT: "Vermont",
			VI: "Virgin Islands",
			VA: "Virginia",
			WA: "Washington",
			WV: "West Virginia",
			WI: "Wisconsin",
			WY: "Wyoming",
		};
		return dict[code];
	},
	encodeCountry(country) {
		let countryCode;
		const dict = {
			CA: "Canada",
			US: "United States",
		};
		Object.keys(dict).forEach(function(key) {
			if (dict[key] === country) {
				countryCode = key;
			}
		});
		return countryCode;
	},

	decodeCountry(code) {
		const dict = {
			CA: "Canada",
			US: "United States",
		};
		return dict[code];
	},

	listRegions(countryCode) {
		if (countryCode === "CA") {
			return [
				"Alberta",
				"British Columbia",
				"Manitoba",
				"New Brunswick",
				"Newfoundland and Labrador",
				"Northwest Territories",
				"Nova Scotia",
				"Nunavut",
				"Ontario",
				"Prince Edward Island",
				"Quebec",
				"Saskatchewan",
				"Yukon",
			];
		}
		if (countryCode === "US") {
			return [
				"Alabama",
				"Alaska",
				"American Samoa",
				"Arizona",
				"Arkansas",
				"California",
				"Colorado",
				"Connecticut",
				"Delaware",
				"District of Columbia",
				"Federated States of Micronesia",
				"Florida",
				"Georgia",
				"Guam",
				"Hawaii",
				"Idaho",
				"Illinois",
				"Indiana",
				"Iowa",
				"Kansas",
				"Kentucky",
				"Louisiana",
				"Maine",
				"Marshall Islands",
				"Maryland",
				"Massachusetts",
				"Michigan",
				"Minnesota",
				"Mississippi",
				"Missouri",
				"Montana",
				"Nebraska",
				"Nevada",
				"New Hampshire",
				"New Jersey",
				"New Mexico",
				"New York",
				"North Carolina",
				"North Dakota",
				"Northern Mariana Islands",
				"Ohio",
				"Oklahoma",
				"Oregon",
				"Palau",
				"Pennsylvania",
				"Puerto Rico",
				"Rhode Island",
				"South Carolina",
				"South Dakota",
				"Tennessee",
				"Texas",
				"Utah",
				"Vermont",
				"Virgin Islands",
				"Virginia",
				"Washington",
				"West Virginia",
				"Wisconsin",
				"Wyoming",
			];
		}
	},
};
