var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "OrigamiCard",
    { staticClass: "tw-p-4 tw-w-80" },
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm.items.length && _vm.isExpanded
        ? _c(
            "ol",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "items",
                  expression: "'items'",
                },
              ],
              staticClass: "tw-overflow-y-auto",
              class: {
                "tw-py-4 tw-pl-2 notification__list--achievement":
                  _vm.hasTrophies,
                notification__list: !_vm.hasTrophies,
              },
            },
            _vm._l(_vm.orderedItems, function (item, index) {
              return _c(
                _vm.getItemComponent(item.type),
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "item",
                      expression: "'item'",
                    },
                  ],
                  key: index,
                  tag: "component",
                  staticClass: "tw-my-4 tw-w-full",
                  class: {
                    "activityNotification tw-mt-0":
                      !_vm.hasTrophies && !_vm.isTopItemChallenge,
                    "tw-mt-10": _vm.isTopItemChallenge,
                    notification: !_vm.isTopItemChallenge,
                  },
                  attrs: { item: item },
                },
                [_vm._v("\n\t\t\t" + _vm._s(item) + "\n\t\t")]
              )
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasTrophies
        ? _c(
            "footer",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "achievement-footer",
                  expression: "'achievement-footer'",
                },
              ],
              staticClass:
                "tw-border-t tw-border-origami-grey-200 tw-pt-4 tw-px-2",
            },
            [
              _vm.isExpanded
                ? _c(
                    "div",
                    {
                      staticClass:
                        "tw-flex tw-flex-col tw-items-stretch tw-w-full tw-gap-2 tw-text-center tw-flex-wrap",
                    },
                    [
                      !_vm.isOnAchievementCenter
                        ? _c(
                            "OrigamiButton",
                            {
                              directives: [
                                {
                                  name: "jest",
                                  rawName: "v-jest",
                                  value: "footer-cta-button",
                                  expression: "'footer-cta-button'",
                                },
                                {
                                  name: "data-cy",
                                  rawName: "v-data-cy",
                                  value: "view-achievements-button",
                                  expression: "'view-achievements-button'",
                                },
                              ],
                              staticClass: "notification__button",
                              attrs: { role: "link" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.$router.push(
                                    _vm.achievementCenterPath
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t" +
                                  _vm._s(_vm.$t("achievements.view")) +
                                  "\n\t\t\t"
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "OrigamiButton",
                        {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: "footer-close-button",
                              expression: "'footer-close-button'",
                            },
                            {
                              name: "data-cy",
                              rawName: "v-data-cy",
                              value: "footer-close-button",
                              expression: "'footer-close-button'",
                            },
                          ],
                          staticClass: "notification__button",
                          attrs: { variant: "secondary" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.$emit("close")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t" +
                              _vm._s(_vm.$t("achievements.close")) +
                              "\n\t\t\t"
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }