<template>
	<SuspensePattern
		v-jest="'suspense'"
		:is-showing-fallback="isLoadingChallenges"
	>
		<div
			v-jest="'challenges-content'"
			class="tw-text-center"
		>
			<BaseCircle
				v-jest="'content-circle-chart'"
				:progress="challengeProgress"
				:total="challengeTotal"
				:thickness="80"
				class="tw-h-32"
			>
				<Avatar
					v-jest="'circle-chart-avatar'"
					:user="userData"
					:size="80"
				/>
			</BaseCircle>
			<div class="tw-mt-1">
				<h3
					v-jest="'content-title'"
					class="tw-font-bold"
				>
					{{ i18n.t("challenges.title") }}
				</h3>
				<p
					v-jest="'content-text'"
					class="tw-text-sm tw-italic tw-mb-2"
				>
					{{ i18n.t(`challenges.time_left`, { days: remainingDays,
						days_plural: remainingDays > 1 ? "s" : "" }) }}
				</p>
			</div>
			<ul
				v-jest="'content-goals-list'"
				class="tw-flex tw-flex-col tw-items-center tw-justify-center"
			>
				<li
					v-for="(goal, index) in goals"
					:key="goal.type"
					v-jest="`goals-list-goal-${index}`"
					class="tw-flex tw-flex-row mb-1"
				>
					<p
						class="tw-text-sm tw-text-center"
					>
						<span class="tw-font-bold">{{ i18n.t("goals.of", goal) }}</span>
						<span class="tw-capitalize">
							{{ i18n.t(GOAL_TYPE_TEXT[goal.type]) }}
						</span>
					</p>
				</li>
			</ul>
		</div>
	</SuspensePattern>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { intervalToDuration } from "date-fns";

import useI18n from "@/composables/useI18n.js";
import useStore from "@/composables/useStore.js";
import Avatar from "@/modules/Avatar/components/AvatarIcon.vue";
import SuspensePattern from "@/components/patterns/SuspensePattern.vue";

import { DEFAULT_TOTAL_SEGMENTS, GOAL_TYPE_TEXT } from "../utilities/index.js";

import BaseCircle from "./BaseCircle.vue";

const i18n = useI18n();
const store = useStore();

const isLoadingChallenges = ref(false);

const userData = computed(() => ({
	...store.state.currentUser,
	...store.state.Student.Profile?.profile,
}));
const goals = computed(() => {
	return store.state.Student.Challenges?.goals;
});
const challengeProgress = computed(() => {
	if (!goals?.value) {
		return 0;
	}
	return goals.value.reduce((acc, current) => {
		return acc += current.completed > current.target ?
			current.target :
			current.completed;
	}, 0);
});
const challengeTotal = computed(() => {
	if (!goals.value?.length) {
		return DEFAULT_TOTAL_SEGMENTS;
	}
	return goals.value.reduce((acc, current) => current.target + acc, 0);
});
const remainingDays = computed(() => {
	const endDate = store.state.Student.Challenges?.challenge?.end_date;
	if (endDate) {
		const endTime = new Date(endDate / 1000);
		const timeLeft = intervalToDuration({
			start: new Date(),
			end: endTime,
		});

		return timeLeft.days;
	} else {
		return 0;
	}
});

const getChallengesData = async() => {
	try {
		isLoadingChallenges.value = true;
		await store.dispatch("Student/Challenges/getChallengesData");
	} catch (e) {
		Sentry.captureException(e);
	} finally {
		isLoadingChallenges.value = false;
	}
};
onMounted(async() => {
	await getChallengesData();
});

defineExpose({ isLoadingChallenges, goals, remainingDays });
</script>
