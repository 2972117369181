<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		viewBox="0 0 26 21"
	>
		<g
			fill="none"
			fill-rule="evenodd"
		>
			<g :fill="iconColor">
				<path
					d="M1026 242c2.21 0 4 1.79 4 4v8c0 2.21-1.79 4-4 4h-8l-6.47 4.044c-.159.1-.343.152-.53.152-.552 0-1-.448-1-1V258h-2c-2.21 0-4-1.79-4-4v-8c0-2.21 1.79-4 4-4h18zm-13.129 5H1009v6h4v-1.079h-2.922v-1.542h2.564v-1.043h-2.564v-1.274h2.793V247zm4.573 0c-.77 0-1.352.168-1.75.505-.396.337-.595.76-.595 1.27 0 .558.206.968.616 1.23.243.158.681.303 1.316.437l.645.135c.377.077.655.166.831.266.176.103.265.248.265.436 0 .322-.178.542-.534.66-.187.062-.431.093-.732.093-.502 0-.856-.118-1.063-.352-.113-.128-.189-.322-.227-.582H1015c0 .591.227 1.056.682 1.395.455.338 1.08.507 1.874.507.777 0 1.38-.172 1.805-.515.426-.344.639-.775.639-1.295 0-.506-.182-.894-.546-1.161-.234-.172-.582-.31-1.042-.413l-1.05-.235c-.406-.09-.67-.169-.795-.236-.193-.1-.29-.252-.29-.455 0-.221.099-.394.295-.517.195-.123.457-.185.785-.185.295 0 .542.047.74.143.298.144.458.387.48.729h1.224c-.022-.605-.26-1.066-.713-1.383-.453-.318-1.001-.477-1.644-.477zm6.833 0H1022v6h1.085v-2.157h1.11c.58 0 1.025-.147 1.337-.44.312-.293.468-.794.468-1.502 0-.649-.156-1.128-.468-1.437-.312-.31-.73-.464-1.255-.464zm-.167 1c.281 0 .5.075.656.226.156.15.234.402.234.756 0 .353-.078.61-.234.774-.156.163-.375.244-.656.244H1023v-2z"
					transform="translate(-1004 -242)"
				/>
			</g>
		</g>
	</svg>
</template>

<script>
import Colors from "../../utilities/Colors.js";
export default {
	props: {
		width: {
			type: [Number, String],
			default: 26,
		},
		height: {
			type: [Number, String],
			default: 21,
		},
		iconColor: {
			type: String,
			default: Colors.C_LANGUAGE_BUBBLE_YELLOW,
		},
	},
};
</script>
