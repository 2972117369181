<template>
	<div
		class="tutorCardInfo"
	>
		<BaseTutorImage
			v-jest="'tutor-image'"
			:class="{ 'tutorImage--disconnectedBorder': isDisconnectedAndPunchedIn, 'tutorCardInfo--greyedOut': isGreyedOut }"
			:profile-path="user.profile_picture"
			:full-tutor-name="user.name"
			:dimensions="56"
		/>
		<div class="tutorCardInfo__text tw-max-w-full tw-truncate">
			<div
				v-jest="'tutor-name'"
				class="tutorCardInfo__name tw-max-w-full tw-flex tw-justify-start tw-justify-between tw-items-center"
			>
				<div
					v-jest="'tutor-name-and-new-status'"
					class="tw-flex tw-truncate"
					:class="{ 'tutorCardInfo--greyedOut': isGreyedOut }"
				>
					<p class="tw-truncate">
						{{ user.name }}
					</p>
					<span
						v-if="user.is_new"
						v-jest="'new-tutor-label'"
						class="tw-text-blue-regular tw-ml-1.5"
					> (New Tutor!)</span>
				</div>
				<div class="tw-flex">
					<OrigamiIconButton
						v-if="canImpersonate"
						v-jest="'tutor-impersonate-button'"
						icon-name="impersonate"
						title="Impersonate Tutor"
						aria-label="Impersonate Tutor"
						@click.native="toggleImpersonationModal"
					/>
					<OrigamiIconButton
						v-jest="'profile-details-button'"
						icon-name="transfer"
						title="Open Tutor Quick Edit"
						aria-label="Open Tutor Quick Edit"
						@click.native="openTutorQuickEdit"
					/>
				</div>
			</div>
			<div
				v-jest="'tutor-shift-info'"
				class="tutorCardInfo__shiftInfo"
				:class="{ 'tutorCardInfo--greyedOut': isGreyedOut }"
			>
				<span
					:class="{ 'tutorCardInfo__shiftInfo--lateColor': isShiftStartRed }"
					@mouseover="showPunchInTime()"
					@mouseleave="showShiftStartTime()"
				>
					{{ tutorShiftStartTime }}</span>
				- {{ shiftEndTime }}
				<template v-if="!isExtraInfoTextEmpty">
					|
					<span
						v-jest="'extra-info-text'"
						:class="extraInfo.class"
					>
						{{ extraInfo.text }}
					</span>
				</template>
				<span
					v-if="isDisconnectedAndPunchedIn"
					class="tutorCardInfo__shiftInfo--disconnectedText"
				>Disconnected</span>
				<span
					v-if="isUpcomingTutorShift"
					class="tutorCardInfo__shiftInfo--floatRight"
				>
					{{ timeUntilShiftStart }}
				</span>
			</div>
		</div>
		<ImpersonationModal
			v-if="isImpersonationModalVisible"
			:user-email="user.email"
			token=""
			@close-modal="toggleImpersonationModal()"
		/>
	</div>
</template>
<script>
import { differenceInMinutes, differenceInHours, format } from "date-fns";
import { OrigamiIconButton } from "@origami/vue2";

import { ImpersonationModal } from "@/modules/Customers/index.js";

import BaseTutorImage from "../../../components/BaseTutorImage.vue";

export default {
	components: {
		BaseTutorImage,
		OrigamiIconButton,
		ImpersonationModal,
	},
	props: {
		user: {
			type: Object,
			required: true,
			validator(value) {
				return value.hasOwnProperty("name") && value.hasOwnProperty("profile_picture") && value.hasOwnProperty("email");
			},
		},
		shift: {
			type: Object,
			required: true,
			validator(value) {
				return (
					value.hasOwnProperty("start_time") &&
					value.hasOwnProperty("end_time") &&
					value.hasOwnProperty("punch_in")
				);
			},
		},
	},
	data() {
		return {
			isShowingPunchInTime: false,
			currentDate: new Date(),
			isImpersonationModalVisible: false,
			canImpersonate: true,
		};
	},
	computed: {
		/**
		 * @returns {Boolean}
		 */
		isDisconnectedAndPunchedIn() {
			return false;
		},
		/**
		 * @returns {Boolean}
		 */
		isGreyedOut() {
			return false;
		},
		/**
		 * @returns {Boolean}
		 */
		isExtraInfoTextEmpty() {
			return this.extraInfo.text === "";
		},
		/**
		 * @returns {Boolean}
		 */
		isShiftStartRed() {
			return false;
		},
		/**
		 * @returns {Boolean}
		 */
		isUpcomingTutorShift() {
			return false;
		},
		/**
		 * @returns {String}
		 */
		shiftStartTime() {
			return format(new Date(this.shift.start_time * 1000), "hh:mm a");
		},
		/**
		 * @returns {String}
		 */
		shiftEndTime() {
			return format(new Date(this.shift.end_time * 1000), "hh:mm a");
		},
		/**
		 * @returns {String}
		 */
		tutorShiftStartTime() {
			return "";
		},
		/**
		 * Determines which class and text to show in the shift info
		 * @returns {Object} with keys: 'class', 'text'
		 */
		extraInfo() {
			const extraInfo = {
				class: "",
				text: "",
			};
			return extraInfo;
		},
		/**
		 * @returns {String}
		 */
		timeUntilShiftStart() {
			return "";
		},
		timeElapsedSincePunchIn() {
			let timeElapsedString = "";
			let hours = 0;
			let mins = 0;
			const punchInDate = new Date(this.shift.punch_in * 1000);
			if (this.shift.punch_in !== null) {
				hours = differenceInHours(this.currentDate, punchInDate);
				mins = differenceInMinutes(this.currentDate, punchInDate);
				mins = mins % 60;
				timeElapsedString = `${hours}h ${mins}min`;
			}
			return timeElapsedString;
		},
	},
	beforeUpdate() {
		this.currentDate = new Date();
	},
	methods: {
		/**
		 * Sets the isShowingPunchInTime flag to true
		 */
		showPunchInTime() {
			this.isShowingPunchInTime = true;
		},
		/**
		 * Sets the isShowingPunchInTime flag to false
		 */
		showShiftStartTime() {
			this.isShowingPunchInTime = false;
		},
		async toggleImpersonationModal() {
			this.isImpersonationModalVisible = !this.isImpersonationModalVisible;
		},
		openTutorQuickEdit() {
			this.$store.dispatch("PlatformManager/ServiceSummaryDashboard/openTutorQuickEdit", this.user);
		},
	},
};
</script>
<style scoped>
.tutorCardInfo {
	display: flex;
	flex-direction: row;
	align-content: center;
	width: 100%;
}
.tutorCardInfo--greyedOut {
	opacity: 0.3;
}
.tutorImage.tutorImage--disconnectedBorder {
	outline: solid var(--orange-dark);
}
.tutorCardInfo__text {
	display: flex;
	flex-direction: column;
	align-self: center;
	margin-left: 12px;
	flex: 1 1 0;
}
.tutorCardInfo__name {
	font-size: 20px;
	font-weight: bold;
	line-height: normal;
	color: var(--black-high);
}
.tutorCardInfo__shiftInfo {
	cursor: default;
	font-size: 12px;
	font-weight: normal;
	line-height: 1.83;
	color: #525161;
}
.tutorCardInfo__shiftInfo--lateColor {
	color: var(--orange-dark);
}
.tutorCardInfo__shiftInfo--shiftEndingColor {
	color: var(--orange-dark);
}
.tutorCardInfo__shiftInfo--disconnectedText {
	font-style: italic;
	color: var(--orange-dark);
	margin-left: 8px;
}
.tutorCardInfo__shiftInfo--floatRight {
	float: right;
}
</style>
