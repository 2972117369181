import { upperFirst } from "lodash";
import { format } from "date-fns";
import enUS from "date-fns/locale/en-US";
import fr from "date-fns/locale/fr";
import es from "date-fns/locale/es";

import ChatIntro from "@/modules/Classroom/classes/ChatIntro.js";

import { hasRole } from "./user.js";

export const SUBJECT_ID_TRIAGE = 31;

/**
 * Computes what string to return for the section/topic/subject session information
 * @returns {String}
 */
export const sectionTopicSubjectString = (session, currentUser) => {
	if (session !== null) {
		if (session.sectionId) {
			return session.sectionName;
		} else if (session.requested_topic) {
			return "topics." + session.requested_topic.name;
		} else if (session.requested_subject && session.requested_subject.id !== SUBJECT_ID_TRIAGE) {
			return "subjects." + session.requested_subject.name;
		} else {
			return currentUser.role === "tutor"
				? "chat.chat_intro.a_question"
				: "chat.chat_intro.your_question";
		}
	} else {
		return null;
	}
};

/**
 * @param {Object} session
 * @param {Object} currentUser
 * @returns {String}
 */
export const groupCreationDate = (session, currentUser) => {
	const dateFnsLanguagesModules = {
		en: enUS,
		fr,
		es,
	};
	return upperFirst(
		format(new Date(session.created_at), "MMMM d", {
			locale: dateFnsLanguagesModules[currentUser.preferred_language],
		}),
	);
};
/**
 * @param {Object} currentSessionData
 * @param {Number} currentSessionData.id
 * @param {String} currentSessionData.type
 * @param {Array} currentSessionData.otherUsers
 * @param {String} currentSessionData.tutor
 * @param {Object} currentUser
 * @param {Number} currentUser.id
 * @param {String} currentUser.role
 * @param {String} sessionStatus
 * @returns {ChatIntro}
 */
export const getChatIntro = (currentSessionData, currentUser, sessionStatus) => {
	const statusType = upperFirst(sessionStatus);
	const sessionType = upperFirst(currentSessionData.type);
	const userRole = upperFirst(currentUser.role);
	return chatIntroHelpers[`getChatIntro${statusType}${sessionType}${userRole}`]?.(
		currentSessionData,
		currentUser,
	);
};

export const chatIntroHelpers = {
	/**
	 *
	 * @param {Object} session
	 * @param {Array} session.otherUsers
	 * @param {Object} currentUser
	 * @returns {ChatIntro}
	 */
	getChatIntroNewIndividualStudent(session, currentUser) {
		const otherUser = session.handoffs?.[0]?.outgoing_tutor ?? session.otherUsers[0];
		const subject = sectionTopicSubjectString(session, currentUser);
		return new ChatIntro({
			header: {
				props: {
					header: "chat.chat_intro.say_hi",
					firstName: otherUser.first_name,
					subHeader: {
						key: "chat.chat_intro.help_shortly",
						params: {
							subject,
						},
					},
				},
				component: "ChatIntroHeaderNewSession",
			},
			senderFirstName: otherUser.first_name,
			badge: {
				component: "BaseTutorImage",
				content: otherUser.profile_image,
			},
		});
	},
	/**
	 *
	 * @param {Object} session
	 * @param {Object} session.otherUsers
	 * @param currentUser
	 * @returns {ChatIntro}
	 */
	getChatIntroNewIndividualTutor(session, currentUser) {
		const student = session.otherUsers.find((user) => user.roles.data.find(({ name }) => name === "student"));
		const subject = sectionTopicSubjectString(session, currentUser);
		return new ChatIntro({
			header: {
				props: {
					header: "chat.chat_intro.heres_your_student",
					firstName: student.first_name,
					subHeader: {
						key: "chat.chat_intro.need_help_with",
						params: {
							name: student.first_name,
							subject,
						},
					},
				},
				component: "ChatIntroHeaderNewSession",
			},
			senderFirstName: student.first_name,
			badge: {
				component: "AvatarIcon",
				content: student,
			},
		});
	},
	/**
	 *
	 * @param {Object} session
	 * @param {Array} session.otherUsers
	 * @param currentUser
	 * @returns {ChatIntro}
	 */
	getChatIntroHandoffIndividualTutor(session, currentUser) {
		return this.getChatIntroNewIndividualTutor(session, currentUser);
	},
	/**
	 *
	 * @param {Object} session
	 * @returns {ChatIntro}
	 */
	getChatIntroNewAssignmentStudent(session) {
		const creator = session.assignment.creator;
		const subject =
			session.assignment.subjects.length === 1 ? session.assignment.subjects[0].name : "";
		return new ChatIntro({
			header: {
				component: "ChatIntroHeaderAssignment",
				props: {
					header: "chat.chat_intro.you_have_been_invited",
					projectName: session.assignment.title,
					groupName: session.name,
					subHeader: {
						key: "chat.chat_intro.by",
						params: {
							creator: `${creator.first_name[0]}. ${creator.last_name}`,
						},
					},
				},
			},
			senderFirstName: creator.first_name,
			badge: {
				component: "SectionSubjectIcon",
				content: `${upperFirst(subject)}`,
			},
		});
	},
	/**
	 *
	 * @param {Object} session
	 * @returns {ChatIntro}
	 */
	getChatIntroNewAssignmentTutor(session, currentUser) {
		const creator = session.assignment.creator;
		const subject =
			session.assignment.subjects.length === 1 ? session.assignment.subjects[0].name : "";
		const createDate = groupCreationDate(session, currentUser);
		return new ChatIntro({
			header: {
				component: "ChatIntroHeaderAssignment",
				props: {
					header: "chat.chat_intro.you_have_been_invited",
					projectName: session.assignment.title,
					groupName: session.name,
					subHeader: {
						key: "chat.chat_intro.they_have_been_working_since",
						params: {
							date: createDate,
						},
					},
				},
			},
			senderFirstName: creator.first_name,
			badge: {
				component: "SectionSubjectIcon",
				content: `${upperFirst(subject)}`,
			},
		});
	},
	/**
	 *
	 * @param {Object} session
	 * @returns {ChatIntro}
	 */
	getChatIntroHandoffIndividualStudent(session) {
		const otherUser = session.otherUsers[0];
		return new ChatIntro({
			header: {
				props: {
					header: "chat.handoff.taking_over",
					firstName: otherUser.first_name,
					subHeader: {
						key: "chat.handoff.dont_worry",
						params: {
							name: otherUser.first_name,
						},
					},
				},
				component: "ChatIntroHeaderHandoff",
			},
			senderFirstName: otherUser.first_name,
			badge: {
				component: "BaseTutorImage",
				content: otherUser.profile_image,
			},
		});
	},
	/**
	 *
	 * @param {Object} session
	 * @param {Object} session.tutor
	 * @returns {ChatIntro}
	 */
	getChatIntroInitialTutorAssignmentStudent(session) {
		return new ChatIntro({
			header: {
				props: {
					header: "chat.chat_intro.here_to_help",
					firstName: session.tutor.first_name,
					subHeaders: [
						{
							key: "chat.chat_intro.cannot_see",
							params: {},
						},
						{
							key: "chat.chat_intro.let_tutor_know",
							params: {
								name: session.tutor.first_name,
							},
						},
					],
				},
				component: "ChatIntroHeaderAssignmentInitialTutor",
			},
			senderFirstName: session.tutor.first_name,
			badge: {
				component: "BaseTutorImage",
				content: session.tutor.profile_image,
			},
		});
	},
	/**
	 *
	 * @param {Object} session
	 * @param {Object} session.tutor
	 * @returns {ChatIntro}
	 */
	getChatIntroHandoffAssignmentStudent(session) {
		const tutor = session.otherUsers.find((user) => hasRole(user, "tutor"));
		return new ChatIntro({
			header: {
				props: {
					header: "chat.handoff.taking_over",
					firstName: tutor.first_name,
					subHeader: {
						key: "chat.handoff.dont_worry",
						params: {
							name: tutor.first_name,
						},
					},
				},
				component: "ChatIntroHeaderHandoff",
			},
			senderFirstName: tutor.first_name,
			badge: {
				component: "BaseTutorImage",
				content: tutor.profile_image,
			},
		});
	},
};
