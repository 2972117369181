import Vue from "vue";
import { isEmpty, isNil } from "lodash";
import { getFlag } from "@paper-co/web-toolkit";

import helpers, { getMessageDataFromMessageClass, filterDeletedMediaMessages } from "@/utilities/SessionStoreHelpers.js";
import { hasRole } from "@/utilities/user.js";
import MessageFactory from "@/services/factories/MessageFactory.js";
import MessageReceipts from "@/services/api/MessageReceipts.js";
import SessionsAPI from "@/services/api/Sessions.js";
import MessagesAPI from "@/services/api/Messages.js";
import HandoffsAPI from "@/services/api/Handoffs.js";
import ReactionsAPI from "@/services/api/Reactions.js";

import LiveHelpTutorAssistantAPI from "../services/TutorLiveHelpAssistantAPI.js";
import MessageMedia from "../classes/MessageMedia.js";
import MessageText from "../classes/MessageText.js";
import MessageMediaEmpty from "../classes/MessageMediaEmpty.js";

const getDefaultState = () => ({
	disableChatInput: false, // not being used?
	isCollapsed: false,
	isConnected: true,
	isFileBeingUploaded: false,
	isInputAreaNeedsFocus: false,
	isLightboxOpen: false,
	isOtherUserConnected: true,
	isLoadingSessions: true,
	isLoadingMessages: false,
	isInputAreaDisabled: false,
	isUnreadOutOfView: {
		below: false,
		above: false,
	},
	isWhiteboardWillInstantiate: false,
	isWhiteboardInstantiated: false,
	newUnread: 0,
	currentSessionId: null,
	respondingUserAction: null,
	respondingUserName: null,

	classroomMessages: [],
	leftSidebarTabs: [],
	rightSidebarTabs: [],
	failedMessages: {},
	leftSidebarComponent: "",
	rightSidebarComponent: "",
	incompleteAssessmentSessions: {},
	sessions: {},
	willScrollToBottom: false,
	isShowingNewMessagesButton: false,
	isShowingScrollToBottomButton: false,
	snackbarText: null,
	availableReactions: [],
	isTeachingAssetsDrawerOpen: false,
	linkForPopupWhiteboardToLoad: null,
	openWindows: [],
	scrollToMessageId: null,
	highlightedMessageId: null,
	suggestion: null,
});

const state = getDefaultState();
export const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},

	CLEAR_WHITEBOARD_IN_SESSION(state) {
		state.sessions[state.currentSessionId].whiteboard = {
			isOpen: false,
			backgroundImageLink: null,
		};
	},

	DELETE_MULTIPLE_SESSIONS(state, { sessionIds }) {
		sessionIds.forEach((element) => {
			Vue.delete(state.sessions, element);
		});
	},

	DELETE_SESSIONS(state, payload) {
		Vue.delete(state.sessions, payload.sessionId);
	},

	EDIT_SESSION(state, payload) {
		const sessionId = payload.id;
		const key = payload.key;
		const value = payload.value;

		Vue.set(state.sessions[sessionId], key, value);
	},
	EDIT_SESSION_IN_SESSIONS(state, payload) {
		Vue.set(state.sessions[payload.sessionId], payload.key, payload.value);
	},
	SET_STUDENT_GRADE_IN_SESSION(state, payload) {
		const sessionId = payload.sessionId;
		const studentUserId = payload.studentUserId;
		const userIndex = state.sessions[sessionId].users.findIndex(
			(user) => user.id === studentUserId,
		);
		Vue.set(state.sessions[sessionId].users[userIndex], "grade", payload.grade);
	},

	INCREMENT_UNREAD_COUNT_IN_SESSION(state, payload) {
		state.sessions[payload.sessionId].unreadCount++;
	},

	INSERT_MEMBER_IN_SESSION(state, payload) {
		const user = payload.user;
		const sessionId = payload.sessionId;
		state.sessions[sessionId].users.push(user);
	},

	SET_SESSION_IN_SESSIONS(state, payload) {
		Vue.set(state.sessions, payload.session.id, payload.session);
	},
	/**
	 * @param {Object} payload
	 * @param {Number} payload.sessionId
	 */
	DELETE_MESSAGE_MEDIA_EMPTY_IN_MESSAGES_IN_SESSION(state, { sessionId }) {
		state.sessions[sessionId].messages = [...state.sessions[sessionId].messages].filter(
			(msg) => !(msg instanceof MessageMediaEmpty),
		);
	},
	PUSH_MESSAGE_IN_SESSION(state, payload) {
		state.sessions[payload.sessionId].messages.push(payload.message);
	},
	SET_LAST_MESSAGE_IN_SESSION(state, payload) {
		Vue.set(state.sessions[payload.sessionId], "lastMessage", payload.message);
	},
	SET_MESSAGE_IS_FAILED(state, { messageIndex, isFailed }) {
		const messages = state.sessions[state.currentSessionId].messages;
		messages.splice(messageIndex, 1, new MessageText({ ...messages[messageIndex], isFailed }));
	},
	ADD_FAILED_MESSAGE(state, { message, sessionId }) {
		if (!state.failedMessages[sessionId]) {
			state.failedMessages[sessionId] = [];
		}
		state.failedMessages[sessionId].push(message);
	},
	REMOVE_FAILED_MESSAGE(state, { message, sessionId }) {
		if (!state.failedMessages[sessionId]) {
			return;
		}
		state.failedMessages[sessionId] =
			state.failedMessages[sessionId].filter((currentMessage) => currentMessage !== message);
	},
	SET_ACTION_OF_OTHER_USER(state, payload) {
		state.respondingUserAction = payload.action;
		state.respondingUserName = payload.firstName;
	},

	SET_CURRENT_SESSION_ID(state, payload) {
		state.currentSessionId = payload.id;
	},

	SET_DISABLE_CHAT_INPUT_IN_SESSIONS(state, payload) {
		state.sessions[payload.sessionId].disableChatInput = payload.disableChatInput;
	},
	SET_IS_LOADED_IN_SESSIONS(state, payload) {
		state.sessions[payload.sessionId].isLoaded = payload.isLoaded;
	},
	SET_IS_UNREAD_OUT_OF_VIEW(state, payload) {
		state.isUnreadOutOfView.above = payload.above;
		state.isUnreadOutOfView.below = payload.below;
	},
	SET_IS_INPUT_AREA_NEEDS_FOCUS(state, payload) {
		state.isInputAreaNeedsFocus = payload.isNeedsFocus;
	},
	SET_IS_INPUT_AREA_DISABLED(state, payload) {
		state.isInputAreaDisabled = payload.isDisabled;
	},
	SET_IS_WHITEBOARD_WILL_INSTANTIATE(state, payload) {
		state.isWhiteboardWillInstantiate = payload.isWhiteboardWillInstantiate;
	},
	SET_IS_WHITEBOARD_INSTANTIATED(state, payload) {
		state.isWhiteboardInstantiated = payload.isWhiteboardInstantiated;
	},
	SET_IS_USER_IN_SESSION_CONNECTED(state, { sessionId, userId, is_online, isConnectionStatusWrong = false, connectionLabel = "" }) {
		const userIndex = state.sessions[sessionId]?.users?.findIndex((user) => {
			return user.id == userId;
		}) ?? -1;
		if (!state.sessions[sessionId].users[userIndex]) {
			return;
		}
		const newUser = { ...state.sessions[sessionId].users[userIndex] };
		newUser.is_online = is_online;
		newUser.isConnectionStatusWrong = isConnectionStatusWrong;
		newUser.connectionLabel = connectionLabel;
		Vue.set(state.sessions[sessionId].users, userIndex, newUser);
	},
	SET_LEFT_SIDEBAR_COMPONENT(state, payload) {
		state.leftSidebarComponent = payload.leftSidebarComponent;
		state.leftSidebarTabs.forEach((tab) => {
			tab.active = tab.component === payload.leftSidebarComponent;
		});
	},

	SET_LEFT_SIDEBAR_TABS(state, payload) {
		state.leftSidebarTabs = payload;
	},

	SET_ID_MESSAGE_IN_SESSIONS(state, { prevId, newId, sessionId }) {
		try {
			const messages = state.sessions[sessionId].messages;
			for (let i = messages.length - 1; i >= 0; i--) {
				if (messages[i].id == prevId) {
					messages[i].id = newId;
					Vue.set(state.sessions[sessionId].messages, i, messages[i]);
					break;
				}
			}
		} catch (e) {
			Sentry.captureException(e);
		}
	},

	SET_IS_LOADING_MESSAGES(state, payload) {
		state.isLoadingMessages = payload.isLoadingMessages;
	},

	SET_IS_LOADING_SESSIONS(state, payload) {
		state.isLoadingSessions = payload.isLoadingSessions;
	},
	SET_NEW_UNREAD(state, payload) {
		state.newUnread = payload.sessionId;
	},
	SET_RIGHT_SIDEBAR_COMPONENT(state, payload) {
		state.rightSidebarComponent = payload.rightSidebarComponent;
		state.rightSidebarTabs.forEach((tab) => {
			tab.active = tab.component === payload.rightSidebarComponent;
		});
	},

	SET_RIGHT_SIDEBAR_TABS(state, payload) {
		state.rightSidebarTabs = payload.rightSidebarTabs;
	},

	SET_SESSION_MESSAGES(state, payload) {
		Vue.set(state.sessions[payload.sessionId], "messages", payload.messages);
	},

	RESET_SESSION_MESSAGES(state, payload) {
		Vue.set(state.sessions[payload.sessionId], "messages", []);
	},

	SET_SESSIONS(state, payload) {
		state.sessions = payload.sessions;
	},
	SET_NEW_SESSIONS(state, { sessions }) {
		if (!state.sessions) {
			state.sessions = {};
		}
		for (const sessionId in sessions) {
			if (!state.sessions[sessionId]) {
				Vue.set(state.sessions, sessionId, sessions[sessionId]);
			}
		}
	},
	SET_SEQUENCE_POSITION_OF_LAST_MESSAGE_IN_SESSIONS(state, { sequencePosition, sessionId }) {
		try {
			const index = state.sessions[sessionId].messages.length - 1;
			if (index === -1) {
			} else if (state.sessions[sessionId].messages[index].message === undefined) {
				state.sessions[sessionId].messages[index].sequencePosition = sequencePosition;
			} else {
				state.sessions[sessionId].messages[
					index
				].message.sequencePosition = sequencePosition;
			}
		} catch (e) {
			Sentry.captureException(e);
		}
	},

	SET_SNACKBAR_TEXT(state, payload) {
		state.snackbarText = payload.text;
	},

	SET_SNACKBAR_TEXT_IN_SESSION(state, payload) {
		state.sessions[payload.sessionId]["snackbarText"] = payload.text;
	},

	SET_UNREAD_COUNT_IN_SESSION(state, payload) {
		state.sessions[payload.sessionId].unreadCount = payload.unreadCount;
	},

	SET_WHITEBOARD_IN_SESSION(state, payload) {
		state.sessions[payload.sessionId].whiteboard.data = payload.data;
	},

	SET_WHITEBOARD_BACKGROUND_IMAGE_IN_SESSION(state, payload) {
		state.sessions[state.currentSessionId].whiteboard.backgroundImageLink = payload.link;
	},

	SPLICE_SESSIONS_USERS(state, payload) {
		const userId = payload.userId;
		const sessionId = payload.sessionId;
		for (let i = 0; i < state.sessions[sessionId].users.length; i++) {
			if (state.sessions[sessionId].users[i].id === userId) {
				state.sessions[sessionId].users.splice(i, 1);
				break;
			}
		}
	},
	TOGGLE_IS_LAST_MESSAGE_IN_SESSIONS(state, { sessionId }) {
		try {
			const index = state.sessions[sessionId].messages.length - 1;
			if (state.sessions[sessionId].messages[index].message === undefined) {
				state.sessions[sessionId].messages[index].isLastMessage = false;
			} else {
				state.sessions[sessionId].messages[index].message.isLastMessage = false;
			}
		} catch (e) {
			Sentry.captureException(e);
		}
	},

	TOGGLE_IS_WHITEBOARD_OPEN_IN_SESSION(state, payload) {
		state.sessions[state.currentSessionId].whiteboard.isOpen = payload.isOpen;
	},
	TOGGLE_WILL_SCROLL_TO_BOTTOM(state) {
		state.willScrollToBottom = !state.willScrollToBottom;
	},

	TOGGLE_WILL_SHOW_SCROLL_TO_BOTTOM_BUTTON(state) {
		state.isShowingNewMessagesButton = !state.willScrollToBottom;
	},
	SHOW_SCROLL_TO_BOTTOM_BUTTON(state) {
		state.isShowingScrollToBottomButton = true;
	},
	HIDE_SCROLL_TO_BOTTOM_BUTTON(state) {
		state.isShowingScrollToBottomButton = false;
	},
	TOGGLE_SIDEBAR_COLLAPSE(state) {
		state.isCollapsed = !state.isCollapsed;
	},
	/**
	 *
	 * @param {Object} state
	 * @param {Array} availableReactions
	 */
	SET_AVAILABLE_REACTIONS(state, { availableReactions }) {
		state.availableReactions = availableReactions;
	},
	/**
	 * @param {Object} state
	 * @param {Array} userReactions
	 * @param {Array} messageIndex
	 * @param {Array} sessionId
	 */
	UPDATE_USER_REACTIONS_FOR_MESSAGE(state, { userReactions, messageIndex, sessionId }) {
		Vue.set(state.sessions[sessionId].messages[messageIndex], "userReactions", userReactions);
	},
	SET_SOLUTION_IN_SESSION(state, payload) {
		Vue.set(state.sessions[payload.sessionId], "solution", payload.solution);
	},
	SET_IS_INPUT_AREA_DISABLED(state, payload) {
		state.isInputAreaDisabled = payload.isDisabled;
	},
	SET_SESSIONS_USER_IS_MOBILE(state, payload) {
		for (let i = 0; i < state.sessions[payload.sessionId].users.length; i++) {
			if (state.sessions[payload.sessionId].users[i].id === payload.userId) {
				state.sessions[payload.sessionId].users[i].is_app_user =
					payload.isMobile;
				break;
			}
		}
	},
	SHIFT_MESSAGES_IN_SESSION(state, payload) {
		state.sessions[payload.sessionId].messages = state.sessions[
			payload.sessionId
		].messages.slice(1);
	},

	SET_IS_TEACHING_ASSETS_DRAWER_OPEN(state, isTeachingAssetsDrawerOpen) {
		state.isTeachingAssetsDrawerOpen = isTeachingAssetsDrawerOpen;
	},
	SET_LINK_FOR_POPUP_WHITEBOARD_TO_LOAD(state, payload) {
		state.linkForPopupWhiteboardToLoad = payload.linkForPopupWhiteboardToLoad;
	},
	SET_CURRENT_SESSION_WHITEBOARD_BACKGROUND(state) {
		state.sessions[state.currentSessionId].whiteboard.backgroundImageLink = state.linkForPopupWhiteboardToLoad;
	},
	ADD_OPEN_POPUP_WINDOW(state, window) {
		state.openWindows = [...state.openWindows, window];
	},
	RESET_OPEN_POPUP_WINDOWS(state) {
		state.openWindows = [];
	},
	DELETE_MESSAGE(state, payload) {
		const { sessionId, messageId } = payload;
		const message = state.sessions[sessionId].messages.find((message) => message.id === messageId);
		if (isNil(message)) {
			return;
		}

		if (!isNil(message.message)) {
			message.message.isDeleted = true;
		} else {
			message.isDeleted = true;
		}
	},
	SET_SCROLL_TO_MESSAGE_ID(state, payload) {
		state.scrollToMessageId = payload;
	},
	SET_HIGHLIGHTED_MESSAGE_ID(state, payload) {
		state.highlightedMessageId = payload;
	},
	SET_SUGGESTION(state, payload) {
		state.suggestion = payload;
	},
};

export const actions = {
	async getSuggestion({ state }, payload) {
		const response = await LiveHelpTutorAssistantAPI.getSuggestion({
			session_id: state.currentSessionId,
			type: payload.type,
		});

		return response?.data?.data;
	},

	async getAllUnreadMessagesToRead({ commit }, payload) {
		await MessageReceipts.updateAllUnreadMessagesToRead(payload.sessionId);
		commit("SET_UNREAD_COUNT_IN_SESSION", payload);
	},

	/**
	 * Removes the session from sessions object
	 * and sets the current session id to null
	 */
	deleteSession({ commit }, payload) {
		try {
			const sessionId = payload.sessionId;

			commit("SET_CURRENT_SESSION_ID", {
				id: null,
			});

			commit("DELETE_SESSIONS", {
				sessionId,
			});
		} catch (e) {
			Sentry.captureException(e);
		}
	},

	/**
	 * Get Specific Active Session
	 * @param {*} param0
	 * @param {Number} sessionId
	 */
	async getActiveSessionByIdForUser({ commit }, sessionId) {
		const response = await SessionsAPI.getActiveSessionByIdForUser(sessionId);

		const newSession = response.data;
		helpers.transformSessionForStore(newSession);
		commit("SET_SESSION_IN_SESSIONS", {
			session: newSession,
		});
		return newSession;
	},

	async loadActiveSessions({ commit }) {
		const response = await SessionsAPI.getActiveSessionsForUser();
		const sessions = response.data;
		if (isEmpty(sessions)) {
			return Promise.resolve([]);
		}

		Object.keys(sessions).forEach(function(sessionId) {
			helpers.transformSessionForStore(sessions[sessionId]);
		});
		const featureFlagUpdateNewSessionsOnly = await getFlag("pe-19664-update-new-sessions-only");
		if (featureFlagUpdateNewSessionsOnly) {
			commit("SET_NEW_SESSIONS", {
				sessions,
			});
		} else {
			commit("SET_SESSIONS", {
				sessions,
			});
		}
		return sessions;
	},
	async getActiveSessions({ state, dispatch }) {
		const sessions = await dispatch("loadActiveSessions");

		// select current session and get messages
		const firstSessionId = Object.values(sessions)?.[0]?.id;
		const sessionId = state.currentSessionId ?? firstSessionId;
		if (sessionId) {
			dispatch("setCurrentSession", sessionId);
		}

		return Object.values(sessions);
	},

	async showActiveSessionInClassroom({ commit, dispatch }, { sessionId }) {
		commit("SET_LEFT_SIDEBAR_COMPONENT", { leftSidebarComponent: "sidebar-sessions-student" });
		await dispatch("setCurrentSession", sessionId);
	},
	async showPastSessionInClassroom({ commit, dispatch }, { sessionId }) {
		commit("SET_LEFT_SIDEBAR_COMPONENT", { leftSidebarComponent: "sidebar-history" });
		await dispatch("Student/Session/selectRecentSession", sessionId, { root: true });
	},
	async showSessionInClassroom({ state, rootState, commit, dispatch }, { sessionId }) {
		// show active session that's already loaded
		if (state.sessions?.[sessionId]) {
			return dispatch("showActiveSessionInClassroom", { sessionId });
		}

		// load list of active sessions and show appropriate one, if found
		await dispatch("loadActiveSessions");
		if (state.sessions?.[sessionId]) {
			return dispatch("showActiveSessionInClassroom", { sessionId });
		}

		// check past sessions to see if the one we need is already there
		if (rootState.Student.Session.recentsSessions?.[sessionId]) {
			return dispatch("showPastSessionInClassroom", { sessionId });
		}

		// load past sessions and check whether the one we need is within the first page
		await dispatch("Student/Session/getPastSessions", undefined, { root: true });
		if (rootState.Student.Session.recentsSessions?.[sessionId]) {
			return dispatch("showPastSessionInClassroom", { sessionId });
		}

		// otherwise, explicitly load a past session that's beyond the first page
		const { numRecentSessions } = rootState.Student.Session;
		await dispatch("Student/Session/loadAndSelectNotLoadedRecentSession", sessionId, { root: true });
		commit("Student/Session/SET_NUM_RECENT_SESSIONS", { numRecentSessions }, { root: true });
	},

	/**
	 * Get all messages associated with a specific session ID and put their respective objects into the messages array.
	 *
	 * @param {Object} context
	 * @param {Function} context.commit
	 * @param {Object} context.state
	 * @param {Object} context.getters
	 * @param {Number} sessionId
	 * @returns {Promise<*>}
	 */
	async getSessionMessages({ commit, state, getters, rootState }, sessionId) {
		try {
			commit("SET_IS_LOADING_MESSAGES", {
				isLoadingMessages: true,
			});

			if (state.sessions[sessionId] !== undefined) {
				commit("SET_UNREAD_COUNT_IN_SESSION", {
					sessionId,
					unreadCount: 0,
				});
			}

			const params = { include: "user.roles" };
			const messages = await MessagesAPI.getSessionMessages(sessionId, params);
			const currentSessionData = {
				...getters.currentSession,
				otherUsers: getters.currentSessionOtherUsers,
			};

			const sessionMessagesArray = helpers.prepareMessagesArrayForStore({
				messagesArray: messages.data.data,
				session: currentSessionData,
				currentUser: rootState.currentUser,
			});
			return Promise.resolve(sessionMessagesArray.filter(Boolean));
		} catch (error) {
			Sentry.captureException(error);
		} finally {
			commit("SET_IS_LOADING_MESSAGES", {
				isLoadingMessages: false,
			});
		}
	},

	/**
	 * Sets current session to desired session and dispatches getSessionMessages action.
	 * @param {Object} context
	 * @param {Function} context.commit
	 * @param {Object} context.state
	 * @param {Function} context.dispatch
	 * @param {?number} sessionId
	 * @returns {Void}
	 */
	async setCurrentSession({ commit, state, dispatch }, sessionId) {
		const firstSessionId = parseInt(Object.keys(state.sessions)[0]);
		const currentSessionId = state.sessions.hasOwnProperty(sessionId) ? sessionId : firstSessionId;

		commit("SET_CURRENT_SESSION_ID", {
			id: currentSessionId,
		});
		const currentSession = state.sessions[currentSessionId];
		if (!currentSession) {
			return;
		}

		dispatch("setRightSidebarTabs");

		if (!isNil(currentSession.snackbarText) && !isNil(currentSessionId)) {
			commit("SET_SNACKBAR_TEXT", {
				text: currentSession.snackbarText,
			});

			commit("SET_SNACKBAR_TEXT_IN_SESSION", {
				sessionId: currentSessionId,
				text: null,
			});
		}

		commit("SET_IS_INPUT_AREA_NEEDS_FOCUS", {
			isNeedsFocus: true,
		});

		if (!currentSession.isLoaded) {
			commit("RESET_SESSION_MESSAGES", {
				sessionId: currentSessionId,
			});
			await dispatch("setSessionMessages", currentSession);
		}
	},

	/**
	 *
	 * @param commit
	 * @param getters
	 * @param state
	 * @returns {void}
	 */
	async setRightSidebarTabs({ commit, getters, rootState }) {
		const rightSidebarTabs = [
			{
				name: "shared_files",
				active: true,
				component: "sidebar-shared-files-list",
			},
		];
		if (getters.currentSession.type === "assignment") {
			rightSidebarTabs[0].active = false;
			rightSidebarTabs.unshift({
				name: "group_members",
				active: true,
				component: "group-session-member-list",
			});
		} else if (
			getters.currentSession.type === "individual" &&
			rootState.currentUser.role === "tutor"
		) {
			rightSidebarTabs[0].active = false;
			rightSidebarTabs.unshift({
				name: "student_profile",
				active: true,
				component: "student-profile",
			});
		}
		commit("SET_RIGHT_SIDEBAR_TABS", {
			rightSidebarTabs,
		});
		commit("SET_RIGHT_SIDEBAR_COMPONENT", {
			rightSidebarComponent: rightSidebarTabs[0].component,
		});
		commit("SET_IS_INPUT_AREA_NEEDS_FOCUS", {
			isNeedsFocus: true,
		});
	},

	async setSessionMessages({ state, commit, dispatch }, session) {
		commit("SET_IS_LOADED_IN_SESSIONS", {
			sessionId: session.id,
			isLoaded: true,
		});

		const failedMessages = state.failedMessages[session.id] || [];
		const response = await dispatch("getSessionMessages", session.id);

		const combinedMessages = response.concat(failedMessages);

		commit("SET_SESSION_MESSAGES", {
			messages: combinedMessages,
			sessionId: session.id,
		});
	},

	/**
	 * Appends new message to sessions.messages array
	 *
	 * @param {Object} context
	 * @param {Function} context.commit
	 * @param {Object} context.state
	 *
	 * @param {Object} payload
	 */
	appendMessage({ commit, state }, payload) {
		const sessionMessagesLength = state.sessions[payload.data.sessionId].messages.length;
		payload.lastMessageInSession =
			sessionMessagesLength > 0
				? state.sessions[payload.data.sessionId].messages[sessionMessagesLength - 1]
				: null;

		if (payload.lastMessageInSession !== null) {
			helpers.updateLastSessionMessageInStore(commit, payload);
		}
		const message = helpers.getMessageFactoryMessage(payload);
		message["html"] = payload.data.message_html;
		message["json"] = payload.data.message_json;
		commit("PUSH_MESSAGE_IN_SESSION", {
			sessionId: payload.data.sessionId,
			message,
		});
	},

	async setMessageFailed({ commit, state }, { messageId, isFailed }) {
		const messages = state.sessions[state.currentSessionId].messages;
		const messageIndex = messages.findIndex((message) => message.id === messageId);
		if (messageIndex > -1) {
			commit("SET_MESSAGE_IS_FAILED", { isFailed, messageIndex });
			const payload = { sessionId: state.currentSessionId, message: messages[messageIndex] };
			if (isFailed) {
				commit("ADD_FAILED_MESSAGE", payload);
			} else {
				commit("REMOVE_FAILED_MESSAGE", payload);
			}
		}
	},

	/**
	 *
	 * Creates a system message with the text
	 * and pushes it to the session's messages
	 *
	 * Received payload should have the
	 * message and the session ID
	 *
	 * @param {Object} context
	 * @param {Function} context.commit
	 * @param {Object}  payload
	 */
	async appendSystemMessage({ commit }, payload) {
		const data = {
			...payload,
			type: "system",
		};
		const message = MessageFactory.create(data);
		commit("PUSH_MESSAGE_IN_SESSION", {
			message: message,
			sessionId: payload.sessionId,
		});
	},

	async createHandoff({ }, payload) {
		return HandoffsAPI.create(payload);
	},

	async createHandoffV1({ }, payload) {
		return HandoffsAPI.createV1(payload);
	},

	/**
	 *
	 * @param {Object} context
	 * @param context.commit
	 * @param context.state
	 */
	async openWhiteboard({ state, commit, rootState }) {
		if (!state.isOrientation) {
			this._vm.$socket.emit("responding", {
				room: `session.${state.currentSessionId}`,
				sessionId: state.currentSessionId,
				senderId: rootState.currentUser.id,
				action: "chat.messages.is_drawing",
				name: rootState.currentUser.firstName,
			});
		}
		if (!state.isWhiteboardWillInstantiate) {
			commit("SET_IS_WHITEBOARD_WILL_INSTANTIATE", {
				isWhiteboardWillInstantiate: true,
			});
		}
		commit("TOGGLE_IS_WHITEBOARD_OPEN_IN_SESSION", {
			isOpen: true,
		});

		if (!state.isCollapsed) {
			commit("TOGGLE_SIDEBAR_COLLAPSE");
		}
	},

	/**
	 *
	 * @param {Object} context
	 * @param context.commit
	 * @param context.state
	 */
	async closeWhiteboard({ commit, state, rootState }) {
		commit("TOGGLE_IS_WHITEBOARD_OPEN_IN_SESSION", {
			isOpen: false,
		});

		if (state.isCollapsed) {
			commit("TOGGLE_SIDEBAR_COLLAPSE");
		}
		this._vm.$socket.emit("responding", {
			room: `session.${state.currentSessionId}`,
			sessionId: state.currentSessionId,
			senderId: rootState.currentUser.id,
			action: "stopped_typing",
		});
	},

	async getAvailableReactions({ commit }) {
		const reactionsVersion = await getFlag("PE-19266-emoji-reactions-V2") ? "listV1" : "list";
		const response = await ReactionsAPI[reactionsVersion]();
		commit("SET_AVAILABLE_REACTIONS", {
			availableReactions: response.data.data,
		});
	},

	/**
	 * @param {Array} payload.userReactions
	 * @param {Number} payload.messageId
	 * @param {Number} payload.sessionId
	 */
	async setUserReactionsForMessage({ commit, state, rootState }, {
		userReactions,
		messageId,
		sessionId,
	}) {
		const session = state.sessions[sessionId];
		const messageIndex = session.messages.findIndex(({ id }) => id === messageId);
		const reactionsSelectedByCurrentUser = userReactions.filter(
			({ user_id }) => rootState.currentUser.id === user_id,
		);
		const reactionIds = reactionsSelectedByCurrentUser.map(({ reaction_id }) => reaction_id);
		commit("UPDATE_USER_REACTIONS_FOR_MESSAGE", {
			sessionId: session.id,
			messageIndex,
			userReactions,
		});
		await MessagesAPI.update(messageId, {
			reaction_ids: reactionIds,
		});

		if (rootState.Bookmark?.bookmarks?.[sessionId]?.messages.find(({ id }) => id === messageId)) {
			commit("Bookmark/UPDATE_REACTIONS_BOOKMARK_MESSAGE", {
				sessionId,
				messageId,
				userReactions,
			}, { root: true });
		}
	},
	/**
	 *
	 * @param context.commit
	 */
	addOpenPopupWindow({ commit }, window) {
		commit("ADD_OPEN_POPUP_WINDOW", window);
	},
	closeAllPopupWindows({ commit }) {
		state.openWindows.forEach((win) => {
			win.close();
		});
		commit("RESET_OPEN_POPUP_WINDOWS");
	},
	deleteMessage({ commit }, payload) {
		commit("DELETE_MESSAGE", payload);
	},
	setScrollToMessage({ commit }, messageId) {
		commit("SET_SCROLL_TO_MESSAGE_ID", messageId);
	},
};

export const getters = {
	currentSession(state, getters, rootState) {
		const { sessions, currentSessionId } = state;
		const { currentUser, Student, Tutor } = rootState;
		const recentSessions = Student?.Session?.recentSessions;
		const assessments = Tutor?.Session?.assessments;
		let currentSession = null;

		if (sessions[currentSessionId]) {
			currentSession = sessions[currentSessionId];
		} else if (hasRole(currentUser, "student") && recentSessions?.[currentSessionId]) {
			currentSession = recentSessions[currentSessionId];
		} else if (!hasRole(currentUser, "student") && assessments?.[currentSessionId]) {
			currentSession = assessments[currentSessionId];
		}
		return currentSession;
	},

	isAssessment(state, getters, rootState) {
		if (rootState.currentUser.roles[0].name === "student") {
			return false;
		} else {
			return (
				!isNil(state.currentSessionId) &&
				!isNil(rootState.Tutor.Session.assessments?.[state.currentSessionId]) &&
				!isNil(rootState.Tutor.Session.assessments?.[state.currentSessionId].assessment)
			);
		}
	},

	sessionUsers(state, getters) {
		return !isNil(getters.currentSession) ? getters.currentSession.users : [];
	},

	currentSessionMessages(state, getters) {
		return !isNil(getters.currentSession) ? getters.currentSession.messages : [];
	},

	currentSessionOtherUsers: (state, getters, rootState) => {
		return !isNil(getters.currentSession)
			? getters.currentSession.users.filter((user) => user.id !== rootState.currentUser.id)
			: [];
	},

	currentSessionTaggedQuestions(state, getters) {
		let result = [];
		if (!isEmpty(getters.currentSessionMessages)) {
			result = getters.currentSessionMessages.filter(function(message) {
				const messageData = getMessageDataFromMessageClass(message);
				if (Boolean(messageData?.isQuestion)) {
					return messageData;
				}
			});
		}
		return result;
	},

	currentSessionSharedFiles(state, getters) {
		let result = [];
		if (!isEmpty(getters.currentSessionMessages)) {
			result = getters.currentSessionMessages.filter(function(message) {
				const messageData = getMessageDataFromMessageClass(message);
				return messageData instanceof MessageMedia && ["image", "file", "video"].includes(messageData.mediaType);
			});
		}
		return filterDeletedMediaMessages(result);
	},

	isTutor(state, _, rootState) {
		return !!rootState.currentUser.roles.find((role) => role === "tutor");
	},

	isWhiteboardOpen(state, getters) {
		return !isNil(getters.currentSession) && getters.currentSession.whiteboard.isOpen;
	},

	whiteboard(state, getters) {
		return !isNil(getters.currentSession) ? getters.currentSession.whiteboard : null;
	},

	whiteboardBackgroundImageLink(state, getters) {
		return !isNil(getters.whiteboard) ? getters.whiteboard.backgroundImageLink : null;
	},

	individualSessions: (state) =>
		Object.filter(state.sessions, (session) => session.type === "individual"),

	currentStudentFirstName(state, getters) {
		return getters.sessionUsers
			.find((user) => user.role === "student")
			?.first_name;
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
};
