var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "tw-flex tw-justify-between tw-items-center tw-w-full tw-border-b-2 tw-h-20",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "tw-text-base tw-break-word tw-leading-6 tw-tracking-wide tw-mr-8 tw-overflow-ellipsis tw-overflow-hidden",
        },
        [_vm._t("content")],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tw-mr-0 tw-flex tw-flex-row" },
        [_vm._t("actions")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }