var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "tw-shadow-elevation-4": !_vm.isFlat } },
    [
      _vm.displayHeader
        ? _c("div", [
            _c(
              "div",
              { staticClass: "tw-flex tw-justify-between tw-items-center" },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "tw-font-bold tw-text-lg tw-pl-6 tw-pr-4 tw-py-4",
                  },
                  [_vm._v("\n\t\t\t\tEdit Shift\n\t\t\t")]
                ),
                _vm._v(" "),
                !_vm.updatedShift
                  ? _c(
                      "OrigamiButton",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "extend-shift-button",
                            expression: "'extend-shift-button'",
                          },
                        ],
                        staticClass: "tw-mr-2",
                        attrs: {
                          "data-cy": "extend-shift-button",
                          variant: "tertiary",
                          "is-disabled": !_vm.canExtendShift,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.startExtendProcess()
                          },
                        },
                      },
                      [_vm._v("\n\t\t\t\tExtend Shift\n\t\t\t")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("hr"),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.updatedShift
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "tw-flex tw-items-center tw-px-6 tw-py-0" },
                [
                  _c("ServiceScheduleTutorShiftForm", {
                    attrs: {
                      placeholder: "Select a shift",
                      "selected-tutor": _vm.selectedTutor,
                      "is-editing": _vm.isShiftSelected,
                      shift: _vm.shift,
                      value: _vm.dates,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "tw-flex tw-justify-between tw-pb-4 tw-pt-0 tw-px-6",
                },
                [
                  _c(
                    "OrigamiButton",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "cancel-btn",
                          expression: "'cancel-btn'",
                        },
                        {
                          name: "data-cy",
                          rawName: "v-data-cy",
                          value: "cancel-btn",
                          expression: "'cancel-btn'",
                        },
                      ],
                      attrs: { variant: "secondary" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.cancel()
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\tCancel\n\t\t\t")]
                  ),
                  _vm._v(" "),
                  _c(
                    "OrigamiButton",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "save-shift-btn",
                          expression: "'save-shift-btn'",
                        },
                      ],
                      attrs: {
                        "data-cy": "save-shift-btn",
                        variant: "primary",
                        "is-disabled": !_vm.isValidShiftEdit,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.updateShift()
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\tSave\n\t\t\t")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.hasAnError
                ? _c("ServiceScheduleTutorErrorMessage", {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "error-message",
                        expression: "'error-message'",
                      },
                    ],
                    staticClass: "tw--mb-16",
                    attrs: { "data-cy": "error-message", error: _vm.error },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tw-flex tw-pb-4 tw-px-6 tw-pt-10" },
                [
                  _c(
                    "OrigamiButton",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "remove-btn",
                          expression: "'remove-btn'",
                        },
                        {
                          name: "data-cy",
                          rawName: "v-data-cy",
                          value: "remove-btn",
                          expression: "'remove-btn'",
                        },
                      ],
                      staticClass: "tw-w-full tw-justify-center",
                      attrs: { variant: "destructive" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.startDeleteProcess()
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\tRemove Shift\n\t\t\t")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("ServiceScheduleTutorSuccessfulAction", {
            attrs: {
              shift: _vm.shift,
              "updated-shift": _vm.updatedShift,
              "success-message-in-sidebar": {
                message: "You have successfully edited the shift.",
                buttonText: "Complete",
              },
            },
            on: {
              "cancel-edit": function ($event) {
                return _vm.cancel()
              },
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }