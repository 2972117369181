export default {
	en: {
		whiteboard: {
			cancel: "Cancel",
			clear: "Clear whiteboard",
			close: "Close whiteboard",
			sending: "Sending...",
			load_error: "The whiteboard was unable to be loaded, please close the window and try again.",
			record: "Record whiteboard",
			stop_recording: "Stop recording",
			pause_recording: "Pause recording",
			resume_recording: "Resume recording",
			send_recording: "Send recording",
			delete_recording: "Delete recording",
			mute_microphone: "Mute microphone",
			unmute_microphone: "Unmute microphone",
			move_menu: "Move menu",
			done: "Done",
			cancel_recording: "Cancel recording",
			click_to_pause: "Click anywhere to pause recording",
			now_recording: "Now recording the whiteboard. No audio is recorded.",
			now_recording_with_audio: "Now recording the whiteboard.",
			now_paused: "Whiteboard recording is paused",
			delete_dialog_title: "Do you want to delete your recording?",
			delete_dialog_description: "Your current whiteboard recording will be lost, and you will need to record again.",
			title_preview_modal: "Preview whiteboard recording",
			close_dialog_title: "Do you want to close the whiteboard?",
			close_dialog_description: "Your current whiteboard drawing and recording will be lost. This cannot be undone.",
			title_shared_file_modal: "Whiteboard recording",
			error: {
				microphone_permission_title: "We do not have permission to record your audio.",
				microphone_permission_subtitle: "If you would like to record your audio, <a class=\"tw-underline\"href=\"https://support.paper.co/hc/en-us/sections/14630807848333-Audio-Settings\" target=\"_blank\"><u>you must update your audio settings.</u></a>",
				audio_permissions_denied: "🚫 Audio permissions denied",
			},
		},
	},
	fr: {
		whiteboard: {
			clear: "Tout effacer",
			close: "Fermer le tableau blanc",
			sending: "Envoye...",
			load_error: "Le tableau blanc n'a pas pu être chargé. Veuillez fermer la fenêtre et réessayer.",
			record: "Enregistrer le tableau blanc",
			stop_recording: "Arrêter l'enregistrement",
			pause_recording: "Pause de l'enregistrement",
			resume_recording: "Reprendre l'enregistrement",
			send_recording: "Envoyer l'enregistrement",
			delete_recording: "Effacer l'enregistrement",
			mute_microphone: "Couper le microphone",
			unmute_microphone: "Rétablir le microphone",
			move_menu: "Déplacer le menu",
			done: "Fait",
			cancel_recording: "Annuler l'enregistrement",
			click_to_pause: "Cliquez n'importe où pour interrompre l'enregistrement",
			now_recording: "Enregistrez maintenant le tableau blanc. Aucun son n'est enregistré.",
			now_recording_with_audio: "Enregistrement en cours du tableau blanc.",
			now_paused: "L'enregistrement du tableau blanc est en pause",
			delete_dialog_title: "Voulez-vous supprimer votre enregistrement ?",
			delete_dialog_description: "Votre enregistrement actuel sur le tableau blanc sera perdu et vous devrez l'enregistrer à nouveau.",
			title_preview_modal: "Aperçu de l'enregistrement du tableau blanc",
			close_dialog_title: "Voulez-vous fermer le tableau blanc ?",
			close_dialog_description: "Votre dessin et enregistrement actuels sur le tableau blanc seront perdus. Ça ne peut pas être annulé.",
			title_shared_file_modal: "Enregistrement du tableau blanc",
			error: {
				microphone_permission_title: "Nous n'avons pas la permission d'enregistrer votre audio.",
				microphone_permission_subtitle: "Si vous souhaitez enregistrer votre audio, <a class=\"tw-underline\"href=\"https://support.paper.co/hc/en-us/sections/14630807848333-Audio-Settings\" target=\"_blank\"><u>vous devez mettre à jour vos paramètres audio.</u></a>",
				audio_permissions_denied: "🚫 Autorisations audio refusées",
			},
		},
	},
	es: {
		whiteboard: {
			clear: "Borrar",
			close: "Cerrar la pizarra",
			sending: "Enviando...",
			load_error: "No se ha podido cargar la pizarra, por favor cierre la ventana e inténtelo de nuevo.",
			record: "Grabar la pizarra",
			stop_recording: "Detener grabación",
			pause_recording: "Pausar grabación",
			resume_recording: "Reanudar grabación",
			send_recording: "Enviar grabación",
			delete_recording: "Eliminar grabación",
			mute_microphone: "Silenciar micrófono",
			unmute_microphone: "Activar micrófono",
			move_menu: "Mover el menú",
			done: "Listo",
			cancel_recording: "Cancelar grabación",
			click_to_pause: "Haz clic en cualquier lugar para pausar la grabación",
			now_recording: "El audio no está siendo grabado",
			now_recording_with_audio: "Grabando la pizarra",
			now_paused: "La grabación de la pizarra está en pausa",
			delete_dialog_title: "¿Quieres eliminar tu grabación?",
			delete_dialog_description: "Se perderá la grabación actual y tendrás que volver a grabar",
			title_preview_modal: "Vista previa de la grabación de la pizarra",
			close_dialog_title: "¿Quieres cerrar la pizarra?",
			close_dialog_description: "Se perderán tus dibujos y grabaciones actuales. Ésto no se puede deshacer.",
			title_shared_file_modal: "Grabación de la pizarra",
			error: {
				microphone_permission_title: "No tenemos permiso para grabar tu audio",
				microphone_permission_subtitle: "Si deseas grabar tu audio, <a class=\"tw-underline\"href=\"https://support.paper.co/hc/en-us/sections/14630807848333-Audio-Settings\" target=\"_blank\"><u>debes actualizar tu configuración de audio.</u></a>",
				audio_permissions_denied: "🚫 Permisos de audio denegados",
			},
		},
	},

};
