<template>
	<div class="serviceDashboard">
		<ServiceDashboardSection
			v-if="PlatformManager.ServiceSummaryDashboard.summary"
			section-title="At a Glance"
		>
			<template #titleButtons>
				<BasePill
					v-data-cy="'essay-review-24hour-toggle'"
					class="summaryPeriodToggle"
					:background-color="isSummaryPeriodToday ? 'transparent' : $root.$colors.C_BLACK"
					:text-color="
						isSummaryPeriodToday ? $root.$colors.C_BLACK : $root.$colors.C_WHITE
					"
					@click.native="setSummaryPeriod('24hour')"
				>
					<slot>Last 24h</slot>
				</BasePill>
				<BasePill
					v-data-cy="'essay-review-today-toggle'"
					class="summaryPeriodToggle"
					:background-color="isSummaryPeriodToday ? $root.$colors.C_BLACK : 'transparent'"
					:text-color="
						isSummaryPeriodToday ? $root.$colors.C_WHITE : $root.$colors.C_BLACK
					"
					@click.native="setSummaryPeriod('today')"
				>
					<slot>Today (EST)</slot>
				</BasePill>
			</template>
			<slot>
				<ServiceDashboardSummaryCardLiveHelp class="tw-px-6 tw-py-4 tw-w-[calc(50%-16px)] tw-m-2 tw-min-h-[50px] tw-bg-origami-white" />
				<ServiceDashboardSummaryCardEssayReview class="tw-px-6 tw-py-4 tw-w-[calc(50%-16px)] tw-m-2 tw-min-h-[50px] tw-bg-origami-white" />
			</slot>
		</ServiceDashboardSection>
		<ServiceDashboardSessionLoad
			v-jest="'service-dashboard-tutor-load-graph'"
		/>
		<ServiceDashboardSection
			v-for="section in sections"
			:key="section.title"
			v-jest="section.testId"
			:section-title="section.title"
			:cards="section.cards"
		>
			<slot v-if="section.isLoading">
				<div
					v-for="num in [1, 2, 3, 4]"
					:key="num"
					class="card"
				>
					<ServiceDashboardTutorCardSkeleton :section-title="section.title" />
				</div>
			</slot>
			<slot v-else-if="checkIfSectionEmpty(section)">
				<ServiceDashboardSectionEmptyState :section-title="section.title" />
			</slot>
			<slot v-else>
				<component
					:is="section.component"
					v-for="card in section.cards"
					:key="card.user.id"
					class="card"
					:card-data="card"
				/>
			</slot>
		</ServiceDashboardSection>
		<TutorQuickEdit v-jest="'service-dashboard-profile-details-drilldown'" />
	</div>
</template>

<script>
import { mapState } from "vuex";
import { isEmpty } from "lodash";

import BasePill from "../../../components/BasePill.vue";

import ServiceDashboardSection from "./ServiceDashboardSection.vue";
import ServiceDashboardSessionLoad from "./ServiceDashboardSessionLoad.vue";
import ServiceDashboardSummaryCardEssayReview from "./ServiceDashboardSummaryCardEssayReview.vue";
import ServiceDashboardSummaryCardLiveHelp from "./ServiceDashboardSummaryCardLiveHelp.vue";
import ServiceDashboardTutorManagerCard from "./ServiceDashboardTutorManagerCard.vue";
import ServiceDashboardLiveTutorCard from "./ServiceDashboardLiveTutorCard.vue";
import ServiceDashboardLateTutorCard from "./ServiceDashboardLateTutorCard.vue";
import ServiceDashboardEssayTutorCard from "./ServiceDashboardEssayTutorCard.vue";
import ServiceDashboardUpcomingTutorCard from "./ServiceDashboardUpcomingTutorCard.vue";
import ServiceDashboardTutorCardSkeleton from "./ServiceDashboardTutorCardSkeleton.vue";
import ServiceDashboardTutorCardHeaderSkeleton from "./ServiceDashboardTutorCardHeaderSkeleton.vue";
import ServiceDashboardListSkeleton from "./ServiceDashboardListSkeleton.vue";
import ServiceDashboardSectionEmptyState from "./ServiceDashboardSectionEmptyState.vue";
import TutorQuickEdit from "./TutorQuickEdit/TutorQuickEdit.vue";

export default {
	components: {
		BasePill,
		ServiceDashboardSection,
		ServiceDashboardSummaryCardEssayReview,
		ServiceDashboardSummaryCardLiveHelp,
		ServiceDashboardTutorManagerCard,
		ServiceDashboardLiveTutorCard,
		ServiceDashboardLateTutorCard,
		ServiceDashboardEssayTutorCard,
		ServiceDashboardTutorCardHeaderSkeleton,
		ServiceDashboardListSkeleton,
		ServiceDashboardUpcomingTutorCard,
		ServiceDashboardTutorCardSkeleton,
		ServiceDashboardSectionEmptyState,
		ServiceDashboardSessionLoad,
		TutorQuickEdit,
	},
	computed: {
		...mapState(["PlatformManager"]),
		isSummaryPeriodToday() {
			return this.PlatformManager.ServiceSummaryDashboard.summaryPeriod === "today";
		},
		/**
		 * @returns {Array}
		 */
		sections() {
			return [
				{
					title: "Platform Managers",
					component: "ServiceDashboardTutorManagerCard",
					isLoading: this.$store.getters["PlatformManager/ServiceSummaryDashboard/isLoadingTutorManagers"],
					cards: this.PlatformManager.ServiceSummaryDashboard.tutorManagers,
					testId: "service-dashboard-platform-managers",
				},
				{
					title: "Late Tutors",
					component: "ServiceDashboardLateTutorCard",
					isLoading: this.$store.getters["PlatformManager/ServiceSummaryDashboard/isLoadingLiveTutors"],
					cards: this.$store.getters["PlatformManager/ServiceSummaryDashboard/lateTutors"],
					testId: "service-dashboard-late-tutors",
				},
				{
					title: "Live Tutors",
					component: "ServiceDashboardLiveTutorCard",
					isLoading: this.$store.getters["PlatformManager/ServiceSummaryDashboard/isLoadingLiveTutors"],
					cards: this.PlatformManager.ServiceSummaryDashboard.liveTutors,
					testId: "service-dashboard-live-tutors",
				},
				{
					title: "Review Center Tutors",
					component: "ServiceDashboardEssayTutorCard",
					isLoading: this.$store.getters["PlatformManager/ServiceSummaryDashboard/isLoadingEssayTutors"],
					cards: this.PlatformManager.ServiceSummaryDashboard.essayTutors,
					testId: "service-dashboard-review-tutors",
				},
				{
					title: "Upcoming Tutors",
					component: "ServiceDashboardUpcomingTutorCard",
					isLoading: this.$store.getters["PlatformManager/ServiceSummaryDashboard/isLoadingUpcomingTutors"],
					cards: this.PlatformManager.ServiceSummaryDashboard.upcomingTutors,
					testId: "service-dashboard-upcoming-tutors",
				},
			];
		},
	},
	methods: {
		/**
		 * @param {Object} section
		 * @returns {Boolean}
		 */
		checkIfSectionEmpty(section) {
			return isEmpty(section.cards);
		},
		setSummaryPeriod(period) {
			this.$store.commit("PlatformManager/ServiceSummaryDashboard/SET_SUMMARY_PERIOD", { period });
		},
	},
};
</script>

<style scoped>
.serviceDashboard {
	background: var(--c-lighter-gray);
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 48px;
}
.card {
	width: calc(50% - 16px);
	min-height: 50px;
	background: #ffffff;
	margin: 8px;
	padding: 12px 16px 16px;
}
.summaryPeriodToggle {
	border: var(--c-black) 2px solid;
	cursor: pointer;
}
@media (max-width: 940px) {
	.card {
		width: calc(100% - 16px);
	}
}
</style>
