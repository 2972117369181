var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "BaseDropdown",
        {
          directives: [
            {
              name: "data-cy",
              rawName: "v-data-cy",
              value: "break-time-blocks-dropdown",
              expression: "'break-time-blocks-dropdown'",
            },
          ],
          staticClass: "timeBlocks__dropdown",
          attrs: {
            "close-on-select": true,
            items: _vm.timeBlocks,
            "show-search-bar": false,
          },
          on: { select: _vm.selectTimeBlock },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ item, index }) {
                return [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "break-request-timeblocks",
                          expression: "'break-request-timeblocks'",
                        },
                      ],
                      staticClass: "tw-flex tw-justify-between tw-mx-2",
                      class: { "tw-mt-2": item.id === 0 },
                    },
                    [
                      _c(
                        "span",
                        {
                          key: `timeBlock-${index}`,
                          staticClass: "tw-font-bold",
                        },
                        [_vm._v(_vm._s(_vm.setPrefix(item, index)))]
                      ),
                      _vm._v(
                        " " + _vm._s(_vm.formattedTime(item)) + "\n\t\t\t"
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "filters" }, [
            _vm._v(
              "\n\t\t\t" + _vm._s(_vm.displaySelectedTimeBlock) + "\n\t\t"
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }