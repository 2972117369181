export default [
	{
		path: "students",
		component: () => import("../pages/administrator/index.vue"),
		children: [
			{
				path: "",
				name: "administratorStudents",
				component: () => import("../pages/administrator/list.vue"),
			},
			{
				path: ":studentId",
				name: "administratorStudentsProfile",
				component: () => import("../pages/administrator/_studentId.vue"),
			},
		],
	},
];
