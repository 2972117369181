<template>
	<button
		type="button"
		:class="{ active, readonly }"
		@click="!readonly && $emit('click', $event)"
	>
		<slot />
	</button>
</template>

<script>
export default {
	name: "BaseIconButton",
	props: {
		active: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style scoped>
button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	width: 2.5rem;
	height: 2.5rem;
	appearance: none;
	background: none;
	vertical-align: middle;
	padding: 0;
	border: 0;
	border-radius: 2.5rem;
	margin: 0;
	outline: 0;
}
button:focus-visible {
	outline: 2px solid var(--origami-blue-200);
}
button:not(.readonly):hover,
button:not(.readonly):focus,
button:not(.readonly):active,
button:not(.readonly).active {
	color: var(--c-blue);
}
button.readonly {
	cursor: inherit;
}
button svg {
	margin: 0;
}
</style>
