<template>
	<div class="tw-flex-shrink-0 tw-w-36">
		<BaseDropdown
			v-jest="'action-dropdown'"
			:v-model="actionItemTypeId"
			:items="filteredActions"
			close-on-select
			class="action__dropdown"
			@select="handleAction"
		>
			<template #filters>
				<span
					v-if="isActionPending"
					class="tw-flex tw-justify-center tw-items-center"
				>
					<LoadingRectangles />
				</span>
				<span
					v-else
					class="tw-flex tw-justify-start tw-items-center"
				>
					<OrigamiIcon
						v-if="actionItem.picked_up_at && actionItem.action_type_id"
						:name="actions[getActionTypeIndex(actionItemTypeId)].icon"
						width="20"
						height="20"
						class="tw-mr-3.5"
						:class="actions[getActionTypeIndex(actionItemTypeId)].color"
					/>
					{{ actionName }}
				</span>
			</template>
			<template #default="{ item }">
				<div class="tw-flex tw-justify-between tw-items-center">
					<label
						:for="`actionItem-${actionItemId}-${item.name}`"
						class="tw-flex tw-items-center tw-flex-grow tw-text-xs tw--mb-0 tw-px-2 tw-truncate tw-cursor-pointer"
					>
						<OrigamiIcon
							:name="item.icon"
							width="20"
							height="20"
							class="tw-mr-3.5"
							:class="item.color"
						/>
						{{ item.name }}
					</label>
					<input
						:id="`actionItem-${actionItemId}-${item.name}`"
						v-jest="'action'"
						:value="item.id"
						class="sr-only tw-cursor-pointer"
						type="button"
					>
					<IconCheckMark
						v-if="isItemSelected(item.id)"
						:width="16"
						:height="16"
						icon-color="currentColor"
						class="tw-text-blue-regular tw-mr-2 tw-cursor-pointer"
					/>
				</div>
			</template>
		</BaseDropdown>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { OrigamiIcon } from "@origami/vue2";

import { INSPECT_ACTION_ID, getActionTypeName, getActionTypeIndex } from "@/modules/ActionCenter/index.js";

import IconCheckMark from "../../../components/icons/IconCheckMark.vue";
import LoadingRectangles from "../../../components/LoadingRectangles.vue";
import BaseDropdown from "../../../components/BaseDropdown.vue";


export default {
	components: {
		IconCheckMark,
		LoadingRectangles,
		OrigamiIcon,
		BaseDropdown,
	},
	props: {
		actionItem: {
			type: Object,
			required: true,
		},
		actions: {
			type: Array,
			required: true,
		},
		isActionPending: {
			type: Boolean,
			require: true,
		},
	},
	computed: {
		...mapState(["currentUser", "PlatformManager"]),
		isPickedUpByAnotherPM() {
			return (
				this.actionItem.picked_up_user_id &&
				this.actionItem.picked_up_user_id !== this.currentUser.id
			);
		},
		isPickedUpByCurrentUser() {
			return (
				this.actionItem.picked_up_user_id &&
				this.actionItem.picked_up_user_id === this.currentUser.id
			);
		},
		actionItemTypeId() {
			return this.actionItem.action_type_id;
		},
		actionItemId() {
			return this.actionItem.id;
		},
		actionName() {
			return getActionTypeName(this.actionItem);
		},
		filteredActions() {
			return this.isPickedUpByAnotherPM ?
				this.actions.filter((action) => action.id === INSPECT_ACTION_ID) :
				this.actions;
		},
	},
	methods: {
		handleAction(action) {
			const payload = { action, sessionId: this.actionItem.content.data.session_id };
			this.$emit("action-taken", {
				eventHandler: action.handleEvent,
				payload,
			});
		},
		getActionTypeIndex(id) {
			return getActionTypeIndex(id, this.actions);
		},
		isItemSelected(id) {
			const isInspecting = id === 0 &&
					!this.actionItem.action_type_id &&
					this.isPickedUpByCurrentUser;
			return id === this.actionItem.action_type_id || isInspecting;
		},
	},
};
</script>

<style>
.action__dropdown :deep(li) {
	padding: 0;
}
</style>
