<template>
	<svg
		:width="width"
		:height="height"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M3 17.2515V21.001H6.74948L17.8079 9.94251L14.0585 6.19303L3 17.2515ZM20.7075 7.04292C21.0975 6.65297 21.0975 6.02306 20.7075 5.63311L18.3679 3.29344C17.9779 2.90349 17.348 2.90349 16.9581 3.29344L15.1283 5.12318L18.8778 8.87266L20.7075 7.04292Z"
			fill="currentColor"
		/>
	</svg>
</template>

<script>
export default {
	props: {
		width: {
			type: [Number, String],
			default: "16px",
		},
		height: {
			type: [Number, String],
			default: "16px",
		},
	},
};
</script>

