<template>
	<OrigamiCard
		:class="bgColorClass"
		class="
			tw-relative
			tw-flex
			tw-flex-col
			md:tw-flex-row
			tw-items-center
			tw-justify-between
			tw-text-center
			md:tw-text-left
			tw-px-4
			md:tw-px-6
			tw-py-4
		"
	>
		<div class="tw-flex-shrink-0">
			<slot name="image">
				<img
					v-if="image"
					v-jest="'bannerImage'"
					:src="image"
					:alt="title"
					class="tw-w-full tw-object-contain"
				>
			</slot>
		</div>
		<div class="tw-flex-1 tw-px-6 tw-mb-4 md:tw-mb-0">
			<slot name="title">
				<h1
					v-jest="'bannerTitle'"
					class="tw-font-bold md:tw-text-lg tw-mb-1"
				>
					{{ title }}
				</h1>
			</slot>
			<slot name="body">
				<p
					v-if="body"
					v-jest="'bannerBody'"
				>
					{{ body }}
				</p>
			</slot>
		</div>
		<div
			v-if="showMainAction"
			v-jest="'bannerAction'"
			class="tw-w-full md:tw-w-auto"
		>
			<OrigamiButton
				v-jest="'actionButton'"
				:data-pendo-id="mainActionPendoId"
				class="tw-w-full md:tw-w-auto tw-justify-center md:tw-text-left"
				@click.native="$emit('main-action')"
			>
				<slot name="buttonText">
					{{ buttonText }}
				</slot>
			</OrigamiButton>
		</div>
		<div class="tw-hidden md:tw-block tw-pl-4">
			<OrigamiIconButton
				v-jest="'closeButton'"
				icon-name="close"
				aria-label="close"
				@click.native="$emit('close')"
			/>
		</div>
		<div class="tw-absolute tw-top-2 tw-right-2 md:tw-hidden">
			<OrigamiIconButton
				v-jest="'closeButton'"
				icon-name="close"
				aria-label="close"
				@click.native="$emit('close')"
			/>
		</div>
	</OrigamiCard>
</template>

<script>
import { OrigamiIconButton, OrigamiButton, OrigamiCard } from "@origami/vue2";

export default {
	name: "BaseBanner",
	components: {
		OrigamiButton,
		OrigamiCard,
		OrigamiIconButton,
	},
	props: {
		title: {
			type: String,
			default: "",
		},
		body: {
			type: String,
			default: "",
		},
		buttonText: {
			type: String,
			default() {
				return this.$t("view");
			},
		},
		image: {
			type: String,
			default: "",
		},
		showMainAction: {
			type: Boolean,
			default: false,
		},
		mainActionPendoId: {
			type: String,
			default: "",
		},
		bgColorClass: {
			type: String,
			default: "tw-bg-origami-green-200",
		},
	},
};
</script>
