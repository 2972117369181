var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tw-flex tw-flex-col tw-gap-2 tw-items-center tw-mb-4" },
    [
      _vm.icon
        ? _c(_vm.icon, {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "component-icon",
                expression: "'component-icon'",
              },
            ],
            tag: "component",
            staticClass: "tw-text-origami-grey-200 tw-w-full",
            attrs: { height: _vm.iconHeight },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tw-font-bold tw-mb-2" },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm.buttonText
        ? _c(
            "BaseButton",
            _vm._b(
              {
                nativeOn: {
                  click: function ($event) {
                    return ((event) =>
                      _vm.$emit("button-clicked", event)).apply(null, arguments)
                  },
                },
              },
              "BaseButton",
              _vm.buttonProps,
              false
            ),
            [_vm._v("\n\t\t" + _vm._s(_vm.buttonText) + "\n\t")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }