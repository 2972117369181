<template>
	<OrigamiButton
		v-if="canCancelRestBreak"
		v-jest="'rest-break-cancel-button'"
		v-data-cy="'rest-break-cancel-button'"
		variant="destructive"
		class="tw-w-full tw-justify-center"
		@click.native="cancelBreak"
	>
		Cancel break
	</OrigamiButton>
</template>
<script>
import { OrigamiButton } from "@origami/vue2";

export default {
	components: {
		OrigamiButton,
	},
	computed: {
		canCancelRestBreak() {
			return this.$store.getters["BreaksManagementWidget/canCancelRestBreak"];
		},
	},
	methods: {
		cancelBreak() {
			this.$store.dispatch("BreaksManagementWidget/cancelBreak");
		},
	},
};
</script>
