<template>
	<div class="resourceList">
		<div
			v-if="isEssayComponent"
			class="resourceList__total resourceList__total--essay"
		>
			<div class="resourceList__title">
				Reviews
			</div>
			<div class="resourceList__resourceTotal">
				Total Submission Reviews
				<span class="resourceList__count">{{ totalResourcesCount }}</span>
			</div>
		</div>
		<div
			v-else
			class="resourceList__total"
		>
			<div class="resourceList__onGoing">
				Ongoing Sessions
				<span class="resourceList__onGoingCount">({{ ongoingSessionsCount }})</span>
			</div>
			<div class="resourceList__sessions">
				Total Sessions
				<span class="resourceList__count">{{ totalResourcesCount }}</span>
			</div>
		</div>
		<ServiceDashboardListItemEmpty
			v-if="isResourcesEmpty"
			:resource-type="componentType"
			:time-since-last-activity="timeSinceLastActivity"
		/>
		<template
			v-for="(resource, key) in resources"
			v-else
		>
			<component
				:is="getListComponent(resource)"
				v-if="resource"
				:key="key"
				class="resourceList__content"
				:resource="resource"
			/>
		</template>
	</div>
</template>
<script>
import ServiceDashboardListItemEmpty from "./ServiceDashboardListItemEmpty.vue";

export default {
	components: {
		ServiceDashboardListItemEmpty,
	},
	props: {
		resources: {
			validator: (value) => {
				return value instanceof Array || value === null;
			},
			required: true,
		},
		timeSinceLastActivity: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		/**
		 * check if current component is of type essay
		 * @returns {Boolean}
		 */
		isEssayComponent() {
			return this.componentType === "essay";
		},
		/**
		 * @returns {Boolean}
		 */
		isResourcesEmpty() {
			return this.resources.length === 0;
		},
		/**
		 * return total amount of resources for the list
		 * @returns {Number}
		 */
		totalResourcesCount() {
			return 0;
		},
		/**
		 * return the amount of on going sessions
		 * @returns {Number}
		 */
		ongoingSessionsCount() {
			return 0;

		},
		/**
		 * returns the type of the current resource
		 * @returns {String}
		 */
		componentType() {
			return "";
		},
		/**
		 *
		 */
		getLastActivity() {
			return "";

		},
	},
	methods: {
		getListComponent() {
			return "";
		},
	},
};
</script>
<style>
.resourceList__total {
	display: flex;
	justify-content: space-between;
}

.resourceList__total--essay {
	margin-top: 16px;
}

.resourceList__content {
	box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.1);
}
.resourceList__title,
.resourceList__resourceTotal {
	font-size: 10px;
}
/* divv */

.resourceList__onGoingCount,
.resourceList__count {
	font-weight: bold;
}
.resourceList__onGoing,
.resourceList__sessions {
	font-size: 10px;
}
</style>
