var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseModal",
    { attrs: { id: _vm.modalName, "modal-ref": _vm.modalName } },
    [
      _c(
        "div",
        {
          staticClass:
            "tw-flex tw-justify-start tw-items-baseline tw-border-none",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "transferSessionModal__icon tw-flex tw-justify-center tw-w-11 tw-h-11 tw-rounded-full tw-bg-origami-blue-300 tw-mr-4",
            },
            [
              _c("IconArrowTransfer", {
                staticClass: "transferSessionModal__svg",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "tw-truncate tw-text-lg tw-font-bold tw-text-origami-text-black",
            },
            [_vm._v("\n\t\t\tTransfer To Self\n\t\t")]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isProcessingTransfer
        ? _c("div", { staticClass: "processingTransfer tw-mt-8 tw-pb-12" }, [
            _c(
              "div",
              {
                staticClass:
                  "tw-w-full tw-pl-6 tw-pr-0.5 tw-flex tw-flex-col tw-justify-center tw-items-center",
              },
              [
                _c("BaseLoadingState", {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "transfer-loading-spinner",
                      expression: "'transfer-loading-spinner'",
                    },
                  ],
                  staticClass: "loadingSpinner",
                }),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "loadingState__text--font tw-text-base tw-mt-2 tw-text-black-medium tw-font-bold",
                  },
                  [_vm._v("\n\t\t\t\tHand off in progress...\n\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "loadingState__text--font tw-text-xs tw-mt-2 tw-text-black-medium",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\tA chat session will open up with the student shortly.\n\t\t\t"
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _c("div", { staticClass: "tw-flex tw-flex-col tw-gap-y-8 tw-mt-8" }, [
            _c("div", [
              _c("p", { staticClass: "tw-text-base" }, [
                _vm._v(
                  "\n\t\t\t\tA classroom session with you and the student will start.\n\t\t\t"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tw-flex tw-justify-end tw-w-full tw-gap-x-4" },
              [
                _c("BaseButton", {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "cancel-button",
                      expression: "'cancel-button'",
                    },
                  ],
                  attrs: { type: "CANCEL" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.closeModal.apply(null, arguments)
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "text",
                      fn: function () {
                        return [_vm._v("\n\t\t\t\t\tCancel\n\t\t\t\t")]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("BaseButton", {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "transfer-button",
                      expression: "'transfer-button'",
                    },
                  ],
                  nativeOn: {
                    click: function ($event) {
                      return _vm.transferSession.apply(null, arguments)
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "text",
                      fn: function () {
                        return [_vm._v("\n\t\t\t\t\tTransfer\n\t\t\t\t")]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }