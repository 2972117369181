import Vue from "vue";
import { createAuth0Client } from "@auth0/auth0-spa-js";
import jsCookie from "js-cookie";
import { getFlag, captureException } from "@paper-co/web-toolkit";

import store from "@/store/index.js";
import { redirectToPostLoginRedirectUri } from "@/modules/Authentication/utilities/auth.js";
import { isEmbedded } from "@/modules/EmbeddedUtilities/index.js";
import { openLinkInPopup } from "@/utilities/links.js";

const TWENTY_FIVE_SECONDS_IN_MS = 25000;

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = async() => {
	await redirectToPostLoginRedirectUri();
};

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

// for testing
export const resetInstance = () => {
	instance = undefined;
};

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
	onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
	redirectUri = window.location.origin,
	...options
}) => {
	if (instance) {
		return instance;
	}

	// The 'instance' is simply a Vue object
	instance = new Vue({
		data() {
			return {
				isLoading: false,
				isAuthenticated: false,
				user: {},
				auth0Client: null,
				popupOpen: false,
				error: null,
				endLoadingDelay: null,
				featureFlagAuth0LoginPerformanceImprovements: false,
			};
		},
		/** Use this lifecycle method to instantiate the SDK client */
		async created() {
			this.featureFlagAuth0LoginPerformanceImprovements = await getFlag("GROW-2316-login-performance-improvement");
			// Create a new instance of the SDK client using members of the given options object
			this.auth0Client = await createAuth0Client({
				...options,
				authorizationParams: {
					redirect_uri: redirectUri,
					audience: process.env.MIX_AUTH0_AUDIENCE,
					...options.authorizationParams,
				},
			});

			try {
				// If the user is returning to the app after authentication..
				if (
					window.location.search.includes("code=") &&
					window.location.search.includes("state=")
				) {
					this.isLoading = true;
					// handle the redirect and retrieve tokens
					const { appState } = await this.auth0Client.handleRedirectCallback();
					this.error = null;

					// Notify subscribers that the redirect callback has happened, passing the appState
					// (useful for retrieving any pre-authentication state)
					await store.dispatch("Login/getAuth0Login", await this.auth0Client.getTokenSilently());
					await onRedirectCallback(appState);
				} else if (!jsCookie.get("access_token")) {
					const featureFlagSilentLoginIsEnabled = await getFlag("PLAT-3684-spa-web-silent-login");
					if (featureFlagSilentLoginIsEnabled) {
						try {
							await store.dispatch("Login/getAuth0Login", await this.auth0Client.getTokenSilently());
							await redirectToPostLoginRedirectUri();
						} catch (e) {
							this.error = e;
						}
					}
				}
			} catch (e) {
				this.error = e;
				window.history.replaceState({}, document.title, window.location.pathname);
				captureException(e);
				store.commit("Login/SET_AUTH0_LOGIN_ERROR", { auth0LoginError: true });
				this.clearEndLoadingDelay();
			} finally {
				// Initialize our internal authentication state
				this.isAuthenticated = await this.auth0Client.isAuthenticated();
				this.user = await this.auth0Client.getUser();
				this.startEndLoadingDelay();
			}
		},
		beforeDestroy() {
			this.clearEndLoadingDelay();
		},
		methods: {
			/** Authenticates the user using the redirect method */
			async loginWithRedirect(o = {}) {
				this.isLoading = true;
				this.startEndLoadingDelay();

				try {
					this.error = null;
					if (isEmbedded()) {
						o.openUrl = this.openAuth0InPopup;
					}
					return this.auth0Client.loginWithRedirect(o);
				} catch (e) {
					this.error = e;
					captureException(e);
					store.commit("Login/SET_AUTH0_LOGIN_ERROR", { auth0LoginError: true });
					this.clearEndLoadingDelay();
				}
			},

			async openAuth0InPopup(url) {
				await openLinkInPopup(url);
				if (!this.featureFlagAuth0LoginPerformanceImprovements) {
					await store.dispatch("Login/getAuth0Login", await this.auth0Client.getTokenSilently());
				}
				await redirectToPostLoginRedirectUri();
			},
			/** Handles the callback when logging in using a redirect */
			async handleRedirectCallback() {
				this.isLoading = true;
				try {
					await this.auth0Client.handleRedirectCallback();
					this.user = await this.auth0Client.getUser();
					this.isAuthenticated = true;
					this.error = null;
				} catch (e) {
					this.error = e;
					captureException(e);
				} finally {
					this.startEndLoadingDelay();
				}
			},
			/** Returns the access token. If the token is invalid or missing, a new one is retrieved */
			getTokenSilently(o) {
				return this.auth0Client.getTokenSilently(o);
			},
			/** Logs the user out and removes their session on the authorization server */
			async logout(o) {
				if (!this.auth0Client) {
					this.auth0Client = await createAuth0Client({
						...options,
						authorizationParams: {
							redirect_uri: redirectUri,
							audience: process.env.MIX_GRADESLAM_API_URL,
							...options.authorizationParams,
						},
					});
				}
				return this.auth0Client.logout(o);
			},
			startEndLoadingDelay() {
				this.endLoadingDelay = setTimeout(() => {
					this.isLoading = false;
				}, TWENTY_FIVE_SECONDS_IN_MS);
			},
			clearEndLoadingDelay() {
				this.isLoading = false;
				clearTimeout(this.endLoadingDelay);
			},
		},
	});

	return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0 = {
	install(Vm) {
		Vm.prototype.$auth = useAuth0({
			domain: process.env.MIX_AUTH0_DOMAIN,
			clientId: process.env.MIX_AUTH0_CLIENT_ID,
		});
	},
};
