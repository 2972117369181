<script setup>
import { isEmpty } from "lodash";
import { ref, computed, watch } from "vue";
import { useRoute } from "vue-router/composables";

import useStore from "@/composables/useStore.js";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";

const store = useStore();
const route = useRoute();

const props = defineProps({
	filter: {
		type: Object,
		required: true,
		validator(value) {
			return (
				value.hasOwnProperty("name") &&
				value.hasOwnProperty("filterChoices")
			);
		},
	},
});

const selectedChoices = ref([]);

const filterDropdownText = computed(() => {
	const filterCount = selectedChoices.value.length;
	return !isEmpty(selectedChoices.value)
		? `${props.filter.name} (+${filterCount})`
		: `Select a ${props.filter.name.toLowerCase()}`;
});

const selectedFilters = computed(() => store.state.ServiceAnalytics.selectedFilters);
const isFiltersSidebarOpen = computed(() => store.state.ServiceAnalytics.isFiltersSidebarOpen);

watch(selectedFilters, (newValue) => {
	if (isEmpty(newValue) || !(props.filter.name in newValue)) {
		selectedChoices.value = [];
	}
});

watch(isFiltersSidebarOpen, (newValue) => {
	if (!newValue && !isEmpty(route.query)) {
		selectedChoices.value = isEmpty(selectedFilters.value) ? [] : selectedFilters.value[props.filter.name];
	}
});

watch(selectedChoices, (newValue) => {
	if (newValue) {
		store.dispatch("ServiceAnalytics/addUnsubmittedFilter", {
			name: props.filter.name,
			unsubmittedFilters: [...selectedChoices.value],
		});
	}
});
</script>

<template>
	<div>
		<label
			v-jest="`service-analytics-${props.filter.name}-filter-label`"
			:data-cy="`service-analytics-${props.filter.name}-filter-label`"
			class="tw-font-bold"
		>
			{{ props.filter.name }}
		</label>
		<BaseDropdown
			v-jest="`service-analytics-${props.filter.name}-filter-dropdown`"
			:data-cy="`service-analytics-${props.filter.name}-filter-dropdown`"
			class="tw-w-1/3"
			:items="props.filter.filterChoices"
		>
			<template slot="filters">
				{{ filterDropdownText }}
			</template>
			<template slot-scope="{ item }">
				<BaseCheckbox
					v-model="selectedChoices"
					v-jest="'filter-dropdown-checkbox'"
					:data-cy="`${filter.name}-filter-dropdown-checkbox-${item.name}`"
					:checkbox-value="{ id: item.id, name: item.name }"
					:checkbox-id="item.id"
				>
					<template slot="text">
						{{ item.name }}
					</template>
				</BaseCheckbox>
			</template>
		</BaseDropdown>
	</div>
</template>
