import { remove } from "lodash";
import {
	identifyLaunchDarklyUser,
	identifyZendeskUser,
	captureException,
	identifyGoogleAnalyticsUser,
} from "@paper-co/web-toolkit";

import "@origami/vue2/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "vue-resize/dist/vue-resize.css";

import "./assets/css/variables.css";
import "./assets/css/spinner.css";
import "./assets/css/fonts.css";
import "./assets/css/nav.css";
import "./assets/css/global.css";
import "./assets/css/tailwind.css";

import Vue from "./global.js";
import i18n from "./i18n/index.js";
import router from "./router/index.js";
import store from "./store/index.js";
import "./plugins/socket.js";
import App from "./App.vue";

store.subscribe(async(mutation) => {
	if (mutation.type === "SET_CURRENT_USER") {
		const { currentUser } = mutation.payload;
		identifyLaunchDarklyUser(currentUser).catch(captureException);
		identifyZendeskUser(currentUser).catch(captureException);
		identifyGoogleAnalyticsUser(currentUser).catch(captureException);
	}
});

new Vue({
	el: "#app",
	i18n,
	router,
	store,
	data: function() {
		return {
			modalQueue: [],
		};
	},
	watch: {
		modalQueue() {
			if (this.modalQueue.length > 0) {
				this.$bvModal.show(this.modalQueue[0]);
			}
		},
	},
	methods: {
		modalPop(id) {
			remove(this.modalQueue, (modal) => modal === id);
			this.$bvModal.hide(id);
			if (this.modalQueue.length > 0) {
				this.$bvModal.show(this.modalQueue[0]);
			}
		},
	},
	render: (h) => h(App),
});
