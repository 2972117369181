import { initializePendo } from "@paper-co/web-toolkit";

import RolesAPI from "@/services/api/Roles.js";
import UsersAPI from "@/modules/Customers/services/users.js";

export default async function(to, from, next) {
	initializePendo({
		apiKey: process.env.MIX_PENDO_API_KEY,
		user: this.state.currentUser,
		getUser: (id, include) => UsersAPI.show(id, { include }),
		getRoles: RolesAPI.list,
		langs: this.state.Lang.publicLanguages,
		env: process.env.MIX_APP_ENV,
	}).catch(Sentry.captureException);
	return next();
}
