import ServiceDashboardInterface from "../interfaces/ServiceDashboardInterface.js";

const serviceDashboardListener = {
	async connect() {
		this.$socket.emit("join.service_dashboard", {}, () => {});
	},
	latest_essay_review_eta_updated(data) {
		this.$store.commit("PlatformManager/ServiceSummaryDashboard/SET_LATEST_ESSAY_REVIEW_ETA", { eta: data.data });
	},
};

ServiceDashboardInterface.isImplementedBy(serviceDashboardListener);

export default serviceDashboardListener;
