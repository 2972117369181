import jwtDecode from "jwt-decode";
import { getFlag } from "@paper-co/web-toolkit";

import { logout, refreshAccessTokenIfNecessary } from "@/services/root.js";
import { isEmbedded } from "@/modules/EmbeddedUtilities/utilities/index.js";

import { getAndConsumePostLoginRedirectUri } from "../utilities/auth.js";

export default async function(to, from, next) {
	try {
		const accessToken = await refreshAccessTokenIfNecessary();
		if (!accessToken) {
			this.commit("SET_CURRENT_USER", { currentUser: null });
			this.commit("SET_SHOW_NAVBAR", false);
			return next();
		}

		const token = jwtDecode(accessToken);
		if (!token.user.role) {
			throw new Error("invalid role");
		}

		if (token.user.roles.includes("affiliate")) {
			alert("PAPER no longer supports affiliate accounts", "danger");
			throw new Error("affiliate accounts no longer supported");
		}

		if (await getFlag("GROW-414-referrals")) {
			const redirectUri = await getAndConsumePostLoginRedirectUri();
			if (redirectUri) {
				return next(redirectUri);
			}
		}
		if (
			token.user.role === "student"
			|| (isEmbedded() && token.user.roles.includes("student"))
		) {
			return next("/student/dashboard");
		} else if (token.user.role === "superuser-creator") {
			return next("/superuser");
		} else {
			return next(`/${token.user.role}`);
		}
	} catch (e) {
		Sentry.captureException(e);

		await logout(to);
	}
}

