import { mapKeys } from "lodash";

const CONTENT_V2_VERSION = "2.0";
const ROLE_TUTOR_ID = 2;
const ROLE_STUDENT_ID = 1;
const MESSAGE_TYPE_TEXT_ID = 1;

export function classroomContentV2Transformer(content) {
	if (content.version === CONTENT_V2_VERSION) {
		return content;
	}

	const data = mapKeys(content.data, (value, key) => {
		switch (key) {
			case "created_at":
				return "message_sent_at";
			case "user_first_name":
				return "message_sender_user_first_name";
			case "user_id":
				return "message_sender_user_id";
			default:
				return key;
		}
	});

	delete data.is_tutor;
	data.message_sender_role_id = content.data.is_tutor ? ROLE_TUTOR_ID : ROLE_STUDENT_ID;
	data.message_content_type_id = MESSAGE_TYPE_TEXT_ID;

	return {
		version: CONTENT_V2_VERSION,
		actionable_item_type: content.actionable_item_type,
		data,
	};
}
