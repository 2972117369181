import api from "./Api.js";

export default {
	/**
	 * Get all activity
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	list(params) {
		return api().get(`activity`, {
			params: params,
		});
	},

	/**
	 * Get all (filtered) essays
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */

	getFilteredEssays(params) {
		return api().get("activity/essays", {
			params,
		});
	},

	/**
	 * Get (filtered) essays for a school
	 * @param {object} params
	 * @param {number} schoolId
	 * @returns {AxiosPromise}
	 */

	getFilteredSchoolEssays(params, schoolId) {
		return api().get(`schools/${schoolId}/essays`, {
			params,
		});
	},

	/**
	 * Get (filtered) essays for a district
	 * @param {object} params
	 * @param {number} districtId
	 * @returns {AxiosPromise}
	 */

	getFilteredDistrictEssays(params, districtId) {
		return api().get(`districts/${districtId}/essays`, {
			params,
		});
	},

	/**
	 * Get (filtered) essays for a teacher
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */

	getFilteredTeacherEssays(params) {
		return api().get("teachers/essays", {
			params,
		});
	},

	/**
	 * Get all (filtered) sessions
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */

	getFilteredSessions(params) {
		return api().get("sessions", {
			params,
		});
	},

	/**
	 * Get (filtered) sessions for school
	 * @param {object} params
	 * @param {number} schoolId
	 * @returns {AxiosPromise}
	 */

	getFilteredSchoolSessions(params, schoolId) {
		return api().get(`schools/${schoolId}/sessions`, {
			params,
		});
	},

	/**
	 * Get (filtered) sessions for a district
	 * @param {object} params
	 * @param {number} districtId
	 * @returns {AxiosPromise}
	 */

	getFilteredDistrictSessions(params, districtId) {
		return api().get(`districts/${districtId}/sessions`, {
			params,
		});
	},

	/**
	 * Get (filtered) sessions for a teacher
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */

	getFilteredTeacherSessions(params) {
		return api().get("teachers/sessions", {
			params,
		});
	},

	/**
	 * Get all activity of teacher group
	 * @param {number} teacherId
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	showTeacher(teacherId, params) {
		return api().get(`/teacher-groups/${teacherId}/activity`, {
			params: params,
		});
	},

	/**
	 * Get all activity of district
	 * @param {number} districtId
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	showDistrict(districtId, params) {
		return api().get(`/districts/${districtId}/activity`, {
			params: params,
		});
	},

	/**
	 * Get all activity of school
	 * @param {number} schoolId
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	showSchool(schoolId, params) {
		return api().get(`/schools/${schoolId}/activity`, {
			params: params,
		});
	},
};
