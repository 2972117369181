import { ref, onMounted, onUnmounted } from "vue";

export default function() {
	const mediumMediaQuery = window.matchMedia("(min-width: 768px)");

	const isShowingDesktopView = ref(false);

	const determineIsShowingDesktopView = () => {
		isShowingDesktopView.value = mediumMediaQuery.matches;
	};

	onMounted(() => {
		determineIsShowingDesktopView();
		mediumMediaQuery.addEventListener("change", determineIsShowingDesktopView);
	});
	onUnmounted(() => {
		mediumMediaQuery.removeEventListener("change", determineIsShowingDesktopView);
	});

	return {
		isShowingDesktopView,
	};
}
