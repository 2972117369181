<template>
	<div :class="{ 'tw-shadow-elevation-4': !isFlat }">
		<div v-if="displayHeader">
			<div class="tw-flex tw-justify-between tw-items-center">
				<h2 class="tw-font-bold tw-text-lg tw-pl-6 tw-pr-4 tw-py-4">
					Edit Shift
				</h2>
				<OrigamiButton
					v-if="!updatedShift"
					v-jest="'extend-shift-button'"
					data-cy="extend-shift-button"
					class="tw-mr-2"
					variant="tertiary"
					:is-disabled="!canExtendShift"
					@click.native="startExtendProcess()"
				>
					Extend Shift
				</OrigamiButton>
			</div>
			<hr>
		</div>
		<div v-if="!updatedShift">
			<div class="tw-flex tw-items-center tw-px-6 tw-py-0">
				<ServiceScheduleTutorShiftForm
					:placeholder="'Select a shift'"
					:selected-tutor="selectedTutor"
					:is-editing="isShiftSelected"
					:shift="shift"
					:value="dates"
				/>
			</div>
			<div class="tw-flex tw-justify-between tw-pb-4 tw-pt-0 tw-px-6">
				<OrigamiButton
					v-jest="'cancel-btn'"
					v-data-cy="'cancel-btn'"
					variant="secondary"
					@click.native="cancel()"
				>
					Cancel
				</OrigamiButton>
				<OrigamiButton
					v-jest="'save-shift-btn'"
					data-cy="save-shift-btn"
					variant="primary"
					:is-disabled="!isValidShiftEdit"
					@click.native="updateShift()"
				>
					Save
				</OrigamiButton>
			</div>
			<ServiceScheduleTutorErrorMessage
				v-if="hasAnError"
				v-jest="'error-message'"
				data-cy="error-message"
				class="tw--mb-16"
				:error="error"
			/>
			<div
				class="tw-flex tw-pb-4 tw-px-6 tw-pt-10"
			>
				<OrigamiButton
					v-jest="'remove-btn'"
					v-data-cy="'remove-btn'"
					variant="destructive"
					class="tw-w-full tw-justify-center"
					@click.native="startDeleteProcess()"
				>
					Remove Shift
				</OrigamiButton>
			</div>
		</div>
		<ServiceScheduleTutorSuccessfulAction
			v-else
			:shift="shift"
			:updated-shift="updatedShift"
			:success-message-in-sidebar="{
				message: 'You have successfully edited the shift.',
				buttonText: 'Complete',
			}"
			@cancel-edit="cancel()"
		/>
	</div>
</template>
<script>
import { mapState } from "vuex";
import { isAfter } from "date-fns";
import { OrigamiButton } from "@origami/vue2";
import { isEmpty } from "lodash";

import { getSlotColorFromShiftType } from "@/utilities/ScheduleHelpers.js";
import { formatDateToTimestamp } from "@/utilities/dateHelpers.js";
import { ScheduleManagementApi } from "@/modules/TutorSchedule/index.js";
import { TRANSITION } from "@/modules/ServiceSchedule/utilities/ShiftStateModifierTypes.js";
import ServiceScheduleTutorSuccessfulAction from "@/modules/ServiceSchedule/components/ServiceScheduleTutorSuccessfulAction.vue";

import ServiceScheduleTutorShiftForm from "./ServiceScheduleTutorShiftForm.vue";
import ServiceScheduleTutorErrorMessage from "./ServiceScheduleTutorErrorMessage.vue";

export default {
	components: {
		ServiceScheduleTutorSuccessfulAction,
		ServiceScheduleTutorShiftForm,
		ServiceScheduleTutorErrorMessage,
		OrigamiButton,
	},
	props: {
		isFlat: {
			type: Boolean,
			default: false,
		},
		displayHeader: {
			type: Boolean,
			default: true,
		},
		shift: {
			type: Object,
			required: true,
			validator(value) {
				return (
					value.hasOwnProperty("start") &&
					value.hasOwnProperty("end") &&
					value.hasOwnProperty("punchIn") &&
					value.hasOwnProperty("name") &&
					value.hasOwnProperty("id") &&
					value.hasOwnProperty("shiftColor") &&
					value.hasOwnProperty("shiftType") &&
					value.hasOwnProperty("shiftEnded") &&
					value.hasOwnProperty("isSelected")
				);
			},
		},
		selectedTutor: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			error: null,
			shiftState: null,
			updatedShift: null,
		};
	},
	computed: {
		...mapState(["PlatformManager", "TutorSchedule"]),
		dates() {
			return { start: this.shift.start, end: this.shift.end };
		},
		isShiftSelected() {
			return this.shift?.isSelected ?? false;
		},
		isValidShiftEdit() {
			const hasValidDates = isAfter(
				this.PlatformManager.ServiceTutorsSchedule.dateRange?.end,
				this.PlatformManager.ServiceTutorsSchedule.dateRange?.start,
			);
			const datesHaveChanged = this.shift.start !== this.PlatformManager.ServiceTutorsSchedule.dateRange.start ||
				this.shift.end !== this.PlatformManager.ServiceTutorsSchedule.dateRange.end;

			const shiftTypeId = this.shift.shiftType?.data?.id;
			const newShiftTypeId = this.PlatformManager.ServiceTutorsSchedule.currentShiftType?.id;
			const isShiftTypeChanged = shiftTypeId && newShiftTypeId && newShiftTypeId !== shiftTypeId;

			return hasValidDates && datesHaveChanged || isShiftTypeChanged;
		},
		hasAnError() {
			return !isEmpty(this.error);
		},
		smaShift() {
			return this.TutorSchedule.shifts
				?.find((shift) => shift.shift_segments
					?.some((segment) => segment._metadata.gs_api_transformer.id === this.shift.id));
		},
		shiftSegment() {
			return this.smaShift?.shift_segments
				.find((segment) => segment._metadata.gs_api_transformer.id === this.shift.id);
		},
		isSegmentInTransition() {
			if (!this.shiftState) {
				return false;
			}

			const modifier = this.shiftSegment.modifiers
				.find((modifier) => modifier.id === this.shiftState.modifier_id);
			return modifier?.type.id === TRANSITION;
		},
		canExtendShift() {
			if (!this.smaShift || !this.shiftSegment) {
				return false;
			}

			return !this.smaShift.extensions.length &&
				this.smaShift.end_time === this.shiftSegment.end_time &&
				this.shiftSegment.punch_in &&
				this.isSegmentInTransition;
		},
	},
	async created() {
		// TODO: SER-1382. Remove this once the shift carries the expected state for in-transition
		this.shiftState = await ScheduleManagementApi.getShiftState(this.smaShift.id);
	},
	methods: {
		getShiftParams() {
			return {
				id: this.shift.id,
				data: {
					schedule_type_id: this.PlatformManager.ServiceTutorsSchedule.currentShiftType?.id,
					name: this.PlatformManager.ServiceTutorsSchedule.currentShiftType?.name,
					start_time: formatDateToTimestamp(this.PlatformManager.ServiceTutorsSchedule.dateRange.start),
					end_time: formatDateToTimestamp(this.PlatformManager.ServiceTutorsSchedule.dateRange.end),
				},
			};
		},
		async updateShift() {
			try {
				const params = this.getShiftParams();
				await this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/updateShift", params);
				const event = this.buildEvent(params);
				this.updateShiftInShifts(event);
				this.updatedShift = params?.data;
				this.$emit("complete-edit");
			} catch (e) {
				this.error = e;
				Sentry.captureException(e);
			}
		},
		startDeleteProcess() {
			this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/startDeleteShiftProcess");
			this.$emit("format-shifts");
		},
		startExtendProcess() {
			this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/startExtendShiftProcess");
			this.$emit("format-shifts");
		},
		buildEvent(params) {
			const color = getSlotColorFromShiftType(this.shift.shiftType.data.id);
			return {
				id: this.shift.id,
				shiftType: {
					data: {
						id: params.data.schedule_type_id,
						name: params.data.name,
					},
				},
				backgroundColor: color,
				borderColor: color,
			};
		},
		updateShiftInShifts(event) {
			const shifts = this.PlatformManager.ServiceTutorsSchedule.shifts?.map((shift) => {
				if (shift.id === event.id) {
					shift.scheduleType = event.shiftType;
				}
				return shift;
			});
			this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/setShiftsArray", {
				shifts,
			});
		},
		cancel() {
			this.$emit("cancel-edit");
			this.error = null;
		},
	},
};

</script>
