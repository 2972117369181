import { merge } from "lodash";

import { generalTranslations } from "../../general.js";

const trans = {
	en: {
		please_confirm: "Please Confirm",
		oops: "Oops!",
		error_user: "There was an issue updating the tutor's info",
		okay: "Okay",
		tutor_profile_picture: "{fullTutorName}'s profile picture",
	},
	fr: {
		please_confirm: "S'il vout plaît confirmer",
		oops: "Oops!",
		error_user: "Une erreur s'est produite lors du mise à jour des données du tuteur.",
		okay: "OK",
		tutor_profile_picture: "Photo de profil de {fullTutorName}",
	},
	es: {
		please_confirm: "Please Confirm",
		oops: "Oops!",
		error_user: "There was an issue updating the tutor's info",
		okay: "Okay",
		tutor_profile_picture: "Foto del perfil del {fullTutorName}",
	},
};

export default merge(generalTranslations, trans);
