<template>
	<div
		:id="value.id"
		:value="value"
		class="baseItem"
		@click="handleInput(value)"
	>
		{{ value.name }}
	</div>
</template>
<script>
export default {
	props: {
		id: {
			type: [Number, String],
			required: true,
		},
		value: {
			type: [Object, String, Number],
			required: true,
		},
	},
	methods: {
		handleInput(value) {
			this.$emit("click", value);
		},
	},
};
</script>
<style>
.baseItem {
	padding: 9px 8px;
}
</style>
