var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("TutorBreakCard", {
    attrs: { break: _vm.upcomingBreak, "is-loading-state": _vm.isLoadingState },
    scopedSlots: _vm._u([
      {
        key: "metaDataIcon",
        fn: function () {
          return [
            _c(_vm.getScheduleTypeId(), {
              tag: "component",
              staticClass: "tw-mr-2 tw-align-middle tw-text-black-high",
              attrs: { "icon-color": "currentColor" },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "metadata",
        fn: function () {
          return [
            _vm._v(
              "\n\t\t" +
                _vm._s(
                  _vm.getTimeRange(
                    _vm.upcomingBreak.start_time,
                    _vm.upcomingBreak.end_time
                  )
                ) +
                "\n\t\t"
            ),
            _vm.upcomingBreak.punch_in
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "elapsed-time",
                        expression: "'elapsed-time'",
                      },
                    ],
                    staticClass: "tw-inline-block",
                  },
                  [
                    _c("span", { staticClass: "tw-mx-1" }, [_vm._v("|")]),
                    _vm._v(
                      " " + _vm._s(_vm.getTimeElapsed()) + " elapsed\n\t\t"
                    ),
                  ]
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "breakDetailsIcon",
        fn: function () {
          return [_c("OrigamiIcon", { attrs: { name: _vm.detailsIcon } })]
        },
        proxy: true,
      },
      {
        key: "breakDetails",
        fn: function () {
          return [
            _vm._v(
              "\n\t\t" +
                _vm._s(
                  _vm.getTimeRange(
                    _vm.upcomingBreak.break.start_time,
                    _vm.upcomingBreak.break.end_time
                  )
                ) +
                "\n\t"
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function () {
          return [
            _c(
              "BaseButton",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "cancel-break",
                    expression: "'cancel-break'",
                  },
                ],
                staticClass: "tw-mr-2",
                attrs: {
                  variants: ["outline", "mini"],
                  disabled: _vm.isLoadingState || !_vm.canCancelBreak,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.cancelUpcomingBreak.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\tCancel Break\n\t\t")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }