export const ACTIVITY_MESSAGE_TRANSLATION_KEYS = {
	1: "activity.tutoring",
	2: "activity.essay",
	3: "activity.paperlive",
	4: "activity.potd",
};

export const ACTIVITY_POINTS = {
	default: 10,
	1: 10,
	2: 10,
	3: 10,
	4: 10,
};
export const CHALLENGE_POINTS = 150;
export const RECOGNITION_POINTS = 100;

export const createManualTrophy = (trophy) => {
	return {
		type: "trophy",
		points: trophy.points,
		trophy: {
			name: trophy.name,
			points: trophy.points,
			trophy_id: trophy.id,
			trophy: {
				name: trophy.name,
			},
		},
		trophy_id: trophy.id,
	};
};

export const BADGE_REQUIREMENTS = {
	"essay-1": 1,
	"essay-5": 5,
	"essay-15": 15,
	"session-1": 1,
	"session-5": 5,
	"session-15": 15,
	"paper-live-1": 1,
	"paper-live-5": 5,
	"paper-live-15": 15,
	"paper-live-joined-1": 1,
	"paper-live-joined-5": 5,
	"paper-live-joined-15": 15,
	"feedback-1": 1,
	"feedback-5": 5,
	"feedback-15": 15,
	"problem-completed-1": 1,
	"problem-completed-5": 5,
	"problem-completed-15": 15,
	"referrer-1": 1,
	"referrer-5": 5,
	"referrer-15": 15,
	"mobile-app-activity-1": 1,
	"mobile-app-activity-5": 5,
	"mobile-app-activity-15": 15,
};

export const BADGE_SECTIONS = [
	"session",
	"essay",
	"paper-live",
	"paper-live-joined",
	"feedback",
	"problem-completed",
	"referrer",
	"special-award",
	"mobile-app-activity",
];

export const GROUPING_TITLE_ICONS = {
	session: "session",
	essay: "essay",
	"paper-live": "paper-live",
	feedback: "feedback",
	"paper-live-joined": "paper-live",
	"problem-completed": "paper-math",
	referrer: "students",
	"mobile-app-activity": "phone",
};

export const ACHIEVEMENT_TYPE_IDS = {
	TROPHIES: "trophies",
	RECOGNITIONS: "recognitions",
	BADGES: "badges",
	CHALLENGES: "challenges",
};

export const ACHIEVEMENT_TYPES = [
	{
		id: ACHIEVEMENT_TYPE_IDS.TROPHIES,
		color: "green",
		emoji: "🏆",
	},
	{
		id: ACHIEVEMENT_TYPE_IDS.RECOGNITIONS,
		color: "purple",
		emoji: "🎉",
	},
	{
		id: ACHIEVEMENT_TYPE_IDS.BADGES,
		color: "pink",
		emoji: "🎖",
	},
	{
		id: ACHIEVEMENT_TYPE_IDS.CHALLENGES,
		color: "cyan",
		emoji: "🏁",
	},
];
