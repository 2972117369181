import { without } from "lodash";

import { StudentAnalyticsApi } from "@/modules/StudentAnalytics/index.js";
import { UNCATEGORIZED_GRADE_LEVEL_ID } from "@/utilities/grade.js";

import StudentFilters from "./StudentFilters.js";


export const getDefaultState = () => ({
	isLoadingStudentList: false,
	isErrorStudentList: false,
	studentList: [],
	studentListPagination: { limit: 25 },
});

const state = getDefaultState();

const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_IS_LOADING_STUDENT_LIST(state, payload) {
		state.isLoadingStudentList = payload.isLoadingStudentList;
	},
	SET_IS_ERROR_STUDENT_LIST(state, payload) {
		state.isErrorStudentList = payload.isErrorStudentList;
	},
	SET_STUDENT_LIST(state, payload) {
		state.studentList = payload.studentList;
	},
	SET_STUDENT_LIST_PAGINATION(state, payload) {
		state.studentListPagination = payload.studentListPagination;
	},
};

const getters = {
	getParams(state) {
		let schoolIds = state.StudentFilters.filterOptions.schools.map((school) => school.id);
		const schoolsSelected = state.StudentFilters.filterSelections.schools;
		if (schoolsSelected.length) {
			schoolIds = schoolsSelected;
		}

		let gradeLevelIds = state.StudentFilters.filterOptions.gradeLevels.map((gradeLevel) => gradeLevel.id);
		const gradeLevelsSelected = state.StudentFilters.filterSelections.gradeLevels;
		if (gradeLevelsSelected.length) {
			gradeLevelIds = gradeLevelsSelected;
		}

		const params = {
			start_date: parseInt(state.StudentFilters.filterSelections.dateRange.start.getTime() / 1000),
			end_date: parseInt(state.StudentFilters.filterSelections.dateRange.end.getTime() / 1000),
			school_ids: schoolIds,
			include_uncategorized: gradeLevelIds.includes(UNCATEGORIZED_GRADE_LEVEL_ID),
			grade_ids: without(gradeLevelIds, UNCATEGORIZED_GRADE_LEVEL_ID),
		};

		const group_id = state.StudentFilters.filterSelections.groups;

		if (group_id) {
			params.group_id = group_id;
		}

		const { limit } = state.studentListPagination;
		const { page, search, order } = state.StudentFilters.studentListParams;
		const searchParam = search ? { search } : {};
		const orderParams = (order.by && order.dir) ? { order_by: order.by, order_by_dir: order.dir } : {};

		return { ...params, ...searchParam, ...orderParams, page, limit };
	},
};

const actions = {
	async getStudents({ commit, getters }) {
		commit("SET_IS_LOADING_STUDENT_LIST", { isLoadingStudentList: true });

		const params = getters["getParams"];

		try {
			const { students, pagination } = await StudentAnalyticsApi.getStudents("", params);

			commit("SET_STUDENT_LIST", { studentList: students });
			commit("SET_STUDENT_LIST_PAGINATION", { studentListPagination: pagination });

			commit("StudentFilters/SET_DATE_RANGE_SUBMITTED");
		} catch (error) {
			commit("SET_IS_ERROR_STUDENT_LIST", { isErrorStudentList: true });
			Sentry.captureException(error);
		} finally {
			commit("SET_IS_LOADING_STUDENT_LIST", { isLoadingStudentList: false });
		}
	},
};

export default {
	namespaced: true,
	actions,
	getters,
	mutations,
	state,
	modules: {
		StudentFilters,
	},
};
