export const ASSESSMENTS_STEPS = {
	RECOGNITIONS: "recognitions",
	QUESTION: "question",
	TAG: "tag",
	NON_ACADEMIC: "nonAcademic",
	FEEDBACK: "feedback",
	SUBMIT: "submit",
};

export const ASSESSMENTS_STEPS_ARRAY = [
	ASSESSMENTS_STEPS.RECOGNITIONS,
	ASSESSMENTS_STEPS.QUESTION,
	ASSESSMENTS_STEPS.TAG,
	ASSESSMENTS_STEPS.NON_ACADEMIC,
	ASSESSMENTS_STEPS.FEEDBACK,
	ASSESSMENTS_STEPS.SUBMIT,
];
