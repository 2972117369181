var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "shift-details",
          expression: "'shift-details'",
        },
        {
          name: "data-cy",
          rawName: "v-data-cy",
          value: "shift-details",
          expression: "'shift-details'",
        },
      ],
      staticClass: "tw-font-bold tw-text-sm",
    },
    [_vm._v("\n\t" + _vm._s(_vm.formattedCurrentShift) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }