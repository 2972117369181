import { getFlag } from "@paper-co/web-toolkit";
export default async function(to, from, next) {
	const isMaintenanceMode = await getFlag("maintenance-mode");
	const isLeavingMaintenancePage = "/maintenance" !== to.path;
	const isGoingToMaintenancePage = !isLeavingMaintenancePage;
	if (isMaintenanceMode && isLeavingMaintenancePage) {
		return next("/maintenance");
	}
	if (!isMaintenanceMode && isGoingToMaintenancePage) {
		return next("/");
	}
	return next();
}
