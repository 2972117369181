var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _setup.breakType !== null,
          expression: "breakType !== null",
        },
        {
          name: "jest",
          rawName: "v-jest",
          value: "break-rc-placeholder",
          expression: "'break-rc-placeholder'",
        },
      ],
      staticClass:
        "tw-flex tw-justify-center tw-flex-col tw-items-center tw-h-full",
    },
    [
      _c(_setup.OrigamiIcon, {
        directives: [
          {
            name: "data-cy",
            rawName: "v-data-cy",
            value: "break-rc-icon",
            expression: "'break-rc-icon'",
          },
          {
            name: "jest",
            rawName: "v-jest",
            value: "break-rc-icon",
            expression: "'break-rc-icon'",
          },
        ],
        staticClass: "tw-mb-4",
        attrs: { width: "64", height: "64", name: _setup.breakType?.icon },
      }),
      _vm._v(" "),
      _c(
        "p",
        {
          directives: [
            {
              name: "data-cy",
              rawName: "v-data-cy",
              value: "break-rc-body-text",
              expression: "'break-rc-body-text'",
            },
          ],
          staticClass:
            "tw-text-center tw-text-md tw-text-origami-grey-300 tw-font-semibold",
        },
        [
          _vm._v("\n\t\tYou are currently on a "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "break-rc-label",
                  expression: "'break-rc-label'",
                },
              ],
            },
            [_vm._v(_vm._s(_setup.breakType?.label))]
          ),
        ]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "tw-text-center tw-text-origami-grey-300" }, [
        _vm._v("\n\t\tand will not be able to review at this time.\n\t"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }