import { render, staticRenderFns } from "./MicroFrontendContent.vue?vue&type=template&id=acbedbc8&scoped=true&"
import script from "./MicroFrontendContent.vue?vue&type=script&setup=true&lang=js&"
export * from "./MicroFrontendContent.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./MicroFrontendContent.vue?vue&type=style&index=0&id=acbedbc8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acbedbc8",
  null
  
)

export default component.exports