<template>
	<OrigamiCard class="tw-pt-6 tw-pb-4">
		<div class="tw-mx-6 tw-border-b-2 tw-border-origami-grey-100">
			<slot name="weekDetails" />
		</div>
		<div class="tw-mx-6 tw-mb-1">
			<slot name="totalHours" />
		</div>
		<div
			v-if="detailsVisible"
			v-jest="'hours-breakdown'"
			class="tw-mx-6"
		>
			<slot name="tutorHoursBreakdown" />
		</div>
		<div class="tw-flex tw-justify-center tw-border-t tw-border-origami-grey-100">
			<OrigamiButton
				v-jest="'tutor-scheduled-hours-details-button'"
				variant="tertiary"
				class="tw-mt-3"
				:is-disabled="hasNoShifts"
				@click.native="detailsVisible = !detailsVisible"
			>
				{{ detailsVisible ? "Hide details" : "Show more details" }}
			</OrigamiButton>
		</div>
	</OrigamiCard>
</template>

<script>
import { OrigamiCard, OrigamiButton } from "@origami/vue2";
export default {
	components: {
		OrigamiCard,
		OrigamiButton,
	},
	data() {
		return {
			detailsVisible: false,
		};
	},
	computed: {
		hasNoShifts() {
			return this.$store.getters["TutorScheduledHours/getLengthOfShifts"] === 0;
		},
	},
};
</script>
