var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "notification-text",
          expression: "'notification-text'",
        },
        {
          name: "data-cy",
          rawName: "v-data-cy",
          value: "notification-text",
          expression: "'notification-text'",
        },
      ],
      staticClass: "tw-p-4 tw-border-2 tw-rounded-[4px] tw-mb-4",
      class: {
        "tw-bg-origami-red-100 tw-border-origami-red-400":
          _vm.notificationType === "error",
        "tw-bg-origami-yellow-100 tw-border-origami-yellow-300":
          _vm.notificationType === "warning",
        "tw-bg-origami-blue-100 tw-border-origami-blue-300":
          _vm.notificationType === "info",
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }