import SubjectsAPI from "@/services/api/Subjects.js";
import TopicsAPI from "@/services/api/Topics.js";

import CoursesAPI from "../../services/courses.js";

const COURSE_INCLUDE =
	"district.schools,sections.teachers,sections.topics,sections.school,sections.students,concepts.topic.subject,topics.subject,subjects";

const getDefaultState = () => ({
	isLoading: true,
	currentPage: 1,
	totalPages: 0,
	course: {
		id: 0,
		clever_id: null,
		district_id: 0,
		district: {
			data: {
				id: 0,
				country_code: "",
				created_at: 0,
				name: "",
				region_code: "",
				timezone: "",
				updated_at: 0,
			},
		},
		clever_name: null,
		name: "",
		is_hidden_for_students: false,
		last_synced_at: 0,
		topics: {
			data: [],
		},
		classrooms: {
			data: [],
		},
		is_clever_synced: false,
	},
	subjects: [],
	selectedSubjectIds: [],
	selectedTopicIds: [],
	selectedConceptIds: [],
	relatedTags: [],
	relatedSubjects: [],
	relatedTopics: [],
	relatedConcepts: [],
	sectionsTableHeaders: [
		{
			value: "id",
			header: "ID",
			orderable: false,
			state: null,
		},
		{
			value: "name",
			header: "section name",
			orderable: false,
			state: null,
		},
		{
			value: "school",
			header: "school",
			orderable: false,
			state: null,
		},
		{
			value: "teacher",
			header: "Teacher",
			orderable: false,
			state: null,
		},
		{
			value: "status",
			header: "status",
			orderable: false,
			state: null,
		},
		{
			value: "active_from",
			header: "start date",
			orderable: false,
			state: null,
		},
		{
			value: "active_to",
			header: "end date",
			orderable: false,
			state: null,
		},
	],
	navbarMainTab: "Customers",
});

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations: {
		RESET_STATE(state) {
			Object.assign(state, getDefaultState());
		},
		SET_COURSE(state, payload) {
			state.course = payload.course;
		},
		SET_IS_LOADING(state, payload) {
			state.isLoading = payload.isLoading;
		},
		SET_SUBJECTS(state, payload) {
			state.subjects = payload.subjects;
		},
		SET_TOTAL_PAGES(state, payload) {
			state.totalPages = Math.floor(payload.totalPages / 100);
		},
		SET_CURRENT_PAGE(state, payload) {
			state.currentPage = payload.currentPage;
		},
		SET_SELECTED_SUBJECTS(state, payload) {
			state.selectedSubjectIds = payload.selectedSubjectIds;
		},
		SET_SELECTED_TOPICS(state, payload) {
			state.selectedTopicIds = payload.selectedTopicIds;
		},
		SET_SELECTED_CONCEPTS(state, payload) {
			state.selectedConceptIds = payload.selectedConceptIds;
		},
		SET_RELATED_TAGS(state, payload) {
			state.relatedTags = payload.relatedTags;
		},
		SET_RELATED_SUBJECTS(state, payload) {
			state.relatedSubjects = payload.relatedSubjects;
		},
		SET_RELATED_TOPICS(state, payload) {
			state.relatedTopics = payload.relatedTopics;
		},
		SET_RELATED_CONCEPTS(state, payload) {
			state.relatedConcepts = payload.relatedConcepts;
		},
	},
	getters: {
		ltiTitle(state) {
			return state.course.synced_with;
		},
		subjectsForTagsFilterTable(state) {
			return state.subjects;
		},
	},
	actions: {
		setCurrentPage({ commit }, payload) {
			commit("SET_CURRENT_PAGE", {
				currentPage: payload.newPage,
			});
		},
		async getCourse({ commit }, payload) {
			const params = {
				include: COURSE_INCLUDE,
			};
			try {
				const { data } = await CoursesAPI.show(payload.courseId, params);
				commit("SET_COURSE", {
					course: data.data,
				});
				commit("SET_RELATED_SUBJECTS", {
					relatedSubjects: data.data.subjects,
				});
				commit("SET_RELATED_TOPICS", {
					relatedTopics: data.data.topics,
				});
				commit("SET_RELATED_CONCEPTS", {
					relatedConcepts: data.data.concepts,
				});
				return Promise.resolve(data);
			} catch (error) {
				return Promise.reject(error);
			} finally {
				commit("SET_IS_LOADING", {
					isLoading: false,
				});
			}
		},
		async getSubjects({ commit }) {
			try {
				const params = { include: "topics,topics.concepts" };
				const { data } = await SubjectsAPI.list(params);
				commit("SET_SUBJECTS", {
					subjects: data.data,
				});
				return Promise.resolve(data);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async updateCourse({ state, commit }, payload) {
			payload["include"] = COURSE_INCLUDE;
			try {
				const { data } = await CoursesAPI.update(state.course.id, payload);
				commit("SET_COURSE", {
					course: data.data,
				});
				commit("SET_RELATED_SUBJECTS", {
					relatedSubjects: data.data.subjects,
				});
				commit("SET_RELATED_TOPICS", {
					relatedTopics: data.data.topics,
				});
				commit("SET_RELATED_CONCEPTS", {
					relatedConcepts: data.data.concepts,
				});
				return Promise.resolve(data);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async getTopicsOfSubject({}, payload) {
			try {
				const { data } = await TopicsAPI.getTopicsOfSubject(payload.subjectId);
				return Promise.resolve(data);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async getConceptsOfTopic({}, payload) {
			try {
				const { data } = await TopicsAPI.getConceptsOfTopic(payload.topicId);
				return Promise.resolve(data);
			} catch (error) {
				return Promise.reject(error);
			}
		},
	},
};
