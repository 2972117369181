var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "container",
          expression: "'container'",
        },
      ],
      staticClass: "tw-flex tw-flex-col",
      class: { "tw-justify-center tw-items-center tw-h-full": _vm.isLoading },
    },
    [
      _vm.isLoading
        ? _c("BaseLoader", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "loader",
                expression: "'loader'",
              },
            ],
            staticClass: "tw-h-16 tw-w-16 tw-text-blue-regular",
          })
        : [
            _c(
              "p",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "send-student-recognition",
                    expression: "'send-student-recognition'",
                  },
                ],
                staticClass: "assessmentRecognitionStep__text",
              },
              [
                _vm._v(
                  "\n\t\t\tSend " +
                    _vm._s(_vm.student.first_name) +
                    " stickers to recognize this session\n\t\t"
                ),
              ]
            ),
            _vm._v(" "),
            _c("BaseDropdown", {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "recognitions-dropdown",
                  expression: "'recognitions-dropdown'",
                },
              ],
              staticClass: "tw-mb-4 tw-h-14",
              attrs: {
                "aria-label": "Selected Recognition",
                items: _vm.recognitionTypes,
              },
              scopedSlots: _vm._u([
                {
                  key: "filters",
                  fn: function () {
                    return [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.baseDropdownPlaceholder) +
                          "\n\t\t\t"
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "default",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "BaseCheckbox",
                        {
                          attrs: {
                            "checkbox-id": `recognition-${item.id}`,
                            "checkbox-value": item.id,
                          },
                          model: {
                            value: item.selected,
                            callback: function ($$v) {
                              _vm.$set(item, "selected", $$v)
                            },
                            expression: "item.selected",
                          },
                        },
                        [
                          _c("template", { slot: "text" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.upperFirst(item.name)) +
                                "\n\t\t\t\t\t"
                            ),
                          ]),
                        ],
                        2
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("p", { staticClass: "assessmentRecognitionStep__text" }, [
              _vm._v("\n\t\t\tAdd a personalized message (optional)\n\t\t"),
            ]),
            _vm._v(" "),
            _c("BaseTextArea", {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "recognitions-personalized-message",
                  expression: "'recognitions-personalized-message'",
                },
                {
                  name: "data-cy",
                  rawName: "v-data-cy",
                  value: "recognitions-personalized-message",
                  expression: "'recognitions-personalized-message'",
                },
              ],
              staticClass: "tw-h-32",
              attrs: {
                placeholder: _vm.textAreaPlaceHolder,
                disabled: !_vm.selectedRecognitions.length,
              },
              model: {
                value: _vm.message,
                callback: function ($$v) {
                  _vm.message = $$v
                },
                expression: "message",
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }