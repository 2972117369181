var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BModal",
    {
      ref: "simple-modal",
      attrs: {
        id: _vm.id,
        "hide-header": true,
        "no-close-on-backdrop": true,
        "no-fade": true,
        centered: true,
        "aria-labelledby": "modal-header-title",
        "aria-describedby": "modal-body-message",
      },
      model: {
        value: _vm.isSimpleModalShowing,
        callback: function ($$v) {
          _vm.isSimpleModalShowing = $$v
        },
        expression: "isSimpleModalShowing",
      },
    },
    [
      _c(
        "button",
        {
          directives: [
            {
              name: "data-cy",
              rawName: "v-data-cy",
              value: "btn-close-simpleModal",
              expression: "'btn-close-simpleModal'",
            },
          ],
          staticClass: "close",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.hideModal()
            },
          },
        },
        [_vm._v("\n\t\t×\n\t")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "modalHeader" }, [
        _c(
          "h1",
          {
            directives: [
              {
                name: "data-cy",
                rawName: "v-data-cy",
                value: "modal-header-title",
                expression: "'modal-header-title'",
              },
            ],
            staticClass: "modalHeader__title",
            attrs: { id: "modal-header-title" },
          },
          [_vm._v("\n\t\t\t" + _vm._s(_vm.title) + "\n\t\t")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modalBody" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "data-cy",
                rawName: "v-data-cy",
                value: "modal-body-message",
                expression: "'modal-body-message'",
              },
            ],
            staticClass: "modalBody__message",
            attrs: { id: "modal-body-message" },
          },
          [_vm._v("\n\t\t\t" + _vm._s(_vm.message) + "\n\t\t")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "modal-footer" },
        [
          _c(
            "BaseButton",
            {
              directives: [
                {
                  name: "data-cy",
                  rawName: "v-data-cy",
                  value: "modal-footer-button",
                  expression: "'modal-footer-button'",
                },
              ],
              attrs: { type: _vm.SimpleModal.buttonType },
              nativeOn: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onSubmit()
                },
              },
            },
            [
              _c("template", { slot: "text" }, [
                _vm._v("\n\t\t\t\t" + _vm._s(_vm.buttonText) + "\n\t\t\t"),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }