var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "OrigamiButton",
    {
      staticClass: "tw-mt-1",
      nativeOn: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.handleClick.apply(null, arguments)
        },
      },
    },
    [
      _vm._v(
        "\n\t" + _vm._s(_vm.$t("trophies.profile.change_avatar_btn")) + "\n"
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }