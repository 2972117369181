import api from "@/services/api/Api.js";

export default {
	/**
	 * Creates a user
	 *
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	create(data) {
		return api().post(`/users`, data);
	},

	/**
	 * Updates a user
	 *
	 * @param {number} userId
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	update(userId, data) {
		return api().post(`/users/${userId}`, data);
	},

	/**
	 * Find a user by id
	 *
	 * @arg {number} userId
	 * @arg {object} params
	 * @returns {AxiosPromise}
	 */
	show(userId, params) {
		return api().get(`users/${userId}`, {
			params: params,
		});
	},

	/**
	 * Get all users
	 *
	 * @arg {object} params
	 * @returns {AxiosPromise}
	 */
	list(params) {
		return api().get(`users`, {
			params: params,
		});
	},

	/**
	 * Get filtered users
	 *
	 * @arg {object} params
	 * @returns {AxiosPromise}
	 */
	filter(params) {
		return api().get(`users.filter`, {
			params: params,
		});
	},

	/**
	 * Updates a user's score for the NPS Campaign
	 *
	 * @param {number} userId
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	updateUserScoreForNPSCampaign(userId, data) {
		return api().post(`users/${userId}/nps`, data);
	},

	/**
	 * get users from a school
	 * @arg {number} schoolId
	 * @arg {object} params
	 * @returns {AxiosPromise}
	 */
	getSchoolUsers(schoolId, params) {
		return api().get(`schools/${schoolId}/users`, {
			params: params,
		});
	},
	/**
	 * get users from a section
	 * @arg {Number} sectionId
	 * @arg {Object} params
	 * @arg {String} params.include options: "acceptedBackupShifts","administrator","activeSessions","sections","education",
	 * "preferredLanguage", "notifications","promo","requestedBackupShifts","roles","sessions","student",
	 * "schools","superuser","teacher","tutor"
	 * @returns {AxiosPromise}
	 */
	listSectionUsers(sectionId, params) {
		return api().get(`sections/${sectionId}/users`, {
			params: params,
		});
	},
};
