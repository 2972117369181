import Vue from "vue";

import { LanguagesAPI } from "@/modules/Languages/index.js";
import TopicsAPI from "@/services/api/Topics.js";
import TutorsAPI from "@/services/api/Tutors.js";

const getDefaultState = () => ({
	isLoadingTutors: true,
	notScheduledOn: "",
	orderBy: "",
	searchText: "",
	totalUsersFound: 0,
	tableHeaders: [
		{
			value: "id",
			header: "ID",
			orderable: true,
			state: null,
		},
		{
			value: "role",
			header: "role",
			orderable: true,
			state: null,
		},
		{
			value: "name",
			header: "full name",
			orderable: true,
			state: null,
		},
		{
			value: "is_new",
			header: "New Tutor",
			orderable: false,
			state: null,
		},
		{
			value: "tutor_since",
			header: "Tutor Since",
			orderable: true,
			state: null,
		},
		{
			value: "status",
			header: "Status",
			orderable: true,
			state: null,
		},
		{
			value: "pending_assessments",
			header: "Pending Assessments",
			orderable: true,
			state: null,
		},
		{
			value: "impersonation",
			header: "",
			orderable: false,
			state: null,
		},
	],
	tutorFilters: {
		roles: {
			selected: [],
			options: [
				{
					id: "tutor",
					name: "Tutor",
				},
				{
					id: "tutor-manager",
					name: "Tutor Manager",
				},
			],
		},
		topics: {
			selected: [],
			options: [],
		},
		status: {
			selected: [],
			options: [
				{
					id: 0,
					name: "inactive",
				},
				{
					id: 1,
					name: "active",
				},
			],
		},
		sessionLanguage: {
			selected: [],
			options: [],
		},
		essayLanguage: {
			selected: [],
			options: [],
		},
	},
	filterKeys: [
		{
			title: "roles",
			value: "roles",
			type: "checkbox",
		},
		{
			title: "topics",
			value: "topics",
			type: "checkbox",
		},
		{
			title: "status",
			value: "status",
			type: "checkbox",
		},
		{
			title: "Not scheduled on",
			value: "notScheduledOn",
			type: "date",
		},
		{
			title: "Review Language",
			value: "essayLanguage",
			type: "radio",
		},
		{
			title: "Session Language",
			value: "sessionLanguage",
			type: "radio",
		},
	],
	tutors: [],
	navbarMainTab: "Service",
	pagination: {
		total_pages: 0,
		current_page: 0,
	},
});

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations: {
		RESET_STATE(state) {
			Object.assign(state, getDefaultState());
		},
		SET_FILTER_IN_FILTERS_KEY_VALUE(state, payload) {
			Vue.set(state.tutorFilters[payload.filter], payload.key, payload.value);
		},
		SET_IS_LOADING_TUTORS(state, payload) {
			state.isLoadingTutors = payload.isLoadingTutors;
		},
		SET_NOT_SCHEDULED_ON(state, payload) {
			state.notScheduledOn = payload.notScheduledOn;
		},
		SET_ORDER_BY(state, payload) {
			state.orderBy = payload.orderBy;
		},
		SET_SEARCH_TEXT(state, payload) {
			state.searchText = payload.searchText;
		},
		SET_TOTAL_USERS_FOUND(state, payload) {
			state.totalUsersFound = payload.totalUsersFound;
		},
		SET_TABLE_HEADERS(state, payload) {
			state.tableHeaders = payload.tableHeaders;
		},
		SET_TUTORS(state, payload) {
			state.tutors = payload.tutors;
		},
		SET_PAGINATION(state, payload) {
			state.pagination = payload.pagination;
		},
		SET_CURRENT_PAGE(state, payload) {
			state.pagination.current_page = payload.currentPage;
		},
		RESET_PAGINATION(state) {
			state.pagination = { total_pages: 0, current_page: 0 };
		},
	},
	getters: {
		filtersAsParamsObject(state) {
			const params = {};

			if (state.searchText !== "") {
				params["search"] = state.searchText;
			}

			if (state.orderBy !== "") {
				params["orderBy"] = state.orderBy;
			}

			if (state.notScheduledOn !== "") {
				params["notScheduledOn"] = state.notScheduledOn;
			}

			Object.keys(state.tutorFilters).forEach((filter) => {
				params[filter] = state.tutorFilters[filter].selected;
			});

			return params;
		},
	},
	actions: {
		async getTutorAvailabilityFilteredTutorData({ state, getters, commit }) {
			commit("SET_IS_LOADING_TUTORS", {
				isLoadingTutors: true,
			});
			const params = {
				...getters.filtersAsParamsObject,
				page: state.pagination.current_page,
				showAll: 0,
			};
			try {
				const response = await TutorsAPI.list(params);
				const { data, meta } = response.data;
				commit("SET_PAGINATION", {
					pagination: meta.pagination,
				});
				commit("SET_TUTORS", {
					tutors: data,
				});
				commit("SET_TOTAL_USERS_FOUND", {
					totalUsersFound: meta.pagination.total,
				});
			} catch (error) {
				Sentry.captureException(error);
				commit("RESET_PAGINATION");
			} finally {
				commit("SET_IS_LOADING_TUTORS", {
					isLoadingTutors: false,
				});
			}
		},
		async getTopicsFilterOptions({ commit }) {
			try {
				const topics = [];
				const response = await TopicsAPI.list({});
				response.data.data.forEach(function(topic) {
					topics.push({
						name: topic.name,
						id: topic.id,
					});
				});
				commit("SET_FILTER_IN_FILTERS_KEY_VALUE", {
					filter: "topics",
					key: "options",
					value: topics,
				});
				return Promise.resolve(topics);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async getLanguagesFilterOptions({ commit }) {
			try {
				const sessionLanguages = [];
				const essayLanguages = [];
				const response = await LanguagesAPI.list();
				response.data.data.forEach((language) => {
					essayLanguages.push({
						id: language.id,
						name: language.language,
					});

					if (language.tutorable === 1) {
						sessionLanguages.push({
							id: language.id,
							name: language.language,
						});
					}
				});
				commit("SET_FILTER_IN_FILTERS_KEY_VALUE", {
					filter: "essayLanguage",
					key: "options",
					value: essayLanguages,
				});
				commit("SET_FILTER_IN_FILTERS_KEY_VALUE", {
					filter: "sessionLanguage",
					key: "options",
					value: sessionLanguages,
				});

				return Promise.resolve();
			} catch (e) {
				return Promise.reject(e);
			}
		},
		async setTutorsCurrentPageList({ commit, dispatch }, payload) {
			commit("SET_CURRENT_PAGE", {
				currentPage: payload.newPage,
			});
			try {
				return dispatch("getTutorAvailabilityFilteredTutorData");
			} catch (error) {
				Sentry.captureException(error);
			}
		},
	},
};
