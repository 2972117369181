<template>
	<div id="app">
		<OrigamiIconSprite />
		<NavigationWrapper
			v-jest="'navigation-wrapper'"
			:view-as-links="getViewAsLinks"
			:normal-links="getNormalLinks"
			:user-links="getUserLinks"
			:languages="Lang.languages"
		>
			<router-view />
		</NavigationWrapper>
		<BannersContainer />
		<BaseToaster
			v-if="featureFlagReferralsIsEnabled"
			v-data-cy="'referral-toast'"
			class="tw-z-[1100]"
		/>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { OrigamiIconSprite } from "@origami/vue2";

import { REFERRAL_REDEMPTION_STATUS_KEY } from "@/modules/Referrals/utilities/index.js";
import NavigationWrapper from "@/modules/Navigation/components/NavigationWrapper.vue";

import BannersContainer from "./components/patterns/BannersContainer.vue";

export default {
	name: "App",
	metaInfo: {
		title: "Home",
		titleTemplate: "%s | PAPER",
	},
	components: {
		OrigamiIconSprite,
		NavigationWrapper,
		BannersContainer,
		BaseToaster: () => import("@/components/elements/BaseToaster.vue"),
	},
	data: () => ({
		featureFlagPaperLiveIsEnabled: false,
		featureFlagPaperAcademyLink: false,
		featureFlagMathD2IsEnabled: false,
		featureFlagReferralsIsEnabled: false,
		featureFlagTeachingAssetsAdminMfe: false,
		featureFlagTutorMissions: false,
		featureFlagScheduleBuilderIsEnabled: false,
		isDesktopXL: false,
	}),
	computed: {
		...mapState(["currentUser", "Lang"]),
		getViewAsLinks() {
			if (!this.currentUser) {
				return [];
			}
			const links = [
				{
					title: "navbar.view_as_superuser",
					target: "/superuser",
					role: "superuser",
				},
				{
					title: "navbar.view_as_district_manager",
					target: "/district-manager",
					role: "district-manager",
				},
				{
					title: "navbar.view_as_administrator",
					target: "/administrator",
					role: "administrator",
				},
				{
					title: "navbar.view_as_teacher",
					target: "/teacher",
					role: "teacher",
				},
				{
					title: "navbar.view_as_tutor",
					target: "/tutor",
					role: "tutor",
				},
				{
					title: "navbar.view_as_platform_manager",
					target: "/tutor-manager/services",
					role: "tutor-manager",
				},
				{
					title: "navbar.view_as_student",
					target: "/student/dashboard",
					role: "student",
				},
				{
					title: "navbar.view_as_problem_creator",
					target: "paper-math/problem-creator",
					role: "paper-math/problem-creator",
				},
			];

			if (this.featureFlagScheduleBuilderIsEnabled) {
				links.push({
					title: "navbar.view_as_schedule_builder",
					target: process.env["MIX_SCHEDULE_BUILDER_WEB_URL"],
					role: "schedule-builder",
				});
			}

			if (this.featureFlagTeachingAssetsAdminMfe) {
				links.push({
					title: "navbar.view_as_clips_editor",
					target: "/teaching-assets/admin",
					role: "paper-clips/editor",
				});
			}

			const { roles, role } = this.currentUser;

			return links.filter((l) =>
				(roles.includes(l.role) || this.isScheduleBuilderRole(roles) === l.role)
				&& l.role !== role);
		},
		getUserLinks() {
			if (!this.currentUser) {
				return [];
			}

			const links = {
				teacher: [
					{
						title: "navbar.dashboard",
						target: "/teacher/dashboard",
						icon: "Dashboard",
					},
					{
						title: "navbar.students",
						target: "/teacher/students",
						icon: "Students",
					},
					{
						title: "navbar.activity",
						target: "/teacher/activity",
						icon: "Activity",
					},
				],
				administrator: [
					{
						title: "navbar.dashboard",
						icon: "Dashboard",
						target: "/administrator",
					},
					{
						title: "navbar.students",
						target: "/administrator/students",
						icon: "Students",
					},
					{
						title: "navbar.activity",
						target: "/administrator/activity",
						icon: "Activity",
					},
				],
				["district-manager"]: [
					{
						title: "navbar.dashboard",
						target: "/district-manager",
						icon: "Dashboard",
					},
					{
						title: "navbar.students",
						target: "/district-manager/students",
						icon: "Students",
					},
					{
						title: "navbar.activity",
						target: "/district-manager/activity",
						icon: "Activity",
					},
				],
				superuser: [
					{
						title: "navbar.dashboard",
						target: "/superuser",
					},
					{
						title: "navbar.customers",
						target: "/superuser/customers",
					},
					{
						title: "navbar.service",
						target: "/superuser/services/",
					},
					{
						title: "navbar.communications",
						target: "/superuser/email-communications",
					},
					{
						title: "navbar.activity",
						target: "/superuser/activity",
					},
					{
						title: "navbar.blog",
						target: "https://paper.co/blog",
					},
				],
				["superuser-creator"]: [],
				["tutor-manager"]: [
					{
						title: "navbar.service",
						target: "/tutor-manager/services/",
					},
					{
						title: "navbar.activity",
						target: "/tutor-manager/activity",
					},
				],
				tutor: [
					{
						title: "navbar.dashboard",
						target: "/tutor",
						icon: "Dashboard",
					},
					{
						title: "navbar.classroom",
						target: "/tutor/classroom",
						icon: "Classroom",
					},
					{
						title: "navbar.review_center",
						target: "/tutor/review-center",
						icon: "Essay",
					},
				],
				["paper-math/problem-creator"]: [
					{
						title: "navbar.missionsAdmin",
						target: "/paper-math/missions-admin",
						icon: "PaperMath",
					},
					{
						title: "navbar.problemGenerator",
						target: "/paper-math/problem-generator",
						icon: "PaperMath",
					},
					{
						title: "navbar.problemCreator",
						target: "/paper-math/problem-creator",
						icon: "PaperMath",
					},
				],
				["paper-clips/editor"]: [
					{
						title: "navbar.paperClips",
						target: "/teaching-assets/admin/clips",
					},
					{
						title: "navbar.paperClipsTags",
						target: "/teaching-assets/admin/tags",
					},
				],
				student: this.getStudentLinks,
			};

			if (this.featureFlagTutorMissions) {
				links.tutor.push({
					title: "navbar.paperMath",
					target: "/tutor/missions",
					icon: "PaperMath",
				});
			}

			if (this.featureFlagIsTeacherReadingEnabled) {
				links.teacher.push({
					title: "navbar.reading",
					target: "/reading",
					icon: "Reading",
				});
			}
			if (!this.isDesktopXL) {
				links.teacher.push({
					title: this.featureFlagPaperAcademyLink ? "navbar.resourceCenter" : "navbar.resourceHub",
					target: "https://sites.google.com/paper.co/resourcehub",
					icon: "external",
				});
			}
			if (this.featureFlagPaperAcademyLink && !this.isDesktopXL) {
				links.teacher.push({
					title: "navbar.paperAcademy",
					target: "https://academy.paper.co/",
					icon: "external",
				});
			}

			links.tutor.push({
				title: "navbar.pave",
				target: "https://paper.myabsorb.ca/#/dashboard",
				icon: "Pave",
			});

			const { role } = this.currentUser;
			if (!links[role]) {
				throw new Error("[SPA] role not found in userLinks App.vue");
			}
			return links[role];
		},
		getNormalLinks() {
			if (!this.currentUser) {
				return [];
			}

			const { role } = this.currentUser;
			const { isImpersonate } = this.$store.state;

			const normalLinks = [
				{
					title: "navbar.settings",
					target: `/${role}/settings`,
				},
				{
					title: "navbar.logout",
					target: "/logout",
				},
			].filter(Boolean);

			if (isImpersonate) {
				normalLinks.push({
					title: "navbar.impersonation",
					target: "/stop-impersonation",
				});
			}

			return normalLinks;
		},
		getStudentLinks() {
			if (!this.currentUser) {
				return [];
			}

			const { school } = this.currentUser;
			const studentLinks = [];

			if (!school || school.id !== 134) {
				studentLinks.push({
					title: "navbar.dashboard",
					target: "/dashboard",
					icon: "Dashboard",
				});
			}

			if (school?.features_live_help) {
				studentLinks.push({
					title: "navbar.classroom",
					target: "/student/classroom",
					icon: "Classroom",
				});
			}

			if (school?.features_essay_review) {
				studentLinks.push({
					title: "navbar.review_center",
					target: "/student/review-center",
					icon: "Essay",
					notificationCount: this.$store.getters["Navbar/reviewedEssaysNotificationCount"],
				});
			}

			if (this.featureFlagPaperLiveIsEnabled) {
				studentLinks.push({
					title: "navbar.paperlive",
					target: "/paperlive",
					icon: "Paperlive",
				});
			}

			if (this.featureFlagMathD2IsEnabled) {
				studentLinks.push({
					title: "navbar.paperMath",
					target: "/math?ref=nav_icon",
					icon: "PaperMath",
				});
			}

			studentLinks.push({
				title: "navbar.paperMissions",
				target: "/missions?ref=nav_icon",
				icon: "PaperMath",
			});

			return studentLinks;
		},
		isUserStudent() {
			return this.currentUser?.role === "student";
		},
	},
	watch: {
		currentUser: {
			async handler(newCurrentUser) {
				this.featureFlagPaperLiveIsEnabled = await this.$getFlag("paper-live-access");
				this.featureFlagMathD2IsEnabled = await this.$getFlag("PE-11990-STEM-D2");
				this.featureFlagPaperAcademyLink = await this.$getFlag("GROW-2309-paper-academy-link");
				this.featureFlagIsTeacherReadingEnabled = await this.$getFlag("CORE-55-readlee-teacher-integration");
				this.featureFlagReferralsIsEnabled = await this.$getFlag("GROW-414-referrals");
				this.featureFlagTeachingAssetsAdminMfe = await this.$getFlag("PE-20174-teaching-assets-admin-mfe");
				this.featureFlagTutorMissions = await this.$getFlag("PE-20738-Tutor-Missions");
				this.featureFlagScheduleBuilderIsEnabled = await this.$getFlag("SER-783-Schedule-Builder");

				if (newCurrentUser && this.featureFlagReferralsIsEnabled) {
					this.checkForReferralRedemptionStatus();
				}
			},
			immediate: true,
		},
		isUserStudent: {
			handler: async function() {
				if (this.isUserStudent) {
					await this.$store.dispatch("Student/Profile/get");
				}
			},
			immediate: true,
		},
	},
	mounted() {
		this.getIsDesktopXL();
		window.addEventListener("resize", this.getIsDesktopXL);
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.getIsDesktopXL);
	},
	methods: {
		isScheduleBuilderRole(rolesArray) {
			if (rolesArray.some((role) => /schedule-builder/g.test(role))) {
				return "schedule-builder";
			}
		},
		checkForReferralRedemptionStatus() {
			const redemptionStatus = window.sessionStorage.getItem(REFERRAL_REDEMPTION_STATUS_KEY);
			window.sessionStorage.removeItem(REFERRAL_REDEMPTION_STATUS_KEY);

			if (redemptionStatus) {
				this.$store.dispatch("Toaster/showToaster", {
					text: redemptionStatus === "true" ?
						this.$t("referrals.success") :
						this.$t("referrals.failed"),
				});
			}
		},
		getIsDesktopXL() {
			this.isDesktopXL = window.matchMedia("(min-width: 1280px)").matches;
		},
	},
};
</script>
<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style>
.tippy-box {
	background-color: transparent;
}

.tippy-box[data-theme~="origami-card"] {
	background: transparent;
	color: initial;
	font-size: initial;
	border-radius: initial;
}
.tippy-box[data-theme~="origami-card"] .tippy-content {
	padding: 0 1rem;
}
.tippy-box[data-theme~="origami-card"] .tippy-arrow {
	width: 1.5rem;
	height: 1.5rem;
	color: var(--origami-page-bg);
	z-index: 1;
}
.tippy-box[data-theme~="origami-card"] .tippy-arrow::before {
	transform: rotate(45deg);
	top: calc(-0.75rem + 4.5px);
	border-color: var(--origami-grey-200);
	background: var(--origami-white);
	width: 0.75rem;
	height: 0.75rem;
	border-width: 1px 0 0 1px;
}

@media (prefers-reduced-motion) {
	html {
		scroll-behavior: auto;
	}
}
</style>
