import api from "@/services/api/Api.js";

export default {
	/**
	 * @param {object} data
	 * @returns {AxiosPromise<any>}
	 */
	update(data) {
		return api().post("me/notification-options", data);
	},
};
