export default {
	en: {
		teaching_assets: {
			thumbnail: {
				alt: "Video preview for {name}",
			},
		},
	},
	fr: {},
	es: {},
};
