<template>
	<div
		v-jest="'notification-text'"
		v-data-cy="'notification-text'"
		class="tw-p-4 tw-border-2 tw-rounded-[4px] tw-mb-4"
		:class="{
			'tw-bg-origami-red-100 tw-border-origami-red-400': notificationType === 'error',
			'tw-bg-origami-yellow-100 tw-border-origami-yellow-300': notificationType === 'warning',
			'tw-bg-origami-blue-100 tw-border-origami-blue-300': notificationType === 'info',
		}"
	>
		<slot />
	</div>
</template>

<script>
export default {
	name: "BreaksNotification",
	props: {
		notificationType: {
			type: String,
			default: "error",
		},
	},
};
</script>
