var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "OrigamiCard",
    {
      staticClass:
        "tw-relative tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-between tw-text-center md:tw-text-left tw-px-4 md:tw-px-6 tw-py-4",
      class: _vm.bgColorClass,
    },
    [
      _c(
        "div",
        { staticClass: "tw-flex-shrink-0" },
        [
          _vm._t("image", function () {
            return [
              _vm.image
                ? _c("img", {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "bannerImage",
                        expression: "'bannerImage'",
                      },
                    ],
                    staticClass: "tw-w-full tw-object-contain",
                    attrs: { src: _vm.image, alt: _vm.title },
                  })
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tw-flex-1 tw-px-6 tw-mb-4 md:tw-mb-0" },
        [
          _vm._t("title", function () {
            return [
              _c(
                "h1",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "bannerTitle",
                      expression: "'bannerTitle'",
                    },
                  ],
                  staticClass: "tw-font-bold md:tw-text-lg tw-mb-1",
                },
                [_vm._v("\n\t\t\t\t" + _vm._s(_vm.title) + "\n\t\t\t")]
              ),
            ]
          }),
          _vm._v(" "),
          _vm._t("body", function () {
            return [
              _vm.body
                ? _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "bannerBody",
                          expression: "'bannerBody'",
                        },
                      ],
                    },
                    [_vm._v("\n\t\t\t\t" + _vm._s(_vm.body) + "\n\t\t\t")]
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.showMainAction
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "bannerAction",
                  expression: "'bannerAction'",
                },
              ],
              staticClass: "tw-w-full md:tw-w-auto",
            },
            [
              _c(
                "OrigamiButton",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "actionButton",
                      expression: "'actionButton'",
                    },
                  ],
                  staticClass:
                    "tw-w-full md:tw-w-auto tw-justify-center md:tw-text-left",
                  attrs: { "data-pendo-id": _vm.mainActionPendoId },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.$emit("main-action")
                    },
                  },
                },
                [
                  _vm._t("buttonText", function () {
                    return [
                      _vm._v(
                        "\n\t\t\t\t" + _vm._s(_vm.buttonText) + "\n\t\t\t"
                      ),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tw-hidden md:tw-block tw-pl-4" },
        [
          _c("OrigamiIconButton", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "closeButton",
                expression: "'closeButton'",
              },
            ],
            attrs: { "icon-name": "close", "aria-label": "close" },
            nativeOn: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tw-absolute tw-top-2 tw-right-2 md:tw-hidden" },
        [
          _c("OrigamiIconButton", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "closeButton",
                expression: "'closeButton'",
              },
            ],
            attrs: { "icon-name": "close", "aria-label": "close" },
            nativeOn: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }