<template>
	<BaseButton
		v-b-tooltip="{ title: $t('close') }"
		:variants="['outline', 'mini']"
		:aria-label="$t('close')"
		class="embeddedCloseButton tw-leading-none"
		v-bind="baseButtonProps"
		@click.native="handleClose"
		@mousedown.native.prevent
	>
		<IconClose class="tw-h-4 tw-w-4" />
	</BaseButton>
</template>

<script>
import BaseButton from "@/components/elements/BaseButton.vue";
import IconClose from "@/components/elements/icons/Close.vue";

import { CHROME_EXTENSION_EVENTS, sendMessageToExtension } from "../../utilities/index.js";

export default {
	components: {
		BaseButton,
		IconClose,
	},
	props: {
		...BaseButton.props,
	},
	computed: {
		baseButtonProps() {
			return Object.keys(BaseButton.props).reduce((acc, key) => {
				acc[key] = this[key];
				return acc;
			}, {});
		},
	},
	methods: {
		async handleClose() {
			try {
				await sendMessageToExtension({ type: CHROME_EXTENSION_EVENTS.SIDEBAR_HIDE });
				document.activeElement.blur();
			} catch (error) {
				Sentry.captureException(error);
			}
		},
	},
};
</script>

<style scoped>
.embeddedCloseButton {
	padding: 0.25rem;
}
</style>
