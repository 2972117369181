import Vue from "vue";
import VueSocketIO from "vue-socket.io";
import io from "socket.io-client";
import jsCookie from "js-cookie";
import * as Sentry from "@sentry/vue";
import { waitForUserIdentification, getFlag } from "@paper-co/web-toolkit";
import { omit } from "lodash";

const AUTH_RETRY_INTERVAL = 1000;
export const MAX_AUTH_ATTEMPTS = 5;
const AUTH_ERROR_STORAGE_KEY = "paper-rtm-socket-auth-error";

export async function resetIO() {
	const ioInstance = await getIOInstance();
	vueSocketIOInstance.listener.io = ioInstance;
	vueSocketIOInstance.listener.register();
	Vue.prototype.$socket = ioInstance;
}
let connectAttempts = 0;
export async function handleConnectError(e) {
	const isValidTokenError = e.data?.code === "invalid_token";
	if (isValidTokenError && ++connectAttempts <= MAX_AUTH_ATTEMPTS) {
		vueSocketIOInstance.listener.io.disconnect();
		setTimeout(async() => {
			await resetIO();
			vueSocketIOInstance.listener.io.connect();
		}, AUTH_RETRY_INTERVAL);
	} else if (isValidTokenError) {
		const lastAuthError = Number(localStorage.getItem(AUTH_ERROR_STORAGE_KEY) ?? 0);
		const thirtySeconds = 30 * 1000;
		const hasPreviouslyRefreshed = (Date.now() - lastAuthError) < thirtySeconds;
		if (!hasPreviouslyRefreshed) {
			localStorage.setItem(AUTH_ERROR_STORAGE_KEY, Date.now());
			location.reload();
		}
	}
}

let socketDisconnectedErrors = [];

async function handleDisconnect(reason) {
	const featureFlagLogAllConnectionIssues = await getFlag("sup-2554-log-all-connection-issues");
	if (featureFlagLogAllConnectionIssues) {
		Sentry.captureException(new Error("Socket disconnected: " + reason));
		return;
	}
	if (reason === "transport close") {
		const timestamp = Date.now();
		const error = { reason, timestamp };
		socketDisconnectedErrors.push(error);
		analyzeSocketDisconnectErrors();
	}

	if (reason === "transport error") {
		Sentry.captureException(new Error("Socket disconnected: " + reason));
	}
}

function analyzeSocketDisconnectErrors() {
	const MIN_ERRORS_COUNT = 5;
	const MAX_INTERVAL_IN_SECONDS = 5;
	const REASON = "transport close";

	if (socketDisconnectedErrors.length < MIN_ERRORS_COUNT) {
		return;
	}

	const lastErrorTime = socketDisconnectedErrors[socketDisconnectedErrors.length - 1].timestamp;
	const firstErrorTime = socketDisconnectedErrors[0].timestamp;
	const diffInSeconds = (lastErrorTime - firstErrorTime) / 1000;

	if (diffInSeconds < MAX_INTERVAL_IN_SECONDS) {
		Sentry.captureMessage(new Error("Socket disconnected", { cause: new Error(REASON) }));
	}

	socketDisconnectedErrors = [];
}

export function filterSocketArgsForPII(args) {
	const filteredArgs = omit(args, ["name", "firstName", "lastName"]);
	const filteredSchools = filteredArgs.schools?.map((school) => omit(school, "name"));
	return filteredSchools ?
		{ ...filteredArgs, schools: filteredSchools } :
		filteredArgs;
}

export async function addMiddleware(vueSocketIOInstance) {
	const featureFlagWebsocketLogging = await getFlag("core-547-websocket-logging");
	const originalOnEvent = vueSocketIOInstance.listener.onEvent;
	vueSocketIOInstance.listener.onEvent = function(event, args) {
		if (args?.length) {
			args = args[0];
		}
		if (featureFlagWebsocketLogging && process.env.NODE_ENV === "production") {
			const filteredArgs = filterSocketArgsForPII({ ...args });
			const message = `|Socket Event| ${event}:\n${JSON.stringify(filteredArgs || {})}`;
			window.FS?.log("debug", message);
		}
		originalOnEvent.bind(this)(event, args);
	};
}

async function getIOInstance() {
	const ioInstance = io(process.env.MIX_SOCKET_SERVER, {
		secure: true,
		transports: ["websocket"],
		autoConnect: false,
		path: "/v4",
		auth: { token: `Bearer ${jsCookie.get("access_token")}` },
	});
	ioInstance.on("connect_error", handleConnectError);
	ioInstance.on("disconnect", handleDisconnect);
	ioInstance.on("connect", () => {
		localStorage.removeItem(AUTH_ERROR_STORAGE_KEY);
	});
	return ioInstance;
}
export let vueSocketIOInstance;
(async() => {
	await waitForUserIdentification();
	vueSocketIOInstance = new VueSocketIO({
		debug: process.env.NODE_ENV !== "production",
		connection: await getIOInstance(),
	});
	Vue.use(vueSocketIOInstance);
	await addMiddleware(vueSocketIOInstance);
})();

