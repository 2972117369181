import Api from "@/services/api/Api.js";

export default {
	askResetPassword: async(data) => {
		return await Api().post("/passwords", data);
	},
	resetPassword: async(data) => {
		return await Api().post(`/passwords.reset`, data);
	},
};
