<template>
	<div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
		<slot name="icon">
			<OrigamiIcon
				name="warning-outlined"
				width="48"
				height="48"
			/>
		</slot>
		<slot name="header">
			<h1 class="tw-mt-4 tw-font-bold">
				{{ $t("error_state.something_went_wrong") }}
			</h1>
		</slot>
		<slot name="main">
			<div class="tw-mt-1 tw-text-center">
				<span class="tw-whitespace-nowrap tw-font-normal">
					{{ $t("error_state.cannot_connect") }}
				</span>
				<span class="tw-whitespace-nowrap tw-font-normal">
					{{ $t("error_state.please_try_again") }}
				</span>
			</div>
		</slot>
		<BaseButton
			v-if="isShowButton"
			v-jest="'error-button'"
			class="tw-mt-4"
			:color="buttonColor"
			:variants="buttonVariants"
			@click.native="$emit('button-clicked', $event)"
		>
			<slot name="button-text">
				{{ $t("error_state.try_again") }}
			</slot>
		</BaseButton>
	</div>
</template>

<script>
import { OrigamiIcon } from "@origami/vue2";

import BaseButton from "../elements/BaseButton.vue";
export default {
	components: {
		OrigamiIcon,
		BaseButton,
	},
	props: {
		buttonColor: {
			type: String,
			default: "blue",
		},
		buttonVariants: {
			type: Array,
			default: () => [],
		},
		isShowButton: {
			type: Boolean,
			default: true,
		},
	},
};
</script>
