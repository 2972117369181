var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLinkExist
    ? _c(
        "RouterLink",
        {
          staticClass: "spa",
          attrs: {
            to: _vm.to,
            exact: _vm.shouldBeExact(_vm.to),
            target: _vm.target ? "_blank" : false,
          },
        },
        [_vm._t("default")],
        2
      )
    : _c(
        "a",
        {
          staticClass: "noSpa",
          attrs: { href: _vm.to, target: _vm.target ? "_blank" : false },
        },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }