var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("TutorBioBreakCard", {
    attrs: { break: _vm.currentBreak },
    scopedSlots: _vm._u([
      {
        key: "metaDataIcon",
        fn: function () {
          return [
            _c(_vm.getScheduleTypeId() ? "IconSession" : "IconEssay", {
              tag: "component",
              staticClass: "tw-mr-2 tw-align-middle tw-text-black-high",
              attrs: { "icon-color": "currentColor" },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "metadata",
        fn: function () {
          return [
            _vm._v(
              "\n\t\t" +
                _vm._s(
                  _vm.getScheduleTypeId() ? "Live Help" : "Writing Review"
                ) +
                "\n\t"
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "breakDetailsIcon",
        fn: function () {
          return [_c("OrigamiIcon", { attrs: { name: "bio-break" } })]
        },
        proxy: true,
      },
      {
        key: "breakDetails",
        fn: function () {
          return [
            _vm._v("\n\t\tBack at " + _vm._s(_vm.formatShiftTime()) + "\n\t"),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }