<template>
	<div class="tw-py-3.5">
		<div class="tw-w-11/12 tw-m-auto tw-mb-10">
			<div class="tw-flex tw-flex-row tw-justify-between">
				<h4 class="tw-text-xl tw-font-semibold tw-text-origami-black">
					Bio Breaks &amp; Absences
				</h4>
			</div>
			<ServiceAbsencesTableFilters
				v-jest="'table-filters'"
			/>
			<ServiceAbsencesTableContainer
				v-if="!isTutorListEmpty && !PlatformManager.ServiceAbsencesTable.loading"
				v-jest="'tutor-table'"
				:tutor-list="PlatformManager.ServiceAbsencesTable.filteredTutors"
			/>
			<p
				v-else-if="!PlatformManager.ServiceAbsencesTable.loading"
				class="tw-text-center tw-mt-4"
			>
				There are no tutors on break matching filter you have selected.
			</p>
		</div>
		<BaseLoader
			v-if="PlatformManager.ServiceAbsencesTable.loading"
			class="tw-w-8 tw-h-8 tw-mx-auto tw-my-3"
		/>
		<div
			v-if="!isTutorListEmpty"
			class="tw-text-center"
		>
			<p
				v-jest="'page-indicator'"
				class="tw-bg-origami-blue-300 tw-p-2 tw-text-origami-white tw-m-auto tw-rounded-md tw-w-7 tw-mb-2"
			>
				{{ getCurrentPage }}
			</p>
			<OrigamiButton
				v-jest="'prev-button'"
				@click.native="getPrevPage"
			>
				Prev
			</OrigamiButton>
			<OrigamiButton
				v-jest="'next-button'"
				@click.native="getNextPage"
			>
				Next
			</OrigamiButton>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { isEmpty } from "lodash";
import { OrigamiButton } from "@origami/vue2";

import BaseLoader from "@/components/elements/BaseLoader.vue";

import ServiceAbsencesTableContainer from "./ServiceAbsencesTableContainer.vue";
import ServiceAbsencesTableFilters from "./ServiceAbsencesTableFilters.vue";

export default {
	name: "ServiceAbsencesTable",
	components: {
		BaseLoader,
		OrigamiButton,
		ServiceAbsencesTableFilters,
		ServiceAbsencesTableContainer,
	},
	computed: {
		...mapState(["PlatformManager"]),
		isTutorListEmpty() {
			return isEmpty(this.PlatformManager.ServiceAbsencesTable.filteredTutors);
		},
		getCurrentPage() {
			return this.PlatformManager.ServiceAbsencesTable.currentPage;
		},
	},
	methods: {
		getPrevPage() {
			this.$store.dispatch("PlatformManager/ServiceAbsencesTable/getPrevPageInSummary");
		},
		getNextPage() {
			this.$store.dispatch("PlatformManager/ServiceAbsencesTable/getNextPageInSummary");
		},
	},
};
</script>
