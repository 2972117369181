var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "nav",
        {
          staticClass:
            "tw-flex tw-items-end tw-justify-start tw-w-full tw-mb-6 tw-border-b-4 tw-border-origami-grey-100",
          attrs: { "aria-label": _vm.pageName },
        },
        _vm._l(_vm.tabs, function (tab) {
          return _c(
            "RouterLink",
            {
              directives: [
                {
                  name: "data-cy",
                  rawName: "v-data-cy",
                  value: `tab-${tab.name}`,
                  expression: "`tab-${tab.name}`",
                },
              ],
              key: tab.id,
              staticClass: "tw-text-base tw-cursor-pointer tw-py-3 tw-px-5",
              class: {
                "tw-text-origami-grey-200 hover:tw-text-origami-grey-200":
                  _vm.$route.path !== tab.route,
              },
              attrs: {
                "active-class":
                  "tw-border-b-4 tw--mb-1 tw-text-origami-blue-300 hover:tw-text-origami-blue-300 tw-font-semibold",
                to: tab.route,
                "aria-label": `Navigate to ${tab.name}'s tab in the ${_vm.pageName}`,
                custom: "",
              },
            },
            [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(tab.name) +
                  " " +
                  _vm._s(
                    tab.numberGetter
                      ? `(${_vm.$store.getters[tab.numberGetter]})`
                      : ""
                  ) +
                  "\n\t\t"
              ),
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }