import Vue from "vue";

import TopicsAPI from "@/services/api/Topics.js";

import CoursesAPI from "../../services/courses.js";
import DistrictsAPI from "../../services/districts.js";

const getDefaultState = () => ({
	items: [],
	searchText: "",
	orderBy: "",
	currentPage: 0,
	totalPages: 0,
	totalCourses: 0,
	loading: false,
	isShowingNoFilterBanner: true,
	headers: [
		{
			value: "synced_with",
			header: "",
			orderable: false,
			state: null,
		},
		{
			value: "id",
			header: "Paper Id",
			orderable: true,
			state: null,
		},
		{
			value: "name",
			header: "Course Name",
			orderable: true,
			state: null,
		},
		{
			value: "district_name",
			header: "District",
			orderable: true,
			state: null,
		},
		{
			value: "topics_count",
			header: "Topics",
			orderable: false,
			state: null,
		},
		{
			value: "status",
			header: "Status",
			orderable: false,
			state: null,
		},
		{
			value: "is_hidden_for_students",
			header: "Shown/Hidden",
			orderable: false,
			state: null,
		},
	],
	coursesFilters: {
		districts: {
			selected: [],
			options: [],
		},
		topics: {
			selected: [],
			options: [],
		},
		isHidden: {
			selected: [],
			options: [
				{
					id: "0",
					name: "Shown",
				},
				{
					id: "1",
					name: "Hidden",
				},
			],
		},
		status: {
			selected: [],
			options: [
				{
					id: "0",
					name: "Inactive",
				},
				{
					id: "1",
					name: "Active",
				},
			],
		},
	},
	filterKeys: [
		{
			title: "districts",
			value: "districts",
			type: "checkbox",
		},
		{
			title: "topics",
			value: "topics",
			type: "checkbox",
		},
		{
			title: "Hidden",
			value: "isHidden",
			type: "radio",
		},
		{
			title: "status",
			value: "status",
			type: "radio",
		},
	],
	navbarMainTab: "Customers",
});

export default {
	namespaced: true,
	state: getDefaultState(),
	getters: {
		filtersAsParamsObject(state) {
			const params = {};

			if (state.searchText !== "") {
				params["search"] = state.searchText;
			}

			if (state.orderBy !== "") {
				params["orderBy"] = state.orderBy;
			}

			Object.keys(state.coursesFilters).forEach((filter) => {
				params[filter] = state.coursesFilters[filter].selected;
			});

			if (state.currentPage > 1) {
				params["page"] = state.currentPage;
			}
			return params;
		},
	},
	mutations: {
		RESET_STATE(state) {
			Object.assign(state, getDefaultState());
		},
		SET_IS_SHOWING_NO_FILTER_BANNER(state, payload) {
			state.isShowingNoFilterBanner = payload.isShowingNoFilterBanner;
		},
		SET_LOADING(state, payload) {
			state.loading = payload.loading;
		},
		SET_COURSES_IN_ITEMS(state, payload) {
			state.items = payload.items;
		},
		SET_DISTRICTS(state, payload) {
			state.coursesFilters.districts.options = payload.districts;
		},
		SET_FILTER_IN_FILTERS_KEY_VALUE(state, payload) {
			Vue.set(state.coursesFilters[payload.filter], payload.key, payload.value);
		},
		SET_TOPICS(state, payload) {
			state.coursesFilters.topics.options = payload.topics;
		},
		SET_SEARCH_TEXT(state, payload) {
			state.searchText = payload.searchText;
		},
		SET_TABLE_HEADERS(state, payload) {
			state.headers = payload.tableHeaders;
		},
		SET_ORDER_BY(state, payload) {
			state.orderBy = payload.orderBy;
		},
		SET_CURRENT_PAGE(state, payload) {
			state.currentPage = payload.currentPage;
		},
		SET_TOTAL_PAGES(state, payload) {
			state.totalPages = payload.totalPages;
		},
		SET_TOTAL_COURSES(state, payload) {
			state.totalCourses = payload.totalCourses;
		},
	},
	actions: {
		async getFilteredCoursesData({ commit, getters }) {
			commit("SET_LOADING", {
				loading: true,
			});
			commit("SET_IS_SHOWING_NO_FILTER_BANNER", {
				isShowingNoFilterBanner: false,
			});
			const params = getters.filtersAsParamsObject;
			try {
				const response = await CoursesAPI.list(params);
				commit("SET_COURSES_IN_ITEMS", {
					items: response.data.data,
				});

				commit("SET_TOTAL_PAGES", {
					totalPages: response.data.meta.pagination.total_pages,
				});

				commit("SET_CURRENT_PAGE", {
					currentPage: response.data.meta.pagination.current_page,
				});

				commit("SET_TOTAL_COURSES", {
					totalCourses: response.data.meta.pagination.total,
				});

				return Promise.resolve();
			} catch (error) {
				return Promise.reject(error);
			} finally {
				commit("SET_LOADING", {
					loading: false,
				});
			}
		},
		async getDistricts({ commit }) {
			try {
				const response = await DistrictsAPI.list();
				commit("SET_DISTRICTS", {
					districts: response.data.data,
				});
				return Promise.resolve();
			} catch (e) {
				return Promise.reject(e);
			}
		},

		async getTopics({ commit }) {
			try {
				const response = await TopicsAPI.list();
				commit("SET_TOPICS", {
					topics: response.data.data,
				});
				return Promise.resolve();
			} catch (e) {
				return Promise.reject(e);
			}
		},
		async setCurrentPage({ commit, dispatch }, payload) {
			commit("SET_CURRENT_PAGE", {
				currentPage: payload.newPage,
			});
			await dispatch("getFilteredCoursesData");
		},
	},
};
