<template>
	<OrigamiButton
		v-if="opensDrawer"
		v-jest="'sidebar-button'"
		class="sidebar-button"
		:class="{ 'sidebar-button--active': isLinkActive, 'sidebar-button--drawer-open': isOpen }"
		@click.native="emit('open-drawer')"
	>
		<OrigamiIcon
			:name="icon"
			class="sidebar-button-icon"
			width="32"
			height="32"
		/>
		<p class="sidebar-button-label">
			{{ $t(title) }}
		</p>
	</OrigamiButton>
	<component
		:is="getLinkComponent"
		v-else
		v-jest="'sidebar-link'"
		v-bind="getLinkProperties"
		class="sidebar-button"
		:class="{ 'sidebar-button--active': isLinkActive, 'sidebar-button--drawer-open': isOpen }"
		@click.native="emit('close-drawer')"
	>
		<OrigamiIcon
			:name="icon"
			class="sidebar-button-icon"
			width="32"
			height="32"
		/>
		<span class="sidebar-button-label">
			{{ $t(title) }}
		</span>
	</component>
</template>
<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router/composables";
import { OrigamiButton, OrigamiIcon } from "@origami/vue2";

const route = useRoute();
const props = defineProps({
	title: {
		type: String,
		required: true,
	},
	icon: {
		type: String,
		required: true,
	},
	opensDrawer: {
		type: Boolean,
		default: false,
		required: true,
	},
	isExternal: {
		type: Boolean,
		required: true,
	},
	name: {
		type: String,
		required: true,
	},
	isOpen: {
		type: Boolean,
		required: true,
	},
	target: {
		type: String,
		default: "",
	},
});
const emit = defineEmits(["open-drawer", "close-drawer"]);

const getLinkComponent = props.isExternal ? "a" : "router-link";
const getLinkProperties = { [props.isExternal ? "href" : "to"]: `${props?.target}` };

const isLinkActive = computed(() => {
	const isRouteReviewCenter = (route.path === "/student/review-center" || route.path === "/student/upload-work") && props.name === "review-center";
	const isNavLinkOrChildRoute = route.path.startsWith(props?.target);
	return isRouteReviewCenter || isNavLinkOrChildRoute;
});

</script>

<style scoped>
.sidebar-button {
	@apply md:tw-m-2 tw-p-1 tw-flex tw-items-center tw-justify-center tw-flex-col tw-w-[80px] md:tw-w-[64px] tw-h-[72px] tw-bg-origami-white hover:tw-bg-origami-grey-100 tw-rounded;
	box-shadow: none;
}
.sidebar-button:hover {
	box-shadow: none;
}
.sidebar-button:focus-visible {
	@apply tw-relative tw-bg-origami-blue-400 tw-border-none tw-outline-none before:tw-border-2 before:tw-border-origami-blue-300 before:tw-absolute before:tw--inset-1 before:tw-rounded;
}
.sidebar-button--drawer-open {
	@apply tw-bg-origami-grey-100;
}
.sidebar-button-label {
	@apply tw-text-[11px] md:tw-text-[13px] tw-text-origami-black tw-font-light tw-leading-4 tw-text-center;
}
.sidebar-button-icon {
	@apply tw-text-origami-grey-300 tw-w-[32px] tw-h-[32px] tw-min-w-[32px] tw-min-h-[32px];
}
.sidebar-button--active .sidebar-button-label {
	@apply tw-text-origami-blue-300 tw-font-bold;
}
.sidebar-button--active .sidebar-button-icon {
	@apply tw-text-origami-blue-300;
}
.sidebar-button:focus-visible .sidebar-button-icon {
	@apply tw-text-origami-white;
}
.sidebar-button:focus-visible .sidebar-button-label {
	@apply tw-text-origami-white;
}
</style>
