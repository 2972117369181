<template>
	<ul class="tw-divide-y-2 tw-divide-origami-grey-100 tw-border-t-2 tw-border-origami-grey-100 tw-w-full">
		<li
			v-for="(value, name) in shiftBreakdown"
			:key="name"
			v-jest="'shift-type'"
			class="tw-py-2 tw-text-lg tw-grid tw-grid-cols-4"
		>
			<div class="tw-col-span-3 tw-flex tw-place-items-center">
				<span
					v-jest="'shift-color'"
					class="tw-w-5 tw-h-5 tw-rounded-md"
					:class="getOrigamiColorFromShiftTypeId(name)"
				/>
				<span
					v-jest="'shift-title'"
					class="tw-font-bold tw-ml-2"
				>
					{{ getShiftTypeNameFromShiftTypeId(name) }}
				</span>
			</div>
			<div class="tw-col-span-1 tw-flex tw-place-content-end tw-place-items-center tw-font-bold">
				<p v-jest="'shift-total'">
					{{ formatTotalMinutesToHoursMins((shiftBreakdown[name].total), false) }}
				</p>
			</div>
		</li>
	</ul>
</template>

<script>
import { mapState } from "vuex";

import { formatTotalMinutesToHoursMins } from "../utilities/TutorScheduledHoursHelpers.js";
import { getShiftTypeNameFromShiftTypeId, getOrigamiColorFromShiftTypeId } from "../../../utilities/ScheduleHelpers.js";

export default {
	computed: {
		...mapState(["TutorScheduledHours"]),
		shiftBreakdown() {
			return this.TutorScheduledHours.shiftHoursBreakdown;
		},
	},
	methods: {
		getOrigamiColorFromShiftTypeId,
		getShiftTypeNameFromShiftTypeId,
		formatTotalMinutesToHoursMins,
	},
};
</script>
