import jsCookie from "js-cookie";
import jwtDecode from "jwt-decode";

import { logout, refreshAccessTokenOrLogout } from "@/services/root.js";
import { getAndConsumePostLoginRedirectUri } from "@/modules/Authentication/index.js";

export default async function(to, from, next) {
	try {
		const accessToken = await refreshAccessTokenOrLogout(to);
		const { user } = jwtDecode(accessToken);
		const isUpdatingTimezone = jsCookie.get("isUpdatingTimezone") == 1;
		this.commit("SET_CURRENT_USER", {
			currentUser: {
				...user,
				isUpdatingTimezone,
			},
		});

		const impersonating = jsCookie.get("impersonating") || false;
		this.commit("SET_IMPERSONATE", impersonating);

		const redirect_uri = await getAndConsumePostLoginRedirectUri(true);
		return next(redirect_uri);
	} catch (error) {
		Sentry.captureException(error);

		await logout(to);
	}
}
