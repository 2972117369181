var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 32 40",
      },
    },
    [
      _c("defs", [
        _c("path", {
          attrs: {
            id: "tg39217jna",
            d: "M17.574 0l10.544 11.115v22.23c0 2.038-1.582 3.705-3.515 3.705H3.497C1.564 37.05 0 35.383 0 33.345l.018-29.64C.018 1.667 1.582 0 3.515 0h14.059zm-2.237 4.832H6.39c-1.405 0-2.543 1.233-2.543 2.74l-.013 21.907c0 1.506 1.138 2.739 2.544 2.739h15.35c1.405 0 2.556-1.233 2.556-2.739V13.05l-.151-.162h-8.796V4.832zm5.751 20.942v3.222H7.03v-3.222h14.06zm0-6.443v3.221H7.03v-3.221h14.06z",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            fill: "none",
            "fill-rule": "evenodd",
            transform: "translate(1.92 1.339)",
          },
        },
        [
          _c("mask", { attrs: { id: "d3ifjnm7fb", fill: _vm.iconColor } }, [
            _c("use", { attrs: { "xlink:href": "#tg39217jna" } }),
          ]),
          _vm._v(" "),
          _c("use", {
            attrs: { fill: _vm.iconColor, "xlink:href": "#tg39217jna" },
          }),
          _vm._v(" "),
          _c(
            "g",
            { attrs: { fill: _vm.iconColor, mask: "url(#d3ifjnm7fb)" } },
            [
              _c("path", {
                attrs: {
                  d: "M0 0H35.147V37.05H0z",
                  transform: "translate(-3.515)",
                },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }