var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "filter-tag",
          expression: "'filter-tag'",
        },
      ],
      staticClass:
        "tw-rounded-md tw-py-0.5 tw-px-1 tw-mr-2 tw-whitespace-nowrap",
      class: {
        "tw-bg-origami-blue-100": _setup.isCountryTag,
        "tw-bg-origami-pink-100": !_setup.isCountryTag,
      },
      attrs: { "data-cy": "filter-tag" },
    },
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "filter-text",
              expression: "'filter-text'",
            },
          ],
          staticClass:
            "tw-font-origami-bold tw-text-origami-sm tw-leading-none",
          class: {
            "tw-text-origami-blue-300": _setup.isCountryTag,
            "tw-text-origami-pink-400": !_setup.isCountryTag,
          },
        },
        [_vm._v("\n\t\t" + _vm._s(_setup.props.filterTagName) + "\n\t")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }