import api from "./Api.js";

export default {
	/**
	 * Create tutor comment
	 * @arg {Object} data
	 * @arg {Number} data.user_student_id
	 * @arg {String} data.comment
	 */
	create(data) {
		return api().post("tutor-comments", data);
	},
	/**
	 * Get all comments of student
	 *
	 * @arg {number} userId
	 * @returns {AxiosPromise}
	 */
	list(userId) {
		return api().get(`students/${userId}/tutor-comments?include=tutor`);
	},

	/**
	 * Update tutor comment
	 * @param {Number} tutorCommentId
	 * @param {Object} data
	 * @param {Boolean} data.is_active
	 */
	update(tutorCommentId, data) {
		return api().post(`tutor-comments/${tutorCommentId}`, data);
	},
};
