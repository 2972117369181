import rootInstance from "@/services/root.js";

import { cacheApiResponse } from "./apiHelpers.js";

const apiInstance = () => {
	rootInstance.defaults.baseURL = process.env.MIX_CONTENT_CATALOG_API_URL + "/api/v1";
	return rootInstance;
};

export default {
	getVideosList() {
		return apiInstance().get("/videos");
	},
	getVideo(id, { refetch = false } = {}) {
		return cacheApiResponse(
			"contentCatalog",
			`getVideo/${id}`,
			() => apiInstance().get(`/videos/${id}`),
			{ refetch },
		);
	},
	getVideoContentUrl(id, { refetch = false } = {}) {
		return cacheApiResponse(
			"contentCatalog",
			`getVideoContentUrl/${id}`,
			() => apiInstance().get(`/videos/${id}/content-url`),
			{ refetch },
		);
	},
	sendTrackingInfo(payload) {
		return apiInstance().post("/tracking/video", payload);
	},
};
