var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "80",
        height: "56",
        viewBox: "0 0 80 56",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M6 56C4.4 56 3 55.3833 1.8 54.15C0.6 52.9167 0 51.5333 0 50V6C0 4.4 0.6 3 1.8 1.8C3 0.599999 4.4 0 6 0H74C75.6 0 77 0.599999 78.2 1.8C79.4 3 80 4.4 80 6V50C80 51.5333 79.4 52.9167 78.2 54.15C77 55.3833 75.6 56 74 56H6ZM6 50H74V6H6V50ZM22 43.5H58V37.5H22V43.5ZM12.3 31H18.3V25H12.3V31ZM24.7 31H30.7V25H24.7V31ZM37 31H43V25H37V31ZM49.4 31H55.4V25H49.4V31ZM61.7 31H67.7V25H61.7V31ZM12.3 18.5H18.3V12.5H12.3V18.5ZM24.7 18.5H30.7V12.5H24.7V18.5ZM37 18.5H43V12.5H37V18.5ZM49.4 18.5H55.4V12.5H49.4V18.5ZM61.7 18.5H67.7V12.5H61.7V18.5ZM6 50V6V50Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }