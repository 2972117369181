var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("NotificationButton", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "button",
            expression: "'button'",
          },
        ],
        ref: "buttonRef",
        attrs: {
          "is-selected": _vm.hasNewNotifications,
          "notification-count": _vm.sortedAchievementsList.length,
          points: _vm.points,
        },
      }),
      _vm._v(" "),
      _c(
        "NotificationPopover",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "popover",
              expression: "'popover'",
            },
            {
              name: "data-cy",
              rawName: "v-data-cy",
              value: "popover",
              expression: "'popover'",
            },
          ],
          ref: "popoverRef",
          staticClass: "tw-flow-root tw-mt-6",
          attrs: { items: _vm.sortedAchievementsList },
          on: {
            close: function ($event) {
              return _vm.popover.hide()
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "tw-flex tw-justify-between tw-items-start tw-max-w-full tw-relative",
              class: !_vm.hasAchievements
                ? "closeButton__positioning"
                : "tw-border-b tw-border-origami-grey-200 tw-mx-2",
            },
            [
              _vm.hasAchievements
                ? _c(
                    "h4",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "achievement-header",
                          expression: "'achievement-header'",
                        },
                        {
                          name: "data-cy",
                          rawName: "v-data-cy",
                          value: "achievement-header",
                          expression: "'achievement-header'",
                        },
                      ],
                      staticClass:
                        "tw-flex tw-align-center tw-gap-1 tw-w-full tw-pb-4 tw-pt-2 tw-font-bold tw-text-lg",
                    },
                    [
                      _c("Emoji", {
                        staticClass: "medal",
                        attrs: { emoji: "Medal" },
                      }),
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(
                            _vm.$t("achievements.title", {
                              num: _vm.numberOfAchievements,
                            })
                          ) +
                          "\n\t\t\t"
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("OrigamiIconButton", {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "close-button",
                    expression: "'close-button'",
                  },
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "notification-close-button",
                    expression: "'notification-close-button'",
                  },
                ],
                class: {
                  "tw-relative tw--right-4 tw--top-2": _vm.hasAchievements,
                },
                attrs: { "icon-name": "close" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.popover.hide()
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }