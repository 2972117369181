<template>
	<div
		ref="textareaContainer"
		v-click-outside="handleClickOutside"
		v-jest="'text-area-container'"
		class="tw-flex tw-items-start tw-flex-nowrap tw-bg-white-high tw-text-black-high tw-rounded tw-border-2 tw-border-grey-dark tw-cursor-text"
		:class="[variant, { disabled, focused, 'hover:tw-bg-grey-pale': !focused }]"
		@click="$refs.textarea.focus()"
	>
		<slot name="prepend" />
		<textarea
			ref="textarea"
			v-jest="'text-area'"
			class="tw-flex tw-h-9 tw-resize-none tw-w-full tw-text-inherit tw-text-xs tw-m-0 tw-px-4 tw-py-2 tw-border-0 tw-rounded tw-outline-none"
			:value="value"
			v-bind="$attrs"
			:disabled="disabled"
			@input="handleInput"
			@focus="focused = true"
			@blur="focused = false"
			v-on="listeners"
		/>
		<slot name="append">
			<Check
				v-if="variant === 'success'"
				v-jest="'icon-check'"
				class="tw-text-green-regular tw-m-2"
			/>
			<Warning
				v-else-if="variant === 'warning'"
				v-jest="'icon-warning'"
				class="tw-text-red-regular tw-m-2"
			/>
		</slot>
	</div>
</template>

<script>
import { omit } from "lodash";

import { Check, Warning } from "./icons/index.js";

export default {
	name: "BaseTextArea",
	components: {
		Check,
		Warning,
	},
	inheritAttrs: false,
	props: {
		value: {
			type: String,
			default: "",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		debounce: {
			type: Boolean,
			default: false,
		},
		debounceTime: {
			type: [String, Number],
			default: "300",
		},
		variant: {
			type: String,
			default: "default",
			validator: function(variant) {
				return ["default", "success", "warning"].includes(variant);
			},
		},
		autoResize: {
			type: Boolean,
			default: false,
		},
	},
	data: function() {
		return {
			debounceTimeout: null,
			focused: false,
			initialHeight: 0,
		};
	},
	computed: {
		listeners() {
			return omit(this.$listeners, ["input", "focus", "blur"]);
		},
	},
	mounted() {
		this.initialHeight = this.$refs.textareaContainer.clientHeight;
	},
	beforeDestroy() {
		clearTimeout(this.debounceTimeout);
	},
	methods: {
		handleInput(e) {
			if (this.autoResize) {
				this.$refs.textareaContainer.style.height = "";
				this.$refs.textarea.style.height = "";
				this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight}px`;
				this.$refs.textareaContainer.style.height = this.$refs.textarea.scrollHeight > this.initialHeight ?
					`${this.$refs.textarea.scrollHeight}px` :
					`${this.initialHeight}px`;
			}
			if (!this.debounce) {
				return this.$emit("input", e.target.value);
			}
			clearTimeout(this.debounceTimeout);
			this.debounceTimeout = setTimeout(() => {
				this.$emit("input", e.target.value);
			}, this.debounceTime);
		},
		handleClickOutside() {
			this.focused = false;
		},
	},
};
</script>

<style scoped>
textarea {
	background: transparent;
	cursor: inherit;
}

.disabled {
	color: var(--white-disabled);
	background-color: var(--white-disabled);
	border-color: transparent;
	cursor: not-allowed;
}
.focused {
	border-color: var(--blue-regular);
}
.success {
	border-color: var(--green-regular);
}
.warning {
	border-color: var(--red-regular);
}
</style>
