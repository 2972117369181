var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tw-flex tw-flex-col" }, [
    _c(
      "div",
      { staticClass: "tw-flex tw-flex-row tw-items-center" },
      [
        _c(
          "h3",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "title-text",
                expression: "'title-text'",
              },
            ],
            staticClass: "tw-font-bold tw-text-md",
          },
          [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(
                  _vm.$t(`challenges.translations.${_vm.challenge.title_key}`)
                ) +
                "\n\t\t"
            ),
          ]
        ),
        _vm._v(" "),
        _c("InfoToolTip", {
          staticClass: "tw--mt-1",
          attrs: {
            options: _vm.toolTipOptions,
            "aria-describedby": _vm.tooltipId,
            tabindex: "0",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    !_vm.challengeCompleted
      ? _c(
          "p",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "time-text",
                expression: "'time-text'",
              },
            ],
            staticClass: "tw-text-sm tw-mb-2",
          },
          [
            _vm._v(
              "\n\t\t" +
                _vm._s(
                  _vm.$t(`challenges.${_vm.timingTextKey}`, {
                    days: _vm.remainingDays,
                    days_plural: _vm.remainingDays > 1 ? "s" : "",
                  })
                ) +
                "\n\t"
            ),
          ]
        )
      : _c("p", { staticClass: "tw-mb-2" }, [
          _c(
            "span",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "completed-text",
                  expression: "'completed-text'",
                },
              ],
            },
            [_vm._v(_vm._s(_vm.$t("challenges.completed")))]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "restart-text",
                  expression: "'restart-text'",
                },
              ],
              staticClass: "tw-text-sm tw-text-origami-grey-300",
            },
            [
              _vm._v(
                _vm._s(_vm.$t("goals.restart", { days: _vm.remainingDays }))
              ),
            ]
          ),
        ]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "tw-pl-2" },
      _vm._l(_vm.goals, function (item) {
        return _c(
          "li",
          { key: item.type, staticClass: "tw-flex tw-flex-row tw-mb-1" },
          [
            item.completed >= item.target
              ? _c("OrigamiIcon", {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: `${item.type}_checkmark`,
                      expression: "`${item.type}_checkmark`",
                    },
                  ],
                  staticClass: "tw-text-origami-blue-300 tw-pr-1",
                  attrs: { name: "check-filled" },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: `${item.type}_text`,
                    expression: "`${item.type}_text`",
                  },
                ],
                staticClass: "tw-truncate",
                class: {
                  "tw-text-origami-blue-300": item.completed >= item.target,
                  "tw-pl-6": item.completed < item.target,
                },
              },
              [
                _c("span", { staticClass: "tw-font-bold" }, [
                  _vm._v(_vm._s(_vm.$t("goals.of", item))),
                ]),
                _vm._v(" " + _vm._s(_vm.text[item.type]) + "\n\t\t\t"),
              ]
            ),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }