<template>
	<div>
		<section class="tw-pl-2 tw-pr-0 tw-pt-2 tw-pb-4 tw-flex tw-justify-between tw-items-end">
			<RouterLink
				class="tw-flex tw-items-center tw-text-blue-regular"
				to="/superuser/services/schedule/shift-schedule"
				target="_blank"
				:aria-label="`Navigate to the Schedule page`"
			>
				<OrigamiIcon
					name="external"
					width="15"
					height="15"
				/>
				<span class="tw-font-bold tw-text-sm tw-pl-2">Schedule Page</span>
			</RouterLink>
			<OrigamiButton
				v-jest="'create-btn'"
				v-data-cy="'create-btn'"
				variant="primary"
				class="tw-flex"
				:disabled="isEditingShift || isDeletingShift"
				@click.native="startCreateProcess"
			>
				Create New Shift
			</OrigamiButton>
		</section>
		<div class="tw-flex">
			<ServiceScheduleTutorScheduleCalendar
				:formatted-shifts="formattedShifts"
				@on-select-shift-segment="onSelectShiftSegment"
			/>
			<component
				:is="sidebarComponent.component"
				v-jest="sidebarComponent.jestName"
				class="tw-w-1/2"
				v-bind="sidebarComponent.props"
				@cancel-edit="cancelEdit"
				@complete-creation="completeCreation"
				@complete-edit="completeEdit"
				@complete-extend="completeExtend"
				@complete-deletion="completeDeletion"
				@format-shifts="formatShifts"
			/>
		</div>
	</div>
</template>
<script>
import { OrigamiIcon, OrigamiButton } from "@origami/vue2";
import { mapState } from "vuex";
import { endOfDay, startOfDay } from "date-fns";

import Colors from "@/utilities/Colors.js";
import { getSlotColorFromShiftType, getShiftTypeNameFromShiftTypeId } from "@/utilities/ScheduleHelpers.js";

import {
	DEFAULT,
	CREATING_SHIFT,
	EDITING_SHIFT,
	EXTENDING_SHIFT,
	DELETING_SHIFT,
} from "../utilities/TutorQuickEditSteps.js";

import ServiceScheduleTutorCreateShift from "./ServiceScheduleTutorCreateShift.vue";
import ServiceScheduleTutorEditShift from "./ServiceScheduleTutorEditShift.vue";
import ServiceScheduleTutorExtendShift from "./ServiceScheduleTutorExtendShift.vue";
import ServiceScheduleTutorDeleteShift from "./ServiceScheduleTutorDeleteShift.vue";
import ServiceScheduleTutorSuccessfulAction from "./ServiceScheduleTutorSuccessfulAction.vue";
import ServiceScheduleTutorDefaultMessage from "./ServiceScheduleTutorDefaultMessage.vue";

const ORIGAMI_GREY_200 = "#BABBBF";
const ORIGAMI_RED_200 = "#FF8F80";
const ORIGAMI_RED_400 = "#CC3723";

const ONE_SECOND_IN_MS = 1000;

export default {
	components: {
		OrigamiIcon,
		OrigamiButton,
		ServiceScheduleTutorCreateShift,
		ServiceScheduleTutorEditShift,
		ServiceScheduleTutorExtendShift,
		ServiceScheduleTutorDeleteShift,
		ServiceScheduleTutorSuccessfulAction,
		ServiceScheduleTutorDefaultMessage,
		ServiceScheduleTutorScheduleCalendar: () => import("./ServiceScheduleTutorScheduleCalendar.vue"),
	},
	data() {
		return {
			formattedShifts: [],
			selectedShift: {
				start: "",
				end: "",
				punchIn: "",
				name: "",
				id: "",
				shiftColor: "",
				shiftType: "",
				shiftEnded: "",
				isSelected: false,
			},
			reloadShiftsTimeout: null,
		};
	},
	computed: {
		...mapState(["PlatformManager"]),
		sidebarComponent() {
			const sideBarSteps = {
				[DEFAULT]: {
					component: "ServiceScheduleTutorDefaultMessage",
					jestName: "service-dashboard-sidebar-empty",
					props: {
						"section-title": "No shift selected",
					},
				},
				[CREATING_SHIFT]: {
					component: "ServiceScheduleTutorCreateShift",
					jestName: "service-dashboard-sidebar-create",
					props: {
						"selected-tutor": this.selectedTutor,
					},
				},
				[EDITING_SHIFT]: {
					component: "ServiceScheduleTutorEditShift",
					jestName: "service-dashboard-sidebar-edit",
					props: {
						"selected-tutor": this.selectedTutor,
						shift: this.selectedShift,
					},
				},
				[EXTENDING_SHIFT]: {
					component: "ServiceScheduleTutorExtendShift",
					jestName: "service-dashboard-sidebar-extend",
					props: {
						"selected-tutor": this.selectedTutor,
						shift: this.selectedShift,
					},
				},
				[DELETING_SHIFT]: {
					component: "ServiceScheduleTutorDeleteShift",
					jestName: "service-dashboard-sidebar-delete",
					props: {
						shift: this.selectedShift,
					},
				},
			};

			return sideBarSteps[this.PlatformManager.ServiceTutorsSchedule.currentTutorQuickEditStep] || "";
		},
		tutorSchedule() {
			return this.PlatformManager.ServiceSummaryDashboard.selectedTutorSchedule ?? [];
		},
		selectedTutor() {
			return this.PlatformManager.ServiceSummaryDashboard.selectedTutor ?? {};
		},
		isCreatingShift() {
			return this.PlatformManager.ServiceTutorsSchedule.currentTutorQuickEditStep === CREATING_SHIFT;
		},
		isDeletingShift() {
			return this.PlatformManager.ServiceTutorsSchedule.currentTutorQuickEditStep === DELETING_SHIFT;
		},
		isEditingShift() {
			return [EDITING_SHIFT, EXTENDING_SHIFT]
				.includes(this.PlatformManager.ServiceTutorsSchedule.currentTutorQuickEditStep);
		},
	},
	async created() {
		this.formattedShifts = this.getFormattedShifts();
	},
	beforeDestroy() {
		clearTimeout(this.reloadShiftsTimeout);
	},
	methods: {
		getFormattedShifts() {
			return Object.values(this.tutorSchedule).map((shift) => {
				return (shift.id === this.selectedShift.id && this.selectedShift.isSelected) ?
					this.formatShiftBlock(shift, true)
					: this.formatShiftBlock(shift, false);
			});
		},
		formatShiftBlock(shift, isSelected) {
			const shiftIsSelectedForDeletion = isSelected && this.isDeletingShift;

			let backgroundColor = "";
			let borderColor = "";
			if (shiftIsSelectedForDeletion) {
				backgroundColor = ORIGAMI_RED_200;
				borderColor = ORIGAMI_RED_400;
			} else if (isSelected) {
				backgroundColor = ORIGAMI_GREY_200;
				borderColor = Colors.C_BLACK;
			} else if (shift.transfer_pending) {
				backgroundColor = Colors.C_PEACH;
				borderColor = backgroundColor;
			} else {
				backgroundColor = getSlotColorFromShiftType(shift.scheduleType.data.name);
				borderColor = backgroundColor;
			}

			const isShiftEnded = shift.punch_out !== null;
			const title = getShiftTypeNameFromShiftTypeId(shift.scheduleType.data.id);
			return {
				start: new Date(shift.start_time * 1000),
				end: new Date(shift.end_time * 1000),
				punchIn: shift.punch_in ? new Date(shift.punch_in * 1000) : null,
				id: shift.id,
				title: `[${title}]\n${this.selectedTutor?.name}\nUser ID: ${
					this.selectedTutor?.id}`,
				editable: false,
				shiftType: shift.scheduleType,
				tutorId: this.selectedTutor?.id,
				tutorName: this.selectedTutor?.name,
				backgroundColor: backgroundColor,
				borderColor: borderColor,
				textColor: Colors.C_BLACK,
				shiftEnded: isShiftEnded,
			};
		},
		async updateFormattedShifts() {
			await this.$store.dispatch("PlatformManager/ServiceSummaryDashboard/getSelectedTutorSchedule");
			this.formattedShifts = this.getFormattedShifts();
		},
		formatShifts() {
			this.formattedShifts = this.getFormattedShifts();
		},
		onSelectShiftSegment(info) {
			const isNotPerformingAction = !this.isEditingShift && !this.isCreatingShift && !this.isDeletingShift;
			if (isNotPerformingAction) {
				this.selectedShift = {
					start: info.event.start,
					end: info.event.end,
					punchIn: info.event.extendedProps.punchIn,
					name: info.event.extendedProps.tutorName,
					id: parseInt(info.event.id),
					shiftColor: info.event.backgroundColor,
					shiftType: info.event.extendedProps.shiftType,
					shiftEnded: info.event.extendedProps.shiftEnded,
					isSelected: true,
				};
				this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/startEditShiftProcess");
				this.formattedShifts = this.getFormattedShifts();
			}
		},
		startCreateProcess() {
			this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/setCurrentShiftType", {
				currentShiftType: {},
			});
			this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/startCreateShiftProcess");
		},
		cancelEdit() {
			this.selectedShift.isSelected = false;
			this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/resetTutorQuickEditStep");
			this.formattedShifts = this.getFormattedShifts();
		},
		completeCreation() {
			this.updateFormattedShifts();
		},
		completeEdit() {
			this.updateFormattedShifts();

			const now = new Date();
			this.$store.dispatch("TutorSchedule/getShifts", {
				userId: this.PlatformManager.ServiceSummaryDashboard.selectedTutor.id,
				startDate: startOfDay(now),
				endDate: endOfDay(now),
			});
		},
		async completeExtend() {
			// TODO: SER-1399 - Delay will not be needed once the calendar uses shifts from SMA
			this.reloadShiftsTimeout = setTimeout(async() => {
				await this.updateFormattedShifts();
			}, ONE_SECOND_IN_MS * 2);

			const now = new Date();
			await this.$store.dispatch("TutorSchedule/getShifts", {
				userId: this.PlatformManager.ServiceSummaryDashboard.selectedTutor.id,
				startDate: startOfDay(now),
				endDate: endOfDay(now),
			});
		},
		completeDeletion() {
			this.updateFormattedShifts();
		},
	},
};
</script>
