<template>
	<div class="tw-relative">
		<div class="tutorTags__buttons tw-absolute tw-right-0">
			<BaseButton
				v-if="!isEditing"
				v-jest="'edit-tags-button'"
				class="tutorTags__button tutorTags__buttonPrimary tw-w-20 tw-h-10 tw-text-sm tw-font-semibold tw-text-white-high tw-flex tw-items-center tw-justify-center tw-py-0 tw-px-4  tw-truncate"
				type="button"
				color="blue"
				:variants="['full']"
				@click.native="showFilters"
			>
				Edit
			</BaseButton>
			<BaseButton
				v-else
				v-jest="'cancel-edit-button'"
				class="tutorTags__button tutorTags__cancelButton tw-w-20 tw-h-10 tw-text-sm tw-font-semibold tw-text-black-high tw-flex tw-items-center tw-justify-center tw-py-0 tw-px-4  tw-truncate tw-bg-white-high"
				type="button"
				color="white"
				:variants="['full']"
				@click.native="cancelTags"
			>
				Cancel
			</BaseButton>
		</div>
		<ServiceTutorEditShowTagsTable v-if="!isEditing" />
		<TagsFilterTable
			v-else
			ref="tagsFilterTable"
		/>
	</div>
</template>
<script>
import { mapState } from "vuex";

import TagsFilterTable from "@/components/TagsFilterTable.vue";
import BaseButton from "@/components/elements/BaseButton.vue";

import ServiceTutorEditShowTagsTable from "./ServiceTutorEditShowTagsTable.vue";

export default {
	components: {
		ServiceTutorEditShowTagsTable,
		TagsFilterTable,
		BaseButton,
	},
	data() {
		return {
			isEditing: false,
		};
	},
	computed: {
		...mapState(["PlatformManager"]),
	},
	async mounted() {
		await this.$store.dispatch("PlatformManager/TutorsEdit/getSubjects");
	},
	methods: {
		showFilters() {
			this.isEditing = true;
		},
		cancelTags() {
			this.isEditing = false;
			this.$store.commit("PlatformManager/TutorsEdit/SET_IS_TAGS_UPDATED", { value: false });
		},
	},
};
</script>
<style>
.tutorTags__buttons {
	top: calc(0px - (80px / 2) - 1rem);
}
.tutorTags__button {
	font-size: 0.875rem !important;
	font-weight: 700 !important;
	border: 0px;
	border-radius: 0.3125rem;
	outline: none;
}
.tutorTags__cancelButton {
	border: 2px solid var(--grey-dark);
}
.tutorTags__buttonPrimary {
	background-color: var(--blue-regular);
}
.tutorTags__buttonPrimary:hover,
.tutorTags__buttonPrimary:active {
	background-color: var(--blue-dark);
}
</style>
