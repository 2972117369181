<script setup>
import { OrigamiButton, OrigamiIcon } from "@origami/vue2";
import { reactive, computed } from "vue";

import BaseLoader from "@/components/elements/BaseLoader.vue";
import InfoToolTip from "@/components/patterns/InfoToolTip.vue";
import useStore from "@/composables/useStore.js";

import { CURRENT_TUTOR_SESSION_BREAKDOWN_TOOLTIP } from "../utilities/ConstantValues.js";

const store = useStore();
const props = defineProps({
	subject: {
		type: String,
		required: true,
	},
	isLeaderboardLoaded: {
		type: Boolean,
		required: true,
	},
});
const emit = defineEmits(["sort-tutor-load"]);

const state = reactive({
	isDescendingSort: true,
});

const tutorLeaderboard = computed(() => {
	return store.state.ServiceAnalytics.subjectLeaderboard[props.subject];
});

const sortByTutorLoadScore = () => {
	emit("sort-tutor-load");
	state.isDescendingSort = !state.isDescendingSort;
};
const sortIcon = computed(() => {
	return state.isDescendingSort ? "arrow-down" : "arrow-up";
});
</script>
<template>
	<div class="tw-pb-9 tw-pl-12">
		<div class="xxl:tw-flex tw-justify-between">
			<div class="tw-flex tw-items-center xxl:tw-pb-8 tw-pb-1">
				<h2 class="tw-font-bold xxl:tw-text-lg tw-text-md">
					Current Tutor Session Breakdown
				</h2>
				<InfoToolTip
					class="tw-ml-3 tw-leading-6"
					:width="'15px'"
					:height="'15px'"
					:options="{
						content: CURRENT_TUTOR_SESSION_BREAKDOWN_TOOLTIP,
						placement: 'top',
					}"
				/>
			</div>
			<p class="xxl:tw-text-sm xxl:tw-pb-auto tw-pb-2 tw-text-xs tw-mr-16 tw-mt-0.5 tw-text-origami-grey-300">
				List shows only 6 tutors at a time
			</p>
		</div>
		<table
			v-if="props.isLeaderboardLoaded"
			class="tw-text-sm tw-h-fit tw-table-auto tw-mr-12"
		>
			<thead class="tw-font-bold tw-bg-origami-grey-100">
				<tr>
					<th class="tw-w-1/5 tw-flex">
						<OrigamiButton
							v-jest="'sort-load-score-button'"
							v-data-cy="'sort-load-score-button'"
							variant="tertiary"
							@click.native="sortByTutorLoadScore"
						>
							<span class="tw-flex tw-text-origami-black tw-text-sm">
								Sessions
								<OrigamiIcon
									v-jest="'sort-icon'"
									class="tw-text-origami-black tw-mt-1.5 tw-ml-2"
									width="12"
									height="12"
									:name="sortIcon"
								/>
							</span>
						</OrigamiButton>
					</th>
					<th class="tw-w-4/5">
						Tutor Name
					</th>
				</tr>
			</thead>
			<tbody v-jest="'leaderboard-container'">
				<tr
					v-for="tutor in tutorLeaderboard"
					:key="tutor.id"
					class="tw-border-2 tw-border-origami-grey-100"
				>
					<td class="tw-w-1/5 tw-px-4 tw-py-2">
						{{ tutor.sessions }}
					</td>
					<td class="tw-w-4/5">
						{{ tutor.name }}
					</td>
				</tr>
			</tbody>
		</table>
		<BaseLoader
			v-else
			v-jest="'analytics-loader'"
			thickness="8px"
			class="tw-h-12 tw-w-12 tw-my-20 tw-mx-auto tw-text-blue-regular"
		/>
	</div>
</template>
