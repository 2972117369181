var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BreaksConfirmation", {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "h3",
              {
                directives: [
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "meal-break-request-header",
                    expression: "'meal-break-request-header'",
                  },
                ],
              },
              [_vm._v("\n\t\t\tMeal break request\n\t\t")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "bodyIcon",
        fn: function () {
          return [
            _c("OrigamiIcon", {
              staticClass: "tw-text-origami-grey-400",
              attrs: { name: "schedule", width: "32", height: "32" },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "bodyText",
        fn: function () {
          return [
            _c(
              "p",
              {
                directives: [
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "meal-start-time-text",
                    expression: "'meal-start-time-text'",
                  },
                ],
                staticClass: "tw-text-left tw-mb-1 tw-text-xs",
              },
              [_vm._v("\n\t\t\tStart time\n\t\t")]
            ),
            _vm._v(" "),
            _c("BreakRequestTimeDropdown", {
              attrs: {
                "min-start-time": _vm.minStartTime,
                "max-start-time": _vm.maxStartTime,
              },
              on: { "select-time-block": _vm.handleSelectedTime },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function () {
          return [
            _c(
              "OrigamiButton",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "cancel-meal-break-button",
                    expression: "'cancel-meal-break-button'",
                  },
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "cancel-meal-break-button",
                    expression: "'cancel-meal-break-button'",
                  },
                ],
                staticClass: "tw-m-auto tw-mb-2 tw-w-full tw-justify-center",
                attrs: { variant: "secondary" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.cancelMealBreak.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\tCancel\n\t\t")]
            ),
            _vm._v(" "),
            _c(
              "OrigamiButton",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "request-meal-break-button",
                    expression: "'request-meal-break-button'",
                  },
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "request-meal-break-button",
                    expression: "'request-meal-break-button'",
                  },
                ],
                staticClass: "tw-mb-2 tw-w-full tw-justify-center",
                attrs: {
                  variant: "primary",
                  "is-loading":
                    _vm.$store.state.BreaksManagementWidget.isRequestLoading,
                  disabled: !_vm.selectedTimeBlock,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.scheduleMealBreak.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\tRequest\n\t\t")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }