<template>
	<div class="tw-flex tw-items-center tw-justify-center tw-w-full tw-min-h-screen">
		<OrigamiButton
			v-if="!isLoadingFile"
			v-jest="'google-drive-button'"
			:is-loading="isLoading"
			@click.native="openGooglePicker"
		>
			<span class="tw-flex tw-items-center tw-gap-2">
				<IconGoogleDrive
					aria-hidden="true"
					focusable="false"
				/>
				<span>{{ $t("googleDriveInstructions") }}</span>
			</span>
		</OrigamiButton>
		<div v-else>
			<BaseLoader
				v-jest="'loader'"
				class="tw-h-20 tw-w-20 tw-text-blue-regular tw-mx-auto"
			>
				<IconEssay
					icon-color="currentColor"
					class="tw-w-5 tw-h-7"
					aria-hidden="true"
					focusable="false"
				/>
			</BaseLoader>
			<p class="tw-mt-4 tw-font-bold">
				{{ $t("processingFilePopup") }}
			</p>
		</div>
		<GooglePicker
			ref="googlePicker"
			:auth-config="{ prompt: 'select_account' }"
			:doc-validator="docValidator"
			:mime-types="mimeTypes"
			@change="handleGooglePickerEssayFileInput"
			@cancel="handleCancel"
			@error="handleFileUploadError"
		>
			<div v-show="false" />
		</GooglePicker>
	</div>
</template>

<script>
import { ref } from "vue";
import { OrigamiButton } from "@origami/vue2";

import { TAGS, getFilteredFileTypeIds, formatFileTypeIdsAsExtensionsRegex, formatFileTypeIdsAsMimeTypes } from "../../utilities/fileTypes.js";
import GooglePicker, { UPLOAD_ERROR_KEYS } from "../../components/GooglePicker.vue";
import IconGoogleDrive from "../../components/elements/icons/GoogleDrive.vue";
import BaseLoader from "../../components/elements/BaseLoader.vue";
import IconEssay from "../../components/icons/IconEssay.vue";

// minimum window size where picker UI is at its maximum size (including padding)
export const pickerPopupDimensions = {
	width: 1280,
	height: 768,
};

export default {
	components: {
		BaseLoader,
		GooglePicker,
		IconEssay,
		IconGoogleDrive,
		OrigamiButton,
	},
	setup() {
		const googlePicker = ref(null);
		const isLoading = ref(false);
		const isLoadingFile = ref(false);

		async function openGooglePicker() {
			try {
				isLoading.value = true;
				await googlePicker.value?.trigger();
				isLoadingFile.value = true;
			} catch (e) {
				Sentry.captureException(e);
			}
		}

		const fileUploadFileTypeIds = getFilteredFileTypeIds({
			includeTags: [TAGS.TEXT],
		});
		const essayFileMimeTypes = formatFileTypeIdsAsMimeTypes(fileUploadFileTypeIds);
		const instructionsFileUploadFileTypeIds = getFilteredFileTypeIds({
			includeTags: [TAGS.TEXT, TAGS.IMAGE],
		});
		const instructionsFileMimeTypes = formatFileTypeIdsAsMimeTypes(instructionsFileUploadFileTypeIds);

		const checkIsFileValid = (fileArray, fileTypeIds) => {
			const validFileRegex = formatFileTypeIdsAsExtensionsRegex(fileTypeIds);

			let isValid = true;
			if (fileArray.length !== 1 || !validFileRegex.test(fileArray[0].name)) {
				isValid = false;
			}
			return isValid;
		};
		const essayFileDocValidator = (file) => {
			if (!checkIsFileValid([file], fileUploadFileTypeIds)) {
				throw new Error(UPLOAD_ERROR_KEYS.INVALID);
			}
		};
		const instructionsFileValidator = (file) => {
			if (!checkIsFileValid([file], instructionsFileUploadFileTypeIds)) {
				throw new Error(UPLOAD_ERROR_KEYS.INVALID);
			}
		};

		const handleGooglePickerEssayFileInput = (file) => {
			window.opener?.postMessage({ file }, window.location.origin);
		};
		const handleFileUploadError = (error) => {
			window.opener?.postMessage({ error }, window.location.origin);
		};
		const handleCancel = () => {
			window.opener?.postMessage({ cancel: true }, window.location.origin);
		};

		const queryParams = new URLSearchParams(window.location.search);
		const isInstructionsFile = queryParams.get("is_instructions") === "true";
		const docValidator = isInstructionsFile ? instructionsFileValidator : essayFileDocValidator;
		const mimeTypes = isInstructionsFile ? instructionsFileMimeTypes : essayFileMimeTypes;

		return {
			docValidator,
			googlePicker,
			handleGooglePickerEssayFileInput,
			handleFileUploadError,
			handleCancel,
			isLoading,
			isLoadingFile,
			mimeTypes,
			openGooglePicker,
		};
	},
};
</script>
