import GradesAPI from "@/services/api/Grades.js";
import LmsTypesAPI from "@/services/api/LmsTypes.js";
import LoginTypesAPI from "@/modules/Authentication/services/api/LoginTypes.js"; // barrel file not working here
import PlatformEmailsAPI from "@/services/api/PlatformEmails.js";
import TransformAPI from "@/services/transformers/showTransformer.js";

import SchoolsAPI from "../../services/schools.js";
import UsersAPI from "../../services/users.js";

const getDefaultState = () => ({
	isLoading: true,
	school: {},
	managers: [],
	modalHeader: "",
	modalText: "",
	studentCount: 0,
	teacherCount: 0,
	sectionCount: 0,
	sessionCount: 0,
	saved: "none",
	essayCount: 0,
	administratorHeaderTitles: [
		{
			value: "id",
			header: "ID",
			orderable: true,
			state: null,
		},
		{
			value: "fullName",
			header: "Full Name",
			orderable: true,
			state: null,
		},
		{
			value: "username",
			header: "Username",
			orderable: true,
			state: null,
		},
		{
			value: "email",
			header: "Email",
			orderable: true,
			state: null,
		},
		{
			value: "license",
			header: "License Expiry",
			orderable: true,
			state: null,
		},
		{
			value: "",
			header: " ",
			orderable: false,
			state: null,
		},
	],
	administratorTableData: [],
	orderBy: "",
	grades: [],
	loginTypes: [],
	lmsTypes: [],
	isSendingEmail: false,
	navbarMainTab: "Customers",
	loginInstructions: "",
});

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations: {
		RESET_STATE(state) {
			Object.assign(state, getDefaultState());
		},
		SET_IS_SENDING(state, payload) {
			state.isSendingEmail = payload.isSendingEmail;
		},
		SET_SCHOOL(state, payload) {
			state.school = payload.school;
		},
		SET_GRADES(state, payload) {
			state.grades = payload.grades;
		},
		SET_LOGIN_TYPES(state, payload) {
			state.loginTypes = payload.loginTypes;
		},
		SET_LMS_TYPES(state, payload) {
			state.lmsTypes = payload.lmsTypes;
		},
		SET_TABLE_DATA(state, payload) {
			state.administratorTableData = payload.data;
		},
		SET_ORDER_BY(state, payload) {
			state.orderBy = payload.orderBy;
		},
		SET_SAVED(state, payload) {
			state.saved = payload.saved;
		},
		SET_MODAL(state, payload) {
			state.modalHeader = payload.modalHeader;
			state.modalText = payload.modalText;
		},
		SET_MANAGERS(state, payload) {
			state.managers = payload.managers;
		},
		SET_STATS(state, payload) {
			state.studentCount = payload.students;
			state.teacherCount = payload.teachers;
			state.sectionCount = payload.sections;
			state.sessionCount = payload.sessions;
			state.essayCount = payload.essays;
		},
		UPDATE_HEADERS(state, payload) {
			state.administratorHeaderTitles = payload.headers;
		},
		SET_IS_LOADING(state, payload) {
			state.isLoading = payload.isLoading;
		},
		SET_LOGIN_INSTRUCTIONS(state, payload) {
			state.loginInstructions = payload.loginInstructions;
		},
	},
	getters: {
		ltiTitle(state) {
			return state.school.synced_with;
		},
	},
	actions: {
		async sendEmail({ state, commit }, payload) {
			try {
				commit("SET_IS_SENDING", {
					isSendingEmail: true,
				});
				if (payload.data === undefined) {
					await PlatformEmailsAPI.audienceWelcomeEmail(state.school.id, payload.audience);
				} else {
					await PlatformEmailsAPI.audienceWelcomeEmail(
						state.school.id,
						payload.audience,
						payload.data,
					);
				}
			} catch (e) {
				return Promise.reject(e);
			} finally {
				commit("SET_IS_SENDING", {
					isSendingEmail: false,
				});
			}
		},
		async setSchool({ commit }, payload) {
			payload.params["include"] = "district,grades,manager,classlink,clever";
			try {
				const school = await SchoolsAPI.show(payload.schoolId, payload.params);
				commit("SET_SCHOOL", {
					school: school.data.data,
				});
				commit("SET_LOGIN_INSTRUCTIONS", {
					loginInstructions: school.data.data.login_instructions,
				});
				return Promise.resolve();
			} catch (error) {
				commit("SET_MODAL", {
					modalHeader: "An error has occurred with status " + error.response.status,
					modalText: error.response.data.error_description + " error while loading the school",
				});
				return Promise.reject(error);
			} finally {
				commit("SET_IS_LOADING", {
					isLoading: false,
				});
			}
		},

		async setManagers({ commit }) {
			try {
				const managers = await UsersAPI.list({ role: "superuser" });
				commit("SET_MANAGERS", {
					managers: managers.data.data,
				});
				return Promise.resolve();
			} catch (error) {
				commit("SET_MODAL", {
					modalHeader: "An error has occurred with status " + error.response.status,
					modalText:
						error.response.data.error_description +
						" error while loading the list of superusers for CS managers",
				});
				return Promise.reject(error);
			}
		},
		async updateSchool({ commit, dispatch, state }, payload) {
			try {
				await SchoolsAPI.update(state.school.id, payload.params).then(function() {
					dispatch({
						type: "setSchool",
						schoolId: state.school.id,
						params: {
							include: "district,grades,manager,classlink,clever",
						},
					});

					commit("SET_SAVED", {
						saved: payload.saved,
					});
				});
				return Promise.resolve();
			} catch (error) {
				commit("SET_MODAL", {
					modalHeader: "An error has occurred with status " + error.response.status,
					modalText: "An error occurred while updating the school.",
				});
				return Promise.reject(error);
			}
		},

		async setStats({ commit }, payload) {
			try {
				const stats = await SchoolsAPI.aggregate(payload.schoolId);
				commit({
					type: "SET_STATS",
					students: stats.data.students,
					teachers: stats.data.teachers,
					sections: stats.data.sections,
					sessions: stats.data.sessions,
					essays: stats.data.essays,
				});
				return Promise.resolve();
			} catch (error) {
				commit("SET_MODAL", {
					modalHeader: "An error has occurred with status " + error.response.status,
					modalText: " Sorry, there was a problem loading the stats",
				});
				return Promise.reject(error);
			}
		},

		async setOrderBy({ commit, dispatch, state }, payload) {
			commit("SET_IS_LOADING", {
				isLoading: true,
			});

			commit("SET_ORDER_BY", {
				orderBy: payload,
			});

			try {
				await dispatch({
					type: "setTable",
					schoolId: state.school.id,
					orderBy: payload,
				});

				commit("SET_IS_LOADING", {
					isLoading: false,
				});
				return Promise.resolve();
			} catch (error) {
				commit("SET_MODAL", {
					modalHeader: "An error has occurred with status " + error.response.status,
					modalText: "Oops there was an error while ordering!",
				});
				return Promise.reject(error);
			}
		},

		async setTable({ commit }, payload) {
			try {
				const params = {
					role: [4],
					school: [payload.schoolId],
				};
				if (payload.orderBy) {
					params.orderBy = payload.orderBy;
				}
				const admins = await UsersAPI.filter(params);
				const adminData = [];
				admins.data.data.forEach(function(user) {
					adminData.push(TransformAPI.user(user));
				});

				commit("SET_TABLE_DATA", {
					data: adminData,
				});
				return Promise.resolve();
			} catch (error) {
				commit("SET_MODAL", {
					modalHeader: "An error has occurred with status " + error.response.status,
					modalText: "There was an error while loading the table data",
				});
				return Promise.reject(error);
			}
		},

		async setGrades({ commit }) {
			try {
				const grades = await GradesAPI.list();
				commit("SET_GRADES", {
					grades: grades.data.data,
				});
				return Promise.resolve();
			} catch (error) {
				commit("SET_MODAL", {
					modalHeader: "An error has occurred with status " + error.response.status,
					modalText: "There was an error while loading the grades from the database",
				});
				return Promise.reject(error.response);
			}
		},

		async setLoginTypes({ commit }) {
			try {
				const response = await LoginTypesAPI.list();
				const loginTypes = [{ id: 0, name: "N/A" }, ...response.data.data];
				commit("SET_LOGIN_TYPES", {
					loginTypes: loginTypes,
				});
				return Promise.resolve();
			} catch (error) {
				commit("SET_MODAL", {
					modalHeader: "An error has occurred with status " + error.response.status,
					modalText: "There was an error while loading the login types from the database",
				});
				return Promise.reject(error.response);
			}
		},
		async setLmsTypes({ commit }) {
			try {
				const response = await LmsTypesAPI.list();
				const lmsTypes = [{ id: 0, name: "N/A" }, ...response.data.data];
				commit("SET_LMS_TYPES", {
					lmsTypes: lmsTypes,
				});
				return Promise.resolve(response);
			} catch (error) {
				commit("SET_MODAL", {
					modalHeader: "An error has occurred with status " + error.response.status,
					modalText: "There was an error while loading the lms types from the database",
				});
				return Promise.reject(error.response);
			}
		},
	},
};
