<script>
import { mapState } from "vuex";
import { isEmpty, isNil } from "lodash";

import TransferSessionModalAbstract from "@/components/TransferSessionModalAbstract.vue";

const TUTOR_MANAGER_SHIFT_ID = 3;

export default {
	extends: TransferSessionModalAbstract,
	computed: {
		...mapState(["PlatformManager", "currentUser", "Tutor"]),
		/**
		 * @returns {Boolean}
		 */
		isTutorSelected() {
			return this.newTutorId !== null;
		},
		/**
		 * @returns {Boolean}
		 */
		isTutorListAvailable() {
			return Boolean(this.PlatformManager.ServiceSummaryDashboard.filteredTutors?.length);
		},
		/**
		 * @returns {Boolean}
		 */
		isSubmitButtonDisabled() {
			return !this.isTutorSelected || this.isProcessingTransfer;
		},
		/**
		 * @returns {String}
		 */
		studentNameInSession() {
			return this.PlatformManager.ServiceSummaryDashboard.transferSessionData.sessionName;
		},
		/**
		 * @returns {String}
		 */
		submitHandoffText() {
			return this.isProcessingTransfer ? "Processing..." : "Transfer";
		},
		currentTutorInSessionId() {
			return this.PlatformManager.ServiceSummaryDashboard.transferSessionData.outgoingTutorId;
		},
		schoolRegionCode() {
			return this.PlatformManager.ServiceSummaryDashboard.transferSessionData.schoolRegionCode;
		},
		schoolDistrictId() {
			return this.PlatformManager.ServiceSummaryDashboard.transferSessionData.schoolDistrictId;
		},
		/**
		 * @returns {Array}
		 */
		availableLiveTutors() {
			const allTutorsArray = [
				...this.PlatformManager.ServiceSummaryDashboard.tutorManagers,
				...this.PlatformManager.ServiceSummaryDashboard.filteredTutors,
			];

			return allTutorsArray.filter((tutor) => {
				const { id } = tutor.user;
				const isMatchingTutor = this.tutorName(tutor).toLowerCase().includes(this.searchText.toLowerCase());
				return id !== this.PlatformManager.ServiceSummaryDashboard.transferSessionData.outgoingTutorId &&
					isMatchingTutor;
			});
		},
		isTransferToSelfDisabled() {
			const currentShiftSegment = this.$store.getters["TutorSchedule/getCurrentShiftSegment"];
			return this.currentUser.id === this.currentTutorInSessionId ||
				currentShiftSegment?.type?.id !== TUTOR_MANAGER_SHIFT_ID;
		},
		currentUserRole() {
			return this.currentUser.role;
		},
	},
	methods: {
		/**
		 * Select to which tutor to hand off session
		 * @param {Number} newTutor
		 */
		selectTutor(newTutor) {
			this.newTutorId = newTutor.user.id;
		},
		/**
		 * @returns {String}
		 */
		tutorFirstName(newTutor) {
			return newTutor.user.name;
		},
		/**
		 * @returns {String}
		 */
		tutorId(newTutor) {
			return newTutor.user.id;
		},
		/**
		 * Hides the bootstrap vue modal.
		 */
		hideModal() {
			this.isProcessingTransfer = false;
			this.newTutorId = null;
			this.comment = "";
			this.fileList = [];
			this.searchText = "";
			this.$bvModal.hide("transfer-session-modal");
			this.$store.commit("PlatformManager/ServiceSummaryDashboard/SET_TRANSFER_SESSION_DATA", {
				transferSessionData: {},
			});
		},
		/**
		 * @returns {String}
		 */
		tutorName(newTutor) {
			return newTutor.user.name;
		},
		unsetNewTutorId() {
			return (this.newTutorId = null);
		},
		/**
		 * Creates the handoff in the database and sends it.
		 * @returns {Number}
		 */
		async createAndSendHandoff() {
			const handOffType = this.currentUserRole === "superuser" ? "activity-superuser" : "activity-tutor-manager";
			const params = new FormData();
			params.append("session_id", this.PlatformManager.ServiceSummaryDashboard.transferSessionData.sessionId);
			params.append("incoming_tutor_id", this.newTutorId);
			params.append("handoff_type_name", handOffType);
			params.append("comment", this.comment);
			this.fileList.forEach((file) => {
				if (!isEmpty(file.name)) {
					params.append("files[]", file);
				}
			});
			try {
				const response = await this.$store.dispatch("Activity/createHandoff", params);
				if (response.data.data.incoming_tutor_id === null) {
					throw new Error();
				}
				return response;
			} finally {
				this.searchText = "";
			}
		},
		/**
		 * Sends a request for a handoff and
		 * removes the current session from the
		 * tutors view
		 */
		async handoff() {
			try {
				this.isProcessingTransfer = true;
				if (!isNil(this.newTutorId)) {
					const handoffResponse = await this.createAndSendHandoff();
					if (handoffResponse.status === 200) {
						this.hideModal();
					}
				}
			} catch (e) {
				this.hideModal();
				this.$bvModal.show("activity-failed-transfer-session-modal");
				if (e.response?.status !== 422) {
					Sentry.captureException(e);
				}
			}
		},
		async handoffToSelf() {
			const currentTutorManager = this.PlatformManager.ServiceSummaryDashboard.tutorManagers.find(
				(tutorManager) => {
					return tutorManager.user.id === this.currentUser.id;
				});
			if (currentTutorManager?.user?.id) {
				this.selectTutor(currentTutorManager);
			}
			await this.handoff();
			await this.pushToClassroom();
		},
		async pushToClassroom() {
			this.hideModal();
			await this.$router.push("/tutor/classroom");
		},
		handleFileChange(file) {
			this.fileList.push(file);
		},
		removeAttachmentFile(fileIndex) {
			this.fileList.splice(fileIndex, 1);
		},
	},
};
</script>
