<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		viewBox="0 0 24 16"
	>
		<g
			fill="none"
			fill-rule="evenodd"
		>
			<path d="M0-4h24v24H0z" />
			<path
				:fill="iconColor"
				fill-rule="nonzero"
				d="M4 2h18V0H4C2.9 0 2 .9 2 2v11H0v3h14v-3H4V2zm19 2h-6c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V5c0-.55-.45-1-1-1zm-1 9h-4V6h4v7z"
			/>
		</g>
	</svg>
</template>

<script>
import Colors from "../../utilities/Colors.js";

export default {
	props: {
		width: {
			type: Number,
			default: 24,
		},
		height: {
			type: Number,
			default: 16,
		},
		iconColor: {
			type: String,
			default: Colors.C_BLUE,
		},
	},
};
</script>
