var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tw-shadow-elevation-4" },
    [
      _c("div", { staticClass: "tw-flex tw-justify-between tw-items-center" }, [
        _c(
          "h2",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "title",
                expression: "'title'",
              },
            ],
            staticClass: "tw-font-bold tw-text-lg tw-pl-6 tw-pr-4 tw-py-4",
          },
          [_vm._v("\n\t\t\tEdit Shift\n\t\t")]
        ),
        _vm._v(" "),
        _c("h3", { staticClass: "tw-text-[#6A6B74] tw-font-bold tw-mr-6" }, [
          _vm._v("\n\t\t\tExtend Shift\n\t\t"),
        ]),
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      !_vm.updatedShift
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "tw-px-6 tw-py-4" },
                [
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "extend-shift-message",
                          expression: "'extend-shift-message'",
                        },
                      ],
                      staticClass: "tw-pb-4",
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\tDo you want to add 15 minutes to this shift block?\n\t\t\t"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("ShiftSegmentTypeTag", {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "shift-segment-type-tag",
                        expression: "'shift-segment-type-tag'",
                      },
                    ],
                    attrs: { "shift-type-id": _vm.shift.shiftType?.data.id },
                  }),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "tutor-info",
                          expression: "'tutor-info'",
                        },
                      ],
                      staticClass: "tw-font-bold tw-pt-4 tw-text-sm",
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.name) +
                          " [" +
                          _vm._s(_vm.selectedTutor.id) +
                          "]\n\t\t\t"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "start-time",
                          expression: "'start-time'",
                        },
                      ],
                      staticClass: "tw-pt-2 tw-text-sm",
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.formattedStartTime) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "end-time",
                          expression: "'end-time'",
                        },
                      ],
                      staticClass: "tw-pt-2 tw-text-sm",
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" + _vm._s(_vm.formattedEndTime) + "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "tw-flex tw-justify-between tw-pb-4 tw-pt-4 tw-px-6",
                },
                [
                  _c(
                    "OrigamiButton",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "cancel-extend-button",
                          expression: "'cancel-extend-button'",
                        },
                      ],
                      attrs: {
                        "data-cy": "cancel-extend-button",
                        variant: "secondary",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.cancel()
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\tCancel\n\t\t\t")]
                  ),
                  _vm._v(" "),
                  _c(
                    "OrigamiButton",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "confirm-extend-button",
                          expression: "'confirm-extend-button'",
                        },
                      ],
                      attrs: {
                        "data-cy": "confirm-extend-button",
                        variant: "primary",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.extendShift()
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\tConfirm\n\t\t\t")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.hasAnError
                ? _c("ServiceScheduleTutorErrorMessage", {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "error-message",
                        expression: "'error-message'",
                      },
                    ],
                    staticClass: "tw--mb-16",
                    attrs: { error: _vm.error },
                  })
                : _vm._e(),
            ],
            1
          )
        : _c("ServiceScheduleTutorSuccessfulAction", {
            attrs: {
              shift: _vm.shift,
              "updated-shift": _vm.updatedShift,
              "success-message-in-sidebar": {
                message: "You have successfully edited the shift.",
                buttonText: "Complete",
              },
            },
            on: {
              "cancel-edit": function ($event) {
                return _vm.cancel()
              },
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }