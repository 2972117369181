var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { width: "128", height: "128", viewBox: "0 0 128 128" } },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M0 64C0 28.672 28.672 0 64 0C99.328 0 128 28.672 128 64C128 99.328 99.328 128 64 128C28.672 128 0 99.328 0 64ZM89.6001 64.0001L51.2001 35.2001V92.8001L89.6001 64.0001Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }