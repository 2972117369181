import middleware from "@/middleware/index.js";
import isConnected from "@/middleware/isConnected.js";
import hasRole from "@/middleware/hasRole.js";
import hasSchool from "@/middleware/hasSchool.js";
import administratorDashboardRoutes from "@/modules/EducatorInsights/router/administrator.js";
import initializePendo from "@/middleware/initializePendo.js";
import initializeZendesk from "@/middleware/initializeZendesk.js";
import initFullStory from "@/middleware/initFullStory.js";
import { studentGroupsRoutes } from "@/modules/StudentGroups/index.js";
import { administratorStudentRoutes } from "@/modules/SiteAdministratorStudentList/index.js";
import { MICRO_FRONTENDS_NAMES } from "@/modules/MicroFrontend/utilities/index.js";
import store from "@/store/index.js";

export default [
	{
		path: "/administrator",
		component: () => import("../pages/administrator/index.vue"),
		beforeEnter: middleware([
			isConnected,
			hasRole("administrator"),
			hasSchool,
			initializePendo,
			initializeZendesk,
			initFullStory,
			(to, from, next) => {
				store.commit("SET_SHOW_NAVBAR", true);
				next();
			},
		]),
		children: [
			...administratorDashboardRoutes,
			{
				path: "activity",
				name: "administratorActivity",
				component: () => import("@/modules/Activity/pages/administrator-activity.vue"),
			},
			{
				name: "administratorActivityDashboard",
				path: "activity_mfe",
				component: () => import("@/modules/MicroFrontend/components/MicroFrontend.vue"),
				props: {
					microFrontendName: MICRO_FRONTENDS_NAMES.ACTIVITY_DASHBOARD,
				},
			},
			{
				path: "settings",
				name: "administratorSettings",
				component: () => import("@/modules/UserSettings/pages/administrator-settings.vue"),
			},
			{
				path: "competition",
				name: "administratorCompetitions",
				component: () => import("@/pages/administrator/competition/index.vue"),
			},
			{
				path: "competition/:schoolId",
				name: "administratorCompetitionsBySection",
				component: () => import("@/pages/administrator/competition/section.vue"),
			},
			{
				path: "past-competitions",
				name: "administratorPastCompetitions",
				component: () => import("@/pages/administrator/competition/past.vue"),
			},
			...studentGroupsRoutes,
			...administratorStudentRoutes,
		],
	},
];
