<template>
	<div>
		<NotificationButton
			ref="buttonRef"
			v-jest="'button'"
			:is-selected="hasNewNotifications"
			:notification-count="sortedAchievementsList.length"
			:points="points"
		/>
		<NotificationPopover
			ref="popoverRef"
			v-jest="'popover'"
			v-data-cy="'popover'"
			:items="sortedAchievementsList"
			class="tw-flow-root tw-mt-6"
			@close="popover.hide()"
		>
			<div
				class="tw-flex tw-justify-between tw-items-start tw-max-w-full tw-relative"
				:class="!hasAchievements ? 'closeButton__positioning' : 'tw-border-b tw-border-origami-grey-200 tw-mx-2'"
			>
				<h4
					v-if="hasAchievements"
					v-jest="'achievement-header'"
					v-data-cy="'achievement-header'"
					class="tw-flex tw-align-center tw-gap-1 tw-w-full tw-pb-4 tw-pt-2 tw-font-bold tw-text-lg"
				>
					<Emoji
						emoji="Medal"
						class="medal"
					/>
					{{ $t("achievements.title", { num: numberOfAchievements }) }}
				</h4>
				<OrigamiIconButton
					v-jest="'close-button'"
					v-data-cy="'notification-close-button'"
					icon-name="close"
					:class="{ 'tw-relative tw--right-4 tw--top-2': hasAchievements }"
					@click.native="popover.hide()"
				/>
			</div>
		</NotificationPopover>
	</div>
</template>

<script>
import tippy from "tippy.js";
import { mapState } from "vuex";
import { cloneDeep } from "lodash";
import { OrigamiIconButton } from "@origami/vue2";

import { Emoji } from "@/modules/Emoji/index.js";

import { celebrate, originateFromElement, randomInRange } from "../../utilities/celebrate.js";

import NotificationButton from "./NotificationButton.vue";
import NotificationPopover from "./NotificationPopover.vue";

const TRIGGER = {
	HOVER: "mouseenter focus",
	FORCED: "manual",
};
function isGoalCompleted(goal) {
	return goal.completed >= goal.target;
}
function isChallengeCompleted(challenge) {
	return challenge.goals.every(isGoalCompleted);
}
function isAnyGoalNewlyCompleted(newChallenge, oldChallenge) {
	return newChallenge.goals.some((newGoal) => {
		if (isGoalCompleted(newGoal)) {
			const oldGoal = oldChallenge?.goals?.find(({ id }) => id === newGoal.id);
			if (oldGoal && !isGoalCompleted(oldGoal)) {
				return true;
			}
		}
		return false;
	});
}

export default {
	components: {
		NotificationButton,
		NotificationPopover,
		Emoji,
		OrigamiIconButton,
	},
	sockets: {
		async trophy(trophy) {
			this.updateTrophy(trophy);
			this.addAchievement({
				type: "trophy",
				points: trophy.trophy.points,
				trophy,
			});
			if (trophy.name === "avatar-customization") {
				this.$store.dispatch("Achievements/setIsShowingAvatarCustomization");
			}
			this.handleCelebrate();
		},
		async challenge() {
			const oldVal = cloneDeep(this.Challenges.challenge);
			const wasChallengeAlreadyCompleted = isChallengeCompleted(oldVal);
			await this.refreshChallengeData();

			const newVal = this.Challenges.challenge;
			const isChallengeNowCompleted = isChallengeCompleted(newVal);
			const isChallengeNewlyCompleted = isChallengeNowCompleted && !wasChallengeAlreadyCompleted;
			const isChallengeGoalNewlyCompleted = isAnyGoalNewlyCompleted(newVal, oldVal);

			const payload = isChallengeNowCompleted
				? {
					type: "trophy",
					// points: CHALLENGE_POINTS,
					trophy: {
						name: "challenge-completed",
					},
				}
				: {
					type: "challenge",
					points: 0, // need to set points to 0 for sorting in popover
					challenge: newVal,
					// possible_points: CHALLENGE_POINTS,
				};
			const challengeIndex = this.achievementsList.findIndex((item) => {
				const isChallengeType = item.type === "challenge";
				const isCompletedChallengeTrophy = item?.trophy?.name === "challenge-completed";
				return isChallengeType || isCompletedChallengeTrophy;
			});
			if (challengeIndex === -1) {
				if (isChallengeNewlyCompleted || isChallengeGoalNewlyCompleted) {
					this.addAchievement(payload);
				}
			} else {
				this.achievementsList.splice(challengeIndex, 1, payload);
			}

			if (isChallengeNewlyCompleted || isChallengeGoalNewlyCompleted) {
				this.hasNewNotifications = true;
				this.handleCelebrate();
			}
		},
		async activity(activity) {
			this.addAchievement({
				type: "activity",
				points: activity.points,
				activity,
			});
			this.handleCelebrate();
		},
	},
	data() {
		return {
			popover: null,
			hasNewNotifications: false,
			achievementsList: [],
		};
	},
	computed: {
		...mapState("Student", ["Challenges", "Profile"]),
		...mapState(["Achievements"]),
		sortedAchievementsList() {
			const list = [...this.achievementsList];
			return list.sort((a, b) => b.points - a.points);
		},
		numberOfAchievements() {
			return this.sortedAchievementsList.filter((item) => item.type === "trophy").length;
		},
		hasAchievements() {
			return this.numberOfAchievements > 0;
		},
		points() {
			return this.$store.getters["Achievements/points"];
		},
		isLoading() {
			return this.Achievements.isLoading;
		},
	},
	watch: {
		achievementsList(current) {
			if (!current) {
				this.popover.setProps({ trigger: TRIGGER.FORCED }); // prevent showing if no contents
			} else if (!this.hasNewNotifications) {
				this.popover.setProps({ trigger: TRIGGER.HOVER });
			}
		},
		hasNewNotifications(current) {
			if (current) {
				this.popover.setProps({ trigger: TRIGGER.FORCED }); // prevent closing on mouseleave
				this.popover.show();
			}
		},
		$route: {
			handler(current, previous) {
				if (current?.path !== previous?.path) {
					this.popover.hide();
				}
			},
			deep: true,
		},
	},
	async created() {
		await this.refreshChallengeData();
		if (!this.$socket.connected) {
			this.$socket.connect();
		}

		const unwatch = this.$watch("isLoading", (val) => {
			if (!val) {
				unwatch();
			}
		});
	},
	async mounted() {
		this.popover = tippy(this.$refs.buttonRef.$el, {
			content: this.$refs.popoverRef.$el,
			placement: "bottom-start",
			theme: "origami-card",
			maxWidth: "calc(20rem + (2 * 1rem))",
			interactive: true,
			hideOnClick: false,
			trigger: TRIGGER.FORCED,
			onHide: () => {
				this.popover.setProps({ trigger: TRIGGER.HOVER }); // (re)enable showing on hover
				this.hasNewNotifications = false;
			},
		});
	},
	methods: {
		async handleCelebrate() {
			for (const i in [1, 2, 3]) { // eslint-disable-line no-unused-vars
				celebrate({
					decay: randomInRange(0.96, 0.98),
					startVelocity: randomInRange(1, 25),
					particleCount: randomInRange(150, 200),
					spread: 360,
					scalar: randomInRange(0.5, 1.2),
					...originateFromElement(this.$refs.buttonRef?.$el),
				});
				await new Promise((r) => setTimeout(r));
			}
		},
		async refreshChallengeData() {
			return this.$store.dispatch("Student/Challenges/getChallengesData");
		},
		addAchievement(achievement) {
			this.achievementsList = this.achievementsList.concat(achievement);
			this.hasNewNotifications = true;
		},
		updateTrophy(trophy) {
			this.$store.commit("Achievements/ACHIEVE_TROPHY", {
				trophyId: trophy.trophy_id,
				trophy,
			});
		},
		doesStudentHaveBadge(name) {
			return this.$store.getters["Achievements/badges"].some((badge) => {
				return badge.name === name ? Boolean(badge.$my?.achieved_at) : false;
			});
		},
	},
};
</script>

<style scoped>
.closeButton__positioning {
	@apply tw-absolute tw-right-4 tw-top-4;
}
.medal {
	font-size: 1.75rem;
}
</style>
