var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ListItemLayout", {
    staticClass:
      "tw--mt-1 tw-mb-1 tw-bg-origami-grey-100 tw-border-origami-grey-100",
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", { staticClass: "tw-flex tw-pl-6" }, [
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "item-content-question",
                      expression: "'item-content-question'",
                    },
                  ],
                  staticClass:
                    "ellipsis tw-overflow-hidden tw-break-word tw-leading-6 tw-tracking-wide",
                },
                [
                  _vm._v("\n\t\t\t\tAre you sure you want to delete\n\t\t\t\t"),
                  _c("span", { staticClass: "tw-font-bold" }, [
                    _vm._v(_vm._s(_vm.groupName)),
                  ]),
                  _vm._v("\n\t\t\t\t?\n\t\t\t"),
                ]
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c("div", { staticClass: "tw-mr-6" }, [
              !_vm.isDeleting
                ? _c(
                    "div",
                    { staticClass: "tw-flex tw-flex-row" },
                    [
                      _c(
                        "BaseButton",
                        {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: "cancel-button",
                              expression: "'cancel-button'",
                            },
                          ],
                          staticClass: "tw-mr-2",
                          attrs: {
                            variants: ["outline", "mini"],
                            "aria-label": "Cancel group deletion",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.$emit("cancel-delete")
                            },
                          },
                        },
                        [_vm._v("\n\t\t\t\t\tCancel\n\t\t\t\t")]
                      ),
                      _vm._v(" "),
                      _c(
                        "BaseButton",
                        {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: "delete-button",
                              expression: "'delete-button'",
                            },
                          ],
                          attrs: {
                            color: "red",
                            variants: ["full", "mini"],
                            "aria-label": "Confirm group deletion",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.deleteGroup.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("\n\t\t\t\t\tDelete Group\n\t\t\t\t")]
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("BaseLoader", {
                        staticClass:
                          "tw-h-8 tw-w-8 tw-mr-2 tw-text-blue-regular",
                      }),
                    ],
                    1
                  ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }