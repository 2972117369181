var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M6 0C3.79086 0 2 1.79086 2 4V20C2 22.2091 3.79086 24 6 24H20.5C21.3284 24 22 23.3284 22 22.5V1.5C22 0.671573 21.3284 0 20.5 0H6ZM5.5 19C4.67157 19 4 19.6716 4 20.5C4 21.3284 4.67157 22 5.5 22H20V19H5.5ZM6 12.3484L8.00151 6H9.55776L11.5593 12.3484H10.4659L10.0273 10.9729H7.52664L7.09334 12.3484H6ZM8.76934 7.02803L9.71099 9.98099H7.83912L8.76934 7.02803ZM12.7515 15.5478V15.4023L15.8816 10.1957H12.9719V9.19939H17.72V9.34928L14.6075 14.547H17.645V15.5478H12.7515Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }