var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("OrigamiDialog", {
    attrs: {
      title: "Do you want to mark this as a false alarm?",
      description: "This will be marked as a false alarm.",
      "primary-button-text": "Yes, false alarm",
      "secondary-button-text": "Cancel",
      "is-open": _vm.isOpen,
      "is-loading": _vm.isUpdating,
    },
    on: { confirm: _vm.updateActionItem, cancel: _vm.closeDialog },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }