<template>
	<Navbar
		v-jest="'navbar'"
		:dropdown-links="reactiveDropdownLinks"
		:is-sidebar-toggled="isSidebarToggled"
		:is-mobile-menu-toggled="isMobileMenuToggled"
		@toggle-sidebar="emit('toggle-sidebar')"
		@toggle-mobile-menu="emit('toggle-mobile-menu')"
		@close-mobile-menu="emit('close-mobile-menu')"
	>
		<template #beforeAvatar>
			<AchievementNotificationHandler />
			<MicroFrontendContent
				v-if="featureFlagStudentNotificationsDrawerIsEnabled"
				:micro-frontend-name="MICRO_FRONTENDS_NAMES.STUDENT_NOTIFICATIONS_DRAWER"
				:is-widget="true"
			>
				<template #loader>
					<BaseLoader class="tw-h-10 tw-w-10" />
				</template>

				<template #error>
					<!-- Could not load notifications drawer -->
					<div />
				</template>
			</MicroFrontendContent>
			<LanguagePicker @select-language="(lang) => store.dispatch('Lang/changeLanguage', lang)" />
		</template>
	</Navbar>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { getFlag } from "@paper-co/web-toolkit";

import useStore from "@/composables/useStore.js";
import LanguagePicker from "@/modules/Languages/components/LanguagePicker.vue";
import AchievementNotificationHandler from "@/modules/Achievements/components/Notifications/NotificationHandler.vue";
import MicroFrontendContent from "@/modules/MicroFrontend/components/MicroFrontendContent.vue";
import { MICRO_FRONTENDS_NAMES } from "@/modules/MicroFrontend/utilities/index.js";
import BaseLoader from "@/components/elements/BaseLoader.vue";

import useNavLinks from "../composables/useNavLinks.js";

import Navbar from "./Navbar.vue";

const emit = defineEmits(["toggle-sidebar", "toggle-mobile-menu", "close-mobile-menu"]);
defineProps({
	isSidebarToggled: {
		type: Boolean,
		required: true,
	},
	isMobileMenuToggled: {
		type: Boolean,
		required: true,
	},
});

const store = useStore();

const { reactiveDropdownLinks } = useNavLinks();

const featureFlagStudentNotificationsDrawerIsEnabled = ref(false);

onMounted(async() => {
	featureFlagStudentNotificationsDrawerIsEnabled.value = await getFlag("GROW-1054-student-notifications-drawer");
});

</script>
