<template>
	<div class="tw-w-full">
		<img
			:v-jest="milestoneNumbers.includes(essayNumber) ? `milestone-${essayNumber}` : 'normal'"
			:src="`/images/tutor/${currentFeedbackPicture}.png`"
			class="tw-max-w-80 tw-max-h-80 tw-mx-auto"
		>
		<h2 class="tw-w-full tw-break-words tw-text-2xl tw-text-center tw-font-bold tw-flex-col">
			<div v-if="milestoneNumbers.includes(essayNumber)">
				<span v-jest="'top-text'">
					Congratulations!
				</span>
				<span
					v-jest="'bottom-text'"
					class="tw-whitespace-nowrap"
				>
					You've uploaded your {{ essayNumber }}th essay!
				</span>
			</div>
			<div v-else>
				<span v-jest="'top-text'">
					You've done it!
				</span>
				<span
					v-jest="'bottom-text'"
					class="tw-whitespace-nowrap"
				>
					Thank you for your hard work.
				</span>
			</div>
		</h2>
	</div>
</template>

<script>

export default {
	props: {
		essayNumber: {
			required: true,
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			milestoneNumbers: [100, 500, 1000],
		};
	},
	computed: {
		currentFeedbackPicture() {
			const feedbackChoices = {
				100: "essay-review-tutor-feedback-100",
				500: "essay-review-tutor-feedback-500",
				1000: "essay-review-tutor-feedback-1000",
			};
			return feedbackChoices[this.essayNumber] || "essay-review-tutor-feedback";
		},
	},
};
</script>
