import { cloneDeep } from "lodash";

import StudentGroupsApi from "../services/StudentGroupsApi.js";
import { MAX_LIMIT_BY_PAGE, DEFAULT_PARAMS_PAGE, DEFAULT_PARAMS_ORDER } from "../utilities/constants.js";

const API_VALIDATION_ERROR_CODE = 422;

const getObjectArrayFromIds = (selectedIds, optionObjs) => (
	optionObjs.reduce((accumulator, option) => {
		if (selectedIds.includes(option.id)) {
			accumulator.push(option);
		}
		return accumulator;
	}, [])
);

const getDefaultState = () => ({
	group: {},
	groups: [],
	groupIsLoading: true,
	groupMembers: [],
	groupMembersPage: 1,
	groupMembersPaginated: [],
	nameError: {},
	studentError: {},
	studentList: [],
	initialFilterOptions: {
		schools: [],
		teachers: [],
		courses: [],
	},
	filterOptions: {
		schools: [],
		teachers: [],
		courses: [],
	},
	filterSelections: {
		schools: [],
		teachers: [],
		courses: [],
	},
	studentListIsLoading: true,
	studentListParams: {
		order: DEFAULT_PARAMS_ORDER,
		page: DEFAULT_PARAMS_PAGE,
		search: "",
	},
	studentListPagination: {},
	toaster: {
		isShowing: false,
		text: "",
	},
});

const state = getDefaultState();

const mutations = {
	SET_GROUPS(state, payload) {
		state.groups = payload;
	},
	SET_GROUP(state, payload) {
		state.group = payload;
	},
	SET_GROUP_IS_LOADING(state, isLoading) {
		state.groupIsLoading = isLoading;
	},
	SET_STUDENT_LIST(state, payload) {
		state.studentList = payload;
	},
	SET_STUDENT_LIST_ORDER(state, payload) {
		state.studentListParams.order = payload.order;
	},
	SET_STUDENT_LIST_PAGE(state, payload) {
		state.studentListParams.page = payload.page;
	},
	SET_STUDENT_LIST_SEARCH(state, payload) {
		state.studentListParams.search = payload.search;
	},
	SET_STUDENT_LIST_PAGINATION(state, payload) {
		state.studentListPagination = payload;
	},
	SET_GROUP_IS_LOADING(state, isLoading) {
		state.groupIsLoading = isLoading;
	},
	SET_STUDENT_LIST_IS_LOADING(state, isLoading) {
		state.studentListIsLoading = isLoading;
	},
	ADD_STUDENT_TO_GROUP(state, student) {
		state.groupMembers.push(student);
	},
	SET_GROUP_MEMBERS(state, members) {
		state.groupMembers = members;
	},
	REMOVE_STUDENT_FROM_GROUP(state, student) {
		state.groupMembers = state.groupMembers.filter((member) => {
			return member.id !== student.id;
		});
	},
	SET_GROUP_MEMBERS_PAGINATION(state, currentPage) {
		const paginatedGroupStudents = (page) => (
			state.groupMembers.slice(
				(MAX_LIMIT_BY_PAGE * (page - 1)),
				MAX_LIMIT_BY_PAGE * page,
			)
		);
		const page =
			(paginatedGroupStudents(currentPage).length === 0 && currentPage > 1) ? currentPage - 1 : currentPage;
		state.groupMembersPage = page;
		state.groupMembersPaginated = paginatedGroupStudents(page);
	},
	SET_GROUP_ERROR(state, { studentError = {}, nameError = {} }) {
		state.studentError = studentError;
		state.nameError = nameError;
	},
	SET_TOASTER(state, { text, isShowing }) {
		state.toaster = { text, isShowing };
	},
	RESET_FILTER_OPTIONS(state) {
		state.filterOptions = cloneDeep(state.initialFilterOptions);
	},
	SET_FILTER_OPTIONS(state, filters) {
		state.filterOptions = cloneDeep(filters);
		state.initialFilterOptions = cloneDeep(filters);
	},
	SET_FILTER_SELECTIONS(state, filter) {
		state.filterSelections[filter.name] = filter.value;
	},
	RESET_FILTER_SELECTIONS(state) {
		const defaultState = getDefaultState();
		state.filterSelections = defaultState.filterSelections;
	},
	SET_FILTER_OPTIONS_BY_NAME(state, filter) {
		state.filterOptions[filter.name] = filter.value;
	},
};

const getters = {
	getStudentListQuery(state) {
		const { order, page, search } = state.studentListParams;

		let query = `?page=${page}`;
		if (!!order.by && !!order.dir) {
			query += `&order_by=${order.by}&order_by_dir=${order.dir}`;
		}
		if (!!search) {
			query += `&search=${search}`;
		}

		return query;
	},
};

const actions = {
	adjustFromSchools({ commit, state }) {
		let schools = getObjectArrayFromIds(state.filterSelections.schools, state.initialFilterOptions.schools);

		if (!state.filterSelections.schools.length) {
			schools = state.filterOptions.schools;
			commit("SET_FILTER_SELECTIONS", { name: "schools", value: [] });
			commit("SET_FILTER_SELECTIONS", { name: "courses", value: [] });
		}

		const filteredTeachersIds = schools.reduce((accumulator, current) => {
			accumulator.push(...current.teacher_ids);
			return [...new Set(accumulator)];
		}, []);

		const teachers = getObjectArrayFromIds(filteredTeachersIds, state.initialFilterOptions.teachers);

		commit("SET_FILTER_OPTIONS_BY_NAME", { name: "teachers", value: teachers });

		const filteredCourseIds = teachers.reduce((accumulator, current) => {
			accumulator.push(...current.course_ids);
			return accumulator;
		}, []);

		const courses = getObjectArrayFromIds([...new Set(filteredCourseIds)], state.initialFilterOptions.courses);

		commit("SET_FILTER_OPTIONS_BY_NAME", { name: "courses", value: courses });
	},
	adjustFromTeachers({ commit, dispatch, state }) {
		if (!state.filterSelections.teachers.length) {
			dispatch("adjustFromSchools");
			commit("SET_FILTER_SELECTIONS", { name: "courses", value: [] });
		} else {
			const teachers =
				getObjectArrayFromIds(state.filterSelections.teachers, state.initialFilterOptions.teachers);

			const filteredCourseIds = teachers.reduce((accumulator, current) => {
				accumulator.push(...current.course_ids);
				return [...new Set(accumulator)];
			}, []);

			const courses = getObjectArrayFromIds(filteredCourseIds, state.initialFilterOptions.courses);

			commit("SET_FILTER_OPTIONS_BY_NAME", { name: "courses", value: courses });
		}
	},
	async getGroups({ commit }, params = {}) {
		try {
			const { groups } = await StudentGroupsApi.getStudentGroups(params);
			commit("SET_GROUPS", groups);
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	async getGroup({ commit }, groupId) {
		commit("SET_GROUP_IS_LOADING", true);
		try {
			const group = await StudentGroupsApi.getStudentGroup(groupId);
			commit("SET_GROUP", group);
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	async getGroupUsers({ commit }, groupId) {
		try {
			const members = await StudentGroupsApi.getGroupUsers(groupId);
			commit("SET_GROUP_MEMBERS", members);
		} catch (error) {
			Sentry.captureException(error);
		} finally {
			commit("SET_GROUP_IS_LOADING", false);
		}
	},
	async deleteGroup(_, groupId) {
		try {
			return await StudentGroupsApi.deleteStudentGroup(groupId);
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	async createGroup({ commit }, name) {
		commit("SET_GROUP_ERROR", { nameError: {}, studentError: {} });
		try {
			const { group } = await StudentGroupsApi.createStudentGroup(name);
			commit("SET_GROUP", group);
		} catch (error) {
			if (error.response.status === API_VALIDATION_ERROR_CODE) {
				commit("SET_GROUP_ERROR", { nameError: error.response.data });
			} else {
				Sentry.captureException(error);
			}
		}
	},
	async updateGroup({ commit }, params) {
		commit("SET_GROUP_ERROR", { nameError: {}, studentError: {} });
		try {
			const group = await StudentGroupsApi.updateStudentGroupUsers(params);
			commit("SET_GROUP", group);
			commit("SET_TOASTER", {
				text: `The group "${group.name}" has been ${params.type}`,
				isShowing: true,
			});
		} catch (error) {
			if (error.response?.status === API_VALIDATION_ERROR_CODE) {
				commit("SET_GROUP_ERROR", { studentError: error.response.data });
			} else {
				Sentry.captureException(error);
			}
		}
	},
	async updateGroupName({ commit }, { groupId, name }) {
		commit("SET_GROUP_ERROR", { nameError: {}, studentError: {} });
		try {
			const { group } = await StudentGroupsApi.updateStudentGroupName(groupId, name);
			commit("SET_GROUP", group);
		} catch (error) {
			if (error.response.status === API_VALIDATION_ERROR_CODE) {
				commit("SET_GROUP_ERROR", { nameError: error.response.data });
			} else {
				Sentry.captureException(error);
			}
		}
	},
	async getStudents({ commit, getters, state }) {
		commit("SET_STUDENT_LIST_IS_LOADING", true);
		try {
			const query = getters.getStudentListQuery;
			const params = {
				school_ids: state.filterSelections.schools,
				teacher_ids: state.filterSelections.teachers,
				course_ids: state.filterSelections.courses,
			};
			const { users, pagination } = await StudentGroupsApi.getUsers({ query, params });
			commit("SET_STUDENT_LIST", users);
			commit("SET_STUDENT_LIST_PAGINATION", pagination);
		} catch (error) {
			Sentry.captureException(error);
		} finally {
			commit("SET_STUDENT_LIST_IS_LOADING", false);
		}
	},
	async getFilters({ commit }) {
		try {
			const filters = await StudentGroupsApi.getGroupFilters();
			commit("SET_FILTER_OPTIONS", filters);
		} catch (error) {
			Sentry.captureException(error);
		}
	},
};

export default {
	namespaced: true,
	actions,
	getters,
	mutations,
	state,
};
