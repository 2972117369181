import ActivityMessageFactory from "../factories/ActivityMessageFactory.js";

const privateMethods = {
	checkIfSenderIsTutor(rolesArray) {
		let isTutor = false;
		for (let i = 0; i < rolesArray.length; i++) {
			if (rolesArray[i].id === 2) {
				isTutor = true;
			}
		}
		return isTutor;
	},

	/**
	 *
	 * @param {Number} prevSender - user id
	 * @param {Number} currentSender - user id
	 * @param {Number} nextSender - user id
	 * @param {Number} index
	 * @param {Number} messagesLength
	 */
	determineSequencePosition(prevSender, currentSender, nextSender, index, messagesLength) {
		const nextIndex = index + 1;
		let sequencePosition = "top";

		if (nextIndex === messagesLength && currentSender === prevSender) {
			sequencePosition = "bottom";
		} else {
			if (prevSender === currentSender && nextSender === currentSender) {
				sequencePosition = "middle";
			} else if (prevSender === currentSender && nextSender !== currentSender) {
				sequencePosition = "bottom";
			}
		}
		return sequencePosition;
	},

	checkIfLastMessageInSequence(currentSender, nextSender) {
		if (currentSender !== nextSender) {
			return true;
		}
		return false;
	},

	/**
	 *
	 * @param {Object[]} messagesArray
	 * @param {Number} currentIndex
	 * @param {Number} previousIndex
	 * @param {Number} nextIndex
	 */
	determinePreviousCurrentNextSenders(messages, index) {
		const prevIndex = index - 1;
		const nextIndex = index + 1;
		let prevSender = null,
			nextSender = null;

		const currentSender = messages[index].user_id;

		if (prevIndex !== 0 && messages[prevIndex].message_type !== "system") {
			prevSender = messages[prevIndex].user_id;
		}

		if (nextIndex !== messages.length && messages[nextIndex].message_type !== "system") {
			nextSender = messages[nextIndex].user_id;
		}

		return [prevSender, currentSender, nextSender];
	},
};

export default {
	transformMessagesForActivity(messages) {
		const transformedMessages = [];
		let prevSender, currentSender, nextSender;
		messages.forEach(function(message, index) {
			if (message.message_type !== "system") {
				[
					prevSender,
					currentSender,
					nextSender,
				] = privateMethods.determinePreviousCurrentNextSenders(messages, index);
				message["isTutor"] = privateMethods.checkIfSenderIsTutor(message.user.data.roles.data);
				message["isLastMessage"] = privateMethods.checkIfLastMessageInSequence(
					currentSender,
					nextSender,
				);
				message["sequencePosition"] = privateMethods.determineSequencePosition(
					prevSender,
					currentSender,
					nextSender,
					index,
					messages.length,
				);
			}

			transformedMessages.push(ActivityMessageFactory.create(message));
		});
		return transformedMessages;
	},
};
