import api from "../../../services/api/Api.js";

export default {
	/**
     * Returns a collection of action items. Defaults to returning all action items
     * @apiParam {String} [order_by] should be in the format of ORDER_BY_FIELD.ORDER
     * ORDER_BY_FIELD can be 'id', 'picked_up_at', 'resolved_at', 'created_at', 'updated_at'
     * ORDER can be 'asc' or 'desc'
     * e.g. 'created_at.asc'
     * @apiParam {Number} [type_id] the type id corresponding to the types listed in platform_manager_action_item_types table
     * @apiParam {Number} [picked_up_user_id] the user id of the platform manager who picked up the action item
     * @apiParam {Number} [action_type_id] resolution type corresponding to the types listed in platform_manager_action_item_action_types table
     * @apiParam {Number} [is_picked_up] 0 or 1
     * @apiParam {Number} [is_resolved] 0 or 1
     * @returns {AxiosPromise}
     */
	list(filters) {
		const params = {
			include: "pickedUpUser",
			...filters,
		};
		return api().get(`/platform-manager-action-items`, { params });
	},
	update({ payload, id }) {
		return api().put(`/platform-manager-action-items/${id}`, {
			...payload,
		});
	},
};
