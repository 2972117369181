var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BModal",
    {
      attrs: {
        id: "tutorDashboardSubmitAvailabilitiesModal",
        "content-class": "bootstrapFourModalContent",
        "body-class": "bootstrapFourModalBody",
        "footer-class": "bootstrapFourModalFooter",
        "hide-header": true,
        "no-close-on-backdrop": true,
        "no-fade": true,
        centered: true,
      },
    },
    [
      _c(
        "button",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "close-button",
              expression: "'close-button'",
            },
          ],
          staticClass: "submitAvailabilitiesModal__closeButton",
          on: {
            click: function ($event) {
              return _vm.hideModal()
            },
          },
        },
        [_vm._v("\n\t\t×\n\t")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "submitAvailabilitiesModal__header" }, [
        _c(
          "div",
          { staticClass: "submitAvailabilitiesModal__icon" },
          [
            _c("IconCalendar", {
              staticClass: "submitAvailabilitiesModal__svg",
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "submitAvailabilitiesModal__title" }, [
          _vm._v("\n\t\t\tConfirm Availability Submission\n\t\t"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "submitAvailabilitiesModal__content" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "create-availabilities-modal-text",
                expression: "'create-availabilities-modal-text'",
              },
            ],
            key: "create-availabilities-modal-text",
          },
          [
            _c("p", { staticClass: "submitAvailabilitiesModal__text" }, [
              _vm._v("\n\t\t\t\tYou will be scheduled up to "),
              _c(
                "strong",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "max-hours",
                      expression: "'max-hours'",
                    },
                  ],
                },
                [_vm._v(_vm._s(_vm.maxHours) + " hours")]
              ),
              _vm._v(" of the "),
              _c(
                "strong",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "total-availabilities",
                      expression: "'total-availabilities'",
                    },
                  ],
                },
                [_vm._v(_vm._s(_vm.totalHoursForWeek) + " hours")]
              ),
              _vm._v(" you selected.\n\t\t\t"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "submitAvailabilitiesModal__text" }, [
              _vm._v(
                "\n\t\t\t\tYou can edit your availability until the submission deadline.\n\t\t\t"
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isSubmissionErrorShowing,
                expression: "isSubmissionErrorShowing",
              },
              {
                name: "jest",
                rawName: "v-jest",
                value: "submission-error-message",
                expression: "'submission-error-message'",
              },
            ],
            staticClass: "submitAvailabilitiesModal__errorText",
          },
          [
            _vm._v(
              "\n\t\t\tThere was a problem submitting your availability. If this problem persists, please contact\n\t\t\tsupport.\n\t\t"
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "modal-footer" },
        [
          _c(
            "BaseButton",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "cancel-button",
                  expression: "'cancel-button'",
                },
              ],
              staticClass: "submitAvailabilitiesModal__button",
              attrs: { type: "CANCEL" },
              nativeOn: {
                click: function ($event) {
                  return _vm.hideModal()
                },
              },
            },
            [
              _c("template", { slot: "text" }, [
                _vm._v("\n\t\t\t\tCancel\n\t\t\t"),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "BaseButton",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "submit-button",
                  expression: "'submit-button'",
                },
              ],
              staticClass: "submitAvailabilitiesModal__button",
              attrs: { type: "SUBMIT", "is-disabled": _vm.isSubmitting },
              nativeOn: {
                click: function ($event) {
                  return _vm.submitAvailabilities()
                },
              },
            },
            [
              _c("template", { slot: "text" }, [
                _vm._v("\n\t\t\t\tSubmit\n\t\t\t"),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }