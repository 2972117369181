export default [
	{
		path: "",
		redirect: "users",
	},
	{
		path: "users",
		component: () => import("../../components/users/UsersView.vue"),
		children: [
			{
				path: "",
				name: "superuserUsersList",
				component: () => import("../../components/users/UsersListWrapper.vue"),
			},
			{
				path: "create",
				name: "superuserUsersCreate",
				component: () => import("../../components/users/UserCreateWrapper.vue"),
			},
			{
				path: ":userId",
				name: "superuserUsersEdit",
				component: () => import("../../components/users/UserEditWrapper.vue"),
			},
		],
	},
];
