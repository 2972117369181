var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "shiftCalendar", staticClass: "tw-relative" },
    [
      _c(
        _setup.BaseButton,
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "calendar-button",
              expression: "'calendar-button'",
            },
            {
              name: "data-cy",
              rawName: "v-data-cy",
              value: "calendar-button",
              expression: "'calendar-button'",
            },
          ],
          staticClass:
            "calendarButton !tw-pt-2.5 !tw-pr-0 !tw-pb-2.5 !tw-pl-2.5 tw-outline",
          class: { "!tw-rounded-r-none": _setup.props.isLeftButton },
          attrs: { type: _vm.buttonType },
          nativeOn: {
            click: function ($event) {
              return _setup.toggleIsShowingSummary()
            },
          },
        },
        [
          _c(
            "template",
            { slot: "icon" },
            [_c(_setup.iconComponent, { tag: "component" })],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _setup.isShowingSummary
        ? _c(_setup.TutorDashboardShiftSummary, {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "punch-clock-shift-summary",
                expression: "'punch-clock-shift-summary'",
              },
            ],
            staticClass:
              "tw-w-[360px] tw-top-[4.1em] tw-z-30 tw-absolute tw-right-0 tw-shadow-elevation-2",
            attrs: {
              "data-cy": "punch-clock-shift-summary",
              "is-title-showing": false,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }