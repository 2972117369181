import middleware from "@/middleware/index.js";
import isConnected from "@/middleware/isConnected.js";
import redirectIfNotPopupFromRoute from "@/middleware/redirectIfNotPopupFromRoute.js";

export default [
	{
		name: "lightbox",
		path: "/lightbox",
		component: () => import(
			"../pages/lightbox.vue"
		),
		beforeEnter: middleware([
			isConnected,
			redirectIfNotPopupFromRoute(["/embedded/student/classroom"], "/student/classroom"),
		]),
	},
];
