import api from "./Api.js";

export default {
	/**
	 * Create essay
	 * @param {object} data
	 * @param {function|null} uploadCallback
	 * @returns {AxiosPromise}
	 */
	create(data, uploadCallBack = null) {
		const config = {};

		if (typeof uploadCallBack == "function") {
			config.onUploadProgress = (progressEvent) => {
				uploadCallBack(progressEvent);
			};
		}

		return api().post(`/essays`, data, config);
	},

	/**
	 * Update essay
	 * @param {number} essayId
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	update(essayId, data) {
		return api().post(`/essays/${essayId}`, data);
	},

	/**
	 * Cancel essay
	 * @param {number} essayId
	 * @returns {AxiosPromise}
	 */
	cancel(essayId) {
		return api().post(`/essays/${essayId}`, { status: -1 });
	},

	/**
	 * Get essay
	 * @param {number} essayId
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	show(essayId, params = null) {
		return api().get(`/essays/${essayId}`, {
			params: params,
		});
	},

	/**
	 * Get all essays
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	list(params) {
		return api().get(`/essays`, {
			params: params,
		});
	},

	/**
	 * Get paginated student essays
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	getStudentEssays(params) {
		return api().get(`/me/essays`, {
			params: params,
		});
	},

	/**
	 * Get essay statistics for superuser service dashboard
	 * @returns {AxiosPromise}
	 */
	getSuperuserStatistics() {
		return api().get(`/essay-statistics`);
	},

	getEstimatedReviewTime() {
		return api().get(`/essays/estimated-review-time`);
	},
	essayPickUp(params) {
		return api().post(`/essays/pick-up`, params);
	},
	unassignEssay(essayId) {
		return api().put(`/essays/${essayId}/unassign`);
	},
	getEssayStatisticsForTutor() {
		return api().get("me.essays-statistics");
	},
	reserveEssay(params) {
		return api().post("essays/reservations", params);
	},
	getReservedEssay(params) {
		return api().get("essays/reservations", {
			params,
		});
	},
	skipReservedEssay(params) {
		return api().post("essays/pass-on", params);
	},
	hideEssay(essayId) {
		return api().put(`essays/${essayId}/quarantine`);
	},
	getSubmissionTypes() {
		return api().get("/review-submission-types");
	},
	submitGeneralFeedback(essayId, params) {
		return api().post(`/essays/${essayId}/feedback`, params);
	},
	submitOptionalFeedback(essayId, params) {
		return api().patch(`/essays/${essayId}/optional_feedback`, params);
	},
	submitReview(essayId, params) {
		return api().post(`/essays/${essayId}/review`, params);
	},
};
