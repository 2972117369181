import api from "./Api.js";

export default {
	/**
	 * Gets count of all notifications that are sent, unread and not hidden for the current user
	 *
	 * @arg {object} params
	 * @returns {AxiosPromise}
	 */
	getUserNotificationsForNavbar(params) {
		return api().get(`me/navbar-notifications`, {
			params: params,
		});
	},
};
