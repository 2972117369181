<template>
	<div class="tw-flex tw-items-start tw-pt-6">
		<ShiftSummaryShiftTypeIcon
			v-jest="'shift-summary-icon'"
			:title="shiftSegment.type.name"
			:dimension="48"
		/>
		<div class="shiftSummaryItem__detail tw-relative">
			<div class="tw-flex tw-items-center tw-mb-1">
				<span
					v-jest="'shift-summary-shift-type'"
					class="tw-font-bold tw-text-xl tw-leading-none"
				>
					{{ shiftSegment.type.name }}
				</span>
				<div
					v-jest="'shift-summary-label'"
					class="shiftSummaryItem__label"
					:class="[{
						'tw-bg-blue-dark': shiftStatus === 'Upcoming',
						'tw-bg-green-dark': shiftStatus === 'Ongoing',
						'tw-bg-orange-dark': shiftStatus === 'Late',
						'tw-bg-grey-dark': shiftStatus === 'Completed',
					}]"
				>
					{{ shiftStatus }}
				</div>
			</div>
			<span
				v-jest="'shift-summary-date'"
				class="tw-text-xs"
			>
				{{ shiftDate }}
			</span>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { format, endOfDay, isEqual } from "date-fns";
import { isNil } from "lodash-es";

import { TUTORING_SESSION_ID, WRITING_REVIEW_ID } from "@/modules/TutorSchedule/index.js";

import ShiftSummaryShiftTypeIcon from "./ShiftSummaryShiftTypeIcon.vue";

const ONE_SECOND_IN_MS = 1000;

export default {
	components: {
		ShiftSummaryShiftTypeIcon,
	},
	props: {
		shiftSegment: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			refreshInterval: null,
			currentTime: new Date(),
		};
	},
	computed: {
		...mapState(["currentUser"]),
		isClientFacingSegment() {
			return [TUTORING_SESSION_ID, WRITING_REVIEW_ID].includes(this.shiftSegment.type.id);
		},
		shiftStatus() {
			if (this.currentTime < this.startTime && isNil(this.shiftSegment.punch_in)) {
				return "Upcoming";
			}
			const isPunchedOutShiftSegment = !isNil(this.shiftSegment.punch_out);
			const isEndedShiftSegment = !this.isClientFacingSegment && this.currentTime >= this.endTime;
			if (isPunchedOutShiftSegment || isEndedShiftSegment) {
				return "Completed";
			}
			const isShiftOver = this.currentTime > this.endTime;
			const isShiftStarted = this.currentTime >= this.startTime;
			const isTutorLate = isShiftStarted && !isShiftOver &&
				isNil(this.shiftSegment.punch_in) && this.isClientFacingSegment;
			if (isTutorLate) {
				return "Late";
			}

			return "Ongoing";
		},
		startTime() {
			return new Date(this.shiftSegment.start_time);
		},
		endTime() {
			return new Date(this.shiftSegment.end_time);
		},
		shiftDate() {
			return this.getShiftDate();
		},
	},
	mounted() {
		this.refreshInterval = setInterval(
			() => this.currentTime = new Date(),
			ONE_SECOND_IN_MS,
		);
	},
	beforeDestroy() {
		window.clearInterval(this.refreshInterval);
	},
	methods: {
		getShiftDate() {
			const startTime = format(this.startTime, "MMM d hh:mm aaa");
			const endTime = this.isShiftEndingOnSameDay(this.startTime, this.endTime)
				? format(this.endTime, "hh:mm aaa")
				: format(this.endTime, "hh:mm aaa MMM d");
			return `${startTime} - ${endTime}`;
		},
		isShiftEndingOnSameDay(startDate, endDate) {
			return isEqual(endOfDay(startDate), endOfDay(endDate));
		},
	},
};
</script>

<style scoped>
.shiftSummaryItem__detail {
	margin-left: 16px;
	display: flex;
	flex-direction: column;
}
.shiftSummaryItem__label {
	margin-left: 8px;
	padding: 0px 6px;
	color: white;
	border-radius: 16px;
	height: 20px;
	font-size: 0.625rem;
	align-items: center;
	display: flex;
	margin-top: 5px;
}
</style>
