<template>
	<div
		class="tw-w-full tw-p-4 tw-flex tw-flex-col tw-gap-4 tw-bg-origami-grey-100 tw-rounded-2xl tw-px-6 tw-pt-6 tw-pb-10"
		:class="{ 'tw-items-center': isLoadingRecognitionTypes }"
	>
		<BaseLoader
			v-if="isLoadingRecognitionTypes"
			v-jest="'loader'"
			class="tw-h-16 tw-w-16 tw-text-blue-regular"
		/>
		<template v-else>
			<p class="tw-font-origami-bold">
				Select stickers
			</p>
			<div class="tw-flex tw-gap-4 tw-flex-wrap">
				<OrigamiCard
					v-for="recognitionType in recognitionTypes"
					:key="recognitionType.id"
					v-jest="'recognition-card'"
					:is-selected="recognitionType.selected"
					is-interactive
					class="tw-flex tw-gap-4 tw-p-4 tw-w-44 tw-items-center"
					@click.native="recognitionType.selected = !recognitionType.selected"
				>
					<Emoji
						v-jest="'recognition-emoji'"
						class="tw-text-2xl"
						:emoji="RECOGNITION_ICON_COMPONENTS[recognitionType.id] || RECOGNITION_ICON_COMPONENTS[1]"
					/>
					<p v-jest="'recognition-name'">
						{{ upperFirst(recognitionType.name) }}
					</p>
				</OrigamiCard>
			</div>
			<OrigamiCard
				v-if="isShowingErrorCard"
				v-jest="'error-card'"
				class="tw-p-4 tw-bg-origami-red-100 tw-text-origami-red-400 tw-text-center"
			>
				<p>At least one sticker must be selected</p>
			</OrigamiCard>
			<p>
				<span class="tw-font-origami-bold">Add a message </span>(optional)
			</p>
			<BaseTextArea
				v-jest="'recognitions-message'"
				:placeholder="textAreaPlaceHolder"
				class="tw-h-20"
				auto-resize
				:disabled="!selectedRecognitions.length"
				@input="message = $event"
			/>
			<div class="tw-flex tw-justify-end tw-gap-x-2">
				<OrigamiButton
					v-jest="'cancel-button'"
					variant="secondary"
					@click.native="$emit('cancel')"
				>
					Cancel
				</OrigamiButton>
				<OrigamiButton
					v-jest="'submit-button'"
					:is-loading="isSubmittingRecognitions"
					@click.native="submitRecognitions()"
				>
					Send to student
				</OrigamiButton>
			</div>
		</template>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { upperFirst } from "lodash";
import { OrigamiCard, OrigamiButton } from "@origami/vue2";

import { Emoji } from "@/modules/Emoji/index.js";

import BaseTextArea from "../../../components/elements/BaseTextArea.vue";
import BaseLoader from "../../../components/elements/BaseLoader.vue";
import RecognitionApi from "../services/api/index.js";
import { RECOGNITION_ICON_COMPONENTS } from "../utilities/index.js";
import { showErrorModal } from "../../../utilities/errorHandlingHelpers.js";

export default {
	components: {
		BaseLoader,
		BaseTextArea,
		Emoji,
		OrigamiButton,
		OrigamiCard,
	},
	props: {
		sessionId: {
			type: Number,
			required: true,
		},
	},
	data: () => ({
		message: "",
		recognitionTypes: [],
		isLoadingRecognitionTypes: false,
		isSubmittingRecognitions: false,
		isShowingErrorCard: false,
		RECOGNITION_ICON_COMPONENTS,
	}),
	computed: {
		...mapState(["Recognition"]),
		selectedRecognitions() {
			return this.recognitionTypes
				.filter(({ selected }) => selected)
				.map(({ id, name }) => ({ id, name }));
		},
		textAreaPlaceHolder() {
			return `Write your message${
				!this.selectedRecognitions.length ?
					" (at least one sticker must be selected)" :
					""
			}`;
		},
	},
	watch: {
		selectedRecognitions(value) {
			if (value.length) {
				this.isShowingErrorCard = false;
			}
		},
	},
	async created() {
		try {
			this.isLoadingRecognitionTypes = true;
			await this.$store.dispatch("Recognition/getActiveRecognitionTypes");
			this.recognitionTypes = this.Recognition.recognitionTypes.map((item) => ({ ...item, selected: false }));
		} catch (e) {
			Sentry.captureException(e);
			showErrorModal(this);
		} finally {
			this.isLoadingRecognitionTypes = false;
		}
	},
	methods: {
		upperFirst,
		async submitRecognitions() {
			try {
				if (!this.selectedRecognitions.length) {
					this.isShowingErrorCard = true;
					return;
				}
				this.isShowingErrorCard = false;
				this.isSubmittingRecognitions = true;
				const payload = {
					recognition_type_ids: this.selectedRecognitions.map(({ id }) => id),
				};
				if (this.message) {
					payload.message = this.message;
				}
				await RecognitionApi.create(this.sessionId, payload);
				this.$emit("submit", {
					recognitionTypes: this.selectedRecognitions,
					message: this.message,
				});
			} catch (e) {
				Sentry.captureException(e);
				showErrorModal(this);
			} finally {
				this.isSubmittingRecognitions = false;
			}
		},
	},
};
</script>
