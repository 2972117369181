import ActionCenterInterface from "../interfaces/ActionCenterInterface.js";
import { ActionItemTypeMapping, LONG_ESSAY_REVIEW_ID, INAPPROPRIATE_BEHAVIOUR_ESSAY_ID, INAPPROPRIATE_BEHAVIOUR_ID } from "../actionCenterHelpers.js";
import { classroomContentV2Transformer } from "../actionCenterContentTransformer.js";

const actionCenterListener = {
	async connect() {
		this.$socket.emit("join.action_center_items", {}, () => {});
	},
	platform_manager_action_item_created(payload) {
		try {
			switch (payload.data.type_id) {
				case LONG_ESSAY_REVIEW_ID :
					this.$store.commit("PlatformManager/ActionCenter/ADD_ITEM_TO_LONG_ESSAY_REVIEWS", payload.data);
					break;
				case INAPPROPRIATE_BEHAVIOUR_ID :
					this.$store.commit("PlatformManager/ActionCenter/ADD_ITEM_TO_INAPPROPRIATE_BEHAVIOUR", {
						...payload.data,
						content: classroomContentV2Transformer(payload.data.content),
					});
					break;
				case INAPPROPRIATE_BEHAVIOUR_ESSAY_ID :
					this.$store.commit("PlatformManager/ActionCenter/ADD_ITEM_TO_INAPPROPRIATE_ESSAY_BEHAVIOUR", payload.data);
					break;
				default :
					throw new Error(`Action item ${payload.data.type_id} is not accepted`);
			}
		} catch (error) {
			Sentry.captureException();
		}
	},
	platform_manager_action_item_updated(payload) {
		const actionItem = payload.data;

		const typeArray = ActionItemTypeMapping[actionItem.type_id];
		const newArray = this.PlatformManager.ActionCenter[typeArray];
		const index = newArray.findIndex(({ id }) => id === actionItem.id);
		const mutationType = actionItem.resolved_at ? "RESOLVE_ACTION_ITEM" : "UPDATE_ACTION_ITEM";
		this.$store.commit(`PlatformManager/ActionCenter/${mutationType}`, {
			typeArray,
			index,
			actionItem,
		});
	},
};

ActionCenterInterface.isImplementedBy(actionCenterListener);

export default actionCenterListener;
