<template>
	<nav
		v-if="totalPages > 0"
		v-jest="'pagination-wrapper'"
		:aria-label="$t('navbar.previous_page')"
		class="tw-flex tw-justify-center tw-mt-6"
	>
		<OrigamiIconButton
			v-show="isTotalGreaterThanLimit"
			v-jest="'backwards-button'"
			icon-name="backward"
			aria-label="Previous Page"
			class="shared-button-class"
			@click.native="handleClick(currentPage - 1)"
		/>
		<OrigamiButton
			v-for="page in pages"
			:key="page + shiftValue"
			v-jest="'number-buttons'"
			:aria-label="$t('navbar.pagination') + ' ' + (page + shiftValue)"
			class="shared-button-class"
			:class="currentPage === (page + shiftValue) ? 'active' : 'number-buttons'"
			@click.native="handleClick(page + shiftValue)"
		>
			{{ page + shiftValue }}
		</OrigamiButton>
		<OrigamiIconButton
			v-show="isTotalGreaterThanLimit"
			v-jest="'forwards-button'"
			icon-name="forward"
			:aria-label="$t('navbar.next_page')"
			class="shared-button-class"
			@click.native="handleClick(currentPage + 1)"
		/>
	</nav>
</template>

<script>
import { OrigamiButton, OrigamiIconButton } from "@origami/vue2";

const VISIBLE_PAGE_LIMIT = 7;

export default {
	components: {
		OrigamiButton,
		OrigamiIconButton,
	},
	props: {
		currentPage: {
			type: Number,
			default: 0,
		},
		totalPages: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		isTotalGreaterThanLimit() {
			return this.totalPages > VISIBLE_PAGE_LIMIT;
		},
		pages() {
			return this.isTotalGreaterThanLimit ? VISIBLE_PAGE_LIMIT : this.totalPages;
		},
		shiftValue() {
			if (this.currentPage > 4 && this.currentPage <= this.totalPages && this.totalPages >= VISIBLE_PAGE_LIMIT) {
				if (this.currentPage > this.totalPages - 3) {
					return this.totalPages - VISIBLE_PAGE_LIMIT;
				}
				return this.currentPage - 4;
			} else {
				return 0;
			}
		},
	},
	methods: {
		handleClick(page) {
			if (page < 1 || page > this.totalPages) {
				return;
			}
			this.$emit("change", page);
		},
	},
};
</script>

<style scoped>
.shared-button-class {
	border: 1px solid var(--origami-grey-200);
	height: 30px;
	width: 30px;
	margin-left: 2px;
	margin-right: 2px;
	border-radius: 8px;
	padding: 8px;
	align-items: center;
	justify-content: center;
}
.number-buttons {
	color: var(--origami-black);
	background: transparent;
	box-shadow: none;
}
.number-buttons:hover {
	background: var(--origami-grey-100);
	box-shadow: none;
}
.active {
	box-shadow: none;
}
.active:hover {
	box-shadow: none;
}
</style>
