var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasMealBreak && _vm.isOnMealBreakWorkflow
    ? _c("BreakDetails", {
        scopedSlots: _vm._u(
          [
            {
              key: "breakDetails",
              fn: function () {
                return [
                  _vm.formattedBreakTimes
                    ? _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: "meal-break-details",
                              expression: "'meal-break-details'",
                            },
                            {
                              name: "data-cy",
                              rawName: "v-data-cy",
                              value: "meal-break-details",
                              expression: "'meal-break-details'",
                            },
                          ],
                          staticClass: "tw-mt-4",
                        },
                        [
                          _vm._v(
                            "\n\t\t\tMeal break at " +
                              _vm._s(_vm.formattedBreakTimes.startTime) +
                              " to " +
                              _vm._s(_vm.formattedBreakTimes.endTime) +
                              "\n\t\t"
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          4163935508
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }