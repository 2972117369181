var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canCancelMealBreak
    ? _c("BreaksConfirmation", {
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "h3",
                    {
                      directives: [
                        {
                          name: "data-cy",
                          rawName: "v-data-cy",
                          value: "meal-cancel-approved-break-header",
                          expression: "'meal-cancel-approved-break-header'",
                        },
                      ],
                    },
                    [_vm._v("\n\t\t\tCancel approved break?\n\t\t")]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "bodyIcon",
              fn: function () {
                return [
                  _c("OrigamiIcon", {
                    directives: [
                      {
                        name: "data-cy",
                        rawName: "v-data-cy",
                        value: "meal-break-cancel-body-icon",
                        expression: "'meal-break-cancel-body-icon'",
                      },
                    ],
                    staticClass: "tw-text-origami-blue-400",
                    attrs: { name: "meal-break", width: "32", height: "32" },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "bodyText",
              fn: function () {
                return [
                  _vm.formattedBreakTimes
                    ? _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: "cancel-approved-meal-break-details",
                              expression:
                                "'cancel-approved-meal-break-details'",
                            },
                            {
                              name: "data-cy",
                              rawName: "v-data-cy",
                              value: "cancel-approved-meal-break-details",
                              expression:
                                "'cancel-approved-meal-break-details'",
                            },
                          ],
                        },
                        [
                          _vm._v(
                            "\n\t\t\tCancel your break for " +
                              _vm._s(_vm.formattedBreakTimes.startTime) +
                              " - " +
                              _vm._s(_vm.formattedBreakTimes.endTime) +
                              "?\n\t\t"
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "buttons",
              fn: function () {
                return [
                  _c(
                    "OrigamiButton",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "confirm-cancel-meal-break-button",
                          expression: "'confirm-cancel-meal-break-button'",
                        },
                        {
                          name: "data-cy",
                          rawName: "v-data-cy",
                          value: "confirm-cancel-meal-break-button",
                          expression: "'confirm-cancel-meal-break-button'",
                        },
                      ],
                      staticClass: "tw-w-full tw-justify-center",
                      attrs: {
                        variant: "destructive",
                        "is-loading":
                          _vm.$store.state.BreaksManagementWidget
                            .isRequestLoading,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.cancelApprovedMealBreak.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_vm._v("\n\t\t\tYes, cancel break\n\t\t")]
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          2743640418
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }