import BreaksCenterListenerInterface from "../interfaces/BreaksCenterListenerInterface.js";

const breaksCenterListener = {
	async connect() {
		this.$socket.emit("join.tutor_managers", {}, () => { });
		this.onAuthenticated();
	},
	schedule_break_request_created(breakRequestObject) {
		this.$store.dispatch("BreaksCenter/showBreakRequest", {
			breakRequest: breakRequestObject.data,
		});
	},
	schedule_break_request_approved(segmentedShiftObject) {
		const approvedBreak = segmentedShiftObject.data.approved_request;
		const scheduleTypeId = segmentedShiftObject.data.shifts[0].schedule_type_id;
		const startingShift = segmentedShiftObject.data.shifts[0];
		const breakShift = segmentedShiftObject.data.shifts[1];
		const endingShift = segmentedShiftObject.data.shifts[2];
		const punch_in = startingShift.punch_in;
		const start_time = startingShift.start_time;
		const end_time = endingShift.end_time;
		approvedBreak.segment_id = segmentedShiftObject.data.segment_id;
		this.$store.dispatch("BreaksCenter/removeBreakRequest", {
			breakId: segmentedShiftObject.data.approved_request.id,
		});
		approvedBreak.id = breakShift.id;
		approvedBreak.user = segmentedShiftObject.data.user;
		this.$store.dispatch("BreaksCenter/showApprovedBreakRequest", {
			approvedBreak,
			scheduleTypeId,
			punch_in,
			start_time,
			end_time,
		});
	},
	schedule_break_request_rejected(breakObject) {
		this.$store.dispatch("BreaksCenter/removeBreakRequest", {
			breakId: breakObject.data.id,
		});
	},
	schedule_break_cancelled(breakObject) {
		this.$store.dispatch("BreaksCenter/removeApprovedRequestedBreak", {
			breakObject: breakObject.data,
		});
	},
	schedule_break_request_cancelled(breakObject) {
		this.$store.dispatch("BreaksCenter/removeBreakRequest", {
			breakId: breakObject.data.id,
		});
	},
};

BreaksCenterListenerInterface.isImplementedBy(breaksCenterListener);

export default breaksCenterListener;
