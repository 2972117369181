var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ScheduledBreakError", {
    attrs: { header: "Meal break request" },
    on: { "break-error-try-again": _vm.requestMealBreak },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }