var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 16 16",
      },
    },
    [
      _c("defs", [
        _c("path", {
          attrs: {
            id: _vm.randomId,
            d: "M0 12.667V16h3.333l9.83-9.83L9.83 2.837 0 12.667zm15.74-9.074a.885.885 0 0 0 0-1.253L13.66.26a.885.885 0 0 0-1.253 0l-1.626 1.626 3.333 3.333 1.626-1.626z",
          },
        }),
      ]),
      _vm._v(" "),
      _c("use", {
        attrs: {
          fill: _vm.iconColor,
          "fill-rule": "evenodd",
          "xlink:href": `#${_vm.randomId}`,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }