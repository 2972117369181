var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "tw-flex tw-items-center" },
      [
        _c("h3", { staticClass: "tw-text-origami-md tw-font-bold" }, [
          _vm._v("\n\t\t\tCurrent Tutor Session Load\n\t\t"),
        ]),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "analytics-link",
                expression: "'analytics-link'",
              },
            ],
            staticClass: "tw-ml-3 tw-leading-none tw-h-5",
            attrs: { to: "analytics", "data-cy": "analytics-link" },
          },
          [
            _c(_setup.OrigamiIcon, {
              attrs: { width: "20", height: "20", name: "external" },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("p", { staticClass: "tw-text-origami-sm" }, [
      _vm._v("\n\t\tTutor vs. session breakdown of overall platform\n\t"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }