<template>
	<BreakDetails>
		<template #breakDetails>
			<p
				v-jest="'bio-break-details'"
				v-data-cy="'bio-break-details'"
				class="tw-font-bold tw-text-sm"
			>
				{{ remainingTimeInMinutes }}
			</p>
		</template>
	</BreakDetails>
</template>

<script>
import { mapState } from "vuex";

import { formatPlurality } from "@/utilities/strings.js";

import BreakDetails from "../templates/BreakDetails.vue";

export default {
	components: {
		BreakDetails,
	},
	computed: {
		...mapState(["BreaksManagementWidget"]),
		remainingTimeInMinutes() {
			const timeLeftInBioBreak = this.$store.getters["BreaksManagementWidget/getTimeLeftInBioBreak"];
			const remainingTimeString = formatPlurality(Math.abs(timeLeftInBioBreak), "min");
			if (this.isLateFromBioBreak) {
				return timeLeftInBioBreak === 0 ? "You are now late" : `${remainingTimeString} late`;
			} else {
				return timeLeftInBioBreak === 1 ? "Under a min left to your break" : `${remainingTimeString} left to your break`;
			}
		},
		isLateFromBioBreak() {
			return this.$store.getters["BreaksManagementWidget/isLateFromBioBreak"];
		},
	},
};
</script>
