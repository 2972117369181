// Libs
import Vue from "vue";
import momentTz from "moment-timezone";
import { getFlag } from "@paper-co/web-toolkit";

// Services
import MessagesTransformer from "@/modules/Activity/transformers/messagesTransformer.js";
// API
import ActivityAPI from "@/services/api/Activity.js";
import EssaysAPI from "@/services/api/Essays.js";
import FlagsAPI from "@/services/api/Flags.js";
import MessagesAPI from "@/services/api/Messages.js";
import TopicsAPI from "@/services/api/Topics.js";
import TutorsAPI from "@/services/api/Tutors.js";
import HandoffsAPI from "@/services/api/Handoffs.js";
import SessionSharesAPI from "@/services/api/SessionShares.js";
import SessionsAPI from "@/services/api/Sessions.js";
// Helpers
import { defaultEndDate, getTimestampFromWeekAgo } from "@/utilities/dateHelpers.js";
import { DistrictsAPI, SectionsAPI, SchoolsAPI } from "@/modules/Customers/index.js";
import IconEmojiLove from "@/components/icons/IconEmojiLove.vue";
import IconEmojiHappy from "@/components/icons/IconEmojiHappy.vue";
import IconEmojiMeh from "@/components/icons/IconEmojiMeh.vue";
import IconEmojiSad from "@/components/icons/IconEmojiSad.vue";
import IconEmojiAngry from "@/components/icons/IconEmojiAngry.vue";

import helpers from "../utilities/helpers.js";

Object.filter = (obj, predicate) =>
	Object.keys(obj)
		.filter((key) => predicate(obj[key]))
		.reduce((res, key) => ((res[key] = obj[key]), res), {});

const getDefaultState = () => ({
	isLoading: true,
	isLoadingMessages: true,
	currentTab: "Sessions",
	searchText: "",
	sessionShareKey: "",
	tutors: [],
	tabs: [],
	startDate: null,
	endDate: null,
	file: {},
	errorMessage: "",
	reviewed_at: "",
	reviewed_file_data: null,
	flagSessionReasons: {},
	districtTimezone: null,
	isHideActivityDialogOpen: false,
	activityToHide: null,
	essays: {
		currentPage: 0,
		lastPage: 0,
		data: {},
		selectedId: null,
		params: {},
		total: 0,
	},
	sessions: {
		currentPage: 0,
		lastPage: 0,
		data: {},
		selectedId: null,
		params: {},
		total: 0,
	},
	filters: {
		tutors: {
			label: "Tutors",
			isUsable: false,
			options: [],
			selected: [],
		},
		districts: {
			label: "District",
			isUsable: false,
			options: [],
			selected: [],
		},
		topics: {
			label: "Topic",
			isUsable: false,
			options: [],
			selected: [],
		},
		schools: {
			label: "School",
			isUsable: false,
			options: [],
			selected: [],
		},
		ratings: {
			label: "Student Rating",
			isUsable: false,
			options: [],
			selected: [],
		},
		sections: {
			label: "Section",
			isUsable: false,
			options: [],
			selected: [],
		},
		status: {
			label: "Status",
			isUsable: false,
			options: [],
			selected: [],
		},
	},
	roleFilters: {
		superuser: ["tutors", "districts", "schools", "ratings", "topics", "status"],
		"tutor-manager": ["tutors", "districts", "schools", "ratings", "topics", "status"],
		teacher: ["topics", "sections", "status"],
		administrator: ["topics", "status"],
		"district-manager": ["schools", "topics", "status"],
	},
	disabledTabFilters: {
		Sessions: ["status"],
		Essays: ["topics", "ratings"],
	},
});

const districtDateTimeToUtc = (dateTime, districtTimezone) => {
	const dt = momentTz(dateTime * 1000).tz(districtTimezone);

	return dt.unix() - dt.utcOffset() * 60;
};

const getters = {
	activeActivityId(state) {
		switch (state.currentTab) {
			case "Essays":
				return state.essays.selectedId;
			case "Sessions":
				return state.sessions.selectedId;
			default:
				return null;
		}
	},
	filterParams(state) {
		const { startDate, endDate, districtTimezone } = state;

		let from = startDate || getTimestampFromWeekAgo(new Date());
		let to = endDate || defaultEndDate();

		if (districtTimezone) {
			from = districtDateTimeToUtc(from, districtTimezone);
			to = districtDateTimeToUtc(to, districtTimezone);
		}

		const params = { from, to };

		if (state.searchText.length > 0) {
			params["search"] = state.searchText;
		}
		Object.keys(state.filters).forEach((filter) => {
			if (state.filters[filter].isUsable) {
				params[filter] = state.filters[filter].selected;
			}
		});
		return params;
	},
	sessionsArray(state) {
		if (Object.keys(state.sessions.data).length > 0) {
			return Object.values(state.sessions.data).sort((a, b) => b.id - a.id);
		} else {
			return [];
		}
	},
	essaysArray(state) {
		if (Object.keys(state.essays.data).length > 0) {
			return Object.values(state.essays.data).sort((a, b) => b.id - a.id);
		} else {
			return [];
		}
	},
};

const mutations = {
	PUSH_TO_FLAGS_IN_SESSION(state, payload) {
		state.sessions.data[payload.sessionId].flag.data.push(payload.flag);
	},
	PUSH_TO_TABS(state, payload) {
		state.tabs.push(payload.tab);
	},
	CLEAR_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_CURRENT_PAGE_IN_ESSAYS(state, payload) {
		state.essays.currentPage = payload.currentPage;
	},
	SET_CURRENT_PAGE_IN_SESSIONS(state, payload) {
		state.sessions.currentPage = payload.currentPage;
	},
	SET_CURRENT_TAB(state, payload) {
		state.currentTab = payload.tab;
	},
	SET_TOTAL_IN_ESSAYS(state, payload) {
		state.essays.total = payload.total;
	},
	SET_TOTAL_IN_SESSIONS(state, payload) {
		state.sessions.total = payload.total;
	},
	SET_DATA_IN_ESSAYS(state, payload) {
		if (state.isLoading) {
			state.essays.data = {};
			state.essays.selectedId = null;
		}
		payload.data.forEach((essay) => Vue.set(state.essays.data, essay.id, essay));
	},
	SET_DATA_IN_SESSIONS(state, payload) {
		if (state.isLoading) {
			state.sessions.data = {};
			state.sessions.selectedId = null;
		}
		payload.data.forEach((session) => Vue.set(state.sessions.data, session.id, session));
	},
	SET_DATA_IN_SESSIONS_KEY_VALUE(state, payload) {
		Vue.set(state.sessions.data[payload.sessionId], payload.key, payload.value);
	},
	SET_DATE_RANGE(state, payload) {
		state.startDate = payload.start;
		state.endDate = payload.end;
	},
	SET_FILTER_IN_FILTERS_KEY_VALUE(state, payload) {
		Vue.set(state.filters[payload.filter], payload.key, payload.value);
	},
	SET_FLAG_SESSION_REASONS(state, payload) {
		state.flagSessionReasons = payload.flagSessionReasons;
	},
	SET_DISTRICT_TIME_ZONE(state, payload) {
		state.districtTimezone = payload.districtTimezone;
	},
	SET_IS_LOADING(state, payload) {
		state.isLoading = payload.isLoading;
	},
	SET_IS_LOADING_MESSAGES(state, payload) {
		state.isLoadingMessages = payload.isLoadingMessages;
	},
	SET_LAST_PAGE_IN_ESSAYS(state, payload) {
		state.essays.lastPage = payload.lastPage;
	},
	SET_LAST_PAGE_IN_SESSIONS(state, payload) {
		state.sessions.lastPage = payload.lastPage;
	},
	SET_SEARCH_TEXT(state, payload) {
		state.searchText = payload.text;
	},
	SET_SELECTED_ID_IN_ESSAYS(state, payload) {
		state.essays.selectedId = payload.id;
	},
	SET_SELECTED_ID_IN_SESSIONS(state, payload) {
		state.sessions.selectedId = payload.id;
	},
	SET_SESSION_SHARE_KEY(state, payload) {
		state.sessionShareKey = payload.key;
	},
	SET_TUTORS_IN_SESSION(state, payload) {
		state.tutors = payload.tutors;
	},
	SET_ERROR_ON_FORCE_TRANSFER(state, payload) {
		state.error = payload.error;
	},
	SET_FILE(state, payload) {
		state.file = payload.file;
	},
	SET_ERROR_MESSAGE(state, payload) {
		state.errorMessage = payload.errorMessage;
	},
	SET_REVIEWED_AT(state, payload) {
		state.reviewed_at = payload.reviewed_at;
	},
	SET_REVIEWED_FILE_DATA(state, payload) {
		state.reviewed_file_data = payload.reviewed_file_data;
	},
	SPLICE_FLAG_IN_SESSIONS(state, payload) {
		state.sessions.data[payload.sessionId].flag.data.splice(payload.index, 1);
	},
	DELETE_MESSAGE_FROM_SESSION(state, payload) {
		state.sessions.data[payload.sessionId].messages =
			state.sessions.data[payload.sessionId].messages.map((message) => {
				if (message.id === payload.messageId) {
					message.isDeleted = true;
				}
				return message;
			});
	},
	SET_ESSAY_STATUS(state, payload) {
		state.essays.data[payload.id].status = payload.status;
	},
	OPEN_HIDE_ACTIVITY_DIALOG(state) {
		state.isHideActivityDialogOpen = true;
	},
	CLOSE_HIDE_ACTIVITY_DIALOG(state) {
		state.isHideActivityDialogOpen = false;
	},
	RESTORE_MESSAGE_FROM_SESSION(state, payload) {
		state.sessions.data[payload.sessionId].messages =
			state.sessions.data[payload.sessionId].messages.map((message) => {
				if (message.id === payload.messageId) {
					message.isDeleted = false;
				}
				return message;
			});
	},
	QUARANTINE_MESSAGE_FROM_SESSION(state, payload) {
		state.sessions.data[payload.sessionId].messages =
			state.sessions.data[payload.sessionId].messages.map((message) => {
				if (message.id === payload.messageId) {
					message.isQuarantined = true;
				}
				return message;
			});
	},
};

const getPaginationItem = (payload, key) => payload.meta?.pagination[key] || 0;

const actions = {
	async getFilteredData({ commit, state, dispatch }, params) {
		commit("SET_IS_LOADING", {
			isLoading: true,
		});

		for (let i = 0; i < state.tabs.length; i = i + 1) {
			const activityItems = await dispatch(`getFiltered${state.tabs[i]}Data`, params);
			await dispatch(`set${state.tabs[i]}Data`, activityItems.data);
		}
		commit("SET_IS_LOADING", {
			isLoading: false,
		});
		return Promise.resolve("success");
	},
	async setSessionsData({ commit, dispatch, state, getters }, payload) {
		commit("SET_LAST_PAGE_IN_SESSIONS", {
			lastPage: getPaginationItem(payload, "total_pages"),
		});
		commit("SET_CURRENT_PAGE_IN_SESSIONS", {
			currentPage: getPaginationItem(payload, "current_page"),
		});
		commit("SET_DATA_IN_SESSIONS", {
			data: payload.data,
		});
		commit("SET_TOTAL_IN_SESSIONS", {
			total: getPaginationItem(payload, "total"),
		});
		if (state.sessions.selectedId === null && getters.sessionsArray.length > 0) {
			const id = getters.sessionsArray[0].id;
			dispatch("selectSessionId", id);
		}
	},
	async setEssaysData({ commit, dispatch, state, getters }, payload) {
		commit("SET_LAST_PAGE_IN_ESSAYS", {
			lastPage: getPaginationItem(payload, "total_pages"),
		});
		commit("SET_CURRENT_PAGE_IN_ESSAYS", {
			currentPage: getPaginationItem(payload, "current_page"),
		});
		commit("SET_DATA_IN_ESSAYS", {
			data: payload.data,
		});
		commit("SET_TOTAL_IN_ESSAYS", {
			total: getPaginationItem(payload, "total"),
		});

		if (state.essays.selectedId == null && getters.essaysArray.length > 0) {
			const id = getters.essaysArray[0].id;
			dispatch("selectEssayId", id);
		}
	},
	async loadMore({ state, dispatch, getters }) {
		const tab = state.currentTab.toLowerCase();
		const params = getters.filterParams;
		const nextPage =
			state[tab].lastPage > state[tab].currentPage ? state[tab].currentPage + 1 : 0;
		if (nextPage > 0) {
			params["page"] = nextPage;
			const response = await dispatch(`getFiltered${state.currentTab}Data`, params);
			await dispatch(`set${state.currentTab}Data`, response.data);
		}
	},
	async getFilterOptionsByRole({ dispatch, state }, role) {
		state.roleFilters[role].forEach(async(filter) => {
			const actionTitle =
				"get" +
				filter.replace(/^\w/, (letter) => letter.toUpperCase()) +
				"FilterOptions";
			await dispatch(actionTitle);
		});
	},
	/**
	 * Action for getting filtered individual sessions only.
	 * @param {Object} payload - filter params for the api request
	 */
	async getFilteredSessionsData({ rootState }, payload) {
		const featureFlagSessionIntentsIsEnabled = await getFlag("PE-21021-session-intents");

		const params = payload;
		params["include"] =
			"users.tutor,activeUsers.tutor,users.schools.district,topics,requestedTopic,subjects,requestedSubject,assessment,studentEndedSession,flag,section,concepts";

		if (featureFlagSessionIntentsIsEnabled) {
			params["include"] = `${params["include"]},intentType`;
		}
		let response;
		// eslint-disable-next-line default-case
		switch (rootState.currentUser.role) {
			case "superuser":
				response = await ActivityAPI.getFilteredSessions(params);
				break;
			case "tutor-manager":
				response = await ActivityAPI.getFilteredSessions(params);
				break;
			case "teacher":
				response = await ActivityAPI.getFilteredTeacherSessions(params);
				break;
			case "administrator":
				response = await ActivityAPI.getFilteredSchoolSessions(
					params,
					rootState.currentUser.school.id,
				);
				break;
			case "district-manager":
				response = await ActivityAPI.getFilteredDistrictSessions(
					params,
					rootState.currentUser.district_id,
				);
				break;
		}
		return Promise.resolve(response);
	},
	async selectSessionId({ commit, state, dispatch }, id) {
		commit("SET_SELECTED_ID_IN_SESSIONS", { id });

		const userWithSchool = state.sessions.data[id].users.data.find(
			({ schools: { data } }) => data.length,
		);

		const timezone = userWithSchool?.schools.data[0].district.data.timezone || "";

		if (!state.sessions.data[id].hasOwnProperty("messages")) {
			await dispatch("getSessionMessages", {
				sessionId: state.sessions.selectedId,
				sessionType: "individual",
				timezone,
			});
		}
	},
	async getFilteredEssaysData({ rootState }, payload) {
		let response;
		payload["include"] = "student.schools.district,tutor.tutor,section,language,reviewSubmissionType,teacherInstructionFiles";

		// eslint-disable-next-line default-case
		switch (rootState.currentUser.role) {
			case "superuser":
				response = await ActivityAPI.getFilteredEssays(payload);
				break;
			case "tutor-manager":
				response = await ActivityAPI.getFilteredEssays(payload);
				break;
			case "teacher":
				response = await ActivityAPI.getFilteredTeacherEssays(payload);
				break;
			case "administrator":
				response = await ActivityAPI.getFilteredSchoolEssays(
					payload,
					rootState.currentUser.school.id,
				); // CHECK THE ID LOCATION
				break;
			case "district-manager":
				response = await ActivityAPI.getFilteredDistrictEssays(
					payload,
					rootState.currentUser.district_id,
				); // CHECK THE ID LOCATION
				break;
		}
		return Promise.resolve(response);
	},
	async selectEssayId({ commit }, id) {
		commit("SET_SELECTED_ID_IN_ESSAYS", {
			id,
		});
		commit("SET_REVIEWED_FILE_DATA", {
			reviewed_file_data: null,
		});
	},
	async getSessionMessages({ dispatch, commit }, { sessionId, sessionType, timezone }) {
		try {
			commit("SET_IS_LOADING_MESSAGES", {
				isLoadingMessages: true,
			});

			const params = {
				session_id: sessionId,
				include: "user.roles,assessment",
			};

			const { data: { data: sessionMessages } } = await MessagesAPI.getSessionMessagesForActivity(params);
			const { createMessageTextContent } = await import("@/utilities/MessageFormattingHelpers.js");

			const messagesWithEmoji = sessionMessages.map((sessionMessage) => {
				const { message_json } = sessionMessage;
				//  field 'message' of sessionMessages was not supporting emojis initially
				//  this changed with addition message_json in the DB, but message_json is only available
				//  for newer messages, if it is missing we fallback on the original message
				const message = message_json?.length && typeof message_json[0] !== "string"
					? createMessageTextContent(message_json)
					: sessionMessage.message;
				return { ...sessionMessage, message };
			});

			const transformedMessages =
				MessagesTransformer.transformMessagesForActivity(messagesWithEmoji)
					.map((message) => Object.assign(message, { timezone }));

			const setMessagesPayload = {
				sessionId,
				sessionType,
				key: "messages",
				value: transformedMessages,
			};
			dispatch("setDataKeyValueAccordingToSessionType", setMessagesPayload);

			commit("SET_IS_LOADING_MESSAGES", {
				isLoadingMessages: false,
			});

			return Promise.resolve(transformedMessages);
		} catch (error) {
			Promise.reject(error);
		}
	},
	async getTutorsFilterOptions({ commit }) {
		try {
			const tutors = [];
			const response = await TutorsAPI.listLite();
			const tutorData = response.data.data || response.data;
			tutorData.forEach(function(tutor) {
				tutors.push({
					name: tutor.name + " (" + tutor.id + ")",
					id: tutor.id,
				});
			});
			commit("SET_FILTER_IN_FILTERS_KEY_VALUE", {
				filter: "tutors",
				key: "options",
				value: tutors,
			});
			return Promise.resolve();
		} catch (error) {}
	},
	async getDistrictsFilterOptions({ commit }) {
		try {
			const districts = [];
			const response = await DistrictsAPI.list({});
			response.data.data.forEach(function(district) {
				districts.push({
					name: district.name,
					id: district.id,
				});
			});
			commit("SET_FILTER_IN_FILTERS_KEY_VALUE", {
				filter: "districts",
				key: "options",
				value: districts,
			});
			return Promise.resolve();
		} catch (error) {}
	},
	async getSchoolsFilterOptions({ commit, rootState }) {
		try {
			let schoolsResponseData = [];
			const schools = [];

			if (rootState.currentUser.role === "district-manager") {
				const response = await DistrictsAPI.show(rootState.currentUser.district_id, { include: "schools" });
				schoolsResponseData = response.data.data.schools.data;

				commit("SET_DISTRICT_TIME_ZONE", {
					districtTimezone: response.data.data.timezone,
				});
			} else if (
				rootState.currentUser.role === "superuser" ||
				rootState.currentUser.role === "tutor-manager"
			) {
				const response = await SchoolsAPI.listLite();
				schoolsResponseData = response.data.data;
			}

			schoolsResponseData.forEach(function(school) {
				schools.push({
					name: school.name,
					id: school.id,
					district_id: school.district_id,
				});
			});

			commit("SET_FILTER_IN_FILTERS_KEY_VALUE", {
				filter: "schools",
				key: "options",
				value: schools,
			});

			return Promise.resolve();
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	async getRatingsFilterOptions({ commit }) {
		try {
			const ratingsFilterArray = [
				{
					id: 1,
					name: IconEmojiAngry,
				},
				{
					id: 2,
					name: IconEmojiSad,
				},
				{
					id: 3,
					name: IconEmojiMeh,
				},
				{
					id: 4,
					name: IconEmojiHappy,
				},
				{
					id: 5,
					name: IconEmojiLove,
				},
			];
			commit("SET_FILTER_IN_FILTERS_KEY_VALUE", {
				filter: "ratings",
				key: "options",
				value: ratingsFilterArray,
			});
			return Promise.resolve();
		} catch (error) {
			Sentry.captureException(error);
		}
	},

	/**
	 * Gets list of all of all session ratings and puts them in the the filters.
	 */
	async getTopicsFilterOptions({ commit }) {
		try {
			const topics = [];
			const response = await TopicsAPI.list({});
			response.data.data.forEach(function(topic) {
				topics.push({
					name: topic.name,
					id: topic.id,
				});
			});
			commit("SET_FILTER_IN_FILTERS_KEY_VALUE", {
				filter: "topics",
				key: "options",
				value: topics,
			});
			return Promise.resolve();
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	async getSectionsFilterOptions({ commit, rootState }) {
		try {
			const sections = [];
			const params = {
				include: "topics",
				show_all: 1,
			};
			const response = await SectionsAPI.getUserSections(rootState.currentUser.id, params);
			response.data.data.forEach(function(section) {
				const topics = [];
				for (let i = 0; i < section.topics.data.length; i = i + 1) {
					topics.push(section.topics.data[i].id);
				}
				sections.push({
					name: section.name,
					id: section.id,
					topics: topics,
				});
			});
			commit("SET_FILTER_IN_FILTERS_KEY_VALUE", {
				filter: "sections",
				key: "options",
				value: sections,
			});
			return Promise.resolve();
		} catch (error) {
			return Promise.reject();
		}
	},

	/**
	 * Gets list of all of all sections and puts them in the the filters.
	 */
	async getStatusFilterOptions({ commit }) {
		try {
			const statuses = [
				{
					name: "Pending",
					id: 0,
				},
				{
					name: "In Progress",
					id: 1,
				},
				{
					name: "Reviewed",
					id: 2,
				},
				{
					name: "Downloaded",
					id: 3,
				},
			];
			commit("SET_FILTER_IN_FILTERS_KEY_VALUE", {
				filter: "status",
				key: "options",
				value: statuses,
			});
			return Promise.resolve();
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	setDataKeyValueAccordingToSessionType({ commit }, payload) {
		if (payload.sessionType === "individual") {
			commit("SET_DATA_IN_SESSIONS_KEY_VALUE", {
				sessionId: payload.sessionId,
				key: payload.key,
				value: payload.value,
			});
		}
	},
	async getSessionShareKey({ commit }, sessionId) {
		try {
			const response = await SessionSharesAPI.create({ session_id: sessionId });
			const key = response.data.data.key;
			commit("SET_SESSION_SHARE_KEY", {
				key,
			});
			return Promise.resolve(key);
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	async getAvailableTutors({ commit }) {
		try {
			const response = await HandoffsAPI.getTutorsForHandoff();
			const tutors = response.data.data;
			commit("SET_TUTORS_IN_SESSION", {
				tutors: tutors,
			});
			return Promise.resolve(tutors);
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	async createHandoff({}, payload) {
		try {
			const response = await HandoffsAPI.create(payload);
			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	showFailTransferModal({ commit }, payload) {
		commit("SET_TUTORS_IN_SESSION", {
			error: payload.error,
		});
	},
	async reUploadEssay(_, payload) {
		try {
			const params = new FormData();
			params.append("reviewed_at", payload.reviewed_at);
			params.append("file", payload.file);
			const response = await EssaysAPI.update(payload.essayId, params);
			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	async getReviewedEssayFilename({ commit }, payload) {
		try {
			const response = await EssaysAPI.show(payload.essayId);
			commit("SET_REVIEWED_FILE_DATA", {
				reviewed_file_data: response.data.data.reviewed_file_data,
			});
			Promise.resolve(response);
		} catch (error) {
			Promise.reject(error);
		}
	},
	async createNewFlag({ commit }, payload) {
		try {
			const response = await FlagsAPI.create(payload);
			commit("PUSH_TO_FLAGS_IN_SESSION", {
				sessionId: payload.session_id,
				flag: response.data.data,
			});
			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	async getFlagOptions({ commit }) {
		const flagsObject = {};
		try {
			const response = await FlagsAPI.list();
			response.data.data.forEach((flag) => {
				flagsObject[flag.id] = flag;
			});
			commit("SET_FLAG_SESSION_REASONS", {
				flagSessionReasons: flagsObject,
			});
		} catch (error) {
			return Promise.reject(error);
		}
	},
	async deleteFlag({ commit, state }, payload) {
		try {
			const response = await FlagsAPI.delete(payload.sessionFlagId);
			const flagIndex = state.sessions.data[payload.sessionId].flag.data.findIndex(
				(flag) => flag.session_flag_id === payload.sessionFlagId,
			);
			commit("SPLICE_FLAG_IN_SESSIONS", {
				sessionId: payload.sessionId,
				index: flagIndex,
			});
			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	resetFilters({ commit, state, getters, dispatch }) {
		try {

			Object.keys(state.filters).forEach((filterKey) => {
				commit("SET_FILTER_IN_FILTERS_KEY_VALUE", {
					filter: filterKey,
					key: "selected",
					value: [],
				});
			});
			const params = helpers.configureDefaultDates({});
			commit("SET_DATE_RANGE", {
				start: params.from,
				end: params.to,
			});
			commit("SET_SEARCH_TEXT", { text: "" });
			dispatch("getFilteredData", { ...getters.filterParams });
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	async hideSession({}, id) {
		try {
			const response = await SessionsAPI.hideSession(id);
			return Promise.resolve(response);
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject(error);
		}
	},
	async hideEssay({}, id) {
		try {
			const response = await EssaysAPI.hideEssay(id);
			return Promise.resolve(response);
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject(error);
		}
	},
};

export default {
	namespaced: true,
	state: getDefaultState(),
	getters,
	mutations,
	actions,
};
