/**
 *
 * @param {String}  text
 * @param {Array}  	files
 * @param {Date}  	expiryDate
 */
function InfoBlock({ text, files }) {
	this.text = text;
	this.files = files;
}

export default InfoBlock;
