import {
	createComment,
} from "../service/comments.js";

export const getDefaultState = () => ({
	commentsList: [],
	problemInEdit: null,
});

const state = getDefaultState();

export default {
	namespaced: true,
	state,
	mutations: {
		SET_COMMENTS(state, payload) {
			state.commentsList = payload;
		},
	},
	actions: {
		async createComment(_, { problemId, comment }) {
			await createComment(problemId, comment);
		},
	},
};
