var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "tw-flex tw-flex-col tw-content-center tw-items-center tw-py-32 tw-m-5 tw-bg-white-high tw-text-grey-dark",
    },
    [
      _vm.hasIconSlot
        ? _vm._t("icon")
        : _c("Search", { staticClass: "tw-w-11 tw-h-12" }),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "tw-text-center tw-w-1/3 tw-mt-6" },
        [_vm._t("default")],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }