var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vueCustomerDashboardTable" }, [
    _c(
      "table",
      { staticClass: "table table-striped" },
      [
        _c(
          "tr",
          { staticClass: "vueTableHeader" },
          _vm._l(_vm.headers, function (header) {
            return _c(
              "th",
              {
                key: header.value,
                class: { statusPad: header.header === "Status" },
                attrs: { nowrap: "" },
              },
              [
                _vm._v("\n\t\t\t\t" + _vm._s(header.header) + "\n\t\t\t\t"),
                header.orderable
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: header.value,
                            expression: "header.value",
                          },
                        ],
                        staticClass: "headerOrderBy",
                        on: {
                          click: function ($event) {
                            return _vm.handleOrderByEvent(header)
                          },
                        },
                      },
                      [
                        _c(_vm.determineSortingIcon(header.state), {
                          tag: "component",
                          staticClass: "tw-inline",
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm._l(_vm.items, function (item, index) {
          return _c(
            "tr",
            {
              directives: [
                {
                  name: "data-cy",
                  rawName: "v-data-cy",
                  value: `dashboard-table-item`,
                  expression: "`dashboard-table-item`",
                },
              ],
              key: item.id,
              staticClass: "vueTableItem",
            },
            [_vm._t("default", null, { item: item, index: index })],
            2
          )
        }),
        _vm._v(" "),
        _vm._t("extraRows"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }