import {
	getTopics,
	getSubjects,
	getStatusList,
} from "../service/problemCreatorFilters.js";
import { getTags } from "../service/tags.js";

const filterConfig = [
	{
		key: "problem_status_id",
		title: "Status",
		type: "radio",
		apiOptions: getStatusList,
		prependOption: "Status (All)",
	},
	{
		key: "tag_id",
		title: "Tags",
		type: "checkbox",
		apiOptions: getTags,
	},
	{
		key: "mission_type_id",
		title: "Mission Type",
		type: "radio",
		options: [{ id: 1, name: "Math" }, { id: 2, name: "Y/N" }, { id: 3, name: "Multiple Choice" }, { id: 4, name: "Inputs" }],
	},
	{
		key: "subject_id",
		title: "Subjects",
		type: "checkbox",
		apiOptions: getSubjects,
	},
	{
		key: "topic_id",
		parentKey: "subject_id",
		title: "Topics",
		type: "checkbox",
		apiOptions: getTopics,
	},
];

export default filterConfig;
