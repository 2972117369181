var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.group ? "transition-group" : "transition",
    {
      tag: "component",
      attrs: { name: "expand", tag: _vm.groupTag },
      on: {
        enter: _vm.onEnter,
        "after-enter": _vm.onAfterEnter,
        leave: _vm.onLeave,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }