import { merge } from "lodash";
import { format } from "date-fns";

import BreaksInterface from "../../interfaces/BreaksInterface.js";
import { isMealBreakShift } from "../ScheduleHelpers.js";

const breaksListener = {
	async connect() {
		this.$socket.emit("init.room", {}, () => { });
	},
	schedule_break_request_approved(data) {
		if (isMealBreakShift(data.data?.approved_request)) {
			this.$store.commit("PunchClock/SET_BREAK_REQUEST_STATUS", {
				status: "APPROVED",
			});
		}

		const scheduledBreak = data.data.shifts;
		scheduledBreak.forEach((shift) => {
			shift.segment_id = data.data.segment_id;
		});

		const newShifts = {};
		data.data.shifts.map((shift) => newShifts[shift.id] = shift);
		const upcomingShifts = merge({}, this.$store.state.TutorShiftSummary.upcomingShifts, newShifts);

		this.$store.commit("TutorShiftSummary/SET_UPCOMING_SHIFTS", {
			upcomingShifts: Object.values(upcomingShifts),
		});

		this.$store.dispatch("TutorDashboardSchedule/updateShifts", {
			shifts: data.data.shifts,
			approvedBreak: data.data.approved_request,
		});

		const snackbarText = "Your break request has been approved from " + format(new Date(data.data.shifts[1].start_time * 1000), "h:mm aaaa") +
			" to " + format(new Date(data.data.shifts[1].end_time * 1000), "h:mm aaaa");
		this.$store.dispatch("Snackbar/showSnackbar", {
			snackbarText,
			snackbarType: "success",
		});
	},
	schedule_break_request_rejected(data) {
		const snackbarText = "Your break request has not been approved from " + format(new Date(data.data.start_time * 1000), "h:mm aaaa") +
			" to " + format(new Date(data.data.end_time * 1000), "h:mm aaaa");

		this.$store.commit("PunchClock/SET_STATUS_OF_BREAK_REJECT_RESPONSE", {
			response: data.data,
		});

		this.$store.commit("PunchClock/SET_BREAK_REQUEST_STATUS", {
			status: "REJECTED",
		});

		this.$store.dispatch("Snackbar/showSnackbar", {
			snackbarText,
			snackbarType: "error",
		});
	},
	/**
		 * @param {Object} data
		 * @param {Object} data.data
		 * @param {Number[]} data.data.deletedScheduleIds
		 * @param {Object} data.data.updatedSchedule
		 */
	schedule_break_cancelled({ data: { deletedScheduleIds, updatedSchedule } }) {
		deletedScheduleIds.forEach((shiftId) => {
			this.$store.commit("TutorShiftSummary/REMOVE_SHIFT_FROM_UPCOMING_SHIFTS", {
				shiftId,
			});
			if (this.$store.state.PunchClock.breakRequestStatus !== "PENDING") {
				this.$store.commit("PunchClock/SET_BREAK_REQUEST_STATUS", {
					status: "NONE",
				});
			}
			this.$store.commit("TutorDashboardSchedule/DELETE_SHIFT_IN_SHIFTS", {
				shiftId,
			});
		});

		this.$store.commit("TutorShiftSummary/SET_SHIFT_IN_UPCOMING_SHIFTS", {
			shift: updatedSchedule,
		});

		this.$store.dispatch("TutorDashboardSchedule/updateShifts", {
			shifts: [updatedSchedule],
		});
	},
	schedule_break_started({ data }) {
		const upcomingShifts = Object.values(this.$store.state.TutorShiftSummary.upcomingShifts)
			.sort((segmentA, segmentB) => segmentA.start_time - segmentB.start_time);
		const breakStartTimestamp = data.start_time;
		const breakEndTimestamp = data.end_time;

		const breakIndex = upcomingShifts.findIndex((shiftSegment) => shiftSegment.id === data.id);

		upcomingShifts[breakIndex - 1].punch_out = breakStartTimestamp;
		upcomingShifts[breakIndex - 1].end_time = breakStartTimestamp;
		upcomingShifts[breakIndex].start_time = breakStartTimestamp;
		upcomingShifts[breakIndex].end_time = breakEndTimestamp;
		upcomingShifts[breakIndex + 1].start_time = breakEndTimestamp;

		this.$store.commit("TutorShiftSummary/SET_UPCOMING_SHIFTS", {
			upcomingShifts,
		});
	},
};

BreaksInterface.isImplementedBy(breaksListener);

export default breaksListener;
