import middleware from "@/middleware/index.js";
import isConnected from "@/middleware/isConnected.js";
import hasRole from "@/middleware/hasRole.js";
import hasSchool from "@/middleware/hasSchool.js";
import districtManagerDashboardRoutes from "@/modules/EducatorInsights/router/districManager.js";
import initializePendo from "@/middleware/initializePendo.js";
import initializeZendesk from "@/middleware/initializeZendesk.js";
import initFullStory from "@/middleware/initFullStory.js";
import { studentGroupsRoutes } from "@/modules/StudentGroups/index.js";
import { districtManagerStudentRoutes } from "@/modules/SiteAdministratorStudentList/index.js";
import { MICRO_FRONTENDS_NAMES } from "@/modules/MicroFrontend/utilities/index.js";
import store from "@/store/index.js";

export default [
	{
		path: "/district-manager",
		component: () => import("../pages/district-manager/index.vue"),
		beforeEnter: middleware([
			isConnected,
			hasRole("district-manager"),
			hasSchool,
			initializePendo,
			initializeZendesk,
			initFullStory,
			(to, from, next) => {
				store.commit("SET_SHOW_NAVBAR", true);
				next();
			},
		]),
		children: [
			...districtManagerDashboardRoutes,
			{
				path: "activity",
				name: "districtManagerActivity",
				component: () => import("@/modules/Activity/pages/district-manager-activity.vue"),
			},
			{
				name: "districtManagerActivityDashboard",
				path: "activity_mfe",
				component: () => import("@/modules/MicroFrontend/components/MicroFrontend.vue"),
				props: {
					microFrontendName: MICRO_FRONTENDS_NAMES.ACTIVITY_DASHBOARD,
				},
			},
			{
				path: "settings",
				name: "districtManagerSettings",
				component: () => import("@/modules/UserSettings/pages/district-manager-settings.vue"),
			},
			{
				path: "competition",
				name: "districtManagerCompetitions",
				component: () => import("@/pages/district-manager/competition/index.vue"),
			},
			{
				path: "competition/:school/:competitionId",
				name: "districtManagerCompetitionsBySchool",
				component: () => import("@/pages/district-manager/competition/school.vue"),
			},
			{
				path: "past-competitions",
				name: "districtManagerPastCompetitions",
				component: () => import("@/pages/district-manager/competition/past.vue"),
			},
			...studentGroupsRoutes,
			...districtManagerStudentRoutes,
		],
	},
];
