import api from "../../../services/api/Api.js";

export default {
	/**
	 * Post request a break
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	requestBreak(data) {
		return api().post("/schedule-break-requests", data);
	},
	/**
	 * Cancel break request
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	cancelBreakRequest(breakRequestId) {
		return api().post(`/schedule-break-requests/${breakRequestId}/cancel`);
	},
	/** get breaks
	 *
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	getBreakRequestsForTutors(data) {
		return api().get(`/schedule-break-requests`, data);
	},
	/** 
	 * tutor-manger approve break request
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	approveBreakRequest(breakRequestId) {
		return api().post(`schedule-break-requests/${breakRequestId}/approve`);
	},
	/** 
	 * tutor-manager reject break request
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	rejectBreakRequest(breakRequestId) {
		return api().post(`/schedule-break-requests/${breakRequestId}/reject`);
	},
	cancelBreak(breakId) {
		return api().post(`schedules/${breakId}/cancel-break`);
	},
};
