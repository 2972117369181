var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ServiceDashboardSummaryCard", {
    scopedSlots: _vm._u(
      [
        {
          key: "cardTitle",
          fn: function () {
            return [_vm._v("\n\t\tLive Help\n\t")]
          },
          proxy: true,
        },
        _vm.isMissedStudentNumGreaterThanZero
          ? {
              key: "badges",
              fn: function () {
                return [
                  _c(
                    "BaseLink",
                    {
                      staticClass:
                        "summaryCard__badge summaryCard__badge--salmon summaryCard__badge--cursorPointer",
                      attrs: { to: _vm.missedSessionActivityLink },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t" +
                          _vm._s(_vm.missedStudentsNum) +
                          " Missed Students\n\t\t"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("HelpTooltip", {
                    attrs: { "help-text": _vm.tooltipString },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
        {
          key: "leftTitle",
          fn: function () {
            return [_vm._v("\n\t\tOngoing\n\t")]
          },
          proxy: true,
        },
        {
          key: "leftNumber",
          fn: function () {
            return [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "data-cy",
                      rawName: "v-data-cy",
                      value: "live-help-ongoing",
                      expression: "'live-help-ongoing'",
                    },
                  ],
                  staticClass: "summaryCardContent__leftNumber",
                  class: {
                    loading:
                      _vm.$store.getters[
                        "PlatformManager/ServiceSummaryDashboard/isLoadingSummary"
                      ],
                  },
                },
                [_vm._v("\n\t\t\t" + _vm._s(_vm.ongoingSessionsNum) + "\n\t\t")]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "rightTitle",
          fn: function () {
            return [
              _c("span", { attrs: { id: "liveHelpPeriodTitle" } }, [
                _vm._v("Total " + _vm._s(_vm.totalString)),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "rightNumber",
          fn: function () {
            return [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "data-cy",
                      rawName: "v-data-cy",
                      value: "live-help-total-today",
                      expression: "'live-help-total-today'",
                    },
                  ],
                  staticClass: "summaryCardContent__rightNumber",
                  class: {
                    loading:
                      _vm.$store.getters[
                        "PlatformManager/ServiceSummaryDashboard/isLoadingSummary"
                      ],
                  },
                },
                [_vm._v("\n\t\t\t" + _vm._s(_vm.totalSessionsNum) + "\n\t\t")]
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }