var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("TutorScheduledHoursWrapper", {
    scopedSlots: _vm._u([
      {
        key: "weekDetails",
        fn: function () {
          return [_c("WeekDetails")]
        },
        proxy: true,
      },
      {
        key: "totalHours",
        fn: function () {
          return [_c("TotalHoursScheduled")]
        },
        proxy: true,
      },
      {
        key: "tutorHoursBreakdown",
        fn: function () {
          return [_c("TutorHoursBreakdown")]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }