import rootInstance from "@/services/root.js";

// if spoofing uncomment the next line
// import stage_access_token from './stage.token.json';

const CONTENT_CATALOG_URL = process.env.MIX_CONTENT_CATALOG_API_URL + "/api/v1/";

const mathApi = (accessToken = undefined) => {
	rootInstance.defaults.baseURL = CONTENT_CATALOG_URL;
	if (accessToken) {
		rootInstance.defaults.headers.common.Authorization = accessToken;
	}
	return rootInstance;
};

const extractData = ({ data: { data } }) => data;

let uploadFiles = null;
let getImageUrl = null;

if (typeof stage_access_token !== "undefined") {
	// eslint-disable-next-line no-undef
	const { access_token: stage_token } = stage_access_token;

	uploadFiles = (payload) =>
		mathApi(stage_token)
			.post(`/images`, payload)
			.then(extractData);

	getImageUrl = (urlUuid) =>
		mathApi(stage_token)
			.get(`/images/${urlUuid}/content-url`)
			.then(extractData);
} else {
	uploadFiles = (payload) =>
		mathApi()
			.post(`/images`, payload)
			.then(extractData);

	getImageUrl = (urlUuid) =>
		mathApi()
			.get(`/images/${urlUuid}/content-url`)
			.then(extractData);
}

export { uploadFiles, getImageUrl };
