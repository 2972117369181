var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M3.42486 2.81558V2.8151C3.42486 2.42944 3.75206 2.08362 4.19105 2.08362H11.8101C12.254 2.08362 12.5839 2.43427 12.5839 2.8151V13.542L8.28903 11.7524L8.00057 11.6322L7.71211 11.7524L3.41796 13.5417L3.42486 2.81558Z",
          stroke: "currentColor",
          "stroke-width": "1.5",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }