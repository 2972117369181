export const MISSION_TYPES = {
	0: "mission",
	1: "training",
};

export const MISSION_LABEL = {
	0: "Mission",
	1: "Tutorial",
};

export const MISSION_TYPE_IDS = {
	MATH_PROBLEM: 1,
	TRUE_FALSE: 2,
	MULTIPLE_CHOICE: 3,
};

export const MISSION_FEEDBACK = {
	CORRECT: [
		"Excellent work! Care to try another?",
		"Fantastic work! We’re here with you!",
		"Nice work! Practice makes improvement.",
		"Did we ever tell you you’re awesome? You’re awesome.",
		"See? Practice pays off!",
		"SUPERB!",
	],
	INCORRECT: [
		"Remember: you learn every time you make mistakes.",
		"But that’s alright! You’re learning.",
		"It’s all good! Mistakes are just a natural part of the process.",
		"But keep calm and practice on.",
		"Stay on your feet, though. We know you got this.",
		"But that’s OK! You’ll get it next time.",
	],
};

export const MCQ_TYPES = {
	TEXT: "text",
	IMAGE: "image",
};
