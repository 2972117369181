import { pendoInitializedPromise, initializeZendesk } from "@paper-co/web-toolkit";

export default async function(to, from, next) {
	pendoInitializedPromise
		.then(() => initializeZendesk({
			key: process.env.MIX_ZENDESK_KEY,
			departmentId: process.env.MIX_ZENDESK_DEPARTMENT_ID,
			user: this.state.currentUser,
		}))
		.catch(Sentry.captureException);
	return next();
}
