var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.opensDrawer
    ? _c(
        _setup.OrigamiButton,
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "sidebar-button",
              expression: "'sidebar-button'",
            },
          ],
          staticClass: "sidebar-button",
          class: {
            "sidebar-button--active": _setup.isLinkActive,
            "sidebar-button--drawer-open": _vm.isOpen,
          },
          nativeOn: {
            click: function ($event) {
              return _setup.emit("open-drawer")
            },
          },
        },
        [
          _c(_setup.OrigamiIcon, {
            staticClass: "sidebar-button-icon",
            attrs: { name: _vm.icon, width: "32", height: "32" },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "sidebar-button-label" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.$t(_vm.title)) + "\n\t"),
          ]),
        ],
        1
      )
    : _c(
        _setup.getLinkComponent,
        _vm._b(
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "sidebar-link",
                expression: "'sidebar-link'",
              },
            ],
            tag: "component",
            staticClass: "sidebar-button",
            class: {
              "sidebar-button--active": _setup.isLinkActive,
              "sidebar-button--drawer-open": _vm.isOpen,
            },
            nativeOn: {
              click: function ($event) {
                return _setup.emit("close-drawer")
              },
            },
          },
          "component",
          _setup.getLinkProperties,
          false
        ),
        [
          _c(_setup.OrigamiIcon, {
            staticClass: "sidebar-button-icon",
            attrs: { name: _vm.icon, width: "32", height: "32" },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "sidebar-button-label" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.$t(_vm.title)) + "\n\t"),
          ]),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }