<template>
	<BreaksConfirmation>
		<template #header>
			<p v-jest="'scheduled-break-cancelled-header'">
				{{ breakTypeName }} break cancelled
			</p>
		</template>
		<template #bodyIcon>
			<OrigamiIcon
				name="block"
				width="32"
				height="32"
				class="tw-text-origami-red-400"
			/>
		</template>
		<template #bodyText>
			<p
				v-if="formattedBreakTimes"
				v-jest="'scheduled-break-cancelled-details'"
			>
				Your scheduled break has been cancelled by a Platform Manager for {{ formattedBreakTimes.startTime }} to {{ formattedBreakTimes.endTime }}
			</p>
		</template>
		<template #buttons>
			<OrigamiButton
				v-jest="'scheduled-break-cancelled-try-again-button'"
				variant="primary"
				class="tw-w-full tw-justify-center"
				@click.native="requestBreak"
			>
				Try for another time
			</OrigamiButton>
		</template>
	</BreaksConfirmation>
</template>

<script>
import { OrigamiButton, OrigamiIcon } from "@origami/vue2";

import { formatIsoDateToHM } from "@/utilities/dateHelpers.js";
import { REST_BREAK_ID } from "@/modules/TutorSchedule/index.js";

import BreaksConfirmation from "../templates/BreaksConfirmation.vue";

export default {
	components: {
		OrigamiButton,
		BreaksConfirmation,
		OrigamiIcon,
	},
	props: {
		breakTypeId: {
			type: Number,
			required: true,
		},
	},
	computed: {
		breakTypeName() {
			return this.breakTypeId === REST_BREAK_ID ? "Rest" : "Meal";
		},
		formattedBreakTimes() {
			const { cancelledBreak } = this.$store.state.TutorSchedule;

			return cancelledBreak ? {
				startTime: formatIsoDateToHM(cancelledBreak.start_time),
				endTime: formatIsoDateToHM(cancelledBreak.end_time),
			} : null;
		},
	},
	methods: {
		requestBreak() {
			if (this.breakTypeId === REST_BREAK_ID) {
				this.$store.dispatch("BreaksManagementWidget/requestRestBreak");
			} else {
				this.$store.dispatch("BreaksManagementWidget/requestMealBreak");
			}

			this.$store.dispatch("TutorSchedule/resetCancelledBreak");
		},
	},
};
</script>
