import { subMonths } from "date-fns";

import { StudentAnalyticsApi } from "@/modules/StudentAnalytics/index.js";
import {
	getGradeIds,
	UNCATEGORIZED_GRADE_LEVEL_ID,
	UNCATEGORIZED_GRADE_LEVEL_NAME,
} from "@/utilities/grade.js";

export const DEFAULT_PARAMS_PAGE = 1;
export const MAX_GROUP_LIMIT = 50;
export const DEFAULT_PARAMS_ORDER = {
	by: "",
	dir: "",
};

export const getDefaultState = () => ({
	dateRangeSubmitted: {
		start: subMonths(new Date(), 1),
		end: new Date(),
	},
	filterSelections: {
		dateRange: {
			start: subMonths(new Date(), 1),
			end: new Date(),
		},
		gradeLevels: [],
		schools: [],
		groups: null,
	},
	filterOptions: {
		gradeLevels: [],
		schools: [],
		groups: [],
	},
	studentListParams: {
		order: DEFAULT_PARAMS_ORDER,
		page: DEFAULT_PARAMS_PAGE,
		search: "",
	},
	gradeLevels: [],
});

const state = getDefaultState();

const mutations = {
	SET_GRADE_LEVELS(state, payload) {
		state.gradeLevels = payload;
	},
	RESET_FILTER_SELECTIONS(state, payload) {
		const defaultState = getDefaultState();
		state.filterSelections = {
			...defaultState.filterSelections,
			dateRange: payload || defaultState.filterSelections.dateRange,
		};
	},
	SET_DATE_RANGE_SUBMITTED(state) {
		state.dateRangeSubmitted = structuredClone(state.filterSelections.dateRange);
	},
	SET_FILTER_OPTIONS(state, payload) {
		state.filterOptions = payload;
	},
	SET_FILTER_OPTIONS_BY_KEY(state, { key, value }) {
		state.filterOptions = { ...state.filterOptions, [key]: value };
	},
	SET_FILTER_SELECTION_BY_KEY(state, { key, value }) {
		state.filterSelections = { ...state.filterSelections, [key]: value };
	},
	SET_IS_LOADING_STUDENT_LIST(state, payload) {
		state.isLoadingStudentList = payload.isLoadingStudentList;
	},
	SET_STUDENT_LIST_ORDER(state, payload) {
		state.studentListParams.order = payload.order;
	},
	SET_STUDENT_LIST_PAGE(state, payload) {
		state.studentListParams.page = payload.page;
	},
	SET_STUDENT_LIST_SEARCH(state, payload) {
		state.studentListParams.search = payload.search;
	},
};

const getters = {
	getStudentListOrder(state) {
		return state.studentListParams.order;
	},
	getDateRange(state) {
		return state.filterOptions.dateRange;
	},
};

const actions = {
	adjustGradeLevels({ state, commit }) {
		const { filterOptions, filterSelections, gradeLevels } = state;

		const gradeIdsOfSelectedSchools = getGradeIds(filterOptions.schools, filterSelections.schools);

		const categorizedGradeLevelsOfSelectedSchools = gradeLevels.filter(({ id }) =>
			gradeIdsOfSelectedSchools.includes(id) && id !== UNCATEGORIZED_GRADE_LEVEL_ID,
		)
			.sort((a, b) => a.id - b.id);

		const gradeLevelsOptions =
			gradeIdsOfSelectedSchools.includes(UNCATEGORIZED_GRADE_LEVEL_ID)
				? [
					...categorizedGradeLevelsOfSelectedSchools,
					{ id: UNCATEGORIZED_GRADE_LEVEL_ID, name: UNCATEGORIZED_GRADE_LEVEL_NAME },
				]
				: categorizedGradeLevelsOfSelectedSchools;

		commit("SET_FILTER_OPTIONS_BY_KEY", { key: "gradeLevels", value: gradeLevelsOptions });
		commit("SET_FILTER_SELECTION_BY_KEY", { key: "gradeLevels", value: [] });
	},
	async getFiltersData({ state, rootState, commit, dispatch }) {
		const { groups } = rootState.StudentGroups;
		const { schools = [], grade_levels: gradeLevels = [] } = await StudentAnalyticsApi.getFilters();

		commit("SET_GRADE_LEVELS", gradeLevels);

		const { dateRange } = state.filterSelections;
		commit("RESET_FILTER_SELECTIONS", dateRange);
		commit("SET_FILTER_OPTIONS", { schools, dateRange, groups, gradeLevels });

		await dispatch("adjustGradeLevels");
	},
};

export default {
	namespaced: true,
	actions,
	getters,
	mutations,
	state,
};
