var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.featureFlagSidebarRedesignIsEnabled
    ? _c(_setup.SidebarNew, {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "sidebar-new",
            expression: "'sidebar-new'",
          },
        ],
        on: {
          "open-drawer": function ($event) {
            return _setup.emit("open-drawer")
          },
          "close-drawer": function ($event) {
            return _setup.emit("close-drawer")
          },
        },
      })
    : _c(
        "div",
        { staticClass: "tw-flex" },
        [
          !_vm.isMobileWebviewOpened
            ? _c(_setup.Sidebar, {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "sidebar",
                    expression: "'sidebar'",
                  },
                ],
                attrs: { "is-toggled": _vm.isSidebarToggled },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isMobileWebviewOpened
            ? _c(_setup.SidebarMobile, {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isMobileMenuToggled,
                    expression: "isMobileMenuToggled",
                  },
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "sidebar-mobile",
                    expression: "'sidebar-mobile'",
                  },
                ],
                ref: "mobileNav",
                on: {
                  "reset-mobile-menu": function ($event) {
                    return _setup.emit("reset-mobile-menu")
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }