import api from "../../../services/api/Api.js";

export default {
	/**
	 * Update assessment
	 * @param {number} assessmentId
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	update(assessmentId, data) {
		return api().post(`assessments/${assessmentId}`, data);
	},

	/**
	 * Get incomplete assessments for tutor
	 * @param {number} tutorId
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	getTutorIncompleteAssessments(tutorId, params) {
		return api().get(`/tutors/${tutorId}/incomplete-assessments`, {
			params: params,
		});
	},

	/**
	 * Get incomplete assessments for tutor
	 * @param {number} tutorId
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	getCountPendingAssessmentsByUserId(tutorId) {
		return api().get(`/assessments/${tutorId}/incomplete-assessments-count`);
	},
	/**
	 * Get concepts, subject, and topics
	 * @returns {AxiosPromise}
	 */
	getAcademicUnits(params) {
		return api().get("/academic-units", { params });
	},
	/**
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	sendQuestionsForAcademicUnitsAnalysis(id, params) {
		return api().get(`/sessions/${id}/academic-units`, { params });
	},
	/**
	 * Get Concepts and Topics 
	 * tied to each Subjects for assessment
	 * @returns {AxiosPromise}
	 */
	getAssessmentMap() {
		return api().get("/assessment_map");
	},
};
