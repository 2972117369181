import { getFlag } from "@paper-co/web-toolkit";

import TopicsAPI from "../service/topics.js";

const getDefaultState = () => ({
	activeSubject: null,
	activeTopic: null,
	allSubjects: null,
	allTopics: null,
	savedTopic: null,
	savedSubject: null,
});

const state = getDefaultState();

export const mutations = {
	SET_SUBJECT(state, payload) {
		state.activeSubject = payload;
	},
	CLEAR_SUBJECT(state) {
		state.activeSubject = null;
	},
	SET_SAVED_SUBJECT(state, payload) {
		state.savedSubject = payload;
	},
	CLEAR_SAVED_SUBJECT(state) {
		state.savedSubject = null;
	},
	SET_TOPIC(state, payload) {
		state.activeTopic = { ...payload, index: state.allTopics.findIndex(({ id }) => id === payload.id) };
	},
	CLEAR_TOPIC(state) {
		state.activeTopic = null;
	},
	SET_SAVED_TOPIC(state, payload) {
		state.savedTopic = { ...payload, index: state.allTopics.findIndex(({ id }) => id === payload.id) };
	},
	CLEAR_SAVED_TOPIC(state) {
		state.savedTopic = null;
	},
	SET_ALL_SUBJECTS(state, payload) {
		state.allSubjects = payload;
	},
	CLEAR_ALL_SUBJECTS(state) {
		state.allSubjects = null;
	},
	SET_ALL_TOPICS(state, payload) {
		state.allTopics = payload;
	},
	CLEAR_ALL_TOPICS(state) {
		state.allTopics = null;
	},
};

export const actions = {
	async setAllSubjects({ rootState, commit }) {
		try {
			const result = await TopicsAPI.getProgressBySubject();
			const isMissionsSlice3FeatureFlagEnabled = await getFlag("PE-17104-Missions-S3");
			if (!isMissionsSlice3FeatureFlagEnabled) {
				result.splice(-1);
			} else if (isMissionsSlice3FeatureFlagEnabled && rootState.currentUser.school.region_code !== "MS") {
				result.splice(-1);
			}
			commit("SET_ALL_SUBJECTS", result);
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	async refreshProgress({ commit }) {
		try {
			const result = await TopicsAPI.getAllTopicsBySubject(state.activeSubject.id);
			commit("SET_ALL_TOPICS", result);
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	async setAllTopicsBySubject({ commit, state }, subject) {
		try {
			if (state.allTopics) {
				commit("CLEAR_ALL_TOPICS");
			}
			if (state.activeTopic) {
				commit("CLEAR_TOPIC");
			}
			commit("SET_SUBJECT", subject);

			const result = await TopicsAPI.getAllTopicsBySubject(subject.id);
			commit("SET_ALL_TOPICS", result);
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	async setHistory({ commit, state }) {
		try {
			const result = await TopicsAPI.getLastTopic();
			if (result) {
				const subject = state.allSubjects.find((subject) => subject.id === result.subject_id);
				const allTopics = await TopicsAPI.getAllTopicsBySubject(subject.id);
				commit("SET_ALL_TOPICS", allTopics);
				const topic = state.allTopics.find((topic) => topic.id === result.topic_id);

				commit("SET_SAVED_TOPIC", topic);
				commit("SET_SAVED_SUBJECT", subject);
			}
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	async continueProgress({ commit, state }) {
		commit("SET_SUBJECT", state.savedSubject);
		commit("SET_TOPIC", state.savedTopic);
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
