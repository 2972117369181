var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tagsTable" }, [
    _c(
      "table",
      { staticClass: "tw-w-full tw-max-w-full" },
      [
        _c(
          "tr",
          {
            staticClass:
              "tagsTable__headersContainer tw-min-w-full tw-w-full tw-max-w-full tw-flex tw-justify-between",
          },
          [
            _c(
              "th",
              {
                staticClass:
                  "tagsTable__headers tw-text-xs tw-font-bold tw-flex-grow tw-w-1/3",
              },
              [_vm._v("\n\t\t\t\t" + _vm._s(_vm.headers[0].name) + "\n\t\t\t")]
            ),
            _vm._v(" "),
            _c(
              "th",
              {
                staticClass:
                  "tagsTable__headers tw-text-xs tw-font-bold tw-flex-grow tw-w-1/3",
              },
              [_vm._v("\n\t\t\t\t" + _vm._s(_vm.headers[1].name) + "\n\t\t\t")]
            ),
            _vm._v(" "),
            _c(
              "th",
              {
                staticClass:
                  "tagsTable__headers tw-text-xs tw-font-bold tw-flex-grow tw-w-1/3",
              },
              [_vm._v("\n\t\t\t\t" + _vm._s(_vm.headers[2].name) + "\n\t\t\t")]
            ),
          ]
        ),
        _vm._v(" "),
        _vm._l(_vm.tags, function (item) {
          return _c(
            "tr",
            { key: item.id, staticClass: "tw-w-full tw-flex tw-justify-start" },
            [
              _c(
                "td",
                { staticClass: "tw-w-1/3", attrs: { valign: "top" } },
                [
                  _c(
                    "Tag",
                    {
                      staticClass: "tw-ml-0 tw-mr-3 tw-my-1",
                      attrs: { "is-clearable": false, item: item },
                    },
                    [
                      _c("template", { slot: "text" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" + _vm._s(item.name) + "\n\t\t\t\t\t"
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "td",
                {
                  staticClass: "tagsTable__topicsCell",
                  attrs: { valign: "top" },
                },
                [
                  _c(
                    "table",
                    { staticClass: "tw-w-full" },
                    _vm._l(item.topics, function (secondLevelItem) {
                      return _c(
                        "tr",
                        {
                          key: secondLevelItem.id,
                          staticClass: "tw-flex tw-justify-start tw-gap-x-px",
                        },
                        [
                          _c(
                            "td",
                            {
                              staticClass:
                                "tagsTable__topicsTableItem tw-flex-grow tw-flex-shrink-0 tw-p-0",
                            },
                            [
                              _c(
                                "Tag",
                                {
                                  staticClass: "tw-ml-0 tw-mr-3 tw-my-1",
                                  attrs: { "is-clearable": false, item: item },
                                },
                                [
                                  _c("template", { slot: "text" }, [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(secondLevelItem.name) +
                                        "\n\t\t\t\t\t\t\t\t"
                                    ),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass:
                                "tagsTable__topicsTableItem tw-flex-grow tw-flex-shrink-0 tw-p-0",
                            },
                            [
                              _c(
                                "table",
                                { staticClass: "tw-w-full" },
                                _vm._l(
                                  secondLevelItem.concepts,
                                  function (thirdLevelItem) {
                                    return _c(
                                      "tr",
                                      {
                                        key: thirdLevelItem.id,
                                        staticClass: "tw-w-full",
                                      },
                                      [
                                        _c(
                                          "td",
                                          { staticClass: "tw-w-full" },
                                          [
                                            _c(
                                              "Tag",
                                              {
                                                staticClass:
                                                  "tw-ml-0 tw-mr-3 tw-my-1",
                                                attrs: {
                                                  "is-clearable": false,
                                                  item: item,
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "text" },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          thirdLevelItem.name
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ]
          )
        }),
        _vm._v(" "),
        _c(
          "tr",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isTagsEmpty,
                expression: "isTagsEmpty",
              },
            ],
            attrs: { id: "noResults" },
          },
          [
            _c(
              "td",
              { attrs: { colspan: _vm.headers.length } },
              [
                _c("BaseEmptyState", {
                  staticClass: "emptyState",
                  attrs: {
                    header: "No tags to display",
                    "sub-header": "Add tags by clicking 'Edit Tag'",
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }