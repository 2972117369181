var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "actionItem tw-py-4 tw-w-full tw-border-b-2 tw-border-grey-regular tw-border-solid tw-flex tw-justify-between tw-gap-x-4",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "tw-flex tw-flex-col tw-items-start tw-flex-grow tw-gap-y-2",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "detailsBubble tw-shadow-elevation-1 tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-grey-dark tw-w-full tw-break-all",
            },
            [
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "action-item-content",
                      expression: "'action-item-content'",
                    },
                  ],
                  staticClass:
                    "tw-text-base tw-font-normal tw-text-black-high tw-w-full",
                },
                [_vm._t("flagged-message")],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "tw-flex tw-justify-between tw-items-center tw-w-full",
            },
            [
              _c(
                "p",
                { staticClass: "tw-text-sm tw-leading-5 tw-truncate" },
                [_vm._t("flagged-user-info")],
                2
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "status-text",
                      expression: "'status-text'",
                    },
                  ],
                  staticClass:
                    "tw-text-sm tw-leading-5 tw-font-bold tw-ml-4 tw-whitespace-nowrap",
                },
                [_vm._t("status-text")],
                2
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tw-flex tw-items-start tw-gap-y-2" },
        [
          _c("OrigamiIconButton", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "inspect-uninspect-button",
                expression: "'inspect-uninspect-button'",
              },
            ],
            attrs: {
              "icon-name": "inspect",
              "aria-label": _vm.inspectAriaLabel,
              "is-selected": _vm.isCurrentlyInspecting,
            },
            nativeOn: {
              click: function ($event) {
                _vm.isCurrentlyInspecting
                  ? _vm.handleUninspect()
                  : _vm.handleInspect()
              },
            },
          }),
          _vm._v(" "),
          _c("OrigamiIconButton", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "false-alarm-button",
                expression: "'false-alarm-button'",
              },
            ],
            attrs: {
              "icon-name": "block",
              "aria-label": "This item is a False Alarm",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.handleFalseAlarm.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("ActionItemDropdown", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "behaviour-action-dropdown",
            expression: "'behaviour-action-dropdown'",
          },
        ],
        attrs: {
          "action-item": _vm.actionItem,
          actions: _vm.actions,
          "is-action-pending": _vm.isActionPending,
        },
        on: { "action-taken": _vm.handleAction },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }