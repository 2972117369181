<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		viewBox="0 0 14 12"
	>
		<path
			:fill="iconColor"
			fill-rule="evenodd"
			d="M8.556 3.2V0L14 5.6l-5.444 5.6V7.92C4.666 7.92 1.944 9.2 0 12c.778-4 3.111-8 8.556-8.8z"
		/>
	</svg>
</template>

<script>
export default {
	props: {
		width: {
			type: [Number, String],
			default: "14",
		},
		height: {
			type: [Number, String],
			default: "12",
		},
		iconColor: {
			type: String,
			default: "#FFF",
		},
	},
};
</script>
