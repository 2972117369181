export const BREAK_STEP_EMPTY = "";

export const BIO_BREAK_CONFIRMATION = "BIO_BREAK_CONFIRMATION";
export const BIO_BREAK_CLASSROOM_ERROR = "BIO_BREAK_CLASSROOM_ERROR";
export const BIO_BREAK_ACTIVITY_ON_HOLD = "BIO_BREAK_ACTIVITY_ON_HOLD";
export const BIO_BREAK_END = "BIO_BREAK_END";

export const REST_BREAK_REQUEST = "REST_BREAK_REQUEST";
export const REST_BREAK_CONFIRMATION = "REST_BREAK_CONFIRMATION";
export const REST_BREAK_CANCEL = "REST_BREAK_CANCEL";
export const REST_BREAK_CANCELLED = "REST_BREAK_CANCELLED";
export const REST_BREAK_LOCKED = "REST_BREAK_LOCKED";
export const REST_BREAK_END = "REST_BREAK_END";
export const REST_BREAK_ERROR = "REST_BREAK_ERROR";
export const REST_BREAK_IN_PROGRESS = "REST_BREAK_IN_PROGRESS";

export const MEAL_BREAK_REQUEST = "MEAL_BREAK_REQUEST";
export const MEAL_BREAK_PENDING_APPROVAL = "MEAL_BREAK_PENDING_APPROVAL";
export const MEAL_BREAK_REQUEST_REJECTED = "MEAL_BREAK_REQUEST_REJECTED";
export const MEAL_BREAK_REQUEST_APPROVED = "MEAL_BREAK_REQUEST_APPROVED";
export const MEAL_BREAK_CANCELLED = "MEAL_BREAK_CANCELLED";
export const MEAL_BREAK_UPCOMING = "MEAL_BREAK_UPCOMING";
export const MEAL_BREAK_CANCEL = "MEAL_BREAK_CANCEL";
export const MEAL_BREAK_CANCEL_CONFIRM = "MEAL_BREAK_CANCEL_CONFIRM";
export const MEAL_BREAK_ERROR = "MEAL_BREAK_ERROR";
export const MEAL_BREAK_IN_PROGRESS = "MEAL_BREAK_IN_PROGRESS";
export const MEAL_BREAK_LATE = "MEAL_BREAK_LATE";

export const CANCEL_SCHEDULED_BREAK = "CANCEL_SCHEDULED_BREAK";

export const REST_BREAK_STEPS = [
	REST_BREAK_REQUEST,
	REST_BREAK_CONFIRMATION,
	REST_BREAK_CANCEL,
	REST_BREAK_CANCELLED,
	REST_BREAK_LOCKED,
	REST_BREAK_END,
	REST_BREAK_ERROR,
	CANCEL_SCHEDULED_BREAK,
	REST_BREAK_IN_PROGRESS,
];

export const ACTIVE_REST_BREAK_STEPS = [
	REST_BREAK_IN_PROGRESS,
	REST_BREAK_LOCKED,
	REST_BREAK_END,
];

export const REQUEST_REST_BREAK_STEPS = [
	REST_BREAK_REQUEST,
	REST_BREAK_CANCELLED,
];

export const BIO_BREAK_STEPS = [
	BIO_BREAK_CONFIRMATION,
	BIO_BREAK_CLASSROOM_ERROR,
	BIO_BREAK_ACTIVITY_ON_HOLD,
	BIO_BREAK_END,
];

export const MEAL_BREAK_STEPS = [
	MEAL_BREAK_REQUEST,
	MEAL_BREAK_PENDING_APPROVAL,
	MEAL_BREAK_REQUEST_REJECTED,
	MEAL_BREAK_CANCELLED,
	MEAL_BREAK_REQUEST_APPROVED,
	MEAL_BREAK_UPCOMING,
	MEAL_BREAK_CANCEL,
	MEAL_BREAK_CANCEL_CONFIRM,
	MEAL_BREAK_ERROR,
	MEAL_BREAK_IN_PROGRESS,
	MEAL_BREAK_LATE,
];

export const ACTIVE_MEAL_BREAK_STEPS = [
	MEAL_BREAK_UPCOMING,
	MEAL_BREAK_IN_PROGRESS,
	MEAL_BREAK_LATE,
];

export const REQUEST_MEAL_BREAK_STEPS = [
	MEAL_BREAK_REQUEST,
	MEAL_BREAK_REQUEST_REJECTED,
	MEAL_BREAK_CANCELLED,
];
