<template>
	<BaseModal
		id="skip-essay-pickup"
		modal-ref="skip-essay-pickup"
	>
		<div class="tw-flex tw-flex-col tw-mb-4">
			<h4 class="tw-font-bold tw-text-xl tw-mb-4">
				Pass on
			</h4>
			<p class="tw-font-bold tw-text-lg">
				Help us understand the problem. What is going on with this Essay?
			</p>
		</div>
		<div
			id="rg1"
			role="radiogroup"
			aria-labelledby="label"
		>
			<BaseRadio
				v-for="reason in rejectReasons"
				:key="`reject-reason-${reason.id}`"
				v-model="selectedReason"
				role="radio"
				class="tw-mb-4 tw-text-sm tw-border-transparent focus-within:tw-border-origami-blue-200 tw-border-2 tw-border-solid"
				:radio-id="`reason-${reason.id}`"
				:radio-value="reason.id"
				:radio-name="reason.text"
			>
				<template #text>
					{{ reason.text }}
				</template>
			</BaseRadio>
		</div>
		<p class="tw-text-sm tw-text-origami-blue-300">
			Other (Contact a Platform Manager for any other reason that you are not able to review this essay)
		</p>
		<div class="tw-flex tw-justify-end tw-mt-8">
			<BaseButton
				v-jest="'cancel-button'"
				:is-disabled="isLoading"
				class="tw-shadow-elevation-2 tw-mr-4"
				type="CANCEL"
				@click.native="closeModal"
			>
				<template #text>
					Cancel
				</template>
			</BaseButton>
			<BaseButton
				v-jest="'pass-on-button'"
				:is-disabled="isPassButtonDisabled"
				class="tw-shadow-elevation-2"
				@click.native="passOnEssay"
			>
				<template #text>
					<LoadingRectangles
						v-if="isLoading"
						v-jest="'loading-rectangles'"
					/>
					<span v-else>
						Pass-on Essay
					</span>
				</template>
			</BaseButton>
		</div>
	</BaseModal>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import BaseRadio from "@/components/BaseRadio.vue";

import BaseModal from "../../../components/patterns/BaseModal.vue";
import LoadingRectangles from "../../../components/LoadingRectangles.vue";

export default {
	components: {
		BaseModal,
		LoadingRectangles,
		BaseButton,
		BaseRadio,
	},
	data() {
		return {
			isLoading: false,
			selectedReason: null,
			rejectReasons: [
				{ id: 1, text: "The essay has content I am uncomfortable reviewing" },
				{ id: 2, text: "I will not have enough time left in my shift to review this essay" },
			],
		};
	},
	computed: {
		isPassButtonDisabled() {
			return this.selectedReason === null || this.isLoading;
		},
	},
	methods: {
		async passOnEssay() {
			this.isLoading = true;
			const params = {
				essay_skipped_reason_id: this.selectedReason,
			};
			await this.$store.dispatch("Essay/skipReservedEssay", params);
			this.closeModal();
		},
		closeModal() {
			this.isLoading = false;
			this.selectedReason = null;
			this.$bvModal.hide("skip-essay-pickup");
		},
	},

};
</script>
