<template>
	<div class="tw-flex tw-flex-col tw-justify-start tw-gap-4 tw-h-full sm:tw-grid-cols-8 sm:tw-grid sm:tw-gap-x-4">
		<div class="tw-col-span-4">
			<EssayStatisticsCard v-jest="'tutor-essay-stats-card'">
				<template #cardTitle>
					Submission queue
				</template>
				<template #cardTooltip>
					<InfoToolTip
						:width="'11'"
						:height="'11'"
						:options="toolTipTutorEssayStatisticsCard"
					/>
				</template>
				<template #essayDetail>
					{{ EssayStatistics.essayStatistics.queued_essays }}
				</template>
				<template #essayMiscOne>
					<span>{{ numEssayStatisticsInProgress }} in progress</span>
				</template>
				<template #essayMiscTwo>
					<span>{{ numEssayStatisticsReservedEssays }} in reserve</span>
				</template>
			</EssayStatisticsCard>
		</div>
		<div class="tw-col-span-4">
			<EssayStatisticsCard v-jest="'total-essay-reviewed-card'">
				<template #cardTitle>
					Total reviewed
				</template>
				<template #cardTooltip>
					<InfoToolTip
						:width="'11'"
						:height="'11'"
						:options="tooltipTotalEssayReviewedCard"
					/>
				</template>
				<template #essayDetail>
					{{ currentEssayNumber }}
				</template>
				<template #essayMisc>
					{{ totalEssayReviewedMiscText }}
				</template>
			</EssayStatisticsCard>
		</div>
	</div>
</template>

<script>
import { format } from "date-fns";
import { mapState } from "vuex";

import InfoToolTip from "../../../components/patterns/InfoToolTip.vue";
import DispatchErrorHandlingMixin from "../../../mixins/DispatchErrorHandlingMixin.js";
import { getErrorText, showErrorModal } from "../../../utilities/errorHandlingHelpers.js";
import { formatTimestampToDate } from "../../../utilities/dateHelpers.js";

import EssayStatisticsCard from "./EssayStatisticsCard.vue";

const ONE_MINUTE_IN_MS = 60000;

export default {
	components: {
		InfoToolTip,
		EssayStatisticsCard,
	},
	mixins: [DispatchErrorHandlingMixin],
	data() {
		return {
			featureFlagMilestoneIsEnabled: false,
			isDisplayingFeedback: false,
		};
	},
	computed: {
		...mapState(["Essay"]),
		...mapState("Essay", ["EssayStatistics"]),
		currentEssayNumber() {
			return this.featureFlagMilestoneIsEnabled && this.isDisplayingFeedback
				? this.EssayStatistics.essayStatistics.my_total_reviewed_essays + 1
				: this.EssayStatistics.essayStatistics.my_total_reviewed_essays;
		},
		isPunchedIntoEssayReviewShift() {
			return this.$store.getters["TutorSchedule/isPunchedInToWritingReviewShift"];
		},
		numEssayStatisticsInProgress() {
			return this.EssayStatistics.essayStatistics.in_progress_essays ?? 0;
		},
		numEssayStatisticsReservedEssays() {
			return this.EssayStatistics.essayStatistics.active_reserved_essays ?? 0;
		},
		totalEssayReviewedMiscText() {
			return this.isPunchedIntoEssayReviewShift ? "" : "you are currently not scheduled";
		},
		tutorCreationDate() {
			return format(formatTimestampToDate(this.Essay.tutorCreatedAt), "dd/MM/yyyy");
		},
		tooltipTotalEssayReviewedCard() {
			return {
				placement: "right",
				content: `This number shows how many writing reviews you have completed since ${this.tutorCreationDate}`,
			};
		},
		toolTipTutorEssayStatisticsCard() {
			return {
				placement: "right",
				content: "The number of submissions in the queue shows how many student submissions are waiting to be reviewed. The number of submissions in progress shows how many of your colleagues are working on writing reviews with you.",
			};
		},
	},
	async created() {
		this.isLoading = true;
		try {
			this.featureFlagMilestoneIsEnabled = await this.$getFlag("PE-9010-Tutor-ER-Milestones");
		} catch (error) {
			Sentry.captureException(error);
			const options = {
				message: getErrorText("loading the essay statistics"),
			};
			showErrorModal(this, options);
		}
		this.refreshComponent("Essay/EssayStatistics/getEssayStatistics", ONE_MINUTE_IN_MS);
		this.isLoading = false;
	},
	beforeDestroy() {
		clearInterval(this["Essay/EssayStatistics/getEssayStatistics"]);
	},
	methods: {
		async getResource(actionName) {
			try {
				await this.$store.dispatch(actionName);
			} catch (error) {
				Sentry.captureException(error);
			}
		},
		refreshComponent(resource, time) {
			this.getResource(resource);
			this[resource] = setInterval(() => {
				this.getResource(resource);
			}, time);
		},
	},
};
</script>
