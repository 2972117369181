<template>
	<span
		v-jest="'points'"
		class="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-whitespace-nowrap"
	>
		<slot name="prepend" />
		<Emoji
			emoji="💎"
			aria-hidden
		/>
		<BaseSkeletonLoader
			v-if="isLoading"
			v-jest="'loader'"
			class="tw-w-20 tw-h-6"
		/>
		<template v-else>
			{{ points }} {{ label }}
		</template>
	</span>
</template>

<script>
import { Emoji } from "@/modules/Emoji/index.js";
import BaseSkeletonLoader from "@/components/elements/BaseSkeletonLoader.vue";

export default {
	components: {
		Emoji,
		BaseSkeletonLoader,
	},
	props: {
		points: {
			type: Number,
			required: true,
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			default() {
				return this.$t("trophies.points.points_short");
			},
		},
	},
};
</script>
