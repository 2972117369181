import { getFlag } from "@paper-co/web-toolkit";

import { transformSessionsToOldFormat, transformSingleSessionToOldFormat } from "@/utilities/SessionsTransformers.js";

import api from "./Api.js";

export default {
	temp() {
		return true;
	},

	getResultFromQuestionMatcher(question) {
		return api().post(`/sessions/question-start`, question);
	},

	/**
	 * fetches individual session
	 * @param sessionId
	 * @param params
	 * @returns {AxiosPromise}
	 */
	show(sessionId, params = null) {
		return api().get(`/sessions/${sessionId}`, {
			params: params,
		});
	},

	/**
	 * Creates a simple session
	 *
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	create(data) {
		return api().post(`/simple-sessions`, data);
	},

	/**
	 * Update session
	 *
	 * @param {number} sessionId
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	update(sessionId, data) {
		return api().post(`/sessions/${sessionId}`, data);
	},

	/**
	 * Upload file to session
	 *
	 * @param {number} sessionId
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	uploadFile(sessionId, data) {
		return api().post(`/sessions/${sessionId}/files`, data);
	},

	/**
	 * Upload file to session
	 *
	 * @param {number} sessionId
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	uploadFileV2(sessionId, data) {
		return api().post(`/sessions/v1/${sessionId}/messages/file`, data);
	},

	/**
	 * Get recent student sessions in descending order of time of creation
	 *
	 * @param studentId
	 * @param params
	 * @returns {AxiosPromise}
	 */
	async getRecentStudentSessions(studentId, params = null) {
		const response = await api().get(`students/${studentId}/sessions`, {
			params: params,
		});
		const sessions = {
			...response,
			data: {
				total: response.data.meta?.pagination?.total,
				...response.data,
				data: transformSessionsToOldFormat(response.data.data),
			},
		};
		return sessions;
	},

	/**
	 * Get user's active session
	 *
	 * @param userId
	 * @param params
	 * @returns {AxiosPromise}
	 */
	getActiveSessionForUser(userId, params = null) {
		return api().get(`students/${userId}/sessions.individual`, {
			params: params,
		});
	},

	/**
	 * Get aggregate school session data
	 *
	 * @param schoolId
	 * @param params
	 * @returns {AxiosPromise}
	 */
	getSchoolSessionsAggregateCount(schoolId, params = null) {
		return api().get(`/schools/${schoolId}/sessions.count`, {
			params: params,
		});
	},

	/**
	 * Get aggregate district session data
	 *
	 * @param districtId
	 * @param params
	 * @returns {AxiosPromise}
	 */
	getDistrictSessionsAggregateCount(districtId, params = null) {
		return api().get(`/districts/${districtId}/sessions.count`, {
			params: params,
		});
	},

	/**
	 * Get aggregate section session data
	 *
	 * @param sectionId
	 * @param params
	 * @returns {AxiosPromise}
	 */
	getClassroomSessionsAggregateCount(sectionId, params = null) {
		return api().get(`/sections/${sectionId}/sessions.count`, {
			params: params,
		});
	},

	/**
	 * Get my active sessions formatted for the section
	 *
	 * @param params
	 * @returns {AxiosPromise}
	 */
	async getActiveSessionsForUser(params = null) {
		const featureFlagActiveSessionsV1IsEnabled = await getFlag("core-2390-use-sessions-v-1-active");
		const activeSessionsEndpoint = featureFlagActiveSessionsV1IsEnabled ? `/sessions/v1/active` : `/me.active-sessions`;

		const sessions = await api().get(activeSessionsEndpoint, {
			params: params,
		});
		const transformedSession = {
			...sessions,
			data: transformSessionsToOldFormat(sessions.data.data),
		};
		return transformedSession;
	},

	/**
	 *
	 * @param {*} params
	 * @param {Promise} sessionId
	 */
	async getActiveSessionByIdForUser(sessionId, params = null) {
		const featureFlagActiveSessionsV1IsEnabled = await getFlag("core-2390-use-sessions-v-1-active");
		const activeSessionsEndpoint = featureFlagActiveSessionsV1IsEnabled ? `/sessions/v1/active` : `/me.active-sessions`;

		const session = await api().get(`${activeSessionsEndpoint}/${sessionId}`, {
			params: params,
		});
		const transformedSession = {
			...session,
			data: transformSingleSessionToOldFormat(session.data.data),
		};
		return transformedSession;
	},

	/**
	 *
	 * @param {Object} data
	 * @returns {AxiosPromise}
	 */
	getSessionsWithBookmarkedMessages(data) {
		return api().get(`/me.bookmarked-sessions`, data);
	},

	/**
	 * Get my sessions which have incomplete assessments formatted for the section
	 *
	 * @param params
	 * @returns {AxiosPromise}
	 */
	async getIncompleteAssessmentSessionsForUser(params = null) {
		const incompleteAssessments = await api().get(`/me.incomplete-assessments`, {
			params: params,
		});

		const formattedIncompleteAssessments = {
			...incompleteAssessments,
			data: transformSessionsToOldFormat(incompleteAssessments.data.data),
		};
		return formattedIncompleteAssessments;
	},

	/**
	 *
	 * @param {Object} data
	 */
	endSession(data) {
		return api().post("/sessions.end", data);
	},
	hideSession(sessionId) {
		return api().put(`/sessions/${sessionId}/quarantine`);
	},
};
