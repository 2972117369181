var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        viewBox: "0 0 14 8",
        width: "14",
        height: "8",
        fill: "currentColor",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M7.70711 7.29289C7.31658 7.68342 6.68342 7.68342 6.29289 7.29289L0.707105 1.7071C0.0771405 1.07714 0.523309 -7.88755e-07 1.41421 -7.1087e-07L12.5858 2.6578e-07C13.4767 3.43665e-07 13.9229 1.07714 13.2929 1.70711L7.70711 7.29289Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }