import api from "./Api.js";

export default {
	/**
	 * creates and initiates handoff
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	create(data) {
		return api().post(`handoffs`, data);
	},

	createV1(data) {
		return api().post(`handoffs/v1`, data);
	},
	/**
	 * Gets other available tutors for
	 * handoff
	 * @param {*} params
	 */
	getTutorsForHandoff(params) {
		return api().get("tutors.scheduled", {
			params: params,
		});
	},

	getHandoffReasons() {
		return api().get(`handoffs/v1/reasons`);
	},
};
