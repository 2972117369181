import SubjectApi from "@/services/api/Subjects.js";
import TopicsAPI from "@/services/api/Topics.js";

import CoursesAPI from "../../services/courses.js";
import DistrictsAPI from "../../services/districts.js";
import SchoolsAPI from "../../services/schools.js";
import SectionsAPI from "../../services/sections.js";
import UsersAPI from "../../services/users.js";

const getDefaultState = () => {
	return {
		section: {},
		sectionUserIds: [],
		districts: [],
		errors: {},
		loading: true,
		loadingUsers: false,
		isSectionCreating: false,
		isErrorLoadingUsers: false,
		schools: [],
		schoolTeachers: [],
		schoolStudents: [],
		selectedTeachers: [],
		selectedStudents: [],
		subjects: [],
		districtId: -1,
		courses: [],
		topics: [],
		currentPage: 0,
		totalPages: 0,
		selectedCourseId: -1,
		isLoading: false,
		selectedTopicIds: [],
		totalCourses: 0,
		searchText: "",
		navbarMainTab: "Customers",
	};
};

const mutations = {
	SET_SECTION(state, payload) {
		state.section = payload.section;
	},
	SET_DISTRICTS(state, payload) {
		state.districts = payload.districts;
	},
	SET_SCHOOLS(state, payload) {
		state.schools = payload.schools;
	},
	SET_ERRORS(state, payload) {
		state.errors = payload.errors;
	},
	SET_SUBJECTS(state, payload) {
		state.subjects = payload.subjects;
	},
	SET_LOADING(state, payload) {
		state.loading = payload.loading;
	},
	SET_LOADING_USERS(state, payload) {
		state.loadingUsers = payload.loading;
	},
	SET_SECTION_USER_IDS(state, payload) {
		state.sectionUserIds = payload.sectionUserIds;
	},
	SET_SECTION_CREATING(state, payload) {
		state.isSectionCreating = payload.value;
	},
	SET_SCHOOL_STUDENTS(state, payload) {
		state.schoolStudents = payload.students;
	},
	SET_SCHOOL_TEACHERS(state, payload) {
		state.schoolTeachers = payload.teachers;
	},
	SET_SELECTED_STUDENTS(state, payload) {
		state.selectedStudents = payload.students;
	},
	SET_SELECTED_TEACHERS(state, payload) {
		state.selectedTeachers = payload.teachers;
	},
	SET_ERROR_INTERNAL_LOADING_USERS(state, payload) {
		state.isErrorLoadingUsers = payload.value;
	},
	SET_DISTRICT_ID(state, payload) {
		state.districtId = payload.districtId;
	},
	SET_COURSES(state, payload) {
		state.courses = payload.courses;
	},
	SET_TOPICS(state, payload) {
		state.topics = payload.topics;
	},
	SET_CURRENT_PAGE(state, payload) {
		state.currentPage = payload.currentPage;
	},
	SET_TOTAL_PAGES(state, payload) {
		state.totalPages = payload.totalPages;
	},
	SET_SELECTED_COURSE_ID(state, payload) {
		state.selectedCourseId = payload.selectedCourseId;
	},
	SET_IS_LOADING(state, payload) {
		state.isLoading = payload.isLoading;
	},
	SET_SELECTED_TOPIC_IDS(state, payload) {
		state.selectedTopicIds = payload.selectedTopicIds;
	},
	SET_TOTAL_COURSES(state, payload) {
		state.totalCourses = payload.totalCourses;
	},
	SET_SEARCH_TEXT(state, payload) {
		state.searchText = payload.searchText;
	},
};

const actions = {
	async setDistricts({ commit }, payload) {
		try {
			const districts = await DistrictsAPI.list(payload);
			districts.data.data.unshift({
				id: 0,
				name: "No District",
			});
			commit("SET_LOADING", {
				loading: false,
			});
			commit("SET_DISTRICTS", {
				districts: districts.data.data,
			});
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response);
		}
	},
	async setSubjects({ commit }, payload) {
		try {
			const subjects = await SubjectApi.list(payload);
			commit("SET_SUBJECTS", {
				subjects: subjects.data.data,
			});
			return Promise.resolve(subjects.data.data);
		} catch (error) {
			return Promise.reject(error.response);
		}
	},
	async setTopics({ commit }) {
		try {
			const response = await TopicsAPI.list();
			commit("SET_TOPICS", {
				topics: response.data.data,
			});
			return Promise.resolve(response);
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async setSchools({ commit }, payload) {
		try {
			const schools = await SchoolsAPI.list(payload);
			commit("SET_LOADING", {
				loading: false,
			});
			commit("SET_SCHOOLS", {
				schools: schools.data.data,
			});
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response);
		}
	},
	async setSchoolTeachersByFilter({ commit }, payload) {
		commit("SET_LOADING_USERS", {
			loading: true,
		});
		try {
			const users = await UsersAPI.filter({
				role: [7],
				school: [payload.schoolId],
				search: payload.term,
			});
			commit("SET_SCHOOL_TEACHERS", {
				teachers: users.data.data,
			});
			commit("SET_ERROR_INTERNAL_LOADING_USERS", {
				value: false,
			});

			return Promise.resolve();
		} catch (error) {
			if (error.response.status === 500) {
				commit("SET_ERROR_INTERNAL_LOADING_USERS", {
					value: true,
				});
			}
			return Promise.reject(error.response);
		} finally {
			commit("SET_LOADING_USERS", {
				loading: false,
			});
		}
	},
	async setSchoolStudentsByFilter({ commit }, payload) {
		commit("SET_LOADING_USERS", {
			loading: true,
		});
		try {
			const users = await UsersAPI.filter({
				role: [1],
				school: [payload.schoolId],
				search: payload.term,
			});
			commit("SET_SCHOOL_STUDENTS", {
				students: users.data.data,
			});
			commit("SET_ERROR_INTERNAL_LOADING_USERS", {
				value: false,
			});
			return Promise.resolve();
		} catch (error) {
			if (error.response.status === 500) {
				commit("SET_ERROR_INTERNAL_LOADING_USERS", {
					value: true,
				});
			}

			return Promise.reject(error.response);
		} finally {
			commit("SET_LOADING_USERS", {
				loading: false,
			});
		}
	},
	async createSection({ commit }, payload) {
		commit("SET_SECTION_CREATING", { value: true });
		try {
			const section = await SectionsAPI.create(payload.data);
			commit("SET_SECTION", { section: section.data.data });
			commit("SET_ERRORS", { errors: {} });
			return Promise.resolve();
		} catch (error) {
			commit("SET_ERRORS", { errors: error.response.data.error_description });
			return Promise.reject(error.response);
		} finally {
			commit("SET_SECTION_CREATING", { value: false });
		}
	},
	async setSelectedDistrictId({ commit, dispatch }, payload) {
		commit("SET_DISTRICT_ID", {
			districtId: payload.districtId,
		});
		try {
			const params = payload.districtId !== 0 ? { districts: [payload.districtId] } : {};
			const response = await dispatch("getCourses", params);
			return Promise.resolve(response);
		} catch (e) {
			Promise.reject(e.response);
		}
	},
	async getCourses({ commit }, payload) {
		try {
			const response = await CoursesAPI.list(payload);
			commit("SET_COURSES", {
				courses: response.data.data,
			});
			commit("SET_TOTAL_PAGES", {
				totalPages: response.data.meta.pagination.total_pages,
			});
			commit("SET_CURRENT_PAGE", {
				currentPage: response.data.meta.pagination.current_page,
			});
			commit("SET_TOTAL_COURSES", {
				totalCourses: response.data.meta.pagination.total,
			});
			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject(error.response);
		}
	},
	async setCurrentPage({ dispatch, state }, payload) {
		const params = {};
		const districtArray = [];
		if (state.districtId !== -1) {
			districtArray.push(state.districtId);
		}
		if (state.selectedTopicIds.length !== 0) {
			params["topics"] = state.selectedTopicIds;
		}
		if (state.searchText !== "") {
			params["search"] = state.searchText;
		}
		if (districtArray[0] !== null) {
			params["districts"] = districtArray;
		}
		params["page"] = payload.newPage;
		try {
			const response = await dispatch("getCourses", params);
			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject(error.response);
		}
	},
};

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations,
	actions,
};
