import { formatDistanceToNow } from "date-fns";

import SchedulesAPI from "@/services/api/Schedules.js";
import SubjectsAPI from "@/services/api/Subjects.js";
import SessionsAPI from "@/services/api/Sessions.js";
import EssaysAPI from "@/services/api/Essays.js";
import AccountAPI from "@/services/api/Account.js";
import {
	EVENTS,
	getSessionStartPayload,
	getTrackingParamsDataList,
	removeTrackingParamsData,
} from "@/utilities/trackRequests.js";
import { isEmbedded } from "@/modules/EmbeddedUtilities/index.js";

const getDefaultState = () => ({
	essays: [],
	undownloadedEssays: [],
	isAlreadyInSession: false,
	ongoingSessionData: {},
	sectionItems: [],
	subjectItems: [],
	responseMessage: "",
	buttonText: "",
	subject_id: 0,
	matchedQuestion: "",
	isQuestionMatcherUnableToMatch: false,
	isIncorrectSubjectShowing: false,
	isQuestionLoading: false,
	tutorId: 0,
});

const state = getDefaultState();

const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_ESSAYS(state, payload) {
		state.essays = payload.essays;
	},
	SET_ESSAYS_UNDOWNLOADED(state, payload) {
		state.undownloadedEssays = payload.undownloadedEssays;
	},
	SET_IS_ALREADY_IN_SESSION(state, payload) {
		state.isAlreadyInSession = payload.isAlreadyInSession;
	},
	SET_ONGOING_SESSION_DATA(state, payload) {
		state.ongoingSessionData = payload.ongoingSessionData;
	},
	SET_SECTION_ITEMS(state, payload) {
		state.sectionItems = payload.sectionItems;
	},
	SET_SUBJECT_ITEMS(state, payload) {
		state.subjectItems = payload.subjectItems;
	},
	SET_RESPONSE_MESSAGE(state, payload) {
		state.responseMessage = payload.responseMessage;
	},
	SET_BUTTON_AS_RESPONSE(state, payload) {
		state.buttonText = payload.buttonText;
	},
	SET_SUBJECT_ID(state, payload) {
		state.subject_id = payload.subject_id;
	},
	SET_MATCHED_QUESTION(state, payload) {
		state.matchedQuestion = payload.question;
	},
	SET_SUBJECTS(state, payload) {
		state.subjects = payload.subjects;
	},
	SET_IS_QUESTION_MATCHED(state, payload) {
		state.isQuestionMatcherUnableToMatch = payload.isQuestionMatcherUnableToMatch;
	},
	SET_IS_RIGHT_SUBJECT(state, payload) {
		state.isIncorrectSubjectShowing = payload.isIncorrectSubjectShowing;
	},
	SET_IS_QUESTION_LOADING(state, payload) {
		state.isQuestionLoading = payload.isQuestionLoading;
	},
	SET_TUTOR_ID(state, payload) {
		state.tutorId = payload.tutorId;
	},
};

const getters = {
};
const actions = {
	async submitQuestion({ dispatch, commit, state }, payload) {
		try {
			commit("SET_IS_QUESTION_LOADING", {
				isQuestionLoading: true,
			});

			const subjects = await SubjectsAPI.list();

			commit("SET_SUBJECTS", {
				subjects: subjects.data.data,
			});
			if (state.subjects.length === 0) {
				await dispatch("getAllSubjects");
			}

			const response = await SessionsAPI.getResultFromQuestionMatcher(payload);
			const subjectId = response.data.data.data.subject_id;
			const question = response.data.original;
			const subjectName = state.subjects.find((subject) => subject.id === subjectId);

			commit("SET_TUTOR_ID", {
				tutorId: response.data.data.data.tutor_ids[0],
			});

			commit("SET_SUBJECT_ID", {
				subject_id: subjectId,
			});
			commit("SET_MATCHED_QUESTION", {
				question,
			});
			commit("SET_RESPONSE_MESSAGE", {
				responseMessage: `This looks like a ${subjectName.name
				} question. Are you ready to chat with a tutor now?`,
			});
			commit("SET_BUTTON_AS_RESPONSE", {
				buttonText: `Yes, ask a ${subjectName.name} tutor`,
			});
			commit("SET_IS_RIGHT_SUBJECT", {
				isIncorrectSubjectShowing: true,
			});

			return Promise.resolve();
		} catch (e) {
			if (e.response?.status === 500) {
				commit("SET_RESPONSE_MESSAGE", {
					responseMessage: "Uh-oh! Something went wrong. Please try again.",
				});
			} else if (
				e.response?.status === 400 &&
				e.response?.data?.error_description?.detail?.next_available_timestamp === null &&
				e.response?.data?.error_description?.detail?.subject_id === null
			) {
				commit("SET_IS_QUESTION_MATCHED", {
					isQuestionMatcherUnableToMatch: true,
				});
			} else if (e.response?.status === 400) {
				const timeUntilTutor = formatDistanceToNow(
					new Date(e.response?.data?.error_description?.detail?.next_available_timestamp * 1000),
				);
				const subjectName = state.subjects.find(
					(subject) => subject.id == e.response?.data?.error_description?.detail?.subject_id,
				)?.name;
				commit("SET_RESPONSE_MESSAGE", {
					responseMessage: `This looks like a ${subjectName} question. Unfortunately, our tutor is only available in ${timeUntilTutor}. If you need any other help, select your class below.`,
				});

				commit("SET_IS_RIGHT_SUBJECT", {
					isIncorrectSubjectShowing: true,
				});
			}
			return Promise.reject(e);
		} finally {
			commit("SET_IS_QUESTION_LOADING", {
				isQuestionLoading: false,
			});
		}
	},
	async startSession({ }, payload) {
		try {
			const trackingParamsDataList = getTrackingParamsDataList([EVENTS.REQUEST_CHANNEL]);
			const params = await getSessionStartPayload(payload, trackingParamsDataList);

			await SessionsAPI.create(params);

			if (!isEmbedded() && trackingParamsDataList.length) {
				removeTrackingParamsData(trackingParamsDataList.map(({ key }) => key));
			}
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject(error);
		}
	},

	async getAllSubjects({ commit }) {
		try {
			const response = await SubjectsAPI.list();
			commit("SET_SUBJECTS", {
				subjects: response.data.data,
			});

			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject(error);
		}
	},

	async startSessionFromQuestionMatcher({ commit }, payload) {
		try {
			const trackingParamsDataList = getTrackingParamsDataList([EVENTS.REQUEST_CHANNEL]);
			const params = await getSessionStartPayload(payload, trackingParamsDataList);

			await SessionsAPI.create(params);

			if (!isEmbedded() && trackingParamsDataList.length) {
				removeTrackingParamsData(trackingParamsDataList.map(({ key }) => key));
			}
		} catch (error) {
			Sentry.captureException(error);
			commit("SET_RESPONSE_MESSAGE", {
				responseMessage: "Uh-Oh! An error occured while connecting you to your tutor.",
			});
			return Promise.reject(error);
		}
	},

	async getStudentSessionAvailabilities({ commit }, payload) {
		try {
			const response = await SchedulesAPI.getStudentSessionAvailabilities(payload);
			const sectionItems = [];
			const subjectItems = [];

			response.data.forEach((item) => {
				if (item.subject_id === null) {
					sectionItems.push(item);
				} else {
					subjectItems.push(item);
				}
			});

			commit("SET_SECTION_ITEMS", {
				sectionItems,
			});
			commit("SET_SUBJECT_ITEMS", {
				subjectItems,
			});

		} catch (error) {
			Promise.reject(error);
		}
	},

	/**
	 * get all essays belonging to the student
	 * and if so, extract the undownloaded ones
	 */
	async getEssays({ commit }) {
		try {
			const { data: { data } = [] } = await EssaysAPI.getStudentEssays();
			commit("SET_ESSAYS", { essays: data });
			commit("SET_ESSAYS_UNDOWNLOADED", { undownloadedEssays: data.filter((essay) => essay.status !== "downloaded") });
		} catch (e) {
			Sentry.captureException(e);
		}
	},

	/**
	 * Checks if a user has an ongoing tutoring session
	 * and if so, returns data for use in the view
	 */
	async checkOngoingSession({ commit, rootState }) {
		try {
			const response = await SessionsAPI.getActiveSessionsForUser(rootState.currentUser.id);
			if (response.status !== 200) {
				return;
			}
			let isInTutoringSession = false;
			Object.keys(response.data).forEach((key) => {
				if (response.data[key].type === "individual") {
					isInTutoringSession = true;
					commit("SET_ONGOING_SESSION_DATA", {
						ongoingSessionData: response.data[key],
					});
				}
			});
			commit("SET_IS_ALREADY_IN_SESSION", {
				isAlreadyInSession: isInTutoringSession,
			});
		} catch (e) {
			Sentry.captureException(e);
		}
	},

	async setAcceptedAgreement({ }, payload) {
		try {
			const response = await AccountAPI.updateProfile(payload);
			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject(error);
		}
	},
};


export default {
	namespaced: true,
	mutations,
	state,
	getters,
	actions,
};
