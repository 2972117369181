var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.name === "default"
    ? _c("IconStudent", {
        staticClass: "tw-p-2 tw-text-grey-regular",
        class: {
          "tw-animate-pulse": _vm.$store.state.Student.Profile.isLoadingProfile,
        },
      })
    : _c(_vm.componentName, {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "avatar",
            expression: "'avatar'",
          },
        ],
        tag: "component",
        attrs: {
          colors: _vm.avatarColors,
          "full-desc": _vm.fullDesc,
          "label-ids": _vm.componentIds,
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }