import middleware from "@/middleware/index.js";
import { doesStudentNeedOnboarding } from "@/modules/StudentOnboarding/index.js";

export default [
	{
		name: "studentLearnerProfile",
		path: "student/profile*",
		meta: {
			title: "My profile",
		},
		component: () => import("../pages/profile.vue"),
		beforeEnter: middleware([
			doesStudentNeedOnboarding,
		]),
	},
];
