var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 26 27",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("defs"),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Tutor-Dashboard",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Tutor-Schedule-:-Transfer-Shift-:-Shift-Expired",
                transform: "translate(-388.000000, -200.000000)",
                fill: _vm.iconColor,
              },
            },
            [
              _c("path", {
                attrs: {
                  id: "Combined-Shape",
                  d: "M402.805755,212.684435 L405.305895,210.184295 C405.895048,209.595142 405.891189,208.649253 405.305402,208.063467 C404.715532,207.473596 403.770089,207.47746 403.184575,208.062974 L400.684435,210.563114 L398.025407,207.904087 C397.439985,207.318664 396.491118,207.319545 395.905332,207.905332 C395.315461,208.495202 395.318988,209.440308 395.904087,210.025407 L398.563114,212.684435 L396.062974,215.184575 C395.473821,215.773727 395.47768,216.719616 396.063467,217.305402 C396.653337,217.895273 397.59878,217.891409 398.184295,217.305895 L400.684435,214.805755 L403.343462,217.464783 C403.928885,218.050205 404.877751,218.049324 405.463538,217.463538 C406.053408,216.873667 406.049881,215.928561 405.464783,215.343462 L402.805755,212.684435 Z M400.805397,200 C393.733572,200 388,205.825881 388,213.013596 C388,220.2004 393.733572,226.027193 400.805397,226.027193 C407.878119,226.027193 413.610794,220.2004 413.610794,213.013596 C413.610794,205.825881 407.878119,200 400.805397,200 Z",
                },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }