<template>
	<div class="col-md-12 section__divider tw-p-0 tw-pt-6 tw-mb-14">
		<h3 class="tw-font-extrabold tw-text-black-high tw-text-2xl tw-mb-6">
			Expertise Details
		</h3>
		<form
			v-jest="'submit-expertise-form'"
			@submit.prevent="submitDetails"
		>
			<div class="tw-flex tw-flex-col tw-w-max tw-max-w-max">
				<h4 class="tw-text-lg tw-text-black-high tw-text-left tw-w-full tw-font-bold tw-mb-7">
					Eligible For
				</h4>
				<div class="tw-flex tw-mb-7">
					<p
						class="tw-text-sm tw-leading-6 tw-pr-9 tw-text-left tw-flex-grow"
					>
						Classroom Text Messages
					</p>
					<div class="tw-flex tw-gap-x-9">
						<BaseRadio
							v-jest="'classroom-yes-radio'"
							class="expertiseDetails__radioButton--maxWidth tw-w-14"
							:class="tutorProfile.can_receive_sync_chat_sessions ? 'tw-font-bold' : ''"
							radio-id="canTutorClassroom-yes"
							radio-name="canTutorClassroom-yes"
							:radio-value="true"
							:value="tutorProfile.can_receive_sync_chat_sessions"
							@click.native="
								setTutorProfileProperty({ can_receive_sync_chat_sessions: true })
							"
						>
							<template slot="text">
								Yes
							</template>
						</BaseRadio>
						<BaseRadio
							v-jest="'classroom-no-radio'"
							class="expertiseDetails__radioButton--maxWidth tw-w-14"
							:class="tutorProfile.can_receive_sync_chat_sessions ? '' : 'tw-font-bold'"
							radio-id="canTutorClassroom-no"
							radio-name="canTutorClassroom-no"
							:radio-value="false"
							:value="tutorProfile.can_receive_sync_chat_sessions"
							@click.native="
								setTutorProfileProperty({ can_receive_sync_chat_sessions: false })
							"
						>
							<template slot="text">
								No
							</template>
						</BaseRadio>
					</div>
				</div>

				<div class="tw-flex tw-mb-7 tw-justify-between">
					<p class="tw-text-sm tw-leading-6 tw-pr-9">
						Writing Review
					</p>
					<div class="tw-flex tw-gap-x-9">
						<BaseRadio
							v-jest="'essay-yes-radio'"
							class="expertiseDetails__radioButton--maxWidth tw-w-14"
							:class="tutorProfile.essay_review ? 'tw-font-bold' : ''"
							radio-id="essayReview-yes"
							radio-name="essayReview-yes"
							:radio-value="true"
							:value="tutorProfile.essay_review"
							@click.native="
								setTutorProfileProperty({ essay_review: true })
							"
						>
							<template slot="text">
								Yes
							</template>
						</BaseRadio>
						<BaseRadio
							v-jest="'essay-no-radio'"
							class="expertiseDetails__radioButton--maxWidth tw-w-14"
							:class="tutorProfile.essay_review ? '' : 'tw-font-bold'"
							radio-id="essayReview-no"
							radio-name="essayReview-no"
							:radio-value="false"
							:value="tutorProfile.essay_review"
							@click.native="
								setTutorProfileProperty({ essay_review: false })
							"
						>
							<template slot="text">
								No
							</template>
						</BaseRadio>
					</div>
				</div>
				<div class="tw-flex tw-mb-7 tw-justify-between">
					<p class="tw-text-sm tw-leading-6 tw-pr-9">
						California Tutoring <br> (LAUSD Fingerprint)
					</p>
					<div class="tw-flex tw-gap-x-9">
						<BaseRadio
							v-jest="'us-ca-fingerprinted-yes-radio'"
							class="expertiseDetails__radioButton--maxWidth tw-w-14"
							:class="tutorProfile.is_us_ca_fingerprinted ? 'tw-font-bold' : ''"
							radio-id="isUsCaFingerprinted-yes"
							radio-name="isUsCaFingerprinted-yes"
							:radio-value="true"
							:value="tutorProfile.is_us_ca_fingerprinted"
							@click.native="
								setTutorProfileProperty({ is_us_ca_fingerprinted: true })
							"
						>
							<template slot="text">
								Yes
							</template>
						</BaseRadio>
						<BaseRadio
							v-jest="'us-ca-fingerprinted-no-radio'"
							class="expertiseDetails__radioButton--maxWidth tw-w-14"
							:class="tutorProfile.is_us_ca_fingerprinted ? '' : 'tw-font-bold'"
							radio-id="isUsCaFingerprinted-no"
							radio-name="isUsCaFingerprinted-no"
							:radio-value="false"
							:value="tutorProfile.is_us_ca_fingerprinted"
							@click.native="
								setTutorProfileProperty({ is_us_ca_fingerprinted: false })
							"
						>
							<template slot="text">
								No
							</template>
						</BaseRadio>
					</div>
				</div>
				<div
					v-if="featureFlagApsApproved"
					v-jest="'is-us-nm-aps-background-selections'"
					class="tw-flex tw-mb-7 tw-justify-between"
				>
					<p class="tw-text-sm tw-leading-6 tw-pr-9">
						APS Approved <br> (Albuquerque Background Check)
					</p>
					<div class="tw-flex tw-gap-x-9">
						<BaseRadio
							v-jest="'is-us-nm-aps-background-yes-radio'"
							class="expertiseDetails__radioButton--maxWidth tw-w-14"
							:class="tutorProfile.is_us_nm_aps_background_checked ? 'tw-font-bold' : ''"
							radio-id="isUsNmApsBackgroundChecked-yes"
							radio-name="isUsNmApsBackgroundChecked-yes"
							:radio-value="true"
							:value="tutorProfile.is_us_nm_aps_background_checked"
							@click.native="
								setTutorProfileProperty({ is_us_nm_aps_background_checked: true })
							"
						>
							<template slot="text">
								Yes
							</template>
						</BaseRadio>
						<BaseRadio
							v-jest="'is-us-nm-aps-background-no-radio'"
							class="expertiseDetails__radioButton--maxWidth tw-w-14"
							:class="tutorProfile.is_us_nm_aps_background_checked ? '' : 'tw-font-bold'"
							radio-id="isUsNmApsBackgroundChecked-no"
							radio-name="isUsNmApsBackgroundChecked-no"
							:radio-value="false"
							:value="tutorProfile.is_us_nm_aps_background_checked"
							@click.native="
								setTutorProfileProperty({ is_us_nm_aps_background_checked: false })
							"
						>
							<template slot="text">
								No
							</template>
						</BaseRadio>
					</div>
				</div>
				<div
					v-jest="'audio-profile'"
					class="tw-flex tw-mb-7 tw-justify-between"
				>
					<p class="tw-text-sm tw-leading-6 tw-pr-9">
						Classroom Voice Messages
					</p>
					<div class="tw-flex tw-gap-x-9">
						<BaseRadio
							v-jest="'audio-yes-radio'"
							class="expertiseDetails__radioButton--maxWidth tw-w-14"
							:class="{ 'tw-font-bold': tutorProfile.can_receive_async_audio_sessions }"
							radio-id="audio-yes"
							radio-name="audio-yes"
							:radio-value="true"
							:value="tutorProfile.can_receive_async_audio_sessions"
							@click.native="
								setTutorProfileProperty({ can_receive_async_audio_sessions: true })
							"
						>
							<template slot="text">
								Yes
							</template>
						</BaseRadio>
						<BaseRadio
							v-jest="'audio-no-radio'"
							class="expertiseDetails__radioButton--maxWidth tw-w-14"
							:class="{ 'tw-font-bold': !tutorProfile.can_receive_async_audio_sessions }"
							radio-id="audio-no"
							radio-name="audio-no"
							:radio-value="false"
							:value="tutorProfile.can_receive_async_audio_sessions"
							@click.native="
								setTutorProfileProperty({ can_receive_async_audio_sessions: false })
							"
						>
							<template slot="text">
								No
							</template>
						</BaseRadio>
					</div>
				</div>
			</div>
			<div class="tw-flex tw-flex-col tw-w-max tw-max-w-max">
				<h4 class="tw-text-lg tw-text-black-high tw-text-left tw-w-full tw-font-bold tw-mb-7">
					Language
				</h4>
				<div class="tw-flex tw-items-center tw-justify-end tw-w-full tw-flex-wrap tw-mb-7">
					<p
						class="tw-flex tw-flex-grow tw-text-sm tw-leading-6 tw-text-right tw-pr-9"
						for="last_name"
					>
						Tutoring Language
					</p>
					<div class="tw-flex tw-gap-x-9">
						<div
							v-for="language in PlatformManager.TutorsEdit.tutorLanguages"
							:key="language.id"
							class="expertiseDetails__languageCheckbox"
						>
							<div class="checkbox">
								<BaseCheckbox
									v-cloak
									v-model="selectedTutorLanguages"
									v-jest="`tutor-language-${language.id}`"
									name="languages"
									class="topic topicCheckbox"
									:class="selectedTutorLanguages.includes(language.id) ? 'checkbox__boldLabel' : ''"
									:checkbox-value="language.id"
									:value="language.id"
									:checkbox-id="`tutorLanguage-${language.id}`"
								>
									<template
										slot="text"
									>
										{{ language.language }}
									</template>
								</BaseCheckbox>
							</div>
						</div>
					</div>
				</div>
				<div class="tw-flex tw-items-center tw-justify-end tw-w-full tw-flex-wrap tw-mb-7">
					<p
						class="tw-flex-grow tw-text-sm tw-leading-6 tw-pr-9"
						for="last_name"
					>
						Writing Review Language
					</p>
					<div class="tw-flex tw-gap-x-9">
						<div
							v-for="language in PlatformManager.TutorsEdit.essayLanguages"
							:key="language.id"
							class="expertiseDetails__languageCheckbox"
						>
							<div class="checkbox">
								<BaseCheckbox
									v-cloak
									v-model="selectedEssayLanguages"
									v-jest="`essay-language-${language.id}`"
									name="languages"
									class="topic topicCheckbox"
									:class="selectedEssayLanguages.includes(language.id) ? 'checkbox__boldLabel' : ''"
									:checkbox-value="language.id"
									:value="language.id"
									:checkbox-id="`essayLanguage-${language.id}`"
								>
									<template
										slot="text"
									>
										{{ language.language }}
									</template>
								</BaseCheckbox>
							</div>
						</div>
					</div>
				</div>

				<div
					v-jest="'audio-languages'"
					class="tw-flex tw-items-center tw-justify-end tw-w-full tw-flex-wrap tw-mb-7"
				>
					<p
						class="tw-flex-grow tw-text-sm tw-leading-6 tw-pr-9"
						for="last_name"
					>
						Voice Message Language
					</p>
					<div class="tw-flex tw-gap-x-9">
						<div
							v-for="language in PlatformManager.TutorsEdit.audioLanguages"
							:key="language.id"
							class="expertiseDetails__languageCheckbox"
						>
							<div class="checkbox">
								<BaseCheckbox
									v-cloak
									v-model="selectedAudioLanguages"
									v-jest="`audio-language-${language.id}`"
									name="languages"
									class="topic topicCheckbox"
									:class="{ 'checkbox__boldLabel': selectedAudioLanguages.includes(language.id) }"
									:checkbox-value="language.id"
									:value="language.id"
									:checkbox-id="`audioLanguages-${language.id}`"
								>
									<template
										slot="text"
									>
										{{ language.language }}
									</template>
								</BaseCheckbox>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<details
					v-jest="'academic-units-toggle'"
					@toggle="isAcademicUnitsOpen = !isAcademicUnitsOpen"
				>
					<summary class="tw-block tw-mb-7 tw-w-full">
						<h4 class="tw-text-lg tw-text-black-high tw-text-left tw-font-bold tw-flex tw-items-center">
							Academic Units
							<span
								v-if="PlatformManager.TutorsEdit.tutor"
								v-jest="'academic-units-number'"
								class="tw-text-grey-dark"
							>&nbsp;({{ PlatformManager.TutorsEdit.tutor.tutor.data.subjects.data.length }})</span>
							<IconCaret
								class="tw-text-black-high tw-h-4 tw-ml-3"
								:style="isAcademicUnitsOpen ? 'transform: rotate(90deg)' : ''"
							/>
						</h4>
					</summary>
					<ServiceTutorEditShowTags ref="tutorTags" />
				</details>
			</div>
			<div class="form-group tw-overflow-auto">
				<BaseButton
					v-jest="'submit-expertise-button'"
					type="SUBMIT"
					class="pull-right tw-mt-6 tw-w-48"
					:disabled="!isSubmitExpertiseDetailsButtonEnabled"
				>
					<template
						slot="text"
					>
						<LoadingRectangles
							v-if="isSubmitInProgress"
							class="tw-text-blue-regular"
						/>
						<span
							v-else
						>
							Submit Expertise Details
						</span>
					</template>
				</BaseButton>
			</div>
			<div
				v-if="isShowingUpdateSuccess"
				class="alert alert-success tw-truncate"
			>
				Expertise details were updated successfully!
			</div>
			<div
				v-if="isShowingUpdateError"
				v-jest="'error-message'"
				class="alert alert-danger tw-truncate"
			>
				{{ submitProfileError }}
			</div>
		</form>
	</div>
</template>

<script>
import { mapState } from "vuex";

import IconCaret from "@/components/icons/IconCaret.vue";
import LoadingRectangles from "@/components/LoadingRectangles.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import BaseRadio from "@/components/BaseRadio.vue";

import ServiceTutorEditShowTags from "./ServiceTutorEditShowTags.vue";

const isLanguageArrayUpdated = (arr1, arr2) => {
	const isAnyLanguageAdded = arr1.some((lang) => !arr2.includes(lang));
	const isAnyLanguageRemoved = arr2.some((lang) => !arr1.includes(lang));
	return isAnyLanguageAdded || isAnyLanguageRemoved;
};
export default {
	components: {
		ServiceTutorEditShowTags,
		IconCaret,
		LoadingRectangles,
		BaseButton,
		BaseCheckbox,
		BaseRadio,
	},
	data() {
		return {
			tutorProfile: {},
			tutorProfileOnLoad: {},
			selectedTutorLanguages: [],
			selectedEssayLanguages: [],
			selectedAudioLanguages: [],
			isLanguageSubmissionInProgress: false,
			tutorLanguagesOnLoad: [],
			essayLanguagesOnLoad: [],
			audioLanguagesOnLoad: [],
			isShowingUpdateSuccess: false,
			isShowingUpdateError: false,
			isAcademicUnitsOpen: false,
			isSubmitInProgress: false,
			submitProfileError: "",
			featureFlagApsApproved: false,
		};
	},
	computed: {
		...mapState([
			"PlatformManager",
		]),
		isEssayLanguagesUpdated() {
			return isLanguageArrayUpdated(this.selectedEssayLanguages, this.essayLanguagesOnLoad);
		},
		isTutorLanguagesUpdated() {
			return isLanguageArrayUpdated(this.selectedTutorLanguages, this.tutorLanguagesOnLoad);
		},
		isAudioMessageLanguagesUpdated() {
			return isLanguageArrayUpdated(this.selectedAudioLanguages, this.audioLanguagesOnLoad);
		},
		isLanguagesUpdated() {
			return this.isEssayLanguagesUpdated || this.isTutorLanguagesUpdated || this.isAudioMessageLanguagesUpdated;
		},
		isLanguagesEmpty() {
			return (this.tutorProfile.can_receive_sync_chat_sessions && this.selectedTutorLanguages.length === 0) ||
				(this.tutorProfile.essay_review && this.selectedEssayLanguages.length === 0) ||
				(this.tutorProfile.can_receive_async_audio_sessions && this.selectedAudioLanguages.length === 0);
		},
		isTutorProfileUpdated() {
			return [
				"can_receive_sync_chat_sessions",
				"essay_review",
				"is_us_ca_fingerprinted",
				"is_us_nm_aps_background_checked",
				"can_receive_async_audio_sessions",
			].some((key) => this.tutorProfileOnLoad[key] !== this.tutorProfile[key]);
		},
		isTagsTableUpdated() {
			return this.PlatformManager.TutorsEdit.isTagsUpdated;
		},
		isAnythingUpdated() {
			return this.isLanguagesUpdated ||
                this.isTutorProfileUpdated ||
                this.isTagsTableUpdated;
		},
		isSubmitExpertiseDetailsButtonEnabled() {
			const isEnabled = this.isAnythingUpdated && !this.isSubmitInProgress && !this.isLanguagesEmpty;
			this.$emit("on-expertise-submit", { isEnabled });
			return isEnabled;
		},
	},
	async created() {
		this.tutorProfile = this.PlatformManager.TutorsEdit.tutor.tutor.data;
		this.tutorProfileOnLoad = this.tutorProfile;
		this.PlatformManager.TutorsEdit.tutor.tutor.data.languages.data.forEach((language) => {
			this.selectedTutorLanguages.push(language.id);
		});
		this.PlatformManager.TutorsEdit.tutor.tutor.data.essayLanguages.data.forEach((language) => {
			this.selectedEssayLanguages.push(language.id);
		});
		this.PlatformManager.TutorsEdit.tutor.tutor.data.asyncAudioSessionLanguages.data.forEach((language) => {
			this.selectedAudioLanguages.push(language.id);
		});
		this.tutorLanguagesOnLoad = [...this.selectedTutorLanguages];
		this.essayLanguagesOnLoad = [...this.selectedEssayLanguages];
		this.audioLanguagesOnLoad = [...this.selectedAudioLanguages];

		this.featureFlagApsApproved = await this.$getFlag("PE-20588-APS-Approved");
	},
	methods: {
		async submitDetails() {
			this.isSubmitInProgress = true;

			const {
				profileInfoToUpdateFirst,
				languagesToUpdateFirst,
				profileInfoToUpdateSecond,
				languagesToUpdateSecond,
			} = this.determineRequestOrder();

			try {
				const tags = this.PlatformManager.TutorsEdit.isTagsUpdated;
				const firstRequests = this.organizeRequestArray(languagesToUpdateFirst, profileInfoToUpdateFirst, tags);
				await Promise.all(firstRequests);
				this.tutorProfileOnLoad = this.PlatformManager.TutorsEdit.tutor.tutor.data;

				const secondRequests = this.organizeRequestArray(languagesToUpdateSecond, profileInfoToUpdateSecond);
				await Promise.all(secondRequests);

				this.tutorProfileOnLoad = this.PlatformManager.TutorsEdit.tutor.tutor.data;
				this.isShowingUpdateError = false;
				this.isShowingUpdateSuccess = true;
			} catch (e) {
				this.isShowingUpdateSuccess = false;
				this.isShowingUpdateError = true;
				this.determineErrorMessage(e);
			} finally {
				this.$store.commit("PlatformManager/TutorsEdit/SET_IS_TAGS_UPDATED", { value: false });
				this.isSubmitInProgress = false;
			}
		},
		async submitLanguages(languages) {
			const languagesOnStart = {
				selectedTutorLanguages: [...this.selectedTutorLanguages],
				selectedEssayLanguages: [...this.selectedEssayLanguages],
				selectedAudioLanguages: [...this.selectedAudioLanguages],
			};
			const payload = {};
			if (languages.includes("Tutor")) {
				payload.languages = this.selectedTutorLanguages;
			} else {
				payload.languages = this.tutorLanguagesOnLoad;
			}
			if (languages.includes("Essay")) {
				payload.essayLanguages = this.selectedEssayLanguages;
			} else {
				payload.essayLanguages = this.essayLanguagesOnLoad;
			}
			payload.asyncAudioSessionLanguages = languages.includes("Audio") ? this.selectedAudioLanguages : this.audioLanguagesOnLoad;
			if (Object.keys(payload).length) {
				await this.$store.dispatch("PlatformManager/TutorsEdit/updateLanguages", payload);
			}
			["Tutor", "Essay", "Audio"].forEach((item) => {
				if (languages.includes(item)) {
					this[`${item.toLowerCase()}LanguagesOnLoad`] = [...languagesOnStart[`selected${item}Languages`]];
				}
			});
			return Promise.resolve();
		},
		async submitProfileInfo(areas) {
			const payload = {};
			["essay_review", "can_receive_sync_chat_sessions", "can_receive_async_audio_sessions"].forEach((item) => {
				if (areas.includes(item)) {
					payload[item] = this.tutorProfile[item];
				}
			});
			if (this.tutorProfile.is_us_ca_fingerprinted !== this.tutorProfileOnLoad.is_us_ca_fingerprinted) {
				payload.is_us_ca_fingerprinted = this.tutorProfile.is_us_ca_fingerprinted;
			}
			if (this.tutorProfile.is_us_nm_aps_background_checked !==
				this.tutorProfileOnLoad.is_us_nm_aps_background_checked) {
				payload.is_us_nm_aps_background_checked = this.tutorProfile.is_us_nm_aps_background_checked;
			}
			if (Object.keys(payload).length) {
				await this.$store.dispatch("PlatformManager/TutorsEdit/updateTutor", payload);
			}
			return Promise.resolve();
		},
		async submitSubjectTags() {
			const conceptIds = [];
			const topicIds = [];
			const params = {};
			this.PlatformManager.TutorsEdit.selectedTopicIds.forEach((topic) => {
				if (typeof topic !== "string") {
					topicIds.push(topic);
				}
			});
			this.PlatformManager.TutorsEdit.selectedConceptIds.forEach((concept) => {
				if (!concept.includes("allConcepts-")) {
					conceptIds.push(parseInt(concept.replace("concept-", "")));
				}
			});
			params["subjects"] = this.PlatformManager.TutorsEdit.selectedSubjectIds;
			params["topics"] = topicIds;
			params["concepts"] = conceptIds;
			if (params !== {}) {
				try {
					await this.$store.dispatch("PlatformManager/TutorsEdit/updateTutor", params);
				} catch (e) {
					Sentry.captureException(e);
					this.$store.dispatch("SimpleModal/configureSimpleModal", {
						buttonText: "okay",
						message: `there_was_error`,
						title: "error",
						buttonType: "PRIMARY",
					});
					this.$bvModal.show("coursesSimpleModal");
				}
			}
			this.$refs.tutorTags.isEditing = false;
			return Promise.resolve();
		},
		setTutorProfileProperty(property) {
			this.tutorProfile = {
				...this.tutorProfile,
				...property,
			};
		},
		/*
			Because the backend requires a language to be selected in order to
			enable classroom/essay review, and because we can not remove languages when the
			classroom/essay review are enabled, we need to make the update requests in a specific
			order (languages first vs. profile info first) to make sure requests are completed successfully
		*/
		determineRequestOrder() {
			const profileInfoToUpdateFirst = [];
			const languagesToUpdateFirst = [];
			const profileInfoToUpdateSecond = [];
			const languagesToUpdateSecond = [];

			this.tutorProfileOnLoad.can_receive_sync_chat_sessions ?
				profileInfoToUpdateFirst.push("can_receive_sync_chat_sessions") :
				profileInfoToUpdateSecond.push("can_receive_sync_chat_sessions");
			this.tutorProfileOnLoad.essay_review ?
				profileInfoToUpdateFirst.push("essay_review") :
				profileInfoToUpdateSecond.push("essay_review");
			this.tutorProfileOnLoad.can_receive_async_audio_sessions ?
				profileInfoToUpdateFirst.push("can_receive_async_audio_sessions") :
				profileInfoToUpdateSecond.push("can_receive_async_audio_sessions");
			this.tutorLanguagesOnLoad.length ?
				languagesToUpdateSecond.push("Tutor") :
				languagesToUpdateFirst.push("Tutor");
			this.essayLanguagesOnLoad.length ?
				languagesToUpdateSecond.push("Essay") :
				languagesToUpdateFirst.push("Essay");
			this.audioLanguagesOnLoad.length ?
				languagesToUpdateSecond.push("Audio") :
				languagesToUpdateFirst.push("Audio");
			return {
				profileInfoToUpdateFirst,
				languagesToUpdateFirst,
				profileInfoToUpdateSecond,
				languagesToUpdateSecond,
			};
		},
		organizeRequestArray(languages, profileInfo, tags = false) {
			const languageResponse = languages.length ?
				this.submitLanguages(languages) :
				Promise.resolve();
			const profileInfoResponse = profileInfo.length ?
				this.submitProfileInfo(profileInfo) :
				Promise.resolve();
			const tagsResponse = tags ?
				this.submitSubjectTags() :
				Promise.resolve();
			return [
				languageResponse,
				profileInfoResponse,
				tagsResponse,
			];
		},
		determineErrorMessage(error) {
			const defaultMessage = "There was a problem updating expertise details. Please try again.";
			const { data, status } = error.response;
			switch (status) {
				case 400:
					this.submitProfileError = data.error_description ?? defaultMessage;
					break;
				case 422:
					this.submitProfileError = defaultMessage;
					break;
				default:
					Sentry.captureException(error);
					this.submitProfileError = defaultMessage;
					break;
			}
		},
	},
};
</script>
<style scoped>
.section__divider {
	border-top: 1px solid var(--black-high);
}
.expertiseDetails__radioButton--maxWidth {
	max-width: 3.5rem;
}
.expertiseDetails__languageCheckbox {
	width: 5.25rem;
	max-width: 5.25rem;
}
</style>
<style>
.checkbox__boldLabel .text p {
	font-weight: 700 !important;
}
</style>
