<template>
	<ListItemLayout
		class="tw--mt-1 tw-mb-1 tw-bg-origami-grey-100 tw-border-origami-grey-100"
	>
		<template #content>
			<div class="tw-flex tw-pl-6">
				<p
					v-jest="'item-content-question'"
					class="
						ellipsis tw-overflow-hidden
						tw-break-word tw-leading-6 tw-tracking-wide
					"
				>
					Are you sure you want to delete
					<span class="tw-font-bold">{{ groupName }}</span>
					?
				</p>
			</div>
		</template>

		<template #actions>
			<div class="tw-mr-6">
				<div
					v-if="!isDeleting"
					class="tw-flex tw-flex-row"
				>
					<BaseButton
						v-jest="'cancel-button'"
						class="tw-mr-2"
						:variants="['outline', 'mini']"
						aria-label="Cancel group deletion"
						@click.native="$emit('cancel-delete')"
					>
						Cancel
					</BaseButton>

					<BaseButton
						v-jest="'delete-button'"
						color="red"
						:variants="['full', 'mini']"
						aria-label="Confirm group deletion"
						@click.native="deleteGroup"
					>
						Delete Group
					</BaseButton>
				</div>

				<div v-else>
					<BaseLoader class="tw-h-8 tw-w-8 tw-mr-2 tw-text-blue-regular" />
				</div>
			</div>
		</template>
	</ListItemLayout>
</template>

<script>
import BaseButton from "../../../../components/elements/BaseButton.vue";
import BaseLoader from "../../../../components/elements/BaseLoader.vue";

import ListItemLayout from "./ListItemLayout.vue";

export default {
	components: {
		BaseButton,
		BaseLoader,
		ListItemLayout,
	},
	props: {
		groupId: {
			type: Number,
			required: true,
		},
		groupName: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			isDeleting: false,
		};
	},
	methods: {
		async deleteGroup() {
			this.isDeleting = true;

			try {
				const success = await this.$store.dispatch("StudentGroups/deleteGroup", this.groupId);

				if (success) {
					this.$store.dispatch("Toaster/showToaster", {
						text: `Group "${this.groupName}" was deleted.`,
					});
					await this.$store.dispatch("StudentGroups/getGroups");
					await this.$store.dispatch("SiteAdministrator/StudentList/StudentFilters/getFiltersData");
				} else {
					this.$store.dispatch("Toaster/showToaster", {
						text: `Deletion of group "${this.groupName}" failed.`,
					});
				}
			} catch (e) {
				this.$store.dispatch("Toaster/showToaster", {
					text: `Deletion of group "${this.groupName}" failed due to ${e}.`,
				});
			} finally {
				this.isDeleting = false;
			}
		},
	},
};
</script>

<style scoped>
.ellipsis {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
</style>
