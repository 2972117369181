<template>
	<component
		:is="componentName"
		:height="dimension"
		:width="dimension"
		:icon-color="iconColor"
	/>
</template>
<script>
import Colors from "../../../utilities/Colors.js";
export default {
	components: {
		IconShiftTutoringSession: () => import("./icons/IconShiftTutoringSession.vue"),
		IconShiftWritingReview: () => import("./icons/IconShiftEssayReview.vue"),
		IconShiftEssayReview: () => import("./icons/IconShiftEssayReview.vue"),
		IconShiftTutorManager: () => import("./icons/IconShiftTutorManager.vue"),
		IconShiftTestSession: () => import("./icons/IconShiftTestSession.vue"),
		IconShiftTraining: () => import("./icons/IconShiftTraining.vue"),
		IconShiftOnboarding: () => import("./icons/IconShiftOnboarding.vue"),
		IconShiftAssessment: () => import("./icons/IconShiftAssessment.vue"),
		IconShiftPayAdjustment: () => import("./icons/IconShiftPayAdjustment.vue"),
		IconShiftSpecialProject: () => import("./icons/IconShiftSpecialProject.vue"),
		IconShiftMeeting: () => import("./icons/IconShiftMeeting.vue"),
	},
	props: {
		title: {
			type: String,
			required: true,
			default: "",
			validator(value) {
				return [
					"Tutoring Session",
					"Writing Review",
					"Essay Review",
					"Tutor Manager",
					"Test Session",
					"Training",
					"Onboarding",
					"Assessment",
					"Pay Adjustment",
					"Special Project",
					"Meeting",
				].includes(value);
			},
		},
		dimension: {
			type: [Number, String],
			required: true,
			default: 48,
		},
		iconColor: {
			type: String,
			default: Colors["C_LIGHT_GREEN"],
		},
	},
	computed: {
		/**
		 * @returns {String}
		 */
		componentName() {
			return "IconShift" + this.title.replace(/\s+/g, "");
		},
	},
};
</script>
