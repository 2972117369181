var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 14 12",
      },
    },
    [
      _c("path", {
        attrs: {
          fill: _vm.iconColor,
          "fill-rule": "evenodd",
          d: "M8.556 3.2V0L14 5.6l-5.444 5.6V7.92C4.666 7.92 1.944 9.2 0 12c.778-4 3.111-8 8.556-8.8z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }