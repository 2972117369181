var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 4 22",
      },
    },
    [
      _c("g", { attrs: { fill: _vm.iconColor, "fill-rule": "nonzero" } }, [
        _c("path", { attrs: { d: "M4 22H0v-4h4zM4 15H0V0h4z" } }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }