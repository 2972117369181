import hasRole from "@/middleware/hasRole.js";
import hasSchool from "@/middleware/hasSchool.js";
import initializePendo from "@/middleware/initializePendo.js";
import initializeZendesk from "@/middleware/initializeZendesk.js";
import initFullStory from "@/middleware/initFullStory.js";
import isConnected from "@/middleware/isConnected.js";
import middleware from "@/middleware/index.js";
import store from "@/store/index.js";
import { teacherStudentsRoutes } from "@/modules/TeacherStudentsList/index.js";
import { MICRO_FRONTENDS_NAMES } from "@/modules/MicroFrontend/utilities/index.js";

export default [
	{
		path: "/teacher",
		component: () => import("../pages/teacher/index.vue"),
		beforeEnter: middleware([
			isConnected,
			hasRole("teacher"),
			hasSchool,
			initializePendo,
			initializeZendesk,
			initFullStory,
			(to, from, next) => {
				store.commit("SET_SHOW_NAVBAR", true);
				next();
			},
		]),
		children: [
			{
				path: "",
				redirect: "/teacher/dashboard",
				component: () => import("@/modules/TeacherStudentsList/pages/index.vue"),
			},
			{
				path: "dashboard*",
				name: "teacherDashboard",
				component: () => import("@/modules/MicroFrontend/components/MicroFrontend.vue"),
				props: {
					microFrontendName: MICRO_FRONTENDS_NAMES.TEACHER_DASHBOARD,
					containerClass: "tw-bg-origami-white tw-min-h-[var(--height-screen-without-header)]",
				},
			},
			{
				path: "activity",
				name: "teacherActivity",
				component: () => import("@/modules/Activity/pages/teacher-activity.vue"),
			},
			{
				name: "teacherActivityDashboard",
				path: "activity_mfe",
				component: () => import("@/modules/MicroFrontend/components/MicroFrontend.vue"),
				props: {
					microFrontendName: MICRO_FRONTENDS_NAMES.ACTIVITY_DASHBOARD,
				},
			},
			{
				path: "competition",
				name: "teacherCompetition",
				component: () => import("../pages/teacher/competition/index.vue"),
			},
			{
				path: "past-competitions",
				name: "teacherPastCompetitions",
				component: () => import("../pages/teacher/competition/past.vue"),
			},
			{
				path: "settings",
				name: "teacherSettings",
				component: () => import("@/modules/UserSettings/pages/teacher-settings.vue"),
			},
			...teacherStudentsRoutes,
		],
	},
];
