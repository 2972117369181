var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BModal",
    {
      ref: "service-schedule-edit-shift-modal",
      attrs: {
        id: "service-schedule-edit-shift-modal",
        "hide-header": true,
        "no-close-on-backdrop": true,
        "no-fade": true,
        centered: true,
        size: "sm",
      },
      nativeOn: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.hideModal()
        },
      },
    },
    [
      _c(
        "button",
        {
          staticClass: "close editShift__closeBtn",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.hideModal()
            },
          },
        },
        [_vm._v("\n\t\t×\n\t")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "editShift__header" }, [
        _vm._v("\n\t\tEdit Shift\n\t"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "editShift__body" },
        [
          _c("ServiceScheduleShiftsCalendarEdit", {
            ref: "shiftsCalendarEdit",
            staticClass: "editShift__calendar",
            attrs: {
              placeholder: "Select a time range",
              "is-edit": _vm.isEditing,
              shift: _vm.shiftObject,
              value: _vm.dates,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "modal-footer" }, [
        _c(
          "div",
          { staticClass: "editShift__footer tw-w-full" },
          [
            !_vm.isEditing
              ? _c(
                  "BaseButton",
                  {
                    directives: [
                      {
                        name: "data-cy",
                        rawName: "v-data-cy",
                        value: "edit-save-shift-btn",
                        expression: "'edit-save-shift-btn'",
                      },
                    ],
                    staticClass: "editShift__saveBtn",
                    attrs: { type: "SUBMIT" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.permitEdits()
                      },
                    },
                  },
                  [
                    _c("template", { slot: "text" }, [
                      _vm._v("\n\t\t\t\t\tEdit\n\t\t\t\t"),
                    ]),
                  ],
                  2
                )
              : _c(
                  "BaseButton",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "save-shift-btn",
                        expression: "'save-shift-btn'",
                      },
                    ],
                    staticClass: "editShift__saveBtn",
                    attrs: {
                      type: "SECONDARY",
                      "is-disabled": !_vm.isValidShiftEdit,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.updateShift()
                      },
                    },
                  },
                  [
                    _c("template", { slot: "text" }, [
                      _vm._v("\n\t\t\t\t\tSave\n\t\t\t\t"),
                    ]),
                  ],
                  2
                ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "editShift__btnContainer" },
              [
                !_vm.isEditing
                  ? _c(
                      "BaseButton",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "remove-shift-btn",
                            expression: "'remove-shift-btn'",
                          },
                          {
                            name: "data-cy",
                            rawName: "v-data-cy",
                            value: "remove-shift-btn",
                            expression: "'remove-shift-btn'",
                          },
                        ],
                        staticClass: "editShift__btn",
                        attrs: { type: "CANCEL" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.showRemoveShiftModal()
                          },
                        },
                      },
                      [
                        _c("template", { slot: "text" }, [
                          _vm._v("\n\t\t\t\t\t\tRemove\n\t\t\t\t\t"),
                        ]),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "BaseButton",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "close-modal-btn",
                        expression: "'close-modal-btn'",
                      },
                      {
                        name: "data-cy",
                        rawName: "v-data-cy",
                        value: "close-modal-btn",
                        expression: "'close-modal-btn'",
                      },
                    ],
                    staticClass: "editShift__btn",
                    attrs: { type: "CANCEL" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.hideModal()
                      },
                    },
                  },
                  [
                    _c("template", { slot: "text" }, [
                      _vm._v("\n\t\t\t\t\t\tClose\n\t\t\t\t\t"),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }