var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ServiceDashboardTutorCardHeaderEssayTutor", {
        attrs: { user: _vm.user, shift: _vm.shift },
      }),
      _vm._v(" "),
      _c("ServiceDashboardListEssay", {
        attrs: {
          resources: _vm.essays,
          "total-essays": _vm.totalEssays,
          "time-since-last-activity": _vm.cardData.last_essay_reviewed_at,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }