export const getErrorText = (errorSubString) => {
	return `
		There was an issue ${errorSubString}. Please try again. 
		If the problem persists, please contact support.
	`;
};

export const showErrorModal = ({ $store, $bvModal }, options = {}) => {
	$store.dispatch("SimpleModal/configureSimpleModal", {
		buttonText: "close",
		message: "errors.general",
		eventFired: null,
		title: "errors.whoops",
		...options,
	});
	$bvModal.show("simpleModal");
};
