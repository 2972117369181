var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "searchDropdown", staticClass: "baseSearchDropdown" },
    [
      _vm.isShowingLabel
        ? _c("label", { attrs: { for: _vm.id } }, [_vm._v(_vm._s(_vm.label))])
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: {
          id: _vm.id,
          autoComplete: _vm.autoComplete,
          type: _vm.type,
          disabled: _vm.isDisabled,
          placeholder: _vm.placeholder,
        },
        domProps: { value: _vm.value },
        on: { input: _vm.handleInput },
      }),
      _vm._v(" "),
      _c("div", [
        _vm.showDropdown
          ? _c(
              "ul",
              { staticClass: "dropContainer", attrs: { id: _vm.dropdownId } },
              [
                _c("li", { staticClass: "dropContainer__otherItems" }, [
                  _vm._v("\n\t\t\t\tSelect one of the existing tags\n\t\t\t"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.filteredItems, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "dropContainer__item",
                      on: { click: _vm.closeDropdown },
                    },
                    [_vm._t("default", null, { item: item, index: index })],
                    2
                  )
                }),
              ],
              2
            )
          : _vm.value && _vm.filteredItems.length === 0
          ? _c("span", { staticClass: "dropContainer__otherItems" }, [
              _vm._v("No tag found"),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }