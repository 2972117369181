var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "section__divider tw-mb-14" }, [
    _c(
      "h3",
      {
        staticClass: "tw-font-extrabold tw-text-black-high tw-text-2xl tw-mt-5",
      },
      [_vm._v("\n\t\tScheduling Details\n\t")]
    ),
    _vm._v(" "),
    _c(
      "form",
      {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "submit",
            expression: "'submit'",
          },
        ],
        staticClass: "tw-flex tw-flex-col",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.saveSchedulingDetails()
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "tw-flex tw-w-full tw-my-7" },
          [
            _c("label", { staticClass: "tw-text-sm tw-pr-9" }, [
              _vm._v("Allow Shift Transfers"),
            ]),
            _vm._v(" "),
            _c(
              "BaseRadio",
              {
                class: _vm.isShiftTransferPermitted ? "tw-font-bold" : "",
                attrs: {
                  "radio-id": "shiftTransferAllowed-yes",
                  "radio-name": "shiftTransferAllowed-yes",
                  "radio-value": true,
                  value: _vm.isShiftTransferPermitted,
                },
                nativeOn: {
                  click: function ($event) {
                    _vm.isShiftTransferPermitted = true
                  },
                },
              },
              [
                _c("template", { slot: "text" }, [
                  _vm._v("\n\t\t\t\t\tYes\n\t\t\t\t"),
                ]),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "BaseRadio",
              {
                staticClass: "baseRadio--boolean tw-mx-9",
                class: !_vm.isShiftTransferPermitted ? "tw-font-bold" : "",
                attrs: {
                  "radio-id": "shiftTransferAllowed-no",
                  "radio-name": "shiftTransferAllowed-no",
                  "radio-value": false,
                  value: _vm.isShiftTransferPermitted,
                },
                nativeOn: {
                  click: function ($event) {
                    _vm.isShiftTransferPermitted = false
                  },
                },
              },
              [
                _c("template", { slot: "text" }, [
                  _vm._v("\n\t\t\t\t\tNo\n\t\t\t\t"),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("ServiceTutorEditShiftBlocksIsEnabled", {
          ref: "availabilityBlocks",
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tw-flex tw-flex-row tw-items-center" },
          [
            _c("BaseFormField", {
              attrs: {
                id: "maxWeeklyHours",
                "is-disabled": true,
                type: "text",
                name: "quote",
                label: "Maximum Hours",
                placeholder: _vm.maxHoursText,
              },
              model: {
                value: _vm.maxHoursText,
                callback: function ($$v) {
                  _vm.maxHoursText = $$v
                },
                expression: "maxHoursText",
              },
            }),
            _vm._v(" "),
            _c(
              "BaseButton",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "resetMaxHoursButton",
                    expression: "'resetMaxHoursButton'",
                  },
                ],
                staticClass: "tw-mt-2 tw-mx-4",
                attrs: {
                  "is-disabled": !_vm.isMaxHoursSet,
                  type: "CANCEL",
                  "data-loading-text": "Submitting...",
                },
                nativeOn: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.resetMaxHours.apply(null, arguments)
                  },
                },
              },
              [
                _c("template", { slot: "text" }, [
                  _vm._v("\n\t\t\t\t\tReset Max Hours\n\t\t\t\t"),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "BaseButton",
              {
                staticClass: "tw-w-48 tw-float-right",
                attrs: { type: "SUBMIT", disabled: _vm.isSubmitDisabled },
              },
              [
                _c(
                  "template",
                  { slot: "text" },
                  [
                    _vm.isLoadingSchedulingDetails
                      ? _c("LoadingRectangles", {
                          staticClass: "tw-text-blue-regular",
                        })
                      : _c("span", [
                          _vm._v(
                            "\n\t\t\t\t\t\tSubmit Scheduling Details\n\t\t\t\t\t"
                          ),
                        ]),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }