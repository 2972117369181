<template>
	<div class="tw-pt-4">
		<label class="tw-text-sm tw-font-bold tw-leading-7">
			{{ $t("whatTeacherInstructionsAndExpectations") }}
		</label>
		<textarea
			v-model="writtenInstructions"
			v-jest="'instruction-text'"
			v-data-cy="'instruction-text'"
			class="tw-border-b tw-rounded-b-none tw-rounded-t-md tw-border-2 tw-border-solid tw-border-origami-grey-200 tw-min-h-[10rem] tw-py-4 tw-px-3.5 tw-w-full placeholder:tw-text-xs"
			:placeholder="$t('instructions')"
			@change="handleWrittenInput"
		/>
		<div class="tw-bg-grey-pale tw-border-origami-grey-200 tw-border-solid tw-border-2 tw-border-t tw-rounded-t-none tw-rounded-b-md tw-pr-1.5 tw-pl-3 tw-py-3 tw--mt-1.5">
			<input
				ref="instructionFiles"
				v-data-cy="'instruction-file'"
				type="file"
				:accept="instructionFileMimeTypes.join()"
				multiple
				class="tw-hidden"
				@change="handleInitialFileUpload(Array.from($event.target.files))"
			>
			<div
				v-show="instructionFiles.length < fileLimit"
				v-jest="'main-upload-buttons'"
				class="tw-gap-2"
			>
				<div class="tw-font-bold tw-text-xs">
					{{ $t('instructionsCTA') }}:
				</div>
				<OrigamiButton
					variant="secondary"
					leading-icon="computer"
					@click.native="openFilePicker"
				>
					{{ $t("browseFiles") }}
				</OrigamiButton>
				<GooglePicker
					:doc-validator="docValidator"
					:mime-types="instructionFileMimeTypes"
					class="tw-relative"
					multiple
					@change="handleInitialFileUpload"
					@change-start="isLoadingGooglePickerFile = true"
					@change-end="isLoadingGooglePickerFile = false"
					@error="handleGooglePickerError"
				>
					<template #default="{ trigger: triggerGooglePicker, isLoaded }">
						<OrigamiButton
							variant="secondary"
							leading-icon="google"
							:is-loading="!isLoaded"
							@click.native="openGooglePicker(triggerGooglePicker)"
						>
							{{ $t("googleDrive") }}
						</OrigamiButton>
					</template>
				</GooglePicker>
			</div>
			<BaseLoader
				v-if="isLoadingGooglePickerFile"
				class="tw-w-4 tw-h-4 tw-blue-regular tw-m-2"
			/>
			<div
				v-for="(instructionFile, index) in instructionFiles"
				:key="index"
				class="tw-flex tw-flex-row hover:tw-bg-grey-slightly tw-border-2 tw-border-dashed tw-border-grey-dark tw-overflow-hidden tw-mt-2"
			>
				<input
					ref="instructionFile"
					type="file"
					:accept="instructionFileMimeTypes.join()"
					multiple
					class="tw-hidden"
					@change="handleFileChange($event.target.files[0], index)"
				>
				<div class="tw-flex-grow tw-flex tw-items-center tw-overflow-hidden tw-text-origami-blue-300">
					<OrigamiIcon
						name="essay-outlined"
						class="tw-mx-2"
						width="16"
						height="16"
					/>
					<span class="tw-text-sm tw-font-bold tw-overflow-hidden tw-overflow-ellipsis">
						{{ instructionFile.name }}
					</span>
				</div>
				<BDropdown
					right
					size="sm"
					no-caret
					variant="link"
					class="ChangeFileDropdown tw-relative"
					:popper-opts="{ positionFixed: true }"
				>
					<template #button-content>
						<span class="tw-text-origami-blue-300 tw-font-bold">
							{{ $t('changeFile') }}
							<OrigamiIcon name="caret" />
						</span>
					</template>
					<BDropdownText v-if="embedded">
						<span class="tw-font-bold tw-text-sm">
							{{ $t("changeFile") }}
						</span>
					</BDropdownText>
					<BDropdownItem @click.native="$refs.instructionFile[index].click()">
						<OrigamiIcon name="computer" />
						{{ $t("browseFiles") }}
					</BDropdownItem>
					<GooglePicker
						:doc-validator="docValidator"
						:mime-types="instructionFileMimeTypes"
						class="tw-relative"
						@change="handleFileChange($event, index)"
						@change-start="isLoadingGooglePickerFile = true"
						@change-end="isLoadingGooglePickerFile = false"
						@error="handleGooglePickerError"
					>
						<template #default="{ trigger: triggerGooglePicker, isLoaded }">
							<BDropdownItem
								v-if="isLoaded"
								@click.native="openGooglePicker(triggerGooglePicker)"
							>
								<OrigamiIcon name="google" />
								{{ $t("googleDrive") }}
							</BDropdownItem>
						</template>
					</GooglePicker>
					<template v-if="embedded">
						<BDropdownDivider />
						<BDropdownItem>
							<OrigamiButton
								leading-icon="close-full"
								@click.native="clearInstructionFile(instructionFile)"
							>
								{{ $t("removeFile") }}
							</OrigamiButton>
						</BDropdownItem>
					</template>
				</BDropdown>
				<OrigamiIconButton
					v-if="!embedded"
					icon-name="close-full"
					aria-hidden="true"
					:title="$t('removeFile')"
					@click.native.prevent="clearInstructionFile(instructionFile)"
				>
					<span class="slds-assistive-text">
						{{ $t("removeFile") }}
					</span>
				</OrigamiIconButton>
			</div>
		</div>
		<div v-if="fileErrors.length">
			<ul>
				<li
					v-for="(error, index) in fileErrors"
					:key="index"
					class="tw-text-sm tw-font-bold tw-text-origami-red-400 tw-mt-4"
				>
					{{ error == null ? '' : $t(`errors.${error}`) }}
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { OrigamiButton, OrigamiIcon, OrigamiIconButton } from "@origami/vue2";

import BaseLoader from "@/components/elements/BaseLoader.vue";
import GooglePicker, { UPLOAD_ERROR_KEYS, getLocalizedErrorSubKey } from "@/components/GooglePicker.vue";
import { TAGS, getFilteredFileTypeIds, formatFileTypeIdsAsExtensionsRegex } from "@/utilities/fileTypes.js";

const FILE_SIZE_LIMIT = 6081740;
const MAX_FILES_LIMIT = 3;

export default {
	components: {
		OrigamiButton,
		OrigamiIcon,
		OrigamiIconButton,
		BaseLoader,
		GooglePicker,
	},
	props: {
		docValidator: {
			type: Function,
			default: () => {},
		},
		instructionFiles: {
			type: Array,
			default: () => ([]),
		},
		instructionFileMimeTypes: {
			type: Array,
			default: () => ([]),
		},
		fileErrors: {
			type: Array,
			default: () => ([]),
		},
		embedded: {
			type: Boolean,
			default: false,
		},
	},
	emits: [
		"set-errors",
		"set-instruction-files",
		"clear-errors",
		"update-instructions",
	],
	data() {
		return {
			writtenInstructions: "",
			validatedFiles: [],
			isLoadingGooglePickerFile: false,
			fileLimit: MAX_FILES_LIMIT,
		};
	},
	computed: {
		instructionsFileUploadFileTypeIds() {
			return getFilteredFileTypeIds({
				includeTags: [TAGS.TEXT, TAGS.IMAGE],
			});
		},
	},
	watch: {
		instructionFiles() {
			this.validatedFiles = this.instructionFiles;
		},
	},
	async created() {
		const featureFlagCanvasIntegrationIsEnabled = await this.$getFlag("GROW-882-canvas-assignment-linking");
		if (featureFlagCanvasIntegrationIsEnabled && this.$route.query?.description) {
			this.writtenInstructions = this.$route.query.description;
		}
	},
	methods: {
		openFilePicker() {
			this.$refs.instructionFiles.value = null;
			this.$refs.instructionFiles.click();
		},
		handleWrittenInput() {
			this.$emit("update-instructions", this.writtenInstructions);
		},
		clearInstructionFile(selectedFile) {
			this.validatedFiles = this.validatedFiles.length > 1
				? this.validatedFiles.filter((file) => file.name !== selectedFile.name)
				: [];
			this.$emit("clear-errors");
			this.$emit("set-instruction-files", this.validatedFiles);
		},
		getFileInvalidType(file) {
			const validFileRegex = formatFileTypeIdsAsExtensionsRegex(this.instructionsFileUploadFileTypeIds);
			let errorType = "";

			if (!validFileRegex.test(file.name)) {
				errorType = UPLOAD_ERROR_KEYS.INVALID;
			} else if (file.size > FILE_SIZE_LIMIT) {
				errorType = UPLOAD_ERROR_KEYS.TOO_LARGE;
			}
			return errorType;
		},
		getUniqueErrors(errors) {
			return errors.reduce((accumulated, error) => {
				if (!accumulated.some((existingError) => existingError === error)) {
					accumulated.push(error);
				}
				return accumulated;
			}, []);
		},
		getUniqueFiles(files) {
			return files.reduce((accumulated, file) => {
				if (!accumulated.some((addedFile) => addedFile.name === file.name)) {
					accumulated.push(file);
				}
				return accumulated;
			}, []);
		},
		checkAreFilesValid(filesToCheck) {
			const validFileRegex = formatFileTypeIdsAsExtensionsRegex(this.instructionsFileUploadFileTypeIds);

			this.$emit("clear-errors");
			const uploadErrors = [];

			let files = filesToCheck.reduce((accumulated, file) => {
				if ((!validFileRegex.test(file.name)) || file.size > FILE_SIZE_LIMIT) {
					const invalidType = this.getFileInvalidType(file);
					if (invalidType !== "") {
						uploadErrors.push(invalidType);
					}
				} else {
					accumulated.push(file);
				}
				return accumulated;
			}, []);
			// set the first three files but show user the error
			if (files.length > MAX_FILES_LIMIT) {
				uploadErrors.push(UPLOAD_ERROR_KEYS.TOO_MANY_FILES);
				files = files.slice(0, MAX_FILES_LIMIT);
			}

			this.$emit("set-errors", this.getUniqueErrors(uploadErrors));
			this.$emit("set-instruction-files", files);
		},
		handleInitialFileUpload(selectedFiles) {
			const preexistingFiles = [...this.validatedFiles];
			this.checkAreFilesValid(this.getUniqueFiles(preexistingFiles.concat(selectedFiles)));
		},
		handleFileChange(file, index) {
			const preexistingFiles = [...this.validatedFiles];
			preexistingFiles[index] = file;
			this.checkAreFilesValid(this.getUniqueFiles(preexistingFiles));
		},
		openGooglePicker(triggerGooglePicker) {
			triggerGooglePicker();
		},
		handleGooglePickerError(error) {
			this.$emit("set-errors", [getLocalizedErrorSubKey(error)]);
		},
	},
};
</script>

<style scoped>
.ChangeFileDropdown :deep(.btn-link) {
	color: inherit;
}
.ChangeFileDropdown :deep(.btn-link:hover),
.ChangeFileDropdown :deep(.btn-link:focus),
.ChangeFileDropdown :deep(.btn-link:active) {
	color: var(--origami-blue-300);
	text-decoration: none;
}
</style>
