export const modalsCommon = {
	en: {
		modals: {
			common: {
				password_confirm: "Confirm new password",
				password_new: "New password",
				store_password_safe:
					"Make sure to store your password in a safe place where you can easily find it in case you forget it.",
			},
		},
	},
	fr: {
		modals: {
			common: {
				password_confirm: "Confirmez mot de passe",
				password_new: "Nouveau mot de passe",
				store_password_safe:
					"Veuillez conserver votre mot de passe dans un endroit sûr au cas ou vous l'oubliez.",
			},
		},
	},
	es: {
		modals: {
			common: {
				password_confirm: "Confirmar contraseña",
				password_new: "Nueva contraseña",
				store_password_safe:
					"Asegúrate de guardar tu contraseña en un lugar seguro que puedas recuerdar en caso de que la olvides.",
			},
		},
	},
};

export const modalsChangePassword = {
	en: {
		modals: {
			change_password: {
				password_confirm_message: "You can now login with your new password",
				password_header: "Setup your new password",
				password_instructions:
					"You can use your new password with your email address to login to PAPER from the homepage.",
				password_title: "Update password",
			},
		},
	},
	fr: {
		modals: {
			change_password: {
				password_confirm_message:
					"Vous pouvez maintenant vous connecter à PAPER avec votre nouveau mot de passe",
				password_header: "Configurez votre nouveau mot de passe",
				password_instructions:
					"Vous pouvez utiliser votre adresse couriel ainsi que votre mot de passe pour vous connecter à PAPER à partir de la page d'accueil.",
				password_title: "Choisissez un mot de passe",
			},
		},
	},
	es: {
		modals: {
			change_password: {
				password_confirm_message: "Puedes ingresar a Paper con tu nueva contraseña",
				password_header: "Configura tu nueva contraseña",
				password_instructions:
					"Puedes utilizar tu nueva contraseña con tu correo electrónico para ingresar a Paper desde la página de inicio.",
				password_title: "Actualiza la contraseña",
			},
		},
	},
};

export const modalsEssay = {
	en: {
		modals: {
			error_getting_essays: "There was an error getting your essays. Please try again",
			error_cancelling_essay: "There was an error cancelling your essay. Please try again",
			error_page: "There was an error setting up the page. Please try again",
		},
	},
	fr: {
		modals: {
			error_getting_essays: "There was an error getting your essays. Please try again",
			error_cancelling_essay: "There was an error cancelling your essay. Please try again",
			error_page: "There was an error setting up the page. Please try again",
		},
	},
	es: {
		modals: {
			error_getting_essays: "Ocurrió un error al obtener tus ensayos. Inténtalo de nuevo.",
			error_cancelling_essay: "Ocurrió un errror al cancelar tu ensayo. Inténtalo de nuevo.",
			error_page: "Ocurrió un error al configurar la página. Inténtalo de nuevo.",
		},
	},
};
