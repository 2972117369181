var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.tagNamesExists
    ? _c(
        "div",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "filter-tag",
              expression: "'filter-tag'",
            },
          ],
          staticClass:
            "tw-bg-origami-grey-300 tw-rounded-md tw-flex tw-items-center tw-px-3 tw-mr-2",
          attrs: { title: _setup.tagNamesArray.join(", ") },
        },
        [
          _c(
            "span",
            { staticClass: "tw-text-origami-white tw-mr-2 tw-text-sm" },
            [_vm._v(_vm._s(_setup.getTagContent))]
          ),
          _vm._v(" "),
          _c(_setup.OrigamiIconButton, {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "filter-tags-group-close-button",
                expression: "'filter-tags-group-close-button'",
              },
              {
                name: "data-cy",
                rawName: "v-data-cy",
                value: "filter-tags-group-close-button",
                expression: "'filter-tags-group-close-button'",
              },
            ],
            staticClass: "!tw-p-0 tw-text-sm tw-w-5 tw-h-5",
            attrs: { "icon-name": "close-full" },
            nativeOn: {
              click: function ($event) {
                return _setup.closeTagGroup.apply(null, arguments)
              },
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }