var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "tw-flex tw-items-center tw-justify-center tw-w-full tw-min-h-screen",
    },
    [
      !_vm.isLoadingFile
        ? _c(
            "OrigamiButton",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "google-drive-button",
                  expression: "'google-drive-button'",
                },
              ],
              attrs: { "is-loading": _vm.isLoading },
              nativeOn: {
                click: function ($event) {
                  return _vm.openGooglePicker.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "tw-flex tw-items-center tw-gap-2" },
                [
                  _c("IconGoogleDrive", {
                    attrs: { "aria-hidden": "true", focusable: "false" },
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("googleDriveInstructions"))),
                  ]),
                ],
                1
              ),
            ]
          )
        : _c(
            "div",
            [
              _c(
                "BaseLoader",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "loader",
                      expression: "'loader'",
                    },
                  ],
                  staticClass:
                    "tw-h-20 tw-w-20 tw-text-blue-regular tw-mx-auto",
                },
                [
                  _c("IconEssay", {
                    staticClass: "tw-w-5 tw-h-7",
                    attrs: {
                      "icon-color": "currentColor",
                      "aria-hidden": "true",
                      focusable: "false",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { staticClass: "tw-mt-4 tw-font-bold" }, [
                _vm._v(
                  "\n\t\t\t" + _vm._s(_vm.$t("processingFilePopup")) + "\n\t\t"
                ),
              ]),
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "GooglePicker",
        {
          ref: "googlePicker",
          attrs: {
            "auth-config": { prompt: "select_account" },
            "doc-validator": _vm.docValidator,
            "mime-types": _vm.mimeTypes,
          },
          on: {
            change: _vm.handleGooglePickerEssayFileInput,
            cancel: _vm.handleCancel,
            error: _vm.handleFileUploadError,
          },
        },
        [
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false",
              },
            ],
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }