<script setup>
import { OrigamiCard, OrigamiIcon, OrigamiButton } from "@origami/vue2";
import { computed, reactive, ref } from "vue";

import InfoToolTip from "@/components/patterns/InfoToolTip.vue";

import SubjectCard from "./SubjectCard.vue";

const props = defineProps({
	card: {
		type: Object,
		required: true,
	},
});

const state = reactive({
	isExpanded: false,
	isDescendingSort: true,
});

const isViewMoreButtonDisplayed = computed(() =>
	props.card.limit > 0 && props.card.subjects.length > props.card.limit);

const getCardsByLimit = computed(() =>
	!state.isExpanded && props.card.limit > 0 ? sortedCards().slice(0, props.card.limit) : sortedCards());

const expandRiskCard = () => state.isExpanded = !state.isExpanded;
const expandButtonText = computed(() => state.isExpanded ? "Show Less" : "View More");

const hasSubjects = computed(() => props.card.subjects.length);

const sortedCards = () => {
	const sortedCardArray = [...props.card.subjects];
	state.isDescendingSort
		? sortedCardArray.sort((cardA, cardB) => cardB.score - cardA.score)
		: sortedCardArray.sort((cardA, cardB) => cardA.score - cardB.score);
	return sortedCardArray;
};

const sortByTutorLoadScore = () => {
	collapseAll();
	state.isDescendingSort = !state.isDescendingSort;
};
const sortIcon = computed(() => {
	return state.isDescendingSort ? "arrow-down" : "arrow-up";
});

const subjectCardsRef = ref([]);

const collapseAll = () => {
	state.isExpanded = false;
	subjectCardsRef.value.forEach((card) => card.collapseChart());
};
</script>

<template>
	<OrigamiCard class="tw-shadow-origami-md tw-my-5">
		<div class="tw-py-3">
			<div class="tw-flex tw-justify-between tw-items-center tw-w-full tw-px-5 tw-pb-2 tw-relative after:tw-absolute after:tw-w-full after:tw-border-grey-regular after:tw-border-b-2 after:tw-h-0 after:tw-top-full after:tw-left-0 after:tw-right-0">
				<div class="tw-flex tw-items-center">
					<OrigamiIcon
						:name="props.card.icon"
						:class="props.card.color"
						width="26"
						height="26"
					/>
					<h2 class="tw-text-origami-l tw-ml-1 tw-mr-2 tw-font-origami-bold tw-leading-5">
						{{ props.card.title }}
					</h2>
					<InfoToolTip
						class="tw-ml-0 tw-leading-6"
						:width="'15px'"
						:height="'15px'"
						:options="{
							content: props.card.tooltipText,
							placement: 'top',
						}"
					/>
				</div>
				<div class="tw-flex tw-items-center tw-divide-grey-regular tw-divide-x-2">
					<div class="tw-flex tw-items-center ">
						<OrigamiButton
							v-jest="'collapse-all-risk-cards-button'"
							data-cy="'collapse-all-risk-cards-button'"
							variant="tertiary"
							@click.native="collapseAll"
						>
							<span class="tw-text-origami-black">Collapse All</span>
						</OrigamiButton>
					</div>
					<div class="tw-flex tw-items-center">
						<OrigamiButton
							v-jest="'sort-load-score-button'"
							v-data-cy="'sort-load-score-button'"
							variant="tertiary"
							class="tw-mx-3"
							@click.native="sortByTutorLoadScore"
						>
							<span class="tw-text-origami-black">Sort</span>
							<OrigamiIcon
								class="tw-text-origami-black"
								width="18"
								height="18"
								:name="sortIcon"
							/>
						</OrigamiButton>
					</div>
				</div>
			</div>
			<div
				v-if="hasSubjects"
				class="tw-divide-y-2 tw-divide-origami-grey-100 tw-px-5"
			>
				<SubjectCard
					v-for="subject in getCardsByLimit"
					:key="subject.title"
					ref="subjectCardsRef"
					v-jest="'subject-card'"
					:data-cy="`subject-card-${subject.subject}`"
					:subject="subject"
					:risk-level="props.card.riskLevel"
				/>
			</div>
			<div
				v-else
				v-jest="'empty-subject-error'"
				class="tw-text-center tw-py-11"
			>
				<h3 class="tw-font-origami-bold tw-text-origami-grey-400">
					No alerts to review
				</h3>
				<p class="tw-mt-3 tw-text-origami-grey-400">
					Is this incorrect? Refresh the alerts to update the page
				</p>
			</div>
			<div
				v-if="isViewMoreButtonDisplayed"
				class="tw-text-center tw-pt-3 tw-relative after:tw-absolute after:tw-w-full after:tw-border-grey-regular after:tw-border-b-2 after:tw-h-0 after:tw-top-0 after:tw-left-0 after:tw-right-0"
			>
				<OrigamiButton
					v-jest="'view-more-button'"
					variant="tertiary"
					@click.native="expandRiskCard"
				>
					{{ expandButtonText }} <span class="origami-sr-only">, {{ props.card.title }}</span>
				</OrigamiButton>
			</div>
		</div>
	</OrigamiCard>
</template>
