import { merge } from "lodash";

import gradeLevels from "./gradeLevels.js";

const general = {
	en: {
		a: "a",
		add: "Add",
		and: "and",
		app: "App",
		chrome: "Chrome",
		apple_app_store: "Apple App Store",
		cancel: "Cancel",
		confirm: "Confirm",
		close: "Close",
		create: "Create",
		demo: "Demo",
		download_paper_app: "Download the Paper App",
		draw: "Draw",
		edit: "Edit",
		error: "Error",
		flag: "Flag",
		for: "for",
		google_play_store: "Google Play Store",
		go_to_step: "go to step {stepNumber} of {totalSteps}",
		hi: "Hi",
		menu: "Menu",
		new: "New",
		no: "No",
		okay: "Okay",
		paper_qr_code: "Paper App QR Code",
		preview: "Preview",
		read: "Read",
		rights_reserved: "All rights reserved",
		save: "Save",
		search: "Search",
		send: "Send",
		submit: "Submit",
		transfer: "Transfer",
		unread: "Unread",
		view: "View",
		yes: "Yes",
		you: "You",
		from: "from",
		tutor: "tutor",
		teacher: "teacher",
		navbar: {
			activity: "Activity",
			reading: "Paper Reading",
			blog: "Blog",
			classroom: "Chat",
			communications: "Communications",
			customers: "Customers",
			dashboard: "Dashboard",
			review_center: "Review Center",
			question_answer_center: "Questions",
			inbox: "Notifications",
			impersonation: "Stop Impersonation",
			logout: "Logout",
			paperlive: "Paper Video",
			paperMath: "Paper Math",
			paperMissions: "Paper Missions",
			problemCreator: "Problem Creator",
			missionsAdmin: "Missions Admin",
			problemGenerator: "Problem Generator",
			paperClips: "PaperClips",
			paperClipsTags: "Tags",
			resources: "Resources",
			resourceHub: "Resource Hub", // deprecate if GROW-2309-paper-academy-link goes live
			resourceCenter: "Teacher Resource Center", // deprecate if GROW-2309-paper-academy-link doesn't go live
			paperAcademy: "Paper Academy", // deprecate if GROW-2309-paper-academy-link doesn't go live
			service: "Service",
			settings: "Settings",
			students: "Students",
			teacher_resource: "Paper Binder",
			view_as_superuser: "View as Superuser",
			view_as_district_manager: "View as District Manager",
			view_as_administrator: "View as Administrator",
			view_as_teacher: "View as Teacher",
			view_as_tutor: "View as Tutor",
			view_as_platform_manager: "View as Platform Manager",
			view_as_student: "View as Student",
			view_as_clips_editor: "View as Clips Editor",
			view_as_schedule_builder: "View as Schedule Builder",
			pagination: "Go to page",
			previous_page: "Previous page",
			next_page: "Next page",
			pave: "PAVE",
		},
		fileTypes: {
			googleDocs: "Google docs",
			googleSlides: "Google slides",
		},
	},
	fr: {
		a: "un",
		add: "Ajouter",
		and: "et",
		app: "App",
		chrome: "Chrome",
		apple_app_store: "Apple App Store",
		cancel: "Annuler",
		close: "Annuler",
		create: "Créer",
		demo: "Démo",
		download_paper_app: "Téléchargez l'appli Paper",
		draw: "Dessiner",
		edit: "Modifier",
		error: "Error",
		flag: "Signaler",
		for: "pour",
		google_play_store: "Google Play Store",
		go_to_step: "passer à l'étape {stepNumber} sur {totalSteps}",
		hi: "Salut",
		menu: "Menu",
		new: "Nouveau",
		no: "Non",
		okay: "D'accords",
		paper_qr_code: "Paper App QR Code",
		preview: "Aperçu",
		read: "Déjà lue",
		rights_reserved: "Tous droits réservés",
		save: "Sauvegarder",
		search: "Rechercher",
		send: "Envoyer",
		submit: "Soumettre",
		transfer: "Transférer",
		unread: "N'a pas été lue",
		view: "Voir",
		yes: "Oui",
		you: "Toi",
		from: "de",
		tutor: "tuteur",
		teacher: "professeur",
		navbar: {
			classroom: "Chat",
			dashboard: "Tableau de bord",
			logout: "Se déconnecter",
			inbox: "Notifications",
			next_page: "Page suivante",
			pagination: "Aller à la page",
			previous_page: "Page précédente",
			question_answer_center: "Questions",
			review_center: "Centre de révision",
		},
		fileTypes: {
			googleDocs: "fichiers Google",
			googleSlides: "présentations Google",
		},
	},
	es: {
		a: "un",
		add: "Agregar",
		and: "y",
		app: "App",
		chrome: "Chrome",
		apple_app_store: "Apple App Store",
		cancel: "Cancelar",
		close: "Cerrar",
		create: "Crear",
		demo: "Demostración",
		download_paper_app: "Descarga la app de Paper",
		draw: "Dibujar",
		edit: "Editar",
		error: "Error",
		flag: "Marcar",
		for: "por",
		google_play_store: "Google Play Store",
		go_to_step: "ir al paso {stepNumber} de {totalSteps}",
		hi: "Hola",
		menu: "Menú",
		new: "Nuevo",
		no: "No",
		okay: "Ok",
		paper_qr_code: "Paper App QR Code",
		preview: "Vista previa",
		read: "Leído",
		rights_reserved: "Todos los derechos reservados",
		save: "Guardar",
		search: "Buscar",
		send: "Enviar",
		submit: "Enviar",
		transfer: "Transferir",
		unread: "No leído",
		view: "Ver",
		yes: "Sí",
		you: "Tú",
		from: "de",
		tutor: "tutor",
		teacher: "profesor",
		navbar: {
			classroom: "Chat",
			dashboard: "Tablero",
			logout: "Cerrar sesión",
			inbox: "Notificaciones",
			next_page: "Siguiente página",
			pagination: "Ir a la página",
			previous_page: "Pagina anterior",
			question_answer_center: "Preguntas",
			review_center: "Centro de Revisión",
		},
		fileTypes: {
			googleDocs: "expedientes de Google",
			googleSlides: "presentaciones de Google",
		},
	},
};

export const generalTranslations = merge(general, gradeLevels);
