var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "shift-summary-break",
          expression: "'shift-summary-break'",
        },
      ],
      staticClass: "tw-list-none tw-ml-16 tw-text-xs",
    },
    [
      _c("OrigamiIcon", {
        attrs: { name: _vm.breakIcon, height: "14", width: "14" },
      }),
      _vm._v(
        "\n\t" + _vm._s(_vm.startTime) + " - " + _vm._s(_vm.endTime) + "\n"
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }