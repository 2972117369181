<template>
	<div
		ref="dropdown"
		class="baseDropdown"
		@click-outside="toggleDropdown"
	>
		<label v-if="isShowingLabel">{{ label }}</label>
		<div
			v-jest="'select'"
			class="mainDropdown"
			tabindex="0"
			:class="{ mainDropdownActive: activeDropdown, mainDropdownDisabled: isDisabledOrNoData }"
			:disabled="isDisabledOrNoData"
			:aria-expanded="showDropdown ? 'true' : 'false'"
			:aria-controls="dropdownId"
			@keyup.enter="toggleDropdown"
			@keyup.esc="showDropdown = false"
			@keyup.down="focusListItem(selectedItemIndex)"
			@click="toggleDropdown"
		>
			<div class="labelContainer">
				<span class="labelContainer__span"><slot name="filters" /></span>
			</div>
			<div
				v-show="!showDeselectButton"
				class="caret"
				:class="{ caretActive: activeDropdown }"
			/>
			<img
				v-show="showDeselectButton"
				v-jest="'deselect-all'"
				v-data-cy="'deselect-icon'"
				src="/images/icon-x-close-blue.svg"
				@click.stop="deselectAll"
			>
		</div>
		<transition
			tag="ul"
			name="slide"
		>
			<ul
				v-show="showDropdown && !isDisabledOrNoData"
				:id="dropdownId"
				v-jest="'dropdown'"
				role="listbox"
				class="dropContainer"
			>
				<li
					v-if="isLongList && showSearchBar"
					class="searchBar"
				>
					<input
						:id="searchBarId"
						v-jest="'filter'"
						type="text"
						:placeholder="searchText"
						class="vueDropdownSearchBar tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-regular tw-rounded-sm"
						@keyup="filterItems"
						@keyup.esc="focusDropdown()"
					>
				</li>
				<li
					v-if="$slots.selectAll"
					class="checkboxSelectAll"
					role="option"
				>
					<slot name="selectAll" />
				</li>
				<li
					v-for="(item, index) in items"
					:id="`dropdown-item-${index}`"
					:key="index"
					v-jest="'item'"
					role="option"
					tabindex="-1"
					:aria-selected="index === selectedItemIndex ? 'true' : 'false'"
					@keyup.up="focusListItem(index === 0 ? index : index - 1)"
					@keyup.down="focusListItem(index + 1)"
					@keyup.home="focusListItem(0)"
					@keyup.end="focusListItem(items.length - 1)"
					@keyup.enter="handleSelect(item)"
					@keyup.esc="focusDropdown()"
					@click="handleSelect(item)"
				>
					<slot
						:item="item"
						:index="index"
					/>
				</li>
				<li
					v-if="isLongerList && !isSearching"
					class="moreResults"
				>
					<p>Use Search for more results</p>
				</li>
				<li v-if="noDataComponent && !items.length">
					<component :is="noDataComponent" />
				</li>
			</ul>
		</transition>
	</div>
</template>

<script>
export default {
	props: {
		items: {
			type: Array,
			default: () => [],
		},
		label: {
			type: String,
			default: "",
		},
		selectedItemIndex: {
			type: Number,
			default: 0,
		},
		isClearable: {
			type: Boolean,
			required: false,
			default: function() {
				return false;
			},
		},
		noDataComponent: {
			type: Object,
			default: function() {
				return null;
			},
		},
		isDisabled: {
			type: Boolean,
			default: function() {
				return false;
			},
		},
		closeOnSelect: {
			type: Boolean,
			default: false,
		},
		itemsLimit: {
			type: Number,
			default: 100,
		},
		showSearchBar: {
			type: Boolean,
			default: true,
		},
	},
	data: function() {
		return {
			showDropdown: false,
			searchBarId: "",
			dropdownId: "",
			isSearching: false,
			isListReduced: false,
		};
	},

	computed: {
		/**
		 * 	 whether or not the dropdown list is longer than 5 items.
		 * @return {Boolean}
		 */
		isLongList() {
			return this.items.length > 5;
		},
		/**
		 * Determine whether to disable the dropdown
		 */
		isDisabledOrNoData() {
			return this.isDisabled || (this.items.length == 0 && !this.noDataComponent);
		},
		/**
		 * Return the lang text for the word "Search"
		 * @return {String}
		 */
		searchText() {
			return "Search";
		},
		/**
		 * Determine whether or not to show the deselect x button.
		 * @return {Boolean}
		 */
		showDeselectButton() {
			return this.isClearable && !this.showDropdown;
		},

		activeDropdown() {
			return (this.showDropdown || this.isClearable) && !this.isDisabledOrNoData;
		},
		/**
		 * Is the label showing?
		 * @returns {Boolean}
		 */
		isShowingLabel() {
			return this.label.length > 0;
		},
		/**
		 * Is the list length longer than itemsLimit or by default 100?
		 * @returns {Boolean}
		 */
		isLongerList() {
			return this.items.length > this.itemsLimit;
		},
	},
	watch: {
		showDropdown(isShowing) {
			if (!isShowing && this.isLongList && this.showSearchBar) {
				document.getElementById(this.searchBarId).value = "";
				this.isSearching = false;
				this.filterItems();
				this.isListReduced = false;
			}
			if (isShowing && this.isLongerList) {
				this.filterItems();
			}
		},
	},
	mounted() {
		this.setSearchBarIds();

		// Closes dropdown option list if focus goes to an other item in the page
		document.addEventListener("focusin", this.closeDropdownOnFocus);
	},
	beforeDestroy() {
		document.removeEventListener("mousedown", this.clickOut);
		document.removeEventListener("focusin", this.closeDropdownOnFocus);
	},
	methods: {
		toggleDropdown() {
			if (!this.isDisabledOrNoData) {
				this.showDropdown = !this.showDropdown;
			}
			if (this.showDropdown) {
				//listen for clicks outside of the dropdown to close it
				this.addUniqueMouseDownListener();
			}
		},
		closeDropdownOnFocus(event) {
			if (!this.$el.contains(event.target)) {
				this.showDropdown = false;
			}
		},
		focusDropdown() {
			this.showDropdown = false;
			const dropdown = this.$el.querySelector(".mainDropdown");
			dropdown.focus();
		},
		focusListItem(index) {
			document.addEventListener("keydown", (event) => {
				if (["ArrowUp", "ArrowDown", "End", "Home"].includes(event.code)) {
					event.preventDefault();
					event.stopPropagation();
				}
			}, { once: true });
			this.showDropdown = true;

			const listItems = Array.from(this.$el.querySelectorAll("li"));
			const newIndex = listItems.findIndex((item) => item.id === `dropdown-item-${index}`);
			const itemToFocus = newIndex >= 0 ? listItems.at(newIndex) : listItems.at(index);

			if (itemToFocus) {
				itemToFocus.focus();
			}
		},
		clickOut(e) {
			const isClickInside = this.$refs.dropdown.contains(e.target);
			if (!isClickInside) {
				this.showDropdown = false;
			} else {
				this.addUniqueMouseDownListener();
			}
		},
		addUniqueMouseDownListener() {
			document.addEventListener("mousedown", this.clickOut, { once: true });
		},
		/**
		 * Create unique ids for the dropdown menu elements.
		 * */
		setSearchBarIds() {
			this.searchBarId = Math.random()
				.toString(36)
				.substring(2, 15);
			this.dropdownId = Math.random()
				.toString(36)
				.substring(2, 15);
		},
		/**
		 * Filters out the list items that do not contain the string typed into the search bar.
		 */
		filterItems() {
			const input = document.getElementById(this.searchBarId),
				filter = input.value.toUpperCase(),
				div = document.getElementById(this.dropdownId),
				li = div.querySelectorAll("li[role='option']");
			this.isSearching = !!input.value;
			if (this.isLongerList && !filter && !this.isListReduced) {
				this.isListReduced = true;
				for (let i = this.itemsLimit - 1; i < li.length; i++) {
					li[i].style.display = "none";
				}
			} else {
				for (let i = 0; i < li.length; i++) {
					const txtValue = li[i].textContent || li[i].innerText || "";
					li[i].style.display = txtValue.toUpperCase().indexOf(filter) > -1 ? "" : "none";
				}
			}
		},
		/**
		 * Used to send event to deselect all selected items
		 * */
		deselectAll() {
			this.$emit("deselect");
		},
		handleSelect(item) {
			this.$emit("select", item);
			if (this.closeOnSelect) {
				this.showDropdown = false;
			}
		},
	},
};
</script>

<style scoped>
.baseDropdown {
	position: relative;
}
label {
	font-family: "MonumentGrotesk", "Open Sans", sans-serif;
	font-size: 12px;
	font-weight: 600;
	font-style: normal;
	line-height: normal;
}
.mainDropdown {
	padding: 6px 16px;
	height: 40px;
	border-radius: 5px;
	border: 2px solid var(--c-gray);
	font-size: 12px;
	font-weight: normal;
	color: var(--c-black);
	background: var(--c-white);
	text-align: left;
}

.mainDropdown:hover {
	cursor: pointer;
	background: var(--c-light-gray);
}

.mainDropdownActive {
	color: var(--c-blue);
	border: 2px solid var(--c-blue);
	font-weight: 600;
}

.mainDropdownDisabled {
	opacity: 0.6;
}

.caret {
	width: 4px;
	height: 7px;
	border-radius: 2px;
	float: right;
	margin: 11px 0;
	display: inline-block;
	vertical-align: middle;
	border-top: 4px dashed;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
}

.caretActive {
	transform: rotate(180deg);
	margin: 9px 0;
}

.dropContainer {
	background: var(--c-white);
	border-radius: 5px;
	box-shadow: 0 10px 20px 0 var(--c-box-shadow);
	padding: unset;
	position: absolute;
	margin: unset;
	list-style-type: none;
	transform-origin: top;
	transition: transform 0.2s ease-in-out;
	overflow-y: scroll;
	overflow-x: auto;
	width: -webkit-fill-available;
	width: -moz-available;
	width: 100%;
	max-height: 195px;
	z-index: 3;
}

li {
	padding: 8px;
	background: var(--c-white);
	text-align: left;
}

li:hover {
	cursor: pointer;
}

li:focus,
li:focus-within,
li:hover {
	background: var(--c-light-gray);
}

.slide-enter,
.slide-leave-to {
	transform: scaleY(0);
}

li.searchBar {
	width: 100%;
	padding: 0.125rem;
	overflow: hidden;
}
li.moreResults {
	width: 100%;
	padding: 0.125rem;
	padding-left: 0.5rem;
}
li.moreResults:hover {
	background: none;
	cursor: default;
}
.vueDropdownSearchBar {
	background-image: url("/images/icon-search-black.svg");
	width: 100%;
	background-repeat: no-repeat;
	background-position: 8px 10px;
	padding: 8px 20px 8px 35px;
	font-size: 12px;
	border: none;
}

.mainDropdown img {
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	position: absolute;
	margin: 0 14px;
}

.mainDropdown svg:hover {
	cursor: pointer;
	fill: var(--c-black);
}

.labelContainer {
	display: inline-block;
	width: 92%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.labelContainer__span {
	line-height: 25px;
}
</style>
