<template>
	<button
		class="tw-inline-flex tw-justify-center tw-items-center"
		:class="[color, ...variants]"
	>
		<slot name="prepend" />
		<div><slot /></div>
		<slot name="append" />
	</button>
</template>

<script>
export default {
	name: "BaseButton",
	props: {
		color: {
			type: String,
			validator(value) {
				return ["blue", "green", "orange", "black", "white", "red", "inherit"].includes(value);
			},
			default: "blue",
		},
		variants: {
			type: Array,
			validator(value) {
				return value.every((variant) => ["mini", "pill", "outline", "full", "link", ""].includes(variant));
			},
			default: () => [],
		},
	},
};
</script>

<style scoped>
button {
	font-size: var(--sm);
	font-weight: 700;
	cursor: pointer;
	border: 0;
	padding: .75rem 1.5rem;
	outline: none;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	border-radius: .25rem;
}
.pill {
	border-radius: 9999px;
}
.mini {
	font-size: var(--xs);
	padding: 0.5rem 1rem;
}
.full {
	display: flex;
}

.black {
	background-color: var(--black-high);
	color: var(--white-high);
}

.white {
	color: var(--blue-regular);
	background-color: var(--white-high);
}
.white:hover,
.white:active {
	background-color: var(--white-slightly);
}

.blue {
	background-color: var(--blue-regular);
	color: var(--white-high);
}
.blue:hover,
.blue:active {
	background-color: var(--blue-dark);
}

.green {
	color: var(--white-high);
	background-color: var(--green-regular);
}
.green:hover,
.green:active {
	background-color: var(--green-dark);
}

.orange {
	background-color: var(--orange-regular);
	color: var(--white-high);
}
.orange:hover,
.orange:active {
	background-color: var(--orange-dark);
}

.red {
	background-color: var(--origami-red-400);
	color: var(--origami-white);
}
.red:hover,
.red:active {
	background-color: var(--origami-red-300);
}

.outline {
	background-color: transparent;
	color: var(--black-high);
	box-shadow: inset 0 0 0 2px var(--grey-dark);
}
.outline:hover,
.outline:active {
	background-color: var(--grey-regular);
	color: var(--black-high);
}

.outline.white {
	color: var(--white-high);
	box-shadow: inset 0 0 0 2px var(--white-high);
}
.outline.white:hover,
.outline.white:active {
	background-color: var(--white-medium);
}

.outline.black {
	color: var(--black-high);
	box-shadow: inset 0 0 0 2px var(--black-high);
}
.outline.black:hover,
.outline.black:active {
	background-color: var(--black-disabled);
}

.link {
	background-color: transparent;
	padding: 0;
	border: 0;
	border-radius: 0;
}

.link.blue {
	background-color: transparent;
	color: var(--blue-regular);
}
.link.blue:hover,
.link.blue:active {
	background-color: transparent;
	color: var(--blue-dark);
}

.link.black {
	background-color: transparent;
	color: var(--black-high);
}
.link.black:hover,
.link.black:active {
	background-color: transparent;
	color: var(--black-slightly);
}

:disabled {
	background-color: var(--black-disabled);
	color: var(--black-high);
	cursor: not-allowed;
}

:disabled:hover {
	background-color: var(--black-disabled);
	color: var(--black-high);
}

.blue:focus-visible, .green:focus-visible,
.orange:focus-visible, .black:focus-visible,
.white:focus-visible, .red:focus-visible,
.link:focus-visible, .outline:focus-visible {
	outline: 2px solid var(--origami-blue-200);
}
</style>
