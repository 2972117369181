<template>
	<ServiceDashboardSummaryCard>
		<template #cardTitle>
			Review Center
		</template>

		<template
			v-if="badges.length > 0"
			#badges
		>
			<span
				v-for="badge in badgesFiltered"
				:key="badge.color"
				v-data-cy="'essays-count-badge'"
				:class="`summaryCard__badge tw-leading-loose ${badge.backgroundColorClass}`"
			>
				{{ badge.num }} {{ badge.text }}
			</span>
			<IconInformationCircle
				v-b-tooltip.hover.focus="tooltipOptions"
				class="summaryCard__icon"
				width="1.25rem"
				height="1.25rem"
			/>
		</template>

		<template #leftTitle>
			In Queue
		</template>

		<template #leftNumber>
			<span
				v-data-cy="'essays-in-queue'"
				class="summaryCardContent__leftNumber"
				:class="{ loading: isLoadingSummary }"
			>
				{{ inQueueNum }}
			</span>
		</template>

		<template #leftDetails>
			<div class="summaryCardContent__details">
				<span
					id="essayReviewedNumber"
					class="summaryCardContent__detailsItem"
				>
					<strong
						v-data-cy="'essays-fresh'"
						:class="{ loading: isLoadingSummary }"
					>
						{{ newInQueueNum }}
					</strong>
					&nbsp;New
				</span>
				<span class="summaryCardContent__detailsItem">
					<strong
						v-data-cy="'essays-inProgress'"
						:class="{ loading: isLoadingSummary }"
					>
						{{ inProgressInQueueNum }}
					</strong>
					&nbsp;In Progress
				</span>
			</div>
		</template>

		<template #rightTitle>
			<span id="essayReviewedTitle">Reviewed {{ summaryPeriodString }}</span>
		</template>

		<template #rightNumber>
			<span
				id="essayReviewedNumber"
				v-data-cy="'essays-reviewed-today'"
				class="summaryCardContent__rightNumber"
				:class="{ loading: isLoadingSummary }"
			>
				{{ reviewedNum }}
			</span>
		</template>

		<template #rightDetails>
			<div class="summaryCardContent__details">
				<span class="summaryCardContent__detailsItem">
					<strong
						id="essayReviewedLateNumber"
						v-data-cy="'essays-late'"
						:class="{ loading: isLoadingSummary }"
					>
						{{ reviewedLateNum }}
					</strong>
					&nbsp;Late
				</span>
			</div>
		</template>
	</ServiceDashboardSummaryCard>
</template>

<script>
import { mapState } from "vuex";

import IconInformationCircle from "../../../components/elements/icons/InformationCircle.vue";

import ServiceDashboardSummaryCard from "./ServiceDashboardSummaryCard.vue";

export default {
	components: {
		ServiceDashboardSummaryCard,
		IconInformationCircle,
	},
	computed: {
		...mapState(["PlatformManager"]),
		summary() {
			return this.PlatformManager.ServiceSummaryDashboard.summary;
		},
		summaryPeriod() {
			return this.PlatformManager.ServiceSummaryDashboard.summaryPeriod;
		},
		isLoadingSummary() {
			return this.$store.getters["PlatformManager/ServiceSummaryDashboard/isLoadingSummary"];
		},
		badgesFiltered() {
			return this.badges.filter((b) => b.isShowing);
		},
		/**
		 * @returns {Array}
		 */
		badges() {
			return [
				{
					isShowing: this.isLateNumGreaterThanZero,
					num: this.lateNum,
					text: "Late",
					backgroundColorClass: "tw-bg-red-regular",
				},
				{
					isShowing: this.isCriticalNumGreaterThanZero,
					num: this.criticalNum,
					text: "Critical",
					backgroundColorClass: "tw-bg-salmon-regular",
				},
				{
					isShowing: this.isAtRiskNumGreaterThanZero,
					num: this.atRiskNum,
					text: "At Risk",
					backgroundColorClass: "tw-bg-yellow-light",
				},
				{
					isShowing: true,
					num: this.essayETANum + "h",
					text: "Latest ETA",
					backgroundColorClass: this.essayETABadgeColor,
				},
			];
		},
		/**
		 * @returns {Number}
		 */
		lateNum() {
			return this.summary.current_late_essays;
		},
		/**
		 * @returns {String}
		 */
		essayETABadgeColor() {
			return this.essayETANum < 25 ? "tw-bg-blue-regular" : "tw-bg-orange-dark";
		},
		/**
		 * @returns {Number}
		 */
		criticalNum() {
			return this.summary.critical_essays;
		},
		/**
		 * @returns {Number}
		 */
		atRiskNum() {
			return this.summary.at_risk_essays;
		},
		/**
		 * @returns {Number}
		 */
		essayETANum() {
			const currentTimestamp = Date.now();
			const latestEssayReviewEta = this.summary.latest_essay_review_eta * 1000;
			const differenceInHour = (latestEssayReviewEta - currentTimestamp) / (3600 * 1000);
			return Math.ceil(differenceInHour);
		},
		/**
		 * @returns {Number}
		 */
		inQueueNum() {
			return this.summary.queued_essays;
		},
		/**
		 * @returns {Number}
		 */
		newInQueueNum() {
			return this.summary.new_essays;
		},
		/**
		 * @returns {Number}
		 */
		inProgressInQueueNum() {
			return this.summary.in_progress_essays;
		},
		/**
		 * @returns {Number}
		 */
		reviewedNum() {
			return this.summary[this.summaryPeriod].reviewed_essays;
		},
		/**
		 * @returns {Number}
		 */
		reviewedLateNum() {
			return this.summary[this.summaryPeriod].reviewed_late_essays;
		},
		isSummaryPeriodToday() {
			return this.summaryPeriod === "today";
		},
		/**
		 * @returns {boolean}
		 */
		isLateNumGreaterThanZero() {
			return this.lateNum > 0;
		},
		/**
		 * @returns {boolean}
		 */
		isCriticalNumGreaterThanZero() {
			return this.criticalNum > 0;
		},
		/**
		 * @returns {boolean}
		 */
		isAtRiskNumGreaterThanZero() {
			return this.atRiskNum > 0;
		},
		summaryPeriodString() {
			return this.isSummaryPeriodToday ? "Today (EST)" : "Last 24h";
		},
		tooltipOptions() {
			return {
				placement: "rightbottom",
				title: "Latest ETA is the number of hours we expect to take us for reviewing last submitted essay",
			};
		},
	},
};
</script>

<style>
.summaryCard__icon {
	margin: 0.125rem 0.3125rem;
}
</style>
