import api from "./Api.js";

export default {
	/**
	 * Lists all grades
	 *@param {Array.String} includes - available options: "gradeGroups"
	 *
	 * @returns {AxiosPromise}
	 */
	list(params = {}) {
		return api().get(`grades`, { params });
	},
};
