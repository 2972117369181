var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "tw-relative",
      class: { "tw-z-0": !_setup.isFiltersSidebarOpen },
    },
    [
      _c(_setup.ServiceAnalyticsHeader, {
        staticClass:
          "tw-sticky tw-top-0 tw-pb-5 tw-z-10 tw-bg-origami-grey-100",
      }),
      _vm._v(" "),
      _setup.isLoading
        ? _c(_setup.BaseLoader, {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "analytics-loader",
                expression: "'analytics-loader'",
              },
            ],
            staticClass:
              "tw-h-12 tw-w-12 tw-mt-20 tw-text-blue-regular tw-m-auto",
            attrs: { thickness: "8px" },
          })
        : _c(_setup.ServiceAnalyticsContent, {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "analytics-content",
                expression: "'analytics-content'",
              },
            ],
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }