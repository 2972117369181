import { getErrorText } from "@/utilities/errorHandlingHelpers.js";

export default {
	methods: {
		/**
		 * @param {String} snackbarType
		 * @param {String} snackbarText
		 */
		snackbarMessage(snackbarType, snackbarText) {
			this.$store.dispatch("Snackbar/showSnackbar", {
				snackbarText,
				snackbarType,
			});
		},
		/**
		 * @param {Object} error
		 * @param {Object} errorActionMsg Action user try to perform
		 * @returns {String}
		 */
		getErrorMessage(error, errorActionMsg = "trying to create the shift") {
			let errorMessage = "";
			if (error.response.status === 409) {
				errorMessage = getErrorText(error.error_description);
			} else if (error.response.status === 422) {
				const errorDescription = error.response?.data?.error_description;
				errorMessage = typeof errorDescription === "string" ?
					errorDescription :
					Object.values(errorDescription)[0][0];
			} else {
				errorMessage = error.response?.data?.error_description || getErrorText(errorActionMsg);
			}
			return errorMessage;
		},
	},
};
