var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BModal",
    {
      ref: "service-schedule-create-shift-modal",
      attrs: {
        id: "service-schedule-create-shift-modal",
        "hide-header": true,
        "no-close-on-backdrop": true,
        "no-fade": true,
        centered: true,
        size: "sm",
      },
      nativeOn: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.hideModal()
        },
      },
    },
    [
      _c(
        "button",
        {
          directives: [
            {
              name: "data-cy",
              rawName: "v-data-cy",
              value: "btn-close-createShift",
              expression: "'btn-close-createShift'",
            },
          ],
          staticClass: "close createShift__closeBtn",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.hideModal()
            },
          },
        },
        [_vm._v("\n\t\t×\n\t")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "createShift__header" }, [
        _c("div", { staticClass: "createShift__headerText" }, [
          _vm._v("\n\t\t\tCreate New Shift\n\t\t"),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "createShift__body" },
        [
          _c("ServiceScheduleShiftsCalendarCreate", {
            ref: "shiftsCalendarCreate",
            staticClass: "createShift__calendar",
            attrs: {
              placeholder: "Select a time range",
              value: _vm.defaultShift,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "modal-footer" }, [
        _c(
          "div",
          { staticClass: "createShift__footer tw-w-full" },
          [
            _c(
              "BaseButton",
              {
                directives: [
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "save-shift-btn",
                    expression: "'save-shift-btn'",
                  },
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "create-shift-btn",
                    expression: "'create-shift-btn'",
                  },
                ],
                staticClass: "createShift__btn",
                attrs: {
                  type: "SUBMIT",
                  "is-disabled": _vm.isSubmitButtonDisabled,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.createShift()
                  },
                },
              },
              [
                _c("template", { slot: "text" }, [
                  _vm._v("\n\t\t\t\t\tSave\n\t\t\t\t"),
                ]),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "BaseButton",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "close-shift-btn",
                    expression: "'close-shift-btn'",
                  },
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "btn-cancel-createShift",
                    expression: "'btn-cancel-createShift'",
                  },
                ],
                staticClass: "createShift__btn",
                attrs: { type: "CANCEL" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.hideModal()
                  },
                },
              },
              [
                _c("template", { slot: "text" }, [
                  _vm._v("\n\t\t\t\t\tClose\n\t\t\t\t"),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }