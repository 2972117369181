var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 8 5",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Fill 1")]),
      _vm._v(" "),
      _c("desc", [_vm._v("Created with Sketch.")]),
      _vm._v(" "),
      _c("defs"),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Students-list-(classes-menu)",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
            transform: "translate(-245.000000, -411.000000)",
          },
        },
        [
          _c("path", {
            attrs: {
              id: "Fill-1",
              d: "M250.944006,411.113942 C250.944006,409.799381 250.123366,409.420661 249.118998,410.261399 L246.581046,412.385872 C246.053925,412.827434 246.053925,413.93014 246.581046,414.371103 L249.118998,416.495576 C250.126922,417.33929 250.944006,416.957628 250.944006,415.643033 L250.944006,411.113942 Z",
              fill: _vm.iconColor,
              transform:
                "translate(248.564855, 413.379150) scale(1, -1) rotate(-90.000000) translate(-248.564855, -413.379150) ",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }