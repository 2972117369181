<template>
	<component
		:is="emoji"
		v-if="emoji in $options.components"
		style="height: 1em; width: 1em;"
	/>
	<span v-else>
		{{ emoji }}
	</span>
</template>

<script>

export default {
	components: {
		AvatarBee: () => import("@/modules/Avatar/components/illustrations/AvatarBee.vue"),
		Beaver: () => import("./illustrations/emojis/Beaver.vue"),
		Bison: () => import("./illustrations/emojis/Bison.vue"),
		Buffalo: () => import("./illustrations/emojis/Buffalo.vue"),
		Camel: () => import("./illustrations/emojis/Camel.vue"),
		Cheetah: () => import("./illustrations/emojis/Cheetah.vue"),
		Chicken: () => import("./illustrations/emojis/Chicken.vue"),
		Dodo: () => import("./illustrations/emojis/Dodo.vue"),
		Dolphin: () => import("./illustrations/emojis/Dolphin.vue"),
		Elephant: () => import("./illustrations/emojis/Elephant.vue"),
		Fish: () => import("./illustrations/emojis/Fish.vue"),
		FishingPole: () => import("./illustrations/emojis/FishingPole.vue"),
		Flamingo: () => import("./illustrations/emojis/Flamingo.vue"),
		FlexedBiceps: () => import("./illustrations/emojis/FlexedBiceps.vue"),
		Fox: () => import("./illustrations/emojis/Fox.vue"),
		Frog: () => import("./illustrations/emojis/Frog.vue"),
		GlowingStar: () => import("./illustrations/emojis/GlowingStar.vue"),
		Hedgehog: () => import("./illustrations/emojis/Hedgehog.vue"),
		Handshake: () => import("./illustrations/emojis/Handshake.vue"),
		HatchingChick: () => import("./illustrations/emojis/HatchingChick.vue"),
		Llama: () => import("./illustrations/emojis/Llama.vue"),
		Mammoth: () => import("./illustrations/emojis/Mammoth.vue"),
		Medal: () => import("./illustrations/emojis/Medal.vue"),
		Memo: () => import("./illustrations/emojis/Memo.vue"),
		Monkey: () => import("./illustrations/emojis/Monkey.vue"),
		Owl: () => import("./illustrations/emojis/Owl.vue"),
		Parrot: () => import("./illustrations/emojis/Parrot.vue"),
		PartyPooper: () => import("./illustrations/emojis/PartyPooper.vue"),
		Rabbit: () => import("./illustrations/emojis/Rabbit.vue"),
		Raccoon: () => import("./illustrations/emojis/Raccoon.vue"),
		Ram: () => import("./illustrations/emojis/Ram.vue"),
		Reindeer: () => import("./illustrations/emojis/Reindeer.vue"),
		Rhino: () => import("./illustrations/emojis/Rhino.vue"),
		Seal: () => import("./illustrations/emojis/Seal.vue"),
		Swan: () => import("./illustrations/emojis/Swan.vue"),
		ThumbsUp: () => import("./illustrations/emojis/ThumbsUp.vue"),
		WavingHand: () => import("./illustrations/emojis/WavingHand.vue"),
		Wolf: () => import("./illustrations/emojis/Wolf.vue"),
		Worm: () => import("./illustrations/emojis/Worm.vue"),
	},
	props: {
		emoji: {
			type: String,
			required: true,
		},
	},
};
</script>
