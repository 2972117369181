<template>
	<div class="tw-py-4">
		<label
			v-jest="'label-questions-description'"
			class="tw-text-sm tw-font-bold"
		>
			{{ $t("anyQuestionsSoFar") }} *
		</label>
		<textarea
			id="comments"
			v-model="essayComments"
			v-jest="'comments'"
			v-data-cy="'comments'"
			class="tw-border-2 tw-border-grey-dark tw-rounded-md tw-p-2.5 tw-w-full placeholder:tw-text-xs"
			:class="{ 'tw-border-2 tw-border-solid tw-border-origami-red-400': isEssayCommentsErrorShowing }"
			name="comment"
			:placeholder="$t('additionalComments')"
		/>
		<span
			v-show="isEssayCommentsErrorShowing"
			v-jest="'comments-error'"
			class="tw-text-sm tw-font-bold tw-text-origami-red-400"
		>
			{{ $t("essayCommentsRequired") }}
		</span>
	</div>
</template>

<script>
export default {
	props: {
		isEssayCommentsErrorShowing: {
			type: Boolean,
			default: false,
		},
		value: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			essayComments: this.value,
		};
	},
	watch: {
		essayComments(value) {
			this.$emit("input", value);
		},
	},
};
</script>
