var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "footer",
    {
      staticClass:
        "tw-border-t tw-border-origami-grey-200 tw-bg-origami-grey-100 tw-mt-4",
    },
    [
      _c("div", { staticClass: "origami-container tw-flex tw-flex-col" }, [
        _c(
          "div",
          { staticClass: "tw-py-4 tw-flex" },
          [
            _c(_setup.PaperAppQRCode, {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "qr-code",
                  expression: "'qr-code'",
                },
              ],
              staticClass:
                "tw-mr-2 tw-h-16 tw-w-16 tw-border-solid tw-border-2 tw-border-origami-black tw-rounded-lg tw-p-1",
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tw-flex tw-flex-col tw-place-content-between" },
              [
                _c(
                  "h2",
                  { staticClass: "tw-font-bold tw-text-base tw-leading-none" },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.$t("download_paper_app")) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "tw-flex tw-mt-2" }, [
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "link-to-googleplaystore",
                          expression: "'link-to-googleplaystore'",
                        },
                      ],
                      staticClass: "tw-mr-2",
                      attrs: {
                        href: "https://play.google.com/store/apps/details?id=com.gradeslam",
                        "aria-label": _vm.$t("google_play_store"),
                      },
                    },
                    [
                      _c(_setup.IconGooglePlayStore, {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "icon-play-store",
                            expression: "'icon-play-store'",
                          },
                        ],
                        attrs: {
                          width: "125",
                          height: "38",
                          "aria-hidden": "true",
                          focusable: "false",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "link-to-appleappstore",
                          expression: "'link-to-appleappstore'",
                        },
                      ],
                      attrs: {
                        href: "https://apps.apple.com/ca/app/paper-education/id1360686296",
                        "aria-label": _vm.$t("apple_app_store"),
                      },
                    },
                    [
                      _c(_setup.IconAppleStore, {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "icon-apple-store",
                            expression: "'icon-apple-store'",
                          },
                        ],
                        attrs: {
                          width: "124",
                          height: "38",
                          "aria-hidden": "true",
                          focusable: "false",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "tw-border-t tw-border-origami-grey-200 tw-text-center xs:tw-flex xs:tw-justify-start tw-text-sm tw-leading-tight tw-py-4",
          },
          [
            _c("p", { staticClass: "tw-font-bold tw-mb-3 xs:tw-mb-0" }, [
              _c("span", { staticClass: "tw-font-normal" }, [_vm._v("©")]),
              _vm._v(
                "\n\t\t\t\tPaper 2022 " +
                  _vm._s(_vm.$t("rights_reserved")) +
                  "\n\t\t\t"
              ),
            ]),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "tw-hidden xs:tw-inline tw-ml-4 tw-mr-3" },
              [_vm._v("•")]
            ),
            _vm._v(" "),
            _c("span", [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "link-to-privacypolicy",
                      expression: "'link-to-privacypolicy'",
                    },
                  ],
                  staticClass:
                    "privacy-policy tw-text-origami-brand-blue tw-rounded tw-px-0.5 hover:tw-bg-origami-cyan-200 hover:tw-text-origami-brand-blue focus:tw-bg-origami-cyan-200 focus:tw-text-origami-brand-blue focus:tw-outline-none active:tw-bg-origami-cyan-200 active:tw-text-origami-brand-navy",
                  attrs: { href: "https://paper.co/privacy-policy-services" },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\t" +
                      _vm._s(_vm.$t("privacy_policy")) +
                      "\n\t\t\t\t"
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }