var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sectionEmptyState" }, [
    _c("div", { staticClass: "sectionEmptyState__text" }, [
      _vm._v("\n\t\t" + _vm._s(_vm.emptyStateText) + "\n\t"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }