import ChallengesApi from "../services/index.js";

export function transformChallenge(challengeFromApi) {
	const { challenges, ...challenge } = challengeFromApi;
	return { ...challenge, goals: challenges };
}

export const getDefaultState = () => ({
	challenge: null,
	goals: [],
});

const state = getDefaultState();

export const mutations = {
	SET_CHALLENGE(state, payload) {
		state.challenge = payload.challenge;
	},
	SET_GOALS(state, payload) {
		state.goals = payload.goals;
	},
};

export const actions = {
	getChallengesData: async({ commit }) => {
		try {
			const response = await ChallengesApi.getChallengesData();

			// Future proof to support multi-circles payload
			// Starting with a unique one for first slices
			const index = 0;
			const challenge = transformChallenge(response.data.data[index]);

			commit("SET_CHALLENGE", { challenge });
			commit("SET_GOALS", { goals: response.data.data[index].challenges });
		} catch (error) {
			Sentry.captureException(error);
		}
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
