<template>
	<div class="tw-shadow-elevation-4">
		<div class="tw-flex tw-justify-between tw-items-center">
			<h2
				v-jest="'title'"
				class="tw-font-bold tw-text-lg tw-pl-6 tw-pr-4 tw-py-4"
			>
				Edit Shift
			</h2>
			<h3 class="tw-text-[#6A6B74] tw-font-bold tw-mr-6">
				Extend Shift
			</h3>
		</div>
		<hr>
		<div v-if="!updatedShift">
			<div class=" tw-px-6 tw-py-4">
				<p
					v-jest="'extend-shift-message'"
					class="tw-pb-4"
				>
					Do you want to add 15 minutes to this shift block?
				</p>
				<ShiftSegmentTypeTag
					v-jest="'shift-segment-type-tag'"
					:shift-type-id="shift.shiftType?.data.id"
				/>
				<p
					v-jest="'tutor-info'"
					class="tw-font-bold tw-pt-4 tw-text-sm"
				>
					{{ name }} [{{ selectedTutor.id }}]
				</p>
				<p
					v-jest="'start-time'"
					class="tw-pt-2 tw-text-sm"
				>
					{{ formattedStartTime }}
				</p>
				<p
					v-jest="'end-time'"
					class="tw-pt-2 tw-text-sm"
				>
					{{ formattedEndTime }}
				</p>
			</div>
			<div class="tw-flex tw-justify-between tw-pb-4 tw-pt-4 tw-px-6">
				<OrigamiButton
					v-jest="'cancel-extend-button'"
					data-cy="cancel-extend-button"
					variant="secondary"
					@click.native="cancel()"
				>
					Cancel
				</OrigamiButton>
				<OrigamiButton
					v-jest="'confirm-extend-button'"
					data-cy="confirm-extend-button"
					variant="primary"
					@click.native="extendShift()"
				>
					Confirm
				</OrigamiButton>
			</div>
			<ServiceScheduleTutorErrorMessage
				v-if="hasAnError"
				v-jest="'error-message'"
				class="tw--mb-16"
				:error="error"
			/>
		</div>
		<ServiceScheduleTutorSuccessfulAction
			v-else
			:shift="shift"
			:updated-shift="updatedShift"
			:success-message-in-sidebar="{
				message: 'You have successfully edited the shift.',
				buttonText: 'Complete',
			}"
			@cancel-edit="cancel()"
		/>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { OrigamiButton } from "@origami/vue2";
import { isEmpty, isNil } from "lodash";
import { format } from "date-fns";

import { formatTimestampToDate, formatDateToTimestamp } from "@/utilities/dateHelpers.js";
import ServiceScheduleTutorSuccessfulAction from "@/modules/ServiceSchedule/components/ServiceScheduleTutorSuccessfulAction.vue";

import ServiceScheduleTutorErrorMessage from "./ServiceScheduleTutorErrorMessage.vue";
import ShiftSegmentTypeTag from "./ShiftSegmentTypeTag.vue";

export default {
	components: {
		ServiceScheduleTutorSuccessfulAction,
		ServiceScheduleTutorErrorMessage,
		OrigamiButton,
		ShiftSegmentTypeTag,
	},
	props: {
		shift: {
			type: Object,
			required: true,
			validator(value) {
				return (
					value.hasOwnProperty("start") &&
					value.hasOwnProperty("end") &&
					value.hasOwnProperty("punchIn") &&
					value.hasOwnProperty("name") &&
					value.hasOwnProperty("id") &&
					value.hasOwnProperty("shiftColor") &&
					value.hasOwnProperty("shiftType") &&
					value.hasOwnProperty("shiftEnded") &&
					value.hasOwnProperty("isSelected")
				);
			},
		},
		selectedTutor: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			error: null,
			updatedShift: null,
		};
	},
	computed: {
		...mapState(["PlatformManager", "TutorSchedule"]),
		smaShift() {
			return this.TutorSchedule.shifts
				.find((shift) => shift.shift_segments
					?.some((segment) => segment._metadata.gs_api_transformer.id === this.shift.id));
		},
		name() {
			return isNil(this.shift.user) ?
				this.shift.name :
				`${this.shift.user.data.first_name} ${this.shift.user.data.last_name}`;
		},
		startTime() {
			return isNil(this.shift.start_time) ?
				new Date(this.shift.start) :
				formatTimestampToDate(this.shift.start_time);
		},
		endTime() {
			return isNil(this.shift.end_time) ?
				new Date(this.shift.end) :
				formatTimestampToDate(this.shift.end_time);
		},
		formattedStartTime() {
			return "Start: " + format(this.startTime, "PPpp");
		},
		formattedEndTime() {
			return "End: " + format(this.endTime, "PPpp");
		},
		hasAnError() {
			return !isEmpty(this.error);
		},
	},
	methods: {
		async extendShift() {
			try {
				const result = await this.$store.dispatch("TutorSchedule/extendShift", this.smaShift.id);
				this.updatedShift = {
					schedule_type_id: this.PlatformManager.ServiceTutorsSchedule.currentShiftType?.id,
					name: this.PlatformManager.ServiceTutorsSchedule.currentShiftType?.name,
					start_time: formatDateToTimestamp(new Date(result.start_time)),
					end_time: formatDateToTimestamp(new Date(result.end_time)),
				};
				this.$emit("complete-extend");
			} catch (e) {
				this.error = e;
				Sentry.captureException(e);
			}
		},
		cancel() {
			this.$emit("cancel-edit");
			this.error = null;
		},
	},
};

</script>
