var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BreakButton", {
    directives: [
      {
        name: "jest",
        rawName: "v-jest",
        value: "bio-break-button",
        expression: "'bio-break-button'",
      },
      {
        name: "data-cy",
        rawName: "v-data-cy",
        value: "bio-break-button",
        expression: "'bio-break-button'",
      },
    ],
    attrs: {
      "aria-label": "Go on bio break",
      "icon-name": "bio-break",
      "is-active": _vm.isOnBioBreakWorkflow,
      disabled: _vm.isDisabled,
    },
    nativeOn: {
      click: function ($event) {
        return _vm.requestBioBreak.apply(null, arguments)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }