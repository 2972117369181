var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "icon__transformFix",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: _vm.width,
        height: _vm.height,
        viewBox: _vm.viewbox,
      },
    },
    [
      _c("defs", [
        _c("path", {
          attrs: {
            id: _vm.randomId,
            d: "M3.996 0L0 4.23 12.98 18 0 31.77 3.996 36 21 18z",
          },
        }),
      ]),
      _vm._v(" "),
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("mask", { attrs: { id: `a${_vm.randomId}`, fill: "#fff" } }, [
          _c("use", { attrs: { "xlink:href": `#${_vm.randomId}` } }),
        ]),
        _vm._v(" "),
        _c("use", {
          attrs: { fill: "#484756", "xlink:href": `#${_vm.randomId}` },
        }),
        _vm._v(" "),
        _c(
          "g",
          { attrs: { fill: _vm.iconColor, mask: `url(#a${_vm.randomId})` } },
          [_c("path", { attrs: { d: "M-18-12h60v60h-60z" } })]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }