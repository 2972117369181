var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.countdownText
    ? _c(
        "div",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "punch-clock-countdown",
              expression: "'punch-clock-countdown'",
            },
          ],
          staticClass:
            "punch-tooltip tw-bg-origami-white tw-absolute tw-left-[50%] tw-w-auto tw-translate-x-[-50%] tw-shadow-elevation-2 tw-rounded tw-p-2 tw-text-[14px] tw-leading-none tw-whitespace-nowrap tw-text-origami-black tw-block tw-z-50 after:tw-content-[''] after:tw-absolute after:tw-top-[-6px] after:tw-left-1/2 after:tw-translate-x-[-50%]",
        },
        [
          _c("div", { attrs: { id: "tooltip" } }, [
            _c(
              "p",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "punch-clock-countdown-text",
                    expression: "'punch-clock-countdown-text'",
                  },
                ],
                staticClass: "tw-font-bold tw-text-blue-regular",
                class: { "tw-text-orange-dark": _setup.isLate },
              },
              [_vm._v("\n\t\t\t" + _vm._s(_setup.countdownText) + "\n\t\t")]
            ),
            _vm._v(" "),
            _setup.isLockedOutOfShift
              ? _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "punch-clock-locked-message",
                        expression: "'punch-clock-locked-message'",
                      },
                    ],
                    staticClass:
                      "tw-block tw-text-[0.75rem] tw-text-origami-black tw-leading-4 tw-mt-2",
                  },
                  [
                    _vm._v("\n\t\t\tPlease Contact a"),
                    _c("br"),
                    _vm._v("\n\t\t\tPlatform Manager\n\t\t"),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }