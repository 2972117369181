export default {
	en: {
		referrals: {
			title: "Refer a friend",
			unlock: "Unlock my referral link",
			copy_success: "Copied!",
			error: "Something went wrong, please try again",
			description: "Get your friends at school to join Paper by sending them this invite link. Spread the learning!",
			description_prize: "Get a chance to win AirPods by sending your friends at school this invite link to join Paper. See terms and conditions below.",
			success: "You have successfully redeemed a referral link!",
			failed: "The referral link redemption was unsuccessful.",
			input_title: "My referral link",
			params: {
				intro: "Referrals are valid if you refer someone who:",
				only_access: "has a Paper account",
				redeem_once: "has not redeemed another referral",
			},
			terms: {
				title: "Terms & Conditions",
				description: "Open to licensed student users at Districts allowing participation in the Sweepstakes. Entries may be earned by sharing or receiving and using referral links (see the complete <a href=\"{url}\" target=\"_blank\">Sweepstakes Rules</a> for more information). Sweepstakes runs from 3/15/23 to 4/14/23, with random winner selection by 5/5/23. Districts may opt-out of Paper contest/sweepstakes at any time by contacting Paper Account Representative.",
			},
			view_history: "View my referral history",
			history_title: "My referral history",
			redemption: "A friend used your referral link on",
			no_redemptions: "Your friends have not logged in with your referral link yet.",
			share_code: "Share your link with friends to help them learn!",
		},
	},
};
