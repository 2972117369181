var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M7.75858 14.4019C7.94711 14.3219 8.16709 14.3877 8.2769 14.5588L9.93169 17.1403L10.1497 17.4803L10.3604 17.8092C10.5712 17.6875 10.7895 17.581 11.01 17.4803C11.184 17.4011 11.3604 17.3289 11.5396 17.2636C11.6038 17.2394 11.6688 17.216 11.7346 17.1946C11.7964 17.1736 11.8582 17.1526 11.9207 17.1352C12.0645 17.0908 12.209 17.05 12.3559 17.0159C12.3721 17.0123 12.3886 17.0096 12.4052 17.0096H12.4871C12.523 17.0096 12.5578 17.0191 12.5889 17.0353C12.9651 17.2331 13.479 17.3464 14.0014 17.3464C14.523 17.3464 15.0377 17.2331 15.4127 17.0353C15.4438 17.0191 15.4794 17.0104 15.5149 17.0104H15.6275C15.6638 17.0104 15.6994 17.0195 15.7305 17.0361C15.8645 17.0686 15.9969 17.1063 16.1281 17.1483C16.1802 17.1637 16.2314 17.1807 16.2827 17.1978C16.3578 17.2224 16.4317 17.2489 16.506 17.2758C17.4522 17.6241 18.3291 18.1674 19.0751 18.8786C19.1171 18.9187 19.1401 18.9729 19.1401 19.0304V19.788C19.1401 19.9049 19.0441 20 18.925 20H9.07825C8.95875 20 8.86149 19.9049 8.86149 19.788V19.6913C8.84049 19.6576 8.82273 19.6216 8.81183 19.5824L8.23574 17.4803L7.86312 16.1204L7.52359 14.8822C7.47031 14.6872 7.57005 14.4827 7.75858 14.4019ZM21.3067 4C21.6893 4 22 4.30436 22 4.67966V16.5564C22 16.9325 21.6893 17.2365 21.3067 17.2365H19.7828C19.0947 16.6693 18.3303 16.2108 17.5154 15.8767H20.613V5.3601H3.38659V15.8767H6.63972L7.01264 17.2365H2.69327C2.30986 17.2365 2 16.9325 2 16.5564V4.67966C2 4.30436 2.30986 4 2.69327 4H21.3067ZM14.2242 10.0989C15.5048 10.0989 16.5426 11.1781 16.5426 12.5094C16.5426 12.7818 16.4982 13.0431 16.4179 13.2881C16.5564 13.3519 16.6533 13.496 16.6533 13.6646C16.6533 13.8934 16.4756 14.0783 16.2563 14.0783L16.2486 14.0779C16.1666 15.1662 15.2919 16.2549 14.2242 16.2549C13.1557 16.2549 12.281 15.1662 12.199 14.0779L12.1917 14.0783C11.9725 14.0783 11.7944 13.8934 11.7944 13.6646C11.7944 13.496 11.8913 13.3519 12.0302 13.2881C11.9499 13.0431 11.9054 12.7818 11.9054 12.5094C11.9054 11.1781 12.9437 10.0989 14.2242 10.0989Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }