var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "tw-flex tw-p-4 tw-gap-x-4 tw-bg-white-high tw-shadow-elevation-1 tw-cursor-pointer hover:tw-shadow-elevation-2",
        class: { "tw-mb-4": !_vm.isLoadingState },
      },
      [
        _c("BaseTutorImage", {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "profile-image",
              expression: "'profile-image'",
            },
          ],
          attrs: {
            dimensions: 56,
            "full-tutor-name": _vm.fullTutorName,
            "profile-path": _vm.profileImage,
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "tw-flex-grow" }, [
          _vm.includeShiftStatus
            ? _c(
                "div",
                { class: { "tw-grid tw-grid-cols-2": _vm.includeShiftStatus } },
                [
                  _c(
                    "h3",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "name",
                          expression: "'name'",
                        },
                      ],
                      staticClass: "tw-text-xl tw-mb-1 tw-font-bold",
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.fullTutorName) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._m(0),
                ]
              )
            : _c(
                "h3",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "name",
                      expression: "'name'",
                    },
                  ],
                  staticClass: "tw-text-xl tw-mb-1 tw-font-bold",
                },
                [_vm._v("\n\t\t\t\t" + _vm._s(_vm.fullTutorName) + "\n\t\t\t")]
              ),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "tw-text-xs tw-mb-2" },
            [_vm._t("metaDataIcon"), _vm._v(" "), _vm._t("metadata")],
            2
          ),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "tw-text-blue-regular tw-text-base tw-font-bold" },
            [_vm._t("breakDetailsIcon"), _vm._v(" "), _vm._t("breakDetails")],
            2
          ),
          _vm._v(" "),
          _vm.$slots.buttons
            ? _c(
                "div",
                { staticClass: "tw-flex tw-justify-end tw-mt-3" },
                [_vm._t("buttons")],
                2
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoadingState,
          expression: "isLoadingState",
        },
      ],
      staticClass: "animatedLoadingBar tw-mb-4",
    }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "tw-px-1 tw-inline-flex tw-items-center tw-justify-end" },
      [
        _c(
          "span",
          {
            staticClass:
              "tw-font-bold tw-text-sm tw-mb-px tw-text-origami-red-300",
          },
          [_vm._v("\n\t\t\t\t\t\tLate\n\t\t\t\t\t")]
        ),
        _vm._v(" "),
        _c("span", {
          staticClass:
            "tw-w-2 tw-h-2 tw-rounded-full tw-ml-1 tw-bg-origami-red-300",
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }