<template>
	<div class="tw-mx-6">
		<ListItemLayout class="tw-border-origami-grey-200">
			<template #content>
				<p
					v-jest="'item-name'"
					class="ellipsis tw-font-bold tw-overflow-hidden"
				>
					{{ groupName }}
				</p>
				<p
					v-jest="'item-students-count'"
					class="tw-text-sm tw-text-origami-grey-300"
				>
					{{ studentsCount }} students
				</p>
			</template>

			<template #actions>
				<div class="tw-whitespace-nowrap">
					<BaseIconButton
						v-jest="'edit-button'"
						:aria-label="`Navigate to edit ${groupName} group`"
						@click.native="$router.push(`/${currentUser.role}/student-groups/edit/${groupId}`)"
					>
						<PenIcon />
					</BaseIconButton>

					<BaseIconButton
						v-jest="'delete-button'"
						:aria-label="`Delete group ${groupName}`"
						@click.native="$emit('delete-item', groupId)"
					>
						<TrashIcon />
					</BaseIconButton>
				</div>
			</template>
		</ListItemLayout>
	</div>
</template>

<script>
import { mapState } from "vuex";

import PenIcon from "../../../../components/elements/icons/Pen.vue";
import TrashIcon from "../../../../components/elements/icons/Trash.vue";
import BaseIconButton from "../../../../components/elements/BaseIconButton.vue";

import ListItemLayout from "./ListItemLayout.vue";

export default {
	components: {
		ListItemLayout,
		TrashIcon,
		PenIcon,
		BaseIconButton,
	},
	props: {
		groupId: {
			type: Number,
			required: true,
		},
		groupName: {
			type: String,
			required: true,
		},
		studentsCount: {
			type: Number,
			required: true,
		},
	},
	computed: {
		...mapState(["currentUser"]),
	},
};
</script>

<style scoped>
/*
	-webkit-line-clamp is required because line-clamp doesn't work in all browsers (eg. Chrome)
	we need -webkit-line-clamp, but it, in turn, doesn't work with display:flex,
	so we need display:-webkit-box (rather than most recent flex)
	https://css-tricks.com/almanac/properties/l/line-clamp/#aa-so-whats-the-catch
*/
.ellipsis {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
</style>
