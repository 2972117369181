import middleware from "@/middleware/index.js";
import { MICRO_FRONTENDS_NAMES } from "@/modules/MicroFrontend/utilities/index.js";
import withNavbar from "@/middleware/withNavbar.js";

import doesStudentNeedOnboarding from "../middleware/doesStudentNeedOnboarding.js";

export default [
	{
		name: "studentOnboarding",
		path: "student/onboarding*",
		meta: {
			title: "Onboarding",
		},
		component: () => import("@/modules/MicroFrontend/components/MicroFrontend.vue"),
		beforeEnter: middleware([
			doesStudentNeedOnboarding,
			(to, from, next) => {
				import("../assets/css/style.css");
				document.body.classList.add("disable-scroll-onboarding");
				next();
			},
			withNavbar(false),
		]),
		props: {
			microFrontendName: MICRO_FRONTENDS_NAMES.STUDENT_ONBOARDING,
			isAlwaysLoading: true,
		},
	},
];
