<template>
	<div class="emptyItem">
		<component
			:is="iconComponent"
			class="emptyItem__icon"
			icon-color="#c8c8cc"
			:height="iconDimensions.height"
			:width="iconDimensions.width"
		/>
		<div class="emptyItem__message">
			{{ noItemsText }}
		</div>
	</div>
</template>
<script>
import { differenceInMinutes } from "date-fns";

import IconSession from "@/components/icons/IconSession.vue";
import IconEssay from "@/components/icons/IconEssay.vue";

export default {
	components: {
		IconSession,
		IconEssay,
	},
	props: {
		resourceType: {
			required: true,
			validator(value) {
				return value === "session" || value === "essay";
			},
		},
		timeSinceLastActivity: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		/**
		 * Show message for no sessions and essays
		 * @return {String}
		 */
		noItemsText() {
			const noPreviousActivity = this.timeSinceLastActivity === null;
			const isHasPreviousActivity = typeof this.timeSinceLastActivity === "number";
			const isSession = this.resourceType === "session";
			const isEssay = this.resourceType === "essay";

			let noItemsText = "";
			if (noPreviousActivity && isSession) {
				noItemsText = "Has no ongoing sessions";
			} else if (noPreviousActivity && isEssay) {
				noItemsText = "Has not picked up any submission yet";
			} else if (isHasPreviousActivity && isEssay) {
				noItemsText = `${this.minutesElapsed} min since last reviewed essay`;
			} else if (isHasPreviousActivity && isSession) {
				noItemsText = `${this.minutesElapsed} min since last session`;
			}
			return noItemsText;
		},
		/**
		 * Get icon component for session or essay
		 */
		iconComponent() {
			let componentName = "";
			if (this.resourceType === "session") {
				componentName = "IconSession";
			} else if (this.resourceType === "essay") {
				componentName = "IconEssay";
			}
			return componentName;
		},
		iconDimensions() {
			return {
				width: 12,
				height: this.resourceType === "session" ? 12 : 15,
			};
		},
		minutesElapsed() {
			return differenceInMinutes(new Date().getTime(), this.timeSinceLastActivity * 1000);
		},
	},
};
</script>
<style scoped>
.emptyItem__icon {
	margin-right: 10px;
}
.emptyItem__message {
	font-size: 12px;
	font-style: italic;
	color: #c8c8cc;
}
.emptyItem {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 8px 8px 8px 8px;
	box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.1);
	height: 45px;
}
</style>
