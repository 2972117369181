<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		viewBox="0 0 32 32"
	>
		<path
			:fill="`#${iconColor}`"
			fill-rule="evenodd"
			d="M16 3.8a4.2 4.2 0 1 1 .001 8.399A4.2 4.2 0 0 1 16 3.8zm0 18c5.94 0 12.2 2.92 12.2 4.2v2.2H3.8V26c0-1.28 6.26-4.2 12.2-4.2zM16 0c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm0 18c-5.34 0-16 2.68-16 8v6h32v-6c0-5.32-10.66-8-16-8z"
		/>
	</svg>
</template>
<script>
import Colors from "../../utilities/Colors.js";

export default {
	props: {
		width: {
			type: [Number, String],
			default: 32,
		},
		height: {
			type: [Number, String],
			default: 32,
		},
		iconColor: {
			type: String,
			default: Colors.C_BLUE,
		},
	},
};
</script>
