export default {
	namespaced: true,
	state: {
		snackbarText: null,
		snackbarType: null,
	},

	mutations: {
		SET_SNACKBAR_TEXT(state, payload) {
			state.snackbarText = payload.snackbarText;
		},

		SET_SNACKBAR_TYPE(state, payload) {
			state.snackbarType = payload.snackbarType;
		},
	},

	actions: {
		resetSnackbar({ commit }) {
			try {
				commit("SET_SNACKBAR_TEXT", {
					snackbarText: null,
				});

				commit("SET_SNACKBAR_TYPE", {
					snackbarType: null,
				});
				return Promise.resolve();
			} catch (e) {
				return Promise.reject();
			}
		},

		showSnackbar({ commit }, payload) {
			try {
				commit("SET_SNACKBAR_TEXT", {
					snackbarText: payload.snackbarText,
				});

				commit("SET_SNACKBAR_TYPE", {
					snackbarType: payload.snackbarType,
				});
				return Promise.resolve();
			} catch (e) {
				return Promise.reject();
			}
		},
	},
};
