/**
 * Defines the object signature for an assessment
 * which is a key in the session object
 * @param {String}  createdAt
 * @param {Number}  id
 * @param {Number}  sessionId
 */
function Assessment(createdAt, assessmentId, sessionId, step = "recognitions") {
	this.completed_at = null;
	this.created_at = createdAt;
	this.email_sent_at = null;
	this.feedback = null;
	this.id = assessmentId;
	this.session_id = sessionId;
	this.step = step;
	this.student_goal = null;
	this.updated_at = null;
	this.shoutout = null;
}

export default Assessment;
