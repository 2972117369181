import { isNil } from "lodash";

import { MEAL_BREAK_ID } from "@/modules/TutorSchedule/index.js";

const ONE_SECOND_IN_MS = 1000;

const breaksManagementWidgetListener = {
	async connect() {
		this.$socket.emit("init.room", {}, () => { });
	},
	async schedule_break_cancelled({ data }) {
		if (!this.$store.state.TutorSchedule.isCancelledByTutor) {
			await this.$store.dispatch("TutorSchedule/setCancelledBreak", {
				gsApiSegmentId: data.deletedScheduleIds[0],
				isCancelledByTutor: false,
			});
			setTimeout(async() => {
				await this.$store.dispatch("TutorSchedule/getCurrentShiftAndState");
				await this.$store.dispatch("TutorSchedule/getCurrentUserUpcomingShifts");
				if (!isNil(this.$store.state.TutorSchedule.cancelledBreak)) {
					this.$store.dispatch("BreaksManagementWidget/cancelBreakExternal");
				}
			}, ONE_SECOND_IN_MS * 2);
		}
	},
	async schedule_break_request_approved({ data }) {
		if (!data.approved_request) {
			return;
		}

		await this.$store.dispatch("TutorSchedule/setApprovedBreakRequest", {
			startTime: data.approved_request.start_time,
			endTime: data.approved_request.end_time,
		});

		setTimeout(async() => {
			await this.$store.dispatch("TutorSchedule/getCurrentShiftAndState");

			if (data.approved_request.schedule_type_id === MEAL_BREAK_ID) {
				await this.$store.dispatch("BreaksManagementWidget/setMealBreakRequestApproved");
			}
		}, ONE_SECOND_IN_MS * 2);
	},
	async schedule_break_request_rejected({ data }) {
		await this.$store.dispatch("TutorSchedule/setRejectedBreakRequest", {
			startTime: data.start_time,
			endTime: data.end_time,
		});
		setTimeout(async() => {
			await this.$store.dispatch("TutorSchedule/getCurrentShiftAndState");
		}, ONE_SECOND_IN_MS * 2);
	},
};

export default breaksManagementWidgetListener;
