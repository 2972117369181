var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "tw-shadow-elevation-2",
      attrs: { id: "service-analytics-header" },
    },
    [
      _c(_setup.ServiceAnalyticsPageHeader, {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "analytics-page-header",
            expression: "'analytics-page-header'",
          },
        ],
      }),
      _vm._v(" "),
      _c("div", { staticClass: "tw-max-w-[1440px] tw-m-auto tw-px-8" }, [
        _c(
          "div",
          {
            staticClass:
              "tw-bg-origami-white tw-rounded-lg tw-border-origami-grey-200 tw-border-2 lg:tw-flex tw-px-8 tw-py-3 tw-mb-3 lg:tw-space-x-8 tw-divide-x-2 tw-divide-origami-grey-100",
            class: {
              "tw-bg-origami-grey-200 tw-rounded-none": _setup.isLoading,
            },
            attrs: { tabindex: "-1" },
          },
          _vm._l(_setup.state.alerts, function (alert) {
            return _c(_setup.ServiceAnalyticsSummaryCard, {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: `analytics-summary-${alert.cardID}`,
                  expression: "`analytics-summary-${alert.cardID}`",
                },
              ],
              key: alert.title,
              staticClass: "analytics-summary-cards",
              class: { "tw-invisible": _setup.isLoading },
              attrs: { "data-cy": `${alert.title}-summary-card`, alert: alert },
            })
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _c(_setup.ServiceAnalyticsFilters, {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "analytics-page-filters",
            expression: "'analytics-page-filters'",
          },
        ],
        staticClass: "tw-max-w-[1440px] tw-m-auto tw-px-8",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }