<template>
	<div>
		<component :is="restBreakComponent" />
	</div>
</template>
<script>
import { mapState } from "vuex";

import {
	REST_BREAK_REQUEST,
	REST_BREAK_CONFIRMATION,
	REST_BREAK_CANCEL,
	REST_BREAK_CANCELLED,
	REST_BREAK_ERROR,
	CANCEL_SCHEDULED_BREAK,
	REST_BREAK_END,
	REST_BREAK_IN_PROGRESS,
	REST_BREAK_LOCKED,
} from "../../utilities/BreakWorkflowSteps.js";

import RestBreakScheduleRequest from "./RestBreakScheduleRequest.vue";
import RestBreakConfirmation from "./RestBreakConfirmation.vue";
import RestBreakCancelButton from "./RestBreakCancelButton.vue";
import RestBreakCancelled from "./RestBreakCancelled.vue";
import RestBreakInProgress from "./RestBreakInProgress.vue";
import CancelScheduledRestBreak from "./CancelScheduledRestBreak.vue";
import RestBreakError from "./RestBreakError.vue";

export default {
	components: {
		RestBreakScheduleRequest,
		RestBreakConfirmation,
		RestBreakCancelButton,
		RestBreakInProgress,
		RestBreakCancelled,
		CancelScheduledRestBreak,
		RestBreakError,
	},
	computed: {
		...mapState(["BreaksManagementWidget"]),
		restBreakComponent() {
			const restBreakSteps = {
				[REST_BREAK_CANCEL]: "RestBreakCancelButton",
				[REST_BREAK_CONFIRMATION]: "RestBreakConfirmation",
				[REST_BREAK_REQUEST]: "RestBreakScheduleRequest",
				[REST_BREAK_CANCELLED]: "RestBreakCancelled",
				[CANCEL_SCHEDULED_BREAK]: "CancelScheduledRestBreak",
				[REST_BREAK_IN_PROGRESS]: "RestBreakInProgress",
				[REST_BREAK_ERROR]: "RestBreakError",
				[REST_BREAK_END]: "RestBreakEnd",
				[REST_BREAK_LOCKED]: "RestBreakInProgress",
			};

			return restBreakSteps[this.BreaksManagementWidget.currentBreakStep] || "";
		},
	},
};
</script>;
