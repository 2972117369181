import { FULTON_LIBRARY_SCHOOL_ID } from "../utilities/fulton.js";

export default async function(to, from, next) {
	const currentUser = this.state.currentUser;

	if (currentUser.school_id === FULTON_LIBRARY_SCHOOL_ID && !currentUser.email) {
		return next("/fulton-account-library-completion");
	} else {
		return next();
	}
}

