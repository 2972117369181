import api from "@/services/api/Api.js";

export default {
	/**
	 * Create new teacher content category
	 * @param {Object} params
	 * @return {AxiosPromise}
	 */
	createTeacherContentCategory(params) {
		return api().post("teacher-content-categories", params);
	},
	/**
	 * Get all teacher content categories
	 * @return {AxiosPromise}
	 */
	list() {
		return api().get("teacher-content-categories");
	},

	/**
	 * Update existing teacher content category
	 * @param {Object} params
	 * @return {AxiosPromise}
	 */
	update(teacherContentCategoryId, params) {
		return api().post(`teacher-content-categories/${teacherContentCategoryId}`, params);
	},

	/**
	 *
	 * @param {*} params
	 */
	create(params) {
		return api().post("teacher-content", params);
	},
};
