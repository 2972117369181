<template>
	<div>
		<div>
			<p
				class="assessment__text assessment__text--bold"
				v-html="
					$t('right_sidebar.assessment.tutor_to_professor_feedback', { name: studentName })
				"
			/>
			<div>
				<div class="assessment__feedbackLabel">
					{{ $t("right_sidebar.assessment.concerns") }}
					<IconExclamationMarkStroke />
				</div>
				<textarea
					v-data-cy="'assessment-concerns'"
					:value="feedback"
					class="assessment__concern"
					:placeholder="feedbackPlaceholder"
					:maxlength="maxFeedbackLength"
					@input="$emit('feedback-update', $event.target.value)"
				/>
				<p class="assessment__charactersLeft">
					<span
						v-if="isCharacterLimitReachedForConcern"
						v-jest="'feedback-character-limit'"
						class="assessment__charactersLeft--alert"
					>
						Character limit reached.
					</span>
					{{ `${charactersLeftForConcern}/${maxFeedbackLength}` }}
				</p>
			</div>
			<div>
				<StringWithEmojis
					class="assessment__feedbackLabel"
					:value="$t('right_sidebar.assessment.shout_out')"
				/>
				<textarea
					v-data-cy="'assessment-shoutout'"
					:value="shoutout"
					class="assessment__shoutout"
					:placeholder="shoutOutPlaceholder"
					:maxlength="maxShoutOutLength"
					@input="$emit('shoutout-update', $event.target.value)"
				/>
				<p class="assessment__charactersLeft">
					<span
						v-if="isCharacterLimitReachedForShoutOut"
						v-jest="'shoutout-character-limit'"
						class="assessment__charactersLeft--alert"
					>
						Character limit reached.
					</span>
					{{ `${charactersLeftForShoutOut}/${maxShoutOutLength}` }}
				</p>
			</div>
		</div>
		<Comment
			:is-submitting="isSubmitting"
			:student-id="studentId"
		/>
	</div>
</template>

<script>
import IconExclamationMarkStroke from "@/components/icons/IconExclamationMarkStroke.vue";

import Comment from "./Comment.vue";

export default {
	components: {
		StringWithEmojis: () => import("@/components/StringWithEmojis.js"),
		IconExclamationMarkStroke,
		Comment,
	},
	props: {
		studentName: {
			type: String,
			default: "",
		},
		studentId: {
			type: Number,
			default: 0,
		},
		feedback: {
			type: String,
			default: "",
		},
		shoutout: {
			type: String,
			default: "",
		},
		isSubmitting: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		maxFeedbackLength: 250,
		maxShoutOutLength: 250,
	}),
	computed: {
		/**
		 * @return {String}
		 */
		feedbackPlaceholder() {
			return this.$t("right_sidebar.assessment.explain_understanding", {
				name: this.studentName,
			});
		},
		/**
		 * @return {String}
		 */
		shoutOutPlaceholder() {
			return this.$t("right_sidebar.assessment.give_shout_out", {
				name: this.studentName,
			});
		},
		/**
		 * @return {Number}
		 */
		charactersLeftForConcern() {
			return this.feedback === null
				? this.maxFeedbackLength
				: this.maxFeedbackLength - this.feedback.length;
		},
		/**
		 * @return {Number}
		 */
		charactersLeftForShoutOut() {
			return this.shoutout === null || this.shoutout === undefined
				? this.maxShoutOutLength
				: this.maxShoutOutLength - this.shoutout.length;
		},
		/**
		 * @returns {Boolean}
		 */
		isCharacterLimitReachedForConcern() {
			return this.charactersLeftForConcern === 0;
		},
		/**
		 * @returns {Boolean}
		 */
		isCharacterLimitReachedForShoutOut() {
			return this.charactersLeftForShoutOut === 0;
		},
	},

};
</script>

<style scoped>
.assessment__text {
	font-size: 14px;
	margin-bottom: 12px;
	color: #484756;
}
.assessment__text--bold {
	font-weight: 600;
}
.assessment__feedbackLabel {
	font-size: 12px;
	font-weight: 600;
	margin-bottom: 8px;
}
.assessment__concern {
	font-size: 12px;
	height: 120px;
	padding: 14px 16px;
	line-height: 1.42857143;
	color: #4a4a4a;
	border: solid 2px rgba(255, 121, 61, 0.2);
	border-radius: 5px;
	font-weight: 300;
	width: 100%;
}
.assessment__concern:focus {
	outline: none;
	border: solid 2px #ff793d;
}
.assessment__charactersLeft {
	font-weight: 300;
	font-family: "MonumentGrotesk", "Open Sans", Arial, sans-serif;
	font-size: 12px;
	text-align: right;
}
.assessment__charactersLeft--alert {
	color: var(--c-red);
}
.assessment__shoutout {
	font-size: 12px;
	height: 120px;
	padding: 14px 16px;
	line-height: 1.42857143;
	color: #4a4a4a;
	border: solid 2px rgba(25, 223, 73, 0.2);
	border-radius: 5px;
	font-weight: 300;
	width: 100%;
}
.assessment__shoutout:focus {
	outline: none;
	border: solid 2px #19df49;
}
</style>
