<template>
	<div
		v-data-cy="'late-tutor-card'"
		class="lateTutorCard lateTutorCard--redTopBorder"
	>
		<ServiceDashboardTutorCardHeaderLateTutor
			:shift="cardData.shift"
			:user="cardData.user"
		/>
	</div>
</template>

<script>
import ServiceDashboardTutorCardHeaderLateTutor from "./ServiceDashboardTutorCardHeaderLateTutor.vue";

export default {
	components: {
		ServiceDashboardTutorCardHeaderLateTutor,
	},
	props: {
		cardData: {
			type: Object,
			required: true,
			validator(value) {
				return (
					value.hasOwnProperty("user") &&
					value.hasOwnProperty("shift")
				);
			},
		},
	},
};
</script>

<style scoped>
.lateTutorCard {
	border-top: solid transparent 4px;
	padding-top: 12px;
}
.lateTutorCard--redTopBorder {
	border-top: solid var(--c-orange) 4px;
}
</style>
