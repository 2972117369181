<template>
	<BreaksConfirmation>
		<template #header>
			<h3 v-data-cy="'meal-break-request-header'">
				Meal break request
			</h3>
		</template>
		<template #bodyIcon>
			<OrigamiIcon
				name="schedule"
				width="32"
				height="32"
				class="tw-text-origami-grey-400"
			/>
		</template>
		<template #bodyText>
			<p
				v-data-cy="'meal-start-time-text'"
				class="tw-text-left tw-mb-1 tw-text-xs"
			>
				Start time
			</p>
			<BreakRequestTimeDropdown
				:min-start-time="minStartTime"
				:max-start-time="maxStartTime"
				@select-time-block="handleSelectedTime"
			/>
		</template>
		<template #buttons>
			<OrigamiButton
				v-jest="'cancel-meal-break-button'"
				v-data-cy="'cancel-meal-break-button'"
				variant="secondary"
				class="tw-m-auto tw-mb-2 tw-w-full tw-justify-center"
				@click.native="cancelMealBreak"
			>
				Cancel
			</OrigamiButton>
			<OrigamiButton
				v-jest="'request-meal-break-button'"
				v-data-cy="'request-meal-break-button'"
				class="tw-mb-2 tw-w-full tw-justify-center"
				variant="primary"
				:is-loading="$store.state.BreaksManagementWidget.isRequestLoading"
				:disabled="!selectedTimeBlock"
				@click.native="scheduleMealBreak"
			>
				Request
			</OrigamiButton>
		</template>
	</BreaksConfirmation>
</template>

<script>
import { OrigamiButton, OrigamiIcon } from "@origami/vue2";

import BreaksConfirmation from "../templates/BreaksConfirmation.vue";
import BreakRequestTimeDropdown from "../elements/BreakRequestTimeDropdown.vue";

export default {
	components: {
		OrigamiButton,
		BreaksConfirmation,
		BreakRequestTimeDropdown,
		OrigamiIcon,
	},
	data() {
		return {
			selectedTimeBlock: null,
			minStartTime: null,
			maxStartTime: null,
		};
	},
	created() {
		const minMaxStartTime = this.$store.getters["TutorSchedule/getMealBreakMinMaxStartTime"];
		this.minStartTime = new Date(minMaxStartTime.min_start_time);
		this.maxStartTime = new Date(minMaxStartTime.max_start_time);
	},
	methods: {
		cancelMealBreak() {
			this.$store.dispatch("BreaksManagementWidget/resetWorkflow");
		},
		scheduleMealBreak() {
			this.$store.dispatch("BreaksManagementWidget/scheduleMealBreak", {
				scheduledStartTime: this.selectedTimeBlock,
			});
		},
		handleSelectedTime(selectedTime) {
			this.selectedTimeBlock = selectedTime;
		},
	},
};
</script>
