<script setup>
import { reactive, computed, watch, ref, onUnmounted, onMounted } from "vue";
import { debounce, isEmpty } from "lodash";
import { OrigamiIcon } from "@origami/vue2";
import { v4 as uuidv4 } from "uuid";

import BaseCheckbox from "@/components/BaseCheckbox.vue";
import BaseRadio from "@/components/BaseRadio.vue";

const SEARCH_DELAY_MS = 500;

const props = defineProps({
	items: {
		type: Array,
		default: () => [],
		validator: (items) => items.every((item) => item.hasOwnProperty("id")),
	},
	label: {
		type: String,
		required: true,
	},
	labelClass: {
		type: String,
		required: false,
		default: "",
	},
	collapseLabel: {
		type: Boolean,
		default: false,
	},
	promptText: {
		type: String,
		default: "",
	},
	isDisabled: {
		type: Boolean,
		default: false,
	},
	itemLimit: {
		type: Number,
		default: 100,
	},
	getItemText: {
		type: Function,
		required: true,
	},
	isMultiple: {
		type: Boolean,
		default: false,
	},
	value: {
		validator: (prop) => Array.isArray(prop) || typeof prop === "number" || prop === null,
		required: true,
	},
	isClearable: {
		type: Boolean,
		default: false,
	},
});

const state = reactive({
	isOpen: false,
	displayList: [],
	searchText: "",
	debouncedSearchText: "",
});

const emit = defineEmits(["input"]);

const isDropDownDisabled = computed(() => props.isDisabled || !props.items.length);
const exceedsItemLimit = computed(() => props.items.length > props.itemLimit);
const isEmptyMultiSelect = computed(() => Array.isArray(props.value) && isEmpty(props.value));
const hasNoSelections = computed(() => isEmptyMultiSelect.value || !props.value);
const icon = computed(() => {
	if (state.isOpen) {
		return "caret-active";
	}

	return props.isClearable && !isEmptyMultiSelect.value ? "close-full" : "caret";
});
const showItemsList = computed(
	() => state.isOpen && !state.debouncedSearchText || state.debouncedSearchText.length >= 3,
);
const inputText = computed(() => {
	if (props.promptText && hasNoSelections.value) {
		return props.promptText;
	}

	if (hasNoSelections.value) {
		return props.label;
	}

	return props.isMultiple ?
		`${props.label} +${props.value.length}` :
		props.getItemText(props.items.find((item) => props.value === item.id));
});

const dropDown = ref(null);

const onClickOutside = (e) => {
	const isClickOutside = !dropDown.value.contains(e.target);

	if (state.isOpen && isClickOutside) {
		toggleIsOpen();
	}
};

const toggleIsOpen = () => {
	if (isDropDownDisabled.value) {
		return;
	}

	state.isOpen = !state.isOpen;
	if (!state.isOpen) {
		state.searchText = "";
	}

	if (state.isOpen && !state.displayList.length && props.items.length) {
		filterItems();
	}
};

const filterItems = (searchText) => {
	if (!searchText) {
		state.displayList = exceedsItemLimit.value ? props.items.slice(0, props.itemLimit) : props.items;
		return;
	}

	if (searchText.length < 3) {
		return;
	}

	state.displayList = props.items.filter(
		(item) => props.getItemText(item).toLowerCase().includes(searchText.toLowerCase()),
	);
};

const isSelected = (item) => {
	if (!props.value) {
		return false;
	}

	return props.isMultiple ? !!props.value.find((i) => i === item.id) : props.value === item.id;
};

const selectItem = (item) => {
	emit("input", item.id);
	toggleIsOpen();
};

const addItemToSelected = (item) => {
	if (!props.value) {
		emit("input", [item]);
		return;
	}

	const isItemSelected = isSelected(item);
	const newValue = isItemSelected ?
		props.value.filter((i) => i !== item.id) :
		[...props.value, item.id];

	emit("input", newValue);
};

const handleIconClick = (e) => {
	if (icon.value !== "close-full") {
		return;
	}

	e.stopPropagation();

	emit("input", Array.isArray(props.value) ? [] : null);
};

onMounted(() => {
	document.addEventListener("click", onClickOutside);
});

onUnmounted(() => {
	document.removeEventListener("click", onClickOutside);
});

watch(() => props.items.length, () => filterItems(state.searchText));
watch(() => state.searchText, debounce((newValue) => {
	state.debouncedSearchText = newValue;
	filterItems(newValue);
}, SEARCH_DELAY_MS));
</script>

<template>
	<div
		ref="dropDown"
		class="tw-w-full tw-relative"
	>
		<label
			v-if="!collapseLabel"
			v-jest="'drop-down-label'"
			class="tw-font-bold tw-text-sm tw-text-xs tw-mb-[7px]"
			:class="labelClass"
		>
			{{ props.label }}
		</label>
		<div
			v-jest="'drop-down'"
			class="tw-bg-origami-white tw-p-2 hover:tw-bg-origami-grey-100 hover:tw-cursor-pointer tw-rounded tw-border-2 tw-border-grey-dark tw-flex tw-align-middle tw-h-[40px] tw-justify-between tw-px-3.5"
			:class="{
				'tw-opacity-60': isDropDownDisabled,
				'tw-border-blue-regular': state.isOpen || !hasNoSelections,
			}"
			@click="toggleIsOpen"
		>
			<span
				v-jest="'drop-down-input-text'"
				class="tw-text-xs"
				:class="{
					'tw-text-blue-regular tw-font-bold': state.isOpen || !hasNoSelections,
				}"
			>
				{{ inputText }}
			</span>
			<OrigamiIcon
				v-jest="'drop-down-icon'"
				:width="icon === 'close-full' ? 20 : 12"
				:height="icon === 'close-full' ? 20 : 12"
				:class="{
					'tw-text-blue-regular': icon === 'close-full',
					'tw-mt-1': icon !== 'close-full',
				}"
				:name="icon"
				@click.native="handleIconClick"
			/>
		</div>
		<div
			v-show="state.isOpen"
			class="tw-absolute tw-bg-origami-white tw-rounded tw-w-full tw-max-h-[195px] tw-overflow-y-scroll tw-drop-shadow-xl tw-z-[9999]"
		>
			<input
				:id="`search-${uuidv4()}`"
				v-model="state.searchText"
				v-jest="'search'"
				type="text"
				placeholder="Search"
				class="search-field tw-text-xs tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-regular tw-rounded-sm tw-my-1 tw-ml-1 tw-py-2 tw-pr-4 tw-pl-8 tw-w-[calc(100%-6px)]"
			>
			<ul class="tw-list-none">
				<li
					v-for="(item) in state.displayList"
					v-show="showItemsList"
					:key="item.id"
					v-jest="'select-item'"
					v-data-cy="'select-item'"
					class="tw-text-sm tw-p-2 hover:tw-bg-origami-grey-100"
				>
					<BaseCheckbox
						v-if="props.isMultiple"
						v-jest="'select-item-check'"
						v-data-cy="'select-item-check'"
						class="hover:tw-cursor-pointer"
						:checkbox-id="`select-item-${uuidv4()}`"
						:checkbox-value="item"
						:value="isSelected(item)"
						@input="() => addItemToSelected(item)"
					>
						<template #text>
							<span
								v-jest="'item-text'"
								v-data-cy="'select-item-text'"
							>
								{{ props.getItemText(item) }}
							</span>
						</template>
					</BaseCheckbox>
					<BaseRadio
						v-else
						v-jest="'select-item-radio'"
						v-data-cy="'select-item-radio'"
						class="hover:tw-cursor-pointer"
						:value="isSelected(item) ? item.id : 0"
						:radio-name="`radio-select-item-${item.id}`"
						:radio-id="`radio-select-item-${uuidv4()}`"
						:radio-value="item.id"
						@input="() => selectItem(item)"
					>
						<template #text>
							<span
								v-jest="'item-text'"
								v-data-cy="'select-item-text'"
							>
								{{ props.getItemText(item) }}
							</span>
						</template>
					</BaseRadio>
				</li>
				<li>
					<p
						v-if="!state.debouncedSearchText && props.items.length > props.itemLimit"
						v-jest="'search-prompt'"
						class="tw-text-sm tw-text-center"
					>
						Use Search for more results
					</p>
					<p
						v-else-if="state.debouncedSearchText && state.debouncedSearchText.length < 3"
						v-jest="'search-prompt'"
						class="tw-text-sm tw-mt-5 tw-mb-20 tw-text-center"
					>
						You must type a minimum of 3 characters to search
					</p>
					<p
						v-else-if="state.debouncedSearchText.length >= 3 && !state.displayList.length"
						v-jest="'search-prompt'"
						class="tw-text-sm tw-mt-5 tw-mb-24 tw-text-center"
					>
						No search results for "{{ state.debouncedSearchText }}"
					</p>
				</li>
			</ul>
		</div>
	</div>
</template>

<style scoped>
.search-field {
	background-image: url("/images/icon-search-black.svg");
	background-repeat: no-repeat;
	background-position: 8px 10px;
}
</style>
