<template>
	<BreakButton
		v-jest="'rest-break-button'"
		v-data-cy="'rest-break-button'"
		aria-label="Go on rest break"
		icon-name="break"
		:is-active="isOnRestBreakWorkflow"
		:disabled="isDisabled"
		:show-notification="hasRestBreak"
		@click.native="requestRestBreak"
	/>
</template>
<script>
import { mapState } from "vuex";
import { isNil } from "lodash";

import BreakButton from "../elements/BreakButton.vue";

export default {
	components: {
		BreakButton,
	},
	computed: {
		...mapState(["TutorSchedule"]),
		isDisabled() {
			return this.hasActiveMealBreak || this.isRequestingMealBreak || this.isOnBioBreakWorkflow ||
				(!this.hasRestBreak && (this.isInRestBreakTransitionPeriod || !this.isEligibleForRestBreak));
		},
		isOnRestBreakWorkflow() {
			return this.$store.getters["BreaksManagementWidget/isOnRestBreakWorkflow"];
		},
		isInRestBreakTransitionPeriod() {
			return this.$store.getters["BreaksManagementWidget/isInRestBreakTransitionPeriod"];
		},
		isEligibleForRestBreak() {
			return !isNil(this.TutorSchedule.shiftState?.eligible_actions?.schedule_rest_break);
		},
		hasRestBreak() {
			return this.$store.getters["TutorSchedule/hasRestBreak"];
		},
		hasActiveMealBreak() {
			return this.$store.getters["BreaksManagementWidget/hasActiveMealBreak"];
		},
		isRequestingMealBreak() {
			return this.$store.getters["BreaksManagementWidget/isRequestingMealBreak"];
		},
		isOnBioBreakWorkflow() {
			return this.$store.getters["BreaksManagementWidget/isOnBioBreakWorkflow"];
		},
	},
	methods: {
		requestRestBreak() {
			if (!this.isOnRestBreakWorkflow) {
				this.$store.dispatch("BreaksManagementWidget/requestRestBreak");
			}
		},
	},
};
</script>
