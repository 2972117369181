<template>
	<IconStudent
		v-if="name === 'default'"
		class="tw-p-2 tw-text-grey-regular"
		:class="{ 'tw-animate-pulse': $store.state.Student.Profile.isLoadingProfile }"
	/>
	<component
		:is="componentName"
		v-else
		v-jest="'avatar'"
		:colors="avatarColors"
		:full-desc="fullDesc"
		:label-ids="componentIds"
	/>
</template>
<script>
import IconStudent from "@/components/icons/IconStudent.vue";

import { AVATARS_LIST } from "../utilities/index.js";

export default {
	components: {
		AvatarTutor: () => import("@/components/elements/illustrations/CharacterTutor.vue"),
		AvatarElephant: () => import("./illustrations/AvatarElephant.vue"),
		AvatarRacoon: () => import("./illustrations/AvatarRacoon.vue"),
		AvatarMouse: () => import("./illustrations/AvatarMouse.vue"),
		AvatarBee: () => import("./illustrations/AvatarBee.vue"),
		AvatarChameleon: () => import("./illustrations/AvatarChameleon.vue"),
		IconStudent,
	},
	props: {
		name: {
			type: String,
			required: true,
			validator(value) {
				return [...AVATARS_LIST, "Tutor"].includes(value) || value === "default";
			},
		},
		colors: {
			type: Array,
			default: () => [],
		},
		fullDesc: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		componentName() {
			return `Avatar${this.name}`;
		},
		componentIds() {
			const variation = this.fullDesc ? "full" : "icon";
			return {
				title: `${this.name}-title-${variation}`,
				desc: `${this.name}-desc-${variation}`,
			};
		},
		avatarColors() {
			return this.colors.length ? this.colors : undefined;
		},
	},
};
</script>
