<template>
	<ChartBar
		:data="data"
		:options="options"
	/>
</template>

<script>
import {
	Chart as ChartJS,
	Tooltip,
	BarElement,
	CategoryScale,
	LinearScale,
} from "chart.js";
import { Bar as ChartBar } from "vue-chartjs";
import { computed } from "vue";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

import {
	chartDefaultBarColorOption,
	chartBarOptions,
	chartBarAxeX,
	chartBarAxeY,
	chartBarData,
} from "../../utilities/charts.js";

export default {
	components: {
		ChartBar,
	},
	props: {
		chartData: {
			type: Array,
			default: null,
			required: true,
		},
		barChartOptions: {
			type: Object,
			default: () => ({}),
		},
		color: {
			type: String,
			default: () => chartDefaultBarColorOption,
		},
		xAxes: {
			type: Object,
			default: () => chartBarAxeX(),
		},
		yAxes: {
			type: Object,
			default: () => chartBarAxeY,
		},
	},
	setup(props) {
		const data = computed(() => {
			return chartBarData(props.chartData, props.color, props.barChartOptions);
		});
		const options = computed(() => ({
			...chartBarOptions(props.chartData),
			scales: {
				x: props.xAxes,
				y: props.yAxes,
			},
		}));

		return { data, options };
	},
};
</script>

<style scoped>
canvas {
	height: 100% !important;
	width: 100% !important;
}
</style>
