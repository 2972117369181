var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "section",
      {
        staticClass:
          "tw-pl-2 tw-pr-0 tw-pt-2 tw-pb-4 tw-flex tw-justify-between tw-items-end",
      },
      [
        _c(
          "RouterLink",
          {
            staticClass: "tw-flex tw-items-center tw-text-blue-regular",
            attrs: {
              to: "/superuser/services/schedule/shift-schedule",
              target: "_blank",
              "aria-label": `Navigate to the Schedule page`,
            },
          },
          [
            _c("OrigamiIcon", {
              attrs: { name: "external", width: "15", height: "15" },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "tw-font-bold tw-text-sm tw-pl-2" }, [
              _vm._v("Schedule Page"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "OrigamiButton",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "create-btn",
                expression: "'create-btn'",
              },
              {
                name: "data-cy",
                rawName: "v-data-cy",
                value: "create-btn",
                expression: "'create-btn'",
              },
            ],
            staticClass: "tw-flex",
            attrs: {
              variant: "primary",
              disabled: _vm.isEditingShift || _vm.isDeletingShift,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.startCreateProcess.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n\t\t\tCreate New Shift\n\t\t")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tw-flex" },
      [
        _c("ServiceScheduleTutorScheduleCalendar", {
          attrs: { "formatted-shifts": _vm.formattedShifts },
          on: { "on-select-shift-segment": _vm.onSelectShiftSegment },
        }),
        _vm._v(" "),
        _c(
          _vm.sidebarComponent.component,
          _vm._b(
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: _vm.sidebarComponent.jestName,
                  expression: "sidebarComponent.jestName",
                },
              ],
              tag: "component",
              staticClass: "tw-w-1/2",
              on: {
                "cancel-edit": _vm.cancelEdit,
                "complete-creation": _vm.completeCreation,
                "complete-edit": _vm.completeEdit,
                "complete-extend": _vm.completeExtend,
                "complete-deletion": _vm.completeDeletion,
                "format-shifts": _vm.formatShifts,
              },
            },
            "component",
            _vm.sidebarComponent.props,
            false
          )
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }