import { ACHIEVEMENT_TYPES, ACHIEVEMENT_TYPE_IDS } from "../utilities/index.js";

export default {
	en: {
		achievements: {
			challenge: {
				challenge_ends_in: "{name} ends in ",
				days: "{num} days",
				lets_go: "Let's go!",
				earn: "Earn",
			},
			activity: {
				completed: "Activity completed",
				essay: "Review submitted",
				paperlive: "PaperLive show attended",
				pillText: "+ {num} pts",
				potd: "Paper Mission completed",
				tutoring: "Tutoring session completed",
			},
			trophy: {
				completed: "Trophy Completed",
			},
			title: "{num} New Achievements",
			view: "View all achievements",
			close: "Close",
		},
		achievement_center: {
			title: "My Achievements",
			buttons: {
				points: "points",
				badges: "badges",
				stickers: "stickers",
			},
			badges: {
				title: "Badges",
			},
			latest: {
				title: "Latest achievements",
				previous: {
					disabled: "no previous slides",
					enabled: "previous",
				},
				next: {
					disabled: "no more slides to show",
					enabled: "next",
				},
			},
			points: {
				title: "Points",
				description: "Get points and unlock badges by completing these activities!",
				more_activities_coming_soon: "More activities coming soon!",
			},
		},
		trophies: {
			labels: {
				not_started: "Trophy completion not started",
				started: "Trophy completion is in progress",
				completed: "Trophy is acquired",
			},
			banner: {
				got_it: "Got it",
				close_banner: "Close banner",
				view_button: "View",
			},
			metadata: {
				"code-of-conductor": {
					name: "Code of Conductor",
					emoji: "Dodo",
					incomplete_description: "Complete the Code of Conduct",
					completed_description: "Choo choo! You just took a ride on the code of conduct train!",
				},
				"essay-1": {
					name: "Evaluating Eel",
					emoji: "Worm",
					incomplete_description: "Have you tried using our Review Center? Get fast feedback on your papers and earn a trophy while you're at it!",
					completed_description: "Eels have terrible eyesight, so you probably don't want them to review your writing. Good job for reaching out to us and submitting your first item for review!",
				},
				"essay-5": {
					name: "Erudite Elephant",
					emoji: "Elephant",
					incomplete_description: "Submit five items to the Review Center to level up and earn the Erudite Elephant trophy!",
					completed_description: "They say elephants never forget. Just like you never forget to submit your writing to Paper. In fact, you've submitted five items to Review Center so far!",
				},
				"essay-15": {
					name: "Belletristic Barracuda",
					emoji: "Fish",
					incomplete_description: "Submit fifteen items to the Review Center and we'll explain to you what belletristic means. And what a barracuda is.",
					completed_description: "Congratulations on submitting fifteen items to the Review Center! That means you're smart enough to submit the words belletristic and barracuda into Google and figure out what they mean on your own.",
				},
				"session-1": {
					name: "Chatty Cheetah",
					emoji: "Cheetah",
					incomplete_description: "Hurry up and catch the Chatty Cheetah trophy by completing your first chat session!",
					completed_description: "Cheetahs are the world's fastest land mammal. But you'll be learning even faster now that you've completed your first chat session!",
				},
				"session-5": {
					name: "Garrulous Gopher",
					emoji: "Beaver",
					incomplete_description: "Finish five chat sessions to level up and earn the Garrulous Gopher trophy!",
					completed_description: "Gophers have been known to dig tunnels up to 2000 square feet. And you have been known to complete five chat sessions.\n Keep on digging!",
				},
				"session-15": {
					name: "Loquacious Llama",
					emoji: "Llama",
					incomplete_description: "Think you're up to the challenge to complete fifteen chat sessions? The Loquacious Llama trophy awaits you!",
					completed_description: "Loquacious: meaning tending to talk a great deal; talkative. Also, you completed fifteen chat sessions.",
				},
				"talkative-beaver": {
					name: "Yakkity Yak",
					emoji: "Buffalo",
					completed_description: "Yakkity Yak - You talked back! Congratulations on sending 1000 chat messages.",
				},
				"early-bird": {
					name: "Early Bird",
					emoji: "Parrot",
					completed_description: "Tweet tweet - you completed a morning activity before school even starts. What a great start!",
				},
				"night-owl": {
					name: "Night Owl",
					emoji: "Owl",
					completed_description: "The whole world is probably asleep, but you're here to study with us! Keep up the good work.",
				},
				"paper-live-1": {
					name: "Tune In Tuna",
					emoji: "FishingPole",
					incomplete_description: "Sign up for your first interactive PaperLive session and earn yourself another trophy!",
					completed_description: "Tuna can reach speeds between 44 and 62 miles per hour. That's how fast they're swimming to get to their first PaperLive sessions on time.",
				},
				"paper-live-5": {
					name: "Watching Walrus",
					emoji: "Seal",
					incomplete_description: "Keep registering for PaperLive sessions to level up to the Watching Walrus trophy!",
					completed_description: "Yay, you've booked 5 PaperLive shows! Walrus can call each other from over a mile away. So make sure to wear headphones while you're watching PaperLive.",
				},
				"paper-live-15": {
					name: "Binging Bison",
					emoji: "Bison",
					incomplete_description: "Keep tuning into PaperLive to earn your Binging Bison trophy!",
					completed_description: "Bison primarily eat grasses, weeds, and leafy plants—typically foraging for 9–11 hours a day. You aren't quite watching PaperLive that much, but you sure are getting close.",
				},
				"comeback-camel": {
					name: "Comeback Camel",
					emoji: "Camel",
					completed_description: "We missed you! Thanks for coming back to Paper.",
				},
				"feedback-1": {
					name: "Feedback Frog",
					emoji: "Frog",
					incomplete_description: "Leave a tutor some feedback and earn the Feedback Frog trophy!",
					completed_description: "Each frog has a distinct croak, and the sound can be amplified up to a mile away. Frogs have got nothing on your first feedback though - which is amplified across the globe (anonymously).",
				},
				"feedback-5": {
					name: "Feedback Fox",
					emoji: "Fox",
					incomplete_description: "Have more to say? Leave feedback after five tutoring sessions to earn your next trophy.",
					completed_description: "What does the fox say? It says you won another trophy for sending 5 feedback submissions to us. Thank you for sharing your thoughts!",
				},
				"feedback-15": {
					name: "Feedback Flamingo",
					emoji: "Flamingo",
					incomplete_description: "Keep giving your tutors more feedback to get your next trophy!",
					completed_description: "A group of flamingoes is called a flamboyance. We're not sure how to call the 15 feedback comments you've shared with us, but we're grateful for them anyway!",
				},
				"profile-completed": {
					name: "Profile Porcupine",
					emoji: "Hedgehog",
					incomplete_description: "Complete your profile and earn yourself a badge!",
					completed_description: "Baby porcupines are known as porcupettes. Does that make a new Paper user a Paperpette?",
				},
				"avatar-customization": {
					name: "Avatar Customization",
					emoji: "AvatarBee",
					completed_description: "You've unlocked new color options for your avatar. Give your profile a fresh look!",
				},
				"paper-live-joined-1": {
					name: "Show Up Swan",
					emoji: "Swan",
					incomplete_description: "Join your first interactive PaperLive show and earn the Show Up Swan trophy!",
					completed_description: "Swan eggs take between 35 and 42 days to hatch. Imagine how much PaperLive you could watch while you wait for that to happen!",
				},
				"paper-live-joined-5": {
					name: "Channel-Surfing Chicken",
					emoji: "Chicken",
					incomplete_description: "Keep coming to PaperLive shows to level up to the Channel-Surfing Chicken trophy!",
					completed_description: "Chickens have better color vision than humans; they can even see ultraviolet light! I bet they'd like all these PaperLive episodes you've been watching too.",
				},
				"paper-live-joined-15": {
					name: "Ratings Rabbit",
					emoji: "Rabbit",
					incomplete_description: "Keep driving up PaperLive ratings and earn your Ratings Rabbit trophy!",
					completed_description: "A rabbit's teeth never stop growing. You know what else doesn't stop growing? Your love for PaperLive! Aww.",
				},
				"problem-completed-1": {
					name: "Challenge Chick",
					emoji: "HatchingChick",
					incomplete_description: "Crack open a mission to earn your first Paper Mission trophy!",
					completed_description: "Baby chicks take about 21 days to hatch. You finished your first Paper Mission much faster!",
				},
				"problem-completed-5": {
					name: "Winning Wolf",
					emoji: "Wolf",
					incomplete_description: "Complete five Paper Missions to earn another trophy!",
					completed_description: "Wolves have an average length of about five feet. That's one foot of wolf for every Paper Mission you've completed!",
				},
				"problem-completed-15": {
					name: "Math Mammoth",
					emoji: "Mammoth",
					incomplete_description: "Keep tackling Paper Missions to earn another trophy!",
					completed_description: "Mammoth tusks reached up to 15 feet in length! Bet those mammoths couldn't finish 15 Paper Missions like you did though.",
				},
				"referrer-1": {
					name: "Referral Raccoon",
					emoji: "Raccoon",
					incomplete_description: "Earn this badge by getting one friend to sign into Paper using your referral link!",
					completed_description: "Did you know that President Calvin Coolidge kept a pet raccoon in the White House? She was “referred” to as Rebecca, just like you referred a student to Paper (thanks, by the way).",
				},
				"referrer-5": {
					name: "Referral Rhino",
					emoji: "Rhino",
					incomplete_description: "Get five friends to sign into Paper to earn the Referral Rhino badge!",
					completed_description: "There are five different species of rhinos on the planet. That's one species for each friend you've referred to Paper!",
				},
				"referrer-15": {
					name: "Referral Reindeer",
					emoji: "Reindeer",
					incomplete_description: "Give the gift of learning by referring even more friends!",
					completed_description: "Reindeer are migratory animals, meaning they move where they live throughout the year. Thanks to you, 15 of your friends have migrated to learning on Paper!",
				},
				"mobile-app-activity-1": {
					name: "Download Dolphin",
					emoji: "Dolphin",
					incomplete_description: "Download the mobile app (available for iOS and Android) and complete your first chat session or submit work for review on the app to earn this badge!",
					completed_description: "You now have the Paper mobile app and are well on your way to earning more badges! Keep it up!",
				},
				"mobile-app-activity-5": {
					name: "Monkeying Around On Mobile",
					emoji: "Monkey",
					incomplete_description: "It's no monkey business - Paper is here to support you! Use the mobile app 5 times by chatting with a tutor or submitting work for review to earn this badge!",
					completed_description: "Monkey see, monkey do, and so can you! Congratulations on using the mobile app 5 times!",
				},
				"mobile-app-activity-15": {
					name: "Alpaca With “App”-titude",
					emoji: "Llama",
					incomplete_description: "Keep using the mobile app 15 times and you'll earn this badge!",
					completed_description: "Wow, you just earned another badge! Fun fact: alpacas are not the same as llamas. Some differences are that alpacas are smaller and more social than llamas.",
				},
				redeemer: {
					name: "Redeeming Ram",
					emoji: "Ram",
					completed_description: "The scientific name for a ram is Ovis canadensis. The scientific name for a Paper student is Scholasticus excellens. Okay, not really, but we sure do think you're excellent for joining us!",
				},
				"challenge-completed": {
					name: "Challenge Completed",
					emoji: `${ACHIEVEMENT_TYPES.find((type) => type.id === ACHIEVEMENT_TYPE_IDS.CHALLENGES).emoji}`,
					completed_description: "Yay, you've made it to the finish line in time.",
				},
			},
			modal: {
				view: "View my trophies",
				ok_got_it: "Ok, Got it",
				feedback: "Leave a feedback and let us know how your session goes.",
				redeem_title: "Redeem Points",
				redeem_heading: "Get Paper Swags!",
				redeem_body_amount: "You have 💎 <strong>{pointsNum} points</strong> for a <strong>${value}</strong> gift card on Paper Store.",
				redeem_body_code: "Copy the code below and use it on check out.",
				redeem_go_to_store: "Go to Paper Store",
				trophy: "Trophy",
				badge: "Badge",
				earned_achievements: "You've earned a new {achievement}!",
			},
			profile: {
				achievements: "Achievements",
				trophies: "Trophies",
				trophies_description: "Level up by completing these activities below!",
				stickers: "Stickers",
				stickers_description: "Collect special notes for your tutoring sessions",
				stickers_empty: "Stay tuned! The stickers you receive will show up here.",
				badges: "Badges",
				badges_description: "Earn more badges by exploring Paper Platform!",
				challenges: "Challenges",
				change_avatar: "Customize Avatar",
				change_avatar_btn: "Customize Now",
				redeem: "Redeem Points",
				session_group_title: "Complete tutoring sessions",
				essay_group_title: "Submit to Review Center",
				"paper-live_group_title": "Register to PaperLive",
				"paper-live-joined_group_title": "Attend PaperLive sessions",
				feedback_group_title: "Provide feedback",
				"problem-completed_group_title": "Complete Paper Missions",
				error_group_title: "Other",
				activity_amount_1: "1st",
				activity_amount_5: "5th",
				activity_amount_15: "15th",
				"special-award_group_title": "Special awards",
				referrer_group_title: "Refer a friend",
				"mobile-app-activity_group_title": "Use the mobile app",
			},
			points: {
				points_short: "pts",
			},
		},
	},
	fr: {
		achievements: {
			challenge: {
				challenge_ends_in: "{name} se termine dans ",
				days: "{num} jours",
				lets_go: "Allons-y!",
				earn: "Gagner",
			},
			activity: {
				completed: "Activité complété",
				essay: "Travail soumis",
				paperlive: "Assisté à l'émission PaperLive",
				pillText: "+ {num} pts",
				potd: "Paper Mission complété",
				tutoring: "Session de tutorat complété",
			},
			trophy: {
				completed: "Trophée complété",
			},
			title: "{num} Nouveaux Accomplissements",
			view: "Voir tous les accomplissements",
			close: "Fermer",
		},
		achievement_center: {
			title: "Mes accomplissements",
			buttons: {
				points: "points",
				badges: "insignes",
				stickers: "stickers",
			},
			badges: {
				title: "Insignes",
			},
			latest: {
				title: "Dernières accomplissements",
				previous: {
					disabled: "aucune diapositives précédentes",
					enabled: "précédente",
				},
				next: {
					disabled: "pas plus de diapositives à afficher",
					enabled: "suivant",
				},
			},
			points: {
				title: "Points",
				description: "Obtiens des points et débloque des insignes en complétant ces activités!",
				more_activities_coming_soon: "Plus d’activités à venir!",
			},
		},
		trophies: {
			labels: {
				not_started: "L'achèvement du trophée n'est pas commencé",
				started: "L'achèvement du trophée est en cours",
				completed: "Trophée est acquis",
			},
			banner: {
				got_it: "J'ai compris",
				close_banner: "Fermer la bannière",
				view_button: "Voir",
			},
			metadata: {
				"code-of-conductor": {
					name: "Code de Conducteur",
					emoji: "Dodo",
					banner_title: "Ouais, tu as débloqué ton premier badge!",
					banner_description: "Vous avez gagné le Code de Conducteur.",
					incomplete_description: "Complétez le Code de Conduite",
					completed_description: "Chou chou! Vous venez de faire un tour dans le train du code de conduite",
				},
				"essay-1": {
					name: "L’Anguille Évaluatrice",
					emoji: "Worm",
					incomplete_description: "Avez-vous essayé d'utiliser notre fonctionnalité de révision de rédaction? Recevez des commentaires rapides sur vos papiers et gagnez un trophée pendant que vous y êtes!",
					completed_description: "Les anguilles ont une vue terrible, donc vous ne voulez probablement pas qu'elles passent en revue vos rédactions. C’est une bonne chose que nous vous ayons aidés à la place !",
				},
				"essay-5": {
					name: "Élephant Érudit",
					emoji: "Elephant",
					incomplete_description: "Soumettez cinq redactions pour monter de niveau et gagner le trophée Élephant Érudit!",
					completed_description: "On dit que les éléphants n'oublient jamais. Tout comme vous n'oubliez jamais de soumettre vos rédactions sur Paper.",
				},
				"essay-15": {
					name: "Blaireau Essayiste",
					emoji: "Fish",
					incomplete_description: "Soumettez quinze rédactions et nous vous expliquerons ce que signifie belletriste et ce que c’est qu’un barracuda",
					completed_description: "Félicitations pour soumettre quinze rédactions ! Cela signifie que vous êtes assez intelligent pour soumettre les mots essayiste et barracuda dans Google et comprendre ce qu'ils signifient par vous-même.",
				},
				"session-1": {
					name: "Guépard Bavard",
					emoji: "Cheetah",
					incomplete_description: "Dépêchez-vous d'attraper le trophée du guépard en complétant votre première session de chat!",
					completed_description: "Les guépards sont les mammifères terrestres les plus rapides au monde. Mais tu apprendras encore plus vite maintenant que tu as terminé ta première session de chat !",
				},
				"session-5": {
					name: "Gauphre Bavard",
					emoji: "Beaver",
					incomplete_description: "Terminez cinq sessions de chat pour passer au niveau supérieur et gagner le trophée Gauphre Bavard!",
					completed_description: "On sait que des gauphres creusent des tunnels jusqu'à 2000 pieds carrés. Et vous avez été connu pour terminer cinq sessions de chat.\n Continuez à creuser !",
				},
				"session-15": {
					name: "Llama Loquace",
					emoji: "Llama",
					incomplete_description: "Pensez-vous que vous êtes à la hauteur du défi de compléter quinze sessions de chat? Le trophée de Llama Loquace vous attend !",
					completed_description: "Loquace: veut dire tendant à parler beaucoup; bavard. Aussi, vous avez terminé quinze sessions de chat.",
				},
				"talkative-beaver": {
					name: "Yakkity Yack",
					emoji: "Buffalo",
					completed_description: "Yakkity Yack - Vous avez parlé! Félicitations pour l'envoi de 1000 messages de chat.",
				},
				"early-bird": {
					name: "Oiseau Précoce",
					emoji: "Parrot",
					completed_description: "Tweet tweet - vous avez terminé une activité entre 3h du matin et 8h du matin !",
				},
				"night-owl": {
					name: "Hibou de Nuit",
					emoji: "Owl",
					completed_description: "Vous avez terminé une session entre 20h et 3h du matin ! Maintenant brossez vos dents et allez au lit.",
				},
				"paper-live-1": {
					name: "Thon Impliqué",
					emoji: "FishingPole",
					incomplete_description: "Inscrivez-vous à votre première session interactive sur PaperLive et gagnez un autre trophée!",
					completed_description: "Le thon peut atteindre des vitesses comprises entre 44 et 62 miles par heure. C'est à cette vitesse qu’ils nagent pour arriver à leurs sessions PaperLive à temps.",
				},
				"paper-live-5": {
					name: "Morse Observateur",
					emoji: "Seal",
					incomplete_description: "Continuez à vous inscrire pour les sessions PaperLive pour passer au niveau du Trophée Morse Observateur!",
					completed_description: "Les morses peuvent s'appeler l'un à l'autre depuis plus d'un kilomètre de distance. Alors assurez-vous de porter des écouteurs pendant que vous regardez PaperLive.",
				},
				"paper-live-15": {
					name: "Bison Frénetique",
					emoji: "Bison",
					incomplete_description: "Continuez à vous brancher sur PaperLive pour gagner votre trophée Bison Frénetique!",
					completed_description: "Le Bison mange principalement de l'herbe, des mauvaises herbes et des plantes arborées généralement de 9 à 11 heures par jour. Vous ne regardez pas autant PaperLive, mais vous vous approchez.",
				},
				"comeback-camel": {
					name: "Chameau Revenant",
					emoji: "Camel",
					completed_description: "Vous nous avez manqué! Merci de revenir sur Paper.",
				},
				"feedback-1": {
					name: "Grenouille de Commentaires",
					emoji: "Frog",
					incomplete_description: "Laissez un commentaire au tuteur et gagnez le trophée de grenouille de commentaires !",
					completed_description: "Chaque grenouille croasse distinctivement, et le son peut être amplifié jusqu'à un mile de distance. Les grenouilles ne savent rien par rapport à votre commentaire; qui sont amplifiés à travers le monde (anonyme- nous ne sommes pas étranges).",
				},
				"feedback-5": {
					name: "Renard de Commentaires",
					emoji: "Fox",
					incomplete_description: "Vous avez plus à dire? Laissez un commentaire après cinq sessions de tutorat pour gagner votre prochain trophée.",
					completed_description: "Que dit le renard ? Il dit que vous avez gagné un autre trophée! Dinging-ding-dinging-ding",
				},
				"feedback-15": {
					name: "Flamant de Commentaires",
					emoji: "Flamingo",
					incomplete_description: "Continuez à donner à vos tuteurs plus de commentaires pour obtenir votre prochain trophée!",
					completed_description: "Un groupe de flamants est appelé une flamboyance. Nous ne savons pas ce que tous vos commentaires sont appelés, mais nous vous en sommes reconnaissants.",
				},
				"profile-completed": {
					name: "Porc-épic de Profil",
					emoji: "Hedgehog",
					banner_title: "Vous avez obtenu le badge du Porc-épic de Profil!",
					banner_description: "Continuez à avancer, votre profil a l'air beau.",
					incomplete_description: "Complétez votre profil et gagnez un badge!",
					completed_description: "Les bébés porc-épics sont aussi connus sous le nom de porc-épics. Est-ce que cela fait un nouvel utilisateur de Paper un Paperepic ?",
				},
				"avatar-customization": {
					name: "Personnalisation d’Avatar",
					emoji: "AvatarBee",
					banner_title: "Personnalisation d’avatar",
					banner_description: "Tu as débloqué de nouvelles options de couleurs pour ton avatar. Donne à ton profil un nouveau look!",
					completed_description: "Vous avez débloqué de nouvelles options de couleurs pour votre avatar. Donnez à votre profil un nouveau look!",
				},
				"paper-live-joined-1": {
					name: "Cygne Participatif",
					emoji: "Swan",
					incomplete_description: "Participe à ton premier show interactif avec PaperLive et tu recevras le trophée du Cygne Participatif!",
					completed_description: "Les œufs de cygne prennent entre 35 et 42 jours pour éclore. Imagine combien de shows de PaperLive tu pourrais écouter en attendant cela.",
				},
				"paper-live-joined-5": {
					name: "Poulet Navigateur",
					emoji: "Chicken",
					incomplete_description: "Continue à participer aux shows de PaperLive pour monter le niveau du Poulet Navigateur.",
					completed_description: "Les poulets ont une meilleure vision des couleurs que les humains; ils peuvent même voir la lumière ultraviolette! Je crois qu'ils aimeraient aussi tous ces épisodes PaperLive que vous avez regardés.",
				},
				"paper-live-joined-15": {
					name: "Lapin de Classement",
					emoji: "Rabbit",
					incomplete_description: "Continue à améliorer le classement de PaperLive et tu recevras un trophée de Lapin de Classement!",
					completed_description: "Les dents d'un lapin ne cessent de pousser. Vous savez quoi d'autre n'arrête pas de grandir ? Votre amour pour PaperLive! Aww.",
				},
				"problem-completed-1": {
					name: "Poussin de Défis",
					emoji: "HatchingChick",
					incomplete_description: "Commence un défi pour obtenir ton premier trophée de Paper Missions!",
					completed_description: "Les poussins prennent environ 21 jours pour éclore. Tu as terminé votre premier défi de Paper Missions beaucoup plus rapidement!",
				},
				"problem-completed-5": {
					name: "Loup Gagnant",
					emoji: "Wolf",
					incomplete_description: "Complète cinq défis de Paper Missions pour gagner un autre trophée!",
					completed_description: "Les loups ont une longueur moyenne d'environ cinq pieds. C'est un pied de loup pour chaque défi de Paper Missions que vous avez terminé!",
				},
				"problem-completed-15": {
					name: "Mammouth de Mathématiques",
					emoji: "Mammoth",
					incomplete_description: "Continue à completer des défis de Paper Missions pour gagner un autre trophée!",
					completed_description: "Les défenses de mammouth atteignaient jusqu'à 15 pieds de long! Je ne crois pas que les mammouths ont pu terminer 15 défis de Paper Missions comme vous l'avez fait.",
				},
				"mobile-app-activity-1": {
					name: "Dauphin de téléchargement",
					emoji: "Dolphin",
					incomplete_description: "Télécharge l'application mobile (disponible pour iOS et Android) et termine ta première session de chat ou soumets ton travail pour révision sur l'application pour gagner ce badge !",
					completed_description: "Tu as maintenant l'application mobile Paper et tu es sur la bonne voie pour gagner plus de badges ! Continue comme ça !",
				},
				"mobile-app-activity-5": {
					name: "Singe de téléphone",
					emoji: "Monkey",
					incomplete_description: "Ce n'est pas une affaire de singe - Paper est là pour te soutenir ! Utilise l'application mobile 5 fois en discutant avec un tuteur ou en soumettant un travail pour révision pour gagner ce badge !",
					completed_description: "Le singe voit, le singe fait, et toi aussi ! Félicitations pour avoir utilisé l'application mobile 5 fois !",
				},
				"mobile-app-activity-15": {
					name: "Alpaga Avec “App”-titude",
					emoji: "Llama",
					incomplete_description: "Continue d'utiliser l'application mobile 15 fois et tu gagneras ce badge !",
					completed_description: "Wow, tu viens de gagner un autre badge ! Fait amusant : les alpagas ne sont pas pareil que les lamas. Par example, les alpagas sont plus petits et plus sociaux que les lamas.",
				},
			},
			modal: {
				vue: "Voir mes trophées",
				ok_got_it: "Ok, compris",
				feedback: "Laissez un commentaire et faites-nous savoir comment va votre session.",
				redeem_title: "Échanger des Points",
				redeem_heading: "Recevez de la marchandise Paper!",
				redeem_body_amount: "Vous avez 💎 <strong>{pointsNum} points</strong> pour une carte-cadeau <strong>${value}</strong> sur le Magasin de Paper.",
				redeem_body_code: "Copiez le code ci-dessous et utilisez-le lors du paiement.",
				redeem_go_to_store: "Allez à la boutique Paper",
				trophy: "Trophée",
				badge: "Badge",
				earned_achievements: "Vous avez gagné un nouveau {achievement}!",
			},
			profile: {
				achievements: "Accomplissements",
				trophies: "Trophées",
				trophies_description: "Montez de niveau en complétant les activités ci-dessous!",
				stickers: "Stickers",
				stickers_description: "Collectez des notes spéciales pour vos sessions de tutorat",
				stickers_empty: "Restez à l'écoute! Les stickers que vous recevez apparaîtront ici.",
				badges: "Badges",
				badges_description: "Gagnez plus de badges en explorant la plateforme Paper!",
				challenges: "Défis",
				change_avatar: "Personnaliser l'avatar",
				change_avatar_btn: "Personnaliser maintenant",
				redeem: "Échanger des Points",
				session_group_title: "Compléter les sessions de tutorat",
				essay_group_title: "Soumettre au Centre de Révision",
				error_group_title: "Autres",
				"paper-live_group_title": "Enregistrez-vous à PaperLive",
				"paper-live-joined_group_title": "Participe à des sessions de PaperLive",
				feedback_group_title: "Fournir un commentaire",
				"problem-completed_group_title": "Complète le défi quotidien de Paper Missions",
				activity_amount_1: "1er",
				activity_amount_5: "5ème",
				activity_amount_15: "15ème",
				"special-award_group_title": "Récompenses spéciales",
			},
			points: {
				points_short: "pts",
			},
		},
	},
	es: {
		achievements: {
			challenge: {
				challenge_ends_in: "{name} termina en ",
				days: "{num} días",
				lets_go: "¡Vamos!",
				earn: "Ganar",
			},
			activity: {
				completed: "Actividad completada",
				essay: "Revisión enviada",
				paperlive: "Programa de PaperLive atendido",
				pillText: "+ {num} pts",
				potd: "Paper Mission completada",
				tutoring: "Sesión de tutoría completada",
			},
			trophy: {
				completed: "Trofeo completado",
			},
			title: "{num} Nuevos Logros",
			view: "Ver todos logros",
			close: "Cerrar",
		},
		achievement_center: {
			title: "Mis Logros",
			buttons: {
				points: "puntos",
				badges: "insignias",
				stickers: "stickers",
			},
			badges: {
				title: "Insignias",
			},
			latest: {
				title: "Mis últimos logros",
				previous: {
					disabled: "no hay diapositivas anteriores",
					enabled: "anterior",
				},
				next: {
					disabled: "no hay más diapositivas que mostrar",
					enabled: "siguiente",
				},
			},
			points: {
				title: "Puntos",
				description: "¡Obtén puntos y desbloquea insignias completando estas actividades!",
				more_activities_coming_soon: "¡Más actividades próximamente!",
			},
		},
		trophies: {
			labels: {
				not_started: "Finalización del trofeo no iniciada",
				started: "La finalización del trofeo está en curso",
				completed: "Se adquiere el trofeo",
			},
			banner: {
				got_it: "Entendido",
				close_banner: "Cerrar banner",
				view_button: "Ver",
			},
			metadata: {
				"code-of-conductor": {
					name: "Código de Conductor",
					emoji: "Dodo",
					banner_title: "¡Hola, has desbloqueado tu primera insignia!",
					banner_description: "Has ganado el Código de Conductor.",
					incomplete_description: "Completa el Código de Conductor",
					completed_description: "¡Choo choo! ¡Acabas de dar un paseo en el Código del Conductor!",
				},
				"essay-1": {
					name: "Anguila de Evaluación",
					emoji: "Worm",
					incomplete_description: "¿Ha intentado utilizar nuestra función de revisión del ensayo? ¡Obtén comentarios rápidos sobre tus papeles y gana un trofeo mientras estás en él!",
					completed_description: "Las anguilas tienen ojos terribles, así que probablemente no quieren que revisen sus ensayos. ¡Bueno recibiste ayuda de nosotros en su lugar!",
				},
				"essay-5": {
					name: "Elefante Erudito",
					emoji: "Elephant",
					incomplete_description: "¡Envía cinco ensayos para subir de nivel y ganar el trofeo del elefante erudito!",
					completed_description: "Dicen que los elefantes nunca olvidan. Al igual que nunca te olvidas de enviar tus ensayos a Paper.",
				},
				"essay-15": {
					name: "Insignia Belletrista",
					emoji: "Fish",
					incomplete_description: "Envíe quince ensayos y le explicaremos lo que significa belletrístico. Y lo que es una barracuda.",
					completed_description: "¡Felicitaciones por la presentación de quince ensayos! Eso significa que eres lo suficientemente inteligente como para enviar las palabras belletrística y barracuda a Google y averiguar lo que significan por ti mismo.",
				},
				"session-1": {
					name: "Guepardo Hablador",
					emoji: "Cheetah",
					incomplete_description: "¡Date prisa y obtén el trofeo de Guepardo Hablador completando tu primera sesión de chat!",
					completed_description: "Los Guepardos son el mamífero de tierra más rápido del mundo. ¡Pero estarás aprendiendo aún más rápido ahora que has completado tu primera sesión de chat!",
				},
				"session-5": {
					name: "Ardilla Gárrula",
					emoji: "Beaver",
					incomplete_description: "¡Termina cinco sesiones de chat para subir de nivel y ganar el trofeo de la Ardilla Gárrula!",
					completed_description: "Se sabe que las Adrillas cavan túneles de hasta 2000 pies cuadrados. Y se sabe que has completado cinco sesiones de chat.\n ¡Sigue cavando!",
				},
				"session-15": {
					name: "Llama Locuaz",
					emoji: "Llama",
					incomplete_description: "¿Crees que estás a la altura del desafío de completar quince sesiones de chat? ¡El trofeo Llama Locuaz te espera!",
					completed_description: "Locuaz: significando tender a hablar mucho. Además, ha completado quince sesiones de chat.",
				},
				"talkative-beaver": {
					name: "Yakkity Yak",
					emoji: "Buffalo",
					completed_description: "Yakkity Yak - ¡Has hablado de nuevo! Felicidades por enviar 1000 mensajes de chat.",
				},
				"early-bird": {
					name: "Ave Madrugadora",
					emoji: "Parrot",
					completed_description: "Tweet tweet - has completado una actividad entre las 3 am y las 8 am!",
				},
				"night-owl": {
					name: "Búho nocturno",
					emoji: "Owl",
					completed_description: "Usted completó una sesión entre las 8 pm y las 3 am! Ahora enjuague los dientes y vaya a la cama.",
				},
				"paper-live-1": {
					name: "Atún en Sintonía",
					emoji: "FishingPole",
					incomplete_description: "Regístrate en tu primera sesión interactiva de PaperLive y gana otro trofeo!",
					completed_description: "El atún puede alcanzar velocidades entre 44 y 62 millas por hora. Así de rápido están nadando para llegar a sus sesiones de PaperLive a tiempo.",
				},
				"paper-live-5": {
					name: "Morsa de Observación",
					emoji: "Seal",
					incomplete_description: "¡Sigue registrándote para las sesiones de PaperLive para subir de nivel a los trofeos de la Morsa!",
					completed_description: "Las Morsas puede llamarse entre sí desde más de una milla de distancia. Así que asegúrate de usar auriculares mientras estás viendo PaperLive.",
				},
				"paper-live-15": {
					name: "Bisonte de Atracón",
					emoji: "Bison",
					incomplete_description: "Sigue sintonizando en PaperLive para ganar tu trofeo de Bisonte de Atracón!",
					completed_description: "El bisonte principalmente come hierbas, hierbas y plantas frondosas—típicamente forrajera durante 9 a 11 horas al día. Usted no está viendo PaperLive tanto, pero seguro que se está acercando.",
				},
				"comeback-camel": {
					name: "Camello de regreso",
					emoji: "Camel",
					completed_description: "¡Te echamos de menos! Gracias por volver a Paper.",
				},
				"feedback-1": {
					name: "Rana de Retroalimentación",
					emoji: "Frog",
					incomplete_description: "Deja a un tutor algunos comentarios y gana la Rana de Retroalimentación!",
					completed_description: "Cada rana tiene un croado distinto, y el sonido puede amplificarse hasta una milla de distancia. Sin embargo, las ranas no tienen nada en sus comentarios - que se amplifica en todo el mundo (anónimamente - no somos extraños).",
				},
				"feedback-5": {
					name: "Zorro de comentarios",
					emoji: "Fox",
					incomplete_description: "¿Tienes más que decir? Deja tu opinión después de cinco sesiones de tutoring para ganar tu próximo trofeo.",
					completed_description: "¿Qué dice el zorro? ¡Dice que ganaste otro trofeo de comentarios! Dando el ding-ding-ding-ding-ding-ding-ding",
				},
				"feedback-15": {
					name: "Flamenco de retroalimentación",
					emoji: "Flamingo",
					incomplete_description: "Sigue dando a tus tutores más comentarios para conseguir tu próximo trofeo!",
					completed_description: "Un grupo de flamencos se llama bandadas. No sabemos cómo llaman todos sus comentarios, pero de todos modos estamos agradecidos por ello.",
				},
				"profile-completed": {
					name: "Puerco espín de perfil",
					emoji: "Hedgehog",
					banner_title: "¡Has ganado la insignia de Puerco espín de perfil!",
					banner_description: "Sigue adelante, tu perfil se ve estupendo.",
					incomplete_description: "¡Completa tu perfil y gana una insignia!",
					completed_description: "Los Puerco espín de bebé son conocidos como porcupettes. ¿Eso hace que un nuevo usuario de Paper sea un Paperpette?",
				},
				"avatar-customization": {
					name: "Personalización de avatar",
					emoji: "AvatarBee",
					banner_title: "Personalización de avatar",
					banner_description: "Has desbloqueado nuevas opciones de color para tu avatar. ¡Dale un toque fresco a tu perfil!",
					completed_description: "Has desbloqueado nuevas opciones de color para tu avatar. ¡Dale un toque fresco a tu perfil!",
				},
				"paper-live-joined-1": {
					name: "Cisne Participador",
					emoji: "Swan",
					incomplete_description: "¡Únete a tu primer show interactivo de PaperLive y gana el trofeo Cisne Participador!",
					completed_description: "Los cisnes tardan entre 35 y 42 días en salir de su cascarón. ¡Imagina cuántos shows de PaperLive podrías ver mientras esperas a que eso suceda!",
				},
				"paper-live-joined-5": {
					name: "Pollo Navegador",
					emoji: "Chicken",
					incomplete_description: "¡Sigue viniendo a los shows de PaperLive para subir de nivel hasta el trofeo Pollo Navegador!",
					completed_description: "Los pollos pueden ver los colores mejor que los humanos; ¡incluso pueden ver la luz ultravioleta! Apuesto a que también les gustarían todos los episodios de PaperLive que has visto.",
				},
				"paper-live-joined-15": {
					name: "Conejo de Calificaciones",
					emoji: "Rabbit",
					incomplete_description: "¡Sigue aumentando las calificaciones de PaperLive y gana tu trofeo de Conejo de Calificaciones!",
					completed_description: "Los dientes de un conejo nunca dejan de crecer. ¿Sabes qué más no deja de crecer? ¡Tu amor por PaperLive! Aww.",
				},
				"problem-completed-1": {
					name: "Pollito de Retos",
					emoji: "HatchingChick",
					incomplete_description: "¡Empieza un reto para ganar tu primer trofeo de Paper Missions!",
					completed_description: "Los pollitos tardan 21 días en salir de su cascarón. ¡Terminaste tu primer reto de Paper Missions mucho más rápido que eso!",
				},
				"problem-completed-5": {
					name: "Lobo Ganador",
					emoji: "Wolf",
					incomplete_description: "¡Completa cinco retos de Paper Missions para ganar otro trofeo!",
					completed_description: "Los lobos tienen una longitud promedio de aproximadamente cinco pies. ¡Eso es un pie de lobo por cada reto de Paper Missions que has completado!",
				},
				"problem-completed-15": {
					name: "Mamut Matemático",
					emoji: "Mammoth",
					incomplete_description: "¡Sigue completando los retos de Paper Missions para ganar otro trofeo!",
					completed_description: "¡Los colmillos de mamut llegaron a medir hasta 15 pies de largo! Apuesto a que esos mamuts no podrían terminar 15 retos de Paper Missions como lo hiciste tú.",
				},
				"mobile-app-activity-1": {
					name: "Descargar Delfín",
					emoji: "Dolphin",
					incomplete_description: "Descarga la aplicación móvil (disponible para iOS y Android) y complete su primera sesión de chat o envíe un trabajo para su revisión en la aplicación para obtener esta insignia.",
					completed_description: "¡Ahora tiene la aplicación móvil de Paper y está en camino de ganar más insignias! ¡Avanza!!",
				},
				"mobile-app-activity-5": {
					name: "Mono de teléfono",
					emoji: "Monkey",
					incomplete_description: "No es un asunto de monos: ¡el papel está aquí para apoyarlo! ¡Use la aplicación móvil 5 veces chateando con un tutor o enviando un trabajo para su revisión para ganar esta insignia!",
					completed_description: "El mono ve, el mono hace, ¡y tú también puedes! ¡Felicitaciones por usar la aplicación móvil 5 veces!",
				},
				"mobile-app-activity-15": {
					name: "Alpaca con actitud de “aplicación”",
					emoji: "Llama",
					incomplete_description: "¡Siga usando la aplicación móvil 15 veces y obtendrá esta insignia!",
					completed_description: "¡Guau, acabas de ganar otra insignia! Dato curioso: las alpacas no son lo mismo que las llamas. Algunas diferencias son que las alpacas son más pequeñas y sociales que las llamas.",
				},
			},
			modal: {
				view: "Ver mis trofeos",
				ok_got_it: "Ok, Entendido",
				feedback: "Deja un comentario y haznos saber cómo va tu sesión.",
				redeem_title: "Puntos de canje",
				redeem_heading: "Obtener mercancía de Papel",
				redeem_body_amount: "Tienes 💎 <strong>{pointsNum} puntos</strong> para una tarjeta de regalo <strong>${value}</strong> en la tienda de Paper",
				redeem_body_code: "Copie el código de abajo y úselo en la pantalla de compra.",
				redeem_go_to_store: "Ir a la tienda de Paper",
				trophy: "Trouto",
				badge: "Insignia",
				earned_achievements: "¡Has ganado un nuevo {achievement}!",
			},
			profile: {
				achievements: "Logros",
				trophies: "Trofeos",
				trophies_description: "¡Sube de nivel completando estas actividades a continuación!",
				stickers: "Stickers",
				stickers_description: "Recoge notas especiales para tus sesiones de tutoría",
				stickers_empty: "¡Mantente atento! Los stickers que recibas aparecerán aquí.",
				badges: "Insignias",
				badges_description: "¡Gana más insignias explorando la plataforma de Paper!",
				challenges: "Retos",
				change_avatar: "Personalizar avatar",
				change_avatar_btn: "Personalizar ahora",
				redeem: "Puntos de canje",
				session_group_title: "Sesiones de tutoría completadas",
				essay_group_title: "Enviar al centro de revisión",
				error_group_title: "Otro",
				"paper-live_group_title": "Registrarse para PaperLive",
				"paper-live-joined_group_title": "Participa en las sesiones de PaperLive",
				feedback_group_title: "Proporcionar comentarios",
				"problem-completed_group_title": "Completa retos diarios de Paper Missions",
				activity_amount_1: "1",
				activity_amount_5: "5",
				activity_amount_15: "15",
				earned_achievements: "¡Has ganado un nuevo {achievement}!",
				"special-award_group_title": "Premios especiales",
			},
			points: {
				points_short: "pts",
			},
		},
	},
};
