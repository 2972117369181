<template>
	<a
		v-if="showScrollToTop"
		v-jest="'scroll-to-top-link'"
		href="#"
		class="tw-flex tw-items-center tw-justify-center tw-fixed tw-bottom-5 tw-right-5 tw-bg-origami-brand-blue hover:tw-bg-origami-brand-navy tw-shadow-elevation-4 tw-rounded-full tw-w-12 tw-h-12 tw-cursor-pointer"
		@click="scrollToTop"
	>
		<OrigamiIcon
			v-jest="'scroll-to-top-icon'"
			name="arrow-up"
			class="tw-text-origami-white"
		/>
		<span class="origami-sr-only">Back to top</span>
	</a>
</template>

<script>
import { OrigamiIcon } from "@origami/vue2";

export default {
	name: "ScrollToTop",
	components: {
		OrigamiIcon,
	},
	data() {
		return {
			showScrollToTop: false,
		};
	},
	created() {
		window.addEventListener("scroll", this.handleScroll);
	},
	destroyed() {
		window.removeEventListener("scroll", this.handleScroll);
	},
	methods: {
		handleScroll() {
			this.showScrollToTop = document.documentElement.scrollTop > 0;
		},
		scrollToTop(event) {
			const scrollOptions = {
				top: 0,
				left: 0,
				behavior: "smooth",
			};
			const focusableElements = document.querySelectorAll("button, a, input, select, textarea, [tabindex]:not([tabindex=\"-1\"])");

			const supportsNativeSmoothScroll = "scrollBehavior" in document.documentElement.style;
			event.preventDefault();

			supportsNativeSmoothScroll ?
				window.scrollTo(scrollOptions) :
				window.scrollTo(scrollOptions.left, scrollOptions.top);

			focusableElements[0].focus({
				preventScroll: true,
			});
		},
	},
};
</script>
