<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		viewBox="0 0 16 16"
	>
		<path
			:fill="iconColor"
			fill-rule="evenodd"
			d="M6.302 11.191l1.254 1.253L12 8 7.556 3.556 6.302 4.809 8.596 7.11H0V8.89h8.596L6.302 11.19zM14.222 0H1.778C.79 0 0 .8 0 1.778v3.555h1.778V1.778h12.444v12.444H1.778v-3.555H0v3.555C0 15.2.791 16 1.778 16h12.444C15.2 16 16 15.2 16 14.222V1.778C16 .8 15.2 0 14.222 0z"
		/>
	</svg>
</template>
<script>
export default {
	props: {
		width: {
			type: Number,
			default: 16,
		},
		height: {
			type: Number,
			default: 16,
		},
		iconColor: {
			type: String,
			default: "#FFF",
		},
	},
};
</script>
