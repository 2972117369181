var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("TutorBioBreakCard", {
    directives: [
      {
        name: "jest",
        rawName: "v-jest",
        value: "break-request-card",
        expression: "'break-request-card'",
      },
    ],
    attrs: {
      break: _vm.bioBreak,
      "include-shift-status": true,
      "is-loading-state": _vm.isLoadingState,
    },
    scopedSlots: _vm._u([
      {
        key: "metaDataIcon",
        fn: function () {
          return [
            _c(_vm.getOriginalScheduleType(), {
              tag: "component",
              staticClass: "tw-mr-2 tw-align-middle tw-text-black-high",
              attrs: { "icon-color": "currentColor" },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "metadata",
        fn: function () {
          return [
            _vm._v(
              "\n\t\t" +
                _vm._s(
                  _vm.getTimeRange(
                    _vm.bioBreak.start_time,
                    _vm.bioBreak.end_time
                  )
                ) +
                "\n\t\t"
            ),
            _c("span", { staticClass: "tw-mx-1" }, [_vm._v("|")]),
            _vm._v("\n\t\t" + _vm._s(_vm.getTimeElapsed()) + " elapsed\n\t"),
          ]
        },
        proxy: true,
      },
      {
        key: "breakDetailsIcon",
        fn: function () {
          return [
            _c("OrigamiIcon", {
              staticClass: "tw-block tw-align-top tw-mr-1 tw-mt-1",
              attrs: { name: "bio-break" },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "breakDetails",
        fn: function () {
          return [
            _vm._v(
              "\n\t\t" +
                _vm._s(`Ended at ${_vm.getBioBreakEndedTime}`) +
                "\n\n\t\t"
            ),
            _c(
              "div",
              { staticClass: "tw-pt-5 tw-text-origami-grey-200 tw-text-xs" },
              [
                _vm._v(
                  "\n\t\t\t" + _vm._s(_vm.remainingTimeInMinutes) + "\n\t\t"
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }