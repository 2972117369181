var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shiftsCalendar tw-w-full" },
    [
      _c("DropDown", {
        directives: [
          {
            name: "data-cy",
            rawName: "v-data-cy",
            value: "tutor-list-dropdown",
            expression: "'tutor-list-dropdown'",
          },
        ],
        staticClass: "shiftsCalendar__tutorDropdown",
        attrs: {
          label: "Tutor",
          items: _vm.PlatformManager.ServiceTutorsSchedule.tutorsList,
          "get-item-text": (item) => `${item.name} - ${item.id}`,
        },
        model: {
          value: _vm.tutorId,
          callback: function ($$v) {
            _vm.tutorId = $$v
          },
          expression: "tutorId",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "shiftsCalendar__calendar" },
        [
          _c(
            "label",
            { staticClass: "tw-font-bold tw-text-sm tw-text-xs tw-mb-[7px]" },
            [_vm._v("\n\t\t\tTime\n\t\t")]
          ),
          _vm._v(" "),
          _c("DatePicker", {
            directives: [
              {
                name: "data-cy",
                rawName: "v-data-cy",
                value: "createShift-date-picker",
                expression: "'createShift-date-picker'",
              },
            ],
            attrs: {
              popover: { placement: "right" },
              mode: "dateTime",
              is24hr: true,
              "minute-increment": 15,
              "is-range": true,
              "min-date": _vm.minimumDate,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ inputValue, inputEvents }) {
                  return [
                    _c(
                      "input",
                      _vm._g(
                        {
                          staticClass: "datePicker__input",
                          attrs: {
                            name: "shiftDate",
                            placeholder: _vm.placeholder,
                          },
                          domProps: { value: inputValue.start },
                        },
                        inputEvents.start
                      )
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.selectedRange,
              callback: function ($$v) {
                _vm.selectedRange = $$v
              },
              expression: "selectedRange",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "shiftsCalendar__shiftTypeDropdown" },
        [
          _c(
            "label",
            { staticClass: "tw-font-bold tw-text-sm tw-text-xs tw-mb-[7px]" },
            [_vm._v("\n\t\t\tShift Type\n\t\t")]
          ),
          _vm._v(" "),
          _c(
            "BaseDropdown",
            {
              directives: [
                {
                  name: "data-cy",
                  rawName: "v-data-cy",
                  value: "shiftType-list-dropdown",
                  expression: "'shiftType-list-dropdown'",
                },
              ],
              ref: "shiftTypeListDropdown",
              attrs: { items: _vm.shiftTypesForCreation },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "BaseRadio",
                        {
                          directives: [
                            {
                              name: "data-cy",
                              rawName: "v-data-cy",
                              value: `shiftType-list-dropdown-${item.id}`,
                              expression:
                                "`shiftType-list-dropdown-${item.id}`",
                            },
                          ],
                          attrs: {
                            "radio-name": "shiftType",
                            "radio-id": `shiftType-${item.id}`,
                            "radio-value": item.id,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.closeOnSelect("shiftTypeListDropdown")
                            },
                          },
                          model: {
                            value: _vm.shiftTypeId,
                            callback: function ($$v) {
                              _vm.shiftTypeId = $$v
                            },
                            expression: "shiftTypeId",
                          },
                        },
                        [
                          _c("template", { slot: "text" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.getShiftTypeName(item)) +
                                "\n\t\t\t\t\t"
                            ),
                          ]),
                        ],
                        2
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "filters" }, [
                _vm._v("\n\t\t\t\t" + _vm._s(_vm.shiftTypeFilter) + "\n\t\t\t"),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }