var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "data-cy",
          rawName: "v-data-cy",
          value: "late-tutor-card",
          expression: "'late-tutor-card'",
        },
      ],
      staticClass: "lateTutorCard lateTutorCard--redTopBorder",
    },
    [
      _c("ServiceDashboardTutorCardHeaderLateTutor", {
        attrs: { shift: _vm.cardData.shift, user: _vm.cardData.user },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }