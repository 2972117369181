<template>
	<span
		ref="infoToolTip"
		v-jest="'info-tooltip'"
		class="info-tooltip tw-cursor-pointer tw-ml-2"
	>
		<IconInformationCircle
			:width="width"
			:height="height"
			class="tw-rounded-full tw-cursor-pointer"
			:icon-color="iconColor"
			@focus.native="tooltipShowing = true"
			@blur.native="tooltipShowing = false"
		/>
	</span>
</template>

<script>
import tippy from "tippy.js";

import IconInformationCircle from "../icons/IconInformationCircle.vue";

export default {
	components: {
		IconInformationCircle,
	},
	props: {
		width: {
			type: String,
			default: "16px",
		},
		height: {
			type: String,
			default: "16px",
		},
		options: {
			required: true,
			type: Object,
		},
		iconColor: {
			type: String,
			default: "#181C28",
		},
	},
	data() {
		return {
			tooltipShowing: false,
			tippyInstance: null,
		};
	},
	watch: {
		tooltipShowing() {
			this.tooltipShowing ? this.tippyInstance?.show() : this.tippyInstance?.hide();
		},
	},
	mounted() {
		this.tippyInstance = tippy(this.$refs.infoToolTip, {
			appendTo: this.$refs.infoToolTip,
			placement: "bottom",
			...this.options,
		});
	},
};
</script>

<style scoped>
@import "~tippy.js/dist/tippy.css";
@import "~tippy.js/dist/svg-arrow.css";

:deep(.tippy-box) {
	border-radius: 10px;
	text-align: left;
	font-weight: 300;
	padding: 8px;
	background-color: var(--origami-black);
	max-width: 240px !important;
}

.info-tooltip :deep(svg path) {
	opacity: 1;
}

:deep(.tippy-box > .tippy-arrow) {
	color: var(--origami-black);
}
</style>
