<template>
	<div class="actionItem tw-py-4 tw-w-full tw-border-b-2 tw-border-grey-regular tw-border-solid tw-flex tw-justify-between tw-gap-x-4">
		<div class="tw-flex tw-flex-col tw-items-start tw-flex-grow tw-gap-y-2">
			<div class="detailsBubble tw-shadow-elevation-1 tw-py-2 tw-px-4 tw-border tw-border-solid tw-border-grey-dark tw-w-full tw-break-all">
				<p
					v-jest="'action-item-content'"
					class="tw-text-base tw-font-normal tw-text-black-high tw-w-full"
				>
					<slot name="flagged-message" />
				</p>
			</div>
			<div class="tw-flex tw-justify-between tw-items-center tw-w-full">
				<p class="tw-text-sm tw-leading-5 tw-truncate">
					<slot name="flagged-user-info" />
				</p>
				<p
					v-jest="'status-text'"
					class="tw-text-sm tw-leading-5 tw-font-bold tw-ml-4 tw-whitespace-nowrap"
				>
					<slot name="status-text" />
				</p>
			</div>
		</div>
		<div class="tw-flex tw-items-start tw-gap-y-2">
			<OrigamiIconButton
				v-jest="'inspect-uninspect-button'"
				icon-name="inspect"
				:aria-label="inspectAriaLabel"
				:is-selected="isCurrentlyInspecting"
				@click.native="isCurrentlyInspecting ? handleUninspect() : handleInspect()"
			/>
			<OrigamiIconButton
				v-jest="'false-alarm-button'"
				icon-name="block"
				aria-label="This item is a False Alarm"
				@click.native="handleFalseAlarm"
			/>
		</div>
		<ActionItemDropdown
			v-jest="'behaviour-action-dropdown'"
			:action-item="actionItem"
			:actions="actions"
			:is-action-pending="isActionPending"
			@action-taken="handleAction"
		/>
	</div>
</template>

<script>
import { OrigamiIconButton } from "@origami/vue2";

import { UNINSPECT_ACTION_ID, INSPECT_ACTION_ID, FALSE_ALARM_ACTION_ID } from "@/modules/ActionCenter/index.js";

import ActionItemDropdown from "./ActionItemDropdown.vue";

export default {
	components: {
		ActionItemDropdown,
		OrigamiIconButton,
	},
	props: {
		actionItem: {
			type: Object,
			required: true,
		},
		actions: {
			type: Array,
			required: true,
		},
		isActionPending: {
			type: Boolean,
			require: true,
		},
		isCurrentlyInspecting: {
			type: Boolean,
			require: true,
		},
	},
	data() {
		return {
			buttonActions: [
				{ id: INSPECT_ACTION_ID, name: `Inspect`, icon: "inspect", handleEvent: this.handleInspect },
				{ id: UNINSPECT_ACTION_ID, name: `Uninspect`, icon: "inspect", handleEvent: this.handleUninspect },
				{ id: FALSE_ALARM_ACTION_ID, name: `False Alarm`, icon: "block", handleEvent: this.handleFalseAlarm },
			],
		};
	},
	computed: {
		inspectAriaLabel() {
			return this.isCurrentlyInspecting ? "Uninspect this Item" : "Inspect this Item";
		},
	},
	methods: {
		handleAction({ eventHandler, payload }) {
			eventHandler(payload);
		},
		handleInspect() {
			this.$emit("handle-inspect");
		},
		handleUninspect() {
			this.$emit("handle-uninspect");
		},
		handleFalseAlarm() {
			this.$emit("handle-false-alarm");
		},
	},
};
</script>

<style scoped>
.actionItem:last-child {
	border: none;
}
.detailsBubble {
	border-radius: 1.25rem 1.25rem 1.25rem 0;
}

</style>
