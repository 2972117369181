<template>
	<div class="tw-pt-3.5">
		<ServiceScheduleHeader
			:aggregate-shift-hours="aggregateShiftHours"
			:is-showing-shift-hours="isShowingShiftHours"
			:created-shift-tutor-data="createdShiftTutorData"
			@open-create-shift-modal="openCreateShiftModal"
			@set-shift-filter="setShiftFilter"
			@clear-filters="clearFilters"
			@set-selected-tutors-list="setSelectedTutorsList"
		/>
		<div class="tw-flex tw-justify-center">
			<ServiceScheduleNoFilterBanner
				v-show="!isShowingCalendar"
				class="tw-w-3/5"
			/>
		</div>
		<ServiceScheduleCalendar
			v-show="isShowingCalendar"
			ref="serviceScheduleCalendar"
			:formatted-shifts="formattedShifts"
			@edit-shift="showEditShiftModal"
			@week-change="displayFilteredShifts"
			@set-week-dates="setWeekDates"
		/>
		<ServiceScheduleCreateShiftModal
			:start="startTime"
			:end="endTime"
			@shiftCreated="handleShiftCreated"
		/>
		<ServiceScheduleEditShiftModal
			:shift-object="shiftObject"
			@shiftEdited="updateFormattedShifts"
		/>
		<ServiceScheduleDeleteShiftModal
			:shift="shiftObject"
			@shiftDeleted="updateFormattedShifts"
		/>
		<ServiceScheduleFooter
			:is-showing-calendar="isShowingCalendar"
			:total-language-hours="totalLanguageHours"
			:total-subject-hours="totalSubjectHours"
		/>
	</div>
</template>
<script>
import { mapState } from "vuex";
import { getTime, addWeeks } from "date-fns";
import { isEmpty } from "lodash";

import Colors from "@/utilities/Colors.js";
import { getErrorText } from "@/utilities/errorHandlingHelpers.js";
import { formatDateToTimestamp } from "@/utilities/dateHelpers.js";
import { getShiftTypeNameFromShiftTypeId, getSlotColorFromShiftTypeId } from "@/utilities/ScheduleHelpers.js";
import ScheduleTypeAPI from "@/services/api/ScheduleTypes.js";

import shiftModalsMixin from "../mixins/shiftModalsMixin.js";

import ServiceScheduleCreateShiftModal from "./create/ServiceScheduleCreateShiftModal.vue";
import ServiceScheduleEditShiftModal from "./edit/ServiceScheduleEditShiftModal.vue";
import ServiceScheduleDeleteShiftModal from "./delete/ServiceScheduleDeleteShiftModal.vue";
import ServiceScheduleNoFilterBanner from "./ServiceScheduleNoFilterBanner.vue";
import ServiceScheduleHeader from "./ServiceScheduleHeader.vue";
import ServiceScheduleFooter from "./ServiceScheduleFooter.vue";

export default {
	components: {
		ServiceScheduleNoFilterBanner,
		ServiceScheduleCreateShiftModal,
		ServiceScheduleEditShiftModal,
		ServiceScheduleDeleteShiftModal,
		ServiceScheduleHeader,
		ServiceScheduleFooter,
		ServiceScheduleCalendar: () => import("./ServiceScheduleCalendar.vue"),
	},
	mixins: [shiftModalsMixin],
	data() {
		return {
			startTime: null,
			endTime: null,
			startDate: null,
			endDate: null,
			tutorId: null,
			filters: {
				selectedTutors: this.tutorIdFilter,
				selectedScheduleType: this.shiftTypeFilter,
				selectedLanguages: this.languageIdFilter,
				selectedSubjects: this.subjectsFilter,
			},
			appliedFilters: [],
			selectedLanguages: [],
			selectedScheduleType: [],
			selectedSubjects: [],
			selectedTutors: [],
			aggregateShiftHours: 0,
			shiftObject: {
				start: "",
				end: "",
				punchIn: "",
				name: "",
				id: "",
				shiftColor: "",
				shiftType: "",
				shiftEnded: "",
			},
			isSettingShiftFilter: false,
			isShowingShiftHours: false,
			isShowingCalendar: false,
			formattedShifts: [],
		};
	},
	computed: {
		...mapState([
			"PlatformManager",
			"Snackbar",
			"currentUser",
		]),
		/**
		 * @returns {Date}
		 */
		currentWeekStartTime() {
			return getTime(new Date(this.PlatformManager.ServiceTutorsSchedule.currentWeek));
		},
		/**
		 * @returns {Date}
		 */
		currentWeekEndTime() {
			return getTime(addWeeks(new Date(this.PlatformManager.ServiceTutorsSchedule.currentWeek * 1000), 1)) / 1000;
		},
		/**
		 * @returns {Array}
		 */
		totalLanguageHours() {
			const languages = this.PlatformManager.ServiceTutorsSchedule.languageList;
			const languageArray = languages.map((language) => ({ ...language, hours: 0 }));
			this.formattedShifts.forEach((shift) => {
				const isAdhoc = shift.shiftType.data.id !== 1 && shift.shiftType.data.id !== 2;
				if (!isAdhoc) {
					shift.languageId.forEach((id) => {
						const language = languageArray.find((lang) => lang.id === id);
						if (language) {
							language.hours += this.getShiftDuration(shift);
						}
					});
				}
			});
			return languageArray;
		},
		/**
		 * @returns {Object}
		 */
		totalSubjectHours() {
			const subjectsObject = {};
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			this.aggregateShiftHours = 0;
			this.formattedShifts.forEach((shift) => {
				const duration = this.getShiftDuration(shift);
				const isAdhoc = shift.shiftType.data.id !== 1 && shift.shiftType.data.id !== 2;
				if (!isAdhoc) {
					this.setAggregateShiftHours(shift);
					shift.subjects.forEach(({ id, name }) => {
						if (subjectsObject[name]) {
							subjectsObject[name].hours += duration;
						} else {
							subjectsObject[name] = { id, name, hours: duration };
						}
					});
				}
			});
			return subjectsObject;
		},
		/**
		 * @returns {Array}
		 */
		selectedTutorIds() {
			return this.selectedTutors.map((tutor) => tutor.id);
		},
		/**
		 * @returns {Boolean}
		 */
		isFilterSelected() {
			return (
				!isEmpty(this.selectedLanguages) ||
				!isEmpty(this.selectedScheduleType) ||
				!isEmpty(this.selectedSubjects) ||
				!isEmpty(this.selectedTutors)
			);
		},
		createdShiftTutorData() {
			return !isEmpty(this.selectedTutors) ? this.selectedTutors : [];
		},
	},
	watch: {
		isFilterSelected(value) {
			if (!value) {
				this.formattedShifts = [];
				this.isShowingCalendar = false;
			}
		},
	},
	async created() {
		this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/getAvailabilityTutors", { status: [1] });
		await this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/getLanguageList");
	},
	mounted() {
		this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/getSubjects");
		this.getScheduleTypes();
	},
	methods: {
		getShiftDuration(shift) {
			const shiftStartTime = formatDateToTimestamp(shift.start);
			const shiftEndTime = formatDateToTimestamp(shift.end);
			const duration = (shift.end - shift.start) / 3600000;
			const isShiftEntirelyInWeek = shiftStartTime >= this.currentWeekStartTime &&
				shiftEndTime <= this.currentWeekEndTime;
			const isShiftStartingInPreviousWeek = shiftStartTime < this.currentWeekStartTime &&
				shiftEndTime > this.currentWeekStartTime;
			const isShiftEndingInNextWeek = shiftStartTime < this.currentWeekEndTime &&
				shiftEndTime > this.currentWeekEndTime;
			if (isShiftEntirelyInWeek) {
				return duration;
			} else if (isShiftStartingInPreviousWeek) {
				const newDuration = shiftEndTime - this.currentWeekStartTime;
				return newDuration / 3600;
			} else if (isShiftEndingInNextWeek) {
				const newDuration = this.currentWeekEndTime - shiftStartTime;
				return newDuration / 3600;
			} else {
				return 0;
			}
		},
		async getScheduleTypes() {
			try {
				const response = await ScheduleTypeAPI.get();
				this.$store.commit("PlatformManager/ServiceTutorsSchedule/SET_SCHEDULE_TYPES", {
					scheduleTypesList: response.data.data,
				});
			} catch (e) {
				Sentry.captureException(e);
			}
		},
		updateFormattedShifts() {
			this.formattedShifts = this.getFormattedShifts();
		},
		/**
		 * @returns {Array}
		 */
		getFormattedShifts() {
			return Object.values(this.PlatformManager.ServiceTutorsSchedule.shifts).map((shift) => {
				return this.formatShiftBlock(shift);
			});
		},
		/**
		 * @returns {Array}
		 */
		getNewAppliedFilters() {
			const newAppliedFilters = [];
			Object.keys(this.filters).forEach((key) => {
				if (!isEmpty(this[key])) {
					newAppliedFilters.push(key);
				}
			});
			return newAppliedFilters;
		},
		/**
		 * @param {Object} shift
		 */
		async handleShiftCreated(shift) {
			try {
				this.isShowingShiftHours = true;
				this.resetFilters();
				this.setFiltersToShiftTutor(shift);
				const startDate = new Date(shift.start_time * 1000);
				setTimeout(async() => await this.$refs.serviceScheduleCalendar.debouncedHandleGoToDate(startDate), 0);
			} catch (e) {
				const errorText = getErrorText("getting all the shifts");
				this.snackbarMessage("error", errorText);
			}
		},
		/**
		 * @param {Object} shift
		 */
		setFiltersToShiftTutor(shift) {
			this.selectedTutors = [
				{
					id: shift.user.data.id,
					name: `${shift.user.data.first_name} ${shift.user.data.last_name}`,
				},
			];
			this.appliedFilters = this.getNewAppliedFilters();
		},
		openCreateShiftModal() {
			this.$bvModal.show("service-schedule-create-shift-modal");
		},
		/**
		 * @param {Object} shift
		 * @param {Number} duration
		 */
		setAggregateShiftHours(shift) {
			this.aggregateShiftHours += this.getShiftDuration(shift);
		},
		/**
		 * @param {Object} shift
		 * @returns {Boolean}
		 */
		tutorIdFilter(shift) {
			return isEmpty(this.selectedTutorIds) || this.selectedTutorIds.includes(shift.user.data.id);
		},
		/**
		 * @param {Object} shift
		 * @returns {Boolean}
		 */
		shiftTypeFilter(shift) {
			return (
				isEmpty(this.selectedScheduleType) ||
				this.selectedScheduleType.includes(shift.scheduleType.data.id)
			);
		},
		/**
		 * @param {Object} shift
		 * @returns {Boolean}
		 */
		languageIdFilter(shift) {
			const arrayOfLanguagesId = this.getArrayOfLanguagesIds(shift);
			return this.selectedLanguages.every((id) => arrayOfLanguagesId.includes(parseInt(id)));
		},
		/**
		 * @param {Object} shift
		 * @returns {Boolean}
		 */
		subjectsFilter(shift) {
			const shiftSubjects = this.getArrayOfShiftObjects(shift);
			return this.selectedSubjects.every((id) => shiftSubjects.some((shift) => shift.id === id));
		},
		/**
		 * @param {Object} shift
		 * @returns {Array}
		 */
		getArrayOfLanguagesIds(shift) {
			return shift.user.data.tutor.data.languages.data.map((language) => language.id);
		},
		/**
		 * @param {Object} shift
		 * @returns {Array}
		 */
		getArrayOfShiftObjects(shift) {
			return shift.user.data.tutor.data.subjects.data.map((subject) => {
				return { id: subject.id, name: subject.name };
			});
		},
		setSelectedTutorsList(selectedTutors) {
			this.selectedTutors = selectedTutors;
			if (this.selectedTutors.length === 0) {
				this.isShowingShiftHours = false;
			}
		},
		async setShiftFilter(filters) {
			this.selectedLanguages = filters.selectedLanguages;
			this.selectedScheduleType = filters.selectedScheduleType;
			this.selectedSubjects = filters.selectedSubjects;
			this.selectedTutors = filters.selectedTutors;

			this.isSettingShiftFilter = true;
			try {
				this.appliedFilters = this.getNewAppliedFilters();
				await this.displayFilteredShifts();
			} catch (e) {
				const errorText = getErrorText("getting all the shifts");
				this.snackbarMessage("error", errorText);
			} finally {
				this.isSettingShiftFilter = false;
				this.isShowingShiftHours = true;
			}
		},
		clearFilters() {
			this.resetFilters();
			this.isShowingShiftHours = false;
			this.isShowingCalendar = false;
		},
		resetFilters() {
			this.appliedFilters = [];
			this.selectedLanguages = [];
			this.selectedScheduleType = [];
			this.selectedSubjects = [];
			this.selectedTutors = [];
			this.formattedShifts = [];
		},
		/**
		 * @param {Object} event
		 */
		showEditShiftModal(event) {
			let isEssayShiftBlock;
			if (event.event.extendedProps.shiftType.data.id === 2) {
				isEssayShiftBlock = true;
			} else if (event.event.extendedProps.shiftType.data.id === 1) {
				isEssayShiftBlock = false;
			}
			this.shiftObject = {
				start: event.event.start,
				end: event.event.end,
				punchIn: event.event.extendedProps.punchIn,
				name: event.event.extendedProps.tutorName,
				id: parseInt(event.event.id),
				shiftColor: event.event.backgroundColor,
				shiftType: event.event.extendedProps.shiftType,
				shiftEnded: event.event.extendedProps.shiftEnded,
			};
			this.$store.commit("PlatformManager/ServiceTutorsSchedule/SET_IS_ESSAY_SHIFT", {
				isEssayShift: isEssayShiftBlock,
			});
			this.$bvModal.show("service-schedule-edit-shift-modal");
		},
		/**
		 * @param {Array} langs
		 * @return {Array}
		 */
		getLanguageShortnames(langs) {
			const langsArray = [];
			langs.forEach((lang) => {
				langsArray.push(lang.abbreviation.toUpperCase());
			});
			return langsArray;
		},
		/**
		 * @param {Object} shift
		 * @return {Object}
		 */
		formatShiftBlock(shift) {
			const color = shift.transfer_pending
				? Colors.C_PEACH
				: getSlotColorFromShiftTypeId(shift.scheduleType.data.id);
			const langs = this.getLanguageShortnames(shift.user.data.tutor.data.languages.data);
			const startDateOfYesterday = new Date();
			startDateOfYesterday.setDate(startDateOfYesterday.getDate() - 1);
			const isShiftEnded = shift.punch_out !== null;
			return {
				start: new Date(shift.start_time * 1000),
				end: new Date(shift.end_time * 1000),
				punchIn: shift.punch_in,
				id: shift.id,
				title: `[${getShiftTypeNameFromShiftTypeId(shift.scheduleType.data.id)}]\n` +
					`${shift.user.data.first_name} ${shift.user.data.last_name}\n` +
					`User ID: ${shift.user.data.id}\n` +
					`[${langs.join(", ")}]`,
				editable: false,
				shiftType: shift.scheduleType,
				tutorId: shift.user.data.id,
				tutorName: `${shift.user.data.first_name} ${shift.user.data.last_name}`,
				backgroundColor: color,
				borderColor: color,
				textColor: Colors.C_BLACK,
				languageId: this.getArrayOfLanguagesIds(shift),
				subjects: this.getArrayOfShiftObjects(shift),
				shiftEnded: isShiftEnded,
			};
		},
		/**
		 * @param {Object} start
		 * @param {Object} end
		 */
		async getShifts() {
			try {
				const data = {
					include: "user.tutor.languages,shiftTransfer,scheduleType,user.tutor.subjects",
					from: this.startDate,
					to: this.endDate,
					tutors: this.selectedTutorIds,
					schedule_type_ids: this.selectedScheduleType,
					languages: this.selectedLanguages,
					subjects: this.selectedSubjects,
				};
				await this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/getShifts", data);
				this.tutorId = null;
			} catch (e) {
				Sentry.captureException(e);
				const errorText = getErrorText("getting all the shifts");
				this.snackbarMessage("error", errorText);
			}
		},
		/**
		 * Set date range on view change
		 */
		async displayFilteredShifts() {
			try {
				await this.getShifts();
				this.$store.commit("PlatformManager/ServiceTutorsSchedule/SET_CURRENT_START_OF_WEEK_IN_SCHEDULER", {
					currentWeek: this.startDate,
				});
				this.formattedShifts = this.getFormattedShifts();
				this.isShowingCalendar = true;
			} catch (e) {
				const errorText = getErrorText("getting all the shifts");
				this.snackbarMessage("error", errorText);
			}
		},
		setWeekDates({ startDate, endDate }) {
			this.startDate = startDate;
			this.endDate = endDate;
		},
	},
};
</script>
