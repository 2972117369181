<template>
	<div
		v-data-cy="'section-meal-break-request'"
		class="tw-max-w-screen-lg tw-mx-auto tw-pb-24"
	>
		<section aria-label="Meal Break Requests">
			<h2
				v-data-cy="'meal-break-request'"
				class="tw-text-2xl tw-font-extrabold tw-mb-5"
			>
				Meal Break Requests ({{ filteredBreakRequests.length }})
			</h2>
			<BaseCheckbox
				v-model="isOnlyEssayReviewBreakRequests"
				v-data-cy="'essay-break-request'"
				:checkbox-value="'essayBreakRequest'"
				:checkbox-id="'essayBreakRequest'"
			>
				<template slot="text">
					Writing Review Shifts Only
				</template>
			</BaseCheckbox>
			<div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-4 tw-mb-12 tw-mt-4">
				<div class="breaks tw-overflow-y-auto">
					<div
						v-if="isMealBreakRequestEmpty"
						v-jest="'empty-break-request'"
						v-data-cy="'empty-break-request'"
						class="tw-grid tw-items-center tw-bg-white-high tw-font-bold tw-text-2xl tw-text-center tw-font-bold tw-text-grey-dark tw-px-4 tw-h-full"
					>
						Go grab a coffee! There are no break requests at this moment
					</div>
					<TutorBreakCardMealBreakRequest
						v-for="breakRequest in filteredBreakRequests"
						:key="breakRequest.id"
						v-jest="'break-request'"
						:break-request="breakRequest"
						@select-time="getBreaksForSelectedTime($event)"
					/>
				</div>
				<div class="breaks tw-bg-grey-slightly tw-border-2 tw-text-grey-dark tw-p-6">
					<template v-if="selectedBreakStartTime === null">
						<div
							v-jest="'empty-tutors-on-break'"
							v-data-cy="'empty-tutors-on-break'"
							class="tw-grid tw-items-center tw-bg-grey-slightly tw-font-bold tw-text-2xl tw-text-center tw-font-bold tw-h-full"
						>
							Click on a break request to see additional information
						</div>
					</template>
					<template v-else>
						<span class="tw-text-xl tw-font-bold tw-h-full">
							Tutors on Breaks From  <br> {{ timeRange }} ({{ breaks.length }})
						</span>
						<div class="tutorsOnBreakFrom tw-overflow-y-auto tw-mt-4 tw-h-full">
							<TutorBreakCard
								v-for="tutorBreak in breaks"
								:key="tutorBreak.id"
								v-jest="'break-selected'"
								:break="tutorBreak"
							/>
						</div>
					</template>
				</div>
			</div>
		</section>
		<section
			v-data-cy="'upcoming-meal-break'"
			aria-label="Upcoming Meal Breaks"
		>
			<h2 class="tw-text-3xl tw-font-extrabold tw-mb-5">
				Upcoming Meal Breaks ({{ upcomingBreaks.length }})
			</h2>
			<div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-mb-12 tw-gap-x-4">
				<TutorBreakCardUpcomingBreak
					v-for="upcomingBreak in upcomingBreaks"
					:key="upcomingBreak.id"
					v-jest="'upcoming-break'"
					:upcoming-break="upcomingBreak"
					details-icon="meal-break"
				/>
			</div>
		</section>
		<section
			v-data-cy="'active-meal-break'"
			aria-label="Active Meal Breaks"
		>
			<h2 class="tw-text-3xl tw-font-extrabold tw-mb-5">
				Active Meal Breaks ({{ currentBreaks.length }})
			</h2>
			<div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-mb-12 tw-gap-x-4">
				<TutorBreakCardActiveBreak
					v-for="currentBreak in currentBreaks"
					:key="currentBreak.id"
					v-jest="'current-break'"
					:current-break="currentBreak"
					details-icon="meal-break"
				/>
			</div>
		</section>
	</div>
</template>

<script>
import BaseCheckbox from "@/components/BaseCheckbox.vue";

import { formatTimestampToHM } from "../../../utilities/dateHelpers.js";

import TutorBreakCard from "./TutorBreakCard.vue";
import TutorBreakCardMealBreakRequest from "./TutorBreakCardMealBreakRequest.vue";
import TutorBreakCardUpcomingBreak from "./TutorBreakCardUpcomingBreak.vue";
import TutorBreakCardActiveBreak from "./TutorBreakCardActiveBreak.vue";

export default {
	components: {
		TutorBreakCard,
		TutorBreakCardMealBreakRequest,
		TutorBreakCardUpcomingBreak,
		TutorBreakCardActiveBreak,
		BaseCheckbox,
	},
	data() {
		return {
			isOnlyEssayReviewBreakRequests: false,
			breaks: [],
			selectedBreakStartTime: null,
			selectedBreakEndTime: null,
			refreshInterval: null,
		};
	},
	computed: {
		timeRange() {
			return `${formatTimestampToHM(this.selectedBreakStartTime)} - ${formatTimestampToHM(this.selectedBreakEndTime)}`;
		},
		currentBreaks() {
			return this.$store.getters["BreaksCenter/currentMealBreaks"];
		},
		upcomingBreaks() {
			return this.$store.getters["BreaksCenter/upcomingMealBreaks"];
		},
		isMealBreakRequestEmpty() {
			return this.$store.getters["BreaksCenter/isMealBreakRequestEmpty"];
		},
		filteredBreakRequests() {
			return this.isOnlyEssayReviewBreakRequests ?
				this.$store.getters["BreaksCenter/essayShiftMealBreakRequests"]
				: this.$store.getters["BreaksCenter/mealBreakRequests"];
		},
	},
	created() {
		const ONE_SECOND_IN_MS = 1000;
		this.refreshInterval = setInterval(() => {
			this.$store.dispatch("BreaksCenter/setCurrentTime");
		}, ONE_SECOND_IN_MS);
	},
	beforeDestroy() {
		window.clearInterval(this.refreshInterval);
	},
	methods: {
		getBreaksForSelectedTime(selectedTime) {
			this.selectedBreakStartTime = selectedTime.start_time;
			this.selectedBreakEndTime = selectedTime.end_time;
			this.breaks = this.upcomingBreaks.filter((tutorBreak) => {
				return (
					tutorBreak.break.start_time < selectedTime.end_time &&
					tutorBreak.break.end_time > selectedTime.start_time
				);
			});
		},
	},
};
</script>
<style scoped>
	.breaks {
		height: 45rem;
	}
	.tutorsOnBreakFrom {
		height: 33rem;
	}
</style>
