import { camelCase, upperFirst } from "lodash";

export default {
	CASE_CHECK: "(?=.*[a-z])(?=.*[A-Z])",
	NUMBER_CHECK: "(?=.*[0-9])",
	SPECIAL_CHAR_CHECK: "(?=.*[^A-Za-z0-9])",
};

/**
 * Outputs a number and its textual unit--conditionally pluralized, as necessary
 *   (e.g.
 * 		"0 items", "1 message", "2 foobars"
 *   	"0 activities", "1 activity", "2 activities"
 *	 )
 * @param {Number|String} number
 * @param {String} text
 * @returns {String}
 */
export function formatPlurality(number, text) {
	const isSingular = Number(number) === 1;

	if (isSingular) {
		return `${number} ${text}`;
	} else {
		let formattedText = text;
		if (text.endsWith("y")) {
			formattedText = `${text.slice(0, -1)}ies`;
		} else if (!text.endsWith("s")) {
			formattedText = `${text}s`;
		}
		return `${number} ${formattedText}`;
	}
}

export function unPluralize(text) {
	if (text.endsWith("ies")) {
		return `${text.slice(0, -3)}y`;
	}

	return text.endsWith("s") ? text.slice(0, -1) : text;
}

/**
 * Outputs a human-friendly stringified array
 *   (e.g. "Item1, Item2, and Item 3")
 * @param {string[]} items List of strings to be nicely joined
 * @param {string} [andTranslation] Defaults to English but be sure to pass a translation as/if needed
 * @returns {String}
 */
export function formatListOfStrings(items, andTranslation = "and") {
	const listOfStrings = items.reduce((list, item, index, arr) => {
		const isLastItem = index === arr.length - 1;
		const separator = isLastItem ? ` ${andTranslation} ` : ", ";
		return `${list ? `${list}${separator}` : ""}${item}`;
	}, "");
	return listOfStrings;
}

/**
 * @param {string[]} userIds
 * @param {User[]} availableUsers
 * @param {string} currentUserId
 * @returns {String}
 */
export function formatListOfUserNames({
	userIds,
	availableUsers,
	currentUserId,
	translate,
}) {
	const otherUserNames = userIds
		.filter((v, i, a) => a.indexOf(v) === i) // remove duplicates
		.filter((userId) => userId !== currentUserId) // remove self
		.map((userId) => {
			const user = availableUsers.find(({ id }) => id === userId);
			if (user) {
				return `${user.first_name} ${user.last_name[0]}`;
			}
		})
		.filter(Boolean) // remove empties
		.sort((a, b) => a.localeCompare(b)); // sort alphabetically
	const allUserNames = otherUserNames
		.concat(userIds.includes(currentUserId) ? translate("chat.reactions.you") : null)
		.filter(Boolean);
	const listOfUserNames = formatListOfStrings(allUserNames, translate("chat.reactions.and"));
	return listOfUserNames;
}

export function pascalCase(str) {
	return upperFirst(camelCase(str)).trim();
}
/**
 * Outputs a string of the converted number and it's corresponding symbole
 * @param {Number} number
 * @returns {String}
 */
export function numberTrimmer(number) {
	let nbr = number.toString();
	if (number >= 1000000) {
		nbr = (Math.round(number / 1000000)) + "M";
	} else if (number >= 1000) {
		if ((number / 1000).toFixed(2) % 1 == 0 || number >= 100000) {
			nbr = Math.floor(number / 1000) + "K";
		} else if (number <= 9999) {
			nbr = Math.floor((number / 100)) / 10 + "K";
		}
	}
	return nbr;
}
