import { find, map, pickBy, identity, omit, orderBy } from "lodash";

import {
	getProblems,
	getProblem,
	saveProblem,
	createProblem,
	deleteProblem,
	addComment,
	setProblemListOrder,
} from "../service/problemCreator.js";

import Filters from "./problemCreatorFilters.js";
import Selectors from "./problemCreatorSelectors.js";
import Comments from "./comments.js";


const getDefaultState = () => ({
	problemList: [],
	problemInEdit: null,
	apiQueryObject: {},
	pagination: {
		paged: 1,
		pageSize: 15,
		page: 1,
		isActive: true,
	},
	searchString: "",
	api: {
		isLoading: false,
		isLoadError: false,
		isSaveError: false,
		isSaveSuccess: false,
		isSaveCommentError: false,
	},
});

const ALL_OPTIONS_SELECTED = -1;

const buildSlug = ({ display_title, slug }) =>
	slug
		? slug
		: display_title.toLowerCase().match(/[a-z0-9]+/g, "").join("-");

const buildQueryObject = (params, display_title = "") => {
	const validParams = Object.entries(params).reduce((agg, [key, selections]) => (
		selections === ALL_OPTIONS_SELECTED
			? agg
			: { ...agg, [key]: selections }
	), {});

	const paginatedParams =
		params.isActive ? validParams : { ...validParams, page: 1 };

	return {
		...omit(paginatedParams, "isActive"),
		...(display_title ? { display_title } : {}),
	};
};

const json_configure_default = () => ({ AlgSet: ["AlgebraSupportEnabled", "additiveCommutativeLaw", "multCommutativeLaw", "distributiveLaw", "inverseDistributiveLaw", "substitution", "dragToCopy", "commonDenominator", "dragOutOfDivision", "parenthesesLaws", "combineFactors", "inverseCombineFactors", "factorOut", "addUnaryLaws", "distributeExponent", "nthroots", "quadratics", "changeSide", "variableIndices", "peelOff", "absoluteValue"], GenSet: [], NumSet: ["NumericsEnabled", "addSubtract", "multiplyDivide", "exponentiate", "computeRoots", "primeFactorization", "functionEvaluation"], FuncIdentSet: [] });

const state = getDefaultState();

const store = {
	namespaced: true,
	state,
	mutations: {
		SET_NEW_PROBLEM(state) {
			const json_configure = json_configure_default();
			state.problemInEdit = { id: null, json_configure };
		},

		SET_API_QUERY_OBJECT(state, payload) {
			state.apiQueryObject = payload;
		},

		SET_API_STATE(state, payload) {
			state.api = { ...state.api, ...payload };
		},

		CLEAR_LOAD_ERROR(state) {
			state.api = { ...state.api, isLoadError: false };
		},

		SET_PROBLEMS(state, payload) {
			state.problemList = payload;
		},

		SET_PAGINATION(state, payload) {
			state.pagination = { ...state.pagination, ...payload };
		},

		SET_PROBLEM_IN_EDIT(state, payload) {
			if (payload) {
				const problemInEdit = { ...state.problemInEdit, ...payload };

				state.problemInEdit = problemInEdit.json_configure
					? problemInEdit
					: { ...problemInEdit, json_configure: json_configure_default() };
			} else {
				state.problemInEdit = null;
			}
		},

		SET_SEARCH_STRING(state, searchString) {
			state.pagination = { ...state.pagination, isActive: false };
			state.searchString = searchString;
		},
	},
	getters: {
		filters() {
			return store.modules.Filters.state.filterSelections;
		},
		selectors() {
			// pickBy looks at object values and returns only non-falsy values (which is tested by identity)
			return pickBy(store.modules.Selectors.state.filterSelections, identity);
		},
	},
	actions: {
		clearFilters({ commit }) {
			commit("Filters/CLEAR_FILTER_SELECTIONS");
			commit("SET_SEARCH_STRING", "");
		},

		async changePage({ commit, dispatch }, page) {
			commit("SET_PAGINATION", page);
			dispatch("loadProblems");
		},
		async loadAllProblemsForTopic({ state, commit }) {
			commit("SET_API_STATE", { isLoadError: false, isLoading: true });
			const params = {
				...buildQueryObject({ topic_id: [state.problemList[0]?.topic_id] }),
			};
			try {
				const { data } = await getProblems(params);
				commit("SET_PROBLEMS", data);
			} catch (e) {
				Sentry.captureException(e);
				commit("SET_API_STATE", { isLoadError: true });
			}
			commit("SET_API_STATE", { isLoading: false });
		},
		async loadProblems({ state, commit, getters: { filters } }) {
			commit("SET_API_STATE", { isLoadError: false, isLoading: true });

			const { searchString, pagination } = state;

			const params = {
				...buildQueryObject({ ...filters, ...pagination }, searchString.trim()),
			};

			try {
				const { data, pagination: { last_page: total, current_page: page } } = await getProblems(params);

				commit("SET_PROBLEMS", data);
				commit("SET_PAGINATION", { total, isActive: true, page });
			} catch (e) {
				Sentry.captureException(e);
				commit("SET_API_STATE", { isLoadError: true });
			}

			commit("SET_API_STATE", { isLoading: false });
		},

		async createProblem({ state, commit, dispatch, getters: { selectors } }) {
			commit("SET_API_STATE", { isSaveError: false });
			commit("SET_PROBLEM_IN_EDIT", { ...state.problemInEdit, ...selectors });

			try {
				const { id } = await createProblem({ ...state.problemInEdit, ...selectors });
				const updatedProblems = await getProblems();
				const updatedProblemsWithSlug = updatedProblems.map((problem) =>
					problem.id === id
						? { ...problem, slug: buildSlug(problem) }
						: problem,
				);

				commit("SET_PROBLEMS", updatedProblemsWithSlug);
				dispatch("setProblemInEdit", { id });
			} catch (e) {
				Sentry.captureException(e);
				commit("SET_API_STATE", { isSaveError: true });
			}
		},

		async saveProblem({ state, commit, getters: { selectors } }) {
			commit("SET_API_STATE", { isSaveError: false });
			commit("SET_PROBLEM_IN_EDIT", { ...state.problemInEdit, ...selectors });

			try {
				await saveProblem(state.problemInEdit);
				commit("SET_PROBLEMS", await getProblems());
			} catch (e) {
				Sentry.captureException(e);
				commit("SET_API_STATE", { isSaveError: true });
			}
		},

		async deleteProblem({ state, commit }) {
			await deleteProblem(state.problemInEdit.id);
			commit("SET_PROBLEMS", await getProblems());
		},

		setProblemInEdit({ state, commit }, { id, problem }) {
			const problemInEdit =
				problem
					? problem
					: find(state.problemList, { id });

			const tags = map(problemInEdit.tags, "id");

			commit("SET_PROBLEM_IN_EDIT", {
				...problemInEdit,
				tags,
			});
		},

		async addComment({ state, dispatch, commit }, comment) {
			const { id: problem_id } = state.problemInEdit;
			commit("SET_API_STATE", { isSaveCommentError: false });

			try {
				await addComment(problem_id, { comment, problem_id });
				dispatch("setProblemInEdit", { problem: await getProblem(problem_id) });
			} catch (e) {
				Sentry.captureException(e);
				commit("SET_API_STATE", { isSaveCommentError: true });
			}
		},

		async saveProblemListOrder({ dispatch, commit }, order) {
			try {
				await setProblemListOrder({ order });
				dispatch("loadProblems");
				commit("SET_API_STATE", { isSaveSuccess: true });
				setTimeout(() => {
					commit("SET_API_STATE", { isSaveSuccess: false });
				}, 3000);
			} catch (e) {
				Sentry.captureException(e);
				commit("SET_API_STATE", { isSaveError: true });
			}
		},

		updateProblemList({ state, commit }, updatedProblem) {
			const convertedProblem = {
				...updatedProblem,
				comments: orderBy(updatedProblem.comments, ["created_at"]),
				json_configure: JSON.parse(updatedProblem.json_configure),
			};

			commit("SET_PROBLEMS",
				state.problemList.map((problem) =>
					problem.id === updatedProblem.id ? convertedProblem : problem,
				),
			);
		},

		async updateProblemById({ state, dispatch, commit }, field) {
			try {
				const problemToUpdate = find(state.problemList, { id: field.id });

				if (problemToUpdate) {
					const tags = map(problemToUpdate.tags, "id");
					const updatedProblem = await saveProblem({
						...problemToUpdate,
						tags,
						...omit(field, "id"),
					});
					dispatch("updateProblemList", updatedProblem);
					commit("SET_API_STATE", { isSaveSuccess: true });
				} else {
					commit("SET_API_STATE", { isSaveSuccess: false });
				}
			} catch (e) {
				Sentry.captureException(e);
				commit("SET_API_STATE", { isSaveSuccess: false });
			}
		},
	},
	modules: {
		Filters,
		Selectors,
		Comments,
	},
};

export default store;
