var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BreaksNotification",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "meal-break-in-progress-notification",
          expression: "'meal-break-in-progress-notification'",
        },
      ],
      staticClass: "tw-mb-4",
      attrs: { "notification-type": "info" },
    },
    [
      _c(
        "p",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "meal-break-in-progress-time",
              expression: "'meal-break-in-progress-time'",
            },
          ],
          staticClass: "tw-font-bold tw-text-sm",
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.timeText) + "\n\t")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }