import moment from "moment";
import { isEmpty } from "lodash";

export default {
	// for show district
	school(data) {
		const isLoginTypePresent = !isEmpty(data.loginType.data);

		return {
			id: data.id,
			name: data.name,
			licensedStudents: data.students_count,
			licenseExpiry: data.license_ends_at
				? moment.unix(data.license_ends_at).format("YYYY-MM-DD")
				: "N/A",
			loginType: isLoginTypePresent ? data.loginType.data.name : "Not Assigned",
			CSmanager: Object.keys(data.manager.data).length
				? data.manager.data.first_name + " " + data.manager.data.last_name
				: "Unassigned",
		};
	},
	tutorComment(data) {
		return {
			date: moment(data.created_at).format("YYYY-MM-DD"),
			tutor: data.tutor.data.first_name,
			comment: data.comment,
			id: data.id,
		};
	},
	// works for DMs
	user(data) {
		return {
			id: data.id,
			fullName: data.first_name ? data.first_name + " " + data.last_name : "N/A",
			username: data.username ? data.username : "Not Assigned",
			email: data.email,
			licenseExpiry: data.license_expiry_date
				? moment.unix(data.license_expiry_date).format("YYYY-MM-DD")
				: "N/A",
		};
	},
	// for show school
	transformSection(data) {
		const teachers = data.teachers.data;
		let teacherNames = "";
		let counter = 0;

		const hasTeachers = teachers.length;

		// generate teacher list
		if (hasTeachers) {
			teachers.forEach(function(teacher) {
				counter = counter + 1;
				const teacherName = teacher.first_name + " " + teacher.last_name;
				teacherNames += teacherName;
				if (counter < hasTeachers) {
					teacherNames += ", ";
				}
			});
		}

		const topics = data.topics.data;
		const topicNames = [];
		let displayTopics = "";

		// sort topics by subject
		topics.forEach(function(topic) {
			topicNames.push(topic.name);
		});

		displayTopics = topicNames[0] + " (" + topicNames.length + ")";

		return {
			id: data.id,
			name: data.name,
			courseName: data.course.data.name,
			teacher: hasTeachers ? teacherNames : "N/A",
			status: data.status,
			startDate: data.active_from,
			endDate: data.active_to,
			students: data.students_count,
			topics: displayTopics,
		};
	},
};
