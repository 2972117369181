<template>
	<div>
		<BreaksNotification class="tw-mb-4">
			You cannot go on a <span class="tw-font-bold">“Bio break”</span> when you have students in the chat. Please try again when you have no students in the chat.
		</BreaksNotification>
		<OrigamiButton
			v-jest="'bio-break-classroom-error-ok-button'"
			class="tw-mb-2 tw-w-full tw-justify-center"
			variant="primary"
			@click.native="cancelBioBreakRequest"
		>
			Ok, got it!
		</OrigamiButton>
	</div>
</template>

<script>
import { OrigamiButton } from "@origami/vue2";

import BreaksNotification from "../templates/BreaksNotification.vue";

export default {
	name: "BioBreakClassroomError",
	components: {
		BreaksNotification,
		OrigamiButton,
	},
	methods: {
		cancelBioBreakRequest() {
			this.$store.dispatch("BreaksManagementWidget/resetWorkflow");
		},
	},
};
</script>
