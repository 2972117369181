import { setPostLoginRedirectUri } from "@/modules/Authentication/index.js";

import { ONBOARDING_ROUTE } from "../utils/constant.js";

let promisedStudentStatus;

export default async function(to, from, next) {
	if (!promisedStudentStatus) {
		promisedStudentStatus = this.dispatch("StudentOnboarding/getStudentStatus");
	}
	await promisedStudentStatus;

	if (to.path === ONBOARDING_ROUTE) {
		if (!this.state.StudentOnboarding.isNew) {
			return next("/student/dashboard");
		}
		return next();
	}
	if (this.state.StudentOnboarding.isNew) {
		setPostLoginRedirectUri(to.fullPath);
		return next(ONBOARDING_ROUTE);
	}
	return next();
}
