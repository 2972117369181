import { isEmpty, isNil } from "lodash";
import { isAfter, startOfDay } from "date-fns";

import TopicsAPI from "@/services/api/Topics.js";

import HandoffsAPI from "../../../services/api/Handoffs.js";
import ServiceDataAPI from "../services/ServiceData.js";
import SchedulesAPI from "../../../services/api/Schedules.js";
import { formatDateToTimestamp, formatTimestampToDate } from "../../../utilities/dateHelpers.js";

const getDefaultState = () => ({
	selectedTutor: null,
	isTutorQuickEditOpen: false,
	selectedTutorSchedule: [],
	liveTutors: [],
	tutorManagers: [],
	upcomingTutors: [],
	essayTutors: [],
	summary: null,
	summaryPeriod: "today",
	transferSessionData: {
		sessionId: null,
		sessionName: "",
		outgoingTutorId: null,
		schoolRegionCode: "",
		schoolDistrictId: "",
	},
	navbarMainTab: "Service",
	liveUSTutors: [],
	filteredTutors: [],
});

export const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_SELECTED_TUTOR(state, payload) {
		state.selectedTutor = payload.selectedTutor;
	},
	SET_TUTOR_QUICK_EDIT_OPEN(state, payload) {
		state.isTutorQuickEditOpen = payload.isTutorQuickEditOpen;
	},
	SET_SELECTED_TUTOR_SCHEDULE(state, payload) {
		state.selectedTutorSchedule = payload.selectedTutorSchedule;
	},
	SET_TUTOR_MANAGERS(state, payload) {
		state.tutorManagers = payload.tutorManagers;
	},
	SET_UPCOMING_TUTORS(state, payload) {
		state.upcomingTutors = payload.upcomingTutors;
	},
	SET_ESSAY_TUTORS(state, payload) {
		state.essayTutors = payload.essayTutors;
	},
	SET_SUMMARY(state, payload) {
		state.summary = payload.summary;
	},
	SET_SUMMARY_PERIOD(state, { period }) {
		state.summaryPeriod = period;
	},
	SET_IS_LOADING_RESOURCE(state, payload) {
		state[payload.key] = payload.value;
	},
	SET_TUTORS(state, payload) {
		state.liveTutors = payload.liveTutors;
	},
	SET_US_TUTORS(state, payload) {
		state.liveUSTutors = payload.liveUSTutors;
	},
	SET_TRANSFER_SESSION_DATA(state, payload) {
		state.transferSessionData = payload.transferSessionData;
	},
	SET_SESSIONS_IN_LIVE_TUTOR(state, payload) {
		state.liveTutors[payload.liveTutorIndex].sessions = payload.sessions;
	},
	SET_LATEST_ESSAY_REVIEW_ETA(state, payload) {
		state.summary.latest_essay_review_eta = payload.eta;
	},
	SET_FILTERED_TUTORS(state, payload) {
		state.filteredTutors = payload;
	},
};

export const getters = {
	isLoadingSummary(state) {
		return state.summary === null;
	},
	isLoadingTutorManagers(state) {
		return state.tutorManagers === null;
	},
	isLoadingLiveTutors(state) {
		return state.liveTutors === null;
	},
	isLoadingEssayTutors(state) {
		return state.essayTutors === null;
	},
	isLoadingUpcomingTutors(state) {
		return state.upcomingTutors === null;
	},
	essayTutorsLength(state) {
		if (state.essayTutors === null) {
			return 0;
		}
		return state.essayTutors.length;
	},
	liveTutorsLength(state) {
		if (state.liveTutors === null) {
			return 0;
		}
		return state.liveTutors.length;
	},
	essayReviewSummary({ summary }) {
		let essayReviewSummary = null;
		if (summary !== null) {
			essayReviewSummary = {
				lateNum: summary.current_late_essays,
				criticalNum: summary.critical_essays,
				atRiskNum: summary.at_risk_essays,
				inQueueNum: summary.queued_essays,
				newInQueueNum: summary.new_essays,
				inProgressInQueueNum: summary.in_progress_essays,
				reviewedNum: summary.reviewed_essays,
				reviewedLateNum: summary.reviewed_late_essays,
			};
		}
		return essayReviewSummary;
	},
	liveHelpSummary({ summary }) {
		let sessionsSummary = null;
		if (summary !== null) {
			sessionsSummary = {
				missedStudentsNum: summary.missed_students,
				ongoingSessionsNum: summary.ongoing_sessions,
				totalSessionsNum: summary.total_sessions,
				missedSessionIds: summary.missed_session_ids,
			};
		}
		return sessionsSummary;
	},
	USCAFingerPrintedTutors(state) {
		return state.liveTutors.filter((tutor) => tutor.user.is_us_ca_fingerprinted);
	},
	lateTutors(state) {
		const lateTutors = state.liveTutors.filter((tutor) =>
			!tutor.shift.punch_in && isAfter(new Date(), formatTimestampToDate(tutor.shift.start_time)));

		return lateTutors.concat(
			state.essayTutors.filter((tutor) =>
				!tutor.shift.punch_in && isAfter(new Date(), formatTimestampToDate(tutor.shift.start_time))),
		);
	},
	sessionLoadCount(state) {
		return state.liveTutors.reduce((load, { sessions }) => {
			load[sessions.length] = load[sessions.length] + 1 || 1;
			return load;
		}, {});
	},
};

export const actions = {
	setSelectedTutor({ commit }, payload) {
		commit("SET_SELECTED_TUTOR", {
			selectedTutor: payload,
		});
	},
	setTutorQuickEditOpen({ commit, dispatch }, payload) {
		commit("SET_TUTOR_QUICK_EDIT_OPEN", {
			isTutorQuickEditOpen: payload,
		});
		dispatch("PlatformManager/ServiceTutorsSchedule/resetTutorQuickEditStep",
			null,
			{ root: true });
	},
	openTutorQuickEdit({ dispatch }, payload) {
		dispatch("setSelectedTutor", payload);
		dispatch("setTutorQuickEditOpen", true);
	},
	setIsEssayShift({ dispatch }, payload) {
		dispatch("PlatformManager/ServiceTutorsSchedule/setIsEssayShift",
			{ isEssayShift: payload.isEssayShift },
			{ root: true });
	},
	async getSelectedTutorSchedule({ commit, state }) {
		try {
			if (!isNil(state.selectedTutor)) {
				const currentFromDate = startOfDay(new Date());
				const currentToDate = startOfDay(new Date());
				const from = formatDateToTimestamp(currentFromDate.setDate(currentFromDate.getDate() - 1));
				const to = formatDateToTimestamp(currentToDate.setDate(currentToDate.getDate() + 2));
				const params = {
					from,
					to,
				};
				const response = await SchedulesAPI.getSchedulesforTutor(state.selectedTutor.id, params);
				commit("SET_SELECTED_TUTOR_SCHEDULE", {
					selectedTutorSchedule: response.data.data,
				});
			}
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	resetSelectedTutorSchedule({ commit }) {
		commit("SET_SELECTED_TUTOR_SCHEDULE", {
			selectedTutorSchedule: [],
		});
	},
	async getUpcomingTutors({ commit }) {
		try {
			const response = await ServiceDataAPI.getUpcomingTutors();
			commit("SET_UPCOMING_TUTORS", {
				upcomingTutors: response.data.data,
			});

			return Promise.resolve();
		} catch (e) {
			Promise.reject(e);
		}
	},
	async getSummary({ commit }) {
		try {
			const response = await ServiceDataAPI.getSummary();
			commit("SET_SUMMARY", {
				summary: {
					...response.data.data,
				},
			});

			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async getLiveTutors({ commit }, payload = {}) {
		try {
			const response = await ServiceDataAPI.getLiveTutors(payload);
			isEmpty(payload) ?
				commit("SET_TUTORS", {
					liveTutors: response.data.data,
				}) :
				commit("SET_US_TUTORS", {
					liveUSTutors: response.data.data,
				});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async getTutorManagers({ commit }) {
		try {
			const response = await ServiceDataAPI.getTutorManagers();
			commit("SET_TUTOR_MANAGERS", {
				tutorManagers: response.data.data,
			});

			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async getEssayTutors({ commit }) {
		try {
			const response = await ServiceDataAPI.getEssayTutors();
			commit("SET_ESSAY_TUTORS", {
				essayTutors: response.data.data,
			});

			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async getFilteredTutors({ commit }, payload) {
		try {
			const response = await ServiceDataAPI.getLiveTutors(payload);
			commit("SET_FILTERED_TUTORS", response.data.data);
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async getTopicsOfSubject({}, payload) {
		try {
			const { data } = await TopicsAPI.getTopicsOfSubject(payload.subjectId);
			return Promise.resolve(data);
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject(error);
		}
	},
	async createHandoff({ state, commit }, payload) {
		try {
			const response = await HandoffsAPI.create(payload);
			const incomingTutorId = parseInt(payload.get("incoming_tutor_id"));

			const getTutorIndex = (tutorId) =>
				state.liveTutors.findIndex((tutor) => tutor.user.id === tutorId);

			const outgoingliveTutorIndex = getTutorIndex(state.transferSessionData.outgoingTutorId);
			const incomingLiveTutorIndex = getTutorIndex(incomingTutorId);
			const newOutgoingLiveTutorSessionsArray = [
				...state.liveTutors[outgoingliveTutorIndex].sessions,
			];

			const indexOfSessionToBeRemoved = state.liveTutors[
				outgoingliveTutorIndex
			].sessions.findIndex((session) => {
				return session.id === state.transferSessionData.sessionId;
			});

			const handoffSession = newOutgoingLiveTutorSessionsArray.splice(
				indexOfSessionToBeRemoved,
				1,
			);

			commit("SET_SESSIONS_IN_LIVE_TUTOR", {
				liveTutorIndex: outgoingliveTutorIndex,
				sessions: newOutgoingLiveTutorSessionsArray,
			});

			commit("SET_SESSIONS_IN_LIVE_TUTOR", {
				liveTutorIndex: incomingLiveTutorIndex,
				sessions: [...state.liveTutors[incomingLiveTutorIndex].sessions, handoffSession],
			});

			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject(error);
		}
	},
};

export default {
	namespaced: true,
	state: getDefaultState(),
	getters,
	mutations,
	actions,
};
