<template>
	<BreaksConfirmation
		v-if="canCancelRestBreak"
	>
		<template #header>
			<p v-data-cy="'cancel-scheduled-break-header'">
				Cancel scheduled break?
			</p>
		</template>
		<template #bodyIcon>
			<OrigamiIcon
				v-data-cy="'block-icon'"
				name="break"
				width="32"
				height="32"
				class="tw-text-origami-blue-400"
			/>
		</template>
		<template #bodyText>
			<p
				v-if="formattedRestBreakTimes"
				v-jest="'cancel-scheduled-break-details'"
				v-data-cy="'cancel-scheduled-break-details'"
			>
				Cancel your break for {{ formattedRestBreakTimes.startTime }} to {{ formattedRestBreakTimes.endTime }}?
			</p>
		</template>
		<template #buttons>
			<OrigamiButton
				v-jest="'cancel-scheduled-break-modal-ok-button'"
				v-data-cy="'cancel-scheduled-break-modal-ok-button'"
				variant="destructive"
				class="tw-w-full tw-justify-center"
				:is-loading="$store.state.BreaksManagementWidget.isRequestLoading"
				@click.native="cancelScheduledRestBreak"
			>
				Yes, cancel break
			</OrigamiButton>
		</template>
	</BreaksConfirmation>
</template>

<script>
import { OrigamiButton, OrigamiIcon } from "@origami/vue2";

import { formatIsoDateToHM } from "@/utilities/dateHelpers.js";

import BreaksConfirmation from "../templates/BreaksConfirmation.vue";

export default {
	name: "CancelScheduledRestBreak",
	components: {
		OrigamiButton,
		BreaksConfirmation,
		OrigamiIcon,
	},
	computed: {
		formattedRestBreakTimes() {
			const scheduledBreak = this.$store.getters["TutorSchedule/getUpcomingRestBreak"];
			return scheduledBreak ? {
				startTime: formatIsoDateToHM(scheduledBreak.start_time),
				endTime: formatIsoDateToHM(scheduledBreak.end_time),
			} : null;
		},
		canCancelRestBreak() {
			return this.$store.getters["BreaksManagementWidget/canCancelRestBreak"];
		},
	},
	methods: {
		async cancelScheduledRestBreak() {
			await this.$store.dispatch("BreaksManagementWidget/cancelScheduledRestBreak");
		},
	},
};
</script>
