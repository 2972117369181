<template>
	<div class="searchTutors">
		<div class="searchTutors__title">
			Tutor Search
		</div>
		<div class="searchTutors__searchContainer">
			<OrigamiInputText
				id="txtquery"
				v-model="name"
				name="name"
				value=""
				placeholder="Search by name"
				label=""
				debounce-time="500"
				@input="newSearchName"
			/>
			<div class="searchTutors__filtersContainer">
				Filter:
				<select
					id="subject"
					v-model="subject"
					class="searchTutors__dropdown"
					name="subject"
					placeholder="Subjects"
					@change="newSearchSubject"
				>
					<option value="">
						Subjects
					</option>
					<option
						v-for="s in subjects"
						:key="s.id"
						:value="s.id"
					>
						{{ s.name }}
					</option>
				</select>
				<select
					id="idtopic"
					v-model="topic"
					class="searchTutors__dropdown"
					value=""
					placeholder="Topic"
					@change="newSearchTopic"
				>
					<option value="">
						Topics
					</option>
					<option
						v-for="t in topics"
						:key="t.id"
						:value="t.id"
					>
						{{
							t.name
						}}
					</option>
				</select>
				<BaseButton
					class="searchTutors__resetButton"
					type="SECONDARY"
					@click.prevent.native="resetTutorSearch"
				>
					<template slot="text">
						Reset
					</template>
				</BaseButton>
			</div>
		</div>
		<div
			v-show="isSearching"
			class="loadingSpinnerDiv"
		/>

		<div v-show="!isSearching">
			<div
				id="tutorSearchResults"
				class="tutorSearchResults"
			>
				<h4>{{ countSearchResults }} scheduled tutors found {{ formatSearchParameters }}</h4>
			</div>
			<BaseEmptyState
				v-show="isResultsEmpty"
				header="No Results Found"
				sub-header="Try different parameters"
			>
				<template slot="icon">
					<IconUserSilhouette />
				</template>
			</BaseEmptyState>
			<TutorSearchResult
				v-for="searchResult in searchResults"
				:key="getSearchResultKey(searchResult)"
				:current="searchResult"
			/>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { isSameDay, format } from "date-fns";
import { OrigamiInputText } from "@origami/vue2";
import { uniqueId } from "lodash";

import IconUserSilhouette from "@/components/icons/IconUserSilhouette.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseEmptyState from "@/components/BaseEmptyState.vue";

import TutorSearchResult from "./TutorSearchResult.vue";

export default {
	components: {
		TutorSearchResult,
		IconUserSilhouette,
		OrigamiInputText,
		BaseButton,
		BaseEmptyState,
	},
	data() {
		return {
			count: 0,
			isSearching: false,
			isFirstLoad: true,
			parameters: "",
			searchResults: [],
			subjects: [],
			topics: [],
			searchQuery: {},

			/** Inputs from user **/
			subject: "", //value stored is the id of the subject
			topic: "",
			name: "",
		};
	},
	computed: {
		...mapState(["Tutor"]),
		/**
		 * @returns Number
		 */
		countSearchResults() {
			return this.searchResults.length;
		},
		/**
		 * computes whether search results returned something
		 * @returns Boolean
		 */
		isResultsEmpty() {
			return !this.isSearching && this.countSearchResults == 0 && !this.isFirstLoad;
		},
		formatSearchParameters: function() {
			if (Object.keys(this.searchQuery).length > 0) {
				for (const propName in this.searchQuery) {
					if (
						this.searchQuery[propName] === null ||
						this.searchQuery[propName] === undefined ||
						this.searchQuery[propName] != ""
					) {
						let index, paramater;
						switch (propName) {
							case "subject_id":
								index = this.subjects.findIndex(
									(element) => element.id == this.searchQuery[propName],
								);
								paramater = this.subjects[index].name;
								break;
							case "topic_id":
								index = this.topics.findIndex(
									(element) => element.id == this.searchQuery[propName],
								);
								paramater = this.topics[index].name;
								break;
							default:
								paramater = this.searchQuery[propName];
								break;
						}
						return (
							"for the query by " + propName.replace("_", " ").split(" ")[0] + " = " + paramater
						);
					}
				}
			}
			return "";
		},
	},
	async mounted() {
		await this.$store.dispatch("TutorDashboardTutorList/getSubjects", {
			include: "availableTopics",
		});
		this.subjects = this.$store.getters["TutorDashboardTutorList/filteredSubjects"];
	},
	created() {
		this.newSearch();
	},
	methods: {
		/**
		 * Additional method for subject search which allows to retrieve all the topics
		 * for that particular subject
		 */
		newSearchSubject: function() {
			this.showTopicsOfSubject();
			this.searchQuery = {
				subject_id: this.subject,
			};
			this.name = "";
			this.topic = "";

			if (this.subject) {
				this.newSearch();
			}
		},

		/**
		 * Additional method for name search which resets the topic and
		 * subject to the default value
		 */
		newSearchName: function() {
			this.searchQuery = {
				name: this.name,
			};

			if (0 !== this.name.length) {
				this.newSearch();
				this.topics = [];
				this.subject = "";
			}
		},

		newSearchTopic: function() {
			this.searchQuery = {
				topic_id: this.topic,
			};
			this.name = "";

			if (this.topic) {
				this.newSearch();
			}
		},

		resetTutorSearch() {
			this.subject = "";
			this.topic = "";
			this.searchQuery = {};
			this.searchResults = [];
			this.newSearch();
		},

		/**
		 * Method that returns the list of scheduled tutors provided with the subject, name or topic
		 */
		async newSearch() {
			this.isSearching = true;
			this.isFirstLoad = false;
			try {
				await this.$store.dispatch(
					"TutorDashboardTutorList/getScheduledTutors",
					this.searchQuery,
				);
				const tutors = this.$store.getters["TutorDashboardTutorList/filteredScheduledTutors"];
				this.count = tutors.length;
				const searchResults = [];

				for (let i = 0; i < this.count; i++) {
					const currentTutorShiftDate = tutors[i].schedule.data.start_time ?
						new Date(tutors[i].schedule.data.start_time * 1000) :
						null;
					const lastTutorShiftDate = new Date(
						i === 0 ? 0 : tutors[i - 1].schedule.data.start_time * 1000,
					);

					if (!isSameDay(currentTutorShiftDate, lastTutorShiftDate)) {
						tutors[i].makeNewHeader = true;
						tutors[i].dateHeader = currentTutorShiftDate ? format(currentTutorShiftDate, "MMM do") : "";
					} else {
						tutors[i].makeNewHeader = false;
					}
					searchResults.push(tutors[i]);
				}

				this.searchResults = searchResults;
			} catch (error) {
				alert("Error: There was an error retrieving the tutor search results.");
				Sentry.captureException(error);
				throw new Error(error);
			} finally {
				this.isSearching = false;
			}
		},

		showTopicsOfSubject: function() {
			const index = this.subjects.findIndex((element) => element.id === this.subject);
			if (-1 === index) {
				this.topics = [];
			} else {
				const searchSubject = this.subjects[index];
				this.topics = searchSubject.availableTopics.data;
			}
		},
		getSearchResultKey(result) {
			return uniqueId(result.profile);
		},
	},
};
</script>
<style scoped>
.tutorSearchResults {
	background-color: var(--c-white);
}
.tutorSearchResults h4 {
	border: 0px;
	margin: 0px;
	padding: 30px;
	color: var(--c-green);
	font-weight: 600;
	font-size: 16px;
}
.loadingSpinnerDiv {
	width: 100%;
	min-height: 120px;
	background-size: 40px 40px;
}

.searchTutors {
	margin: 0px;
	padding-bottom: 20px;
	padding-top: 5px;
}

.searchTutors__title {
	font-size: 22px;
	color: #4656a1;
	font-weight: 600;
	line-height: 1.5em;
	margin: 15px 0;
}

.searchTutors__searchContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.searchTutors__filtersContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #4656a1;
}

.searchTutors__dropdown {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: var(--c-white) url(/images/default-caret.png) no-repeat right 12px center !important;
	margin-left: 20px;
	padding-left: 12px;
	padding-right: 35px;
	line-height: 36px;
	min-width: 50px;
	height: 46px;
	color: var(--c-black);
	border: 2px solid var(--c-light-gray);
}

.searchTutors__resetButton {
	margin-left: 17px;
}
</style>
