import { SchoolsAPI, UsersAPI } from "@/modules/Customers/index.js";
import GradesAPI from "@/services/api/Grades.js";
import AccountsAPI from "@/services/api/Account.js";
import StudentsAPI from "@/services/api/Students.js";
import { refreshAccessTokenIfNecessary } from "@/services/root.js";

import StudentNotificationsOptionsAPI from "../services/StudentNotificationsOptions.js";
import StudentAppSoundsOptionsAPI from "../services/StudentAppSoundsOptions.js";

export const getDefaultState = () => ({
	userId: "",
	firstName: "",
	lastName: "",
	username: "",
	email: "",
	currentGrade: "",
	syncedWith: null,
	isVerificationMessage: false,
	isEmailNotificationsEnabled: false,
	isBrowserNotificationsEnabled: false,
	isAppSoundsEnabled: false,
	isEventNotificationsEnabled: false,
	isMobileNotificationsEnabled: false,
	isSharedSessionsEnabled: false,
	userRoles: [],
	isLmsUser: false,
	grades: [],
	activeTab: "account",
	errorText: "",
	studentNotificationsOptionsStatus: "",
	isSubmittingChange: false,
	isEditPasswordSuccessful: false,
	isEditGradeSuccessful: false,
	currentEditField: "",
});

const state = getDefaultState();

export const getters = {
	isHasStudentRole(state) {
		return state.userRoles.includes("student");
	},
	fullName(state) {
		return `${state.firstName} ${state.lastName}`;
	},
	ltiTitle(state) {
		return state.syncedWith;
	},
};

export const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_USER_ID(state, payload) {
		state.userId = payload.userId;
	},
	SET_GRADES_ARRAY(state, payload) {
		state.grades = payload.grades;
	},
	SET_CURRENT_GRADE(state, payload) {
		state.currentGrade = payload.currentGrade;
	},
	SET_FIRST_NAME(state, payload) {
		state.firstName = payload.firstName;
	},
	SET_LAST_NAME(state, payload) {
		state.lastName = payload.lastName;
	},
	SET_USERNAME(state, payload) {
		state.username = payload.username;
	},
	SET_SYNCED_WITH(state, payload) {
		state.syncedWith = payload.syncedWith;
	},
	SET_IS_LMS_USER(state, payload) {
		state.isLmsUser = payload.isLmsUser;
	},
	SET_IS_VERIFICATION_MESSAGE(state, payload) {
		state.isVerificationMessage = payload.isVerificationMessage;
	},
	SET_CURRENT_EDIT_FIELD(state, payload) {
		state.currentEditField = payload.currentEditField;
	},
	SET_USER_ROLES(state, payload) {
		state.userRoles = payload.userRoles;
	},
	SET_EMAIL(state, payload) {
		state.email = payload.email;
	},
	SET_ERROR_TEXT(state, payload) {
		state.errorText = payload.errorText;
	},
	SET_IS_SUBMITTING_CHANGE(state, payload) {
		state.isSubmittingChange = payload.isSubmittingChange;
	},
	SET_IS_EDIT_GRADE_SUCCESSFUL(state, payload) {
		state.isEditGradeSuccessful = payload.isEditGradeSuccessful;
	},
	SET_IS_EDIT_PASSWORD_SUCCESSFUL(state, payload) {
		state.isEditPasswordSuccessful = payload.isEditPasswordSuccessful;
	},
	SET_ACTIVE_TAB(state, payload) {
		state.activeTab = payload.activeTab;
	},
	SET_IS_EMAIL_NOTIFICATIONS_ENABLED(state, payload) {
		state.isEmailNotificationsEnabled = payload.isEmailNotificationsEnabled;
	},
	SET_IS_BROWSER_NOTIFICATIONS_ENABLED(state, payload) {
		state.isBrowserNotificationsEnabled = payload.isBrowserNotificationsEnabled;
	},
	SET_IS_EVENT_NOTIFICATIONS_ENABLED(state, payload) {
		state.isEventNotificationsEnabled = payload.isEventNotificationsEnabled;
	},
	SET_IS_MOBILE_NOTIFICATIONS_ENABLED(state, payload) {
		state.isMobileNotificationsEnabled = payload.isMobileNotificationsEnabled;
	},
	SET_IS_APP_SOUNDS_ENABLED(state, payload) {
		state.isAppSoundsEnabled = payload.isAppSoundsEnabled;
	},
	SET_STUDENT_NOTIFICATIONS_OPTIONS_STATUS(state, payload) {
		state.studentNotificationsOptionsStatus = payload.studentNotificationsOptionsStatus;
	},
	SET_IS_SHARED_SESSIONS_ENABLED(state, payload) {
		state.isSharedSessionsEnabled = payload.isSharedSessionsEnabled;
	},
};

export const actions = {
	async getGrades({ commit }) {
		try {
			const response = await GradesAPI.list();
			commit("SET_GRADES_ARRAY", {
				grades: response.data.data,
			});
			return Promise.resolve(response);
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async getUser({ commit, getters }, userId) {
		const params = getters.isHasStudentRole ? { include: "student.grade" } : {};

		try {
			const response = await UsersAPI.show(userId, params);
			const user = response.data.data;
			if (getters.isHasStudentRole) {
				const gradeData = user.student.data.grade.data;
				const isEmailNotificationsEnabled = user.student.data.email_notifications;
				const isEventNotificationsEnabled = user.student.data.event_email_notifications;
				const isMobileNotificationsEnabled = user.student.data.mobile_push_notifications;
				const isAppSoundsEnabled = user.student.data.app_sounds;
				commit("SET_CURRENT_GRADE", {
					currentGrade: gradeData.hasOwnProperty("id") ? gradeData : "",
				});
				commit("SET_IS_EMAIL_NOTIFICATIONS_ENABLED", {
					isEmailNotificationsEnabled,
				});
				commit("SET_IS_EVENT_NOTIFICATIONS_ENABLED", {
					isEventNotificationsEnabled,
				});
				commit("SET_IS_MOBILE_NOTIFICATIONS_ENABLED", {
					isMobileNotificationsEnabled,
				});
				commit("SET_IS_APP_SOUNDS_ENABLED", {
					isAppSoundsEnabled,
				});

			}
			commit("SET_SYNCED_WITH", {
				syncedWith: user.synced_with,
			});
			commit("SET_IS_VERIFICATION_MESSAGE", {
				isVerificationMessage: user.pending_email !== null,
			});
		} catch (err) {
			Sentry.captureException(err);
		}
	},
	async saveGrade({ state, commit }, gradeId) {
		commit("SET_IS_SUBMITTING_CHANGE", {
			isSubmittingChange: true,
		});
		commit("SET_ERROR_TEXT", {
			errorText: "",
		});

		await StudentsAPI.update(state.userId, { grade_id: gradeId });
		await refreshAccessTokenIfNecessary(true);
		window.location.reload();
	},
	async savePassword({ dispatch }, data) {
		try {
			await dispatch("savePasswordCommon", {
				apiRequest: AccountsAPI.postPassword,
				data,
			});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async savePasswordLMS({ dispatch }, data) {
		try {
			await dispatch("savePasswordCommon", {
				apiRequest: AccountsAPI.postPasswordLMS,
				data,
			});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async savePasswordCommon({ commit }, { apiRequest, data }) {
		commit("SET_IS_SUBMITTING_CHANGE", {
			isSubmittingChange: true,
		});
		try {
			await apiRequest(data);
			commit("SET_ERROR_TEXT", {
				errorText: "",
			});
			commit("SET_IS_EDIT_PASSWORD_SUCCESSFUL", {
				isEditPasswordSuccessful: true,
			});
			commit("SET_CURRENT_EDIT_FIELD", {
				currentEditField: "",
			});
			return Promise.resolve();
		} catch (err) {
			const errorDescription = err.response.data.error_description;
			const error = errorDescription[Object.keys(errorDescription)[0]][0];

			let newModifier = "";
			if (data.hasOwnProperty("new_password")) {
				newModifier = "new ";
			}

			let errorResponseText = "";
			switch (error) {
				case `The ${newModifier}password must be at least 6 characters.`:
					errorResponseText = "password_too_short";
					break;
				case `The ${newModifier}password format is invalid.`:
					errorResponseText = "password_whitespace";
					break;
				case `The ${newModifier}password confirmation does not match.`:
					errorResponseText = "passwords_dont_match";
					break;
				default:
					errorResponseText = "something_wrong_password";
					break;
			}

			if (errorDescription === "The password provided was incorrect.") {
				errorResponseText = "password_incorrect";
			}
			commit("SET_ERROR_TEXT", {
				errorText: errorResponseText,
			});
			return Promise.reject(err);
		} finally {
			commit("SET_IS_SUBMITTING_CHANGE", {
				isSubmittingChange: false,
			});
		}
	},
	async toggleBrowserNotifications({ dispatch }, subObject) {
		try {
			const cleanData = subObject === null ? "" : JSON.stringify(subObject);
			await dispatch("updateStudentNotificationsOptions", {
				web_push_subscription_object: cleanData,
			});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async toggleEmailNotifications({ commit, state, dispatch }) {
		try {
			await commit("SET_IS_EMAIL_NOTIFICATIONS_ENABLED", {
				isEmailNotificationsEnabled: !state.isEmailNotificationsEnabled,
			});
			await dispatch("updateStudentNotificationsOptions", {
				email_notifications: state.isEmailNotificationsEnabled,
			});
			commit("SET_STUDENT_NOTIFICATIONS_OPTIONS_STATUS", {
				studentNotificationsOptionsStatus: "success",
			});
		} catch (e) {
			//revert value of checkbox
			commit("SET_IS_EMAIL_NOTIFICATIONS_ENABLED", {
				isEmailNotificationsEnabled: !state.isEmailNotificationsEnabled,
			});
			commit("SET_STUDENT_NOTIFICATIONS_OPTIONS_STATUS", {
				studentNotificationsOptionsStatus: "error",
			});
		}
	},
	async toggleEventNotifications({ commit, state, dispatch }) {
		try {
			await commit("SET_IS_EVENT_NOTIFICATIONS_ENABLED", {
				isEventNotificationsEnabled: !state.isEventNotificationsEnabled,
			});
			await dispatch("updateStudentNotificationsOptions", {
				event_email_notifications: state.isEventNotificationsEnabled,
			});
			commit("SET_STUDENT_NOTIFICATIONS_OPTIONS_STATUS", {
				studentNotificationsOptionsStatus: "success",
			});
		} catch (e) {
			commit("SET_IS_EVENT_NOTIFICATIONS_ENABLED", {
				isEventNotificationsEnabled: !state.isEventNotificationsEnabled,
			});
			commit("SET_STUDENT_NOTIFICATIONS_OPTIONS_STATUS", {
				studentNotificationsOptionsStatus: "error",
			});
		}
	},
	async toggleMobileNotifications({ commit, state, dispatch }) {
		try {
			await commit("SET_IS_MOBILE_NOTIFICATIONS_ENABLED", {
				isMobileNotificationsEnabled: !state.isMobileNotificationsEnabled,
			});
			await dispatch("updateStudentNotificationsOptions", {
				mobile_push_notifications: state.isMobileNotificationsEnabled,
			});
			commit("SET_STUDENT_NOTIFICATIONS_OPTIONS_STATUS", {
				studentNotificationsOptionsStatus: "success",
			});
		} catch (e) {
			commit("SET_IS_MOBILE_NOTIFICATIONS_ENABLED", {
				isMobileNotificationsEnabled: !state.isMobileNotificationsEnabled,
			});
			commit("SET_STUDENT_NOTIFICATIONS_OPTIONS_STATUS", {
				studentNotificationsOptionsStatus: "error",
			});
		}
	},
	async toggleAppSoundsNotifications({ state, commit }) {
		return new Promise(async(resolve, reject) => {
			try {
				await StudentAppSoundsOptionsAPI.update({ app_sounds: !state.isAppSoundsEnabled });
				await commit("SET_IS_APP_SOUNDS_ENABLED", { isAppSoundsEnabled: !state.isAppSoundsEnabled });
				resolve();
			} catch (e) {
				Sentry.captureException(e);
				reject();
			}
		});
	},
	async updateStudentNotificationsOptions(_, payload) {
		try {
			await StudentNotificationsOptionsAPI.update(payload);
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	async setFeaturesForSchool({ rootState }, payload) {
		try {
			const response = await SchoolsAPI.update(rootState.currentUser.school.id, payload);
			return Promise.resolve(response);
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async getFeaturesForSchool({ commit, rootState }) {
		try {
			const response = await SchoolsAPI.show(rootState.currentUser.school.id);
			commit("SET_IS_SHARED_SESSIONS_ENABLED", {
				isSharedSessionsEnabled: response.data.data.features_shared_sessions,
			});
			return Promise.resolve(response);
		} catch (e) {
			return Promise.reject(e);
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
