export const HIGH = {
	title: "At Risk",
	icon: "critical",
	color: "tw-text-origami-red-400",
	tooltipText: `This section indicates any items requiring immediate attention. 
		Items in this section have an average tutor load of 4.0 and above.`,
};

export const MODERATE = 	{
	title: "Moderate",
	icon: "warning",
	color: "tw-text-origami-yellow-300",
	tooltipText: `This section indicates any items requires monitoring. 
		Items in this section have an average tutor load ranging from 3.1 to 3.9.`,
};

export const LOW = {
	title: "Stable",
	icon: "check-filled",
	color: "tw-text-origami-green-400",
	tooltipText: `This section indicates any items requiring immediate attention. 
		Items in this section have an average tutor ranging from 0 to 3.0.`,
};

export const REQUIREMENTS_FILTERS = {
	name: "Requirements",
	filterChoices: [
		{
			name: "US",
			id: "US",
		},
		{
			name: "Fingerprinted",
			id: "is_us_ca_fingerprinted",
		},
		{
			name: "VT",
			id: "can_receive_async_audio_sessions",
		},
	],
};

export const AVERAGE_TUTOR_LOAD_OVER_TIME_TOOLTIP = `This graph shows the average tutor load over the last 30 mins. 
	Be aware that tutors with multiple subject expertise can be included in more than one load graph.`;

export const CURRENT_TUTOR_SESSION_BREAKDOWN_TOOLTIP = `Be aware that tutors with multiple subject expertise can be 
	included in more than one session breakdown table.`;

export const TARGET_AVERAGE_TUTOR_LOAD = 3;
