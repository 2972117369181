var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "p",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "error-message-container",
          expression: "'error-message-container'",
        },
      ],
      staticClass: "tw-px-6 tw-py-0 tw-h-16 tw-text-origami-red-400",
    },
    [_vm._v("\n\t" + _vm._s(_vm.errorMessage) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }