var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "shiftButtons__container tw-flex tw-items-center tw-justify-center",
    },
    [
      _c("PunchClockShiftCalendar", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "shift-button-left",
            expression: "'shift-button-left'",
          },
        ],
        attrs: {
          "icon-type": "CALENDAR",
          "button-type": _vm.buttonType,
          "is-left-button": _vm.hasShift,
        },
      }),
      _vm._v(" "),
      _vm.hasShift
        ? _c("PunchButton", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "punch-button",
                expression: "'punch-button'",
              },
            ],
            attrs: { "current-date": _vm.currentDate },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }