var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "BreaksNotification",
        {
          staticClass: "tw-mb-4",
          attrs: { "notification-type": _vm.notificationType },
        },
        [
          _c("BioBreakDetails"),
          _vm._v(" "),
          _c(
            "p",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "bio-break-end-prompt-text",
                  expression: "'bio-break-end-prompt-text'",
                },
                {
                  name: "data-cy",
                  rawName: "v-data-cy",
                  value: "bio-break-end-prompt-text",
                  expression: "'bio-break-end-prompt-text'",
                },
              ],
              staticClass: "tw-text-sm",
            },
            [_vm._v("\n\t\t\t" + _vm._s(_vm.promptText) + "\n\t\t")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "OrigamiButton",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "end-bio-break-button",
              expression: "'end-bio-break-button'",
            },
            {
              name: "data-cy",
              rawName: "v-data-cy",
              value: "end-bio-break-button",
              expression: "'end-bio-break-button'",
            },
          ],
          staticClass: "tw-m-auto tw-mb-2 tw-w-full tw-justify-center",
          attrs: { variant: "primary" },
          nativeOn: {
            click: function ($event) {
              return _vm.endBioBreak.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n\t\tI'm back! End break\n\t")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }