<template>
	<BModal
		id="tutorDashboardSubmitAvailabilitiesModal"
		content-class="bootstrapFourModalContent"
		body-class="bootstrapFourModalBody"
		footer-class="bootstrapFourModalFooter"
		:hide-header="true"
		:no-close-on-backdrop="true"
		:no-fade="true"
		:centered="true"
	>
		<button
			v-jest="'close-button'"
			class="submitAvailabilitiesModal__closeButton"
			@click="hideModal()"
		>
			&times;
		</button>
		<div class="submitAvailabilitiesModal__header">
			<div class="submitAvailabilitiesModal__icon">
				<IconCalendar class="submitAvailabilitiesModal__svg" />
			</div>

			<div class="submitAvailabilitiesModal__title">
				Confirm Availability Submission
			</div>
		</div>
		<div class="submitAvailabilitiesModal__content">
			<div
				key="create-availabilities-modal-text"
				v-jest="'create-availabilities-modal-text'"
			>
				<p class="submitAvailabilitiesModal__text">
					You will be scheduled up to <strong v-jest="'max-hours'">{{ maxHours }} hours</strong> of the <strong v-jest="'total-availabilities'">{{ totalHoursForWeek }} hours</strong> you selected.
				</p>
				<p class="submitAvailabilitiesModal__text">
					You can edit your availability until the submission deadline.
				</p>
			</div>
			<div
				v-show="isSubmissionErrorShowing"
				v-jest="'submission-error-message'"
				class="submitAvailabilitiesModal__errorText"
			>
				There was a problem submitting your availability. If this problem persists, please contact
				support.
			</div>
		</div>
		<template slot="modal-footer">
			<BaseButton
				v-jest="'cancel-button'"
				class="submitAvailabilitiesModal__button"
				type="CANCEL"
				@click.native="hideModal()"
			>
				<template slot="text">
					Cancel
				</template>
			</BaseButton>
			<BaseButton
				v-jest="'submit-button'"
				class="submitAvailabilitiesModal__button"
				type="SUBMIT"
				:is-disabled="isSubmitting"
				@click.native="submitAvailabilities()"
			>
				<template slot="text">
					Submit
				</template>
			</BaseButton>
		</template>
	</BModal>
</template>

<script>
// Components
import { mapState } from "vuex";

import BaseButton from "../../../components/BaseButton.vue";
import IconCalendar from "../../../components/icons/IconClock3.vue";

// Libs
export default {
	components: {
		BaseButton,
		IconCalendar,
	},
	props: {
		// eslint-disable-next-line vue/prop-name-casing
		maxHours: {
			validator(value) {
				return typeof parseInt(value, 10) === "number" || value === null;
			},
			required: true,
		},
		preferredHours: {
			validator(value) {
				return typeof parseInt(value, 10) === "number" || value === null;
			},
			default: 0,
			required: false,
		},
	},
	data() {
		return {
			isSubmitting: false,
			isSubmissionErrorShowing: false,
			featureFlagPreferredHoursFieldEnabled: null,
		};
	},
	computed: {
		...mapState(["currentUser", "Availabilities"]),
		totalHoursForWeek() {
			return this.$store.getters["Availabilities/totalHoursToSubmitForWeek"];
		},
	},
	async created() {
		this.featureFlagPreferredHoursFieldEnabled = await this.$getFlag("SER-550-preferred-hours-field");
	},
	methods: {
		/**
		 * Hides modal and resets all booleans to default value.
		 */
		hideModal() {
			this.$bvModal.hide("tutorDashboardSubmitAvailabilitiesModal");
			this.isSubmissionErrorShowing = false;
			this.isSubmitting = false;
		},
		/**
		 * Handles logic for submitting the tutor availabilities.
		 */
		async submitAvailabilities() {
			try {
				this.isSubmitting = true;
				if (this.featureFlagPreferredHoursFieldEnabled) {
					await Promise.all([
						this.$store.dispatch("Availabilities/submitBatchAvailabilities"),
						this.$store.dispatch("Availabilities/updateTutorMaxAndPreferredHours", {
							currentUserId: this.currentUser.id,
							params: {
								max_weekly_hours: this.maxHours,
								preferred_weekly_hours: this.preferredHours,
							},
						}),
					]);
				} else {
					await Promise.all([
						this.$store.dispatch("Availabilities/submitBatchAvailabilities"),
						this.$store.dispatch("Availabilities/updateTutorMaxHours", {
							currentUserId: this.currentUser.id,
							params: { max_weekly_hours: this.maxHours },
						}),
					]);
				}
				this.hideModal();
			} catch (error) {
				this.isSubmissionErrorShowing = true;
			} finally {
				this.isSubmitting = false;
			}
		},
	},
};
</script>

<style scoped>
.submitAvailabilitiesModal__closeButton {
	background-color: transparent;
	border: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: -38px -35px;
	float: right;
	font-size: 2rem;
	line-height: 1;
	color: var(--c-black);
	text-shadow: 0 1px 0 #fff;
}

.submitAvailabilitiesModal__header {
	justify-content: flex-start;
	display: flex;
	align-items: center;
	border: none;
	padding: 0;
}

.submitAvailabilitiesModal__icon {
	display: flex;
	justify-content: center;
	width: 44px;
	min-width: 44px;
	height: 44px;
	border-radius: 44px;
	background-color: var(--c-blue);
}

.submitAvailabilitiesModal__svg {
	align-self: center;
}

.submitAvailabilitiesModal__title {
	font-size: 20px;
	font-weight: 600;
	color: var(--c-blue);
	margin: 0 16px;
}

.submitAvailabilitiesModal__content {
	font-size: 14px;
	color: var(--c-black);
	margin-top: 26px;
}

.submitAvailabilitiesModal__text {
	font-size: 16px;
	font-weight: normal;
	color: var(--c-black);
	margin: 6px 0;
}

.submitAvailabilitiesModal__errorText {
	margin-top: 16px;
	padding: 8px 16px;
	border-radius: 5px;
	border-color: #f5c6cb;
	background-color: #f8d7da;
	color: #721c24;
	line-height: normal;
}

.submitAvailabilitiesModal__button {
	margin-left: 10px;
}
:deep(.bootstrapFourModalContent) {
	border-radius: 10px;
	background-color: var(--c-white);
}
:deep(.bootstrapFourModalBody) {
	padding: 48px;
	position: relative;
}
:deep(.bootstrapFourModalFooter) {
	padding: 20px 48px;
	background-color: var(--c-light-gray);
}
</style>
