<template>
	<BreakButton
		v-jest="'meal-break-button'"
		v-data-cy="'meal-break-button'"
		aria-label="Go on meal break"
		icon-name="meal-break"
		:is-active="isOnMealBreakWorkflow"
		:disabled="isDisabled"
		:show-notification="hasPendingMealBreakRequest || hasMealBreak"
		@click.native="requestMealBreak"
	/>
</template>

<script>
import { isNil } from "lodash";
import { mapState } from "vuex";

import BreakButton from "../elements/BreakButton.vue";

export default {
	components: {
		BreakButton,
	},
	computed: {
		...mapState(["TutorSchedule"]),
		isDisabled() {
			return this.isOnBioBreakWorkflow || this.isRequestingRestBreak || this.hasActiveRestBreak ||
				(!this.hasMealBreak && (this.isInMealBreakTransitionPeriod || !this.isEligibleForMealBreak));
		},
		isOnMealBreakWorkflow() {
			return this.$store.getters["BreaksManagementWidget/isOnMealBreakWorkflow"];
		},
		hasActiveRestBreak() {
			return this.$store.getters["BreaksManagementWidget/hasActiveRestBreak"];
		},
		isRequestingRestBreak() {
			return this.$store.getters["BreaksManagementWidget/isRequestingRestBreak"];
		},
		isOnBioBreakWorkflow() {
			return this.$store.getters["BreaksManagementWidget/isOnBioBreakWorkflow"];
		},
		isInMealBreakTransitionPeriod() {
			return this.$store.getters["BreaksManagementWidget/isInMealBreakTransitionPeriod"];
		},
		isEligibleForMealBreak() {
			return !isNil(this.TutorSchedule.shiftState?.eligible_actions?.request_meal_break);
		},
		hasMealBreak() {
			return this.$store.getters["TutorSchedule/hasMealBreak"];
		},
		hasPendingMealBreakRequest() {
			return this.$store.getters["TutorSchedule/hasPendingMealBreakRequest"];
		},
	},
	methods: {
		requestMealBreak() {
			if (!this.isOnMealBreakWorkflow) {
				this.$store.dispatch("BreaksManagementWidget/requestMealBreak");
			}
		},
	},
};
</script>
