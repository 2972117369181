import { getFlag } from "@paper-co/web-toolkit";

import { getInstance as auth0Instance } from "@/plugins/Auth0.js";

export default async function(to, from, next) {
	let featureFlagAuth0SSOLoginIsEnabled;
	try {
		featureFlagAuth0SSOLoginIsEnabled = await getFlag("PLAT-1680-paper-identity-authentication-to-auth0");
	} catch (e) {
		Sentry.captureException(e);
	}

	if (featureFlagAuth0SSOLoginIsEnabled) {
		try {
			await auth0Instance().loginWithRedirect();
		} catch (e) {
			Sentry.captureException(e);
			this.commit("Login/SET_AUTH0_LOGIN_ERROR", { auth0LoginError: true });
			next("/login/student");
		}
	} else {
		next();
	}
}
