import TransformAPI from "@/services/transformers/showTransformer.js";

import DistrictsAPI from "../../services/districts.js";
import SchoolsAPI from "../../services/schools.js";
import UsersAPI from "../../services/users.js";

const getDefaultState = () => ({
	isLoading: true,
	schools: [],
	orderBy: "",
	schoolHeaderTitles: [
		{
			value: "id",
			header: "ID",
			orderable: true,
			state: null,
		},
		{
			value: "name",
			header: "School",
			orderable: true,
			state: null,
		},
		{
			value: "students",
			header: "Licensed Students",
			orderable: true,
			state: null,
		},
		{
			value: "license",
			header: "License Expiry",
			orderable: true,
			state: null,
		},
		{
			value: "loginType",
			header: "Login Type",
			orderable: false,
			state: null,
		},
		{
			value: "csManager",
			header: "CS Manager",
			orderable: true,
			state: null,
		},
	],
	districtManagerHeaderTitles: [
		{
			value: "id",
			header: "ID",
			orderable: true,
			state: null,
		},
		{
			value: "fullName",
			header: "Full Name",
			orderable: true,
			state: null,
		},
		{
			value: "username",
			header: "Username",
			orderable: true,
			state: null,
		},
		{
			value: "email",
			header: "Email",
			orderable: true,
			state: null,
		},
		{
			value: "license",
			header: "License Expiry",
			orderable: true,
			state: null,
		},
		{
			value: "",
			header: " ",
			orderable: false,
			state: null,
		},
	],
	district: {},
	editedDistrict: {},
	dmTableData: [],
	schoolTableData: [],
	schoolCount: 0,
	dmCount: 0,
	adminCount: 0,
	teacherCount: 0,
	studentCount: 0,
	error: "",
	modalHeader: "",
	modalText: "",
	navbarMainTab: "Customers",
});

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations: {
		RESET_STATE(state) {
			Object.assign(state, getDefaultState());
		},
		SET_SCHOOLS(state, payload) {
			state.schools = payload.schools;
		},
		SET_DISTRICT(state, payload) {
			state.district = payload.district;
		},
		SET_IS_LOADING(state, payload) {
			state.isLoading = payload.isLoading;
		},
		SET_DM_TABLE(state, payload) {
			state.dmTableData = payload.districtManagers;
		},
		SET_SCHOOL_TABLE(state, payload) {
			state.schoolTableData = payload.districtSchools;
		},
		SET_ERROR(state, payload) {
			state.error = payload;
		},
		SET_ORDER_BY(state, payload) {
			state.orderBy = payload.orderBy;
		},
		SET_MODAL(state, payload) {
			state.modalHeader = payload.modalHeader;
			state.modalText = payload.modalText;
		},
		SET_STATS(state, payload) {
			state.schoolCount = payload.schools;
			state.dmCount = payload.districtManagers;
			state.adminCount = payload.administrators;
			state.teacherCount = payload.teachers;
			state.studentCount = payload.licensedStudents;
		},
		UPDATE_SCHOOL_HEADERS(state, payload) {
			state.schoolHeaderTitles = payload.schoolHeaderTitles;
		},
		UPDATE_DM_HEADERS(state, payload) {
			state.districtManagerHeaderTitles = payload.districtManagerHeaderTitles;
		},
		SET_TABLE_HEADERS() {},
	},
	getters: {
		filteredSchools(state) {
			return state.schools.filter(
				(school) =>
					school.district.data.length === 0 || school.district.data.id === state.district.id,
			);
		},
		dmCount(state) {
			return state.dmCount.toString();
		},
		adminCount(state) {
			return state.adminCount.toString();
		},
		schoolCount(state) {
			return state.schoolCount.toString();
		},
		teacherCount(state) {
			return state.teacherCount.toString();
		},
		studentCount(state) {
			return state.studentCount.toString();
		},
		ltiTitle(state) {
			return state.district.synced_with;
		},
	},
	actions: {
		async updateData(context, payload) {
			try {
				await DistrictsAPI.update(context.state.district.id, payload);
				await context.dispatch("initData", {
					districtId: context.state.district.id,
				});
				return Promise.resolve();
			} catch (error) {
				context.commit("SET_ERROR", error);
				context.commit("SET_MODAL", {
					modalHeader: "An error has occurred with status " + error.response.status,
					modalText: "Error while updating the district",
				});
				return Promise.reject(error);
			}
		},

		async setSchoolOrderBy({ commit, state }, payload) {
			const params = {
				include: "manager,loginType",
				district: [state.district.id],
				orderBy: payload,
			};
			try {
				const districtSchools = await SchoolsAPI.filter(params);
				const transformedSchools = [];
				districtSchools.data.data.forEach(function(school) {
					transformedSchools.push(TransformAPI.school(school));
				});
				commit("SET_SCHOOL_TABLE", {
					districtSchools: transformedSchools,
				});
				return Promise.resolve();
			} catch (error) {
				commit("SET_ERROR", error);
				commit("SET_MODAL", {
					modalHeader: "An error has occurred with status " + error.response.status,
					modalText: error.response.data.error_description,
				});
				return Promise.reject(error);
			}
		},

		async setDMOrderBy({ commit, state }, payload) {
			const params = {
				role: [8],
				district: [state.district.id],
				orderBy: payload,
			};

			const districtManagers = await UsersAPI.filter(params);
			const transformedDMs = [];

			districtManagers.data.data.forEach(function(user) {
				transformedDMs.push(TransformAPI.user(user));
			});

			commit("SET_DM_TABLE", {
				districtManagers: transformedDMs,
			});
		},

		async deleteDistrict() {
			try {
				// soft delete implementation goes here
			} catch (e) {}
		},
		async setSchools({ commit }) {
			const params = {
				include: "district",
			};
			try {
				const schools = await SchoolsAPI.list(params);
				const data = [];
				schools.data.data.forEach(function(school) {
					data.push(school);
				});
				commit("SET_SCHOOLS", {
					schools: data,
				});
				return Promise.resolve();
			} catch (error) {
				commit("SET_ERROR", error);
				commit("SET_MODAL", {
					modalHeader: "An error has occurred with status " + error.response.status,
					modalText: error.response.data.error_description,
				});
				return Promise.reject(error);
			}
		},
		async setDistrictManagers(context, payload) {
			const transformedDMs = [];
			const params = {
				role: [8],
				district: [payload.districtId],
			};
			try {
				const districtManagers = await UsersAPI.filter(params);

				districtManagers.data.data.forEach(function(user) {
					transformedDMs.push(TransformAPI.user(user));
				});

				context.commit("SET_DM_TABLE", {
					districtManagers: transformedDMs,
				});
				return Promise.resolve();
			} catch (error) {
				context.commit("SET_ERROR", error);
				context.commit("SET_MODAL", {
					modalHeader: "An error has occurred with status " + error.response.status,
					modalText: error.error_description,
				});
				return Promise.reject(error);
			}
		},
		async setDistrict(context, payload) {
			const params = {
				include: "managers,clever,classlink",
			};
			try {
				const district = await DistrictsAPI.show(payload.districtId, params);
				context.commit("SET_DISTRICT", {
					district: district.data.data,
				});
				return Promise.resolve();
			} catch (error) {
				context.commit("SET_ERROR", error);
				context.commit("SET_MODAL", {
					modalHeader: "An error has occurred",
					modalText: error.toString(),
				});
				return Promise.reject(error);
			} finally {
				context.commit("SET_IS_LOADING", {
					isLoading: false,
				});
			}
		},
		async setDistrictSchools(context, payload) {
			const params = {
				district: [payload.districtId],
				include: "manager,loginType",
			};
			try {
				const districtSchools = await SchoolsAPI.filter(params);
				const transformedSchools = [];
				districtSchools.data.data.forEach(function(school) {
					transformedSchools.push(TransformAPI.school(school));
				});
				context.commit("SET_SCHOOL_TABLE", {
					districtSchools: transformedSchools,
				});
				return Promise.resolve();
			} catch (error) {
				context.commit("SET_ERROR", error);
				context.commit("SET_MODAL", {
					modalHeader: "An error has occurred with status " + error.response.status,
					modalText: error.response.data.error_description + " while retrieving district's schools",
				});
				return Promise.reject(error);
			}
		},

		async setStats({ commit }, payload) {
			const stats = await DistrictsAPI.aggregate(payload.districtId);
			commit("SET_STATS", {
				schools: stats.data.schools[0].total,
				districtManagers: stats.data.districtManagers[0].total,
				administrators: stats.data.administrators[0].total,
				teachers: stats.data.teachers[0].total,
				licensedStudents: stats.data.licensedStudents[0].total,
			});
		},
		async initData(context, payload) {
			try {
				// get current district
				await context.dispatch({ type: "setDistrict", districtId: payload.districtId });
				// get district managers
				await context.dispatch({ type: "setDistrictManagers", districtId: payload.districtId });
				// get district schools
				await context.dispatch({ type: "setDistrictSchools", districtId: payload.districtId });
				// get stats
				await context.dispatch({ type: "setStats", districtId: payload.districtId });
				// get all schools
				context.dispatch("setSchools");
				return Promise.resolve();
			} catch (error) {
				return Promise.reject(error);
			}
		},
	},
};
