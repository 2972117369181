import jsCookie from "js-cookie";
import Vue from "vue";

import { UsersAPI } from "@/modules/Customers/index.js";
import i18n from "@/i18n/index.js";
import { refreshAccessTokenIfNecessary } from "@/services/root.js";

import Languages from "../services/Languages.js";

const PREFERRED_LANGUAGE_COOKIE_NAME = "preferredLanguage";
function loadCookie() {
	return jsCookie.get(PREFERRED_LANGUAGE_COOKIE_NAME);
}
function saveCookie(preferredLanguage) {
	// NB: sameSite/secure are required for this cookie to be accessible within embedded context
	// (e.g. chrome extension iframe)
	jsCookie.set(PREFERRED_LANGUAGE_COOKIE_NAME, preferredLanguage, { sameSite: "none", secure: true });
}

export default {
	namespaced: true,
	state: {
		languages: [],
		preferredLanguage: null,
		publicLanguages: [
			{ abbreviation: "en", language: "English", id: 1 },
			{ abbreviation: "fr", language: "Français", id: 2 },
			{ abbreviation: "es", language: "Español", id: 4 },
			{ abbreviation: "zh", language: "中文", id: 3 },
		],
	},
	getters: {
		getTutorableLanguages(state) {
			return state.filter((language) => language.tutorable === 1);
		},
		essayLanguages(state) {
			return state.publicLanguages.filter((lang) => lang.id !== 3);
		},
	},
	mutations: {
		SET_LANGUAGES(state, languages) {
			state.languages = languages;
		},
		SET_PUBLIC_LANGUAGES(state, publicLanguages) {
			state.publicLanguages = publicLanguages;
		},
		SET_PREFERRED_LANGUAGE(state, preferredLanguage) {
			state.preferredLanguage = preferredLanguage;
		},
	},
	actions: {
		async initLanguagesAnonymous({ state, commit }) {
			const cookiePreferredLanguage = loadCookie();
			const languages = state.publicLanguages;

			const preferredLanguage = languages.find((lang) => lang.abbreviation === cookiePreferredLanguage);

			if (!preferredLanguage) {
				saveCookie(languages[0].abbreviation);
				commit("SET_PREFERRED_LANGUAGE", languages[0]);
				Vue.set(i18n, "locale", languages[0].abbreviation);
			} else {
				commit("SET_PREFERRED_LANGUAGE", preferredLanguage);
				Vue.set(i18n, "locale", preferredLanguage.abbreviation);
			}
		},
		async initLanguages({ state, commit, rootState }) {
			const data = await Languages.list();
			const languages = data?.data.data;
			const cookiePreferredLanguage = loadCookie();
			const currentUser = rootState.currentUser;

			commit("SET_LANGUAGES", languages);

			const preferredLanguage = languages?.find((lang) => lang?.abbreviation === cookiePreferredLanguage);
			const userPreferredLanguage = languages?.find((lang) =>
				lang?.abbreviation === currentUser.preferred_language);

			let finalLanguage = userPreferredLanguage;

			if (!preferredLanguage) {
				saveCookie(finalLanguage?.abbreviation);
			} else if (userPreferredLanguage.abbreviation !== preferredLanguage.abbreviation) {
				await UsersAPI.update(currentUser.id, { preferred_language: preferredLanguage.id });
				localStorage.setItem("languagePrompt", 1);
				finalLanguage = preferredLanguage;
			}

			const languageToUse = state.publicLanguages
				.find((lang) => lang.abbreviation == finalLanguage?.abbreviation);
			commit("SET_PREFERRED_LANGUAGE", languageToUse);
			Vue.set(i18n, "locale", languageToUse?.abbreviation);
		},
		async changeLanguage({ rootState }, language) {
			const currentUser = rootState.currentUser;
			await UsersAPI.update(currentUser.id, {
				preferred_language: language.id,
			}, {
				root: true,
			});
			saveCookie(language?.abbreviation);
			await refreshAccessTokenIfNecessary(true);
			window.location.reload();
		},
		async changeLanguageAnonymous(_, language) {
			saveCookie(language?.abbreviation);
			window.location.reload();
		},
	},
};
