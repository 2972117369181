import Vue from "vue";

import { getListRecognitionsSentByUserForSession } from "@/modules/RecognitionEvents/index.js";

import TrophiesAPI from "../services/index.js";
import { RECOGNITION_POINTS } from "../utilities/index.js";

function getDefaultState() {
	return {
		types: [],
		trophies: [],
		isShowingAvatarCustomization: false,
		isLoadingAvatarCustomization: false,
		isLoading: false,
	};
}

export const state = getDefaultState();

export const mutations = {
	SET_TYPES(state, { types }) {
		state.types = types;
	},
	SET_TROPHIES(state, { trophies }) {
		state.trophies = trophies;
	},
	UPDATE_TROPHY(state, { trophyId, params }) {
		const indexOfTrophy = state.trophies.findIndex(({ trophy_id }) => trophyId === trophy_id);
		try {
			Vue.set(state.trophies, indexOfTrophy, {
				...state.trophies[indexOfTrophy],
				...params,
			});
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	ACHIEVE_TROPHY(state, { trophyId, trophy }) {
		const indexOfTrophy = state.trophies.findIndex(({ trophy_id }) => trophyId === trophy_id);

		if (indexOfTrophy >= 0) {
			state.trophies.splice(indexOfTrophy, 1, trophy);
		} else {
			state.trophies = [
				...state.trophies,
				trophy,
			];
		}
	},
	SET_IS_SHOWING_AVATAR_CUSTOMIZATION(state, { isShowingAvatarCustomization }) {
		state.isShowingAvatarCustomization = isShowingAvatarCustomization;
	},
	SET_IS_LOADING_AVATAR_CUSTOMIZATION(state, { isLoadingAvatarCustomization }) {
		state.isLoadingAvatarCustomization = isLoadingAvatarCustomization;
	},
	SET_IS_LOADING(state, { isLoading }) {
		state.isLoading = isLoading;
	},
};

function transformTrophy(trophy) {
	const $progress = trophy.achieved_at
		? 100
		: (Math.round(trophy.json_state?.current / trophy.json_state?.target * 100) || 0);
	return {
		...trophy,
		$progress,
	};
}

const findTrophy = (array, type) => {
	return array.find(({ trophy }) => trophy.name === type.name);
};

export const getters = {
	badges(state) {
		const badges = state.types
			.filter((type) => type?.is_badge)
			.filter((type) => {
				const trophy = findTrophy(state.trophies, type);
				return type.is_hidden ? trophy?.achieved_at : true;
			})
			.map((type) => {
				const trophy = findTrophy(state.trophies, type);
				return {
					...type,
					$my: trophy && transformTrophy(trophy),
				};
			});
		return badges;
	},
	trophies(state) {
		const trophies = state.types
			.filter((type) => !type?.is_badge)
			.filter((type) => {
				const trophy = findTrophy(state.trophies, type);
				return type.is_hidden ? trophy?.achieved_at : true;
			})
			.map((type) => {
				const trophy = findTrophy(state.trophies, type);
				return {
					...type,
					$my: trophy && transformTrophy(trophy),
				};
			});
		return trophies;
	},
	points(s, getters, rs, rootGetters) {
		const pointsFromRecognitions = (rootGetters["Recognition/recognitionsList"]).length * RECOGNITION_POINTS;
		const achievements = [...getters.trophies, ...getters.badges];
		return achievements.reduce((total, current) => {
			if (current.$my?.achieved_at) {
				total += current.points;
			}
			return total;
		}, pointsFromRecognitions);
	},
	total(state) {
		return state.trophies.length;
	},
	latest(s, getters, rootState) {
		const latest = [
			...getters.badges,
			...getters.trophies,
			...rootState.Recognition.sessionsWithRecognitions,
		].reduce((acc, item) => {
			if (item?.$my?.achieved_at) {
				acc.push(item);
			} else if (item?.recognitions) {
				item.lastRecognitionAt = item.recognitions.data.reduce((last, current) => {
					return last > current.created_at ? last : current.created_at;
				}, 0);
				acc.push(...getListRecognitionsSentByUserForSession(item));
			}
			return acc;
		}, []);
		latest.sort((a, b) => {
			// recognitions use seconds timestamps and badges use microsecond timestamps
			const bDate = b?.lastRecognitionAt ?? (b?.$my.achieved_at / 1000000);
			const aDate = a?.lastRecognitionAt ?? (a?.$my.achieved_at / 1000000);
			return bDate - aDate;
		});
		return latest.slice(0, 12);
	},
};

export const actions = {
	async getTypes({ state, commit }, { force = false } = {}) {
		if (state.types?.length && !force) {
			return;
		}

		const response = await TrophiesAPI.list();
		commit("SET_TYPES", { types: response.data.data });
	},
	async getTrophies({ state, commit }, { force = false } = {}) {
		if (state.trophies?.length && !force) {
			return;
		}

		const response = await TrophiesAPI.getMyTrophies();
		commit("SET_TROPHIES", { trophies: response.data.data });
	},
	async setIsShowingAvatarCustomization({ state, getters, dispatch, commit }) {
		try {
			if (state.isLoadingAvatarCustomization || state.isShowingAvatarCustomization) {
				return;
			}
			commit("SET_IS_LOADING_AVATAR_CUSTOMIZATION", {
				isLoadingAvatarCustomization: true,
			});
			await Promise.all([
				dispatch("getTrophies"),
				dispatch("getTypes"),
			]);
			const avatarCustomizationTrophy = getters.badges?.find(({ name }) => name === "avatar-customization") || null;
			if (Boolean(avatarCustomizationTrophy?.$my?.achieved_at)) {
				commit("SET_IS_SHOWING_AVATAR_CUSTOMIZATION", { isShowingAvatarCustomization: true });
			}
		} catch (e) {
			Sentry.captureException(e);
			Promise.reject(e);
		} finally {
			commit("SET_IS_LOADING_AVATAR_CUSTOMIZATION", {
				isLoadingAvatarCustomization: false,
			});
		}
	},
	async achieveTrophy({ dispatch }, { trophyId }) {
		try {
			if (!trophyId) {
				return;
			}
			await TrophiesAPI.updateTrophy(trophyId, { read_at: Date.now() * 1000 });
			dispatch("getTrophies");
		} catch (error) {
			Sentry.captureException(error);
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
