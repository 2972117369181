var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tw-flex tw-flex-col tw-items-center tw-justify-center" },
    [
      _vm._t("icon", function () {
        return [
          _c(_vm.icon, {
            tag: "component",
            staticClass: "tw-text-grey-dark",
            attrs: { width: "48", height: "48" },
          }),
        ]
      }),
      _vm._v(" "),
      _vm._t("header", function () {
        return [
          _c(
            "h3",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "message-info",
                  expression: "'message-info'",
                },
              ],
              staticClass: "tw-font-bold",
              class: _vm.messageInfoClass,
            },
            [_vm._v("\n\t\t\t" + _vm._s(_vm.message.info) + "\n\t\t")]
          ),
        ]
      }),
      _vm._v(" "),
      _vm._t("main", function () {
        return [
          _vm.message.instruction
            ? _c("div", { staticClass: "tw-mt-1 tw-text-center" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "message-instruction",
                        expression: "'message-instruction'",
                      },
                    ],
                    staticClass: "tw-whitespace-nowrap tw-font-normal",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.message.instruction) +
                        "\n\t\t\t"
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      }),
      _vm._v(" "),
      _vm.isShowButton
        ? _c(
            "BaseButton",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "no-data-button",
                  expression: "'no-data-button'",
                },
              ],
              staticClass: "tw-mt-4",
              attrs: { color: _vm.buttonColor, variants: _vm.buttonVariants },
              nativeOn: {
                click: function ($event) {
                  return _vm.$emit("button-clicked", $event)
                },
              },
            },
            [
              _vm._t("button-text", function () {
                return [_vm._v("\n\t\t\t" + _vm._s(_vm.buttonText) + "\n\t\t")]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }