<template>
	<div class="profile">
		<span class="profile__text profile__text--large">
			{{ student.first_name + " " + student.last_name.charAt(0) + "." }}
		</span>
		<div class="profile__item">
			<p
				v-jest="'session-id'"
				class="profile__text profile__text--bold"
			>
				{{ $t("right_sidebar.student_profile.session_id") }} #{{ sessionId }}
			</p>
		</div>
		<div
			v-if="featureFlagSessionIntentsIsEnabled && !isEmpty(sessiontIntentType)"
			v-jest="'session-intent-type'"
			class="profile__item"
		>
			<span class="profile__item--title profile__text">
				{{ $t("session_intent.title") }}
			</span>
			<span class="profile__text profile__text--bold">
				{{ sessionIntentTypeName }}
			</span>
		</div>
		<div class="profile__item">
			<span class="profile__item--title profile__text">
				{{ $t("right_sidebar.student_profile.grade_level") }}
			</span>
			<span
				v-if="!isEditingGrade"
				v-jest="'grade-level'"
				class="profile__text profile__text--bold"
			>
				{{ $t(`grade_levels["${gradeLevelKey}"]`) }}
				<span
					class="profile__text--iconPen"
					@click="isEditingGrade = true"
				>
					<IconPen
						:icon-color="blackColor"
						:height="12"
						:width="12"
					/>
				</span>
			</span>
			<div
				v-else
				class="profile__edit-grade"
			>
				<BaseDropdown
					:items="grades"
					:is-clearable="false"
					close-on-select
				>
					<template slot="filters">
						{{ dropdownFilter }}
					</template>
					<template slot-scope="{ item }">
						<span
							:key="item.id"
							class="profile__dropdown-item"
							@click="selectedGrade = item"
						>
							<!-- {{ item.name }} -->
							{{ $t(`grade_levels["${item.name}"]`) }}
						</span>
					</template>
				</BaseDropdown>
				<div class="profile__editButtons">
					<BaseButton
						class="profile__editButtons-item"
						type="CANCEL"
						@click.native="cancelGradeSelection"
					>
						<template slot="text">
							{{ $t("cancel") }}
						</template>
					</BaseButton>
					<BaseButton
						class="profile__editButtons-item"
						type="SUBMIT"
						@click.native="submitGrade"
					>
						<template slot="text">
							{{ $t("save") }}
						</template>
					</BaseButton>
				</div>
			</div>
		</div>
		<div
			v-if="student.grade === null && isAttemptingToUpdateCleverStudentGrade === false"
			class="profile__item profile__unknownGrade"
		>
			<span class="profile__text">
				{{
					$t("right_sidebar.student_profile.ask_grade_level", {
						name: student.first_name,
					})
				}}
			</span>
		</div>
		<div
			v-else
			class="profile__item profile__unknownGrade"
		>
			<span class="profile__text">
				You cannot change the grade of a student who is synced with Clever
			</span>
		</div>
		<div class="profile__item">
			<span class="profile__item--title profile__text">
				{{ $t("right_sidebar.student_profile.class_name") }}
			</span>
			<span class="profile__text profile__text--bold">
				{{ sectionNameText }}
			</span>
		</div>
		<div class="profile__item">
			<span class="profile__item--title profile__text">
				{{ $t("right_sidebar.student_profile.teacher_name") }}
			</span>
			<span class="profile__text profile__text--bold">{{ teacherName }}</span>
		</div>
		<div
			v-show="isStudentAppUser || isStudentExtensionUser"
			class="profile__item--large"
		>
			<span class="profile__text profile__text--large ">
				<span
					v-jest="'limited-functionality-title'"
					class="profile__text--orange"
				>
					{{ limitedFunctionalityTitle }}
				</span>
				<span v-jest="'limited-functionality-header'"> {{ limitedFunctionalityHeader }} </span>
			</span>
			<span
				v-jest="'limited-functionality-text'"
				class="profile__text"
			>
				{{ limitedFunctionalityText }}
			</span>
		</div>
		<StudentProfilePreferredLanguage
			v-if="!isStudentLanguageEnglish"
			:preferred-language-key="student.language"
		/>
		<span
			v-else
			class="profile__text"
		>
			{{ $t("right_sidebar.student_profile.resource_not_available") }}
		</span>
		<div class="profile__tutorComments">
			Tutor's comments
		</div>
		<div v-if="isCommentsLoading">
			<LoadingDots />
		</div>
		<div
			v-else-if="comments.length == 0"
			class="profile__noComments"
		>
			No Comments
		</div>
		<div
			v-else
			class="profile__tutorComment"
		>
			<TutorComment
				v-for="(el, index) in comments"
				:key="index"
				:name="el.tutor"
				:comment="el.comment"
				:date="el.date"
			/>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { isNil, isEmpty } from "lodash";

import ShowTransformer from "@/services/transformers/showTransformer.js";
import Colors from "@/utilities/Colors.js";
import IconPen from "@/components/icons/IconPen.vue";
import LoadingDots from "@/components/LoadingDots.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";

import StudentProfilePreferredLanguage from "./StudentProfilePreferredLanguage.vue";
import StudentProfileTutorComment from "./StudentProfileTutorComment.vue";

export default {
	components: {
		IconPen,
		TutorComment: StudentProfileTutorComment,
		StudentProfilePreferredLanguage,
		LoadingDots,
		BaseButton,
		BaseDropdown,
	},
	data() {
		return {
			comments: [],
			sessionComments: {},
			teachers: [],
			grades: [],
			isEditingGrade: false,
			currentGrade: null,
			selectedGrade: null,
			isCommentsLoading: false,
			isAttemptingToUpdateCleverStudentGrade: false,
			featureFlagSessionIntentsIsEnabled: false,
			featureFlagLimitQuickAnswerIsEnabled: false,
			featureFlagSessionIntentsSlice2IsEnabled: false,
		};
	},

	computed: {
		...mapState(["Classroom"]),
		currentSession() {
			return this.$store.getters["Classroom/currentSession"];
		},

		/**
		 * @returns {Boolean}
		 */
		isStudentAppUser() {
			return this.student.is_app_user;
		},
		/**
		 * @returns {Boolean}
		 */
		isStudentExtensionUser() {
			return this.student.is_extension_user;
		},
		/**
		 * @returns {String}
		 */
		limitedFunctionalityText() {
			return this.isStudentAppUser ?
				this.$t("right_sidebar.student_profile.students_mobile_app") :
				this.$t("right_sidebar.student_profile.students_chrome_extension");
		},
		/**
		 * @returns {String}
		 */
		limitedFunctionalityHeader() {
			return this.isStudentAppUser ?
				this.$t("right_sidebar.student_profile.mobile_app") :
				this.$t("right_sidebar.student_profile.chrome_extension");
		},
		/**
		 * @returns {String}
		 */
		limitedFunctionalityTitle() {
			return this.isStudentAppUser ?
				this.$t("app").toUpperCase() :
				this.$t("chrome").toUpperCase();
		},
		/**
		 * @returns {Boolean}
		 */
		isStudentLanguageEnglish() {
			return this.student.language === "en";
		},

		student() {
			return this.currentSession.users.find(function(user) {
				return user.role === "student";
			});
		},

		/**
		 * Returns the string to be shown under section name.
		 * @returns {String}
		 */
		sectionNameText() {
			return this.currentSession.sectionName
				? this.currentSession.sectionName
				: this.$t("right_sidebar.student_profile.no_class");
		},

		/**
		 * returns the i18n key for deciding which grade to display
		 * @returns {String}
		 */
		gradeLevelKey() {
			if (this.currentGrade) {
				return this.currentGrade.name;
			} else {
				return "unknown";
			}
		},

		/**
		 * get the text that goes on the closed dropdown button
		 * @returns {String}
		 */
		dropdownFilter() {
			if (this.selectedGrade !== null) {
				return this.$t(`grade_levels["${this.selectedGrade.name}"]`);
			} else {
				return this.$t("grade_levels.select_grade_level");
			}
		},

		/**
		 * Returns the string to be displayed below "Teacher Name".
		 * @returns {String}
		 */
		teacherName() {
			if (this.teachers.length > 1) {
				return this.$t("right_sidebar.student_profile.multiple_teachers");
			} else if (this.teachers.length === 1) {
				return `${this.teachers[0].first_name} ${this.teachers[0].last_name}`;
			} else {
				return this.$t("right_sidebar.student_profile.no_teacher");
			}
		},
		/**
		 * @returns {Number}
		 */
		sessionId() {
			return this.currentSession.id;
		},
		/**
		 * @returns {String}
		 */
		blackColor() {
			return Colors.C_BLACK;
		},
		sessiontIntentType() {
			return this.currentSession?.intentType?.data;
		},
		sessionIntentTypeName() {
			if (this.featureFlagSessionIntentsSlice2IsEnabled) {
				return this.$t(`session_intent.list_V3[${this.sessiontIntentType.id}]`);
			} else if (this.featureFlagLimitQuickAnswerIsEnabled) {
				return this.$t(`session_intent.list_V2[${this.sessiontIntentType.id}]`);
			} else {
				return this.$t(`session_intent.list[${this.sessiontIntentType.id}]`);
			}
		},
	},

	watch: {
		async sessionId() {
			if (this.currentSession == null) {
				return;
			}

			if (this.sessionComments[this.currentSession.id] == undefined) {
				await this.getTutorComments();
			} else {
				this.comments = this.sessionComments[this.sessionId];
			}
			this.cancelGradeSelection();
			this.initializeGrade();
		},
	},

	async created() {
		this.featureFlagSessionIntentsIsEnabled = await this.$getFlag("PE-21021-session-intents");
		this.featureFlagLimitQuickAnswerIsEnabled = await this.$getFlag("core-1773-session-intents-limit-quick-answer");
		this.featureFlagSessionIntentsSlice2IsEnabled = await this.$getFlag("core-2349-session-intents-slice-2");
	},

	async mounted() {
		this.initializeGrade();
		try {
			await this.getTutorComments();

			const gradesResponse = await this.$store.dispatch("Tutor/Session/getStudentGrades");
			this.grades = gradesResponse.data;

			if (!isNil(this.currentSession?.sectionId)) {
				const payload = {
					sectionId: this.currentSession.sectionId,
					params: {
						include: "teachers",
					},
				};
				const response = await this.$store.dispatch("Tutor/Session/getClassroom", payload);
				this.teachers = response.data.teachers.data;
			}
		} catch (err) {
			Sentry.captureException(err);
		}
	},

	methods: {
		isEmpty,
		cancelGradeSelection() {
			this.isEditingGrade = false;
			this.selectedGrade = null;
		},

		initializeGrade() {
			//unrefactored fix; this.student.grade should never be undefined
			if (this.student.grade) {
				this.currentGrade = this.student.grade;
			} else {
				this.currentGrade = null;
			}
		},

		async getTutorComments() {
			try {
				this.isCommentsLoading = true;
				const transformedTutorComments = [];
				const studentUser = this.$store.getters["Classroom/currentSessionOtherUsers"].filter(({ role }) => role === "student");
				const tutorComments = await this.$store.dispatch(
					"Tutor/Session/getTutorCommentsForUser",
					studentUser[0].id,
				);

				tutorComments.data.forEach(function(tutorComment) {
					transformedTutorComments.push(ShowTransformer.tutorComment(tutorComment));
				});
				if (!isNil(this.currentSession?.id)) {
					this.sessionComments[this.currentSession.id] = transformedTutorComments;
				}
				this.comments = transformedTutorComments;
				return Promise.resolve();
			} catch (error) {
				Sentry.captureException(error);
				this.$store.dispatch("SimpleModal/configureSimpleModal", {
					buttonText: "Okay",
					title: "Error",
					message:
						"There was an error loading the student's comments, please refresh and try again",
					buttonType: "PRIMARY",
					eventFired: () => {
						location.reload();
					},
				});
				this.$bvModal.show("simpleModal");
				return Promise.reject(error);
			} finally {
				this.isCommentsLoading = false;
			}
		},
		async submitGrade() {
			try {
				const payload = {
					studentId: this.student.id,
					params: {
						grade_id: this.selectedGrade.id,
					},
				};
				await this.$store.dispatch("Tutor/Session/updateStudent", payload);
				this.$store.commit("Classroom/SET_STUDENT_GRADE_IN_SESSION", {
					studentUserId: this.student.id,
					sessionId: this.sessionId,
					grade: this.selectedGrade,
				});
				this.isEditingGrade = false;
				this.currentGrade = this.selectedGrade;
				this.selectedGrade = null;
			} catch (err) {
				if (err.response.status === 422) {
					this.isAttemptingToUpdateCleverStudentGrade = true;
				}
			}
		},
	},
};
</script>

<style scoped>
.profile {
	padding: 0 20px;
	overflow-y: scroll;
}

.profile__item {
	margin-bottom: 15px;
}

.profile__item--title {
	margin-bottom: 3px;
}

.profile__item--large {
	margin-bottom: 40px;
}

.profile__text {
	font-size: 12px;
	font-weight: 300;
	color: #4a4a4a;
	display: flex;
	align-items: center;
	margin-bottom: 0px;
}

.profile__text--bold {
	font-weight: 600;
}

.profile__text--iconPen {
	margin-left: 5px;
}

.profile__unknownGrade {
	background: rgba(255, 158, 69, 0.2);
	border: 2px solid var(--c-orange);
	border-radius: 6px;
	padding: 5px 12px;
}

.profile__editButtons {
	display: flex;
	justify-content: space-between;
	margin-top: 8px;
}

.profile__editButtons-item {
	width: 48%;
}

.profile__dropdown-item {
	width: 100%;
	height: 100%;
	display: inline-block;
}

.profile__text--large {
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 10px;
}

.profile__text--orange {
	color: var(--c-orange);
	margin-right: 5px;
}

.profile__noComments {
	font-size: 12px;
	font-weight: 100;
}

.profile__tutorComments {
	color: #4a4a4a;
	font-size: 14px;
	line-height: 1.86;
	font-weight: bold;
	margin-top: 20px;
}

:deep(.listItem__Icon) {
	color: var(--blue-regular);
}
</style>
