var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "trophy-notification",
          expression: "'trophy-notification'",
        },
      ],
      staticClass: "tw-flex tw-gap-4 tw-mb-0",
      attrs: { role: "listitem" },
      on: {
        mouseenter: function ($event) {
          _vm.isBadgeZoomed = true
        },
        mouseleave: function ($event) {
          _vm.isBadgeZoomed = false
        },
        focus: function ($event) {
          _vm.isBadgeZoomed = true
        },
        blur: function ($event) {
          _vm.isBadgeZoomed = false
        },
        mouseup: function ($event) {
          _vm.isBadgeZoomed = true
        },
        mousedown: function ($event) {
          _vm.isBadgeZoomed = false
        },
        click: _vm.handleCelebrate,
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.handleCelebrate.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "badge__container tw-relative tw-w-20 tw-h-20" },
        [
          _c("Badge", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "badge",
                expression: "'badge'",
              },
            ],
            ref: "badge",
            staticClass: "emoji",
            class: { zoom: _vm.isBadgeZoomed },
            attrs: { emoji: _vm.emoji, color: _vm.color },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-1 tw-text-left",
        },
        [
          _c(
            "h5",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "trophy-name",
                  expression: "'trophy-name'",
                },
              ],
              staticClass: "tw-font-bold tw-text-md",
            },
            [_vm._v("\n\t\t\t" + _vm._s(_vm.name) + "\n\t\t")]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "trophy-desc",
                  expression: "'trophy-desc'",
                },
              ],
            },
            [_vm._v("\n\t\t\t" + _vm._s(_vm.description) + "\n\t\t")]
          ),
          _vm._v(" "),
          _vm.item.points
            ? _c("Points", {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "points",
                    expression: "'points'",
                  },
                ],
                staticClass:
                  "tw-border tw-border-origami-grey-200 tw-rounded-full tw-py-1 tw-px-2 tw-mt-1",
                attrs: { points: _vm.item.points },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.trophy.name === "avatar-customization"
            ? _c("CustomizeAvatarButton", {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "customize-now",
                    expression: "'customize-now'",
                  },
                ],
                staticClass: "tw-mt-1",
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }