var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "dropdown",
      staticClass: "baseDropdown",
      on: { "click-outside": _vm.toggleDropdown },
    },
    [
      _vm.isShowingLabel ? _c("label", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "select",
              expression: "'select'",
            },
          ],
          staticClass: "mainDropdown",
          class: {
            mainDropdownActive: _vm.activeDropdown,
            mainDropdownDisabled: _vm.isDisabledOrNoData,
          },
          attrs: {
            tabindex: "0",
            disabled: _vm.isDisabledOrNoData,
            "aria-expanded": _vm.showDropdown ? "true" : "false",
            "aria-controls": _vm.dropdownId,
          },
          on: {
            keyup: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.toggleDropdown.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape",
                  ])
                )
                  return null
                _vm.showDropdown = false
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown",
                  ])
                )
                  return null
                return _vm.focusListItem(_vm.selectedItemIndex)
              },
            ],
            click: _vm.toggleDropdown,
          },
        },
        [
          _c("div", { staticClass: "labelContainer" }, [
            _c(
              "span",
              { staticClass: "labelContainer__span" },
              [_vm._t("filters")],
              2
            ),
          ]),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showDeselectButton,
                expression: "!showDeselectButton",
              },
            ],
            staticClass: "caret",
            class: { caretActive: _vm.activeDropdown },
          }),
          _vm._v(" "),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showDeselectButton,
                expression: "showDeselectButton",
              },
              {
                name: "jest",
                rawName: "v-jest",
                value: "deselect-all",
                expression: "'deselect-all'",
              },
              {
                name: "data-cy",
                rawName: "v-data-cy",
                value: "deselect-icon",
                expression: "'deselect-icon'",
              },
            ],
            attrs: { src: "/images/icon-x-close-blue.svg" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.deselectAll.apply(null, arguments)
              },
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("transition", { attrs: { tag: "ul", name: "slide" } }, [
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showDropdown && !_vm.isDisabledOrNoData,
                expression: "showDropdown && !isDisabledOrNoData",
              },
              {
                name: "jest",
                rawName: "v-jest",
                value: "dropdown",
                expression: "'dropdown'",
              },
            ],
            staticClass: "dropContainer",
            attrs: { id: _vm.dropdownId, role: "listbox" },
          },
          [
            _vm.isLongList && _vm.showSearchBar
              ? _c("li", { staticClass: "searchBar" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "filter",
                        expression: "'filter'",
                      },
                    ],
                    staticClass:
                      "vueDropdownSearchBar tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-regular tw-rounded-sm",
                    attrs: {
                      id: _vm.searchBarId,
                      type: "text",
                      placeholder: _vm.searchText,
                    },
                    on: {
                      keyup: [
                        _vm.filterItems,
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "esc", 27, $event.key, [
                              "Esc",
                              "Escape",
                            ])
                          )
                            return null
                          return _vm.focusDropdown()
                        },
                      ],
                    },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.$slots.selectAll
              ? _c(
                  "li",
                  {
                    staticClass: "checkboxSelectAll",
                    attrs: { role: "option" },
                  },
                  [_vm._t("selectAll")],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.items, function (item, index) {
              return _c(
                "li",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "item",
                      expression: "'item'",
                    },
                  ],
                  key: index,
                  attrs: {
                    id: `dropdown-item-${index}`,
                    role: "option",
                    tabindex: "-1",
                    "aria-selected":
                      index === _vm.selectedItemIndex ? "true" : "false",
                  },
                  on: {
                    keyup: [
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "up", 38, $event.key, [
                            "Up",
                            "ArrowUp",
                          ])
                        )
                          return null
                        return _vm.focusListItem(
                          index === 0 ? index : index - 1
                        )
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "down", 40, $event.key, [
                            "Down",
                            "ArrowDown",
                          ])
                        )
                          return null
                        return _vm.focusListItem(index + 1)
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "home",
                            undefined,
                            $event.key,
                            undefined
                          )
                        )
                          return null
                        return _vm.focusListItem(0)
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "end",
                            undefined,
                            $event.key,
                            undefined
                          )
                        )
                          return null
                        return _vm.focusListItem(_vm.items.length - 1)
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleSelect(item)
                      },
                      function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "esc", 27, $event.key, [
                            "Esc",
                            "Escape",
                          ])
                        )
                          return null
                        return _vm.focusDropdown()
                      },
                    ],
                    click: function ($event) {
                      return _vm.handleSelect(item)
                    },
                  },
                },
                [_vm._t("default", null, { item: item, index: index })],
                2
              )
            }),
            _vm._v(" "),
            _vm.isLongerList && !_vm.isSearching
              ? _c("li", { staticClass: "moreResults" }, [
                  _c("p", [_vm._v("Use Search for more results")]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.noDataComponent && !_vm.items.length
              ? _c("li", [_c(_vm.noDataComponent, { tag: "component" })], 1)
              : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }