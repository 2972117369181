import { omit, cloneDeep } from "lodash";

import ReceivedUserMessageDecorator from "@/modules/Classroom/classes/ReceivedUserMessageDecorator.js";
import MessageText from "@/modules/Classroom/classes/MessageText.js";
import MessageMedia from "@/modules/Classroom/classes/MessageMedia.js";

import sessionHelpers from "./SessionStoreHelpers.js";

/**
 * @param {Object} bookmarks
 * @param {Object} currentUser
 * @returns {Array} of {message{}, session{}}
 */
export const getBookmarkedMessages = (bookmarks, currentUser) => {
	const messages = [];
	bookmarks.forEach((bookmark) => {
		bookmark.messages.forEach((message) => {
			const session = omit(bookmark, "messages");
			const isMessageFormatted = [
				ReceivedUserMessageDecorator,
				MessageText,
				MessageMedia,
			].some((type) => message instanceof type);

			const transformedMessage = isMessageFormatted ?
				message :
				sessionHelpers.prepareMessageForSessionMessagesArray({
					sessionMessagesArray: [],
					session: bookmark,
					message: {
						...message,
						is_bookmarked: true,
					},
					sessionId: bookmark.session_id,
					currentUser: currentUser,
				});

			messages.push({
				session: session,
				message: getFormattedBookmarkMessage(transformedMessage),
			});
		});
	});
	return messages;
};

/**
 * 
 * @param {Object} message 
 * @returns {Object}
 */
export const getFormattedBookmarkMessage = (message) => {
	const updatedMessage = cloneDeep(message);
	if (message.message) {
		updatedMessage.message.sequencePosition = "top";
		updatedMessage.message.isLastMessage = true;
		updatedMessage.message.bookmarkedAt = message.bookmarkedAt;
	} else {
		updatedMessage.sequencePosition = "top";
	}
	return updatedMessage;
};
