var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tw-relative" },
    [
      _c("ChartDoughnut", {
        staticClass: "tw-h-full",
        attrs: { data: _vm.data, options: _vm.options, "aria-hidden": "true" },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "tw-absolute tw-top-0 tw-left-0 tw-flex tw-items-center tw-justify-center tw-w-full tw-h-full",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "tw-flex tw-items-center tw-justify-center tw-w-20 tw-h-20",
            },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }