const getDefaultState = () => ({
	currentPlayerId: null,
	currentTextToSpeechSpeed: null,
	transcriptions: new Map(),
	openedTranscriptionsIds: new Set(),
});

const getters = {
	getTranscriptionById: (state) => (messageId) => state.transcriptions.get(messageId),
	isTranscriptionOpened: (state) => (messageId) => state.openedTranscriptionsIds.has(messageId),
};

const mutations = {
	SET_CURRENT_PLAYER_ID(state, { id }) {
		state.currentPlayerId = id;
	},
	SET_TEXT_TO_SPEECH_SPEED(state, { currentTextToSpeechSpeed }) {
		state.currentTextToSpeechSpeed = currentTextToSpeechSpeed;
	},
	RESET_CURRENT_PLAYER_ID(state) {
		state.currentPlayerId = getDefaultState().currentPlayerId;
	},
	SET_TRANSCRIPTION(state, { id, text }) {
		state.transcriptions.set(id, text);
	},
	TOGGLE_TRANSCRIPTION_VIEW(state, { id }) {
		const { openedTranscriptionsIds } = state;
		if (openedTranscriptionsIds.has(id)) {
			openedTranscriptionsIds.delete(id);
		} else {
			openedTranscriptionsIds.add(id);
		}
	},
};

export default {
	namespaced: true,
	state: getDefaultState(),
	getters,
	mutations,
};
