import middleware from "@/middleware/index.js";
import isConnected from "@/middleware/isConnected.js";
import redirectIfNotPopupFromRoute from "@/middleware/redirectIfNotPopupFromRoute.js";

export default [
	{
		name: "whiteboard",
		path: "/whiteboard",
		component: () => import(
			"../pages/whiteboard.vue"
		),
		beforeEnter: middleware([
			isConnected,
			redirectIfNotPopupFromRoute(["/embedded/student/classroom", "/lightbox"], "/student/classroom"),
		]),
	},
];
