import ReferralsApi from "../services/index.js";

export const getDefaultState = () => ({
	referralCode: null,
	isLoading: false,
	isShowingError: false,
	redemptions: [],
});
const state = getDefaultState();

export const mutations = {
	SET_REFERRAL_CODE(state, payload) {
		state.referralCode = payload.referralCode;
	},
	SET_IS_LOADING(state, payload) {
		state.isLoading = payload.isLoading;
	},
	SET_IS_SHOWING_ERROR(state, payload) {
		state.isShowingError = payload.isShowingError;
	},
	SET_REDEMPTIONS(state, payload) {
		state.redemptions = payload.redemptions;
	},
};

export const actions = {
	async checkReferralLink({ commit }) {
		commit("SET_IS_LOADING", { isLoading: true });
		commit("SET_IS_SHOWING_ERROR", { isShowingError: false });
		try {
			const response = await ReferralsApi.check();
			commit("SET_REFERRAL_CODE", { referralCode: response?.data?.data?.referral_code || null });
			commit("SET_REDEMPTIONS", { redemptions: response?.data?.data?.redemptions || [] });
		} catch (error) {
			Sentry.captureException(error);
			commit("SET_IS_SHOWING_ERROR", { isShowingError: true });
		}
		commit("SET_IS_LOADING", { isLoading: false });
	},
	async unlockReferralLink({ commit }) {
		commit("SET_IS_LOADING", { isLoading: true });
		commit("SET_IS_SHOWING_ERROR", { isShowingError: false });
		try {
			const response = await ReferralsApi.unlock();
			commit("SET_REFERRAL_CODE", { referralCode: response?.data?.data?.referral_code || null });
		} catch (error) {
			Sentry.captureException(error);
			commit("SET_IS_SHOWING_ERROR", { isShowingError: true });
		}
		commit("SET_IS_LOADING", { isLoading: false });
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
