var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "tw-flex tw-flex-col tw-justify-start tw-gap-4 tw-h-full sm:tw-grid-cols-8 sm:tw-grid sm:tw-gap-x-4",
    },
    [
      _c(
        "div",
        { staticClass: "tw-col-span-4" },
        [
          _c("EssayStatisticsCard", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "tutor-essay-stats-card",
                expression: "'tutor-essay-stats-card'",
              },
            ],
            scopedSlots: _vm._u([
              {
                key: "cardTitle",
                fn: function () {
                  return [_vm._v("\n\t\t\t\tSubmission queue\n\t\t\t")]
                },
                proxy: true,
              },
              {
                key: "cardTooltip",
                fn: function () {
                  return [
                    _c("InfoToolTip", {
                      attrs: {
                        width: "11",
                        height: "11",
                        options: _vm.toolTipTutorEssayStatisticsCard,
                      },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "essayDetail",
                fn: function () {
                  return [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.EssayStatistics.essayStatistics.queued_essays
                        ) +
                        "\n\t\t\t"
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "essayMiscOne",
                fn: function () {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.numEssayStatisticsInProgress) +
                          " in progress"
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "essayMiscTwo",
                fn: function () {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.numEssayStatisticsReservedEssays) +
                          " in reserve"
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tw-col-span-4" },
        [
          _c("EssayStatisticsCard", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "total-essay-reviewed-card",
                expression: "'total-essay-reviewed-card'",
              },
            ],
            scopedSlots: _vm._u([
              {
                key: "cardTitle",
                fn: function () {
                  return [_vm._v("\n\t\t\t\tTotal reviewed\n\t\t\t")]
                },
                proxy: true,
              },
              {
                key: "cardTooltip",
                fn: function () {
                  return [
                    _c("InfoToolTip", {
                      attrs: {
                        width: "11",
                        height: "11",
                        options: _vm.tooltipTotalEssayReviewedCard,
                      },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "essayDetail",
                fn: function () {
                  return [
                    _vm._v(
                      "\n\t\t\t\t" + _vm._s(_vm.currentEssayNumber) + "\n\t\t\t"
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "essayMisc",
                fn: function () {
                  return [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.totalEssayReviewedMiscText) +
                        "\n\t\t\t"
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }