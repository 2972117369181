import CountryTransformer from "@/services/transformers/codeTransformer.js";
import ListTransformer from "@/services/transformers/listTransformer.js";

import DistrictsAPI from "../../services/districts.js";

const getDefaultState = () => (
	{
		districts: [],
		countries: [],
		regions: [],

		searchText: "",
		countryFilter: [],
		regionFilter: [],

		loading: false,
		isShowingNoFilterBanner: true,
		orderBy: "",
		pagination: {
			total_pages: 0,
			current_page: 0,
		},
		districtsTableHeaders: [
			{
				value: "synced_with",
				header: "",
				orderable: false,
				state: null,
			},
			{
				value: "id",
				header: "ID",
				orderable: true,
				state: null,
			},
			{
				value: "name",
				header: "district name",
				orderable: true,
				state: null,
			},
			{
				value: "country",
				header: "Country",
				orderable: true,
				state: null,
			},
			{
				value: "region",
				header: "State/Prov",
				orderable: true,
				state: null,
			},
			{
				value: "timezone",
				header: "Time zone",
				orderable: true,
				state: null,
			},
			{
				value: "schools",
				header: "schools",
				orderable: true,
				state: null,
			},
			{
				value: "students",
				header: "students",
				orderable: true,
				state: null,
			},
		],
		navbarMainTab: "Customers",
	}
);
const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_IS_SHOWING_NO_FILTER_BANNER(state, payload) {
		state.isShowingNoFilterBanner = payload.isShowingNoFilterBanner;
	},
	SET_LOADING(state, payload) {
		state.loading = payload.loading;
	},

	SET_ORDER_BY(state, payload) {
		state.orderBy = payload.orderBy;
	},

	UPDATE_HEADERS(state, payload) {
		state.districtsTableHeaders = payload.districtsTableHeaders;
	},

	SET_PAGINATION(state, payload) {
		state.pagination = payload.pagination;
	},

	RESET_PAGINATION(state) {
		state.pagination = { total_pages: 0, current_page: 0 };
	},

	SET_CURRENT_PAGE(state, payload) {
		state.pagination.current_page = payload.currentPage;
	},

	SET_DISTRICTS(state, payload) {
		state.districts = payload.districts;
	},

	SET_REGIONS(state, payload) {
		state.regions = payload.regions;
	},

	SET_COUNTRIES(state, payload) {
		state.countries = payload.countries;
	},

	SET_FILTERS(state, payload) {
		state.searchText = payload.searchText;
		state.countryFilter = payload.countryFilter;
		state.regionFilter = payload.regionFilter;
	},
};

const actions = {
	async setDistricts({ commit }, payload) {
		try {
			commit("SET_LOADING", {
				loading: true,
			});

			commit({
				type: "SET_IS_SHOWING_NO_FILTER_BANNER",
				isShowingNoFilterBanner: false,
			});

			commit("SET_FILTERS", {
				searchText: payload.search,
				countryFilter: payload.country,
				regionFilter: payload.region,
			});

			commit("SET_ORDER_BY", {
				orderBy: payload.orderBy,
			});

			const districts = await DistrictsAPI.filter(payload);

			const transformedDistricts = [];
			districts.data.data.forEach(function(district) {
				transformedDistricts.push(ListTransformer.transformDistrict(district));
			});

			commit("SET_DISTRICTS", {
				districts: transformedDistricts,
			});

			commit("SET_PAGINATION", {
				pagination: districts.data.meta.pagination,
			});

			return Promise.resolve();
		} catch (error) {
			commit("SET_DISTRICTS", {
				districts: [],
			});
			commit("RESET_PAGINATION");

			return Promise.reject(error.response);
		} finally {
			commit("SET_LOADING", {
				loading: false,
			});
		}
	},

	async getRegions({ commit }, countries) {
		let regions = [];
		if (countries.length > 1) {
			regions = CountryTransformer.listRegions(CountryTransformer.encodeCountry(countries[0]));
			regions = regions.concat(
				CountryTransformer.listRegions(CountryTransformer.encodeCountry(countries[1])),
			);
		} else {
			regions = CountryTransformer.listRegions(CountryTransformer.encodeCountry(countries[0]));
		}

		commit("SET_REGIONS", {
			regions,
		});
	},

	async setOrderBy({ commit, state }, payload) {
		commit("SET_LOADING", {
			loading: true,
		});

		commit("SET_ORDER_BY", {
			orderBy: payload,
		});

		commit("SET_CURRENT_PAGE", {
			currentPage: 1,
		});

		const params = {
			country: state.countryFilter,
			region: state.regionFilter,
			search: state.searchText,
			orderBy: payload,
		};

		try {
			const districts = await DistrictsAPI.filter(params);

			const transformedDistricts = [];
			districts.data.data.forEach(function(district) {
				transformedDistricts.push(ListTransformer.transformDistrict(district));
			});

			commit("SET_DISTRICTS", {
				districts: transformedDistricts,
			});

			commit("SET_LOADING", {
				loading: false,
			});

			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response);
		}
	},

	async setCurrentPage({ commit, state }, payload) {
		commit("SET_LOADING", {
			loading: true,
		});

		commit("SET_CURRENT_PAGE", {
			currentPage: payload.newPage,
		});

		const params = {
			country: state.countryFilter,
			region: state.regionFilter,
			search: state.searchText,
			orderBy: state.orderBy,
			page: payload.newPage,
		};
		try {
			const districts = await DistrictsAPI.filter(params);

			const transformedDistricts = [];
			districts.data.data.forEach(function(district) {
				transformedDistricts.push(ListTransformer.transformDistrict(district));
			});

			commit("SET_DISTRICTS", {
				districts: transformedDistricts,
			});

			commit("SET_LOADING", {
				loading: false,
			});

			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response);
		}
	},


};

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations,
	actions,
};
