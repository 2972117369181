<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		viewBox="0 0 26 20"
	>
		<g
			fill="none"
			fill-rule="evenodd"
		>
			<g :fill="iconColor">
				<path
					d="M1026 170c2.21 0 4 1.79 4 4v7.839c0 2.209-1.79 4-4 4h-8.278l-6.816 4.035c-.154.09-.33.14-.51.14-.512 0-.935-.387-.993-.884l-.006-.117v-3.174H1008c-2.21 0-4-1.791-4-4V174c0-2.21 1.79-4 4-4h18zm-9.39 4h-4.92v7h1.5v-2.93h3.05v-1.21h-3.05v-1.65h3.42V174zm3.63 0h-2.72v7h1.51v-2.65h1.16c.79 0 1.19.31 1.19 1.09 0 .88.06 1.19.27 1.56h1.62l.01-.03c-.31-.45-.4-.9-.4-1.6 0-.76-.27-1.27-.94-1.52.66-.29 1.11-.87 1.11-1.74 0-1.79-1.3-2.11-2.81-2.11zm-.12 1.18c.82 0 1.44.1 1.44.99 0 .87-.62 1-1.45 1h-1.08v-1.99h1.09z"
					transform="translate(-1004 -170)"
				/>
			</g>
		</g>
	</svg>
</template>

<script>
import Colors from "../../utilities/Colors.js";
export default {
	props: {
		width: {
			type: [Number, String],
			default: 26,
		},
		height: {
			type: [Number, String],
			default: 20,
		},
		iconColor: {
			type: String,
			default: Colors.C_LANGUAGE_BUBBLE_BLUE,
		},
	},
};
</script>
