export default {
	C_LIGHT_BLUE: "#99b1ef",
	C_BLUE: "#0e0fe0",
	C_DARK_BLUE: "#0b0cb3",
	C_DARKER_BLUE: "#050f64",
	C_BLUE_PASTEL: "#becde7",
	C_LIGHT_GREEN: "#82d38b",
	C_GREEN_PASTEL: "#add9b2",
	C_GREEN: "#04cf56",
	C_DARK_GREEN: "#009d40",
	C_PEACH: "#ffd1ab",
	C_ORANGE: "#ff9e45",
	C_LIGHT_ORANGE: "#ffba81",
	C_DARK_ORANGE: "#ff570d",
	C_ORANGE_PASTEL: "#ffd1ab",
	C_RED: "#ff190d",
	C_PINK: "#FF9BD7",
	C_WHITE: "#ffffff",
	C_LIGHTER_GRAY: "#f4f3f1",
	C_LIGHT_GRAY: "#e5e5e5",
	C_GRAY: "#a3a3a3",
	C_BLACK: "#191919",
	C_YELLOW: "#ffe500",
	C_LIGHT_YELLOW: "#fee687",
	C_LIGHT_PINK: "#ffbbdd",
	C_DARK_PINK: "#c83593",
	C_CARDBOARD_BEIGE: "#dcd2c7",
	C_BOX_SHADOW: "rgba(60, 83, 150, 0.25)",

	C_LANGUAGE_BUBBLE_GREEN: "#017a3d",
	C_LANGUAGE_BUBBLE_RED: "#c3264e",
	C_LANGUAGE_BUBBLE_BLUE: "#002393",
	C_LANGUAGE_BUBBLE_ORANGE: "#de2910",
	C_LANGUAGE_BUBBLE_YELLOW: "#ffc228",
};
