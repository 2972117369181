<template>
	<svg
		viewBox="0 0 8 15"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		:style="orientationClass"
	>
		<defs>
			<path
				id="path-1"
				d="M2.98427949e-13,0.465966102 L7.81820339,0.465966102 L7.81820339,15.7288136 L2.98427949e-13,15.7288136 L2.98427949e-13,0.465966102 Z"
			/>
		</defs>
		<g
			stroke="none"
			stroke-width="1"
			fill="none"
			fill-rule="evenodd"
		>
			<g transform="translate(-665.000000, -132.000000)">
				<g transform="translate(665.000000, 112.000000)">
					<g transform="translate(0.000000, 19.000000)">
						<mask
							id="mask-2"
							fill="white"
						>
							<use xlink:href="#path-1" />
						</mask>
						<g id="Clip-2" />
						<path
							id="Fill-1"
							d="M7.81795763,1 L0.649550847,7.00054237 C-0.216516949,7.7260339 -0.216516949,9.53779661 0.649550847,10.2623051 L7.81795763,16.2628475 L7.81795763,1 Z"

							class="fillCurrent"
							mask="url(#mask-2)"
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>
<script>
import IconId from "./IconId.js";
export default {
	extends: IconId,
	props: {
		orientation: {
			type: String,
			default: "down",
			validator(value) {
				return ["right", "left", "up", "down"].includes(value);
			},
		},
	},
	computed: {
		orientationClass() {
			switch (this.orientation) {
				case "left":
					return "";
				case "right":
					return "transform: rotate(180deg)";
				case "up":
					return "transform: rotate(90deg)";
				case "down":
					return "transform: rotate(-90deg)";
				default:
					return "transform: rotate(-90deg)";
			}
		},
	},
};
</script>
<style scoped>
.fillCurrent {
	fill: currentColor;
}
</style>
