export function clickEphemeralLink(link, attrs = {}) {
	const a = document.createElement("a");
	a.href = link;
	for (const attr in attrs) {
		a[attr] = attrs[attr];
	}
	document.body.appendChild(a);
	a.click();
	a.remove();
}

export function openLinkInNewTab(link, attrs = {}) {
	clickEphemeralLink(link, { ...attrs, target: "_blank" });
}

export function downloadLinkAsFile(link, filename = link, attrs = {}) {
	clickEphemeralLink(link, { ...attrs, download: filename });
}

export async function openLinkInPopup(link, { width = 600, height = 600 } = {}) {
	if (!link) {
		return;
	}
	return new Promise((resolve) => {
		const left = (window.screen.width - width) / 2;
		const top = (window.screen.height - height) / 2;
		const popup = window.open(link, "_blank", `popup, width=${width}, height=${height}, left=${left}, top=${top}`);

		const listener = ({ source, data }) => {
			if (source === popup) {
				popup.close();
				window.removeEventListener("message", listener);

				resolve(data);
			}
		};
		window.addEventListener("message", listener);
	});
}

export function openLinkInPopupWithoutListener(link, { width = 600, height = 600 } = {}) {
	if (!link) {
		return;
	}
	const left = (window.screen.width - width) / 2;
	const top = (window.screen.height - height) / 2;
	return window.open(link, "_blank", `popup, width=${width}, height=${height}, left=${left}, top=${top}`);
}

export const POPUP_CLOSING_MESSAGE = "paper-co.popup.closing";
