<template>
	<div
		v-jest="'shift-summary-break'"
		class="tw-list-none tw-ml-16 tw-text-xs"
	>
		<OrigamiIcon
			:name="breakIcon"
			height="14"
			width="14"
		/>
		{{ startTime }} - {{ endTime }}
	</div>
</template>

<script>
import { OrigamiIcon } from "@origami/vue2";
import { format } from "date-fns";

import { MEAL_BREAK_ID } from "@/modules/TutorSchedule/index.js";

export default {
	components: {
		OrigamiIcon,
	},
	props: {
		shiftSegment: {
			type: Object,
			required: true,
		},
	},
	computed: {
		startTime() {
			return this.formatDate(this.shiftSegment.start_time);
		},
		endTime() {
			return this.formatDate(this.shiftSegment.end_time);
		},
		breakIcon() {
			return this.shiftSegment.type.id === MEAL_BREAK_ID ? "meal-break" : "break";
		},
	},
	methods: {
		formatDate(time) {
			return format(new Date(time), "h:mm aaa");
		},
	},
};
</script>
