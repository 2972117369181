var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    {
      staticClass:
        "tw-divide-y-2 tw-divide-origami-grey-100 tw-border-t-2 tw-border-origami-grey-100 tw-w-full",
    },
    _vm._l(_vm.shiftBreakdown, function (value, name) {
      return _c(
        "li",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "shift-type",
              expression: "'shift-type'",
            },
          ],
          key: name,
          staticClass: "tw-py-2 tw-text-lg tw-grid tw-grid-cols-4",
        },
        [
          _c(
            "div",
            { staticClass: "tw-col-span-3 tw-flex tw-place-items-center" },
            [
              _c("span", {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "shift-color",
                    expression: "'shift-color'",
                  },
                ],
                staticClass: "tw-w-5 tw-h-5 tw-rounded-md",
                class: _vm.getOrigamiColorFromShiftTypeId(name),
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "shift-title",
                      expression: "'shift-title'",
                    },
                  ],
                  staticClass: "tw-font-bold tw-ml-2",
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.getShiftTypeNameFromShiftTypeId(name)) +
                      "\n\t\t\t"
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "tw-col-span-1 tw-flex tw-place-content-end tw-place-items-center tw-font-bold",
            },
            [
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "shift-total",
                      expression: "'shift-total'",
                    },
                  ],
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(
                        _vm.formatTotalMinutesToHoursMins(
                          _vm.shiftBreakdown[name].total,
                          false
                        )
                      ) +
                      "\n\t\t\t"
                  ),
                ]
              ),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }