import { render, staticRenderFns } from "./LanguagePicker.vue?vue&type=template&id=da6a49d8&scoped=true&"
import script from "./LanguagePicker.vue?vue&type=script&setup=true&lang=js&"
export * from "./LanguagePicker.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./LanguagePicker.vue?vue&type=style&index=0&id=da6a49d8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da6a49d8",
  null
  
)

export default component.exports