<template>
	<OrigamiCard
		tag="li"
		is-interactive
		:is-selected="isSelected"
		class="tw-flex tw-items-center tw-gap-3 tw-p-3"
		@click.native="$emit('click', $event)"
	>
		<VideoThumbnail
			v-jest="'thumbnail'"
			:url="thumbnailUrl"
			class="tw-rounded-md"
			@click.native="$emit('thumbnail-click', $event)"
		/>
		<div class="tw-flex-auto">
			<button
				v-jest="'bookmark'"
				class="bookmark origami-icon-button tw-float-right tw--mt-1"
				@click.stop="$emit('bookmark-click', $event)"
			>
				<IconBookmark
					v-if="isBookmarked"
					v-jest="'bookmark-on'"
					class="tw-text-origami-brand-blue"
				/>
				<IconBookmarkOutlined
					v-else
					v-jest="'bookmark-off'"
					class="tw-text-origami-grey-300"
				/>
			</button>
			<h4
				v-if="video.title"
				v-jest="'title'"
				:title="video.title"
				class="line-clamp-2 tw-text-sm tw-font-bold"
			>
				{{ video.title }}
			</h4>
			<p
				v-if="video.description"
				v-jest="'description'"
				:title="video.description"
				class="line-clamp-2 tw-text-2xs"
			>
				{{ video.description }}
			</p>
		</div>
	</OrigamiCard>
</template>

<script>
import { OrigamiCard } from "@origami/vue2";

import IconBookmark from "@/components/elements/icons/Bookmark.vue";
import IconBookmarkOutlined from "@/components/elements/icons/BookmarkOutlined.vue";

import { getImageByType } from "../utilities/index.js";
import ContentCatalogApi from "../services/contentCatalog.js";

import VideoThumbnail from "./VideoThumbnail.vue";

export default {
	components: {
		OrigamiCard,
		IconBookmark,
		IconBookmarkOutlined,
		VideoThumbnail,
	},
	props: {
		isSelected: OrigamiCard.props.isSelected,
		video: {
			type: Object,
			default: () => ({
				title: null,
				description: null,
				content_id: null,
			}),
		},
		isBookmarked: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			videoData: null,
		};
	},
	computed: {
		thumbnailUrl() {
			return getImageByType(this.videoData?.images, "thumbnail");
		},
	},
	async created() {
		this.videoData = (await ContentCatalogApi.getVideo(this.video.content_id)).data.data;
	},
};
</script>

<style scoped>
.line-clamp-2 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	overflow: hidden;
}
.bookmark {
	padding: 0.25rem;
}
</style>
