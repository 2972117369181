<script setup>
import { computed } from "vue";
import { differenceInMinutes } from "date-fns";

import useStore from "@/composables/useStore.js";
import { formatPlurality } from "@/utilities/strings.js";

const props = defineProps({
	shift: {
		type: Object,
		required: true,
	},
	currentDate: {
		type: Date,
		required: true,
	},
});

const store = useStore();

const isLockedOutOfShift = computed(() => store.getters["TutorSchedule/isLockedOutOfShift"]);
const isLate = computed(() => store.getters["TutorSchedule/isLateForShift"]);
const isShiftEnding = computed(() => store.getters["TutorSchedule/isShiftEnding"]);
const lateCountdown = computed(() => {
	const minutesLate = differenceInMinutes(props.currentDate, new Date(props.shift.start_time));
	return minutesLate ? `${formatPlurality(minutesLate, "min")} late` : null;
});
const earlyCountdown = computed(() => {
	const minutesToShiftStart = differenceInMinutes(new Date(props.shift.start_time), props.currentDate);
	return minutesToShiftStart ? `Shift starting in ${formatPlurality(minutesToShiftStart, "min")}` : null;
});
const endShiftCountdown = computed(() => {
	const minutesToShiftEnd = differenceInMinutes(
		new Date(props.shift.end_time), props.currentDate, { roundingMethod: "ceil" });
	return minutesToShiftEnd === 1 ? "<1 min left" : `${minutesToShiftEnd} mins left`;
});
const countdownText = computed(() => {
	if (isLate.value) {
		return lateCountdown.value;
	} else if (isShiftEnding.value) {
		return endShiftCountdown.value;
	}

	return earlyCountdown.value;
});
</script>

<template>
	<div
		v-if="countdownText"
		v-jest="'punch-clock-countdown'"
		class="punch-tooltip tw-bg-origami-white tw-absolute tw-left-[50%] tw-w-auto tw-translate-x-[-50%] tw-shadow-elevation-2 tw-rounded tw-p-2 tw-text-[14px] tw-leading-none tw-whitespace-nowrap tw-text-origami-black tw-block tw-z-50 after:tw-content-[''] after:tw-absolute after:tw-top-[-6px] after:tw-left-1/2 after:tw-translate-x-[-50%]"
	>
		<div id="tooltip">
			<p
				v-jest="'punch-clock-countdown-text'"
				class=" tw-font-bold tw-text-blue-regular"
				:class="{ 'tw-text-orange-dark': isLate }"
			>
				{{ countdownText }}
			</p>
			<p
				v-if="isLockedOutOfShift"
				v-jest="'punch-clock-locked-message'"
				class="tw-block tw-text-[0.75rem] tw-text-origami-black tw-leading-4 tw-mt-2"
			>
				Please Contact a<br>
				Platform Manager
			</p>
		</div>
	</div>
</template>

<style scoped>
.punch-tooltip {
	top: calc(100% + 0.5rem);
}

.punch-tooltip:after {
	border-color: white transparent;
	border-width: 0 8px 8px 8px;
}
</style>
