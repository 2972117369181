var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "bannersContainer tw-max-w-full tw-fixed tw-bottom-0 md:tw-bottom-5 tw-left-0 md:tw-left-1/2 md:tw-transform md:tw--translate-x-1/2 tw-p-4 tw-pointer-events-none",
    },
    [
      _c(
        "TransitionGroup",
        {
          staticClass: "tw-relative tw-flex tw-flex-col tw-gap-3 md:tw-gap-5",
          attrs: { name: "banner", tag: "div" },
        },
        _vm._l(
          _vm.Banner.activeBanners,
          function ({ component, ...props }, index) {
            return _c(
              component,
              _vm._g(
                _vm._b(
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "banner",
                        expression: "'banner'",
                      },
                    ],
                    key: index,
                    tag: "component",
                    staticClass: "banner-item tw-pointer-events-auto",
                    on: {
                      "main-action": function ($event) {
                        return _vm.onMainAction(index)
                      },
                      close: function ($event) {
                        return _vm.onClose(index)
                      },
                    },
                  },
                  "component",
                  props,
                  false
                ),
                _vm.$listeners
              )
            )
          }
        ),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }