<template>
	<div
		class="tag"
		:class="{
			'tag--enabled': isClearable,
			'tag--disabled': !isClearable,
		}"
	>
		<div class="tag__leftItem">
			<p class="tag__text">
				<slot name="text" />
			</p>
		</div>
		<div
			v-if="isClearable"
			class="tag__rightItem"
		>
			<slot name="icon" />
		</div>
	</div>
</template>
<script>
export default {
	props: {
		item: {
			type: Object,
			required: true,
		},
		isClearable: {
			type: Boolean,
			required: true,
		},
	},
};
</script>
<style>
.tag {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 304px;
	height: 40px;
	border-radius: 5px;
}
.tag--enabled {
	border: solid 2px #a3a3a3;
	background-color: #ffffff;
}
.tag--disabled {
	background-color: #e5e5e5;
}
.tag__text {
	margin: 0 16px;
	font-size: 12px;
	font-weight: 600;
	color: #191919;
	width: 240px;
	text-overflow: ellipsis;
	overflow-y: hidden;
	word-break: break-all;
}
.tag__rightItem {
	padding-right: 12px;
	display: flex;
}
</style>
