import { isEmpty } from "lodash";
import { getFlag } from "@paper-co/web-toolkit";

import GradesAPI from "@/services/api/Grades.js";
import StudentAPI from "@/services/api/Students.js";

export const state = {
	profile: {
		grade: null,
		avatar: null,
	},
	topSubjects: [],
	isLoadingProfile: false,
	gradesList: [],
};
export const mutations = {
	/**
	 * 
	 * @param state 
	 * @param {Object} profile 
	 */
	SET_PROFILE(state, { profile }) {
		state.profile = profile;
	},
	/**
	 * 
	 * @param state 
	 * @param {Array} topSubjects 
	 */
	SET_TOP_SUBJECTS(state, { topSubjects }) {
		state.topSubjects = topSubjects;
	},
	SET_IS_LOADING_PROFILE(state, { isLoadingProfile }) {
		state.isLoadingProfile = isLoadingProfile;
	},
	SET_GRADES_LIST(state, { gradesList }) {
		state.gradesList = gradesList;
	},
};

export const parseProfileData = (profileData) => {
	const {
		grade,
		avatar,
		...profile
	} = profileData;
	profile.grade = isEmpty(grade.data) ? null : grade.data;
	profile.avatar = avatar ? JSON.parse(avatar) : null;
	return profile;
};

export const actions = {
	/**
	 * 
	 * @param commit 
	 * @param {Number} studentId 
	 */
	async get({ commit }) {
		commit({
			type: "SET_IS_LOADING_PROFILE",
			isLoadingProfile: true,
		});
		const response = await StudentAPI.getProfile();
		const profile = parseProfileData(response.data.data);
		commit({
			type: "SET_PROFILE",
			profile,
		});
		commit({
			type: "SET_IS_LOADING_PROFILE",
			isLoadingProfile: false,
		});

		return profile;
	},
	/**
	 * 
	 * @param commit 
	 * @param {Number} studentId 
	 * @param {Object} profile 
	 * @param {Object} params 
	 */
	async update({ commit }, { studentId, profile, params }) {
		await StudentAPI.update(studentId, params);
		commit({
			type: "SET_PROFILE",
			profile,
		});
	},
	/**
	 * 
	 * @param commit 
	 * @param {Number} limit 
	 */
	async getTopSubjects({ commit }, { limit }) {
		const response = await StudentAPI.getSubjects({ limit });
		commit({
			type: "SET_TOP_SUBJECTS",
			topSubjects: response.data.data,
		});
	},
	/**
	 * 
	 * @returns {Array}
	 */
	async getGrades({ commit }) {
		const gradesList = (await GradesAPI.list({ include: "gradeGroup" })).data.data;

		if (await getFlag("GROW-1734-major-clarity-from-paper")) {
			commit("SET_GRADES_LIST", { gradesList });
			return gradesList;
		}

		return gradesList;
	},
	/**
	 * 
	 * @returns {Array}
	 */
	async getLearningStyles() {
		return (await StudentAPI.getLearningStyles()).data.data;
	},
};

export default {
	namespaced: true,
	mutations,
	actions,
	state,
};
