import { LaravelApi } from "@/services/root.js";
import middleware from "@/middleware/index.js";
import store from "@/store/index.js";
import { getInstance } from "@/plugins/Auth0.js";

import { redirectInPopupHandler } from "../utilities/auth.js";
import setPostLoginRedirectFromQuery from "../middleware/setPostLoginRedirectFromQuery.js";
import redirectIfConnected from "../middleware/redirectIfConnected.js";
import oauth2Check from "../middleware/oauth2Check.js";
import redirectToAuth0Login from "../middleware/redirectToAuth0Login.js";
import getIdTokenPostAuth0Login from "../middleware/getIdTokenPostAuth0Login.js";

export default [
	{
		path: "/login",
		component: () => import("../components/loginPages/login.vue"),
		beforeEnter: middleware([
			setPostLoginRedirectFromQuery,
			redirectIfConnected,
			redirectInPopupHandler,
			redirectToAuth0Login,
		]),
		meta: {
			embeddable: true,
			screen: "Login",
		},
	},
	{
		path: "/login/student",
		component: () => import("../components/loginPages/student.vue"),
		beforeEnter: middleware([
			setPostLoginRedirectFromQuery,
			getIdTokenPostAuth0Login,
			redirectIfConnected,
		]),
		meta: {
			embeddable: true,
			screen: "Login",
		},

	},
	{
		path: "/login/student/:districtName?/:schoolName?",
		component: () => import("../components/loginPages/_districtName/_schoolName.vue"),
		beforeEnter: middleware([
			setPostLoginRedirectFromQuery,
			redirectIfConnected,
		]),
		meta: {
			embeddable: true,
		},
	},
	{
		path: "/login/noSchool",
		component: () => import("../components/loginPages/noSchool.vue"),
		beforeEnter: middleware([
			setPostLoginRedirectFromQuery,
			redirectIfConnected,
			redirectToAuth0Login,
		]),
		meta: {
			embeddable: true,
		},
	},
	{
		path: "/login/oauth2/authorize",
		beforeEnter: middleware([
			oauth2Check,
		]),
		meta: {
			embeddable: true,
		},
	},
	{
		path: "/password/reset/:token",
		component: () => import("../components/loginPages/password/_token.vue"),
		beforeEnter: middleware([
			redirectIfConnected,
		]),
		meta: {
			embeddable: true,
		},
	},
	{
		path: "/trouble-logging-in",
		component: () => import("../components/loginPages/troubleLoggingInNoSchool.vue"),
		beforeEnter: middleware([
			redirectIfConnected,
		]),
		meta: {
			embeddable: true,
			screen: "ForgotPassword",
		},
	},
	{
		path: "/trouble-logging-in/noschool",
		component: () => import("../components/loginPages/troubleLoggingInNoSchool.vue"),
		beforeEnter: middleware([
			redirectIfConnected,
		]),
		meta: {
			embeddable: true,
			screen: "ForgotPassword",
		},
	},
	{
		path: "/trouble-logging-in/student/:district?/:school?",
		component: () => import("../components/loginPages/troubleLoggingIn.vue"),
		beforeEnter: middleware([
			redirectIfConnected,
		]),
		meta: {
			embeddable: true,
			screen: "ForgotPassword",
		},
	},
	{
		path: "/logout",
		component: () => import("../components/loginPages/logout.vue"),
		beforeEnter: middleware([
			(to, from, next) => {
				store.commit("SET_SHOW_NAVBAR", false);
				next();
			},
		]),
		meta: {
			embeddable: true,
		},
	},
	{
		path: "/stop-impersonation",
		async beforeEnter() {
			try {
				await LaravelApi().get("/stop-impersonation");
			} catch (error) {
				Sentry.captureException(error);
			} finally {
				window.location.href = "/";
			}
		},
	},
	{
		path: "/clever/instant-login",
		async beforeEnter() {
			const auth0 = await getInstance();
			return auth0.loginWithRedirect({ authorizationParams: { connection: "Clever" } });
		},
	},
];
