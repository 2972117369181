<template>
	<div class="tw-flex tw-flex-col tw-gap-2 tw-items-center tw-mb-4">
		<component
			:is="icon"
			v-if="icon"
			v-jest="'component-icon'"
			:height="iconHeight"
			class="tw-text-origami-grey-200 tw-w-full"
		/>

		<div class="tw-font-bold tw-mb-2">
			<slot />
		</div>

		<BaseButton
			v-if="buttonText"
			v-bind="buttonProps"
			@click.native="(event) => $emit('button-clicked', event)"
		>
			{{ buttonText }}
		</BaseButton>
	</div>
</template>

<script>
import BaseButton from "../elements/BaseButton.vue";

export default {
	components: {
		BaseButton,
	},
	props: {
		icon: {
			type: Object,
			default: () => null,
		},
		iconHeight: {
			type: Number,
			default: 48,
		},
		buttonText: {
			type: String,
			default: "",
		},
		buttonProps: {
			type: Object,
			default: () => ({
				color: "inherit",
				variants: ["outline", "mini"],
			}),
		},
	},
};
</script>
