<template>
	<OrigamiCard
		v-jest="'recognition-card'"
		:is-interactive="isInteractive"
		:tag="tag"
		class="sessionRecognitionsCard tw-flex tw-flex-col tw-p-6 tw-gap-4"
	>
		<h3
			v-jest="'from-user'"
			class="tw-font-bold"
		>
			{{ title }}
		</h3>
		<p
			v-if="message"
			v-jest="'message'"
		>
			"{{ message }}"
		</p>
		<ul class="tw-gap-4 tw-flex tw-flex-col">
			<RecognitionsCardItem
				v-for="recognition in recognitions"
				:id="recognition.recognition_type_id"
				:key="recognition.recognition_type_id"
				v-jest="'recognitions'"
				tag="li"
				:name="recognition.recognitionType.data.name"
			/>
		</ul>
		<p
			v-if="isShowingSessionDetails"
			v-jest="'student-details'"
			class="tw-text-sm tw-text-origami-grey-300"
		>
			<span v-jest="'session-date'">{{ getDateFromTimestamp(session.created_at) }}</span>
			<template
				v-if="session.requestedSubject.data.id !==
					SUBJECT_ID_TRIAGE"
			>
				&bull;
				<span v-jest="'subject-name'">
					{{ getTranslatedSubject(session
						.requestedSubject
						.data
						.name)
					}}
				</span>
			</template>
		</p>
	</OrigamiCard>
</template>

<script>
import { mapState } from "vuex";
import { fromUnixTime } from "date-fns";
import { upperFirst } from "lodash";
import { OrigamiCard } from "@origami/vue2";


import { formatDateTranslation } from "@/utilities/dateHelpers.js";
import { SUBJECT_ID_TRIAGE } from "@/utilities/ChatIntroHelpers.js";

import RecognitionsCardItem from "./RecognitionsCardItem.vue";

export default {
	components: {
		OrigamiCard,
		RecognitionsCardItem,
	},
	props: {
		fromUser: {
			type: Object,
			default: () => ({}),
		},
		session: {
			type: Object,
			default: () => ({}),
		},
		recognitions: {
			type: Array,
			default: () => [],
		},
		message: {
			type: String,
			default: "",
		},
		tag: {
			type: String,
			default: "article",
		},
		isInteractive: {
			type: Boolean,
			default: false,
		},
		isShowingSessionDetails: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		SUBJECT_ID_TRIAGE,
	}),
	computed: {
		...mapState(["currentUser"]),
		title() {
			const titleUserName = this.currentUser.id === this.fromUser.id ?
				this.$t("you") :
				this.fromUser.first_name;

			return `${upperFirst(this.$t("from"))} ${titleUserName}:`;
		},
	},
	methods: {
		upperFirst,
		getTranslatedSubject(subjectName) {
			const translationKey = `subjects.${subjectName}`;
			return this.$te(translationKey) ? this.$t(translationKey) : subjectName;
		},
		getDateFromTimestamp(timestamp) {
			const date = fromUnixTime(timestamp);
			return formatDateTranslation(date, "MMM d, yyyy", this.currentUser.preferred_language);
		},
	},
};
</script>
<style scoped>
.sessionRecognitionsCard {
	height: fit-content;
}
</style>
