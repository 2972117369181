<template>
	<OrigamiCard
		tag="div"
		is-interactive
		class="tw-flex tw-items-center tw-justify-center tw-flex-shrink-0 tw-bg-origami-black tw-text-origami-white tw-rounded-none tw-overflow-hidden"
		:style="{ width: size, height: size }"
	>
		<img
			v-if="!isError"
			v-show="isLoaded"
			v-jest="'image'"
			:src="url"
			:alt="$t('teaching_assets.thumbnail.alt', { name: title })"
			class="tw-object-cover tw-opacity-50 tw-rounded-none tw-mb-0 tw-mt-0"
			:style="{ width: size, height: size }"
			@error="isError = true; isLoaded = true"
			@load="isLoaded = true"
		>
		<BaseLoadingState
			v-if="!isLoaded && !isError"
			v-jest="'loading'"
		/>
		<OrigamiIcon
			v-if="isLoaded || isError"
			name="play"
			class="tw-absolute tw-pointer-events-none"
		/>
	</OrigamiCard>
</template>

<script>
import { OrigamiCard, OrigamiIcon } from "@origami/vue2";

import BaseLoadingState from "@/components/BaseLoadingState.vue";

export default {
	components: {
		OrigamiCard,
		OrigamiIcon,
		BaseLoadingState,
	},
	props: {
		url: {
			type: String,
			default: null,
		},
		size: {
			type: String,
			default: "4rem",
		},
		title: {
			type: String,
			default: "a paper teaching asset",
		},
	},
	data: () => ({
		isLoaded: false,
		isError: false,
	}),
};
</script>

<style scoped>
.tw-rounded-none:focus-visible:before {
	@apply tw-rounded-lg;
}
</style>
