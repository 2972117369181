var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 8 12",
      },
    },
    [
      _c("path", {
        attrs: {
          fill: _vm.iconColor,
          "fill-rule": "evenodd",
          d: "M1.3 7h4.529c1.314 0 1.696.817.852 1.825l-2.124 2.538c-.441.527-1.544.527-1.985 0L.447 8.825C-.393 7.821-.015 7 1.3 7zm0-2.242c-1.315 0-1.694-.82-.853-1.825L2.572.395c.441-.527 1.544-.527 1.985 0L6.68 2.933c.844 1.008.462 1.825-.852 1.825h-4.53z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }