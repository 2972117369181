<template>
	<div class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full">
		<IconCalendar
			:icon-color="colorGray"
			:width="64"
			:height="64"
		/>
		<span class="noShifts__text">You have no upcoming shift for the next 24 hrs</span>
	</div>
</template>
<script>
import IconCalendar from "../../../components/icons/IconCalendar.vue";
export default {
	components: {
		IconCalendar,
	},
	computed: {
		colorGray() {
			return this.$root.$colors.C_GRAY;
		},
	},
};
</script>
<style scoped>
.noShifts__text {
	font-size: 0.75rem;
	color: var(--c-gray);
	margin-top: 13px;
	width: 146px;
	text-align: center;
}
</style>
