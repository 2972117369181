<template>
	<BaseModal
		:id="modalName"
		:modal-ref="modalName"
	>
		<div class="tw-flex tw-justify-start tw-items-baseline tw-border-none">
			<div class="transferSessionModal__icon tw-flex tw-justify-center tw-w-11 tw-h-11 tw-rounded-full tw-bg-origami-blue-300 tw-mr-4">
				<IconArrowTransfer class="transferSessionModal__svg" />
			</div>

			<div class="tw-truncate tw-text-lg tw-font-bold tw-text-origami-text-black">
				Transfer To Self
			</div>
		</div>
		<div
			v-if="isProcessingTransfer"
			class="processingTransfer tw-mt-8 tw-pb-12"
		>
			<div class="tw-w-full tw-pl-6 tw-pr-0.5 tw-flex tw-flex-col tw-justify-center tw-items-center">
				<BaseLoadingState
					v-jest="'transfer-loading-spinner'"
					class="loadingSpinner"
				/>
				<p class="loadingState__text--font tw-text-base tw-mt-2 tw-text-black-medium tw-font-bold">
					Hand off in progress...
				</p>
				<p class="loadingState__text--font tw-text-xs tw-mt-2 tw-text-black-medium ">
					A chat session will open up with the student shortly.
				</p>
			</div>
		</div>
		<div
			v-else
			class="tw-flex tw-flex-col tw-gap-y-8 tw-mt-8"
		>
			<div>
				<p class="tw-text-base">
					A classroom session with you and the student will start.
				</p>
			</div>
			<div class="tw-flex tw-justify-end tw-w-full tw-gap-x-4">
				<BaseButton
					v-jest="'cancel-button'"
					type="CANCEL"
					@click.native="closeModal"
				>
					<template #text>
						Cancel
					</template>
				</BaseButton>
				<BaseButton
					v-jest="'transfer-button'"
					@click.native="transferSession"
				>
					<template #text>
						Transfer
					</template>
				</BaseButton>
			</div>
		</div>
	</BaseModal>
</template>

<script>
import { mapState } from "vuex";

import BaseButton from "@/components/BaseButton.vue";
import BaseLoadingState from "@/components/BaseLoadingState.vue";

import { SELF_TRANSFER_ACTION_ID } from "../utilities/actionCenterHelpers.js";
import BaseModal from "../../../components/patterns/BaseModal.vue";
import IconArrowTransfer from "../../../components/icons/IconArrowTransfer.vue";

export default {
	components: {
		BaseModal,
		IconArrowTransfer,
		BaseButton,
		BaseLoadingState,
	},
	props: {
		modalName: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			isProcessingTransfer: false,
		};
	},
	computed: {
		...mapState(["currentUser", "PlatformManager"]),
		currentUserRole() {
			return this.currentUser.role;
		},
	},
	methods: {
		closeModal() {
			this.isProcessingTransfer = false;
			this.$bvModal.hide(this.modalName);
		},
		async transferSession() {
			this.isProcessingTransfer = true;
			const params = this.prepareHandoffParams();
			const updatePayload = {
				id: this.PlatformManager.ActionCenter.selectedActionItem.id,
				params: {
					action_type_id: SELF_TRANSFER_ACTION_ID,
					picked_up_user_id: this.currentUser.id,
				},
			};
			try {
				await this.$store.dispatch("PlatformManager/ActionCenter/updateActionItem", updatePayload);
				const response = await this.$store.dispatch("Activity/createHandoff", params);
				if (response.data.data.incoming_tutor_id === null) {
					throw new Error();
				}
				await this.pushToClassroom();
			} catch (error) {
				const reverseUpdatePayload = {
					id: this.PlatformManager.ActionCenter.selectedActionItem.id,
					params: {
						action_type_id: SELF_TRANSFER_ACTION_ID,
						picked_up_user_id: this.currentUser.id,
					},
				};
				this.$store.dispatch("PlatformManager/ActionCenter/updateActionItem", reverseUpdatePayload);
				this.closeModal();
				this.$bvModal.show(`serviceDashboardFailedTransferModalId`);
			} finally {
				this.isProcessingTransfer = false;
			}
		},
		async pushToClassroom() {
			this.closeModal();
			await this.$router.push("/tutor/classroom");
		},
		prepareHandoffParams() {
			const handoffType = this.currentUserRole === "superuser" ? "activity-superuser" : "activity-tutor-manager";
			const params = new FormData();
			params.append("session_id", this.PlatformManager.ServiceSummaryDashboard.transferSessionData.sessionId);
			params.append("incoming_tutor_id", this.currentUser.id);
			params.append("handoff_type_name", handoffType);
			params.append("comment", "");
			return params;
		},
	},
};
</script>

<style scoped>
.transferSessionModal__icon {
	min-width: 2.75rem;
}
.transferSessionModal__svg {
	margin-top: 13px;
}
.processingTransfer .loadingSpinner {
	border-color: rgba(14, 15, 224, 0.2);
	border-left-color: var(--blue-regular);
	border-width: 0.625rem;
	margin: 0.5rem 0;
	height: 3rem;
	width: 3rem;
}
.loadingState__text--font {
	font-family: MonumentGrotesk-Bold, sans-serif;
}
</style>
