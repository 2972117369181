<template>
	<div
		class="loadingDots"
		:style="loadingDotsStyles"
	>
		<span>.</span><span>.</span><span>.</span>
	</div>
</template>
<script>
import Colors from "../utilities/Colors.js";
export default {
	props: {
		size: {
			type: String,
			required: false,
			default: "100px",
		},
		color: {
			type: String,
			required: false,
			default: Colors["C_BLUE"],
		},
	},
	computed: {
		/**
		 * @returns {Object}
		 */
		loadingDotsStyles() {
			return {
				"font-size": this.size,
				color: this.color,
			};
		},
	},
};
</script>
<style scoped>
.loadingDots {
	align-self: center;
	height: 100%;
}
.loadingDots span {
	animation-name: blink;
	animation-duration: 1.4s;
	animation-iteration-count: infinite;
	animation-fill-mode: both;
}

.loadingDots span:nth-child(2) {
	animation-delay: 0.2s;
}

.loadingDots span:nth-child(3) {
	animation-delay: 0.4s;
}
@keyframes blink {
	0% {
		opacity: 0.2;
	}
	20% {
		opacity: 1;
	}
	100% {
		opacity: 0.2;
	}
}
</style>
