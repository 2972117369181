import SchoolsAPI from "../../services/schools.js";

const getDefaultState = () => ({
	currentUser: null,
	isImpersonate: false,
	countries: ["CA", "US"],
	regions: [],
	schools: [],
	navbarMainTab: "Customers",
});

const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_REGIONS(state, payload) {
		state.regions = payload.regions;
	},
	SET_SCHOOLS(state, payload) {
		state.schools = payload.schools;
	},
};

const actions = {
	async setSchools({ commit }) {
		try {
			const schools = await SchoolsAPI.list({
				district_id: "null",
				include: "loginType,manager",
			});
			commit("SET_SCHOOLS", {
				schools: schools.data.data,
			});
		} catch (err) {
			alert(
				"Unable to get list of schools. Please refresh the page, and if the problem persists, contact the dev team.",
			);
		}
	},
};

export default {
	namespaced: true,
	state: getDefaultState(),
	actions,
	mutations,
};
