var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "subNavbar" }, [
    _c(
      "nav",
      {
        staticClass:
          "tw-max-w-5xl tw-flex tw-mx-auto tw-justify-start tw-relative",
      },
      [
        _c(
          "ul",
          { class: _vm.listClass },
          _vm._l(_vm.tabs, function (tab) {
            return _c(
              "li",
              {
                directives: [
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: `sub-navbar-tab-${tab.name}`,
                    expression: "`sub-navbar-tab-${tab.name}`",
                  },
                ],
                key: `navbar${tab.name}`,
                staticClass: "subNavbar__tab",
                class: {
                  "subNavbar-lastTab": _vm.isBreakTab(tab.name),
                },
              },
              [
                !_vm.isBreakTab(tab.name)
                  ? _c(
                      "BaseLink",
                      {
                        directives: [
                          {
                            name: "data-cy",
                            rawName: "v-data-cy",
                            value: `subnavbar-tab-${tab.name}`,
                            expression: "`subnavbar-tab-${tab.name}`",
                          },
                        ],
                        staticClass: "subNavbar__link tw-py-4 tw-px-7",
                        attrs: { to: tab.url },
                      },
                      [_vm._v("\n\t\t\t\t\t" + _vm._s(tab.name) + "\n\t\t\t\t")]
                    )
                  : _c(
                      "BaseLink",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "show-icon-break",
                            expression: "'show-icon-break'",
                          },
                        ],
                        staticClass: "iconBreak__position tw-relative",
                        attrs: { to: tab.url },
                      },
                      [
                        _c("IconBreak"),
                        _vm._v(" "),
                        !_vm.isMealBreakRequestEmpty
                          ? _c("div", {
                              directives: [
                                {
                                  name: "jest",
                                  rawName: "v-jest",
                                  value: "breaks-notification",
                                  expression: "'breaks-notification'",
                                },
                              ],
                              staticClass:
                                "tw-w-2 tw-h-2 tw-bg-orange-dark tw-rounded-full tw-absolute tw-top-1/4 tw-left-11",
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
              ],
              1
            )
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }