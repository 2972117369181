<template>
	<ul
		ref="moisaicGrid"
		class="auto-rows-0 tw-grid tw-gap-4 tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3"
	>
		<RecognitionsCard
			v-for="(recognitionsSentByUserForSession, index) in listRecognitionsSentByUserForSessions"
			:key="index"
			ref="moisaicItem"
			v-jest="'recognition-card'"
			:from-user="recognitionsSentByUserForSession.fromUser"
			:session="recognitionsSentByUserForSession.session"
			:recognitions="recognitionsSentByUserForSession.recognitions"
			:message="recognitionsSentByUserForSession.message"
			tag="li"
			is-interactive
			is-showing-session-details
			@click.native="$router.push(`/student/classroom?sessionId=${recognitionsSentByUserForSession.session.id}`)"
		/>
	</ul>
</template>

<script>
import { mapState } from "vuex";

import { getListRecognitionsSentByUserForSession } from "../utilities/index.js";

import RecognitionsCard from "./RecognitionsCard.vue";

export default {
	components: {
		RecognitionsCard,
	},
	computed: {
		...mapState(["currentUser", "Recognition"]),
		listRecognitionsSentByUserForSessions() {
			let listRecognitionsSentByUserForSessions = [];
			const sessionsWithRecognitionsReversed = [...this.Recognition.sessionsWithRecognitions].reverse(); // Ordering by latest sessions first
			sessionsWithRecognitionsReversed.forEach((session) => {
				listRecognitionsSentByUserForSessions = listRecognitionsSentByUserForSessions.concat(
					getListRecognitionsSentByUserForSession(session),
				);
			});
			return listRecognitionsSentByUserForSessions;
		},
	},
	mounted() {
		this.resizeAllMosaicItems();
	},
	methods: {
		resizeAllMosaicItems() { // Inspired from https://w3bits.com/css-grid-masonry/
			const grid = this.$refs.moisaicGrid;
			const moisaicItems = this.$refs.moisaicItem;
			moisaicItems.forEach(({ $el }) => {
				const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue("grid-row-gap"));
				const rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue("grid-auto-rows"));
				const rowSpan = Math.ceil(($el.getBoundingClientRect().height + rowGap) / (rowHeight + rowGap));
				$el.style.gridRowEnd = "span " + rowSpan;
			});
		},
	},
};
</script>
<style scoped>
.sessionRecognitionsCard {
	height: fit-content;
}
.auto-rows-0 {
	grid-auto-rows: 0;
}
</style>
