import api from "../../../../services/api/Api.js";

export default {
	types(data) {
		return api().get("/recognition-types", data);
	},
	list(data) {
		return api().get("me.recognitions", data);
	},
	check(sessionId, data) {
		return api().get(`/sessions/${sessionId}/recognized-by-me`, data);
	},
	create(sessionId, data) {
		return api().post(`/sessions/${sessionId}/recognitions`, data);
	},
	getSessionRecognitions(sessionId) {
		return api().get(`/sessions/${sessionId}/recognitions`);
	},
};
