const state = {
	recording: null,
	isOpen: false,
};
const mutations = {
	SET_RECORDING(state, { file, src, duration }) {
		state.recording = { file, src, duration };
	},
	RESET_RECORDING(state) {
		state.recording = null;
	},
	SET_PREVIEW_MODAL(state, payload) {
		state.isOpen = payload.isOpen;
	},
};

export default { namespaced: true, mutations, state };
