<template>
	<p
		:class="currentShiftScheduleTypeColor"
		class="tw-flex tw-text-center tw-w-max tw-mb-auto tw-ml-auto tw-px-1 tw-py-0 tw-rounded-md tw-font-bold tw-text-sm"
	>
		{{ name }}
	</p>
</template>
<script>
import { getOrigamiColorFromShiftTypeId, getShiftTypeNameFromShiftTypeId } from "@/utilities/ScheduleHelpers.js";

export default {
	props: {
		shiftType: {
			type: Number,
			required: true,
		},
	},
	computed: {
		currentShiftScheduleTypeColor() {
			return getOrigamiColorFromShiftTypeId(this.shiftType);
		},
		name() {
			return getShiftTypeNameFromShiftTypeId(this.shiftType);
		},
	},
};
</script>
