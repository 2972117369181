<template>
	<OrigamiCard class="tw-w-72 tw-p-4 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4">
		<div
			class="tw-w-16 tw-h-16 tw-bg-origami-grey-100 tw-flex tw-items-center tw-justify-center tw-filter tw-grayscale tw-rounded-full"
			aria-hidden="true"
		>
			<Emoji
				class="tw-text-3xl"
				emoji="PartyPooper"
			/>
		</div>
		<p class="tw-text-center">
			Celebrate learning moments with your student
		</p>
		<OrigamiButton
			v-jest="'send-recognition-button'"
			class="tw-w-full tw-flex tw-justify-center"
			@click.native="$emit('recognition-send')"
		>
			Send a sticker
		</OrigamiButton>
	</OrigamiCard>
</template>

<script>
import { OrigamiCard, OrigamiButton } from "@origami/vue2";

import { Emoji } from "@/modules/Emoji/index.js";

export default {
	components: {
		OrigamiCard,
		OrigamiButton,
		Emoji,
	},
};
</script>
