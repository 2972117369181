import api from "../../../services/api/Api.js";

export default {
	/**
	 * Get list of upcoming tutors
	 * @returns {Promise}
	 */
	getUpcomingTutors() {
		return api().get(`service/upcoming-tutors`);
	},
	/**
	 * Get List of Essay Tutors
	 *  @returns {Promise}
	 */
	getEssayTutors() {
		return api().get(`service/essay-tutors`);
	},

	/**
	 *  Get Summary Data
	 * @returns {Promise}
	 */
	getSummary() {
		return api().get(`service/summary`);
	},

	/**
	 * Get Current Live Tutors
	 * @returns {Promise}
	 */
	getLiveTutors(params) {
		return api().get(`service/live-tutors`, { params });
	},

	/**
	 * Get Scheduled tutor managers Added stuff
	 * @returns {Promise}
	 */
	getTutorManagers() {
		return api().get(`service/tutor-managers`);
	},
};
