import { isEmpty } from "lodash";

import AccountsAPI from "@/services/api/Account.js";
import CountryTransformer from "@/services/transformers/codeTransformer.js";
import GradesAPI from "@/services/api/Grades.js";
import PlatformEmailsAPI from "@/services/api/PlatformEmails.js";
import RolesAPI from "@/services/api/Roles.js";
import ShowTransformer from "@/services/transformers/showTransformer.js";
import StudentsAPI from "@/services/api/Students.js";
import TutorsCommentsAPI from "@/services/api/TutorComments.js";

import SchoolsAPI from "../../services/schools.js";
import DistrictsAPI from "../../services/districts.js";
import UsersAPI from "../../services/users.js";

const getDefaultState = () => (
	{
		user: {
			first_name: "",
			last_name: "",
			roles: {
				data: [],
			},
			schools: {
				data: [],
			},
			sessions: {
				data: [],
			},
			student: {
				data: [],
			},
		},
		sections: [],
		roles: [],
		grades: [],
		districts: [],
		schools: [],
		countries: ["Canada", "United States"],
		regions: [],
		tutorComments: [],
		isLoading: true,
		saved: {
			fullName: false,
			username: false,
			roles: false,
			school: false,
			location: false,
			license: false,
			email: false,
		},
		passwordError: false,
		passwordSuccess: false,
		welcomeEmailError: false,
		welcomeEmailSuccess: false,
		errors: {
			fullName: false,
			username: false,
			roles: false,
			school: false,
			location: false,
			license: false,
			email: false,
		},
		errorMessage: "",
		sectionsTableHeaders: [
			{
				value: "id",
				header: "ID",
				orderable: false,
				state: null,
			},
			{
				value: "className",
				header: "section name",
				orderable: false,
				state: null,
			},
			{
				value: "courseName",
				header: "course name",
				orderable: false,
				state: null,
			},
			{
				value: "teacher",
				header: "teacher",
				orderable: false,
				state: null,
			},
			{
				value: "status",
				header: "Status",
				orderable: false,
				state: null,
			},
			{
				value: "startDate",
				header: "start date",
				orderable: false,
				state: null,
			},
			{
				value: "endDate",
				header: "end date",
				orderable: false,
				state: null,
			},
			{
				value: "students",
				header: "students",
				orderable: false,
				state: null,
			},
			{
				value: "topics",
				header: "topics",
				orderable: false,
				state: null,
			},
		],
		tutorCommentsTableHeaders: [
			{
				value: "date",
				header: "date",
				orderable: false,
				state: null,
			},
			{
				value: "tutor",
				header: "tutor",
				orderable: false,
				state: null,
			},
			{
				value: "comment",
				header: "comment",
				orderable: false,
				state: null,
			},
		],
		navbarMainTab: "Customers",
	}
);

export default {
	namespaced: true,
	state: getDefaultState(),
	getters: {
		isUserBanned(state) {
			if (!isEmpty(state.user.student.data)) {
				return state.user.student.data.is_banned;
			} else {
				return false;
			}
		},
		ltiTitle(state) {
			return state.user.synced_with;
		},
	},
	mutations: {
		RESET_STATE(state) {
			Object.assign(state, getDefaultState());
		},
		SET_USER(state, payload) {
			state.user = payload.user;
		},
		SET_IS_LOADING(state, payload) {
			state.isLoading = payload.isLoading;
		},
		SET_SECTIONS(state, payload) {
			state.sections = payload.sections;
		},
		SET_TUTOR_COMMENTS(state, { tutorComments }) {
			state.tutorComments = tutorComments;
		},
		SET_DISTRICTS(state, payload) {
			state.districts = payload.districts;
		},
		SET_SCHOOLS(state, payload) {
			state.schools = payload.schools;
		},
		SET_ROLES(state, payload) {
			state.roles = payload.roles;
		},
		SET_GRADES(state, payload) {
			state.grades = payload.grades;
		},
		SET_REGIONS(state, payload) {
			state.regions = payload.regions;
		},
		SET_COUNTRIES(state, payload) {
			state.countries = payload.countries;
		},
		SET_SAVED(state, payload) {
			const keys = Object.keys(payload);
			keys.forEach(function(key) {
				if (key !== "type") {
					state.saved[key] = payload[key];
				}
			});
		},
		SET_ERRORS(state, payload) {
			const keys = Object.keys(payload);
			const errorKeys = Object.keys(state.errors);
			errorKeys.forEach((errorKey) => {
				state.errors[errorKey] = false;
			});
			keys.forEach((key) => {
				if (key !== "type" && key !== "errorMessage") {
					state.errors[key] = payload[key];
				}
			});
			state.errorMessage = payload.errorMessage;
		},
		SET_ORDER_BY(state, payload) {
			state.orderBy = payload.orderBy;
		},

		UPDATE_HEADERS(state, payload) {
			state.schoolsTableHeaders = payload.schoolsTableHeaders;
		},

		SET_PASSWORD_ERRORS(state, payload) {
			state.passwordError = payload.error;
			state.passwordSuccess = payload.success;
		},

		SET_WELCOME_ERRORS(state, payload) {
			state.welcomeEmailError = payload.error;
			state.welcomeEmailSuccess = payload.success;
		},
		SET_IS_BANNED(state, payload) {
			state.user.student.data.is_banned = payload.isBanned;
		},
	},
	actions: {
		async setUser({ commit }, payload) {
			try {
				const user = await UsersAPI.show(payload.id, payload.params);
				commit("SET_USER", {
					user: user.data.data,
				});
				return Promise.resolve();
			} catch (error) {
				return Promise.reject(error.response);
			}
		},
		async updateUser({ dispatch, state }, payload) {
			let savedItem;
			try {
				const id = state.user.id;

				if (payload.hasOwnProperty("first_name")) {
					savedItem = "fullName";
				} else if (payload.hasOwnProperty("country_code")) {
					savedItem = "location";
				} else if (payload.hasOwnProperty("license_expiry_date")) {
					savedItem = "license";
				} else {
					savedItem = Object.keys(payload)[0];
				}
				dispatch("resetErrors", savedItem);
				await UsersAPI.update(state.user.id, payload);
				const params = {
					id: id,
					params: {
						include:
							"roles,schools.district,sections.teachers,sections.topics,sections.course,sessions,essays,student.grade",
					},
				};

				dispatch("setUser", params);
				dispatch("setSaved", savedItem);
			} catch (error) {
				let message = "";
				if (error.response.status === 422) {
					const errorObject = error.response.data.error_description;
					message = Object.values(errorObject)[0][0];
				} else if (error.response.status === 403) {
					message = "Fields synced with an LTI cannot be edited";
				} else {
					message =
						error.response.data.toString() + ": Something went wrong while updating this user.";
				}

				const payload = {
					itemError: savedItem,
					errorMessage: message,
				};
				dispatch("setErrors", payload);
				return Promise.reject(error.response);
			}
		},

		async updateUserRoles({ dispatch, state }, payload) {
			const savedItem = "roles";
			try {
				const id = state.user.id;
				dispatch("resetErrors", savedItem);
				await RolesAPI.update(state.user.id, payload);
				const params = {
					id: id,
					params: {
						include:
							"roles,schools.district,sections.teachers,sections.topics,sessions,essays,student.grade",
					},
				};
				dispatch("setUser", params);
				dispatch("setSaved", savedItem);
			} catch (error) {
				const payload = {
					itemError: savedItem,
					errorMessage: "Error: The user can not have role tutor and/or superuser.",
				};
				dispatch("setErrors", payload);
				return Promise.reject(error.response);
			}
		},

		async updateUserSchool({ dispatch, state }, payload) {
			const savedItem = "school";
			try {
				const id = state.user.id;
				dispatch("resetErrors", savedItem);
				await SchoolsAPI.updateUserSchool(state.user.id, payload);
				const params = {
					id: id,
					params: {
						include:
							"roles,schools.district,sections.teachers,sections.topics,sessions,essays,student.grade",
					},
				};
				dispatch("setUser", params);
				dispatch("setSaved", savedItem);
				return Promise.resolve();
			} catch (error) {
				let message = "Error: There was a problem updating the user's school.";
				if (error.response.status === 403) {
					message = "Fields synced with an LTI cannot be edited";
				}
				const payload = {
					itemError: savedItem,
					errorMessage: message,
				};
				dispatch("setErrors", payload);
				return Promise.reject(error.response);
			}
		},

		async setSections({ commit, state }) {
			const sections = state.user.sections.data;
			const transformedSections = [];

			sections.forEach(function(section) {
				transformedSections.push(ShowTransformer.transformSection(section));
			});
			commit("SET_SECTIONS", {
				sections: transformedSections,
			});

			commit("SET_IS_LOADING", {
				isLoading: false,
			});
		},
		async updateUserGrade({ dispatch, state }, payload) {
			const userId = state.user.id;
			const savedItem = "grade";
			try {
				await StudentsAPI.update(userId, { grade_id: payload.grade_id });
				const params = {
					id: userId,
					params: {
						include:
							"roles,schools.district,sections.teachers,sections.topics,sessions,essays,student.grade",
					},
				};
				dispatch("setUser", params);
				dispatch("setSaved", savedItem);
			} catch (err) {
				const payload = {
					itemError: savedItem,
					errorMessage: "Error: There was a problem updating the user's grade.",
				};
				dispatch("setErrors", payload);
			}
		},

		async updateUserPassword({ commit }, payload) {
			try {
				await AccountsAPI.postPasswords(payload).then(
					function() {
						commit("SET_PASSWORD_ERRORS", {
							error: false,
							success: true,
						});
					},
					function() {
						commit("SET_PASSWORD_ERRORS", {
							error: true,
							success: false,
						});
					},
				);
			} catch (error) {
				Sentry.captureException(error);
			}
		},

		async getDistricts({ commit }) {
			const districts = await DistrictsAPI.list();
			commit("SET_DISTRICTS", {
				districts: districts.data.data,
			});
		},

		async getSchools({ commit }) {
			const schools = await SchoolsAPI.list({ include: "district" });
			commit("SET_SCHOOLS", {
				schools: schools.data.data,
			});
		},

		async getTutorComments({ commit }, { userId }) {
			try {
				const tutorComments = await TutorsCommentsAPI.list(userId);

				const transformedTutorComments = [];

				tutorComments.data.data.forEach(function(tutorComment) {
					transformedTutorComments.push(ShowTransformer.tutorComment(tutorComment));
				});

				commit("SET_TUTOR_COMMENTS", {
					tutorComments: transformedTutorComments,
				});
			} catch (e) {
				Sentry.captureException(e);
			}
		},

		removeTutorCommentFromArray({ commit, state }, { id }) {
			try {
				const tutorComments = state.tutorComments;
				const index = tutorComments.findIndex(function(el) {
					return el.id == id;
				});

				if (index == -1) {
					throw new Error("Something went wrong finding the tutor comment");
				}

				tutorComments.splice(index, 1);

				commit("SET_TUTOR_COMMENTS", {
					tutorComments: tutorComments,
				});
			} catch (e) {
				Sentry.captureException(e);
			}
		},

		getRoles({ commit }) {
			commit("SET_ROLES", {
				roles: [
					{
						name: "Student",
						id: 1,
					},
					{
						name: "Superuser",
						id: 3,
					},
					{
						name: "Administrator",
						id: 4,
					},
					{
						name: "Teacher",
						id: 7,
					},
					{
						name: "District Manager",
						id: 8,
					},
					{
						name: "PaperLive Editor",
						id: 12,
					},
					{
						name: "PaperClips Editor",
						id: 13,
					},
				],
			});
		},

		getRolesForSuperuserCreator({ commit }) {
			commit("SET_ROLES", {
				roles: [
					{
						name: "Student",
						id: 1,
					},
					{
						name: "Superuser",
						id: 3,
					},
					{
						name: "Administrator",
						id: 4,
					},
					{
						name: "Teacher",
						id: 7,
					},
					{
						name: "District Manager",
						id: 8,
					},
					{
						name: "PaperLive Editor",
						id: 12,
					},
					{
						name: "PaperClips Editor",
						id: 13,
					},
					{
						name: "ScheduleBuilder Superuser",
						id: 15,
					},
				],
			});
		},

		getRolesForScheduleBuilderSuperuser({ commit }) {
			commit("SET_ROLES", {
				roles: [
					{
						name: "Student",
						id: 1,
					},
					{
						name: "Superuser",
						id: 3,
					},
					{
						name: "Administrator",
						id: 4,
					},
					{
						name: "Teacher",
						id: 7,
					},
					{
						name: "District Manager",
						id: 8,
					},
					{
						name: "PaperLive Editor",
						id: 12,
					},
					{
						name: "PaperClips Editor",
						id: 13,
					},
					{
						name: "ScheduleBuilder Creator",
						id: 14,
					},
				],
			});
		},

		getRolesForSuperuserCreatorAndScheduleBuilderSuperuser({ commit }) {
			commit("SET_ROLES", {
				roles: [
					{
						name: "Student",
						id: 1,
					},
					{
						name: "Superuser",
						id: 3,
					},
					{
						name: "Administrator",
						id: 4,
					},
					{
						name: "Teacher",
						id: 7,
					},
					{
						name: "District Manager",
						id: 8,
					},
					{
						name: "PaperLive Editor",
						id: 12,
					},
					{
						name: "PaperClips Editor",
						id: 13,
					},
					{
						name: "ScheduleBuilder Creator",
						id: 14,
					},
					{
						name: "ScheduleBuilder Superuser",
						id: 15,
					},
				],
			});
		},



		async getGrades({ commit }) {
			const response = await GradesAPI.list();
			commit("SET_GRADES", {
				grades: response.data.data,
			});
		},

		async getRegions({ commit }, country) {
			let regions = [];

			regions = CountryTransformer.listRegions(CountryTransformer.encodeCountry(country));

			commit("SET_REGIONS", {
				regions,
			});
		},

		async sendWelcomeEmail({ commit, state }, payload) {
			await PlatformEmailsAPI.userWelcomeEmail(state.user.id, payload).then(
				function() {
					commit("SET_WELCOME_ERRORS", {
						error: false,
						success: true,
					});
				},
				function() {
					commit("SET_WELCOME_ERRORS", {
						error: true,
						success: false,
					});
				},
			);
		},

		async setIsBanned({ commit, state }, payload) {
			try {
				await StudentsAPI.update(state.user.id, payload);
				commit("SET_IS_BANNED", {
					isBanned: payload.is_banned,
				});
				return Promise.resolve();
			} catch (error) {
				return Promise.reject(error);
			}
		},

		setSaved({ commit }, itemSaved) {
			commit("SET_SAVED", {
				[itemSaved]: true,
			});
		},

		resetErrors({ commit }, payload) {
			commit("SET_ERRORS", {
				[payload]: false,
				errorMessage: "",
			});
		},

		setErrors({ commit }, payload) {
			commit("SET_ERRORS", {
				[payload.itemError]: true,
				["errorMessage"]: payload.errorMessage,
			});
		},

		setPasswordErrors({ commit }, payload) {
			commit("SET_PASSWORD_ERRORS", {
				error: payload.error,
				success: payload.success,
			});
		},

		setWelcomeErrors({ commit }, payload) {
			commit("SET_WELCOME_ERRORS", {
				error: payload.error,
				success: payload.success,
			});
		},
	},
};
