<template>
	<div>
		<TutorDashboardAvailabilitiesInfo />
		<TutorDashboardAvailabilitiesCalendar v-jest="'availabilities-calendar'" />
		<TutorDashboardAvailabilitiesFooter v-jest="'availabilities-footer'" />

		<OrigamiDialog
			v-jest="'cancel-edition-dialog'"
			title="Cancel changes to your availability?"
			description="Are you sure you want to cancel? All updates will be lost, and your availability will default back to your original selection."
			secondary-button-text="Go Back"
			primary-button-text="Confirm"
			is-destructive
			:is-open="Availabilities.isPromptingCancelWarning"
			@confirm="Availabilities.cancelConfirmedCallback"
			@cancel="$store.dispatch('Availabilities/closeCancelAvailabilitiesEdition')"
		/>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { OrigamiDialog } from "@origami/vue2";

import TutorDashboardAvailabilitiesInfo from "./TutorDashboardAvailabilitiesInfo.vue";
import TutorDashboardAvailabilitiesFooter from "./TutorDashboardAvailabilitiesFooter.vue";

export default {
	components: {
		TutorDashboardAvailabilitiesInfo,
		TutorDashboardAvailabilitiesFooter,
		OrigamiDialog,
		TutorDashboardAvailabilitiesCalendar: () => import("./TutorDashboardAvailabilitiesCalendar.vue"),
	},
	computed: mapState(["Availabilities"]),
};
</script>
