var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 26 26",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            fill: "none",
            "fill-rule": "evenodd",
            transform: "translate(1 1)",
          },
        },
        [
          _c("circle", {
            attrs: {
              cx: "12",
              cy: "12",
              r: "12",
              fill: "#FFD879",
              stroke: "#E2C172",
            },
          }),
          _vm._v(" "),
          _c("circle", {
            attrs: { cx: "7.622", cy: "9.243", r: "2.108", fill: "#3C5396" },
          }),
          _vm._v(" "),
          _c("circle", {
            attrs: { cx: "16.054", cy: "9.243", r: "2.108", fill: "#3C5396" },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              stroke: "#3C5396",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "1.5",
              d: "M16.77 17.885s-1.424-2.642-4.818-2.642c-3.394 0-4.817 2.642-4.817 2.642",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }