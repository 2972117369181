import api from "./Api.js";

export default {
	/**
	 * Gets a student's sections
	 *
	 * @returns mixed
	 */
	getUserClasses(userId) {
		return api().get(`users/${userId}/sections`);
	},

	/**
	 * update a student's information
	 *
	 * @param {number} userId
	 * @param {object} data
	 *
	 * @returns mixed
	 */
	update(userId, data) {
		return api().post(`students/${userId}`, data);
	},

	/**
	 * get students by district ID
	 *
	 * @param {number} districtId
	 * @param {object} params
	 *
	 * @return mixed
	 *
	 */
	getStudentsByDistrict(districtId, params) {
		let url = `
			districts/${districtId}/students?
			sort=${params.sort}
			&orderBy=${params.key}
			&expired=${params.expired}
			&page=${params.page}
		`;

		if (params.hasOwnProperty("searchTerm")) {
			url += `&search=${params.searchTerm}`;
		}

		return api().get(url);
	},

	/**
	 * search for User by DistrictId
	 *
	 * @param {number} schoolId
	 * @param {object} params
	 *
	 */
	getStudentsBySchool(schoolId, params) {
		let url = `
			schools/${schoolId}/students?
			sort=${params.sort}
			&orderBy=${params.key}
			&expired=${params.expired}
			&page=${params.page}
		`;

		if (params.hasOwnProperty("searchTerm")) {
			url += `&search=${params.searchTerm}`;
		}

		return api().get(url);
	},

	/**
	 *
	 * @param {number} schoolId
	 */
	getStudentsAggregateForSchool(schoolId) {
		return api().get(`schools/${schoolId}/students-aggregate`);
	},

	/**
	 *
	 * @param {Number} districtId
	 * @returns {Promise}
	 */
	getStudentsAggregateForDistrict(districtId) {
		return api().get(`districts/${districtId}/students-aggregate`);
	},
	/**
	 * Fetch a curated list of subjects for a logged in user based on their courses/district/etc.
	 *
	 * @arg {Object} params
	 * @arg {Number} params.limit
	 * @returns {AxiosPromise}
	 */
	getSubjects(params) {
		return api().get("student/subjects", {
			params,
		});
	},
	/**
	 * Fetch profile-specific data for logged in user
	 *
	 * @arg {Object} params
	 * @returns {AxiosPromise}
	 */
	getProfile(params) {
		return api().get("students/profile", {
			params,
		});
	},
	/**
	 *
	 * @returns {AxiosPromise}
	 */
	getLearningStyles() {
		return api().get("learning-styles");
	},

	async sendDashboardLandedEvent(payload) {
		return api().post("/events/student-dashboard-landed", payload);
	},

	async sendReviewCenterLandedEvent(payload) {
		return api().post("/events/review-center-landed", payload);
	},

	studentPaperMathModalOpen(payload) {
		return api().post("student-math-engagement-modal", payload);
	},

	studentPaperMathDemoComplete(payload) {
		return api().post("paper-math-interacted", payload);
	},
};
