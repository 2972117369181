import { logout } from "@/services/root.js";
import { hasRole } from "@/utilities/user.js";

export default function(_role) {
	return async function(to, from, next) {
		const currentUser = this.state.currentUser;

		if (hasRole(currentUser, _role)) {
			currentUser.role = _role;
			this.commit("SET_CURRENT_USER", { currentUser });
			return next();
		} else {
			await logout();
		}
	};
}
