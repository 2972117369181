<!--
	Requires the following data :
	1) An integer notification count
-->
<template>
	<div
		v-if="notificationCount !== 0"
		class="redPill"
		:class="{ mobilePill: isMobile }"
	>
		<p
			class="text"
			:class="{ mobileText: isMobile }"
		>
			{{ numberToDisplay }}
		</p>
	</div>
</template>

<script>
export default {
	props: {
		notificationCount: {
			type: Number,
			default: 0,
		},

		isMobile: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		/**
		 * Computes the number that should be displayed within the bubble.
		 * Any value greater than 9 says +9 instead of the actual count.
		 *
		 * @return mixed
		 */
		numberToDisplay: function() {
			if (this.notificationCount <= 9) {
				return this.notificationCount;
			} else {
				return "+9";
			}
		},
	},
};
</script>

<style scoped>
.redPill {
	border-radius: 9px;
	background-color: var(--c-orange);
	width: 22px;
	height: 16px;
	margin-left: auto;
	margin-bottom: -15px;
}

.mobilePill {
	border-radius: 10px;
	width: 27px;
	height: 20px;
	margin-bottom: 0;
	margin-left: 8px;
}

.text {
	font-size: 10px;
	font-weight: bold;
	text-align: center;
	color: var(--c-white);
	margin-top: -1px;
	padding-top: 3px;
	line-height: 10px;
}

.mobileText {
	font-size: 12px;
	line-height: 16px;
}
</style>
