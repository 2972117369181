import { differenceInSeconds, format, endOfDay, isAfter, subDays, startOfDay, getTime, parseISO } from "date-fns";
import { enUS, fr, es } from "date-fns/locale";

/**
 * @returns {String} rough beginning of user history on platform
 */
const BEGINNING_OF_TIME = "08/20/2015";

const DAY_CODES = {
	SUNDAY: 0,
	MONDAY: 1,
	TUESDAY: 2,
	WEDNESDAY: 3,
	THURSDAY: 4,
	FRIDAY: 5,
	SATURDAY: 6,
};

/**
 * @returns {String}
 */
const currentTimeZone = () => {
	return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

/**
 * @returns {Number}
 */
const currentYear = () => {
	return new Date().getFullYear();
};

/**
 * @returns {Number}
 */
const defaultEndDate = () => {
	return formatDateToTimestamp(endOfDay(new Date()));
};

/**
 * @returns {Number}
 */
const previousAugustYear = () => {
	const date = new Date(currentYear() - 1, 7, 1);
	return formatDateToTimestamp(date);
};

/**
 * @returns {Number}
 */
const currentAugustYear = () => {
	const date = new Date(currentYear(), 7, 1);
	return formatDateToTimestamp(date);
};

/**
 * @returns {Number}
 */
const getStartOfSchoolYear = () => {
	return isAfter(currentAugustYear(), defaultEndDate())
		? previousAugustYear()
		: currentAugustYear();
};

/**
 * @param {Date} date
 * @returns {Number}
 */
const formatDateToTimestamp = (date) => {
	return Math.floor(getTime(date) / 1000);
};
const formatDateToMillisecondTimestamp = (date) => {
	return Math.floor(getTime(date));
};
/**
 * 
 * @param {Date} date 
 * @param {String} formatString
 * @param {String} language 
 * @param {Object} options 
 * @returns 
 */
const formatDateTranslation = (date, formatString, language, options = {}) => {
	const dateFnsLanguagesModules = {
		en: enUS,
		fr,
		es,
	};
	return format(date,
		formatString,
		{
			locale: dateFnsLanguagesModules[language],
			...options,
		},
	);
};
/**
 * @param {Number} timestamp
 * @returns {Date}
 */
const formatTimestampToDate = (timestamp) => {
	return new Date(timestamp * 1000);
};
/**
 * @param {Number} timestamp
 * @returns {Date}
 */
const formatSecondsTimestampToDate = (timestamp) => {
	return new Date(timestamp * 1000);
};

/**
 * @returns {Number}
 */
const getTimestampFromWeekAgo = (startDate) => {
	const dateFromAWeekAgo = subDays(startOfDay(startDate), 7);
	return formatDateToTimestamp(dateFromAWeekAgo);
};

const formatDateToTimestampYMD = (timestamp) => {
	return timestamp ? format(new Date(timestamp * 1000), "yyyy-MM-dd") : "";
};

const formatTimestampToHM = (time) => {
	return time ? format(formatTimestampToDate(time), "h:mm a") : "";
};

const formatDateToHM = (time) => {
	return time ? format(time, "h:mmaaa") : "";
};

const formatIsoDateToHM = (date) => {
	return date ? formatDateToHM(parseISO(date)) : "";
};

/**
 * @param {String} date
 * @returns {Number}
 */
const formatIsoDateToTimestampEndOfDay = (date) => {
	const endDate = endOfDay(parseISO(date));
	return formatDateToTimestamp(endDate);
};

/**
 * @returns {Number} rough beginning of user history on platform as unix
 */
const beginningOfTimeTimeStamp = () => {
	return formatDateToTimestamp(new Date(BEGINNING_OF_TIME));
};

/**
 * @returns {Number} the number of minutes between 2 dates rounded up
 */
const getMinutesBetweenDates = (laterDate, earlierDate) => {
	return Math.ceil(
		differenceInSeconds(laterDate, earlierDate) / 60,
	);
};

export {
	currentTimeZone,
	getStartOfSchoolYear,
	formatDateToTimestamp,
	formatDateToMillisecondTimestamp,
	formatSecondsTimestampToDate,
	defaultEndDate,
	getTimestampFromWeekAgo,
	formatTimestampToDate,
	formatDateToTimestampYMD,
	formatDateToHM,
	formatIsoDateToHM,
	formatDateTranslation,
	formatIsoDateToTimestampEndOfDay,
	formatTimestampToHM,
	BEGINNING_OF_TIME,
	beginningOfTimeTimeStamp,
	DAY_CODES,
	getMinutesBetweenDates,
};
