<template>
	<BModal
		id="serviceDashboardFailedTransferModalId"
		ref="superuser-failed-transfer-session-modal"
		content-class="failedTransferSessionModal"
		body-class="failedTransferSessionModal__body"
		footer-class="failedTransferSessionModal__footer"
		:hide-header="true"
		:no-close-on-backdrop="true"
		:no-fade="true"
		:centered="true"
	>
		<button
			type="button"
			class="failedTransferSessionModal__closeButton"
			@click="hideModal()"
		>
			&times;
		</button>
		<div class="failedTransferSessionModal__header">
			<div class="failedTransferSessionModal__icon">
				<IconWarning class="failedTransferSessionModal__svg" />
			</div>
			<div class="failedTransferSessionModal__title">
				Seems like an error has occured
			</div>
		</div>
		<div class="failedTransferSessionModal__content">
			<div class="failedransferSessionModal__text">
				The session failed to transfer. Please try again.
			</div>
		</div>
		<template slot="modal-footer">
			<div class="failedTransferSessionModal__footer--displayFlex">
				<BaseButton
					type="SUBMIT"
					@click.prevent.native="hideModal()"
				>
					<template slot="text">
						Okay
					</template>
				</BaseButton>
			</div>
		</template>
	</BModal>
</template>
<script>
import BaseButton from "@/components/BaseButton.vue";

import IconWarning from "../../../components/icons/IconWarning.vue";

export default {
	components: {
		IconWarning,
		BaseButton,
	},
	props: {
		// eslint-disable-next-line vue/require-default-prop
		failedTransferModalId: {
			type: String,
		},
	},
	methods: {
		/**
		 * Hides the bootstrap vue modal.
		 */
		hideModal() {
			this.$bvModal.hide(`serviceDashboardFailedTransferModalId`);
		},
	},
};
</script>
<style>
.failedTransferSessionModal {
	border-radius: 10px;
	box-shadow: 0 20px 20px 0 rgba(60, 83, 150, 0.5);
	border: none;
}
.failedTransferSessionModal__body {
	padding: 48px 48px 24px 48px;
}
.failedTransferSessionModal__content {
	margin-top: 20px;
}
.failedTransferSessionModal__header {
	justify-content: flex-start;
	display: flex;
	align-items: center;
	border: none;
}
.failedTransferSessionModal__title {
	width: 400px;
	height: 27px;
	font-family: "Open Sans", sans-serif;
	font-size: 20px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: var(--c-blue);
}
.failedTransferSessionModal__closeButton {
	background-color: transparent;
	border: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	float: right;
	font-size: 2rem;
	line-height: 1;
	color: var(--c-black);
	text-shadow: 0 1px 0 #fff;
	margin: -40px;
}
.failedTransferSessionModal__footer {
	background: var(--c-light-gray);
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	border: none;
	padding: 21px 48px;
}
.failedTransferSessionModal__footer--displayFlex {
	background-color: var(--c-light-gray);
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}
.failedTransferSessionModal__icon {
	display: flex;
	justify-content: center;
	width: 44px;
	min-width: 44px;
	height: 44px;
	border-radius: 44px;
	background-color: var(--c-orange);
	margin-right: 16px;
}
.failedTransferSessionModal__svg {
	color: var(--c-white);
	margin-top: 10px;
}
</style>
