var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "IconButtonState",
    {
      attrs: { icon: _vm.studentsIcon, "button-text": "Create group" },
      on: {
        "button-clicked": function ($event) {
          return _vm.$router.push(
            `/${_vm.currentUser.role}/student-groups/create`
          )
        },
      },
    },
    [_vm._v("\n\tNo groups created\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }