export default [
	{
		path: "email-communications",
		component: () => import("../pages/index.vue"),
		children: [
			{
				name: "superuserCommunicationsList",
				path: "",
				component: () => import("../pages/list.vue"),
			},
			{
				path: "create",
				name: "superuserCommunicationsCreate",
				component: () => import("../pages/create.vue"),
			},
			{
				path: "edit/:communicationId",
				name: "superuserCommunicationsEdit",
				component: () => import("../pages/_communicationId.vue"),
			},
			{
				path: "render/:renderId",
				name: "superuserCommunicationsRender",
				component: () => import("../pages/_renderId.vue"),
			},
		],
	},
];
