<script>
import { mapState } from "vuex";
import { isEmpty, isNumber, omitBy } from "lodash";

import FilterContainerAbstract from "@/components/FilterContainerAbstract.vue";
// Mixins
import QueryStringMixin from "@/mixins/QueryStringMixin.js";

const isFilterDefined = (item) => !(!isEmpty(item) || isNumber(item));

export default {
	extends: FilterContainerAbstract,
	mixins: [QueryStringMixin],
	data() {
		return {
			filtersOnLoad: {},
		};
	},
	computed: {
		...mapState(["PlatformManager"]),
		/**
    	 * @returns {Boolean}
    	 */
		isSubmitDisabled() {
			return this.PlatformManager.TutorsList.isLoadingTutors;
		},
		/**
    	 * @returns {String}
    	 */
		searchBarPlaceholder() {
			return "Search by name or tutor ID";
		},
		/**
    	 * Get the searchtext from the store and sets it by committing it to the store.
    	 * @returns {String}
    	 */
		searchText: {
			get() {
				return this.PlatformManager.TutorsList.searchText;
			},
			set(value) {
				this.$store.commit("PlatformManager/TutorsList/SET_SEARCH_TEXT", {
					searchText: value,
				});
			},
		},
		/**
    	 * Gets notScheduledOn value from store and sets it by committing it to the store.
    	 * @returns {String}
    	 */
		notScheduledOn: {
			get() {
				return this.PlatformManager.TutorsList.notScheduledOn;
			},
			set(value) {
				this.$store.commit(
					"PlatformManager/TutorsList/SET_NOT_SCHEDULED_ON",
					{
						notScheduledOn: value,
					},
				);
			},
		},
		/**
    	 * Gets selected roles from store, sets newly selected roles in the store
    	 * @returns {Array}
    	 */
		roles: {
			get() {
				return this.PlatformManager.TutorsList.tutorFilters.roles
					.selected;
			},
			set(value) {
				try {
					this.$store.commit(
						"PlatformManager/TutorsList/SET_FILTER_IN_FILTERS_KEY_VALUE",
						{
							filter: "roles",
							key: "selected",
							value: value,
						},
					);
				} catch (e) {}
			},
		},
		/**
    	 * Gets selected status from store, sets newly selected status in the store
    	 * @returns {Array}
    	 */
		status: {
			get() {
				return this.PlatformManager.TutorsList.tutorFilters.status
					.selected;
			},
			set(value) {
				try {
					this.$store.commit(
						"PlatformManager/TutorsList/SET_FILTER_IN_FILTERS_KEY_VALUE",
						{
							filter: "status",
							key: "selected",
							value: value,
						},
					);
				} catch (e) {}
			},
		},
		/**
     	 * Gets selected topics from store, sets newly selected topics in the store
    	 * @returns {Array}
     	 */
		topics: {
			get() {
				return this.PlatformManager.TutorsList.tutorFilters.topics
					.selected;
			},
			set(value) {
				try {
					this.$store.commit(
						"PlatformManager/TutorsList/SET_FILTER_IN_FILTERS_KEY_VALUE",
						{
							filter: "topics",
							key: "selected",
							value: value,
						},
					);
				} catch (e) {}
			},
		},
		/**
    	 * Gets selected essay languages from store, sets newly selected essay languages in the store
    	 * @returns {Array}
    	 */
		essayLanguage: {
			get() {
				return this.PlatformManager.TutorsList.tutorFilters.essayLanguage
					.selected;
			},
			set(value) {
				try {
					this.$store.commit(
						"PlatformManager/TutorsList/SET_FILTER_IN_FILTERS_KEY_VALUE",
						{
							filter: "essayLanguage",
							key: "selected",
							value: value,
						},
					);
				} catch (e) {}
			},
		},
		/**
    	 * Gets selected session languages from store, sets newly selected session languages in the store
    	 * @returns {Array}
    	 */
		sessionLanguage: {
			get() {
				return this.PlatformManager.TutorsList.tutorFilters
					.sessionLanguage.selected;
			},
			set(value) {
				try {
					this.$store.commit(
						"PlatformManager/TutorsList/SET_FILTER_IN_FILTERS_KEY_VALUE",
						{
							filter: "sessionLanguage",
							key: "selected",
							value: value,
						},
					);
				} catch (e) {}
			},
		},
		/**
		 * @returns {Object}
		 */
		filterOptions() {
			return {
				roles: this.PlatformManager.TutorsList.tutorFilters.roles.options,
				topics: this.PlatformManager.TutorsList.tutorFilters.topics.options,
				status: this.PlatformManager.TutorsList.tutorFilters.status.options,
				essayLanguage: this.PlatformManager.TutorsList.tutorFilters.essayLanguage.options,
				sessionLanguage: this.PlatformManager.TutorsList.tutorFilters.sessionLanguage.options,
			};
		},
	},
	mounted() {
		const params = this.$route.query;
		this.setSelectedFilters(params);
		this.filtersOnLoad = this.$store.getters["PlatformManager/TutorsList/filtersAsParamsObject"];
	},
	methods: {
		/**
    	 * Checks if filter at filterkey is selected.
    	 * @param {String} filterKey
    	 * @returns {Boolean}
    	 */
		checkIsFilterSelected(filterKey) {
			return !(
				this.filterSelectedLength(filterKey) === 0 || this[filterKey] === null
			);
		},
		/**
    	 * Returns the length selected of the filter.
    	 * @param {String} filterKey
    	 * @returns {Number}
    	 */
		filterSelectedLength(filterKey) {
			return this[filterKey].length;
		},
		/**
    	 * @param {Object} filter
    	 * @returns {String}
    	 */
		computeFilterText(filter) {
			if (this.checkIsFilterSelected(filter.value)) {
				return `${filter.title} +${this.filterSelectedLength(filter.value)}`;
			}
			return filter.title;
		},
		/**
    	 * @param {String} filterKey
    	 */
		deselectAll(filterKey) {
			this[filterKey] = [];
		},
		/**
    	 * Dispatches to store
    	 */
		submitFilters() {
			const params = this.$store.getters["PlatformManager/TutorsList/filtersAsParamsObject"];
			const urlParam = omitBy(params, isFilterDefined);
			try {
				this.$router.replace({ path: this.$route.path, query: urlParam });
				this.$store.commit("PlatformManager/TutorsList/SET_CURRENT_PAGE", { currentPage: 1 });
				this.$store.dispatch("PlatformManager/TutorsList/getTutorAvailabilityFilteredTutorData");
				this.filtersOnLoad = params;
			} catch (error) {
				Sentry.captureException(error);
				this.$bvModal.show(`tutorsListErrorModal`);
			}
		},
		/**
     	 * Commits all filters from the url to the store
     	 * @param {Object} params
    	 */
		setSelectedFilters(params) {
			Object.keys(params).forEach((key) => {
				if (
					!this.PlatformManager.TutorsList.tutorFilters.hasOwnProperty(
						key,
					)
				) {
					return;
				}
				if (!Array.isArray(params[key])) {
					params[key] = [params[key]];
				}
				this.$store.commit(
					"PlatformManager/TutorsList/SET_FILTER_IN_FILTERS_KEY_VALUE",
					{
						filter: key,
						key: "selected",
						value: params[key],
					},
				);
			});
		},
	},
};
</script>
