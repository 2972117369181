import middleware from "@/middleware/index.js";
import isGeorgeBrownSchool from "@/middleware/isGeorgeBrownSchool.js";
import hasStudentAcceptedAgreement from "@/middleware/hasStudentAcceptedAgreement.js";
import { doesStudentNeedOnboarding } from "@/modules/StudentOnboarding/index.js";

export default [
	{
		name: "studentSettings",
		path: "student/settings",
		component: () => import("../pages/settings.vue"),
		beforeEnter: middleware([
			hasStudentAcceptedAgreement,
			doesStudentNeedOnboarding,
			isGeorgeBrownSchool,
		]),
	},
];
