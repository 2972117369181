<script setup>
import { OrigamiButton } from "@origami/vue2";
import { reactive, computed, onMounted } from "vue";

import useStore from "@/composables/useStore.js";
import Drilldown from "@/components/patterns/Drilldown.vue";

import { REQUIREMENTS_FILTERS } from "../utilities/ConstantValues.js";
import { useLoading } from "../composables/loading.js";

import ServiceAnalyticsFiltersDropdown from "./ServiceAnalyticsFiltersDropdown.vue";

const store = useStore();
const isLoading = useLoading();

const state = reactive({
	filters: [REQUIREMENTS_FILTERS],
});

const isFiltersSidebarOpen = computed(() => store.state.ServiceAnalytics.isFiltersSidebarOpen);

onMounted(async() => {
	try {
		await store.dispatch("ServiceAnalytics/getSubjects");
		state.filters.push({
			name: "Subjects",
			filterChoices: store.state.ServiceAnalytics.subjects,
		});
	} catch (e) {
		Sentry.captureException(e);
	}
});

const submitResults = async() => {
	document.body.classList.remove("disable-scroll");
	store.dispatch("ServiceAnalytics/submitFilters");
};

const clearFilters = async() => {
	store.dispatch("ServiceAnalytics/clearSelectedFilters");
};
</script>

<template>
	<Drilldown
		v-jest="'service-analytics-filters-sidebar'"
		:data-cy="'service-analytics-filters-sidebar'"
		title="Filters"
		:is-drilldown-open="isFiltersSidebarOpen"
		@close-drilldown="store.dispatch('ServiceAnalytics/closeFiltersSidebar')"
	>
		<div class="tw-px-6 tw-pt-8">
			<section
				v-for="filter in state.filters"
				:key="filter.name"
				class="first:tw-pt-0 tw-pt-12"
			>
				<ServiceAnalyticsFiltersDropdown
					v-jest="'service-analytics-filters-dropdown'"
					:filter="filter"
				/>
			</section>
			<section class="tw-flex tw-justify-between tw-pt-12">
				<OrigamiButton
					v-jest="'service-analytics-clear-filters-button'"
					:data-cy="'service-analytics-clear-filters-button'"
					variant="tertiary"
					aria-label="Clear filters"
					:is-disabled="isLoading"
					@click.native="clearFilters"
				>
					Clear Filters
				</OrigamiButton>
				<OrigamiButton
					v-jest="'service-analytics-show-results-button'"
					:data-cy="'service-analytics-show-results-button'"
					variant="primary"
					aria-label="Submit Results"
					:is-disabled="isLoading"
					@click.native="submitResults"
				>
					Show Results
				</OrigamiButton>
			</section>
		</div>
	</Drilldown>
</template>
