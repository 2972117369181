import SubjectsAPI from "@/services/api/Subjects.js";

import CoursesAPI from "../../services/courses.js";
import DistrictsAPI from "../../services/districts.js";
import SectionsAPI from "../../services/sections.js";

const COURSE_INCLUDE =
	"schools.sections,schools.sections.teachers,schools.sections.topics,schools.sections.students";

const getDefaultState = () => ({
	currentUser: null,
	isImpersonate: false,
	currentPage: 1,
	totalPages: 0,
	districts: [],
	subjects: [],
	selectedTopics: [],
	selectedSubjectIds: [],
	schools: [],
	classroom: [],
	sectionsTableHeaders: [
		{
			value: "id",
			header: "ID",
			orderable: false,
			state: null,
		},
		{
			value: "name",
			header: "class name",
			orderable: false,
			state: null,
		},
		{
			value: "display_name",
			header: "display name",
			orderable: false,
			state: null,
		},
		{
			value: "school",
			header: "school",
			orderable: false,
			state: null,
		},
		{
			value: "teacher",
			header: "Teacher",
			orderable: false,
			state: null,
		},
		{
			value: "status",
			header: "status",
			orderable: false,
			state: null,
		},
		{
			value: "active_from",
			header: "start date",
			orderable: false,
			state: null,
		},
		{
			value: "active_to",
			header: "end date",
			orderable: false,
			state: null,
		},
		{
			value: "students",
			header: "students",
			orderable: false,
			state: null,
		},
		{
			value: "topics",
			header: "topics",
			orderable: false,
			state: null,
		},
	],
	course: {
		id: 0,
		clever_id: null,
		district_id: 0,
		district: {
			data: {
				id: 0,
				country_code: "",
				created_at: 0,
				name: "",
				region_code: "",
				timezone: "",
				updated_at: 0,
			},
		},
		clever_name: null,
		name: "",
		is_hidden_for_students: false,
		last_synced_at: 0,
		topics: {
			data: [],
		},
		classrooms: {
			data: [],
		},
		is_clever_synced: false,
	},
	navbarMainTab: "Customers",
});

const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_DISTRICT(state, payload) {
		state.course.district.data = payload.district;
	},
	SET_DISTRICTS(state, payload) {
		state.districts = payload.districts;
	},
	SET_SCHOOLS(state, payload) {
		state.schools = payload.schools;
	},
	SET_SUBJECTS(state, payload) {
		state.subjects = payload.subjects;
	},
	SET_SELECTED_SUBJECTS(state, payload) {
		state.selectedSubjectIds = payload.selectedSubjectIds;
	},
	SET_SECTIONS(state, payload) {
		state.course.sections.data = payload.data;
	},
	SET_TOTAL_PAGES(state, payload) {
		state.totalPages = Math.floor(payload.totalPages / 100);
	},
	SET_CURRENT_PAGE(state, payload) {
		state.currentPage = payload.currentPage;
	},
	SET_SECTION(state, payload) {
		state.classroom = payload.classroom;
	},
};

const actions = {
	setCurrentPage({ commit }, payload) {
		commit("SET_CURRENT_PAGE", {
			currentPage: payload.newPage,
		});
	},
	updateCourse({ commit }, payload) {
		const newCourseClassrooms = [];
		payload.classrooms.forEach((classroom) => {
			if (classroom !== undefined) {
				newCourseClassrooms.push(classroom);
			}
		});
		commit("SET_SECTION", {
			classroom: newCourseClassrooms,
		});
	},
	async getDistricts({ commit }, payload) {
		try {
			const districts = await DistrictsAPI.list(payload);
			commit("SET_DISTRICTS", {
				districts: districts.data.data,
			});
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response);
		}
	},
	async getSingleDistrictClasses({ commit }, payload) {
		try {
			const params = {};
			params["include"] = COURSE_INCLUDE;
			const response = await DistrictsAPI.show(payload, params);
			commit("SET_DISTRICT", {
				district: response.data.data,
			});
		} catch (e) {
			Promise.resolve(e);
		}
	},
	async getSubjects({ commit }, payload) {
		try {
			const subjects = await SubjectsAPI.list(payload);
			commit("SET_SUBJECTS", {
				subjects: subjects.data.data,
			});
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response);
		}
	},
	async createCourse({ }, payload) {
		try {
			const response = await CoursesAPI.create(payload);
			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject(error.response);
		}
	},

	async getClassrooms({ }) {
		try {
			await SectionsAPI.filter();
		} catch (error) {
			Sentry.captureException(error);
		}
	},
};

export default {
	namespaced: true,
	state: getDefaultState(),
	actions,
	mutations,
};
