// api
import EssaysAPI from "../../../services/api/Essays.js";

export const getDefaultState = () => ({
	essayStatistics: {
		queued_essays: 0,
		in_progress_essays: 0,
		my_total_reviewed_essays: 0,
		active_reserved_essays: 0,
	},
});

export const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_ESSAY_STATISTICS(state, payload) {
		state.essayStatistics = payload.essayStatistics;
	},
};

export const actions = {
	async getEssayStatistics({ commit }) {
		try {
			const {
				data: {
					data,
				},
			} = await EssaysAPI.getEssayStatisticsForTutor();
			commit({
				type: "SET_ESSAY_STATISTICS",
				essayStatistics: data,
			});
		} catch (e) {
			Sentry.captureException(e);
		}
	},
};

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations,
	actions,
};
