<template>
	<component
		:is="getLanguageIcon(language)"
		:icon-color="iconColor"
		:width="width"
		:height="height"
	/>
</template>

<script>
// Utilities
import Colors from "../utilities/Colors.js";

// Components
import IconBubbleArabic from "./icons/IconBubbleArabic.vue";
import IconBubbleEnglish from "./icons/IconBubbleEnglish.vue";
import IconBubbleSpanish from "./icons/IconBubbleSpanish.vue";
import IconBubbleFrench from "./icons/IconBubbleFrench.vue";
import IconBubbleMandarin from "./icons/IconBubbleMandarin.vue";
import IconBubbleHaitian from "./icons/IconBubbleHaitian.vue";
const languages = {
	ar: "IconBubbleArabic",
	en: "IconBubbleEnglish",
	es: "IconBubbleSpanish",
	fr: "IconBubbleFrench",
	zh: "IconBubbleMandarin",
	ht: "IconBubbleHaitian",
};
export default {
	components: {
		IconBubbleArabic,
		IconBubbleEnglish,
		IconBubbleSpanish,
		IconBubbleFrench,
		IconBubbleMandarin,
		IconBubbleHaitian,
	},
	props: {
		language: {
			type: String,
			default: "en",
		},
		width: {
			require: false,
			type: [Number, String],
			default: 26,
		},
		height: {
			require: false,
			type: [Number, String],
			default: 20,
		},
		iconColor: {
			type: String,
			required: false,
			default: Colors.C_GREEN,
		},
	},
	methods: {
		getLanguageIcon(language) {
			return languages[language];
		},
	},
};
</script>
