<template>
	<div>
		<BaseDropdown
			v-data-cy="'break-time-blocks-dropdown'"
			:close-on-select="true"
			:items="timeBlocks"
			:show-search-bar="false"
			class="timeBlocks__dropdown"
			@select="selectTimeBlock"
		>
			<template slot="filters">
				{{ displaySelectedTimeBlock }}
			</template>
			<template #default="{ item, index }">
				<span
					v-cloak
					v-jest="'break-request-timeblocks'"
					class="tw-flex tw-justify-between tw-mx-2"
					:class="{ 'tw-mt-2': item.id === 0 }"
				>
					<span
						:key="`timeBlock-${index}`"
						class="tw-font-bold"
					>{{ setPrefix(item, index) }}</span> {{ formattedTime(item) }}
				</span>
			</template>
		</BaseDropdown>
	</div>
</template>

<script>
import { eachMinuteOfInterval, format, startOfDay, roundToNearestMinutes } from "date-fns";

import BaseDropdown from "@/components/BaseDropdown.vue";

export default {
	components: {
		BaseDropdown,
	},
	props: {
		maxStartTime: {
			type: Date,
			required: true,
		},
		minStartTime: {
			type: Date,
			required: true,
		},
	},
	data() {
		return {
			selectedTimeBlock: null,
		};
	},
	computed: {
		timeBlocks() {
			const FIFTEEN_MINUTES = 15;
			const FIFTEEN_MINUTES_IN_MS = 1000 * 60 * FIFTEEN_MINUTES;

			const startTime = new Date(Math.ceil(this.minStartTime.getTime() / FIFTEEN_MINUTES_IN_MS)
				* FIFTEEN_MINUTES_IN_MS);
			const endTime = roundToNearestMinutes(this.maxStartTime,
				{ nearestTo: FIFTEEN_MINUTES, roundingMethod: "floor" });

			if (startTime.getTime() === endTime.getTime()) {
				return [endTime];
			}

			return eachMinuteOfInterval({ start: startTime, end: endTime }, { step: FIFTEEN_MINUTES });
		},
		displaySelectedTimeBlock() {
			return this.selectedTimeBlock ? format(this.selectedTimeBlock, "MMM dd h:mm a") : "Select a time";
		},
	},
	methods: {
		selectTimeBlock(timeBlock) {
			this.selectedTimeBlock = timeBlock;
			this.$emit("select-time-block", timeBlock);
		},
		setPrefix(timeBlock, index) {
			if (index === 0 || this.formattedTime(timeBlock) === this.formattedTime(startOfDay(this.maxStartTime))) {
				return format(timeBlock, "MMM dd");
			}
		},
		formattedTime(timeBlock) {
			return format(timeBlock, "h:mm a");
		},
	},
};
</script>
