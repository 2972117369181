<template>
	<div class="section__divider tw-mb-14">
		<h3 class="tw-font-extrabold tw-text-black-high tw-text-2xl tw-mt-5">
			Scheduling Details
		</h3>
		<form
			v-jest="'submit'"
			class="tw-flex tw-flex-col"
			@submit.prevent="saveSchedulingDetails()"
		>
			<div class="tw-flex tw-w-full tw-my-7">
				<label class="tw-text-sm tw-pr-9">Allow Shift Transfers</label>
				<BaseRadio
					:class="isShiftTransferPermitted ? 'tw-font-bold' : ''"
					radio-id="shiftTransferAllowed-yes"
					radio-name="shiftTransferAllowed-yes"
					:radio-value="true"
					:value="isShiftTransferPermitted"
					@click.native="isShiftTransferPermitted = true"
				>
					<template slot="text">
						Yes
					</template>
				</BaseRadio>
				<BaseRadio
					class="baseRadio--boolean tw-mx-9"
					:class="!isShiftTransferPermitted ? 'tw-font-bold' : ''"
					radio-id="shiftTransferAllowed-no"
					radio-name="shiftTransferAllowed-no"
					:radio-value="false"
					:value="isShiftTransferPermitted"
					@click.native="isShiftTransferPermitted = false"
				>
					<template slot="text">
						No
					</template>
				</BaseRadio>
			</div>
			<ServiceTutorEditShiftBlocksIsEnabled
				ref="availabilityBlocks"
			/>
			<div class="tw-flex tw-flex-row tw-items-center">
				<BaseFormField
					id="maxWeeklyHours"
					v-model="maxHoursText"
					:is-disabled="true"
					type="text"
					name="quote"
					label="Maximum Hours"
					:placeholder="maxHoursText"
				/>
				<BaseButton
					v-jest="'resetMaxHoursButton'"
					:is-disabled="!isMaxHoursSet"
					class="tw-mt-2 tw-mx-4"
					type="CANCEL"
					data-loading-text="Submitting..."
					@click.prevent.native="resetMaxHours"
				>
					<template slot="text">
						Reset Max Hours
					</template>
				</BaseButton>
			</div>
			<div>
				<BaseButton
					class="tw-w-48 tw-float-right"
					type="SUBMIT"
					:disabled="isSubmitDisabled"
				>
					<template
						slot="text"
					>
						<LoadingRectangles
							v-if="isLoadingSchedulingDetails"
							class="tw-text-blue-regular"
						/>
						<span
							v-else
						>
							Submit Scheduling Details
						</span>
					</template>
				</BaseButton>
			</div>
		</form>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { isNil } from "lodash";

import LoadingRectangles from "@/components/LoadingRectangles.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseFormField from "@/components/BaseFormField.vue";
import BaseRadio from "@/components/BaseRadio.vue";

import ServiceTutorEditShiftBlocksIsEnabled from "./ServiceTutorEditShiftBlocksIsEnabled.vue";

export default {
	components: {
		ServiceTutorEditShiftBlocksIsEnabled,
		LoadingRectangles,
		BaseButton,
		BaseFormField,
		BaseRadio,
	},
	data() {
		return {
			maxHoursText: "",
			isMaxHoursSet: false,
			isShiftTransferPermitted: false,
			shiftBlocks: [],
			newAvailabilityDays: [],
			originalSchedulingDetails: {},
			isLoadingSchedulingDetails: false,
		};
	},
	computed: {
		...mapState(["PlatformManager"]),
		tutor() {
			return this.PlatformManager.TutorsEdit.tutor;
		},
		isSubmitDisabled() {
			const isDisabled = (
				this.isShiftTransferPermitted === this.originalSchedulingDetails.shift_transfer_allowed &&
				this.isShiftBlockArrayEmpty
			) || this.isLoadingSchedulingDetails;
			this.$emit("on-scheduling-submit", { isEnabled: !isDisabled });
			return isDisabled;
		},
		isShiftBlockArrayEmpty() {
			return this.$refs.availabilityBlocks.getModifiedShiftBlockLength() === 0;
		},
	},
	mounted() {
		this.originalSchedulingDetails = {
			shift_transfer_allowed: this.tutor.tutor.data.shift_transfer_allowed,
		};
		this.isShiftTransferPermitted = this.tutor.tutor.data.shift_transfer_allowed;
		if (
			!isNil(this.tutor) &&
			(this.tutor.tutor.data.max_weekly_hours === "" ||
				this.tutor.tutor.data.max_weekly_hours === null)
		) {
			this.maxHoursText = "0";
			this.isMaxHoursSet = false;
		} else {
			this.maxHoursText = this.tutor.tutor.data.max_weekly_hours;
			this.isMaxHoursSet = true;
		}
	},
	methods: {
		async resetMaxHours() {
			try {
				this.isMaxHoursSet = false;
				await this.$store.dispatch("PlatformManager/TutorsEdit/updateTutor", { max_weekly_hours: null });
				this.maxHoursText = "0";
			} catch (e) {
				this.isMaxHoursSet = true;
				Sentry.captureException(e);
			}
		},
		async saveSchedulingDetails() {
			try {
				this.isLoadingSchedulingDetails = true;
				await this.$store.dispatch("PlatformManager/TutorsEdit/updateTutor", {
					shift_transfer_allowed: this.isShiftTransferPermitted,
				});
				this.originalSchedulingDetails = {
					shift_transfer_allowed: this.isShiftTransferPermitted,
				};
				await this.$refs.availabilityBlocks.saveShiftBlocks();
			} catch (e) {
				Sentry.captureException(e);
			} finally {
				this.isLoadingSchedulingDetails = false;
			}

		},
	},
};
</script>

<style scoped>
.section__divider {
	border-top: 1px solid var(--black-high);
}
</style>
