<template>
	<div
		v-click-outside="closeDropdown"
		class="tw-relative"
	>
		<div
			:id="generateId('dropdown')"
			ref="languagePicker"
			v-jest="'language-picker'"
			:aria-controls="listBoxId"
			:aria-expanded="Boolean(isExpanded).toString()"
			aria-haspopup="listbox"
			role="combobox"
			tabindex="0"
			class="dropdown"
			:class="{ 'dropdown--active': isExpanded }"
			@keydown.enter="toggleDropdown"
			@keydown.esc="closeDropdown"
			@keydown.down.prevent="focusListItem(selectedItemIndex)"
			@click="toggleDropdown"
		>
			<span>
				<OrigamiIcon
					v-if="!store.state.Lang.isLoading"
					name="social-studies"
					:width="16"
					:height="16"
				/>
			</span>
			<label id="dropdown-label">
				{{ preferredLangTitle }}
			</label>
			<span>
				<OrigamiIcon
					v-jest="'dropdown-caret'"
					:name="isExpanded ? 'caret-active' : 'caret'"
					:width="12"
					:height="12"
				/>
			</span>
		</div>
		<ul
			v-show="isExpanded"
			:id="listBoxId"
			v-jest="'dropdown-menu'"
			class="dropdown__menu"
			role="listbox"
			aria-labelledby="dropdown-label"
		>
			<li
				v-for="(lang, index) in store.state.Lang.publicLanguages"
				:key="lang.id"
			>
				<button
					:id="`dropdown-menu-item-${index}`"
					ref="dropdownLink"
					v-jest="'language-option'"
					role="option"
					class="dropdown__menu-item"
					:class="{
						'dropdown__menu-item--selected':
							lang.abbreviation === preferredLang?.abbreviation,
					}"
					:aria-selected="
						lang.abbreviation === preferredLang?.abbreviation ? 'true' : 'false'
					"
					@keydown.up.prevent="focusListItem(index - 1)"
					@keydown.down.prevent="focusListItem(index + 1)"
					@keydown.home.prevent="focusListItem(0)"
					@keydown.enter="handleLanguageChange(lang)"
					@keydown.esc="focusDropdown"
					@keydown.tab.exact="handleDropdownTab(index)"
					@click="handleLanguageChange(lang)"
				>
					{{ lang.language }}
					<OrigamiIcon
						v-if="lang.abbreviation === preferredLang?.abbreviation"
						name="check"
						class="tw-text-origami-blue-300"
						:width="14"
					/>
				</button>
			</li>
		</ul>
	</div>
</template>

<script setup>
import { ref, computed } from "vue";
import { v4 as uuidv4 } from "uuid";
import { OrigamiIcon } from "@origami/vue2";

import useI18n from "@/composables/useI18n.js";
import useStore from "@/composables/useStore.js";

const emit = defineEmits(["select-language"]);
const store = useStore();
const i18n = useI18n();

const isExpanded = ref(false);
const dropdownLink = ref(null);
const languagePicker = ref(null);

const preferredLang = computed(() => {
	return store.state.Lang.preferredLanguage;
});
const preferredLangTitle = computed(() => {
	return preferredLang.value?.language || i18n.t("loading");
});
const listBoxId = computed(() => {
	return generateId("list-box");
});

const selectedItemIndex = computed(() => {
	return store.state.Lang.publicLanguages.findIndex(
		(lang) => lang === preferredLang.value,
	);
});

const generateId = (element) => {
	return `${element}-${uuidv4()}`;
};
const toggleDropdown = () => {
	isExpanded.value = !isExpanded.value;
};
const closeDropdown = () => {
	isExpanded.value = false;
};
const handleLanguageChange = async(lang) => {
	if (lang === preferredLang.value) {
		return false;
	}
	emit("select-language", lang);
	toggleDropdown();
};
const focusListItem = (index) => {
	isExpanded.value = true;

	if (dropdownLink.value?.length === index) {
		dropdownLink.value?.[0].focus();
	}
	if (index === -1) {
		dropdownLink.value?.[dropdownLink.value?.length - 1].focus();
	}
	dropdownLink.value?.[index]?.focus();
};
const handleDropdownTab = (index) => {
	if (index === dropdownLink.value?.length - 1) {
		closeDropdown();
	}
};
const focusDropdown = () => {
	closeDropdown();
	languagePicker.value.focus();
};

defineExpose({ isExpanded, toggleDropdown });
</script>

<style scoped>
.dropdown {
	@apply tw-flex tw-items-center tw-bg-origami-blue-400 tw-text-white-high tw-border-2 tw-border-origami-white tw-border-solid tw-py-2 tw-px-4 tw-text-origami-label tw-cursor-pointer;
	border-radius: 5px;
	max-width: 140px;
}
.dropdown:hover {
	background: #434b8b;
}
.dropdown--active {
	@apply tw-text-origami-blue-400 tw-bg-white-high;
}
.dropdown--active:hover {
	@apply tw-text-origami-black tw-bg-white-high;
}
.dropdown > label {
	@apply tw-mx-2 tw-mb-0 tw-whitespace-nowrap tw-overflow-hidden tw-overflow-ellipsis;
}
.dropdown > span {
	@apply tw-flex tw-justify-center;
}
ul.dropdown__menu {
	@apply tw-absolute tw-z-10 tw-w-full tw-text-origami-black tw-bg-white-high tw-list-none tw-h-auto tw-left-0 tw-rounded-lg tw-text-origami-label tw-cursor-pointer;
	margin-top: 4px;
	box-shadow: var(--origami-shadow-md);
}
button.dropdown__menu-item {
	@apply tw-px-4 tw-py-2 tw-flex tw-justify-between tw-w-full;
}
button.dropdown__menu-item:hover,
button.dropdown__menu-item:focus-visible {
	@apply tw-bg-origami-grey-100 tw-rounded-lg;
}
button.dropdown__menu-item--selected {
	@apply tw-font-bold;
}
</style>

