\<template>
	<aside>
		<slot name="buttonContent" />
		<div class="tw-z-10">
			<slot name="tooltipContent" />
		</div>
	</aside>
</template>

<script>
export default {};
</script>

<style scoped>
aside {
	position: relative;
}
aside > div {
	display: none;
	position: absolute;
	left: 100%;
	top: 0;
	margin-left: 0.5rem;
	background-color: var(--c-white);
	padding: 0.5rem 1rem;
	box-shadow: var(--box-shadow);
	white-space: nowrap;
}
aside:hover > div {
	display: block;
}
</style>
