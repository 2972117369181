import api from "./Api.js";

export default {
	/**
	 * creates a flag
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	create(data) {
		return api().post(`flags`, data);
	},
	/**
	 * gets all flags in database
	 * @param {Object} params
	 * @returns {AxiosPromise}
	 */
	list(params) {
		return api().get(`flags`, {
			params: params,
		});
	},
	/**
	 * gets all flags in database
	 * @param {Number} params
	 * @returns {AxiosPromise}
	 */
	delete(sessionFlagId) {
		return api().delete(`flags/${sessionFlagId}`);
	},
};
