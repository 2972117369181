var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "impersonationModal auth_modal",
      attrs: { id: "authorizeImpersonationModal" },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "h4",
              { staticClass: "modal-title", attrs: { id: "myModalLabel" } },
              [_vm._v("\n\t\t\t\t\tImpersonate user\n\t\t\t\t")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button", "aria-label": "Close" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.closeModal.apply(null, arguments)
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "svg",
                  attrs: { src: "/images/icon-times.svg" },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "form",
              {
                staticClass: "tw-mb-0",
                attrs: {
                  id: "authorizeImpersonationForm",
                  role: "form",
                  method: "POST",
                  action: "impersonate",
                },
              },
              [
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.impersonationEmail,
                        expression: "impersonationEmail",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "email",
                      name: "email",
                      tabindex: "1",
                      placeholder: "Email Address",
                    },
                    domProps: { value: _vm.impersonationEmail },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.impersonationEmail = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.impersonationPassword,
                        expression: "impersonationPassword",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "password",
                      name: "password",
                      tabindex: "2",
                      placeholder: "Password",
                    },
                    domProps: { value: _vm.impersonationPassword },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.impersonationPassword = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("input", {
                  staticClass:
                    "btn btn-success btn-block tw-bg-green-regular tw-border-green-regular",
                  class: { loadingState: _vm.loading },
                  staticStyle: { padding: "8px 40px" },
                  attrs: {
                    type: "submit",
                    name: "submit",
                    value: "Continue",
                    disabled: _vm.loading,
                    tabindex: "3",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.submitImpersonationForm.apply(null, arguments)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showErrors,
                        expression: "showErrors",
                      },
                    ],
                    attrs: { id: "authorizeImpersonationErrors" },
                  },
                  [
                    _c("div", { staticClass: "alert alert-danger" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t" +
                          _vm._s(_vm.errorMessage) +
                          "\n\t\t\t\t\t\t"
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }