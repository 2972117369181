<template>
	<div class="tw-pb-10">
		<h3 class="tw-my-10 tw-font-bold tw-text-xl tw-text-black-high">
			{{ title }}
		</h3>
		<div class="tw-flex tw-flex-row tw-flex-wrap tw-gap-x-4 tw-gap-y-4">
			<InsightCard
				v-for="(card, index) in cards"
				:key="`card-${index}`"
				v-jest="'insight-card'"
				:card="card"
				:action-items="isShowingOnlyOwnedActionItems ?
					$store.getters[`PlatformManager/ActionCenter/${getOwnedActionItemTypeState(card.id)}`] :
					PlatformManager.ActionCenter[getActionItemTypeState(card.id)]
				"
			>
				<template #content>
					<component
						:is="card.innerComponent"
						v-for="item in isShowingOnlyOwnedActionItems ?
							$store.getters[`PlatformManager/ActionCenter/${getOwnedActionItemTypeState(card.id)}`] :
							PlatformManager.ActionCenter[getActionItemTypeState(card.id)]
						"
						:key="`action-item-${item.id}`"
						v-jest="'action-item-component'"
						:action-item="item"
						@action-taken="handleAction"
					/>
				</template>
			</InsightCard>
		</div>
		<ActionCenterFalseAlarmActionItemDialog
			v-jest="'false-alarm-dialog'"
			:is-open="PlatformManager.ActionCenter.isShowingFalseAlarmDialog"
		/>
		<ActionCenterResolveActionItemDialog
			v-jest="'resolve-dialog'"
			:is-open="PlatformManager.ActionCenter.isShowingResolveDialog"
		/>
		<BaseToaster />
		<ActionCenterHideActionItemDialog />
	</div>
</template>

<script>
import { mapState } from "vuex";

import { InappropriateBehaviourEssayActionItem, InappropriateBehaviourClassroomActionItem } from "@/modules/InappropriateBehaviour/index.js";
import { LongEssayReviewActionItem } from "@/modules/LongEssayReviews/index.js";

import BaseToaster from "../../../components/elements/BaseToaster.vue";
import { checkIsObjectKeysPresentAndRightType } from "../../../utilities/propValidationHelpers.js";
import { ActionItemTypeMapping, OwnedActionItemTypeMapping } from "../utilities/actionCenterHelpers.js";

import ActionCenterFalseAlarmActionItemDialog from "./ActionCenterFalseAlarmActionItemDialog.vue";
import ActionCenterResolveActionItemDialog from "./ActionCenterResolveActionItemDialog.vue";
import ActionCenterHideActionItemDialog from "./ActionCenterHideActionItemDialog.vue";
import InsightCard from "./InsightCard.vue";

const requiredKeys = [
	{ property: "title", type: ["string"] },
	{ property: "description", type: ["string"] },
	{ property: "number", type: ["number"] },
	{ property: "innerComponent", type: ["string"] },
];

export default {
	name: "ActionCenterWrapper",
	components: {
		ActionCenterFalseAlarmActionItemDialog,
		ActionCenterResolveActionItemDialog,
		InsightCard,
		InappropriateBehaviourClassroomActionItem,
		InappropriateBehaviourEssayActionItem,
		LongEssayReviewActionItem,
		BaseToaster,
		ActionCenterHideActionItemDialog,
	},
	props: {
		cards: {
			type: Array,
			required: true,
			validator: (cards) => cards.every((card) => checkIsObjectKeysPresentAndRightType(card, requiredKeys)),
		},
		title: {
			type: String,
			required: true,
		},
	},
	computed: {
		...mapState(["PlatformManager"]),
		isShowingOnlyOwnedActionItems() {
			return this.PlatformManager.ActionCenter.isShowingOnlyOwnedActionItems;
		},
	},
	methods: {
		getActionItemTypeState(item) {
			return ActionItemTypeMapping[item];
		},
		getOwnedActionItemTypeState(item) {
			return OwnedActionItemTypeMapping[item];
		},
		handleAction({ eventHandler, payload }) {
			eventHandler(payload);
		},
	},
};
</script>
