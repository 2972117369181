<template>
	<ServiceDashboardSummaryCard>
		<template #cardTitle>
			Live Help
		</template>

		<template
			v-if="isMissedStudentNumGreaterThanZero"
			#badges
		>
			<BaseLink
				:to="missedSessionActivityLink"
				class="summaryCard__badge summaryCard__badge--salmon summaryCard__badge--cursorPointer"
			>
				{{ missedStudentsNum }} Missed Students
			</BaseLink>
			<HelpTooltip :help-text="tooltipString" />
		</template>

		<template #leftTitle>
			Ongoing
		</template>

		<template #leftNumber>
			<span
				v-data-cy="'live-help-ongoing'"
				class="summaryCardContent__leftNumber"
				:class="{ loading: $store.getters['PlatformManager/ServiceSummaryDashboard/isLoadingSummary'] }"
			>
				{{ ongoingSessionsNum }}
			</span>
		</template>

		<template #rightTitle>
			<span id="liveHelpPeriodTitle">Total {{ totalString }}</span>
		</template>

		<template #rightNumber>
			<span
				v-data-cy="'live-help-total-today'"
				class="summaryCardContent__rightNumber"
				:class="{ loading: $store.getters['PlatformManager/ServiceSummaryDashboard/isLoadingSummary'] }"
			>
				{{ totalSessionsNum }}
			</span>
		</template>
	</ServiceDashboardSummaryCard>
</template>

<script>
import { mapState } from "vuex";

import HelpTooltip from "../../../components/TooltipHelp.vue";

import ServiceDashboardSummaryCard from "./ServiceDashboardSummaryCard.vue";

export default {
	components: { HelpTooltip, ServiceDashboardSummaryCard },
	computed: {
		...mapState(["currentUser", "PlatformManager"]),
		summary() {
			return this.PlatformManager.ServiceSummaryDashboard.summary;
		},
		summaryPeriod() {
			return this.PlatformManager.ServiceSummaryDashboard.summaryPeriod;
		},
		/**
		 * @returns {Number}
		 */
		missedStudentsNum() {
			return this.summary[this.summaryPeriod].missed_students;
		},
		/**
		 * @returns {Number}
		 */
		ongoingSessionsNum() {
			return this.summary.ongoing_sessions;
		},
		/**
		 * @returns {Number}
		 */
		totalSessionsNum() {
			return this.summary[this.summaryPeriod].total_sessions;
		},
		isSummaryPeriodToday() {
			return this.summaryPeriod === "today";
		},
		/**
		 * @returns {boolean}
		 */
		isMissedStudentNumGreaterThanZero() {
			return this.missedStudentsNum > 0;
		},
		tooltipString() {
			return this.isSummaryPeriodToday ? "Today so far (EST)" : "Data from the last 24h";
		},
		totalString() {
			return this.isSummaryPeriodToday ? "Today (EST)" : "Last 24h";
		},
		missedSessionActivityLink() {
			return `/${this.currentUser.role}/activity?sessionId=[${this.summary[this.summaryPeriod].missed_session_ids}]&tab=Sessions`;
		},
	},
};
</script>


<style scoped>
.summaryCard__badge:hover {
	text-decoration: none;
	cursor: pointer;
	opacity: 1;
	color: var(--c-white);
}
</style>
