<template>
	<BreaksNotification
		v-jest="'meal-break-in-progress-notification'"
		notification-type="info"
		class="tw-mb-4"
	>
		<p
			v-jest="'meal-break-in-progress-time'"
			class="tw-font-bold tw-text-sm"
		>
			{{ timeText }}
		</p>
	</BreaksNotification>
</template>

<script>
import { mapState } from "vuex";

import { getMinutesBetweenDates } from "@/utilities/dateHelpers.js";

import BreaksNotification from "../templates/BreaksNotification.vue";

export default {
	components: {
		BreaksNotification,
	},
	computed: {
		...mapState("BreaksManagementWidget", ["currentTime"]),
		timeText() {
			const breakInProgress = this.$store.getters["TutorSchedule/getCurrentMealBreak"];
			const minutesRemaining = getMinutesBetweenDates(new Date(breakInProgress.end_time), this.currentTime);
			return minutesRemaining > 1 ?
				`${minutesRemaining} mins left on your meal break` :
				"Less than 1 min left on your meal break";
		},
	},
};
</script>
