var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "p",
      {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "message-text",
            expression: "'message-text'",
          },
        ],
        staticClass: "tw-text-base tw-mt-4 tw-pl-6 tw-pr-0 tw-py-0",
      },
      [_vm._v("\n\t\t" + _vm._s(_vm.successMessage.message) + "\n\t")]
    ),
    _vm._v(" "),
    _vm.shift
      ? _c(
          "div",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "shift-details-container",
                expression: "'shift-details-container'",
              },
            ],
          },
          [
            _c(
              "p",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "shift-type-name",
                    expression: "'shift-type-name'",
                  },
                ],
                staticClass:
                  "tw-flex tw-text-center tw-w-max tw-ml-6 tw-my-4 tw-px-1 tw-py-0 tw-rounded-md tw-font-bold tw-text-sm",
                style: _vm.shiftScheduleTypeColor,
              },
              [_vm._v("\n\t\t\t" + _vm._s(_vm.shiftTypeName) + "\n\t\t")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "tw-pl-6 tw-pr-4 tw-py-0 tw-text-sm" }, [
              _c("p", { staticClass: "tw-pb-2 tw-pt-0 tw-px-0 tw-font-bold" }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.name) +
                    " [" +
                    _vm._s(_vm.shift.id) +
                    "]\n\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "shift-start-time",
                      expression: "'shift-start-time'",
                    },
                  ],
                  staticClass: "tw-pb-2 tw-pt-0 tw-px-0",
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.formattedShiftStartTime) +
                      "\n\t\t\t"
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "shift-end-time",
                      expression: "'shift-end-time'",
                    },
                  ],
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.formattedShiftEndTime) +
                      "\n\t\t\t"
                  ),
                ]
              ),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.updatedShift
      ? _c(
          "div",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "updated-shift-details-container",
                expression: "'updated-shift-details-container'",
              },
            ],
          },
          [
            _c(
              "p",
              { staticClass: "tw-flex tw-pt-4 tw-pb-2 tw-ml-6 tw-text-base" },
              [_vm._v("\n\t\t\tto:\n\t\t")]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "updated-shift-type-name",
                    expression: "'updated-shift-type-name'",
                  },
                ],
                staticClass:
                  "tw-flex tw-text-center tw-w-max tw-ml-6 tw-my-4 tw-px-1 tw-py-0 tw-rounded-md tw-font-bold tw-text-sm",
                style: _vm.updatedShiftScheduleTypeColor,
              },
              [_vm._v("\n\t\t\t" + _vm._s(_vm.updatedShiftTypeName) + "\n\t\t")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "tw-pl-6 tw-pr-4 tw-py-0 tw-text-sm" }, [
              _c("p", { staticClass: "tw-pb-2 tw-pt-0 tw-px-0 tw-font-bold" }, [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.name) +
                    " [" +
                    _vm._s(_vm.shift.id) +
                    "]\n\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "updated-shift-start-time",
                      expression: "'updated-shift-start-time'",
                    },
                  ],
                  staticClass: "tw-pb-2 tw-pt-0 tw-px-0",
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.formattedUpdatedShiftStartTime) +
                      "\n\t\t\t"
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "updated-shift-end-time",
                      expression: "'updated-shift-end-time'",
                    },
                  ],
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.formattedUpdatedShiftEndTime) +
                      "\n\t\t\t"
                  ),
                ]
              ),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tw-flex tw-pt-6 tw-pb-4 tw-px-6" },
      [
        _c(
          "OrigamiButton",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "return-btn",
                expression: "'return-btn'",
              },
              {
                name: "data-cy",
                rawName: "v-data-cy",
                value: "return-btn",
                expression: "'return-btn'",
              },
            ],
            staticClass: "tw-w-full tw-justify-center",
            attrs: { variant: "primary" },
            nativeOn: {
              click: function ($event) {
                return _vm.$emit("cancel-edit")
              },
            },
          },
          [
            _vm._v(
              "\n\t\t\t" + _vm._s(_vm.successMessage.buttonText) + "\n\t\t"
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }