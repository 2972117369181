var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 26 20",
      },
    },
    [
      _c("path", {
        attrs: {
          fill: _vm.iconColor,
          "fill-rule": "evenodd",
          d: "M22 0c2.21 0 4 1.79 4 4v7.845c0 2.21-1.79 4-4 4h-8l-6.474 4.007c-.158.098-.34.15-.526.15-.552 0-1-.448-1-1v-3.157H4c-2.21 0-4-1.79-4-4V4c0-2.21 1.79-4 4-4h18zm-9 2.97h-1v.991H8v.99h7.261c-.515.95-1.392 2.003-2.631 3.157-.599-.686-1.119-1.407-1.562-2.166L10 6.43c.5.859 1.093 1.67 1.776 2.438-.804.69-1.73 1.412-2.776 2.167l.892.849c1.019-.735 1.932-1.444 2.737-2.127.783.757 1.664 1.466 2.642 2.127l.729-.846c-.936-.633-1.775-1.31-2.517-2.032 1.607-1.472 2.69-2.82 3.247-4.054H18v-.99h-5V2.97z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }