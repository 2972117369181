export const EXTENSION_ID = process.env.MIX_CHROME_EXTENSION_ID;
export const EXTENSION_NEXT_ID = process.env.MIX_CHROME_EXTENSION_NEXT_ID;

export const EMBEDDED_BYPASS_KEY = "__EMBEDDED__";
export const CHROME_EXTENSION_EVENTS = {
	EXTENSION_INFO: "extension.info",
	PAGE_INFO: "page.info",
	SIDEBAR_HIDE: "sidebar.hide",
	SIDEBAR_TOGGLE: "sidebar.toggle",
};
export const CHROME_EXTENSION_ERRORS = {
	EXTENSION_DOES_NOT_EXIST: "Could not establish connection. Receiving end does not exist.",
};

export function isEmbedded() {
	let isBypassed = false;
	try {
		if (window.location.search) {
			const query = new URLSearchParams(window.location.search);
			if (query.has(EMBEDDED_BYPASS_KEY)) {
				const isFalsy = ["off", "no", "false", "0"].includes(query.get(EMBEDDED_BYPASS_KEY));
				window.sessionStorage.setItem(EMBEDDED_BYPASS_KEY, !isFalsy);
			}
		}

		if (JSON.parse(window.sessionStorage.getItem(EMBEDDED_BYPASS_KEY) || false)) {
			isBypassed = true;
		}
	} catch (error) {
		// since this is a runtime IIFE; Sentry isn't available yet; that said,
		// this functionality is for internal use only, so just report locally for easier debugging
		console.error(error);
	}
	const isInChromeExtension = Boolean(window.self !== window.top && window.chrome?.runtime);
	return isBypassed || isInChromeExtension;
}
export const sendMessageToSpecificExtension = (extensionId, payload) => {
	return new Promise(async(resolve, reject) => {
		window.chrome.runtime.sendMessage(extensionId, payload, (response) => {
			const isExtensionExist = window.chrome.runtime.lastError?.message
				!== CHROME_EXTENSION_ERRORS.EXTENSION_DOES_NOT_EXIST;
			if (!isExtensionExist) {
				reject(window.chrome.runtime.lastError);
			} else {
				if (response?.error) {
					reject(response.error);
				} else {
					resolve(response);
				}
			}
		});
	});
};
export async function sendMessageToExtension(payload) {
	return new Promise(async(resolve, reject) => {
		let error, response;
		const extensionIds = [EXTENSION_ID, EXTENSION_NEXT_ID];
		for (const id of extensionIds) {
			try {
				response = await sendMessageToSpecificExtension(id, payload);
			} catch (e) {
				error = e;
			}
		}
		if (error && error.message !== CHROME_EXTENSION_ERRORS.EXTENSION_DOES_NOT_EXIST) {
			reject(error);
		} else if (response) {
			resolve(response);
		}
	});
}

export const EMBEDDED_ONLY = "only";
export function embeddedRouteGuard(to, from, next) {
	const isEmbeddedValue = isEmbedded();
	const isEmbeddable = to.matched.reduce((acc, { meta }) => meta?.embeddable || acc, false);
	const isEmbeddableOnlyGuarded = isEmbeddable === EMBEDDED_ONLY && !isEmbeddedValue;
	const isUnembeddableGuarded = isEmbeddedValue && !isEmbeddable;
	if (isEmbeddableOnlyGuarded || isUnembeddableGuarded) {
		if (to.meta?.embeddedPath && isEmbeddedValue) {
			return next(to.meta.embeddedPath);
		}
		if (to.meta?.unembeddedPath && !isEmbeddedValue) {
			return next(to.meta.unembeddedPath);
		}
		if (isEmbeddableOnlyGuarded) {
			return next(`/embeddable-only?to=${to.fullPath}`);
		}
		return next(`/embedded/unembeddable?to=${to.fullPath}`);
	}
	next();
}
