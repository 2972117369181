import Vue from "vue";
import { orderBy, isEmpty, partition } from "lodash";

import SchedulesAPI from "@/services/api/Schedules.js";
import { formatDateToTimestamp } from "@/utilities/dateHelpers.js";
import { isBreakShift, isMealBreakShift } from "@/utilities/ScheduleHelpers.js";

export const state = {
	upcomingShifts: {},
	isLoadingUpcomingShifts: false,
};

export const mutations = {
	/**
	 * @param {Object} payload
	 * @param {Array} payload.upcomingShifts
	 */
	SET_UPCOMING_SHIFTS(state, payload) {
		const shifts = {};
		payload.upcomingShifts.forEach((shift) => shifts[shift.id] = shift);
		state.upcomingShifts = shifts;
	},
	SET_SHIFT_IN_UPCOMING_SHIFTS(state, payload) {
		state.upcomingShifts[payload.shift.id] = payload.shift;
	},
	/**
	 * @param {Object} payload
	 * @param {Number} payload.shiftId
	 */
	REMOVE_SHIFT_FROM_UPCOMING_SHIFTS(state, payload) {
		Vue.delete(state.upcomingShifts, payload.shiftId);
	},
	/**
	 * @param {Object} payload
	 * @param {Boolean} payload.isLoadingUpcomingShifts
	 */
	SET_IS_LOADING_UPCOMING_SHIFTS(state, payload) {
		state.isLoadingUpcomingShifts = payload.isLoadingUpcomingShifts;
	},
};

export const getters = {
	getNextShiftSegmentsWithBreak(state) {
		const sortedUpcomingShiftSegments = orderBy(Object.values(state.upcomingShifts), ["start_time"], ["asc"]);
		const [breakSegments, clientFacingSegments] = partition(
			sortedUpcomingShiftSegments,
			isBreakShift,
		);


		if (breakSegments.length > 0) {
			clientFacingSegments.forEach((clientFacingSegment) => {
				clientFacingSegment.break = breakSegments.find((breakSegment) =>
					breakSegment.start_time === clientFacingSegment.end_time &&
					breakSegment.segment_id === clientFacingSegment.segment_id,
				) || null;
			});
		}

		return clientFacingSegments;
	},
};

export const actions = {
	async getCurrentShift({ commit, state }) {
		const params = { include: "scheduleType,pendingScheduleBreakRequest" };
		const response = await SchedulesAPI.getCurrentShift(params);

		if (!isEmpty(response?.data?.data)) {
			const isBreakInShiftArray = Object.values(state.upcomingShifts).some((shift) => isMealBreakShift(shift));
			const { status } = response.data?.data?.pendingScheduleBreakRequest?.data;

			let breakStatus = "NONE";
			if (isBreakInShiftArray) {
				breakStatus = "APPROVED";
			} else if (status === "Pending") {
				breakStatus = "PENDING";
			}

			commit("PunchClock/SET_BREAK_REQUEST_STATUS", {
				status: breakStatus,
			}, { root: true });
		}
	},
	/**
	 * @param {Object} payload
	 * @param {Number} payload.userId
	 */
	async getUpcomingShifts({ commit }, payload) {
		const currentDate = new Date();
		const from = formatDateToTimestamp(currentDate);
		const to = formatDateToTimestamp(currentDate.setDate(currentDate.getDate() + 1));
		const params = {
			from,
			to,
		};
		const response = await SchedulesAPI.getSchedulesforTutor(payload.userId, params);
		commit("SET_UPCOMING_SHIFTS", {
			upcomingShifts: response.data.data,
		});
	},
};

export default { namespaced: true, state, mutations, getters, actions };
