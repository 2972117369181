<template>
	<div>
		<OrigamiPageHeader
			title="Review Center"
			back-button-aria-label="Navigate back to previous page"
			is-showing-back-button
			@back-action="goBack"
		/>
		<div class="origami-container tw-py-8">
			<div v-if="isLoading">
				<BaseLoadingState v-jest="'loading-state'" />
			</div>
			<div
				v-else-if="isEssayReviewDisabled"
				class="tw-flex"
			>
				<h4
					v-jest="'essay-review-disabled'"
					class="tw-text-center tw-text-xl tw-max-w-xl tw-mx-auto"
				>
					You do not have access to writing review. If you wish to discuss becoming a writing reviewer, please contact a Platform Manager!
				</h4>
			</div>
			<div v-else>
				<div class="tw-flex tw-flex-col tw-gap-4 tw-justify-start md:tw-grid md:tw-grid-cols-12 tw-mb-4">
					<div
						class="tw-col-span-8"
					>
						<TutorEssayStatistics v-jest="'essay-statistics'" />
					</div>
					<div class="tw-col-span-4">
						<OrigamiCard
							class="tw-h-full"
							tag="div"
						>
							<BreaksManagementWidget />
						</OrigamiCard>
					</div>
				</div>

				<div v-if="isPunchedIntoEssayReviewShift">
					<div v-if="pickedUpEssay">
						<TutorEssayPreview
							v-jest="'picked-up-essay-component'"
							:essay="pickedUpEssay"
						/>
						<TutorEssayReviewSubmission
							v-jest="'tutor-essay-review-submission'"
							@reviewed-essay="handleReviewedEssay"
						/>
					</div>
					<div
						v-else-if="featureFlagMilestoneIsEnabled
							&& isDisplayingFeedback"
						class="tw-mt-24 tw-max-w-lg tw-my-0 tw-mx-auto tw-flex tw-justify-center tw-items-center tw-w-full tw-flex-col"
					>
						<TutorEssayFlowAcknowledgement
							v-jest="'milestone'"
							:essay-number="currentEssayNumber"
						/>
					</div>
					<div
						v-else-if="isDisplayingFeedback"
						v-jest="'tutor-feedback-component'"
						class="tw-mt-24 tw-max-w-lg tw-my-0 tw-mx-auto tw-flex tw-justify-center tw-items-center tw-w-full tw-flex-col"
					>
						<img
							:v-jest="'essay-feedback-art'"
							src="/images/tutor/essay-review-tutor-feedback.png"
							class="tw-w-80 tw-h-80"
						>
						<h2 class="tw-px-20 tw-break-words tw-text-2xl tw-text-center tw-font-bold tw-flex-col">
							<span>
								You've done it!
							</span>
							<span class="tw-whitespace-nowrap">
								Thank you for your hard work.
							</span>
						</h2>
					</div>
					<TutorEssayPreview
						v-else-if="hasReservedEssay && !isOnBioBreakOrMIA"
						v-jest="'reserve-essay-component'"
						:essay="reservedEssay"
					/>
					<div
						v-else-if="isOnBioBreakOrMIA"
						class="tw-max-w-lg tw-my-0 tw-mx-auto tw-flex tw-justify-center tw-items-center tw-w-full tw-flex-col"
					>
						<div
							v-jest="'bio-break-placeholder'"
							class="tw-my-5"
						>
							<img
								src="/images/tutor/essay-review-not-punched-in.png"
								height="452"
								width="452"
							>
						</div>
					</div>
					<div
						v-else
						class="tw-max-w-lg tw-my-0 tw-mx-auto tw-flex tw-justify-center tw-items-center tw-w-full tw-flex-col"
					>
						<img
							:v-jest="'empty-queue'"
							src="/images/tutor/essay-review-empty-queue.png"
							class="tw-w-56 tw-h-56"
						>
						<h3 class="tw-px-12 tw-break-words tw-text-2xl tw-font-bold tw-text-center">
							Nothing currently reserved.
						</h3>
						<p class="tw-break-words tw-text-lg tw-text-center">
							Please refresh the page to reserve the next item in the queue.
						</p>
						<BaseButton
							v-jest="'try-new-essay'"
							class="tw-shadow-elevation-2 tw-mt-6"
							@click.native="tryToReserveEssay"
						>
							<template #text>
								Refresh queue
							</template>
						</BaseButton>
					</div>
				</div>

				<div
					v-else
					v-jest="'illustration-container'"
					class="tw-max-w-md tw-my-0 tw-mx-auto tw-flex tw-justify-center tw-items-center tw-w-full tw-flex-col"
				>
					<img
						:v-jest="'not-punched-art'"
						src="/images/tutor/essay-review-not-punched-in.png"
						class="tw-h-80 tw-h-80"
					>
					<h3 class="tw-px-4 md:tw-px-12 tw-py-4 tw-break-words tw-text-lg tw-text-center">
						You are currently not punched-in, so there are no submissions for you to review.
					</h3>
				</div>
			</div>
		</div>
		<TutorModalSkipEssayPickup v-jest="'skip-essay-modal'" />
	</div>
</template>

<script>
import { mapState } from "vuex";
import { isEmpty } from "lodash";
import { OrigamiCard, OrigamiPageHeader } from "@origami/vue2";

import BaseLoadingState from "@/components/BaseLoadingState.vue";
import DispatchErrorHandlingMixin from "@/mixins/DispatchErrorHandlingMixin.js";
import { getErrorText, showErrorModal } from "@/utilities/errorHandlingHelpers.js";
import { BreaksManagementWidget } from "@/modules/BreaksManagementWidget/index.js";
import { TutorEssayStatistics } from "@/modules/TutorEssayStatistics/index.js";
import BaseButton from "@/components/BaseButton.vue";

import TutorEssayFlowAcknowledgement from "./TutorEssayFlowAcknowledgement.vue";
import TutorEssayPreview from "./TutorEssayPreview.vue";
import TutorModalSkipEssayPickup from "./TutorModalSkipEssayPickup.vue";


const ESSAY_REVIEW_SHIFT_ID = 2;

export default {
	components: {
		BaseLoadingState,
		BreaksManagementWidget,
		OrigamiCard,
		OrigamiPageHeader,
		TutorEssayFlowAcknowledgement,
		TutorEssayPreview,
		TutorEssayReviewSubmission: () => import("./TutorEssayReviewSubmission.vue"),
		TutorEssayStatistics,
		TutorModalSkipEssayPickup,
		BaseButton,
	},
	mixins: [DispatchErrorHandlingMixin],
	data() {
		return {
			isLoading: false,
			isDisplayingFeedback: false,
			featureFlagMilestoneIsEnabled: false,
		};
	},
	computed: {
		...mapState(["BreaksManagementWidget", "Essay"]),
		...mapState("Essay", ["EssayStatistics"]),
		currentShiftSegment() {
			return this.$store.getters["TutorSchedule/getCurrentShiftSegment"];
		},
		isPunchedIntoEssayReviewShift() {
			const isEssayReviewShift = this.currentShiftSegment?.type?.id === ESSAY_REVIEW_SHIFT_ID;
			const isPunchedIn = this.currentShiftSegment?.punch_in;
			return isEssayReviewShift && isPunchedIn;
		},
		isOnBioBreakOrMIA() {
			return this.$store.getters["TutorSchedule/isOnBioBreakOrMIA"];
		},
		hasReservedEssay() {
			return !isEmpty(this.reservedEssay);
		},
		reservedEssay() {
			return this.Essay.reservedEssay;
		},
		pickedUpEssay() {
			return this.Essay.essay;
		},
		isEssayReviewDisabled() {
			return !this.Essay.isEssayReviewer;
		},
		currentEssayNumber() {
			return this.featureFlagMilestoneIsEnabled && this.isDisplayingFeedback
				? this.EssayStatistics.essayStatistics.my_total_reviewed_essays + 1
				: this.EssayStatistics.essayStatistics.my_total_reviewed_essays;
		},
	},
	watch: {
		async reservedEssay() {
			if (!this.hasReservedEssay && !this.pickedUpEssay) {
				this.isLoading = true;
				await this.reserveEssay();
				this.isLoading = false;
			}
		},
	},
	async created() {
		this.isLoading = true;
		try {
			await Promise.all([
				this.$store.dispatch("TutorSchedule/getCurrentShiftAndState"),
				this.$store.dispatch("Essay/getReviewerStatus"),
				this.featureFlagMilestoneIsEnabled = await this.$getFlag("PE-9010-Tutor-ER-Milestones"),
			]);
		} catch (error) {
			Sentry.captureException(error);
			const options = {
				message: getErrorText("loading the writing review page"),
			};
			showErrorModal(this, options);
		}
		if (this.isPunchedIntoEssayReviewShift && !this.pickedUpEssay) {
			await this.tryToReserveEssay();
		}
		this.isLoading = false;
	},
	methods: {
		goBack() {
			this.$router.back();
		},
		handleReviewedEssay() {
			const TEN_SECONDS = 10000;
			this.isDisplayingFeedback = true;
			setTimeout(() => {
				this.isDisplayingFeedback = false;
				this.tryToReserveEssay();
			}, TEN_SECONDS);
		},
		async reserveEssay() {
			try {
				await this.$store.dispatch("Essay/getReservedEssay");
			} catch (error) {
				const options = {
					message: getErrorText("reserving an essay"),
				};
				showErrorModal(this, options);
			}
		},
		async tryToReserveEssay() {
			try {
				this.isLoading = true;
				await this.$store.dispatch("Essay/getReservedEssay");
			} catch (error) {
				Sentry.captureException(error);
			} finally {
				this.isLoading = false;
			}
		},
	},
};
</script>
