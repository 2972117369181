var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "tw-h-80" },
    [
      _c(_setup.ChartBar, {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "bar-graph",
            expression: "'bar-graph'",
          },
        ],
        attrs: {
          "chart-data": _setup.loadCount,
          "bar-chart-options": {
            maxBarThickness: 120,
          },
          "y-axes": _setup.yAxes,
          "x-axes": _setup.xAxes(),
          color: "#6E6FEC",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }