export default [
	{
		path: "courses",
		component: () => import("../../components/courses/CoursesView.vue"),

		children: [
			{
				path: "",
				name: "superuserCoursesList",
				component: () => import("../../components/courses/CoursesListWrapper.vue"),
			},
			{
				path: "create",
				name: "superuserCoursesCreate",
				component: () => import("../../components/courses/CourseCreateWrapper.vue"),
			},
			{
				path: ":courseId",
				name: "superuserCoursesEdit",
				component: () => import("../../components/courses/CourseEditWrapper.vue"),
			},
		],
	},
];
