<template>
	<div id="mobile-menu">
		<button
			id="mobile-menu-button"
			ref="mobile-menu-button"
			data-pendo-id="nav-dropdown-mobile"
			class="tw-z-50 tw-text-origami-white"
			:aria-label="ariaLabel"
			:aria-expanded="ariaExpanded"
			aria-controls="mobile-menu-list"
			@click="setMenuVisibility(!isOpen)"
			@keydown.enter.prevent="setMenuVisibility(!isOpen)"
			@keydown.esc="setMenuVisibility(false)"
			@keydown.down.prevent="focusListItem(0)"
			@keydown.shift.tab="setMenuVisibility(false)"
		>
			<Burger
				v-if="!isOpen"
				aria-hidden="true"
				focusable="false"
			/>
			<Close
				v-else
				class="tw-w-8 tw-h-8"
				aria-hidden="true"
				focusable="false"
			/>
		</button>

		<transition name="slide">
			<ul
				v-show="isOpen"
				id="mobile-menu-list"
				class="tw-fixed tw-bottom-0 tw-inset-x-0 tw-bg-blue-darker tw-w-full tw-z-40 xl:tw-hidden tw-overflow-scroll"
			>
				<li
					v-for="(link, index) in menuItems"
					:key="`mobile-link-${index}`"
					v-jest="`list-link-${link.title}`"
					:class="{ 'user-links-end': isEndOfUserLinks(link.title) }"
				>
					<a
						:ref="`mobile-menu-list-item-${index}`"
						:key="link.target"
						v-jest="'mobile-nav-button'"
						:href="link.target"
						role="link"
						class="tw-block tw-font-extrabold tw-text-origami-white tw-px-6 tw-py-3 mobileNavButton"
						:class="featureFlagPaperAcademyLink ? 'tw-text-lg' : 'tw-text-2xl'"
						@click.prevent="handleGoToLink(link.target)"
						@keydown.down.prevent="focusListItem(Math.min(index + 1, menuItems.length))"
						@keydown.up.prevent="focusListItem(Math.max(0, index - 1))"
						@keydown.esc="focusMenuButton()"
						@keydown.tab.exact="handleTab(index)"
						@keydown.shift.tab.stop
					>
						{{ $t(link.title) }}

						<OrigamiIcon
							v-if="showExternalIcon(link.title)"
							v-jest="`external-icon-${link.title}`"
							name="external"
						/>
					</a>
				</li>
			</ul>
		</transition>
	</div>
</template>

<script>
import { OrigamiIcon } from "@origami/vue2";

import { Burger, Close } from "@/components/elements/icons/index.js";
import { isVueRoute } from "@/utilities/vue-router.js";

export default {
	name: "MobileMenu",
	components: {
		Burger,
		Close,
		OrigamiIcon,
	},
	props: {
		menuItems: {
			type: Array,
			default: () => [],
		},
	},
	data: () => ({
		isOpen: false,
		featureFlagPaperAcademyLink: false,
		resourceHubLink: "https://sites.google.com/paper.co/resourcehub",
	}),
	computed: {
		ariaLabel() {
			return this.$t(`${this.isOpen ? "close" : "menu"}`);
		},
		ariaExpanded() {
			return this.isOpen.toString();
		},
	},
	async mounted() {
		this.featureFlagPaperAcademyLink = await this.$getFlag("GROW-2309-paper-academy-link");
	},
	methods: {
		setMenuVisibility(isOpen) {
			this.isOpen = isOpen;
			document.documentElement.style.overflow = isOpen ? "hidden" : "auto";
		},
		focusMenuButton() {
			this.setMenuVisibility(false);
			this.$refs["mobile-menu-button"].focus();
		},
		focusListItem(targetIdx) {
			const listItemToFocus = this.$refs[`mobile-menu-list-item-${targetIdx}`][0].$el;
			listItemToFocus.focus();
		},
		handleTab(index) {
			const isLastItem = index === (this.menuItems.length - 1);
			isLastItem && this.setMenuVisibility(false);
		},
		handleGoToLink(target) {
			this.setMenuVisibility(false);
			if (!isVueRoute(target)) {
				if (target === this.resourceHubLink) {
					window.open(target, "_blank");
					return;
				}
				window.location.href = target;
				return;
			}
			this.$router.push(target);
		},
		isLinkExternal(target) {
			return isVueRoute(target);
		},
		showExternalIcon(title) {
			const isResourceCenterTitle = title === "navbar.resourceCenter";
			const isPaperAcademyTitle = title === "navbar.paperAcademy";
			const isResourceHubTitle = title === "navbar.resourceHub";
			const isReadingTitle = title === "navbar.reading";

			if (this.featureFlagPaperAcademyLink) {
				return isResourceCenterTitle || isPaperAcademyTitle || isReadingTitle;
			}
			return isResourceHubTitle || isReadingTitle;
		},
		isEndOfUserLinks(title) {
			return this.featureFlagPaperAcademyLink && title === "navbar.paperAcademy" ||
				(
					this.featureFlagNewTeacherDashboard &&
					!this.featureFlagPaperAcademyLink &&
					title === "navbar.resourceHub"
				);
		},
	},
};
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
	transition: all 0.3s ease;
}

.slide-enter,
.slide-leave-to {
	transform: translateX(100%);
}

#mobile-menu-button:focus-visible,
#mobile-menu-button:focus {
	outline: 2px solid var(--origami-blue-200);
	border-radius: 5px;
}
#mobile-menu-button:hover {
	background: #434B8B;
	border-radius: 5px;
}

#mobile-menu-list {
	height: calc(100vh - 6rem);
}
.mobileNavButton:focus-visible {
	outline: 2px solid var(--origami-blue-200);
}
.user-links-end {
	margin-bottom: 2rem;
	position: relative;
}
.user-links-end::after {
	content: "";
	position: absolute;
	bottom: -1rem;
	left: 1.5rem;
	width: calc(100% - 3rem);
	height: 1px;
	background-color: #fff;
}
</style>
