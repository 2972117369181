<template>
	<div
		class="tutorImage"
		:style="{ height: dimensions + 'px', width: dimensions + 'px' }"
	>
		<img
			v-if="!isShowingDefaultImage"
			class="tutorImage__picture tw-text-xs"
			:alt="$t('tutor_profile_picture', { fullTutorName })"
			:src="profilePath"
			:height="dimensions"
			:width="dimensions"
			@error="showDefaultImage()"
		>
		<IconDefaultTutorPicture
			v-else
			class="defaultTutorImage"
			:width="dimensions"
			:height="dimensions"
		/>
	</div>
</template>
<script>
import IconDefaultTutorPicture from "./icons/IconDefaultTutorPicture.vue";
export default {
	components: {
		IconDefaultTutorPicture,
	},
	props: {
		profilePath: {
			type: String,
			required: false,
			default() {
				return "";
			},
		},
		dimensions: {
			type: Number,
			required: false,
			default() {
				return 40;
			},
		},
		fullTutorName: {
			type: String,
			required: false,
			default() {
				return this.$t("chat.chat_intro.the_tutor");
			},
		},
	},
	data() {
		return {
			isShowingDefaultImage: false,
		};
	},
	watch: {
		/**
		 * When the profile path changes, do not show the default profile image
		 */
		profilePath() {
			this.isShowingDefaultImage = false;
		},
	},
	methods: {
		showDefaultImage() {
			this.isShowingDefaultImage = true;
		},
	},
};
</script>
<style scoped>
.tutorImage {
	border-radius: 50%;
	outline: solid transparent;
}
.tutorImage__picture {
	border-radius: 50%;
}
.defaultTutorImage {
	display: flex;
}
</style>
