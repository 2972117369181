var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseButton",
    {
      attrs: { variants: ["mini", _vm.isRecognized ? "outline" : ""] },
      nativeOn: {
        click: function ($event) {
          return _vm.handleClick.apply(null, arguments)
        },
      },
    },
    [
      _vm.isLoading
        ? _c("BaseLoader", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "loader",
                expression: "'loader'",
              },
            ],
            staticClass:
              "tw-inline-flex tw-align-self-center tw-w-6 tw-h-6 tw--my-2 tw--ml-2 tw-mr-2",
            attrs: { thickness: "0.25rem" },
          })
        : _vm.isRecognized
        ? _c("IconCheck", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "check-icon",
                expression: "'check-icon'",
              },
            ],
            staticClass: "tw--mt-3 tw--mb-2 tw--ml-2 tw-mr-2",
          })
        : _c("IconEmojiThumbsUp", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "like-icon",
                expression: "'like-icon'",
              },
            ],
            staticClass: "tw--mt-3 tw--mb-2 tw--ml-1 tw-mr-2",
          }),
      _vm._v(" "),
      _vm.isRecognized
        ? [
            _vm._v(
              "\n\t\t" +
                _vm._s(
                  _vm.$t("recognition.recognition_sent", {
                    recognition_name: "Kudos",
                  })
                ) +
                "\n\t"
            ),
          ]
        : [
            _vm._v(
              "\n\t\t" +
                _vm._s(
                  _vm.$t("recognition.recognition_send", {
                    recognition_name: "Kudos",
                  })
                ) +
                "\n\t"
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }