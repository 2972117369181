<template>
	<div class="form-group tw-mt-8 tw-mb-14">
		<div class="tw-flex">
			<BaseTutorImage
				:profile-path="profileImage"
				:full-tutor-name="fullTutorName"
				:dimensions="96"
			/>
			<div class="tw-ml-6 tw-flex tw-flex-col tw-w-full">
				<div class="tw-flex tw-w-full tw-justify-between tw-items-center">
					<div class="tw-flex tw-flex-row tw-mt-9">
						<BaseButton
							v-jest="'upload-profile-image-button'"
							v-data-cy="'btn-upload-profile-image'"
							class="tw-mr-6 tw-w-36"
							:is-disabled="isLoadingRemoveImage"
							@click.native="$refs.imageUpload.click()"
						>
							<template
								slot="text"
							>
								<LoadingRectangles
									v-if="isLoadingUploadImage"
									class="tw-text-white-high"
								/>
								<span
									v-else
								>
									Upload New Photo
									<input
										ref="imageUpload"
										type="file"
										class="updateButton__fileInput"
										@change="handleFileUpload($event)"
									>
								</span>
							</template>
						</BaseButton>
						<BaseButton
							v-if="isTutorHasProfilePicture"
							v-jest="'delete-profile-image-button'"
							v-data-cy="'btn-delete-profile-image'"
							type="CANCEL"
							:is-disabled="isLoadingUploadImage"
							@click.native="deleteTutorProfilePicture"
						>
							<template
								slot="text"
							>
								<LoadingRectangles
									v-if="isLoadingRemoveImage"
									class="tw-text-blue-regular"
								/>
								<span
									v-else
								>
									Remove Photo
								</span>
							</template>
						</BaseButton>
					</div>
					<div class="tw-flex tw-flew-row tw-mt-9">
						<span class="tw-text-lg tw-text-grey-dark tw-mr-2 tw-whitespace-nowrap">
							Tutor ID #{{ tutorId }}
						</span>
						<button
							v-jest="'impersonate-button'"
							v-data-cy="'btn-impersonate'"
							@click="impersonateUser(PlatformManager.TutorsEdit.tutor)"
						>
							<Impersonate
								class="tw-text-blue-regular hover:tw-text-blue-darker tw-cursor-pointer tw-w-6"
							/>
						</button>
					</div>
				</div>
				<div
					:class="{ tutorImage__error: isShowingError, tutorImage__success: isShowingSuccess }"
				>
					<template v-if="isShowingError">
						<IconExclamationMark />&nbsp;{{ message }}
					</template>
					<template v-if="isShowingSuccess">
						<IconCheckMark :icon-color="$root.$colors.C_GREEN" />&nbsp;{{ message }}
					</template>
				</div>
			</div>
		</div>
		<ImpersonationModal
			v-show="showImpersonationModal"
			v-jest="'impersonate-user'"
			:user-email="impersonatedUserEmail"
			:token="impersonationToken"
			@close-modal="showImpersonationModal = false"
		/>
	</div>
</template>

<script>
import { mapState } from "vuex";

import BaseTutorImage from "@/components/BaseTutorImage.vue";
import IconCheckMark from "@/components/icons/IconCheckMark.vue";
import IconExclamationMark from "@/components/icons/IconExclamationMark.vue";
import { Impersonate } from "@/components/elements/icons/index.js";
import { ImpersonationModal } from "@/modules/Customers/index.js";
import LoadingRectangles from "@/components/LoadingRectangles.vue";
import BaseButton from "@/components/BaseButton.vue";

export default {
	components: {
		BaseTutorImage,
		IconCheckMark,
		IconExclamationMark,
		Impersonate,
		ImpersonationModal,
		LoadingRectangles,
		BaseButton,
	},
	data() {
		return {
			isLoadingRemoveImage: false,
			isLoadingUploadImage: false,
			isShowingError: false,
			isShowingSuccess: false,
			message: "",
			showImpersonationModal: false,
			impersonatedUserEmail: "",
			impersonationToken: "",
		};
	},
	computed: {
		...mapState(["PlatformManager"]),
		isTutorHasProfilePicture() {
			return (
				this.PlatformManager.TutorsEdit.tutor !== undefined &&
				this.PlatformManager.TutorsEdit.tutor !== null &&
				this.PlatformManager.TutorsEdit.tutor.tutor.data.profile_image !== ""
			);
		},
		profileImage() {
			if (this.PlatformManager.TutorsEdit.tutor !== null) {
				return this.PlatformManager.TutorsEdit.tutor.tutor.data.profile_image;
			}
			return "";
		},
		tutorId() {
			return this.PlatformManager.TutorsEdit.tutor?.id;
		},
		fullTutorName() {
			return `${this.PlatformManager.TutorsEdit.tutor?.first_name} ${this.PlatformManager.TutorsEdit.tutor?.last_name}`;
		},
	},
	methods: {
		async handleFileUpload(event) {
			this.isLoadingUploadImage = true;
			this.isShowingError = false;
			this.isShowingSuccess = false;
			this.message = "";
			try {
				const formData = new FormData();
				formData.append("file", event.target.files[0]);
				await this.$store.dispatch("PlatformManager/TutorsEdit/updateTutorProfilePicture", formData);
				this.message = "Image updated";
				this.isShowingSuccess = true;
			} catch (e) {
				Sentry.captureException(e);
				this.isShowingSuccess = false;
				if (e.response.status === 422) {
					this.message = e.response.data.error_description.file[0];
				} else if (e.response.status === 500) {
					Sentry.captureException(e);
				} else {
					Sentry.captureException(e);
					this.message = "There was an error updating the picture.";
				}
				this.isShowingError = true;
			} finally {
				this.isLoadingUploadImage = false;
			}
		},
		async deleteTutorProfilePicture() {
			this.isLoadingRemoveImage = true;
			this.isShowingError = false;
			this.isShowingSuccess = false;
			this.message = "";
			try {
				await this.$store.dispatch("PlatformManager/TutorsEdit/deleteTutorProfilePicture");
				this.message = "Image removed";
				this.isShowingSuccess = true;
			} catch (e) {
				Sentry.captureException(e);
				this.isShowingSuccess = false;
				this.message = "There was an error deleting the picture";
				this.isShowingError = true;
			} finally {
				this.isLoadingRemoveImage = false;
			}
		},
		impersonateUser(user) {
			this.showImpersonationModal = true;
			this.impersonatedUserEmail = user.email;
		},
	},
};
</script>

<style scoped>
.tutorImage__error {
	display: flex;
	align-items: center;
	color: var(--c-red);
	font-size: 12px;
}
.tutorImage__success {
	display: flex;
	align-items: center;
	color: var(--c-green);
	font-size: 12px;
}

.updateButton__fileInput {
	opacity: 0;
	position: absolute;
	z-index: -1;
	visibility: hidden;
}
.updateButton__fileInput--lowOpacity {
	opacity: 0.6;
}
</style>
