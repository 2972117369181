<template>
	<div>
		<label class="tw-text-sm tw-font-bold">
			{{ $t('chooseSubmissionType') }} *
		</label>
		<BaseDropdown
			ref="submissionTypeDropdown"
			v-data-cy="'submission-type-dropdown'"
			v-jest="'submission-type-dropdown'"
			:items="submissionTypes"
			:class="{ 'warning': error }"
		>
			<template slot="filters">
				{{ submissionDropdownText }}
			</template>
			<template #default="{ item }">
				<BaseRadio
					v-model="submissionType"
					v-data-cy="`submission-type-${item.id}`"
					radio-name="submission-type"
					:radio-value="item.id"
					:radio-id="`submission-type-${item.id}`"
				>
					<template slot="text">
						{{ $t(item.key) }}
					</template>
				</BaseRadio>
			</template>
		</BaseDropdown>
		<span
			v-show="error"
			v-jest="'submission-type-error'"
			class="tw-text-sm tw-font-bold tw-text-origami-red-400"
		>
			{{ $t("chooseSubmissionType") }}
		</span>
	</div>
</template>

<script>
import { mapState } from "vuex";

import BaseRadio from "@/components/BaseRadio.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";

export default {
	components: {
		BaseRadio,
		BaseDropdown,
	},
	props: {
		value: { type: Number, default: 0 },
		error: { type: Boolean, default: false },
	},

	data: function() {
		return {
			submissionType: this.value,
		};
	},

	computed: {
		...mapState(["Student"]),
		submissionTypes() {
			return this.Student.Essays.submissionTypes;
		},
		submissionDropdownText() {
			const submissionType = this.submissionTypes.find((type) => type.id === this.submissionType);
			if (submissionType?.key) {
				return this.$t(`${submissionType.key}`);
			}
			return this.$t("chooseSubmissionType");
		},
	},

	watch: {
		submissionType(value) {
			this.$emit("input", value);
		},
	},
};
</script>

<style scoped>
.warning :deep(.mainDropdown) {
	border: 2px solid var(--origami-red-400);
}
</style>
