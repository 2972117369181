<template>
	<div :class="{ 'tw-shadow-elevation-4': !isFlat }">
		<div v-if="displayHeader">
			<h2 class="tw-pl-6 tw-pr-4 tw-py-4 tw-font-bold tw-text-lg">
				Remove selected shift?
			</h2>
			<hr>
		</div>
		<div v-if="!isDeleteComplete">
			<p class="tw-pl-6 tw-pr-4 tw-pt-4 tw-pb-0 tw-text-base">
				Are you sure you want to delete this shift?<br>You cannot undo this action.
			</p>
			<p
				:style="currentShiftScheduleTypeColor"
				class="tw-flex tw-text-center tw-w-max tw-ml-6 tw-my-4 tw-px-1 tw-py-0 tw-rounded-md tw-font-bold tw-text-sm"
			>
				{{ shift.shiftType.data.name }}
			</p>
			<div class="tw-pl-6 tw-pr-4 tw-py-0 tw-text-sm">
				<p class="tw-pb-2 tw-pt-0 tw-px-0 tw-font-bold">
					{{ shift.name }} [{{ shift.id }}]
				</p>
				<p class="tw-pb-2 tw-pt-0 tw-px-0">
					{{ formattedShiftStartTime }}
				</p>
				<p>{{ formattedShiftEndTime }}</p>
			</div>
			<div class="tw-flex tw-justify-between tw-pt-8 tw-pb-4 tw-pl-6 tw-pr-4">
				<OrigamiButton
					v-jest="'cancel-btn'"
					v-data-cy="'close-modal-btn'"
					variant="secondary"
					:class="{ 'tw-w-full tw-justify-center': hasAnError }"
					@click.native="cancel()"
				>
					Cancel
				</OrigamiButton>
				<OrigamiButton
					v-if="!hasAnError"
					v-jest="'delete-shift-btn'"
					variant="destructive"
					@click.native="deleteShift()"
				>
					Remove Shift
				</OrigamiButton>
			</div>
			<ServiceScheduleTutorErrorMessage
				v-if="hasAnError"
				v-jest="'error-message'"
				:error="error"
			/>
		</div>
		<ServiceScheduleTutorSuccessfulAction
			v-else
			:shift="shift"
			:success-message-in-sidebar="{
				message: 'You have successfully deleted the shift.',
				buttonText: 'Complete',
			}"
			@cancel-edit="cancel()"
		/>
	</div>
</template>

<script>
import { OrigamiButton } from "@origami/vue2";
import { format } from "date-fns";
import { isEmpty } from "lodash";

import { checkIsObjectKeysPresent } from "@/utilities/propValidationHelpers.js";
import { getSlotColorFromShiftType } from "@/utilities/ScheduleHelpers.js";
import ServiceScheduleTutorSuccessfulAction from "@/modules/ServiceSchedule/components/ServiceScheduleTutorSuccessfulAction.vue";

import ServiceScheduleTutorErrorMessage from "./ServiceScheduleTutorErrorMessage.vue";

export default {
	components: {
		ServiceScheduleTutorSuccessfulAction,
		OrigamiButton,
		ServiceScheduleTutorErrorMessage,
	},
	props: {
		isFlat: {
			type: Boolean,
			default: false,
		},
		displayHeader: {
			type: Boolean,
			default: true,
		},
		shift: {
			type: Object,
			required: true,
			validator: (value) =>
				checkIsObjectKeysPresent(value,
					["start", "end", "name", "id", "shiftColor", "shiftType", "shiftEnded"],
				),
		},
	},
	data() {
		return {
			error: null,
			isDeleteComplete: false,
		};
	},
	computed: {
		formattedShiftStartTime() {
			return "Start: " + format(this.shift.start, "PPpp");
		},
		formattedShiftEndTime() {
			return "End: " + format(this.shift.end, "PPpp");
		},
		currentShiftScheduleTypeColor() {
			return "background: " + getSlotColorFromShiftType(this.shift.shiftType.data.name);
		},
		hasAnError() {
			return !isEmpty(this.error);
		},
	},
	methods: {
		cancel() {
			this.$emit("cancel-edit");
			this.error = null;
		},
		async deleteShift() {
			try {
				await this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/deleteShift",
					{ id: this.shift?.id });
				this.isDeleteComplete = true;
				this.$emit("complete-deletion");
			} catch (e) {
				this.error = e;
				Sentry.captureException(e);
			}
		},
	},
};
</script>
