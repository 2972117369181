<template>
	<div
		v-show="isShowingCalendar"
		class="schedule__coveredContainer tw-flex tw-p-5 tw-max-w-5xl tw-m-auto"
	>
		<div class="schedule__hoursContainer">
			<p class="schedule__totalHoursBanner">
				Language Covered Hours
			</p>
			<div
				v-for="language in totalLanguageHours"
				:key="language.id"
				class="schedule__languageHours"
			>
				<div v-jest="'language-covered-hours'">
					<span class="schedule__itemLabel">{{ language.language }}:</span>
					{{ Math.round(language.hours) }} hours
				</div>
			</div>
		</div>
		<div class="schedule__hoursContainer">
			<p class="schedule__totalHoursBanner">
				Subject Covered Hours
			</p>
			<div class="schedule__listContainer">
				<div
					v-for="(subject, index) in totalSubjectHours"
					:key="index"
					class="schedule__languageHours"
				>
					<div
						v-jest="'subject-covered-hours'"
						class="schedule__hoursList"
					>
						<span class="schedule__itemLabel">{{ subject.name }}:</span>
						{{ Math.round(subject.hours) }} hours
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		isShowingCalendar: {
			type: Boolean,
			required: true,
		},
		totalLanguageHours: {
			type: Array,
			required: true,
		},
		totalSubjectHours: {
			type: Object,
			required: true,
		},
	},
};
</script>
<style scoped>
.schedule__totalHoursBanner {
	font-size: 18px;
	color: var(--c-black);
	align-self: center;
	margin-right: 15px;
	font-weight: 600;
}
.schedule__languageHours {
	display: flex;
	flex-direction: column;
}
.schedule__hoursContainer {
	margin-right: 15px;
	width: 300px;
}
.schedule__listContainer {
	overflow-y: auto;
	height: 200px;
}
.schedule__itemLabel {
	font-weight: 600;
}
.schedule__coveredContainer {
	background-color: var(--c-white);
}
</style>
