var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "19",
        height: "19",
        viewBox: "0 0 19 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M1.35375 14.0989L4.20375 11.508L4.10875 11.4216C3.76042 11.1049 3.59021 10.7055 3.59812 10.2233C3.60604 9.7411 3.78417 9.34167 4.1325 9.025L13.6562 0.367045C13.9254 0.122348 14.2658 0 14.6775 0C15.0892 0 15.4296 0.122348 15.6987 0.367045L18.3587 2.78523C18.6279 3.02992 18.7585 3.35739 18.7506 3.76761C18.7427 4.17784 18.6042 4.5053 18.335 4.75L8.81125 13.408C8.51042 13.6814 8.09479 13.8182 7.56437 13.8182C7.03396 13.8182 6.61833 13.6814 6.3175 13.408L6.05625 13.1705L5.035 14.0989H1.35375ZM13.0863 7.68636L10.355 5.20341L4.82125 10.2341L7.5525 12.717L13.0863 7.68636ZM0 19V16.3875H19V19H0Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }