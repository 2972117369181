var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: {
        "flex items-center justify-center": _setup.props.isShowingFallback,
      },
    },
    [
      _setup.props.isShowingFallback
        ? _vm._t("fallback", function () {
            return [
              _c(_setup.BaseLoader, {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "loading-spinner",
                    expression: "'loading-spinner'",
                  },
                ],
                staticClass:
                  "tw-mt-8 tw-mx-auto tw-h-16 tw-w-16 tw-text-blue-regular tw-self-center",
                class: _vm.spinnerClass,
                attrs: { thickness: _vm.spinnerThickness },
              }),
            ]
          })
        : _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }