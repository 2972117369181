import middleware from "@/middleware/index.js";
import { MICRO_FRONTENDS_NAMES } from "@/modules/MicroFrontend/utilities/index.js";
import { handleDashboardLandedEvent } from "@/utilities/trackRequests.js";
import { doesStudentNeedOnboarding } from "@/modules/StudentOnboarding/index.js";

export default [
	{
		name: "studentDashboard",
		path: "/student/dashboard*",
		component: () => import("@/modules/MicroFrontend/components/MicroFrontend.vue"),
		beforeEnter: middleware([
			doesStudentNeedOnboarding,
			handleDashboardLandedEvent,
		]),
		meta: {
			embeddedPath: "/embedded/student/sessions",
			title: "Dashboard",
		},
		props: {
			microFrontendName: MICRO_FRONTENDS_NAMES.STUDENT_DASHBOARD,
			containerClass: "tw-flex-grow tw-px-4 md:tw-px-8",
		},
	},
	{
		path: "/dashboard",
		alias: "/dashboard-v2",
		beforeEnter: middleware([
			(to, from, next) => {
				next("/student/dashboard");
			},
		]),
		meta: {
			embeddedPath: "/embedded/student/sessions",
		},
	},
	{
		path: "/dashboard/resources/:id",
		alias: "/student/resources/:id",
		beforeEnter: middleware([
			(to, from, next) => {
				next(`/student/dashboard/resources/${to.params.id}`);
			},
		]),
	},
];
