<template>
	<div>
		<ServiceDashboardTutorCardHeaderUpcomingTutor
			:user="user"
			:shift="shift"
		/>
	</div>
</template>

<script>
import ServiceDashboardTutorCardHeaderUpcomingTutor from "./ServiceDashboardTutorCardHeaderUpcomingTutor.vue";
export default {
	components: {
		ServiceDashboardTutorCardHeaderUpcomingTutor,
	},
	props: {
		cardData: {
			type: Object,
			required: true,
			validator(value) {
				return value.hasOwnProperty("user") && value.hasOwnProperty("shift");
			},
		},
	},
	computed: {
		/**
		 * @returns {Object}
		 */
		shift() {
			return this.cardData.shift;
		},
		/**
		 * @returns {Object}
		 */
		user() {
			return this.cardData.user;
		},
	},
};
</script>
