var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "info-tooltip",
          expression: "'info-tooltip'",
        },
      ],
      ref: "infoToolTip",
      staticClass: "info-tooltip tw-cursor-pointer tw-ml-2",
    },
    [
      _c("IconInformationCircle", {
        staticClass: "tw-rounded-full tw-cursor-pointer",
        attrs: {
          width: _vm.width,
          height: _vm.height,
          "icon-color": _vm.iconColor,
        },
        nativeOn: {
          focus: function ($event) {
            _vm.tooltipShowing = true
          },
          blur: function ($event) {
            _vm.tooltipShowing = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }