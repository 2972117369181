<template>
	<div class="tw-w-full">
		<section class="tw-flex tw-justify-between tw-py-4">
			<div class="tw-w-1/2">
				<h3 class="tw-font-extrabold tw-pb-2 tw-pt-0 tw-px-0">
					Tutor ID
				</h3>
				<p class="tw-text-sm">
					{{ selectedTutorName }} [{{ selectedTutorId }}]
				</p>
			</div>
			<ShiftSegmentScheduleTypeTag
				v-if="isEditing"
				v-jest="'schedule-type-tag'"
				class="tw-mt-0"
				:shift-type="shiftTypeId"
			/>
		</section>
		<div
			v-jest="'shift-details-container'"
			class="tw-pb-4"
		>
			<DropDown
				v-model="shiftTypeId"
				v-jest="'shiftType-list-dropdown'"
				:is-disabled="isShiftTypeSelectDisabled"
				class="shift-edit-shift-type-select"
				data-cy="shiftType-list-dropdown"
				label="Shift Type"
				prompt-text="Select A Shift Type"
				:items="shiftTypeSelectItems"
				:get-item-text="(item) => getShiftTypeName(item)"
			/>
		</div>
		<section class="tw-pb-3 tw-pt-0 tw-px-0">
			<label
				for="datepicker"
				class="tw-font-extrabold tw-pb-2 tw-pt-0 tw-px-0"
			>
				Date
			</label>
			<DatePicker
				v-model="selectedRange"
				is-expanded
				mode="dateTime"
				class="tw-pl-0 tw-py-0"
				:is24hr="true"
				:minute-increment="15"
				:is-range="true"
				:min-date="minimumDate"
			/>
		</section>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { isNil } from "lodash";
import { addHours, startOfHour } from "date-fns";

import { REST_BREAK_ID, MEAL_BREAK_ID, TUTOR_MANAGER_ID } from "@/modules/TutorSchedule/index.js";
import { getShiftTypeNameFromShiftTypeId } from "@/utilities/ScheduleHelpers.js";
import DropDown from "@/components/elements/DropDown.vue";
import { CLIENT_FACING_SHIFT_TYPE_IDS } from "@/modules/ServiceSchedule/utilities/ShiftTypes.js";

import ShiftSegmentScheduleTypeTag from "./ShiftSegmentScheduleTypeTag.vue";

export default {
	components: {
		DatePicker: () => import("v-calendar/lib/components/date-picker.umd.min.js"),
		ShiftSegmentScheduleTypeTag,
		DropDown,
	},
	props: {
		shift: {
			type: Object,
			required: false,
			default: () => ({}),
		},
		isEditing: {
			type: Boolean,
			required: true,
		},
		selectedTutor: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			selectedRange: null,
			tutorIndex: null,
			shiftTypeId: 0,
		};
	},
	computed: {
		...mapState([
			"PlatformManager",
		]),
		minimumDate() {
			const HOUR_IN_MILLISECONDS = 3600000;
			return new Date() - (24 * HOUR_IN_MILLISECONDS);
		},
		selectedTutorName() {
			return this.selectedTutor?.name ?? "";
		},
		selectedTutorId() {
			return this.selectedTutor?.id ?? "";
		},
		shiftTypesForCreation() {
			return this.PlatformManager.ServiceTutorsSchedule.scheduleTypesList
				.filter((type) => ![REST_BREAK_ID, MEAL_BREAK_ID, TUTOR_MANAGER_ID].includes(type.id));
		},
		selectedShiftType() {
			return this.shiftTypesForCreation.find((shift) => shift.id === this.shiftTypeId);
		},
		shiftTypeFilter() {
			return this.selectedShiftType?.name || "Select a shift type";
		},
		isShiftTypeSelectDisabled() {
			if (!this.isEditing) {
				return false;
			}

			return !!this.shift?.punchIn || !CLIENT_FACING_SHIFT_TYPE_IDS.includes(this.shift?.shiftType?.data?.id);
		},
		shiftTypeSelectItems() {
			return this.isShiftTypeSelectDisabled || !this.isEditing ?
				this.shiftTypesForCreation :
				this.shiftTypesForCreation.filter((shiftType) => CLIENT_FACING_SHIFT_TYPE_IDS.includes(shiftType.id));
		},
	},
	watch: {
		selectedRange(value) {
			this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/setDateRange", {
				dateRange: value,
			});
		},
		shift() {
			this.selectedRange = this.getSelectedRange();
			this.tutorIndex = this.selectedTutor;
			this.shiftTypeId = this.getSelectedShiftId();
		},
		selectedShiftType(value) {
			this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/setCurrentShiftType", {
				currentShiftType: value,
			});
		},
	},
	created() {
		this.selectedRange = this.isEditing
			? { start: this.shift.start, end: this.shift.end }
			: { start: startOfHour(addHours(new Date(), 1)),
				end: startOfHour(addHours(new Date(), 4)) };

		this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/getScheduleTypes");
	},
	mounted() {
		this.shiftTypeId = this.shift.shiftType?.data.id;
	},
	methods: {
		getShiftTypeName(item) {
			return getShiftTypeNameFromShiftTypeId(item.id);
		},
		getSelectedShiftId() {
			return this.shift.shiftType?.data.id;
		},
		getSelectedRange() {
			return isNil(this.shift)
				? { start: startOfHour(addHours(new Date(), 1)),
					end: startOfHour(addHours(new Date(), 4)) }
				: { start: this.shift.start, end: this.shift.end };
		},
	},
};
</script>
