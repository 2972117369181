<template>
	<OrigamiDialog
		title="Do you want to resolve this report?"
		description="This will be marked as resolved and will be removed from the list."
		primary-button-text="Yes, resolve"
		secondary-button-text="Cancel"
		:is-open="isOpen"
		:is-loading="isResolving"
		@cancel="closeModal"
		@confirm="resolveActionItem"
	/>
</template>

<script>
import { mapState } from "vuex";
import { OrigamiDialog } from "@origami/vue2";

import { getErrorText, showErrorModal } from "../../../utilities/errorHandlingHelpers.js";

export default {
	components: {
		OrigamiDialog,
	},
	props: {
		isOpen: {
			required: true,
			type: Boolean,
		},
	},
	data() {
		return {
			isResolving: false,
		};
	},
	computed: {
		...mapState(["currentUser", "PlatformManager", "Toaster"]),
		actionItem() {
			return this.PlatformManager.ActionCenter.selectedActionItem;
		},
	},
	methods: {
		closeModal() {
			this.$store.commit("PlatformManager/ActionCenter/CLOSE_RESOLVE_DIALOG");
		},
		async resolveActionItem() {
			this.isResolving = true;
			const payload = {
				id: this.actionItem.id,
				params: {
					picked_up_user_id: this.currentUser.id,
					resolved: true,
				},
			};
			try {
				await this.$store.dispatch("PlatformManager/ActionCenter/updateActionItem", payload);
				this.showToaster();
			} catch (error) {
				const message = getErrorText("resolving the action item");
				showErrorModal(this, {
					message,
				});
			} finally {
				this.$store.commit("PlatformManager/ActionCenter/CLOSE_RESOLVE_DIALOG");
				this.isResolving = false;
			}
		},
		showToaster() {
			this.$store.dispatch("Toaster/showToaster", {
				text: "This action item has successfully been resolved",
			});
		},
	},
};
</script>

<style scoped>
.transferSessionModal__icon {
	min-width: 2.75rem;
}
</style>
