var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "button",
      class: [
        _vm.typeClasses[_vm.type],
        { mini: _vm.isMini },
        { right: _vm.willFloatRight },
      ],
      attrs: { disabled: _vm.isDisabled },
    },
    [
      _c("div", { staticClass: "icon" }, [_vm._t("icon")], 2),
      _vm._v(" "),
      _c("div", [_vm._t("text")], 2),
      _vm._v(" "),
      _c("div", { staticClass: "icon--right" }, [_vm._t("icon-right")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }