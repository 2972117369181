<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { OrigamiToaster } from "@origami/vue2";
import { getFlag } from "@paper-co/web-toolkit";
import { isNil } from "lodash-es";

import useStore from "@/composables/useStore.js";

const ONE_SECOND_IN_MS = 1000;
const ONE_MINUTE_IN_MS = 60 * ONE_SECOND_IN_MS;
const FORTY_FIVE_SECONDS_IN_MS = 45 * ONE_SECOND_IN_MS;

let currentTimeRefreshInterval = null;
let currentStateRefreshInterval = null;
let currentShiftRefreshInterval = null;

const emit = defineEmits(["break-change-request"]);
const props = defineProps({
	disableActions: {
		type: Boolean,
		default: false,
	},
});

const store = useStore();
const toast = ref(null);

const minutesToNextBreak = computed(() => store.getters["TutorSchedule/minutesToNextBreak"]);
const rejectedBreakRequest = computed(() => store.state.TutorSchedule.rejectedBreakRequest);
const approvedBreakRequest = computed(() => store.state.TutorSchedule.approvedBreakRequest);
const cancelledBreak = computed(() => store.state.TutorSchedule.cancelledBreak);
const breakCancelledByTutor = computed(() => store.state.TutorSchedule.isCancelledByTutor);

const handleAction = () => {
	emit("break-change-request");
};

const showUpcomingBreakNotification = () => {
	const timeText = minutesToNextBreak.value > 1 ? `${minutesToNextBreak.value} minutes` : "less than 1 minute";
	toast.value = {
		variant: "warning",
		label: `Your break is scheduled to start in ${timeText}`,
	};
};

watch(minutesToNextBreak, (newValue, oldValue) => {
	if (oldValue === 16 && newValue === 15 ||
		oldValue === 6 && newValue === 5 ||
		oldValue === 2 && newValue === 1) {
		showUpcomingBreakNotification();
	}
});

watch(rejectedBreakRequest, (newValue) => {
	if (newValue) {
		toast.value = {
			variant: "error",
			label: "Your meal break request was rejected by a Platform Manager",
			action: !props.disableActions ? {
				text: "Try another time",
			} : null,
		};
	}
});

watch(cancelledBreak, (newValue) => {
	if (newValue && !breakCancelledByTutor.value) {
		toast.value = {
			variant: "error",
			label: "Your scheduled break was cancelled by a Platform Manager",
			action: !props.disableActions ? {
				text: "Try another time",
			} : null,
		};
	}
});

watch(approvedBreakRequest, (newValue) => {
	if (newValue) {
		toast.value = {
			variant: "confirmation",
			label: "Your break request has been approved",
		};
	}
});

onMounted(async() => {
	await store.dispatch("TutorSchedule/getCurrentShift");
	await store.dispatch("TutorSchedule/getShiftState");

	const featureFlagBMWCurrentShiftPollingInterval = await getFlag("pe-18154-bmw-current-shift-polling-interval");

	currentTimeRefreshInterval = setInterval(() => {
		store.dispatch("BreaksManagementWidget/setCurrentTime");
	}, ONE_SECOND_IN_MS);
	currentStateRefreshInterval = setInterval(() => {
		store.dispatch("TutorSchedule/getShiftState");
	}, FORTY_FIVE_SECONDS_IN_MS);
	currentShiftRefreshInterval = setInterval(() => {
		store.dispatch("TutorSchedule/getCurrentShift");
	}, featureFlagBMWCurrentShiftPollingInterval * ONE_MINUTE_IN_MS);

	if (!isNil(minutesToNextBreak.value) && minutesToNextBreak.value <= 15) {
		showUpcomingBreakNotification();
	}
});

onUnmounted(() => {
	clearInterval(currentTimeRefreshInterval?.value);
	clearInterval(currentStateRefreshInterval?.value);
	clearInterval(currentShiftRefreshInterval?.value);
});
</script>

<template>
	<div class="tw-text-origami-black">
		<OrigamiToaster
			data-cy="breaks-notifier"
			class="tw-ml-0"
			is-left-align
			:duration="ONE_SECOND_IN_MS * 5"
			:toast="toast"
			@action="handleAction"
		/>
	</div>
</template>
