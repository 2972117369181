<template>
	<nav
		class="navbar"
		:class="[featureFlagSidebarRedesignIsEnabled ? 'md:tw-ml-[80px] tw-left-0 tw-right-0 tw-fixed' : 'tw-relative']"
	>
		<div class="navbar__container">
			<template v-if="!featureFlagSidebarRedesignIsEnabled">
				<button
					v-jest="'button-toggle-sidebar'"
					class="navbar-toggle__button tw-hidden md:tw-block"
					:aria-label="i18n.t('student_navigation.navbar.side_nav')"
					:aria-expanded="(!props.isSidebarToggled).toString()"
					aria-controls="sidebar"
					@click="$emit('toggle-sidebar')"
				>
					<OrigamiIcon
						name="mobile-menu"
						:width="32"
						:height="32"
					/>
				</button>
				<button
					ref="mobileMenuButton"
					v-jest="'button-toggle-mobile-menu'"
					class="navbar-toggle__button tw-block md:tw-hidden"
					:aria-label="i18n.t('student_navigation.navbar.menu')"
					:aria-expanded="props.isMobileMenuToggled.toString()"
					aria-controls="mobile-nav"
					@click="$emit('toggle-mobile-menu')"
					@keydown.esc="$emit('close-mobile-menu')"
				>
					<OrigamiIcon
						:name="toggleMobileMenuIcon"
						:width="32"
						:height="32"
					/>
				</button>
			</template>
			<router-link
				v-slot="{ href, navigate }"
				v-jest="'dashboard-link'"
				custom
				to="/dashboard"
				:class="[featureFlagSidebarRedesignIsEnabled ? 'tw-mr-8 tw-ml-0 md:tw-mr-auto' : 'tw-mr-auto tw-ml-8']"
				:aria-label="i18n.t('student_navigation.navbar.home')"
			>
				<a
					:href="href"
					@click="emitAndRedirect(navigate, $event)"
				>
					<img
						src="/images/logo-paper.svg"
						width="171"
						height="48"
						alt="PAPER"
					>
				</a>
			</router-link>

			<div class="tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-3">
				<slot name="beforeAvatar" />
			</div>
			<div
				v-click-outside="closeDropdown"
				class="navbar__dropdown"
			>
				<button
					ref="navDropdownButton"
					v-jest="'avatar-button'"
					:aria-expanded="isDropdownToggled.toString()"
					aria-controls="dropdown-menu-content"
					:aria-label="ariaLabelUserProfile"
					:aria-describedby="avatarLabelIds.desc"
					@click="isDropdownToggled = !isDropdownToggled"
					@keydown.esc="isDropdownToggled = false"
					@keydown.shift.tab="isDropdownToggled = false"
					@keydown.down.prevent="focusLink(0)"
				>
					<Avatar
						v-if="!isLoadingNavbar"
						v-jest="'avatar-icon'"
						:user="userData"
						:size="56"
					/>
				</button>
				<ul
					v-show="isDropdownToggled"
					id="dropdown-menu-content"
					v-jest="'dropdown-menu-content'"
					class="dropdown__content"
				>
					<li>
						<LearnerProfileLink
							v-jest="'learner-profile-link'"
							@redirect="isDropdownToggled = false"
						/>
					</li>
					<li
						v-for="(link, index) in props.dropdownLinks"
						:key="index"
					>
						<a
							ref="dropdownLink"
							v-jest="'dropdown-link'"
							href="javascript:void(0);"
							@click="handleDropdownLinkClick(link.target)"
							@keydown.down.prevent="focusLink(index + 1)"
							@keydown.up.prevent="focusLink(index - 1)"
							@keydown.esc="focusDropdownClose"
							@keydown.tab.exact="handleDropdownTab(index)"
						>
							<OrigamiIcon
								:name="link.icon"
								class="tw-mr-4"
							/>
							{{ i18n.t(link.title) }}
						</a>
					</li>
				</ul>
			</div>
		</div>
	</nav>
</template>
<script setup>
import { ref, computed, onMounted } from "vue";
import { v4 as uuidv4 } from "uuid";
import { OrigamiIcon } from "@origami/vue2";
import { useRouter } from "vue-router/composables";

import useFeatureFlag from "@/composables/useFeatureFlag.js";
import useStore from "@/composables/useStore.js";
import useI18n from "@/composables/useI18n.js";
import Avatar from "@/modules/Avatar/components/AvatarIcon.vue";

import LearnerProfileLink from "./LearnerProfileLink.vue";

const emit = defineEmits(["toggle-sidebar", "toggle-mobile-menu", "close-mobile-menu"]);

const store = useStore();
const i18n = useI18n();
const router = useRouter();

const props = defineProps({
	dropdownLinks: {
		type: Array,
		default: () => [],
	},
	isSidebarToggled: {
		type: Boolean,
		required: true,
		default: false,
	},
	isMobileMenuToggled: {
		type: Boolean,
		required: true,
		default: false,
	},
});

const isDropdownToggled = ref(false);
const isLoadingNavbar = ref(false);
const dropdownLink = ref(null);
const navDropdownButton = ref(null);
const mobileMenuButton = ref(null);

const featureFlagSidebarRedesignIsEnabled = useFeatureFlag("GROW-1308-sidebar-secondary-tab-redesign");

const studentProfile = computed(() => store.state.Student.Profile?.profile);
const currentUser = computed(() => store.state.currentUser);
const profileAvatar = computed(() => studentProfile.value?.avatar?.name || "default");
const userFullName = computed(() => `${currentUser.value.firstName} ${currentUser.value.lastName}`);
const ariaLabelUserProfile = computed(() => i18n.t("navbar.profile_menu", { user: userFullName.value }));
const toggleMobileMenuIcon = computed(() => props.isMobileMenuToggled ? "close" : "mobile-menu");
const avatarLabelIds = computed(() => ({
	title: `${profileAvatar.value}-title-${uuidv4()}`,
	desc: `${profileAvatar.value}-desc-${uuidv4()}`,
}));
const userData = computed(() => ({
	...store.state.currentUser,
	...studentProfile.value,
}));

const getProfile = async() => {
	try {
		isLoadingNavbar.value = true;
		await store.dispatch("Student/Profile/get");
	} catch (e) {
		Sentry.captureException(e);
	} finally {
		isLoadingNavbar.value = false;
	}
};

const focusLink = (index) => {
	if (dropdownLink.value?.length === index) {
		dropdownLink.value?.[0].focus();
	}
	if (index === -1) {
		dropdownLink.value?.[dropdownLink.value?.length - 1].focus();
	}
	dropdownLink.value?.[index]?.focus();
};

const focusDropdownClose = () => {
	isDropdownToggled.value = false;
	navDropdownButton.value.focus();
};

const handleDropdownTab = (index) => {
	if (index === dropdownLink.value?.length - 1) {
		isDropdownToggled.value = false;
	}
};

const closeDropdown = () => {
	isDropdownToggled.value = false;
};

const emitAndRedirect = (navigate, event) => {
	if (props.isMobileMenuToggled) {
		emit("close-mobile-menu");
	}
	navigate(event);
};

const handleDropdownLinkClick = (path) => {
	isDropdownToggled.value = false;
	router.push(path);
};

onMounted(async() => {
	await getProfile();
});

defineExpose({ mobileMenuButton, toggleMobileMenuIcon, closeDropdown, emitAndRedirect });
</script>

<style scoped>
.navbar {
	@apply tw-text-white-high tw-bg-origami-brand-navy tw-h-origami-22 tw-z-[21];
}
.navbar__container {
	@apply tw-box-border tw-flex tw-items-center tw-justify-between tw-w-full tw-h-full tw-py-0 xl:tw-m-auto md:tw-py-0 md:tw-px-4;
}
.navbar__dropdown {
	@apply tw-relative tw-hidden md:tw-block tw-ml-4;
}

ul.dropdown__content {
	@apply tw-list-none tw-m-0 tw-p-0 tw-bg-white-high tw-text-black-high tw-absolute tw-h-auto tw-right-0 tw-mt-4 tw-rounded-lg tw-z-50;
	width: 13rem;
	box-shadow: var(--origami-shadow-md);
}

ul.dropdown__content li a {
	@apply tw-flex tw-items-center tw-no-underline tw-p-2;
	padding: 0.75rem;
	color: inherit;
}

ul.dropdown__content li a:not(:last-of-type) {
	@apply tw-border-b tw-border-origami-grey-100 tw-border-solid;
}

ul.dropdown__content li a:hover, ul.dropdown__content li a:focus-visible {
	@apply tw-bg-origami-grey-100;
}

ul.dropdown__content li a:active {
	@apply tw-bg-origami-grey-200;
}

ul.dropdown__content li a:first-of-type {
	border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
}
ul.dropdown__content li a:last-of-type {
	border-bottom-left-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
}

ul.dropdown__content li a:focus-visible {
	outline: transparent;
	box-shadow: inset 0px 0px 0px 2px var(--origami-blue-300);
}
</style>
