import store from "../store/index.js";

function isUndefined(value) {
	return value === undefined;
}

function evaluateGuards(guards, to, from, next) {
	const guardsLeft = guards.slice(0); // clone the array so we do not accidentally modify it
	const nextGuard = guardsLeft.shift();

	if (isUndefined(nextGuard)) {
		return next();
	}

	nextGuard.bind(store)(to, from, (nextArg) => {
		if (isUndefined(nextArg)) {
			evaluateGuards(guardsLeft, to, from, next);
			return;
		}
		return next(nextArg);
	});
}

export default function(middlewares = []) {
	if (!Array.isArray(middlewares)) {
		throw new Error("You must specify an array of middlewares");
	}

	return (to, from, next) => {
		return evaluateGuards(middlewares, to, from, next);
	};
}
