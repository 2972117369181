var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 26 26",
      },
    },
    [
      _c("defs", [
        _c("path", {
          attrs: {
            id: `a${_vm.randomId}`,
            d: "M0 12c0 6.628 5.374 12 12 12 6.628 0 12-5.372 12-12S18.628 0 12 0C5.374 0 0 5.372 0 12z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            id: `c${_vm.randomId}`,
            d: "M.714 3.244a2.108 2.108 0 1 0 4.216 0 2.108 2.108 0 0 0-4.216 0z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            id: `e${_vm.randomId}`,
            d: "M.746 3.244a2.108 2.108 0 1 0 4.216 0 2.108 2.108 0 0 0-4.216 0z",
          },
        }),
      ]),
      _vm._v(" "),
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            fill: "#ED7B7D",
            d: "M13 25c6.626 0 12-5.372 12-12S19.626 1 13 1 1 6.372 1 13s5.374 12 12 12",
          },
        }),
        _vm._v(" "),
        _c("g", { attrs: { transform: "translate(1 1)" } }, [
          _c("mask", { attrs: { id: `b${_vm.randomId}`, fill: "#fff" } }, [
            _c("use", { attrs: { "xlink:href": `#a${_vm.randomId}` } }),
          ]),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#ED7B7D",
              d: "M-1.2 25.2h26.4V-1.2H-1.2z",
              mask: `url(#b${_vm.randomId})`,
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#ED7B7D",
              d: "M-6 30h36V-6H-6z",
              mask: `url(#b${_vm.randomId})`,
            },
          }),
        ]),
        _vm._v(" "),
        _c("path", {
          attrs: {
            stroke: "#D36063",
            d: "M13 25c6.626 0 12-5.372 12-12S19.626 1 13 1 1 6.372 1 13s5.374 12 12 12z",
          },
        }),
        _vm._v(" "),
        _c("g", { attrs: { transform: "translate(5.8 8.2)" } }, [
          _c("mask", { attrs: { id: `d${_vm.randomId}`, fill: "#fff" } }, [
            _c("use", { attrs: { "xlink:href": `#c${_vm.randomId}` } }),
          ]),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#3C5396",
              d: "M-5.286 11.35h16.217V-4.865H-5.286z",
              mask: `url(#d${_vm.randomId})`,
            },
          }),
        ]),
        _vm._v(" "),
        _c("g", { attrs: { transform: "translate(14.2 8.2)" } }, [
          _c("mask", { attrs: { id: `f${_vm.randomId}`, fill: "#fff" } }, [
            _c("use", { attrs: { "xlink:href": `#e${_vm.randomId}` } }),
          ]),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#3C5396",
              d: "M-5.254 11.35h16.216V-4.865H-5.254z",
              mask: `url(#f${_vm.randomId})`,
            },
          }),
        ]),
        _vm._v(" "),
        _c("path", {
          attrs: {
            stroke: "#3C5396",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "1.5",
            d: "M17.77 20.085s-1.425-2.642-4.818-2.642c-3.394 0-4.816 2.642-4.816 2.642",
          },
        }),
        _vm._v(" "),
        _c("g", { attrs: { stroke: "#3C5396" } }, [
          _c("path", {
            attrs: {
              d: "M15.85 10.65s1.375-2.35 5.125-2.05M9.85 10.65S8.475 8.3 4.725 8.6",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }