import BaseBanner from "../../components/patterns/BaseBanner.vue";

export const getDefaultState = () => ({
	activeBanners: [],
});

const state = getDefaultState();

export const mutations = {
	SHOW_BANNER(state, payload) {
		payload.component = payload.component || BaseBanner;
		state.activeBanners.push(payload);
	},
	CLOSE_BANNER(state, { index }) {
		state.activeBanners.splice(index, 1);
	},
};

export default {
	namespaced: true,
	state,
	mutations,
};
