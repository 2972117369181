<template>
	<OrigamiDialog
		title="Do you want to hide this session?"
		secondary-button-text="Cancel"
		primary-button-text="Yes, hide"
		description="This action will remove visibility on this session."
		:is-open="PlatformManager.ActionCenter.isShowingHideDialog"
		:is-loading="isUpdating"
		@confirm="updateActionItem"
		@cancel="closeModal"
	/>
</template>

<script>
import { mapState } from "vuex";
import { OrigamiDialog } from "@origami/vue2";

import { getErrorText, showErrorModal } from "../../../utilities/errorHandlingHelpers.js";
import { HIDE_ACTION_ID, INAPPROPRIATE_BEHAVIOUR_ID } from "../utilities/actionCenterHelpers.js";
import SessionsAPI from "../../../services/api/Sessions.js";
import EssaysAPI from "../../../services/api/Essays.js";

export default {
	components: {
		OrigamiDialog,
	},
	data() {
		return {
			isUpdating: false,
		};
	},
	computed: {
		...mapState(["PlatformManager"]),
		actionItem() {
			return this.PlatformManager.ActionCenter.selectedActionItem;
		},
	},
	methods: {
		closeModal() {
			this.$store.commit("PlatformManager/ActionCenter/CLOSE_HIDE_DIALOG");
		},
		async updateActionItem() {
			this.isUpdating = true;
			const updatePayload = {
				id: this.actionItem.id,
				params: {
					action_type_id: HIDE_ACTION_ID,
				},
			};
			try {
				await this.$store.dispatch("PlatformManager/ActionCenter/updateActionItem", updatePayload);
				this.actionItem.type_id === INAPPROPRIATE_BEHAVIOUR_ID ?
					await SessionsAPI.hideSession(this.actionItem.content.data.session_id) :
					await EssaysAPI.hideEssay(this.actionItem.content.data.essay_id);
			} catch (error) {
				const message = getErrorText("marking the action item as hidden");
				showErrorModal(this, {
					message,
				});
			} finally {
				this.$store.commit("PlatformManager/ActionCenter/CLOSE_HIDE_DIALOG");
				this.isUpdating = false;
			}
		},
	},
};
</script>
