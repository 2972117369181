var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "NoFilterBanner",
    {
      scopedSlots: _vm._u([
        {
          key: "icon",
          fn: function () {
            return [_c("Calendar", { staticClass: "tw-w-11 tw-h-12" })]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(
        "\n\tSelect at least one of the filters above to view the scheduled shifts\n"
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }