var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    {
      ref: "moisaicGrid",
      staticClass:
        "auto-rows-0 tw-grid tw-gap-4 tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3",
    },
    _vm._l(
      _vm.listRecognitionsSentByUserForSessions,
      function (recognitionsSentByUserForSession, index) {
        return _c("RecognitionsCard", {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "recognition-card",
              expression: "'recognition-card'",
            },
          ],
          key: index,
          ref: "moisaicItem",
          refInFor: true,
          attrs: {
            "from-user": recognitionsSentByUserForSession.fromUser,
            session: recognitionsSentByUserForSession.session,
            recognitions: recognitionsSentByUserForSession.recognitions,
            message: recognitionsSentByUserForSession.message,
            tag: "li",
            "is-interactive": "",
            "is-showing-session-details": "",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.$router.push(
                `/student/classroom?sessionId=${recognitionsSentByUserForSession.session.id}`
              )
            },
          },
        })
      }
    ),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }