import AbstractMessageClass from "./AbstractMessageClass.js";

function SimpleMessage(
	id,
	userId,
	userName,
	createdAt,
	sequencePosition,
	isLastMessage,
	isQuestion,
	isDeleted,
	isFile,
	isTutor,
	text,
) {
	AbstractMessageClass.call(
		this,
		id,
		userId,
		userName,
		createdAt,
		sequencePosition,
		isLastMessage,
		isQuestion,
		isDeleted,
		isFile,
		isTutor,
	);
	this.text = text;
}

SimpleMessage.prototype = Object.create(AbstractMessageClass.prototype);

export default SimpleMessage;
