var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.isLabelWrapped ? "label" : "div",
    {
      tag: "component",
      staticClass: "vue-checkbox",
      class: [_vm.typeClasses[_vm.type]],
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.checked,
            expression: "checked",
          },
          {
            name: "jest",
            rawName: "v-jest",
            value: "input",
            expression: "'input'",
          },
        ],
        attrs: {
          id: _vm.checkboxId,
          type: "checkbox",
          disabled: _vm.isDisabled,
        },
        domProps: {
          value: _vm.checkboxValue,
          checked: _vm.isChecked,
          checked: Array.isArray(_vm.checked)
            ? _vm._i(_vm.checked, _vm.checkboxValue) > -1
            : _vm.checked,
        },
        on: {
          change: [
            function ($event) {
              var $$a = _vm.checked,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = _vm.checkboxValue,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.checked = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.checked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.checked = $$c
              }
            },
            _vm.handleInput,
          ],
        },
      }),
      _vm._v(" "),
      _c(_vm.isLabelWrapped ? "span" : "label", {
        tag: "component",
        staticClass: "checkbox",
        attrs: { for: _vm.checkboxId },
      }),
      _vm._v(" "),
      _c(
        _vm.isLabelWrapped ? "span" : "label",
        {
          tag: "component",
          staticClass: "text",
          attrs: { for: _vm.checkboxId },
        },
        [_c("p", [_vm._t("text")], 2)]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }