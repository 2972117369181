import api from "../../../services/api/Api.js";

export default {
	/**
	 * Create availability
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	create(data) {
		return api().post(`/availabilities`, data);
	},

	/**
	 * @param {object} data
	 * @returns {AxiosPromise<any>}
	 */
	confirm(userId) {
		return api().post(`/tutors/${userId}/availabilities`, { action: "confirm-open-week" });
	},

	/**
	 * Get paginated availabilities
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	filter(params) {
		return api().get(`/availabilities`, {
			params: params,
		});
	},

	/**
	 * Update availability
	 * @param {number} availabilityId
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	update(availabilityId, data) {
		return api().post(`/availabilities/${availabilityId}`, data);
	},

	/**
	 * Delete availability
	 * @param {number} availabilityId
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	delete(availabilityId) {
		return api().delete(`/availabilities/${availabilityId}`);
	},

	/**
	 * Update block shift for a tutor
	 *
	 */
	updateShiftBlocks(tutorId, data) {
		return api().put(`tutors/${tutorId}/availability-blocks`, data);
	},
	/**
	 * Get all block shifts
	 */
	getAllBlocks(tutorId) {
		return api().get(`tutors/${tutorId}/availability-blocks`);
	},
	/* *
	 * Get availabilities for tutor, filtered with from/to
	 * @param userId
	 * @param data
	 * @returns {AxiosPromise<any>}
	 */
	getAvailabilitiesForTutor(userId, data) {
		return api().get(`tutors/${userId}/availabilities`, data);
	},
	/* *
	 * Get open week for tutor
	 * @param userId
	 * @returns {AxiosPromise<any>}
	 */
	getOpenWeekForTutor(userId) {
		return api().get(`tutors/${userId}/availabilities/open-week`);
	},

	/**
	 * Get availability blocks for tutor
	 * @param userId
	 */
	getShiftBlocksForTutor(userId, data) {
		return api().get(`tutors/${userId}/availability-blocks`, data);
	},
	/**
	 * Create and submit availabilities in batch mode for open week
	 * @param tutorId
	 * @param availabilities
	 * @returns {Promise}
	 */
	createBatchAvailabilities(availabilities) {
		return api().post("availabilities/batch", { availabilities });
	},
	/**
	 * Update (create/delete) and submit availabilities in batch mode for open week
	 * @param tutorId
	 * @param availabilities
	 * @returns {Promise}
	 */
	updateBatchAvailabilities(availabilities) {
		return api().put("availabilities/batch", { availabilities });
	},

};
