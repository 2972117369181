import { isEmpty } from "lodash";

export default {
	methods: {
		/**
		 * @param {String} ltiTitle
		 * @returns {Boolean}
		 */
		getIsSyncedWithLTI(ltiTitle) {
			return !isEmpty(ltiTitle);
		},
		/**
		 * @param {String} ltiTitle
		 * @returns {Boolean}
		 */
		getIsSyncedWithClever(ltiTitle) {
			return ltiTitle === "clever";
		},
		/**
		 * @param {String} ltiTitle
		 * @param {Object} header
		 * @param {String} header.value
		 * @returns {Boolean}
		 */
		getIsSyncedWithLTIForTables(itemLtiTitle, header) {
			if (header.value === "synced_with") {
				return this.getIsSyncedWithLTI(itemLtiTitle);
			} else {
				return false;
			}
		},
	},
};
