var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("footer", { staticClass: "footer" }, [
    _c(
      "div",
      { staticClass: "footer__container" },
      [
        _c(_setup.PaperAppQRCode, {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "paper-qr-code",
              expression: "'paper-qr-code'",
            },
          ],
          staticClass: "footer__qr-code",
        }),
        _vm._v(" "),
        _c("div", { staticClass: "app-links" }, [
          _c("h2", { staticClass: "app-links__title" }, [
            _vm._v(
              "\n\t\t\t\t" + _vm._s(_vm.$t("download_paper_app")) + "\n\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "app-links__container" }, [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "google-store-link",
                    expression: "'google-store-link'",
                  },
                ],
                staticClass: "tw-mr-2",
                attrs: {
                  href: "https://play.google.com/store/apps/details?id=com.gradeslam",
                  "aria-label": _vm.$t("google_play_store"),
                  target: "_blank",
                },
              },
              [
                _c(_setup.BadgeGooglePlayStore, {
                  attrs: {
                    width: "125",
                    height: "38",
                    "aria-hidden": "true",
                    focusable: "false",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "apple-store-link",
                    expression: "'apple-store-link'",
                  },
                ],
                attrs: {
                  href: "https://apps.apple.com/ca/app/paper-education/id1360686296",
                  "aria-label": _vm.$t("apple_app_store"),
                  target: "_blank",
                },
              },
              [
                _c(_setup.BadgeAppleStore, {
                  attrs: {
                    width: "124",
                    height: "38",
                    "aria-hidden": "true",
                    focusable: "false",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "privacy-policy" }, [
      _c("p", { staticClass: "privacy-policy__message" }, [
        _c("span", { staticClass: "tw-font-normal" }, [_vm._v("©")]),
        _vm._v(
          "\n\t\t\tPaper " +
            _vm._s(_setup.currentYear) +
            " " +
            _vm._s(_vm.$t("rights_reserved")) +
            "\n\t\t"
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "privacy-policy__divider" }, [_vm._v("•")]),
      _vm._v(" "),
      _c("span", [
        _c(
          "a",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "privacy-policy-link",
                expression: "'privacy-policy-link'",
              },
            ],
            staticClass: "privacy-policy__link",
            attrs: {
              href: "https://paper.co/privacy-policy-services",
              target: "_blank",
            },
          },
          [
            _vm._v(
              "\n\t\t\t\t" +
                _vm._s(_vm.$t("student_navigation.footer.privacy_policy")) +
                "\n\t\t\t"
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }