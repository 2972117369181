import rootInstance from "@/services/root.js";

const baseUrl = process.env.MATH_PROBLEM_API_URL + "/api/v1/";

const mathApi = () => {
	rootInstance.defaults.baseURL = baseUrl;
	return rootInstance;
};

const handleDate = () => {
	const date = new Date();
	date.setDate(date.getDate() - 8);

	return date.getFullYear()
		+ "-" + String(date.getMonth() + 1).padStart(2, "0")
		+ "-" + String(date.getDate()).padStart(2, "0");
};

export default {
	getAllPastProblems: (grade) =>
		mathApi().get(`?grade=${grade}`)
			.then(({ data }) => data),
	getPastProblems: (grade) =>
		mathApi().get(`?grade=${grade}&from=${handleDate()}`)
			.then(({ data }) => data),
	getProblemOfTheDay: (grade) =>
		mathApi().get(`/today-problem?grade=${grade}`)
			.then(({ data }) => data),
	studentStartedProblem: (problemId, payload) =>
		mathApi().post(`/problem/${problemId}/start`, payload),
	studentCompletedProblem: (problemId, payload) =>
		mathApi().put(`/problem/${problemId}/complete`, payload),
	getProblem: (problemId) =>
		mathApi().get(`/problem/${problemId}/status`)
			.then(({ data }) => data),
	getStreaksAndCompleted: () =>
		mathApi().get(`/analytics`)
			.then(({ data }) => data),
	getProblemCompletedCounts: (params) =>
		mathApi().get(`/problem-completed-counts?${params}`)
			.then(({ data: { data } }) => data),
	studentVisit: (payload) =>
		mathApi().post("/student-visit-event", payload),
	modalInteracted: (payload) =>
		mathApi().post("intro-modal-interacted", payload),
	missionCardClick: (payload) =>
		mathApi().post("/mission-card-clicked", payload),
};
