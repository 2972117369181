import { initApiCache, mathApi } from "./apiCache.js";

const apiCache = initApiCache();

export const getTags = () => apiCache.get("/tags");

const invalidateTags = () => apiCache.invalidate("/tags");

export const createTag = (tagLabel) =>
	mathApi().post("/tags", { tagLabel })
		.then(({ data }) => {
			invalidateTags();
			return data;
		});

export default { getTags, createTag };
