<script setup>
import { OrigamiButton } from "@origami/vue2";
import { computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router/composables";
import { isEmpty, isEqual, omitBy } from "lodash";

import useStore from "@/composables/useStore.js";

import { useLoading } from "../composables/loading.js";

import ServiceAnalyticsFilterSidebar from "./ServiceAnalyticsFilterSidebar.vue";
import FilterTag from "./FilterTag.vue";


const store = useStore();
const router = useRouter();
const route = useRoute();

const isLoading = useLoading();

const selectedFilters = computed(() => store.state.ServiceAnalytics.selectedFilters);

const closeFilterTagsGroup = async(name) => {
	store.dispatch("ServiceAnalytics/clearSelectedFiltersGroup", name);
};

const updateRouterParams = (selectedFilters) => {
	const isFilterDefined = (item) => !(!isEmpty(item));

	const { Subjects, Requirements } = selectedFilters.value;

	const params = {
		subjects: Subjects?.map(({ id }) => id),
		special_requirements: Requirements?.map(({ id }) => id),
	} || {};

	const urlParam = omitBy(params, isFilterDefined);
	const filters = omitBy(route.query, isFilterDefined);
	if (!isEqual(urlParam, filters)) {
		router.replace({ path: route.path, query: params });
	}
};

watch(selectedFilters, async(newValue) => {
	if (newValue) {
		updateRouterParams(selectedFilters);
		await store.dispatch("ServiceAnalytics/refreshResults");
	}
});
</script>

<template>
	<div>
		<div class="tw-flex tw-flex-wrap tw-justify-between">
			<div class="tw-flex">
				<OrigamiButton
					v-jest="`open-filters-sidebar-button`"
					:data-cy="`open-filters-sidebar-button`"
					variant="secondary"
					aria-label="Filters"
					:class="{
						'!tw-bg-origami-grey-200 !tw-text-transparent !tw-border-none': isLoading,
					}"
					:is-disabled="isLoading"
					@click.native="store.dispatch('ServiceAnalytics/openFiltersSidebar')"
				>
					Filters
				</OrigamiButton>
				<div
					v-if="!isLoading"
					v-jest="`service-analytics-filter-tag-container`"
					class="tw-flex tw-ml-3 tw-overflow-hidden"
				>
					<FilterTag
						v-for="(filter, key) in selectedFilters"
						:key="key"
						v-jest="`service-analytics-filter-tag`"
						:tags="filter"
						:name="key"
						@close-filter-tags-group="closeFilterTagsGroup"
					/>
				</div>
			</div>
		</div>
		<ServiceAnalyticsFilterSidebar
			v-jest="`service-analytics-filters-sidebar`"
		/>
	</div>
</template>
