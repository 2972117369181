var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShowingCalendar,
          expression: "isShowingCalendar",
        },
      ],
      staticClass:
        "schedule__coveredContainer tw-flex tw-p-5 tw-max-w-5xl tw-m-auto",
    },
    [
      _c(
        "div",
        { staticClass: "schedule__hoursContainer" },
        [
          _c("p", { staticClass: "schedule__totalHoursBanner" }, [
            _vm._v("\n\t\t\tLanguage Covered Hours\n\t\t"),
          ]),
          _vm._v(" "),
          _vm._l(_vm.totalLanguageHours, function (language) {
            return _c(
              "div",
              { key: language.id, staticClass: "schedule__languageHours" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "language-covered-hours",
                        expression: "'language-covered-hours'",
                      },
                    ],
                  },
                  [
                    _c("span", { staticClass: "schedule__itemLabel" }, [
                      _vm._v(_vm._s(language.language) + ":"),
                    ]),
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(Math.round(language.hours)) +
                        " hours\n\t\t\t"
                    ),
                  ]
                ),
              ]
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "schedule__hoursContainer" }, [
        _c("p", { staticClass: "schedule__totalHoursBanner" }, [
          _vm._v("\n\t\t\tSubject Covered Hours\n\t\t"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "schedule__listContainer" },
          _vm._l(_vm.totalSubjectHours, function (subject, index) {
            return _c(
              "div",
              { key: index, staticClass: "schedule__languageHours" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "subject-covered-hours",
                        expression: "'subject-covered-hours'",
                      },
                    ],
                    staticClass: "schedule__hoursList",
                  },
                  [
                    _c("span", { staticClass: "schedule__itemLabel" }, [
                      _vm._v(_vm._s(subject.name) + ":"),
                    ]),
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(Math.round(subject.hours)) +
                        " hours\n\t\t\t\t"
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }