import { CommentAnnotation, SuggestionAnnotation } from "../classes/paper-annotations.js";

export function isComment(annotation) {
	return annotation instanceof CommentAnnotation;
}

export function isSavedComment(annotation) {
	return isComment(annotation) && !!annotation.comment;
}

export function isUnsavedComment(annotation) {
	return isComment(annotation) && !isSavedComment(annotation);
}

export function isSuggestion(annotation) {
	return annotation instanceof SuggestionAnnotation;
}
