import rootInstance from "@/services/root.js";

const scheduleApi = () => {
	rootInstance.defaults.baseURL = `${process.env.MIX_SCHEDULE_API_BASE_URI}/api/v1`;
	return rootInstance;
};

export default {
	getRiskLevels() {
		return scheduleApi().get("/analytics/risk-levels");
	},
	getTutorLoad(params) {
		return scheduleApi().get("/analytics/tutor-load", params);
	},
	getTutorLoadBySubject(subjectId, params) {
		return scheduleApi().get(`/analytics/tutor-load/subject/${subjectId}`, params);
	},
};

