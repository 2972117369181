var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    _vm._b(
      {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "break-button",
            expression: "'break-button'",
          },
        ],
        staticClass:
          "tw-relative tw-rounded-full tw-block tw-w-10 tw-h-10 breakButton tw-border-[1px] tw-border-origami-grey-200 tw-bg-origami-white hover:tw-bg-origami-blue-300 hover:tw-text-origami-white tw-mr-1.5",
        class: {
          "tw-bg-origami-blue-400 tw-text-origami-white hover:tw-bg-origami-blue-400":
            _vm.isActive,
        },
      },
      "button",
      _vm.$attrs,
      false
    ),
    [
      _c("OrigamiIcon", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "break-button-icon",
            expression: "'break-button-icon'",
          },
        ],
        staticClass: "tw-block",
        attrs: { name: _vm.iconName },
      }),
      _vm._v(" "),
      _vm.showNotification
        ? _c("span", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "break-button-notification",
                expression: "'break-button-notification'",
              },
            ],
            staticClass:
              "tw-w-3 tw-h-3 tw-rounded-full tw-absolute tw-bottom-0 tw-right-0 tw-bg-origami-red-400",
            class: {
              activeNotification: _vm.isActive,
              "tw-bg-origami-red-200": _vm.$attrs["disabled"],
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }