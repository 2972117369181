<template>
	<span
		v-jest="'shift-details'"
		v-data-cy="'shift-details'"
		class="tw-font-bold tw-text-sm"
	>
		{{ formattedCurrentShift }}
	</span>
</template>

<script>
import { formatIsoDateToHM } from "@/utilities/dateHelpers.js";

export default {
	name: "CurrentShiftDetails",
	computed: {
		formattedCurrentShift() {
			const currentShift = this.$store.state.TutorSchedule.currentShift;
			const shiftStartTime = currentShift?.start_time;
			const shiftEndTime = currentShift?.end_time;
			return shiftStartTime && shiftEndTime ?
				`${formatIsoDateToHM(shiftStartTime)} - ${formatIsoDateToHM(shiftEndTime)}`
				: "";
		},
	},
};
</script>
