var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 20.704 26",
        "enable-background": "new 0 0 20.704 26",
        "xml:space": "preserve",
      },
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            fill: _vm.iconColor,
            d: "M12.424,23.5H8.28c-0.158,0-0.285,0.128-0.285,0.285c0,1.222,1.057,2.216,2.356,2.216\n            s2.357-0.994,2.357-2.216C12.709,23.627,12.582,23.5,12.424,23.5z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            fill: _vm.iconColor,
            d: "M17.274,17.097c0.239-0.685,0.36-1.389,0.36-2.099V9.24c0-2.937-1.971-5.5-4.926-6.441V2.217\n            C12.709,0.995,11.651,0,10.352,0S7.995,0.995,7.995,2.217v0.58C5.04,3.741,3.07,6.304,3.07,9.24v5.758\n            c0,0.714,0.12,1.418,0.359,2.099C1.479,17.204,0,18.197,0,19.445v2.073c0,0.158,0.127,0.285,0.285,0.285h20.133\n            c0.158,0,0.286-0.127,0.286-0.285v-2.073C20.704,18.197,19.224,17.204,17.274,17.097z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }