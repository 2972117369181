import Vue from "vue";
import { isEmpty, isNil } from "lodash";
import { getFlag } from "@paper-co/web-toolkit";

import { SectionsAPI } from "@/modules/Customers/index.js";
import AssessmentClass from "@/modules/Classroom/classes/Assessment.js";
import ChatIntro from "@/modules/Classroom/classes/ChatIntro.js";
import SessionStoreHelpers from "@/utilities/SessionStoreHelpers.js";
import { pascalCase } from "@/utilities/strings.js";
import { TutorAssessmentApi, ASSESSMENTS_STEPS } from "@/modules/TutorAssessment/index.js";
import GradesAPI from "@/services/api/Grades.js";
import MessagesAPI from "@/services/api/Messages.js";
import SchedulesAPI from "@/services/api/Schedules.js";
import SessionsAPI from "@/services/api/Sessions.js";
import StudentsAPI from "@/services/api/Students.js";
import SubjectsAPI from "@/services/api/Subjects.js";
import TutorCommentsAPI from "@/services/api/TutorComments.js";

const getDefaultState = () => ({
	subjects: {},
	assessments: {},
	numPendingAssessments: 0,
	sessionIdsToBeDeleted: [],
	handoffData: {},
	searchText: "",
	selectedSubjectIndex: -1,
	selectedTopicIndex: -1,
	isConnected: true,
	toast: null,
	isShowingTransferSessionModal: false,
	isShowingEndSessionDialog: false,
});
export const state = getDefaultState();

const getters = {
	firstAssessment(state) {
		const keys = Object.keys(state.assessments);
		if (keys.length !== 0) {
			return state.assessments[keys[0]];
		}
		return null;
	},
	isAssessment(state, getters, rootState) {
		return (
			!isNil(rootState.Classroom.currentSessionId) &&
            !isNil(state.assessments[rootState.Classroom.currentSessionId]) &&
            !isNil(state.assessments[rootState.Classroom.currentSessionId].assessment)
		);
	},
	sessionUsers(state, getters, rootState, rootGetters) {
		return !isNil(rootGetters["Classroom/currentSession"]) ? rootGetters["Classroom/currentSession"].users : null;
	},
	assessmentQuestions(state, getters, rootState, rootGetters) {
		let questions = [];
		if (getters.isAssessment && !isEmpty(rootGetters["Classroom/currentSessionMessages"])) {
			questions = rootGetters["Classroom/currentSessionMessages"].filter((message) => {
				if (message.message !== undefined) {
					return message.message.isQuestion;
				}
			});
		}
		return questions;
	},
};

export const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	DELETE_ASSESSMENT_IN_ASSESSMENTS(state, { id }) {
		Vue.delete(state.assessments, id);
	},
	EDIT_ASSESSMENT_IN_SESSION_IN_ASSESSMENTS(state, payload) {
		const sessionId = payload.id;
		const key = payload.key;
		const value = payload.value;

		Vue.set(state.assessments[sessionId]?.assessment, key, value);
	},
	EDIT_SESSION_IN_ASSESSMENTS(state, payload) {
		const sessionId = payload.id;
		const key = payload.key;
		const value = payload.value;

		Vue.set(state.assessments[sessionId], key, value);
	},
	INSERT_IN_ASSESSMENTS(state, { assessment, sessionId }) {
		Vue.set(state.assessments, sessionId, assessment);
	},
	EMPTY_SESSION_IDS_TO_BE_DELETED(state) {
		state.sessionIdsToBeDeleted = [];
	},
	PUSH_TO_SESSION_IDS_TO_BE_DELETED(state, payload) {
		state.sessionIdsToBeDeleted.push(payload.sessionId);
	},
	SET_ASSESSMENT_SESSIONS(state, payload) {
		state.assessments = payload.assessments;
	},
	SET_IS_LOADED_IN_ASSESSMENTS(state, payload) {
		if (state.assessments[payload.sessionId] !== undefined) {
			state.assessments[payload.sessionId].isLoaded = payload.isLoaded;
		}
	},
	SET_MESSAGES_IN_ASSESSMENT(state, { messages, sessionId }) {
		try {
			if (state.assessments[sessionId] !== undefined) {
				state.assessments[sessionId].messages = messages;
			}
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	SET_NUM_PENDING_ASSESSMENTS(state, payload) {
		state.numPendingAssessments = payload.numPendingAssessments;
	},
	SET_SUBJECTS(state, payload) {
		state.subjects = payload.subjects;
	},
	SET_HANDOFF_DATA(state, payload) {
		state.handoffData = payload.data;
	},
	TOGGLE_IS_QUESTION_FOR_MESSAGE_IN_SESSION(state, { index, isAssessment, currentSessionId, sessions }) {
		try {
			const sessionId = currentSessionId;
			const messageIndex = index;

			if (isAssessment) {
				const isQuestion = !!state.assessments[sessionId].messages[messageIndex].message
					.isQuestion;

				Vue.set(
					state.assessments[sessionId].messages[messageIndex].message,
					"isQuestion",
					!isQuestion,
				);
			} else {
				const isQuestion = !!sessions[sessionId].messages[messageIndex]
					.message.isQuestion;

				Vue.set(
					sessions[sessionId].messages[messageIndex].message,
					"isQuestion",
					!isQuestion,
				);
			}
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	SET_QUESTION_MATCHER_ASSESSMENT_QUESTION(state, { sessionId, index }) {
		Vue.set(
			state.assessments[sessionId].messages[index].message,
			"isQuestion",
			true,
		);
	},
	SPLICE_LAST_MESSAGE_IN_ASSESSMENT(state, payload) {
		state.assessments[payload.sessionId].messages.pop();
	},
	SET_SEARCH_TEXT(state, payload) {
		state.searchText = payload.searchText;
	},
	SET_SELECTED_SUBJECT_INDEX(state, payload) {
		state.selectedSubjectIndex = payload.selectedSubjectIndex;
	},
	SET_SELECTED_TOPIC_INDEX(state, payload) {
		state.selectedTopicIndex = payload.selectedTopicIndex;
	},
	SET_USER_IS_CONNECTED(state, { isConnected }) {
		state.isConnected = isConnected;
	},
	SET_TOAST(state, { toast }) {
		state.toast = toast;
	},
	TOGGLE_TRANSFER_SESSION_MODAL(state, { isOpen }) {
		state.isShowingTransferSessionModal = isOpen;
	},
	TOGGLE_END_SESSION_DIALOG(state, { isOpen }) {
		state.isShowingEndSessionDialog = isOpen;
	},
};

export const actions = {
	async endSession({}, payload) {
		try {
			const response = await SessionsAPI.endSession(payload);
			return Promise.resolve(response);
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject(error);
		}
	},
	async removeLastMessageInMultipleSessions({ commit }, { sessionIds }) {
		try {
			sessionIds.forEach((id) => {
				commit("SPLICE_LAST_MESSAGE_IN_ASSESSMENT", {
					sessionId: id,
				});
			});
			return Promise.resolve();
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject(error);
		}
	},
	async findNextSessionToOpen({ commit, dispatch, rootState, rootGetters }) {
		try {
			commit("Classroom/SET_IS_INPUT_AREA_DISABLED", {
				isDisabled: false,
			}, { root: true });
			if (rootState.Classroom.leftSidebarComponent === "sidebar-sessions") {
				let nextSessionId = null;
				const individualSessions = rootGetters["Classroom/individualSessions"];
				if (Object.keys(individualSessions).length > 0) {
					nextSessionId = Object.values(individualSessions)[0].id;
				}

				if (nextSessionId !== null) {
					await dispatch("Classroom/setCurrentSession", nextSessionId, { root: true });
				} else {
					commit("Classroom/SET_CURRENT_SESSION_ID", {
						id: null,
					}, { root: true });
				}
			}
			return Promise.resolve();
		} catch (e) {
			Sentry.captureException(e);
			return Promise.reject(e);
		}
	},
	async getAssessmentMessages({ rootState }, sessionId) {
		try {
			const params = { include: "user.roles" };
			const messages = await MessagesAPI.getSessionMessages(sessionId, params);
			const sessionMessagesArray = [];
			messages.data.data.forEach((message) => {
				const preparedMessageData = SessionStoreHelpers.normalizeApiMessage(
					message,
					sessionId,
					rootState.currentUser,
				);
				const lastMessageInSession =
					sessionMessagesArray.length > 0
						? sessionMessagesArray[sessionMessagesArray.length - 1]
						: null;
				if (lastMessageInSession !== null) {
					SessionStoreHelpers.updateLastSessionMessageInArray(
						lastMessageInSession, message.user_id,
					);
				}
				const messageFactoryMessage = SessionStoreHelpers.getMessageFactoryMessage({
					data: preparedMessageData,
					lastMessageInSession,
				});
				sessionMessagesArray.push(messageFactoryMessage);
			});
			return Promise.resolve(sessionMessagesArray);
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	async selectAssessment({ state, commit, dispatch, rootState, rootGetters }, sessionId) {
		commit("Classroom/SET_CURRENT_SESSION_ID", {
			id: sessionId,
		}, { root: true });
		if (state.assessments[sessionId].isLoaded === false) {
			const response = await dispatch("getAssessmentMessages", sessionId);
			await commit("SET_MESSAGES_IN_ASSESSMENT", {
				messages: response,
				sessionId: sessionId,
			});
			if (
				!isEmpty(rootGetters["Classroom/currentSessionMessages"]) &&
                rootGetters["Classroom/currentSessionMessages"][0].constructor.name ===
                    "QuestionMatcherMessage" &&
                    rootGetters["Classroom/currentSessionMessages"][0].message.text !== "file"
			) {
				commit("SET_QUESTION_MATCHER_ASSESSMENT_QUESTION", {
					index: 0,
					sessionId: rootState.Classroom.currentSessionId,
				});

				await MessagesAPI.update(rootGetters["Classroom/currentSessionMessages"][0].id, {
					is_question: true,
				});
			}
			commit("SET_IS_LOADED_IN_ASSESSMENTS", {
				sessionId: sessionId,
				isLoaded: true,
			});
		}
	},
	async getSubjects({ commit }) {
		try {
			const params = {
				include: "availableTopics",
			};

			const response = await SubjectsAPI.list(params);
			const subjects = response.data.data;
			const subjectsById = {};
			const subjectsKeys = Object.keys(subjects);


			for (const key of subjectsKeys) {
				const subjectId = subjects[key].id;
				const subjectName = subjects[key].name;

				subjectsById[subjectId] = subjects[key];

				subjectsById[subjectId].langKey = pascalCase(subjectName);

				const topicsById = {};
				const topics = subjectsById[subjectId].availableTopics.data;

				for (const topic of topics) {
					const topicId = topic.id;
					const topicName = topic.name;

					topicsById[topicId] = topic;

					topicsById[topicId].langKey = pascalCase(topicName);
				}

				subjectsById[subjectId].topics = topicsById;
			}

			commit("SET_SUBJECTS", {
				subjects: subjectsById,
			});
			return Promise.resolve(subjects);
		} catch (error) {
			return Promise.reject(error.response.status);
		}
	},
	async updateMessage({}, payload) {
		try {
			const response = await MessagesAPI.update(payload.id, payload.data);

			return Promise.resolve(response);
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	async getIncompleteAssessmentSessions({ commit }) {
		commit("Classroom/SET_IS_LOADING_SESSIONS", {
			isLoadingSessions: true,
		}, { root: true });

		try {
			const assessmentsLimit = await getFlag("SER-1188-limit-assessments") || 10;
			const params = {
				include: "activeUsers,topics,subjects",
				per_page: assessmentsLimit,
			};

			const response = await SessionsAPI.getIncompleteAssessmentSessionsForUser(params);
			let assessments = {};
			if (response.data.length !== 0) {
				assessments = response.data;
			}
			for (const key in assessments) {
				Vue.set(assessments[key].assessment, "step", ASSESSMENTS_STEPS.RECOGNITIONS);
				!isNil(assessments[key].requested_topic)
					? (assessments[key].requested_topic.name = pascalCase(assessments[key].requested_topic.name))
					: null;
				!isNil(assessments[key].requested_subject)
					? (assessments[key].requested_subject.name = pascalCase(assessments[key].requested_subject.name))
					: null;
				!isNil(assessments[key].topic)
					? (assessments[key].topic.name = pascalCase(assessments[key].topic.name))
					: null;
				!isNil(assessments[key].subject)
					? (assessments[key].subject.name = pascalCase(assessments[key].subject.name))
					: null;
			}
			commit("SET_ASSESSMENT_SESSIONS", {
				assessments: assessments,
			});

			return Promise.resolve();
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject(error.response);
		} finally {
			commit("Classroom/SET_IS_LOADING_SESSIONS", {
				isLoadingSessions: false,
			}, { root: true });
		}
	},

	async removeSessionAndMoveToAssessments({ commit, dispatch }, payload) {
		try {
			await dispatch("addSessionToAssessments", payload);

			commit("Classroom/DELETE_SESSIONS", {
				sessionId: payload.sessionId,
			}, { root: true });

			dispatch("findNextSessionToOpen");
		} catch (e) {
			Sentry.captureException(e);
		}
	},

	async addSessionToAssessments({ commit, rootState }, payload) {
		const assessment = new AssessmentClass(
			new Date(),
			payload.assessmentId,
			payload.sessionId,
			ASSESSMENTS_STEPS.RECOGNITIONS,
		);
		commit("Classroom/EDIT_SESSION_IN_SESSIONS", {
			sessionId: payload.sessionId,
			key: "assessment",
			value: assessment,
		}, { root: true });
		const firstMessage = rootState.Classroom.sessions[payload.sessionId].messages[0];
		if (firstMessage instanceof ChatIntro) {
			commit("Classroom/SHIFT_MESSAGES_IN_SESSION", {
				sessionId: payload.sessionId,
			}, { root: true });
		}
		commit("INSERT_IN_ASSESSMENTS", {
			sessionId: payload.sessionId,
			assessment: rootState.Classroom.sessions[payload.sessionId],
		});

		const newCount = state.numPendingAssessments + 1;
		commit("SET_NUM_PENDING_ASSESSMENTS", {
			numPendingAssessments: newCount,
		});
	},
	async getPendingAssessmentCount({ commit }, tutorId) {
		try {
			const response = await TutorAssessmentApi.getCountPendingAssessmentsByUserId(tutorId);
			commit("SET_NUM_PENDING_ASSESSMENTS", {
				numPendingAssessments: response.data.total_incomplete_assessments,
			});
			return Promise.resolve();
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject();
		}
	},
	async loadMoreAssessments({ state, commit }, payload) {
		try {
			const assessmentsLimit = await getFlag("SER-1188-limit-assessments") || 10;
			const params = {
				include: "activeUsers,topics,subjects",
				page: payload.pageNumber,
				per_page: assessmentsLimit,
			};
			const response = await SessionsAPI.getIncompleteAssessmentSessionsForUser(params);

			const assessments = response.data;
			for (const key in assessments) {
				if (state.assessments[key] === undefined || state.assessments[key] === null) {
					Vue.set(assessments[key].assessment, "step", ASSESSMENTS_STEPS.RECOGNITIONS);
					assessments[key].requested_topic !== null
						? (assessments[key].requested_topic.name = pascalCase(
							assessments[key].requested_topic.name,
						))
						: null;
					assessments[key].requested_subject !== null
						? (assessments[key].requested_subject.name = pascalCase(
							assessments[key].requested_subject.name,
						))
						: null;

					!isEmpty(assessments[key].topic)
						? (assessments[key].topic.name = pascalCase(assessments[key].topic.name))
						: null;
					!isEmpty(assessments[key].subject)
						? (assessments[key].subject.name = pascalCase(assessments[key].subject.name))
						: null;

					commit("INSERT_IN_ASSESSMENTS", {
						sessionId: key,
						assessment: assessments[key],
					});
				}
			}

			return Promise.resolve();
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject();
		}
	},
	async updateSchedule({}, payload) {
		try {
			const response = await SchedulesAPI.update(payload.scheduleId, payload.params);
			if (response.status == 200) {
				return Promise.resolve();
			} else {
				throw Error();
			}
		} catch (e) {
			Sentry.captureException(e);
			return Promise.reject();
		}
	},
	async updateAssessment({}, payload) {
		try {
			const response = await TutorAssessmentApi.update(
				payload.assessment_id,
				payload.assessmentData,
			);
			return Promise.resolve(response);
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject(error);
		}
	},
	async getClassroom({}, payload) {
		try {
			const { data } = await SectionsAPI.show(payload.sectionId, payload.params);
			return Promise.resolve(data);
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject(error);
		}
	},
	async createTutorComments({}, payload) {
		try {
			const response = await TutorCommentsAPI.create(payload);
			return Promise.resolve(response);
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject(error);
		}
	},
	async getStudentGrades({}) {
		try {
			const { data } = await GradesAPI.list();
			return Promise.resolve(data);
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject(error);
		}
	},
	async getAcademicUnitAnalysis({}, payload) {
		try {
			const response = await TutorAssessmentApi.sendQuestionsForAcademicUnitsAnalysis(
				payload.params.sessionId,
				payload.params.data,
			);
			return Promise.resolve(response);
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject(error);
		}
	},
	async updateStudent({}, payload) {
		try {
			const response = await StudentsAPI.update(payload.studentId, payload.params);
			return Promise.resolve(response);
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject(error);
		}
	},
	async getAllAcademicUnits({}) {
		try {
			const response = await TutorAssessmentApi.getAcademicUnits({
				include: "availableTopics",
			});
			return Promise.resolve(response);
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject(error);
		}
	},
	async getTutorCommentsForUser({}, userId) {
		try {
			const { data } = await TutorCommentsAPI.list(userId);
			return Promise.resolve(data);
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject(error);
		}
	},
	async updateSession({}, payload) {
		try {
			const sessionId = payload.id;
			const data = payload.data;

			await SessionsAPI.update(sessionId, data);

			return Promise.resolve(sessionId);
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject(error.response);
		}
	},
	toggleTransferSessionModal({ commit }, payload) {
		commit("TOGGLE_TRANSFER_SESSION_MODAL", {
			isOpen: payload,
		});
	},
	toggleEndSessionDialog({ commit }, payload) {
		commit("TOGGLE_END_SESSION_DIALOG", {
			isOpen: payload,
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
