var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _setup.closeDropdown,
          expression: "closeDropdown",
        },
      ],
      staticClass: "tw-relative",
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "language-picker",
              expression: "'language-picker'",
            },
          ],
          ref: "languagePicker",
          staticClass: "dropdown",
          class: { "dropdown--active": _setup.isExpanded },
          attrs: {
            id: _setup.generateId("dropdown"),
            "aria-controls": _setup.listBoxId,
            "aria-expanded": Boolean(_setup.isExpanded).toString(),
            "aria-haspopup": "listbox",
            role: "combobox",
            tabindex: "0",
          },
          on: {
            keydown: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _setup.toggleDropdown.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape",
                  ])
                )
                  return null
                return _setup.closeDropdown.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown",
                  ])
                )
                  return null
                $event.preventDefault()
                return _setup.focusListItem(_setup.selectedItemIndex)
              },
            ],
            click: _setup.toggleDropdown,
          },
        },
        [
          _c(
            "span",
            [
              !_setup.store.state.Lang.isLoading
                ? _c(_setup.OrigamiIcon, {
                    attrs: { name: "social-studies", width: 16, height: 16 },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("label", { attrs: { id: "dropdown-label" } }, [
            _vm._v("\n\t\t\t" + _vm._s(_setup.preferredLangTitle) + "\n\t\t"),
          ]),
          _vm._v(" "),
          _c(
            "span",
            [
              _c(_setup.OrigamiIcon, {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "dropdown-caret",
                    expression: "'dropdown-caret'",
                  },
                ],
                attrs: {
                  name: _setup.isExpanded ? "caret-active" : "caret",
                  width: 12,
                  height: 12,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _setup.isExpanded,
              expression: "isExpanded",
            },
            {
              name: "jest",
              rawName: "v-jest",
              value: "dropdown-menu",
              expression: "'dropdown-menu'",
            },
          ],
          staticClass: "dropdown__menu",
          attrs: {
            id: _setup.listBoxId,
            role: "listbox",
            "aria-labelledby": "dropdown-label",
          },
        },
        _vm._l(_setup.store.state.Lang.publicLanguages, function (lang, index) {
          return _c("li", { key: lang.id }, [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "language-option",
                    expression: "'language-option'",
                  },
                ],
                ref: "dropdownLink",
                refInFor: true,
                staticClass: "dropdown__menu-item",
                class: {
                  "dropdown__menu-item--selected":
                    lang.abbreviation === _setup.preferredLang?.abbreviation,
                },
                attrs: {
                  id: `dropdown-menu-item-${index}`,
                  role: "option",
                  "aria-selected":
                    lang.abbreviation === _setup.preferredLang?.abbreviation
                      ? "true"
                      : "false",
                },
                on: {
                  keydown: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "up", 38, $event.key, [
                          "Up",
                          "ArrowUp",
                        ])
                      )
                        return null
                      $event.preventDefault()
                      return _setup.focusListItem(index - 1)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "down", 40, $event.key, [
                          "Down",
                          "ArrowDown",
                        ])
                      )
                        return null
                      $event.preventDefault()
                      return _setup.focusListItem(index + 1)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k(
                          $event.keyCode,
                          "home",
                          undefined,
                          $event.key,
                          undefined
                        )
                      )
                        return null
                      $event.preventDefault()
                      return _setup.focusListItem(0)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _setup.handleLanguageChange(lang)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, [
                          "Esc",
                          "Escape",
                        ])
                      )
                        return null
                      return _setup.focusDropdown.apply(null, arguments)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                      )
                        return null
                      if (
                        $event.ctrlKey ||
                        $event.shiftKey ||
                        $event.altKey ||
                        $event.metaKey
                      )
                        return null
                      return _setup.handleDropdownTab(index)
                    },
                  ],
                  click: function ($event) {
                    return _setup.handleLanguageChange(lang)
                  },
                },
              },
              [
                _vm._v("\n\t\t\t\t" + _vm._s(lang.language) + "\n\t\t\t\t"),
                lang.abbreviation === _setup.preferredLang?.abbreviation
                  ? _c(_setup.OrigamiIcon, {
                      staticClass: "tw-text-origami-blue-300",
                      attrs: { name: "check", width: 14 },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }