var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ServiceDashboardTutorCardHeaderSkeleton"),
      _vm._v(" "),
      _vm.isSectionWithSessions
        ? _c("ServiceDashboardListSkeleton", {
            scopedSlots: _vm._u(
              [
                {
                  key: "left",
                  fn: function () {
                    return [_vm._v("\n\t\t\tOngoing Sessions\n\t\t")]
                  },
                  proxy: true,
                },
                {
                  key: "right",
                  fn: function () {
                    return [_vm._v("\n\t\t\tTotal Sessions\n\t\t")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2135598777
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isSectionWithEssays
        ? _c("ServiceDashboardListSkeleton", {
            attrs: { resource: "essay" },
            scopedSlots: _vm._u(
              [
                {
                  key: "left",
                  fn: function () {
                    return [_vm._v("\n\t\t\tEssay\n\t\t")]
                  },
                  proxy: true,
                },
                {
                  key: "right",
                  fn: function () {
                    return [_vm._v("\n\t\t\tTotal Reviewed Essays\n\t\t")]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1909626424
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }