<template>
	<div
		ref="searchDropdown"
		class="baseSearchDropdown"
	>
		<label
			v-if="isShowingLabel"
			:for="id"
		>{{ label }}</label>
		<input
			:id="id"
			class="form-control"
			:autoComplete="autoComplete"
			:type="type"
			:disabled="isDisabled"
			:placeholder="placeholder"
			:value="value"
			@input="handleInput"
		>
		<div>
			<ul
				v-if="showDropdown"
				:id="dropdownId"
				class="dropContainer"
			>
				<li class="dropContainer__otherItems">
					Select one of the existing tags
				</li>
				<li
					v-for="(item, index) in filteredItems"
					:key="index"
					class="dropContainer__item"
					@click="closeDropdown"
				>
					<slot
						:item="item"
						:index="index"
					/>
				</li>
			</ul>
			<span
				v-else-if="value && filteredItems.length === 0"
				class="dropContainer__otherItems"
			>No tag found</span>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		label: {
			type: String,
			default: "",
		},
		placeholder: {
			type: String,
			default: "",
		},
		id: {
			type: String,
			default: "",
		},
		type: {
			type: String,
			default: "text",
		},
		// eslint-disable-next-line vue/require-default-prop
		value: [String, Number],
		isDisabled: {
			type: Boolean,
			default: false,
		},
		items: {
			type: Array,
			default() {
				return ["No results found"];
			},
		},
		autoComplete: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isVisible: true,
			dropdownId: "",
			filteredItems: [],
		};
	},
	computed: {
		isShowingLabel() {
			return this.label !== "";
		},
		showDropdown() {
			return this.value && this.filteredItems.length !== 0 && this.isVisible;
		},
	},
	mounted() {
		this.setDropdownIds();
	},
	methods: {
		setDropdownIds() {
			this.dropdownId = Math.random()
				.toString(36)
				.substring(2, 15);
		},
		handleInput(e) {
			this.isVisible = true;
			this.filteredItems = this.items.filter((item) => {
				return (
					item.name
						.toLowerCase()
						.replace(/\s/g, "")
						.indexOf(e.target.value.toLowerCase().replace(/\s/g, "")) !== -1
				);
			});

			this.$emit("input", e.target.value);
		},
		closeDropdown() {
			this.isVisible = false;
		},
	},
};
</script>
<style scoped>
input {
	height: 44px;
	border-radius: 5px;
	border: 2px solid var(--c-gray);
	outline: none;
	padding: 6px 16px;
	font-family: "MonumentGrotesk", "Open Sans", sans-serif;
	font-size: 12px;
	color: var(--c-black);
}

input:focus {
	border-color: var(--c-blue);
}

input:disabled {
	color: var(--c-black) 99;
}

input.inputField_error {
	border-color: var(--c-orange);
}

input.inputField_success {
	border-color: var(--c-green);
}

input.inputField_disabled {
	border-color: var(--c-gray);
	background-color: var(--c-gray);
}

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	font-family: "MonumentGrotesk", "Open Sans", sans-serif;
	font-size: 12px;
}
::-moz-placeholder {
	/* Firefox 19+ */
	font-family: "MonumentGrotesk", "Open Sans", sans-serif;
	font-size: 12px;
}
:-ms-input-placeholder {
	/* IE 10+ */
	font-family: "MonumentGrotesk", "Open Sans", sans-serif;
	font-size: 12px;
}
:-moz-placeholder {
	/* Firefox 18- */
	font-family: "MonumentGrotesk", "Open Sans", sans-serif;
	font-size: 12px;
}

label {
	font-family: "MonumentGrotesk", "Open Sans", sans-serif;
	font-size: 12px;
	font-weight: 600;
	font-style: normal;
	line-height: normal;
}

.paddingIcon {
	padding-left: 33px;
}
.dropContainer {
	background: var(--c-white);
	border-radius: 5px;
	box-shadow: 0 10px 20px 0 rgba(60, 83, 150, 0.5);
	padding: 9px 9px;
	list-style-type: none;
	transform-origin: top;
	transition: transform 0.2s ease-in-out;
	overflow: hidden;
	width: -webkit-fill-available;
	width: -moz-available;
	width: 100%;
	z-index: 3;
	overflow-y: scroll;
	overflow-x: auto;
	height: 162px;
}
.dropContainer__otherItems {
	font-size: 12px;
	color: var(--c-blue);
}
.dropContainer__item {
	font-size: 14px;
	color: var(--c-black);
	padding: 9px 8px;
}
.dropContainer__item:hover {
	cursor: pointer;
	background-color: rgba(88, 108, 178, 0.2);
}
.slide-enter,
.slide-leave-to {
	transform: scaleY(0);
}
</style>
