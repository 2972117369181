var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BModal",
    {
      ref: "superuser-failed-transfer-session-modal",
      attrs: {
        id: "serviceDashboardFailedTransferModalId",
        "content-class": "failedTransferSessionModal",
        "body-class": "failedTransferSessionModal__body",
        "footer-class": "failedTransferSessionModal__footer",
        "hide-header": true,
        "no-close-on-backdrop": true,
        "no-fade": true,
        centered: true,
      },
    },
    [
      _c(
        "button",
        {
          staticClass: "failedTransferSessionModal__closeButton",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.hideModal()
            },
          },
        },
        [_vm._v("\n\t\t×\n\t")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "failedTransferSessionModal__header" }, [
        _c(
          "div",
          { staticClass: "failedTransferSessionModal__icon" },
          [
            _c("IconWarning", {
              staticClass: "failedTransferSessionModal__svg",
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "failedTransferSessionModal__title" }, [
          _vm._v("\n\t\t\tSeems like an error has occured\n\t\t"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "failedTransferSessionModal__content" }, [
        _c("div", { staticClass: "failedransferSessionModal__text" }, [
          _vm._v(
            "\n\t\t\tThe session failed to transfer. Please try again.\n\t\t"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("template", { slot: "modal-footer" }, [
        _c(
          "div",
          { staticClass: "failedTransferSessionModal__footer--displayFlex" },
          [
            _c(
              "BaseButton",
              {
                attrs: { type: "SUBMIT" },
                nativeOn: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.hideModal()
                  },
                },
              },
              [
                _c("template", { slot: "text" }, [
                  _vm._v("\n\t\t\t\t\tOkay\n\t\t\t\t"),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }