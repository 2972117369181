import Session from "./Session.js";

export const modules = {
	Session,
};

export const getDefaultState = () => ({});

export const state = getDefaultState();

export const getters = {};

export const mutations = {};

export const actions = {};

export default {
	namespaced: true,
	modules,
	state,
	getters,
	mutations,
	actions,
};
