<template>
	<component :is="bioBreakStep" />
</template>
<script>
import { mapState } from "vuex";

import {
	BIO_BREAK_CONFIRMATION,
	BIO_BREAK_ACTIVITY_ON_HOLD,
	BIO_BREAK_CLASSROOM_ERROR,
	BIO_BREAK_END,
} from "../../utilities/BreakWorkflowSteps.js";

import BioBreakClassroomError from "./BioBreakClassroomError.vue";
import BioBreakConfirmation from "./BioBreakConfirmation.vue";
import BioBreakActivityOnHold from "./BioBreakActivityOnHold.vue";
import BioBreakEndPrompt from "./BioBreakEndPrompt.vue";

export default {
	components: {
		BioBreakClassroomError,
		BioBreakConfirmation,
		BioBreakActivityOnHold,
		BioBreakEndPrompt,
	},
	computed: {
		...mapState(["BreaksManagementWidget"]),
		bioBreakStep() {
			const bioBreakSteps = {
				[BIO_BREAK_CONFIRMATION]: "BioBreakConfirmation",
				[BIO_BREAK_CLASSROOM_ERROR]: "BioBreakClassroomError",
				[BIO_BREAK_ACTIVITY_ON_HOLD]: "BioBreakActivityOnHold",
				[BIO_BREAK_END]: "BioBreakEndPrompt",
			};
			return bioBreakSteps[this.BreaksManagementWidget.currentBreakStep] || "";
		},
	},
	created() {
		this.$store.dispatch("BreaksManagementWidget/setCurrentBioBreakStep");
	},
};
</script>
