<template>
	<BreaksConfirmation
		v-if="canCancelMealBreak"
	>
		<template #header>
			<h3 v-data-cy="'meal-cancel-approved-break-header'">
				Cancel approved break?
			</h3>
		</template>
		<template #bodyIcon>
			<OrigamiIcon
				v-data-cy="'meal-break-cancel-body-icon'"
				name="meal-break"
				width="32"
				height="32"
				class="tw-text-origami-blue-400"
			/>
		</template>
		<template #bodyText>
			<p
				v-if="formattedBreakTimes"
				v-jest="'cancel-approved-meal-break-details'"
				v-data-cy="'cancel-approved-meal-break-details'"
			>
				Cancel your break for {{ formattedBreakTimes.startTime }} - {{ formattedBreakTimes.endTime }}?
			</p>
		</template>
		<template #buttons>
			<OrigamiButton
				v-jest="'confirm-cancel-meal-break-button'"
				v-data-cy="'confirm-cancel-meal-break-button'"
				variant="destructive"
				class="tw-w-full tw-justify-center"
				:is-loading="$store.state.BreaksManagementWidget.isRequestLoading"
				@click.native="cancelApprovedMealBreak"
			>
				Yes, cancel break
			</OrigamiButton>
		</template>
	</BreaksConfirmation>
</template>

<script>
import { OrigamiButton, OrigamiIcon } from "@origami/vue2";

import { formatIsoDateToHM } from "@/utilities/dateHelpers.js";

import BreaksConfirmation from "../templates/BreaksConfirmation.vue";

export default {
	components: {
		OrigamiButton,
		BreaksConfirmation,
		OrigamiIcon,
	},
	computed: {
		formattedBreakTimes() {
			const scheduledBreak = this.$store.getters["TutorSchedule/getUpcomingMealBreak"];
			return scheduledBreak ? {
				startTime: formatIsoDateToHM(scheduledBreak.start_time),
				endTime: formatIsoDateToHM(scheduledBreak.end_time),
			} : null;
		},
		canCancelMealBreak() {
			return this.$store.getters["BreaksManagementWidget/canCancelMealBreak"];
		},
	},
	methods: {
		async cancelApprovedMealBreak() {
			await this.$store.dispatch("BreaksManagementWidget/cancelApprovedMealBreak");
		},
	},
};
</script>
