<template>
	<div
		:class="`
			tw-inline-flex tw-items-center tw-justify-center tw-flex-shrink-0
			tw-bg-white-high tw-text-black-high tw-align-middle
			tw-font-bold tw-rounded-full tw-overflow-hidden
		`"
		:style="{ width: `${size}px`, height: `${size}px` }"
	>
		<Avatar
			v-if="avatarName || isShowDefaultAvatar || $store.state.Student.Profile.isLoadingProfile"
			:name="avatarName || 'default'"
			:colors="avatarColors"
			:class="`${variant === 'cover' ? 'tw-self-start tw-w-full tw-h-auto' : 'tw-w-auto tw-h-2/3'}`"
		/>
		<template v-else>
			{{ initials }}
		</template>
	</div>
</template>
<script>
import Avatar from "./Avatar.vue";

export default {
	components: {
		Avatar,
	},
	props: {
		user: {
			type: Object,
			required: true,
		},
		size: {
			type: Number,
			default: 40,
		},
		variant: {
			type: String,
			default: "cover",
			validator(value) {
				return ["contain", "cover"].includes(value);
			},
		},
		isShowDefaultAvatar: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		avatarName() {
			return this.user.avatar?.name;
		},
		avatarColors() {
			return this.user.avatar?.colors;
		},
		initials() {
			return `${(this.user.first_name || this.user.firstName || "")[0]}${(this.user.last_name || this.user.lastName || "")[0]}`;
		},
		isLoadingAvatarCustomization() {
			return this.$store.state.Achievements.isLoadingAvatarCustomization;
		},
	},
};
</script>
