<template>
	<div>
		<NotificationSnackbar
			v-jest="'snackbar'"
			:textcontent="snackbarText"
			:notificationtype="snackbarType"
			@snackbar-close="$store.dispatch('Snackbar/resetSnackbar')"
		>
			<template slot="icon">
				<span v-jest="'icon'">
					{{ snackbarIcon }}
				</span>
			</template>
			<template slot="prompt">
				<span v-jest="'prompt'">
					&times;
				</span>
			</template>
		</NotificationSnackbar>
		<TutorEssay />
		<SimpleModal id="simpleModal" />
		<BreaksNotifier v-if="featureFlagBmwToastNotifications" />
	</div>
</template>

<script>
import { mapState } from "vuex";

import SimpleModal from "@/modules/Classroom/components/modals/SimpleModal.vue";
import NotificationSnackbar from "@/modules/Notifications/components/NotificationSnackbar.vue";
import tutorBreaksListener from "@/utilities/listeners/tutorBreaksListener.js";
import BreaksNotifier from "@/modules/BreaksManagementWidget/components/BreaksNotifier.vue";

import TutorEssay from "./TutorEssay.vue";

export default {
	components: {
		TutorEssay,
		SimpleModal,
		NotificationSnackbar,
		BreaksNotifier,
	},
	data() {
		return {
			featureFlagBmwToastNotifications: false,
		};
	},
	computed: {
		...mapState(["currentUser"]),
		...mapState("Snackbar", ["snackbarText", "snackbarType"]),
		snackbarIcon() {
			return {
				success: "\u2713",
				error: "\u2717",
				warning: "\u0021",
			}[this.snackbarType];
		},
	},
	sockets: {
		...tutorBreaksListener,
	},
	async created() {
		try {
			this.$store.commit({
				type: "SET_CURRENT_USER",
				currentUser: this.currentUser,
			});

			await this.warnIfTutoring();

			await this.$store.dispatch("Essay/getReviewerStatus");
			await this.$store.dispatch("Essay/getInProgressEssay");
		} catch (error) {
			Sentry.captureException(error);
			await this.$store.dispatch("SimpleModal/configureSimpleModal", {
				buttonText: "ok",
				message: "pleaseRefresh",
				title: "error",
				buttonType: "PRIMARY",
			});
			this.$bvModal.show("simpleModal");
		}

		await this.$store.dispatch("TutorSchedule/getCurrentShiftAndState");
		if (this.$store.getters["TutorSchedule/isPunchedInToTutoringShift"]) {
			this.$store.dispatch("Snackbar/showSnackbar", {
				snackbarText: "You may appear disconnected and will be unable to receive students if you do not have the classroom open.",
				snackbarType: "warning",
			});
		}

		this.featureFlagBmwToastNotifications = await this.$getFlag("core-2216-bmw-toast-notifications");
	},
	methods: {
		async warnIfTutoring() {
			await this.$store.dispatch("TutorSchedule/getCurrentShiftAndState");
			if (this.$store.getters["TutorSchedule/isPunchedInToTutoringShift"]) {
				this.$store.dispatch("Snackbar/showSnackbar", {
					snackbarText: "You may appear disconnected and will be unable to receive students if you do not have the classroom open.",
					snackbarType: "warning",
				});
			}
		},
	},
};
</script>
