var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tw-py-4" }, [
    _c(
      "label",
      {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "label-questions-description",
            expression: "'label-questions-description'",
          },
        ],
        staticClass: "tw-text-sm tw-font-bold",
      },
      [_vm._v("\n\t\t" + _vm._s(_vm.$t("anyQuestionsSoFar")) + " *\n\t")]
    ),
    _vm._v(" "),
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.essayComments,
          expression: "essayComments",
        },
        {
          name: "jest",
          rawName: "v-jest",
          value: "comments",
          expression: "'comments'",
        },
        {
          name: "data-cy",
          rawName: "v-data-cy",
          value: "comments",
          expression: "'comments'",
        },
      ],
      staticClass:
        "tw-border-2 tw-border-grey-dark tw-rounded-md tw-p-2.5 tw-w-full placeholder:tw-text-xs",
      class: {
        "tw-border-2 tw-border-solid tw-border-origami-red-400":
          _vm.isEssayCommentsErrorShowing,
      },
      attrs: {
        id: "comments",
        name: "comment",
        placeholder: _vm.$t("additionalComments"),
      },
      domProps: { value: _vm.essayComments },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.essayComments = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isEssayCommentsErrorShowing,
            expression: "isEssayCommentsErrorShowing",
          },
          {
            name: "jest",
            rawName: "v-jest",
            value: "comments-error",
            expression: "'comments-error'",
          },
        ],
        staticClass: "tw-text-sm tw-font-bold tw-text-origami-red-400",
      },
      [_vm._v("\n\t\t" + _vm._s(_vm.$t("essayCommentsRequired")) + "\n\t")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }