var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "baseItem",
      attrs: { id: _vm.value.id, value: _vm.value },
      on: {
        click: function ($event) {
          return _vm.handleInput(_vm.value)
        },
      },
    },
    [_vm._v("\n\t" + _vm._s(_vm.value.name) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }