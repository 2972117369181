<template>
	<BreaksConfirmation>
		<template #header>
			<p v-jest="'meal-break-rejected-header'">
				Meal break request
			</p>
		</template>
		<template #bodyIcon>
			<OrigamiIcon
				name="block"
				width="32"
				height="32"
				class="tw-text-origami-red-400"
			/>
		</template>
		<template #bodyText>
			<p
				v-if="formattedBreakRequestTimes"
				v-jest="'meal-break-rejected-details'"
			>
				Your break has been rejected for {{ formattedBreakRequestTimes.startTime }} - {{ formattedBreakRequestTimes.endTime }}
			</p>
		</template>
		<template #buttons>
			<OrigamiButton
				v-jest="'meal-break-rejected-try-again-button'"
				variant="primary"
				class="tw-w-full tw-justify-center"
				:is-loading="isLoading"
				@click.native="requestBreak"
			>
				Try for another time
			</OrigamiButton>
		</template>
	</BreaksConfirmation>
</template>

<script>
import { OrigamiButton, OrigamiIcon } from "@origami/vue2";

import { formatDateToHM, formatSecondsTimestampToDate } from "@/utilities/dateHelpers.js";

import BreaksConfirmation from "../templates/BreaksConfirmation.vue";

export default {
	components: {
		OrigamiButton,
		BreaksConfirmation,
		OrigamiIcon,
	},
	data() {
		return {
			isLoading: false,
		};
	},
	computed: {
		formattedBreakRequestTimes() {
			const { rejectedBreakRequest } = this.$store.state.TutorSchedule;

			return rejectedBreakRequest ? {
				startTime: formatDateToHM(formatSecondsTimestampToDate(rejectedBreakRequest.startTime)),
				endTime: formatDateToHM(formatSecondsTimestampToDate(rejectedBreakRequest.endTime)),
			} : null;
		},
	},
	methods: {
		async requestBreak() {
			this.isLoading = true;

			await this.$store.dispatch("TutorSchedule/getCurrentShiftAndState");
			await this.$store.dispatch("TutorSchedule/resetRejectedBreakRequest");
			this.isLoading = false;

			this.$store.dispatch("BreaksManagementWidget/requestMealBreak");
		},
	},
};
</script>
