var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M6.07143 19.7787C6.07143 21.001 7.03571 22.001 8.21429 22.001H16.7857C17.9643 22.001 18.9286 21.001 18.9286 19.7787V6.4454H6.07143V19.7787ZM20 3.11209H16.25L15.1786 2.00098H9.82143L8.75 3.11209H5V5.33431H20V3.11209Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }