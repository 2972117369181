var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseModal",
    { attrs: { id: "skip-essay-pickup", "modal-ref": "skip-essay-pickup" } },
    [
      _c("div", { staticClass: "tw-flex tw-flex-col tw-mb-4" }, [
        _c("h4", { staticClass: "tw-font-bold tw-text-xl tw-mb-4" }, [
          _vm._v("\n\t\t\tPass on\n\t\t"),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "tw-font-bold tw-text-lg" }, [
          _vm._v(
            "\n\t\t\tHelp us understand the problem. What is going on with this Essay?\n\t\t"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          attrs: { id: "rg1", role: "radiogroup", "aria-labelledby": "label" },
        },
        _vm._l(_vm.rejectReasons, function (reason) {
          return _c("BaseRadio", {
            key: `reject-reason-${reason.id}`,
            staticClass:
              "tw-mb-4 tw-text-sm tw-border-transparent focus-within:tw-border-origami-blue-200 tw-border-2 tw-border-solid",
            attrs: {
              role: "radio",
              "radio-id": `reason-${reason.id}`,
              "radio-value": reason.id,
              "radio-name": reason.text,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "text",
                  fn: function () {
                    return [
                      _vm._v("\n\t\t\t\t" + _vm._s(reason.text) + "\n\t\t\t"),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
            model: {
              value: _vm.selectedReason,
              callback: function ($$v) {
                _vm.selectedReason = $$v
              },
              expression: "selectedReason",
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("p", { staticClass: "tw-text-sm tw-text-origami-blue-300" }, [
        _vm._v(
          "\n\t\tOther (Contact a Platform Manager for any other reason that you are not able to review this essay)\n\t"
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tw-flex tw-justify-end tw-mt-8" },
        [
          _c("BaseButton", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "cancel-button",
                expression: "'cancel-button'",
              },
            ],
            staticClass: "tw-shadow-elevation-2 tw-mr-4",
            attrs: { "is-disabled": _vm.isLoading, type: "CANCEL" },
            nativeOn: {
              click: function ($event) {
                return _vm.closeModal.apply(null, arguments)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "text",
                fn: function () {
                  return [_vm._v("\n\t\t\t\tCancel\n\t\t\t")]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("BaseButton", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "pass-on-button",
                expression: "'pass-on-button'",
              },
            ],
            staticClass: "tw-shadow-elevation-2",
            attrs: { "is-disabled": _vm.isPassButtonDisabled },
            nativeOn: {
              click: function ($event) {
                return _vm.passOnEssay.apply(null, arguments)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "text",
                fn: function () {
                  return [
                    _vm.isLoading
                      ? _c("LoadingRectangles", {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: "loading-rectangles",
                              expression: "'loading-rectangles'",
                            },
                          ],
                        })
                      : _c("span", [
                          _vm._v("\n\t\t\t\t\tPass-on Essay\n\t\t\t\t"),
                        ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }