export const checkIsObjectKeysPresent = (propObject, keys) => {
	let isValid = true;
	keys.forEach((key) => {
		if (!propObject.hasOwnProperty(key)) {
			isValid = false;
		}
	});
	return isValid;
};

export const checkIsObjectKeysPresentAndRightType = (item, requiredKeys) => {
	return requiredKeys.every(({ property, type, subKeys }) => {
		let hasRequiredSubKeys = true;
		if (subKeys) {
			hasRequiredSubKeys = checkIsObjectKeysPresentAndRightType(item[property], subKeys);
		}
		return item?.hasOwnProperty(property) &&
			(
				(typeof item[property] === type[0] || item[property] === type[1]) ||
				(type[0] === "array" && Array.isArray(item[property]))
			) &&
			hasRequiredSubKeys;
	});
};
