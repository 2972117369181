<template>
	<ScheduledBreakError
		header="Meal break request"
		@break-error-try-again="requestMealBreak"
	/>
</template>

<script>
import ScheduledBreakError from "../elements/ScheduledBreakError.vue";

export default {
	components: {
		ScheduledBreakError,
	},
	methods: {
		requestMealBreak() {
			this.$store.dispatch("BreaksManagementWidget/requestMealBreak");
		},
	},
};
</script>
