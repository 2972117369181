<template>
	<OrigamiCard
		:tag="tag"
		class="tw-flex tw-p-2 tw-items-center tw-bg-origami-purple-100 tw-rounded-lg tw-gap-4"
	>
		<div class="tw-w-10 tw-h-10 tw-rounded-full tw-bg-origami-white tw-flex tw-items-center tw-justify-center">
			<Emoji
				v-jest="'recognition-emoji'"
				class="tw-text-xl"
				:emoji="
					RECOGNITION_ICON_COMPONENTS[id] ||
						RECOGNITION_ICON_COMPONENTS[1]
				"
			/>
		</div>
		<p
			v-jest="'recognition-name'"
			class="tw-font-bold"
		>
			{{ upperFirst($t(`recognition.types.${name}`)) }}
		</p>
	</OrigamiCard>
</template>

<script>
import { upperFirst } from "lodash";
import { OrigamiCard } from "@origami/vue2";

import { Emoji } from "@/modules/Emoji/index.js";

import { RECOGNITION_ICON_COMPONENTS } from "../utilities/index.js";

export default {
	components: {
		Emoji,
		OrigamiCard,
	},
	props: {
		id: {
			type: Number,
			default: 1,
		},
		name: {
			type: String,
			default: "",
		},
		tag: {
			type: String,
			default: "article",
		},
	},
	data: () => ({
		RECOGNITION_ICON_COMPONENTS,
	}),
	methods: {
		upperFirst,
	},
};
</script>
