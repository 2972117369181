<template>
	<BModal
		id="service-schedule-edit-shift-modal"
		ref="service-schedule-edit-shift-modal"
		:hide-header="true"
		:no-close-on-backdrop="true"
		:no-fade="true"
		:centered="true"
		size="sm"
		@keydown.esc.native="hideModal()"
	>
		<button
			type="button"
			class="close editShift__closeBtn"
			@click="hideModal()"
		>
			&times;
		</button>

		<div class="editShift__header">
			Edit Shift
		</div>

		<div class="editShift__body">
			<ServiceScheduleShiftsCalendarEdit
				ref="shiftsCalendarEdit"
				class="editShift__calendar"
				:placeholder="'Select a time range'"
				:is-edit="isEditing"
				:shift="shiftObject"
				:value="dates"
			/>
		</div>

		<template slot="modal-footer">
			<div class="editShift__footer tw-w-full">
				<BaseButton
					v-if="!isEditing"
					v-data-cy="'edit-save-shift-btn'"
					class="editShift__saveBtn"
					type="SUBMIT"
					@click.native="permitEdits()"
				>
					<template
						slot="text"
					>
						Edit
					</template>
				</BaseButton>
				<BaseButton
					v-else
					v-jest="'save-shift-btn'"
					class="editShift__saveBtn"
					type="SECONDARY"
					:is-disabled="!isValidShiftEdit"
					@click.native="updateShift()"
				>
					<template
						slot="text"
					>
						Save
					</template>
				</BaseButton>
				<div class="editShift__btnContainer">
					<BaseButton
						v-if="!isEditing"
						v-jest="'remove-shift-btn'"
						v-data-cy="'remove-shift-btn'"
						class="editShift__btn"
						type="CANCEL"
						@click.native="showRemoveShiftModal()"
					>
						<template
							slot="text"
						>
							Remove
						</template>
					</BaseButton>
					<BaseButton
						v-jest="'close-modal-btn'"
						v-data-cy="'close-modal-btn'"
						class="editShift__btn"
						type="CANCEL"
						@click.native="hideModal()"
					>
						<template
							slot="text"
						>
							Close
						</template>
					</BaseButton>
				</div>
			</div>
		</template>
	</BModal>
</template>
<script>
//lib
import { mapState } from "vuex";
//Utilities
import { isAfter } from "date-fns";

import { TUTOR_MANAGER_ID } from "@/modules/TutorSchedule/index.js";
import BaseButton from "@/components/BaseButton.vue";

import { getSlotColorFromShiftType } from "../../../../utilities/ScheduleHelpers.js";
import { formatDateToTimestamp } from "../../../../utilities/dateHelpers.js";
// components
import shiftModalsMixin from "../../mixins/shiftModalsMixin.js";

import ServiceScheduleShiftsCalendarEdit from "./ServiceScheduleShiftsCalendarEdit.vue";
//mixins

export default {
	components: {
		ServiceScheduleShiftsCalendarEdit,
		BaseButton,
	},
	mixins: [shiftModalsMixin],
	props: {
		shiftObject: {
			type: Object,
			required: true,
			validator(value) {
				return (
					value.hasOwnProperty("start") &&
					value.hasOwnProperty("end") &&
					value.hasOwnProperty("punchIn") &&
					value.hasOwnProperty("name") &&
					value.hasOwnProperty("id") &&
					value.hasOwnProperty("shiftColor") &&
					value.hasOwnProperty("shiftType") &&
					value.hasOwnProperty("shiftEnded")
				);
			},
		},
	},
	data() {
		return {
			isEditing: false,
		};
	},
	computed: {
		...mapState(["PlatformManager"]),
		dates() {
			return { start: this.shiftObject.start, end: this.shiftObject.end };
		},
		isEssayShift: {
			get() {
				return this.PlatformManager.ServiceTutorsSchedule.isEssayShift;
			},
			set(isEssayShift) {
				this.$store.commit("PlatformManager/ServiceTutorsSchedule/SET_IS_ESSAY_SHIFT", {
					isEssayShift,
				});
			},
		},
		/**
		 * @return {Boolean}
		 */
		isShiftTypeTutorManager() {
			return (
				this.shiftObject.shiftType !== undefined &&
				this.shiftObject.shiftType.hasOwnProperty("data") &&
				this.shiftObject.shiftType.data.id === TUTOR_MANAGER_ID
			);
		},
		/**
		 * @return {Number}
		 */
		checkBoxValue() {
			if (
				this.shiftObject.shiftType !== undefined &&
				this.shiftObject.shiftType.hasOwnProperty("data")
			) {
				return this.shiftObject.shiftType.data.id;
			} else {
				return 0;
			}
		},
		isValidShiftEdit() {
			return (
				isAfter(this.PlatformManager.ServiceTutorsSchedule.dateRange.end,
					this.PlatformManager.ServiceTutorsSchedule.dateRange.start) && (
					this.shiftObject.start !== this.PlatformManager.ServiceTutorsSchedule.dateRange.start ||
					this.shiftObject.end !== this.PlatformManager.ServiceTutorsSchedule.dateRange.end ||
					this.shiftObject.shiftType.data.id !==
						this.PlatformManager.ServiceTutorsSchedule.currentShiftType.id
				)
			);
		},
	},

	methods: {
		permitEdits() {
			this.isEditing = true;
		},
		async updateShift() {
			let snackbarType, snackbarText;
			try {
				const params = this.getShiftParams();
				await this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/updateShift", params);
				const event = this.buildEvent(params);
				this.updateShiftInShifts(event);
				this.$emit("shiftEdited");
				snackbarType = "success";
				snackbarText = "Change is successfully saved";
				this.hideModal();
			} catch (error) {
				snackbarType = "error";
				snackbarText = this.getErrorMessage(error, "trying to edit the shift");
			} finally {
				this.snackbarMessage(snackbarType, snackbarText);
			}
		},
		/**
		 * @returns {Object}
		 */
		getShiftParams() {
			return {
				id: this.shiftObject.id,
				data: {
					schedule_type_id: this.PlatformManager.ServiceTutorsSchedule.currentShiftType.id,
					name: this.PlatformManager.ServiceTutorsSchedule.currentShiftType.name,
					start_time: formatDateToTimestamp(this.PlatformManager.ServiceTutorsSchedule.dateRange.start),
					end_time: formatDateToTimestamp(this.PlatformManager.ServiceTutorsSchedule.dateRange.end),
				},
			};
		},
		/**
		 * @param {Object} params
		 * @returns {Object}
		 */
		buildEvent(params) {
			const color = getSlotColorFromShiftType(this.shiftObject.shiftType.data.id);
			return {
				id: this.shiftObject.id,
				shiftType: {
					data: {
						id: params.data.schedule_type_id,
						name: params.data.name,
					},
				},
				backgroundColor: color,
				borderColor: color,
			};
		},
		/**
		 * @param {Object} event
		 */
		updateShiftInShifts(event) {
			const shifts = this.PlatformManager.ServiceTutorsSchedule.shifts.map((shift) => {
				if (shift.id === event.id) {
					shift.scheduleType = event.shiftType;
				}
				return shift;
			});
			this.$store.commit("PlatformManager/ServiceTutorsSchedule/SET_SHIFTS_ARRAY", {
				shifts,
			});
		},
		showRemoveShiftModal() {
			this.$bvModal.show("service-schedule-delete-shift-modal");
			this.hideModal();
		},
		hideModal() {
			this.isEditing = false;
			this.resetDropdowns();
			this.$refs.shiftsCalendarEdit.resetData();
			this.$store.commit("PlatformManager/ServiceTutorsSchedule/SET_IS_ESSAY_SHIFT", {
				isEssayShift: false,
			});
			this.$bvModal.hide("service-schedule-edit-shift-modal");
		},
		resetDropdowns() {
			this.$store.commit("PlatformManager/ServiceTutorsSchedule/SET_CURRENT_TUTOR", {
				currentTutor: {},
			});
			this.$store.commit("PlatformManager/ServiceTutorsSchedule/SET_CURRENT_SHIFT_TYPE", {
				currentShiftType: {},
			});
			this.$store.commit("PlatformManager/ServiceTutorsSchedule/SET_DATE_RANGE", {
				dateRange: {},
			});
		},
	},
};
</script>
<style scoped>
.editShift__body {
	margin-top: 1.25rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.editShift__headerText {
	margin-top: 1.5rem;
	font-size: 1rem;
	font-weight: bold;
	color: var(--c-black);
}
.editShift__subHeader {
	font-size: 0.75rem;
	font-weight: normal;
	color: var(--c-black);
}
.editShift__essayShiftCheckbox {
	margin-top: 3rem;
}
.editShift__footer {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.modal-footer {
	border-top: none;
	padding-bottom: 1rem;
}
.editShift__saveBtn {
	height: 1.9rem !important;
	width: 9.8rem;
	margin-bottom: 0.5rem;
}
.editShift__btnContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 9.8rem;
}
.editShift__btn {
	flex-grow: 1;
	width: 47%;
	height: 1.9rem !important;
}
.editShift__calendar:hover {
	cursor: pointer;
}
</style>
