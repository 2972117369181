import middleware from "@/middleware/index.js";
import redirectWhenFeatureFlagIsEnabled from "@/middleware/redirectWhenFeatureFlagIsEnabled.js";

export default [
	{
		name: "tutorEssay",
		path: "review-center",
		component: () => import("../pages/reviewCenter.vue"),
		beforeEnter: middleware([
			redirectWhenFeatureFlagIsEnabled("PE-19321-tutor-review-center", { name: "tutorReviewCenter" }),
		]),
	},
];
