import { omit } from "lodash";

export const RECOGNITION_TYPE_IDS = {
	KUDOS: 1,
	COLLABORATION: 2,
	GREETING: 3,
	PERSEVERANCE: 4,
	POLITENESS: 5,
	PREPAREDNESS: 6,
};

export const RECOGNITION_ICON_COMPONENTS = {
	[RECOGNITION_TYPE_IDS.KUDOS]: "ThumbsUp",
	[RECOGNITION_TYPE_IDS.GREETING]: "WavingHand",
	[RECOGNITION_TYPE_IDS.COLLABORATION]: "Handshake",
	[RECOGNITION_TYPE_IDS.PERSEVERANCE]: "FlexedBiceps",
	[RECOGNITION_TYPE_IDS.POLITENESS]: "GlowingStar",
	[RECOGNITION_TYPE_IDS.PREPAREDNESS]: "Memo",
};

export const getRecognitionsSenderMessage = (session, senderUserId) => {
	const senderRecognition = session?.recognitionMessages?.data?.find(
		({ from_user_id }) => from_user_id === senderUserId,
	);
	return senderRecognition?.message || "";
};

export const getListRecognitionsSentByUserForSession = (session) => {
	const recognitionsByUsers = {};
	const listRecognitionsSentByUserForSession = [];
	session?.recognitions?.data?.forEach((recognition) => {
		if (!recognitionsByUsers[recognition.from_user_id]) {
			recognitionsByUsers[recognition.from_user_id] = [recognition];
		} else {
			recognitionsByUsers[recognition.from_user_id].push(recognition);
		}
	});
	Object.values(recognitionsByUsers)?.forEach((recognitionsByUser) => {
		const fromUser = recognitionsByUser[0].fromUser.data;
		listRecognitionsSentByUserForSession.push({
			session: omit(session, "recognitions"),
			fromUser,
			recognitions: recognitionsByUser,
			message: getRecognitionsSenderMessage(session, fromUser.id),
			lastRecognitionAt: recognitionsByUser.reduce((last, r) => last > r.created_at ? last : r.created_at, 0),
		});
	});
	return listRecognitionsSentByUserForSession || [];
};
