import { getFlag, waitForUserIdentification } from "@paper-co/web-toolkit";

export default function(featureFlag, route = "/", isExternal = false) {
	return async function(to, from, next) {
		let isFeatureFlagOn;

		try {
			await waitForUserIdentification();
			isFeatureFlagOn = await getFlag(featureFlag);
		} catch (e) {
			Sentry.captureException(e);
		}

		if (isFeatureFlagOn) {
			return next();
		} else {
			if (isExternal) {
				return window.location.href = route;
			}
			return next(route);
		}
	};
}
