var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.OrigamiCard,
    { staticClass: "tw-shadow-origami-md tw-my-5" },
    [
      _c("div", { staticClass: "tw-py-3" }, [
        _c(
          "div",
          {
            staticClass:
              "tw-flex tw-justify-between tw-items-center tw-w-full tw-px-5 tw-pb-2 tw-relative after:tw-absolute after:tw-w-full after:tw-border-grey-regular after:tw-border-b-2 after:tw-h-0 after:tw-top-full after:tw-left-0 after:tw-right-0",
          },
          [
            _c(
              "div",
              { staticClass: "tw-flex tw-items-center" },
              [
                _c(_setup.OrigamiIcon, {
                  class: _setup.props.card.color,
                  attrs: {
                    name: _setup.props.card.icon,
                    width: "26",
                    height: "26",
                  },
                }),
                _vm._v(" "),
                _c(
                  "h2",
                  {
                    staticClass:
                      "tw-text-origami-l tw-ml-1 tw-mr-2 tw-font-origami-bold tw-leading-5",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_setup.props.card.title) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(_setup.InfoToolTip, {
                  staticClass: "tw-ml-0 tw-leading-6",
                  attrs: {
                    width: "15px",
                    height: "15px",
                    options: {
                      content: _setup.props.card.tooltipText,
                      placement: "top",
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "tw-flex tw-items-center tw-divide-grey-regular tw-divide-x-2",
              },
              [
                _c(
                  "div",
                  { staticClass: "tw-flex tw-items-center" },
                  [
                    _c(
                      _setup.OrigamiButton,
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "collapse-all-risk-cards-button",
                            expression: "'collapse-all-risk-cards-button'",
                          },
                        ],
                        attrs: {
                          "data-cy": "'collapse-all-risk-cards-button'",
                          variant: "tertiary",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _setup.collapseAll.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "tw-text-origami-black" }, [
                          _vm._v("Collapse All"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tw-flex tw-items-center" },
                  [
                    _c(
                      _setup.OrigamiButton,
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "sort-load-score-button",
                            expression: "'sort-load-score-button'",
                          },
                          {
                            name: "data-cy",
                            rawName: "v-data-cy",
                            value: "sort-load-score-button",
                            expression: "'sort-load-score-button'",
                          },
                        ],
                        staticClass: "tw-mx-3",
                        attrs: { variant: "tertiary" },
                        nativeOn: {
                          click: function ($event) {
                            return _setup.sortByTutorLoadScore.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "tw-text-origami-black" }, [
                          _vm._v("Sort"),
                        ]),
                        _vm._v(" "),
                        _c(_setup.OrigamiIcon, {
                          staticClass: "tw-text-origami-black",
                          attrs: {
                            width: "18",
                            height: "18",
                            name: _setup.sortIcon,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _setup.hasSubjects
          ? _c(
              "div",
              {
                staticClass: "tw-divide-y-2 tw-divide-origami-grey-100 tw-px-5",
              },
              _vm._l(_setup.getCardsByLimit, function (subject) {
                return _c(_setup.SubjectCard, {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "subject-card",
                      expression: "'subject-card'",
                    },
                  ],
                  key: subject.title,
                  ref: "subjectCardsRef",
                  refInFor: true,
                  attrs: {
                    "data-cy": `subject-card-${subject.subject}`,
                    subject: subject,
                    "risk-level": _setup.props.card.riskLevel,
                  },
                })
              }),
              1
            )
          : _c(
              "div",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "empty-subject-error",
                    expression: "'empty-subject-error'",
                  },
                ],
                staticClass: "tw-text-center tw-py-11",
              },
              [
                _c(
                  "h3",
                  {
                    staticClass:
                      "tw-font-origami-bold tw-text-origami-grey-400",
                  },
                  [_vm._v("\n\t\t\t\tNo alerts to review\n\t\t\t")]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "tw-mt-3 tw-text-origami-grey-400" }, [
                  _vm._v(
                    "\n\t\t\t\tIs this incorrect? Refresh the alerts to update the page\n\t\t\t"
                  ),
                ]),
              ]
            ),
        _vm._v(" "),
        _setup.isViewMoreButtonDisplayed
          ? _c(
              "div",
              {
                staticClass:
                  "tw-text-center tw-pt-3 tw-relative after:tw-absolute after:tw-w-full after:tw-border-grey-regular after:tw-border-b-2 after:tw-h-0 after:tw-top-0 after:tw-left-0 after:tw-right-0",
              },
              [
                _c(
                  _setup.OrigamiButton,
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "view-more-button",
                        expression: "'view-more-button'",
                      },
                    ],
                    attrs: { variant: "tertiary" },
                    nativeOn: {
                      click: function ($event) {
                        return _setup.expandRiskCard.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t" + _vm._s(_setup.expandButtonText) + " "
                    ),
                    _c("span", { staticClass: "origami-sr-only" }, [
                      _vm._v(", " + _vm._s(_setup.props.card.title)),
                    ]),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }