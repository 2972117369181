<template>
	<div
		:id="name"
		class="snackbar"
		:class="notificationClass"
	>
		<div
			class="snackbar__iconContainer"
			:class="iconContainer"
		>
			<slot
				name="icon"
				class="snackbar__icon"
				:class="iconClass"
			/>
		</div>
		<div
			v-jest="'snackbar-textcontent'"
			class="snackbar__textContent"
			:class="textContentClass"
		>
			{{ textcontent }}
		</div>
		<button
			v-jest="'snackbar-prompt'"
			class="snackbar__prompt"
			:class="promptClass"
			@click="hideSnackbar"
		>
			<slot name="prompt" />
		</button>
	</div>
</template>

<script>
export default {
	props: {
		name: {
			type: String,
			default: "notification-snackbar",
		},
		textcontent: {
			type: String,
			default: "",
		},
		notificationtype: {
			type: String,
			default: "success",
			validator(value) {
				if (!!value) {
					const acceptedTypes = ["success", "warning", "info", "error"];
					return acceptedTypes.includes(value);
				} else {
					return true;
				}
			},
		},

		showme: {
			type: Boolean,
			default: true,
		},
	},

	data() {
		return {
			notificationClass: "",
			snackBarCloseTimer: null,
		};
	},

	computed: {
		/**
		 * Returns the icon class
		 * @returns {string}
		 */
		iconClass() {
			return "snackbar__icon--" + this.notificationtype;
		},

		/**
		 * Returns the icon container class
		 * @returns {string}
		 */
		iconContainer() {
			return "snackbar__iconContainer--" + this.notificationtype;
		},

		/**
		 * Returns the prompt class
		 * @returns {string}
		 */
		promptClass() {
			return "snackbar__prompt--" + this.notificationtype;
		},

		/**
		 * Return the text content
		 * @returns {string}
		 */
		textContentClass() {
			return "snackbar__textContent--" + this.notificationtype;
		},
	},

	watch: {
		textcontent(newval) {
			if (newval == null) {
				return;
			}

			if (newval.length > 0) {
				this.showSnackbar();
			}
		},
	},

	methods: {
		/**
		 * Sends an event to the vue instance sets the
		 * snackbar values to null and
		 * sets the notification class to null
		 */
		hideSnackbar() {
			this.$emit("snackbar-close");
			this.notificationClass = "";
		},

		/**
		 * Show snackbar and automatically close it
		 * after 2.5 seconds
		 */
		showSnackbar() {
			this.notificationClass += "snackbar--" + this.notificationtype;
			setTimeout(
				function() {
					this.hideSnackbar();
				}.bind(this),
				5000,
			);
		},
	},
};
</script>

<style scoped>
.snackbar {
	min-width: 100vw;
	height: 60px;
	text-align: center;
	left: 0px;
	position: fixed;
	top: -110px;
	z-index: 9999;
}

.snackbar--success {
	background-color: var(--c-green);
	border-top: 4px solid var(--c-dark-green);
	-webkit-animation: fadein 4.5s ease-in-out 0s 1;
	animation: fadein 4.5s ease-in-out 0s 1;
}

.snackbar--warning {
	visibility: visible !important;
	background-color: var(--c-peach);
	-webkit-animation: fadein 4.5s ease-in-out 0s 1;
	animation: fadein 4.5s ease-in-out 0s 1;
}

.snackbar--error {
	visibility: visible !important;
	background-color: var(--c-orange);
	-webkit-animation: fadein 4.5s ease-in-out 0s 1;
	animation: fadein 4.5s ease-in-out 0s 1;
}

.snackbar--info {
	visibility: visible !important;
	background-color: var(--c-blue);
	-webkit-animation: fadein 4.5s ease-in-out 0s 1;
	animation: fadein 4.5s ease-in-out 0s 1;
}

.snackbar__prompt {
	float: right;
	border: none;
	margin-right: 23px;
	font-size: 30px;
	line-height: 20px;
	margin-top: 16px;
}

.snackbar__prompt--success {
	background-color: var(--c-green);
	color: var(--c-white);
}

.snackbar__prompt--info {
	background-color: var(--c-blue);
	color: var(--c-white);
}

.snackbar__prompt--warning {
	background-color: var(--c-peach);
	color: var(--c-black);
}

.snackbar__prompt--error {
	background-color: var(--c-orange);
	color: var(--c-white);
}

.snackbar__textContent {
	float: left;
	margin-top: 20px;
	margin-bottom: 20px;
	line-height: 20px;
	padding-top: 0;
	padding-bottom: 0;
}

.snackbar__textContent--success,
.snackbar__textContent--error,
.snackbar__textContent--info {
	color: var(--c-white);
}

.snackbar__textContent--warning {
	color: var(--c-black);
}

.snackbar__icon--success {
	background-color: var(--c-green);
	color: var(--c-white);
	border-radius: 50%;
	width: 18px;
	height: 18px;
}

.snackbar__icon--warning {
	color: var(--c-black);
	border: var(--c-black);
}

.snackbar__iconContainer {
	float: left;
	border: 2px solid var(--c-white);
	border-radius: 50%;
	margin: 8px;
	margin-left: 20px;
	margin-top: 14px;
	width: 30px;
	height: 30px;
}

.snackbar__iconContainer--success {
	float: left;
	color: var(--c-white);
	background-color: var(--c-green);
}

.snackbar__iconContainer--warning {
	border-color: var(--c-black);
}

.snackbar__iconContainer--info {
	color: var(--c-white);
}

.snackbar__iconContainer--error {
	float: left;
	color: var(--c-white);
	background-color: var(--c-orange);
}

@-webkit-keyframes fadein {
	0%,
	100% {
		top: -110px;
		opacity: 0;
	}
	20%,
	50%,
	80% {
		top: 0px;
		opacity: 1;
	}
}
@keyframes fadein {
	0%,
	100% {
		top: -110px;
		opacity: 0;
	}
	20%,
	50%,
	80% {
		top: 0px;
		opacity: 1;
	}
}
/* Mobile only css */

@media only screen and (max-width: 768px) {
	.snackbar {
		top: unset;
		bottom: -110px;
	}
	@-webkit-keyframes fadein {
		0%,
		100% {
			bottom: -110px;
			opacity: 0;
		}
		20%,
		50%,
		80% {
			bottom: 0px;
			opacity: 1;
		}
	}
	@keyframes fadein {
		0%,
		100% {
			bottom: -110px;
			opacity: 0;
		}
		20%,
		50%,
		80% {
			bottom: 0px;
			opacity: 1;
		}
	}
}
</style>
