<template>
	<DynamicScrollerItem
		:item="recognition"
		:size-dependencies="[text]"
		:active="active"
		:data-index="index"
		class="tw-flex tw-items-center tw-justify-center tw-py-2"
	>
		<div class="tw-inline-flex tw-items-center tw-bg-origami-white tw-border tw-border-origami-grey-200 tw-shadow-elevation-2 tw-rounded-2xl tw-gap-2 tw-p-2 tw-mx-auto">
			<span
				v-jest="'icon-container'"
				class="tw-inline-flex tw-items-center tw-justify-center tw-flex-shrink-0 tw-w-10 tw-h-10 tw-bg-origami-blue-300 tw-text-lg tw-rounded-full"
			>
				<Emoji
					v-jest="'emoji-icon'"
					:emoji="iconComponent"
					aria-hidden="true"
				/>
			</span>
			<span
				v-jest="'recognition-text'"
				class="tw-flex-auto tw-overflow-hidden tw-overflow-ellipsis tw-mr-2"
			>
				{{ text }}
			</span>
		</div>
	</DynamicScrollerItem>
</template>

<script>
import { upperFirst } from "lodash";
import { DynamicScrollerItem } from "vue-virtual-scroller";

import { Emoji } from "@/modules/Emoji/index.js";

import { RECOGNITION_ICON_COMPONENTS } from "../utilities/index.js";


export default {
	components: {
		DynamicScrollerItem,
		Emoji,
	},
	props: {
		recognition: {
			type: Object,
			required: true,
		},
		active: {
			type: Boolean,
			required: true,
		},
		index: {
			type: Number,
			required: true,
		},
	},
	computed: {
		iconComponent() {
			return RECOGNITION_ICON_COMPONENTS[this.recognition.recognition_type_id];
		},
		text() {
			const user_name = this.recognition.fromUser.data.first_name;
			const recognition_name = this.recognition.recognitionType.data.name.toLowerCase();
			return this.$t("recognition.recogntion_message_text", {
				user_name,
				recognition_name: upperFirst(this.$t(`recognition.types.${recognition_name}`)),
			});
		},
	},
};
</script>
