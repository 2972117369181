<template>
	<div>
		<div class="tw-flex tw-mt-3 tw-mb-2 tw-items-start tw-w-full tw-justify-between">
			<BaseDropdown
				:items="PlatformManager.ServiceTutorsSchedule.subjectsList"
				:label="'Subjects'"
				class="tw-w-48"
			>
				<template slot="filters">
					{{ subjectsDropdownFilter }}
				</template>
				<template #default="{ item }">
					<BaseRadio
						v-model="selectedSubject"
						:radio-id="item.name"
						:radio-value="item.id"
						:radio-name="item.name"
					>
						<template slot="text">
							{{ item.name }}
						</template>
					</BaseRadio>
				</template>
			</BaseDropdown>
			<BaseDropdown
				:items="topicsList"
				:label="'Topics'"
				:disabled="isTopicsListEmpty"
				class="tw-w-48"
			>
				<template slot="filters">
					{{ topicsDropdownFilter }}
				</template>
				<template #default="{ item }">
					<BaseRadio
						v-model="selectedTopic"
						:radio-id="item.name"
						:radio-value="item.id"
						:radio-name="item.name"
					>
						<template slot="text">
							{{ item.name }}
						</template>
					</BaseRadio>
				</template>
			</BaseDropdown>
		</div>
		<div class="tw-text-left">
			<OrigamiButton
				v-jest="'filter-submit-button'"
				variant="primary"
				class="tw-mr-1"
				@click.native="submitSearch"
			>
				Show Results
			</OrigamiButton>
			<OrigamiButton
				v-jest="'filter-clear-button'"
				variant="secondary"
				@click.native="clearSearch"
			>
				Reset Filter
			</OrigamiButton>
		</div>
	</div>
</template>
<script>
import { OrigamiButton } from "@origami/vue2";
import { mapState } from "vuex";
import { isEmpty, isNil } from "lodash";

import BaseRadio from "./BaseRadio.vue";
import BaseDropdown from "./BaseDropdown.vue";

const LAUSD_DISTRICT_ID = 552;
const APS_DISTRICT_ID = 991;
const MS_REGION_CODE = "MS";
const US_REGION_CODE = "US";

export default {
	components: {
		BaseDropdown,
		BaseRadio,
		OrigamiButton,
	},
	data() {
		return {
			featureFlagTutorMatcherLAUSDIsEnabled: false,
			featureFlagApsApproved: false,
			selectedSubject: null,
			selectedTopic: null,
			topicsList: [],
		};
	},
	computed: {
		...mapState(["PlatformManager"]),
		subjectsDropdownFilter() {
			const { subjectsList } = this.PlatformManager.ServiceTutorsSchedule;
			const subject = subjectsList?.find((subject) => this.selectedSubject === subject.id);
			return subject?.name ?? "Select A Subject";
		},
		topicsDropdownFilter() {
			const topic = this.topicsList?.find((topic) => this.selectedTopic === topic.id);
			return topic?.name ?? "Select A Topic";
		},
		isUSCAfFingerPrinted() {
			const { schoolDistrictId } = this.PlatformManager.ServiceSummaryDashboard.transferSessionData;
			return this.featureFlagTutorMatcherLAUSDIsEnabled && schoolDistrictId === LAUSD_DISTRICT_ID ? 1 : null;
		},
		requiresUsApsBackgroundCheck() {
			const { schoolDistrictId } = this.PlatformManager.ServiceSummaryDashboard.transferSessionData;
			return this.featureFlagApsApproved && schoolDistrictId === APS_DISTRICT_ID ? 1 : null;
		},
		getMississippiRegionCode() {
			const { schoolRegionCode } = this.PlatformManager.ServiceSummaryDashboard.transferSessionData;
			return schoolRegionCode === MS_REGION_CODE ? US_REGION_CODE : null;
		},
		isTopicsListEmpty() {
			return isEmpty(this.topicsList);
		},
	},
	watch: {
		selectedSubject(newVal) {
			if (!isNil(newVal)) {
				this.getTopicsOfSubject(newVal);
				this.selectedTopic = null;
			}
		},
	},
	async created() {
		this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/getSubjects");
		this.featureFlagTutorMatcherLAUSDIsEnabled = await this.$getFlag("tutor-matcher-lausd");
		this.featureFlagApsApproved = await this.$getFlag("PE-20588-APS-Approved");

		this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
			if (modalId === "transfer-session-modal") {
				this.selectedSubject = null;
				this.selectedTopic = null;
				this.topicsList = [];
			}
		});

		this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
			if (modalId === "transfer-session-modal") {
				this.getFilteredTutors();
			}
		});

	},
	methods: {
		submitSearch() {
			this.getFilteredTutors();
		},
		getFilteredTutors() {
			this.$store.dispatch("PlatformManager/ServiceSummaryDashboard/getFilteredTutors", {
				country_code: this.getMississippiRegionCode,
				subject_id: this.selectedSubject,
				is_us_ca_fingerprinted: this.isUSCAfFingerPrinted,
				is_us_nm_aps_background_checked: this.requiresUsApsBackgroundCheck,
				topic_id: this.selectedTopic,
			});
		},
		async getTopicsOfSubject(subjectID) {
			const topics = await this.$store.dispatch("PlatformManager/ServiceSummaryDashboard/getTopicsOfSubject", {
				subjectId: subjectID,
			});

			if (!isNil(topics)) {
				this.topicsList = topics.data;
			}
		},
		clearSearch() {
			this.selectedTopic = null;
			this.selectedSubject = null;
			this.topicsList = [];
			this.getFilteredTutors();
		},
	},
};
</script>
