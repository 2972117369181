<template>
	<div class="filterContainer">
		<div class="filterContainer__row">
			<div class="filterContainer__label">
				Search
			</div>
			<BaseFormField
				v-model="searchText"
				v-data-cy="'base-form-field'"
				v-jest="'base-form-field'"
				class="filterContainer__searchInput"
				img-path="/images/icon-search.svg"
				type="text"
				:placeholder="searchBarPlaceholder"
				@keyup.native.enter="submitFilters()"
			/>
		</div>
		<div class="filterContainer__row">
			<div class="filterContainer__label">
				Filters
			</div>
			<div class="filterContainer__dropdowns">
				<template v-for="filter in filters">
					<BaseDatePicker
						v-if="checkIsDate(filter.type)"
						:id="filter.value"
						:key="filter.title"
						v-model="self[filter.value]"
						class="filterContainer__dropdown"
						:placeholder="filter.title"
					/>
					<BaseDropdown
						v-else
						:key="filter.value"
						v-data-cy="`${filter.value}-dropdown`"
						class="filterContainer__dropdown"
						:is-clearable="checkIsFilterSelected(filter.value)"
						:items="filterOptions[filter.value]"
						@deselect="deselectAll(filter.value)"
					>
						<template slot="filters">
							{{ computeFilterText(filter) }}
						</template>
						<template slot-scope="{ item, index }">
							<BaseRadio
								v-if="checkIsRadio(filter.type)"
								v-model="self[filter.value]"
								v-data-cy="`${filter.value}-dropdown-${index}`"
								:radio-value="item.id"
								:radio-id="filter.value + item.id"
								:radio-name="filter.value"
							>
								<template slot="text">
									{{ item.name }}
								</template>
							</BaseRadio>
							<BaseCheckbox
								v-else
								v-model="self[filter.value]"
								v-data-cy="`${filter.value}-dropdown-${index}`"
								v-jest="'base-checkbox'"
								:is-checked="false"
								:checkbox-value="item.id"
								:checkbox-id="filter.value + item.id"
							>
								<template slot="text">
									{{ item.name }}
								</template>
							</BaseCheckbox>
						</template>
					</BaseDropdown>
				</template>
			</div>
		</div>
		<div class="filterContainer__row">
			<div class="filterContainer__label" />
			<BaseButton
				v-data-cy="'filter-submit'"
				type="SUBMIT"
				:is-disabled="isSubmitDisabled"
				@click.native="submitFilters()"
			>
				<template slot="text">
					Show results
				</template>
			</BaseButton>
		</div>
	</div>
</template>
<script>
import BaseButton from "./BaseButton.vue";
import BaseCheckbox from "./BaseCheckbox.vue";
import BaseDatePicker from "./BaseDatePicker.vue";
import BaseDropdown from "./BaseDropdown.vue";
import BaseFormField from "./BaseFormField.vue";
import BaseRadio from "./BaseRadio.vue";
export default {
	components: {
		BaseButton,
		BaseCheckbox,
		BaseDatePicker,
		BaseDropdown,
		BaseFormField,
		BaseRadio,
	},
	props: {
		filters: {
			type: Array,
			required: true,
			validator(value) {
				let isArrayOfObjectsWithCorrectKeys = true;
				value.forEach((filter) => {
					if (
						!filter.hasOwnProperty("title") ||
						!filter.hasOwnProperty("type") ||
						!filter.hasOwnProperty("value")
					) {
						isArrayOfObjectsWithCorrectKeys = false;
					}
				});
				return isArrayOfObjectsWithCorrectKeys;
			},
		},
	},
	data() {
		return {
			self: this,
		};
	},
	computed: {
		/**
		 * @returns {String}
		 */
		searchBarPlaceholder() {
			return "Search";
		},
		/**
		 * @returns {String}
		 */
		searchText() {
			return "";
		},
		/**
		 * Object containing the filter title as keys and their options as the corresponding value
		 * @returns {Object}
		 */
		filterOptions() {
			return {};
		},
		/**
		 * @returns {Boolean}
		 */
		isSubmitDisabled() {
			return false;
		},
	},
	methods: {
		/**
		 * Checks if filter at filterkey is selected.
		 * @param {String} filterKey
		 * @returns {Boolean}
		 */
		checkIsFilterSelected(filterKey) { // eslint-disable-line no-unused-vars
			return false;
		},
		/**
		 * Returns the length selected of the filter.
		 * @param {String} filterKey
		 * @returns {Number}
		 */
		filterSelectedLength(filterKey) { // eslint-disable-line no-unused-vars
			return 0;
		},
		/**
		 * @param {Object} filter
		 * @returns {String}
		 */
		computeFilterText(filter) {
			return filter.title;
		},
		/**
		 * @param {String} filterKey
		 */
		deselectAll(filterKey) {
			this[filterKey] = [];
		},

		/**
		 * @param {String} filterType
		 * @returns {Boolean}
		 */
		checkIsRadio(filterType) {
			return filterType === "radio";
		},
		/**
		 * @param {String} filterType
		 * @returns {Boolean}
		 */
		checkIsDate(filterType) {
			return filterType === "date";
		},
		/**
		 * Method used to handle submitting the filters
		 */
		submitFilters() {},
	},
};
</script>
<style scoped>
.filterContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.filterContainer__row {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 8px 0;
}
.filterContainer__label {
	width: 55px;
	min-width: 55px;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: -0.02px;
	color: #484756;
}
.filterContainer__searchInput {
	width: 290px;
	margin: 0;
}
.filterContainer__dropdowns {
	display: flex;
	flex-wrap: wrap;
}

.filterContainer__dropdown {
	margin: 0 0.75rem 0.5rem 0;
	width: 160px;
	text-transform: capitalize;
}
</style>
