module.exports = new Promise((resolve, reject) => {
		window["_paperco_mfe_resolver_teacher-dashboard-web"] = { resolve, reject };
		const script = document.createElement("script");
		script.type = "module";
		script.async = true;
		script.innerHTML = `
			import("https://teacher-dashboard-web.paperstg.co//assets/remoteEntry.mjs")
				.then(remoteEntry => window["_paperco_mfe_resolver_teacher-dashboard-web"].resolve(remoteEntry))
				.catch(() => window["_paperco_mfe_resolver_teacher-dashboard-web"].reject("Failed to import _paperco_mfe_resolver_teacher-dashboard-web"));
		`
		document.head.appendChild(script);
	});