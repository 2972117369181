<template>
	<div class="tagFilters">
		<table class="tw-w-full">
			<tr class="tagFilters__headerRow tw-flex tw-justify-between tw-gap-x-2 tw-w-full">
				<th class="tw-w-full">
					<BaseDropdown
						ref="subjectDropdown"
						v-jest="'subjects-dropdown'"
						class="tw-mb-2 tw-w-full tw-h-10"
						:items="subjects"
					>
						<template slot="filters">
							{{ "Select subject" }}
						</template>
						<template slot="selectAll">
							<BaseCheckbox
								v-model="selectDeselectAll"
								class="tw-p-2"
								checkbox-value="select_all"
								checkbox-id="select_all"
								@change.native="selectAllValues"
							>
								<template slot="text">
									Select All
								</template>
							</BaseCheckbox>
						</template>
						<template slot-scope="{ item }">
							<BaseCheckbox
								v-model="selectedSubjectIds"
								v-jest="'subject-checkbox'"
								class="tw-py-0 tw-px-7"
								:checkbox-value="item.id"
								:checkbox-id="`subject-${item.id}`"
								:value="item.id"
								@change.native="changeSubject(item)"
							>
								<template slot="text">
									{{ item.name }}
								</template>
							</BaseCheckbox>
						</template>
					</BaseDropdown>
				</th>
				<th class="tw-w-full tw-flex">
					<BaseDropdown
						ref="topicDropdown"
						v-jest="'topic-dropdown'"
						class="tw-mb-2 tw-w-full tw-h-10"
						:items="topics"
						:is-disabled="!isTopicsDropdownEnabled"
					>
						<template
							slot="filters"
						>
							Select topic
						</template>
						<template slot-scope="{ item }">
							<BaseCheckbox
								v-model="selectedTopicIds"
								class="tw--m-2 tw-py-2.5 tw-px-2 tw-bg-white-high tw-overflow-ellipsis hover:tw-bg-grey-regular"
								:checkbox-value="`allTopics-${item.id}`"
								:checkbox-id="`allTopics-${item.id}`"
								:value="`allTopics-${item.id}`"
								@change.native="changeTopic(item, item, `allTopics-${item.id}`)"
							>
								<template slot="text">
									{{ item.name }} (Select All)
								</template>
							</BaseCheckbox>
							<div
								v-for="topicItem in item.topics.data"
								:key="topicItem.id"
							>
								<BaseCheckbox
									v-model="selectedTopicIds"
									class="tw--m-2 tw-py-2.5 tw-pl-9 tw-pr-0 tw-bg-white-high tw-overflow-ellipsis hover:tw-bg-grey-regular"
									:checkbox-value="topicItem.id"
									:value="topicItem.id"
									:checkbox-id="topicItem.id"
									@change.native="changeTopic(item, topicItem, topicItem.id)"
								>
									<template slot="text">
										{{ topicItem.name }}
									</template>
								</BaseCheckbox>
							</div>
						</template>
					</BaseDropdown>
				</th>
				<th class="tw-w-full">
					<BaseDropdown
						ref="conceptDropdown"
						v-jest="'concept-dropdown'"
						class="tw-mb-2 tw-w-full tw-h-10"
						:items="concepts"
						:is-disabled="!isConceptsDropdownEnabled"
					>
						<template
							slot="filters"
						>
							Select concept
						</template>
						<template slot-scope="{ item }">
							<BaseCheckbox
								v-model="selectedConceptIds"
								class="tw--m-2 tw-py-2.5 tw-px-2 tw-bg-white-high tw-overflow-ellipsis hover:tw-bg-grey-regular"
								:checkbox-value="`allConcepts-${item.id}`"
								:value="`allConcepts-${item.id}`"
								:checkbox-id="`allConcepts-${item.id}`"
								@change.native="
									changeConcept(item, item, `allConcepts-${item.id}`)
								"
							>
								<template slot="text">
									{{ item.name }} (Select All)
								</template>
							</BaseCheckbox>
							<div
								v-for="conceptItem in item.concepts.data"
								:key="conceptItem.id"
							>
								<BaseCheckbox
									v-model="selectedConceptIds"
									class="tw--m-2 tw-py-2.5 tw-pl-9 tw-pr-0 tw-bg-white-high tw-overflow-ellipsis hover:tw-bg-grey-regular"
									:checkbox-value="`concept-${conceptItem.id}`"
									:value="`concept-${conceptItem.id}`"
									:checkbox-id="`concept-${conceptItem.id}`"
									@change.native="
										changeConcept(
											item,
											conceptItem,
											`concept-${conceptItem.id}`,
										)
									"
								>
									<template slot="text">
										{{ conceptItem.name }}
									</template>
								</BaseCheckbox>
							</div>
						</template>
					</BaseDropdown>
				</th>
			</tr>
			<tr
				v-for="subject in selectedSubjects"
				:key="subject.id"
				class="tagFilters__row tw-flex tw-justify-between tw-gap-x-2 tw-w-full"
			>
				<td
					valign="top"
					class="tagFilters__subjectCell tw-p-px"
				>
					<Tag
						:is-clearable="true"
						:item="subject"
						class="tw-my-1"
					>
						<template slot="text">
							{{ subject.name }}
						</template>
						<template slot="icon">
							<IconClose
								v-jest="'remove-subject'"
								class="tw-text-blue-regular"
								@click.native="removeSubject(subject)"
							/>
						</template>
					</Tag>
				</td>
				<td class="tagFilters__topicsCell tw-p-px">
					<table class="tw-w-full">
						<tr
							v-for="topic in selectedTopics"
							:key="topic.id"
							class="tw-flex tw-justify-between tw-gap-x-2 tw-w-full"
						>
							<td
								v-if="topic.subject.data.id === subject.id"
								class="topicsTable__cell tw-flex-grow tw-flex-shrink-0 tw-p-0"
							>
								<Tag
									:is-clearable="true"
									:item="topic"
									class="tw-my-1"
								>
									<template slot="text">
										{{ topic.name }}
									</template>
									<template slot="icon">
										<IconClose
											v-jest="'remove-topic-icon'"
											icon-color="#0E0FE0"
											@click.native="removeTopic(topic)"
										/>
									</template>
								</Tag>
							</td>
							<td
								class="topicsTable__cell tw-flex-grow tw-flex-shrink-0 tw-p-0"
							>
								<table class="tw-w-full">
									<tr
										v-for="concept in selectedConcepts"
										:key="concept.id"
									>
										<td
											v-if="
												concept.topic_id === topic.id &&
													topic.subject.data.id === subject.id
											"
										>
											<Tag
												:is-clearable="true"
												:item="concept"
												class="tw-my-1"
											>
												<template slot="text">
													{{ concept.name }}
												</template>
												<template slot="icon">
													<IconClose
														v-jest="'remove-concept-icon'"
														icon-color="#0E0FE0"
														@click.native="removeConcept(concept)"
													/>
												</template>
											</Tag>
										</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>
				</td>
			</tr>
		</table>
	</div>
</template>
<script>
//Lib
import { mapState } from "vuex";
import { isEmpty } from "lodash";

//Components
import BaseDropdown from "./BaseDropdown.vue";
import BaseCheckbox from "./BaseCheckbox.vue";
import Tag from "./Tag.vue";
import IconClose from "./icons/IconClose.vue";

const isTagsArrayUpdated = (arr1, arr2) => {
	const isAnyTagAdded = arr1.some((lang) => !arr2.includes(lang));
	const isAnyTagRemoved = arr2.some((lang) => !arr1.includes(lang));
	return isAnyTagAdded || isAnyTagRemoved;
};

export default {
	components: {
		BaseDropdown,
		BaseCheckbox,
		Tag,
		IconClose,
	},
	data() {
		return {
			selectedSubjects: [],
			selectedTopics: [],
			topics: [],
			selectedConcepts: [],
			concepts: [],
			selectedTags: [],
			selectDeselectAll: false,
			selectedConceptIds: [],
			selectedConceptIdsOnLoad: [],
			selectedTopicIdsOnLoad: [],
			selectedSubjectIdsOnLoad: [],
		};
	},
	computed: {
		...mapState(["PlatformManager"]),
		/**
		 * @returns {Array}
		 */
		subjects() {
			return this.$store.getters["PlatformManager/TutorsEdit/subjectsForTagsFilterTable"];
		},
		/**
		 * @returns {Boolean}
		 */
		isTopicsDropdownEnabled() {
			return this.isSubjectSelected && this.isTopicsExist;
		},
		/**
		 * @returns {Boolean}
		 */
		isConceptsDropdownEnabled() {
			return this.isTopicsDropdownEnabled && this.isConceptsExistInTopics;
		},
		/**
		 * @return {Array}
		 */
		selectedSubjectIds: {
			set(value) {
				this.selectDeselectAll = false;
				this.$store.commit("PlatformManager/TutorsEdit/SET_SELECTED_SUBJECTS", {
					selectedSubjectIds: value,
				});
			},
			get() {
				return this.PlatformManager.TutorsEdit.selectedSubjectIds;
			},
		},
		/**
		 * @return {Array}
		 */
		selectedTopicIds: {
			set(value) {
				this.$store.commit("PlatformManager/TutorsEdit/SET_SELECTED_TOPICS", {
					selectedTopicIds: value,
				});
			},
			get() {
				return this.PlatformManager.TutorsEdit.selectedTopicIds;
			},
		},
		/**
		 * @return {Boolean}
		 */
		isSubjectSelected() {
			return this.PlatformManager.TutorsEdit.selectedSubjectIds !== undefined
				? this.PlatformManager.TutorsEdit.selectedSubjectIds.length !== 0
				: false;
		},
		/**
		 * @return {Boolean}
		 */
		isTopicsExist() {
			return this.topics.some((subject) => {
				return !isEmpty(subject.topics);
			});
		},
		/**
		 * @returns {Boolean}
		 */
		isConceptsExistInTopics() {
			return this.concepts.some((topics) => {
				return !isEmpty(topics.concepts);
			});
		},
		isConceptsUpdated() {
			return isTagsArrayUpdated(this.selectedConceptIdsOnLoad, this.selectedConceptIds);
		},
		isTopicsUpdated() {
			return isTagsArrayUpdated(this.selectedTopicIdsOnLoad, this.selectedTopicIds);
		},
		isSubjectsUpdated() {
			return isTagsArrayUpdated(this.selectedSubjectIdsOnLoad, this.selectedSubjectIds);
		},
		isTagsUpdated() {
			return this.isConceptsUpdated || this.isTopicsUpdated || this.isSubjectsUpdated;
		},
	},
	mounted() {
		const subjectIds = [];
		const topicIds = [];
		const conceptIds = [];
		if (this.PlatformManager.TutorsEdit.relatedTags.length !== 0) {
			this.PlatformManager.TutorsEdit.relatedTags.forEach((tag) => {
				this.selectedSubjects.push(tag);
				subjectIds.push(tag.id);
				if (tag.topics.length !== 0) {
					tag.topics.forEach((topic) => {
						this.selectedTopics.push(topic);
						topicIds.push(topic.id);
					});
					this.subjects.forEach((subject) => {
						if (subject.id === tag.id) {
							if (tag.topics.length === subject.topics.data.length) {
								topicIds.push(`allTopics-${tag.id}`);
							}
						}
					});
				}
			});
			this.$store.commit("PlatformManager/TutorsEdit/SET_SELECTED_SUBJECTS", {
				selectedSubjectIds: subjectIds,
			});
			this.$store.commit("PlatformManager/TutorsEdit/SET_SELECTED_TOPICS", {
				selectedTopicIds: topicIds,
			});
		}
		this.selectedTopics.forEach((topic) => {
			if (topic.concepts.length !== 0) {
				topic.concepts.forEach((concept) => {
					let conceptId = concept.id;
					concept.id = concept.id.toString();
					if (!concept.id.includes("concept-")) {
						conceptId = `concept-${concept.id}`;
					}
					conceptIds.push(conceptId);
					concept["id"] = conceptId;
					this.selectedConcepts.push(concept);
				});
				this.subjects.forEach((subject) => {
					if (topic.subject_id === subject.id) {
						subject.topics.data.forEach((topicOption) => {
							if (
								topicOption.id === topic.id &&
								topicOption.concepts.data.length === topic.concepts.length
							) {
								conceptIds.push(`allConcepts-${topic.id}`);
							}
						});
					}
				});
			}
		});
		this.$store.commit("PlatformManager/TutorsEdit/SET_SELECTED_CONCEPTS", {
			selectedConceptIds: conceptIds,
		});
		this.selectedConceptIds = this.PlatformManager.TutorsEdit.selectedConceptIds;

		this.subjects.forEach((subject) => {
			if (this.PlatformManager.TutorsEdit.selectedSubjectIds.includes(subject.id)) {
				this.topics.push(subject);
			}
			subject.topics.data.forEach((topic) => {
				if (this.PlatformManager.TutorsEdit.selectedTopicIds.includes(topic.id)) {
					this.concepts.push(topic);
				}
			});
		});

		this.selectedConceptIdsOnLoad = [...this.PlatformManager.TutorsEdit.selectedConceptIds];
		this.selectedTopicIdsOnLoad = [...this.PlatformManager.TutorsEdit.selectedTopicIds];
		this.selectedSubjectIdsOnLoad = [...this.PlatformManager.TutorsEdit.selectedSubjectIds];
	},
	methods: {
		changeConcept(topic, concept, conceptId) {
			if (conceptId.includes("allConcepts-")) {
				if (!this.selectedConceptIds.includes(conceptId)) {
					this.removeAllConcepts(topic);
				} else {
					this.addAllConcepts(topic);
				}
			} else {
				if (!this.selectedConceptIds.includes(conceptId)) {
					const conceptObject = {
						...concept,
						id: `concept-${concept.id}`,
					};
					this.removeConcept(conceptObject);
				} else {
					this.addConcept(concept, topic);
				}
			}
			this.sortTags();
			this.$store.commit("PlatformManager/TutorsEdit/SET_IS_TAGS_UPDATED", { value: this.isTagsUpdated });
		},
		addAllConcepts(topic) {
			topic.concepts.data.forEach((conceptInTopic) => {
				if (!this.selectedConceptIds.includes(`concept-${conceptInTopic.id}`)) {
					this.selectedConceptIds.push(`concept-${conceptInTopic.id}`);
					this.addConcept(conceptInTopic, topic);
				}
			});
		},
		removeAllConcepts(topic) {
			topic.concepts.data.forEach((conceptInTopic) => {
				const conceptObject = {
					...conceptInTopic,
					id: `concept-${conceptInTopic.id}`,
				};
				this.selectedConceptIds = this.selectedConceptIds.filter((id) => id === `concept-${conceptInTopic.id}`);
				this.removeConcept(conceptObject);
			});
		},
		addConcept(concept, topic) {
			const newConceptObject = {
				...concept,
				id: `concept-${concept.id}`,
			};
			this.selectedConcepts.push(newConceptObject);
			const isAllConceptsAdded = topic.concepts.data.every((concept) => this.selectedConceptIds.includes(`concept-${concept.id}`));
			if (isAllConceptsAdded && !this.selectedConceptIds.includes(`allConcepts-${topic.id}`)) {
				this.selectedConceptIds.push(`allConcepts-${topic.id}`);
			}
			this.$store.commit("PlatformManager/TutorsEdit/SET_SELECTED_CONCEPTS", {
				selectedConceptIds: [...this.selectedConceptIds],
			});
		},
		removeConcept(concept) {
			const newSelectedConceptIds = this.selectedConceptIds.filter((conceptId) => (conceptId !== `allConcepts-${concept.topic_id}` && conceptId !== concept.id));
			this.selectedConceptIds = newSelectedConceptIds;
			this.$store.commit("PlatformManager/TutorsEdit/SET_SELECTED_CONCEPTS", {
				selectedConceptIds: newSelectedConceptIds,
			});
			this.selectedConcepts = this.selectedConcepts.filter((currentConcept) => currentConcept.id !== concept.id);
			this.$store.commit("PlatformManager/TutorsEdit/SET_IS_TAGS_UPDATED", { value: this.isTagsUpdated });
		},
		changeTopic(subject, topic, topicId) {
			const alreadySelectedTopics = this.selectedTopics.map((topic) => topic.id);
			if (typeof topicId === "string" && topicId.includes("allTopics")) {
				const isAllSelected = subject.topics.data.every((topic) => alreadySelectedTopics.includes(topic.id));
				if (isAllSelected && subject.topics.data.length > 0) {
					this.removeAllTopics(subject);
				} else {
					this.addAllTopics(subject, topicId);
				}
			} else {
				if (alreadySelectedTopics.includes(topicId)) {
					const newTopicObject = {
						...topic,
						concepts: [...topic.concepts.data],
					};
					this.removeTopic(newTopicObject);
				} else {
					this.addTopic(subject, topic, topicId);
				}
			}
			this.sortTags();
			this.$store.commit("PlatformManager/TutorsEdit/SET_IS_TAGS_UPDATED", { value: this.isTagsUpdated });
		},
		addAllTopics(subject, topicId) {
			subject.topics.data.forEach((topicInSubject) => {
				if (!this.selectedTopicIds.includes(topicInSubject.id)) {
					this.selectedTopicIds.push(topicInSubject.id);
					this.addTopic(subject, topicInSubject, topicId);
				}
			});
		},
		removeAllTopics(subject) {
			subject.topics.data.forEach((topic) => {
				const newTopicObject = {
					...topic,
					concepts: topic.concepts?.data?.length ? [...topic.concepts.data] : topic.concepts,
				};
				this.selectedTopicIds = this.selectedTopicIds.filter((id) => id === topic.id);
				this.removeTopic(newTopicObject);
			});
		},
		addTopic(subject, topic, topicId) {
			const newTopic = { ...topic };
			if (!topic.hasOwnProperty("subject")) {
				newTopic.subject = { data: { ...subject } };
			}
			this.selectedTopics.push(newTopic);
			if (!this.concepts.some((concept) => concept.id === newTopic.id)) {
				this.concepts.push(newTopic);
			}
			const newSelectedTopicIdsArray = [
				...this.PlatformManager.TutorsEdit.selectedTopicIds,
				topicId,
			];
			this.$store.commit("PlatformManager/TutorsEdit/SET_SELECTED_TOPICS", {
				selectedTopicIds: newSelectedTopicIdsArray,
			});
		},
		removeTopic(topic) {
			const newTopicsArray = this.selectedTopics.filter((currentTopic) => {
				return currentTopic.id !== topic.id;
			});
			this.selectedTopics = [...newTopicsArray];
			const newSelectedTopicIds = newTopicsArray.map((topic) => topic.id);
			this.$store.commit("PlatformManager/TutorsEdit/SET_SELECTED_TOPICS", {
				selectedTopicIds: newSelectedTopicIds,
			});
			if (topic.concepts.length) {
				topic.concepts.forEach((concept) => {
					const conceptObject = {
						...concept,
						id: `${concept.id}`.includes("concept-") ? concept.id : `concept-${concept.id}`,
					};
					this.selectedConceptIds = this.selectedConceptIds.filter((id) => id !== `concept-${concept.id}`);
					this.removeConcept(conceptObject);
				});
				this.concepts = this.concepts.filter((topicObject) => topicObject.id !== topic.id);
			}
			this.$store.commit("PlatformManager/TutorsEdit/SET_IS_TAGS_UPDATED", { value: this.isTagsUpdated });
		},
		changeSubject(subject) {
			if (this.PlatformManager.TutorsEdit.selectedSubjectIds.indexOf(subject.id) !== -1) {
				this.selectedSubjects.push(subject);
				this.topics.push(subject);
				this.concepts = this.selectedTopics;
			} else {
				const subjectObject = {
					...subject,
					topics: [...subject.topics.data],
				};
				this.removeSubject(subjectObject);
			}
			this.sortTags();
			this.$store.commit("PlatformManager/TutorsEdit/SET_IS_TAGS_UPDATED", { value: this.isTagsUpdated });
		},
		removeSubject(subject) {
			const newSubjectsArray = this.selectedSubjects.filter((currentSubject) => {
				return currentSubject.id !== subject.id;
			});
			this.selectedSubjects = [...newSubjectsArray];
			const newSelectedSubjectIds = newSubjectsArray.map((subject) => subject.id);
			const sortedSubjectIds = newSelectedSubjectIds.sort((a, b) => a > b ? 1 : -1);
			this.$store.commit("PlatformManager/TutorsEdit/SET_SELECTED_SUBJECTS", {
				selectedSubjectIds: sortedSubjectIds,
			});

			if (subject.topics.length > 0) {
				const subjectObject = {
					...subject,
					topics: {
						data: [
							...subject.topics,
						],
					},
				};
				this.removeAllTopics(subjectObject);
			}

			this.topics = this.topics.filter((subjectObject) => subjectObject.id !== subject.id);
			this.$store.commit("PlatformManager/TutorsEdit/SET_IS_TAGS_UPDATED", { value: this.isTagsUpdated });
		},
		sortTags() {
			const tagArrays = {
				selectedSubjects: this.selectedSubjects,
				selectedTopics: this.selectedTopics,
				selectedConcepts: this.selectedConcepts,
				topics: this.topics,
				concepts: this.concepts,
			};
			["selectedSubjects", "selectedTopics", "selectedConcepts", "topics", "concepts"].forEach((array) => {
				tagArrays[array].sort((a, b) => a.id > b.id ? 1 : -1);
			});
		},
		selectAllValues() {
			const subjectIds = [];
			if (this.selectDeselectAll) {
				this.selectedSubjects = [...this.subjects];
				this.topics = [...this.subjects];
				this.subjects.forEach((subject) => {
					subjectIds.push(subject.id);
				});

				this.$store.commit("PlatformManager/TutorsEdit/SET_SELECTED_SUBJECTS", {
					selectedSubjectIds: subjectIds,
				});
			} else {
				this.$store.commit("PlatformManager/TutorsEdit/SET_SELECTED_SUBJECTS", {
					selectedSubjectIds: [],
				});
				this.selectedSubjects = [];
				this.topics = [];
			}
			this.$store.commit("PlatformManager/TutorsEdit/SET_IS_TAGS_UPDATED", { value: this.isTagsUpdated });
		},
	},
};
</script>
<style>
.tagFilters .tag,
.tagFilters .tag__text {
	width: 100%;
}
.tagFilters__headerRow {
	border-bottom: 2px solid #dddddd;
}
.tagFilters__row {
	padding-top: 0.3125rem;
}
.tagFilters__subjectCell {
	flex-basis: calc(100% - 0.5rem);
}
.tagFilters__topicsCell {
	flex-basis: calc(200% + 0.75rem);
}
.topicsTable__cell {
	flex-basis: calc(50% - 0.25rem);
}
</style>
