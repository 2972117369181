import rootInstance from "@/services/root.js";

const autoReviewApi = () => {
	rootInstance.defaults.baseURL = process.env.MIX_AUTO_REVIEW_API_BASE_URI + "/api/v2";
	return rootInstance;
};

export default {
	getEssaySuggestions: (essayId) =>
		autoReviewApi()
			.get("suggestions", {
				params: {
					["filter[essay_id]"]: essayId,
				},
			})
			.then(({ data: { data } }) => data),
	updateSuggestionStatus: (suggestionId, newStatus, reason = undefined) =>
		autoReviewApi()
			.patch(`suggestions/${suggestionId}`, {
				status: newStatus,
				status_change_reason: reason,
			})
			.then(({ data: { data } }) => data),
	rephraseSuggestion: (suggestionId) =>
		autoReviewApi()
			.post(`suggestions/${suggestionId}/rephrase`)
			.then(({ data: { data } }) => data),
	getGeneratedSummary: (essayId) =>
		autoReviewApi()
			.get(`/essay/${essayId}/generated-summary-comment`)
			.then(({ data: { data } }) => data),
	rephraseSummary: ({ essayId, summary, comments, parentId = null }) =>
		autoReviewApi()
			.post("rephrase-summary-comment", {
				essay_id: essayId,
				parent_id: parentId,
				summary,
				comments,
			})
			.then(({ data: { data } }) => data),
	updateSummary: (summaryId, data) =>
		autoReviewApi()
			.patch(`summary-comments/${summaryId}`, data)
			.then(({ data: { data } }) => data),
};
