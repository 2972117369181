var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M12 16.7196C8.12617 16.7196 4.93047 14.6118 3.62323 11.7883C4.93047 8.96469 8.12617 6.85687 12 6.85687C15.8738 6.85687 19.0695 8.96469 20.3768 11.7883C19.0695 14.6118 15.8738 16.7196 12 16.7196ZM12 5.35687C16.6638 5.35687 20.619 8.05346 22 11.7883C20.619 15.5231 16.6638 18.2196 12 18.2196C7.33623 18.2196 3.38101 15.5231 2 11.7883C3.38101 8.05346 7.33623 5.35687 12 5.35687ZM12.0161 14.8649C13.6729 14.8649 15.0161 13.5218 15.0161 11.8649C15.0161 10.208 13.6729 8.8649 12.0161 8.8649C10.3592 8.8649 9.01606 10.208 9.01606 11.8649C9.01606 13.5218 10.3592 14.8649 12.0161 14.8649Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }