export default [
	{
		path: "paper-math/problem-creator",
		name: "problemCreator",
		component: () => import("../pages/admin/problemList.vue"),
	},
	{
		path: "paper-math/problem-edit-math",
		name: "problemEdit",
		component: () => import("../pages/admin/problemEditMath.vue"),
	},
	{
		path: "paper-math/problem-edit-multiple/:mission_id",
		name: "problemEditMultiple",
		component: () => import("../pages/admin/problemEditMultiple.vue"),
	},
	{
		path: "paper-math/problem-edit-inputs/:mission_id",
		name: "problemEditInputs",
		component: () => import("../pages/admin/problemEditInputs.vue"),
	},
	{
		path: "paper-math/missions-admin",
		name: "missionsAdmin",
		component: () => import("../pages/admin/missionsAdmin.vue"),
	},
	{
		path: "paper-math/missions-edit/:season_id?",
		name: "missionsEdit",
		component: () => import("../pages/admin/missionsEdit.vue"),
	},
	{
		path: "paper-math/problem-generator",
		name: "problemGenerator",
		component: () => import("../pages/admin/problemGenerator.vue"),
	},
];
