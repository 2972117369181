import { merge } from "lodash";

import { subjectsAndTopicsUnderTopics } from "./subjectsAndTopics.js";

export default merge(
	subjectsAndTopicsUnderTopics,
	{
		en: {
			profile: {
				progress: {
					title: {
						initial: "Complete your profile",
						complete: "Awesome, your profile is complete!",
					},
					subtitle: {
						initial: "Start by selecting your avatar and fill in the information below",
						started: "Tell us {step_description} below",
						complete: "Thank you for completing your profile",
					},
					step_descriptions: {
						avatar: "your favourite avatar",
						grade: "your grade",
					},
					labels: {
						progress_bar: "Student profile progress bar",
						progress_bar_pending_step: "Pending student profile bar step",
						progress_bar_completed_step: "Completed student profile bar step",
					},
				},
				settings: {
					title: "Settings",
					section_general: "General Info",
					section_notifications: "Notifications",
					change_avatar: "Change your avatar",
					loading_avatar: "Loading your avatar",
					select_avatar: "Select your avatar",
					avatar_elephant: "Elephant avatar",
					avatar_elephant_desc_full: "{color1} elephant wearing a {color2} shirt, a {color3} hat and {color3} pants and shoes",
					avatar_elephant_desc_icon: "{color1} elephant wearing a {color2} shirt and a {color3} hat",
					avatar_racoon: "Racoon avatar",
					avatar_racoon_desc_full: "{color1} racoon wearing a {color2} shirt and shoes and {color3} pants",
					avatar_racoon_desc_icon: "{color1} racoon wearing a {color2} shirt",
					avatar_mouse: "Mouse avatar",
					avatar_mouse_desc_full: "{color1} mouse with {color2} ears, wearing a {color4} shirt and {color3} shoes and pants",
					avatar_mouse_desc_icon: "{color1} mouse with {color2} ears, wearing a {color4} shirt",
					avatar_bee: "Bee avatar",
					avatar_bee_desc_full: "{color1} bee with {color3} wings, wearing sunglasses, a black hat and shirt, {color2} pants, holding a {color4} skateboard",
					avatar_bee_desc_icon: "{color1} bee with {color3} wings, wearing sunglasses and a black hat and shirt",
					avatar_chameleon: "Chameleon avatar",
					avatar_chameleon_desc_full: "{color1} chameleon wearing a {color2} shirt, {color2} shoes and {color3} pants",
					avatar_chameleon_desc_icon: "{color1} chameleon wearing a {color2} shirt",
					avatar_blank: "Blank avatar",
					avatar_color_button: "Select {colors} for {avatar}",
					edit: "Edit",
					save: "Save Changes",
					cancel: "Cancel",
					previous: "Previous",
					next: "Next",
					fullName: "Full Name",
					email: "Email",
					password: "Password",
					oldPassword: "Old Password",
					newPassword: "New Password",
					confirmNewPassword: "Confirm New Password",
					grade: "Grade",
					grade_change_warning: "Warning: Changing your grade level may affect your subject preferences.",
					errors: {
						general: {
							default: "An error occurred while updating your password. Please try again or contact support if the problem persists.",
							The_new_password_must_be_at_least_6_characters_: "The new password must be at least 6 characters.",
							The_new_password_format_is_invalid_: "The new password format is invalid",
							The_new_password_confirmation_does_not_match_: "The new password confirmation does not match",
							The_password_provided_was_incorrect_: "The password provided was incorrect.",
						},
						profile: "There was an error fetching your profile details. Please refresh and try again or contact support if the problem persists.",
					},
				},
				classroom: {
					comfortable_with: "Comfortable with",
					uncomfortable_with: "Uncomfortable with",
				},
			},
		},
		fr: {
			profile: {
				progress: {
					title: {
						initial: "Complète ton profil",
						complete: "Parfait, ton profil est complet!",
					},
					subtitle: {
						initial: "Commence par sélectionner ton avatar et entrer les informations ci-dessous",
						started: "Dit nous {step_description} ci-dessous",
						complete: "Merci d’avoir complété ton profil",
					},
					step_descriptions: {
						avatar: "ton avatar préféré",
						grade: "ton niveau scolaire",
					},
					labels: {
						progress_bar: "Barre de progression du profil étudiant",
						progress_bar_pending_step: "Étape de la barre de progression du profil d'étudiant incomplète",
						progress_bar_completed_step: "Étape de la barre de progression du profil d'étudiant complète",
					},
				},
				settings: {
					title: "Settings",
					section_general: "Informations générales",
					section_notifications: "Notifications",
					change_avatar: "Change ton avatar",
					loading_avatar: "Chargement de ton avatar",
					select_avatar: "Choisir ton avatar",
					edit: "Modifier tes paramètres",
					save: "Sauvegarder les changements",
					cancel: "Annuler",
					fullName: "Nom complet",
					email: "Address courriel",
					password: "Mot de passe",
					oldPassword: "Ancien mot de passe",
					newPassword: "Nouveau mot de passe",
					confirmNewPassword: "Confirmation du mot de passe",
					grade: "Niveau scolaire",
					grade_change_warning: "Attention: La modification de votre niveau scolaire peut affecter vos préférences de matières.",
					errors: {
						general: {
							default: "Une erreur s’est produite lors de la mise à jour de votre mot de passe. Veuillez réessayer ou nous contacter si le problème persiste",
							The_new_password_must_be_at_least_6_characters_: "Le mot de passe doit contenir au moins 6 caractères.",
							The_new_password_format_is_invalid_: "Le nouveau format de mot de passe n’est pas valide.",
							The_new_password_confirmation_does_not_match_: "Les mots de passe ne sont pas identiques.",
							The_password_provided_was_incorrect_: "Le mot de passe est incorrect.",
						},
						profile: "Une erreur s’est produite lors de la récupération des détails de votre profil. Veuillez réessayer ou nous contacter si le problème persiste",
					},
				},
				classroom: {
					comfortable_with: "À l’aise avec",
					uncomfortable_with: "Pas à l’aise avec",
				},
				dashboard: {
					video: "Vidéo d’introduction",
					teacher: "Nominez votre professeur ",
				},

			},
		},
		es: {
			profile: {
				progress: {
					title: {
						initial: "Completa tu perfil",
						complete: "¡Genial, tu perfil está completo!",
					},
					subtitle: {
						initial: "Comienza seleccionando tu avatar y completa la información a continuación",
						started: "Comparte {step_description} a continuación",
						complete: "Gracias por completar tu perfil",
					},
					step_descriptions: {
						avatar: "tu avatar favorito",
						grade: "tu grado",
					},
					labels: {
						progress_bar: "Barra de progreso del perfil del estudiante",
						progress_bar_pending_step: "Paso de la barra de progreso del perfil del estudiante incompleto",
						progress_bar_completed_step: "Paso de la barra de progreso del perfil del estudiante completo",
					},
				},
				settings: {
					title: "Settings",
					section_general: "Información general",
					section_notifications: "Notificaciones",
					change_avatar: "Cambia tu avatar",
					loading_avatar: "Cargando tu avatar",
					select_avatar: "Selecciona tu avatar",
					edit: "Editar",
					save: "Guardar cambios",
					cancel: "Cancelar",
					fullName: "Nombre completo",
					email: "Correo electrónico",
					password: "Contraseña",
					oldPassword: "Contraseña anterior",
					newPassword: "Nueva contraseña",
					confirmNewPassword: "Confirmación de contraseña",
					grade: "Grado",
					grade_change_warning: "Advertencia: Cambiar tu grado puede afectar tus preferencias de materias.",
					errors: {
						general: {
							default: "Ocurrió un error al actualizar tu contraseña. Vuelve a intentarlo o comunícate con el equipo de apoyo si el problema persiste.",
							The_new_password_must_be_at_least_6_characters_: "La contraseña debe contener al menos 6 caracteres.",
							The_new_password_format_is_invalid_: "El formato de la nueva contraseña no es válido",
							The_new_password_confirmation_does_not_match_: "Las contraseñas no son similares.",
							The_password_provided_was_incorrect_: "La contraseña es incorrecta.",
						},
						profile: "Ocurrió un error al obtener los detalles de tu perfil. Recarga la página y vuelve a intentarlo o comunícate con el equipo de apoyo si el problema persiste.",
					},
				},
				classroom: {
					comfortable_with: "Se siente competente en",
					uncomfortable_with: "No se siente competente en",
				},
				dashboard: {
					video: "Video de introducción",
					teacher: "Nomina a tu maestro",
				},

			},
		},
	},
);
