<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		viewBox="0 0 11 14"
	>
		<g
			fill="none"
			fill-rule="evenodd"
		>
			<path
				:fill="iconColor"
				d="M6.875 0h-5.5C.619 0 .007.63.007 1.4L0 12.6c0 .77.612 1.4 1.368 1.4h8.257c.756 0 1.375-.63 1.375-1.4V4.2L6.875 0z"
			/>
			<path
				:fill="iconColor"
				d="M2.5 1.5c-.55 0-.995.495-.995 1.1L1.5 11.4c0 .605.445 1.1.995 1.1H8.5c.55 0 1-.495 1-1.1V4.8l-3-3.3h-4z"
			/>
			<path
				:fill="iconSecondaryColor"
				d="M8.25 11.2h-5.5V9.8h5.5zM8.25 8.4h-5.5V7h5.5zM6 4.85V1l3.781 3.85z"
			/>
		</g>
	</svg>
</template>

<script>
import colors from "../../utilities/Colors.js";
export default {
	props: {
		width: {
			type: [Number, String],
			default: 11,
		},
		height: {
			type: [Number, String],
			default: 14,
		},
		iconColor: {
			type: String,
			default: colors.C_LIGHTER_GRAY,
		},
		iconSecondaryColor: {
			type: String,
			default: colors.C_WHITE,
		},
	},
};
</script>
