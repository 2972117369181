<template>
	<div class="preferredLanguage">
		<div class="preferredLanguage__header">
			<PreferredLanguageBubble
				class="languageIcon"
				:language="preferredLanguageKey"
			/>
			{{ studentPreferredLanguage }} Speaker
		</div>
		Student is more comfortable speaking {{ studentPreferredLanguage }}. Please adjust your approach
		to help them understand easily.
	</div>
</template>
<script>
import Colors from "@/utilities/Colors.js";
import PreferredLanguageBubble from "@/components/PreferredLanguageBubble.vue";

export default {
	components: {
		PreferredLanguageBubble,
	},
	props: {
		preferredLanguageKey: {
			type: String,
			required: true,
			validator(value) {
				const allowedValues = ["es", "en", "ar", "zh", "fr", "ht"];
				return allowedValues.includes(value);
			},
		},
	},
	computed: {
		/**
		 * @returns {String}
		 */
		greenColor() {
			return Colors.C_GREEN;
		},
		/**
		 * @returns {String}
		 */
		studentPreferredLanguage() {
			switch (this.preferredLanguageKey) {
				case "fr":
					return "French";
				case "es":
					return "Spanish";
				case "zh":
					return "Mandarin";
				case "ar":
					return "Arabic";
				default:
					return "English";
			}
		},
	},
};
</script>
<style scoped>
.preferredLanguage {
	display: flex;
	flex-direction: column;
	margin: 32px 0;
	font-size: 12px;
	line-height: 1.33;
	letter-spacing: 0.02px;
	color: var(--c-black);
}
.languageIcon {
	margin-right: 8px;
}
.preferredLanguage__header {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 8px;
	font-size: 14px;
	font-weight: bold;
	color: var(--c-black);
}
</style>
