// Api

import { startOfWeek, endOfWeek, millisecondsToMinutes } from "date-fns";
import { getTimezoneOffset } from "date-fns-tz";

import SchedulesAPI from "../../../services/api/Schedules.js";
import { formatDateToTimestamp } from "../../../utilities/dateHelpers.js";

const SUNDAY_WEEKDAY_INDEX = 0;
const MONDAY_WEEKDAY_INDEX = 1;

const getCurrentESTOffset = () => {
	const estOffset = getTimezoneOffset("America/New_York");
	const estOffsetFormatted = millisecondsToMinutes(estOffset) * -1;
	return estOffsetFormatted;
};

const startDayOfWeekIndex = () => {
	const currentDate = new Date();
	const currentZone = getCurrentESTOffset() - currentDate.getTimezoneOffset();
	return currentZone < 0 ? SUNDAY_WEEKDAY_INDEX : MONDAY_WEEKDAY_INDEX;
};


export const state = {
	startDate: "",
	endDate: "",
	shiftHoursBreakdown: [],
	overallTotal: 0,
};

export const mutations = {
	SET_START_DATE(state, payload) {
		state.startDate = payload;
	},
	SET_END_DATE(state, payload) {
		state.endDate = payload;
	},
	SET_SHIFT_HOURS_BREAKDOWN(state, payload) {
		state.shiftHoursBreakdown = payload.shiftHoursBreakdown;
	},
	SET_TOTAL_HOURS(state, payload) {
		state.overallTotal = payload.overallTotal;
	},
};

export const actions = {
	async getShiftHoursStatistics({ state, commit }) {
		try {
			const params = {
				start_time: state.startDate,
				end_time: state.endDate,
			};
			const response = await SchedulesAPI.getShiftStatistics(params);
			commit("SET_SHIFT_HOURS_BREAKDOWN", {
				shiftHoursBreakdown: response.data.data.detail,
			});
			commit("SET_TOTAL_HOURS", {
				overallTotal: response.data.data.overall.total,
			});
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	getStartAndEndDates({ commit }) {
		const currentDate = new Date();
		const weekIndex = startDayOfWeekIndex();
		const startDate = formatDateToTimestamp(startOfWeek(currentDate, { weekStartsOn: weekIndex }));
		const endDate = formatDateToTimestamp(endOfWeek(currentDate, { weekStartsOn: weekIndex }));
		commit("SET_START_DATE", startDate);
		commit("SET_END_DATE", endDate);
	},
};

export const getters = {
	getLengthOfShifts(state) {
		return Object.keys(state.shiftHoursBreakdown).length;
	},
};

export default { namespaced: true, state, getters, mutations, actions };
