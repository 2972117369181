import Colors from "./Colors.js";

/**
 * Return default colors option for doughnut chart
*/
export const chartDefaultDoughnutColorsOptions = [
	Colors.C_DARK_GREEN,
	Colors.C_LIGHT_BLUE,
	Colors.C_DARK_ORANGE,
	Colors.C_BLUE,
	Colors.C_DARK_PINK,
];

/**
 * Return default color option for line chart
*/
export const chartDefaultLineColorOption = Colors.C_BLUE;

/**
 * Return default color area option for line chart
*/
export const chartDefaultLineAreaColorOption = "rgba(14, 15, 224, 0.1)";

/**
 * Return default bar color
 */
export const chartDefaultBarColorOption = Colors.C_BLUE;

/**
 * Return default color option for tick
*/
const chartDefaultTickColorOption = Colors.C_BLACK;

/**
 * Default option for all chart
*/
const defaultOptions = {
	responsive: true,
	maintainAspectRatio: false,
};

/**
 * Return default option for line chart
*/
export const chartLineOptions = (chartData, hasLegend = false) => ({
	...defaultOptions,
	plugins: {
		legend: {
			display: hasLegend,
			position: "bottom",
			labels: {
				usePointStyle: true,
				pointStyle: "dash",
			},
		},
		tooltip: {
			position: "nearest",
			padding: 12,
			displayColors: false,
			callbacks: {
				title: (context) => {
					const index = context[0].dataIndex;
					return chartData[index].label.title;
				},
				label: ({ dataIndex }) => chartData[dataIndex].label.content,
			},
		},
	},
});

/**
 * Return default option for line chart
*/
export const chartBarOptions = (chartData) => ({
	...defaultOptions,
	plugins: {
		legend: {
			display: false,
		},
		tooltip: {
			position: "nearest",
			padding: 12,
			displayColors: false,
			callbacks: {
				title: (context) => {
					const index = context[0].dataIndex;
					return chartData[index].label.title;
				},
				label: ({ dataIndex }) => chartData[dataIndex].label.content,
			},
		},
	},
});

/**
 * Return default option for doughnut chart
*/
export const chartDoughnutOptions = (tooltipItems) => ({
	...defaultOptions,
	cutout: "77%",
	plugins: {
		legend: {
			display: false,
		},
		tooltip: {
			position: "nearest",
			padding: 12,
			displayColors: false,
			callbacks: {
				title: (context) => context[0].label,
				label: ({ dataIndex }) => tooltipItems[dataIndex],
			},
		},
	},
});

/**
 * Return X axe rules option for bar chart
 * @param {Object} callback - custom ticks rule rendering
 * @returns {Object}
*/
export const chartBarAxeX = (callback) => {
	const options = {
		border: {
			display: false,
		},
		grid: {
			display: false,
			drawOnChartArea: false,
			drawTicks: false,
		},
		ticks: {
			display: true,
			autoSkip: false,
			maxRotation: 0,
			color: chartDefaultTickColorOption,
			font: {
				size: 10,
			},
			padding: 8,
		},
	};

	if (callback) {
		options.ticks.callback = callback;
	}

	return options;
};

/**
 * Return Y axe rules option for bar chart
 * @returns {Object}
*/
export const chartBarAxeY = {
	beginAtZero: true,
	border: {
		display: false,
	},
	grid: {
		display: false,
		drawOnChartArea: false,
		drawTicks: false,
	},
	ticks: {
		display: false,
	},
};

/**
 * Return X or Y axe rules option for line chart
 * @param {Object} callback - custom ticks rule rendering
 * @returns {Object}
*/
export const chartLineAxe = (callback, maxTicks) => {
	const options = {
		beginAtZero: true,
		grid: {
			display: true,
			drawBorder: true,
			drawOnChartArea: false,
			drawTicks: true,
		},
		ticks: {
			display: true,
			maxRotation: 0,
			color: chartDefaultTickColorOption,
			font: {
				size: 10,
			},
			padding: 4,
			callback,
		},
	};

	if (maxTicks) {
		options.ticks.maxTicksLimit = maxTicks;
	}

	if (callback) {
		options.ticks.callback = callback;
	}
	return options;
};

/**
 * Return bar chart data
 * @param {Object} chartData
 * @param {Object} color
 * @returns {Object}
*/
export const chartBarData = (chartData, backgroundColor, options) => ({
	labels: chartData.map(({ label }) => label.tick),
	datasets: [{
		minBarLength: 4,
		data: chartData.map(({ value }) => value),
		backgroundColor,
		...options,
	}],
});

/**
 * Return line chart data formatted
 * @param {Object} chartData
 * @param {String} color
 * @param {String} backgroundColor
 * @param {Boolean} average
 * @param {Number} targetValue
 * @param {String} dataLabel
 * @returns {Object}
*/
export const chartLineData = (chartData, color, backgroundColor, average, targetValue, dataLabel = "Data") => {
	const data = {
		labels: chartData.map(({ label }) => label.tick),
		datasets: [{
			data: chartData.map(({ value }) => value),
			label: dataLabel,
			borderColor: color,
			borderWidth: 1,
			backgroundColor,
			pointBorderColor: color,
			pointBackgroundColor: color,
			fill: true,
			tension: 0.4,
		}],
	};
	const chartDataLength = chartData.length;
	if (average) {
		const datasetForAverageLine = () => {
			const average = chartData.reduce((sum, data) => sum + data.value, 0) / chartDataLength;
			return Array.apply(null, Array(chartDataLength)).map(() => average);
		};
		data.datasets.push({
			data: datasetForAverageLine(),
			label: "Average",
			borderColor: Colors.C_BLUE,
			borderWidth: 3,
			borderDash: [5],
			pointRadius: 0,
		});
	}
	if (targetValue) {
		const datasetForTargetLine = () => {
			return Array.apply(null, Array(chartDataLength)).map(() => targetValue);
		};
		data.datasets.push({
			data: datasetForTargetLine(),
			label: "Ideal",
			borderColor: Colors.C_LIGHT_YELLOW,
			borderWidth: 8,
			pointRadius: 0,
		});
	}
	return data;
};

/**
 * Return doughnut chart data formatted
 * @param {Object} chartData
 * @returns {Object}
*/
export const chartDoughnutData = (chartData) => ({
	labels: chartData.map(({ label }) => label),
	datasets: [{
		data: chartData.map(({ value }) => value),
		backgroundColor: chartData.map(({ color }) => color),
	}],
});
