// API
import { getFlag } from "@paper-co/web-toolkit";

import { UsersAPI } from "@/modules/Customers/index.js";
import MathAPI from "@/modules/Math/service/MathAPI.js";

// NOTE: 	when removing featureFlagTeacherMissionInsightsIsEnabled 
// 				rename tableHeadersWithMissions as tableHeaders and remove tableHeaders (below)

const tableHeadersWithMissions = [
	{
		header: "",
		value: "isActive",
		orderable: false,
		state: null,
	},
	{
		header: "Last Name",
		value: "last_name",
		orderable: true,
		state: null,
	},
	{
		header: "First Name",
		value: "first_name",
		orderable: true,
		state: null,
	},
	{
		header: "sessions",
		value: "number_of_sessions",
		orderable: true,
		state: null,
	},
	{
		header: "essays",
		value: "number_of_essays",
		orderable: true,
		state: null,
	},
	{
		header: "missions",
		value: "number_of_completed_missions",
		orderable: false,
		state: null,
	},
	{
		header: "last active",
		value: "last_activity",
		orderable: true,
		state: null,
	},
	{
		header: "flags",
		value: "number_of_flags",
		orderable: true,
		state: null,
	},
];

const tableHeaders = [
	{
		header: "",
		value: "isActive",
		orderable: false,
		state: null,
	},
	{
		header: "Last Name",
		value: "last_name",
		orderable: true,
		state: null,
	},
	{
		header: "First Name",
		value: "first_name",
		orderable: true,
		state: null,
	},
	{
		header: "sessions",
		value: "number_of_sessions",
		orderable: true,
		state: null,
	},
	{
		header: "essays",
		value: "number_of_essays",
		orderable: true,
		state: null,
	},
	{
		header: "last active",
		value: "last_activity",
		orderable: true,
		state: null,
	},
	{
		header: "flags",
		value: "number_of_flags",
		orderable: true,
		state: null,
	},
];

const getDefaultState = () => ({
	isLoading: true,
	orderBy: "",
	selectedSectionId: 0,
	sections: [],
	onboardSlides: ["teacher-welcome", "teacher-students", "teacher-analytics"],
	users: [],
	tableHeaders,
	pagination: {
		totalPages: 1,
		currentPage: 1,
	},
});

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations: {
		CLEAR_STATE(state) {
			Object.assign(state, getDefaultState());
		},
		/**
		 * @param {Array} sections
		 */
		SET_SECTIONS(state, { sections }) {
			state.sections = sections;
		},
		/**
		 * @param {Boolean} isLoading
		 */
		SET_IS_LOADING(state, { isLoading }) {
			state.isLoading = isLoading;
		},
		/**
		 * @param {Array} onboardSlides
		 */
		SET_ONBOARD_SLIDES(state, { onboardSlides }) {
			state.onboardSlides = onboardSlides;
		},
		/**
		 * @param {String} orderBy
		 */
		SET_ORDER_BY(state, { orderBy }) {
			state.orderBy = orderBy;
		},
		/**
		 * @param {Object} pagination
		 */
		SET_PAGINATION(state, { totalPages, currentPage }) {
			state.pagination = { totalPages, currentPage };
		},
		/**
		 * @param {Integer} payload.selectedSectionId
		 */
		SET_SELECTED_SECTION_ID(state, { selectedSectionId }) {
			state.selectedSectionId = selectedSectionId;
		},
		/**
		 * @param {Array} tableHeaders
		 */
		SET_TABLE_HEADERS(state, { tableHeaders }) {
			state.tableHeaders = tableHeaders;
		},
		/**
		 * @param {Array} users
		 */
		SET_USERS(state, { users }) {
			state.users = users;
		},
	},
	actions: {
		/**
		 * Handles request to get the current user and their sections.
		 */
		async getCurrentUserSections({ rootState, commit }) {
			commit("SET_IS_LOADING", { isLoading: true });
			commit("SET_ORDER_BY", { orderBy: "" });

			const params = { include: "sections" };
			try {
				const response = await UsersAPI.show(rootState.currentUser.id, params);
				const sections = response.data.data.sections.data.map(function(section) {
					const obj = {};
					obj.id = section.id;
					obj.name = section.name;
					obj.active_from = section.active_from;
					return obj;
				});

				commit("SET_SECTIONS", { sections });
				commit("SET_IS_LOADING", { isLoading: false });

				return Promise.resolve(sections);
			} catch (error) {
				commit("SET_IS_LOADING", { isLoading: false });
				return Promise.reject(error);
			}
		},
		/**
		 * Handles request to get all users in a specified section.
		 * @param {Object} payload
		 * @param {?String} payload.wildcard-name
		 * @param {?String} payload.sort
		 * @param {?Number} payload.page
		 */
		async getUsersInSection({ state, commit }, payload) {
			commit("SET_IS_LOADING", { isLoading: true });
			payload["limit"] = 30;
			try {
				const response = await UsersAPI.listSectionUsers(state.selectedSectionId, payload);
				const {
					data: {
						data,
						meta: {
							pagination: {
								total_pages: totalPages,
								current_page: currentPage,
							},
						},
					},
				} = response;
				const featureFlagTeacherMissionInsightsIsEnabled = await getFlag("PE-19207-teacher-mission-insight");

				if (featureFlagTeacherMissionInsightsIsEnabled) {
					// NOTE: when removing the feature flag the next line should also be removed
					// as default tableHeaders will already have missions column defined.
					commit("SET_TABLE_HEADERS", { tableHeaders: tableHeadersWithMissions });
					const userIdsQueryString = data.map(({ id }) => `user_ids[]=${id}`).join("&");

					const completedMissions =
						(await MathAPI.getProblemCompletedCounts(userIdsQueryString))
							.reduce((agg, { user_id, problem_completed_count }) => ({
								...agg,
								[user_id]: problem_completed_count,
							}), {});

					const users = data.map((user) => ({
						...user,
						number_of_completed_missions: completedMissions[user.id],
					}));

					commit("SET_USERS", { users });
				} else {
					commit("SET_USERS", { users: data });
				}

				commit("SET_PAGINATION", { totalPages, currentPage });
				commit("SET_IS_LOADING", { isLoading: false });

				return Promise.resolve(response);
			} catch (error) {
				commit("SET_IS_LOADING", { isLoading: false });
				return Promise.reject(error);
			}
		},
		/**
		 * @param {Object} payload
		 * @param {Number} payload.newPage
		 */
		async setCurrentPage({ state, dispatch }, payload) {
			const params = {
				page: payload.newPage,
				sort: state.orderBy,
			};

			try {
				const response = await dispatch("getUsersInSection", params);
				return Promise.resolve(response);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async getTableHeaders({ state, commit }) {
			const newHeaders = state.tableHeaders.map((header) => {
				if (header.value === "number_of_sessions") {
					header.header = "Live sessions";
				} else if (header.value === "number_of_essays") {
					header.header = "Writing reviews";
				}
				return header;
			});
			commit("SET_TABLE_HEADERS", { tableHeaders: newHeaders });
		},
	},
};
