<script>
//Lib
import { mapState } from "vuex";

//Components
import Tag from "@/components/Tag.vue";
import TagsShowTableAbstract from "@/components/TagsShowTableAbstract.vue";

export default {
	components: {
		Tag,
	},
	extends: TagsShowTableAbstract,
	computed: {
		...mapState(["PlatformManager"]),
		TutorsEdit() {
			return this.PlatformManager.TutorsEdit;
		},
		/**
		 * @return {Boolean}
		 */
		isTagsEmpty() {
			return this.TutorsEdit.tutor?.tutor.data.subjects.data.length === 0 ? true : false;
		},
	},
	async mounted() {
		await this.$store.dispatch("PlatformManager/TutorsEdit/getTutor");
		if (this.TutorsEdit.isLoading === false) {
			this.setUpTags();
		}
	},
	methods: {
		/**
		 * Build subject-topic-concept object
		 * and assign the entire object as the course's
		 * tags
		 */
		setUpTags() {
			const subjectIds = [];
			const topicsWithSubject = [];
			const topicsWithoutSubject = [];
			const tutorTags = [];
			let conceptsOfTopic = [];
			let topicsOfASubjectArray = [];

			//get all subject ids
			this.TutorsEdit.relatedSubjects.forEach((subject) => {
				subjectIds.push(subject.id);
			});
			//add topic to subject with topics array if it's related to a subject
			this.TutorsEdit.relatedTopics.forEach((topic) => {
				if (subjectIds.includes(topic.subject_id) === true) {
					topicsWithSubject.push(topic);
				} else {
					topicsWithoutSubject.push(topic);
				}
			});

			//build topic-concept object
			this.TutorsEdit.relatedTopics.forEach((topic) => {
				conceptsOfTopic = this.TutorsEdit.relatedConcepts.filter((concept) => {
					return concept.topic_id === topic.id;
				});
				topic["concepts"] = conceptsOfTopic;
				conceptsOfTopic = [];
			});

			//build subject-topic object
			this.TutorsEdit.relatedSubjects.forEach((subject) => {
				topicsOfASubjectArray = topicsWithSubject.filter((topic) => {
					return subject.id === topic.subject_id;
				});
				subject["topics"] = topicsOfASubjectArray;
				tutorTags.push(subject);
				topicsOfASubjectArray = [];
			});

			//set tags to newly built subject-topic-concept object
			this.tags = tutorTags;
			this.$store.commit("PlatformManager/TutorsEdit/SET_RELATED_TAGS", {
				relatedTags: this.tags,
			});
		},
	},
};
</script>
