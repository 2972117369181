import Vue from "vue";

import { formatDateToTimestamp } from "@/utilities/dateHelpers.js";
import { transformSessionTopicAndSubject } from "@/utilities/SessionStoreHelpers.js";
import { getFormattedBookmarkMessage } from "@/utilities/BookmarkHelpers.js";
import MessagesAPI from "@/services/api/Messages.js";
import SessionsAPI from "@/services/api/Sessions.js";

const state = {
	bookmarks: {},
	isLoaded: false,
	isShowingBookmarks: false,
	bookmarksMetadata: null,
	bookmarkingParams: {
		sessionId: null,
		messageId: null,
	},
};

export const mutations = {
	/**
	 *
	 * @param {*} state
	 * @param {Object} bookmarks
	 */
	SET_BOOKMARKS(state, { bookmarks }) {
		state.bookmarks = bookmarks;
	},
	/**
	 *
	 * @param {*} state
	 * @param {Boolean} isLoaded
	 */
	SET_IS_BOOKMARKS_LOADED(state, { isLoaded }) {
		state.isLoaded = isLoaded;
	},
	/**
	 *
	 * @param {*} state
	 * @param {Boolean} isShowingBookmarks
	 */
	SET_IS_SHOWING_BOOKMARKS(state, { isShowingBookmarks }) {
		state.isShowingBookmarks = isShowingBookmarks;
	},
	/**
	 *
	 * @param {*} state
	 * @param {Boolean} isShowingBookmarks
	 */
	SET_BOOKMARKS_METADATA(state, { currentPage, totalPages }) {
		state.bookmarksMetadata = { currentPage, totalPages };
	},
	SET_BOOKMARKING_PARAMS(state, { sessionId, messageId }) {
		state.bookmarkingParams = { sessionId, messageId };
	},
	UPDATE_REACTIONS_BOOKMARK_MESSAGE(state, { sessionId, messageId, userReactions }) {
		const bookmarkedMessage = state.bookmarks[sessionId].messages.find(({ id }) => id === messageId);
		Vue.set(bookmarkedMessage, "userReactions", userReactions.data || userReactions);
	},
};

export const actions = {
	/**
	 * 
	 * @param commit
	 * @param state 
	 * @param dispatch
	 */
	async selectBookmarksSession({ state, commit, dispatch }) {
		commit("Classroom/SET_CURRENT_SESSION_ID", {
			id: null,
		}, { root: true });
		commit("Classroom/SET_RIGHT_SIDEBAR_TABS", {
			rightSidebarTabs: [],
		}, { root: true });
		commit("Classroom/SET_RIGHT_SIDEBAR_COMPONENT", {
			rightSidebarComponent: "",
		}, { root: true });
		commit("SET_IS_SHOWING_BOOKMARKS", {
			isShowingBookmarks: true,
		});
		if (!state.isLoaded) {
			dispatch("getBookmarkedMessages", { page: 1 });
			commit("SET_IS_BOOKMARKS_LOADED", {
				isLoaded: true,
			});
		}
	},

	/**
	 * 
	 * @param commit
	 * @param state 
	 * @param {Boolean} page
	 */
	async getBookmarkedMessages({ commit }, { page }) {
		const response = await SessionsAPI.getSessionsWithBookmarkedMessages({
			params: {
				paginate: 10,
				page,
				include: "messages.user,messages.user.roles,users.roles",
			},
		});
		const bookmarks = response.data.data.map(transformSessionTopicAndSubject);
		const bookmarkedMessages = {};
		bookmarks.forEach((bookmarkSession) => {
			bookmarkedMessages[bookmarkSession.id] = {
				...bookmarkSession,
				messages: bookmarkSession.messages.data,
			};
		});
		commit("SET_BOOKMARKS_METADATA", {
			currentPage: response.data.meta.pagination.current_page,
			totalPages: response.data.meta.pagination.total_pages,
		});
		commit("SET_BOOKMARKS", {
			bookmarks: {
				...state.bookmarks,
				...bookmarkedMessages,
			},
		});
	},

	/**
	 * 
	 * @param commit
	 * @param state 
	 * @param {Number} state.currentSessionId
	 * @param {Number || null} bookmarkedAt
	 * @param {Number} messageId
	 */
	async setMessageBookmarkStatus({ rootState, dispatch, commit }, {
		bookmarkedAt,
		messageId,
		sessionId,
	}) {
		if (bookmarkedAt) {
			await dispatch("bookmarkMessage", { messageId, sessionId, bookmarkedAt });
		} else {
			await dispatch("removeMessageBookmark", { messageId, sessionId });
		}
		const session = rootState.Classroom.sessions[sessionId] || rootState.Student.Session.recentSessions[sessionId];
		const message = session?.messages.find((message) => message.id === messageId);
		if (message) {
			commit("Student/Session/UPDATE_MESSAGE_PARAM", {
				message,
				key: "bookmarkedAt",
				value: bookmarkedAt,
			}, { root: true });
		}
	},

	/**
	 * 
	 * @param commit
	 * @param state 
	 * @param {Object} state.bookmarks
	 * @param {Number} messageId
	 * @param {Number} sessionId
	 * @param {Number || null} bookmarkedAt
	 */
	async bookmarkMessage({ state, commit, rootState }, { messageId, sessionId, bookmarkedAt }) {
		const session = rootState.Classroom.sessions[sessionId] ||
			rootState.Student.Session.recentSessions[sessionId] ||
			null;
		const { bookmarks, bookmarksMetadata } = state;
		await MessagesAPI.bookmarkMessage(messageId);
		const isMorePagesToLoad = bookmarksMetadata?.currentPage !== bookmarksMetadata?.totalPages;
		const isAllowedToAppendBookmark = !isMorePagesToLoad ||
			Object.keys(bookmarks).some((id) => id <= sessionId);
		if (state.isLoaded && isAllowedToAppendBookmark) {
			const unformattedMessage = session?.messages.find(({ id }) => id === messageId);
			unformattedMessage.bookmarkedAt = bookmarkedAt;
			const message = getFormattedBookmarkMessage(unformattedMessage);
			const updatedBookmarks = { ...bookmarks };
			if (updatedBookmarks[sessionId]) {
				updatedBookmarks[sessionId].messages.unshift(message);
			} else {
				updatedBookmarks[sessionId] = {
					...session,
					messages: [message],
					created_at: formatDateToTimestamp(new Date(`${session.created_at} UTC`)),
				};
			}
			commit("SET_BOOKMARKS", {
				bookmarks: updatedBookmarks,
			});
		}
	},

	/**
	 * 
	 * @param commit
	 * @param state 
	 * @param {Object} state.bookmarks
	 * @param {Number} messageId
	 * @param {Number} sessionId
	 * @param {Number || null} bookmarkedAt
	 */
	async removeMessageBookmark({ state, commit }, { messageId, sessionId }) {
		await MessagesAPI.removeMessageBookmark(messageId);
		if (state.isLoaded && state.bookmarks[sessionId]) {
			const updatedBookmarks = { ...state.bookmarks };
			updatedBookmarks[sessionId].messages = updatedBookmarks[sessionId].messages.filter(
				(message) => message.id !== messageId,
			);
			if (updatedBookmarks[sessionId].messages.length === 0) {
				delete updatedBookmarks[sessionId];
			}
			commit("SET_BOOKMARKS", {
				bookmarks: updatedBookmarks,
			});
		}
	},

	/**
	 * 
	 * @param commit
	 * @param state 
	 * @param dispatch 
	 * @param {Object} state.Classroom.sessions
	 * @param {Object} state.recentSessions
	 * @param {Number} sessionId
	 */
	async navigateToSession({ rootState, commit, dispatch }, { sessionId }) {
		const liveSession = rootState.Classroom.sessions[sessionId];
		const pastSession = rootState.Student.Session.recentSessions[sessionId];
		if (liveSession) {
			dispatch("Classroom/setCurrentSession", sessionId, { root: true });
			commit("Classroom/SET_LEFT_SIDEBAR_COMPONENT", {
				leftSidebarComponent: "sidebar-sessions-student",
			}, { root: true });
		} else if (pastSession) {
			dispatch("Student/Session/selectRecentSession", sessionId, { root: true });
			commit("Classroom/SET_LEFT_SIDEBAR_COMPONENT", {
				leftSidebarComponent: "sidebar-history",
			}, { root: true });
		} else {
			dispatch("Student/Session/loadAndSelectNotLoadedRecentSession", sessionId, { root: true });
		}
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
