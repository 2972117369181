<script setup>
import { OrigamiIcon, OrigamiIconButton } from "@origami/vue2";

const props = defineProps({
	alert: {
		type: Object,
		required: true,
	},
});

const scrollToRiskCard = (cardID) => {
	const headerOffset = document.getElementById("service-analytics-header").offsetHeight;
	const cardPosition = document.getElementById(cardID).offsetTop;

	window.scrollTo({
		top: cardPosition - headerOffset,
		behavior: "smooth",
	});
};
</script>

<template>
	<div
		v-jest="'summary-card-container'"
		class="tw-w-full"
	>
		<div class="tw-flex tw-w-full tw-justify-center tw-items-center">
			<OrigamiIcon
				v-jest="'summary-card-icon'"
				:name="props.alert.icon"
				:class="props.alert.color"
				width="40"
				height="40"
			/>
			<p
				v-jest="'summary-card-text'"
				class="tw-pl-4 tw-text-xl tw-leading-tight"
			>
				<span class="tw-font-bold">{{ props.alert.numberOfSubjects }}</span> {{ props.alert.title }}
			</p>
			<OrigamiIconButton
				v-jest="'summary-card-button'"
				class="tw-ml-3"
				:data-cy="`jump-to-${alert.title}-section-button`"
				:icon-name="'logout'"
				@click.native="scrollToRiskCard(props.alert.cardID)"
			/>
		</div>
	</div>
</template>
