<template>
	<div>
		<nav
			:aria-label="pageName"
			class="tw-flex tw-items-end tw-justify-start tw-w-full tw-mb-6 tw-border-b-4 tw-border-origami-grey-100"
		>
			<RouterLink
				v-for="tab in tabs"
				:key="tab.id"
				v-data-cy="`tab-${tab.name}`"
				class="tw-text-base tw-cursor-pointer tw-py-3 tw-px-5"
				:class="{ 'tw-text-origami-grey-200 hover:tw-text-origami-grey-200': $route.path !== tab.route }"
				:active-class="'tw-border-b-4 tw--mb-1 tw-text-origami-blue-300 hover:tw-text-origami-blue-300 tw-font-semibold'"
				:to="tab.route"
				:aria-label="`Navigate to ${tab.name}'s tab in the ${pageName}`"
				custom
			>
				{{ tab.name }} {{ tab.numberGetter ? `(${$store.getters[tab.numberGetter]})` : "" }}
			</RouterLink>
		</nav>
		<router-view />
	</div>
</template>
<script>
import { checkIsObjectKeysPresentAndRightType } from "@/utilities/propValidationHelpers.js";

const requiredKeys = [
	{ property: "id", type: ["number"] },
	{ property: "name", type: ["string"] },
	{ property: "isSelected", type: ["boolean"] },
	{ property: "route", type: ["string"] },
];

export default {
	props: {
		tabs: {
			type: Array,
			required: true,
			validator: (tabs) => tabs.every((tab) => checkIsObjectKeysPresentAndRightType(tab, requiredKeys)),
		},
		pageName: {
			type: String,
			required: true,
		},
	},
};
</script>
