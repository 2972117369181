var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.emoji in _vm.$options.components
    ? _c(_vm.emoji, {
        tag: "component",
        staticStyle: { height: "1em", width: "1em" },
      })
    : _c("span", [_vm._v("\n\t" + _vm._s(_vm.emoji) + "\n")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }