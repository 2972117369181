<template>
	<BreaksConfirmation>
		<template #header>
			<h3 v-jest="'scheduled-break-error-header'">
				{{ header }}
			</h3>
		</template>
		<template #bodyIcon>
			<OrigamiIcon
				width="45"
				height="45"
				name="close-full"
				class="tw-text-origami-red-300 tw-my-3"
			/>
		</template>
		<template #bodyText>
			<p class="tw-text-left">
				You cannot schedule a break within 30 minutes of:
			</p>
			<ul class="tw-list-disc tw-text-center tw-inline-block tw-ml-8 tw-my-3">
				<li
					v-jest="'shift-start-message'"
					class="tw-list-outside tw-text-left"
				>
					Shift start
				</li>
				<li
					v-jest="'shift-end-message'"
					class="tw-list-outside tw-text-left"
				>
					Shift end
				</li>
				<li
					v-jest="'shift-transition-message'"
					class="tw-list-outside tw-text-left"
				>
					Transition into different shift
				</li>
				<li
					v-jest="'break-start-message'"
					class="tw-list-outside tw-text-left"
				>
					Break start
				</li>
			</ul>
			<p class="tw-text-center">
				Please try for another time
			</p>
		</template>
		<template #buttons>
			<OrigamiButton
				v-jest="'break-error-try-again-button'"
				variant="primary"
				class="tw-w-full tw-justify-center"
				@click.native="$emit('break-error-try-again')"
			>
				Ok, got it!
			</OrigamiButton>
		</template>
	</BreaksConfirmation>
</template>

<script>
import { OrigamiButton, OrigamiIcon } from "@origami/vue2";

import BreaksConfirmation from "../templates/BreaksConfirmation.vue";

export default {
	components: {
		BreaksConfirmation,
		OrigamiIcon,
		OrigamiButton,
	},
	props: {
		header: {
			type: String,
			required: true,
		},
	},
};
</script>
