var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ServiceAbsencesTableAbstract", {
    attrs: {
      headers: _vm.PlatformManager.ServiceAbsencesTable.tableHeaders,
      items: _vm.tutorList,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ item }) {
          return [
            _c(
              "td",
              {
                staticClass: "tw-w-32",
                class: { "tw-bg-origami-red-100": _vm.hasMIA(item) },
              },
              [_vm._v("\n\t\t\t" + _vm._s(item.user_id) + "\n\t\t")]
            ),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass: "tw-w-32",
                class: { "tw-bg-origami-red-100": _vm.hasMIA(item) },
              },
              [
                _vm._v(
                  "\n\t\t\t" + _vm._s(_vm.getTutorName(item.user_id)) + "\n\t\t"
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass: "tw-w-32",
                class: { "tw-bg-origami-red-100": _vm.hasMIA(item) },
              },
              [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.formatDate(item.shift_segment.start_time)) +
                    "\n\t\t"
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass: "tw-w-32",
                class: { "tw-bg-origami-red-100": _vm.hasMIA(item) },
              },
              [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.formatTime(item.shift_segment.start_time)) +
                    "\n\t\t"
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass: "tw-w-32",
                class: { "tw-bg-origami-red-100": _vm.hasMIA(item) },
              },
              [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.formatTime(item.shift_segment.end_time)) +
                    "\n\t\t"
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass: "tw-w-32",
                class: { "tw-bg-origami-red-100": _vm.hasMIA(item) },
              },
              [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.formatTime(item.start_time)) +
                    "\n\t\t"
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass: "tw-w-32",
                class: { "tw-bg-origami-red-100": _vm.hasMIA(item) },
              },
              [
                _vm._v(
                  "\n\t\t\t" + _vm._s(_vm.formatTime(item.end_time)) + "\n\t\t"
                ),
              ]
            ),
            _vm._v(" "),
            item.absence !== null
              ? _c(
                  "td",
                  {
                    staticClass: "tw-w-32",
                    class: { "tw-bg-origami-red-100": _vm.hasMIA(item) },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t" +
                        _vm._s(_vm.formatTime(item.absence.start_time)) +
                        "\n\t\t"
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            item.absence !== null
              ? _c(
                  "td",
                  {
                    staticClass: "tw-w-32",
                    class: { "tw-bg-origami-red-100": _vm.hasMIA(item) },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t" +
                        _vm._s(_vm.formatTime(item.absence.end_time)) +
                        "\n\t\t"
                    ),
                  ]
                )
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }