import { classroomContentV2Transformer } from "@/modules/ActionCenter/utilities/actionCenterContentTransformer.js";

import ActionItemsAPI from "../services/ActionItems.js";
import { ActionItemTypeMapping, LONG_ESSAY_REVIEW_ID, INAPPROPRIATE_BEHAVIOUR_ID, INAPPROPRIATE_BEHAVIOUR_ESSAY_ID } from "../utilities/actionCenterHelpers.js";

export const getDefaultState = () => ({
	navbarMainTab: "Action Center",
	currentActionCenterTab: "Classroom",
	expandedCards: {
		Coverage: "",
		Classroom: "",
		EssayReview: "",
	},
	inappropriateBehaviourItems: [],
	isShowingHideDialog: false,
	isShowingFalseAlarmDialog: false,
	isShowingOnlyOwnedActionItems: false,
	isShowingResolveDialog: false,
	longEssayReviewItems: [],
	inappropriateBehaviourEssayItems: [],
	selectedActionItem: null,
});

export const state = getDefaultState();

export const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_CURRENT_TAB(state, tab) {
		state.currentActionCenterTab = tab;
	},
	SET_CARD_AS_EXPANDED(state, cardTitle) {
		state.expandedCards[state.currentActionCenterTab] = cardTitle;
	},
	SET_INAPPROPRIATE_BEHAVIOUR_ITEMS(state, items) {
		state.inappropriateBehaviourItems = items;
	},
	ADD_ITEM_TO_INAPPROPRIATE_BEHAVIOUR(state, item) {
		state.inappropriateBehaviourItems = [
			...state.inappropriateBehaviourItems,
			item,
		];
	},
	SET_LONG_ESSAY_REVIEW_ITEMS(state, items) {
		state.longEssayReviewItems = items;
	},
	ADD_ITEM_TO_LONG_ESSAY_REVIEWS(state, item) {
		state.longEssayReviewItems = [
			...state.longEssayReviewItems,
			item,
		];
	},
	SET_INAPPROPRIATE_BEHAVIOUR_ESSAY_ITEMS(state, items) {
		state.inappropriateBehaviourEssayItems = items;
	},
	ADD_ITEM_TO_INAPPROPRIATE_ESSAY_BEHAVIOUR(state, item) {
		state.inappropriateBehaviourEssayItems = [
			...state.inappropriateBehaviourEssayItems,
			item,
		];
	},
	SELECT_ACTION_ITEM(state, item) {
		state.selectedActionItem = item;
	},
	UPDATE_ACTION_ITEM(state, { actionItem, typeArray, index }) {
		if (index !== -1) {
			state[typeArray].splice(index, 1, actionItem);
		}
	},
	RESOLVE_ACTION_ITEM(state, { typeArray, index }) {
		if (index !== -1) {
			state[typeArray].splice(index, 1);
		}
	},
	OPEN_FALSE_ALARM_DIALOG(state) {
		state.isShowingFalseAlarmDialog = true;
	},
	CLOSE_FALSE_ALARM_DIALOG(state) {
		state.isShowingFalseAlarmDialog = false;
	},
	OPEN_RESOLVE_DIALOG(state) {
		state.isShowingResolveDialog = true;
	},
	CLOSE_RESOLVE_DIALOG(state) {
		state.isShowingResolveDialog = false;
	},
	OPEN_HIDE_DIALOG(state) {
		state.isShowingHideDialog = true;
	},
	CLOSE_HIDE_DIALOG(state) {
		state.isShowingHideDialog = false;
	},
	SET_SHOW_ONLY_OWNED_ACTION_ITEMS(state, val) {
		state.isShowingOnlyOwnedActionItems = val;
	},
};

export const actions = {
	collapseCardInCurrentTab: ({ commit, dispatch }) => {
		commit("SET_CARD_AS_EXPANDED", "");
		dispatch("showOnlyOwnedActionItems", false);
	},
	expandCard: ({ commit }, title) => {
		commit("SET_CARD_AS_EXPANDED", title);
	},
	getInappropriateClassroomActionItems: async({ commit }, filters) => {
		const requestFilters = {
			...filters,
			type_id: INAPPROPRIATE_BEHAVIOUR_ID,
		};
		try {
			const response = await ActionItemsAPI.list(requestFilters);
			const { data } = response.data;
			const items = data.map((item) => ({
				...item,
				content: classroomContentV2Transformer(item.content),
			}));
			commit("SET_INAPPROPRIATE_BEHAVIOUR_ITEMS", items);
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject();
		}
	},
	getLongEssayReviewActionItems: async({ commit }, filters) => {
		const requestFilters = {
			...filters,
			type_id: LONG_ESSAY_REVIEW_ID,
		};
		try {
			const response = await ActionItemsAPI.list(requestFilters);
			const items = [...response.data.data];
			commit("SET_LONG_ESSAY_REVIEW_ITEMS", items);
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject();
		}
	},
	getInappropriateEssayActionItems: async({ commit }, filters) => {
		const requestFilters = {
			...filters,
			type_id: INAPPROPRIATE_BEHAVIOUR_ESSAY_ID,
		};
		try {
			const response = await ActionItemsAPI.list(requestFilters);
			const items = [...response.data.data];
			commit("SET_INAPPROPRIATE_BEHAVIOUR_ESSAY_ITEMS", items);
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject();
		}
	},
	showOnlyOwnedActionItems: ({ commit }, val) => {
		commit("SET_SHOW_ONLY_OWNED_ACTION_ITEMS", val);
	},
	updateActionItem: async({ commit }, { id, params }) => {
		try {
			const resolvePayload = {
				id,
				payload: {
					...params,
				},
			};
			const response = await ActionItemsAPI.update(resolvePayload);

			const { data } = response.data;
			const actionItem = data.type_id === INAPPROPRIATE_BEHAVIOUR_ID ?
				{
					...data,
					content: classroomContentV2Transformer(data.content),
				} :
				data;

			const typeArray = ActionItemTypeMapping[actionItem.type_id];
			const index = state[typeArray].findIndex(({ id }) => id === actionItem.id);
			const mutationType = response.data.data.resolved_at ? "RESOLVE_ACTION_ITEM" : "UPDATE_ACTION_ITEM";
			commit(mutationType, {
				typeArray,
				index,
				actionItem,
			});
		} catch (error) {
			Sentry.captureException(error);
			return Promise.reject();
		}
	},
};

export const getters = {
	numberOfCoverageItems() {
		return 0;
	},
	numberOfClassroomItems(state) {
		return state.inappropriateBehaviourItems.length;
	},
	numberOfEssayReviewItems(state) {
		return state.longEssayReviewItems.length + state.inappropriateBehaviourEssayItems.length;
	},
	ownedInappropriateBehaviourItems(state, getters, rootState) {
		return state.inappropriateBehaviourItems.filter((item) => item.picked_up_user_id === rootState.currentUser.id);
	},
	ownedLongEssayReviewItems(state, getters, rootState) {
		return state.longEssayReviewItems.filter((item) => item.picked_up_user_id === rootState.currentUser.id);
	},
	ownedInappropriateBehaviourEssayItems(state, getters, rootState) {
		return state.inappropriateBehaviourEssayItems.filter((item) =>
			item.picked_up_user_id === rootState.currentUser.id);
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
