<template>
	<ScheduledBreakError
		v-jest="'rest-break-error'"
		header="Schedule rest break"
		@break-error-try-again="confirmRestBreakReschedule"
	/>
</template>

<script>
import ScheduledBreakError from "../elements/ScheduledBreakError.vue";

export default {
	components: {
		ScheduledBreakError,
	},
	methods: {
		confirmRestBreakReschedule() {
			this.$store.dispatch("BreaksManagementWidget/confirmRestBreakReschedule");
		},
	},
};
</script>
