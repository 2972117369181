var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("OrigamiIconSprite"),
      _vm._v(" "),
      _c(
        "NavigationWrapper",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "navigation-wrapper",
              expression: "'navigation-wrapper'",
            },
          ],
          attrs: {
            "view-as-links": _vm.getViewAsLinks,
            "normal-links": _vm.getNormalLinks,
            "user-links": _vm.getUserLinks,
            languages: _vm.Lang.languages,
          },
        },
        [_c("router-view")],
        1
      ),
      _vm._v(" "),
      _c("BannersContainer"),
      _vm._v(" "),
      _vm.featureFlagReferralsIsEnabled
        ? _c("BaseToaster", {
            directives: [
              {
                name: "data-cy",
                rawName: "v-data-cy",
                value: "referral-toast",
                expression: "'referral-toast'",
              },
            ],
            staticClass: "tw-z-[1100]",
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }