<template>
	<div
		v-data-cy="'section-rest-break-request'"
		class="tw-max-w-screen-lg tw-mx-auto tw-pb-24"
	>
		<section
			v-data-cy="'upcoming-rest-break'"
			aria-label="Upcoming rest Breaks"
		>
			<h2 class="tw-text-3xl tw-font-extrabold tw-mb-5">
				Upcoming Rest Breaks ({{ upcomingBreaks.length }})
			</h2>
			<div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-mb-12 tw-gap-x-4">
				<TutorBreakCardUpcomingBreak
					v-for="upcomingBreak in upcomingBreaks"
					:key="upcomingBreak.id"
					v-jest="'upcoming-break'"
					:upcoming-break="upcomingBreak"
				/>
			</div>
		</section>
		<section
			v-data-cy="'active-rest-break'"
			aria-label="Active Rest Breaks"
		>
			<h2 class="tw-text-3xl tw-font-extrabold tw-mb-5">
				Active Rest Breaks ({{ currentBreaks.length }})
			</h2>
			<div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-mb-12 tw-gap-x-4">
				<TutorBreakCardActiveBreak
					v-for="currentBreak in currentBreaks"
					:key="currentBreak.id"
					v-jest="'current-break'"
					:current-break="currentBreak"
				/>
			</div>
		</section>
	</div>
</template>

<script>
import TutorBreakCardUpcomingBreak from "./TutorBreakCardUpcomingBreak.vue";
import TutorBreakCardActiveBreak from "./TutorBreakCardActiveBreak.vue";

export default {
	components: {
		TutorBreakCardUpcomingBreak,
		TutorBreakCardActiveBreak,
	},
	data() {
		return {
			refreshInterval: null,
		};
	},
	computed: {
		currentBreaks() {
			return this.$store.getters["BreaksCenter/currentRestBreaks"];
		},
		upcomingBreaks() {
			return this.$store.getters["BreaksCenter/upcomingRestBreaks"];
		},
	},
	created() {
		const ONE_SECOND_IN_MS = 1000;
		this.refreshInterval = setInterval(() => {
			this.$store.dispatch("BreaksCenter/setCurrentTime");
		}, ONE_SECOND_IN_MS);
	},
	beforeDestroy() {
		window.clearInterval(this.refreshInterval);
	},
};
</script>
