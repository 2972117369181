<template>
	<span
		v-jest="'total-hours'"
		class="hours__text tw-text-origami-blue-300 tw-font-bold tw-text-left"
	>
		{{ totalHours }}
	</span>
</template>

<script>
import { mapState } from "vuex";

import { formatTotalMinutesToHoursMins } from "../utilities/TutorScheduledHoursHelpers.js";

export default {
	computed: {
		...mapState(["TutorScheduledHours"]),
		totalHours() {
			const totalMinutes = this.TutorScheduledHours.overallTotal;
			return formatTotalMinutesToHoursMins(totalMinutes);
		},
	},
};
</script>

<style>
.hours__text {
	font-size: 4rem;
}
</style>
