import SimpleMessage from "../classes/SimpleMessage.js";
import MediaMessage from "../classes/MediaMessage.js";
import SystemMessage from "../classes/SystemMessage.js";

const privateMethods = {
	buildUserName(messageData) {
		return {
			firstName: messageData.user.data.first_name,
			lastName: messageData.user.data.last_name,
		};
	},
};

export default {
	/**
	 * Creates a message object
	 * @param {Object} messageData
	 */
	create(messageData) {
		let userName = {};
		if (messageData.message_type === "system") {
			return new SystemMessage(
				"system",
				messageData.message,
				messageData.reason,
				messageData.created_at,
			);
		} else if (messageData.is_file) {
			userName = privateMethods.buildUserName(messageData);
			return new MediaMessage(
				messageData.id,
				messageData.user_id,
				userName,
				messageData.created_at,
				messageData.sequencePosition,
				messageData.isLastMessage,
				messageData.is_question,
				messageData.is_deleted,
				messageData.is_quarantined,
				messageData.is_file,
				messageData.isTutor,
				messageData.fileData?.file_path,
				messageData.fileData?.thumbnail_file_path,
				messageData.fileData?.file_type,
				messageData.voiceNoteMetadata?.data,
			);
		} else {
			userName = privateMethods.buildUserName(messageData);
			return new SimpleMessage(
				messageData.id,
				messageData.user_id,
				userName,
				messageData.created_at,
				messageData.sequencePosition,
				messageData.isLastMessage,
				messageData.is_question,
				messageData.is_deleted,
				messageData.is_file,
				messageData.isTutor,
				messageData.message,
			);
		}
	},
};
