<template>
	<TutorBioBreakCard
		v-jest="'break-request-card'"
		:break="bioBreak"
		:include-shift-status="true"
		:is-loading-state="isLoadingState"
	>
		<template #metaDataIcon>
			<component
				:is="getOriginalScheduleType()"
				icon-color="currentColor"
				class="tw-mr-2 tw-align-middle tw-text-black-high"
			/>
		</template>
		<template #metadata>
			{{ getTimeRange(
				bioBreak.start_time, bioBreak.end_time,
			) }}
			<span class="tw-mx-1">|</span>
			{{ getTimeElapsed() }} elapsed
		</template>
		<template #breakDetailsIcon>
			<OrigamiIcon
				name="bio-break"
				class="tw-block tw-align-top tw-mr-1 tw-mt-1"
			/>
		</template>
		<template #breakDetails>
			{{ `Ended at ${getBioBreakEndedTime}` }}

			<div class="tw-pt-5 tw-text-origami-grey-200 tw-text-xs">
				{{ remainingTimeInMinutes }}
			</div>
		</template>
	</TutorBioBreakCard>
</template>

<script>
import { OrigamiIcon } from "@origami/vue2";
import { differenceInMinutes } from "date-fns";

import IconSession from "@/components/icons/IconSession.vue";
import IconEssay from "@/components/icons/IconEssay.vue";
import BaseButton from "@/components/elements/BaseButton.vue";
import { getTimeElapsedForShiftString } from "@/utilities/ScheduleHelpers.js";
import { formatPlurality } from "@/utilities/strings.js";
import { formatIsoDateToHM, formatDateToTimestamp } from "@/utilities/dateHelpers.js";

import { SHIFT_TYPE_ID } from "../utilities/ShiftTypeIDs.js";

import TutorBioBreakCard from "./TutorBioBreakCard.vue";

export default {
	components: {
		TutorBioBreakCard,
		OrigamiIcon,
		IconSession,
		IconEssay,
		BaseButton,
	},
	props: {
		bioBreak: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			isLoadingState: false,
		};
	},
	computed: {
		getBioBreakEndedTime() {
			return formatIsoDateToHM(this.bioBreak.state.current_modifier.start_time);
		},
		remainingTimeInMinutes() {
			const timeLeftInBioBreak = differenceInMinutes(
				new Date(this.bioBreak.state.current_modifier.start_time),
				new Date(),
			);
			const remainingTimeString = formatPlurality(Math.abs(timeLeftInBioBreak), "min");
			return `${remainingTimeString} late`;
		},
	},
	methods: {
		getTimeRange(start_time, end_time) {
			return `${formatIsoDateToHM(start_time)} - ${formatIsoDateToHM(end_time)}`;
		},
		getOriginalScheduleType() {
			return this.bioBreak.state.current_segment.type.id === SHIFT_TYPE_ID.CLASSROOM ? "IconSession" : "IconEssay";
		},
		getTimeElapsed() {
			return getTimeElapsedForShiftString(formatDateToTimestamp(new Date(this.bioBreak.start_time)));
		},
	},
};
</script>
