<template>
	<main id="main-content">
		<GenericSettings v-jest="'generic-settings'" />
		<FooterPrivacyPolicy />
		<NotificationSnackbar
			:textcontent="snackbarText"
			:notificationtype="snackbarType"
			@snackbar-close="$store.dispatch('Snackbar/resetSnackbar')"
		>
			<template slot="icon">
				{{ snackbarIcon }}
			</template>
			<template slot="prompt">
				&times;
			</template>
		</NotificationSnackbar>
	</main>
</template>

<script>
import { mapState } from "vuex";

import tutorBreaksListener from "@/utilities/listeners/tutorBreaksListener.js";
import FooterPrivacyPolicy from "@/components/FooterPrivacyPolicy.vue";
import NotificationSnackbar from "@/modules/Notifications/components/NotificationSnackbar.vue";

import GenericSettings from "./GenericSettings.vue";

export default {
	components: {
		GenericSettings,
		NotificationSnackbar,
		FooterPrivacyPolicy,
	},
	computed: {
		...mapState("Snackbar", ["snackbarText", "snackbarType"]),
		snackbarIcon() {
			return {
				success: "\u2713",
				error: "\u2717",
				warning: "\u0021",
			}[this.snackbarType];
		},
	},
	sockets: {
		...tutorBreaksListener,
	},
};
</script>
