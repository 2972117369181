<template>
	<OrigamiButton
		v-if="canCancelBreak"
		v-jest="'meal-break-cancel-button'"
		v-data-cy="'meal-break-cancel-button'"
		variant="destructive"
		class="tw-w-full tw-justify-center"
		@click.native="cancelBreak"
	>
		Cancel break
	</OrigamiButton>
</template>

<script>
import { OrigamiButton } from "@origami/vue2";

export default {
	components: {
		OrigamiButton,
	},
	computed: {
		canCancelBreak() {
			return this.$store.getters["BreaksManagementWidget/canCancelMealBreak"];
		},
	},
	methods: {
		cancelBreak() {
			this.$store.dispatch("BreaksManagementWidget/confirmCancelMealBreak");
		},
	},
};
</script>
