var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("OrigamiPageHeader", {
        attrs: {
          title: "Review Center",
          "back-button-aria-label": "Navigate back to previous page",
          "is-showing-back-button": "",
        },
        on: { "back-action": _vm.goBack },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "origami-container tw-py-8" }, [
        _vm.isLoading
          ? _c(
              "div",
              [
                _c("BaseLoadingState", {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "loading-state",
                      expression: "'loading-state'",
                    },
                  ],
                }),
              ],
              1
            )
          : _vm.isEssayReviewDisabled
          ? _c("div", { staticClass: "tw-flex" }, [
              _c(
                "h4",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "essay-review-disabled",
                      expression: "'essay-review-disabled'",
                    },
                  ],
                  staticClass:
                    "tw-text-center tw-text-xl tw-max-w-xl tw-mx-auto",
                },
                [
                  _vm._v(
                    "\n\t\t\t\tYou do not have access to writing review. If you wish to discuss becoming a writing reviewer, please contact a Platform Manager!\n\t\t\t"
                  ),
                ]
              ),
            ])
          : _c("div", [
              _c(
                "div",
                {
                  staticClass:
                    "tw-flex tw-flex-col tw-gap-4 tw-justify-start md:tw-grid md:tw-grid-cols-12 tw-mb-4",
                },
                [
                  _c(
                    "div",
                    { staticClass: "tw-col-span-8" },
                    [
                      _c("TutorEssayStatistics", {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "essay-statistics",
                            expression: "'essay-statistics'",
                          },
                        ],
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tw-col-span-4" },
                    [
                      _c(
                        "OrigamiCard",
                        { staticClass: "tw-h-full", attrs: { tag: "div" } },
                        [_c("BreaksManagementWidget")],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.isPunchedIntoEssayReviewShift
                ? _c(
                    "div",
                    [
                      _vm.pickedUpEssay
                        ? _c(
                            "div",
                            [
                              _c("TutorEssayPreview", {
                                directives: [
                                  {
                                    name: "jest",
                                    rawName: "v-jest",
                                    value: "picked-up-essay-component",
                                    expression: "'picked-up-essay-component'",
                                  },
                                ],
                                attrs: { essay: _vm.pickedUpEssay },
                              }),
                              _vm._v(" "),
                              _c("TutorEssayReviewSubmission", {
                                directives: [
                                  {
                                    name: "jest",
                                    rawName: "v-jest",
                                    value: "tutor-essay-review-submission",
                                    expression:
                                      "'tutor-essay-review-submission'",
                                  },
                                ],
                                on: {
                                  "reviewed-essay": _vm.handleReviewedEssay,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.featureFlagMilestoneIsEnabled &&
                          _vm.isDisplayingFeedback
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "tw-mt-24 tw-max-w-lg tw-my-0 tw-mx-auto tw-flex tw-justify-center tw-items-center tw-w-full tw-flex-col",
                            },
                            [
                              _c("TutorEssayFlowAcknowledgement", {
                                directives: [
                                  {
                                    name: "jest",
                                    rawName: "v-jest",
                                    value: "milestone",
                                    expression: "'milestone'",
                                  },
                                ],
                                attrs: {
                                  "essay-number": _vm.currentEssayNumber,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm.isDisplayingFeedback
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "jest",
                                  rawName: "v-jest",
                                  value: "tutor-feedback-component",
                                  expression: "'tutor-feedback-component'",
                                },
                              ],
                              staticClass:
                                "tw-mt-24 tw-max-w-lg tw-my-0 tw-mx-auto tw-flex tw-justify-center tw-items-center tw-w-full tw-flex-col",
                            },
                            [
                              _c("img", {
                                staticClass: "tw-w-80 tw-h-80",
                                attrs: {
                                  "v-jest": "essay-feedback-art",
                                  src: "/images/tutor/essay-review-tutor-feedback.png",
                                },
                              }),
                              _vm._v(" "),
                              _vm._m(0),
                            ]
                          )
                        : _vm.hasReservedEssay && !_vm.isOnBioBreakOrMIA
                        ? _c("TutorEssayPreview", {
                            directives: [
                              {
                                name: "jest",
                                rawName: "v-jest",
                                value: "reserve-essay-component",
                                expression: "'reserve-essay-component'",
                              },
                            ],
                            attrs: { essay: _vm.reservedEssay },
                          })
                        : _vm.isOnBioBreakOrMIA
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "tw-max-w-lg tw-my-0 tw-mx-auto tw-flex tw-justify-center tw-items-center tw-w-full tw-flex-col",
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "jest",
                                      rawName: "v-jest",
                                      value: "bio-break-placeholder",
                                      expression: "'bio-break-placeholder'",
                                    },
                                  ],
                                  staticClass: "tw-my-5",
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/images/tutor/essay-review-not-punched-in.png",
                                      height: "452",
                                      width: "452",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "tw-max-w-lg tw-my-0 tw-mx-auto tw-flex tw-justify-center tw-items-center tw-w-full tw-flex-col",
                            },
                            [
                              _c("img", {
                                staticClass: "tw-w-56 tw-h-56",
                                attrs: {
                                  "v-jest": "empty-queue",
                                  src: "/images/tutor/essay-review-empty-queue.png",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "tw-px-12 tw-break-words tw-text-2xl tw-font-bold tw-text-center",
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\tNothing currently reserved.\n\t\t\t\t\t"
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "tw-break-words tw-text-lg tw-text-center",
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\tPlease refresh the page to reserve the next item in the queue.\n\t\t\t\t\t"
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("BaseButton", {
                                directives: [
                                  {
                                    name: "jest",
                                    rawName: "v-jest",
                                    value: "try-new-essay",
                                    expression: "'try-new-essay'",
                                  },
                                ],
                                staticClass: "tw-shadow-elevation-2 tw-mt-6",
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.tryToReserveEssay.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "text",
                                      fn: function () {
                                        return [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\tRefresh queue\n\t\t\t\t\t\t"
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2437210468
                                ),
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "illustration-container",
                          expression: "'illustration-container'",
                        },
                      ],
                      staticClass:
                        "tw-max-w-md tw-my-0 tw-mx-auto tw-flex tw-justify-center tw-items-center tw-w-full tw-flex-col",
                    },
                    [
                      _c("img", {
                        staticClass: "tw-h-80 tw-h-80",
                        attrs: {
                          "v-jest": "not-punched-art",
                          src: "/images/tutor/essay-review-not-punched-in.png",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "h3",
                        {
                          staticClass:
                            "tw-px-4 md:tw-px-12 tw-py-4 tw-break-words tw-text-lg tw-text-center",
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\tYou are currently not punched-in, so there are no submissions for you to review.\n\t\t\t\t"
                          ),
                        ]
                      ),
                    ]
                  ),
            ]),
      ]),
      _vm._v(" "),
      _c("TutorModalSkipEssayPickup", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "skip-essay-modal",
            expression: "'skip-essay-modal'",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "h2",
      {
        staticClass:
          "tw-px-20 tw-break-words tw-text-2xl tw-text-center tw-font-bold tw-flex-col",
      },
      [
        _c("span", [_vm._v("\n\t\t\t\t\t\t\tYou've done it!\n\t\t\t\t\t\t")]),
        _vm._v(" "),
        _c("span", { staticClass: "tw-whitespace-nowrap" }, [
          _vm._v("\n\t\t\t\t\t\t\tThank you for your hard work.\n\t\t\t\t\t\t"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }