
import { merge } from "lodash";

import { generalTranslations } from "../general.js";
import studentProfileTranslations from "../studentProfile.js";

const trans = {
	en: {
		full_name: "Full Name",
		email: "Email",
		password: "Password",
		phone: "Phone",
		username: "username",
		preferred_language: "Preferred Language",
		account_settings: "Account Settings",
		update_password: "Update password",
		old_password: "Old password",
		new_password: "New password",
		confirm_new_password: "Confirm new password",
		password_successfully_updated: "Your password has successfully been updated!",
		grade_level: "Grade Level",
		save_grade_level: "Save grade level",
		select_your_grade_level: "Select your grade level",
		grade_successfully_updated: "Your grade has successfully been updated!",
		add_grade_level: "Add a grade level",

		// error messages
		something_wrong_grade: "Something went wrong while saving your grade level",
		password_incorrect: "The password provided was incorrect",

		//onboarding errors?

		password_whitespace: "Password cannot contain whitespace.",
		password_too_short: "Password must be at least 6 characters long.",
		passwords_dont_match: "Passwords do not match.",
		something_wrong_password: "Something went wrong while saving your password.",

		notifications: {
			addPhone: "Add your phone number",
			app_sounds: "Application Sounds",
			app_sounds_details: "Disable/Enable all in app sounds.",
			changePhone: "Changer your phone number",
			email_settings: "Email Settings",
			note: "Note: You will continue to receive transactional e-mails related to your account",
			settings_updated: "Settings Updated",
			error: "We're sorry, there was an error processing your request.",
			verifyCode: "Verify Code",
			verifyCodeConfirmation: "Your phone number has been verified!",
			receive_browser_notifications:
				"Receive notifications from your browser when a scheduled tutor is online and when PAPER is updated",
			description: "Choose how to be notified. Select mobile and email notifications you'd like to receive.",
			notification_type_email: "Email",
			notification_type_app: "Mobile App",
			website_settings: "Website Settings",
			status_updates: "Status updates",
			receive_status_updates: "Reviewed essays, stickers, trophies, and other achievements",
			event_updates: "Upcoming events and fun stuff",
			receive_event_updates: "PaperLive events, fun contests, and prizes",
		},

		sidebar: {
			account: "Account Settings",
			features: "Features",
			my_account: "My Account",
			notifications: "Notifications",
		},
		accessibility: {
			disabled_slider_at_percentage: "Disabled {sliderName} slider at {percentage}%",
			enabled_slider_at_percentage: "Enabled {sliderName} slider at {percentage}%",
		},
	},
	fr: {
		email: "Adresse Courriel",
		username: "Nom d'utilisateur",
		password: "Mot de passe",
		preferred_language: "Langue préférée",
		account_settings: "Paramètres de compte",
		update_password: "Modifier Mot de passe",
		old_password: "Ancien mot de passe",
		new_password: "Nouveau mot de passe",
		confirm_new_password: "Confirmation du mot de passe",
		password_successfully_updated: "Votre nouveau mot de passe a été sauvegardé avec succès!",
		grade_level: "Niveau Scolaire",
		save_grade_level: "Enregistrer",
		select_your_grade_level: "Sélectionner le Niveau Scolaire",
		grade_successfully_updated: "Votre niveau scolaire a été sauvegardé avec succès!",
		add_grade_level: "Ajouter un Niveau Scolaire",

		// error messages
		something_wrong_grade: "Une erreur est survenue lors de la sauvegarde.",

		password_whitespace: "Le mot de passe ne peut pas contenir d'espaces.",
		password_too_short: "Le mot de passe doit contenir au moins 6 caractères.",
		passwords_dont_match: "Les mots de passe ne sont pas identiques.",
		something_wrong_password:
			"Une erreur s'est produite lors de l'enregistrement de votre mot de passe",

		notifications: {
			app_sounds: "Sons de l’application",
			app_sounds_details: "Désactiver/Activer tous les sons de l’application.",
			email_settings: "Paramètres courriel",
			note: "Note: Vous continuerez à recevoir des messages transactionnels reliés à votre compte",
			settings_updated: "Paramètres Sauvegardés",
			error: "Désolé, une erreur s'est produite lors du traitement de votre requête.",
			receive_browser_notifications:
				"Recevez une alerte de votre navigateur quand votre tuteur est en ligne ou quand PAPER est mise à jour.",
			description: "Choisissez comment être notifié. Sélectionnez les notifications mobiles et courriel que vous souhaitez recevoir.",
			notification_type_email: "Courriel",
			notification_type_app: "Application Mobile",
			website_settings: "Paramètres du site",
			status_updates: "Mises à jour de statut",
			receive_status_updates: "Rédactions évaluées, autocollants, trophées et autres récompenses",
			event_updates: "Prochains événements et activités amusants",
			receive_event_updates: "Événements PaperLive, concours amusants et prix",
		},

		sidebar: {
			account: "Paramètres du compte",
			features: "Features",
			my_account: "Mon Compte",
			notifications: "Alertes",
		},
	},
	es: {
		full_name: "Nombre completo",
		email: "Correo electrónico",
		password: "Contraseña",
		phone: "Teléfono",
		username: "Nombre de usuario",
		preferred_language: "Idioma de preferencia",
		account_settings: "Ajustes de la cuenta",
		update_password: "Actualiza tu contraseña",
		old_password: "Contraseña anterior",
		new_password: "Nueva contraseña",
		confirm_new_password: "Confirmación de contraseña",
		password_successfully_updated: "¡Tu contraseña ha sido actualizada!",
		grade_level: "Grado",
		save_grade_level: "Guardar grado",
		select_your_grade_level: "Selecciona tu grado",
		grade_successfully_updated: "¡Tu grado ha sizo actualizado!",
		add_grade_level: "Agrega un grado",

		// error messages
		something_wrong_grade: "Hubo un error al guardar tu grado.",
		password_incorrect: "La contraseña es incorrecta.",

		//onboarding errors?

		password_whitespace: "La contraseña no puede contener espacios.",
		password_too_short: "La contraseña debe contener al menos 6 caracteres.",
		passwords_dont_match: "Las contraseñas no son similares.",
		something_wrong_password: "Ocurrió un error al guardar tu contraseña.",

		notifications: {
			addPhone: "Agrega tu número de teléfono",
			app_sounds: "Sonidos de la aplicación",
			app_sounds_details: "Deshabilitar/Habilitar todos sonidos de la aplicación.",
			changePhone: "Actualiza tu número de teléfono",
			email_settings: "Ajustes de correo electrónico",
			note: "Nota: Continuarás recibiendo correos electrónicos transaccionales relacionados con tu cuenta",
			settings_updated: "Ajustes actualizados",
			error: "Lo sentimos, ocurrió un error al procesar tu solicitud.",
			verifyCode: "Verifica el código",
			verifyCodeConfirmation: "¡Tu número de teléfono ha sido verificado!",
			receive_browser_notifications:
				"Recibe notificaciones de tu navegador cuando un tutor esté en línea o cuando PAPER sea actualizado",
			description: "Elija cómo ser notificado. Seleccione las notificaciones móviles y de correo electrónico que te gustaría recibir.",
			notification_type_email: "Correo electrónico",
			notification_type_app: "Aplicación movil",
			website_settings: "Aplicación le réseau",
			status_updates: "Actualizaciones de estado",
			receive_status_updates: "Ensayos revisados, pegatinas, trofeos y otros logros.",
			event_updates: "Próximos eventos y cosas divertidas",
			receive_event_updates: "Eventos de PaperLive, divertidos concursos y premios",
		},

		sidebar: {
			account: "Ajustes de la cuenta",
			features: "Funcionalidades",
			my_account: "Mi Cuenta",
			notifications: "Notificaciones",
		},
	},
};

export default merge(
	generalTranslations,
	studentProfileTranslations,
	trans,
);
