import rootInstance from "@/services/root.js";

function api() {
	rootInstance.defaults.baseURL = process.env.MIX_REFERRALS_API_URL + "/api/v1";
	return rootInstance;
}

export default {
	async check() {
		return api().get("/referrals");
	},
	async unlock() {
		return api().put("/referrals");
	},
	async redeem(referralCode) {
		return api().put(`/referrals/redeem/${referralCode}`);
	},
};
