<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		viewBox="0 0 14 14"
	>
		<path
			:fill="iconColor"
			fill-rule="evenodd"
			d="M13.3 2.8h-1.4v6.3H2.8v1.4c0 .385.315.7.7.7h7.7L14 14V3.5c0-.385-.315-.7-.7-.7zM10.5 7V.7c0-.385-.315-.7-.7-.7H.7C.315 0 0 .315 0 .7v9.8l2.8-2.8h7c.385 0 .7-.315.7-.7z"
		/>
	</svg>
</template>

<script>
import colors from "../../utilities/Colors.js";
export default {
	props: {
		width: {
			type: [Number, String],
			default: 14,
		},
		height: {
			type: [Number, String],
			default: 14,
		},
		iconColor: {
			type: String,
			default: colors.C_BLUE,
		},
	},
};
</script>
