import { isBefore, isAfter, isEqual } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { store as Profile } from "@/modules/StudentSettings/index.js";
import Dashboard from "@/modules/StudentDashboardLayout/store/index.js";
import { store as Essays } from "@/modules/StudentReviewCenter/index.js";
import Challenges from "@/modules/Challenges/store/index.js";
import { UsersAPI } from "@/modules/Customers/index.js";
import { DAY_CODES } from "@/utilities/dateHelpers.js";

import LibraryProfileCompletion from "./LibraryProfileCompletion.js";
import Session from "./Session.js";

export const modules = {
	Profile,
	Dashboard,
	LibraryProfileCompletion,
	Essays,
	Session,
	Challenges,
};

const getDefaultState = () => ({
	student: {},
});
export const state = getDefaultState();

export const getters = {
	getSchoolOperatingHours(state, getters, rootState) {
		const school = rootState.currentUser?.school;
		const user = rootState.currentUser;
		let operatingHoursStart = null;
		let operatingHoursEnd = null;

		if (school.operating_hours_start || school.operating_hours_end) {
			const timezone = school.timezone ?? user.timezone;
			const date = new Date();
			const now = utcToZonedTime(date, timezone);

			const year = now.getUTCFullYear();
			const month = now.getUTCMonth();
			const day = now.getUTCDate();

			const operatingStart = school.operating_hours_start.split(":");

			const hourOperatingStart = operatingStart[0];
			const minuteOperatingStart = operatingStart[1];

			const operatingEnd = school.operating_hours_end.split(":");

			const hourOperatingEnd = operatingEnd[0];
			const minuteOperatingEnd = operatingEnd[1];

			operatingHoursStart = new Date(year, month, day, hourOperatingStart, minuteOperatingStart, 0);

			operatingHoursEnd = new Date(year, month, day, hourOperatingEnd, minuteOperatingEnd, 0);
		}
		return {
			operatingHoursStart,
			operatingHoursEnd,
		};
	},
	isUserInOperatingHours(state, getters, rootState) {
		const { operatingHoursStart, operatingHoursEnd } = getters.getSchoolOperatingHours;
		if (!operatingHoursStart || !operatingHoursEnd) {
			return true;
		}

		const school = rootState.currentUser?.school;
		const district = rootState.currentUser?.district_id;
		const user = rootState.currentUser;
		const timezone = school.timezone ?? user.timezone;
		const date = new Date();
		const now = utcToZonedTime(date, timezone);
		const day = date.getDay();
		const isWeekend = day === DAY_CODES.SUNDAY || day === DAY_CODES.SATURDAY;
		const isWeekendDisabled = district === 991;

		const isInSameDayOperatingHours = operatingHoursEnd > operatingHoursStart &&
			(isAfter(now, operatingHoursStart) || isEqual(now, operatingHoursStart)) &&
			(isBefore(now, operatingHoursEnd) || isEqual(now, operatingHoursEnd));
		const isInOverlapOperatingHours = operatingHoursEnd < operatingHoursStart &&
			(
				(isAfter(now, operatingHoursStart) || isEqual(now, operatingHoursStart)) ||
				(isBefore(now, operatingHoursEnd) || isEqual(now, operatingHoursEnd))
			);

		return isInSameDayOperatingHours ||
			isInOverlapOperatingHours ||
			(isWeekend && !isWeekendDisabled);
	},
};

export const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	GET_STUDENT(state, payload) {
		state.student = payload.student;
	},
};

export const actions = {
	/**
	 *
	 * @param {Object} context
	 * @param {Function} context.commit
	 * @param {Object} payload
	 * @param {Number} payload.studentId
	 * @param {Object} payload.params
	 * @returns {Promise}
	 */
	async getStudent({ commit }, payload) {
		try {
			const response = await UsersAPI.show(payload.studentId, payload.params);
			commit("GET_STUDENT", {
				student: response.data.data,
			});
			return Promise.resolve(response);
		} catch (e) {
			return Promise.reject(e);
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
	modules,
};
