<template>
	<div>
		<div class="tw-flex tw-justify-between tw-items-center tw-mt-5">
			<span class="availabilitiesFooter__total tw-flex-1">
				Total Availability Selected: <b>{{ totalHoursForWeek }} Hours</b>
			</span>
			<div
				v-if="isCurrentWeekOpen"
				class="tw-flex tw-gap-x-20 tw-justify-center tw-content-center tw-items-center tw-text-center"
			>
				<div
					v-jest="'max-hours-container'"
				>
					<label
						for="input"
						class="availabilitiesFooter__maxHours tw-mb-0"
					>
						Max Hours:
					</label>
					<input
						id="input"
						v-model.number="maxHours"
						v-jest="'max-hours'"
						:disabled="canEdit"
						type="number"
						step="1"
						class="availabilitiesFooter__input"
					>
					<InfoToolTip
						v-if="featureFlagPreferredHoursFieldEnabled"
						v-jest="'availability-max-hours-tooltip'"
						v-data-cy="'availability-max-hours-tooltip'"
						:options="maxHoursToolTipOptions"
						aria-describedby="Max Hours"
					/>
				</div>
				<div
					v-if="featureFlagPreferredHoursFieldEnabled"
					v-jest="'preferred-hours-container'"
				>
					<label
						for="input"
						class="availabilitiesFooter__maxHours tw-mb-0"
					>
						Preferred Hours:
					</label>
					<input
						id="input"
						v-model.number="preferredHours"
						v-jest="'preferred-hours'"
						:disabled="canEdit"
						type="number"
						step="1"
						class="availabilitiesFooter__input"
					>
					<InfoToolTip
						v-jest="'availability-preferred-hours-tooltip'"
						v-data-cy="'availability-preferred-hours-tooltip'"
						:options="prefHoursToolTipOptions"
						aria-describedby="Preferred Hours"
					/>
				</div>
			</div>
			<div class="tw-flex-1 tw-flex tw-justify-end">
				<OrigamiButton
					v-if="canSubmit"
					key="cancel-editing"
					v-jest="'cancel-editing'"
					variant="secondary"
					class="tw-mr-4"
					@click.native="cancelEdit"
				>
					Cancel
				</OrigamiButton>
				<OrigamiButton
					v-if="canSubmit"
					key="submit-availabilities"
					v-jest="'submit-availabilities'"
					type="submit"
					variant="primary"
					:is-disabled="!isSubmitButtonEnabled"
					@click.native="showSubmitAvailabilitiesModal"
				>
					Submit
				</OrigamiButton>
				<OrigamiButton
					v-if="canEdit"
					key="edit-availabilities"
					v-jest="'edit-availabilities'"
					variant="primary"
					@click.native="editAvailabilities"
				>
					Edit
				</OrigamiButton>
			</div>
		</div>
		<div
			v-if="!isCurrentWeekOpen && !isCurrentWeekAfterOpenWeek"
			v-jest="'week-closed-message'"
			class="availabilitiesFooter__closed"
		>
			<IconInfo
				class="availabilitiesFooter__icon"
				:width="18"
				:height="18"
			/>
			<span class="availabilitiesFooter__closed--padding">
				This week is currently closed. The schedule is released by 11:59 p.m. ET each Friday for the week beginning 10 days later.
			</span>
		</div>
		<div
			v-if="isCurrentWeekOpen && maxHoursPerWeek !== null"
			v-jest="'already-submitted-message'"
			class="availabilitiesFooter__closed"
		>
			<IconInfo
				class="availabilitiesFooter__icon"
				:width="18"
				:height="18"
			/>
			<span class="availabilitiesFooter__closed--padding">
				You already submitted availability this week, but you can edit until the submission deadline.
			</span>
		</div>
		<div
			v-if="isCurrentWeekAfterOpenWeek"
			v-jest="'week-not-open-message'"
			class="availabilitiesFooter__closed"
		>
			<IconInfo
				class="availabilitiesFooter__icon"
				:width="18"
				:height="18"
			/>
			<span class="availabilitiesFooter__closed--padding">
				This week is not currently open for availability.
			</span>
		</div>
		<TutorDashboardSubmitAvailabilitiesModal
			v-if="featureFlagPreferredHoursFieldEnabled"
			:max-hours="maxHours"
			:preferred-hours="preferredHours"
		/>
		<TutorDashboardSubmitAvailabilitiesModal
			v-else
			:max-hours="maxHours"
		/>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { OrigamiButton } from "@origami/vue2";
import { isAfter } from "date-fns";

import IconInfo from "@/components/icons/IconInfo.vue";
import InfoToolTip from "@/components/patterns/InfoToolTip.vue";

import TutorDashboardSubmitAvailabilitiesModal from "./TutorDashboardSubmitAvailabilitiesModal.vue";

export default {
	components: {
		OrigamiButton,
		IconInfo,
		InfoToolTip,
		TutorDashboardSubmitAvailabilitiesModal,
	},
	data() {
		return {
			maxHours: null,
			preferredHours: null,
			featureFlagPreferredHoursFieldEnabled: null,
		};
	},
	computed: {
		...mapState(["Availabilities", "currentUser"]),
		totalHoursForWeek() {
			return this.$store.getters["Availabilities/totalHoursToSubmitForWeek"];
		},
		maxHoursPerWeek() {
			return this.$store.state.Availabilities.max_weekly_hours;
		},
		preferredHoursPerWeek() {
			return this.$store.state.Availabilities.preferred_weekly_hours;
		},
		/**
		 * Check if the current visible week is open for submission.
		 * @returns {Boolean}
		 */
		isCurrentWeekOpen() {
			return this.$store.getters["Availabilities/isCurrentWeekOpen"];
		},
		/**
		 * Checks if the visible week is greater than the open week.
		 * @returns {Boolean}
		 */
		isCurrentWeekAfterOpenWeek() {
			return isAfter(this.Availabilities.currentWeek, this.Availabilities.openWeek.endDate);
		},
		/**
		 * @returns {Boolean}
		 */
		isSubmitButtonEnabled() {
			return (this.$store.getters["Availabilities/availabilitiesDifferences"].length > 0 && this.maxHours > 0) ||
				Number(this.maxHours) !== Number(this.maxHoursPerWeek) ||
				Number(this.preferredHours) !== Number(this.preferredHoursPerWeek);
		},
		canEdit() {
			return this.isCurrentWeekOpen && !this.Availabilities.canEditAvailabilities;
		},
		canSubmit() {
			return this.Availabilities.canEditAvailabilities && this.isCurrentWeekOpen;
		},
		maxHoursToolTipOptions() {
			const tooltipText = "The maximum number of hours you would be willing to work; your assigned schedule will not exceed the number inputted";
			return {
				allowHTML: true,
				arrow: true,
				content: `<span id="preferred-hours" class="tw-text-sm tw-w-96">${tooltipText}</span>`,
				hideOnClick: false,
				maxWidth: 305,
				placement: "top",
				popperOptions: { strategy: "fixed" },
				trigger: "mouseenter focus",
			};
		},
		prefHoursToolTipOptions() {
			const tooltipText = "The number of hours you would prefer to work; this is not a guarantee, but we will try our best to accomodate. Preferred hours must be lower than Maximum hours inputted";
			return {
				allowHTML: true,
				arrow: true,
				content: `<span id="preferred-hours" class="tw-text-sm tw-w-96">${tooltipText}</span>`,
				hideOnClick: false,
				maxWidth: 305,
				placement: "top",
				popperOptions: { strategy: "fixed" },
				trigger: "mouseenter focus",
			};
		},
	},
	watch: {
		maxHoursPerWeek(newVal) {
			this.maxHours = newVal;
		},
	},
	async created() {
		this.featureFlagPreferredHoursFieldEnabled = await this.$getFlag("SER-550-preferred-hours-field");
		this.featureFlagPreferredHoursFieldEnabled
			? await this.$store.dispatch("Availabilities/getUserMaxAndPreferredHours", this.currentUser.id)
			: await this.$store.dispatch("Availabilities/getUserMaxHours", this.currentUser.id);
		this.maxHours = this.$store.state.Availabilities.max_weekly_hours;
		this.preferredHours = this.$store.state.Availabilities.preferred_weekly_hours;
	},
	methods: {
		showSubmitAvailabilitiesModal() {
			this.$bvModal.show("tutorDashboardSubmitAvailabilitiesModal");
		},
		editAvailabilities() {
			this.$store.dispatch("Availabilities/setCanEditAvailabilities", true);
		},
		cancelEdit() {
			this.$store.dispatch("Availabilities/cancelAvailabilitiesEdition", {
				callback: async() => {
					this.featureFlagPreferredHoursFieldEnabled
						? await this.$store.dispatch("Availabilities/getUserMaxAndPreferredHours", this.currentUser.id)
						: await this.$store.dispatch("Availabilities/getUserMaxHours", this.currentUser.id);
					this.maxHours = this.$store.state.Availabilities.max_weekly_hours;
					this.$store.dispatch("Availabilities/resetAvailabilitiesToSubmit");
					this.$store.dispatch("Availabilities/closeCancelAvailabilitiesEdition");
					this.$store.dispatch("Availabilities/setCanEditAvailabilities", false);
				},
			});
		},
	},
};
</script>

<style scoped>
.availabilitiesFooter__maxHours {
	font-weight: 600;
}
.availabilitiesFooter__closed--padding {
	padding-left: 12px;
}
.availabilitiesFooter__closed {
	padding: 16px 20px;
	margin-top: 20px;
	background-color: rgba(132, 182, 226, 0.2);
	border-radius: 5px;
	border: solid 2px var(--c-blue);
	font-size: 14px;
	letter-spacing: -0.05px;
	color: var(--c-black);
	display: flex;
	align-items: center;
}

.availabilitiesFooter__input {
	height: 40px;
	margin-left: 10px;
	border: 2px solid var(--c-light-gray);
	width: 5rem;
	border-radius: 0.3125rem;
	padding: 0.5rem 1rem;
}

.availabilitiesFooter__question {
	width: 220px;
	display: inline-block;
}

.availabilityFooter__exclamation {
	position: relative;
}
</style>
