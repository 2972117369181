var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "data-cy",
          rawName: "v-data-cy",
          value: "section-meal-break-request",
          expression: "'section-meal-break-request'",
        },
      ],
      staticClass: "tw-max-w-screen-lg tw-mx-auto tw-pb-24",
    },
    [
      _c(
        "section",
        { attrs: { "aria-label": "Meal Break Requests" } },
        [
          _c(
            "h2",
            {
              directives: [
                {
                  name: "data-cy",
                  rawName: "v-data-cy",
                  value: "meal-break-request",
                  expression: "'meal-break-request'",
                },
              ],
              staticClass: "tw-text-2xl tw-font-extrabold tw-mb-5",
            },
            [
              _vm._v(
                "\n\t\t\tMeal Break Requests (" +
                  _vm._s(_vm.filteredBreakRequests.length) +
                  ")\n\t\t"
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "BaseCheckbox",
            {
              directives: [
                {
                  name: "data-cy",
                  rawName: "v-data-cy",
                  value: "essay-break-request",
                  expression: "'essay-break-request'",
                },
              ],
              attrs: {
                "checkbox-value": "essayBreakRequest",
                "checkbox-id": "essayBreakRequest",
              },
              model: {
                value: _vm.isOnlyEssayReviewBreakRequests,
                callback: function ($$v) {
                  _vm.isOnlyEssayReviewBreakRequests = $$v
                },
                expression: "isOnlyEssayReviewBreakRequests",
              },
            },
            [
              _c("template", { slot: "text" }, [
                _vm._v("\n\t\t\t\tWriting Review Shifts Only\n\t\t\t"),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-4 tw-mb-12 tw-mt-4",
            },
            [
              _c(
                "div",
                { staticClass: "breaks tw-overflow-y-auto" },
                [
                  _vm.isMealBreakRequestEmpty
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: "empty-break-request",
                              expression: "'empty-break-request'",
                            },
                            {
                              name: "data-cy",
                              rawName: "v-data-cy",
                              value: "empty-break-request",
                              expression: "'empty-break-request'",
                            },
                          ],
                          staticClass:
                            "tw-grid tw-items-center tw-bg-white-high tw-font-bold tw-text-2xl tw-text-center tw-font-bold tw-text-grey-dark tw-px-4 tw-h-full",
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\tGo grab a coffee! There are no break requests at this moment\n\t\t\t\t"
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.filteredBreakRequests, function (breakRequest) {
                    return _c("TutorBreakCardMealBreakRequest", {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "break-request",
                          expression: "'break-request'",
                        },
                      ],
                      key: breakRequest.id,
                      attrs: { "break-request": breakRequest },
                      on: {
                        "select-time": function ($event) {
                          return _vm.getBreaksForSelectedTime($event)
                        },
                      },
                    })
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "breaks tw-bg-grey-slightly tw-border-2 tw-text-grey-dark tw-p-6",
                },
                [
                  _vm.selectedBreakStartTime === null
                    ? [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "jest",
                                rawName: "v-jest",
                                value: "empty-tutors-on-break",
                                expression: "'empty-tutors-on-break'",
                              },
                              {
                                name: "data-cy",
                                rawName: "v-data-cy",
                                value: "empty-tutors-on-break",
                                expression: "'empty-tutors-on-break'",
                              },
                            ],
                            staticClass:
                              "tw-grid tw-items-center tw-bg-grey-slightly tw-font-bold tw-text-2xl tw-text-center tw-font-bold tw-h-full",
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\tClick on a break request to see additional information\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ]
                    : [
                        _c(
                          "span",
                          { staticClass: "tw-text-xl tw-font-bold tw-h-full" },
                          [
                            _vm._v("\n\t\t\t\t\t\tTutors on Breaks From  "),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(_vm.timeRange) +
                                " (" +
                                _vm._s(_vm.breaks.length) +
                                ")\n\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "tutorsOnBreakFrom tw-overflow-y-auto tw-mt-4 tw-h-full",
                          },
                          _vm._l(_vm.breaks, function (tutorBreak) {
                            return _c("TutorBreakCard", {
                              directives: [
                                {
                                  name: "jest",
                                  rawName: "v-jest",
                                  value: "break-selected",
                                  expression: "'break-selected'",
                                },
                              ],
                              key: tutorBreak.id,
                              attrs: { break: tutorBreak },
                            })
                          }),
                          1
                        ),
                      ],
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        {
          directives: [
            {
              name: "data-cy",
              rawName: "v-data-cy",
              value: "upcoming-meal-break",
              expression: "'upcoming-meal-break'",
            },
          ],
          attrs: { "aria-label": "Upcoming Meal Breaks" },
        },
        [
          _c("h2", { staticClass: "tw-text-3xl tw-font-extrabold tw-mb-5" }, [
            _vm._v(
              "\n\t\t\tUpcoming Meal Breaks (" +
                _vm._s(_vm.upcomingBreaks.length) +
                ")\n\t\t"
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-mb-12 tw-gap-x-4",
            },
            _vm._l(_vm.upcomingBreaks, function (upcomingBreak) {
              return _c("TutorBreakCardUpcomingBreak", {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "upcoming-break",
                    expression: "'upcoming-break'",
                  },
                ],
                key: upcomingBreak.id,
                attrs: {
                  "upcoming-break": upcomingBreak,
                  "details-icon": "meal-break",
                },
              })
            }),
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        {
          directives: [
            {
              name: "data-cy",
              rawName: "v-data-cy",
              value: "active-meal-break",
              expression: "'active-meal-break'",
            },
          ],
          attrs: { "aria-label": "Active Meal Breaks" },
        },
        [
          _c("h2", { staticClass: "tw-text-3xl tw-font-extrabold tw-mb-5" }, [
            _vm._v(
              "\n\t\t\tActive Meal Breaks (" +
                _vm._s(_vm.currentBreaks.length) +
                ")\n\t\t"
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-mb-12 tw-gap-x-4",
            },
            _vm._l(_vm.currentBreaks, function (currentBreak) {
              return _c("TutorBreakCardActiveBreak", {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "current-break",
                    expression: "'current-break'",
                  },
                ],
                key: currentBreak.id,
                attrs: {
                  "current-break": currentBreak,
                  "details-icon": "meal-break",
                },
              })
            }),
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }