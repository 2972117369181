<template>
	<div class="shiftButtons__container tw-flex tw-items-center tw-justify-center">
		<PunchClockShiftCalendar
			v-jest="'shift-button-left'"
			icon-type="CALENDAR"
			:button-type="buttonType"
			:is-left-button="hasShift"
		/>
		<PunchButton
			v-if="hasShift"
			v-jest="'punch-button'"
			:current-date="currentDate"
		/>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { isAfter, isBefore, subMinutes } from "date-fns";

import { SHIFT_ENDED } from "@/modules/ServiceSchedule/utilities/ShiftStates.js";

import PunchClockShiftCalendar from "./v2/PunchClockShiftCalendar.vue";
import PunchButton from "./v2/PunchButton.vue";

const ONE_SECOND_IN_MS = 1000;

export default {
	components: {
		PunchClockShiftCalendar,
		PunchButton,
	},
	data() {
		return {
			refreshInterval: null,
			currentDate: new Date(),
		};
	},
	computed: {
		...mapState(["PunchClock", "currentUser", "TutorSchedule"]),
		hasShift() {
			const shift = this.$store.getters["TutorSchedule/getCurrentShift"];

			if (!shift) {
				return false;
			}

			return !this.$store.getters["TutorSchedule/isTutorManagerShift"] &&
        isAfter(this.currentDate, subMinutes(new Date(shift.start_time), 15)) &&
        this.TutorSchedule.shiftState.state_id !== SHIFT_ENDED &&
        isBefore(this.currentDate, new Date(shift.end_time));
		},
		buttonType() {
			if (
				this.$store.getters["TutorSchedule/isLateForShift"] || this.$store.getters["TutorSchedule/isLockedOutOfShift"]
			) {
				return "NEGATIVE";
			} else if (this.hasShift) {
				return "SUBMIT";
			}
			return "PRIMARY";
		},
	},
	async created() {
		this.refreshInterval = setInterval(() => {
			this.currentDate = new Date();
			this.$store.dispatch("TutorSchedule/setCurrentDate", new Date());
		}, ONE_SECOND_IN_MS);
	},
	beforeDestroy() {
		window.clearInterval(this.refreshInterval);
	},
};
</script>

<style scoped>
.shiftButtons__container {
	margin-right: 1.125rem;
}
</style>
