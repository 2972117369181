var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tag",
      class: {
        "tag--enabled": _vm.isClearable,
        "tag--disabled": !_vm.isClearable,
      },
    },
    [
      _c("div", { staticClass: "tag__leftItem" }, [
        _c("p", { staticClass: "tag__text" }, [_vm._t("text")], 2),
      ]),
      _vm._v(" "),
      _vm.isClearable
        ? _c("div", { staticClass: "tag__rightItem" }, [_vm._t("icon")], 2)
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }