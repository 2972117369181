var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "scheduleInfo" }, [
    _c(
      "div",
      { staticClass: "scheduleInfo__warningMessageContainer" },
      [
        _c("OrigamiIcon", {
          directives: [
            {
              name: "data-cy",
              rawName: "v-data-cy",
              value: "schedule-info-button",
              expression: "'schedule-info-button'",
            },
          ],
          staticClass: "pull-left",
          attrs: { name: "info", width: "16", height: "16" },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "data-cy",
                rawName: "v-data-cy",
                value: "schedule-info-warning-message",
                expression: "'schedule-info-warning-message'",
              },
            ],
            staticClass: "scheduleInfo__warningMessage",
          },
          [
            _vm._v(
              "\n\t\t\tIn order for you to be scheduled for tutoring, you "
            ),
            _c("strong", [_vm._v("must")]),
            _vm._v(" set your future\n\t\t\tavailability in the\n\t\t\t"),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "schedule-info-availability-link",
                    expression: "'schedule-info-availability-link'",
                  },
                ],
                staticClass: "scheduleInfo__availabilitiesLink",
                attrs: { href: "/tutors#availability" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.toAvailabilities.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Availability")]
            ),
            _vm._v(
              "\n\t\t\tsection. This will help us understand which days and times you are available.\n\t\t"
            ),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "scheduleInfo__infoContainer" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "data-cy",
              rawName: "v-data-cy",
              value: "schedule-info-title",
              expression: "'schedule-info-title'",
            },
          ],
          staticClass: "scheduleInfo__title",
        },
        [
          _vm._v("\n\t\t\tSchedule\n\t\t\t"),
          _c(
            "span",
            {
              directives: [
                {
                  name: "data-cy",
                  rawName: "v-data-cy",
                  value: "tutor-dashboard-schedule-download",
                  expression: "'tutor-dashboard-schedule-download'",
                },
              ],
              staticClass: "scheduleInfo__link scheduleInfo__link--floatRight",
            },
            [
              _c(
                "button",
                {
                  staticClass: "scheduleInfo__linkText",
                  on: { click: _vm.getTutorSchedule },
                },
                [_vm._v("Download Schedule")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "data-cy",
                  rawName: "v-data-cy",
                  value: "tutor-dashboard-schedule-legend",
                  expression: "'tutor-dashboard-schedule-legend'",
                },
              ],
              staticClass: "scheduleInfo__link scheduleInfo__link--floatRight",
            },
            [
              _c(
                "a",
                {
                  staticClass: "scheduleInfo__linkText",
                  attrs: { href: "#tutorDashboardScheduleLegend" },
                },
                [_vm._v("Legend")]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "data-cy",
              rawName: "v-data-cy",
              value: "schedule-info-paragraph",
              expression: "'schedule-info-paragraph'",
            },
          ],
          staticClass: "scheduleInfo__paragraph",
        },
        [
          _vm._v(
            "\n\t\t\tBelow is a calendar with your scheduled shifts.\n\t\t"
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }