import { useRouter, useRoute } from "vue-router/composables";

import useStore from "@/composables/useStore.js";
import { refreshAccessTokenIfNecessary } from "@/services/root.js";
import { getAndConsumePostLoginRedirectUri } from "@/modules/Authentication/utilities/auth.js";

import { MICRO_FRONTENDS_SUBSCRIPTION_EVENTS } from "../utilities/index.js";

const attachedEventListeners = {};

export default function useMicroFrontendEvents() {
	const router = useRouter();
	const route = useRoute();
	const store = useStore();

	const updateHostRoute = (eventData) => {
		const { to } = eventData;
		if (route.path !== to) {
			router.push(to);
		}
	};
	const acceptStudentAgreement = () => {
		store.commit("SET_CURRENT_USER", {
			currentUser: {
				...store.state.currentUser,
				accepted_agreement: 1,
			},
		});
	};
	const finishOnboardingAndRedirectUser = async() => {
		window.removeEventListener(
			MICRO_FRONTENDS_SUBSCRIPTION_EVENTS.ONBOARDING_COMPLETE,
			finishOnboardingAndRedirectUser,
		);
		acceptStudentAgreement();
		await Promise.allSettled([
			store.dispatch("Student/Dashboard/setAcceptedAgreement", { accepted_agreement: 1 }),
			store.dispatch("StudentOnboarding/getStudentStatus"),
		]);
		await refreshAccessTokenIfNecessary(true);
		store.commit("SET_SHOW_NAVBAR", true);
		document.body.classList.remove("disable-scroll-onboarding");
		const redirect_uri = await getAndConsumePostLoginRedirectUri();
		const route = redirect_uri ?? "/student/dashboard";
		router.push(route);
	};
	const showMajorClarityFirstTimeUserModal = () => {
		store.commit("MajorClarity/SET_IS_SHOWING_REDIRECT_MODAL", true);
	};
	const eventsHandlers = {
		[MICRO_FRONTENDS_SUBSCRIPTION_EVENTS.ROUTE_UPDATE]: (event) => updateHostRoute(event.detail),
		[MICRO_FRONTENDS_SUBSCRIPTION_EVENTS.STUDENT_DASHBOARD_AGREEMENT_ACCEPT]: acceptStudentAgreement,
		[MICRO_FRONTENDS_SUBSCRIPTION_EVENTS.ONBOARDING_COMPLETE]: finishOnboardingAndRedirectUser,
		[MICRO_FRONTENDS_SUBSCRIPTION_EVENTS.STUDENT_DASHBOARD_MAJOR_CLARITY_CLICK]: showMajorClarityFirstTimeUserModal,
	};
	const subscribeToEvents = () => {
		Object.values(MICRO_FRONTENDS_SUBSCRIPTION_EVENTS).forEach((eventName) => {
			if (attachedEventListeners[eventName]?.numberOfEmitters) {
				attachedEventListeners[eventName].numberOfEmitters++;
				return;
			}
			window.addEventListener(
				eventName,
				eventsHandlers[eventName],
			);
			attachedEventListeners[eventName] = { numberOfEmitters: 1 };
		});
	};
	const unsubscribeToEvents = () => {
		Object.values(MICRO_FRONTENDS_SUBSCRIPTION_EVENTS).forEach((eventName) => {
			if (attachedEventListeners[eventName]?.numberOfEmitters) {
				attachedEventListeners[eventName].numberOfEmitters--;
				if (attachedEventListeners[eventName].numberOfEmitters) {
					return;
				}
			}
			window.removeEventListener(
				eventName,
				eventsHandlers[eventName],
			);
		});
	};

	return {
		subscribeToEvents,
		unsubscribeToEvents,
	};
}
