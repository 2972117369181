var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "profile" },
    [
      _c("span", { staticClass: "profile__text profile__text--large" }, [
        _vm._v(
          "\n\t\t" +
            _vm._s(
              _vm.student.first_name +
                " " +
                _vm.student.last_name.charAt(0) +
                "."
            ) +
            "\n\t"
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "profile__item" }, [
        _c(
          "p",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "session-id",
                expression: "'session-id'",
              },
            ],
            staticClass: "profile__text profile__text--bold",
          },
          [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(_vm.$t("right_sidebar.student_profile.session_id")) +
                " #" +
                _vm._s(_vm.sessionId) +
                "\n\t\t"
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.featureFlagSessionIntentsIsEnabled &&
      !_vm.isEmpty(_vm.sessiontIntentType)
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "session-intent-type",
                  expression: "'session-intent-type'",
                },
              ],
              staticClass: "profile__item",
            },
            [
              _c(
                "span",
                { staticClass: "profile__item--title profile__text" },
                [
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.$t("session_intent.title")) +
                      "\n\t\t"
                  ),
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "profile__text profile__text--bold" }, [
                _vm._v(
                  "\n\t\t\t" + _vm._s(_vm.sessionIntentTypeName) + "\n\t\t"
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "profile__item" }, [
        _c("span", { staticClass: "profile__item--title profile__text" }, [
          _vm._v(
            "\n\t\t\t" +
              _vm._s(_vm.$t("right_sidebar.student_profile.grade_level")) +
              "\n\t\t"
          ),
        ]),
        _vm._v(" "),
        !_vm.isEditingGrade
          ? _c(
              "span",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "grade-level",
                    expression: "'grade-level'",
                  },
                ],
                staticClass: "profile__text profile__text--bold",
              },
              [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.$t(`grade_levels["${_vm.gradeLevelKey}"]`)) +
                    "\n\t\t\t"
                ),
                _c(
                  "span",
                  {
                    staticClass: "profile__text--iconPen",
                    on: {
                      click: function ($event) {
                        _vm.isEditingGrade = true
                      },
                    },
                  },
                  [
                    _c("IconPen", {
                      attrs: {
                        "icon-color": _vm.blackColor,
                        height: 12,
                        width: 12,
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          : _c(
              "div",
              { staticClass: "profile__edit-grade" },
              [
                _c(
                  "BaseDropdown",
                  {
                    attrs: {
                      items: _vm.grades,
                      "is-clearable": false,
                      "close-on-select": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "span",
                              {
                                key: item.id,
                                staticClass: "profile__dropdown-item",
                                on: {
                                  click: function ($event) {
                                    _vm.selectedGrade = item
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.$t(`grade_levels["${item.name}"]`)
                                    ) +
                                    "\n\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c("template", { slot: "filters" }, [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.dropdownFilter) +
                          "\n\t\t\t\t"
                      ),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "profile__editButtons" },
                  [
                    _c(
                      "BaseButton",
                      {
                        staticClass: "profile__editButtons-item",
                        attrs: { type: "CANCEL" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.cancelGradeSelection.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _c("template", { slot: "text" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.$t("cancel")) +
                              "\n\t\t\t\t\t"
                          ),
                        ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "BaseButton",
                      {
                        staticClass: "profile__editButtons-item",
                        attrs: { type: "SUBMIT" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.submitGrade.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("template", { slot: "text" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(_vm.$t("save")) +
                              "\n\t\t\t\t\t"
                          ),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
      ]),
      _vm._v(" "),
      _vm.student.grade === null &&
      _vm.isAttemptingToUpdateCleverStudentGrade === false
        ? _c("div", { staticClass: "profile__item profile__unknownGrade" }, [
            _c("span", { staticClass: "profile__text" }, [
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(
                    _vm.$t("right_sidebar.student_profile.ask_grade_level", {
                      name: _vm.student.first_name,
                    })
                  ) +
                  "\n\t\t"
              ),
            ]),
          ])
        : _c("div", { staticClass: "profile__item profile__unknownGrade" }, [
            _c("span", { staticClass: "profile__text" }, [
              _vm._v(
                "\n\t\t\tYou cannot change the grade of a student who is synced with Clever\n\t\t"
              ),
            ]),
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "profile__item" }, [
        _c("span", { staticClass: "profile__item--title profile__text" }, [
          _vm._v(
            "\n\t\t\t" +
              _vm._s(_vm.$t("right_sidebar.student_profile.class_name")) +
              "\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "profile__text profile__text--bold" }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.sectionNameText) + "\n\t\t"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "profile__item" }, [
        _c("span", { staticClass: "profile__item--title profile__text" }, [
          _vm._v(
            "\n\t\t\t" +
              _vm._s(_vm.$t("right_sidebar.student_profile.teacher_name")) +
              "\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "profile__text profile__text--bold" }, [
          _vm._v(_vm._s(_vm.teacherName)),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isStudentAppUser || _vm.isStudentExtensionUser,
              expression: "isStudentAppUser || isStudentExtensionUser",
            },
          ],
          staticClass: "profile__item--large",
        },
        [
          _c("span", { staticClass: "profile__text profile__text--large" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "limited-functionality-title",
                    expression: "'limited-functionality-title'",
                  },
                ],
                staticClass: "profile__text--orange",
              },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.limitedFunctionalityTitle) +
                    "\n\t\t\t"
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "limited-functionality-header",
                    expression: "'limited-functionality-header'",
                  },
                ],
              },
              [_vm._v(" " + _vm._s(_vm.limitedFunctionalityHeader) + " ")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "limited-functionality-text",
                  expression: "'limited-functionality-text'",
                },
              ],
              staticClass: "profile__text",
            },
            [
              _vm._v(
                "\n\t\t\t" + _vm._s(_vm.limitedFunctionalityText) + "\n\t\t"
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      !_vm.isStudentLanguageEnglish
        ? _c("StudentProfilePreferredLanguage", {
            attrs: { "preferred-language-key": _vm.student.language },
          })
        : _c("span", { staticClass: "profile__text" }, [
            _vm._v(
              "\n\t\t" +
                _vm._s(
                  _vm.$t("right_sidebar.student_profile.resource_not_available")
                ) +
                "\n\t"
            ),
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "profile__tutorComments" }, [
        _vm._v("\n\t\tTutor's comments\n\t"),
      ]),
      _vm._v(" "),
      _vm.isCommentsLoading
        ? _c("div", [_c("LoadingDots")], 1)
        : _vm.comments.length == 0
        ? _c("div", { staticClass: "profile__noComments" }, [
            _vm._v("\n\t\tNo Comments\n\t"),
          ])
        : _c(
            "div",
            { staticClass: "profile__tutorComment" },
            _vm._l(_vm.comments, function (el, index) {
              return _c("TutorComment", {
                key: index,
                attrs: { name: el.tutor, comment: el.comment, date: el.date },
              })
            }),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }