import { differenceInDays, differenceInWeeks, format, subMonths, parseISO, addDays, addWeeks, isSameDay, getDay, getHours, getWeek, startOfWeek, endOfWeek, getDayOfYear, sub } from "date-fns";
import { without } from "lodash";

import {
	getGradeLabel,
	getGradeIds,
	UNCATEGORIZED_GRADE_LEVEL_ID,
	UNCATEGORIZED_GRADE_LEVEL_NAME,
} from "@/utilities/grade.js";
import { formatPlurality } from "@/utilities/strings.js";

import EducatorInsightsAPI from "../services/index.js";

const DAYS_IN_MONTH = 31;

const roundAverage = (averageNumber) => Number.isInteger(averageNumber) ? averageNumber : averageNumber.toFixed(1);

const widgetDefault = {
	data: null,
	isLoading: false,
	isError: false,
};

const getDefaultState = () => ({
	district: {},
	schools: [],
	gradeLevels: [],
	dateRangeSubmitted: {
		start: subMonths(new Date(), 1),
		end: new Date(),
	},
	filterSelections: {
		dateRange: {
			start: subMonths(new Date(), 1),
			end: new Date(),
		},
		gradeLevels: [],
		schools: [],
	},
	filterOptions: {
		gradeLevels: [],
		schools: [],
	},
	quickStats: { ...widgetDefault },
	usageByDay: { ...widgetDefault },
	usageBySchool: { ...widgetDefault },
	mostRequestedTopics: { ...widgetDefault },
	pageStatus: {
		isLoading: false,
		isError: false,
		noData: false,
	},
});

const state = getDefaultState();

const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_DISTRICT(state, payload) {
		state.district = payload;
	},
	SET_SCHOOLS(state, payload) {
		state.schools = payload;
	},
	SET_GRADE_LEVELS(state, payload) {
		state.gradeLevels = payload;
	},
	// WIDGET
	SET_QUICK_STATS(state, payload) {
		state.quickStats = { ...state.quickStats, ...payload };
	},
	SET_USAGE_BY_DAY(state, payload) {
		state.usageByDay = { ...state.usageByDay, ...payload };
	},
	SET_USAGE_BY_SCHOOL(state, payload) {
		state.usageBySchool = { ...state.usageBySchool, ...payload };
	},
	SET_MOST_REQUESTED_TOPICS(state, payload) {
		state.mostRequestedTopics = { ...state.mostRequestedTopics, ...payload };
	},
	// FILTER
	RESET_FILTER_SELECTIONS(state, payload) {
		const defaultState = getDefaultState();
		state.filterSelections = {
			...defaultState.filterSelections,
			dateRange: payload || defaultState.filterSelections.dateRange,
		};
	},
	SET_DATE_RANGE_SUBMITTED(state) {
		state.dateRangeSubmitted = state.filterSelections.dateRange;
	},
	SET_FILTER_OPTIONS(state, payload) {
		state.filterOptions = payload;
	},
	SET_FILTER_OPTIONS_BY_KEY(state, { key, value }) {
		state.filterOptions = { ...state.filterOptions, [key]: value };
	},
	SET_FILTER_SELECTION_BY_KEY(state, { key, value }) {
		state.filterSelections = { ...state.filterSelections, [key]: value };
	},
	// PAGE
	SET_PAGE_STATUS(state, payload) {
		state.pageStatus = { ...state.pageStatus, ...payload };
	},
};

const getters = {
	getParams(state) {
		let schoolIds;
		const schoolsSelected = state.filterSelections.schools;
		if (schoolsSelected.length) {
			schoolIds = schoolsSelected;
		}

		let gradeLevelIds = state.filterOptions.gradeLevels.map((gradeLevel) => gradeLevel.id);
		const gradeLevelsSelected = state.filterSelections.gradeLevels;
		if (gradeLevelsSelected.length) {
			gradeLevelIds = gradeLevelsSelected;
		}

		const params = {
			start_date: format(state.dateRangeSubmitted.start, "yyyy-MM-dd"),
			end_date: format(state.dateRangeSubmitted.end, "yyyy-MM-dd"),
			include: "district,school",
			district_id: state.district.id,
			include_uncategorized: gradeLevelIds.includes(UNCATEGORIZED_GRADE_LEVEL_ID),
			grade_level_ids: without(gradeLevelIds, UNCATEGORIZED_GRADE_LEVEL_ID),
		};

		if (schoolIds) {
			params.school_ids = schoolIds;
		}

		return params;
	},
	getTotalUsageByGrade(state) {
		const totalUsageByGradeNames = getGradeIds(state.filterOptions.schools)
			.sort((a, b) => a > b ? 1 : -1)
			.map((gradeId) => {
				const gradeLevel =
					state.gradeLevels.find((gradeLevel) => (gradeLevel.id ?? UNCATEGORIZED_GRADE_LEVEL_ID) === gradeId);

				return {
					gradeName: gradeLevel.name ?? UNCATEGORIZED_GRADE_LEVEL_NAME,
					value: 0,
				};
			});

		if (totalUsageByGradeNames[0].gradeName === UNCATEGORIZED_GRADE_LEVEL_NAME) {
			totalUsageByGradeNames.push(totalUsageByGradeNames.shift());
		}

		state.usageByDay.data.forEach((usage) => {
			const currentUsageByGradeName =
				totalUsageByGradeNames.find((usageByGrade) => {
					return (usage.grade_name ?? UNCATEGORIZED_GRADE_LEVEL_NAME) === usageByGrade.gradeName;
				});

			if (currentUsageByGradeName) {
				currentUsageByGradeName.value++;
			}
		});

		const totalUsageByGrade = totalUsageByGradeNames
			.map((usage) => {
				const { gradeName, value } = usage;

				return {
					label: {
						title: getGradeLabel(gradeName),
						tick: gradeName === UNCATEGORIZED_GRADE_LEVEL_NAME ? "N/S" : gradeName,
						content: formatPlurality(value, "activity"),
					},
					value,
				};
			});

		return totalUsageByGrade;
	},
	getTotalUsageByHour(state) {
		const timeRange = [
			{ title: "12:00AM - 12:59AM", tick: "12a", hour: 0 },
			{ title: "1:00AM - 1:59AM", tick: "1a", hour: 1 },
			{ title: "2:00AM - 2:59AM", tick: "2a", hour: 2 },
			{ title: "3:00AM - 3:59AM", tick: "3a", hour: 3 },
			{ title: "4:00AM - 4:59AM", tick: "4a", hour: 4 },
			{ title: "5:00AM - 5:59AM", tick: "5a", hour: 5 },
			{ title: "6:00AM - 6:59AM", tick: "6a", hour: 6 },
			{ title: "7:00AM - 7:59AM", tick: "7a", hour: 7 },
			{ title: "8:00AM - 8:59AM", tick: "8a", hour: 8 },
			{ title: "9:00AM - 9:59AM", tick: "9a", hour: 9 },
			{ title: "10:00AM - 10:59AM", tick: "10a", hour: 10 },
			{ title: "11:00AM - 11:59AM", tick: "11a", hour: 11 },
			{ title: "12:00PM - 12:59PM", tick: "12p", hour: 12 },
			{ title: "1:00PM - 1:59PM", tick: "1p", hour: 13 },
			{ title: "2:00PM - 2:59PM", tick: "2p", hour: 14 },
			{ title: "3:00PM - 3:59PM", tick: "3p", hour: 15 },
			{ title: "4:00PM - 4:59PM", tick: "4p", hour: 16 },
			{ title: "5:00PM - 5:59PM", tick: "5p", hour: 17 },
			{ title: "6:00PM - 6:59PM", tick: "6p", hour: 18 },
			{ title: "7:00PM - 7:59PM", tick: "7p", hour: 19 },
			{ title: "8:00PM - 8:59PM", tick: "8p", hour: 20 },
			{ title: "9:00PM - 9:59PM", tick: "9p", hour: 21 },
			{ title: "10:00PM - 10:59PM", tick: "10p", hour: 22 },
			{ title: "11:00PM - 11:59PM", tick: "11p", hour: 23 },
		];

		const usageByHour = state.usageByDay.data.map((usage) => {
			const date = parseISO(usage.time_of_activity);
			return getHours(date);
		});

		return timeRange.map((time) => {
			const value = usageByHour.filter((hour) => hour === time.hour).length;

			return {
				label: {
					title: time.title,
					tick: time.tick,
					content: formatPlurality(value, "activity"),
				},
				value,
			};
		});
	},
	getTotalUsageByDay(state) {
		const totalUsageByDay = [];

		// Build time reference for the chart based on calendar query
		const { start, end } = state.dateRangeSubmitted;
		const days = differenceInDays(end, start);

		for (let index = 0; index < (days + 1); index++) {
			const date = addDays(start, index);
			const defaultTotalUsageByDay = {
				date,
				label: {
					title: format(date, "MMMM d, yyyy"),
					tick: format(date, "MMM d"),
					content: formatPlurality(0, "activity"),
				},
				value: 0,
			};

			totalUsageByDay.push(defaultTotalUsageByDay);
		}

		// Iterate on raw data to aggregate activity by date
		state.usageByDay.data.forEach((usage) => {
			const date = parseISO(usage.time_of_activity);

			const totalUsageDay = totalUsageByDay.find((usage) => isSameDay(usage.date, date));
			if (totalUsageDay) {
				const value = totalUsageDay.value + 1;

				totalUsageDay.value = value;
				totalUsageDay.label.content = formatPlurality(value, "activity");
			}
		});

		return totalUsageByDay;
	},
	getTotalUsageByWeek(state) {
		const totalUsageByWeek = [];

		// Build time reference for the chart based on calendar query
		const { start, end } = state.dateRangeSubmitted;
		const weeks = differenceInWeeks(endOfWeek(end), startOfWeek(start));

		for (let index = 0; index < (weeks + 1); index++) {
			const date = addWeeks(start, index);
			const weekStart = format(startOfWeek(date), "MMMM d, yyyy");
			const weekEnd = format(endOfWeek(date), "MMMM d, yyyy");

			let tick = startOfWeek(date);

			if (index === 0) {
				tick = start;
			} else if (index === weeks) {
				tick = end;
			}

			const defaultTotalUsageByDay = {
				weekIndex: getWeek(date, 1),
				label: {
					title: `${weekStart} - ${weekEnd}`,
					tick: format(tick, "MMM d"),
					content: formatPlurality(0, "activity"),
				},
				value: 0,
			};

			totalUsageByWeek.push(defaultTotalUsageByDay);
		}

		state.usageByDay.data.forEach((usage) => {
			const date = parseISO(usage.time_of_activity);
			const weekIndex = getWeek(date, 1);

			const totalUsageDay = totalUsageByWeek.find((usage) => usage.weekIndex === weekIndex);
			if (totalUsageDay) {
				const value = totalUsageDay.value + 1;

				totalUsageDay.value = value;
				totalUsageDay.label.content = formatPlurality(value, "activity");
			}
		});

		return totalUsageByWeek;
	},
	getAverageUsageByDay(state) {
		// Build time reference for the chart following date-fns weekday index order
		const totalUsageByDay = [
			{ day: "Sunday", tick: "Su", total: 0, dayInWeeks: [] },
			{ day: "Monday", tick: "M", total: 0, dayInWeeks: [] },
			{ day: "Tuesday", tick: "Tu", total: 0, dayInWeeks: [] },
			{ day: "Wednesday", tick: "W", total: 0, dayInWeeks: [] },
			{ day: "Thursday", tick: "Th", total: 0, dayInWeeks: [] },
			{ day: "Friday", tick: "F", total: 0, dayInWeeks: [] },
			{ day: "Saturday", tick: "Sa", total: 0, dayInWeeks: [] },
		];

		// Iterate on raw data to aggregate activity by day
		state.usageByDay.data.forEach((usage) => {
			const date = parseISO(usage.time_of_activity);
			const dayIndex = getDay(date);
			const weekIndex = getWeek(date);

			if (!totalUsageByDay[dayIndex].dayInWeeks.includes(weekIndex)) {
				totalUsageByDay[dayIndex].dayInWeeks.push(weekIndex);
			}
			totalUsageByDay[dayIndex].total++;
		});

		// Calculate average usage by day
		const averageUsageByDay = totalUsageByDay.map((usage) => {
			const averageUsageCurrentDay = usage.total > 1 ? usage.total / usage.dayInWeeks.length : usage.total;
			const value = roundAverage(averageUsageCurrentDay);

			return {
				label: {
					title: usage.day,
					tick: usage.tick,
					content: `${formatPlurality(value, "activity")} on average`,
				},
				value,
			};
		});

		return averageUsageByDay;
	},
	getAverageUsageByHour(state) {
		const timeRange = [
			{ title: "12:00AM - 12:59AM", tick: "12a", hour: 0, daysOfYear: [], usageByHour: 0 },
			{ title: "1:00AM - 1:59AM", tick: "1a", hour: 1, daysOfYear: [], usageByHour: 0 },
			{ title: "2:00AM - 2:59AM", tick: "2a", hour: 2, daysOfYear: [], usageByHour: 0 },
			{ title: "3:00AM - 3:59AM", tick: "3a", hour: 3, daysOfYear: [], usageByHour: 0 },
			{ title: "4:00AM - 4:59AM", tick: "4a", hour: 4, daysOfYear: [], usageByHour: 0 },
			{ title: "5:00AM - 5:59AM", tick: "5a", hour: 5, daysOfYear: [], usageByHour: 0 },
			{ title: "6:00AM - 6:59AM", tick: "6a", hour: 6, daysOfYear: [], usageByHour: 0 },
			{ title: "7:00AM - 7:59AM", tick: "7a", hour: 7, daysOfYear: [], usageByHour: 0 },
			{ title: "8:00AM - 8:59AM", tick: "8a", hour: 8, daysOfYear: [], usageByHour: 0 },
			{ title: "9:00AM - 9:59AM", tick: "9a", hour: 9, daysOfYear: [], usageByHour: 0 },
			{ title: "10:00AM - 10:59AM", tick: "10a", hour: 10, daysOfYear: [], usageByHour: 0 },
			{ title: "11:00AM - 11:59AM", tick: "11a", hour: 11, daysOfYear: [], usageByHour: 0 },
			{ title: "12:00PM - 12:59PM", tick: "12p", hour: 12, daysOfYear: [], usageByHour: 0 },
			{ title: "1:00PM - 1:59PM", tick: "1p", hour: 13, daysOfYear: [], usageByHour: 0 },
			{ title: "2:00PM - 2:59PM", tick: "2p", hour: 14, daysOfYear: [], usageByHour: 0 },
			{ title: "3:00PM - 3:59PM", tick: "3p", hour: 15, daysOfYear: [], usageByHour: 0 },
			{ title: "4:00PM - 4:59PM", tick: "4p", hour: 16, daysOfYear: [], usageByHour: 0 },
			{ title: "5:00PM - 5:59PM", tick: "5p", hour: 17, daysOfYear: [], usageByHour: 0 },
			{ title: "6:00PM - 6:59PM", tick: "6p", hour: 18, daysOfYear: [], usageByHour: 0 },
			{ title: "7:00PM - 7:59PM", tick: "7p", hour: 19, daysOfYear: [], usageByHour: 0 },
			{ title: "8:00PM - 8:59PM", tick: "8p", hour: 20, daysOfYear: [], usageByHour: 0 },
			{ title: "9:00PM - 9:59PM", tick: "9p", hour: 21, daysOfYear: [], usageByHour: 0 },
			{ title: "10:00PM - 10:59PM", tick: "10p", hour: 22, daysOfYear: [], usageByHour: 0 },
			{ title: "11:00PM - 11:59PM", tick: "11p", hour: 23, daysOfYear: [], usageByHour: 0 },
		];
		state.usageByDay.data.forEach((usage) => {
			const date = parseISO(usage.time_of_activity);
			const hourIndex = getHours(date);
			const dayIndex = getDayOfYear(date);
			if (!timeRange[hourIndex].daysOfYear.includes(dayIndex)) {
				timeRange[hourIndex].daysOfYear.push(dayIndex);
			}
			timeRange[hourIndex].usageByHour++;
		});

		return timeRange.map((time) => {
			const averageNumber = time.usageByHour > 1 ? time.usageByHour / time.daysOfYear.length : time.usageByHour;
			const value = roundAverage(averageNumber);

			return {
				label: {
					title: time.title,
					tick: time.tick,
					content: `${formatPlurality(value, "activity")} on average`,
				},
				value,
			};
		});
	},
	getTotalUsageByTime(state, getters) {
		const { start, end } = state.dateRangeSubmitted;

		if (isSameDay(end, start)) {
			return getters.getTotalUsageByHour;
		} else if (differenceInDays(end, start) > DAYS_IN_MONTH) {
			return getters.getTotalUsageByWeek;
		} else {
			return getters.getTotalUsageByDay;
		}
	},
	getTutoringSessions(state) {
		return state.quickStats?.data?.tutoring_sessions?.toLocaleString();
	},
	getEssayReviews(state) {
		return state.quickStats?.data?.essay_reviews?.toLocaleString();
	},
};

const actions = {
	adjustGradeLevels({ state, commit }) {
		const gradeIdsOfSelectedSchools = getGradeIds(state.filterOptions.schools, state.filterSelections.schools);

		const categorizedGradeLevelsOfSelectedSchools = state.gradeLevels.filter(({ id }) =>
			gradeIdsOfSelectedSchools.includes(id) && id !== UNCATEGORIZED_GRADE_LEVEL_ID,
		)
			.sort((a, b) => a.id - b.id);

		const gradeLevelsOptions =
			gradeIdsOfSelectedSchools.includes(UNCATEGORIZED_GRADE_LEVEL_ID)
				? [
					...categorizedGradeLevelsOfSelectedSchools,
					{ id: UNCATEGORIZED_GRADE_LEVEL_ID, name: UNCATEGORIZED_GRADE_LEVEL_NAME },
				]
				: categorizedGradeLevelsOfSelectedSchools;

		commit("SET_FILTER_OPTIONS_BY_KEY", { key: "gradeLevels", value: gradeLevelsOptions });
		commit("SET_FILTER_SELECTION_BY_KEY", { key: "gradeLevels", value: [] });
	},
	async initPage({ state, getters, commit, dispatch }) {
		commit("SET_PAGE_STATUS", { isLoading: true });

		try {
			await dispatch("getFiltersData");

			// Workaround to address no dashboard data before
			// having a specific endpoint creating for this
			const { dateRange } = state.filterSelections;
			const startDate = sub(dateRange.start, { years: 1 });
			const usageOverLastYearParams = {
				...getters.getParams,
				start_date: format(startDate, "yyyy-MM-dd"),
				end_date: format(dateRange.end, "yyyy-MM-dd"),
			};
			const lastYearUsages = await EducatorInsightsAPI.getTotalUsageOverTime(usageOverLastYearParams);
			commit("SET_PAGE_STATUS", { noData: lastYearUsages?.length === 0 });

			await dispatch("getWidgetsData");
		} catch (error) {
			Sentry.captureException(error);
			commit("SET_PAGE_STATUS", { isError: true });
		} finally {
			commit("SET_PAGE_STATUS", { isLoading: false });
		}
	},
	async getFiltersData({ state, commit, dispatch }) {
		const filtersParams = {
			start_date: format(state.filterSelections.dateRange.start, "yyyy-MM-dd"),
			end_date: format(state.filterSelections.dateRange.end, "yyyy-MM-dd"),
		};

		const { district, schools, gradeLevels } = await EducatorInsightsAPI.getFilters(filtersParams);
		commit("SET_DISTRICT", district);
		commit("SET_SCHOOLS", schools);
		commit("SET_GRADE_LEVELS", gradeLevels);

		const { dateRange } = state.filterSelections;
		commit("RESET_FILTER_SELECTIONS", dateRange);
		commit("SET_FILTER_OPTIONS", { district, schools, dateRange });

		dispatch("adjustGradeLevels");
	},
	async getWidgetsData({ commit, dispatch }) {
		commit("SET_USAGE_BY_DAY", { isLoading: true });
		commit("SET_QUICK_STATS", { isLoading: true });
		commit("SET_USAGE_BY_SCHOOL", { isLoading: true });
		commit("SET_MOST_REQUESTED_TOPICS", { isLoading: true });

		await Promise.all([
			dispatch("getTotalUsageOverTime"),
			dispatch("getQuickStats"),
			dispatch("getUsageBySchool"),
			dispatch("getMostRequestedTopics"),
		]);
	},
	async getQuickStats({ commit, getters }) {
		try {
			commit("SET_QUICK_STATS", { ...widgetDefault, data: {}, isLoading: true });
			const data = await EducatorInsightsAPI.getQuickStats(getters.getParams);
			commit("SET_QUICK_STATS", { data });
		} catch (error) {
			Sentry.captureException(error);
			commit("SET_QUICK_STATS", { isError: true });
		} finally {
			commit("SET_QUICK_STATS", { isLoading: false });
		}
	},
	async getTotalUsageOverTime({ commit, getters }) {
		try {
			commit("SET_USAGE_BY_DAY", { ...widgetDefault, data: [], isLoading: true });
			const data = await EducatorInsightsAPI.getTotalUsageOverTime(getters.getParams);
			commit("SET_USAGE_BY_DAY", { data });
		} catch (error) {
			Sentry.captureException(error);
			commit("SET_USAGE_BY_DAY", { isError: true });
		} finally {
			commit("SET_USAGE_BY_DAY", { isLoading: false });
		}
	},
	async getUsageBySchool({ commit, getters }) {
		try {
			commit("SET_USAGE_BY_SCHOOL", { ...widgetDefault, data: [], isLoading: true });

			const data = await EducatorInsightsAPI.getUsageBySchool(getters.getParams);
			if (data.length) {
				const usageBySchool = data
					.sort((a, b) => b.percentage.localeCompare(a.percentage, "en", { numeric: true }))
					.map((usage) => {
						const percentage = parseInt(usage.percentage);

						return {
							activeStudents: usage.num_active_students,
							licensedStudents: usage.num_licensed_students,
							percentage: percentage >= 1 ? Math.round(percentage, 1) : Math.round(percentage),
							schoolId: usage.school_id,
							schoolName: usage.school_name,
						};
					});

				const maxRelativePercentage = usageBySchool[0].percentage || 1;
				usageBySchool.forEach((usage) => {
					usage.maximumPercentage = Math.round((usage.percentage / maxRelativePercentage) * 100);
				});
				commit("SET_USAGE_BY_SCHOOL", { data: usageBySchool });
			}
		} catch (error) {
			Sentry.captureException(error);
			commit("SET_USAGE_BY_SCHOOL", { isError: true });
		} finally {
			commit("SET_USAGE_BY_SCHOOL", { isLoading: false });
		}
	},
	async getMostRequestedTopics({ commit, getters }) {
		try {
			commit("SET_MOST_REQUESTED_TOPICS", { ...widgetDefault, data: [], isLoading: true });
			const data = await EducatorInsightsAPI.getMostRequestedTopics(getters.getParams);
			commit("SET_MOST_REQUESTED_TOPICS", { data });
		} catch (error) {
			Sentry.captureException(error);
			commit("SET_MOST_REQUESTED_TOPICS", { isError: true });
		} finally {
			commit("SET_MOST_REQUESTED_TOPICS", { isLoading: false });
		}
	},
};

export default {
	namespaced: true,
	actions,
	getters,
	mutations,
	state,
};
