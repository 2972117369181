<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		:width="width"
		:height="height"
		viewBox="0 0 32 40"
	>
		<defs>
			<path
				id="tg39217jna"
				d="M17.574 0l10.544 11.115v22.23c0 2.038-1.582 3.705-3.515 3.705H3.497C1.564 37.05 0 35.383 0 33.345l.018-29.64C.018 1.667 1.582 0 3.515 0h14.059zm-2.237 4.832H6.39c-1.405 0-2.543 1.233-2.543 2.74l-.013 21.907c0 1.506 1.138 2.739 2.544 2.739h15.35c1.405 0 2.556-1.233 2.556-2.739V13.05l-.151-.162h-8.796V4.832zm5.751 20.942v3.222H7.03v-3.222h14.06zm0-6.443v3.221H7.03v-3.221h14.06z"
			/>
		</defs>
		<g
			fill="none"
			fill-rule="evenodd"
			transform="translate(1.92 1.339)"
		>
			<mask
				id="d3ifjnm7fb"
				:fill="iconColor"
			>
				<use xlink:href="#tg39217jna" />
			</mask>
			<use
				:fill="iconColor"
				xlink:href="#tg39217jna"
			/>
			<g
				:fill="iconColor"
				mask="url(#d3ifjnm7fb)"
			>
				<path
					d="M0 0H35.147V37.05H0z"
					transform="translate(-3.515)"
				/>
			</g>
		</g>
	</svg>
</template>
<script>
import Colors from "../../utilities/Colors.js";

import IconId from "./IconId.js";
export default {
	extends: IconId,
	props: {
		width: {
			type: Number,
			default: 32,
		},
		height: {
			type: Number,
			default: 40,
		},
		iconColor: {
			type: String,
			required: false,
			default: Colors.C_WHITE,
		},
	},
};
</script>
