var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "tw-shadow-elevation-4": !_vm.isFlat } },
    [
      _vm.displayHeader
        ? _c("div", [
            _c(
              "h2",
              {
                staticClass: "tw-font-bold tw-text-lg tw-pl-6 tw-pr-4 tw-py-4",
              },
              [_vm._v("\n\t\t\tCreate New Shift\n\t\t")]
            ),
            _vm._v(" "),
            _c("hr"),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.newShift
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "tw-flex tw-items-center tw-px-6 tw-py-0" },
                [
                  _c("ServiceScheduleTutorShiftForm", {
                    attrs: {
                      "selected-tutor": _vm.selectedTutor,
                      "is-editing": false,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "tw-flex tw-justify-between tw-pb-4 tw-pt-0 tw-px-6",
                },
                [
                  _c(
                    "OrigamiButton",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "cancel-btn",
                          expression: "'cancel-btn'",
                        },
                        {
                          name: "data-cy",
                          rawName: "v-data-cy",
                          value: "cancel-btn",
                          expression: "'cancel-btn'",
                        },
                      ],
                      attrs: { variant: "secondary" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.cancel()
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\tCancel\n\t\t\t")]
                  ),
                  _vm._v(" "),
                  _c(
                    "OrigamiButton",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "create-btn",
                          expression: "'create-btn'",
                        },
                        {
                          name: "data-cy",
                          rawName: "v-data-cy",
                          value: "create-btn",
                          expression: "'create-btn'",
                        },
                      ],
                      attrs: { variant: "primary" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.createShift()
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\tSave\n\t\t\t")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.hasAnError
                ? _c("ServiceScheduleTutorErrorMessage", {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "error-message",
                        expression: "'error-message'",
                      },
                    ],
                    attrs: { error: _vm.error },
                  })
                : _vm._e(),
            ],
            1
          )
        : _c("ServiceScheduleTutorSuccessfulAction", {
            attrs: {
              shift: _vm.newShift,
              "success-message-in-sidebar": {
                message: "You have successfully created a new shift.",
                buttonText: "Complete",
              },
            },
            on: {
              "cancel-edit": function ($event) {
                return _vm.cancel()
              },
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }