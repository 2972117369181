import middleware from "@/middleware/index.js";
import isConnected from "@/middleware/isConnected.js";
import hasRole from "@/middleware/hasRole.js";
import initializePendo from "@/middleware/initializePendo.js";
import initializeZendesk from "@/middleware/initializeZendesk.js";
import initFullStory from "@/middleware/initFullStory.js";
import insertRoleRedirect from "@/middleware/insertRoleRedirect.js";
import ShiftTransfers from "@/services/api/ShiftTransfers.js";
import tutorEssayRoutes from "@/modules/TutorEssay/router/index.js";
import tutorReviewCenterRoutes from "@/modules/TutorReviewCenter/router/index.js";
import store from "@/store/index.js";
import { formatDateToTimestamp } from "@/utilities/dateHelpers.js";
import { MICRO_FRONTENDS_NAMES } from "@/modules/MicroFrontend/utilities/index.js";
import redirectWhenFeatureFlagIsDisabled from "@/middleware/redirectWhenFeatureFlagIsDisabled.js";
import redirectWhenFeatureFlagIsEnabled from "@/middleware/redirectWhenFeatureFlagIsEnabled.js";


export default [
	{
		path: "/tutor",
		component: () => import("../pages/tutor/index.vue"),
		beforeEnter: middleware([
			isConnected,
			insertRoleRedirect,
			hasRole("tutor"),
			initializePendo,
			initializeZendesk,
			initFullStory,
			(to, from, next) => {
				store.commit("SET_SHOW_NAVBAR", true);
				next();
			},
		]),
		children: [
			{
				name: "tutorDashboard",
				path: "",
				component: () => import("../pages/tutor/dashboard.vue"),
			},
			{
				name: "tutorSettings",
				path: "settings",
				component: () => import(
					"@/modules/UserSettings/pages/tutor-settings.vue"
				),
			},
			{
				name: "tutorClassroom",
				path: "classroom",
				component: () => import(
					"@/modules/Classroom/pages/tutor.vue"
				),
			},
			{
				name: "tutorMissions",
				path: "missions*",
				component: async() => import("@/modules/MicroFrontend/components/MicroFrontend.vue"),
				beforeEnter: middleware([
					redirectWhenFeatureFlagIsEnabled("CORE-2031-Practice-Center", "/practice"),
				]),
				props: {
					microFrontendName: MICRO_FRONTENDS_NAMES.MATH_MISSIONS,
				},
			},
			{
				path: "practice*",
				name: "tutorPracticeCenter",
				component: () => import("@/modules/MicroFrontend/components/MicroFrontend.vue"),
				beforeEnter: middleware([
					redirectWhenFeatureFlagIsDisabled("CORE-2031-Practice-Center", "/missions"),
				]),
				meta: {
					title: "Practice Center",
				},
				props: {
					microFrontendName: MICRO_FRONTENDS_NAMES.PRACTICE_CENTER,
				},
			},
			{
				name: "tutorScheduleValidateTransfer",
				path: "schedule/:scheduleId/validate-transfer",
				beforeEnter: async(to, from, next) => {
					try {
						const response = await ShiftTransfers.update(to.params.scheduleId, { action: "validate" });
						const startTime = formatDateToTimestamp(new Date(response.data?.shift?.start_time));
						const endTime = formatDateToTimestamp(new Date(response.data?.shift?.end_time));
						const shiftTransferResponse = response.status === 200 ? "success" : "expired";

						return next({
							path: `/tutor?shift-transfer=${shiftTransferResponse}&startTime=${startTime}&endTime=${endTime}`,
							replace: true,
						});
					} catch (e) {
						Sentry.captureException(e);
						let shiftTransferResponse = "unexpected";
						if (e.response.status === 404) {
							shiftTransferResponse = "not-found";
						} else if (e.response.status === 409) {
							shiftTransferResponse = "picked";
						}
						return next(`/tutor?shift-transfer=${shiftTransferResponse}`);
					}
				},
			},
			...tutorEssayRoutes,
			...tutorReviewCenterRoutes,
		],
	},
];
