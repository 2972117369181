<template>
	<OrigamiCard class="tw-p-4 tw-w-80">
		<slot />

		<ol
			v-if="items.length && isExpanded"
			v-jest="'items'"
			class="tw-overflow-y-auto"
			:class="{
				'tw-py-4 tw-pl-2 notification__list--achievement': hasTrophies,
				'notification__list': !hasTrophies,
			}"
		>
			<component
				:is="getItemComponent(item.type)"
				v-for="(item, index) in orderedItems"
				:key="index"
				v-jest="'item'"
				:item="item"
				class="tw-my-4 tw-w-full"
				:class="{
					'activityNotification tw-mt-0': !hasTrophies && !isTopItemChallenge,
					'tw-mt-10': isTopItemChallenge,
					'notification': !isTopItemChallenge,
				}"
			>
				{{ item }}
			</component>
		</ol>

		<footer
			v-if="hasTrophies"
			v-jest="'achievement-footer'"
			class="tw-border-t tw-border-origami-grey-200 tw-pt-4 tw-px-2"
		>
			<div
				v-if="isExpanded"
				class="tw-flex tw-flex-col tw-items-stretch tw-w-full tw-gap-2 tw-text-center tw-flex-wrap"
			>
				<OrigamiButton
					v-if="!isOnAchievementCenter"
					v-jest="'footer-cta-button'"
					v-data-cy="'view-achievements-button'"
					class="notification__button"
					role="link"
					@click.native="$router.push(achievementCenterPath)"
				>
					{{ $t("achievements.view") }}
				</OrigamiButton>
				<OrigamiButton
					v-jest="'footer-close-button'"
					v-data-cy="'footer-close-button'"
					class="notification__button"
					variant="secondary"
					@click.native="$emit('close')"
				>
					{{ $t("achievements.close") }}
				</OrigamiButton>
			</div>
		</footer>
	</OrigamiCard>
</template>

<script>
import { OrigamiCard, OrigamiButton, OrigamiIcon } from "@origami/vue2";

import ChallengeNotification from "./ChallengeNotification.vue";
import CoreActivityNotification from "./CoreActivityNotification.vue";
import TrophyNotification from "./TrophyNotification.vue";

const ITEM_TYPE_COMPONENTS = {
	activity: "CoreActivityNotification",
	challenge: "ChallengeNotification",
	trophy: "TrophyNotification",
};

export default {
	components: {
		OrigamiCard,
		ChallengeNotification,
		CoreActivityNotification,
		TrophyNotification,
		OrigamiButton,
		OrigamiIcon,
	},
	props: {
		items: {
			type: Array,
			default: () => [],
		},
	},
	data: () => ({
		isShowingHeader: true,
		isExpanded: true,
		achievementCenterPath: "/student/achievement-center",
	}),
	computed: {
		trophyCount() {
			return this.items.filter((item) => item.type === "trophy").length;
		},
		hasTrophies() {
			return this.trophyCount > 0;
		},
		isOnAchievementCenter() {
			return this.$route.path === this.achievementCenterPath;
		},
		isTopItemChallenge() {
			return this.orderedItems[0].type === "challenge";
		},
		orderedItems() {
			const sortedItems = [...this.items];
			sortedItems.sort((a, b) => {
				let result = (b.points || 0) - (a.points || 0);
				if (result === 0) {
					const aName = this.$t(`trophies.metadata.${a[a.type].name}.name`);
					const bName = this.$t(`trophies.metadata.${b[b.type].name}.name`);
					result = aName.localeCompare(bName);
				}
				return result;
			});
			return sortedItems;
		},
	},
	methods: {
		getItemComponent(type) {
			return ITEM_TYPE_COMPONENTS[type];
		},
	},
};
</script>

<style scoped>
.notification__list {
	/* screen height - distance from top of screen - distance from button - padding in popover - bottom of screen buffer */
	max-height: calc(100vh - 5rem - 2rem - 2rem - 2rem);
}
.notification__list--achievement {
	/* screen height - distance from top of screen - distance from button - height of header - height of footer - bottom of screen buffer */
	max-height: calc(100vh - 5rem - 2rem - 4.25rem - 5rem - 4rem);
}
.notification:first-child {
	@apply tw-mt-0;
}
.notification:last-child {
	@apply tw-mb-0;
}
.activityNotification:first-child {
	padding-right: 2rem;
}
</style>
<style>
.notification__button span {
	@apply tw-w-full;
}
</style>
