<template>
	<div class="editTutorProfile">
		<div class="editTutorProfile__shiftBlockText">
			<h4 class="tw-text-lg tw-font-bold">
				Availability Blocks
			</h4>
		</div>
		<span
			v-for="(time, index) in formattedBlocks"
			:key="time.block"
			class="editTutorProfile__shiftBlockTimeslot"
		>
			<span class="editTutorProfile__shiftBlockText">Block {{ formatBlockNumber(index) }} </span><span
				class="editTutorProfile__shiftBlockTimeslotText"
			>: {{ time.start }} - {{ time.end }}</span>
		</span>
		<div class="editTutorProfile__Days">
			<div
				v-for="day in weekDays"
				:key="day.value"
				class="editTutorProfile__DaysText"
			>
				{{ day.text }}
			</div>
		</div>
		<div class="editTutorProfile__shiftBlockButtonsContainer">
			<div
				v-for="(day, index) in shiftBlocks"
				:key="index"
				class="editTutorProfile__shiftBlockButtonsContainerDays"
			>
				<BaseButton
					v-for="(block, key, i) in day"
					:key="key"
					v-jest="`shift-block-${block.day}-${block.availability_block_id}`"
					class="editTutorProfile__shiftBlockButtons"
					:type="block.isSelected === true ? 'PRIMARY' : 'CANCEL'"
					:value="block.isSelected"
					@click.prevent.native="updateShiftBlock(block, block.isSelected, $event)"
				>
					<template slot="text">
						Block {{ formatBlockNumber(i) }}
					</template>
				</BaseButton>
			</div>
		</div>
		<div
			v-if="isShowingShiftBlocksSuccess"
			class="alert alert-success"
		>
			Shift blocks were updated!
		</div>
		<div
			v-if="isShowingShiftBlocksError"
			v-jest="'error-banner'"
			class="alert alert-danger"
		>
			There was a problem updating shift blocks. Please try again.
		</div>
	</div>
</template>
<script>
import { format } from "date-fns";
import { mapState } from "vuex";

import BaseButton from "@/components/BaseButton.vue";

export default {
	components: {
		BaseButton,
	},
	data() {
		return {
			days: [],
			selected: [],
			modifiedShiftBlocks: [],
			isUpdated: false,
			value: false,
			weekDays: [
				{ value: 1, text: "Mon" },
				{ value: 2, text: "Tue" },
				{ value: 3, text: "Wed" },
				{ value: 4, text: "Thu" },
				{ value: 5, text: "Fri" },
				{ value: 6, text: "Sat" },
				{ value: 7, text: "Sun" },
			],
			isShowingShiftBlocksSuccess: false,
			isShowingShiftBlocksError: false,
		};
	},
	computed: {
		...mapState(["PlatformManager"]),
		/**
		 * Format blocks
		 * @return Array
		 */
		formattedBlocks() {
			const times = [];
			if (this.shiftBlocks[1] !== undefined) {
				Object.keys(this.shiftBlocks[1]).forEach((day) => {
					times.push({
						block: this.shiftBlocks[1][day].availability_block_id,
						start: format(
							new Date(null, null, null, this.shiftBlocks[1][day].start_time.split(":")[0]),
							"h aaa",
						),
						end: format(
							new Date(null, null, null, this.shiftBlocks[1][day].end_time.split(":")[0]),
							"h aaa",
						),
					});
				});
				return times;
			} else {
				return [];
			}
		},
		shiftBlocks() {
			return this.$store.getters["PlatformManager/TutorsEdit/shiftBlocks"];
		},
	},
	methods: {
		getModifiedShiftBlockLength() {
			return this.modifiedShiftBlocks.length;
		},
		/**
		 * Change button color on select and deselect
		 * @param Object
		 * @param Boolean
		 */
		toggleButtonColor(event, selected) {
			if (selected === true) {
				event.target.parentNode.classList.add("cancel");
				event.target.parentNode.classList.remove("primary");
				event.target.parentNode.value = false;
			} else {
				event.target.parentNode.classList.add("primary");
				event.target.parentNode.classList.remove("cancel");
				event.target.parentNode.value = true;
			}
		},
		/**
		 * Set selected status of block to true if is selected, false otherwise
		 * @param Object
		 * @param Boolean
		 * @param Object
		 */
		updateShiftBlock(block, selected, event) {
			this.isUpdated = true;
			this.pushUniqueBlockModifications(block);
			this.toggleButtonColor(event, selected);
			if (selected === true) {
				block.isSelected = false;
			} else {
				block.isSelected = true;
			}
		},
		pushUniqueBlockModifications(block) {
			const modifiedBlockIndex = this.modifiedShiftBlocks.findIndex((modifiedBlock) =>
				modifiedBlock.availability_block_id === block.availability_block_id &&
						modifiedBlock.day === block.day,
			);
			if (modifiedBlockIndex === -1) {
				this.modifiedShiftBlocks.push(block);
			} else {
				this.modifiedShiftBlocks.splice(modifiedBlockIndex, 1);
				if (this.modifiedShiftBlocks.length === 0) {
					this.isUpdated = false;
				}

			}
		},
		/**
		 * Update tutor's block shift in store and db on save
		 * @param object
		 */
		async saveShiftBlocks() {
			const days = Object.values(this.shiftBlocks);
			const newAvailabilityDays = [];

			days.forEach((day) => {
				const blocks = Object.entries(day);
				blocks.forEach((block) => {
					if (block[1].isSelected === true) {
						newAvailabilityDays.push({
							availability_block_id: block[1].availability_block_id,
							day: block[1].day,
						});
					}
				});
			});
			try {
				await this.$store.dispatch("PlatformManager/TutorsEdit/updateTutorShiftBlocks", {
					shiftBlocks: this.shiftBlocks,
					updatedShiftBlocks: newAvailabilityDays,
					tutorId: this.PlatformManager.TutorsEdit.tutorUserId,
				});
				this.isShowingShiftBlocksError = false;
				this.isShowingShiftBlocksSuccess = true;
				this.modifiedShiftBlocks = [];
				this.isUpdated = false;
			} catch (e) {
				Sentry.captureException(e);
				this.isShowingShiftBlocksSuccess = false;
				this.isShowingShiftBlocksError = true;
			}
		},
		/**
		 * @param Number
		 */
		formatBlockNumber(index) {
			return index + 1;
		},
	},
};
</script>
<style scoped>
.editTutorProfile {
	min-width: 756px;
	padding: 0;
}
.editTutorProfile__shiftBlockTimeslot {
	margin-top: 24px;
	font-size: 12px;
}
.editTutorProfile__shiftBlockText {
	font-weight: bold;
}
.editTutorProfile__shiftBlockTimeslotText {
	margin-right: 16px;
	font-size: 12px;
}
.editTutorProfile__shiftBlockButtonsContainerDays {
	text-align: center;
}
.editTutorProfile__shiftBlockButtonsContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}
.editTutorProfile__shiftBlockButtons {
	display: block;
	margin: 12px 8px;
	border-radius: 5px;
	width: 110px;
}
.editTutorProfile__cancelShiftBlockButton {
	margin-right: 8px;
}
.editTutorProfile__buttonContainer {
	margin-top: 24px;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-end;
}
.editTutorProfile__Days {
	display: flex;
	justify-content: space-around;
	margin-top: 24px;
}
.editTutorProfile__DaysText {
	flex-direction: row;
	font-weight: bold;
	font-size: 16px;
	line-height: normal;
}
</style>
