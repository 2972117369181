//Api services
import AccountApi from "../../../services/api/Account.js";

const getDefaultState = () => ({
	isShowingEmailCard: false,
});

export const state = getDefaultState();
export const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_IS_SHOWING_EMAIL_CARD(state, payload) {
		state.isShowingEmailCard = payload.isShowingEmailCard;
	},
};
export const actions = {
	async sendResetEmail({}, payload) {
		try {
			const response = await AccountApi.sendNewEmailNotification(payload);
			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject(error);
		}
	},
};
export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
