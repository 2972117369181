import api from "./Api.js";

export default {
	/**
	 * creates a message
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	create(data) {
		return api().post(`messages`, data);
	},

	/**
	 * creates a message V2
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	createV2(data) {
		return api().post(`sessions/v1/${data.session_id}/messages/text`, data);
	},

	/**
	 * updates a message
	 * @param {number} messageId
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	update(messageId, data) {
		return api().post(`messages/${messageId}`, data);
	},

	/**
	 * get all unread messages for a session
	 * get a message
	 * @arg {number} sessionId
	 * @arg {number} messageId
	 * @arg {object} params
	 * @returns {AxiosPromise}
	 */
	getUnreadMessages(sessionId, messageId, params) {
		return api().get(`sessions/${sessionId}/messages/${messageId}`, { params });
	},

	/**
	 *
	 * get all messages for a session
	 * @arg {number} sessionId
	 * @arg {object} params
	 * @returns {AxiosPromise}
	 */
	getSessionMessages(sessionId, params) {
		return api().get(`sessions/${sessionId}/messages`, { params });
	},

	/**
	 * Get all messages for activity page
	 * @param {Object} params
	 * @returns {AxiosPromise}
	 */
	getSessionMessagesForActivity(params) {
		return api().get(`/sessions/activity-messages`, { params });
	},

	/**
	 * updates a message is_bookmarked property
	 * @param {number} messageId
	 * @returns {AxiosPromise}
	 */
	bookmarkMessage(messageId) {
		return api().post(`messages/${messageId}/bookmark`);
	},

	/**
	 * updates a message is_bookmarked property
	 * @param {number} messageId
	 * @returns {AxiosPromise}
	 */
	removeMessageBookmark(messageId) {
		return api().delete(`messages/${messageId}/bookmark`);
	},

	/**
	 * soft deletes a message
	 * @param {number} messageId
	 * @returns {AxiosPromise}
	 */
	delete(messageId) {
		return api().delete(`messages/${messageId}`);
	},

	/**
	 * restores a soft deleted message
	 * @param {number} messageId
	 * @returns {AxiosPromise}
	 */
	restore(messageId) {
		return api().post(`messages/${messageId}/restore`);
	},

	/**
	 * quarantines a soft deleted message
	 * @param {number} messageId
	 * @returns {AxiosPromise}
	 */
	quarantine(messageId) {
		return api().post(`messages/${messageId}/quarantine`);
	},

	startConnectionCheck(sessionId, checkId) {
		return api().post(`sessions/${sessionId}/connection_check`, { checkId });
	},
};
