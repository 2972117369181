import api from "./Api.js";

export default {
	/**
	 * Creates session share to allow an (anonymized) session to be shared with others
	 * @apiParam {Number} session_id id of the session to share.
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	create(data) {
		return api().post(`/shared-sessions`, data);
	},

	/**
	 * Get shared session
	 * @param {String} key
	 * @param params
	 * @returns {AxiosPromise}
	 */
	show(key, params = null) {
		return api().get(`/shared-sessions/${key}`, {
			params: params,
		});
	},
};
