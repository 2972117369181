var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "NotificationSnackbar",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "snackbar",
              expression: "'snackbar'",
            },
          ],
          attrs: {
            textcontent: _vm.snackbarText,
            notificationtype: _vm.snackbarType,
          },
          on: {
            "snackbar-close": function ($event) {
              return _vm.$store.dispatch("Snackbar/resetSnackbar")
            },
          },
        },
        [
          _c("template", { slot: "icon" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "icon",
                    expression: "'icon'",
                  },
                ],
              },
              [_vm._v("\n\t\t\t\t" + _vm._s(_vm.snackbarIcon) + "\n\t\t\t")]
            ),
          ]),
          _vm._v(" "),
          _c("template", { slot: "prompt" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "prompt",
                    expression: "'prompt'",
                  },
                ],
              },
              [_vm._v("\n\t\t\t\t×\n\t\t\t")]
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c("TutorEssay"),
      _vm._v(" "),
      _c("SimpleModal", { attrs: { id: "simpleModal" } }),
      _vm._v(" "),
      _vm.featureFlagBmwToastNotifications ? _c("BreaksNotifier") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }