<template>
	<div
		v-jest="'container'"
		class="tw-flex tw-flex-col"
		:class="{ 'tw-justify-center tw-items-center tw-h-full': isLoading }"
	>
		<BaseLoader
			v-if="isLoading"
			v-jest="'loader'"
			class="tw-h-16 tw-w-16 tw-text-blue-regular"
		/>
		<template v-else>
			<p
				v-jest="'send-student-recognition'"
				class="assessmentRecognitionStep__text"
			>
				Send {{ student.first_name }} stickers to recognize this session
			</p>
			<BaseDropdown
				v-jest="'recognitions-dropdown'"
				class="tw-mb-4 tw-h-14"
				aria-label="Selected Recognition"
				:items="recognitionTypes"
			>
				<template #filters>
					{{ baseDropdownPlaceholder }}
				</template>
				<template #default="{ item }">
					<BaseCheckbox
						v-model="item.selected"
						:checkbox-id="`recognition-${item.id}`"
						:checkbox-value="item.id"
					>
						<template slot="text">
							{{ upperFirst(item.name) }}
						</template>
					</BaseCheckbox>
				</template>
			</BaseDropdown>
			<p class="assessmentRecognitionStep__text">
				Add a personalized message (optional)
			</p>
			<BaseTextArea
				v-model="message"
				v-jest="'recognitions-personalized-message'"
				v-data-cy="'recognitions-personalized-message'"
				:placeholder="textAreaPlaceHolder"
				class="tw-h-32"
				:disabled="!selectedRecognitions.length"
			/>
		</template>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { upperFirst } from "lodash";

import { getUserWithRole } from "../../../utilities/user.js";
import { formatListOfStrings } from "../../../utilities/strings.js";
import { showErrorModal } from "../../../utilities/errorHandlingHelpers.js";
import BaseDropdown from "../../../components/BaseDropdown.vue";
import BaseCheckbox from "../../../components/BaseCheckbox.vue";
import BaseTextArea from "../../../components/elements/BaseTextArea.vue";
import BaseLoader from "../../../components/elements/BaseLoader.vue";
import RecognitionAPI from "../services/api/index.js";

export default {
	components: {
		BaseDropdown,
		BaseCheckbox,
		BaseTextArea,
		BaseLoader,
	},
	props: {
		session: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		recognitionTypes: [],
		isLoading: false,
		message: "",
	}),
	computed: {
		...mapState(["Recognition"]),
		student() {
			return getUserWithRole(this.session.users, "student");
		},
		selectedRecognitions() {
			return this.recognitionTypes
				.filter(({ selected }) => selected);
		},
		baseDropdownPlaceholder() {
			const selectedRecognitions = this.selectedRecognitions.map(({ name }) => upperFirst(name));
			return this.selectedRecognitions.length ?
				formatListOfStrings(selectedRecognitions) :
				"Select stickers";
		},
		textAreaPlaceHolder() {
			return !this.selectedRecognitions.length ?
				"Message (Select a sticker first)" :
				"Write a message";
		},
	},
	watch: {
		message(value) {
			this.$emit("input", value);
		},
		selectedRecognitions(value) {
			this.$emit("recognition-select", value.map(({ id }) => id));
		},
	},
	async created() {
		try {
			this.$emit("recognition-types-loading");
			this.isLoading = true;
			const recognitionsSubmittedByMe = await RecognitionAPI.check(this.session.id);
			if (recognitionsSubmittedByMe?.data?.data?.length) {
				this.$emit("skip");
				return;
			}
			await this.$store.dispatch("Recognition/getActiveRecognitionTypes");
			this.recognitionTypes = this.Recognition.recognitionTypes.map((item) => ({ ...item, selected: false }));
		} catch (e) {
			Sentry.captureException(e);
			showErrorModal(this);
		} finally {
			this.isLoading = false;
			this.$emit("recognition-types-loaded");
		}
	},
	methods: {
		upperFirst,
	},
};
</script>
<style scoped>
.assessmentRecognitionStep__text {
	@apply tw-text-base tw-font-bold tw-mb-4;
}
</style>
