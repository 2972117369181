<template>
	<div>
		<ServiceDashboardTutorCardHeaderEssayTutor
			:user="user"
			:shift="shift"
		/>
		<ServiceDashboardListEssay
			:resources="essays"
			:total-essays="totalEssays"
			:time-since-last-activity="cardData.last_essay_reviewed_at"
		/>
	</div>
</template>

<script>
import ServiceDashboardTutorCardHeaderEssayTutor from "./ServiceDashboardTutorCardHeaderEssayTutor.vue";
import ServiceDashboardListEssay from "./ServiceDashboardListEssay.vue";

export default {
	components: {
		ServiceDashboardListEssay,
		ServiceDashboardTutorCardHeaderEssayTutor,
	},
	props: {
		cardData: {
			type: Object,
			required: true,
			validator(value) {
				return (
					value.hasOwnProperty("essays") &&
					value.hasOwnProperty("user") &&
					value.hasOwnProperty("shift") &&
					value.hasOwnProperty("total_essays") &&
					value.hasOwnProperty("last_essay_reviewed_at")
				);
			},
		},
	},
	computed: {
		/**
		 * @returns {Array}
		 */
		essays() {
			let essays = [];
			if (this.cardData.essays instanceof Array) {
				essays = this.cardData.essays;
			}
			return essays;
		},
		/**
		 * @returns {Object}
		 */
		shift() {
			return this.cardData.shift;
		},
		/**
		 * @returns {Object}
		 */
		user() {
			return this.cardData.user;
		},
		totalEssays() {
			return this.cardData.total_essays;
		},
	},
};
</script>
