var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.SuspensePattern,
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "suspense",
          expression: "'suspense'",
        },
      ],
      attrs: { "is-showing-fallback": _setup.isLoadingChallenges },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "challenges-content",
              expression: "'challenges-content'",
            },
          ],
          staticClass: "tw-text-center",
        },
        [
          _c(
            _setup.BaseCircle,
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "content-circle-chart",
                  expression: "'content-circle-chart'",
                },
              ],
              staticClass: "tw-h-32",
              attrs: {
                progress: _setup.challengeProgress,
                total: _setup.challengeTotal,
                thickness: 80,
              },
            },
            [
              _c(_setup.Avatar, {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "circle-chart-avatar",
                    expression: "'circle-chart-avatar'",
                  },
                ],
                attrs: { user: _setup.userData, size: 80 },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "tw-mt-1" }, [
            _c(
              "h3",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "content-title",
                    expression: "'content-title'",
                  },
                ],
                staticClass: "tw-font-bold",
              },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_setup.i18n.t("challenges.title")) +
                    "\n\t\t\t"
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "content-text",
                    expression: "'content-text'",
                  },
                ],
                staticClass: "tw-text-sm tw-italic tw-mb-2",
              },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(
                      _setup.i18n.t(`challenges.time_left`, {
                        days: _setup.remainingDays,
                        days_plural: _setup.remainingDays > 1 ? "s" : "",
                      })
                    ) +
                    "\n\t\t\t"
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "content-goals-list",
                  expression: "'content-goals-list'",
                },
              ],
              staticClass:
                "tw-flex tw-flex-col tw-items-center tw-justify-center",
            },
            _vm._l(_setup.goals, function (goal, index) {
              return _c(
                "li",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: `goals-list-goal-${index}`,
                      expression: "`goals-list-goal-${index}`",
                    },
                  ],
                  key: goal.type,
                  staticClass: "tw-flex tw-flex-row mb-1",
                },
                [
                  _c("p", { staticClass: "tw-text-sm tw-text-center" }, [
                    _c("span", { staticClass: "tw-font-bold" }, [
                      _vm._v(_vm._s(_setup.i18n.t("goals.of", goal))),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "tw-capitalize" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(
                            _setup.i18n.t(_setup.GOAL_TYPE_TEXT[goal.type])
                          ) +
                          "\n\t\t\t\t\t"
                      ),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }