import middleware from "@/middleware/index.js";
import isConnected from "@/middleware/isConnected.js";
import redirectWhenFeatureFlagIsDisabled from "@/middleware/redirectWhenFeatureFlagIsDisabled.js";
import redirectIfNotPopupFromRoute from "@/middleware/redirectIfNotPopupFromRoute.js";

export default [
	{
		name: "video-player",
		path: "/video-player",
		component: () => import(
			"../pages/video-player.vue"
		),
		beforeEnter: middleware([
			isConnected,
			redirectWhenFeatureFlagIsDisabled("PE-16098-paper-clips", "/student/classroom"),
			redirectIfNotPopupFromRoute(["/embedded/student/classroom"], "/student/classroom"),
		]),
	},
];
