function QuestionMatcherMessage({ message, id }) {
	this.id = id;
	this.message = {
		id: id,
		message: getMessage(message),
		isQuestion: !isMessageAFile(message),
		text: getText(message),
	};
}

/**
 * @param {String} message
 * @returns {Boolean}
 */
function isMessageAFile(message) {
	return message.substring(19, 27) === `:"file"}`;
}

/**
 * @param {String} message
 * @returns {String}
 */
function getText(message) {
	const endOfTextIndex = message.length - 2;
	return message.substring(21, endOfTextIndex);
}

/**
 * @param {String} message
 * @returns {String}
 */
function getMessage(message) {
	const msg = { question_matcher: getText(message) };
	return msg;
}

export default QuestionMatcherMessage;
