import middleware from "@/middleware/index.js";
import isConnected from "@/middleware/isConnected.js";
import hasRole from "@/middleware/hasRole.js";
import initializePendo from "@/middleware/initializePendo.js";
import initializeZendesk from "@/middleware/initializeZendesk.js";
import initFullStory from "@/middleware/initFullStory.js";
import { MICRO_FRONTENDS_NAMES } from "@/modules/MicroFrontend/utilities/index.js";
import store from "@/store/index.js";

export default [
	{
		path: "/tutor-manager",
		component: () => import("../pages/tutor-manager/index.vue"),
		beforeEnter: middleware([
			isConnected,
			hasRole("tutor-manager"),
			initializePendo,
			initializeZendesk,
			initFullStory,
			(to, from, next) => {
				store.commit("SET_SHOW_NAVBAR", true);
				next();
			},
		]),
		children: [
			{
				path: "",
				redirect: "services",
			},
			{
				name: "tutorManagerActivity",
				path: "activity",
				component: () => import("@/modules/Activity/pages/tutor-manager-activity.vue"),
			},
			{
				name: "tutorManagerActivityDashboard",
				path: "activity_mfe",
				component: () => import("@/modules/MicroFrontend/components/MicroFrontend.vue"),
				props: {
					microFrontendName: MICRO_FRONTENDS_NAMES.ACTIVITY_DASHBOARD,
				},
			},
			{
				path: "services",
				component: () => import("../pages/tutor-manager/services/index.vue"),
				children: [
					{
						path: "",
						redirect: "summary",
					},
					{
						path: "summary",
						component: () => import("../pages/tutor-manager/services/summary.vue"),
					},
					{
						path: "tutors",
						component: () => import("../pages/tutor-manager/services/tutors/index.vue"),
						children: [
							{
								path: "",
								redirect: "list",
							},
							{
								path: "edit/:tutorId",
								component: () => import("../pages/tutor-manager/services/tutors/_tutorId.vue"),
							},
							{
								path: "list",
								component: () => import("../pages/tutor-manager/services/tutors/list.vue"),
							},
							{
								path: "create",
								component: () => import("../pages/tutor-manager/services/tutors/create.vue"),
							},
						],
					},
					{
						path: "schedule",
						component: () => import(
							"../pages/tutor-manager/services/schedule/index.vue"
						),
						children: [
							{
								path: "",
								redirect: "shift-schedule",
							},
							{
								name: "tutorManagerScheduleShiftSchedule",
								path: "shift-schedule",
								component: () => import(
									"../pages/tutor-manager/services/schedule/shift-schedule.vue"
								),
							},
							{
								name: "tutorManagerScheduleBreaksAbsences",
								path: "biobreaks-absences",
								component: () => import(
									"../pages/tutor-manager/services/schedule/biobreaks-absences.vue"
								),
							},
						],
					},
					{
						path: "breaks-center",
						component: () => import(
							"../pages/tutor-manager/services/breaks-center/index.vue"
						),
						children: [
							{
								path: "",
								redirect: "meal-breaks",
							},
							{
								name: "tutorManagerBreaksCenterMealBreaks",
								path: "meal-breaks",
								component: () => import(
									"../pages/tutor-manager/services/breaks-center/meal-breaks.vue"
								),
							},
							{
								name: "tutorManagerBreaksCenterRestBreaks",
								path: "rest-breaks",
								component: () => import(
									"../pages/tutor-manager/services/breaks-center/rest-breaks.vue"
								),
							},
							{
								name: "tutorManagerBreaksCenterBioBreaks",
								path: "bio-breaks",
								component: () => import(
									"../pages/tutor-manager/services/breaks-center/bio-breaks.vue"
								),
							},
						],
					},
					{
						path: "essays-queue",
						component: () => import(
							"../pages/tutor-manager/services/essays.vue"
						),
					},
					{
						path: "action-center",
						component: () => import(
							"../pages/tutor-manager/services/action-center/index.vue"
						),
						children: [
							{
								path: "",
								redirect: "classroom",
							},
							{
								name: "tutorManagerActionCenterClassroom",
								path: "classroom",
								component: () => import(
									"../pages/tutor-manager/services/action-center/classroom.vue"
								),
							},
							{
								name: "tutorManagerActionCenterCoverage",
								path: "coverage",
								component: () => import(
									"../pages/tutor-manager/services/action-center/coverage.vue"
								),
							},
							{
								name: "tutorManagerActionCenterEssayReview",
								path: "essay-review",
								component: () => import(
									"../pages/tutor-manager/services/action-center/essay-review.vue"
								),
							},
						],
					},
					{
						path: "analytics",
						component: () => import(
							"../pages/tutor-manager/services/analytics.vue"
						),
					},
				],
			},
			{
				path: "settings",
				component: () => import(
					"@/modules/UserSettings/pages/platform-manager-settings.vue"
				),
			},
		],
	},
];
