<template>
	<TutorBreakCard
		:break="upcomingBreak"
		:is-loading-state="isLoadingState"
	>
		<template #metaDataIcon>
			<component
				:is="getScheduleTypeId()"
				icon-color="currentColor"
				class="tw-mr-2 tw-align-middle tw-text-black-high"
			/>
		</template>
		<template #metadata>
			{{ getTimeRange(upcomingBreak.start_time, upcomingBreak.end_time) }}
			<div
				v-if="upcomingBreak.punch_in"
				v-jest="'elapsed-time'"
				class="tw-inline-block"
			>
				<span class="tw-mx-1">|</span> {{ getTimeElapsed() }} elapsed
			</div>
		</template>
		<template #breakDetailsIcon>
			<OrigamiIcon :name="detailsIcon" />
		</template>
		<template #breakDetails>
			{{ getTimeRange(upcomingBreak.break.start_time, upcomingBreak.break.end_time) }}
		</template>
		<template #buttons>
			<BaseButton
				v-jest="'cancel-break'"
				:variants="['outline', 'mini']"
				class="tw-mr-2"
				:disabled="isLoadingState || !canCancelBreak"
				@click.native="cancelUpcomingBreak"
			>
				Cancel Break
			</BaseButton>
		</template>
	</TutorBreakCard>
</template>

<script>
import { isBefore, subMinutes } from "date-fns";
import { mapState } from "vuex";
import { OrigamiIcon } from "@origami/vue2";

import {
	UPCOMING_BREAK_THRESHOLD_MINUTES,
} from "@/modules/BreaksManagementWidget/utilities/BreakConfiguration.js";

import IconSession from "../../../components/icons/IconSession.vue";
import IconEssay from "../../../components/icons/IconEssay.vue";
import BaseButton from "../../../components/elements/BaseButton.vue";
import { formatTimestampToHM, formatTimestampToDate } from "../../../utilities/dateHelpers.js";
import { getTimeElapsedForShiftString } from "../../../utilities/ScheduleHelpers.js";
import { getErrorText } from "../../../utilities/errorHandlingHelpers.js";
import SchedulesAPI from "../../../services/api/Schedules.js";
import { SHIFT_TYPE_ID } from "../utilities/ShiftTypeIDs.js";

import TutorBreakCard from "./TutorBreakCard.vue";

export default {
	components: {
		TutorBreakCard,
		IconSession,
		IconEssay,
		BaseButton,
		OrigamiIcon,
	},
	props: {
		upcomingBreak: {
			type: Object,
			required: true,
		},
		detailsIcon: {
			type: String,
			default: "break",
		},
	},
	data() {
		return {
			isLoadingState: false,
		};
	},
	computed: {
		...mapState(["BreaksCenter"]),
		canCancelBreak() {
			const transitionStartTime = subMinutes(
				formatTimestampToDate(this.upcomingBreak?.break?.start_time),
				UPCOMING_BREAK_THRESHOLD_MINUTES,
			);
			return isBefore(formatTimestampToDate(this.BreaksCenter.currentTime), transitionStartTime);
		},
	},
	methods: {
		getScheduleTypeId() {
			return this.upcomingBreak.scheduleType.data.id === SHIFT_TYPE_ID.CLASSROOM ? "IconSession" : "IconEssay";
		},
		getTimeElapsed() {
			return getTimeElapsedForShiftString(this.upcomingBreak.punch_in);
		},
		getTimeRange(start_time, end_time) {
			return `${formatTimestampToHM(start_time)} - ${formatTimestampToHM(end_time)}`;
		},
		async cancelUpcomingBreak() {
			try {
				this.isLoadingState = true;
				await SchedulesAPI.cancelBreak(this.upcomingBreak.break.id);
			} catch (e) {
				Sentry.captureException(e);
			} finally {
				this.isLoadingState = false;
			}
		},
		handleError(errorText) {
			const errorMessage = getErrorText(errorText);
			const modalConfig = {
				title: "Whoops!",
				message: errorMessage,
				buttonText: "Ok",
				eventFired: null,
			};
			this.$store.dispatch("SimpleModal/configureSimpleModal", modalConfig);
			this.$bvModal.show("simpleModal");
		},
	},
};
</script>
