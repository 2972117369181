var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DynamicScrollerItem",
    {
      staticClass: "tw-flex tw-items-center tw-justify-center tw-py-2",
      attrs: {
        item: _vm.recognition,
        "size-dependencies": [_vm.text],
        active: _vm.active,
        "data-index": _vm.index,
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "tw-inline-flex tw-items-center tw-bg-origami-white tw-border tw-border-origami-grey-200 tw-shadow-elevation-2 tw-rounded-2xl tw-gap-2 tw-p-2 tw-mx-auto",
        },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "icon-container",
                  expression: "'icon-container'",
                },
              ],
              staticClass:
                "tw-inline-flex tw-items-center tw-justify-center tw-flex-shrink-0 tw-w-10 tw-h-10 tw-bg-origami-blue-300 tw-text-lg tw-rounded-full",
            },
            [
              _c("Emoji", {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "emoji-icon",
                    expression: "'emoji-icon'",
                  },
                ],
                attrs: { emoji: _vm.iconComponent, "aria-hidden": "true" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "recognition-text",
                  expression: "'recognition-text'",
                },
              ],
              staticClass:
                "tw-flex-auto tw-overflow-hidden tw-overflow-ellipsis tw-mr-2",
            },
            [_vm._v("\n\t\t\t" + _vm._s(_vm.text) + "\n\t\t")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }