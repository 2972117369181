import jsCookie from "js-cookie";
import axios from "axios";

import { logout } from "@/services/root.js";

const apiClient = axios.create({
	baseURL: process.env.MIX_GRADESLAM_API_URL,
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
});

export default async function(to, from, next) {
	try {
		const accessToken = jsCookie.get("access_token");
		if (!accessToken) {
			throw new Error("no access token");
		}

		apiClient.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

		const { data } = await apiClient.post("/oauth2/authorize", to.query);

		window.location.href = data.redirect_uri;

		return next();
	} catch (e) {
		Sentry.captureException(e);

		await logout(to);
	}
}

