<script setup>
import { computed } from "vue";

import ChartBar from "@/components/patterns/ChartBar.vue";
import useStore from "@/composables/useStore.js";
import { formatPlurality } from "@/utilities/strings.js";

const store = useStore();

const MAX_LOAD_COUNT = 5;

const loadCount = computed(() => {
	const sessionLoadCount = store.getters["PlatformManager/ServiceSummaryDashboard/sessionLoadCount"];

	const loadData = Object.keys(sessionLoadCount).map((count) => ({
		label: {
			tick: count >= MAX_LOAD_COUNT ? `${MAX_LOAD_COUNT}+` : count,
			title: formatPlurality(count, "Session"),
			content: formatPlurality(sessionLoadCount[count], "Tutor"),
		},
		value: sessionLoadCount[count],
	}));

	return loadData;
});

const xAxes = () => ({
	grid: {
		display: false,
	},
	title: {
		display: true,
		text: "Number of Sessions",
	},
});

const yAxes = computed(() => ({
	title: {
		display: true,
		text: "Tutors",
	},
	ticks: {
		precision: 0,
	},
	beginAtZero: true,
	suggestedMax: store.state.PlatformManager.ServiceSummaryDashboard.liveTutors.length,
}));
</script>

<template>
	<div class="tw-h-80">
		<ChartBar
			v-jest="'bar-graph'"
			:chart-data="loadCount"
			:bar-chart-options="{
				maxBarThickness: 120,
			}"
			:y-axes="yAxes"
			:x-axes="xAxes()"
			:color="'#6E6FEC'"
		/>
	</div>
</template>
