<template>
	<div>
		<BreaksNotification
			v-jest="'meal-break-late-notification'"
			:notification-type="notificationType"
			class="tw-mb-4"
		>
			<p
				v-if="timeText"
				v-jest="'meal-break-late-time'"
				class="tw-font-bold tw-text-sm"
			>
				{{ timeText }}
			</p>
			<p
				v-jest="'meal-break-late-message'"
				class="tw-text-sm"
			>
				{{ messageText }}
			</p>
		</BreaksNotification>
		<OrigamiButton
			v-if="!pastLateThreshold"
			v-jest="'meal-break-end-button'"
			variant="primary"
			class="tw-w-full tw-justify-center"
			:is-loading="$store.state.BreaksManagementWidget.isRequestLoading"
			@click.native="endMealBreak"
		>
			End break
		</OrigamiButton>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { differenceInMinutes } from "date-fns";
import { OrigamiButton } from "@origami/vue2";

import { formatPlurality } from "@/utilities/strings.js";

import BreaksNotification from "../templates/BreaksNotification.vue";
import {
	BREAK_VERY_LATE_THRESHOLD_MINUTES,
	MEAL_BREAK_LENGTH_MINUTES,
} from "../../utilities/BreakConfiguration.js";

export default {
	components: {
		BreaksNotification,
		OrigamiButton,
	},
	computed: {
		...mapState("BreaksManagementWidget", ["currentTime"]),
		timeText() {
			if (this.minutesLate === 0) {
				return "Your meal break has ended.";
			}

			const lateBreak = this.$store.getters["TutorSchedule/getLateMealBreak"];
			const minutesLate = differenceInMinutes(this.currentTime, new Date(lateBreak.end_time));
			return minutesLate > 0 ? `${formatPlurality(minutesLate, "min")} late` : "";
		},
		messageText() {
			if (this.minutesLate === 0) {
				return "Please end your break now.";
			}

			return !this.pastLateThreshold ?
				`You are over the ${MEAL_BREAK_LENGTH_MINUTES} min limit of your break, please end your break now.` :
				"You are currently way over the time limit of your break, please contact a Platform Manager as soon as possible.";
		},
		currentSegment() {
			return this.$store.getters["TutorSchedule/getCurrentShiftSegment"];
		},
		pastLateThreshold() {
			return this.minutesLate >= BREAK_VERY_LATE_THRESHOLD_MINUTES;
		},
		minutesLate() {
			return differenceInMinutes(this.currentTime, new Date(this.currentSegment.start_time));
		},
		notificationType() {
			return this.minutesLate > 0 ? "error" : "warning";
		},
	},
	methods: {
		endMealBreak() {
			this.$store.dispatch("BreaksManagementWidget/endMealBreak");
		},
	},
};
</script>
