var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tw-my-4", class: { embedded: _vm.embedded } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isLoadingGoogleFile,
              expression: "isLoadingGoogleFile",
            },
          ],
          staticClass:
            "tw-flex tw-justify-center tw-items-center tw-w-full tw-h-52 tw-bg-grey-pale tw-border-dashed tw-border-origami-grey-300 tw-border-2 tw-mb-6 tw-flex tw-flex-col",
        },
        [
          _c(
            "BaseLoader",
            { staticClass: "tw-h-20 tw-w-20 tw-text-blue-regular" },
            [
              _c("IconEssay", {
                staticClass: "tw-w-5 tw-h-7",
                attrs: {
                  "icon-color": "currentColor",
                  "aria-hidden": "true",
                  focusable: "false",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "tw-mt-4 tw-font-bold" }, [
            _vm._v(_vm._s(_vm.$t("processingFile"))),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("FileDropZone", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoadingGoogleFile,
            expression: "!isLoadingGoogleFile",
          },
        ],
        staticClass:
          "tw-flex tw-flex-col tw-items-center tw-w-full tw-px-4 tw-py-12 tw-justify-center",
        attrs: { clickable: "", "dropzone-click-disabled": "" },
        on: {
          change: function ($event) {
            return _vm.$emit("handle-essay-file-input", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ trigger, isDraggingOver, inputZIndex }) {
              return [
                _c("div", { staticClass: "tw-font-bold" }, [
                  _vm._v(
                    "\n\t\t\t\t" + _vm._s(_vm.$t("dropHere")) + "\n\t\t\t"
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "dropArea__text--large dropArea__text--lightGrey tw-pb-3",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.$t("orChooseAFileToUpload")) +
                        "\n\t\t\t"
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tw-grid tw-grid-cols-1 sm:tw-grid-cols-2" },
                  [
                    _c(
                      "BaseButton",
                      {
                        staticClass: "tw-relative tw-m-1",
                        style: {
                          zIndex: isDraggingOver ? "auto" : inputZIndex + 1,
                        },
                        attrs: { id: "uploadButton" },
                        nativeOn: {
                          click: function ($event) {
                            return trigger.apply(null, arguments)
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepend",
                              fn: function () {
                                return [
                                  _c("IconComputer", {
                                    staticClass: "tw-mr-2",
                                    attrs: {
                                      "aria-hidden": "true",
                                      focusable: "false",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.$t("browseFiles")) +
                            "\n\t\t\t\t"
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("GooglePicker", {
                      staticClass: "tw-relative tw-m-1",
                      style: {
                        zIndex: isDraggingOver ? "auto" : inputZIndex + 1,
                      },
                      attrs: {
                        "doc-validator": _vm.docValidator,
                        "mime-types": _vm.mimeTypes,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit(
                            "handle-essay-file-input-from-google-drive",
                            $event
                          )
                        },
                        error: function ($event) {
                          return _vm.$emit("error", $event)
                        },
                        "change-start": function ($event) {
                          _vm.isLoadingGoogleFile = true
                        },
                        "change-end": function ($event) {
                          _vm.isLoadingGoogleFile = false
                        },
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.stopPropagation()
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({
                              trigger: triggerGooglePicker,
                              isLoaded,
                            }) {
                              return [
                                _c(
                                  "BaseButton",
                                  {
                                    attrs: { disabled: !isLoaded },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.openGooglePicker(
                                          triggerGooglePicker
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "prepend",
                                          fn: function () {
                                            return [
                                              _c("IconGoogleDrive", {
                                                staticClass: "tw-mr-2",
                                                attrs: {
                                                  "aria-hidden": "true",
                                                  focusable: "false",
                                                },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.$t("googleDrive")) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tw-text-center tw-text-sm tw-mt-1" },
                  [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.$t("supportedFiles")) +
                        "\n\t\t\t"
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "input",
            fn: function ({ bind, on }) {
              return [
                _c(
                  "input",
                  _vm._g(
                    _vm._b(
                      {
                        directives: [
                          {
                            name: "data-cy",
                            rawName: "v-data-cy",
                            value: "essay-file-instruction-step",
                            expression: "'essay-file-instruction-step'",
                          },
                        ],
                        attrs: { accept: _vm.mimeTypes.join() },
                      },
                      "input",
                      bind,
                      false
                    ),
                    on
                  )
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.fileUploadErrorMessage,
              expression: "fileUploadErrorMessage",
            },
          ],
          staticClass:
            "tw-text-sm tw-font-bold tw-text-origami-red-400 tw-mt-4",
          class: { "tw-px-3": _vm.embedded },
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.fileUploadErrorMessage) + "\n\t")]
      ),
      _vm._v(" "),
      _vm.embedded
        ? _c(
            "a",
            {
              staticClass:
                "tw-text-blue-regular tw-text-center tw-block tw-font-bold tw-my-4",
              attrs: { target: "_blank", href: "/student/review-center" },
            },
            [_vm._v(_vm._s(_vm.$t("viewAllSubmissions")))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }