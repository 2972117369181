var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseLink",
    {
      staticClass:
        "tw-inline-block tw-my-4 tw-text-blue-regular tw-text-sm tw-font-bold hover:tw-text-blue-regular tw-relative tw-left-1/2 tw-transform tw--translate-x-1/2",
      attrs: { to: "https://paper.co/privacy-policy-services", target: true },
    },
    [_vm._v("\n\t" + _vm._s(_vm.$t("privacy_policy")) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }