import api from "@/services/api/Api.js";

export default {
	/**
	* Get filters (schools/grades) data
	* @param {object} params
	* @returns {AxiosPromise}
    */
	getFilters(params) {
		return api()
			.get("admin/dashboard/filter-data", { params })
			.then(({ data: { data } }) => data)
			.then((data) => ({
				...data,
				gradeLevels: data.grade_levels,
			}));
	},
	/**
	* Get (filtered) quick start for a site administrator
	* @param {object} params
	* @returns {AxiosPromise}
	*/
	getQuickStats(params) {
		return api()
			.get("/admin/dashboard/widgets/quick-stats", { params })
			.then(({ data }) => data["quick-stats"])
			.then((data) => ({
				...data,
				tutoring_sessions: data.chat_sessions,
			}));
	},
	/**
	* Get (filtered) total usage over time
	* @param {object} params
	* @returns {AxiosPromise}
	*/
	getTotalUsageOverTime(params) {
		return api()
			.get("/admin/dashboard/widgets/total-usage-over-time", { params })
			.then(({ data }) => data["total-usage-over-time"]);
	},
	/**
	* Get (filtered) usage by school
	* @param {object} params
	* @returns {AxiosPromise}
	*/
	getUsageBySchool(params) {
		return api()
			.get("/admin/dashboard/widgets/usage-by-school", { params })
			.then(({ data }) => data["usage-by-school"]);
	},
	/**
	* Get (filtered) topics requested by students
	* @param {object} params
	* @returns {AxiosPromise}
	*/
	getMostRequestedTopics(params) {
		return api()
			.get("/admin/dashboard/widgets/sessions-and-topics-tutor-assignment", { params })
			.then(({ data }) => data["sessions-and-topics-tutor-assignment"]);
	},
};
