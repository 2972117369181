<template>
	<BreakButton
		v-jest="'bio-break-button'"
		v-data-cy="'bio-break-button'"
		aria-label="Go on bio break"
		icon-name="bio-break"
		:is-active="isOnBioBreakWorkflow"
		:disabled="isDisabled"
		@click.native="requestBioBreak"
	/>
</template>

<script>
import { mapState } from "vuex";

import BreakButton from "../elements/BreakButton.vue";

export default {
	components: {
		BreakButton,
	},
	data() {
		return {
			featureFlagActiveClassroomBioBeakIsEnabled: false,
		};
	},
	computed: {
		...mapState(["BreaksManagementWidget"]),
		isDisabled() {
			if (this.isBioBreakInProgress) {
				return false;
			}

			return this.isRequestingRestBreak ||
				this.isRequestingMealBreak ||
				this.isOnAnotherBreak ||
				this.isInBioBreakTransitionPeriod ||
				!this.isBioBreakEligible;
		},
		isInBioBreakTransitionPeriod() {
			return this.$store.getters["BreaksManagementWidget/isInBioBreakTransitionPeriod"];
		},
		isBioBreakEligible() {
			return this.$store.getters["BreaksManagementWidget/isBioBreakEligible"];
		},
		isOnBioBreakWorkflow() {
			return this.$store.getters["BreaksManagementWidget/isOnBioBreakWorkflow"];
		},
		hasActiveMealBreak() {
			return this.$store.getters["BreaksManagementWidget/hasActiveMealBreak"];
		},
		hasActiveRestBreak() {
			return this.$store.getters["BreaksManagementWidget/hasActiveRestBreak"];
		},
		isOnAnotherBreak() {
			return this.hasActiveRestBreak || this.hasActiveMealBreak;
		},
		isRequestingMealBreak() {
			return this.$store.getters["BreaksManagementWidget/isRequestingMealBreak"];
		},
		isRequestingRestBreak() {
			return this.$store.getters["BreaksManagementWidget/isRequestingRestBreak"];
		},
		isBioBreakInProgress() {
			return this.$store.getters["BreaksManagementWidget/isBioBreakInProgress"];
		},
	},
	methods: {
		requestBioBreak() {
			if (!this.isOnBioBreakWorkflow) {
				this.$store.dispatch("BreaksManagementWidget/requestBioBreakInActiveClassroom");
			}
		},
	},
};
</script>
