var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "339",
        height: "439",
        viewBox: "0 0 339 439",
      },
    },
    [
      _c("defs", [
        _c("path", {
          attrs: {
            id: `${_vm.randomId}_a`,
            d: "M0.26 0.101L38.123 0.101 38.123 431.678 0.26 431.678z",
          },
        }),
      ]),
      _vm._v(" "),
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            fill: "#FFF",
            d: "M.5 7.167L18.98 436 45.5 434.833 27.02 6zM312.5 432L339.5 432 339.5 2 312.5 2z",
            transform: "translate(-.5)",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            fill: "#181818",
            d: "M136.47 245.953h.03V177h-.03v68.953zM109.5 259h.03v-32.8h-.03V259zm0-79.037V209.8l.03-.03v-29.838l-.03.03z",
            transform: "translate(-.5)",
          },
        }),
        _vm._v(" "),
        _c("g", [
          _c("path", {
            attrs: {
              fill: "#FFF",
              d: "M63.31 225.208l-1.828-.018.163-16.432c1.773-2.139 4.123-5.003 6.458-7.93-1.011 5.26-2.14 10.396-2.644 12.906l-2.15 11.474zM90.625 4.143L88.923 176.249 64.515 176.008 61.941 178.916 63.672 3.877zM85.036 245.179L88.241 245.21 86.361 435.157 59.408 434.89 61.158 257.994 82.631 258.207z",
              transform: "translate(-.5) translate(29.5)",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#0E0FE0",
              d: "M88.923 176.249l-.683 68.962-3.204-.033-2.405 13.029-21.473-.211.325-32.805 1.827.016 2.15-11.473c.503-2.509 1.633-7.647 2.643-12.906-2.336 2.926-4.685 5.79-6.458 7.93l.295-29.842 2.575-2.908 24.408.24z",
              transform: "translate(-.5) translate(29.5)",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#FFF",
              d: "M60.114 4.971L59.294 180.047 32.193 211.005 33.159 4.846zM57.768 211.56c.406-.478.873-1.044 1.385-1.67l-.076 16.404-6.889-.033c-1.557-.007-4.432-.02-7.667.084l6.984-7.63 6.263-7.155zM54.91 259.081L58.923 259.099 58.093 436.001 31.138 435.875 32.029 245.923 57.247 246.041z",
              transform: "translate(-.5) translate(29.5)",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#0E0FE0",
              d: "M51.505 218.715l-6.984 7.63c3.235-.105 6.111-.09 7.669-.084l6.887.033-.153 32.805-4.015-.018 2.338-13.04-25.217-.119.163-34.917 27.1-30.958-.14 29.843c-.51.626-.978 1.192-1.385 1.67l-6.263 7.155z",
              transform: "translate(-.5) translate(29.5)",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#FFF",
              d: "M24.198 221.128L19.196 245.061 32.907 245.455 27.455 435.331 0.512 434.558 12.882 3.702 39.827 4.474 33.909 210.553z",
              transform: "translate(-.5) translate(29.5)",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#0E0FE0",
              d: "M33.91 210.553L32.908 245.454 19.197 245.062 24.198 221.128z",
              transform: "translate(-.5) translate(29.5)",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#181818",
              d: "M132.902 192.217v60c.015.017.03.03.045.03v-60.105c-.03.03-.045.045-.045.075zm53.985-13.365v68.591l.03-.03v-68.546c-.015-.015-.015-.03-.03-.015zm-26.985 61.138v20.055c.015.015.015.015.03 0V239.99h-.03zm0-65.299v21.881c0-.015.015-.015.03-.03v-21.866c-.015 0-.03.015-.03.015z",
              transform: "translate(-.5) translate(29.5)",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#FFF",
              d: "M142.659 238.43l2.036-43.4c1.62-1.617 3.52-2.187 5.541-2.092 4.067.19 6.612 2.227 6.291 9.045-.308 6.577-3.772 24.156-7.345 31.3-1.743 3.588-3.666 4.966-6.523 5.147M180.648 3.96l-8.198 174.662c-4.836-3.328-11.113-5.301-18.878-5.665-2.738-.128-5.35-.042-7.852.23L153.723 2.7l26.925 1.262zM169.234 247.137l-8.795 187.385-26.925-1.264 8.204-174.796c11.209-.223 20.475-4.403 27.516-11.325",
              transform: "translate(-.5) translate(29.5)",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#0E0FE0",
              d: "M172.45 178.622l-3.215 68.515c-7.043 6.922-16.308 11.102-27.517 11.326l.94-20.032c2.858-.181 4.78-1.56 6.524-5.149 3.573-7.143 7.037-24.721 7.345-31.3.32-6.816-2.224-8.853-6.29-9.045-2.021-.094-3.921.476-5.542 2.092l1.025-21.84c2.502-.274 5.114-.36 7.853-.232 7.763.364 14.042 2.336 18.877 5.665",
              transform: "translate(-.5) translate(29.5)",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#FFF",
              d: "M142.666 1.28v170.692c-11.86 1.9-20.905 8.186-26.955 17.45V1.28h26.955zM140.705 196.981c.569-1.302 1.228-2.335 1.962-3.128v43.416c-.405.047-.809.06-1.243.06-4.193 0-6.47-2.632-6.47-8.26 0-6.346 2.157-23.587 5.75-32.088M139.386 257.445c1.109 0 2.202-.03 3.28-.12v174.989h-26.955V249.528c5.57 5.224 13.582 7.917 23.675 7.917",
              transform: "translate(-.5) translate(29.5)",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#0E0FE0",
              d: "M140.705 196.981c-3.594 8.501-5.75 25.742-5.75 32.088 0 5.628 2.276 8.26 6.469 8.26.434 0 .838-.013 1.243-.06v20.057c-1.079.088-2.172.12-3.28.12-10.093 0-18.105-2.694-23.676-7.918v-60.105c6.05-9.264 15.095-15.55 26.956-17.45v21.88c-.734.793-1.393 1.826-1.962 3.128",
              transform: "translate(-.5) translate(29.5)",
            },
          }),
          _vm._v(" "),
          _c(
            "g",
            {
              attrs: {
                transform: "translate(-.5) translate(29.5) translate(79.368)",
              },
            },
            [
              _c("mask", { attrs: { id: `${_vm.randomId}_b`, fill: "#fff" } }, [
                _c("use", { attrs: { "xlink:href": `#${_vm.randomId}_a` } }),
              ]),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  fill: "#FFF",
                  d: "M33.357 188.94c-6.25 9.049-9.643 21.106-9.989 34.78-.28 11.027 2.682 19.502 8.469 25.201l-4.63 182.757-26.947-.682 4.812-189.892 7.065.178 3.974-19.661-10.3-.262 9.989-48.972-8.982-.227L11.178.1l26.945.682-4.766 188.157z",
                  mask: `url(#${_vm.randomId}_a)`,
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#0E0FE0",
              d: "M95.479 221.621L91.505 241.282 84.439 241.104 86.186 172.16 95.168 172.387 85.179 221.359zM112.725 188.94l-1.52 59.981c-5.787-5.699-8.749-14.174-8.469-25.2.346-13.675 3.738-25.732 9.989-34.78",
              transform: "translate(-.5) translate(29.5)",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#181818",
              d: "M213.873 210.536v34.543h.03v-34.587l-.03.044zm53.97 34.543h.03v-68.965h-.03v68.965zm-26.985 13.05h.031v-32.806h-.031v32.807zm0-78.723v29.858l.03-.03v-29.858l-.03.03z",
              transform: "translate(-.5) translate(29.5)",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#FFF",
              d: "M290.601 8.232L275.451 439 248.514 438.054 255.19 248.218 262.538 248.475 266.704 228.853 256.407 228.492 266.876 179.619 257.614 179.294 263.664 7.286z",
              transform: "translate(-.5) translate(29.5)",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#0E0FE0",
              d: "M266.704 228.853L262.538 248.474 255.19 248.216 257.614 179.294 266.876 179.619 256.407 228.492z",
              transform: "translate(-.5) translate(29.5)",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#FFF",
              d: "M231.403 225.694l-2.11.07-.53-16.08c1.725-2.244 4.064-5.36 6.387-8.553-.785 5.297-1.694 10.478-2.091 13.008l-1.656 11.555zM248.952 3.673L254.614 175.694 230.502 176.487 227.78 179.84 222.012 4.559zM253.965 244.718L256.883 244.622 263.133 434.473 236.192 435.359 230.372 258.552 252.12 257.838z",
              transform: "translate(-.5) translate(29.5)",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#0E0FE0",
              d: "M254.614 175.694l2.27 68.928-2.92.096-1.845 13.12-21.747.713-1.08-32.788 2.11-.069 1.657-11.556c.397-2.527 1.306-7.709 2.09-13.005-2.322 3.19-4.661 6.306-6.386 8.55l-.983-29.842 2.721-3.354 24.113-.793z",
              transform: "translate(-.5) translate(29.5)",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#FFF",
              d: "M222.121 5.59L225.555 180.964 199.214 212.57 195.17 6.117zM225.074 212.169c.322-.395.685-.852 1.066-1.353l.314 16.056-6.602.129c-1.558.031-4.431.087-7.663.27l6.797-7.798 6.088-7.304zM223.368 259.744L227.096 259.671 230.561 436.542 203.612 437.069 199.89 247.152 225.388 246.652z",
              transform: "translate(-.5) translate(29.5)",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#0E0FE0",
              d: "M218.985 219.473l-6.797 7.796c3.232-.182 6.106-.238 7.663-.268l6.603-.128.642 32.799-3.727.073 2.018-13.093-25.496.5-.677-34.581 26.341-31.607.584 29.851c-.38.503-.744.958-1.066 1.355l-6.088 7.303z",
              transform: "translate(-.5) translate(29.5)",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#FFF",
              d: "M192.877 223.12l-5.936 23.72 13.373.906-12.895 189.518-26.892-1.828L173.26 248.25c9.466-8.957 15.133-22.764 16.215-38.68.887-13.021-2.902-23.51-11.562-29.709l11.87-174.466 26.895 1.83-14.022 206.06-9.78 9.837z",
              transform: "translate(-.5) translate(29.5)",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              fill: "#0E0FE0",
              d: "M189.477 209.568c-1.084 15.917-6.75 29.723-16.215 38.68l4.651-68.388c8.662 6.199 12.45 16.687 11.564 29.708M202.658 213.284L200.314 247.746 186.941 246.839 192.877 223.12z",
              transform: "translate(-.5) translate(29.5)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }