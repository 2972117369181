import middleware from "@/middleware/index.js";
import isConnected from "@/middleware/isConnected.js";
import hasProfileAlreadyCompleted from "@/middleware/hasProfileAlreadyCompleted.js";
import canAccessFultonCompletion from "@/middleware/canAccessFultonCompletion.js";
import AccountApi from "@/services/api/Account.js";
import store from "@/store/index.js";
import whiteboardRoutes from "@/modules/Whiteboard/router/index.js";
import lightboxRoutes from "@/modules/Lightbox/router/index.js";
import videoPlayerRoutes from "@/modules/VideoPlayer/router/index.js";

export default [
	{
		path: "/shared-session/:key",
		component: () => import("../pages/shared-session/_key.vue"),
		beforeEnter: middleware([
			(to, from, next) => {
				store.commit("SET_SHOW_NAVBAR", true);
				next();
			},
		]),
	},
	{
		path: "/profile-completion",
		component: () => import("../pages/profile-completion.vue"),
		beforeEnter: middleware([
			isConnected,
			hasProfileAlreadyCompleted,
			(to, from, next) => {
				store.commit("SET_SHOW_NAVBAR", false);
				next();
			},
		]),
	},
	{
		path: "/fulton-account-library-completion",
		component: () => import("../pages/fulton-account-library-completion.vue"),
		beforeEnter: middleware([
			isConnected,
			canAccessFultonCompletion,
			(to, from, next) => {
				store.commit("SET_SHOW_NAVBAR", false);
				next();
			},
		]),
	},
	{
		path: "/email/confirm/:token/:newEmail",
		beforeEnter: middleware([
			isConnected,
			async function(to, from, next) {
				try {
					await AccountApi.postEmail({
						verification_token: to.params.token,
						new_email: to.params.newEmail,
					});
				} catch (e) {
					Sentry.captureException(e);
				} finally {
					next("/login");
				}
			},
		]),
	},
	{
		name: "googlePicker",
		path: "/google-picker",
		component: () => import("../pages/default/googlePicker.vue"
		),
		beforeEnter: middleware([
			isConnected,
			(to, from, next) => {
				if (!window.opener) {
					next("/");
				}
				next();
			},
		]),
	},
	...whiteboardRoutes,
	...lightboxRoutes,
	...videoPlayerRoutes,
	{
		path: "*",
		name: "notFound",
		component: () => import("../components/ErrorPage.vue"),
		meta: {
			embeddable: true,
		},
	},
];
