const GAPI_SCRIPT_ID = "gapi";
const GSI_CLIENT_SCRIPT_ID = "gsiclient";
const promised = {};

// loading API libs via these helpers will conditionally auto-load the required dependencies for you
export async function load(apiName = undefined) {
	if (!promised[GAPI_SCRIPT_ID] && !window.gapi) {
		promised[GAPI_SCRIPT_ID] = new Promise((resolve, reject) => {
			let script = document.getElementById(GAPI_SCRIPT_ID);
			if (script) {
				script.onload = resolve;
				script.onerror = reject;
			} else {
				script = document.createElement("script");
				script.id = GAPI_SCRIPT_ID;
				script.src = "https://apis.google.com/js/api.js";
				script.onload = resolve;
				script.onerror = reject;
				document.head.appendChild(script);
			}
		});
	}
	await promised[GAPI_SCRIPT_ID];

	if (apiName) {
		if (!promised[apiName] && !window.gapi[apiName]) {
			promised[apiName] = new Promise((resolve, reject) => window.gapi.load(apiName, (err) => {
				if (err) {
					reject(err);
				} else {
					resolve();
				}
			}));
		}
		await promised[apiName];
	}
}
export async function loadClient(apiName = undefined, ...args) {
	await load("client");

	if (apiName) {
		if (!promised[apiName] && !window.gapi.client[apiName]) {
			promised[apiName] = new Promise(async(resolve, reject) => {
				try {
					const result = await window.gapi.client.load(apiName, ...args);
					resolve(result);
				} catch (err) {
					reject(err);
				}
			});
		}
		await promised[apiName];
	}
}
export async function initGSIClient() {
	const script = document.createElement("script");
	script.async = true;
	script.id = GSI_CLIENT_SCRIPT_ID;
	script.src = "https://accounts.google.com/gsi/client";
	document.head.appendChild(script);
}

// misc helpers
export function getBitsFromBinaryString(binaryString) {
	const bits = new Uint8Array(binaryString.length);
	for (let i = 0; i < binaryString.length; i++) {
		bits[i] = binaryString.charCodeAt(i);
	}
	return bits;
}
