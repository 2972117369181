export const MICRO_FRONTENDS_NAMES = {
	ACTIVITY_DASHBOARD: "activity-dashboard",
	INSTANT_FEEDBACK: "instant-feedback-web",
	LEARNER_PROFILE: "learner-profile-web",
	MATH_MISSIONS: "math-missions",
	PRACTICE_CENTER: "practice-center",
	STUDENT_DASHBOARD: "student-dashboard",
	STUDENT_NOTIFICATIONS_DRAWER: "student-notifications-drawer-web",
	STUDENT_ONBOARDING: "student-onboarding-web",
	TEACHER_DASHBOARD: "teacher-dashboard-web",
	TEACHING_ASSETS: "teaching-assets",
};
export const MICRO_FRONTENDS_MAP = {
	[MICRO_FRONTENDS_NAMES.ACTIVITY_DASHBOARD]: {
		id: "activity-dashboard",
		importMicroApp: () => import("@mfe/activity-dashboard/bootstrap.js"),
	},
	[MICRO_FRONTENDS_NAMES.INSTANT_FEEDBACK]: {
		id: "instant-feedback-web-css-prefix",
		importMicroApp: () => import("@mfe/instant-feedback-web/bootstrap.js"),
	},
	[MICRO_FRONTENDS_NAMES.LEARNER_PROFILE]: {
		id: "learner-profile-web-css-prefix",
		importMicroApp: () => import("@mfe/learner-profile-web/bootstrap.js"),
	},
	[MICRO_FRONTENDS_NAMES.MATH_MISSIONS]: {
		id: "missions-mfe",
		importMicroApp: () => import("@mfe/missions/Dashboard"),
	},
	[MICRO_FRONTENDS_NAMES.PRACTICE_CENTER]: {
		id: "missions-mfe",
		importMicroApp: () => import("@mfe/missions/PracticeCenter"),
	},
	[MICRO_FRONTENDS_NAMES.STUDENT_DASHBOARD]: {
		id: "student-dashboard-app",
		importMicroApp: () => import("@mfe/student-dashboard/StudentDashboard"),
	},
	[MICRO_FRONTENDS_NAMES.STUDENT_NOTIFICATIONS_DRAWER]: {
		id: "student-notifications-drawer-web-app",
		importMicroApp: () => import("@mfe/student-notifications-drawer-web/bootstrap.js"),
	},
	[MICRO_FRONTENDS_NAMES.STUDENT_ONBOARDING]: {
		id: "student-onboarding-web-css-prefix",
		importMicroApp: () => import("@mfe/student-onboarding-web/bootstrap.js"),
	},
	[MICRO_FRONTENDS_NAMES.TEACHER_DASHBOARD]: {
		id: "teacher-dashboard-web-css-prefix",
		importMicroApp: () => import("@mfe/teacher-dashboard-web/bootstrap.js"),
	},
	[MICRO_FRONTENDS_NAMES.TEACHING_ASSETS]: {
		id: "teaching-assets-app",
		importMicroApp: () => import("@mfe/teaching-assets/Dashboard"),
	},
};
// Events subscriptions
export const MICRO_FRONTENDS_SUBSCRIPTION_EVENTS = {
	ROUTE_UPDATE: "microAppRouteUpdate",
	STUDENT_DASHBOARD_AGREEMENT_ACCEPT: "studentDashboardAgreementAccept",
	ONBOARDING_COMPLETE: "onboardingComplete",
	STUDENT_DASHBOARD_MAJOR_CLARITY_CLICK: "studentDashboardMajorClarityClick",
};

// Events to be published
export const MICRO_FRONTENDS_HOST_EVENTS = {
	GRADE_LEVEL_UPDATE: "hostGradeLevelUpdate",
	ROUTE_CHANGE: "hostRouteChange",
	AVATAR_CHANGE: "hostAvatarChange",
};

export const publishToMicroFrontEnds = (eventName, data = {}) => {
	if (!(Object.values(MICRO_FRONTENDS_HOST_EVENTS).includes(eventName))) {
		console.warn(`${eventName} is not a micro-frontend host event`);
		return;
	}
	const event = new CustomEvent(
		eventName,
		{
			detail: data,
		},
	);
	window.dispatchEvent(event);
};
