var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tutorCardInfo" }, [
      _c("div", { staticClass: "tutorImageCircle__wrapper" }, [
        _c("div", { staticClass: "tutorImageCircle" }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tutorCardInfo__text tutorCardInfo__text--block" },
        [
          _c("div", { staticClass: "tutorCardInfo__nameBlock" }),
          _vm._v(" "),
          _c("div", { staticClass: "tutorCardInfo__shiftBlock" }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }