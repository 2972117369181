import api from "./Api.js";

export default {
	/**
	 * Get authenticated user account
	 * @returns {AxiosPromise}
	 */
	show() {
		return api().get(`/me/account`);
	},

	/**
	 * Send email to reset authenticated user's email
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	sendNewEmailNotification(data) {
		return api().post(`/me/account.email`, data);
	},

	/**
	 * Change Email for user in response to sent email notification
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	postEmail(data) {
		return api().post(`/me/account.changeEmail`, data);
	},

	/**
	 * Change authenticated user password
	 * @param {object} data
	 * 		new_password
	 * 		old_password or initial_password
	 * @returns {AxiosPromise}
	 */
	postPassword(data) {
		return api().post(`/me/account.password`, data);
	},

	/**
	 * Change authenticated lms user password
	 * @param {object} data
	 * 		password
	 * @returns {AxiosPromise}
	 */
	postPasswordLMS(data) {
		return api().post(`/me/account.passwordLMS`, data);
	},

	/**
	 * Update student information
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	postStudent(data) {
		return api().post(`/accounts/student`, data);
	},

	/**
	 * Change user passwords from SuperUser dashboard
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	postPasswords(data) {
		return api().post(`/accounts/password`, data);
	},

	/**
	 * Change student license ends at date from Superuser dashboard
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	postLicenseDates(data) {
		return api().post(`/accounts/license`, data);
	},

	/**
	 * Get authenticated user profile
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	showProfile(params) {
		return api().get(`/me/profile`, {
			params: params,
		});
	},

	/**
	 * Update authenticated user profile
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	updateProfile(data) {
		return api().post(`/me/profile`, data);
	},

	/**
	 * Get authenticated user schedule in ICS format
	 * @returns {AxiosPromise}
	 */
	getICSSchedule() {
		return api().get(`/me/ICSSchedule`);
	},

	/**
	 * Get whether new user needs a password update
	 * @returns {AxiosPromise}
	 */
	getUserNeedsPasswordUpdate() {
		return api().get(`/me/need.password.update`);
	},

	postUserResetPassword(data) {
		return api().post("/passwords.reset.magic", data);
	},
};
