var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M20.4613 9.1513C20.4542 9.15808 20.4442 9.16001 20.4382 9.16679L20.4422 9.17066L12.2139 17.4297C11.8401 17.8055 11.3426 17.9943 10.8421 17.9991C10.3426 18.0137 9.83802 17.8442 9.44807 17.484C9.44204 17.4772 9.43902 17.4685 9.43199 17.4627L9.42897 17.4656L4.59374 12.9947C3.82388 12.282 3.79976 11.1035 4.53846 10.3617C5.27717 9.61998 6.5003 9.59674 7.27015 10.3075L10.7476 13.5244L17.6542 6.59194L17.6572 6.59581C17.6642 6.58903 17.6663 6.57935 17.6733 6.57257C18.413 5.83081 19.6351 5.80661 20.405 6.51834C21.1758 7.23105 21.201 8.40954 20.4613 9.1513Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }