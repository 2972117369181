<template>
	<div class="tw-relative tw-flex tw-justify-center tw-items-center">
		<div
			v-jest="'spinner'"
			class="spinner tw-w-full tw-h-full tw-absolute tw-animate-spin tw-border-grey-dark tw-rounded-full"
			:style="{ borderWidth: thickness }"
		/>
		<slot />
	</div>
</template>

<script>
export default {
	props: {
		thickness: {
			type: String,
			default: "6px",
		},
	},
};
</script>

<style scoped>
.spinner {
	border-top-color: currentColor;
}
</style>
