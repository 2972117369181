var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-md-12 section__divider tw-p-0 tw-pt-6 tw-mb-14" },
    [
      _c(
        "h3",
        {
          staticClass:
            "tw-font-extrabold tw-text-black-high tw-text-2xl tw-mb-6",
        },
        [_vm._v("\n\t\tExpertise Details\n\t")]
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "submit-expertise-form",
              expression: "'submit-expertise-form'",
            },
          ],
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitDetails.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "tw-flex tw-flex-col tw-w-max tw-max-w-max" },
            [
              _c(
                "h4",
                {
                  staticClass:
                    "tw-text-lg tw-text-black-high tw-text-left tw-w-full tw-font-bold tw-mb-7",
                },
                [_vm._v("\n\t\t\t\tEligible For\n\t\t\t")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tw-flex tw-mb-7" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "tw-text-sm tw-leading-6 tw-pr-9 tw-text-left tw-flex-grow",
                  },
                  [_vm._v("\n\t\t\t\t\tClassroom Text Messages\n\t\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tw-flex tw-gap-x-9" },
                  [
                    _c(
                      "BaseRadio",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "classroom-yes-radio",
                            expression: "'classroom-yes-radio'",
                          },
                        ],
                        staticClass:
                          "expertiseDetails__radioButton--maxWidth tw-w-14",
                        class: _vm.tutorProfile.can_receive_sync_chat_sessions
                          ? "tw-font-bold"
                          : "",
                        attrs: {
                          "radio-id": "canTutorClassroom-yes",
                          "radio-name": "canTutorClassroom-yes",
                          "radio-value": true,
                          value:
                            _vm.tutorProfile.can_receive_sync_chat_sessions,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.setTutorProfileProperty({
                              can_receive_sync_chat_sessions: true,
                            })
                          },
                        },
                      },
                      [
                        _c("template", { slot: "text" }, [
                          _vm._v("\n\t\t\t\t\t\t\tYes\n\t\t\t\t\t\t"),
                        ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "BaseRadio",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "classroom-no-radio",
                            expression: "'classroom-no-radio'",
                          },
                        ],
                        staticClass:
                          "expertiseDetails__radioButton--maxWidth tw-w-14",
                        class: _vm.tutorProfile.can_receive_sync_chat_sessions
                          ? ""
                          : "tw-font-bold",
                        attrs: {
                          "radio-id": "canTutorClassroom-no",
                          "radio-name": "canTutorClassroom-no",
                          "radio-value": false,
                          value:
                            _vm.tutorProfile.can_receive_sync_chat_sessions,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.setTutorProfileProperty({
                              can_receive_sync_chat_sessions: false,
                            })
                          },
                        },
                      },
                      [
                        _c("template", { slot: "text" }, [
                          _vm._v("\n\t\t\t\t\t\t\tNo\n\t\t\t\t\t\t"),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tw-flex tw-mb-7 tw-justify-between" }, [
                _c("p", { staticClass: "tw-text-sm tw-leading-6 tw-pr-9" }, [
                  _vm._v("\n\t\t\t\t\tWriting Review\n\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tw-flex tw-gap-x-9" },
                  [
                    _c(
                      "BaseRadio",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "essay-yes-radio",
                            expression: "'essay-yes-radio'",
                          },
                        ],
                        staticClass:
                          "expertiseDetails__radioButton--maxWidth tw-w-14",
                        class: _vm.tutorProfile.essay_review
                          ? "tw-font-bold"
                          : "",
                        attrs: {
                          "radio-id": "essayReview-yes",
                          "radio-name": "essayReview-yes",
                          "radio-value": true,
                          value: _vm.tutorProfile.essay_review,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.setTutorProfileProperty({
                              essay_review: true,
                            })
                          },
                        },
                      },
                      [
                        _c("template", { slot: "text" }, [
                          _vm._v("\n\t\t\t\t\t\t\tYes\n\t\t\t\t\t\t"),
                        ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "BaseRadio",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "essay-no-radio",
                            expression: "'essay-no-radio'",
                          },
                        ],
                        staticClass:
                          "expertiseDetails__radioButton--maxWidth tw-w-14",
                        class: _vm.tutorProfile.essay_review
                          ? ""
                          : "tw-font-bold",
                        attrs: {
                          "radio-id": "essayReview-no",
                          "radio-name": "essayReview-no",
                          "radio-value": false,
                          value: _vm.tutorProfile.essay_review,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.setTutorProfileProperty({
                              essay_review: false,
                            })
                          },
                        },
                      },
                      [
                        _c("template", { slot: "text" }, [
                          _vm._v("\n\t\t\t\t\t\t\tNo\n\t\t\t\t\t\t"),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "tw-flex tw-mb-7 tw-justify-between" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tw-flex tw-gap-x-9" },
                  [
                    _c(
                      "BaseRadio",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "us-ca-fingerprinted-yes-radio",
                            expression: "'us-ca-fingerprinted-yes-radio'",
                          },
                        ],
                        staticClass:
                          "expertiseDetails__radioButton--maxWidth tw-w-14",
                        class: _vm.tutorProfile.is_us_ca_fingerprinted
                          ? "tw-font-bold"
                          : "",
                        attrs: {
                          "radio-id": "isUsCaFingerprinted-yes",
                          "radio-name": "isUsCaFingerprinted-yes",
                          "radio-value": true,
                          value: _vm.tutorProfile.is_us_ca_fingerprinted,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.setTutorProfileProperty({
                              is_us_ca_fingerprinted: true,
                            })
                          },
                        },
                      },
                      [
                        _c("template", { slot: "text" }, [
                          _vm._v("\n\t\t\t\t\t\t\tYes\n\t\t\t\t\t\t"),
                        ]),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "BaseRadio",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "us-ca-fingerprinted-no-radio",
                            expression: "'us-ca-fingerprinted-no-radio'",
                          },
                        ],
                        staticClass:
                          "expertiseDetails__radioButton--maxWidth tw-w-14",
                        class: _vm.tutorProfile.is_us_ca_fingerprinted
                          ? ""
                          : "tw-font-bold",
                        attrs: {
                          "radio-id": "isUsCaFingerprinted-no",
                          "radio-name": "isUsCaFingerprinted-no",
                          "radio-value": false,
                          value: _vm.tutorProfile.is_us_ca_fingerprinted,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.setTutorProfileProperty({
                              is_us_ca_fingerprinted: false,
                            })
                          },
                        },
                      },
                      [
                        _c("template", { slot: "text" }, [
                          _vm._v("\n\t\t\t\t\t\t\tNo\n\t\t\t\t\t\t"),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.featureFlagApsApproved
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "is-us-nm-aps-background-selections",
                          expression: "'is-us-nm-aps-background-selections'",
                        },
                      ],
                      staticClass: "tw-flex tw-mb-7 tw-justify-between",
                    },
                    [
                      _vm._m(1),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "tw-flex tw-gap-x-9" },
                        [
                          _c(
                            "BaseRadio",
                            {
                              directives: [
                                {
                                  name: "jest",
                                  rawName: "v-jest",
                                  value: "is-us-nm-aps-background-yes-radio",
                                  expression:
                                    "'is-us-nm-aps-background-yes-radio'",
                                },
                              ],
                              staticClass:
                                "expertiseDetails__radioButton--maxWidth tw-w-14",
                              class: _vm.tutorProfile
                                .is_us_nm_aps_background_checked
                                ? "tw-font-bold"
                                : "",
                              attrs: {
                                "radio-id": "isUsNmApsBackgroundChecked-yes",
                                "radio-name": "isUsNmApsBackgroundChecked-yes",
                                "radio-value": true,
                                value:
                                  _vm.tutorProfile
                                    .is_us_nm_aps_background_checked,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.setTutorProfileProperty({
                                    is_us_nm_aps_background_checked: true,
                                  })
                                },
                              },
                            },
                            [
                              _c("template", { slot: "text" }, [
                                _vm._v("\n\t\t\t\t\t\t\tYes\n\t\t\t\t\t\t"),
                              ]),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "BaseRadio",
                            {
                              directives: [
                                {
                                  name: "jest",
                                  rawName: "v-jest",
                                  value: "is-us-nm-aps-background-no-radio",
                                  expression:
                                    "'is-us-nm-aps-background-no-radio'",
                                },
                              ],
                              staticClass:
                                "expertiseDetails__radioButton--maxWidth tw-w-14",
                              class: _vm.tutorProfile
                                .is_us_nm_aps_background_checked
                                ? ""
                                : "tw-font-bold",
                              attrs: {
                                "radio-id": "isUsNmApsBackgroundChecked-no",
                                "radio-name": "isUsNmApsBackgroundChecked-no",
                                "radio-value": false,
                                value:
                                  _vm.tutorProfile
                                    .is_us_nm_aps_background_checked,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.setTutorProfileProperty({
                                    is_us_nm_aps_background_checked: false,
                                  })
                                },
                              },
                            },
                            [
                              _c("template", { slot: "text" }, [
                                _vm._v("\n\t\t\t\t\t\t\tNo\n\t\t\t\t\t\t"),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "audio-profile",
                      expression: "'audio-profile'",
                    },
                  ],
                  staticClass: "tw-flex tw-mb-7 tw-justify-between",
                },
                [
                  _c("p", { staticClass: "tw-text-sm tw-leading-6 tw-pr-9" }, [
                    _vm._v("\n\t\t\t\t\tClassroom Voice Messages\n\t\t\t\t"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tw-flex tw-gap-x-9" },
                    [
                      _c(
                        "BaseRadio",
                        {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: "audio-yes-radio",
                              expression: "'audio-yes-radio'",
                            },
                          ],
                          staticClass:
                            "expertiseDetails__radioButton--maxWidth tw-w-14",
                          class: {
                            "tw-font-bold":
                              _vm.tutorProfile.can_receive_async_audio_sessions,
                          },
                          attrs: {
                            "radio-id": "audio-yes",
                            "radio-name": "audio-yes",
                            "radio-value": true,
                            value:
                              _vm.tutorProfile.can_receive_async_audio_sessions,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.setTutorProfileProperty({
                                can_receive_async_audio_sessions: true,
                              })
                            },
                          },
                        },
                        [
                          _c("template", { slot: "text" }, [
                            _vm._v("\n\t\t\t\t\t\t\tYes\n\t\t\t\t\t\t"),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "BaseRadio",
                        {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: "audio-no-radio",
                              expression: "'audio-no-radio'",
                            },
                          ],
                          staticClass:
                            "expertiseDetails__radioButton--maxWidth tw-w-14",
                          class: {
                            "tw-font-bold":
                              !_vm.tutorProfile
                                .can_receive_async_audio_sessions,
                          },
                          attrs: {
                            "radio-id": "audio-no",
                            "radio-name": "audio-no",
                            "radio-value": false,
                            value:
                              _vm.tutorProfile.can_receive_async_audio_sessions,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.setTutorProfileProperty({
                                can_receive_async_audio_sessions: false,
                              })
                            },
                          },
                        },
                        [
                          _c("template", { slot: "text" }, [
                            _vm._v("\n\t\t\t\t\t\t\tNo\n\t\t\t\t\t\t"),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tw-flex tw-flex-col tw-w-max tw-max-w-max" },
            [
              _c(
                "h4",
                {
                  staticClass:
                    "tw-text-lg tw-text-black-high tw-text-left tw-w-full tw-font-bold tw-mb-7",
                },
                [_vm._v("\n\t\t\t\tLanguage\n\t\t\t")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "tw-flex tw-items-center tw-justify-end tw-w-full tw-flex-wrap tw-mb-7",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "tw-flex tw-flex-grow tw-text-sm tw-leading-6 tw-text-right tw-pr-9",
                      attrs: { for: "last_name" },
                    },
                    [_vm._v("\n\t\t\t\t\tTutoring Language\n\t\t\t\t")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tw-flex tw-gap-x-9" },
                    _vm._l(
                      _vm.PlatformManager.TutorsEdit.tutorLanguages,
                      function (language) {
                        return _c(
                          "div",
                          {
                            key: language.id,
                            staticClass: "expertiseDetails__languageCheckbox",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "checkbox" },
                              [
                                _c(
                                  "BaseCheckbox",
                                  {
                                    directives: [
                                      {
                                        name: "jest",
                                        rawName: "v-jest",
                                        value: `tutor-language-${language.id}`,
                                        expression:
                                          "`tutor-language-${language.id}`",
                                      },
                                    ],
                                    staticClass: "topic topicCheckbox",
                                    class: _vm.selectedTutorLanguages.includes(
                                      language.id
                                    )
                                      ? "checkbox__boldLabel"
                                      : "",
                                    attrs: {
                                      name: "languages",
                                      "checkbox-value": language.id,
                                      value: language.id,
                                      "checkbox-id": `tutorLanguage-${language.id}`,
                                    },
                                    model: {
                                      value: _vm.selectedTutorLanguages,
                                      callback: function ($$v) {
                                        _vm.selectedTutorLanguages = $$v
                                      },
                                      expression: "selectedTutorLanguages",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "text" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(language.language) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "tw-flex tw-items-center tw-justify-end tw-w-full tw-flex-wrap tw-mb-7",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "tw-flex-grow tw-text-sm tw-leading-6 tw-pr-9",
                      attrs: { for: "last_name" },
                    },
                    [_vm._v("\n\t\t\t\t\tWriting Review Language\n\t\t\t\t")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tw-flex tw-gap-x-9" },
                    _vm._l(
                      _vm.PlatformManager.TutorsEdit.essayLanguages,
                      function (language) {
                        return _c(
                          "div",
                          {
                            key: language.id,
                            staticClass: "expertiseDetails__languageCheckbox",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "checkbox" },
                              [
                                _c(
                                  "BaseCheckbox",
                                  {
                                    directives: [
                                      {
                                        name: "jest",
                                        rawName: "v-jest",
                                        value: `essay-language-${language.id}`,
                                        expression:
                                          "`essay-language-${language.id}`",
                                      },
                                    ],
                                    staticClass: "topic topicCheckbox",
                                    class: _vm.selectedEssayLanguages.includes(
                                      language.id
                                    )
                                      ? "checkbox__boldLabel"
                                      : "",
                                    attrs: {
                                      name: "languages",
                                      "checkbox-value": language.id,
                                      value: language.id,
                                      "checkbox-id": `essayLanguage-${language.id}`,
                                    },
                                    model: {
                                      value: _vm.selectedEssayLanguages,
                                      callback: function ($$v) {
                                        _vm.selectedEssayLanguages = $$v
                                      },
                                      expression: "selectedEssayLanguages",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "text" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(language.language) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "audio-languages",
                      expression: "'audio-languages'",
                    },
                  ],
                  staticClass:
                    "tw-flex tw-items-center tw-justify-end tw-w-full tw-flex-wrap tw-mb-7",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "tw-flex-grow tw-text-sm tw-leading-6 tw-pr-9",
                      attrs: { for: "last_name" },
                    },
                    [_vm._v("\n\t\t\t\t\tVoice Message Language\n\t\t\t\t")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tw-flex tw-gap-x-9" },
                    _vm._l(
                      _vm.PlatformManager.TutorsEdit.audioLanguages,
                      function (language) {
                        return _c(
                          "div",
                          {
                            key: language.id,
                            staticClass: "expertiseDetails__languageCheckbox",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "checkbox" },
                              [
                                _c(
                                  "BaseCheckbox",
                                  {
                                    directives: [
                                      {
                                        name: "jest",
                                        rawName: "v-jest",
                                        value: `audio-language-${language.id}`,
                                        expression:
                                          "`audio-language-${language.id}`",
                                      },
                                    ],
                                    staticClass: "topic topicCheckbox",
                                    class: {
                                      checkbox__boldLabel:
                                        _vm.selectedAudioLanguages.includes(
                                          language.id
                                        ),
                                    },
                                    attrs: {
                                      name: "languages",
                                      "checkbox-value": language.id,
                                      value: language.id,
                                      "checkbox-id": `audioLanguages-${language.id}`,
                                    },
                                    model: {
                                      value: _vm.selectedAudioLanguages,
                                      callback: function ($$v) {
                                        _vm.selectedAudioLanguages = $$v
                                      },
                                      expression: "selectedAudioLanguages",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "text" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(language.language) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", [
            _c(
              "details",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "academic-units-toggle",
                    expression: "'academic-units-toggle'",
                  },
                ],
                on: {
                  toggle: function ($event) {
                    _vm.isAcademicUnitsOpen = !_vm.isAcademicUnitsOpen
                  },
                },
              },
              [
                _c("summary", { staticClass: "tw-block tw-mb-7 tw-w-full" }, [
                  _c(
                    "h4",
                    {
                      staticClass:
                        "tw-text-lg tw-text-black-high tw-text-left tw-font-bold tw-flex tw-items-center",
                    },
                    [
                      _vm._v("\n\t\t\t\t\t\tAcademic Units\n\t\t\t\t\t\t"),
                      _vm.PlatformManager.TutorsEdit.tutor
                        ? _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "jest",
                                  rawName: "v-jest",
                                  value: "academic-units-number",
                                  expression: "'academic-units-number'",
                                },
                              ],
                              staticClass: "tw-text-grey-dark",
                            },
                            [
                              _vm._v(
                                " (" +
                                  _vm._s(
                                    _vm.PlatformManager.TutorsEdit.tutor.tutor
                                      .data.subjects.data.length
                                  ) +
                                  ")"
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("IconCaret", {
                        staticClass: "tw-text-black-high tw-h-4 tw-ml-3",
                        style: _vm.isAcademicUnitsOpen
                          ? "transform: rotate(90deg)"
                          : "",
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("ServiceTutorEditShowTags", { ref: "tutorTags" }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group tw-overflow-auto" },
            [
              _c(
                "BaseButton",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "submit-expertise-button",
                      expression: "'submit-expertise-button'",
                    },
                  ],
                  staticClass: "pull-right tw-mt-6 tw-w-48",
                  attrs: {
                    type: "SUBMIT",
                    disabled: !_vm.isSubmitExpertiseDetailsButtonEnabled,
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "text" },
                    [
                      _vm.isSubmitInProgress
                        ? _c("LoadingRectangles", {
                            staticClass: "tw-text-blue-regular",
                          })
                        : _c("span", [
                            _vm._v(
                              "\n\t\t\t\t\t\tSubmit Expertise Details\n\t\t\t\t\t"
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isShowingUpdateSuccess
            ? _c("div", { staticClass: "alert alert-success tw-truncate" }, [
                _vm._v(
                  "\n\t\t\tExpertise details were updated successfully!\n\t\t"
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowingUpdateError
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "error-message",
                      expression: "'error-message'",
                    },
                  ],
                  staticClass: "alert alert-danger tw-truncate",
                },
                [_vm._v("\n\t\t\t" + _vm._s(_vm.submitProfileError) + "\n\t\t")]
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "tw-text-sm tw-leading-6 tw-pr-9" }, [
      _vm._v("\n\t\t\t\t\tCalifornia Tutoring "),
      _c("br"),
      _vm._v(" (LAUSD Fingerprint)\n\t\t\t\t"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "tw-text-sm tw-leading-6 tw-pr-9" }, [
      _vm._v("\n\t\t\t\t\tAPS Approved "),
      _c("br"),
      _vm._v(" (Albuquerque Background Check)\n\t\t\t\t"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }