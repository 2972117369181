var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("PunchClock", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "punch-clock",
            expression: "'punch-clock'",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }