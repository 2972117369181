import Vue from "vue";
import { isEmpty, isNil } from "lodash";

import helpers from "@/utilities/SessionsStudentStoreHelpers.js";
import sessionHelpers from "@/utilities/SessionStoreHelpers.js";
import { formatDateToTimestamp } from "@/utilities/dateHelpers.js";
import { getUserWithRole } from "@/utilities/user.js";
import SessionsAPI from "@/services/api/Sessions.js";
import StudentsAPI from "@/services/api/Students.js";

const getDefaultState = () => ({
	isRatingModalShowing: false,
	numRecentSessions: 0,
	endSessionReason: null,
	currentUserClasses: [],
	endSessionReasons: [],
	recentSessions: {},
	isConnected: true,
	isShowingSessionSkeletonLoadingState: false,
	isLoadingRecentSessionMessages: false,
	endOfChatReasons: [],
	endOfChatLearningStatuses: [],
	toast: null,
	isShowingEndSessionDialog: false,
	isShowingTransferSessionModal: false,
	subjects: [],
});

const state = getDefaultState();
export const getters = {
	/**
	 * Returns a boolean indicating whether or not the current session is a recent session
	 * @param {*} state
	 * @return {Boolean}
	 */
	isRecentSession(state, getters, rootState) {
		return (
			!isNil(rootState.Classroom.currentSessionId) &&
			!isNil(state.recentSessions[rootState.Classroom.currentSessionId])
		);
	},
	/**
	 * filter out active end session reasons
	 * @param {*} state
	 * @returns {Array}
	 */
	activeEndSessionReasons(state) {
		return state.endSessionReasons.filter((reason) => reason.active === true);
	},
};

export const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	INSERT_RECENT_SESSIONS(state, payload) {
		Vue.set(state.recentSessions, payload.session.id, payload.session);
	},
	SET_CURRENT_USER_SECTIONS(state, payload) {
		state.currentUserSections = payload.currentUserSections;
	},
	SET_END_SESSION_REASONS(state, payload) {
		state.endSessionReasons = payload.reasons;
	},
	SET_IS_LOADED_IN_RECENT_SESSIONS(state, payload) {
		state.recentSessions[payload.sessionId].isLoaded = payload.isLoaded;
	},
	SET_NUM_RECENT_SESSIONS(state, payload) {
		state.numRecentSessions = payload.numRecentSessions;
	},
	SET_RECENT_SESSION_MESSAGES(state, payload) {
		Vue.set(state.recentSessions[payload.sessionId], "messages", payload.messages);
	},
	SET_RECENT_SESSIONS(state, payload) {
		state.recentSessions = payload.sessions;
	},
	SET_USER_IS_CONNECTED(state, { isConnected }) {
		state.isConnected = isConnected;
	},
	/**
	 * 
	 * @param {Object} state 
	 * @param {Number} sessionId 
	 * @param {String} key 
	 * @param {Any} value 
	 */
	SET_RECENT_SESSION_PARAM(state, { sessionId, key, value }) {
		Vue.set(state.recentSessions[sessionId], key, value);
	},
	/**
	 * 
	 * @param {Object} state 
	 * @param {Object} message
	 * @param {String} key
	 * @param {Any} value
	 */
	UPDATE_MESSAGE_PARAM(_, { message, key, value }) {
		Vue.set(message?.message || message, key, value);
	},
	/**
	 * 
	 * @param {Object} state 
	 * @param {Boolean} isShowingSessionSkeletonLoadingState 
	 */
	SET_IS_SHOWING_SESSION_SKELETON_LOADING_STATE(state, { isShowingSessionSkeletonLoadingState }) {
		state.isShowingSessionSkeletonLoadingState = isShowingSessionSkeletonLoadingState;
	},
	/**
	 * 
	 * @param {Object} state 
	 * @param {Boolean} isLoadingRecentSessionMessages 
	 */
	SET_IS_LOADING_RECENT_SESSION_MESSAGES(state, { isLoadingRecentSessionMessages }) {
		state.isLoadingRecentSessionMessages = isLoadingRecentSessionMessages;
	},
	/**
	 * 
	 * @param {Object} state 
	 * @param {Array} reasons 
	 */
	SET_END_OF_CHAT_REASONS(state, { endOfChatReasons }) {
		state.endOfChatReasons = endOfChatReasons;
	},
	/**
	 * 
	 * @param {Object} state 
	 * @param {Array} reasons 
	 */
	SET_END_OF_CHAT_LEARNING_STATUSES(state, { endOfChatLearningStatuses }) {
		state.endOfChatLearningStatuses = endOfChatLearningStatuses;
	},
	SET_TOAST(state, { toast }) {
		state.toast = toast;
	},
	TOGGLE_END_SESSION_DIALOG(state, { isOpen }) {
		state.isShowingEndSessionDialog = isOpen;
	},
	TOGGLE_TRANSFER_SESSION_MODAL(state, { isOpen }) {
		state.isShowingTransferSessionModal = isOpen;
	},
	SET_SUBJECTS(state, { subjects }) {
		state.subjects = subjects;
	},
};

export const actions = {
	moveEndedSessionToRecentSessions({ rootState, commit }, sessionId) {
		commit("INSERT_RECENT_SESSIONS", {
			session: rootState.Classroom.sessions[sessionId],
		});
		commit("Classroom/SET_DISABLE_CHAT_INPUT_IN_SESSIONS", {
			disableChatInput: false,
			sessionId: sessionId,
		}, { root: true });
	},
	/**
	 * Ends session using API and deletes the ended session from the active sessions object
	 *
	 * @param 	{*} 		commit
	 * @param 	{object} 	params
	 * @returns {Promise}
	 */
	async endSession(_, params) {
		try {
			const response = await SessionsAPI.endSession(params);

			return Promise.resolve(response.data);
		} catch (error) {
			return Promise.reject(
				"Error: There was a problem ending your session. Please try reloading the page.",
			);
		}
	},

	/**
	 * Sets current session to desired session and dispatches getSessionMessages action
	 *
	 * @param {commit, dispatch}
	 * @param {number} sessionId
	 */
	async selectRecentSession({ state, commit, dispatch }, sessionId) {
		try {
			commit("SET_IS_LOADING_RECENT_SESSION_MESSAGES", {
				isLoadingRecentSessionMessages: true,
			});
			commit("Classroom/SET_CURRENT_SESSION_ID", {
				id: sessionId,
			}, { root: true });

			const rightSidebarTabs = [
				{
					name: "shared_files",
					active: true,
					component: "sidebar-shared-files-list",
				},
			];

			commit("Bookmark/SET_IS_SHOWING_BOOKMARKS", {
				isShowingBookmarks: false,
			}, { root: true });
			commit("Classroom/SET_RIGHT_SIDEBAR_TABS", {
				rightSidebarTabs,
			}, { root: true });
			commit("Classroom/SET_RIGHT_SIDEBAR_COMPONENT", {
				rightSidebarComponent: rightSidebarTabs[0].component,
			}, { root: true });


			if (state.recentSessions[sessionId].isLoaded === false) {
				const response = await dispatch("Classroom/getSessionMessages", sessionId, { root: true });

				commit("SET_RECENT_SESSION_MESSAGES", {
					messages: response,
					sessionId,
				});

				commit("SET_IS_LOADED_IN_RECENT_SESSIONS", {
					sessionId,
					isLoaded: true,
				});
			}
		} catch (e) {
			Sentry.captureException(e);
		} finally {
			commit("SET_IS_LOADING_RECENT_SESSION_MESSAGES", {
				isLoadingRecentSessionMessages: false,
			});
		}
	},

	/**
	 * 
	 * @param {Object} state 
	 * @param {Function} commit 
	 * @param {Function} dispatch 
	 * @param {Number} sessionId 
	 */
	async loadAndSelectNotLoadedRecentSession({ commit, dispatch }, sessionId) {
		const session = (await SessionsAPI.show(sessionId, {
			include: "requestedSubject,requestedTopic,users",
		})).data.data;
		const formattedSession = sessionHelpers.transformSessionForClassroom(session);
		dispatch("addRecentSession", formattedSession);
		dispatch("selectRecentSession", sessionId);
		commit("Classroom/SET_LEFT_SIDEBAR_COMPONENT", {
			leftSidebarComponent: "sidebar-history",
		}, { root: true });
	},

	/**
	 * 
	 * @param {Object} state 
	 * @param {Function} commit 
	 * @param {Object} session
	 */
	addRecentSession({ state, commit }, session) {
		const recentSessions = {
			...state.recentSessions,
			[session.id]: session,
		};
		commit("SET_RECENT_SESSIONS", {
			sessions: recentSessions,
		});
		commit("SET_NUM_RECENT_SESSIONS", {
			numRecentSessions: state.numRecentSessions + 1,
		});
	},

	/**
	 * Gets 10 of the past sessions for the user
	 *
	 * @param commit
	 * @param state
	 */
	async getPastSessions({ commit, rootState }, options = { isDefaultLoadingState: true }) {
		try {
			if (options.isDefaultLoadingState) {
				commit("Classroom/SET_IS_LOADING_SESSIONS", {
					isLoadingSessions: true,
				}, { root: true });
			}
			const params = {
				paginate: 10,
				page: 1,
			};
			const sessions = await SessionsAPI.getRecentStudentSessions(
				rootState.currentUser.id,
				params,
			);

			Object.keys(sessions.data.data).forEach((sessionId) => {
				const newSession = sessions.data.data[sessionId];
				newSession.disableChatInput = true;
				if (state.recentSessions[sessionId]) {
					sessions.data.data[sessionId] = state.recentSessions[sessionId];
				} else {
					sessions.data.data[sessionId] = helpers.formatAllTopicStringsInASession(
						newSession,
					);
				}
			});

			commit("SET_RECENT_SESSIONS", {
				sessions: sessions.data.data,
			});
			commit("SET_NUM_RECENT_SESSIONS", {
				numRecentSessions: sessions.data.total,
			});

			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response);
		} finally {
			commit("Classroom/SET_IS_LOADING_SESSIONS", {
				isLoadingSessions: false,
			}, { root: true });
		}
	},

	convertActiveSessionIntoPastSession(
		{ rootState, rootGetters, commit },
		{ sessionId, didTutorInitiateSessionEnd = false },
	) {
		commit("Classroom/EDIT_SESSION", {
			id: sessionId,
			key: "ended_at",
			value: formatDateToTimestamp(new Date()),
		}, { root: true });
		commit("Classroom/EDIT_SESSION", {
			id: sessionId,
			key: "ended_by",
			value: didTutorInitiateSessionEnd ?
				getUserWithRole(rootGetters["Classroom/currentSessionOtherUsers"], "tutor")?.id :
				rootState.currentUser?.id,
		}, { root: true });
		commit("Classroom/SET_DISABLE_CHAT_INPUT_IN_SESSIONS", {
			sessionId,
			disableChatInput: true,
		}, { root: true });
	},

	async moveEndedSessionToHistoryTab({ state, rootState, rootGetters, commit, dispatch },
		{ sessionId, didTutorInitiateSessionEnd = false },
	) {
		try {
			const session = rootState.Classroom.sessions[sessionId];
			if (isEmpty(session)) {
				return;
			}
			commit("INSERT_RECENT_SESSIONS", {
				session: session,
			});
			commit("SET_RECENT_SESSION_PARAM", {
				sessionId,
				key: "ended_at",
				value: formatDateToTimestamp(new Date()),
			});
			commit("SET_RECENT_SESSION_PARAM", {
				sessionId,
				key: "ended_by",
				value: didTutorInitiateSessionEnd ?
					getUserWithRole(rootGetters["Classroom/currentSessionOtherUsers"], "tutor")?.id :
					rootState.currentUser?.id,
			});
			commit("Classroom/SET_DISABLE_CHAT_INPUT_IN_SESSIONS", {
				sessionId,
				disableChatInput: true,
			}, { root: true });
			commit("Classroom/DELETE_SESSIONS", {
				sessionId,
			}, { root: true });
			commit("Classroom/SET_LEFT_SIDEBAR_COMPONENT", {
				leftSidebarComponent: "sidebar-history",
			}, { root: true });
			commit("SET_IS_LOADED_IN_RECENT_SESSIONS", {
				sessionId,
				isLoaded: true,
			});
			dispatch("selectRecentSession", sessionId);

			const recentSessions = Object.keys(state.recentSessions);
			if (recentSessions.length < 10) {
				commit("SET_IS_SHOWING_SESSION_SKELETON_LOADING_STATE", {
					isShowingSessionSkeletonLoadingState: true,
				});
				await dispatch("getPastSessions", { isDefaultLoadingState: false });
			}
		} catch (e) {
			Sentry.captureException(e);
			throw e;
		} finally {
			commit("SET_IS_SHOWING_SESSION_SKELETON_LOADING_STATE", {
				isShowingSessionSkeletonLoadingState: false,
			});
		}
	},

	async loadMorePastSessions({ rootState, state, commit }, payload) {
		try {
			const params = {
				paginate: 10,
				page: payload.pageNumber,
			};
			const response = await SessionsAPI.getRecentStudentSessions(
				rootState.currentUser.id,
				params,
			);

			const recentSessions = response.data.data;

			for (const key in recentSessions) {
				if (
					state.recentSessions[key] === undefined ||
					state.recentSessions[key] === null
				) {
					recentSessions[key]["disableChatInput"] = true;
					recentSessions[key] = helpers.formatAllTopicStringsInASession(
						recentSessions[key],
					);

					commit("INSERT_RECENT_SESSIONS", {
						session: recentSessions[key],
					});
				}
			}
			return Promise.resolve();
		} catch (error) {
			return Promise.reject();
		}
	},
	/**
	 * Fetches the user's classrooms
	 *
	 * @param {Number} userId
	 * @returns {Promise<*>}
	 */
	async getUserSections({ rootState, commit }) {
		try {
			const response = await StudentsAPI.getUserClasses(rootState.currentUser.id);
			commit("SET_CURRENT_USER_SECTIONS", {
				currentUserSections: response.data.data,
			});
		} catch (e) {
			return Promise.reject(e);
		}
	},
	toggleEndSessionDialog({ commit }, payload) {
		commit("TOGGLE_END_SESSION_DIALOG", {
			isOpen: payload,
		});
	},
	toggleTransferSessionModal({ commit }, payload) {
		commit("TOGGLE_TRANSFER_SESSION_MODAL", {
			isOpen: payload,
		});
	},
	async getSubjects({ commit }) {
		try {
			const response = await StudentsAPI.getSubjects();
			commit({
				type: "SET_SUBJECTS",
				subjects: response.data.data,
			});
		} catch (e) {
			Sentry.captureException(e);
		}

	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
