<template>
	<OrigamiDialog
		title="Do you want to mark this as a false alarm?"
		description="This will be marked as a false alarm."
		primary-button-text="Yes, false alarm"
		secondary-button-text="Cancel"
		:is-open="isOpen"
		:is-loading="isUpdating"
		@confirm="updateActionItem"
		@cancel="closeDialog"
	/>
</template>

<script>
import { mapState } from "vuex";
import { OrigamiDialog } from "@origami/vue2";

import { getErrorText, showErrorModal } from "../../../utilities/errorHandlingHelpers.js";
import { FALSE_ALARM_ACTION_ID } from "../utilities/actionCenterHelpers.js";

export default {
	components: {
		OrigamiDialog,
	},
	props: {
		isOpen: {
			required: true,
			type: Boolean,
		},
	},
	data() {
		return {
			isUpdating: false,
		};
	},
	computed: {
		...mapState(["currentUser", "PlatformManager", "Toaster"]),
		actionItem() {
			return this.PlatformManager.ActionCenter.selectedActionItem;
		},
	},
	methods: {
		closeDialog() {
			this.$store.commit("PlatformManager/ActionCenter/CLOSE_FALSE_ALARM_DIALOG");
		},
		async updateActionItem() {
			this.isUpdating = true;
			const updatePayload = {
				id: this.actionItem.id,
				params: {
					action_type_id: FALSE_ALARM_ACTION_ID,
					picked_up_user_id: this.currentUser.id,
				},
			};
			const resolvePayload = {
				id: this.actionItem.id,
				params: {
					picked_up_user_id: this.currentUser.id,
					resolved: true,
				},
			};
			try {
				await this.$store.dispatch("PlatformManager/ActionCenter/updateActionItem", updatePayload);
				await this.$store.dispatch("PlatformManager/ActionCenter/updateActionItem", resolvePayload);
				this.showToaster();
			} catch (error) {
				const message = getErrorText("marking the action item as a false alarm");
				showErrorModal(this, {
					message,
				});
			} finally {
				this.closeDialog();
				this.isUpdating = false;
			}
		},
		showToaster() {
			this.$store.dispatch("Toaster/showToaster", {
				text: "This action item has successfully been marked as a false alarm",
			});
		},
	},
};
</script>
