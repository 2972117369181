var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { attrs: { id: "main-content" } },
    [
      _c("GenericSettings", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "generic-settings",
            expression: "'generic-settings'",
          },
        ],
      }),
      _vm._v(" "),
      _c("FooterPrivacyPolicy"),
      _vm._v(" "),
      _c(
        "NotificationSnackbar",
        {
          attrs: {
            textcontent: _vm.snackbarText,
            notificationtype: _vm.snackbarType,
          },
          on: {
            "snackbar-close": function ($event) {
              return _vm.$store.dispatch("Snackbar/resetSnackbar")
            },
          },
        },
        [
          _c("template", { slot: "icon" }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.snackbarIcon) + "\n\t\t"),
          ]),
          _vm._v(" "),
          _c("template", { slot: "prompt" }, [_vm._v("\n\t\t\t×\n\t\t")]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }