<template>
	<div>
		<div
			v-if="isPageLoading"
			class="tw-w-full tw-h-screen tw-flex tw-flex-col tw-items-center tw-p-8 tw-tracking-wide tw-border-b-2 tw-border-grey-pale"
		>
			<BaseLoader class="tw-h-16 tw-w-16 tw-text-blue-regular tw-mb-4" />
		</div>
		<OrigamiGrid
			v-else
			class="tw-mx-4 lg:tw-mx-0"
		>
			<div
				column-start="lg:3"
				column-span="4 md:12 lg:8"
				class="tw-w-full"
			>
				<SubmissionFormHeader />
				<SubmissionFormUpload
					:uploaded-essay="uploadedEssay"
					:essay-file-doc-validator="essayFileDocValidator"
					:essay-file-mime-types="essayFileMimeTypes"
					:file-upload-error="fileUploadError"
					:embedded="embedded"
					:handle-essay-file-input="handleEssayFileInput"
					:handle-file-upload-error="handleFileUploadError"
					:handle-google-picker-essay-file-input="handleGooglePickerEssayFileInput"
					:open-google-picker="openGooglePicker"
				/>
				<!-- submission details -->
				<div class="tw-text-lg tw-font-bold tw-my-4">
					{{ $t("newWork") }}
				</div>
				<div class="tw-mb-4">
					{{ $t("submissionDetailsTitle") }}
				</div>
				<div
					v-if="!embedded"
					v-jest="'submissionDetailsText'"
					class="tw-mb-4"
				>
					{{ $t("submissionDetailsText") }}
				</div>
				<!-- form inputs (title, language, class(if applicable), type, teacher instructions, questions) -->
				<form>
					<!-- essay title -->
					<label
						for="essayTitle"
						class="tw-text-sm tw-font-bold"
					>
						{{ $t("titleOfYourUploadedWork") }} *
					</label>
					<BaseInput
						v-model="essayTitle"
						v-data-cy="'essay-title'"
						type="text"
						:variant="isTitleErrorShowing ? 'warning' : 'default'"
						:placeholder="$t('essayTitlePlaceholder')"
					/>
					<span
						v-show="isTitleErrorShowing"
						v-jest="'title-error'"
						class="tw-text-sm tw-font-bold tw-text-origami-red-400"
					>
						{{ $t("titleSelectionRequired") }}
					</span>
					<!-- dropdowns (language, class, type) -->
					<div class="tw-items-end lg:tw-flex lg:tw-flex-row lg:tw-grid lg:tw-grid-cols-12 tw-gap-4 tw-mb-2 tw-pt-4">
						<SubmissionFormSubmissionType
							v-model="submissionType"
							v-jest="'submission-type-dropdown'"
							:error="isSubmissionTypeErrorShowing"
							class="lg:tw-flex-1 lg:tw-mb-0 tw-mb-4"
							:class="isClassroomsEmpty ? 'tw-col-span-6' : 'tw-col-span-4'"
						/>
						<SubmissionFormClassroom
							v-if="!isClassroomsEmpty"
							v-model="selectedClassroom"
							v-jest="'classroom-dropdown'"
							class="lg:tw-flex-1 lg:tw-mb-0 tw-mb-4 tw-col-span-4"
							:error="isClassroomErrorShowing"
						/>
						<SubmissionFormLanguage
							v-model="essayLanguage"
							v-jest="'language-dropdown'"
							class="lg:tw-flex-1"
							:class="isClassroomsEmpty ? 'tw-col-span-6' : 'tw-col-span-4'"
							:error="isLanguageErrorShowing"
						/>
					</div>

					<InstructionFilesUpload
						:doc-validator="essayFileDocValidator"
						:instruction-files="teacherInstructionFiles"
						:instruction-file-mime-types="instructionsFileMimeTypes"
						:embedded="embedded"
						:file-errors="instructionFilesErrors"
						@set-instruction-files="setTeacherInstructionFiles"
						@update-instructions="updateWrittenInstructions"
						@set-errors="updateInstructionFilesErrors"
						@clear-errors="instructionErrors = []"
					/>
					<SubmissionFormQuestions
						v-model="essayComments"
						v-jest="'questions'"
						:is-essay-comments-error-showing="isEssayCommentsErrorShowing"
					/>
				</form>
				<div
					v-jest="'essay-eta'"
					class="essayReview__eta tw-w-full tw-text-sm tw-text-center tw-py-4 tw-px-5 tw-rounded-t-md tw-rounded-b-md tw-border-2 tw-border-blue-regular"
				>
					{{ $t("submissionReviewETA") }}
					<span
						v-jest="'essay-eta-string'"
						class="tw-font-bold"
					> {{ estimatedReviewTimeString }}</span>
				</div>
				<div
					v-show="!isErrorsEmpty"
					v-cloak
					class="alert alert-danger tw-mt-2"
				>
					<ul>
						<li
							v-for="(error, index) in errors"
							:key="index"
						>
							{{ error }}
						</li>
					</ul>
				</div>
				<div class="submitButtons tw-flex tw-flex-row-reverse tw-h-10 tw-mt-10 tw-mb-6">
					<OrigamiButton
						v-data-cy="'submit-essay-btn'"
						v-jest="'submit-essay-btn'"
						class="tw-ml-2"
						variant="primary"
						:is-loading="isSubmittingEssay"
						@click.native="!isSubmittingEssay && submitEssayForReview()"
					>
						{{ $t(embedded ? "submit" : "reviewMyEssay") }}
					</OrigamiButton>
					<OrigamiButton
						v-jest="'cancel-upload'"
						variant="secondary"
						@click.native="cancelNewEssay()"
					>
						{{ $t(embedded ? "clear" : "cancel") }}
					</OrigamiButton>
				</div>
			</div>
		</OrigamiGrid>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { OrigamiButton, OrigamiGrid } from "@origami/vue2";

import { pickerPopupDimensions } from "@/pages/default/googlePicker.vue";
import { UPLOAD_ERROR_KEYS, getLocalizedErrorSubKey } from "@/components/GooglePicker.vue";
import BaseLoader from "@/components/elements/BaseLoader.vue";
import BaseInput from "@/components/elements/BaseInput.vue";
import { sendMessageToExtension, CHROME_EXTENSION_EVENTS } from "@/modules/EmbeddedUtilities/index.js";
import { TAGS, getFilteredFileTypeIds, formatFileTypeIdsAsExtensionsRegex, formatFileTypeIdsAsMimeTypes } from "@/utilities/fileTypes.js";
import { ESSAY_FILE_SOURCES, REQUEST_SOURCES, EMBEDDING_DOMAIN } from "@/utilities/trackRequests.js";
import { getEssayETA } from "@/utilities/essayHelpers.js";
import { openLinkInPopup } from "@/utilities/links.js";

import InstructionFilesUpload from "./InstructionFilesUpload.vue";
import SubmissionFormLanguage from "./submissionForm/SubmissionFormLanguage.vue";
import SubmissionFormHeader from "./submissionForm/SubmissionFormHeader.vue";
import SubmissionFormUpload from "./submissionForm/SubmissionFormUpload.vue";
import SubmissionFormSubmissionType from "./submissionForm/SubmissionFormSubmissionType.vue";
import SubmissionFormClassroom from "./submissionForm/SubmissionFormClassroom.vue";
import SubmissionFormQuestions from "./submissionForm/SubmissionFormQuestions.vue";

const FILE_SIZE_LIMIT = 6081740;

export default {
	components: {
		OrigamiButton,
		OrigamiGrid,
		BaseLoader,
		BaseInput,
		InstructionFilesUpload,
		SubmissionFormLanguage,
		SubmissionFormHeader,
		SubmissionFormUpload,
		SubmissionFormSubmissionType,
		SubmissionFormClassroom,
		SubmissionFormQuestions,
	},
	props: {
		embedded: {
			type: Boolean,
			default: false,
		},
	},
	emits: ["submit"],
	data() {
		return {
			isTitleErrorShowing: false,
			isLanguageErrorShowing: false,
			isClassroomErrorShowing: false,
			isEssayCommentsErrorShowing: false,
			instructionFileUploadError: UPLOAD_ERROR_KEYS.NONE,
			fileUploadError: UPLOAD_ERROR_KEYS.NONE,
			isSubmittingEssay: false,
			essayTitle: "",
			essayLanguage: 1,
			selectedClassroom: null,
			teacherInstructions: "",
			essayComments: "",
			estimatedReviewTime: null,
			isEssayFromGoogleDrive: null,
			featureFlagTrackingEssayMetadataEnabled: false,
			submissionType: null,
			isSubmissionTypeErrorShowing: false,
			isPageLoading: true,
			errors: [],
			teacherInstructionFiles: [],
			instructionFilesErrors: [],
			featureFlagCanvasIntegrationIsEnabled: false,
			featureFlagCanvasTrackEssayMetadataIsEnabled: false,
			featureFlagUploadTrackingIsEnabled: false,
		};
	},
	computed: {
		...mapState(["currentUser", "Student"]),
		uploadedEssay() {
			return this.Student.Essays.uploadedEssay;
		},
		submissionTypes() {
			return this.Student.Essays.submissionTypes;
		},
		essayFile: {
			get() {
				return this.uploadedEssay;
			},
			set(uploadedEssay) {
				this.$store.commit("Student/Essays/SET_UPLOADED_ESSAY", {
					uploadedEssay,
				});
			},
		},
		isErrorsEmpty() {
			return this.errors.length <= 0;
		},
		/**
        * checks if the student has sections
        * @returns {Boolean}
        */
		isClassroomsEmpty() {
			return this.Student.Essays.sections.length <= 0;
		},
		isThereClassroomsAndNotSelected() {
			return !this.isClassroomsEmpty && this.selectedClassroom === null;
		},
		isValidFileSize() {
			return this.uploadedEssay !== null ? this.uploadedEssay.size > 0 : false;
		},
		estimatedReviewTimeString() {
			const { hours, minutes } = getEssayETA(this.estimatedReviewTime);
			return this.$t("timeLeft", { hours: minutes >= 30 ? hours + 1 : hours });
		},
		fileUploadFileTypeIds() {
			return getFilteredFileTypeIds({
				includeTags: [TAGS.TEXT],
			});
		},
		instructionsFileUploadFileTypeIds() {
			return getFilteredFileTypeIds({
				includeTags: [TAGS.TEXT, TAGS.IMAGE],
			});
		},
		essayFileMimeTypes() {
			return formatFileTypeIdsAsMimeTypes(this.fileUploadFileTypeIds);
		},
		instructionsFileMimeTypes() {
			return formatFileTypeIdsAsMimeTypes(this.instructionsFileUploadFileTypeIds);
		},
		instructionsFileUploadErrorMessage() {
			if (!this.instructionsFileUploadError) {
				return null;
			}
			return this.$t(`errors.${this.instructionsFileUploadError}`);
		},
	},
	watch: {
		essayTitle() {
			this.isTitleErrorShowing = false;
		},
		essayLanguage() {
			this.isLanguageErrorShowing = false;
		},
		selectedClassroom() {
			this.isClassroomErrorShowing = false;
		},
		submissionType() {
			this.isSubmissionTypeErrorShowing = false;
		},
		essayComments() {
			this.isEssayCommentsErrorShowing = false;
		},
		submissionTypes() {
			const { submission_type_key } = this.$route.query;
			const submissionType =
				this.submissionTypes?.find((submissionType) => submissionType.key === submission_type_key);
			if (submissionType) {
				this.submissionType = submissionType.id;
			}
		},
	},
	async created() {
		try {
			this.estimatedReviewTime = await this.$store.dispatch("Student/Essays/getEstimatedReviewTime");
			this.featureFlagTrackingEssayMetadataEnabled = await this.$getFlag("PE-8323-track-essays-submit-google-drive");
			this.featureFlagCanvasTrackEssayMetadataIsEnabled = await this.$getFlag("CORE-2309-track-canvas-assignment-essays");
			this.featureFlagCanvasIntegrationIsEnabled = await this.$getFlag("GROW-882-canvas-assignment-linking");
			this.featureFlagUploadTrackingIsEnabled = await this.$getFlag("SUP-2691-track-student-upload");
			if (this.featureFlagCanvasIntegrationIsEnabled && this.$route.query?.title) {
				this.essayTitle = this.$route.query.title;
			}
		} finally {
			this.isPageLoading = false;
		}
	},
	methods: {
		setTeacherInstructionFiles(files) {
			this.teacherInstructionFiles = files;
		},
		updateInstructionFilesErrors(errors) {
			this.instructionFilesErrors = errors;
		},
		updateWrittenInstructions(value) {
			this.teacherInstructions = value;
		},
		checkIsFileValid(fileArray) {
			const validFileRegex = formatFileTypeIdsAsExtensionsRegex(this.fileUploadFileTypeIds);
			let isValid = true;
			if (!validFileRegex.test(fileArray[0].name) || fileArray[0].size > FILE_SIZE_LIMIT) {
				isValid = false;
			}
			return isValid;
		},
		getFileInvalidType(fileArray) {
			const validFileRegex = formatFileTypeIdsAsExtensionsRegex(this.instructionsFileUploadFileTypeIds);
			let errorType = "";
			if (!validFileRegex.test(fileArray[0].name)) {
				errorType = UPLOAD_ERROR_KEYS.INVALID;
			} else if (fileArray[0].size > FILE_SIZE_LIMIT) {
				errorType = UPLOAD_ERROR_KEYS.TOO_LARGE;
			}
			return errorType;
		},
		handleEssayFileInput(e) {
			e.preventDefault();
			const files = e.dataTransfer === undefined ? e.target.files : e.dataTransfer.files;

			if (this.checkIsFileValid(files)) {
				this.essayFile = files[0];
				this.fileUploadError = UPLOAD_ERROR_KEYS.NONE;
			} else {
				this.fileUploadError = this.getFileInvalidType(files);
			}
			this.isEssayFromGoogleDrive = false;
		},
		handleGooglePickerTeacherInstructionsUpload() {
			this.instructionsFileUploadError = UPLOAD_ERROR_KEYS.NONE;
		},
		handleGooglePickerEssayFileInput(file) {
			this.essayFile = file;
			this.fileUploadError = UPLOAD_ERROR_KEYS.NONE;
			this.isEssayFromGoogleDrive = true;
		},
		essayFileDocValidator(file) {
			if (!this.checkIsFileValid([file])) {
				throw new Error(UPLOAD_ERROR_KEYS.INVALID);
			}
		},
		handleGooglePickerTeacherInstructionsUploadError(error) {
			this.instructionsFileUploadError = getLocalizedErrorSubKey(error);
		},
		handleFileUploadError(error) {
			this.fileUploadError = getLocalizedErrorSubKey(error);
			Sentry.captureException(this.fileUploadError);
		},
		cancelNewEssay() {
			this.$store.commit("Student/Essays/SET_ESSAY_UPLOAD_PROGRESS", {
				essayUploadProgress: 0,
			});
			this.$store.commit("Student/Essays/SET_IS_ESSAY_UPLOADED_AND_NOT_SUBMITTED", {
				isEssayUploadedAndNotSubmitted: false,
			});
			this.resetSubmissionForm();
			if (!this.embedded) {
				this.$router.push({ name: "reviewCenter" });
			}
		},
		async submitEssayForReview() {
			this.isSubmittingEssay = true;
			this.errors = [];
			try {
				// check that uploaded file has content (not 0kb)
				if (!this.isValidFileSize) {
					this.fileUploadError = UPLOAD_ERROR_KEYS.NO_FILE_SELECTED;
				}
				// check title, language and class entries to show error
				if (this.essayTitle == "") {
					this.isTitleErrorShowing = true;
				}
				if (this.essayComments == "") {
					this.isEssayCommentsErrorShowing = true;
				}
				if (this.essayLanguage == null) {
					this.isLanguageErrorShowing = true;
				}
				if (this.isThereClassroomsAndNotSelected) {
					this.isClassroomErrorShowing = true;
				}
				if (this.submissionType == null) {
					this.isSubmissionTypeErrorShowing = true;
				}
				// make sure nothing happens if required fields not filled by user
				if (
					this.uploadedEssay == null ||
					this.essayTitle == "" ||
					this.essayComments == "" ||
					this.essayLanguage == null ||
					this.isThereClassroomsAndNotSelected ||
					this.submissionType == null
				) {
					return;
				}

				const data = new FormData();
				data.append("student_id", this.currentUser.id);
				data.append("title", this.essayTitle);
				data.append("file", this.uploadedEssay);
				data.append("language_id", this.essayLanguage.toString());
				data.append("student_comment", this.essayComments);
				data.append("request_source", this.embedded ? REQUEST_SOURCES.GOOGLE_EXTENSION : REQUEST_SOURCES.WEB_APP);

				if (
					this.featureFlagCanvasIntegrationIsEnabled &&
					this.$route.query?.request_source === REQUEST_SOURCES.CANVAS
				) {
					data.set("request_source", REQUEST_SOURCES.CANVAS);
				}
				if (this.teacherInstructionFiles.length) {
					this.teacherInstructionFiles.forEach((file) => {
						data.append("teacher_instruction_files[]", file);
					});
				}
				if (this.teacherInstructions != "") {
					data.append("teacher_instruction", this.teacherInstructions);
				}
				if (this.selectedClassroom !== null && this.selectedClassroom !== 0) {
					data.append("section_id", this.selectedClassroom);
				}
				if (this.submissionType != null) {
					data.append("review_submission_type_id", this.submissionType);
				}
				if (this.featureFlagTrackingEssayMetadataEnabled) {
					const request_writing_review = {
						request_metadata: {
							file_source: this.isEssayFromGoogleDrive ?
								ESSAY_FILE_SOURCES.GOOGLE_DRIVE :
								ESSAY_FILE_SOURCES.FILE_SYSTEM,
						},
					};
					if (this.embedded) {
						const { url, title } = await sendMessageToExtension({
							type: CHROME_EXTENSION_EVENTS.PAGE_INFO,
						});
						request_writing_review.request_metadata.request_url = url;
						request_writing_review.request_metadata.request_source_title = title;
						request_writing_review.request_metadata.request_source = REQUEST_SOURCES.GOOGLE_EXTENSION;
						request_writing_review.request_metadata.request_user_agent = window.navigator.userAgent;
					}
					data.append("request_writing_review", JSON.stringify(request_writing_review));
				}

				if (
					this.featureFlagCanvasTrackEssayMetadataIsEnabled &&
					this.$route.query?.request_source === REQUEST_SOURCES.CANVAS
				) {
					const request_writing_review = {
						request_metadata: {
							embedding_domain: EMBEDDING_DOMAIN.CANVAS,
							embedding_domain_content_id: this.$route.query?.embedding_domain_content_id ?? null,
							embedding_domain_instance_id: this.$route.query?.embedding_domain_instance_id ?? null,
						},
					};
					data.append("request_writing_review", JSON.stringify(request_writing_review));
				}
				if (this.featureFlagUploadTrackingIsEnabled) {
					data.append("origin_type", this.essayFile.originalType || this.essayFile.type);
					data.append("origin_source",
						this.isEssayFromGoogleDrive
							? ESSAY_FILE_SOURCES.GOOGLE_DRIVE
							: ESSAY_FILE_SOURCES.FILE_SYSTEM,
					);
				}

				if (this.$route.query?.embedding_domain) {
					const request_writing_review = {
						request_metadata: {
							embedding_domain: this.$route.query.embedding_domain,
						},
					};
					data.append("request_writing_review", JSON.stringify(request_writing_review));
				}

				const submittedEssay = await this.$store.dispatch("Student/Essays/createEssay", data);
				if (this.embedded) {
					this.$store.dispatch("Toaster/showToaster", { text: this.$t("essay_submitted") });
					this.$store.commit("Student/Dashboard/SET_ESSAYS_UNDOWNLOADED", {
						undownloadedEssays: [submittedEssay, ...this.Student.Dashboard.undownloadedEssays],
					});
					this.resetSubmissionForm();
					window.scrollTo(0, 0, { behavior: "smooth" });
					return;
				}

				this.resetSubmissionForm();
				this.$emit("submit");
				this.$router.push({ name: "reviewCenter" });
			} catch (error) {
				if (error.response?.status === 400) {
					this.errors.push(error.response.data.error_description);
					this.isSubmittingEssay = false;
				} else if (error.response?.status === 422) {
					const errorDescription = error.response.data.error_description;
					for (const errorType in errorDescription) {
						this.errors.push(...errorDescription[errorType]);
					}
					this.isSubmittingEssay = false;
				} else {
					Sentry.captureException(error);
				}
			} finally {
				this.isSubmittingEssay = false;
			}
		},
		resetSubmissionForm() {
			this.essayTitle = "";
			this.$store.commit("Student/Essays/SET_UPLOADED_ESSAY", { uploadedEssay: null });
			this.essayLanguage = null;
			this.essayComments = "";
			this.teacherInstructions = "";
			this.teacherInstructionFiles = [];
			this.selectedClassroom = null;
			this.submissionType = null;
			this.fileUploadError = UPLOAD_ERROR_KEYS.NONE;
			this.instructionFileUploadError = UPLOAD_ERROR_KEYS.NONE;
			this.instructionFilesErrors = [];
			this.isEssayFromGoogleDrive = null;
		},
		async openGooglePickerInPopup(fileType) {
			try {
				const fileTypeData = {
					essay: {
						popupLink: "/google-picker",
						handleUploadErrorMethod: this.handleFileUploadError,
						handleFileUploadMethod: this.handleGooglePickerEssayFileInput,
					},
					instructions: {
						popupLink: "/google-picker?is_instructions=true",
						handleUploadErrorMethod: this.handleGooglePickerInstructionsError,
						handleFileUploadMethod: this.handleGooglePickerInstructionsUpload,
					},
				};
				const { file, error } = await openLinkInPopup(fileTypeData[fileType].popupLink, pickerPopupDimensions);

				if (error) {
					fileTypeData[fileType].handleUploadErrorMethod(error);
					throw error;
				} else if (file) {
					fileTypeData[fileType].handleFileUploadMethod(file);
				}
			} catch (e) {
				Sentry.captureException(e);
			}
		},
		async openGooglePicker(triggerGooglePicker, fileType) {
			if (this.embedded) {
				await this.openGooglePickerInPopup(fileType);
			} else {
				triggerGooglePicker();
			}
		},
	},
};
</script>
<style scoped>
.essayReview__eta {
	background-color: rgba(14, 15, 224, 0.1);
}
@media screen and (max-width:450px) {
	.submitButtons {
		margin: 1rem 0;
	}
}
:deep(.picker-dialog) {
	z-index: 10010 !important; /* z-index of student prompts 99999 + 11 to cover it with wiggle room */
}
:deep(#essayFile__BV_file_outer_) {
	border: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
</style>
