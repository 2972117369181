export default async function(to, from, next) {
	const currentUser = this.state.currentUser;
	if (to.name === "superuserActivity") {
		if (currentUser.roles.includes("superuser")) {
			return next();
		}
		if (currentUser.roles.includes("tutor-manager")) {
			return next({
				path: "/tutor-manager/activity",
				query: to.query,
			});
		}
	}
	return next();
}
