import api from "./Api.js";

export default {
	/**
	 * Get all topics
	 *
	 * @arg {object} params
	 * @returns {AxiosPromise}
	 */
	list(params) {
		return api().get(`topics`, {
			params: params,
		});
	},

	/**
	 * Add topics to tutor account
	 * Topics in the request will replace all the topics for that tutor.
	 *
	 * @params {Array} topic_ids Ids of topics to add to tutor account.
	 * @returns {AxiosPromise}
	 */
	setTutorTopics(userId, data) {
		return api().post(`tutors/${userId}/topics`, data);
	},
	/**
	 * Get topics of a subject
	 *
	 * @params {Number}
	 * @return {AxiosPromise}
	 */
	getTopicsOfSubject(subjectId) {
		return api().get(`subjects/${subjectId}/topics`);
	},
	/**
	 * Get topics of a subject
	 *
	 * @params {Number}
	 * @return {AxiosPromise}
	 */
	getConceptsOfTopic(topicId) {
		return api().get(`topics/${topicId}/concepts`);
	},
};
