/**
 * @param {Object} dispatch
 * @param {String} actionName
 * @param {Number} time
 * 
 * IMPORTANT: remember to clear the interval from the component when the component is unmounted/destroyed
 */
export const callActionAtInterval = (dispatch, actionName, time) => {
	dispatch(actionName);
	const intervalId = setInterval(() => {
		try {
			dispatch(actionName);
		} catch (error) {
			Sentry.captureException(error);
		}
	}, time);
	return () => clearInterval(intervalId);
};
