var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M19 3.9H18V1.875H16V3.9H8V1.875H6V3.9H5C3.89 3.9 3.01 4.81125 3.01 5.925L3 20.1C3 21.2138 3.89 22.125 5 22.125H19C20.1 22.125 21 21.2138 21 20.1V5.925C21 4.81125 20.1 3.9 19 3.9ZM8.99998 10.9875H6.99998V13.0125H8.99998V10.9875ZM13 10.9875H11V13.0125H13V10.9875ZM15 10.9875H17V13.0125H15V10.9875ZM4.99999 20.1H19V8.96249H4.99999V20.1Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }