var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col-md-12 section__divider tw-p-0 tw-pt-6" },
    [
      _c(
        "h3",
        {
          staticClass:
            "tw-font-extrabold tw-text-black-high tw-text-2xl tw-mb-6",
        },
        [_vm._v("\n\t\tPayroll Details\n\t")]
      ),
      _vm._v(" "),
      _c(
        "form",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "submit-payroll-form",
              expression: "'submit-payroll-form'",
            },
          ],
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitPayrollDetails.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "payrollDetails__inputContainer tw-flex tw-flex-col tw-items-end tw-w-full",
            },
            [
              _c("BaseFormField", {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "adp-input",
                    expression: "'adp-input'",
                  },
                ],
                staticClass: "tw-w-full",
                attrs: {
                  id: "adp_number",
                  type: "text",
                  label: "ADP File ID",
                  placeholder: "Enter the ADP File ID",
                },
                model: {
                  value: _vm.adpNumber,
                  callback: function ($$v) {
                    _vm.adpNumber = $$v
                  },
                  expression: "adpNumber",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "payrollDetails__inputContainer tw-flex tw-flex-col tw-items-end tw-w-full",
            },
            [
              _c(
                "BaseDropdown",
                {
                  ref: "employmentLocationDropdown",
                  staticClass: "form-group tw-w-full",
                  attrs: {
                    items: _vm.countries,
                    label: "Employment Country",
                    placeholder: "Select an Employment Country",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "BaseRadio",
                            {
                              attrs: {
                                "radio-name": "employmentLocation",
                                "radio-value": item.value,
                                "radio-id": `${item.value}`,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.selectCountry()
                                },
                              },
                              model: {
                                value: _vm.employmentLocation,
                                callback: function ($$v) {
                                  _vm.employmentLocation = $$v
                                },
                                expression: "employmentLocation",
                              },
                            },
                            [
                              _c("template", { slot: "text" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(item.text) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ]),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "filters" }, [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.getCountryName(_vm.employmentLocation)) +
                        "\n\t\t\t\t"
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "payrollDetails__inputContainer tw-flex tw-flex-col tw-items-end tw-w-full",
            },
            [
              _c(
                "BaseDropdown",
                {
                  ref: "employmentRegionDropdown",
                  staticClass: "form-group tw-w-full",
                  attrs: {
                    items: _vm.regions,
                    label: "Employment Region",
                    default: "Select an Employment Region",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "BaseRadio",
                            {
                              attrs: {
                                "radio-name": "employmentRegion",
                                "radio-value": item.value,
                                "radio-id": `${item.value}`,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.closeOnSelect(
                                    "employmentRegionDropdown"
                                  )
                                },
                              },
                              model: {
                                value: _vm.employmentRegion,
                                callback: function ($$v) {
                                  _vm.employmentRegion = $$v
                                },
                                expression: "employmentRegion",
                              },
                            },
                            [
                              _c("template", { slot: "text" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(item.text) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ]),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "filters" }, [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.getRegionName(_vm.employmentRegion)) +
                        "\n\t\t\t\t"
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "payrollDetails__inputContainer tw-flex tw-mb-7 tw-items-center",
            },
            [
              _c("p", { staticClass: "tw-text-sm tw-leading-6 tw-pr-9" }, [
                _vm._v("\n\t\t\t\tHourly Paid\n\t\t\t"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tw-flex tw-gap-x-9" },
                [
                  _c(
                    "BaseRadio",
                    {
                      attrs: {
                        "radio-id": "isHourly-yes",
                        "radio-name": "isHourly-yes",
                        "radio-value": true,
                        value: _vm.isHourly,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.setIsHourly(true)
                        },
                      },
                    },
                    [
                      _c("template", { slot: "text" }, [
                        _vm._v("\n\t\t\t\t\t\tYes\n\t\t\t\t\t"),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "BaseRadio",
                    {
                      attrs: {
                        "radio-id": "isHourly-no",
                        "radio-name": "isHourly-no",
                        "radio-value": false,
                        value: _vm.isHourly,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.setIsHourly(false)
                        },
                      },
                    },
                    [
                      _c("template", { slot: "text" }, [
                        _vm._v("\n\t\t\t\t\t\tNo\n\t\t\t\t\t"),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group tw-overflow-auto" },
            [
              _c(
                "BaseButton",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "submit-payroll-button",
                      expression: "'submit-payroll-button'",
                    },
                  ],
                  staticClass: "pull-right tw-w-48",
                  attrs: {
                    type: "SUBMIT",
                    disabled: !_vm.isSubmitButtonEnabled,
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "text" },
                    [
                      _vm.isSubmitting
                        ? _c("LoadingRectangles", {
                            staticClass: "tw-text-blue-regular",
                          })
                        : _c("span", [
                            _vm._v(
                              "\n\t\t\t\t\t\tSubmit Payroll Details\n\t\t\t\t\t"
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-group tw-w-full" }, [
        _vm.isShowingUpdateSuccess
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "update-success-banner",
                    expression: "'update-success-banner'",
                  },
                ],
                staticClass: "alert alert-success",
              },
              [
                _vm._v(
                  "\n\t\t\tPayroll details were updated successfully!\n\t\t"
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isShowingUpdateError
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "update-error-banner",
                    expression: "'update-error-banner'",
                  },
                ],
                staticClass: "alert alert-danger",
              },
              [
                _vm._v(
                  "\n\t\t\tThere was a problem updating payroll details. Please try again.\n\t\t"
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }