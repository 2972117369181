import challengesApi from "./api.js";

export default {
	getChallengesData: async() => {
		return await challengesApi().get("current");
	},
	getTotalAchievements: async() => {
		return await challengesApi().get("completed/count");
	},
};
