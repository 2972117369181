<script>
import { mapState } from "vuex";

import TableAbstract from "@/components/TableAbstract.vue";

export default {
	extends: TableAbstract,
	computed: {
		...mapState(["PlatformManager"]),
	},
	methods: {
		async handleOrderByEvent(headerObject) {
			try {
				const orderDirection = this.determineNewOrderByState(headerObject.state);
				this.$store.dispatch("PlatformManager/ServiceAbsencesTable/getUpdatedTableHeaders", {
					headerValue: headerObject.value,
					newHeaderDirection: orderDirection,
				});

				this.$store.dispatch("PlatformManager/ServiceAbsencesTable/updateFilters", {
					order_by_dir: orderDirection,
					order_by: headerObject.value,
				});

				await this.$store.dispatch("PlatformManager/ServiceAbsencesTable/getFilteredBioBreakSummary", this.PlatformManager.ServiceAbsencesTable.currentPage);
			} catch (e) {
				Sentry.captureException(e);
			}
		},
		determineNewOrderByState(prevState) {
			switch (prevState) {
				case null:
				case "desc":
					return "asc";
				case "asc":
					return "desc";
				default:
					return null;
			}
		},
	},
};
</script>
