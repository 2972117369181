import InfoBlock from "./InfoBlock.js";

/**
 *
 * @param {String}  instructions
 * @param {Array}  	files
 */
function AssignmentInstructionsMessage({ text, files }) {
	this.id = Math.random()
		.toString(36)
		.replace(/[^a-z]+/g, "")
		.substr(0, 100);
	InfoBlock.call(this, {
		text,
		files,
	});
}
AssignmentInstructionsMessage.prototype = Object.create(InfoBlock.prototype);

export default AssignmentInstructionsMessage;
