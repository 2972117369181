<template>
	<BaseTooltip class="helpTooltip">
		<template #buttonContent>
			<span class="icon">
				<IconQuestionMark
					:icon-color="$root.$colors.C_WHITE"
					:width="'0.5rem'"
					:height="'0.5rem'"
				/>
			</span>
		</template>
		<template #tooltipContent>
			{{ helpText }}
		</template>
	</BaseTooltip>
</template>

<script>
import BaseTooltip from "./BaseTooltip.vue";
import IconQuestionMark from "./icons/IconQuestionMark.vue";

export default {
	components: { BaseTooltip, IconQuestionMark },
	props: {
		helpText: {
			required: true,
			type: String,
		},
	},
};
</script>

<style scoped>
.helpTooltip {
	margin: 0.25rem 0.75rem;
}
.icon {
	background: var(--c-gray);
	font-size: 0.6rem;
	padding: 0.1rem 0.25rem;
	border-radius: 50%;
}
</style>
