<template>
	<div
		v-click-outside="handleClickOutside"
		v-jest="'input-container'"
		class="tw-relative tw-flex tw-items-center tw-flex-nowrap tw-bg-white-high tw-text-black-high tw-rounded tw-border-2 tw-border-grey-dark tw-cursor-text"
		:class="[variant, { disabled, focused, 'hover:tw-bg-grey-pale': !focused }]"
		@click="$refs.input.focus()"
	>
		<slot name="prepend" />
		<input
			ref="input"
			v-jest="'input'"
			class="tw-flex tw-h-9 tw-w-full tw-text-inherit tw-text-xs tw-m-0 tw-px-4 tw-py-2 tw-border-0 tw-rounded tw-outline-none"
			:value="content"
			:type="type"
			v-bind="$attrs"
			:disabled="disabled"
			:aria-label="ariaLabel"
			:autofocus="autofocus"
			:placeholder="placeholder"
			@input="handleInput"
			@focus="focused = true"
			@blur="focused = false"
		>
		<slot name="append">
			<Check
				v-if="variant === 'success'"
				v-jest="'icon-check'"
				class="tw-text-green-regular tw-m-2"
			/>
			<Warning
				v-else-if="variant === 'warning'"
				v-jest="'icon-warning'"
				class="tw-text-origami-red-400 tw-m-2"
			/>
		</slot>
	</div>
</template>

<script>
import { Check, Warning } from "./icons/index.js";

export default {
	name: "BaseInput",
	components: {
		Check,
		Warning,
	},
	props: {
		value: {
			type: String,
			default: "",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		debounce: {
			type: Boolean,
			default: false,
		},
		debounceTime: {
			type: [String, Number],
			default: "300",
		},
		variant: {
			type: String,
			default: "default",
			validator: function(variant) {
				return ["default", "success", "warning"].includes(variant);
			},
		},
		type: {
			type: String,
			default: "text",
		},
		ariaLabel: {
			type: String,
			default: "",
		},
		autofocus: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: "",
		},
	},
	data: function(vm) {
		return {
			content: vm.value,
			debounceTimeout: null,
			focused: false,
		};
	},
	watch: {
		value(v) {
			this.content = v;
		},
	},
	beforeDestroy() {
		clearTimeout(this.debounceTimeout);
	},
	methods: {
		handleInput(e) {
			if (!this.debounce) {
				this.content = e.target.value;
				return this.$emit("input", this.content);
			}
			clearTimeout(this.debounceTimeout);
			this.debounceTimeout = setTimeout(() => {
				this.content = e.target.value;
				this.$emit("input", this.content);
			}, this.debounceTime);
		},
		handleClickOutside() {
			this.focused = false;
		},
	},
};
</script>

<style scoped>
input {
	background: transparent;
	cursor: inherit;
}

.disabled {
	background: var(--black-disabled);
	border-color: transparent;
	cursor: not-allowed;
}
.focused {
	border-color: var(--blue-regular);
}
.success {
	border-color: var(--green-regular);
}
.warning {
	border-color: var(--origami-red-400);
}
</style>
