var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BreakButton", {
    directives: [
      {
        name: "jest",
        rawName: "v-jest",
        value: "meal-break-button",
        expression: "'meal-break-button'",
      },
      {
        name: "data-cy",
        rawName: "v-data-cy",
        value: "meal-break-button",
        expression: "'meal-break-button'",
      },
    ],
    attrs: {
      "aria-label": "Go on meal break",
      "icon-name": "meal-break",
      "is-active": _vm.isOnMealBreakWorkflow,
      disabled: _vm.isDisabled,
      "show-notification": _vm.hasPendingMealBreakRequest || _vm.hasMealBreak,
    },
    nativeOn: {
      click: function ($event) {
        return _vm.requestMealBreak.apply(null, arguments)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }