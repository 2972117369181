<template>
	<div class="container">
		<div
			id="editTutor"
			class="row content tw-flex tw-justify-center tw-mb-48"
		>
			<div
				v-if="PlatformManager.TutorsEdit.tutor"
				v-jest="'redesign-container'"
				class="tw-pt-4"
			>
				<ServiceTutorEditPicture />
				<ServiceTutorEditTutorPersonalDetails
					ref="personalDetails"
					@on-personal-submit="isPersonalDetailsSubmitButtonEnabled = $event.isEnabled"
				/>
				<ServiceTutorEditExpertiseDetails
					ref="expertiseDetails"
					@on-expertise-submit="isExpertiseDetailsSubmitButtonEnabled = $event.isEnabled"
				/>
				<ServiceTutorEditSchedulingDetails
					ref="schedulingDetails"
					@on-scheduling-submit="isSchedulingDetailsSubmitButtonEnabled = $event.isEnabled"
				/>
				<ServiceTutorEditPayroll
					v-if="currentUser.role === 'superuser'"
					ref="payrollDetails"
					v-jest="'adp-field'"
					@on-payroll-submit="isPayrollDetailsSubmitButtonEnabled = $event.isEnabled"
				/>
				<div class="tw-mt-14 tw-flex tw-justify-center">
					<BaseButton
						ref="saveAllChanges"
						v-jest="'save-all-changes-button'"
						type="PRIMARY"
						class="tw-w-36"
						:is-disabled="!isSubmitAllChangesButtonEnabled"
						@click.native="submitAllChanges"
					>
						<template
							slot="text"
						>
							<LoadingRectangles
								v-if="isLoading"
								class="tw-text-blue-regular"
							/>
							<span
								v-else
							>
								Save All Changes
							</span>
						</template>
					</BaseButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
//store
import { mapState } from "vuex";

//mixins
import LoadingRectangles from "@/components/LoadingRectangles.vue";
import BaseButton from "@/components/BaseButton.vue";

import ServiceTutorDropdownMixins from "../mixins/ServiceTutorDropdownMixins.js";

//components
import ServiceTutorEditPicture from "./ServiceTutorEditPicture.vue";
import ServiceTutorEditTutorPersonalDetails from "./ServiceTutorEditTutorPersonalDetails.vue";
import ServiceTutorEditPayroll from "./ServiceTutorEditPayroll.vue";
import ServiceTutorEditExpertiseDetails from "./ServiceTutorEditExpertiseDetails.vue";
import ServiceTutorEditSchedulingDetails from "./ServiceTutorEditSchedulingDetails.vue";

export default {
	components: {
		ServiceTutorEditPicture,
		ServiceTutorEditTutorPersonalDetails,
		ServiceTutorEditPayroll,
		ServiceTutorEditExpertiseDetails,
		ServiceTutorEditSchedulingDetails,
		LoadingRectangles,
		BaseButton,
	},
	mixins: [ServiceTutorDropdownMixins],
	data() {
		return {
			isExpertiseDetailsSubmitButtonEnabled: false,
			isPayrollDetailsSubmitButtonEnabled: false,
			isPersonalDetailsSubmitButtonEnabled: false,
			isSchedulingDetailsSubmitButtonEnabled: false,
			isLoading: false,
		};
	},
	computed: {
		...mapState([
			"PlatformManager",
			"currentUser",
		]),
		isSubmitAllChangesButtonEnabled() {
			const isEnabled = (this.isExpertiseDetailsSubmitButtonEnabled ||
								this.isPayrollDetailsSubmitButtonEnabled ||
								this.isPersonalDetailsSubmitButtonEnabled ||
								this.isSchedulingDetailsSubmitButtonEnabled) &&
								!this.isLoading;
			this.$emit("save-all-changes", { isEnabled: isEnabled });
			return isEnabled;
		},
	},
	async created() {
		try {
			await Promise.all([
				this.$store.dispatch("PlatformManager/TutorsEdit/getSubjects"),
				this.$store.dispatch("PlatformManager/TutorsEdit/getLanguages"),
				this.$store.dispatch("PlatformManager/TutorsEdit/getTutor"),
				this.$store.dispatch("PlatformManager/TutorsEdit/getAllBlocks", {
					tutorId: this.PlatformManager.TutorsEdit.tutorUserId,
				}),
			]);
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	methods: {
		async submitAllChanges() {
			try {
				this.isLoading = true;
				const tutorInformationToBeSubmitted = [];
				if (this.isExpertiseDetailsSubmitButtonEnabled) {
					tutorInformationToBeSubmitted.push(this.$refs.expertiseDetails.submitDetails());
				}
				if (this.isPayrollDetailsSubmitButtonEnabled) {
					tutorInformationToBeSubmitted.push(this.$refs.payrollDetails.submitPayrollDetails());
				}
				if (this.isPersonalDetailsSubmitButtonEnabled) {
					tutorInformationToBeSubmitted.push(this.$refs.personalDetails.submitTutorProfileDetails());
				}
				if (this.isSchedulingDetailsSubmitButtonEnabled) {
					tutorInformationToBeSubmitted.push(this.$refs.schedulingDetails.saveSchedulingDetails());
				}
				await Promise.all(
					tutorInformationToBeSubmitted,
				);
			} catch (error) {
				Sentry.captureException(error);
			} finally {
				this.isLoading = false;
			}
		},
	},
};
</script>

<style scoped>
.topic.topicCheckbox > label {
	min-width: 18px;
	min-height: 18px;
	padding-left: 0;
}
.pull-right {
	float: right;
}

#editTutor {
	@apply tw-bg-origami-white;
}
</style>
