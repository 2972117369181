import middleware from "@/middleware/index.js";
import isConnected from "@/middleware/isConnected.js";
import hasRole from "@/middleware/hasRole.js";
import hasSchool from "@/middleware/hasSchool.js";
import initializePendo from "@/middleware/initializePendo.js";
import initFullStory from "@/middleware/initFullStory.js";
import isABQUser from "@/middleware/isABQUser.js";
import store from "@/store/index.js";
import { MICRO_FRONTENDS_NAMES } from "@/modules/MicroFrontend/utilities/index.js";
import redirectWhenFeatureFlagIsDisabled from "@/middleware/redirectWhenFeatureFlagIsDisabled.js";

import { EMBEDDED_ONLY } from "../utilities/index.js";

export default [
	{
		path: "/embedded",
		component: () => import(
			"../pages/index.vue"
		),
		beforeEnter: middleware([
			isConnected,
			initFullStory,
			(to, from, next) => {
				store.commit("SET_SHOW_NAVBAR", false);
				next();
			},
		]),
		meta: {
			embeddable: EMBEDDED_ONLY,
		},
		children: [
			{
				path: "",
				redirect: "/embedded/student",
			},
			{
				path: "student",
				component: () => import(
					"../pages/student.vue"
				),
				beforeEnter: middleware([
					hasRole("student"),
					hasSchool,
				]),
				children: [
					{
						path: "",
						redirect: "/embedded/student/sessions",
					},
					{
						path: "sessions",
						component: () => import(
							"../pages/student/sessions.vue"
						),
						meta: {
							unembeddedPath: "/dashboard",
						},
					},
					{
						path: "review-center",
						component: () => import(
							"../pages/student/reviewCenter.vue"
						),
						meta: {
							unembeddedPath: "/student/review-center",
						},
					},
					{
						path: "classroom",
						component: () => import(
							"../pages/student/classroom.vue"
						),
						meta: {
							unembeddedPath: "/student/classroom",
							noHeader: true,
							noHeaderTabs: true,
						},
					},
					{
						path: "start-session",
						component: () => import(
							"@/pages/student/startSession.vue"
						),
						beforeEnter: middleware([
							isABQUser,
						]),
						meta: {
							unembeddedPath: "/student/start-session",
						},
					},
					{
						path: "instant-feedback*",
						meta: {
							title: "Instant Feedback",
						},
						component: () => import("@/modules/MicroFrontend/components/MicroFrontend.vue"),
						beforeEnter: middleware([
							redirectWhenFeatureFlagIsDisabled("GROW-2514-instant-feedback", "/"),
						]),
						props: {
							microFrontendName: MICRO_FRONTENDS_NAMES.INSTANT_FEEDBACK,
						},
						meta: {
							noHeaderTabs: true,
						},
					},
				],
			},
			{
				path: "unembeddable",
				component: () => import(
					"../pages/unembeddable.vue"
				),
			},
		],
	},
	{
		path: "/embeddable-only",
		component: () => import(
			"../pages/embeddable-only.vue"
		),
		beforeEnter: middleware([
			isConnected,
			initializePendo,
			initFullStory,
			(to, from, next) => {
				store.commit("SET_SHOW_NAVBAR", false);
				next();
			},
		]),
	},
];
