import rootInstance from "@/services/root.js";

const educatorInsightsApi = () => {
	rootInstance.defaults.baseURL = process.env.MIX_EDUCATOR_INSIGHTS_API_BASE_URI + "/api/v1.0";
	return rootInstance;
};

export default {
	/**
	* Creates student group with its name
	* @param {String} name - represents the group name
	* @returns {AxiosPromise} - return group created information
	*/
	createStudentGroup: (name) =>
		educatorInsightsApi().post("/student-groups", { name })
			.then(({ data: { data: group } }) => ({ group }))
	,
	/**
	* Get student groups list with pagination
	* @returns {AxiosPromise} - return group list and pagination (25 groups by page)
	*/
	getStudentGroups: (params) =>
		educatorInsightsApi().get("/student-groups", { params })
			.then(({ data: { data: groups, meta: { pagination } } }) => (
				{ groups, pagination }
			))
	,
	/**
	* Get a specific group information
	* @param {object} groupId - represents the query to target a specific group
	* @returns {AxiosPromise} - return specific group information
	*/
	getStudentGroup: (groupId) =>
		educatorInsightsApi().get(`/student-groups/${groupId}`)
			.then(({ data: { data } }) => data)
	,
	/**
	* Updates a specific group name
	* @param {object} groupID - represents the query to target a specific group
	* @param {object} name - represents the new group name
	* @returns {AxiosPromise} - return updated group information
	*/
	updateStudentGroupName: (groupId, name) =>
		educatorInsightsApi().put(`/student-groups/${groupId}`, { name })
			.then(({ data: { data } }) => data)
	,
	/**
	* Deletes a specific group
	* @param {object} groupId - represents the query to target a specific group
	* @returns {AxiosPromise} - return success boolean
	*/
	deleteStudentGroup: (groupId) =>
		educatorInsightsApi().delete(`/student-groups/${groupId}`)
			.then(({ data: { success } }) => success)
	,
	/**
	* Updates users in a specific group (add/remove)
	* @param {object} groupId - represents the query to target a specific group
	* @param {object} userIds - represents the new list of user ID in the group
	* @returns {AxiosPromise} - return group list and pagination (25 groups by page)
	*/
	updateStudentGroupUsers: ({ groupId, userIds }) =>
		educatorInsightsApi().put(`/student-groups/${groupId}/users`, { user_ids: userIds })
			.then(({ data: { data } }) => data)
	,
	/**
	* Get all users that the current user can view, searchable
	* @param {object} query - pagination, search terms, filter params
	* @param {object} limit - number of possible objects returned
	* @returns {AxiosPromise} - returns a list of student users
	*/
	getUsers: ({ query, params }) =>
		educatorInsightsApi().get(`/student-groups/search_group_users/${query}`, { params })
			.then(({ data: { data: users, meta: { pagination } } }) => (
				{ users, pagination }
			))
	,
	/**
	* Get group users
	* @param {object} groupId - id of the group
	* @returns {AxiosPromise} - returns a list of student
	*/
	getGroupUsers: (groupId) =>
		educatorInsightsApi().get(`/student-groups/${groupId}/users`)
			.then(({ data: { data } }) => data)
	,
	/**
	* Get filters for student group page
	* @returns {AxiosPromise} - returns an object with schools, teachers, and subjects
	*/
	getGroupFilters: () =>
		educatorInsightsApi().get("/student-groups/filters")
			.then(({ data: { data } }) => data)
	,
};
