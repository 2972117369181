const cache = {};

export const cacheApiResponse = (apiName, methodName, callback, { refetch = false } = {}) => {
	if (!refetch && cache[apiName]?.[methodName]) {
		return cache[apiName]?.[methodName];
	}
	const response = callback?.();
	if (cache[apiName]) {
		cache[apiName][methodName] = response;
	} else {
		cache[apiName] = {
			[methodName]: response,
		};
	}
	return response;
};
