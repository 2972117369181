<template>
	<div class="tw-max-w-5xl tw-m-auto tw-mb-10">
		<div class="schedule__headerRow">
			<h4 class="schedule__headerName">
				Shift Schedule
			</h4>
		</div>
		<div class="schedule__headerContainer">
			<div class="tw-flex tw-justify-end">
				<BaseButton
					v-data-cy="'schedule-create-btn'"
					class="schedule__createShiftButton"
					type="SECONDARY"
					@click.native="$emit('open-create-shift-modal')"
				>
					<template slot="text">
						Create New Shift
					</template>
				</BaseButton>
			</div>
			<div class="schedule__filterContainer tw-w-4/5">
				<div class="tw-flex tw-mr-5 tw-w-56">
					<BaseDropdown
						v-data-cy="'schedule-language-dropdown'"
						:items="PlatformManager.ServiceTutorsSchedule.languageList"
						class="schedule__dropdown"
						label="Languages"
					>
						<template slot="filters">
							{{ languagesDropdownFilter }}
						</template>
						<template #default="{ item }">
							<BaseCheckbox
								v-model="selectedLanguages"
								v-data-cy="`schedule-language-dropdown-${item.index}`"
								:checkbox-id="`Languages-${item.id}`"
								:checkbox-value="item.id"
							>
								<template slot="text">
									{{ item.language }}
								</template>
							</BaseCheckbox>
						</template>
					</BaseDropdown>
				</div>
				<div class="tw-flex tw-mr-5 tw-w-56">
					<BaseDropdown
						v-data-cy="'schedule-shiftType-dropdown'"
						:items="PlatformManager.ServiceTutorsSchedule.scheduleTypesList"
						class="schedule__dropdown"
						label="Shift Type"
					>
						<template slot="filters">
							{{ scheduleTypeDropdownFilter }}
						</template>
						<template #default="{ item }">
							<BaseCheckbox
								v-model="selectedScheduleType"
								v-data-cy="`schedule-shiftType-dropdown-${item.index}`"
								:checkbox-id="`Schedule-${item.id}`"
								:checkbox-value="item.id"
							>
								<template slot="text">
									{{ getShiftTypeName(item) }}
								</template>
							</BaseCheckbox>
						</template>
					</BaseDropdown>
				</div>
				<div class="tw-flex tw-mr-5 tw-w-56">
					<BaseDropdown
						v-data-cy="'schedule-subjects-dropdown'"
						:items="PlatformManager.ServiceTutorsSchedule.subjectsList"
						class="schedule__dropdown"
						label="Subjects"
					>
						<template slot="filters">
							{{ subjectsDropdownFilter }}
						</template>
						<template #default="{ item }">
							<BaseCheckbox
								v-model="selectedSubjects"
								v-data-cy="`schedule-subjects-dropdown-${item.index}`"
								:checkbox-id="`Subjects-${item.id}`"
								:checkbox-value="item.id"
							>
								<template slot="text">
									{{ item.name }}
								</template>
							</BaseCheckbox>
						</template>
					</BaseDropdown>
				</div>
				<div class="tw-flex tw-mr-5 tw-w-56">
					<DropDown
						v-model="selectedTutorIds"
						v-data-cy="'schedule-tutorsList-dropdown'"
						label="Tutors"
						prompt-text="Select A Tutor"
						class="schedule__dropdown"
						is-multiple
						:items="PlatformManager.ServiceTutorsSchedule.tutorsList"
						:get-item-text="(item) => `${item.name} - ${item.id}`"
					/>
				</div>
			</div>

			<div class="schedule__buttonContainer">
				<BaseButton
					v-data-cy="'btn-schedule-show-results'"
					v-jest="'show-results-button'"
					class="schedule__submitSearchButton"
					type="SUBMIT"
					:disabled="isShowingResultsDisabled"
					@click.native="$emit('set-shift-filter', {
						selectedLanguages,
						selectedScheduleType,
						selectedSubjects,
						selectedTutors,
					})"
				>
					<template
						slot="text"
					>
						Show Results
					</template>
				</BaseButton>
				<BaseButton
					v-data-cy="'btn-schedule-clearFilters'"
					v-jest="'clear-filters-button'"
					class="schedule__submitResetButton"
					type="CANCEL"
					:disabled="!hasSelectedFilters"
					@click.native="resetFilters()"
				>
					<template
						slot="text"
					>
						Reset Filter
					</template>
				</BaseButton>
				<div
					v-show="isShowingShiftHours"
					class="schedule__totalHours"
				>
					<span class="schedule__shiftHours"> Shift Hours:</span>
					{{ Math.round(aggregateShiftHours) }}
				</div>
			</div>
			<div class="schedule__selectedTutors">
				<h5
					v-if="selectedTutors.length"
					class="schedule__shiftHours"
				>
					Selected Tutors
				</h5>
				<div
					class="schedule__tutorBubble"
				>
					<div
						v-for="(tutor, index) in selectedTutors"
						:key="index"
						class="schedule__selectedTutor"
						@click="removeFromSelectedTutors(tutor)"
					>
						{{ tutor.name }}
						<span class="schedule_selectedTutorDelete">X</span>&nbsp;
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapState } from "vuex";
import { isEmpty } from "lodash";

import { getShiftTypeNameFromShiftTypeId } from "@/utilities/ScheduleHelpers.js";
import BaseButton from "@/components/BaseButton.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";
import DropDown from "@/components/elements/DropDown.vue";

import shiftModalsMixin from "../mixins/shiftModalsMixin.js";

export default {
	components: {
		BaseButton,
		BaseCheckbox,
		BaseDropdown,
		DropDown,
	},
	mixins: [shiftModalsMixin],
	props: {
		aggregateShiftHours: {
			type: Number,
			required: true,
		},
		createdShiftTutorData: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		isShowingShiftHours: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			selectedLanguages: [],
			selectedScheduleType: [],
			selectedSubjects: [],
			selectedTutorIds: [],
		};
	},
	computed: {
		...mapState([
			"PlatformManager",
		]),
		isFilterSelected() {
			return (
				!isEmpty(this.selectedLanguages) ||
				!isEmpty(this.selectedScheduleType) ||
				!isEmpty(this.selectedSubjects) ||
				!isEmpty(this.selectedTutors)
			);
		},
		languagesDropdownFilter() {
			return this.selectedLanguages.length > 0
				? `Languages +${this.selectedLanguages.length}`
				: "Select A Language";
		},
		scheduleTypeDropdownFilter() {
			return this.selectedScheduleType.length > 0
				? `Schedule Type +${this.selectedScheduleType.length}`
				: "Select A Schedule Type";
		},
		subjectsDropdownFilter() {
			return this.selectedSubjects.length > 0
				? `Subjects +${this.selectedSubjects.length}`
				: "Select A Subject";
		},
		isShowingResultsDisabled() {
			return this.isSettingShiftFilter || !this.isFilterSelected;
		},
		hasSelectedFilters() {
			return [this.selectedLanguages, this.selectedScheduleType,
				this.selectedSubjects, this.selectedTutors].some((filter) => filter.length > 0);
		},
		selectedTutors() {
			if (isEmpty(this.selectedTutorIds)) {
				return [];
			}

			return this.PlatformManager.ServiceTutorsSchedule.tutorsList.filter(
				(tutor) => this.selectedTutorIds.includes(tutor.id));
		},
	},
	watch: {
		createdShiftTutorData(newVal) {
			if (!isEmpty(newVal)) {
				this.selectedTutorIds = newVal.map((tutor) => tutor.id);
			}
		},
	},
	methods: {
		resetFilters() {
			this.$emit("clear-filters");
			this.selectedLanguages = [];
			this.selectedScheduleType = [];
			this.selectedSubjects = [];
			this.selectedTutorIds = [];
		},
		removeFromSelectedTutors(tutor) {
			this.selectedTutorIds = this.selectedTutorIds.filter((tutorId) => tutorId !== tutor.id);
			this.$emit("set-selected-tutors-list", this.selectedTutors);
		},
		getShiftTypeName(shiftType) {
			return getShiftTypeNameFromShiftTypeId(shiftType.id);
		},
	},
};
</script>
<style scoped>
.schedule__headerName {
	font-size: 24px;
	font-weight: 600;
	color: var(--c-black);
}

.schedule__buttonContainer {
	margin-top: 15px;
	display: flex;
	flex-direction: row;
}

.schedule__filterContainer {
	display: flex;
	flex-direction: row;
}

.schedule__submitSearchButton {
	margin-right: 15px;
}

.schedule__dropdownLabel {
	font-size: 12px;
	color: var(--c-black);
	align-self: center;
	margin-right: 15px;
	font-weight: 600;
}

.schedule__selectedTutors {
	display: flex;
	flex-direction: column;
}

.schedule__selectedTutor {
	background-color: var(--c-light-gray);
	color: var(--c-black);
	padding: 5px 8px;
	margin: 4px 2px;
	cursor: pointer;
	border-radius: 16px;
}

.schedule__selectedTutor:hover {
	cursor: pointer;
	background-color: var(--c-gray);
}

.schedule__tutorBubble {
	display: flex;
	flex-direction: row;
}

.schedule__dropdown {
	width: 300px;
}

.schedule__headerContainer {
	display: flex;
	flex-direction: column;
}

.schedule__totalHours {
	margin-left: 15px;
	align-self: center;
}

.schedule__shiftHours {
	font-weight: 500;
}

.schedule_selectedTutorDelete {
	color: var(--c-black);
	font-weight: 700;
}

.schedule__headerRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
</style>
