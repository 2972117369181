<template>
	<div class="tw-flex tw-py-0 tw-justify-between">
		<div class="tw-flex tw-pt-8 tw-pb-0 tw-px-0">
			<BaseTutorImage
				:profile-path="selectedTutor.profile_picture"
				:full-tutor-name="selectedTutor.name"
				:dimensions="56"
				class="tw-w-min"
			/>
			<div class="tw-flex tw-flex-col tw-pl-4 tw-justify-center">
				<h3
					v-jest="'tutor-name-span'"
					class="tw-font-bold tw-text-lg"
				>
					{{ tutorName }}
				</h3>
				<p
					v-jest="'current-shift-details'"
					class="tw-text-xs"
				>
					{{ formattedCurrentShift }}
				</p>
			</div>
		</div>
		<ShiftSegmentScheduleTypeTag
			v-if="!isCurrentShiftDeleted"
			v-jest="'schedule-type-tag'"
			class="tw-mt-10"
			:shift-type="currentShiftScheduleTypeId"
		/>
	</div>
</template>
<script>
import { mapState } from "vuex";
import { isNil, isEmpty } from "lodash";
import { isAfter, isBefore } from "date-fns";

import { formatTimestampToHM, formatTimestampToDate } from "@/utilities/dateHelpers.js";
import { getTimeElapsedForShiftString } from "@/utilities/ScheduleHelpers.js";
import BaseTutorImage from "@/components/BaseTutorImage.vue";
import { ShiftSegmentScheduleTypeTag } from "@/modules/ServiceSchedule/index.js";

export default {
	components: {
		BaseTutorImage,
		ShiftSegmentScheduleTypeTag,
	},
	computed: {
		...mapState([
			"PlatformManager",
		]),
		selectedTutor() {
			return this.PlatformManager.ServiceSummaryDashboard.selectedTutor ?? {};
		},
		currentShift() {
			return this.PlatformManager.ServiceSummaryDashboard.selectedTutorSchedule.find(
				(shift) => isBefore(formatTimestampToDate(shift.start_time), new Date())
						&& isAfter(formatTimestampToDate(shift.end_time), new Date()))
				?? {};
		},
		tutorName() {
			return this.selectedTutor.name ?? "";
		},
		isCurrentShiftDeleted() {
			return isNil(this.currentShift) || isEmpty(this.currentShift);
		},
		formattedCurrentShift() {
			if (this.isCurrentShiftDeleted) {
				return "";
			}
			const startTime = formatTimestampToHM(this.currentShift?.start_time);
			const endTime = formatTimestampToHM(this.currentShift?.end_time);
			const timeElapsed = getTimeElapsedForShiftString(this.currentShift?.punch_in
							|| this.currentShift?.start_time);
			return `${startTime} - ${endTime} | ${timeElapsed} elapsed`;
		},
		currentShiftScheduleTypeId() {
			return this.currentShift?.scheduleType?.data.id ?? 0;
		},
	},
};
</script>
