var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "data-cy",
          rawName: "v-data-cy",
          value: "live-tutor-card",
          expression: "'live-tutor-card'",
        },
        {
          name: "jest",
          rawName: "v-jest",
          value: "liveTutorCard",
          expression: "'liveTutorCard'",
        },
      ],
      staticClass: "liveTutorCard",
      class: {
        "liveTutorCard--redTopBorder": _vm.isNotPunchedInAndLate,
        "liveTutorCard--yellowTopBorder": _vm.isShiftAlmostOver,
      },
    },
    [
      _c("ServiceDashboardTutorCardHeaderLiveTutor", {
        attrs: { shift: _vm.shift, user: _vm.user },
      }),
      _vm._v(" "),
      _c("ServiceDashboardListSession", {
        attrs: {
          resources: _vm.sessions,
          "total-sessions": _vm.totalSessions,
          "time-since-last-activity": _vm.cardData.last_session_ended_at,
        },
      }),
      _vm._v(" "),
      _vm.isEssayReviewer
        ? _c("ServiceDashboardListEssay", {
            attrs: {
              resources: _vm.essays,
              "total-essays": _vm.totalEssays,
              "time-since-last-activity": _vm.cardData.last_essay_reviewed_at,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }