var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("BreaksNotification", { staticClass: "tw-mb-4" }, [
        _vm._v("\n\t\tYou cannot go on a "),
        _c("span", { staticClass: "tw-font-bold" }, [_vm._v("“Bio break”")]),
        _vm._v(
          " when you have students in the chat. Please try again when you have no students in the chat.\n\t"
        ),
      ]),
      _vm._v(" "),
      _c(
        "OrigamiButton",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "bio-break-classroom-error-ok-button",
              expression: "'bio-break-classroom-error-ok-button'",
            },
          ],
          staticClass: "tw-mb-2 tw-w-full tw-justify-center",
          attrs: { variant: "primary" },
          nativeOn: {
            click: function ($event) {
              return _vm.cancelBioBreakRequest.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n\t\tOk, got it!\n\t")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }