var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tw-max-w-screen-lg tw-mx-auto tw-pb-24" }, [
    _c(
      "section",
      {
        directives: [
          {
            name: "data-cy",
            rawName: "v-data-cy",
            value: "late-bio-breaks",
            expression: "'late-bio-breaks'",
          },
        ],
        staticClass: "tw-pb-12",
        attrs: { "aria-label": "Late Bio Breaks" },
      },
      [
        _c("h2", { staticClass: "tw-text-3xl tw-font-extrabold tw-mb-5" }, [
          _vm._v(
            "\n\t\t\tLate Bio Breaks (" +
              _vm._s(_vm.filteredLateBioBreaks.length) +
              ")\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c(
          "BaseCheckbox",
          {
            directives: [
              {
                name: "data-cy",
                rawName: "v-data-cy",
                value: "essay-break-request",
                expression: "'essay-break-request'",
              },
            ],
            attrs: {
              "checkbox-value": "essayBreakRequest",
              "checkbox-id": "essayBreakRequest",
            },
            model: {
              value: _vm.isOnlyEssayReviewBreakRequests,
              callback: function ($$v) {
                _vm.isOnlyEssayReviewBreakRequests = $$v
              },
              expression: "isOnlyEssayReviewBreakRequests",
            },
          },
          [
            _c("template", { slot: "text" }, [
              _vm._v("\n\t\t\t\tWriting Review Shifts Only\n\t\t\t"),
            ]),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "tw-pt-4 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-mb-12 tw-gap-x-4",
          },
          _vm._l(_vm.filteredLateBioBreaks, function (lateBioBreak) {
            return _c("TutorBreakCardLateBioBreak", {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "late-bio-break",
                  expression: "'late-bio-break'",
                },
              ],
              key: lateBioBreak.id,
              attrs: { "bio-break": lateBioBreak },
            })
          }),
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      {
        directives: [
          {
            name: "data-cy",
            rawName: "v-data-cy",
            value: "active-bio-breaks",
            expression: "'active-bio-breaks'",
          },
        ],
        attrs: { "aria-label": "Active Bio Breaks" },
      },
      [
        _c("h2", { staticClass: "tw-text-3xl tw-font-extrabold tw-mb-5" }, [
          _vm._v(
            "\n\t\t\tActive Bio Breaks (" +
              _vm._s(_vm.filteredActiveBioBreaks.length) +
              ")\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-mb-12 tw-gap-x-4",
          },
          _vm._l(_vm.filteredActiveBioBreaks, function (activeBioBreak) {
            return _c("TutorBreakCardActiveBioBreak", {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "active-bio-break",
                  expression: "'active-bio-break'",
                },
              ],
              key: activeBioBreak.id,
              attrs: { "current-break": activeBioBreak },
            })
          }),
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }