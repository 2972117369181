var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "ul",
    { class: { "tw-flex tw-flex-col tw-items-end": _setup.props.isToggled } },
    _vm._l(_setup.filteredNavLinks, function (link, idx) {
      return _c(
        "li",
        {
          key: `${link}_${idx}`,
          class: { "tw-w-20": _setup.props.isToggled },
          on: { click: _setup.handleMobileMenu },
        },
        [
          _c(
            _setup.handleLinkType(link),
            _vm._g(
              _vm._b(
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: `nav-link-${link.name}`,
                      expression: "`nav-link-${link.name}`",
                    },
                  ],
                  tag: "component",
                  staticClass:
                    "nav-link tw-flex tw-items-center tw-p-2 tw-pl-4 tw-mb-2 tw-border-l-8 tw-border-origami-white",
                  class: { active: link.name === _setup.activeLink?.name },
                  attrs: { "aria-label": link.title },
                },
                "component",
                _setup.getLinkProperties(link),
                false
              ),
              _setup.getLinkEvents(link)
            ),
            [
              _c(_setup.OrigamiIcon, {
                attrs: {
                  name: link.icon,
                  title: _vm.$t(link.title),
                  width: "30",
                  height: "30",
                },
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "ml-2",
                  class: { "nav-link__toggled": _setup.props.isToggled },
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" + _vm._s(_vm.$t(`${link.title}`)) + "\n\t\t\t"
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }