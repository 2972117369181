/**
 * 
 * @param {Object} user 
 * @param {String} role 
 * @returns 
 */
export function hasRole(user, role) {
	if (!user) {
		throw new Error("'user' parameter is missing/invalid");
	}
	if (!role) {
		throw new Error("'role' parameter is missing/invalid");
	}

	const roles = user.roles?.data || user.roles || [user.role];
	return roles.some((r) => r?.name === role || r === role);
}

/**
 * 
 * @param {Array} users 
 * @param {String} role 
 * @returns 
 */
export function getUserWithRole(users, role) {
	return users.find((user) => hasRole(user, role));
}

/**
 * 
 * @param {Number} userId 
 * @param {Array} messages 
 * @returns 
 */
export const getUserMessages = (userId, messages) => {
	return messages.filter(
		(message) => message.message?.userId === userId || message.userId === userId,
	);
};

export const COUNTRY_CODE_US = "US";
export const REGION_CODE_MISSISSIPPI = "MS";
/**
 * 
 * @param {Object} user 
 * @returns {Boolean}
 */
export const isMississippiSchoolUser = (user) => {
	return user?.school?.country_code === COUNTRY_CODE_US &&
	user?.school?.region_code === REGION_CODE_MISSISSIPPI;
};
