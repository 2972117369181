import StudentsAPI from "@/services/api/Students.js";
import {
	EVENTS,
	getDefaultRequestChannel,
	getTrackingParamsDataList,
} from "@/utilities/trackRequests.js";

import MathAPI from "../service/MathAPI.js";

import ProblemCreator from "./problemCreator.js";
import Topics from "./topics.js";
import Missions from "./missions.js";
import { storeTypes } from "./storeTypes.js";
const { SET_PROBLEMS } = storeTypes;

export const getDefaultState = () => {
	return {
		mission: null,
		missionSuccessful: false,
		pastProblems: [],
		potd: {},
		problemInEdit: null,
		problems: [],
		activeProblem: null,
		problem_start_id: -1,
		streaks: -1,
		total_completed: -1,
	};
};
const state = getDefaultState();

export const mutations = {
	SET_MISSION(state, payload) {
		state.mission = payload.mission;
	},
	CLEAR_MISSION(state) {
		state.mission = null;
	},
	SET_MISSION_SUCCESSFUL(state) {
		state.missionSuccessful = true;
	},
	CLEAR_MISSION_SUCCESSFUL(state) {
		state.missionSuccessful = false;
	},
	SET_POTD(state, payload) {
		state.potd = payload.potd;
	},
	SET_ACTIVE_PROBLEM(state, payload) {
		state.activeProblem = payload.activeProblem;
	},
	CLEAR_ACTIVE_PROBLEM(state) {
		state.activeProblem = null;
	},
	SET_PAST_PROBLEMS(state, payload) {
		state.pastProblems = payload.pastProblems;
	},
	SET_PROBLEMS(state, problems) {
		state.problems = problems;
	},
	SET_PROBLEM_IN_EDIT(state, problemInEdit) {
		state.problemInEdit = problemInEdit;
	},
	SET_STREAKS(state, payload) {
		state.streaks = payload;
	},
	SET_TOTAL_COMPLETED(state, payload) {
		state.total_completed = payload;
	},
	SET_PROBELM_START_ID(state, payload) {
		state.problem_start_id = payload;
	},
};

export const actions = {
	async setAllProblems({ commit }, grade) {
		try {
			const response = await MathAPI.getAllPastProblems(grade);
			commit(SET_PROBLEMS, response);
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	async demoInteracted({ state, rootState }) {
		try {
			const { id, school_id, timezone } = rootState.currentUser;
			const { activeProblem } = state;
			const payload = {
				user_id: id,
				user_role_id: 1,
				user_school_id: school_id,
				timezone: timezone,
				interaction_type: activeProblem !== null ? "interaction_with_demo_on_page" : "interaction_with_demo",
			};
			const trackingParamsDataList = getTrackingParamsDataList([EVENTS.REQUEST_CHANNEL]);
			if (trackingParamsDataList.length) {
				trackingParamsDataList.forEach(({ key, value }) => {
					payload[key] = value;
				});
			} else {
				payload[EVENTS.REQUEST_CHANNEL] = getDefaultRequestChannel();
			}

			await StudentsAPI.studentPaperMathDemoComplete(payload);
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	async retrieveStreaksAndCompleted({ commit }) {
		try {
			const { streaks, total_completed } = await MathAPI.getStreaksAndCompleted();

			commit("SET_STREAKS", streaks);
			commit("SET_TOTAL_COMPLETED", total_completed);
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	sortProblems(context, { sortName, sorted }) {
		const problemsCopy = [...context.state.problems];
		problemsCopy.sort((a, b) => {
			if (a[sortName] === b[sortName]) {
				return 0;
			} else if (a[sortName] > b[sortName]) {
				return sorted === "asc" ? 1 : -1;
			} else {
				return sorted === "asc" ? -1 : 1;
			}
		});
		context.commit(SET_PROBLEMS, problemsCopy);
	},
	async updateProblemStatus({ commit, state, dispatch }) {
		try {
			const response = await MathAPI.getProblem(state.activeProblem);
			if (state.potd.id === response.id) {
				commit("SET_POTD", { potd: { ...response, isExpanded: false } });
			} else {
				const pastProblems = state.pastProblems.map((problem) =>
					response.id === problem.id
						? { ...response, isExpanded: false }
						: problem,
				);
				commit("SET_PAST_PROBLEMS", { pastProblems });

				const foundProblem = state.problems.findIndex((prob) => prob.id === response.id);
				const problemCopy = state.problems;
				problemCopy[foundProblem] = response;
				commit(SET_PROBLEMS, problemCopy);
			}
			await dispatch("retrieveStreaksAndCompleted");
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	async userAttemptedProblem({ state, dispatch, commit, rootState }) {
		const { activeProblem } = state;
		if (!activeProblem) {
			return;
		}

		try {
			const { school_id, grade_id, timezone } = rootState.currentUser;
			const payload = {
				user_role_id: 1,
				user_school_id: school_id ?? null,
				user_grade_id: grade_id,
				timezone: timezone,
				delivery_info_user_agent: window.navigator.userAgent,
			};

			const { data: { problem_start_id } } = await MathAPI.studentStartedProblem(activeProblem, payload);
			commit("SET_PROBELM_START_ID", problem_start_id);

			dispatch("updateProblemStatus");
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	async userSuccessfullyCompletedProblem({ state, dispatch, rootState }) {
		const { activeProblem } = state;
		if (!activeProblem) {
			return;
		}
		try {
			const { school_id, grade_id, timezone } = rootState.currentUser;
			const { problem_start_id } = state;

			const payload = {
				user_role_id: 1,
				user_school_id: school_id ?? null,
				user_grade_id: grade_id,
				timezone: timezone,
				delivery_info_user_agent: window.navigator.userAgent,
				problem_start_id,
			};
			await MathAPI.studentCompletedProblem(activeProblem, payload);
			dispatch("updateProblemStatus");
		} catch (e) {
			Sentry.captureException(e);
		}
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	modules: {
		ProblemCreator,
		Missions,
		Topics,
	},
};
