<template>
	<BModal
		id="transfer-session-modal"
		ref="transfer-session-modal"
		content-class="transferSessionModal"
		body-class="transferSessionModal__body"
		footer-class="transferSessionModal__footer"
		:hide-header="true"
		:no-close-on-backdrop="true"
		:no-fade="true"
		:centered="true"
	>
		<button
			type="button"
			class="transferSessionModal__closeButton"
			@click="hideModal()"
		>
			&times;
		</button>
		<div class="modal-header transferSessionModal__header">
			<div class="transferSessionModal__icon">
				<IconArrowTransfer class="transferSessionModal__svg" />
			</div>
			<h2 class="transferSessionModal__title">
				Transfer {{ studentNameInSession }} to...
			</h2>
		</div>
		<div
			v-if="isProcessingTransfer"
			class="processingTransfer"
		>
			<div class="tw-w-full tw-px-8 tw-flex tw-flex-col tw-justify-center tw-items-center tw-my-11">
				<BaseLoadingState
					v-jest="'transfer-loading-spinner'"
					class="loadingSpinner"
				/>
				<p class="loadingState__text--font tw-text-base tw-mt-2 tw-text-black-medium tw-font-bold">
					Hand off in progress...
				</p>
				<p class="loadingState__text--font tw-text-xs tw-mt-2 tw-text-black-medium ">
					A chat session will open up with the student shortly.
				</p>
			</div>
		</div>
		<div
			v-else
			class="tw-py-4 tw-p-9"
		>
			<BaseInput
				v-model="searchText"
				v-jest="'tutor-search'"
				class="searchInput tw-h-7 tw-inline-flex tw-align-middle tw-mt-1 tw-mr-1 tw-py-4 tw-w-full"
				type="text"
				:placeholder="$t('search')"
			>
				<template #prepend>
					<IconSearch
						class="tw-w-6 tw-h-6 tw-text-sm tw-flex-shrink-0 tw-text-blue-regular tw-ml-3 tw--mr-1"
					/>
				</template>
				<template #append>
					<BaseIconButton
						v-if="searchText.length > 0"
						v-jest="'clearSearchedMessage'"
						class="tw-w-6 tw-h-6 tw-mr-0.5"
						@click="searchText = ''"
					>
						<IconCircleX
							class="tw-w-3.5 tw-h-3.5 tw-text-blue-regular"
							:icon-color="'currentColor'"
						/>
					</BaseIconButton>
				</template>
			</BaseInput>
			<TransferSessionModalFilters v-jest="'transfer-session-filters'" />
			<div
				v-if="isTutorListAvailable"
				class="transferSessionModal__list tw-mb-8"
			>
				<div
					v-for="(tutor, index) in availableLiveTutors"
					:key="index"
					v-jest="'tutor-item'"
					class="transferSessionModal__listItem"
					@click="selectTutor(tutor)"
				>
					<span class="transferSessionModal__tutorName">
						{{ tutorName(tutor) }}
					</span>
					<BaseRadio
						v-model="newTutorId"
						class="transferSessionModal__radioButton tw-flex tw-my-auto tw-mx-0"
						:radio-id="tutorFirstName(tutor)"
						radio-name="tutors"
						:radio-value="tutorId(tutor)"
					/>
				</div>
			</div>
			<div
				v-else
				class="transferSessionModal__list tw-mb-8"
			>
				<p>There are no tutors matching filter you have selected.</p>
			</div>
			<TutorCommentForHandoff
				v-model.trim="comment"
				:files="fileList"
				@onFileChange="handleFileChange"
				@onFileRemoval="removeAttachmentFile"
			/>
		</div>
		<template
			slot="modal-footer"
		>
			<div class="tw-flex tw-flex-row tw-justify-between tw-px-12 tw-w-full">
				<div class="tw-flex tw-justify-start">
					<BaseButton
						v-if="currentUser.role === 'tutor-manager'"
						v-jest="'transfer-to-self'"
						type="PRIMARY"
						:disabled="isTransferToSelfDisabled"
						@click.native="handoffToSelf"
					>
						<template slot="text">
							Transfer to self
						</template>
					</BaseButton>
				</div>
				<div class="tw-flex tw-flex-row tw-flex-grow tw-justify-end">
					<BaseButton
						class="tw-ml-2.5"
						type="CANCEL"
						@click.native="hideModal()"
					>
						<template slot="text">
							Cancel
						</template>
					</BaseButton>
					<BaseButton
						class="tw-ml-2.5"
						type="SUBMIT"
						:is-disabled="isSubmitButtonDisabled"
						@click.prevent.native="handoff()"
					>
						<template slot="text">
							{{ submitHandoffText }}
						</template>
					</BaseButton>
				</div>
			</div>
		</template>
	</BModal>
</template>
<script>
//Components
import BaseButton from "@/components/BaseButton.vue";
import BaseLoadingState from "@/components/BaseLoadingState.vue";
import BaseRadio from "@/components/BaseRadio.vue";

import BaseInput from "./elements/BaseInput.vue";
import BaseIconButton from "./elements/BaseIconButton.vue";
import IconArrowTransfer from "./icons/IconArrowTransfer.vue";
import IconCircleX from "./icons/IconCircleX.vue";
import IconSearch from "./elements/icons/Search.vue";
import TutorCommentForHandoff from "./TutorCommentForHandoff.vue";
import TransferSessionModalFilters from "./TransferSessionModalFilters.vue";

export default {
	components: {
		IconArrowTransfer,
		TutorCommentForHandoff,
		BaseInput,
		BaseIconButton,
		IconSearch,
		IconCircleX,
		TransferSessionModalFilters,
		BaseButton,
		BaseLoadingState,
		BaseRadio,
	},
	data() {
		return {
			isProcessingTransfer: false,
			newTutorId: null,
			comment: "",
			fileList: [],
			searchText: "",
		};
	},
	computed: {
		/**
		 * @returns {Boolean}
		 */
		isTutorSelected() {
			return false;
		},
		/**
		 * @returns {Boolean}
		 */
		isTutorListAvailable() {
			return false;
		},
		/**
		 * @returns {Booelan}
		 */
		isSubmitButtonDisabled() {
			return false;
		},
		/**
		 * @returns {String}
		 */
		studentNameInSession() {
			return "";
		},
		/**
		 * @returns {String}
		 */
		submitHandoffText() {
			return "";
		},
		/**
		 * @returns {Array}
		 */
		availableLiveTutors() {
			return [];
		},
		hasSelectedSubject() {
			return false;
		},
		/**
		 * @returns {Number}
		 */
		currentTutorInSessionId() {
			return null;
		},
		selectedSession() {
			return null;
		},
		isTransferToSelfDisabled() {
			return false;
		},
	},
	methods: {
		/**
		 * @param {Number} newTutor
		 */
		selectTutor() {},
		/**
		 * @param {Number} newTutor
		 */
		tutorFirstName() {},
		/**
		 * @param {Number} newTutor
		 */
		tutorId() {},
		/**
		 * Hides the bootstrap vue modal.
		 */
		hideModal() {},
		/**
		 * @param {Number} newTutor
		 */
		tutorName() {},
		/**
		 *
		 */
		unsetNewTutorId() {},
		/**
		 * Creates the handoff in the database and sends it.
		 * @param {Number} newTutorId
		 * @returns {Number}
		 */
		async createAndSendHandoff() {},
		/**
		 * Sends a request for a handoff and
		 * removes the current session from the
		 * tutors view
		 */
		async handoff() {},
		/**
		 * Creates the handoff in the database and sends it.
		 * @param {Object} file
		 * @returns {Number}
		 */
		handleFileChange() {},
		async handoffToSelf() {},
		async pushToClassroom() {},
	},
};
</script>
<style scoped>
.transferSessionModal {
	border-radius: 10px;
	box-shadow: 0 20px 20px 0 rgba(60, 83, 150, 0.5);
	border: none;
}
.transferSessionModal__header {
	justify-content: flex-start;
	display: flex;
	align-items: baseline;
	border: none;
	padding: 38px 38px 0;
}
.transferSessionModal__title {
	height: 27px;
	font-family: OpenSans, Arial, sans-serif;
	font-size: 20px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: var(--c-blue);
}
.transferSessionModal__text {
	color: var(--c-black);
}
.transferSessionModal__closeButton {
	background-color: transparent;
	border: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	float: right;
	font-size: 2rem;
	line-height: 1;
	color: var(--c-black);
	text-shadow: 0 1px 0 #fff;
}
.transferSessionModal .transferSessionModal__footer {
	background: var(--c-light-gray);
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	border: none;
	padding: 0;
}
.transferSessionModal__icon {
	display: flex;
	justify-content: center;
	width: 44px;
	min-width: 44px;
	height: 44px;
	border-radius: 44px;
	background-color: var(--c-blue);
	margin-right: 16px;
}
.transferSessionModal__svg {
	margin-top: 13px;
}
.transferSessionModal__list {
	height: 175px;
	overflow: auto;
	margin-top: 10px;
}
.transferSessionModal__listItem {
	height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0 0 0 10px;
}
.transferSessionModal__listItem:hover {
	background: rgba(88, 108, 177, 0.2);
}
.transferSessionModal__radioButton :deep(input) {
	z-index: 1;
}
.transferSessionModal__tutorName {
	font-size: 14px;
}
.searchInput :deep(input) {
	font-size: 0.875rem;
}
.processingTransfer .loadingSpinner {
	border-color: rgba(14, 15, 224, 0.2);
	border-left-color: var(--blue-regular);
	border-width: 0.625rem;
	margin: 0.5rem 0;
	height: 3rem;
	width: 3rem;
}
.loadingState__text--font {
	font-family: MonumentGrotesk-Bold, sans-serif;
}
</style>
