var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "loadingDots", style: _vm.loadingDotsStyles },
    [
      _c("span", [_vm._v(".")]),
      _c("span", [_vm._v(".")]),
      _c("span", [_vm._v(".")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }