var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tw-m-6" }, [
    _c(
      "div",
      {
        staticClass:
          "availabilityInfo__title tw-flex tw-flex-row tw-font-semibold tw-text-origami-brand-blue",
      },
      [
        _c("IconSchedule", {
          staticClass: "tw-mr-4 tw-self-center",
          attrs: { width: 19, height: 22, fill: "#586cb1" },
        }),
        _vm._v("\n\t\tMy Availability\n\t"),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "availabilityInfo__text tw-text-origami-black tw-text-base tw-font-light tw-leading-relaxed",
      },
      [
        _vm.featureFlagPreferredHoursFieldEnabled
          ? _c(
              "p",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "availability-schedule-description",
                    expression: "'availability-schedule-description'",
                  },
                ],
                staticClass: "tw-mb-6",
              },
              [
                _vm._v(
                  '\n\t\t\tPlease click edit at the bottom of the calendar, then drag and drop to\n\t\t\tselect all the times you will be available and willing to work.\n\t\t\tEnter the maximum number of hours you would be willing to work and your\n\t\t\tpreferred number of hours for the week and press "submit." Availability must\n\t\t\tbe submitted by 11:59 pm ET Wednesday for the week beginning 12 days later.\n\t\t'
                ),
              ]
            )
          : _c(
              "p",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "availability-schedule-description",
                    expression: "'availability-schedule-description'",
                  },
                ],
                staticClass: "tw-mb-6",
              },
              [
                _vm._v(
                  '\n\t\t\tPlease click edit at the bottom of the calendar then drag and drop to\n\t\t\tselect all the times you will be available and willing to work, enter\n\t\t\tthe maximum number of hours for which you would like to be scheduled,\n\t\t\tand press "submit." Availability must be submitted by 11:59 pm ET\n\t\t\tWednesday for the week beginning 12 days later.\n\t\t'
                ),
              ]
            ),
        _vm._v("\n\t\tPlease follow these\n\t\t"),
        _c(
          "span",
          {
            staticClass:
              "tw-font-bold tw-text-origami-brand-blue tw-cursor-pointer hover:tw-underline",
          },
          [
            _c(
              "a",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "conditions-link",
                    expression: "'conditions-link'",
                  },
                ],
                on: {
                  click: function ($event) {
                    return _vm.openModal()
                  },
                },
              },
              [_vm._v("\n\t\t\t\tconditions\n\t\t\t")]
            ),
          ]
        ),
        _vm._v("\n\t\twhen submitting your availability.\n\t\t"),
        _c(
          "OrigamiModal",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "conditions-modal",
                expression: "'conditions-modal'",
              },
            ],
            attrs: {
              title: "Conditions",
              "secondary-button-text": "Close",
              "primary-button-text": "Confirm",
              "is-open": _vm.isModalOpen,
            },
            on: { cancel: _vm.closeModal, confirm: _vm.closeModal },
          },
          [
            _c("ul", { staticClass: "conditionsModal__text" }, [
              _c("li", [
                _vm._v(
                  "\n\t\t\t\t\tYou must provide availability in continuous stretches of\n\t\t\t\t\tat least 4 hours.\n\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n\t\t\t\t\tYou must select at least 10 hours of availability and\n\t\t\t\t\tenter at least 10 max hours.\n\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n\t\t\t\t\tYou may only provide availability during your\n\t\t\t\t\tavailability blocks. To change your availability blocks,\n\t\t\t\t\tsubmit a Service Zendesk ticket.\n\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c(
                "li",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "edit-availabilities-condition",
                      expression: "'edit-availabilities-condition'",
                    },
                  ],
                },
                [
                  _vm._v(
                    "\n\t\t\t\t\tOnce you have submitted your availability, you can edit until the submission deadline.\n\t\t\t\t"
                  ),
                ]
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }