<template>
	<div class="tw-m-6">
		<div class="availabilityInfo__title tw-flex tw-flex-row tw-font-semibold tw-text-origami-brand-blue">
			<IconSchedule
				class="tw-mr-4 tw-self-center"
				:width="19"
				:height="22"
				fill="#586cb1"
			/>
			My Availability
		</div>
		<div class="availabilityInfo__text tw-text-origami-black tw-text-base tw-font-light tw-leading-relaxed">
			<p
				v-if="featureFlagPreferredHoursFieldEnabled"
				v-jest="'availability-schedule-description'"
				class="tw-mb-6"
			>
				Please click edit at the bottom of the calendar, then drag and drop to
				select all the times you will be available and willing to work.
				Enter the maximum number of hours you would be willing to work and your
				preferred number of hours for the week and press "submit." Availability must
				be submitted by 11:59 pm ET Wednesday for the week beginning 12 days later.
			</p>
			<p
				v-else
				v-jest="'availability-schedule-description'"
				class="tw-mb-6"
			>
				Please click edit at the bottom of the calendar then drag and drop to
				select all the times you will be available and willing to work, enter
				the maximum number of hours for which you would like to be scheduled,
				and press "submit." Availability must be submitted by 11:59 pm ET
				Wednesday for the week beginning 12 days later.
			</p>
			Please follow these
			<span class="tw-font-bold tw-text-origami-brand-blue tw-cursor-pointer hover:tw-underline">
				<a
					v-jest="'conditions-link'"
					@click="openModal()"
				>
					conditions
				</a>
			</span>
			when submitting your availability.
			<OrigamiModal
				v-jest="'conditions-modal'"
				title="Conditions"
				secondary-button-text="Close"
				primary-button-text="Confirm"
				:is-open="isModalOpen"
				@cancel="closeModal"
				@confirm="closeModal"
			>
				<ul class="conditionsModal__text">
					<li>
						You must provide availability in continuous stretches of
						at least 4 hours.
					</li>
					<li>
						You must select at least 10 hours of availability and
						enter at least 10 max hours.
					</li>
					<li>
						You may only provide availability during your
						availability blocks. To change your availability blocks,
						submit a Service Zendesk ticket.
					</li>
					<li v-jest="'edit-availabilities-condition'">
						Once you have submitted your availability, you can edit until the submission deadline.
					</li>
				</ul>
			</OrigamiModal>
		</div>
	</div>
</template>

<script>
import { OrigamiModal } from "@origami/vue2";

import IconSchedule from "../../../components/icons/IconSchedule.vue";

export default {
	components: {
		IconSchedule,
		OrigamiModal,
	},
	data() {
		return {
			isModalOpen: false,
			featureFlagPreferredHoursFieldEnabled: null,
		};
	},
	async created() {
		this.featureFlagPreferredHoursFieldEnabled = await this.$getFlag("SER-550-preferred-hours-field");
	},
	methods: {
		openModal() {
			this.isModalOpen = true;
		},
		closeModal() {
			this.isModalOpen = false;
		},
	},
};
</script>

<style>
.availabilityInfo__title {
	font-size: 22px;
	line-height: normal;
}

.availabilityInfo__text {
	line-height: 1.6;
	letter-spacing: -0.02px;
	margin-top: 23px;
}

.conditionsModal__text {
	list-style-type: disc !important;
	margin-left: 1rem !important;
}
</style>
