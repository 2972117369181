import middleware from "@/middleware/index.js";
import hasStudentAcceptedAgreement from "@/middleware/hasStudentAcceptedAgreement.js";
import hasPaperAccess from "@/middleware/hasPaperAccess.js";
import hasEssayReviewFeature from "@/middleware/hasEssayReviewFeature.js";
import { handleReviewCenterLandedEvent } from "@/utilities/trackRequests.js";
import { doesStudentNeedOnboarding } from "@/modules/StudentOnboarding/index.js";

export default [
	{
		name: "reviewCenter",
		path: "student/review-center",
		component: () => import("../pages/reviewCenter.vue"),
		beforeEnter: middleware([
			hasStudentAcceptedAgreement,
			doesStudentNeedOnboarding,
			hasEssayReviewFeature,
			hasPaperAccess,
			handleReviewCenterLandedEvent,
		]),
		meta: {
			embeddedPath: "/embedded/student/review-center",
		},
	},
	{
		name: "uploadWork",
		path: "student/upload-work",
		component: () => import("../pages/uploadWork.vue"),
		beforeEnter: middleware([
			hasStudentAcceptedAgreement,
			doesStudentNeedOnboarding,
			hasEssayReviewFeature,
		]),
	},
];
