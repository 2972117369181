export const UNINSPECT_ACTION_ID = -1;
export const INSPECT_ACTION_ID = 0;
export const ESCALATE_ACTION_ID = 1;
export const SELF_TRANSFER_ACTION_ID = 2;
export const REMOVE_ACTION_ID = 3;
export const SLACK_ACTION_ID = 4;
export const ESSAY_SUBMITTED_ACTION_ID = 5;
export const FALSE_ALARM_ACTION_ID = 6;
export const HIDE_ACTION_ID = 7;
export const RESOLVE_ACTION_ID = 10;

export const LONG_ESSAY_REVIEW_ID = 1;
export const INAPPROPRIATE_BEHAVIOUR_ID = 2;
export const INAPPROPRIATE_BEHAVIOUR_ESSAY_ID = 3;

export const ActionItemTypeMapping = {
	1: "longEssayReviewItems",
	2: "inappropriateBehaviourItems",
	3: "inappropriateBehaviourEssayItems",
};

export const OwnedActionItemTypeMapping = {
	1: "ownedLongEssayReviewItems",
	2: "ownedInappropriateBehaviourItems",
	3: "ownedInappropriateBehaviourEssayItems",
};

export const ActionItemActionsMapping = {
	1: "getLongEssayReviewActionItems",
	2: "getInappropriateClassroomActionItems",
	3: "getInappropriateEssayActionItems",
};

export const getStatusText = (actionItem, isPickedUpByCurrentUser) => {
	if (!actionItem.picked_up_at) {
		return "No action taken";
	}

	let text;
	switch (actionItem.action_type_id) {
		case ESCALATE_ACTION_ID :
			text = isPickedUpByCurrentUser ?
				`Escalated by me` :
				actionItem.pickedUpUser.data.first_name ?
					`${actionItem.pickedUpUser.data.first_name} escalated` :
					`Another PM escalated`;
			break;
		case SELF_TRANSFER_ACTION_ID :
			text = isPickedUpByCurrentUser ?
				`Transferred by me` :
				actionItem.pickedUpUser.data.first_name ?
					`${actionItem.pickedUpUser.data.first_name} transferred to self` :
					`Another PM transferred to self`;
			break;
		case SLACK_ACTION_ID :
			text = isPickedUpByCurrentUser ?
				`Slack messaged by me` :
				actionItem.pickedUpUser.data.first_name ?
					`${actionItem.pickedUpUser.data.first_name} slack messaged` :
					`Another PM slack messaged`;
			break;
		case FALSE_ALARM_ACTION_ID :
			text = isPickedUpByCurrentUser ?
				`False Alarm by me` :
				actionItem.pickedUpUser.data.first_name ?
					`${actionItem.pickedUpUser.data.first_name} marked false alarm` :
					`Another PM marked false alarm`;
			break;
		case HIDE_ACTION_ID :
			text = isPickedUpByCurrentUser ?
				`Hid by me` :
				actionItem.pickedUpUser.data.first_name ?
					`${actionItem.pickedUpUser.data.first_name} hid the session` :
					`Another PM hid the session`;
			break;
		default :
			text = isPickedUpByCurrentUser ?
				`I am inspecting` :
				actionItem.pickedUpUser.data.first_name ?
					`${actionItem.pickedUpUser.data.first_name} is inspecting` :
					`Another PM is inspecting`;
			break;
	}
	return text;
};

export const getActionTypeName = (actionItem) => {
	if (!actionItem.picked_up_at) {
		return "Action";
	}

	let text;
	switch (actionItem.action_type_id) {
		case ESCALATE_ACTION_ID :
			text = "Escalated";
			break;
		case SELF_TRANSFER_ACTION_ID :
			text = `Transferred`;
			break;
		case SLACK_ACTION_ID :
			text = `Slack`;
			break;
		case HIDE_ACTION_ID :
			text = `Hide`;
			break;
		default :
			text = `Action`;
	}
	return text;
};

export const getActionTypeIndex = (id, array) => {
	const actionIndex = array.findIndex((action) => action.id === id);
	return actionIndex !== -1 ? actionIndex : 0;
};

export const formatMessageArray = (message) => {
	const splitMessage = message.replace(/(\r\n|\n|\r)/gm, "").split("•");
	return splitMessage
		.filter((str) => str !== "")
		.map((str) => str.trim());
};

export const formatMessage = (array) => {
	let message = "";
	array.forEach((str) => {
		if (str.includes("Message: ")) {
			const newString = str.replace("Message: ", "");
			const splitStrings = newString.split("*_`");
			const newArray = splitStrings.map((str) => str.split("`_*"));
			const flatArray = newArray.flat();
			message = flatArray;
		}
	});
	return message;
};

export const formatEmailMessage = (array) => {
	let message = "";
	array.forEach((str) => {
		if (str.includes("The following emails were shared in the classroom: ")) {
			const newString = str.replace(": ", ": *: ");
			const splitStrings = newString.split("*: ");
			message = splitStrings;
		}
	});
	return message;
};

export const formatPhoneMessage = (array) => {
	let message = "";
	array.forEach((str) => {
		if (str.includes("The following phone numbers were shared in the classroom: ")) {
			const newString = str.replace(": ", ": *: ");
			const splitStrings = newString.split("*: ");
			message = splitStrings;
		}
	});
	return message;
};
