var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M16.7674 13.569C17.096 13.7624 17.096 14.2376 16.7674 14.431L9.75351 18.5568C9.42019 18.7528 9 18.5125 9 18.1258V9.87421C9 9.48751 9.42019 9.24718 9.75351 9.44325L16.7674 13.569ZM13.42 6.00001H21C22.1 6.00001 23 6.90001 23 8.00001V20C23 21.1 22.1 22 21 22H3C1.9 22 1 21.1 1 20V8.00001C1 6.90001 1.9 6.00001 3 6.00001H10.56L7.11113 2.05449C6.941 1.85987 6.94873 1.56725 7.12888 1.38187V1.38187C7.32949 1.17545 7.66306 1.18239 7.8549 1.39699L11.97 6.00001H12L16.3568 1.37498C16.5501 1.16972 16.8771 1.16711 17.0765 1.3665V1.3665C17.267 1.55696 17.2744 1.86613 17.0907 2.06313L13.42 6.00001Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }