const trans = {
	en: {
		grade_levels: {
			select_grade_level: "Select a grade level",
			unknown: "Unknown",
			PK: "Pre-Kindergarten",
			K: "Kindergarten",
			1: "Grade 1",
			2: "Grade 2",
			3: "Grade 3",
			4: "Grade 4",
			5: "Grade 5",
			6: "Grade 6",
			7: "Grade 7 (Secondary 1)",
			8: "Grade 8 (Secondary 2)",
			9: "Grade 9 (Secondary 3)",
			10: "Grade 10 (Secondary 4)",
			11: "Grade 11 (Secondary 5)",
			12: "Grade 12 (Pre-University)",
			U: "University",
			Other: "Other",
		},
		grade_groups: {
			elementary: "Elementary School",
			middleAndHigh: "Middle & High School",
			postSecondary: "Post-Secondary",
		},
	},
	fr: {
		grade_levels: {
			select_grade_level: "Sélectionnez un niveau scolaire",
			unknown: "Inconnu",
			PK: "Pré-scolaire",
			K: "Maternelle",
			1: "1ere année",
			2: "2e année",
			3: "3e année",
			4: "4e année",
			5: "5e année",
			6: "6e année",
			7: "7e année (Secondaire 1)",
			8: "8e année (Secondaire 2)",
			9: "9e année (Secondaire 3)",
			10: "10e année (Secondaire 4)",
			11: "11e année (Secondaire 5)",
			12: "12e année (Pré-Universitaire)",
			U: "Université",
			Other: "Autre",
		},
		grade_groups: {
			elementary: "École primaire",
			middleAndHigh: "École intermédiaire et secondaire",
			postSecondary: "Post-secondaire",
		},
	},
	es: {
		grade_levels: {
			select_grade_level: "Selecciona un grado",
			unknown: "Desconocido",
			PK: "Pre-Kinder",
			K: "Kinder",
			1: "1er grado",
			2: "2do grado",
			3: "3er grado",
			4: "4to grado",
			5: "5to grado",
			6: "6to grado",
			7: "7mo grado",
			8: "8vo grado",
			9: "9no grado",
			10: "10mo grado",
			11: "11vo grado",
			12: "12vo grado",
			U: "Universidad",
			Other: "Otro",
		},
	},
};

export default trans;
