var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tw-w-full" }, [
    _c(
      "section",
      { staticClass: "tw-flex tw-justify-between tw-py-4" },
      [
        _c("div", { staticClass: "tw-w-1/2" }, [
          _c(
            "h3",
            { staticClass: "tw-font-extrabold tw-pb-2 tw-pt-0 tw-px-0" },
            [_vm._v("\n\t\t\t\tTutor ID\n\t\t\t")]
          ),
          _vm._v(" "),
          _c("p", { staticClass: "tw-text-sm" }, [
            _vm._v(
              "\n\t\t\t\t" +
                _vm._s(_vm.selectedTutorName) +
                " [" +
                _vm._s(_vm.selectedTutorId) +
                "]\n\t\t\t"
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.isEditing
          ? _c("ShiftSegmentScheduleTypeTag", {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "schedule-type-tag",
                  expression: "'schedule-type-tag'",
                },
              ],
              staticClass: "tw-mt-0",
              attrs: { "shift-type": _vm.shiftTypeId },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "shift-details-container",
            expression: "'shift-details-container'",
          },
        ],
        staticClass: "tw-pb-4",
      },
      [
        _c("DropDown", {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "shiftType-list-dropdown",
              expression: "'shiftType-list-dropdown'",
            },
          ],
          staticClass: "shift-edit-shift-type-select",
          attrs: {
            "is-disabled": _vm.isShiftTypeSelectDisabled,
            "data-cy": "shiftType-list-dropdown",
            label: "Shift Type",
            "prompt-text": "Select A Shift Type",
            items: _vm.shiftTypeSelectItems,
            "get-item-text": (item) => _vm.getShiftTypeName(item),
          },
          model: {
            value: _vm.shiftTypeId,
            callback: function ($$v) {
              _vm.shiftTypeId = $$v
            },
            expression: "shiftTypeId",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "tw-pb-3 tw-pt-0 tw-px-0" },
      [
        _c(
          "label",
          {
            staticClass: "tw-font-extrabold tw-pb-2 tw-pt-0 tw-px-0",
            attrs: { for: "datepicker" },
          },
          [_vm._v("\n\t\t\tDate\n\t\t")]
        ),
        _vm._v(" "),
        _c("DatePicker", {
          staticClass: "tw-pl-0 tw-py-0",
          attrs: {
            "is-expanded": "",
            mode: "dateTime",
            is24hr: true,
            "minute-increment": 15,
            "is-range": true,
            "min-date": _vm.minimumDate,
          },
          model: {
            value: _vm.selectedRange,
            callback: function ($$v) {
              _vm.selectedRange = $$v
            },
            expression: "selectedRange",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }