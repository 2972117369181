<template>
	<div class="tutorComment">
		<div class="tutorComment__title">
			Share any comments that could be helpful for other tutors
		</div>
		<div class="tutorComment__title tutorComment__title--optional">
			(optional)
		</div>
		<div
			v-show="!isAddingComment"
			class="tutorComment__buttonAdd"
			@click="isAddingComment = !isAddingComment"
		>
			Add comment
		</div>
		<div
			v-show="isAddingComment"
			class="tutorComment__input"
		>
			<textarea
				v-model="comment"
				v-data-cy="'assessment-comment'"
				class="assessment__feedback"
				:placeholder="commentPlaceholder"
				:maxlength="maxChars"
			/>
			<div class="input__charactersLeft">
				<span
					v-if="isCharacterLimitReachedForComment"
					class="input__charactersLeft--alert"
				>
					Character limit reached.
				</span>
				{{ `${numCharactersRemaining}/${maxChars}` }}
			</div>
			<div class="input__info">
				Comments for other tutors should be general and not specifically relate to any subject.
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		isSubmitting: {
			type: Boolean,
		},
		studentId: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			comment: null,
			isAddingComment: false,
			maxChars: 500,
		};
	},

	computed: {
		isValidComment() {
			return this.isAddingComment && this.numCharactersRemaining >= 0;
		},

		numCharactersRemaining() {
			if (this.comment === null) {
				return this.maxChars;
			} else {
				return this.maxChars - this.comment.length;
			}
		},
		/**
		 * @returns {Boolean}
		 */
		isCharacterLimitReachedForComment() {
			return this.numCharactersRemaining === 0;
		},

		/**
		 * Inserts student name into placeholder
		 * @returns {string}
		 */
		commentPlaceholder() {
			const currentSessionOtherUsers = this.$store.getters["Classroom/currentSessionOtherUsers"];
			if (currentSessionOtherUsers.length > 0) {
				return (
					"Explain " +
					currentSessionOtherUsers[0].first_name +
					"'s way of learning and their key characteristics"
				);
			} else {
				return null;
			}
		},
	},

	watch: {
		isSubmitting(newValue) {
			if (newValue) {
				this.submitTutorComment();
			}
		},

		isValidComment(newValue) {
			this.$emit("input", newValue);
		},
	},

	methods: {
		async submitTutorComment() {
			try {
				const params = {
					user_student_id: this.studentId,
					comment: this.comment,
				};
				if (this.isValidComment && this.comment !== null) {
					await this.$store.dispatch("Tutor/Session/createTutorComments", params);
				}
			} catch (e) {
				await this.$store.dispatch("SimpleModal/configureSimpleModal", {
					buttonText: "okay",
					message:
						"There was an error submitting the comment. Please contact support if the problem persists.",
					title: "Error!",
					buttonType: "PRIMARY",
				});
				this.$bvModal.show("simpleModal");
			}
		},
	},
};
</script>

<style scoped>
.tutorComment__title {
	color: #4a4a4a;
	font-size: 14px;
	font-weight: 600;
}

.tutorComment__title--optional {
	font-weight: 100;
	opacity: 0.6;
}

.tutorComment__buttonAdd {
	background: var(--c-blue);
	text-align: center;
	color: var(--c-white);
	cursor: pointer;
	border-radius: 5px;
	padding: 9px 0;
	font-size: 14px;
	margin-top: 10px;
	transition: background 0.5s;
}

.tutorComment__buttonAdd:hover {
	background: var(--c-blue);
}

.tutorComment__input {
	border: 2px solid var(--c-blue);
	background-color: rgba(88, 108, 178, 0.2);
	border-radius: 12px;
	padding: 8px;
}

.input__textareaComment {
	resize: none;
	font-size: 12px;
	margin-top: 9px;
}

.input__charactersLeft {
	text-align: right;
	font-size: 12px;
	color: var(--c-black);
	margin-top: 5px;
}

.input__charactersLeft--alert {
	color: var(--c-red);
}

.input__info {
	color: var(--c-blue);
	font-size: 12px;
	margin-top: 5px;
}
.assessment__feedback {
	font-size: 12px;
	height: 120px;
	padding: 14px 16px;
	line-height: 1.42857143;
	color: #4a4a4a;
	border: solid 2px rgba(88, 108, 178, 0.2);
	border-radius: 5px;
	font-weight: 300;
	width: 100%;
}

.assessment__feedback:focus {
	outline: none;
	border: solid 2px var(--c-blue);
}
</style>
