export const sidebarLinks = [{
	icon: "home",
	title: "student_navigation.sidebar_links.home",
	target: "/dashboard",
	name: "dashboard",
	opensDrawer: false,
	isExternal: false,
},
{
	icon: "chat",
	title: "student_navigation.sidebar_links.live_help",
	target: "/student/classroom",
	name: "live-help",
	opensDrawer: true,
	isExternal: false,
},
{
	icon: "training",
	title: "student_navigation.sidebar_links.review_center",
	name: "review-center",
	opensDrawer: true,
	isExternal: false,
},
{
	icon: "lightbulb",
	title: "student_navigation.sidebar_links.practice",
	target: "/missions?ref=nav_icon",
	name: "practice",
	opensDrawer: true,
	isExternal: false,
},
{
	icon: "international-business",
	title: "student_navigation.sidebar_links.future_path",
	target: "/undefined", // switch to major clarity when available
	name: "future-path",
	opensDrawer: false,
	isExternal: true,
}];
export const drawerLinks = {
	"live-help": [
		{
			icon: "chat",
			title: "student_navigation.sidebar_links.ask_tutor",
			target: "/student/classroom?tab=live-help",
			name: "ask-a-tutor",
			isExternal: false,
		},
		{
			icon: "history",
			title: "student_navigation.sidebar_links.chat_history",
			target: "/student/classroom?tab=history",
			name: "chat-history",
			isExternal: false,
		},
		{
			icon: "test-session",
			title: "student_navigation.sidebar_links.saved_messages",
			target: "/student/classroom?tab=history&bookmarks=true",
			name: "saved-messages",
			isExternal: false,
		},
	],
	"review-center": [
		{
			icon: "assessment",
			title: "student_navigation.sidebar_links.submit_work_review",
			target: "/student/upload-work",
			name: "upload-work",
			isExternal: false,
		},
		{
			icon: "history",
			title: "student_navigation.sidebar_links.view_tutor_feedback",
			target: "/student/review-center",
			name: "review-center",
			isExternal: false,
		},
	],
	practice: [
		{
			icon: "paper-math",
			title: "student_navigation.sidebar_links.math_mission",
			target: "/missions?ref=nav_icon",
			name: "math-mission",
			isExternal: false,
		},
		{
			icon: "pen",
			title: "student_navigation.sidebar_links.reading_mission",
			target: "/reading",
			name: "reading-mission",
			isExternal: true,
		},
	],
};
export const navLinks = [
	{
		title: "student_navigation.nav_links.home",
		icon: "dashboard",
		target: "/dashboard",
		isExternal: false,
		name: "home",
	},
	{
		title: "student_navigation.nav_links.chat_history",
		icon: "history",
		target: "/student/classroom?tab=history",
		isExternal: false,
		name: "chat-history",
		hasQueryParam: true,
	},
	{
		title: "student_navigation.nav_links.review_center",
		icon: "history",
		target: "/student/review-center",
		isExternal: false,
		name: "review-center",
	},
	{
		title: "student_navigation.nav_links.upload_work",
		icon: "essay-review",
		target: "/student/upload-work",
		isExternal: false,
		name: "upload-work",
	},
	{
		title: "student_navigation.nav_links.paperlive",
		icon: "paper-live",
		target: "/paperlive",
		isExternal: true,
		name: "paperlive",
	},
	{
		title: "student_navigation.nav_links.math",
		icon: "paper-math",
		target: "/missions?ref=nav_icon",
		isExternal: false,
		name: "math",
		hasQueryParam: true,
	},
	{
		title: "student_navigation.nav_links.referrals",
		icon: "students",
		target: "/student/achievement-center?goto=referrals",
		isExternal: false,
		name: "referrals",
		hasQueryParam: true,
	},
	{
		title: "student_navigation.nav_links.future_path",
		icon: "future-pathway",
		target: "/majorclarity",
		isExternal: true,
		name: "major-clarity",
	},
	{
		icon: "reading",
		title: "student_navigation.nav_links.reading",
		target: "/reading",
		isExternal: true,
		name: "reading",
	},
];
export const dropdownLinks = [
	{
		title: "student_navigation.dropdown_links.achievements",
		target: "/student/achievement-center",
		icon: "profile-outlined",
		isExternal: false,
		name: "achievements",
	},
	{
		title: "student_navigation.dropdown_links.settings",
		target: "/student/settings",
		icon: "account-settings-outlined",
		isExternal: false,
		name: "settings",
	},
	{
		title: "student_navigation.dropdown_links.logout",
		target: "/logout",
		icon: "logout",
		isExternal: false,
		name: "logout",
	},
];

export const stopImpersonatingLink = [{
	title: "student_navigation.dropdown_links.stop_impersonating",
	target: "/stop-impersonation",
	isExternal: false,
	name: "stopImpersonating",
}];

export const viewAsLinks = [
	{
		title: "student_navigation.view_as_links.superuser",
		target: "/superuser",
		role: "superuser",
		isExternal: false,
	},
	{
		title: "student_navigation.view_as_links.district_manager",
		target: "/district-manager",
		role: "district-manager",
		isExternal: false,
	},
	{
		title: "student_navigation.view_as_links.administrator",
		target: "/administrator",
		role: "administrator",
		isExternal: false,
	},
	{
		title: "student_navigation.view_as_links.teacher",
		target: "/teacher",
		role: "teacher",
		isExternal: false,
	},
	{
		title: "student_navigation.view_as_links.tutor",
		target: "/tutor",
		role: "tutor",
		isExternal: false,
	},
	{
		title: "student_navigation.view_as_links.platform_manager",
		target: "/tutor-manager/services",
		role: "tutor-manager",
		isExternal: false,
	},
	{
		title: "student_navigation.view_as_links.problem_creator",
		target: "/paper-math/problem-creator",
		role: "paper-math/problem-creator",
		isExternal: false,
	},
];
