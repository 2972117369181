var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ActionItemWrapper", {
    directives: [
      {
        name: "jest",
        rawName: "v-jest",
        value: "action-wrapper",
        expression: "'action-wrapper'",
      },
    ],
    attrs: {
      "action-item": _vm.actionItem,
      actions: _vm.filteredActions,
      "is-action-pending": _vm.isActionPending,
      "is-currently-inspecting": _vm.isCurrentlyInspecting,
    },
    on: {
      "handle-inspect": _vm.handleInspect,
      "handle-uninspect": _vm.handleUninspect,
      "handle-false-alarm": _vm.handleFalseAlarm,
    },
    scopedSlots: _vm._u([
      {
        key: "flagged-message",
        fn: function () {
          return [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "flagged-message",
                    expression: "'flagged-message'",
                  },
                ],
              },
              [
                !_vm.itemContentMessage.hasKeywords
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "unformatted-message",
                            expression: "'unformatted-message'",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t" +
                            _vm._s(_vm.actionItem.content.data.message) +
                            "\n\t\t\t"
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.itemContentMessage.hasKeyword
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "keyword-message",
                            expression: "'keyword-message'",
                          },
                        ],
                        staticClass: "tw-block",
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t" +
                            _vm._s(_vm.itemContentMessage.message[0]) +
                            " "
                        ),
                        _c("span", { staticClass: "tw-font-bold" }, [
                          _vm._v(_vm._s(_vm.itemContentMessage.message[1])),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.itemContentMessage.message[2]) +
                            "\n\t\t\t"
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.itemContentMessage.hasEmail
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "email-message",
                            expression: "'email-message'",
                          },
                        ],
                        staticClass: "tw-block",
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t" +
                            _vm._s(_vm.itemContentMessage.emailMessage[0]) +
                            " "
                        ),
                        _c("span", { staticClass: "tw-font-bold" }, [
                          _vm._v(
                            _vm._s(_vm.itemContentMessage.emailMessage[1])
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.itemContentMessage.hasPhone
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "phone-message",
                            expression: "'phone-message'",
                          },
                        ],
                        staticClass: "tw-block",
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t" +
                            _vm._s(_vm.itemContentMessage.phoneMessage[0]) +
                            " "
                        ),
                        _c("span", { staticClass: "tw-font-bold" }, [
                          _vm._v(
                            _vm._s(_vm.itemContentMessage.phoneMessage[1])
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "flagged-user-info",
        fn: function () {
          return [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "message-type",
                    expression: "'message-type'",
                  },
                ],
                staticClass: "tw-font-bold tw-mr-4",
              },
              [_vm._v("\n\t\t\t" + _vm._s(_vm.messageType) + "\n\t\t")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "role",
                    expression: "'role'",
                  },
                ],
                staticClass: "tw-font-bold",
              },
              [_vm._v("\n\t\t\t" + _vm._s(_vm.role) + ":\n\t\t")]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "sender-name",
                    expression: "'sender-name'",
                  },
                ],
              },
              [_vm._v(_vm._s(_vm.name))]
            ),
            _vm._v(" "),
            _c(
              "RouterLink",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "action-item-link",
                    expression: "'action-item-link'",
                  },
                ],
                staticClass:
                  "tw-mx-2 tw-text-blue-regular tw-underline tw-font-bold",
                attrs: { to: _vm.itemLinkPath, target: "_blank" },
              },
              [_vm._v("\n\t\t\t" + _vm._s(_vm.itemLinkPathText) + "\n\t\t")]
            ),
            _vm._v("\n\t\t" + _vm._s(_vm.convertedDate) + "\n\t"),
          ]
        },
        proxy: true,
      },
      {
        key: "status-text",
        fn: function () {
          return [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "status-text",
                    expression: "'status-text'",
                  },
                ],
              },
              [_vm._v("\n\t\t\t" + _vm._s(_vm.statusText) + "\n\t\t")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }