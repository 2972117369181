var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 16 11",
      },
    },
    [
      _c("path", {
        attrs: {
          fill: _vm.iconColor,
          "fill-rule": "evenodd",
          d: "M14.494 5.5C13.075 2.931 10.615 1.35 8 1.35c-2.615 0-5.075 1.582-6.494 4.151C2.925 8.07 5.385 9.652 8 9.652c2.615 0 5.075-1.582 6.494-4.152zM16 5.5l-.155.306C14.228 9.01 11.222 11 8 11 4.778 11 1.772 9.01.155 5.806L0 5.5l.155-.306C1.772 1.99 4.778 0 8 0c3.222 0 6.228 1.99 7.845 5.194L16 5.5zm-5.916 0A2.094 2.094 0 0 1 8 7.605 2.095 2.095 0 0 1 5.916 5.5c0-1.162.933-2.105 2.084-2.105s2.084.943 2.084 2.105z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }