var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "learner-profile-link-container",
          expression: "'learner-profile-link-container'",
        },
      ],
      staticClass:
        "tw-p-4 tw-border-b tw-border-grey-regular tw-flex tw-flex-col",
    },
    [
      _c(
        "div",
        { staticClass: "tw-flex tw-gap-4 tw-items-center" },
        [
          _c(_setup.AvatarIcon, {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "avatar",
                expression: "'avatar'",
              },
            ],
            staticClass: "tw-border tw-border-grey-dark",
            attrs: { user: _setup.userData, size: 56 },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "tw-font-bold tw-break-all" }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(
                  `${_setup.currentUser.firstName} ${_setup.currentUser.lastName[0]}.`
                ) +
                "\n\t\t"
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        _setup.OrigamiButton,
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "learner-profile-link",
              expression: "'learner-profile-link'",
            },
          ],
          staticClass:
            "tw-whitespace-nowrap tw-mt-4 tw-mx-auto tw-w-full tw-justify-center",
          attrs: { variant: "secondary" },
          nativeOn: {
            click: function ($event) {
              return _setup.redirectToLearnerProfile()
            },
          },
        },
        [
          _vm._v(
            "\n\t\t" +
              _vm._s(
                _setup.i18n.t(
                  "student_navigation.dropdown_links.view_my_profile"
                )
              ) +
              "\n\t"
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }