import { isNil } from "lodash";

import { getShiftTypeNameFromShiftTypeId } from "@/utilities/ScheduleHelpers.js";

export default {
	data() {
		return {
			tutorId: null,
		};
	},
	computed: {
		selectedTutor() {
			return this.tutorId ?
				this.PlatformManager.ServiceTutorsSchedule.tutorsList.find((tutor) => this.tutorId === tutor.id) :
				null;
		},
		isTutorManager() {
			return !isNil(this.selectedTutor) ? this.selectedTutor.role === "tutor-manager" : false;
		},
		shiftTypesForCreation() {
			let shiftTypes = this.PlatformManager.ServiceTutorsSchedule.scheduleTypesList
				.filter((type) => [11, 12].includes(type.id) === false);
			if (!this.isTutorManager) {
				shiftTypes = shiftTypes.filter((type) => type.name !== "Tutor Manager");
			}
			return shiftTypes;
		},
		tutorsDropdownFilter() {
			return this.tutorId
				? `${this.selectedTutor.name}-${this.selectedTutor.id}`
				: "Select A Tutor";
		},
		shiftTypeFilter() {
			return this.selectedShiftType
				? getShiftTypeNameFromShiftTypeId(this.selectedShiftType.id)
				: "Select a shift type";
		},
		selectedShiftType() {
			return this.shiftTypesForCreation.find((shift) => shift.id === this.shiftTypeId);
		},
	},
	watch: {
		selectedTutor(value) {
			this.$store.commit("PlatformManager/ServiceTutorsSchedule/SET_CURRENT_TUTOR", {
				currentTutor: value,
			});
		},
		selectedShiftType(value) {
			this.$store.commit("PlatformManager/ServiceTutorsSchedule/SET_CURRENT_SHIFT_TYPE", {
				currentShiftType: value,
			});
		},
	},
	methods: {
		closeOnSelect(refName) {
			this.$refs[refName].showDropdown = false;
		},
		resetData(defaultSelectedRange = null) {
			this.tutorId = null;
			this.shiftTypeId = null;
			this.selectedRange = defaultSelectedRange;
		},
	},
};
