<template>
	<div
		ref="drilldown"
		v-jest="'drilldown'"
		class="tw-flex tw-fixed tw-inset-0 tw-z-50 tw-transform"
		:class="isDrilldownOpen ? 'tw-translate-x-0' : 'tw-translate-x-full'"
		aria-hidden="true"
		aria-labelledby="drilldown-title"
	>
		<div
			v-jest="'drilldown-underlay'"
			class="tw-fixed tw-inset-0 tw-bg-blue-dark tw-bg-opacity-80"
			data-a11y-dialog-hide
		/>

		<div
			v-jest="'drilldown-content'"
			class="tw-fixed tw-grid tw-h-screen tw-top-0 tw-right-0 tw-bg-origami-white tw-max-w-screen-md tw-w-full tw-overflow-y-scroll tw-duration-700 tw-transform"
			:class="isDrilldownOpen ? 'tw-translate-x-0' : 'tw-translate-x-full'"
			style="grid-template-rows: 5rem auto;"
			tabindex="0"
			role="document"
		>
			<header class="tw-border-b-2 tw-border-origami-grey-200 tw-w-full tw-p-5 tw-flex tw-justify-between tw-items-center">
				<h1
					id="drilldown-title"
					v-jest="'drilldown-title'"
					class="tw-text-xl tw-font-bold tw-tracking-wide"
				>
					{{ title }}
				</h1>
				<BaseIconButton
					v-jest="'close-button'"
					v-data-cy="'close-button'"
					aria-label="Close dialog"
					data-a11y-dialog-hide
				>
					<CloseIcon />
				</BaseIconButton>
			</header>

			<slot />
		</div>
	</div>
</template>

<script>
import A11yDialog from "a11y-dialog";

import BaseIconButton from "../elements/BaseIconButton.vue";
import CloseIcon from "../elements/icons/Close.vue";

export default {
	components: {
		BaseIconButton,
		CloseIcon,
	},
	props: {
		title: {
			type: String,
			default: "",
			required: true,
		},
		isDrilldownOpen: {
			type: Boolean,
			default: false,
			required: true,
		},
	},
	data() {
		return {
			dialog: null,
		};
	},
	watch: {
		isDrilldownOpen(isOpen) {
			if (this.dialog && isOpen) {
				this.dialog.show();
			}
		},
	},
	mounted() {
		this.dialog = new A11yDialog(this.$refs.drilldown);
		this.initListener();
	},
	beforeDestroy() {
		this.dialog.destroy();
	},
	methods: {
		initListener() {
			this.dialog
				.on("show", () => document.body.classList.add("disable-scroll"))
				.on("hide", () => this.closeDrilldown())
				.on("destroy", () => {
					document.body.classList.remove("disable-scroll");
					this.dialog = null;
				});
		},
		closeDrilldown() {
			document.body.classList.remove("disable-scroll");
			this.$emit("close-drilldown");
		},
	},
};
</script>

<style scoped>
#drilldown[aria-hidden='true'] {
	display: none !important;
}
</style>

<style>
body.disable-scroll {
	overflow: hidden;
}
</style>
