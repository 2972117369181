var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "label",
        { staticClass: "tw-text-sm tw-font-bold", attrs: { for: "language" } },
        [_vm._v("\n\t\t" + _vm._s(_vm.$t("selectLanguage")) + " *\n\t")]
      ),
      _vm._v(" "),
      _c(
        "BaseDropdown",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "language-dropdown",
              expression: "'language-dropdown'",
            },
            {
              name: "data-cy",
              rawName: "v-data-cy",
              value: "language-dropdown",
              expression: "'language-dropdown'",
            },
          ],
          ref: "languagesDropdown",
          class: { warning: _vm.error },
          attrs: { items: _vm.essayLanguages },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ item }) {
                return [
                  _c(
                    "BaseRadio",
                    {
                      directives: [
                        {
                          name: "data-cy",
                          rawName: "v-data-cy",
                          value: `language-${item.id}`,
                          expression: "`language-${item.id}`",
                        },
                      ],
                      attrs: {
                        "radio-name": "language",
                        "radio-value": item.id,
                        "radio-id": `language-${item.id}`,
                      },
                      model: {
                        value: _vm.essayLanguage,
                        callback: function ($$v) {
                          _vm.essayLanguage = $$v
                        },
                        expression: "essayLanguage",
                      },
                    },
                    [
                      _c("template", { slot: "text" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" + _vm._s(item.language) + "\n\t\t\t\t"
                        ),
                      ]),
                    ],
                    2
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "filters" }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.languageDropdownText) + "\n\t\t"),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.error,
              expression: "error",
            },
            {
              name: "jest",
              rawName: "v-jest",
              value: "language-error",
              expression: "'language-error'",
            },
          ],
          staticClass: "tw-text-sm tw-font-bold tw-text-origami-red-400",
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.$t("selectLanguage")) + "\n\t")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }