import ChatIntro from "@/modules/Classroom/classes/ChatIntro.js";
import MessageText from "@/modules/Classroom/classes/MessageText.js";
import ReceivedMessage from "@/modules/Classroom/classes/ReceivedUserMessageDecorator.js";
import MessageMedia from "@/modules/Classroom/classes/MessageMedia.js";
import MessageSystem from "@/modules/Classroom/classes/MessageSystem.js";
import MessageQuestionMatcher from "@/modules/Classroom/classes/MessageQuestionMatcher.js";

/**
 *
 * @param {Object} data
 * @param {String} data.firstName
 * @param {String} data.lastName
 * @returns {{senderFirstName: *, senderLastName: *}}
 */
const createObjectForChatIntro = (data) => {
	return {
		...data,
		senderFirstName: data.firstName,
		senderLastName: data.lastName,
	};
};

/**
 *
 * @param {Object} data
 * @param {String} data.user.data.first_name
 * @param {String} data.user.data.last_name
 * @param {String} data.user.data.profile
 * @param {String} data.isTutor
 * @param {Object} messageObject
 * @returns {{senderFirstName: *, message: *, senderLastName: *, senderImage: string, isTutor: *}}
 */
const createObjectForReceivedMessage = (data, messageObject) => {
	return {
		message: messageObject,
		senderFirstName: data.user.data.first_name,
		senderLastName: data.user.data.last_name,
		senderImage: data.user.data.profile,
		isTutor: data.isTutor,
		userReactions: data.userReactions,
		bookmarkedAt: data.bookmarkedAt,
		userAvatar: {
			avatar: data.user.data.avatar,
			firstName: data.user.data.first_name,
			lastName: data.user.data.last_name,
		},
	};
};

/**
 * @param {Number} data.id
 * @param {Number} data.user_id
 * @param {Number} data.created_at
 * @param {String} data.sequencePosition
 * @param {Boolean} data.isLastMessage
 * @param {Boolean} data.isQuestion
 * @returns {{createdAt: *, sequencePosition: *, isQuestion: Boolean, isLastMessage: Boolean, id: *, userId: *, status: null, deliveredAt: *}}
 */
const createObjectForMessageBase = (data) => {
	return {
		id: data.id,
		userId: data.user_id,
		createdAt: data.created_at,
		sequencePosition: data.sequencePosition,
		status: null,
		isLastMessage: data.isLastMessage,
		deliveredAt: data.created_at,
		isQuestion: data.isQuestion,
		isDeleted: data.isDeleted,
		userReactions: data.userReactions,
		bookmarkedAt: data.bookmarkedAt,
		voiceNoteMetadata: data.voiceNoteMetadata,
	};
};

/**
 * @param {Number} data.id
 * @param {Number} data.user_id
 * @param {Number} data.created_at
 * @param {String} data.sequencePosition
 * @param {Boolean} data.isLastMessage
 * @param {Boolean} data.isQuestion
 * @param {String} data.fileData.file_path
 * @param {String} [data.fileData.thumbnail_file_path]
 * @param {String} data.fileData.file_type
 * @returns {{createdAt: *, sequencePosition: *, isQuestion: ((function(): Boolean)|boolean), isLastMessage: boolean, mediaType: *, id: *, userId: *, url: *, status: null, deliveredAt: *}}
 */
const createObjectForMessageMedia = (data) => {
	const messageMedia = {
		...createObjectForMessageBase(data),
		url: data.fileData.file_path,
		fileName: data.fileData.basename,
		mediaType: data.fileData.file_type,
	};
	if (data.fileData.file_type === "image" || data.fileData.file_type === "video") {
		messageMedia.thumbnailUrl = data.fileData.file_thumbnail || data.fileData.file_path;
	}
	return messageMedia;
};

/**
 * @param {Number} data.id
 * @param {Number} data.user_id
 * @param {Number} data.created_at
 * @param {String} data.sequencePosition
 * @param {Boolean} data.isLastMessage
 * @param {Boolean} data.isQuestion
 * @param {String} data.message
 */
const createObjectForMessageText = (data) => {
	return {
		...createObjectForMessageBase(data),
		text: data.message,
		json: data.json,
	};
};

/**
 *
 * @param {String} data.message
 * @param {String} data.name
 * @param {Array} data.members
 * @returns {{members: ((function(): [{latestConnectionUpdate: {data: {user_id: number, session_id: number, online: boolean, id: number, time: string}}, last_name: string, online: boolean, id: number, first_name: string}, {latestConnectionUpdate: {data: {user_id: number, session_id: number, online: boolean, id: number, time: string}}, last_name: string, online: boolean, id: number, first_name: string}, {latestConnectionUpdate: {data: {user_id: number, session_id: number, online: boolean, id: number, time: string}}, last_name: string, online: boolean, id: number, first_name: string}, {latestConnectionUpdate: {data: {user_id: number, session_id: number, online: boolean, id: number, time: string}}, last_name: string, online: boolean, id: number, first_name: string}])|(function(): [{latestConnectionUpdate: {data: {user_id: number, session_id: number, online: boolean, id: number, time: string}}, last_name: string, online: boolean, id: number, first_name: string}])|(function(): [{latestConnectionUpdate: {data: {user_id: number, session_id: number, online: boolean, id: number, time: string}}, last_name: string, online: boolean, id: number, first_name: string}])|Array<TSTypeElement>|Array<TSEnumMember>|any[]|TSEnumMember[]|TSTypeElement[]|Record<string, JestMock.MockFunctionMetadata<T, Y>>|Chai.Members), name: *, text: *, isEndedSession: *}}
 */
const createObjectForMessageSystem = (data) => {
	const endSession = data.end_session ? data.end_session : false;
	return {
		text: data.message,
		isEndedSession: endSession,
		name: data.name,
		members: data.members,
	};
};

const messageCreateFunctionDict = {
	welcome: (data) => new ChatIntro(createObjectForChatIntro(data)),
	questionMatcher: (data) => new MessageQuestionMatcher(data),
	system: (data) => new MessageSystem(createObjectForMessageSystem(data)),
	media: (data) => {
		let message = new MessageMedia(createObjectForMessageMedia(data));
		if (data.justification === "left") {
			message = new ReceivedMessage(createObjectForReceivedMessage(data, message));
		}
		return message;
	},
	text: (data) => {
		let message = new MessageText(createObjectForMessageText(data));
		if (data.justification === "left") {
			message = new ReceivedMessage(createObjectForReceivedMessage(data, message));
		}
		return message;
	},
};

export default {
	/**
	 * Creates a message object
	 * @param {Object} data
	 * @param {String} data.type
	 * @returns {Object}
	 */
	create(data) {
		const createMessage = messageCreateFunctionDict[data.type];
		if (createMessage === undefined) {
			throw new Error(`The message of type ${data.type} has no associated function for creation`);
		}
		return createMessage(data);
	},
};
