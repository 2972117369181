import { merge } from "lodash";

import { generalTranslations } from "../general.js";

const trans = {
	en: {
		okay: "Okay",
		there_was_error: "There was an error rendering the page. Please contact support.",
		error: "Error",
		error_creating_school: "There was an error creating the school. Please try again.",
		error_create_course: "There was an error creating the course. Please try again.",
		error_update_course: "There was an error updating the course. Please try again.",
		error_students: "There was an error getting the students. Please try again",
		error_schools: "There was an error getting the schools. Please try again",
		error_add_teacher_content_category:
			"There was an error adding teacher content. Please try again.",
		error_edit_teacher_content_category:
			"There was an error editing teacher content. Please try again.",
		start_date: "Error: Invalid Start Date",
		expiry_date: "Error: Invalid Expiry Date",
		error_users:
			"There was an issue displaying the list of users. Please contact support if the issue persists.",
		error_user_edit:
			"There was an issue displaying the user. Please contact support if the issue persists.",
		error_empty_query: "You need to have at least 1 filter in order to search.",
	},
};

export default merge(generalTranslations, trans);
