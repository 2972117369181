import { getFlag } from "@paper-co/web-toolkit";

import SessionsAPI from "@/services/api/Sessions.js";
import MessageMediaEmpty from "@/modules/Classroom/classes/MessageMediaEmpty.js";

/**
 * Params for the api
 * @param {Object} 	obj
 * @param {Object|String} obj.data. If file, File Object, if whiteboard, url
 * @param {Enum[file, whiteboard]} obj.type
 */
function helperFileUploadCreateData({ data, type, messageType, inputSource, duration }) {
	let params;
	if (type === "file" || type === "audio" || type === "camera" || type === "video") {
		params = new FormData();
		params.append("fileType", type);
		params.append("messageType", messageType);
		params.append("files[]", data);
		if (inputSource) {
			params.append("input_source", inputSource);
		}
		if (duration) {
			params.append("duration", duration);
		}
	} else if (type === "whiteboard") {
		params = {
			files: [data],
			fileType: "whiteboard",
			messageType,
		};
		if (inputSource) {
			params["input_source"] = inputSource;
		}
	}
	return Promise.resolve(params);
}

export default {
	namespaced: true,
	actions: {
		/**
		 * Takes all the values needed from the response
		 * and appends it to the list
		 * @param {Object} v
		 * @param {Object} obj
		 * @param {String} obj.fileLocation
		 * @param {String} obj.filetype
		 * @param {Number} obj.messageId
		 * @param {Number} obj.sessionId
		 * @param {Number} obj.userId
		 */
		async fileUploadAppendToMessagesList(
			{ dispatch },
			{ fileLocation, filetype, messageId, sessionId, userId, basename, voiceNoteMetadata },
		) {
			try {
				const data = {
					id: messageId,
					user_id: userId,
					created_at: Math.floor(Date.now() / 1000),
					file: {
						id: messageId,
						filepath: fileLocation,
						filetype,
						basename,
					},
					sessionId: sessionId,
					justification: "right",
					voiceNoteMetadata,
				};
				await dispatch("Classroom/appendMessage", { data }, { root: true });
				return Promise.resolve();
			} catch (e) {
				return Promise.reject(e);
			}
		},

		/**
		 * Main method for handling file uploads
		 * @param {Object} v
		 * @param {Object} 			obj
		 * @param {Object|String} 	obj.data
		 * @param {Number} 			obj.sessionId
		 * @param {String} 			obj.type
		 * @param {Number} 			obj.userId
		 */
		async fileUploadMainHandler({ commit, dispatch }, { data, sessionId, type, userId, messageType = "web_desktop", inputSource, duration }) {
			const featureFlagPaperMessageSentV2IsEnabled = await getFlag("PE-14038-Message-Sent-V2");
			const message = new MessageMediaEmpty({ userId });
			commit("Classroom/PUSH_MESSAGE_IN_SESSION", {
				sessionId,
				message,
			}, { root: true });
			try {
				const params = await helperFileUploadCreateData({
					data,
					type,
					messageType,
					inputSource,
					duration,
				});
				const response = featureFlagPaperMessageSentV2IsEnabled ?
					await SessionsAPI.uploadFileV2(sessionId, params) :
					await SessionsAPI.uploadFile(sessionId, params);

				const { fileLocation, filetype, basename, messageId, voiceNoteMetadata } = response.data;
				const paramsFromResponse = {
					fileLocation,
					filetype,
					basename,
					messageId,
					sessionId,
					userId,
					voiceNoteMetadata,
				};

				dispatch("fileUploadAppendToMessagesList", paramsFromResponse);

			} catch (e) {
				commit("Classroom/DELETE_MESSAGE_MEDIA_EMPTY_IN_MESSAGES_IN_SESSION", {
					sessionId,
				}, { root: true });
				throw e;
			}
		},
	},
};
