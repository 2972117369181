import { store as Dashboard } from "@/modules/EducatorInsights/index.js";

const state = {};

const getters = {};

const mutations = {};

const actions = {};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
	modules: {
		Dashboard,
	},
};
