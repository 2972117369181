import api from "./Api.js";

export default {
	/**
	 * Return all roles
	 * @returns {AxiosPromise}
	 */
	list() {
		return api().get("roles");
	},

	/**
	 * update user's roles
	 * @returns {AxiosPromise}
	 */
	update(userId, data) {
		return api().put(`users/${userId}/roles`, data);
	},
};
