var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("p", { staticClass: "assessment__text assessment__text--bold" }, [
      _vm._v(
        "\n\t\t" +
          _vm._s(_vm.$t("right_sidebar.assessment.subject_and_tags")) +
          "\n\t"
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "BaseDropdown",
          {
            ref: "subjectDropdown",
            staticClass: "form-group",
            attrs: { items: _vm.subjects },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ item }) {
                  return [
                    _c(
                      "BaseRadio",
                      {
                        attrs: {
                          "radio-id": item.name,
                          "radio-name": "subject",
                          "radio-value": item.id,
                          value: item.id,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleSubjectSelected(item)
                          },
                        },
                      },
                      [
                        _c("template", { slot: "text" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(item.name) +
                              "\n\t\t\t\t\t"
                          ),
                        ]),
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
          },
          [
            _c("template", { slot: "filters" }, [
              _vm._v(
                "\n\t\t\t\t" + _vm._s(_vm.subjectDropdownText) + "\n\t\t\t"
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "assessment_subjectContainer" }, [
      _c("div", { staticClass: "assessment_icon" }, [_c("IconInfoSmall")], 1),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "assessment__text--small assessment__text--blue" },
        [
          _vm._v(
            "\n\t\t\t" +
              _vm._s(_vm.$t("right_sidebar.assessment.update_main_subject")) +
              "\n\t\t"
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "assessment__text--small" }, [
      _vm._v(
        "\n\t\t" +
          _vm._s(_vm.$t("right_sidebar.assessment.related_tags")) +
          "\n\t"
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      _vm._l(_vm.sessionTags, function (tag) {
        return _c(
          "div",
          {
            key: tag.id,
            staticClass: "tag",
            on: {
              click: function ($event) {
                return _vm.$emit("tag-remove", tag)
              },
            },
          },
          [
            _c("div", { staticClass: "tag__textWrapper" }, [
              _c("span", [_vm._v(_vm._s(tag.name))]),
            ]),
            _vm._v(" "),
            _c("label", { staticClass: "tag__removeButton" }, [_vm._v("✕")]),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "assessment_subjectContainer" }, [
      _c(
        "div",
        { staticClass: "assessment_icon" },
        [
          _c("IconClose", {
            attrs: { "icon-color": "#84b6e2", width: 16, height: 17 },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "assessment__text--small assessment__text--blue" },
        [
          _vm._v(
            "\n\t\t\t" +
              _vm._s(_vm.$t("right_sidebar.assessment.reject_tags")) +
              "\n\t\t"
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("BaseSearchDropdown", {
          ref: "searchDropdown",
          attrs: {
            id: "searchDropdown",
            value: _vm.searchText,
            type: "text",
            disabled: "false",
            placeholder: "Add a related tag",
            label: "Find anything missing?",
            "auto-complete": true,
            items: _vm.tags,
          },
          on: {
            input: function ($event) {
              return _vm.$emit("search-text-input", $event)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ item }) {
                return [
                  _c("BaseItem", {
                    attrs: { id: item.id, value: item },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.$emit("tag-select", item)
                      },
                    },
                  }),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }