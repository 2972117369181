var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "lightbox" } }, [
    _c(
      "div",
      {
        ref: "lightboxContainer",
        class: _vm.isPopup ? "lightbox__embedded" : "lightbox",
        attrs: {
          id: "lightboxContainer",
          tabindex: "0",
          role: "dialog",
          "aria-label": _vm.$t("preview"),
        },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.clickOut.apply(null, arguments)
          },
        },
      },
      [
        _c("img", {
          ref: "lightboxImage",
          staticClass: "lightbox__img--default",
          style: _vm.transformationStyle,
          attrs: { id: "lightboxImage", src: _vm.currentLink },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isArrowButtonsShowable,
                expression: "isArrowButtonsShowable",
              },
            ],
            staticClass: "lightbox__arrow left",
            on: { click: _vm.previous },
          },
          [_c("IconLeft")],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isArrowButtonsShowable,
                expression: "isArrowButtonsShowable",
              },
            ],
            staticClass: "lightbox__arrow right",
            on: { click: _vm.next },
          },
          [_c("IconLeft")],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "toolbar" },
          [
            _c("IconRotate", {
              staticClass: "toolbar__icon",
              attrs: { tabindex: "0" },
              nativeOn: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.rotateImg.apply(null, arguments)
                },
              },
            }),
            _vm._v(" "),
            _c("IconZoomOut", {
              staticClass: "toolbar__icon",
              attrs: { tabindex: "0" },
              nativeOn: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.zoomOutImg.apply(null, arguments)
                },
              },
            }),
            _vm._v(" "),
            _c("IconZoomIn", {
              staticClass: "toolbar__icon",
              attrs: { tabindex: "0" },
              nativeOn: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.zoomInImg.apply(null, arguments)
                },
              },
            }),
            _vm._v(" "),
            _c("IconDownload", {
              staticClass: "toolbar__icon",
              attrs: { tabindex: "0" },
              nativeOn: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.downloadImg.apply(null, arguments)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "BaseButton",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isDrawButtonVisible,
                    expression: "isDrawButtonVisible",
                  },
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "draw",
                    expression: "'draw'",
                  },
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "lightbox-draw-button",
                    expression: "'lightbox-draw-button'",
                  },
                ],
                staticClass: "toolbar__button",
                attrs: {
                  id: "lightbox__toolbarDrawButton",
                  type: "CANCEL",
                  tabindex: "0",
                },
                nativeOn: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.draw.apply(null, arguments)
                  },
                },
              },
              [
                _c("template", { slot: "text" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" + _vm._s(_vm.$t("draw")) + "\n\t\t\t\t"
                  ),
                ]),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "BaseButton",
              {
                directives: [
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "lightbox-close-button",
                    expression: "'lightbox-close-button'",
                  },
                ],
                staticClass: "toolbar__button",
                attrs: { type: "CANCEL", tabindex: "0" },
                nativeOn: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.close.apply(null, arguments)
                  },
                },
              },
              [
                _c("template", { slot: "text" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" + _vm._s(_vm.$t("close")) + "\n\t\t\t\t"
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }