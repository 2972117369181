<template>
	<div class="tutorComment">
		<div class="header">
			<div class="header__name">
				{{ name }}
			</div>
			<div class="header__date">
				{{ date }}
			</div>
		</div>
		<div class="comment">
			{{ comment }}
		</div>
	</div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
export default {
	props: {
		name: {
			type: String,
			required: false,
		},
		date: {
			type: String,
			required: false,
		},
		comment: {
			type: String,
			required: false,
		},
	},
};
</script>

<style scoped>
.tutorComment {
	margin-top: 16px;
}

.header {
	display: flex;
}

.header__name {
	color: #4a4a4a;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.02px;
}

.header__date {
	color: rgba(74, 74, 74, 0.6);
	font-size: 12px;
	letter-spacing: 0.02px;
	margin-left: 5px;
}

.comment {
	color: #4a4a4a;
	font-size: 12px;
	letter-spacing: 0.02px;
	line-height: normal;
	word-break: break-word;
}
</style>
