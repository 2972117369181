<template>
	<div>
		<p class="assessment__text assessment__text--bold">
			{{ $t("right_sidebar.assessment.subject_and_tags") }}
		</p>
		<div>
			<BaseDropdown
				ref="subjectDropdown"
				class="form-group"
				:items="subjects"
			>
				<template slot="filters">
					{{ subjectDropdownText }}
				</template>
				<template #default="{ item }">
					<BaseRadio
						:radio-id="item.name"
						radio-name="subject"
						:radio-value="item.id"
						:value="item.id"
						@click.native="handleSubjectSelected(item)"
					>
						<template slot="text">
							{{ item.name }}
						</template>
					</BaseRadio>
				</template>
			</BaseDropdown>
		</div>
		<div class="assessment_subjectContainer">
			<div class="assessment_icon">
				<IconInfoSmall />
			</div>
			<div class="assessment__text--small assessment__text--blue">
				{{ $t("right_sidebar.assessment.update_main_subject") }}
			</div>
		</div>
		<div class="assessment__text--small">
			{{ $t("right_sidebar.assessment.related_tags") }}
		</div>
		<div>
			<div
				v-for="tag in sessionTags"
				:key="tag.id"
				class="tag"
				@click="$emit('tag-remove', tag)"
			>
				<div class="tag__textWrapper">
					<span>{{ tag.name }}</span>
				</div>
				<label class="tag__removeButton">✕</label>
			</div>
		</div>
		<div class="assessment_subjectContainer">
			<div class="assessment_icon">
				<IconClose
					icon-color="#84b6e2"
					:width="16"
					:height="17"
				/>
			</div>
			<div class="assessment__text--small assessment__text--blue">
				{{ $t("right_sidebar.assessment.reject_tags") }}
			</div>
		</div>
		<div>
			<BaseSearchDropdown
				id="searchDropdown"
				ref="searchDropdown"
				:value="searchText"
				type="text"
				disabled="false"
				placeholder="Add a related tag"
				label="Find anything missing?"
				:auto-complete="true"
				:items="tags"
				@input="$emit('search-text-input', $event)"
			>
				<template slot-scope="{ item }">
					<BaseItem
						:id="item.id"
						:value="item"
						@click.native="$emit('tag-select', item)"
					/>
				</template>
			</BaseSearchDropdown>
		</div>
	</div>
</template>

<script>
import BaseItem from "@/components/BaseItem.vue";
import BaseRadio from "@/components/BaseRadio.vue";
import BaseSearchDropdown from "@/components/BaseSearchDropdown.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";

import IconInfoSmall from "../../../components/icons/IconInfoSmall.vue";
import IconClose from "../../../components/icons/IconClose.vue";

export default {
	components: {
		IconInfoSmall,
		IconClose,
		BaseItem,
		BaseRadio,
		BaseSearchDropdown,
		BaseDropdown,
	},
	props: {
		session: { //currentSession
			type: Object,
			required: true,
		},
		subjects: { // subjectsArray
			type: Array,
			default: () => [],
		},
		tags: { //tagsArray
			type: Array,
			default: () => [],
		},
		sessionTags: { //sessionTags
			type: Array,
			default: () => [],
		},
		searchText: {
			type: String,
			default: "",
		},
	},
	computed: {
		/**
		 * @return {String}
		 */
		subjectDropdownText() {
			let subject;
			if (this.session?.subjectId) {
				subject = this.subjects.find((subject) => {
					return subject.id === this.session.subjectId;
				});
				this.$emit("subject-search-change", subject);
			}
			return subject !== undefined ? subject.name : "Select a subject";
		},
	},
	methods: {
		handleSubjectSelected(item) {
			this.$emit("subject-select", item);
			this.$refs.subjectDropdown.showDropdown = false;
		},
	},
};
</script>
<style scoped>
.assessment__text {
	font-size: 14px;
	margin-bottom: 12px;
	color: #484756;
}
.assessment__text--bold {
	font-weight: 600;
}
.assessment__text--blue {
	color: var(--c-blue);
}
.assessment__text--small {
	font-size: 12px;
}
.assessment_subjectContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: 8px 0;
}
.assessment_icon {
	padding-right: 8px;
}
.tag {
	display: inline-block;
	border-radius: 5px;
	background-color: var(--c-blue);
	max-width: 100%;
	color: var(--c-white);
	padding: 5px 10px;
	font-size: 12px;
	margin: 5px 10px 5px 0;
	cursor: pointer;
	font-weight: 300;
}
.tag:hover {
	background-color: rgba(88, 108, 178, 0.7);
}
.tag__textWrapper {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 88%;
	display: inline-block;
	vertical-align: sub;
}
.tag__removeButton {
	font-size: 18px;
	margin: 0 0 0 5px;
	cursor: pointer;
}
</style>
