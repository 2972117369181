<template>
	<div>
		<p class="assessment__text assessment__text--bold">
			{{
				$t("right_sidebar.assessment.tag_the_question", { name: session.studentName })
			}}
		</p>
		<p class="assessment__text assessment__text--blue assessment__text--question">
			{{ $t("right_sidebar.assessment.tag_questions_by") }}
		</p>
		<div>
			<div class="assessment__nonAcademicTitle">
				{{ $t("right_sidebar.assessment.is_session_non_academic") }}
			</div>
			<BaseCheckbox
				:value="isNonAcademic"
				class="assessment__nonAcademicCheckbox"
				:checkbox-id="1"
				checkbox-value="nonAcademic"
				@input="$emit('non-academic-check', $event)"
			>
				<template #text>
					<span class="assessment__nonAcademicText assessment__nonAcademicText--bold">{{
						$t("right_sidebar.assessment.it_is")
					}}</span>
				</template>
			</BaseCheckbox>
		</div>
		<div
			v-if="isNonAcademic"
			class="assessment__nonAcademic"
		>
			<div class="assessment__info--bold">
				{{ $t("right_sidebar.assessment.important") }}
			</div>
			<p class="assessment__info--normal">
				{{ $t("right_sidebar.assessment.select_non_academic") }}
				<span class="assessment__info--bold">{{ $t("right_sidebar.assessment.only_if") }}</span>
			</p>
			<ul class="assessment__list">
				<li class="assessment__info--normal">
					{{ $t("right_sidebar.assessment.you_dont_know") }}
				</li>
				<li class="assessment__info--normal">
					{{ $t("right_sidebar.assessment.content_not_school_related") }}
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import BaseCheckbox from "@/components/BaseCheckbox.vue";

export default {
	components: {
		BaseCheckbox,
	},
	props: {
		session: {
			type: Object,
			required: true,
		},
		isNonAcademic: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style scoped>
.assessment__text {
	font-size: 14px;
	margin-bottom: 12px;
	color: #484756;
}
.assessment__text--bold {
	font-weight: 600;
}
.assessment__text--blue {
	color: var(--c-blue);
}
.assessment__text--question {
	font-size: 12px;
	font-weight: 300;
	color: var(--c-blue);
	padding-left: 30px;
	background: url(/images/question_tag_active.svg) no-repeat 0 3px/20px;
	margin: 0;
	border-radius: 5px;
	line-height: 20px;
}
.assessment__nonAcademicTitle {
	width: 100%;
	font-size: 14px;
	font-weight: 600;
	color: #4a4a4a;
}
.assessment__nonAcademicCheckbox {
	margin-top: 9px;
}
.assessment__nonAcademicText {
	font-size: 12px;
	font-weight: normal;
	color: #484756;
}
.assessment__nonAcademicText--bold {
	font-weight: bold;
}
.assessment__nonAcademic {
	width: 100%;
	min-height: 100%;
	border-radius: 5px;
	border: solid 2px var(--c-orange);
	background-color: rgba(255, 158, 69, 0.2);
	padding: 16px 16px;
}
.assessment__info--bold {
	font-size: 14px;
	font-weight: bold;
}
.assessment__info--normal {
	font-size: 14px;
	font-weight: normal;
}
.assessment__list {
	padding-left: 20px;
}
</style>
