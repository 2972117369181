import { logout } from "@/services/root.js";
import { UsersAPI } from "@/modules/Customers/index.js";

export default async function(to, from, next) {
	const user = this.state.currentUser;

	if (!user.school) {
		try {
			const { data } = await UsersAPI.show(user.id, { include: "schools.district" });
			this.commit("SET_CURRENT_USER", {
				currentUser: {
					school: data.data.schools.data[0],
					...user,
				},
			});
			return next();
		} catch (e) {
			Sentry.captureException(e);

			await logout(to);
		}
	} else {
		return next();
	}
}
