import { merge } from "lodash";

const subjects = {
	en: {
		subjects: {
			ActMath: "ACT Math",
			Math: "Math",
			ActReading: "ACT Reading",
			ActScience: "ACT Science",
			Science: "Science",
			English: "English",
			Social: "Social",
			SocialScience: "Social Science",
			Languages: "Languages",
			Other: "Other",
			Biology: "Biology",
			HealthSciences: "Health Sciences",
			Physics: "Physics",
			Chemistry: "Chemistry",
			History: "History",
			Geography: "Geography",
			Law: "Law",
			Politics: "Politics",
			EconomicsFinance: "Economics Finance",
			French: "French",
			Mandarin: "Mandarin",
			Spanish: "Spanish",
			Arabic: "Arabic",
			ComputerScience: "Computer Science",
			PhysicalSciences: "Physical Sciences",
			SociologyAnthropology: "Sociology/Anthropology",
			Psychology: "Psychology",
			Philosophy: "Philosophy",
			ReligionTheology: "Religion/Theology",
			Accounting: "Accounting",
			Marketing: "Marketing",
			ArtHistory: "Art History",
			MusicTheory: "Music Theory",
			Triage: "Unspecified",
		},
	},
	fr: {
		subjects: {
			ActMath: "ACT Math",
			Math: "Math",
			Science: "Science",
			English: "Anglais",
			Social: "Sociale",
			SocialScience: "Science sociale",
			Languages: "Langues",
			Other: "Autre",
			Biology: "Biologie",
			HealthSciences: "Sciences santé",
			Physics: "Physique",
			Chemistry: "Chimie",
			History: "Histoire",
			Geography: "Géographie",
			Law: "Loi",
			Politics: "Politiques",
			EconomicsFinance: "Finances économiques",
			French: "Français",
			Mandarin: "Mandarin",
			Spanish: "Español",
			Arabic: "Arabe",
			ComputerScience: "Informatiques",
			PhysicalSciences: "Sciences Physiques",
			SociologyAnthropology: "Sociologie/Anthropologie",
			Psychology: "Psychologie",
			Philosophy: "Philosophie",
			ReligionTheology: "Religion/Théologie",
			Accounting: "Comptabilité",
			Marketing: "Commercialisation",
			ArtHistory: "Histoire de l'art ",
			MusicTheory: "Théorie musicale",
			Triage: "Non spécifié",
		},
	},
	es: {
		subjects: {
			ActMath: "ACT Math",
			Math: "Matemáticas",
			Science: "Ciencias",
			English: "Inglés",
			Social: "Sociales",
			SocialScience: "Ciencias Sociales",
			Languages: "Idiomas",
			Other: "Otro",
			Biology: "BiologÍa",
			HealthSciences: "Ciencias de la Salud",
			Physics: "Física",
			Chemistry: "Química",
			History: "Historia",
			Geography: "Geografía",
			Law: "Leyes",
			Politics: "Política",
			EconomicsFinance: "Economía y Finanzas",
			French: "Francés",
			Mandarin: "Mandarín",
			Spanish: "Español",
			Arabic: "Árabe",
			ComputerScience: "Computación",
			PhysicalSciences: "Ciencias Físicas",
			SociologyAnthropology: "Sociología/Antropología",
			Psychology: "Psicología",
			Philosophy: "Filosofía",
			ReligionTheology: "Religión/Teología",
			Accounting: "Contabilidad",
			Marketing: "Mercadeo",
			ArtHistory: "Historia del Arte",
			MusicTheory: "Teoría Musical",
			Triage: "No especificado",
		},
	},
};
const topics = {
	en: {
		topics: {
			/*Math*/
			Arithmetic: "Arithmetic",
			AdvancedArithmetic: "Advanced Arithmetic",
			AdvancedGeometry: "Advanced Geometry",
			BasicArithmetic: "Basic Arithmetic",
			BasicGeometry: "Basic Geometry",
			Geometry: "Geometry",
			Calculus: "Calculus",
			Calculus1: "Calculus 1",
			Calculus2: "Calculus 2",
			Calculus3: "Calculus 3",
			DiscreteMath: "Discrete Math",
			Equations: "Equations",
			Functions: "Functions",
			PureMathematics: "Pure Mathematics",
			AppliedMathematics: "Applied Mathematics",
			Statistics: "Statistics",
			Probability: "Probability",
			Trigonometry: "Trigonometry",
			Algebra: "Algebra",
			BasicAlgebra: "Basic Algebra",
			PreCalculus: "Pre-Calculus",
			ApCalculus: "AP Calculus",
			Sets: "Sets",
			LinearAlgebra: "Linear Algebra",

			/*Science*/
			AnimalBiology: "Animal Biology",
			AtomicTheory: "Atomic Theory",
			Biology: "Biology",
			BiologyLabs: "Biology Labs",
			Biotechnology: "Biotechnology",
			Cartography: "Cartography",
			CellularBiology: "Cellular Biology",
			ChemicalReactions: "Chemical Reactions",
			ChemicalEquilibrium: "Chemical Equilibrium",
			ChemistryLabs: "Chemistry Labs",
			ChemistryOfSolutions: "Chemistry of Solutions",
			ElectricCircuits: "Electric Circuits",
			ElectromagnetismAndElectrostatics: "Electromagnetism and Electrostatics",
			FluidMechanics: "Fluid Mechanics",
			GeometricalOptics: "Geometrical Optics",
			Gravitation: "Gravitation",
			Kinematics: "Kinematics",
			Kinesiology: "Kinesiology",
			Genetics: "Genetics",
			HumanBiology: "Human Biology",
			Physics: "Physics",
			Chemistry: "Chemistry",
			Ecology: "Ecology",
			Geology: "Geology",
			Biochemistry: "Biochemistry",
			MedicalTechnologies: "Medical Technologies",
			MetabolicProcesses: "Metabolic Processes",
			Microbiology: "Microbiology",
			MolecularBiology: "Molecular Biology",
			MolesAndMatter: "Moles and Matter",
			Neurobiology: "Neurobiology",
			NuclearFissionAndFusion: "Nuclear Fission and Fusion",
			PhysicsLabs: "Physics Labs",
			Physiology: "Physiology",
			PlantBiology: "Plant Biology",
			PopulationDynamics: "Population Dynamics",
			CellBiology: "Cell Biology",
			OrganicChemistry: "Organic Chemistry",
			InorganicChemistry: "Inorganic Chemistry",
			Stoichiometry: "Stoichiometry",
			PhysicalSciences: "Physical Science",
			EarthScience: "Earth Sciences",
			HistorySocialStudies: "History and Social Studies",
			SocialStudies: "Social Sciences",
			EarthAndPlanetarySciences: "Earth and Planetary Sciences",
			PlanetaryScience: "Planetary Science",
			NutritionalScience: "Nutritional Science",
			ScientificApplications: "Scientific Applications",
			Thermodynamics: "Thermodynamics",
			EvolutionSpecies: "Evolution (Species)",

			/*English*/
			EnglishAsASecondLanguage: "English as a Second Language",
			EssayWriting: "Essay Writing",
			EnglishLiterature: "English Literature",
			LiteratureStudies: "Literature Studies",
			ReadingComprehension: "Reading Comprehension",

			/*Social Science*/
			AmericanLaw: "American Law",
			AmericanPolitics: "American Politics",
			BasicGeography: "Basic Geography",
			CanadianHistory: "Canadian History",
			CanadianLaw: "Canadian Law",
			CanadianPolitics: "Canadian Politics",
			AmericanHistory: "American History",
			InternationalLaw: "International Law",
			WorldHistory: "World History",
			Religion: "Religion",
			Humanities: "Humanities",
			ArtHistory: "Art History",
			Sociology: "Sociology",
			Psychology: "Psychology",
			Theology: "Theology",
			Anthropology: "Anthropology",
			History: "History",
			Geography: "Geography",
			ReligionAndTheology: "Religion and Theology",
			EuropeanHistory: "European History",
			GeologyEarthHistory: "Geology/Earth History",
			MoneyFinancialMathematics: "Money/Financial Mathematics",
			TaxonomyClassification: "Taxonomy/Classification",
			PathogensDiseases: "Pathogens/Diseases",
			SciencePublicHealthIssues: "Science/Public Health Issues",
			EnergyWorkPower: "Energy/Work/Power",
			ForcesAndNewtonsLawsDynamics: "Forces and Newton's Laws/Dynamics",
			QuantumMechanicsSpecialRelativity: "Quantum Mechanics/Special Relativity",
			WavesSoundLight: "Waves/Sound/Light",
			AcidsBases: "Acids/Bases",
			EnergyChangesThermochemistry: "Energy Changes/Thermochemistry",
			GasesAtmosphericChemistry: "Gases/Atmospheric Chemistry",
			NomenclaturePeriodicClassification: "Nomenclature/Periodic Classification",
			OxidationReduction: "Oxidation/Reduction",
			AstronomySpaceExploration: "Astronomy/Space Exploration",

			/*Languages*/
			French: "French",
			German: "German",
			Spanish: "Spanish",
			Mandarin: "Mandarin",
			Hebrew: "Hebrew",
			Arabic: "Arabic",
			Romanian: "Romanian",
			Italian: "Italian",

			/*Others*/
			AmericanEconomy: "American Economy",
			CanadianEconomy: "Canadian Economy",
			MusicTheory: "Music Theory",
			ComputerScience: "Computer Science",
			Microeconomics: "Microeconomics",
			Macroeconomics: "Macroeconomics",
			Marketing: "Marketing",
			Accounting: "Accounting",
			HealthAndNutrition: "Health and Nutrition",
			Economics: "Economics",
			StudyTips: "Study Tips",
			InternationalBusiness: "International Business",
			Finance: "Finance",
			Avid: "AVID",
			CollegeApplicationCounselling: "College Application Counselling",
			no_topic: "No Topic",
			EconomicsFinance: "Economics/Finance",
			ResourceManagement: "Resource Management",
			Employment: "Employment",
			PersonalFinanceEconomics: "Personal Finance Economics",
			PrinciplesOfEconomics: "Principles of Economics",
			FrenchWrittenComprehension: "French Written Comprehension",
			FrenchAsASecondLanguage: "French as a Second Language",
			FrenchLiterature: "French Literature",
			FrenchEssayWriting: "French Essay Writing",
			MandarinAsASecondLanguage: "Mandarin as a Second Language",
			MandarinEssayWriting: "Mandarin Essay Writing",
			SpanishAsASecondLanguage: "Spanish as a Second Language",
			SpanishEssayWriting: "Spanish Essay Writing",
			SpanishLiterature: "Spanish Literature",
			SpanishWrittenComprehension: "Spanish Written Comprehension",
			ArabicAsASecondLanguage: "Arabic as a Second Language",
			ArabicEssayWriting: "Arabic Essay Writing",
			Algorithms: "Algorithms",
			Hardware: "Hardware",
			Java: "Java",
			Javascript: "Javascript",
			OperatingSystems: "Operating Systems",
			PHP: "PHP",
			ProgrammingBasics: "Programming Basics",
			Python: "Python",
			WebDevelopmentHtmlCss: "Web Development (HTML/CSS)",
			EnvironmentalScience: "Environmental Science",
			TravelTourism: "Travel/Tourism",
			Oceanography: "Oceanography",
			PopulationDemography: "Population/Demography",
			InternationalDevelopmentWorldIssues: "International Development/World Issues",
			PoliticalPrinciplesFoundations: "Political Principles/Foundations",
			GlobalInterdependenceInequalities: "Global Interdependence/Inequalities",
			CCCObjectiveC: "C++ / C / C# / Objective C",
		},
	},
	fr: {
		topics: {
			/* Math */
			Arithmetic: "Arithmétique",
			Geometry: "Géométrie",
			Calculus1: "Calcul 1",
			Calculus2: "Calcul 2",
			Calculus3: "Calcul 3",
			DiscreteMath: "Mathématiques discrètes",
			PureMathematics: "Mathématiques pures",
			AppliedMathematics: "Mathématiques appliquées",
			Statistics: "Statistiques",
			Probability: "Probabilités",
			Trigonometry: "Trigonométrie",
			Algebra: "Algèbre",
			BasicAlgebra: "Algèbre",
			PreCalculus: "Pre-Calcul",
			ApCalculus: "AP Calcul",
			Sets: "Ensembles",
			LinearAlgebra: "Algèbre Linéaire",

			/*Science*/
			Biology: "Biologie",
			Physics: "Physique",
			Chemistry: "Chimie",
			Ecology: "Écologie",
			Geology: "Géologie",
			Biochemistry: "Biochimie",
			Neurobiology: "Neurobiologie",
			CellBiology: "Biologie cellulaire",
			OrganicChemistry: "Chimie organique",
			InorganicChemistry: "Chimie inorganique",
			Stoichiometry: "Stoechiométrie",
			PhysicalSciences: "Sciences Physiques",
			EarthAndPlanetarySciences: "Science des planètes et la terre",
			EarthScience: "Science de la terre",
			HistorySocialStudies: "Histoire et études sociales",
			SocialStudies: "Études Sociales",

			/*English*/
			EnglishAsASecondLanguage: "Anglais langue secondaire",
			EssayWriting: "Rédaction",
			EnglishLiterature: "Littérature anglaise",

			/*Social Science*/
			CanadianHistory: "Histoire Canadienne",
			AmericanHistory: "Histoire Américaine",
			WorldHistory: "Histoire Mondiale",
			Religion: "Religion",
			Humanities: "Humanités",
			ArtHistory: "Histoire de l'art ",
			Sociology: "Sociologie",
			Psychology: "Psychologie",
			Theology: "Théologie",
			Anthropology: "Anthropologie",
			History: "Histoire",
			Geography: "Géographie",
			ReligionAndTheology: "Religion et théologie",

			/*Languages*/
			French: "Français",
			German: "Allemand",
			Spanish: "Español",
			Mandarin: "Mandarin",
			Hebrew: "Hébreu",
			Arabic: "Arabe",
			Romanian: "Romain",
			Italian: "Italien",

			/*Others*/
			MusicTheory: "Théorie musicale",
			ComputerScience: "Informatiques",
			Microeconomics: "Microéconomie",
			Macroeconomics: "Macroéconomie",
			Marketing: "Commercialisation",
			Accounting: "Comptabilité",
			HealthAndNutrition: "Santé et Nutrition",
			Economics: "Économie",
			StudyTips: "Conseils d'études",
			InternationalBusiness: "Commerce Interational",
			Finance: "Finances",
			Avid: "AVID",
			CollegeApplicationCounselling: "Aides aux demandes d'admission",
			no_topic: "Aucun sujet",
			EconomicsFinance: "Économie et finance",
		},
	},
	es: {
		topics: {
			/*Math*/
			Arithmetic: "Aritmética",
			Geometry: "Geometría",
			Calculus1: "Cálculo 1",
			Calculus2: "Cálculo 2",
			Calculus3: "Cálculo 3",
			DiscreteMath: "Matemática Discreta",
			PureMathematics: "Matemáticas Puras",
			AppliedMathematics: "Matemática Aplicada",
			Statistics: "Estadísticas",
			Probability: "Probabilidades",
			Trigonometry: "Trigonometría",
			Algebra: "Álgebra",
			BasicAlgebra: "Álgebra Básica",
			PreCalculus: "Pre-Cálculo",
			ApCalculus: "Cálculo Avanzado (AP)",
			Sets: "Conjuntos",
			LinearAlgebra: "Álgebra Lineal",

			/*Science*/
			Biology: "Biología",
			Physics: "Física",
			Chemistry: "Química",
			Ecology: "Ecología",
			Geology: "Geología",
			Biochemistry: "Bioquímica",
			Neurobiology: "Neurobiología",
			CellBiology: "Biología Celular",
			OrganicChemistry: "Química Orgánica",
			InorganicChemistry: "Química Inorgánica",
			Stoichiometry: "Estequiometría",
			PhysicalSciences: "Ciencias Físicas",
			EarthScience: "Ciencias de la Tierra",
			HistorySocialStudies: "Historia y Estudios Sociales",
			SocialStudies: "Estudios Sociales",
			EarthAndPlanetarySciences: "Ciencias de la Tierra y Planetas",

			/*English*/
			EnglishAsASecondLanguage: "Inglés como segundo lenguaje",
			EssayWriting: "Escritura",
			EnglishLiterature: "Literatura Inglesa",

			/*Social Science*/
			CanadianHistory: "Historia Canadiense",
			AmericanHistory: "Historia Americana",
			WorldHistory: "Historia Mundial",
			Religion: "Religión",
			Humanities: "Humanidades",
			ArtHistory: "Historia del Arte",
			Sociology: "Sociología",
			Psychology: "Psicología",
			Theology: "Teología",
			Anthropology: "Antropología",
			History: "Historia",
			Geography: "Geografía",
			ReligionAndTheology: "Religión y Teología",

			/*Languages*/
			French: "Francés",
			German: "Alemán",
			Spanish: "Español",
			Mandarin: "Mandarín",
			Hebrew: "Hebreo",
			Arabic: "Árabe",
			Romanian: "Rumano",
			Italian: "Italiano",

			/*Others*/
			MusicTheory: "Teoría musical",
			ComputerScience: "Computación",
			Microeconomics: "Microeconomía",
			Macroeconomics: "Macroeconomía",
			Marketing: "Mercadeo",
			Accounting: "Contabilidad",
			HealthAndNutrition: "Salud y Nutrición",
			Economics: "Economía",
			"Study Tips": "Consejos de estudio",
			InternationalBusiness: "Negocios Internacionales",
			Finance: "Finanzas",
			Avid: "AVID",
			CollegeApplicationCounselling: "Consejería para aplicaciones universitarias",
			no_topic: "Sin tema",
			EconomicsFinance: "Economics/Finance",
		},
	},
};

export const subjectsAndTopicsUnderTopics = {
	en: { topics: { ...subjects.en.subjects, ...topics.en.topics } },
	fr: { topics: { ...subjects.fr.subjects, ...topics.fr.topics } },
	es: { topics: { ...subjects.es.subjects, ...topics.es.topics } },
};

export const subjectsAndTopicsSeparate = merge(topics, subjects);
