var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "nav",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: () => (_setup.isResourceHubOpen = false),
          expression: "() => isResourceHubOpen = false",
        },
        {
          name: "jest",
          rawName: "v-jest",
          value: "resource-hub-dropdown",
          expression: "'resource-hub-dropdown'",
        },
      ],
      staticClass: "tw-relative tw-z-10 tw-hidden xl:tw-flex",
      attrs: { "aria-label": "Resources" },
    },
    [
      _c(
        "button",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "dropdown-toggle",
              expression: "'dropdown-toggle'",
            },
          ],
          ref: "dropdownToggle",
          staticClass:
            "resources-toggle tw-text-origami-white tw-font-bold tw-flex tw-items-center",
          attrs: {
            "aria-controls": "resourcesHubDropdown",
            "aria-expanded": _setup.isResourceHubOpen.toString(),
          },
          on: {
            click: function ($event) {
              _setup.isResourceHubOpen = !_setup.isResourceHubOpen
            },
            keydown: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                )
                  return null
                if (!$event.shiftKey) return null
                _setup.isResourceHubOpen = false
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape",
                  ])
                )
                  return null
                _setup.isResourceHubOpen = false
              },
            ],
          },
        },
        [
          _vm._v("\n\t\t" + _vm._s(_vm.$t("navbar.resources")) + "\n\t\t"),
          _c(_setup.OrigamiIcon, {
            staticClass: "tw-ml-2",
            class: { "tw-rotate-180": _setup.isResourceHubOpen },
            attrs: { name: "caret" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _setup.isResourceHubOpen,
                expression: "isResourceHubOpen",
              },
              {
                name: "jest",
                rawName: "v-jest",
                value: "dropdown-list",
                expression: "'dropdown-list'",
              },
            ],
            staticClass: "resources-dropdown",
            attrs: { id: "resourcesHubDropdown" },
          },
          _vm._l(_setup.resourcesLinks, function (resourceLink, index) {
            return _c(
              "li",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "dropdown-list-item",
                    expression: "'dropdown-list-item'",
                  },
                ],
                key: resourceLink.title,
                class: { "tw-mb-6": index < _setup.resourcesLinks.length - 1 },
                on: {
                  click: function ($event) {
                    _setup.isResourceHubOpen = false
                  },
                  keydown: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, [
                          "Esc",
                          "Escape",
                        ])
                      )
                        return null
                      return _setup.focusDropdownToggle()
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                      )
                        return null
                      if (
                        $event.ctrlKey ||
                        $event.shiftKey ||
                        $event.altKey ||
                        $event.metaKey
                      )
                        return null
                      index === _setup.resourcesLinks.length - 1 &&
                        (_setup.isResourceHubOpen = false)
                    },
                  ],
                },
              },
              [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: `dropdown-link-${resourceLink.title}`,
                        expression: "`dropdown-link-${resourceLink.title}`",
                      },
                    ],
                    staticClass: "tw-flex tw-items-center tw-text-base",
                    attrs: { href: resourceLink.target, target: "_blank" },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.$t(resourceLink.title)) +
                        "\n\t\t\t\t\t"
                    ),
                    _c(_setup.OrigamiIcon, {
                      staticClass: "ml-2",
                      attrs: { name: "external" },
                    }),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }