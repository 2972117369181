import { unescape } from "lodash";

import { DistrictsAPI, SchoolsAPI, UsersAPI } from "@/modules/Customers/index.js";
import { CommunicationsAPI } from "@/modules/Communications/index.js";

import RolesAPI from "../../../services/api/Roles.js";
import ListTransformer from "../../../services/transformers/listTransformer.js";
import GradesAPI from "../../../services/api/Grades.js";

// From modules

export const USER_RESTRICTED_ROLES = new Set([
	"tutor",
	"tutor-manager",
	"superuser",
	"superuser-creator",
	"schedule-builder/superuser",
	"schedule-builder/creator",
]);

export const CREATOR_RESTRICTED_ROLES = new Set([
	"tutor",
	"tutor-manager",
	"schedule-builder/superuser",
	"schedule-builder/creator",
]);

const USER_RESTRICTED_ROLES_FOR_SEARCH = new Set([
	"tutor",
	"tutor-manager",
	"superuser",
	"superuser-creator",
]);

const CREATOR_RESTRICTED_ROLES_FOR_SEARCH = new Set([
	"tutor",
	"tutor-manager",
	"superuser",
]);

export const getDefaultState = () => ({
	communications: [],
	pagination: {
		total_pages: 0,
		current_page: 1,
	},

	// communication create
	schools: [],
	districts: [],
	audienceRoles: [],
	selectedSchoolIds: [],
	selectedDistrictIds: [],
	selectedAudienceRoleIds: [],
	searchText: "",
	filteredUsers: [],
	selectedUsers: [],
	inferredAudienceCount: 0,
	navbarMainTab: "",

	// communication list
	communicationId: 0,

	// user list
	users: [],
	roles: [],
	statuses: [
		{ id: 1, name: "active" },
		{ id: 0, name: "expired" },
	],
	selectedDistricts: [],
	selectedRoles: [],
	selectedSchools: [],
	selectedStatus: null,
	schoolFilter: [],
	districtFilter: [],
	roleFilter: [],
	activeFilter: null,
	isShowingNoFilterBanner: true,
	loading: false,
	orderBy: "",
	usersTableHeaders: [
		{
			value: "synced_with",
			header: "",
			orderable: false,
			state: null,
		},
		{
			value: "id",
			header: "ID",
			orderable: true,
			state: null,
		},
		{
			value: "role",
			header: "role",
			orderable: true,
			state: null,
		},
		{
			value: "fullName",
			header: "full name",
			orderable: true,
			state: null,
		},
		{
			value: "username",
			header: "username",
			orderable: true,
			state: null,
		},
		{
			value: "email",
			header: "email",
			orderable: true,
			state: null,
		},
		{
			value: "district",
			header: "district",
			orderable: true,
			state: null,
		},
		{
			value: "school",
			header: "school",
			orderable: true,
			state: null,
		},
		{
			value: "license",
			header: "license",
			orderable: true,
			state: null,
		},
		{
			value: "impersonation",
			header: "",
			orderable: false,
			state: null,
		},
	],

	// user create
	grades: [],
	errorMessage: null,
});

export const getters = {
	communications(state) {
		return state.communications;
	},
	filteredSchools(state) {
		const isSchoolInDistricts = (schoolDistrictId, districtIds) => {
			return !!districtIds.find((districtId) => {
				return districtId === schoolDistrictId;
			});
		};

		if (state.selectedDistricts.length === 0) {
			return state.schools;
		} else if (state.selectedDistricts.length > 0) {
			return state.schools.filter((school) => {
				return isSchoolInDistricts(
					school.district.data.id,
					state.selectedDistricts,
				);
			});
		}
	},
	userAuthorizedRoles(state) {
		return state.roles.filter((role) => !USER_RESTRICTED_ROLES.has(role.name));
	},
	creatorAuthorizedRoles(state) {
		return state.roles.filter((role) => !CREATOR_RESTRICTED_ROLES.has(role.name));
	},
	creatorAuthorizedRolesForSearch(state) {
		return state.roles.filter((role) => !CREATOR_RESTRICTED_ROLES_FOR_SEARCH.has(role.name));
	},
	userAuthorizedRolesForSearch(state) {
		return state.roles.filter((role) => !USER_RESTRICTED_ROLES_FOR_SEARCH.has(role.name));
	},
};

export const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_NAVBAR_MAIN_TAB(state, tabName) {
		state.navbarMainTab = tabName;
	},
	SET_COMMUNICATIONS(state, payload) {
		state.communications = payload.communications;
	},
	SET_PAGINATION(state, payload) {
		state.pagination = payload.pagination;
	},
	RESET_PAGINATION(state) {
		state.pagination = { total_pages: 0, current_page: 1 };
	},
	SET_CURRENT_PAGE(state, payload) {
		state.pagination.current_page = payload.currentPage;
	},

	// communication create
	SET_SCHOOLS(state, payload) {
		state.schools = payload.schools;
	},
	SET_DISTRICTS(state, payload) {
		state.districts = payload.districts;
	},
	SET_AUDIENCE_ROLES(state, payload) {
		state.audienceRoles = payload.audienceRoles;
	},
	SET_FILTERED_USERS(state, payload) {
		state.filteredUsers = payload.filteredUsers;
	},
	SET_SELECTED_DISTRICT_IDS(state, payload) {
		state.selectedDistrictIds = payload.selectedDistrictIds;
	},
	SET_SELECTED_SCHOOL_IDS(state, payload) {
		state.selectedSchoolIds = payload.selectedSchoolIds;
	},
	SET_SELECTED_AUDIENCE_ROLE_IDS(state, payload) {
		state.selectedAudienceRoleIds = payload.selectedAudienceRoleIds;
	},
	SET_SEARCH_TEXT(state, payload) {
		state.searchText = payload.searchText;
	},
	SET_SELECTED_USERS(state, payload) {
		state.selectedUsers = payload.selectedUsers;
	},
	SET_INFERRED_AUDIENCE_COUNT(state, payload) {
		state.inferredAudienceCount = payload.inferredAudienceCount;
	},

	// communication list
	SET_COMMUNICATION_ID(state, payload) {
		state.communicationId = payload.communicationId;
	},

	// users list
	SET_IS_SHOWING_NO_FILTER_BANNER(state, payload) {
		state.isShowingNoFilterBanner = payload.isShowingNoFilterBanner;
	},
	SET_USERS(state, payload) {
		state.users = payload.users;
	},
	SET_SELECTED_ROLES(state, payload) {
		state.selectedRoles = payload.roles;
	},
	SET_SELECTED_DISTRICTS(state, payload) {
		state.selectedDistricts = payload.districts;
	},
	SET_SELECTED_SCHOOLS(state, payload) {
		state.selectedSchools = payload.schools;
	},
	SET_SELECTED_STATUS(state, payload) {
		state.selectedStatus = payload.status;
	},
	SET_LOADING(state, payload) {
		state.loading = payload.loading;
	},
	SET_ORDER_BY(state, payload) {
		state.orderBy = payload.orderBy;
	},
	UPDATE_HEADERS(state, payload) {
		state.usersTableHeaders = payload.usersTableHeaders;
	},
	SET_ROLES(state, payload) {
		state.roles = payload.roles;
	},
	SET_FILTERS(state, payload) {
		state.searchText = payload.searchText;
		state.districtFilter = payload.districtFilter;
		state.schoolFilter = payload.schoolFilter;
		state.roleFilter = payload.roleFilter;
		state.activeFilter = payload.activeFilter;
	},

	// users create
	SET_ERRORS(state, payload) {
		state.errorMessage = payload.errorMessage;
	},
	SET_GRADES(state, payload) {
		state.grades = payload.grades;
	},
};

export const actions = {
	async setCommunications({ state, commit }) {
		try {
			const communications = await CommunicationsAPI.list({
				page: state.pagination.current_page,
				include: "notification",
			});

			commit("SET_COMMUNICATIONS", {
				communications: communications.data.data,
			});
			commit("SET_PAGINATION", {
				pagination: communications.data.meta.pagination,
			});
		} catch (err) {
			commit("RESET_PAGINATION");
			alert(
				"Unable to get list of communications. Please refresh the page, and if the problem persists, contact the dev team.",
			);
		}
	},
	async setCommunicationCurrentPageList({ commit, dispatch }, payload) {
		commit("SET_CURRENT_PAGE", {
			currentPage: payload.newPage,
		});

		try {
			return dispatch("setCommunications");
		} catch (error) {
			return Promise.reject(error.response);
		}
	},

	// communication create
	async getSchools({ commit }, payload = {}) {
		try {
			const response = await SchoolsAPI.list(payload);
			commit("SET_SCHOOLS", {
				schools: response.data.data,
			});
			return Promise.resolve(response);
		} catch (err) {
			return Promise.reject(err);
		}
	},
	async getDistricts({ commit }) {
		try {
			const response = await DistrictsAPI.list();
			commit("SET_DISTRICTS", {
				districts: response.data.data,
			});
			return Promise.resolve(response);
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async getUsers({ commit, state }, { page = 1 }) {
		try {
			const response = await UsersAPI.filter({
				district: state.selectedDistrictIds,
				school: state.selectedSchoolIds,
				search: state.searchText,
				role: state.selectedAudienceRoleIds,
				include: "schools",
				page,
			});
			commit("SET_FILTERED_USERS", {
				filteredUsers: response.data.data,
			});
			commit("SET_PAGINATION", {
				pagination: response.data.meta.pagination,
			});
			return Promise.resolve(response);
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async getAudienceRoles({ commit }) {
		try {
			const response = await RolesAPI.list();
			const validRoleNames = [
				"student",
				"teacher",
				"administrator",
				"district-manager",
				"tutor",
			];
			commit("SET_AUDIENCE_ROLES", {
				audienceRoles: response.data.data.filter((role) =>
					validRoleNames.includes(role.name),
				),
			});
			return Promise.resolve(response);
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async getInferredAudienceCount({ commit }, payload) {
		try {
			const response = await CommunicationsAPI.audienceCount(payload);
			commit("SET_INFERRED_AUDIENCE_COUNT", {
				inferredAudienceCount: response.data,
			});
			return Promise.resolve(response);
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async saveCommunication({}, payload) {
		try {
			const response = await CommunicationsAPI.create(payload);
			return Promise.resolve(response);
		} catch (e) {
			return Promise.reject(e);
		}
	},

	// communication id
	async getCommunication({ commit, state }, payload = { include: "" }) {
		try {
			let includeParam = "superuser.user,notification,schools,districts";
			if (payload.include !== "") {
				includeParam += `,${payload.include}`;
			}
			const response = await CommunicationsAPI.show(state.communicationId, {
				include: includeParam,
			});
			response.data.data.content = unescape(response.data.data.content);
			commit("SET_INFERRED_AUDIENCE_COUNT", {
				inferredAudienceCount: response.data.data.audience_count,
			});
			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	async sendCommunication(_, params) {
		try {
			const response = await CommunicationsAPI.send(params);
			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	async updateCommunication({ state }, params) {
		try {
			const response = await CommunicationsAPI.update(
				state.communicationId,
				params,
			);
			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject(error);
		}
	},

	// users list
	async setUsers({ commit }, payload) {
		try {
			commit("SET_LOADING", {
				loading: true,
			});
			commit({
				type: "SET_IS_SHOWING_NO_FILTER_BANNER",
				isShowingNoFilterBanner: false,
			});
			commit("SET_FILTERS", {
				searchText: payload.search,
				districtFilter: payload.district,
				schoolFilter: payload.school,
				roleFilter: payload.role,
				activeFilter: payload.active,
			});

			commit("SET_ORDER_BY", {
				orderBy: payload.orderBy,
			});

			const users = await UsersAPI.filter(payload);

			const transformedUsers = [];
			users.data.data.forEach(function(user) {
				transformedUsers.push(ListTransformer.transformUser(user));
			});

			commit("SET_USERS", {
				users: transformedUsers,
			});

			commit("SET_PAGINATION", {
				pagination: users.data.meta.pagination,
			});

			return Promise.resolve();
		} catch (error) {
			commit("SET_USERS", {
				users: [],
			});
			commit("RESET_PAGINATION");
			return Promise.reject(error.response);
		} finally {
			commit("SET_LOADING", {
				loading: false,
			});
		}
	},
	async getRoles({ commit }) {
		try {
			const roles = await RolesAPI.list();
			// Changing the name to reflect
			// the hard coded string in back, ugh
			for (let i = 0; i < roles.data.data.length; i++) {
				if (roles.data.data[i].name == "administrator") {
					roles.data.data[i].name = "admin";
				}
			}
			commit("SET_ROLES", {
				roles: roles.data.data,
			});
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response);
		}
	},
	async setOrderBy({ state, commit }, payload) {
		try {
			commit("SET_LOADING", {
				loading: true,
			});

			commit("SET_ORDER_BY", {
				orderBy: payload,
			});

			commit("SET_CURRENT_PAGE", {
				currentPage: 1,
			});

			const params = {
				include: "schools.district,roles",
				district: state.districtFilter,
				school: state.schoolFilter,
				role: state.roleFilter,
				orderBy: payload,
				search: state.searchText,
				active: state.activeFilter,
			};
			const users = await UsersAPI.filter(params);
			const transformedUsers = [];
			users.data.data.forEach(function(user) {
				transformedUsers.push(ListTransformer.transformUser(user));
			});

			commit("SET_LOADING", {
				loading: false,
			});

			commit("SET_USERS", {
				users: transformedUsers,
			});
			return Promise.resolve(users);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	async setCurrentPageUserList({ state, commit }, page) {
		commit("SET_LOADING", {
			loading: true,
		});

		commit("SET_CURRENT_PAGE", {
			currentPage: page,
		});

		const params = {
			include: "schools.district,roles",
			district: state.districtFilter,
			school: state.schoolFilter,
			role: state.roleFilter,
			orderBy: state.orderBy,
			search: state.searchText,
			page,
			active: state.activeFilter,
		};

		const users = await UsersAPI.filter(params);

		const transformedUsers = [];
		users.data.data.forEach(function(user) {
			transformedUsers.push(ListTransformer.transformUser(user));
		});

		commit("SET_LOADING", {
			loading: false,
		});

		commit("SET_USERS", {
			users: transformedUsers,
		});
	},

	// user list
	async createUser({ commit }, payload) {
		try {
			const response = await UsersAPI.create(payload);

			return response.data.data.id;
		} catch (error) {
			let message = "";
			if (error.response.status === 422) {
				const errorObject = error.response.data.error_description;
				message = Object.values(errorObject)[0][0];
			} else {
				message =
          error.response.data.toString() +
          ": Something went wrong while creating this user.";
			}

			commit("SET_ERRORS", {
				errorMessage: message,
			});

			commit("SET_LOADING", {
				loading: false,
			});

			// Re-throw the error here to let the component catch the error then display a message and then prevent navigation
			throw error;
		}
	},
	async setDistricts({ commit }, payload) {
		try {
			const districts = await DistrictsAPI.list(payload);
			districts.data.data.unshift({
				id: 0,
				name: "No District",
			});
			commit("SET_LOADING", {
				loading: false,
			});

			commit("SET_DISTRICTS", {
				districts: districts.data.data,
			});
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response);
		}
	},
	async setRoles({ commit }) {
		try {
			const roles = await RolesAPI.list();
			// Changing the name to reflect
			// the hard coded string in back
			for (let i = 0; i < roles.data.data.length; i++) {
				if (roles.data.data[i].name == "administrator") {
					roles.data.data[i].name = "admin";
				}
			}
			commit("SET_ROLES", {
				roles: roles.data.data,
			});
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response);
		}
	},
	async setSchools({ commit }, payload) {
		try {
			const schools = await SchoolsAPI.list(payload);
			commit("SET_LOADING", {
				loading: false,
			});
			commit("SET_SCHOOLS", {
				schools: schools.data.data,
			});
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response);
		}
	},
	async setGrades({ commit }) {
		try {
			const grades = await GradesAPI.list();
			commit("SET_GRADES", {
				grades: grades.data.data,
			});
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error);
		}
	},
};

export default {
	namespaced: true,
	state: getDefaultState(),
	getters,
	mutations,
	actions,
};
