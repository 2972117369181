module.exports = new Promise((resolve, reject) => {
		window["_paperco_mfe_resolver_student-dashboard"] = { resolve, reject };
		const script = document.createElement("script");
		script.type = "module";
		script.async = true;
		script.innerHTML = `
			import("https://student-dashboard-web.paperstg.co/assets/remoteEntry.mjs")
				.then(remoteEntry => window["_paperco_mfe_resolver_student-dashboard"].resolve(remoteEntry))
				.catch(() => window["_paperco_mfe_resolver_student-dashboard"].reject("Failed to import _paperco_mfe_resolver_student-dashboard"));
		`
		document.head.appendChild(script);
	});