import { getFlag } from "@paper-co/web-toolkit";

import { MISSION_TYPE_IDS } from "@/modules/Math/utils/missions.js";

import MissionsAPI from "../service/missions.js";
import AnalyticsAPI from "../service/analytics.js";
import userEventPayload from "../utils/userEventPayload.js";

const getDefaultState = () => ({
	activeMission: null,
	allMissions: null,
	missionCompleted: false,
	missionsLoading: false,
	isTakeoverVisible: false,

	// MCQ
	selectedAnswerIndex: null,
	isAnswerSubmitted: false,
});

const state = getDefaultState();

export const getters = {
	question(state) {
		return [MISSION_TYPE_IDS.MULTIPLE_CHOICE, MISSION_TYPE_IDS.TRUE_FALSE]
			.includes(state.activeMission?.mission_type_id)
			? JSON.parse(state.activeMission.json_configure)
			: null;
	},
	isAnswerCorrect(state, getters) {
		return getters.question?.options[state.selectedAnswerIndex]?.isCorrect;
	},
};

export const mutations = {
	SET_ALL_MISSIONS(state, payload) {
		state.allMissions = payload;
	},
	CLEAR_ALL_MISSIONS(state) {
		state.allMissions = null;
	},
	SET_MISSIONS_LOADING(state, loading) {
		state.missionsLoading = loading;
	},
	SET_ACTIVE_MISSION(state, payload) {
		state.activeMission = payload;
	},
	APPEND_ACTIVE_MISSION_EVENT_ID(state, payload) {
		if (state.activeMission) {
			state.activeMission.tracking_id = payload;
		}
	},
	CLEAR_ACTIVE_MISSION(state) {
		state.activeMission = null;
	},
	SET_MISSION_COMPLETED(state) {
		state.missionCompleted = true;
	},
	CLEAR_MISSION_COMPLETED(state) {
		state.missionCompleted = false;
	},
	SET_TAKEOVER_VISIBILITY(state, visible) {
		state.isTakeoverVisible = visible;
	},
	SET_SELECTED_ANSWER_INDEX(state, index) {
		state.selectedAnswerIndex = index;
	},
	SET_IS_ANSWER_SUBMITTED(state, submitted) {
		state.isAnswerSubmitted = submitted;
	},
	CLEAR_MISSION_CONTEXT(state) {
		state.activeMission = null;
		state.missionCompleted = false;
		state.selectedAnswerIndex = null;
		state.isAnswerSubmitted = false;
	},
};

export const actions = {
	async setMissions({ commit, rootState }) {
		try {
			const isSlice3Enabled = await getFlag("PE-17104-Missions-S3");
			commit("SET_MISSIONS_LOADING", true);
			const { activeSubject: { id: subjectID }, activeTopic: { id: topicID } } = rootState.Math.Topics;
			const { data } = await MissionsAPI.getAllMissionsByTopic(
				topicID,
				subjectID,
				isSlice3Enabled ? undefined : 1,
			);
			commit("SET_ALL_MISSIONS", data);
		} catch (e) {
			Sentry.captureException(e);
		} finally {
			commit("SET_MISSIONS_LOADING", false);
		}
	},
	async missionAttempted({ commit, state, rootState }) {
		try {
			const userPayload = userEventPayload(rootState);
			const { data: { problem_start_id: tracking_id } } = await AnalyticsAPI
				.studentStartedProblem(state.activeMission.id, userPayload);

			commit("APPEND_ACTIVE_MISSION_EVENT_ID", tracking_id);
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	async setMissionCompleted({ state, rootState }) {
		const userPayload = { ...userEventPayload(rootState), problem_start_id: state.activeMission.tracking_id };
		await AnalyticsAPI.studentCompletedProblem(state.activeMission.id, userPayload);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
