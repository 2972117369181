var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.getLanguageIcon(_vm.language), {
    tag: "component",
    attrs: {
      "icon-color": _vm.iconColor,
      width: _vm.width,
      height: _vm.height,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }