<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		:width="width"
		:height="height"
		viewBox="0 0 26 26"
	>
		<defs>
			<path
				:id="`a${randomId}`"
				d="M0 12c0 6.628 5.374 12 12 12 6.628 0 12-5.372 12-12S18.628 0 12 0C5.374 0 0 5.372 0 12z"
			/>
			<path
				:id="`c${randomId}`"
				d="M.714 3.244a2.108 2.108 0 1 0 4.216 0 2.108 2.108 0 0 0-4.216 0z"
			/>
			<path
				:id="`e${randomId}`"
				d="M.746 3.244a2.108 2.108 0 1 0 4.216 0 2.108 2.108 0 0 0-4.216 0z"
			/>
		</defs>
		<g
			fill="none"
			fill-rule="evenodd"
		>
			<path
				fill="#ED7B7D"
				d="M13 25c6.626 0 12-5.372 12-12S19.626 1 13 1 1 6.372 1 13s5.374 12 12 12"
			/>
			<g transform="translate(1 1)">
				<mask
					:id="`b${randomId}`"
					fill="#fff"
				>
					<use :xlink:href="`#a${randomId}`" />
				</mask>
				<path
					fill="#ED7B7D"
					d="M-1.2 25.2h26.4V-1.2H-1.2z"
					:mask="`url(#b${randomId})`"
				/>
				<path
					fill="#ED7B7D"
					d="M-6 30h36V-6H-6z"
					:mask="`url(#b${randomId})`"
				/>
			</g>
			<path
				stroke="#D36063"
				d="M13 25c6.626 0 12-5.372 12-12S19.626 1 13 1 1 6.372 1 13s5.374 12 12 12z"
			/>
			<g transform="translate(5.8 8.2)">
				<mask
					:id="`d${randomId}`"
					fill="#fff"
				>
					<use :xlink:href="`#c${randomId}`" />
				</mask>
				<path
					fill="#3C5396"
					d="M-5.286 11.35h16.217V-4.865H-5.286z"
					:mask="`url(#d${randomId})`"
				/>
			</g>
			<g transform="translate(14.2 8.2)">
				<mask
					:id="`f${randomId}`"
					fill="#fff"
				>
					<use :xlink:href="`#e${randomId}`" />
				</mask>
				<path
					fill="#3C5396"
					d="M-5.254 11.35h16.216V-4.865H-5.254z"
					:mask="`url(#f${randomId})`"
				/>
			</g>
			<path
				stroke="#3C5396"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="1.5"
				d="M17.77 20.085s-1.425-2.642-4.818-2.642c-3.394 0-4.816 2.642-4.816 2.642"
			/>
			<g stroke="#3C5396">
				<path d="M15.85 10.65s1.375-2.35 5.125-2.05M9.85 10.65S8.475 8.3 4.725 8.6" />
			</g>
		</g>
	</svg>
</template>

<script>
import IconId from "./IconId.js";
export default {
	extends: IconId,
	props: {
		width: {
			type: [Number, String],
			default: 26,
		},
		height: {
			type: [Number, String],
			default: 26,
		},
	},
};
</script>
