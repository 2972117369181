var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    {
      staticClass: "tw-flex-grow tw-items-center tw-ml-8 tw-text-origami-white",
      attrs: { id: "navbar-user-links" },
    },
    _vm._l(_vm.userLinks, function (link, index) {
      return _c(
        "li",
        { key: `link-${index}`, staticClass: "tw-px-6 link-item tw-relative" },
        [
          link.notificationCount
            ? _c("NotificationBubble", {
                attrs: { "notification-count": link.notificationCount },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "BaseLink",
            {
              directives: [
                {
                  name: "data-cy",
                  rawName: "v-data-cy",
                  value: `navbar-tab-${link.title}`,
                  expression: "`navbar-tab-${link.title}`",
                },
              ],
              key: link.target,
              ref: `navbar-user-link-${index}`,
              refInFor: true,
              staticClass:
                "tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-max",
              attrs: { to: link.target, target: link.title === "navbar.pave" },
              nativeOn: {
                keydown: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "right", 39, $event.key, [
                        "Right",
                        "ArrowRight",
                      ])
                    )
                      return null
                    if ("button" in $event && $event.button !== 2) return null
                    return _vm.focusListItem(index + 1)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "left", 37, $event.key, [
                        "Left",
                        "ArrowLeft",
                      ])
                    )
                      return null
                    if ("button" in $event && $event.button !== 0) return null
                    return _vm.focusListItem(index - 1)
                  },
                ],
              },
            },
            [
              _c(link.icon, { tag: "component" }),
              _vm._v(
                "\n\t\t\t" +
                  _vm._s(
                    link.title !== "navbar.pave" ? _vm.$t(link.title) : null
                  ) +
                  "\n\t\t\t"
              ),
              _c("div", { staticClass: "hover-bar" }),
            ],
            1
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }