var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "OrigamiCard",
    {
      staticClass:
        "tw-flex tw-p-2 tw-items-center tw-bg-origami-purple-100 tw-rounded-lg tw-gap-4",
      attrs: { tag: _vm.tag },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "tw-w-10 tw-h-10 tw-rounded-full tw-bg-origami-white tw-flex tw-items-center tw-justify-center",
        },
        [
          _c("Emoji", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "recognition-emoji",
                expression: "'recognition-emoji'",
              },
            ],
            staticClass: "tw-text-xl",
            attrs: {
              emoji:
                _vm.RECOGNITION_ICON_COMPONENTS[_vm.id] ||
                _vm.RECOGNITION_ICON_COMPONENTS[1],
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "recognition-name",
              expression: "'recognition-name'",
            },
          ],
          staticClass: "tw-font-bold",
        },
        [
          _vm._v(
            "\n\t\t" +
              _vm._s(_vm.upperFirst(_vm.$t(`recognition.types.${_vm.name}`))) +
              "\n\t"
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }