var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "tableContainer--translucent":
          _vm.PlatformManager.TutorsList.isLoadingTutors,
      },
    },
    [
      _c("TutorsListTable", {
        attrs: {
          headers: _vm.PlatformManager.TutorsList.tableHeaders,
          items: _vm.PlatformManager.TutorsList.tutors,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ item }) {
              return [
                _vm._l(
                  _vm.PlatformManager.TutorsList.tableHeaders,
                  function (header) {
                    return [
                      header.value === "impersonation"
                        ? _c(
                            "td",
                            {
                              key: header.value + item.id,
                              staticClass: "table__impersonationCell",
                              on: {
                                click: function ($event) {
                                  return _vm.showImpersonationModal(item.email)
                                },
                              },
                            },
                            [
                              _c("IconImpersonationEye", {
                                staticClass: "impersonationIcon",
                                attrs: { "icon-color": "#1c3075" },
                              }),
                            ],
                            1
                          )
                        : header.value === "tutor_since"
                        ? _c(
                            "td",
                            { key: header.value + item.id },
                            [
                              _c(
                                "BaseLink",
                                {
                                  attrs: {
                                    to: `/${_vm.currentUser.role}/services/tutors/edit/${item.id}`,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(_vm.formatDate(item.tutor_since)) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : header.value === "status"
                        ? _c(
                            "td",
                            { key: header.value + item.id },
                            [
                              _c(
                                "BaseLink",
                                {
                                  directives: [
                                    {
                                      name: "data-cy",
                                      rawName: "v-data-cy",
                                      value: "tutor-edit-button",
                                      expression: "'tutor-edit-button'",
                                    },
                                  ],
                                  attrs: {
                                    to: `/${_vm.currentUser.role}/services/tutors/edit/${item.id}`,
                                  },
                                },
                                [
                                  _c("div", {
                                    staticClass: "table__statusDot",
                                    class: _vm.computeStatusDotColor(
                                      item.status
                                    ),
                                  }),
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.computeStatusText(item.status)
                                      ) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : header.value === "is_new"
                        ? _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "jest",
                                  rawName: "v-jest",
                                  value: "new-tutor-cell",
                                  expression: "'new-tutor-cell'",
                                },
                              ],
                              key: header.value + item.id,
                            },
                            [
                              _c(
                                "BaseLink",
                                {
                                  attrs: {
                                    to: `/${_vm.currentUser.role}/services/tutors/edit/${item.id}`,
                                  },
                                },
                                [
                                  item.is_new
                                    ? _c("IconCheckMark", {
                                        directives: [
                                          {
                                            name: "jest",
                                            rawName: "v-jest",
                                            value: "new-tutor-icon",
                                            expression: "'new-tutor-icon'",
                                          },
                                        ],
                                        staticClass:
                                          "tw-w-5 tw-h-3 tw-ml-1 tw-text-black-high",
                                        attrs: { "icon-color": "currentColor" },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "td",
                            { key: header.value },
                            [
                              _c(
                                "BaseLink",
                                {
                                  attrs: {
                                    to: `/${_vm.currentUser.role}/services/tutors/edit/${item.id}`,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(item[header.value]) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                    ]
                  }
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("ImpersonationModal", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isImpersonationModalVisible,
            expression: "isImpersonationModalVisible",
          },
        ],
        attrs: {
          "user-email": _vm.impersonationEmail,
          token: _vm.impersonationToken,
        },
        on: {
          "close-modal": function ($event) {
            return _vm.closeImpersonationModal()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }