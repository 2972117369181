<template>
	<div>
		<span
			v-jest="'title'"
			class="commentBox_title"
		>
			{{ featureFlagUpdateHandoffFlowIsEnabled ? "Leave a comment (required):" : "Leave a comment (optional):" }}
		</span>
		<div>
			<textarea
				v-jest="'handoff-comment'"
				placeholder="Add comment"
				:value="value"
				class="handoffModal__commentBox tw-p-2"
				:class="{ '!tw-border-origami-red-400': featureFlagUpdateHandoffFlowIsEnabled && isNoCommentError }"
				@input="handleInput"
			/>
		</div>
		<div
			v-if="featureFlagUpdateHandoffFlowIsEnabled && isNoCommentError"
			v-jest="'no-comment-error'"
			class="tw-flex tw-mb-2"
		>
			<OrigamiIcon
				name="warning"
				class="tw-mr-2 tw-text-origami-red-400"
			/>
			<p class="tw-text-base tw-text-origami-red-400">
				Please provide additional details for the next tutor. This is required.
			</p>
		</div>
		<div
			class="handOff__attachment__items"
			@click="redirectClickToInput"
		>
			<span class="handOff__attachment__icon">
				<IconAttachment class="tw-text-blue-regular" />
			</span>
			Select a file to attach
		</div>
		<br>
		<input
			id="fileInput"
			ref="fileInput"
			type="file"
			accept=".pdf, .png, .jpeg, .jpg, .doc, .docx, .xlsx, .zip"
			class="attachmentFile__input"
			@change="handleNewFile"
		>
		<ul class="attachmentFile__list">
			<li
				v-for="(file, index) in files"
				:key="index"
				:file="file"
				class="attachmentFile"
			>
				{{ file.name }}
				<span
					class="attachmentFile__removalLink"
					@click="removeFile(file)"
				>
					Remove file
				</span>
			</li>
		</ul>
	</div>
</template>

<script>
import { OrigamiIcon } from "@origami/vue2";

import IconAttachment from "./icons/IconAttachment.vue";

export default {
	components: {
		IconAttachment,
		OrigamiIcon,
	},
	props: {
		value: {
			type: [String, Number],
			default: "",
		},
		files: {
			type: [Array],
			default: (() => []),
		},
		isNoCommentError: {
			type: Boolean,
			required: false,
		},
	},
	emits: ["input", "on-file-change", "on-file-removal"],
	data() {
		return {
			featureFlagUpdateHandoffFlowIsEnabled: false,
		};
	},
	async created() {
		this.featureFlagUpdateHandoffFlowIsEnabled = await this.$getFlag("PE-20102-update-handoff-flow");
	},
	methods: {
		handleInput(e) {
			this.$emit("input", e.target.value);
		},
		handleNewFile(e) {
			try {
				e.preventDefault();
				const type = e.type === "drop" ? "dataTransfer" : "target";
				this.checkFile(e[type].files[0]);
				this.$emit("on-file-change", e[type].files[0]);
			} catch (error) {
				this.handleFileUploadError();
			}
		},
		maxFileSize(file) {
			return file.size / 1000000 < 10;
		},
		redirectClickToInput(e) {
			this.$refs.fileInput.click(e);
		},
		checkFile(file) {
			if (
				this.maxFileSize(file)
			) {
				return true;
			} else {
				throw new Error("file validation");
			}
		},
		handleFileUploadError() {
			this.$store.dispatch("SimpleModal/configureSimpleModal", {
				buttonText: "Okay",
				title: "Error",
				message:
					"It seems there is an error with your file upload, please make sure it is less than 10MB and is of type: PDF, DOCX, DOC, JPG, PNG, XLSX, ZIP",
				buttonType: "PRIMARY",
			});
			this.$bvModal.show("simpleModal");
		},
		removeFile(file) {
			this.$emit("on-file-removal", this.files.indexOf(file));
		},
	},
};
</script>

<style scoped>
.commentBox_title {
	font-size: 12px;
	font-weight: bold;
	margin-bottom: 0.5rem;
}
.handoffModal__commentBox {
	width: 100%;
	height: 64px;
	border: solid 2px var(--c-gray);
	box-sizing: border-box;
	border-radius: 5px;
	resize: none;
}
.attachmentFile__input {
	display: none;
}
.handOff__attachment__items {
	font-size: 14px;
	font-weight: bold;
	color: var(--c-blue);
}
.handOff__attachment__items:hover {
	text-decoration: underline;
	cursor: pointer;
}
.handOff__attachment__icon {
	padding-top: 3px;
}
.attachmentFile {
	display: flex;
	flex-direction: row;
}
.attachmentFile__removalLink {
	margin-left: auto;
	margin-right: 0.5rem;
	font-size: 0.75rem;
	font-weight: bold;
	color: var(--c-orange);
	cursor: pointer;
	padding-top: 3px;
}
.attachmentFile__removalLink:hover {
	text-decoration: underline;
}
.attachmentFile__list {
	padding: 0;
}
.tw-text-blue-regular {
	color: var(--blue-regular);
}
</style>
