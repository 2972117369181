<template>
	<transition name="lightbox">
		<div
			id="lightboxContainer"
			ref="lightboxContainer"
			:class="isPopup ? 'lightbox__embedded' : 'lightbox'"
			tabindex="0"
			role="dialog"
			:aria-label="$t('preview')"
			@click.stop="clickOut"
		>
			<img
				id="lightboxImage"
				ref="lightboxImage"
				:src="currentLink"
				class="lightbox__img--default"
				:style="transformationStyle"
			>
			<div
				v-show="isArrowButtonsShowable"
				class="lightbox__arrow left"
				@click="previous"
			>
				<IconLeft />
			</div>
			<div
				v-show="isArrowButtonsShowable"
				class="lightbox__arrow right"
				@click="next"
			>
				<IconLeft />
			</div>
			<div class="toolbar">
				<IconRotate
					class="toolbar__icon"
					tabindex="0"
					@click.prevent.native="rotateImg"
				/>
				<IconZoomOut
					class="toolbar__icon"
					tabindex="0"
					@click.prevent.native="zoomOutImg"
				/>
				<IconZoomIn
					class="toolbar__icon"
					tabindex="0"
					@click.prevent.native="zoomInImg"
				/>
				<IconDownload
					class="toolbar__icon"
					tabindex="0"
					@click.prevent.native="downloadImg"
				/>
				<BaseButton
					v-show="isDrawButtonVisible"
					id="lightbox__toolbarDrawButton"
					v-jest="'draw'"
					v-data-cy="'lightbox-draw-button'"
					type="CANCEL"
					class="toolbar__button"
					tabindex="0"
					@click.prevent.native="draw"
				>
					<template slot="text">
						{{ $t("draw") }}
					</template>
				</BaseButton>
				<BaseButton
					v-data-cy="'lightbox-close-button'"
					type="CANCEL"
					class="toolbar__button"
					tabindex="0"
					@click.prevent.native="close"
				>
					<template slot="text">
						{{ $t("close") }}
					</template>
				</BaseButton>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapState } from "vuex";
import { isNil } from "lodash";

import { downloadLinkAsFile } from "@/utilities/links.js";
import BaseButton from "@/components/BaseButton.vue";
import IconLeft from "@/components/icons/IconChevronLeft.vue";
import IconRotate from "@/components/icons/IconRotate.vue";
import IconDownload from "@/components/icons/IconDownload.vue";
import IconZoomIn from "@/components/icons/IconZoomIn.vue";
import IconZoomOut from "@/components/icons/IconZoomOut.vue";

export default {
	components: {
		BaseButton,
		IconLeft,
		IconRotate,
		IconDownload,
		IconZoomIn,
		IconZoomOut,
	},
	props: {
		isPopup: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			/**
			 * Object to map zoom levels to CSS scale transform
			 */
			zoomScale: {
				0: 1,
				1: 1.2,
				2: 1.5,
				3: 2,
				4: 3,
			},
			topMargin: 0,
			leftMargin: 0,
		};
	},
	computed: {
		...mapState(["Lightbox", "Classroom"]),
		/**
		 * the url for the currently displayed image
		 *
		 * @returns {String}
		 */
		currentLink() {
			if (this.Lightbox.array.length) {
				return this.Lightbox.array[this.Lightbox.index].link;
			}
			return "//:0";
		},

		/**
		 * The current image's zoom level (or scale, once mapped)
		 *
		 * @returns {Number}
		 */
		currentZoom() {
			if (this.Lightbox.array.length) {
				return this.zoomScale[this.Lightbox.array[this.Lightbox.index].zoom];
			}
			return 1;
		},

		/**
		 * Computed string to combine scale and rotate transform on the image.
		 * Separate when they are their own transforms in the next CSS version.
		 *
		 * @returns {String} (valid css properties)
		 */
		transformationStyle() {
			if (this.Lightbox.array.length) {
				return `-webkit-transform: rotate(${this.$store.getters["Lightbox/currentRotation"]}deg) scale(${
					this.currentZoom
				}); 
				transform: rotate(${this.$store.getters["Lightbox/currentRotation"]}deg) scale(${this.currentZoom});
				margin-top:${this.topMargin}px;
				margin-left:${this.leftMargin}px;`;
			}
			return "";
		},

		/**
		 * @returns {Boolean}
		 */
		isSidebarCollapsed() {
			return this.Classroom.isCollapsed;
		},

		/**
		 * @returns {Boolean}
		 */
		isDrawButtonVisible() {
			return !isNil(this.Classroom.sessions[this.Classroom.currentSessionId]);
		},

		/**
		 * @returns {Boolean}
		 */
		isArrowButtonsShowable() {
			return this.Lightbox.array.length > 1;
		},

		/**
		 * @returns {Number|Null}
		 */
		currentSessionId() {
			return this.Classroom.currentSessionId;
		},
	},
	watch: {
		currentSessionId() {
			this.$store.commit("Lightbox/SET_LIGHTBOX_INDEX", {
				index: 0,
			});
		},
		currentZoom() {
			this.$nextTick(() => {
				this.makeTopMargin();
				this.makeLeftMargin();
			});
		},
		"Lightbox.isShowing"(val) {
			if (val) {
				this.$refs.lightboxContainer.focus();
			}
		},
	},

	mounted() {
		this.$nextTick(function() {
			window.addEventListener("keyup", this.shortcutHandler);
			this.$refs.lightboxContainer.focus();
		});
	},
	destroyed() {
		window.removeEventListener("keyup", this.shortcutHandler);
	},
	methods: {
		async downloadImg() {
			const imageFile = await fetch(this.currentLink);
			const imageBlob = await imageFile.blob();
			const imageDataString = await URL.createObjectURL(imageBlob);
			const url = new URL(this.currentLink).pathname; // https://example.tld/path/to/file.ext?query=string#hash -> /path/to/file.ext
			const filename = url.substring(url.lastIndexOf("/") + 1); // /path/to/file.ext -> file.ext
			downloadLinkAsFile(imageDataString, filename);
		},
		/**
		 * Handle the event to determine if the click is outside the image
		 * and if it's not on anything, close the lightbox.
		 * @returns {Void}
		 */
		clickOut(e) {
			if (e.target.id === "lightboxContainer" && !this.isPopup) {
				this.close();
			}
		},
		/**
		 * Since will only be called when the lightbox is showing,
		 * not wrapped in an if to toggle the showing state
		 * @returns {Void}
		 */
		close() {
			if (this.isPopup) {
				window.close();
			}
			this.$store.commit("Lightbox/TOGGLE_LIGHTBOX_SHOWING");
		},

		/**
		 * Calls a method to open the whiteboard with the current image
		 * loaded as the background to draw on.
		 * @returns {Void}
		 */
		draw() {
			this.$store.commit("Classroom/CLEAR_WHITEBOARD_IN_SESSION");
			this.$store.commit("Classroom/SET_WHITEBOARD_BACKGROUND_IMAGE_IN_SESSION", {
				link: this.currentLink,
			});
			if (this.isPopup) {
				this.$store.commit("Classroom/SET_LINK_FOR_POPUP_WHITEBOARD_TO_LOAD", {
					linkForPopupWhiteboardToLoad: this.currentLink,
				});
				this.$router.push("/whiteboard");
			} else {
				this.$store.commit("Lightbox/TOGGLE_LIGHTBOX_SHOWING");
				this.$store.dispatch("Classroom/openWhiteboard");
			}
		},

		/**
		 * Display the next image in the array
		 * @returns {Void}
		 */
		next() {
			this.$store.commit("Lightbox/SET_LIGHTBOX_INDEX", {
				index: (this.Lightbox.index + 1) % this.Lightbox.array.length,
			});
		},

		/**
		 * Display the previous image in the array
		 * @returns {Void}
		 */
		previous() {
			// (x % n + n) % n because of the way JS handles negative modulo
			// x is the index -1
			// n is the length
			this.$store.commit("Lightbox/SET_LIGHTBOX_INDEX", {
				index:
					(((this.Lightbox.index - 1) % this.Lightbox.array.length) +
						this.Lightbox.array.length) %
					this.Lightbox.array.length,
			});
		},

		/**
		 * Rotate the image by a right angle clockwise
		 * @returns {Void}
		 */
		rotateImg() {
			this.$store.commit("Lightbox/INCREMENT_CURRENT_ROTATION", {
				value: 90,
			});
		},
		/**
		 * Method callback for keypresses to
		 * check and execute shortcuts
		 * @returns {Void}
		 */
		shortcutHandler(event) {
			if (this.Lightbox.isShowing) {
				if (event.key === "ArrowLeft") {
					this.previous();
				}
				if (event.key === "ArrowRight") {
					this.next();
				}
				if (event.key === "D") {
					this.draw();
				}
			}
		},

		/**
		 * Decrement the zoom/scale level
		 * till min value
		 * @returns {Void}
		 */
		zoomOutImg() {
			if (this.Lightbox.array[this.Lightbox.index].zoom > 0) {
				this.$store.commit("Lightbox/SET_CURRENT_ZOOM", {
					zoom: this.Lightbox.array[this.Lightbox.index].zoom - 1,
				});
			}
		},

		/**
		 * Increment the zoom/scale level,
		 * till max value
		 * @returns {Void}
		 */
		zoomInImg() {
			if (this.Lightbox.array[this.Lightbox.index].zoom < 4) {
				this.$store.commit("Lightbox/SET_CURRENT_ZOOM", {
					zoom: this.Lightbox.array[this.Lightbox.index].zoom + 1,
				});
			}
		},
		/**
		 * Top margin to get the full image
		 *
		 * @returns {Number}
		 */
		makeTopMargin() {
			const container = this.$refs.lightboxContainer;
			const image = this.$refs.lightboxImage;
			const targetHeight = Math.ceil(image.clientHeight * this.currentZoom);
			if (container.clientHeight < targetHeight) {
				this.topMargin = targetHeight - container.clientHeight;
			} else {
				this.topMargin = 0;
			}
		},
		/**
		 * Left margin to get the full image
		 *
		 * @returns {Number}
		 */
		makeLeftMargin() {
			const container = this.$refs.lightboxContainer;
			const image = this.$refs.lightboxImage;
			const targetWidth = Math.ceil(image.getBoundingClientRect().width);
			if (container.clientWidth < targetWidth) {
				this.leftMargin = targetWidth - container.clientWidth;
			} else {
				this.leftMargin = 0;
			}
		},
	},
};
</script>

<style scoped>
.lightbox {
	background-color: rgba(42, 69, 135, 0.8);
	transition: opacity 0.3s ease;
	z-index: 9998;
	@apply tw-fixed tw-top-0 tw-left-0 tw-w-screen tw-h-screen tw-flex tw-items-center tw-justify-center tw-overflow-scroll;
}

.lightbox__embedded {
	max-width: 100vw;
	height: 100%;
	@apply tw-bg-origami-white tw-max-h-screen tw-min-h-full tw-w-full tw-min-w-full tw-fixed tw-flex tw-items-center tw-justify-center;
}

.toolbar {
	bottom: 8vh;
	@apply tw-mx-auto tw-my-0 tw-bg-origami-blue-200 tw-h-14 tw-w-80 tw-rounded-sm tw-p-3 tw-flex tw-fixed tw-flex-row tw-items-center tw-justify-around tw-left-0 tw-right-0;
}

.toolbar__button {
	height: 30px;
	border-radius: 3px;
	@apply tw-w-16;
}

.toolbar__icon {
	@apply tw-cursor-pointer;
}

.lightbox__img--default {
	max-width: 80%;
	transform-origin: center center;
	@apply tw-max-h-full tw-object-scale-down tw-block;
}

.lightbox__arrow {
	background-color: rgba(42, 69, 135, 0.5);
	margin: auto 2vw;
	width: 51px;
	height: 54px;
	@apply tw-cursor-pointer tw-rounded-sm tw-fixed tw-top-0 tw-bottom-0 tw-flex tw-flex-col tw-justify-around;
}

.right {
	transform: rotate(180deg);
	@apply tw-float-right tw-right-0;
}

.left {
	@apply tw-float-left tw-left-0;
}

.lightbox__arrow > svg {
	@apply tw-block tw-m-auto;
}
/*
* The following styles are auto-applied to elements with
* transition="lightbox" when their visibility is toggled
* by Vue.js.
*
* You can easily play with the lightbox transition by editing
* these styles.
*/

.lightbox-enter {
	@apply tw-opacity-0;
}

.lightbox-leave-active {
	@apply tw-opacity-0;
}

.lightbox-enter .lightbox-container,
.lightbox-leave-active .lightbox-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}
</style>
