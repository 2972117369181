<template>
	<div class="tw-flex tw-flex-col tw-content-center tw-items-center tw-py-32 tw-m-5 tw-bg-white-high tw-text-grey-dark">
		<slot
			v-if="hasIconSlot"
			name="icon"
		/>
		<Search
			v-else
			class="tw-w-11 tw-h-12"
		/>
		<span class="tw-text-center tw-w-1/3 tw-mt-6">
			<slot />
		</span>
	</div>
</template>
<script>
import { Search } from "@/components/elements/icons/index.js";

export default {
	name: "NoFilterBanner",
	components: {
		Search,
	},
	computed: {
		hasIconSlot() {
			return !!this.$slots.icon;
		},
	},
};
</script>
