import rootInstance from "@/services/root.js";

const educatorInsightsApi = () => {
	rootInstance.defaults.baseURL = process.env.MIX_EDUCATOR_INSIGHTS_API_BASE_URI + "/api/v1.0";
	return rootInstance;
};

export default {
	/**
	* Get all student that the current user can view
	* @param {object} query - request query
	* @param {object} params - query params
	* @returns {AxiosPromise} - returns a list of student
	*/
	getStudents: async(query, params) =>
		educatorInsightsApi().get(`/student-analytics/students/${query}`, { params })
			.then(({ data: { data: students, meta: { pagination } } }) =>
				({ students, pagination }),
			)
	,
	/**
	* Get filters data
	* @returns {AxiosPromise} - returns a list of student
	*/
	getFilters: () =>
		educatorInsightsApi().get(`/student-analytics/filters`)
			.then(({ data: { data } }) => data)
	,
};
