import api from "./Api.js";

export default {
	/**
	 * Sends welcome emails to school by audience
	 *
	 * @param {number} schoolId
	 * @param {string} audience
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	audienceWelcomeEmail(schoolId, audience, data) {
		return api().post(`schools/${schoolId}/${audience}/welcome-email`, data);
	},

	/**
	 * Sends welcome email to district managers
	 *
	 * @param {number} districtId
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	sendDistrictManagerWelcomeEmail(districtId, data) {
		return api().post(`districts/${districtId}/welcome-email`, data);
	},

	/**
	 * Sends welcome email to a specific user
	 *
	 * @param {number} userId
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	userWelcomeEmail(userId, data) {
		return api().post(`users/${userId}/welcome-email`, data);
	},

	/**
	 * Turns off user's subscription to communications
	 *
	 * @arg {number} emailHash
	 * @arg {object} params
	 * @returns {AxiosPromise}
	 */
	unsubscribeFromCommunicationEmails(emailHash, params) {
		return api().get(`users/${emailHash}/unsubscribe`, {
			params: params,
		});
	},
};
