import { default as coursesRoutes } from "./courses/courses.js";
import { default as districtsRoutes } from "./districts/districts.js";
import { default as schoolsRoutes } from "./schools/schools.js";
import { default as sectionsRoutes } from "./sections/sections.js";
import { default as teacherContentRoutes } from "./teacher-content/teacher-content.js";
import { default as usersRoutes } from "./users/users.js";

export default [
	{
		path: "customers",
		component: () => import("../components/Customers.vue"),
		children: [
			...coursesRoutes,
			...districtsRoutes,
			...schoolsRoutes,
			...sectionsRoutes,
			...teacherContentRoutes,
			...usersRoutes,
		],
	},
];
