<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		viewBox="0 0 14 14"
	>
		<path
			:fill="iconColor"
			fill-rule="evenodd"
			d="M7.7 4.9H6.3V3.5h1.4v1.4zm0 5.6H6.3V6.3h1.4v4.2zM7.007 14C10.871 14 14 10.864 14 7s-3.129-7-6.993-7C3.136 0 0 3.136 0 7s3.136 7 7.007 7zM12.6 7c0 3.094-2.506 5.6-5.6 5.6S1.4 10.094 1.4 7 3.906 1.4 7 1.4s5.6 2.506 5.6 5.6z"
			opacity=".75"
		/>
	</svg>
</template>

<script>
export default {
	props: {
		width: {
			type: String,
			default: "12px",
		},
		height: {
			type: String,
			default: "12px",
		},
		iconColor: {
			type: String,
			default: "#191919",
		},
	},
};
</script>
