<template>
	<BModal
		id="service-schedule-create-shift-modal"
		ref="service-schedule-create-shift-modal"
		:hide-header="true"
		:no-close-on-backdrop="true"
		:no-fade="true"
		:centered="true"
		size="sm"
		@keydown.esc.native="hideModal()"
	>
		<button
			v-data-cy="'btn-close-createShift'"
			type="button"
			class="close createShift__closeBtn"
			@click="hideModal()"
		>
			&times;
		</button>

		<div class="createShift__header">
			<div class="createShift__headerText">
				Create New Shift
			</div>
		</div>

		<div class="createShift__body">
			<ServiceScheduleShiftsCalendarCreate
				ref="shiftsCalendarCreate"
				class="createShift__calendar"
				:placeholder="'Select a time range'"
				:value="defaultShift"
			/>
		</div>

		<template slot="modal-footer">
			<div class="createShift__footer tw-w-full">
				<BaseButton
					v-data-cy="'save-shift-btn'"
					v-jest="'create-shift-btn'"
					class="createShift__btn"
					type="SUBMIT"
					:is-disabled="isSubmitButtonDisabled"
					@click.native="createShift()"
				>
					<template
						slot="text"
					>
						Save
					</template>
				</BaseButton>
				<BaseButton
					v-jest="'close-shift-btn'"
					v-data-cy="'btn-cancel-createShift'"
					class="createShift__btn"
					type="CANCEL"
					@click.native="hideModal()"
				>
					<template
						slot="text"
					>
						Close
					</template>
				</BaseButton>
			</div>
		</template>
	</BModal>
</template>
<script>
import { mapState } from "vuex";
import { isEmpty } from "lodash";
import { addHours, startOfHour, isAfter, isEqual } from "date-fns";

import BaseButton from "@/components/BaseButton.vue";

import shiftModalsMixin from "../../mixins/shiftModalsMixin.js";

import ServiceScheduleShiftsCalendarCreate from "./ServiceScheduleShiftsCalendarCreate.vue";

export default {
	components: {
		ServiceScheduleShiftsCalendarCreate,
		BaseButton,
	},
	mixins: [shiftModalsMixin],
	data() {
		return {
			tutorIndex: null,
			isEssayShift: false,
			isSubmitting: false,
			isCalendarShowing: false,
			defaultShift: {
				start: startOfHour(addHours(new Date(), 1)),
				end: startOfHour(addHours(new Date(), 4)),
			},
		};
	},
	computed: {
		...mapState(["PlatformManager"]),
		/**
		 * @return {Boolean}
		 */
		isTutorIdEntered() {
			return this.PlatformManager.ServiceTutorsSchedule.currentTutor.id !== null;
		},
		/**
		 * @return {String}
		 */
		isSubmitButtonDisabled() {
			const isInvalidDateRange = isEmpty(this.PlatformManager.ServiceTutorsSchedule.dateRange) ||
					isAfter(this.PlatformManager.ServiceTutorsSchedule.dateRange.start,
						this.PlatformManager.ServiceTutorsSchedule.dateRange.end) ||
					isEqual(this.PlatformManager.ServiceTutorsSchedule.dateRange.start,
						this.PlatformManager.ServiceTutorsSchedule.dateRange.end);

			return (
				this.isSubmitting ||
				(isEmpty(this.PlatformManager.ServiceTutorsSchedule.currentShiftType) ||
					isEmpty(this.PlatformManager.ServiceTutorsSchedule.currentTutor) ||
					isInvalidDateRange)
			);
		},
	},
	methods: {
		/**
		 * @param {Date} time
		 */
		getTimeStamp(time) {
			return Math.floor(time.getTime() / 1000);
		},
		/**
		 * @returns {Object}
		 */
		getShiftData() {
			return {
				include: "scheduleType",
				start_time: this.getTimeStamp(new Date(this.PlatformManager.ServiceTutorsSchedule.dateRange.start)),
				end_time: this.getTimeStamp(new Date(this.PlatformManager.ServiceTutorsSchedule.dateRange.end)),
				user_id: this.PlatformManager.ServiceTutorsSchedule.currentTutor.id,
				can_receive_sessions: this.PlatformManager.ServiceTutorsSchedule.currentShiftType.id === 2 ? 0 : 1,
				schedule_type_id: this.PlatformManager.ServiceTutorsSchedule.currentShiftType.id,
			};
		},
		/**
		 * @param {Number} tutorId
		 * @param {Object} shift
		 * @returns {Promise}
		 */
		async buildCurrentEvent(shift) {
			const response = await this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/getTutor", {
				id: this.PlatformManager.ServiceTutorsSchedule.currentTutor.id,
				data: { include: "tutor.languages,tutor.subjects" },
			});
			let newEvent = {};
			newEvent = shift;
			newEvent["user"] = { data: response.data };
			return newEvent;
		},
		/**
		 * @param {Object} shiftData
		 * @returns {Promise}
		 */
		async buildShift(shiftData) {
			const response = await this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/createShift", shiftData);
			const shift = response.data;
			const newShift = await this.buildCurrentEvent(shift);
			return newShift;
		},
		async createShift() {
			let snackbarType, snackbarText;
			try {
				this.isSubmitting = true;
				const data = this.getShiftData();
				const newShift = await this.buildShift(data);
				this.$store.commit("PlatformManager/ServiceTutorsSchedule/SET_SHIFT_IN_SHIFTS_ARRAY", {
					currentEvent: newShift,
				});
				this.$emit("shiftCreated", newShift);
				snackbarType = "success";
				snackbarText = "Shift is successfully created";
				this.hideModal();
			} catch (error) {
				snackbarType = "error";
				snackbarText = this.getErrorMessage(error);
			} finally {
				this.snackbarMessage(snackbarType, snackbarText);
				this.isSubmitting = false;
			}
		},
		/**
		 *
		 * @param {String} refName
		 */
		closeOnSelect(refName) {
			this.$refs[refName].showDropdown = false;
		},
		hideModal() {
			this.resetDropdowns();
			this.$refs.shiftsCalendarCreate.resetData(this.defaultShift);
			this.PlatformManager.ServiceTutorsSchedule.currentTutor.id = null;
			this.isEssayShift = false;
			this.$bvModal.hide("service-schedule-create-shift-modal");
		},
		resetDropdowns() {
			this.$store.commit("PlatformManager/ServiceTutorsSchedule/SET_CURRENT_TUTOR", {
				currentTutor: {},
			});
			this.$store.commit("PlatformManager/ServiceTutorsSchedule/SET_CURRENT_SHIFT_TYPE", {
				currentShiftType: {},
			});
			this.$store.commit("PlatformManager/ServiceTutorsSchedule/SET_DATE_RANGE", {
				dateRange: this.defaultShift,
			});
		},
	},
};
</script>
<style scoped>
.createShift__body {
	margin-top: 1.25rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.createShift__headerText {
	margin-top: 1.5rem;
	font-size: 1rem;
	font-weight: bold;
	color: var(--c-black);
}
.createShift__subHeader {
	font-size: 0.75rem;
	font-weight: normal;
	color: var(--c-black);
}
.createShift__inputLabel {
	font-size: 0.75rem;
	font-weight: 600;
	color: var(--c-black);
}
.createShift__input {
	width: 9.8rem;
	height: 1.9rem;
	margin-bottom: 55px;
}
.createShift__essayShiftCheckbox {
	margin-top: 3rem;
}
.createShift__footer {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.modal-footer {
	border-top: none;
	padding-bottom: 1rem;
}
.createShift__btn {
	height: 1.9rem !important;
	width: 100%;
	margin-bottom: 0.5rem;
}
.createShift__calendar:hover {
	cursor: pointer;
}
</style>
