export default {
	namespaced: true,
	state: {
		buttonText: "okay",
		eventFired: null,
		title: "",
		message: "",
		translationVars: {},
	},
	mutations: {
		SET_SIMPLE_MODAL_BUTTON_TEXT(state, { buttonText }) {
			state.buttonText = buttonText;
		},
		SET_SIMPLE_MODAL_MESSAGE(state, { message }) {
			state.message = message;
		},
		SET_SIMPLE_MODAL_TITLE(state, { title }) {
			state.title = title;
		},
		SET_SIMPLE_MODAL_EVENT_FIRED(state, { eventFired }) {
			state.eventFired = eventFired;
		},
		SET_SIMPLE_MODAL_BUTTON_TYPE(state, { buttonType }) {
			state.buttonType = buttonType;
		},
		SET_SIMPLE_MODAL_TRANSLATION_VARS(state, { vars }) {
			state.translationVars = vars;
		},
	},

	actions: {
		configureSimpleModal(
			{ commit },
			{ title, buttonText = "Ok", message, eventFired = null, buttonType = "PRIMARY", vars = {} },
		) {
			commit("SET_SIMPLE_MODAL_TITLE", { title });
			commit("SET_SIMPLE_MODAL_BUTTON_TEXT", { buttonText });
			commit("SET_SIMPLE_MODAL_MESSAGE", { message });
			commit("SET_SIMPLE_MODAL_EVENT_FIRED", { eventFired });
			commit("SET_SIMPLE_MODAL_BUTTON_TYPE", { buttonType });
			commit("SET_SIMPLE_MODAL_TRANSLATION_VARS", { vars });
		},
	},
};
