import rootInstance from "@/services/root.js";

const baseUrl = process.env.MATH_PROBLEM_API_URL + "/api/v1/";

const mathApi = () => {
	rootInstance.defaults.baseURL = baseUrl;
	return rootInstance;
};

export default {
	async getAllMissionsByTopic(topic_id, subject_id, mission_type_id) {
		let url = `?topic_id[]=${topic_id}&subject_id[]=${subject_id}&active=1`;

		if (mission_type_id !== undefined) {
			url += `&mission_type_id=${mission_type_id}`;
		}

		return await mathApi().get(url);
	},
};
