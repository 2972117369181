<!--
	Requires the following data  :
	1) A tutor search object : [ topics tutor can teach, date and time information, tutor information ]
-->
<template>
	<div>
		<div
			v-if="current.makeNewHeader"
			class="tutorSearchResultsDate"
		>
			{{ current.dateHeader }}
		</div>

		<div class="tutorSearchContainer">
			<div class="tutorSearchTutorInfoBox">
				<div class="tutorSearchProfileTutorImageBox">
					<img
						class="tutorSearchProfileTutorImageOnline"
						:src="imageProfileURL"
						@error="showDefaultTutorImage('imageProfileURL')"
					>
				</div>
				<div class="tutorSearchNameBox">
					<div class="tutorSearchNameBoxText">
						{{ current.name }}
					</div>
					<div
						v-if="current.schedule.data.punch_in != null"
						class="tutorSearchNameBoxDateOnline"
					>
						Online until {{ endTime }}
					</div>
					<div v-else-if="current.schedule.data.start_time">
						{{ startTime + " - " + endTime }}
					</div>
					<div
						v-else
						v-jest="'notScheduledLabel'"
					>
						Not Scheduled
					</div>
				</div>
			</div>

			<div class="tutorSearchTopicBox1">
				<div class="tutorSearchTopicBox2">
					<div
						v-for="topic in current.topics.data"
						:key="topic.name"
						class="tutorSearchTopicDisplay"
					>
						{{ topic.name }}
					</div>
					<div
						v-for="subject in subjectsWithNoTopics"
						:key="subject.name"
						class="tutorSearchTopicDisplay"
					>
						{{ subject.name }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { format } from "date-fns";

export default {
	props: {
		current: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			imageProfileURL: this.current.profile_image,
		};
	},
	computed: {
		startTime() {
			return format(new Date(this.current.schedule.data.start_time * 1000), "hh:mm a");
		},
		endTime() {
			return format(new Date(this.current.schedule.data.end_time * 1000), "hh:mm a");
		},
		subjectsWithNoTopics() {
			const subjectsWithNoTopic = [];
			const subjects = this.current.subjects.data;
			const topics = this.current.topics.data;

			subjects.forEach((subject) => {
				const isSubjectHasTopic = topics.some((topic) => topic.subject_id === subject.id);
				if (!isSubjectHasTopic) {
					subjectsWithNoTopic.push(subject);
				}
			});

			return subjectsWithNoTopic;
		},
	},
	methods: {
		showDefaultTutorImage() {
			this.imageProfileURL = window.location.origin + "/images/default-profile-picture.png";
		},
	},
};
</script>

<style scoped>
.tutorSearchResultsDate {
	color: var(--c-gray);
	font-size: 18px;
	background-color: var(--c-lighter-gray);
	padding: 10px 20px 10px 30px;
}
.tutorSearchContainer {
	background-color: var(--c-white);
	border-top: 2px solid var(--c-lighter-gray);
	clear: both;
	display: inline-block;
	width: 100%;
	padding: 5px 0 11px 0;
}
.tutorSearchProfileTutorImageBox {
	float: left;
	padding: 16px 14px 16px 28px;
}
.tutorSearchProfileTutorImageOnline {
	border-radius: 50%;
	border: 2px solid var(--c-green);
	opacity: 0.7;
	width: 34px;
	height: 34px;
}
.tutorSearchNameBox {
	float: left;
	width: 220px;
	padding: 12px 0px 0px 0px;
}
.tutorSearchTopicDisplay {
	color: var(--c-black);
	border: 1px solid var(--c-gray);
	display: inline-block;
	margin: 0 6px 10px 0;
	padding: 4px 10px;
	font-size: 13px;
	border-radius: 3px;
}
.tutorSearchTopicBox1 {
	display: flex;
}
.tutorSearchTopicBox2 {
	display: block;
	font-size: 14px;
	vertical-align: top;
	padding: 10px 0px 10px 0px;
}
.tutorSearchTutorInfoBox {
	float: left;
}
.tutorSearchNameBoxText {
	color: var(--c-blue);
}
.tutorSearchNameBoxDateOnline {
	color: var(--c-green);
	opacity: 0.7;
}
</style>
