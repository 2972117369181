var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "label",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: `service-analytics-${_setup.props.filter.name}-filter-label`,
              expression:
                "`service-analytics-${props.filter.name}-filter-label`",
            },
          ],
          staticClass: "tw-font-bold",
          attrs: {
            "data-cy": `service-analytics-${_setup.props.filter.name}-filter-label`,
          },
        },
        [_vm._v("\n\t\t" + _vm._s(_setup.props.filter.name) + "\n\t")]
      ),
      _vm._v(" "),
      _c(
        _setup.BaseDropdown,
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: `service-analytics-${_setup.props.filter.name}-filter-dropdown`,
              expression:
                "`service-analytics-${props.filter.name}-filter-dropdown`",
            },
          ],
          staticClass: "tw-w-1/3",
          attrs: {
            "data-cy": `service-analytics-${_setup.props.filter.name}-filter-dropdown`,
            items: _setup.props.filter.filterChoices,
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function ({ item }) {
                return [
                  _c(
                    _setup.BaseCheckbox,
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "filter-dropdown-checkbox",
                          expression: "'filter-dropdown-checkbox'",
                        },
                      ],
                      attrs: {
                        "data-cy": `${_vm.filter.name}-filter-dropdown-checkbox-${item.name}`,
                        "checkbox-value": { id: item.id, name: item.name },
                        "checkbox-id": item.id,
                      },
                      model: {
                        value: _setup.selectedChoices,
                        callback: function ($$v) {
                          _setup.selectedChoices = $$v
                        },
                        expression: "selectedChoices",
                      },
                    },
                    [
                      _c("template", { slot: "text" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" + _vm._s(item.name) + "\n\t\t\t\t"
                        ),
                      ]),
                    ],
                    2
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("template", { slot: "filters" }, [
            _vm._v("\n\t\t\t" + _vm._s(_setup.filterDropdownText) + "\n\t\t"),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }