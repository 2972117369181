<template>
	<ServiceAbsencesTableAbstract
		:headers="PlatformManager.ServiceAbsencesTable.tableHeaders"
		:items="tutorList"
	>
		<template slot-scope="{ item }">
			<td
				:class="{ 'tw-bg-origami-red-100': hasMIA(item) }"
				class="tw-w-32"
			>
				{{ item.user_id }}
			</td>
			<td
				:class="{ 'tw-bg-origami-red-100': hasMIA(item) }"
				class="tw-w-32"
			>
				{{ getTutorName(item.user_id) }}
			</td>
			<td
				:class="{ 'tw-bg-origami-red-100': hasMIA(item) }"
				class="tw-w-32"
			>
				{{ formatDate(item.shift_segment.start_time) }}
			</td>
			<td
				:class="{ 'tw-bg-origami-red-100': hasMIA(item) }"
				class="tw-w-32"
			>
				{{ formatTime(item.shift_segment.start_time) }}
			</td>
			<td
				:class="{ 'tw-bg-origami-red-100': hasMIA(item) }"
				class="tw-w-32"
			>
				{{ formatTime(item.shift_segment.end_time) }}
			</td>
			<td
				:class="{ 'tw-bg-origami-red-100': hasMIA(item) }"
				class="tw-w-32"
			>
				{{ formatTime(item.start_time) }}
			</td>
			<td
				:class="{ 'tw-bg-origami-red-100': hasMIA(item) }"
				class="tw-w-32"
			>
				{{ formatTime(item.end_time) }}
			</td>
			<td
				v-if="item.absence !== null"
				:class="{ 'tw-bg-origami-red-100': hasMIA(item) }"
				class="tw-w-32"
			>
				{{ formatTime(item.absence.start_time) }}
			</td>
			<td
				v-if="item.absence !== null"
				:class="{ 'tw-bg-origami-red-100': hasMIA(item) }"
				class="tw-w-32"
			>
				{{ formatTime(item.absence.end_time) }}
			</td>
		</template>
	</ServiceAbsencesTableAbstract>
</template>

<script>
import { mapState } from "vuex";
import { format } from "date-fns";
import { isEmpty, isNil } from "lodash";

import ServiceAbsencesTableAbstract from "./ServiceAbsencesTableAbstract.vue";

export default {
	components: {
		ServiceAbsencesTableAbstract,
	},
	props: {
		tutorList: {
			type: Array,
			required: true,
		},
	},
	computed: {
		...mapState(["PlatformManager"]),
	},
	methods: {
		formatDate(date) {
			return !isEmpty(date) ? format(new Date(date), "MM/dd/yy") : "";
		},
		formatTime(time) {
			return !isEmpty(time) ? format(new Date(time), "hh:mm a") : "";
		},
		hasMIA(tutor) {
			return !isNil(tutor.absence);
		},
		getTutorName(userID) {
			return this.$store.getters["PlatformManager/ServiceAbsencesTable/getTutorName"](userID);
		},
	},
};
</script>

<style scoped>
	td {
		padding: 0.75em;
	}
</style>
