<template>
	<div>
		<ServiceDashboardTutorCardHeaderTutorManager
			:user="cardData.user"
			:shift="cardData.shift"
		/>
	</div>
</template>

<script>
import ServiceDashboardTutorCardHeaderTutorManager from "./ServiceDashboardTutorCardHeaderTutorManager.vue";
export default {
	components: {
		ServiceDashboardTutorCardHeaderTutorManager,
	},
	props: {
		cardData: {
			type: Object,
			required: true,
			validator(value) {
				return value.hasOwnProperty("user") && value.hasOwnProperty("shift");
			},
		},
	},
};
</script>
