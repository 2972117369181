<template>
	<div
		id="notificationSettings"
		:class="variant"
	>
		<h2
			v-if="!isStudentProfileVariant"
			class="accountSettings__title"
		>
			{{ $t("sidebar.notifications") }}
		</h2>
		<p
			v-jest="'notifications-description'"
			class="tw-mt-2 tw-mb-4"
		>
			{{ $t("notifications.description") }}
		</p>
		<div class="notificationsContainer">
			<transition name="notification-fadeout">
				<div
					v-cloak
					v-if="isSuccess && isShowingMessage"
					v-jest="'success-text'"
					class="successMessage"
				>
					<IconCheckMark :icon-color="'currentColor'" />
					<p class="successMessage__text">
						{{ $t("notifications.settings_updated") }}
					</p>
				</div>
			</transition>
			<div
				v-if="isError"
				v-jest="'error-text'"
				class="errorContainer"
			>
				<IconExclamationMark />
				<p class="infoText">
					{{ $t("notifications.error") }}
				</p>
			</div>
		</div>
		<form class="accountSettingsNew">
			<h3 class="tw-text-lg tw-font-bold tw-mt-4">
				{{ $t("notifications.notification_type_email") }}
			</h3>
			<div class="form-group checkboxGroup">
				<input
					id="statusNotifications"
					v-jest="'Email-checkbox'"
					type="checkbox"
					name="statusNotifications"
					:checked="Settings.isEmailNotificationsEnabled"
					@click="toggleNotificationsCheckbox('Email')"
				>
				<div class="grouped">
					<label
						v-cloak
						for="statusNotifications"
					>
						{{ $t("notifications.status_updates") }}
					</label>
					<p>
						{{ $t("notifications.receive_status_updates") }}
					</p>
				</div>
			</div>
			<div class="form-group checkboxGroup">
				<input
					id="eventSettings"
					type="checkbox"
					name="eventSettings"
					:disabled="!isPushManagerPresent"
					:checked="Settings.isEventNotificationsEnabled"
					@click="toggleNotificationsCheckbox('Event')"
				>
				<div class="grouped">
					<label
						v-cloak
						v-jest="'Event-checkbox'"
						for="eventSettings"
					>
						{{ $t("notifications.event_updates") }}
					</label>
					<p>
						{{ $t("notifications.receive_event_updates") }}
					</p>
				</div>
			</div>

			<h3 class="tw-text-lg tw-font-bold tw-mt-4">
				{{ $t("notifications.notification_type_app") }}
			</h3>

			<div class="form-group checkboxGroup">
				<input
					id="appSettings"
					v-jest="'Mobile-checkbox'"
					type="checkbox"
					name="appSettings"
					:disabled="!isPushManagerPresent"
					:checked="Settings.isMobileNotificationsEnabled"
					@click="toggleNotificationsCheckbox('Mobile')"
				>
				<div class="grouped">
					<label
						v-cloak
						for="appSettings"
					>
						{{ $t("notifications.status_updates") }}
					</label>
					<p>
						{{ $t("notifications.receive_status_updates") }}
					</p>
				</div>
			</div>

			<h3 class="tw-text-lg tw-font-bold tw-mt-4">
				{{ $t("notifications.website_settings") }}
			</h3>

			<div class="form-group checkboxGroup">
				<input
					id="audioSettings"
					v-jest="'AppSounds-checkbox'"
					type="checkbox"
					name="audioSettings"
					:checked="Settings.isAppSoundsEnabled"
					@click="toggleNotificationsCheckbox('AppSounds')"
				>
				<div class="grouped">
					<label
						v-cloak
						for="audioSettings"
					>
						{{ $t("notifications.app_sounds") }}
					</label>
					<p>{{ $t("notifications.app_sounds_details") }}</p>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import { mapState } from "vuex";

import IconCheckMark from "@/components/icons/IconCheckMark.vue";
import IconExclamationMark from "@/components/icons/IconExclamationMark.vue";

export const NOTIFICATION_VISIBILITY_TIME = 5000;

export default {
	components: {
		IconExclamationMark,
		IconCheckMark,
	},
	props: {
		variant: {
			type: String,
			default: "settings",
			validator: (v) => ["settings", "student-profile"].includes(v),
		},
	},
	data() {
		return {
			swRegistration: null,
			isShowingMessage: false,
			hideNotificationTimeout: null,
		};
	},
	computed: {
		...mapState(["Settings"]),
		isError() {
			return this.Settings.studentNotificationsOptionsStatus === "error";
		},
		isSuccess() {
			return this.Settings.studentNotificationsOptionsStatus === "success";
		},
		isPushManagerPresent() {
			return window.PushManager !== undefined;
		},
		isStudentProfileVariant() {
			return this.variant === "student-profile";
		},
	},
	watch: {
		isShowingMessage(value) {
			if (value) {
				this.hideNotificationTimeout = setTimeout(this.hideNotificationMessage, NOTIFICATION_VISIBILITY_TIME);
			}
		},
	},
	beforeDestroy() {
		clearTimeout(this.hideNotificationTimeout);
	},
	mounted() {
		if (this.isPushManagerPresent) {
			this.setIsBrowserNotificationsEnabled();
		}
	},
	methods: {
		hideNotificationMessage() {
			this.isShowingMessage = false;
		},
		toggleNotificationsCheckbox(type) {
			this.$store.dispatch(`Settings/toggle${type}Notifications`);
		},
		async subscribeUser() {
			if (!process.env.MIX_WEB_PUSH_PUBLIC_KEY) {
				throw new Error("Misconfigured MIX_WEB_PUSH_PUBLIC_KEY: check your environment variables");
			}
			const applicationServerKey = this.urlB64ToUint8Array(process.env.MIX_WEB_PUSH_PUBLIC_KEY);
			try {
				const subscription = await this.swRegistration.pushManager.subscribe({
					userVisibleOnly: true,
					applicationServerKey: applicationServerKey,
				});
				await this.$store.dispatch("Settings/toggleBrowserNotifications", subscription);
				this.$store.commit("Settings/SET_STUDENT_NOTIFICATIONS_OPTIONS_STATUS", {
					studentNotificationsOptionsStatus: "success",
				});
			} catch (err) {
				if (Notification.permission === "denied") {
					console.warn("Permission for notifications was denied");
				}
				this.$store.commit("Settings/SET_STUDENT_NOTIFICATIONS_OPTIONS_STATUS", {
					studentNotificationsOptionsStatus: "error",
				});
			} finally {
				this.isShowingMessage = true;
			}
		},
		async unsubscribeUser() {
			try {
				const subscription = await this.swRegistration.pushManager.getSubscription();
				if (subscription) {
					await subscription.unsubscribe();
				}
				await this.$store.dispatch("Settings/toggleBrowserNotifications", null);
				this.$store.commit("Settings/SET_STUDENT_NOTIFICATIONS_OPTIONS_STATUS", {
					studentNotificationsOptionsStatus: "success",
				});
			} catch (e) {
				this.$store.commit("Settings/SET_STUDENT_NOTIFICATIONS_OPTIONS_STATUS", {
					studentNotificationsOptionsStatus: "error",
				});
			} finally {
				this.isShowingMessage = true;
			}
		},
		async setIsBrowserNotificationsEnabled() {
			try {
				this.swRegistration = await navigator.serviceWorker.register("/js/serviceWorker.js");
				const sub = await this.swRegistration.pushManager.getSubscription();

				await this.$store.dispatch("Settings/toggleBrowserNotifications", sub);
				this.$store.commit("Settings/SET_IS_BROWSER_NOTIFICATIONS_ENABLED", {
					isBrowserNotificationsEnabled: sub !== null,
				});
			} catch (e) {
				Sentry.captureException(e);
			}
		},
		urlB64ToUint8Array(base64String) {
			const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
			const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

			const rawData = window.atob(base64);
			const outputArray = new Uint8Array(rawData.length);

			for (let i = 0; i < rawData.length; ++i) {
				outputArray[i] = rawData.charCodeAt(i);
			}
			return outputArray;
		},
	},
};
</script>

<style scoped>
.accountSettings__title {
	font-size: 1.25rem;
}
.student-profile {
	display: flex;
	flex-direction: column;
}
.student-profile .accountSettings {
	display: flex;
	flex-wrap: wrap;
	order: -1;
}
.student-profile .form-group {
	flex: 1;
	align-items: flex-start;
	padding: 0.5rem 1rem;
	border: 0;
	max-width: 50%;
	min-width: 50%;
}
.student-profile .form-group .grouped label {
	font-size: var(--lg);
	font-weight: bold;
}
.student-profile input[type="checkbox"] {
	transform: scale(1.5) translateY(0.35rem);
}
.student-profile .successMessage,
.student-profile .errorContainer {
	color: var(--black-high);
	margin: 1rem;
}
.notification-fadeout-leave-active {
	transition: all .8s ease;
}
.notification-fadeout-leave-to {
	opacity: 0;
}
.accountSettingsNew .form-group {
	padding: 1rem 0;
}
.accountSettingsNew .form-group .grouped label {
	font-size: var(--origami-font-size-body);
	font-weight: normal;
}
</style>
