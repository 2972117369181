var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "data-cy",
          rawName: "v-data-cy",
          value: "section-rest-break-request",
          expression: "'section-rest-break-request'",
        },
      ],
      staticClass: "tw-max-w-screen-lg tw-mx-auto tw-pb-24",
    },
    [
      _c(
        "section",
        {
          directives: [
            {
              name: "data-cy",
              rawName: "v-data-cy",
              value: "upcoming-rest-break",
              expression: "'upcoming-rest-break'",
            },
          ],
          attrs: { "aria-label": "Upcoming rest Breaks" },
        },
        [
          _c("h2", { staticClass: "tw-text-3xl tw-font-extrabold tw-mb-5" }, [
            _vm._v(
              "\n\t\t\tUpcoming Rest Breaks (" +
                _vm._s(_vm.upcomingBreaks.length) +
                ")\n\t\t"
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-mb-12 tw-gap-x-4",
            },
            _vm._l(_vm.upcomingBreaks, function (upcomingBreak) {
              return _c("TutorBreakCardUpcomingBreak", {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "upcoming-break",
                    expression: "'upcoming-break'",
                  },
                ],
                key: upcomingBreak.id,
                attrs: { "upcoming-break": upcomingBreak },
              })
            }),
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        {
          directives: [
            {
              name: "data-cy",
              rawName: "v-data-cy",
              value: "active-rest-break",
              expression: "'active-rest-break'",
            },
          ],
          attrs: { "aria-label": "Active Rest Breaks" },
        },
        [
          _c("h2", { staticClass: "tw-text-3xl tw-font-extrabold tw-mb-5" }, [
            _vm._v(
              "\n\t\t\tActive Rest Breaks (" +
                _vm._s(_vm.currentBreaks.length) +
                ")\n\t\t"
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-mb-12 tw-gap-x-4",
            },
            _vm._l(_vm.currentBreaks, function (currentBreak) {
              return _c("TutorBreakCardActiveBreak", {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "current-break",
                    expression: "'current-break'",
                  },
                ],
                key: currentBreak.id,
                attrs: { "current-break": currentBreak },
              })
            }),
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }