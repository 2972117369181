<template>
	<svg
		v-if="checked"
		viewBox="0 0 24 24"
		height="24"
		width="24"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10.7273 13.4552L9.05682 11.8881L8.5 12.4104L10.7273 14.5L15.5 10.0224L14.9432 9.5L10.7273 13.4552Z"
			fill="currentColor"
			stroke-width="1.16667"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
			fill="currentColor"
		/>
	</svg>
	<svg
		v-else
		viewBox="0 0 24 24"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
			fill="currentColor"
		/>
	</svg>
</template>

<script>
export default {
	props: {
		checked: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

