import api from "@/services/api/Api.js";

export default {
	/**
	 * Create communication
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	create(data) {
		return api().post(`/communications`, data);
	},

	/**
	 * Get communication
	 * @param {number} communicationId
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	show(communicationId, params) {
		return api().get(`/communications/${communicationId}`, {
			params: params,
		});
	},

	/**
	 * Update communication
	 * @param {number} communicationId
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	update(communicationId, data) {
		return api().post(`/communications/${communicationId}`, data);
	},

	/**
	 * Delete communication
	 * @param {number} communicationId
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	delete(communicationId, data) {
		return api().delete(`/communications/${communicationId}`, data);
	},

	/**
	 * Get all communications
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	list(params) {
		return api().get(`/communications`, {
			params: params,
		});
	},

	/**
	 * Send communication
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	send(data) {
		return api().post(`/communications.send`, data);
	},

	audienceCount(params) {
		return api().get(`/communications/audience-count`, { params });
	},
};
