<template>
	<div class="tw-relative">
		<ChartDoughnut
			:data="data"
			:options="options"
			aria-hidden="true"
			class="tw-h-full"
		/>
		<div class="tw-absolute tw-top-0 tw-left-0 tw-flex tw-items-center tw-justify-center tw-w-full tw-h-full">
			<div class="tw-flex tw-items-center tw-justify-center tw-w-20 tw-h-20">
				<slot />
			</div>
		</div>
	</div>
</template>

<script>
import { computed } from "vue";
import { Chart as ChartJS, ArcElement } from "chart.js";
import { Doughnut as ChartDoughnut } from "vue-chartjs";

ChartJS.register(ArcElement);

import Colors from "../../../utilities/Colors.js";

export const COLORS = {
	EMPTY: Colors.C_BLUE,
	COMPLETED: Colors.C_LIGHT_BLUE,
};

export default {
	name: "BaseCircle",
	components: { ChartDoughnut },
	props: {
		progress: {
			type: Number,
			required: true,
		},
		total: {
			type: Number,
			required: true,
		},
		thickness: {
			type: Number,
			validator: (value) => value >= 0 && value <= 100,
			default: 77,
		},
		spacing: {
			type: Number,
			default: 5,
		},
	},
	setup(props) {
		const data = computed(() => {
			const chartData = new Array(props.total).fill(0).map((_, index) => {
				return {
					value: 100 / props.total,
					color: index < props.progress ? COLORS.EMPTY : COLORS.COMPLETED,
				};
			});

			return {
				labels: new Array(props.total).fill(""),
				datasets: [{
					data: chartData.map(({ value }) => value),
					backgroundColor: chartData.map(({ color }) => color),
					cutout: `${props.thickness}%`,
					spacing: props.total === 1 ? 0 : props.spacing,
				}],
			};
		});

		const options = {
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					enabled: false,
				},
			},
		};

		return {
			data,
			options,
		};
	},
};
</script>
