var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "tw-flex tw-pt-20 tw-pb-0 tw-px0 tw-bg-grey-pale tw-h-auto tw-justify-center tw-text-center",
      },
      [
        _c("div", { staticClass: "tw-font-bold tw-text-base" }, [
          _c("p", [_vm._v("No shift segment selected")]),
          _vm._v(" "),
          _c("p", { staticClass: "tw-font-normal tw-text-xs" }, [
            _vm._v("\n\t\t\tClick on a shift segment to edit or remove\n\t\t"),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }