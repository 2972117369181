<template>
	<div class="vueCustomerDashboardTable">
		<table class="table table-striped">
			<tr class="vueTableHeader">
				<th
					v-for="header in headers"
					:key="header.value"
					nowrap
					:class="{ statusPad: header.header === 'Status' }"
				>
					{{ header.header }}
					<span
						v-if="header.orderable"
						v-jest="header.value"
						class="headerOrderBy"
						@click="handleOrderByEvent(header)"
					>
						<component
							:is="determineSortingIcon(header.state)"
							class="tw-inline"
						/>
					</span>
				</th>
			</tr>
			<tr
				v-for="(item, index) in items"
				:key="item.id"
				v-data-cy="`dashboard-table-item`"
				class="vueTableItem"
			>
				<slot
					:item="item"
					:index="index"
				/>
			</tr>
			<slot name="extraRows" />
		</table>
	</div>
</template>

<script>
import IconCaretAsc from "./icons/IconCaretAsc.vue";
import IconCaretDesc from "./icons/IconCaretDesc.vue";
import IconCaretSort from "./icons/IconCaretSort.vue";

export default {
	components: {
		IconCaretAsc,
		IconCaretDesc,
		IconCaretSort,
	},
	props: {
		headers: {
			type: Array,
			required: false,
			default: function() {
				return [];
			},
		},
		items: {
			type: [Array, Object],
			default: function() {
				return [];
			},
		},
	},
	computed: {},
	methods: {
		/**
		 * @param {String} headerState
		 * @returns {Component}
		 */
		determineSortingIcon(headerState) {
			switch (headerState) {
				case "asc":
					return IconCaretAsc;
				case "desc":
					return IconCaretDesc;
				default:
					return IconCaretSort;
			}
		},
		handleOrderByEvent: function() {},
	},
};
</script>

<style scoped>
.statusPad {
	padding-right: 15px;
}

.vueTableItem {
	height: 52px;
	box-shadow: inset 0 -1px 0 0 var(--c-light-gray);
	font-size: 10px;
	font-weight: normal;
	color: var(--c-black);
}

.vueTableItem:hover {
	background: var(--c-light-gray);
	cursor: pointer;
}

.vueTableHeader {
	border-bottom: solid 2px var(--c-light-gray);
}

.licenseDot {
	width: 8px;
	height: 8px;
	border-radius: 8px;
	display: inline-flex;
	margin-right: 5px;
}

.pill {
	text-transform: capitalize;
	border-radius: 10px;
	color: var(--c-white);
	padding: 2px 8px;
}

.greenColor {
	background: var(--c-green);
}

.redColor {
	background: var(--c-red);
}

.yellowColor {
	background: var(--c-peach);
}

.headerOrderBy {
	padding-left: 8px;
}

.headerOrderBy:hover {
	cursor: pointer;
}

td {
	padding: 0;
	vertical-align: middle;
	border-top: none;
	line-height: 1.6rem;
}

th {
	text-transform: capitalize;
	padding: 12px 5px;
	font-size: 12px;
	font-weight: 600;
	color: var(--c-black);
	white-space: nowrap;
	vertical-align: middle;
	border-top: none;
	line-height: 1.6rem;
}

:deep(table > tr > td > a) {
	min-height: 52px;
	padding: 5px;
	display: flex;
	align-items: center;
	text-decoration: none;
	color: var(--c-black);
}

:deep(table > tr > td > a:hover) {
	color: var(--c-black);
}
</style>
