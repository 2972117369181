<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		:width="width"
		:height="height"
		:viewBox="`0 0 ${width} ${height}`"
	>
		<defs>
			<path
				:id="randomId"
				d="M3.996 0L0 4.23 12.98 18 0 31.77 3.996 36 21 18z"
			/>
		</defs>
		<g
			fill="none"
			fill-rule="evenodd"
		>
			<mask
				:id="`a${randomId}`"
				fill="#fff"
			>
				<use :xlink:href="`#${randomId}`" />
			</mask>
			<use
				fill="#484756"
				:xlink:href="`#${randomId}`"
			/>
			<g
				fill="#FFF"
				:mask="`url(#a${randomId})`"
			>
				<path d="M-18-12h60v60h-60z" />
			</g>
		</g>
	</svg>
</template>
<script>
import Colors from "../../utilities/Colors.js";

import IconId from "./IconId.js";
export default {
	extends: IconId,
	props: {
		width: {
			type: [Number, String],
			default: 21,
		},
		height: {
			type: [Number, String],
			default: 36,
		},
		iconColor: {
			type: String,
			default: Colors["C_BLUE"],
		},
	},
};
</script>
<style scoped>
svg {
	transform: rotate(180deg);
}
</style>
