import Vue from "vue";
import VueRouter from "vue-router";

if (!process || process.env.NODE_ENV !== "test") {
	Vue.use(VueRouter);
}

import { authenticationRoutes } from "@/modules/Authentication/index.js";
import { embeddedRouteGuard } from "@/modules/EmbeddedUtilities/index.js";
import embeddedRoutes from "@/modules/EmbeddedUtilities/router/index.js";
import referralRoutes from "@/modules/Referrals/router/index.js";
import { editorRoutes as clipsEditorRoutes } from "@/modules/TeachingAssets/router/editor.js";

import maintenanceRouteGuard from "../middleware/maintenanceRouteGuard.js";

import administratorRoutes from "./administrator.js";
import defaultRoutes from "./default.js";
import districtManagerRoutes from "./district-manager.js";
import redirectRoutes from "./redirect.js";
import studentRoutes from "./student.js";
import superuserRoutes from "./superuser.js";
import teacherRoutes from "./teacher.js";
import tutorManagerRoutes from "./tutor-manager.js";
import missionsAdminRoutes from "./missions-admin.js";
import tutorRoutes from "./tutor.js";

const routes = [
	...referralRoutes,
	...redirectRoutes,
	...teacherRoutes,
	...administratorRoutes,
	...districtManagerRoutes,
	...superuserRoutes,
	...tutorManagerRoutes,
	...tutorRoutes,
	...studentRoutes,
	...embeddedRoutes,
	...authenticationRoutes,
	...defaultRoutes,
	...missionsAdminRoutes,
	...clipsEditorRoutes,
];

const router = new VueRouter({
	mode: "history",
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
});

router.beforeEach(maintenanceRouteGuard);
router.beforeEach(embeddedRouteGuard);

router.onError((error) => {
	if (/loading chunk \w* failed./i.test(error.message)) {
		try {
			const pageHasAlreadyBeenForceRefreshed = JSON.parse(
				window.sessionStorage.getItem("page-has-been-force-refreshed") || "false",
			);
			// we never tried to refresh the page
			if (!pageHasAlreadyBeenForceRefreshed) {
				window.sessionStorage.setItem("page-has-been-force-refreshed", "true");
				return window.location.reload();
			// we have already tried to refresh the page If the webpack update we should not go here
			} else {
				Sentry.captureException(new Error("Redirect because of chunk loading failures"));
				return window.location.href = "/error-assets.html";
			}
		} catch (e) {
			Sentry.captureException(e);
		}
	}
});

export default router;
