<script>
import { differenceInHours, differenceInMinutes, differenceInSeconds, formatDistanceStrict } from "date-fns";

import { formatTimestampToDate } from "@/utilities/dateHelpers.js";

import ServiceDashboardListItemAbstract from "./ServiceDashboardListItemAbstract.vue";

export default {
	extends: ServiceDashboardListItemAbstract,
	props: {
		resource: {
			type: [Object, Array],
			required: true,
		},
	},
	computed: {
		/**
		 * @returns {Boolean}
		 */
		isBlinking() {
			return !this.isTutorResponsive && this.isThirtySecondsAfterSessionStart;
		},
		/**
		 * @returns {Boolean}
		 */
		isGreyedOut() {
			return !this.isStudentResponsive && this.isTutorResponsive;
		},
		isThirtySecondsAfterSessionStart() {
			return differenceInSeconds(this.currentDate, new Date(this.resource.created_at * 1000)) >= 30;
		},
		/**
		 * @returns {Boolean}
		 */
		isIndividualSession() {
			return this.resource.type === "individual";
		},
		/**
		 * @returns {String}
		 */
		leftBottomText() {
			try {
				return this.resource.active_users[0].school;
			} catch (e) {
				return "";
			}
		},
		/**
		 * @returns {Boolean}
		 */
		isStudentDisconnected() {
			try {
				return !this.resource.active_users[0].is_online;
			} catch (e) {
				return false;
			}
		},
		studentIsDisconnectedFor() {
			return formatDistanceStrict(
				formatTimestampToDate(this.resource.active_users[0].disconnected_time),
				new Date(),
			);
		},
		/**
		 * Checks if the session is with a demo user from a demo school.
		 * @returns {Boolean}
		 */
		isDemo() {
			try {
				return this.resource.active_users[0].is_demo;
			} catch (e) {
				return false;
			}
		},
		/**
		 * @returns {Boolean}
		 */
		isStudentResponsive() {
			return this.resource.is_student_active;
		},
		/**
		 * @returns {Boolean}
		 */
		isTutorResponsive() {
			return this.resource.is_tutor_engaged;
		},
		/**
		 * @returns {String}
		 */
		iconComponent() {
			return "icon-session";
		},
		/**
		 * @returns {String}
		 */
		leftTopText() {
			return this.resource?.active_users?.[0]?.student_name;
		},
		/**
		 * @returns {String}
		 */
		middleTopText() {
			return this.resource.subject_name;
		},
		middleBottomText() {
			return `Session ID ${this.resource.id}`;
		},
		/**
		 * @returns {String}
		 */
		timeElapsed() {
			let hours = 0;
			let mins = 0;
			const sessionCreatedAt = new Date(this.resource.created_at * 1000);
			hours = differenceInHours(this.currentDate, sessionCreatedAt);
			mins = differenceInMinutes(this.currentDate, sessionCreatedAt);
			mins = mins % 60;
			return `${hours}h ${mins}min`;
		},
		/**
		 * @returns {String}
		 */
		sessionName() {
			return this.resource.active_users[0].student_name;
		},
		schoolRegionCode() {
			return this.resource.active_users[0].school_region_code;
		},
		studentSchoolDistrictId() {
			return this.resource.active_users[0].school_district_id;
		},
		isEssay() {
			return false;
		},
	},
	methods: {
		showTransferModal() {
			const transferSessionData = {
				sessionId: this.resource.id,
				sessionName: this.sessionName,
				outgoingTutorId: this.resource.user_id,
				schoolRegionCode: this.schoolRegionCode,
				schoolDistrictId: this.studentSchoolDistrictId,
			};
			this.$store.commit("PlatformManager/ServiceSummaryDashboard/SET_TRANSFER_SESSION_DATA", {
				transferSessionData,
			});
			this.$bvModal.show("transfer-session-modal");
		},
		goToActivity() {
			if (!this.isHovering) {
				this.$router.push(`/${this.currentUser.role}/activity?search="${this.resource.id}"&tab=Sessions&from=${this.resource.created_at}`);
			}
		},
	},
};
</script>
