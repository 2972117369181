export default {
	en: {
		error_state: {
			something_went_wrong: "Something Went Wrong",
			cannot_connect: "We were unable to connect to the server.",
			please_try_again: "Please try again.",
			try_again: "Try Again",
		},
		message_error: {
			failed_to_load: "Failed to load. Try refreshing the page.",
			embedded: {
				failed_to_load: "Failed to load. Refresh the page.",
			},
		},
	},
	fr: {},
	es: {},
};
