<template>
	<div
		v-jest="'learner-profile-link-container'"
		class="tw-p-4 tw-border-b tw-border-grey-regular tw-flex tw-flex-col"
	>
		<div class="tw-flex tw-gap-4 tw-items-center">
			<AvatarIcon
				v-jest="'avatar'"
				class="tw-border tw-border-grey-dark"
				:user="userData"
				:size="56"
			/>
			<p class="tw-font-bold tw-break-all">
				{{ `${currentUser.firstName} ${currentUser.lastName[0]}.` }}
			</p>
		</div>
		<OrigamiButton
			v-jest="'learner-profile-link'"
			variant="secondary"
			class="tw-whitespace-nowrap tw-mt-4 tw-mx-auto tw-w-full tw-justify-center"
			@click.native="redirectToLearnerProfile()"
		>
			{{ i18n.t("student_navigation.dropdown_links.view_my_profile") }}
		</OrigamiButton>
	</div>
</template>

<script setup>
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { OrigamiButton } from "@origami/vue2";

import useStore from "@/composables/useStore.js";
import AvatarIcon from "@/modules/Avatar/components/AvatarIcon.vue";
import useI18n from "@/composables/useI18n.js";

const emit = defineEmits(["redirect"]);

const route = useRoute();
const router = useRouter();
const store = useStore();
const i18n = useI18n();

const currentUser = computed(() => store.state.currentUser);
const userData = computed(() => ({
	...currentUser.value,
	...store.state.Student.Profile?.profile,
}));

const redirectToLearnerProfile = () => {
	const learnerProfilePath = "/student/profile";
	if (route.path !== learnerProfilePath) {
		router.push(learnerProfilePath);
	}
	emit("redirect");
};
</script>
