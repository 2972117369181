var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "OrigamiCard",
    {
      staticClass:
        "tw-flex tw-items-center tw-justify-center tw-flex-shrink-0 tw-bg-origami-black tw-text-origami-white tw-rounded-none tw-overflow-hidden",
      style: { width: _vm.size, height: _vm.size },
      attrs: { tag: "div", "is-interactive": "" },
    },
    [
      !_vm.isError
        ? _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isLoaded,
                expression: "isLoaded",
              },
              {
                name: "jest",
                rawName: "v-jest",
                value: "image",
                expression: "'image'",
              },
            ],
            staticClass:
              "tw-object-cover tw-opacity-50 tw-rounded-none tw-mb-0 tw-mt-0",
            style: { width: _vm.size, height: _vm.size },
            attrs: {
              src: _vm.url,
              alt: _vm.$t("teaching_assets.thumbnail.alt", { name: _vm.title }),
            },
            on: {
              error: function ($event) {
                _vm.isError = true
                _vm.isLoaded = true
              },
              load: function ($event) {
                _vm.isLoaded = true
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoaded && !_vm.isError
        ? _c("BaseLoadingState", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "loading",
                expression: "'loading'",
              },
            ],
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoaded || _vm.isError
        ? _c("OrigamiIcon", {
            staticClass: "tw-absolute tw-pointer-events-none",
            attrs: { name: "play" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }