<template>
	<div>
		<SuperuserTutorEdit
			ref="SuperuserTutorEdit"
			@save-all-changes="isSubmitAllChangesEnabled"
		/>
		<SimpleModal id="simpleModal" />
	</div>
</template>

<script>
import { mapState } from "vuex";

import SimpleModal from "@/modules/Classroom/components/modals/SimpleModal.vue";

import SuperuserTutorEdit from "./ServiceTutorEdit.vue";

export default {
	name: "SuperuserTutorsEditWrapper",
	components: {
		SuperuserTutorEdit,
		SimpleModal,
	},
	computed: {
		...mapState(["currentUser"]),
	},
	methods: {
		isSubmitAllChangesEnabled(event) {
			this.$emit("save-all-changes", { isEnabled: event.isEnabled });
		},
	},
};
</script>
