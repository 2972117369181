<script>
import ServiceDashboardListAbstract from "./ServiceDashboardListAbstract.vue";
import ServiceDashboardListItemSession from "./ServiceDashboardListItemSession.vue";
export default {
	components: {
		ServiceDashboardListItemSession,
	},
	extends: ServiceDashboardListAbstract,
	props: {
		resources: {
			type: Array,
			required: true,
		},
		totalSessions: {
			type: Number,
			required: true,
		},
	},
	computed: {
		ongoingSessionsCount() {
			if (this.resources.length === 0) {
				return 0;
			} else {
				return this.resources.length;
			}
		},
		totalResourcesCount() {
			return this.totalSessions;
		},
		componentType() {
			return "session";
		},
		getLastActivity() {
			return this.lastActivity;
		},
	},
	methods: {
		/**
		 * @param {Object} item
		 * @returns {Component}
		 */
		getListComponent() {
			return ServiceDashboardListItemSession;
		},
	},
};
</script>
