var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "section" },
    [
      _c(
        "h2",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "section-title",
              expression: "'section-title'",
            },
          ],
          staticClass:
            "tw-flex tw-text-xl tw-flex-wrap tw-font-origami-bold tw-mt-5",
        },
        [
          _vm._v("\n\t\t" + _vm._s(_vm.sectionHeader) + "\n\t\t"),
          _vm.isLateTutorsSection
            ? _c("OrigamiIconButton", {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "toggle-section-button",
                    expression: "'toggle-section-button'",
                  },
                ],
                staticClass: "tw-ml-1",
                attrs: {
                  "aria-label": "toggle late tutors section",
                  "icon-name": _vm.isOpen ? "chevron-down" : "chevron-up",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.toggleSection.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "section__titleButtons" },
            [_vm._t("titleButtons")],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpen,
              expression: "isOpen",
            },
            {
              name: "jest",
              rawName: "v-jest",
              value: "tutor-card-list",
              expression: "'tutor-card-list'",
            },
          ],
          staticClass:
            "section__cardsList tw-inline-flex tw-flex-wrap tw-max-w-full",
        },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm.isSummarySection
        ? _c(
            "BaseLink",
            {
              staticClass: "section__viewAllEssaysLink",
              attrs: { to: _vm.viewAllEssaysLink },
            },
            [_vm._v("\n\t\tView all submissions\n\t")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }