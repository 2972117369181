var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BModal",
    {
      attrs: {
        id: "tutorDashboardAcceptedShiftTransferModal",
        "content-class": "bootstrapFourModalContent",
        "body-class": "bootstrapFourModalBody",
        "footer-class": "bootstrapFourModalFooter",
        "hide-header": true,
      },
    },
    [
      _c(
        "button",
        {
          staticClass: "bootstrapFourModalCloseButton",
          on: {
            click: function ($event) {
              return _vm.hideModal()
            },
          },
        },
        [_vm._v("\n\t\t×\n\t")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "acceptedShiftTransferModal__header" },
        [
          _c("IconCircleCheckMark", { staticClass: "checkMarkSvg" }),
          _vm._v(" "),
          _c("div", { staticClass: "acceptedShiftTransferModal__title" }, [
            _vm._v("\n\t\t\tShift transfer accepted\n\t\t"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "acceptedShiftTransferModal__content" }, [
        _c("div", { staticClass: "acceptedShiftTransferModal__text" }, [
          _vm._v("\n\t\t\tYour new shift was added to your schedule\n\t\t"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "acceptedShiftTransferModal__text" }, [
          _vm._v(
            "\n\t\t\tYou have successfully accepted the following shift:\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "acceptedShiftTransferModal__shiftDetails" }, [
          _c(
            "div",
            {
              staticClass:
                "acceptedShiftTransferModal__text acceptedShiftTransferModal__text--greenSmaller",
            },
            [_vm._v("\n\t\t\t\t" + _vm._s(_vm.shiftDate) + "\n\t\t\t")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "acceptedShiftTransferModal__text acceptedShiftTransferModal__text--greenSmaller",
            },
            [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(_vm.shiftStartTime) +
                  " to " +
                  _vm._s(_vm.shiftEndTime) +
                  "\n\t\t\t"
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "template",
        { slot: "modal-footer" },
        [
          _c(
            "BaseButton",
            {
              attrs: { type: "CANCEL" },
              nativeOn: {
                click: function ($event) {
                  return _vm.hideModal()
                },
              },
            },
            [
              _c("template", { slot: "text" }, [
                _vm._v("\n\t\t\t\tView Schedule\n\t\t\t"),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }