<template>
	<BModal
		id="tutorsListErrorModal"
		ref="tutors-list-error-modal"
		content-class="tutorsListErrorModal"
		body-class="tutorsListErrorModal__body"
		footer-class="tutorsListErrorModal__footer"
		:hide-header="true"
		:no-close-on-backdrop="true"
		:no-fade="true"
		:centered="true"
	>
		<button
			type="button"
			class="tutorsListErrorModal__closeButton"
			@click="hideModal()"
		>
			&times;
		</button>
		<div class="tutorsListErrorModal__header">
			<div class="tutorsListErrorModal__icon">
				<IconWarning class="tutorsListErrorModal__svg" />
			</div>
			<div class="tutorsListErrorModal__title">
				Something went wrong
			</div>
		</div>
		<div class="tutorsListErrorModal__content">
			<div class="tutorsListErrorModal__text">
				Please try again, if the error persist, contact your support team.
			</div>
		</div>
		<template slot="modal-footer">
			<div class="tutorsListErrorModal__footer">
				<BaseButton
					type="SUBMIT"
					@click.native="hideModal()"
				>
					<template slot="text">
						Okay
					</template>
				</BaseButton>
			</div>
		</template>
	</BModal>
</template>
<script>
import IconWarning from "@/components/icons/IconWarning.vue";
import BaseButton from "@/components/BaseButton.vue";

export default {
	components: {
		IconWarning,
		BaseButton,
	},
	methods: {
		/**
		 * Hides the bootstrap vue modal.
		 */
		hideModal() {
			this.$bvModal.hide(`tutorsListErrorModal`);
		},
	},
};
</script>
<style>
.tutorsListErrorModal {
	border-radius: 10px;
	box-shadow: 0 20px 20px 0 rgba(60, 83, 150, 0.5);
	border: none;
}
.tutorsListErrorModal__body {
	padding: 48px 48px 24px 48px;
}
.tutorsListErrorModal__content {
	margin-top: 20px;
}
.tutorsListErrorModal__header {
	justify-content: flex-start;
	display: flex;
	align-items: center;
	border: none;
}
.tutorsListErrorModal__title {
	width: 400px;
	height: 27px;
	font-family: OpenSans, Arial, sans-serif;
	font-size: 20px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: var(--c-blue);
}
.tutorsListErrorModal__closeButton {
	background-color: transparent;
	border: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	float: right;
	font-size: 2rem;
	line-height: 1;
	color: var(--c-black);
	text-shadow: 0 1px 0 #fff;
}
.tutorsListErrorModal__text {
	margin-left: 60px;
}
.tutorsListErrorModal__footer {
	background: var(--c-light-gray);
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	border: none;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}
.tutorsListErrorModal__icon {
	display: flex;
	justify-content: center;
	width: 44px;
	min-width: 44px;
	height: 44px;
	border-radius: 44px;
	background-color: var(--c-orange);
	margin-right: 16px;
}
.tutorsListErrorModal__svg {
	color: var(--c-white);
	margin-top: 10px;
}
</style>
