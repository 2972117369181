<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		viewBox="0 0 14 14"
	>
		<path
			:fill="iconColor"
			fill-rule="evenodd"
			d="M6.3 9.1h1.4v1.4H6.3V9.1zm0-5.6h1.4v4.2H6.3V3.5zM6.993 0A6.997 6.997 0 0 0 0 7c0 3.864 3.129 7 6.993 7A7.004 7.004 0 0 0 14 7c0-3.864-3.136-7-7.007-7zM7 12.6A5.598 5.598 0 0 1 1.4 7c0-3.094 2.506-5.6 5.6-5.6s5.6 2.506 5.6 5.6-2.506 5.6-5.6 5.6z"
		/>
	</svg>
</template>

<script>
import Colors from "../../utilities/Colors.js";
export default {
	props: {
		width: {
			type: Number,
			default: 14,
		},
		height: {
			type: Number,
			default: 14,
		},
		iconColor: {
			type: String,
			default: Colors.C_RED,
		},
	},
};
</script>
