import jsCookie from "js-cookie";

export default async function(to, from, next) {
	const currentUser = this.state.currentUser;

	const mustCompleteCredentialReminder = to.path.includes("credential-reminder");
	const mustCompleteProfile = to.path.includes("profile-completion");
	const iframeMustCompleteProfile = to.path.includes("iframe-profile-completion");

	const isIframe = jsCookie.get("iframe") || false;
	const isFromLMS = jsCookie.get("from_lms") || false;

	const isProfileCompleted = currentUser.password_changed;
	const isExclusivelyStudentRole = currentUser.role === "student" && currentUser.roles.length === 1;

	const route = "/";

	// if user is not a student OR they aren't an LMS student nor Clever user
	if (!isExclusivelyStudentRole || !isFromLMS) {
		// if user has no reason to be on the profile completion pages, send him back to the dashboard
		return next("/dashboard");
	// if profile completed and they are not from iframe/dont have to complete credential reminder, go to dashboard
	} else if (isProfileCompleted) {
		if (!isIframe || !mustCompleteCredentialReminder) {
			return next(route);
		}
	// if from iframe but do not have to complete profile, go to dashboard
	} else if (isIframe && !iframeMustCompleteProfile) {
		return next(route);
	//if not from iframe/clever and dont have to complete profile, go to dashboard
	} else if (!isIframe && !mustCompleteProfile) {
		return next(route);
	}
	return next();
}

