import { store as TutorsEdit } from "@/modules/ServiceTutorEdit/index.js";
import { store as ServiceTutorsSchedule } from "@/modules/ServiceSchedule/index.js";
import { store as ActionCenter } from "@/modules/ActionCenter/index.js";
import { store as ServiceAbsencesTable } from "@/modules/ServiceAbsencesTable/index.js";
import { store as ServiceSummaryDashboard } from "@/modules/ServiceSummaryDashboard/index.js";
import { store as TutorsList } from "@/modules/ServiceTutorsList/index.js";

import EssaysQueue from "./EssaysQueue.js";

export const modules = {
	TutorsList,
	TutorsEdit,
	ServiceTutorsSchedule,
	EssaysQueue,
	ActionCenter,
	ServiceAbsencesTable,
	ServiceSummaryDashboard,
};

const getDefaultState = () => ({});
export const state = getDefaultState();

export const getters = {};

export const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
};

export const actions = {};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
	modules,
};
