import { uniqBy, isEmpty } from "lodash";

import { ScheduleManagementApi } from "@/modules/TutorSchedule/index.js";
import TutorsAPI from "@/services/api/Tutors.js";

const PAGE_LIMIT = 10;

const getDefaultState = () => ({
	filteredTutors: [],
	loading: false,
	tableHeaders: [
		{
			value: "user_id",
			header: "ID",
			orderable: true,
			state: null,
		},
		{
			value: "tutor",
			header: "Tutor",
			orderable: false,
			state: null,
		},
		{
			value: "shift date",
			header: "Shift Date",
			orderable: false,
			state: null,
		},
		{ value: "shift start",
			header: "Shift Start",
			orderable: false,
			state: null },
		{
			value: "shift end",
			header: "Shift End",
			orderable: false,
			state: null,
		},
		{
			value: "start_time",
			header: "Bio Break Start",
			orderable: true,
			state: "asc",
		},
		{
			value: "end_time",
			header: "Bio Break End",
			orderable: true,
			state: null,
		},
		{
			value: "absent start",
			header: "Absent Start",
			orderable: false,
			state: null,
		},
		{
			value: "absent end",
			header: "Absent End",
			orderable: false,
			state: null,
		},
	],
	filters: {
		limit: PAGE_LIMIT,
		order_by_dir: "asc",
		order_by: "start_time",
	},
	pagination: {},
	tutors: [],
	currentPage: 1,
});

export const state = getDefaultState();

export const mutations = {
	SET_MIA_TUTOR_LIST(state, payload) {
		state.filteredTutors = payload;
	},

	SET_ALL_TUTORS(state, payload) {
		state.tutors = payload;
	},

	SET_FILTERS(state, payload) {
		state.filters = { ...state.filters, ...payload };
	},

	SET_TABLE_HEADERS(state, payload) {
		state.tableHeaders = payload;
	},

	SET_PAGINATION(state, payload) {
		state.pagination = payload;
	},

	SET_CURRENT_PAGE(state, payload) {
		state.currentPage = payload;
	},

	SET_LOADING_STATE(state, payload) {
		state.loading = payload;
	},
};

export const actions = {
	async getFilteredBioBreakSummary({ commit, state, dispatch }, pageNumber) {
		if (state.loading) {
			return;
		}

		const params = { ...state.filters, page: pageNumber };

		try {
			dispatch("setLoadingState", true);
			const bioBreakSummaryResponse = await ScheduleManagementApi.getBioBreakSummary({ params });
			dispatch("setLoadingState", false);

			commit("SET_MIA_TUTOR_LIST", bioBreakSummaryResponse.data);
			commit("SET_PAGINATION", bioBreakSummaryResponse._links);
			dispatch("setCurrentPage", pageNumber ?? 1);
		} catch (e) {
			Sentry.captureException(e);
		} finally {
			dispatch("setLoadingState", false);
		}
	},

	async getAllBioBreakTutors({ commit, state, dispatch }) {
		if (state.loading) {
			return;
		}

		try {
			const params = {
				start_date: state.filters.start_date || null,
				end_date: state.filters.end_date || null,
			};

			dispatch("setLoadingState", true);
			const bioBreakSummaryResponse = await ScheduleManagementApi.getBioBreakSummary({ params });
			const tutorListWithNames = await getMatchingTutorName(bioBreakSummaryResponse);
			dispatch("setLoadingState", false);

			commit("SET_ALL_TUTORS", tutorListWithNames);
		} catch (e) {
			Sentry.captureException(e);
		} finally {
			dispatch("setLoadingState", false);
		}
	},

	updateFilters({ commit }, params) {
		commit("SET_FILTERS", params);
	},

	getUpdatedTableHeaders({ commit, state }, { headerValue, newHeaderDirection }) {
		const newHeaders = state.tableHeaders.map((header) =>
			header.value !== headerValue ? { ...header, state: null } : { ...header, state: newHeaderDirection });

		commit("SET_TABLE_HEADERS", newHeaders);
	},

	async getPrevPageInSummary({ dispatch, state }) {
		if (isEmpty(state.pagination.prev)) {
			return;
		}
		requestNextOrPrevPage(state.pagination.prev.href, dispatch);
	},

	async getNextPageInSummary({ dispatch, state }) {
		if (isEmpty(state.pagination.next)) {
			return;
		}
		requestNextOrPrevPage(state.pagination.next.href, dispatch);
	},

	setCurrentPage({ commit }, pageNumber) {
		commit("SET_CURRENT_PAGE", pageNumber);
	},

	setLoadingState({ commit }, loadingState) {
		commit("SET_LOADING_STATE", loadingState);
	},
};

export const getters = {
	getTutorListWithoutDuplicates(state) {
		return uniqBy(state.tutors, "user_id");
	},

	getTutorName: (state) => (userID) => {
		const { name } = state.tutors.find(({ user_id }) => user_id === userID);
		return name;
	},
};

const getMatchingTutorName = async(bioBreakSummaryResponse) => {
	if (isEmpty(bioBreakSummaryResponse.data)) {
		return [];
	}

	const tutorListResponse = await TutorsAPI.list({
		showAll: 1,
		ids: uniqBy(bioBreakSummaryResponse.data, "user_id").map(({ user_id }) => user_id),
	});

	return bioBreakSummaryResponse.data.map((tutor) => {
		const matchingTutor = tutorListResponse.data.data.find(({ id }) => tutor.user_id === id);
		return { ...tutor, name: matchingTutor?.name };
	});
};

const requestNextOrPrevPage = async(urlWithSearchParams, dispatch) => {
	const queryParams = convertQueryParamsToObject(urlWithSearchParams);
	dispatch("updateFilters", { limit: queryParams.limit });
	await dispatch("getFilteredBioBreakSummary", queryParams.page);
	dispatch("setCurrentPage", queryParams.page);
};

const convertQueryParamsToObject = (urlWithSearchParams) => {
	const baseURL = process.env.MIX_SCHEDULE_MANAGEMENT_API_BASE_URI + urlWithSearchParams;
	const url = new URL(baseURL);
	const urlParams = new URLSearchParams(url.search).entries();
	const queryParams = {};

	for (const [key, value] of urlParams) {
		queryParams[key] = value;
	}

	return queryParams;
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
