import api from "@/services/api/Api.js";

export default {
	/**
	 * Create district
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	create(data) {
		return api().post(`/districts`, data);
	},

	/**
	 * Update district
	 * @param {number} districtId
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	update(districtId, data) {
		return api().post(`/districts/${districtId}`, data);
	},

	/**
	 * Get district
	 * @param {number} districtId
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	show(districtId, params) {
		return api().get(`/districts/${districtId}`, {
			params: params,
		});
	},

	/**
	 * Get all districts
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	list(params = {}) {
		return api().get(`/districts`, {
			params: params,
		});
	},

	/**
	 * Get filtered districts
	 * @param {object} params
	 * @returns {AxiosPromise}
	 */
	filter(params) {
		return api().get(`/districts.filter`, {
			params: params,
		});
	},

	/**
	 * Get aggregate district data
	 * @param {number} districtId
	 * @returns {AxiosPromise}
	 */
	aggregate(districtId) {
		return api().get(`/districts/${districtId}/aggregate`);
	},
};
