<template>
	<p
		v-jest="'error-message-container'"
		class="tw-px-6 tw-py-0 tw-h-16 tw-text-origami-red-400"
	>
		{{ errorMessage }}
	</p>
</template>

<script>
import { isNil } from "lodash";

import { getErrorText } from "@/utilities/errorHandlingHelpers.js";

export default {
	props: {
		error: {
			type: Error,
			required: true,
		},
	},
	computed: {
		errorMessage() {
			return this.getErrorMessage(this.error);
		},
	},
	methods: {
		getErrorMessage(error) {
			if (error.response.status === 409) {
				return getErrorText(error.error_description);
			}
			if (error.response.status === 422) {
				return this.getUnprocessableEntityMessage(error);
			}
			return error.response?.data?.error_description ?? "There seems to be an error.";
		},
		getUnprocessableEntityMessage(error) {
			const error_description = error.response?.data.error_description;
			const errors = error.response?.data?.errors;
			if (!isNil(error_description) && error_description instanceof Object) {
				const priorityError = Object.values(error_description).length - 1;
				return Object.values(error_description)[priorityError][0];
			}
			if (!isNil(error_description)) {
				return error_description;
			}
			if (!isNil(errors)) {
				return this.getRewordedErrorMessage(errors);
			}
			return Object.values(errors)[0][0];
		},
		getRewordedErrorMessage(errors) {
			const priorityError = Object.values(errors).length - 1;
			if (Object.values(errors)[priorityError][0].includes("Start time must be after yesterday")) {
				return "You cannot schedule a shift more than 24hrs in the past.";
			}
			if (Object.values(errors)[priorityError][0].includes("validation.gt.numeric")) {
				return "Start and end time cannot be the same.";
			}
			return Object.values(errors)[priorityError][0];
		},
	},
};
</script>
