var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 11 14",
      },
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            fill: _vm.iconColor,
            d: "M6.875 0h-5.5C.619 0 .007.63.007 1.4L0 12.6c0 .77.612 1.4 1.368 1.4h8.257c.756 0 1.375-.63 1.375-1.4V4.2L6.875 0z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            fill: _vm.iconColor,
            d: "M2.5 1.5c-.55 0-.995.495-.995 1.1L1.5 11.4c0 .605.445 1.1.995 1.1H8.5c.55 0 1-.495 1-1.1V4.8l-3-3.3h-4z",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            fill: _vm.iconSecondaryColor,
            d: "M8.25 11.2h-5.5V9.8h5.5zM8.25 8.4h-5.5V7h5.5zM6 4.85V1l3.781 3.85z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }