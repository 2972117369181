var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tw-flex tw-flex-col tw-items-center tw-justify-center" },
    [
      _vm._t("icon", function () {
        return [
          _c("OrigamiIcon", {
            attrs: { name: "warning-outlined", width: "48", height: "48" },
          }),
        ]
      }),
      _vm._v(" "),
      _vm._t("header", function () {
        return [
          _c("h1", { staticClass: "tw-mt-4 tw-font-bold" }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(_vm.$t("error_state.something_went_wrong")) +
                "\n\t\t"
            ),
          ]),
        ]
      }),
      _vm._v(" "),
      _vm._t("main", function () {
        return [
          _c("div", { staticClass: "tw-mt-1 tw-text-center" }, [
            _c("span", { staticClass: "tw-whitespace-nowrap tw-font-normal" }, [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(_vm.$t("error_state.cannot_connect")) +
                  "\n\t\t\t"
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "tw-whitespace-nowrap tw-font-normal" }, [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(_vm.$t("error_state.please_try_again")) +
                  "\n\t\t\t"
              ),
            ]),
          ]),
        ]
      }),
      _vm._v(" "),
      _vm.isShowButton
        ? _c(
            "BaseButton",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "error-button",
                  expression: "'error-button'",
                },
              ],
              staticClass: "tw-mt-4",
              attrs: { color: _vm.buttonColor, variants: _vm.buttonVariants },
              nativeOn: {
                click: function ($event) {
                  return _vm.$emit("button-clicked", $event)
                },
              },
            },
            [
              _vm._t("button-text", function () {
                return [
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.$t("error_state.try_again")) +
                      "\n\t\t"
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }