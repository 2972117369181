import { intervalToDuration } from "date-fns";

import { formatTimestampToDate } from "./dateHelpers.js";
import { downloadLinkAsFile } from "./links.js";

export const getEssayETA = (estimatedReviewTime) => {
	const start = new Date();
	const end = formatTimestampToDate(estimatedReviewTime);

	if (start > end) {
		return { days: 0, hours: 0, minutes: 0 };
	}

	const timeLeft = intervalToDuration({ start, end: end });
	if (Boolean(timeLeft.days)) {
		timeLeft.hours += timeLeft.days * 24;
		timeLeft.days = 0;
	}

	return timeLeft;
};

export const downloadEssay = async(essay) => {
	try {
		let fileData = essay.converted_file_data;
		if (fileData?.filepath !== null) {
			const response = await fetch(fileData.filepath);
			if (!response.ok) {
				fileData = essay.file_data;
			}
		} else {
			fileData = essay.file_data;
		}
		await downloadLinkAsFile(fileData.filepath, fileData.basename);

	} catch (e) {
		Sentry.captureException(e);
	}
};

export const storageUrlPrefix = (essay) => {
	return (
		"https://storage.googleapis.com/" +
		process.env.MIX_GOOGLE_ESSAYS_BUCKET +
		"/" +
		essay.student.data.id +
		"/"
	);
};
