var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "emptyState" }, [
    _c("div", { staticClass: "icon" }, [_vm._t("icon")], 2),
    _vm._v(" "),
    _vm.header.length
      ? _c("h1", [_vm._v("\n\t\t" + _vm._s(_vm.header) + "\n\t")])
      : _vm._e(),
    _vm._v(" "),
    _vm.subHeader.length
      ? _c("p", [_vm._v("\n\t\t" + _vm._s(_vm.subHeader) + "\n\t")])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "cta" }, [_vm._t("cta")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }