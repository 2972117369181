import { minutesToHours, minutesInHour } from "date-fns";

const formatTotalMinutesToHoursMins = (totalMinutes, optionalZero = true) => {
	return optionalZero && totalMinutes % minutesInHour === 0 ?
		`${minutesToHours(totalMinutes)}h 00m` :
		!optionalZero && totalMinutes % minutesInHour === 0 ?
			`${minutesToHours(totalMinutes)}h` :
			`${minutesToHours(totalMinutes)}h ${totalMinutes % minutesInHour}m`;
};

export { formatTotalMinutesToHoursMins };
