var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BreaksConfirmation", {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "p",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "meal-break-approved-header",
                    expression: "'meal-break-approved-header'",
                  },
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "meal-break-approved-header",
                    expression: "'meal-break-approved-header'",
                  },
                ],
              },
              [_vm._v("\n\t\t\tMeal break request\n\t\t")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "bodyIcon",
        fn: function () {
          return [
            _c("OrigamiIcon", {
              directives: [
                {
                  name: "data-cy",
                  rawName: "v-data-cy",
                  value: "meal-break-check-icon",
                  expression: "'meal-break-check-icon'",
                },
              ],
              staticClass: "tw-text-origami-green-400",
              attrs: { name: "check-filled", width: "32", height: "32" },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "bodyText",
        fn: function () {
          return [
            _vm.formattedBreakRequestTimes
              ? _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "meal-break-approved-details",
                        expression: "'meal-break-approved-details'",
                      },
                      {
                        name: "data-cy",
                        rawName: "v-data-cy",
                        value: "meal-break-approved-details",
                        expression: "'meal-break-approved-details'",
                      },
                    ],
                  },
                  [
                    _vm._v(
                      "\n\t\t\tYour break has been approved for " +
                        _vm._s(_vm.formattedBreakRequestTimes.startTime) +
                        " - " +
                        _vm._s(_vm.formattedBreakRequestTimes.endTime) +
                        "\n\t\t"
                    ),
                  ]
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function () {
          return [
            _c(
              "OrigamiButton",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "reset-workflow-button",
                    expression: "'reset-workflow-button'",
                  },
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "reset-workflow-button",
                    expression: "'reset-workflow-button'",
                  },
                ],
                staticClass: "tw-w-full tw-justify-center",
                attrs: { variant: "primary" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.resetWorkflow.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\tOkay, got it!\n\t\t")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }