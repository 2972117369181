var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tw-pl-5 tw-pt-6 tw-pb-0" }, [
    _c("p", { staticClass: "tw-font-bold tw-text-base" }, [
      _vm._v("\n\t\tSubjects\n\t"),
    ]),
    _vm._v(" "),
    _c(
      "p",
      {
        staticClass:
          "tw-flex tw-flex-row tw-flex-wrap tw-text-xs tw-pt-2 tw-b-0 tw-px-0",
      },
      _vm._l(_vm.subjects, function (subject, i) {
        return _c(
          "span",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "subject-items",
                expression: "'subject-items'",
              },
            ],
            key: i,
            staticClass: "tw-flex tw-mr-10 tw-pl-0 tw-pb-2 tw-pt-0 tw-w-24",
          },
          [_vm._v("\n\t\t\t" + _vm._s(subject.name) + "\n\t\t")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }