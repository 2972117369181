var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BreaksConfirmation", {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "h3",
              {
                directives: [
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "schedule-rest-break-header",
                    expression: "'schedule-rest-break-header'",
                  },
                ],
              },
              [_vm._v("\n\t\t\tSchedule rest break\n\t\t")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "bodyIcon",
        fn: function () {
          return [
            _c("OrigamiIcon", {
              directives: [
                {
                  name: "data-cy",
                  rawName: "v-data-cy",
                  value: "check-icon",
                  expression: "'check-icon'",
                },
              ],
              staticClass: "tw-text-origami-green-400",
              attrs: { name: "check-filled", width: "32", height: "32" },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "bodyText",
        fn: function () {
          return [
            _c(
              "p",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "rest-break-time",
                    expression: "'rest-break-time'",
                  },
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "rest-break-time-text",
                    expression: "'rest-break-time-text'",
                  },
                ],
              },
              [
                _vm._v("\n\t\t\tYour break has been scheduled\n\t\t\t"),
                _vm.formattedRestBreakTimes
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "rest-break-start-end",
                            expression: "'rest-break-start-end'",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\tfor " +
                            _vm._s(_vm.formattedRestBreakTimes.startTime) +
                            " to " +
                            _vm._s(_vm.formattedRestBreakTimes.endTime) +
                            "\n\t\t\t"
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function () {
          return [
            _c(
              "OrigamiButton",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "rest-break-confirmation-button",
                    expression: "'rest-break-confirmation-button'",
                  },
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "rest-break-confirmation-button",
                    expression: "'rest-break-confirmation-button'",
                  },
                ],
                staticClass: "tw-w-full tw-justify-center",
                attrs: { variant: "primary" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.confirmRestBreak.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\tOk, got it!\n\t\t")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }