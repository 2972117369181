<script setup>
import { OrigamiIconButton } from "@origami/vue2";
import { reactive, computed, watch } from "vue";

import useStore from "@/composables/useStore.js";

import SubjectFilterTag from "./SubjectFilterTag.vue";
import ServiceAnalyticsSubjectCardDetails from "./ServiceAnalyticsSubjectCardDetails.vue";
import ServiceAnalyticsSubjectTutorLoadGraph from "./ServiceAnalyticsSubjectTutorLoadGraph.vue";
import ServiceAnalyticsSubjectTutorLoadLeaderboard from "./ServiceAnalyticsSubjectTutorLoadLeaderboard.vue";

const store = useStore();
const props = defineProps({
	subject: {
		type: Object,
		required: true,
		validator: (subject) => {
			return [
				"subject",
				"tags",
				"score",
				"time_series",
			].every((key) => Object.keys(subject).includes(key));
		},
	},
	riskLevel: {
		type: String,
		required: true,
	},
});

const state = reactive({
	isExpanded: false,
	isLeaderboardLoaded: false,
	sort: "DESC",
});

const toggleExpand = () => state.isExpanded = !state.isExpanded;
const updateSorting = async() => {
	state.sort = state.sort === "DESC" ? "ASC" : "DESC";
	state.isLeaderboardLoaded = false;
	await store.dispatch("ServiceAnalytics/getTutorLoadLeaderboard", {
		subject: props.subject.subject,
		subjectId: props.subject.subject_id,
		sort: state.sort,
	});
	state.isLeaderboardLoaded = true;
};
const chevronDirection = computed(() => {
	return state.isExpanded ? "chevron-up" : "chevron-down";
});

const requirementTags = computed(() => {
	return props.subject.tags.filter((tag) =>
		tag.name === "US"
		|| tag.name === "Fingerprinted"
		|| tag.name === "VT");
});

watch(() => state.isExpanded, async(newValue) => {
	if (newValue) {
		state.isLeaderboardLoaded = false;
		await store.dispatch("ServiceAnalytics/getTutorLoadLeaderboard", {
			subject: props.subject.subject,
			subjectId: props.subject.subject_id,
			sort: state.sort,
		});
		state.isLeaderboardLoaded = true;
	}
});

const collapseChart = () => state.isExpanded = false;

defineExpose({
	collapseChart,
	state,
});
</script>

<template>
	<div
		v-jest="'subject-card'"
		:class="{
			'tw-border-origami-grey-100 tw-border-l-2 tw-border-r-2': state.isExpanded,
		}"
	>
		<section
			class="tw-py-5 tw-px-3 tw-flex tw-items-center tw-w-full tw-justify-between"
			:class="{
				'tw-bg-origami-grey-100': state.isExpanded,
			}"
		>
			<OrigamiIconButton
				v-jest="'service-analytics-subject-card-expand-button'"
				v-data-cy="'service-analytics-subject-card-expand-button'"
				class="!tw-p-0 !tw-text-origami-blue-300 tw-mr-2"
				:icon-name="chevronDirection"
				@click.native="toggleExpand"
			/>
			<div class="tw-flex tw-items-center tw-w-full">
				<SubjectFilterTag
					v-for="tag in requirementTags"
					:key="tag.name"
					v-jest="'service-analytics-subject-card-filter-tag-container'"
					v-data-cy="'service-analytics-subject-card-filter-tag-container'"
					:filter-tag-name="tag.name"
				/>
				<ServiceAnalyticsSubjectCardDetails
					v-jest="'service-analytics-subject-card-details-container'"
					v-data-cy="'service-analytics-subject-card-details-container'"
					:subject="props.subject"
					:risk-level="riskLevel"
				/>
			</div>
		</section>
		<section class="tw-flex">
			<ServiceAnalyticsSubjectTutorLoadGraph
				v-show="state.isExpanded"
				v-jest="'service-analytics-graph-container'"
				class="tw-py-9 tw-h-96 tw-w-1/2"
				:time-series="props.subject.time_series"
			/>
			<ServiceAnalyticsSubjectTutorLoadLeaderboard
				v-if="state.isExpanded"
				v-jest="'service-analytics-tutor-load-leaderboard'"
				class="tw-py-9 tw-h-96 tw-w-1/2"
				:subject="props.subject.subject"
				:is-leaderboard-loaded="state.isLeaderboardLoaded"
				@sort-tutor-load="updateSorting"
			/>
		</section>
	</div>
</template>
