<template>
	<section>
		<header
			v-if="groupList.length"
			class="w-full tw-mx-6 tw-border-b-2 tw-border-origami-grey-200 tw-py-4"
		>
			<h2 class="tw-font-bold tw-text-lg">
				Groups
				<span
					v-jest="'group-count'"
					class="tw-font-normal"
				>
					({{ groupList.length }})
				</span>
			</h2>
			<p class="tw-text-origami-black tw-mt-1">
				You can create up to 50 groups.
			</p>
		</header>

		<ul
			v-if="groupList.length"
			class="tw-overflow-y-auto"
		>
			<li
				v-for="{ id, name, students_count } in groupList"
				:key="id"
				v-jest="'group-item'"
			>
				<ListItem
					v-show="itemIdToDelete !== id"
					:group-id="id"
					:group-name="name"
					:students-count="students_count"
					@delete-item="itemIdToDelete = id"
				/>

				<ItemDelete
					v-show="itemIdToDelete === id"
					:group-id="id"
					:group-name="name"
					@cancel-delete="itemIdToDelete = null"
				/>
			</li>
		</ul>

		<NoGroupsCreated
			v-else
			class="tw-mt-12"
		/>
	</section>
</template>

<script>
import { mapState } from "vuex";

import NoGroupsCreated from "../../../../components/siteAdministrator/common/NoGroupsCreated.vue";

import ListItem from "./ListItem.vue";
import ItemDelete from "./ItemDelete.vue";

export default {
	components: {
		ListItem,
		ItemDelete,
		NoGroupsCreated,
	},
	data() {
		return {
			itemIdToDelete: null,
		};
	},
	computed: {
		...mapState(["StudentGroups", "currentUser"]),
		groupList() {
			return this.StudentGroups.groups;
		},
	},
};
</script>
