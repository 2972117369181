<template>
	<BModal
		id="service-schedule-delete-shift-modal"
		ref="service-schedule-delete-shift-modal"
		:hide-header="true"
		:no-close-on-backdrop="true"
		:no-fade="true"
		:centered="true"
		size="sm"
		@keydown.esc.native="hideModal()"
	>
		<button
			type="button"
			class="close deleteShift__closeBtn"
			@click="hideModal()"
		>
			&times;
		</button>

		<div class="deleteShift__header">
			<div class="deleteShift__headerText">
				Are you sure you want to delete the following shift?
			</div>
		</div>

		<div class="deleteShift__body">
			<div class="deleteShift__bodyHeader">
				{{ name }}
			</div>
			<div class="deleteShift__bodySubheader">
				{{ day }}
			</div>
			<div class="deleteShift__bodySubheader">
				{{ shiftTime }}
			</div>
			<div class="deleteShift__bodySubheader">
				{{ duration }}
			</div>
		</div>
		<template slot="modal-footer">
			<div class="deleteShift__footer tw-w-full">
				<BaseButton
					v-data-cy="'delete-shift-yes-btn'"
					class="deleteShift__btn deleteShift__btn--marginRight"
					type="CANCEL"
					@click.native="deleteShift()"
				>
					<template
						slot="text"
					>
						Yes
					</template>
				</BaseButton>
				<BaseButton
					class="deleteShift__btn"
					type="CANCEL"
					@click.native="hideModal()"
				>
					<template
						slot="text"
					>
						Close
					</template>
				</BaseButton>
			</div>
		</template>
	</BModal>
</template>
<script>
import { format, differenceInMinutes } from "date-fns";

import { getErrorText } from "@/utilities/errorHandlingHelpers.js";
import BaseButton from "@/components/BaseButton.vue";

export default {
	components: {
		BaseButton,
	},
	props: {
		shift: {
			type: Object,
			required: true,
			validator(value) {
				return (
					value.hasOwnProperty("start") &&
					value.hasOwnProperty("end") &&
					value.hasOwnProperty("name") &&
					value.hasOwnProperty("id")
				);
			},
		},
	},
	computed: {
		/**
		 * @returns {String}
		 */
		name() {
			return this.shift.name;
		},
		/**
		 * @return {String}
		 */
		day() {
			return this.shift.start ? format(this.shift.start, "EEEE, MMM do") : "";
		},
		/**
		 * @return {String}
		 */
		startTime() {
			return this.shift.start ? format(this.shift.start, "HH:mm aaa") : "";
		},
		/**
		 * @return {String}
		 */
		endTime() {
			return this.shift.end ? format(this.shift.end, "HH:mm aaa") : "";
		},
		/**
		 * @return {String}
		 */
		shiftTime() {
			return this.startTime && this.endTime ? `${this.startTime}-${this.endTime}` : "";
		},
		/**
		 * @return {String}
		 */
		duration() {
			if (!this.shift.start || !this.shift.end) {
				return "";
			}

			const minsDifference = differenceInMinutes(this.shift.end, this.shift.start);
			const hours = Math.floor(Math.abs(minsDifference) / 60);
			const hrStr = hours === 1 ? "hr" : "hrs";
			const minutes = Math.abs(minsDifference) % 60;
			const minStr = minutes === 1 ? "min" : "mins";

			return `${hours}${hrStr} ${minutes}${minStr} shift`;
		},
	},
	methods: {
		async deleteShift() {
			try {
				await this.$store.dispatch("PlatformManager/ServiceTutorsSchedule/deleteShift",
					{ id: this.shift.id });
				this.$emit("shiftDeleted");
				this.$store.dispatch("Snackbar/showSnackbar", {
					snackbarType: "info",
					snackbarText: "Shift is successfully removed",
				});
				this.hideModal();
			} catch (e) {
				this.$store.dispatch("Snackbar/showSnackbar", {
					snackbarText: getErrorText("deleteing the shift"),
					snackbarType: "error",
				});
			}
		},
		hideModal() {
			this.$bvModal.hide("service-schedule-delete-shift-modal");
		},
	},
};
</script>
<style scoped>
.deleteShift__body {
	margin-top: 1.25rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.deleteShift__headerText {
	margin-top: 1.5rem;
	font-size: 1rem;
	font-weight: bold;
	color: var(--c-black);
}
.deleteShift__bodyHeader {
	font-size: 1rem;
	font-weight: bold;
	color: var(--c-black);
}
.deleteShift__bodySubheader {
	font-size: 0.75rem;
	font-weight: normal;
	color: var(--c-black);
}
.deleteShift__footer {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.modal-footer {
	border-top: none;
	padding-bottom: 1rem;
}
.deleteShift__btn {
	height: 1.9rem !important;
	width: 9.8rem;
	margin-bottom: 0.5rem;
}
.deleteShift__btn--marginRight {
	margin-right: 5px;
}
</style>
