var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "snackbar",
      class: _vm.notificationClass,
      attrs: { id: _vm.name },
    },
    [
      _c(
        "div",
        { staticClass: "snackbar__iconContainer", class: _vm.iconContainer },
        [_vm._t("icon")],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "snackbar-textcontent",
              expression: "'snackbar-textcontent'",
            },
          ],
          staticClass: "snackbar__textContent",
          class: _vm.textContentClass,
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.textcontent) + "\n\t")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "snackbar-prompt",
              expression: "'snackbar-prompt'",
            },
          ],
          staticClass: "snackbar__prompt",
          class: _vm.promptClass,
          on: { click: _vm.hideSnackbar },
        },
        [_vm._t("prompt")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }