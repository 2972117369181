var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("p", { staticClass: "assessment__text assessment__text--bold" }, [
      _vm._v(
        "\n\t\t" +
          _vm._s(
            _vm.$t("right_sidebar.assessment.tag_the_question", {
              name: _vm.session.studentName,
            })
          ) +
          "\n\t"
      ),
    ]),
    _vm._v(" "),
    _c(
      "p",
      {
        staticClass:
          "assessment__text assessment__text--blue assessment__text--question",
      },
      [
        _vm._v(
          "\n\t\t" +
            _vm._s(_vm.$t("right_sidebar.assessment.tag_questions_by")) +
            "\n\t"
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("div", { staticClass: "assessment__nonAcademicTitle" }, [
          _vm._v(
            "\n\t\t\t" +
              _vm._s(
                _vm.$t("right_sidebar.assessment.is_session_non_academic")
              ) +
              "\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("BaseCheckbox", {
          staticClass: "assessment__nonAcademicCheckbox",
          attrs: {
            value: _vm.isNonAcademic,
            "checkbox-id": 1,
            "checkbox-value": "nonAcademic",
          },
          on: {
            input: function ($event) {
              return _vm.$emit("non-academic-check", $event)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "text",
              fn: function () {
                return [
                  _c(
                    "span",
                    {
                      staticClass:
                        "assessment__nonAcademicText assessment__nonAcademicText--bold",
                    },
                    [_vm._v(_vm._s(_vm.$t("right_sidebar.assessment.it_is")))]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.isNonAcademic
      ? _c("div", { staticClass: "assessment__nonAcademic" }, [
          _c("div", { staticClass: "assessment__info--bold" }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(_vm.$t("right_sidebar.assessment.important")) +
                "\n\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "assessment__info--normal" }, [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(_vm.$t("right_sidebar.assessment.select_non_academic")) +
                "\n\t\t\t"
            ),
            _c("span", { staticClass: "assessment__info--bold" }, [
              _vm._v(_vm._s(_vm.$t("right_sidebar.assessment.only_if"))),
            ]),
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "assessment__list" }, [
            _c("li", { staticClass: "assessment__info--normal" }, [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(_vm.$t("right_sidebar.assessment.you_dont_know")) +
                  "\n\t\t\t"
              ),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "assessment__info--normal" }, [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(
                    _vm.$t(
                      "right_sidebar.assessment.content_not_school_related"
                    )
                  ) +
                  "\n\t\t\t"
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }