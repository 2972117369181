<template>
	<div
		v-show="breakType !== null"
		v-jest="'break-chat-placeholder'"
		class="tw-flex tw-justify-center tw-flex-col tw-items-center tw-h-full"
	>
		<OrigamiIcon
			v-data-cy="'break-chat-icon'"
			v-jest="'break-chat-icon'"
			width="64"
			height="64"
			:name="breakType?.icon"
			class="tw-mb-4"
		/>
		<p
			v-data-cy="'break-chat-body-text'"
			class="tw-text-center tw-text-md tw-text-origami-grey-300 tw-font-semibold"
		>
			You are currently on a <span v-jest="'break-chat-label'">{{ breakType?.label }}</span>
		</p>
		<p class="tw-text-center tw-text-origami-grey-300">
			You will not receive any students during this time.
		</p>
	</div>
</template>

<script setup>
import { ref, computed, watch, onUnmounted } from "vue";
import { OrigamiIcon } from "@origami/vue2";

import useStore from "@/composables/useStore.js";

const store = useStore();
const breakType = ref(null);

const isBioBreakInProgress = computed(() => {
	return store.getters["BreaksManagementWidget/isBioBreakInProgress"];
});
const isRestBreakInProgress = computed(() => {
	return store.getters["BreaksManagementWidget/isRestBreakInProgress"];
});
const isMealBreakInProgress = computed(() => {
	return store.getters["BreaksManagementWidget/isMealBreakInProgress"];
});

const updateBreak = () => {
	if (isBioBreakInProgress.value) {
		breakType.value = {
			icon: "bio-break",
			label: "bio break",
		};
	} else if (isRestBreakInProgress.value) {
		breakType.value = {
			icon: "break",
			label: "rest break",
		};
	} else if (isMealBreakInProgress.value) {
		breakType.value = {
			icon: "meal-break",
			label: "meal break",
		};
	}
};

watch([isBioBreakInProgress, isRestBreakInProgress, isMealBreakInProgress], updateBreak, { immediate: true });

onUnmounted(() => breakType.value = null);
</script>
