<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		viewBox="0 0 26 20"
	>
		<g
			fill="none"
			fill-rule="evenodd"
		>
			<g :fill="iconColor">
				<path
					d="M1023 145h-1.822l-1.822-3.335c-.252-.45-.682-1.201-.912-1.822h-.02c.042.77.062 1.742.062 1.942V145H1017v-7h1.822l1.738 3.235c.292.55.744 1.342.994 1.972h.022c-.042-.66-.064-1.602-.064-2.012V138H1023v7zm-7-5.788h-3.464v1.652h3.088v1.212h-3.088v1.722H1016V145h-5v-7h5v1.212zm10-5.212h-18c-2.21 0-4 1.788-4 3.996v7.832c0 2.208 1.79 3.996 4 3.996h1.401v3.177c0 .55.44.999 1 .999.169 0 .349-.05.509-.14l6.811-4.036H1026c2.21 0 4-1.788 4-3.996v-7.832c0-2.208-1.79-3.996-4-3.996z"
					transform="translate(-1004 -134)"
				/>
			</g>
		</g>
	</svg>
</template>

<script>
import Colors from "../../utilities/Colors.js";
export default {
	props: {
		width: {
			type: [Number, String],
			default: 26,
		},
		height: {
			type: [Number, String],
			default: 20,
		},
		iconColor: {
			type: String,
			default: Colors.C_LANGUAGE_BUBBLE_RED,
		},
	},
};
</script>
