import jsCookie from "js-cookie";

// Only for student
export default async function(to, from, next) {
	const isIframe = jsCookie.get("iframe") || false;
	const isFromLMS = jsCookie.get("from_lms") || false;
	const currentUser = this.state.currentUser;
	const isExclusivelyStudentRole = currentUser.role === "student" && currentUser.roles.length === 1;

	if (!isExclusivelyStudentRole) {
		return next();
	}

	if (isFromLMS) {
		if (isIframe) {
			if (currentUser.password_changed) {
				return next("/credential-reminder");
			} else {
				return next("/iframe-profile-completion");
			}
		} else {
			if (!currentUser.password_changed) {
				return next("/profile-completion");
			}
		}
	}
	return next();
}

