var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("OrigamiDialog", {
    attrs: {
      title: "Do you want to resolve this report?",
      description:
        "This will be marked as resolved and will be removed from the list.",
      "primary-button-text": "Yes, resolve",
      "secondary-button-text": "Cancel",
      "is-open": _vm.isOpen,
      "is-loading": _vm.isResolving,
    },
    on: { cancel: _vm.closeModal, confirm: _vm.resolveActionItem },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }