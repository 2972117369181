<template>
	<div
		ref="navWrapper"
		v-jest="'nav-wrapper'"
		tabindex="-1"
		:class="{
			'tw-mb-[80px] md:tw-mb-0': featureFlagSidebarRedesignIsEnabled,
		}"
		@keydown="isMobileMenuToggled && handleFocusMobile($event)"
	>
		<SkipToMainContent v-jest="'skip-to-main'" />
		<Navbar
			v-if="isShowingNonStudentNavigation"
			v-jest="'navbar'"
			:view-as-links="viewAsLinks"
			:normal-links="normalLinks"
			:user-links="userLinks"
			:languages="languages"
		>
			<template #appendAfterNavLinks>
				<PunchClockWrapper
					v-if="isUserTutor"
					v-jest="'punch-clock-wrapper'"
				/>
			</template>
		</Navbar>
		<StudentNavbar
			v-if="isShowingStudentNavigation && !isMobileWebviewOpened"
			ref="topNavbar"
			v-jest="'student-navbar'"
			:is-sidebar-toggled="isSidebarToggled"
			:is-mobile-menu-toggled="isMobileMenuToggled"
			@toggle-sidebar="handleSidebarToggle"
			@toggle-mobile-menu="handleMobileMenuToggle"
			@close-mobile-menu="closeMobileMenu"
		/>

		<div
			v-jest="'navigation-container'"
			class="navigation-container tw-flex"
			:class="{ 'navigation-container--fixed-navbar tw-relative': featureFlagSidebarRedesignIsEnabled }"
		>
			<StudentSidebarWrapper
				v-if="isShowingStudentNavigation"
				ref="studentSidebarWrapper"
				:is-mobile-menu-toggled="isMobileMenuToggled"
				:is-mobile-webview-opened="isMobileWebviewOpened"
				:is-sidebar-toggled="isSidebarToggled"
				@open-drawer="isSidebarNewOpen = true"
				@close-drawer="isSidebarNewOpen = false"
				@reset-mobile-menu="handleResetMobileMenu"
			/>

			<div
				id="main-content"
				class="navigation-content tw-overflow-hidden"
				:class="{
					'tw-hidden': isMobileMenuToggled && !isShowingDesktopView,
					'md:tw-ml-[80px] tw-mt-[88px]': featureFlagSidebarRedesignIsEnabled,
					'md:tw-ml-[346px]': isSidebarNewOpen,
				}"
			>
				<slot />
			</div>
		</div>
		<Footer
			v-if="isShowingStudentNavigation && !route.meta?.noFooter && !isMobileWebviewOpened"
			v-jest="'footer'"
		/>
	</div>
</template>

<script setup>
import { ref, watchEffect, watch, computed } from "vue";
import { useRoute } from "vue-router/composables";

import useFeatureFlag from "@/composables/useFeatureFlag.js";
import useResponsiveness from "@/composables/useResponsiveness.js";
import SkipToMainContent from "@/components/patterns/SkipToMainContent.vue";
import useStore from "@/composables/useStore.js";
import { PunchClockWrapper } from "@/modules/PunchClock/index.js";
import Navbar from "@/modules/Navigation/components/Navbar.vue";
import StudentNavbar from "@/modules/StudentNavigation/components/NavbarWrapper.vue";
import StudentSidebarWrapper from "@/modules/StudentNavigation/components/SidebarWrapper.vue";
import Footer from "@/modules/StudentNavigation/components/Footer.vue";

defineProps({
	viewAsLinks: {
		type: Array,
		required: true,
	},
	normalLinks: {
		type: Array,
		required: true,
	},
	userLinks: {
		type: Array,
		required: true,
	},
	languages: {
		type: Array,
		required: true,
	},
});

const store = useStore();
const route = useRoute();
const { isShowingDesktopView } = useResponsiveness();

const isSidebarToggled = ref(false);
const isMobileMenuToggled = ref(false);
const navWrapper = ref(null);
const studentSidebarWrapper = ref(null);
const topNavbar = ref(null);
const featureFlagSidebarRedesignIsEnabled = useFeatureFlag("GROW-1308-sidebar-secondary-tab-redesign");

const isSidebarNewOpen = ref(false);

const dashboardRouteName = "studentDashboard";
const isMobileWebviewOpened = ref(localStorage.getItem("mobileWebviewOpened") === "true");

const userRole = computed(() => store.state.currentUser?.role);
const isUserTutor = computed(() => userRole.value === "tutor");
const isShowingStudentNavigation = computed(() => userRole.value === "student" && store.state.showNavbar);
const isShowingNonStudentNavigation = computed(() => userRole.value !== "student" && store.state.showNavbar);

watchEffect(() => {
	if (isShowingDesktopView.value) {
		document.body.classList.remove("origami-disabled-scroll");
		isMobileMenuToggled.value = false;
	}
});
watch(() => route.path, () => {
	isSidebarToggled.value = route?.name !== dashboardRouteName;
}, { immediate: true });

const handleSidebarToggle = () => {
	isSidebarToggled.value = !isSidebarToggled.value;
};
const handleMobileMenuToggle = () => {
	isMobileMenuToggled.value = !isMobileMenuToggled.value;
	document.body.classList.toggle("origami-disabled-scroll");
};

const handleResetMobileMenu = () => {
	document.body.classList.remove("origami-disabled-scroll");
	isMobileMenuToggled.value = false;
};

const closeMobileMenu = () => {
	handleResetMobileMenu();
	topNavbar.value.mobileMenuButton.focus();
};

const handleFocusMobile = (event) => {
	if (event.key === "Escape") {
		closeMobileMenu();
	}

	if (event.key !== "Tab") {
		return;
	}

	const allDropdownLinks = studentSidebarWrapper.value.mobileNav.navLinksDropdown.$el.querySelectorAll("a");
	const firstElement = topNavbar.value.mobileMenuButton;
	const lastElement = allDropdownLinks[allDropdownLinks.length - 1];

	if (event.shiftKey && document.activeElement === firstElement) {
		lastElement.focus();
		event.preventDefault();
		return;
	}

	if (!event.shiftKey && document.activeElement === lastElement) {
		firstElement.focus();
		event.preventDefault();
	}
};

defineExpose({ isSidebarToggled, isMobileMenuToggled, topNavbar, studentSidebarWrapper });
</script>

<style scoped>
.navigation-container {
	min-height: var(--height-screen-without-header);
}
.navigation-container--fixed-navbar {
	min-height: 100vh;
}

.navigation-content {
	@apply tw-flex-grow;
	transition: all 0.3s ease;
}
</style>
