var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tw-flex-shrink-0 tw-w-36" },
    [
      _c("BaseDropdown", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "action-dropdown",
            expression: "'action-dropdown'",
          },
        ],
        staticClass: "action__dropdown",
        attrs: {
          "v-model": _vm.actionItemTypeId,
          items: _vm.filteredActions,
          "close-on-select": "",
        },
        on: { select: _vm.handleAction },
        scopedSlots: _vm._u([
          {
            key: "filters",
            fn: function () {
              return [
                _vm.isActionPending
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "tw-flex tw-justify-center tw-items-center",
                      },
                      [_c("LoadingRectangles")],
                      1
                    )
                  : _c(
                      "span",
                      {
                        staticClass: "tw-flex tw-justify-start tw-items-center",
                      },
                      [
                        _vm.actionItem.picked_up_at &&
                        _vm.actionItem.action_type_id
                          ? _c("OrigamiIcon", {
                              staticClass: "tw-mr-3.5",
                              class:
                                _vm.actions[
                                  _vm.getActionTypeIndex(_vm.actionItemTypeId)
                                ].color,
                              attrs: {
                                name: _vm.actions[
                                  _vm.getActionTypeIndex(_vm.actionItemTypeId)
                                ].icon,
                                width: "20",
                                height: "20",
                              },
                            })
                          : _vm._e(),
                        _vm._v(
                          "\n\t\t\t\t" + _vm._s(_vm.actionName) + "\n\t\t\t"
                        ),
                      ],
                      1
                    ),
              ]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function ({ item }) {
              return [
                _c(
                  "div",
                  { staticClass: "tw-flex tw-justify-between tw-items-center" },
                  [
                    _c(
                      "label",
                      {
                        staticClass:
                          "tw-flex tw-items-center tw-flex-grow tw-text-xs tw--mb-0 tw-px-2 tw-truncate tw-cursor-pointer",
                        attrs: {
                          for: `actionItem-${_vm.actionItemId}-${item.name}`,
                        },
                      },
                      [
                        _c("OrigamiIcon", {
                          staticClass: "tw-mr-3.5",
                          class: item.color,
                          attrs: { name: item.icon, width: "20", height: "20" },
                        }),
                        _vm._v(
                          "\n\t\t\t\t\t" + _vm._s(item.name) + "\n\t\t\t\t"
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "action",
                          expression: "'action'",
                        },
                      ],
                      staticClass: "sr-only tw-cursor-pointer",
                      attrs: {
                        id: `actionItem-${_vm.actionItemId}-${item.name}`,
                        value: item.id,
                        type: "button",
                      },
                    }),
                    _vm._v(" "),
                    _vm.isItemSelected(item.id)
                      ? _c("IconCheckMark", {
                          staticClass:
                            "tw-text-blue-regular tw-mr-2 tw-cursor-pointer",
                          attrs: {
                            width: 16,
                            height: 16,
                            "icon-color": "currentColor",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }