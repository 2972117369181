<template>
	<div class="tw-flex tw-flex-col">
		<div class="tw-flex tw-flex-row tw-items-center">
			<h3
				v-jest="'title-text'"
				class="tw-font-bold tw-text-md"
			>
				{{ $t(`challenges.translations.${challenge.title_key}`) }}
			</h3>
			<InfoToolTip
				:options="toolTipOptions"
				class="tw--mt-1"
				:aria-describedby="tooltipId"
				tabindex="0"
			/>
		</div>
		<p
			v-if="!challengeCompleted"
			v-jest="'time-text'"
			class="tw-text-sm tw-mb-2"
		>
			{{ $t(`challenges.${timingTextKey}`, {
				days: remainingDays,
				days_plural: remainingDays > 1 ? "s" : "",
			})
			}}
		</p>
		<p
			v-else
			class="tw-mb-2"
		>
			<span v-jest="'completed-text'">{{ $t("challenges.completed") }}</span>
			<span
				v-jest="'restart-text'"
				class="tw-text-sm tw-text-origami-grey-300"
			>{{ $t("goals.restart", { days: remainingDays }) }}</span>
		</p>
		<ul class="tw-pl-2">
			<li
				v-for="item in goals"
				:key="item.type"
				class="tw-flex tw-flex-row tw-mb-1"
			>
				<OrigamiIcon
					v-if="item.completed >= item.target"
					v-jest="`${item.type}_checkmark`"
					name="check-filled"
					class="tw-text-origami-blue-300 tw-pr-1"
				/>
				<p
					v-jest="`${item.type}_text`"
					class="tw-truncate"
					:class="{
						'tw-text-origami-blue-300': item.completed >= item.target,
						'tw-pl-6': item.completed < item.target,
					}"
				>
					<span class="tw-font-bold">{{ $t("goals.of", item) }}</span> {{ text[item.type] }}
				</p>
			</li>
		</ul>
	</div>
</template>

<script>
import { OrigamiIcon } from "@origami/vue2";
import { intervalToDuration } from "date-fns";

import InfoToolTip from "../../../components/patterns/InfoToolTip.vue";
import { checkIsObjectKeysPresent } from "../../../utilities/propValidationHelpers.js";

export default {
	components: {
		OrigamiIcon,
		InfoToolTip,
	},
	props: {
		challengeCompleted: {
			type: Boolean,
			required: true,
		},
		goals: {
			type: Array,
			required: true,
			validator: (goals) => {
				return goals.every((goal) => {
					return checkIsObjectKeysPresent(goal, ["id", "target", "completed", "type"]);
				});
			},
		},
		challenge: {
			type: Object,
			required: true,
			validator: (challenge) => {
				return checkIsObjectKeysPresent(challenge, ["id", "end_date", "description_key", "title_key"]);
			},
		},
	},
	data() {
		return {
			tooltipId: `challenge-tooltip-${Math.random()}`,
			text: {
				ChannelScheduled: this.$t("goals.tutoring_sessions"),
				FeedbackCreated: this.$t("goals.feedback_submissions"),
				ReviewRequestSubmitted: this.$t("goals.review_requested"),
				ProblemCompleted: this.$t("goals.missions_completed"),
			},
			daysLeft: 0,
			toolTipOptions: {
				allowHTML: true,
				arrow: true,
				content: `<span id="${this.tooltipId}" class="tw-text-sm tw-w-96">${this.$t(`challenges.translations.${this.challenge.description_key}`)}</span>`,
				hideOnClick: false,
				maxWidth: 305,
				placement: "right",
				popperOptions: { strategy: "fixed" },
				trigger: "mouseenter focus",
			},
		};
	},
	computed: {
		remainingDays() {
			const endTime = new Date(this.challenge.end_date / 1000);
			const timeLeft = intervalToDuration({
				start: new Date(),
				end: endTime,
			});
			return timeLeft.days;
		},
		timingTextKey() {
			let text = "";
			if (this.challengeCompleted) {
				text = "completed";
			} else {
				return "timing_text";
			}
			return text;
		},
	},
};
</script>
