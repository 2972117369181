<template>
	<BreaksConfirmation>
		<template #header>
			<p
				v-jest="'meal-break-approved-header'"
				v-data-cy="'meal-break-approved-header'"
			>
				Meal break request
			</p>
		</template>
		<template #bodyIcon>
			<OrigamiIcon
				v-data-cy="'meal-break-check-icon'"
				name="check-filled"
				width="32"
				height="32"
				class="tw-text-origami-green-400"
			/>
		</template>
		<template #bodyText>
			<p
				v-if="formattedBreakRequestTimes"
				v-jest="'meal-break-approved-details'"
				v-data-cy="'meal-break-approved-details'"
			>
				Your break has been approved for {{ formattedBreakRequestTimes.startTime }} - {{ formattedBreakRequestTimes.endTime }}
			</p>
		</template>
		<template #buttons>
			<OrigamiButton
				v-jest="'reset-workflow-button'"
				v-data-cy="'reset-workflow-button'"
				variant="primary"
				class="tw-w-full tw-justify-center"
				@click.native="resetWorkflow"
			>
				Okay, got it!
			</OrigamiButton>
		</template>
	</BreaksConfirmation>
</template>

<script>
import { OrigamiButton, OrigamiIcon } from "@origami/vue2";

import { formatDateToHM, formatSecondsTimestampToDate } from "@/utilities/dateHelpers.js";

import BreaksConfirmation from "../templates/BreaksConfirmation.vue";

export default {
	components: {
		OrigamiButton,
		BreaksConfirmation,
		OrigamiIcon,
	},
	computed: {
		formattedBreakRequestTimes() {
			const { approvedBreakRequest } = this.$store.state.TutorSchedule;
			return approvedBreakRequest ? {
				startTime: formatDateToHM(formatSecondsTimestampToDate(approvedBreakRequest.startTime)),
				endTime: formatDateToHM(formatSecondsTimestampToDate(approvedBreakRequest.endTime)),
			} : null;
		},
	},
	methods: {
		async resetWorkflow() {
			await this.$store.dispatch("TutorSchedule/getCurrentShiftAndState");
			this.$store.dispatch("BreaksManagementWidget/resetWorkflow");
			this.$store.dispatch("TutorSchedule/resetApprovedBreakRequest");
		},
	},
};
</script>
