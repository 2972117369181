var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.timeText || _vm.promptText
        ? _c(
            "BreaksNotification",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "rest-break-end-prompt-notification",
                  expression: "'rest-break-end-prompt-notification'",
                },
              ],
              staticClass: "tw-mb-4",
              attrs: { "notification-type": _vm.notificationType },
            },
            [
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "rest-break-end-prompt-time",
                      expression: "'rest-break-end-prompt-time'",
                    },
                  ],
                  staticClass: "tw-font-bold tw-text-sm",
                },
                [_vm._v("\n\t\t\t" + _vm._s(_vm.timeText) + "\n\t\t")]
              ),
              _vm._v(" "),
              _vm.promptText
                ? _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "rest-break-end-prompt-text",
                          expression: "'rest-break-end-prompt-text'",
                        },
                      ],
                      staticClass: "tw-text-sm",
                    },
                    [_vm._v("\n\t\t\t" + _vm._s(_vm.promptText) + "\n\t\t")]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isOnRestBreak || (_vm.isLateFromRestBreak && !_vm.isLatePastThreshold)
        ? _c(
            "OrigamiButton",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "rest-break-end-button",
                  expression: "'rest-break-end-button'",
                },
              ],
              staticClass: "tw-w-full tw-justify-center",
              attrs: {
                variant: "primary",
                "is-loading":
                  _vm.$store.state.BreaksManagementWidget.isRequestLoading,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.endRestBreak.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n\t\tEnd break\n\t")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }