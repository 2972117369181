<template>
	<BreakDetails v-if="hasMealBreak && isOnMealBreakWorkflow">
		<template #breakDetails>
			<p
				v-if="formattedBreakTimes"
				v-jest="'meal-break-details'"
				v-data-cy="'meal-break-details'"
				class="tw-mt-4"
			>
				Meal break at {{ formattedBreakTimes.startTime }} to {{ formattedBreakTimes.endTime }}
			</p>
		</template>
	</BreakDetails>
</template>

<script>
import { formatIsoDateToHM } from "@/utilities/dateHelpers.js";

import BreakDetails from "../templates/BreakDetails.vue";

export default {
	components: {
		BreakDetails,
	},
	computed: {
		hasMealBreak() {
			return this.$store.getters["TutorSchedule/hasMealBreak"];
		},
		isOnMealBreakWorkflow() {
			return this.$store.getters["BreaksManagementWidget/isOnMealBreakWorkflow"];
		},
		mealBreakSegment() {
			return this.$store.getters["TutorSchedule/getCurrentMealBreak"] ||
				this.$store.getters["TutorSchedule/getUpcomingMealBreak"] ||
				this.$store.getters["TutorSchedule/getLateMealBreak"];
		},
		formattedBreakTimes() {
			return this.mealBreakSegment ? {
				startTime: formatIsoDateToHM(this.mealBreakSegment.start_time),
				endTime: formatIsoDateToHM(this.mealBreakSegment.end_time),
			} : null;
		},
	},
};
</script>
