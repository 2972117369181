var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "14",
        height: "14",
        viewBox: "0 0 14 14",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M8.81358 2.3335L7.77759 3.43016L11.1427 7.00016L7.77759 10.5702L8.81358 11.6668L13.222 7.00016L8.81358 2.3335Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c(
        "mask",
        {
          attrs: {
            id: "mask0",
            "mask-type": "alpha",
            maskUnits: "userSpaceOnUse",
            x: "7",
            y: "2",
            width: "7",
            height: "10",
          },
        },
        [
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M8.81358 2.3335L7.77759 3.43016L11.1427 7.00016L7.77759 10.5702L8.81358 11.6668L13.222 7.00016L8.81358 2.3335Z",
              fill: "white",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("g", { attrs: { mask: "url(#mask0)" } }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M3.36924 2.3335L2.33325 3.43016L5.69837 7.00016L2.33325 10.5702L3.36924 11.6668L7.7777 7.00016L3.36924 2.3335Z",
          fill: "currentColor",
        },
      }),
      _vm._v(" "),
      _c(
        "mask",
        {
          attrs: {
            id: "mask1",
            "mask-type": "alpha",
            maskUnits: "userSpaceOnUse",
            x: "2",
            y: "2",
            width: "6",
            height: "10",
          },
        },
        [
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M3.36924 2.3335L2.33325 3.43016L5.69837 7.00016L2.33325 10.5702L3.36924 11.6668L7.7777 7.00016L3.36924 2.3335Z",
              fill: "white",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("g", { attrs: { mask: "url(#mask1)" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }