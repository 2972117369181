import { flatten, find } from "lodash";

const filterStoreUtils = (config) => {

	const optionsWithPrepend =
		config
			.filter(({ prependOption }) => prependOption)
			.reduce((agg, { key, prependOption }) => ({ ...agg, [key]: prependOption }), {});

	const getSelectedOptions = async(key, selections) => {
		const { apiOptions } = find(config, { key });

		const isSelectionAnArray = Array.isArray(selections);

		const selectionsArray = isSelectionAnArray ? selections : [selections];

		const apiCallList = selectionsArray.map((id) => apiOptions(id));

		return isSelectionAnArray
			? flatten(await Promise.all(apiCallList))
			: await apiCallList[0];
	};

	const ALL_OPTIONS_SELECTED = -1;

	const prependAllOption = (key, data) =>
		Object.keys(optionsWithPrepend).includes(key)
			? [
				{ id: ALL_OPTIONS_SELECTED, name: optionsWithPrepend[key] },
				...data,
			]
			: data;

	const loadInitialOptions = async() => {
		const initialCallList = config
			.filter(({ options, apiOptions, parentKey }) =>
				(options || apiOptions) && !parentKey,
			)
			.map(({ key, options, apiOptions }) =>
				apiOptions
					? apiOptions().then((data) => ({ [key]: prependAllOption(key, data) }))
					: Promise.resolve(options).then((data) => ({ [key]: prependAllOption(key, data) })),
			);

		return (await Promise.all(initialCallList))
			.reduce((agg, item) => ({
				...agg,
				...item,
			}), {});
	};

	const filterOptions = config.reduce((agg, { key }) =>
		({ ...agg, [key]: [] })
	, {});

	const filterSelections =
		config.reduce((agg, { key, defaultSelection, type }) =>
			({
				...agg,
				[key]: defaultSelection ?? (type === "radio" ? null : []),
			})
		, {});

	const radioKeys =
		config
			.filter(({ type }) => type === "radio")
			.map(({ key }) => key);

	return { getSelectedOptions, filterSelections, filterOptions, loadInitialOptions, radioKeys };
};

export default filterStoreUtils;
