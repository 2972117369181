var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M12.9961 22C12.6026 22 12.2615 21.7154 12.1758 21.3134L9.05161 6.64752L7.17562 14.292C7.0806 14.68 6.7456 14.951 6.36147 14.951H2.84054C2.37601 14.951 2 14.5592 2 14.0743C2 13.5906 2.37601 13.1984 2.84054 13.1984H5.70934L8.29553 2.66032C8.39136 2.26727 8.73245 1.98223 9.12267 2.00086C9.51167 2.00764 9.84505 2.29184 9.92991 2.68742L12.9526 16.8743L14.4948 8.81181C14.5655 8.44248 14.8558 8.16125 15.214 8.11424C15.5741 8.06553 15.9193 8.2629 16.0764 8.60301L17.9289 12.6156H21.1595C21.6236 12.6156 22 13.0082 22 13.4924C22 13.9769 21.6236 14.3687 21.1595 14.3687H17.401C17.0782 14.3687 16.7838 14.1759 16.6437 13.8727L15.6521 11.7245L13.8208 21.2944C13.7432 21.7014 13.4033 21.9949 13.0054 22H12.9961Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }