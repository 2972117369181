var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "item",
          expression: "'item'",
        },
      ],
      staticClass: "item",
      class: {
        "item--greyBackground": _vm.isGreyedOut,
        "item--blink": _vm.isBlinking,
      },
    },
    [
      _c(
        "div",
        { staticClass: "tw-flex tw-items-center tw-h-full" },
        [
          _c(_vm.iconComponent, {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "activity-icon",
                expression: "'activity-icon'",
              },
            ],
            tag: "component",
            staticClass: "item__icon hover:tw-cursor-pointer",
            attrs: {
              "data-cy": "go-to-activity-icon",
              "icon-color": _vm.iconColor,
              height: 17,
              width: 14,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.goToActivity()
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "item__content tw-flex tw-flex-row tw-items-start tw-justify-between tw-grow-[10]",
        },
        [
          _c("div", { staticClass: "item__sessionTitleContainer tw-pl-2" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "left-top-text",
                    expression: "'left-top-text'",
                  },
                ],
                staticClass: "item__name tw-max-w-[10rem]",
              },
              [_vm._v("\n\t\t\t\t" + _vm._s(_vm.leftTopText) + "\n\t\t\t")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "left-bottom-text",
                    expression: "'left-bottom-text'",
                  },
                ],
                staticClass:
                  "item__school tw-text-origami-grey-300 tw-max-w-[10rem]",
                class: [_vm.isDemo ? "item__greenTextColor" : "item__school"],
              },
              [_vm._v("\n\t\t\t\t" + _vm._s(_vm.leftBottomText) + "\n\t\t\t")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "item__sessionInfoContainer tw-mt-0.5 tw-text-origami-grey-300",
            },
            [
              _vm.middleTopText
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "middle-top-text",
                          expression: "'middle-top-text'",
                        },
                      ],
                      staticClass: "item__middleTop",
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" + _vm._s(_vm.middleTopText) + "\n\t\t\t"
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.middleBottomText
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "middle-bottom-text",
                          expression: "'middle-bottom-text'",
                        },
                      ],
                      staticClass: "item__middleBottom tw-flex tw-items-bottom",
                      class: {
                        "item__middleBottom--red": _vm.isEssayCriticalOrLate,
                        "item__middleBottom--orange": _vm.isEssayAtRisk,
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.middleBottomText) +
                          "\n\t\t\t\t"
                      ),
                      _c(
                        "span",
                        [
                          _c("OrigamiIcon", {
                            directives: [
                              {
                                name: "jest",
                                rawName: "v-jest",
                                value: "copy-resource-id",
                                expression: "'copy-resource-id'",
                              },
                            ],
                            staticClass: "hover:tw-cursor-pointer tw-ml-1",
                            attrs: {
                              id: `copy-resource-id-${_vm.resource.id}`,
                              "data-cy": "copy-resource-icon",
                              name: "copy",
                              color: "black",
                              width: "12",
                              height: "12",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.copyResourceId()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "item__sessionTimeContainer tw-mt-[2px] tw-text-right",
              on: {
                mouseenter: function ($event) {
                  return _vm.setIsHovering(true)
                },
                mouseleave: function ($event) {
                  return _vm.setIsHovering(false)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "item__rightTop" },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "time-elapsed",
                          expression: "'time-elapsed'",
                        },
                      ],
                      staticClass: "item__timeElapsed",
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" + _vm._s(_vm.timeElapsed) + "\n\t\t\t\t"
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.isEssay
                    ? _c("IconArrowShare", {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "transfer-icon",
                            expression: "'transfer-icon'",
                          },
                        ],
                        staticClass: "item__shareIcon tw-ml-1",
                        attrs: {
                          "data-cy": "transfer-icon",
                          "icon-color": "#484756",
                        },
                        nativeOn: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.showTransferModal()
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isStudentDisconnected
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "student-disconnected",
                          expression: "'student-disconnected'",
                        },
                      ],
                      staticClass: "item__disconnected",
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\tDisconnected for " +
                          _vm._s(_vm.studentIsDisconnectedFor) +
                          "\n\t\t\t"
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }