<template>
	<span :style="{ backgroundColor, color: textColor }">
		<slot />
	</span>
</template>

<script>
export default {
	props: {
		backgroundColor: {
			type: String,
			default: "lightgrey",
		},
		textColor: {
			type: String,
			default: "black",
		},
	},
};
</script>

<style scoped>
span {
	padding: 0.25rem 0.5rem;
	margin: 0.5rem 0.25rem 0.5rem 0;
	border-radius: 1rem;
	font-size: 0.75rem;
}
</style>
