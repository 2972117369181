var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "64",
        height: "64",
        viewBox: "0 0 64 64",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M36.9739 36.9744C33.0952 40.8531 26.8067 40.8531 22.9299 36.9744C19.0512 33.0957 19.0512 26.8072 22.9299 22.9304C26.8067 19.0516 33.0952 19.0516 36.9739 22.9304C40.8527 26.8072 40.8527 33.0957 36.9739 36.9744ZM41.7034 18.2012C35.2131 11.7109 24.6914 11.7109 18.2011 18.2012C11.7108 24.6915 11.7108 35.2133 18.2011 41.7036C23.7316 47.2341 32.1848 48.0336 38.5808 44.1379L43.9586 49.5157C45.4935 51.0506 47.9825 51.0506 49.5155 49.5157C51.0504 47.9827 51.0504 45.4936 49.5155 43.9587L44.1377 38.581C48.0334 32.1831 47.2339 23.7317 41.7034 18.2012Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }