var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BreaksManagementWidgetWrapper", {
    scopedSlots: _vm._u([
      {
        key: "status",
        fn: function () {
          return [_c("ShiftStatus")]
        },
        proxy: true,
      },
      {
        key: "shiftDetails",
        fn: function () {
          return [_c("CurrentShiftDetails")]
        },
        proxy: true,
      },
      {
        key: "breakDetails",
        fn: function () {
          return [_c("RestBreakDetails"), _vm._v(" "), _c("MealBreakDetails")]
        },
        proxy: true,
      },
      {
        key: "breakButtons",
        fn: function () {
          return [
            _vm.isPunchedIntoShift
              ? _c("MealBreakButton", {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "meal-break-button",
                      expression: "'meal-break-button'",
                    },
                  ],
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isPunchedIntoShift
              ? _c("RestBreakButton", {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "rest-break-button",
                      expression: "'rest-break-button'",
                    },
                  ],
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isPunchedIntoShift
              ? _c("BioBreakButton", {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "bio-break-button",
                      expression: "'bio-break-button'",
                    },
                  ],
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c("RestBreak"),
            _vm._v(" "),
            _c("MealBreak"),
            _vm._v(" "),
            _vm.isPunchedIntoShift
              ? _c("BioBreak", {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "bio-break",
                      expression: "'bio-break'",
                    },
                  ],
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }