<template>
	<BaseButton
		:variants="['mini', isRecognized ? 'outline' : '']"
		@click.native="handleClick"
	>
		<BaseLoader
			v-if="isLoading"
			v-jest="'loader'"
			thickness="0.25rem"
			class="tw-inline-flex tw-align-self-center tw-w-6 tw-h-6 tw--my-2 tw--ml-2 tw-mr-2"
		/>
		<IconCheck
			v-else-if="isRecognized"
			v-jest="'check-icon'"
			class="tw--mt-3 tw--mb-2 tw--ml-2 tw-mr-2"
		/>
		<IconEmojiThumbsUp
			v-else
			v-jest="'like-icon'"
			class="tw--mt-3 tw--mb-2 tw--ml-1 tw-mr-2"
		/>
		<template v-if="isRecognized">
			{{ $t('recognition.recognition_sent', { recognition_name: "Kudos" }) }}
		</template>
		<template v-else>
			{{ $t('recognition.recognition_send', { recognition_name: "Kudos" }) }}
		</template>
	</BaseButton>
</template>

<script>
// [deprecate:pe-12035-recognitions-phase-2]
import { showErrorModal } from "@/utilities/errorHandlingHelpers.js";
import BaseButton from "@/components/elements/BaseButton.vue";
import BaseLoader from "@/components/elements/BaseLoader.vue";
import IconCheck from "@/components/elements/icons/Check.vue";

import { RECOGNITION_TYPE_IDS } from "../utilities/index.js";
import RecognitionAPI from "../services/api/index.js";

export default {
	components: {
		BaseButton,
		BaseLoader,
		IconCheck,
		IconEmojiThumbsUp: () => import("@/components/icons/IconEmojiThumbsUp.vue"),
	},
	props: {
		sessionId: {
			type: [Number, String],
			required: true,
		},
	},
	data() {
		return {
			isRecognized: false,
			isLoading: false,
		};
	},
	async created() {
		await this.loadRecognitionState();
	},
	methods: {
		async loadRecognitionState() {
			this.isLoading = true;
			try {
				const recognitions = await RecognitionAPI.check(this.sessionId, {
					recognition_type_id: RECOGNITION_TYPE_IDS.KUDOS,
				});
				this.isRecognized = Boolean(recognitions?.data?.data?.some(
					(recognition) => recognition.recognition_type_id === RECOGNITION_TYPE_IDS.KUDOS),
				);
			} catch (error) {
				Sentry.captureException(error);
				showErrorModal(this);
			}
			this.isLoading = false;
		},
		async handleClick() {
			if (this.isRecognized) {
				return;
			}

			this.isLoading = true;
			try {
				await RecognitionAPI.create(this.sessionId, {
					recognition_type_id: RECOGNITION_TYPE_IDS.KUDOS,
				});
				this.isRecognized = true;
			} catch (error) {
				Sentry.captureException(error);
				showErrorModal(this);
			}
			this.isLoading = false;
		},
	},
};
</script>
