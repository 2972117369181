var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canCancelBreak
    ? _c(
        "OrigamiButton",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "meal-break-cancel-button",
              expression: "'meal-break-cancel-button'",
            },
            {
              name: "data-cy",
              rawName: "v-data-cy",
              value: "meal-break-cancel-button",
              expression: "'meal-break-cancel-button'",
            },
          ],
          staticClass: "tw-w-full tw-justify-center",
          attrs: { variant: "destructive" },
          nativeOn: {
            click: function ($event) {
              return _vm.cancelBreak.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n\tCancel break\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }