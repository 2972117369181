var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "tw-px-2 tw-py-1 tw-rounded-t-[4px] tw-bg-origami-grey-100 tw-font-bold tw-border-[1px] tw-border-origami-grey-200",
      },
      [_vm._t("header")],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "tw-flex tw-p-2 tw-bg-origami-white tw-rounded-b-[4px] tw-font-bold tw-text-center tw-text-sm tw-border-[1px] tw-border-t-0 tw-border-origami-grey-200",
      },
      [
        _c("div", { staticClass: "tw-mr-2" }, [_vm._t("bodyIcon")], 2),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "tw-flex-grow tw-text-left tw-text-sm tw-font-normal",
          },
          [_vm._t("bodyText")],
          2
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tw-flex tw-flex-col tw-my-4" },
      [_vm._t("buttons")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }