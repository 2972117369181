var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tw-mx-6" },
    [
      _c("ListItemLayout", {
        staticClass: "tw-border-origami-grey-200",
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "item-name",
                        expression: "'item-name'",
                      },
                    ],
                    staticClass: "ellipsis tw-font-bold tw-overflow-hidden",
                  },
                  [_vm._v("\n\t\t\t\t" + _vm._s(_vm.groupName) + "\n\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "item-students-count",
                        expression: "'item-students-count'",
                      },
                    ],
                    staticClass: "tw-text-sm tw-text-origami-grey-300",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.studentsCount) +
                        " students\n\t\t\t"
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "tw-whitespace-nowrap" },
                  [
                    _c(
                      "BaseIconButton",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "edit-button",
                            expression: "'edit-button'",
                          },
                        ],
                        attrs: {
                          "aria-label": `Navigate to edit ${_vm.groupName} group`,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.$router.push(
                              `/${_vm.currentUser.role}/student-groups/edit/${_vm.groupId}`
                            )
                          },
                        },
                      },
                      [_c("PenIcon")],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "BaseIconButton",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "delete-button",
                            expression: "'delete-button'",
                          },
                        ],
                        attrs: {
                          "aria-label": `Delete group ${_vm.groupName}`,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.$emit("delete-item", _vm.groupId)
                          },
                        },
                      },
                      [_c("TrashIcon")],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }