var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tw-my-5", attrs: { id: "tutorDashboardScheduleLegend" } },
    [
      _c(
        "ul",
        {
          staticClass:
            "tw-grid tw-grid-cols-2 tw-w-full tw-gap-y-4 tw-gap-x-12",
        },
        _vm._l(_vm.scheduleTypes, function (legend, index) {
          return _c(
            "li",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "legend-item",
                  expression: "'legend-item'",
                },
              ],
              key: index,
              staticClass: "tw-w-full",
            },
            [
              _c(
                "div",
                { staticClass: "tw-inline-flex tw-text-base tw-my-1.5" },
                [
                  _c("div", {
                    staticClass:
                      "tw-mt-1 tw-mr-2.5 tw-w-5 tw-h-5 tw-rounded-md",
                    class: legend.color,
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "tw-font-bold" }, [
                    _vm._v(
                      "\n\t\t\t\t\t" + _vm._s(legend.title) + "\n\t\t\t\t"
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "tw-text-sm tw-font-normal tw-m0" }, [
                _c("strong", [
                  _vm._v(
                    " " +
                      _vm._s(legend.emphasis) +
                      " " +
                      _vm._s(
                        _vm.insertScheduleEndDateIfLiveSessionShift(index)
                      ) +
                      " "
                  ),
                ]),
                _vm._v("\n\t\t\t\t" + _vm._s(legend.description) + "\n\t\t\t"),
              ]),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }