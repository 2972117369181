import rootInstance from "@/services/root.js";

const learnerProfileApi = () => {
	rootInstance.defaults.baseURL = process.env.MIX_LEARNER_PROFILE_API_URL + "/api/v1";
	return rootInstance;
};

export async function getStudentStatus() {
	return learnerProfileApi().get("/student-status");
}
