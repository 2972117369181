<template>
	<TutorBreakCard
		v-jest="'break-request-card'"
		:break="breakRequest"
		:is-loading-state="isLoadingState"
		@click.native.prevent="getBreaksForSelectedTime(breakRequest.start_time, breakRequest.end_time)"
	>
		<template #metaDataIcon>
			<component
				:is="getOriginalScheduleType()"
				v-data-cy="getOriginalScheduleType()"
				icon-color="currentColor"
				class="tw-mr-2 tw-align-middle tw-text-black-high"
			/>
		</template>
		<template #metadata>
			{{ getTimeRange(
				breakRequest.originalSchedule.data.start_time, breakRequest.originalSchedule.data.end_time,
			) }}
			<span class="tw-mx-1">|</span>
			{{ getTimeElapsed() }} elapsed
		</template>
		<template #breakDetailsIcon>
			<OrigamiIcon name="meal-break" />
		</template>
		<template #breakDetails>
			{{ getTimeRange(breakRequest.start_time, breakRequest.end_time) }}
		</template>
		<template #buttons>
			<BaseButton
				v-jest="'reject-break-request'"
				v-data-cy="'reject-break-request'"
				:variants="['mini']"
				:color="'orange'"
				class="tw-mr-2"
				:disabled="isLoadingState"
				@click.native="rejectBreakRequest"
			>
				Reject
			</BaseButton>
			<BaseButton
				v-jest="'accept-break-request'"
				v-data-cy="'accept-break-request'"
				:variants="['mini']"
				:color="'green'"
				:disabled="isLoadingState"
				@click.native.prevent="approveBreakRequest"
			>
				Accept
			</BaseButton>
		</template>
	</TutorBreakCard>
</template>

<script>
import { OrigamiIcon } from "@origami/vue2";

import { formatTimestampToHM } from "@/utilities/dateHelpers.js";
import { getTimeElapsedForShiftString } from "@/utilities/ScheduleHelpers.js";
import IconBreak from "@/components/icons/IconBreak.vue";
import IconSession from "@/components/icons/IconSession.vue";
import IconEssay from "@/components/icons/IconEssay.vue";
import BaseButton from "@/components/elements/BaseButton.vue";

import BreakRequestAPI from "../service/BreakRequest.js";
import { SHIFT_TYPE_ID } from "../utilities/ShiftTypeIDs.js";

import TutorBreakCard from "./TutorBreakCard.vue";

export default {
	components: {
		TutorBreakCard,
		IconBreak,
		IconSession,
		IconEssay,
		BaseButton,
		OrigamiIcon,
	},
	props: {
		breakRequest: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			isLoadingState: false,
		};
	},
	methods: {
		getBreaksForSelectedTime(start_time, end_time) {
			this.$emit("select-time", { start_time, end_time });
		},
		getOriginalScheduleType() {
			return this.breakRequest.originalSchedule.data.scheduleType.data.id === SHIFT_TYPE_ID.CLASSROOM ? "IconSession" : "IconEssay";
		},
		getTimeElapsed() {
			return getTimeElapsedForShiftString(this.breakRequest.originalSchedule.data.punch_in);
		},
		getTimeRange(start_time, end_time) {
			return `${formatTimestampToHM(start_time)} - ${formatTimestampToHM(end_time)}`;
		},
		async rejectBreakRequest() {
			try {
				this.isLoadingState = true;
				await BreakRequestAPI.rejectBreakRequest(this.breakRequest.id);
			} catch (e) {
				this.handleError("There was an error rejecting the break request");
				Sentry.captureException(e);
			} finally {
				this.isLoadingState = false;
			}
		},
		async approveBreakRequest() {
			try {
				this.isLoadingState = true;
				await BreakRequestAPI.approveBreakRequest(this.breakRequest.id);
			} catch (e) {
				this.handleError(
					"A break cannot be scheduled within 30 minutes of a shift start, shift end, transition into a " +
					"different shift, or break start");
				Sentry.captureException(e);
			} finally {
				this.isLoadingState = false;
			}
		},
		handleError(errorText) {
			this.$store.dispatch("Snackbar/showSnackbar", {
				snackbarText: errorText,
				snackbarType: "error",
			});
		},
	},
};
</script>
