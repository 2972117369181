var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "104",
        height: "104",
        viewBox: "0 0 104 104",
        fill: "none",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_931_25816)" } }, [
        _c("rect", { attrs: { width: "104", height: "104", fill: "none" } }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M89.6121 75.2245L91.6469 87.5775L104 89.6122L91.6469 91.647L89.6121 104L87.5774 91.647L75.2244 89.6122L87.5774 87.5775L89.6121 75.2245Z",
            fill: "url(#paint0_radial_931_25816)",
            "fill-opacity": "0.8",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M5.39541 38.7367L6.56666 42.9609L10.7908 44.1321L6.56666 45.3034L5.39541 49.5275L4.22417 45.3034L0 44.1321L4.22417 42.9609L5.39541 38.7367Z",
            fill: "url(#paint1_radial_931_25816)",
            "fill-opacity": "0.8",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M17.5524 0L18.7935 20.0395L35.1048 21.5644L18.7935 23.0892L17.5524 43.1287L16.3112 23.0892L0 21.5644L16.3112 20.0395L17.5524 0Z",
            fill: "url(#paint2_radial_931_25816)",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M8.88238 12.6232L17.5492 18.52L26.216 12.6232L20.3192 21.29L26.216 29.9568L17.5492 24.0601L8.88238 29.9568L14.7792 21.29L8.88238 12.6232Z",
            fill: "url(#paint3_radial_931_25816)",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "defs",
        [
          _c(
            "radialGradient",
            {
              attrs: {
                id: "paint0_radial_931_25816",
                cx: "0",
                cy: "0",
                r: "1",
                gradientUnits: "userSpaceOnUse",
                gradientTransform:
                  "translate(89.6121 89.6122) rotate(90) scale(14.3878)",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.265625", "stop-color": "#181C28" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#181C28",
                  "stop-opacity": "0",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "radialGradient",
            {
              attrs: {
                id: "paint1_radial_931_25816",
                cx: "0",
                cy: "0",
                r: "1",
                gradientUnits: "userSpaceOnUse",
                gradientTransform:
                  "translate(5.39541 44.1321) rotate(90) scale(5.39541)",
              },
            },
            [
              _c("stop", {
                attrs: { offset: "0.265625", "stop-color": "#181C28" },
              }),
              _vm._v(" "),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "#181C28",
                  "stop-opacity": "0",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "radialGradient",
            {
              attrs: {
                id: "paint2_radial_931_25816",
                cx: "0",
                cy: "0",
                r: "1",
                gradientUnits: "userSpaceOnUse",
                gradientTransform:
                  "translate(17.5524 21.5644) rotate(90) scale(21.5644 17.5524)",
              },
            },
            [
              _c("stop", {
                attrs: {
                  offset: "0.239583",
                  "stop-color": "#191919",
                  "stop-opacity": "0.9",
                },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-opacity": "0" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "radialGradient",
            {
              attrs: {
                id: "paint3_radial_931_25816",
                cx: "0",
                cy: "0",
                r: "1",
                gradientUnits: "userSpaceOnUse",
                gradientTransform:
                  "translate(17.5492 21.29) rotate(45) scale(12.2567)",
              },
            },
            [
              _c("stop", {
                attrs: {
                  offset: "0.239583",
                  "stop-color": "#191919",
                  "stop-opacity": "0.9",
                },
              }),
              _vm._v(" "),
              _c("stop", { attrs: { offset: "1", "stop-opacity": "0" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("clipPath", { attrs: { id: "clip0_931_25816" } }, [
            _c("rect", {
              attrs: { width: "104", height: "104", fill: "white" },
            }),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }