var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.totalPages > 0
    ? _c(
        "nav",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "pagination-wrapper",
              expression: "'pagination-wrapper'",
            },
          ],
          staticClass: "tw-flex tw-justify-center tw-mt-6",
          attrs: { "aria-label": _vm.$t("navbar.previous_page") },
        },
        [
          _c("OrigamiIconButton", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isTotalGreaterThanLimit,
                expression: "isTotalGreaterThanLimit",
              },
              {
                name: "jest",
                rawName: "v-jest",
                value: "backwards-button",
                expression: "'backwards-button'",
              },
            ],
            staticClass: "shared-button-class",
            attrs: { "icon-name": "backward", "aria-label": "Previous Page" },
            nativeOn: {
              click: function ($event) {
                return _vm.handleClick(_vm.currentPage - 1)
              },
            },
          }),
          _vm._v(" "),
          _vm._l(_vm.pages, function (page) {
            return _c(
              "OrigamiButton",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "number-buttons",
                    expression: "'number-buttons'",
                  },
                ],
                key: page + _vm.shiftValue,
                staticClass: "shared-button-class",
                class:
                  _vm.currentPage === page + _vm.shiftValue
                    ? "active"
                    : "number-buttons",
                attrs: {
                  "aria-label":
                    _vm.$t("navbar.pagination") + " " + (page + _vm.shiftValue),
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.handleClick(page + _vm.shiftValue)
                  },
                },
              },
              [_vm._v("\n\t\t" + _vm._s(page + _vm.shiftValue) + "\n\t")]
            )
          }),
          _vm._v(" "),
          _c("OrigamiIconButton", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isTotalGreaterThanLimit,
                expression: "isTotalGreaterThanLimit",
              },
              {
                name: "jest",
                rawName: "v-jest",
                value: "forwards-button",
                expression: "'forwards-button'",
              },
            ],
            staticClass: "shared-button-class",
            attrs: {
              "icon-name": "forward",
              "aria-label": _vm.$t("navbar.next_page"),
            },
            nativeOn: {
              click: function ($event) {
                return _vm.handleClick(_vm.currentPage + 1)
              },
            },
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }