var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "OrigamiButton",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "notification-button",
          expression: "'notification-button'",
        },
      ],
      staticClass: "notificationButton",
      class: { active: _vm.isSelected },
      nativeOn: {
        click: function ($event) {
          return _vm.goToAchievementCenter()
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "tw-flex tw-items-center tw-flex-nowrap tw-gap-2" },
        [
          _c("Points", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "notification-points",
                expression: "'notification-points'",
              },
            ],
            staticClass: "tw-font-normal tw-text-base",
            attrs: { label: "", points: _vm.points },
          }),
          _vm._v(" "),
          _vm.notificationCount
            ? _c(
                "span",
                {
                  staticClass:
                    "tw-flex tw-items-center tw-flex-nowrap tw-gap-2",
                },
                [
                  _c("Emoji", {
                    staticClass: "medal",
                    attrs: { emoji: "Medal" },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "notificationCount",
                          expression: "'notificationCount'",
                        },
                      ],
                      staticClass:
                        "tw-px-1 tw-flex-nowrap tw-text-base tw-font-normal",
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.notificationCount) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }