var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vue-radio" }, [
    _c("input", {
      attrs: {
        id: _vm.radioId,
        type: "radio",
        name: _vm.radioName,
        disabled: _vm.isDisabled,
        autocomplete: "off",
      },
      domProps: { value: _vm.radioValue, checked: _vm.isRadioButtonChosen },
      on: { change: _vm.handleInput },
    }),
    _vm._v(" "),
    _c("label", { attrs: { for: _vm.radioId } }, [
      _c("p", [_vm._t("text")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }