var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "assessment" },
    [
      _c("SidebarTabs", {
        staticClass: "assessment__title--noPadding",
        attrs: {
          tabs: [
            {
              active: true,
              component: "complete-assessment",
              name: "complete_assessment",
            },
          ],
          "font-color": "#4a4a4a",
          "sidebar-key": "none",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "assessment__contentContainer" },
        [
          _vm.currentSession.assessment.step ===
          _vm.ASSESSMENTS_STEPS.RECOGNITIONS
            ? _c("RecognitionStep", {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "recognition-step",
                    expression: "'recognition-step'",
                  },
                ],
                key: _vm.currentSession.id,
                attrs: { session: _vm.currentSession },
                on: {
                  "recognition-select": function ($event) {
                    _vm.selectedRecognitionIds = $event
                  },
                  input: function ($event) {
                    _vm.recognitionsMessage = $event
                  },
                  skip: function ($event) {
                    return _vm.skipRecognitionsStep()
                  },
                  "recognition-types-loading": function ($event) {
                    _vm.isProcessingAssessment = true
                  },
                  "recognition-types-loaded": function ($event) {
                    _vm.isProcessingAssessment = false
                  },
                },
              })
            : _vm.currentSession.assessment.step ===
              _vm.ASSESSMENTS_STEPS.QUESTION
            ? _c("QuestionStep", {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "assessment-question",
                    expression: "'assessment-question'",
                  },
                ],
                attrs: {
                  "is-non-academic": _vm.isNonAcademic,
                  session: _vm.currentSession,
                  "student-name": _vm.studentName,
                  "assessment-questions":
                    _vm.$store.getters["Tutor/Session/assessmentQuestions"],
                },
                on: {
                  "non-academic-check": function ($event) {
                    _vm.isNonAcademic = $event
                  },
                },
              })
            : _vm.currentSession.assessment.step === _vm.ASSESSMENTS_STEPS.TAG
            ? _c("TagsStep", {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "assessment-tags",
                    expression: "'assessment-tags'",
                  },
                ],
                attrs: {
                  session: _vm.currentSession,
                  subjects: _vm.subjectsArray,
                  tags: _vm.tagsArray,
                  "session-tags": _vm.sessionTags,
                  "search-text": _vm.searchText,
                },
                on: {
                  "tags-update": function ($event) {
                    _vm.tagsArray = $event
                  },
                  "subject-select": _vm.changeSubject,
                  "tag-remove": _vm.removeTag,
                  "search-text-input": function ($event) {
                    _vm.searchText = $event
                  },
                  "tag-select": _vm.addTagToList,
                  "subject-search-change": _vm.getAssessmentMap,
                },
              })
            : _vm.currentSession.assessment.step ===
                _vm.ASSESSMENTS_STEPS.NON_ACADEMIC && _vm.isNonAcademic
            ? _c("NonAcademicStep", {
                attrs: {
                  "is-non-academic": _vm.isNonAcademic,
                  session: _vm.currentSession,
                },
                on: {
                  "non-academic-check": function ($event) {
                    _vm.isNonAcademic = $event
                  },
                },
              })
            : _vm.currentSession.assessment.step ===
              _vm.ASSESSMENTS_STEPS.FEEDBACK
            ? _c("FeedbackStep", {
                attrs: {
                  "student-name": _vm.studentName,
                  "student-id": _vm.studentId,
                  feedback: _vm.feedback,
                  "is-submitting": _vm.isSubmittingAssessment,
                },
                on: {
                  "feedback-update": function ($event) {
                    _vm.feedback = $event
                  },
                  "shoutout-update": function ($event) {
                    _vm.shoutout = $event
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isButtonsShowing
        ? _c(
            "div",
            { staticClass: "assessment__buttonContainer" },
            [
              _c(
                "BaseButton",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "submit-button",
                      expression: "'submit-button'",
                    },
                    {
                      name: "data-cy",
                      rawName: "v-data-cy",
                      value: "assessment-submit-button",
                      expression: "'assessment-submit-button'",
                    },
                  ],
                  attrs: {
                    type: "PRIMARY",
                    "will-float-right": true,
                    "is-disabled":
                      !_vm.isNextStepEnabled ||
                      _vm.isProcessingAssessment ||
                      _vm.isSubmittingAssessment,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.advanceAssessment()
                    },
                  },
                },
                [
                  _c("template", { slot: "text" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.$t("right_sidebar.assessment.next")) +
                        "\n\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "template",
                    { slot: "icon-right" },
                    [
                      _c("IconChevronRight", {
                        attrs: { viewbox: "-15 0 42 38" },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _vm.isBackButtonAllowed
                ? _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "back-button",
                          expression: "'back-button'",
                        },
                        {
                          name: "data-cy",
                          rawName: "v-data-cy",
                          value: "assessment-back-button",
                          expression: "'assessment-back-button'",
                        },
                      ],
                      staticClass: "assessment__backButton",
                      attrs: { href: "#" },
                      on: { click: _vm.goToPreviousStep },
                    },
                    [
                      _c("IconChevronLeft"),
                      _vm._v(
                        "\n\t\t\t" +
                          _vm._s(_vm.$t("right_sidebar.assessment.back")) +
                          "\n\t\t"
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }