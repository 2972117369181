var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "144",
        height: "144",
        viewBox: "0 0 144 144",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            d: "M129.528 118.102L123.542 39.7761L14.6165 16.3384L1.93845 126.654L129.528 118.102Z",
            fill: "#E8E8E9",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M28.7134 106.155L6.33842 52.1882C5.70945 50.6712 6.87015 49.0147 8.51075 49.088L68.4169 51.7642C69.1785 51.7982 69.8708 52.2168 70.2547 52.8754L97.689 99.9339C98.5285 101.374 97.5601 103.194 95.8969 103.302L30.9314 107.534C29.973 107.597 29.0812 107.042 28.7134 106.155Z",
            fill: "#696B73",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M30.0592 98.3018L13.2577 42.3486C12.7855 40.7757 14.1077 39.2451 15.7325 39.484L75.0607 48.206C75.815 48.3168 76.4613 48.8033 76.7767 49.4974L99.3104 99.0894C99.9999 100.607 98.8524 102.32 97.1867 102.259L32.1264 99.8984C31.1666 99.8636 30.3355 99.2217 30.0592 98.3018Z",
            fill: "white",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M37.1338 71.3201C38.4797 68.616 41.6203 63.8714 43.4149 66.5264C45.6582 69.8451 44.7609 75.7451 43.4149 72.7951C42.069 69.8451 49.2474 64.314 51.042 66.5264C52.8367 68.7389 52.8367 74.2701 51.042 72.7951C49.2474 71.3201 55.9772 64.6827 58.2205 66.5264C60.0151 68.0014 61.3611 69.5993 61.8097 70.2139",
            stroke: "#BABBBF",
            "stroke-width": "1.80442",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M41.5582 84.359C43.4032 81.613 47.6077 76.8356 49.6648 79.6945C52.2362 83.268 50.7425 89.3658 49.2613 86.2231C47.7802 83.0805 57.0483 77.6948 59.1339 80.0928C61.2196 82.4907 60.8636 88.2513 58.7305 86.6214C56.5974 84.9916 65.3797 78.4303 68.0461 80.4677C70.1791 82.0975 71.7473 83.832 72.2648 84.4955",
            stroke: "#BABBBF",
            "stroke-width": "1.80442",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M35.182 93.8238L24.1052 55.8461L77.3078 62.2949L92.571 96.5056L35.182 93.8238ZM22.8157 51.8182L20.8587 46.3387L66.6821 52.1998L69.403 57.6416L22.8157 51.8182Z",
            stroke: "#BABBBF",
            "stroke-width": "2.24327",
          },
        }),
        _vm._v(" "),
        _c("circle", {
          attrs: {
            cx: "84.7504",
            cy: "79.6086",
            r: "30.4567",
            transform: "rotate(-49.1843 84.7504 79.6086)",
            fill: "white",
            stroke: "#0E0FE0",
            "stroke-width": "6.01246",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.7",
            d: "M68.6108 69.1936C64.6936 59.0183 84.7633 52.7953 95.2878 50.9558L115.678 66.9624C111.591 68.0162 102.251 70.5989 97.5909 72.4994C91.7659 74.8751 98.3015 80.1808 99.881 87.2013C101.145 92.8177 77.1643 98.5439 65.0162 100.705L59.8759 97.0784C61.8461 96.0364 67.6294 93.0987 75.0013 89.6834C84.2162 85.4144 73.5074 81.9127 68.6108 69.1936Z",
            fill: "#E8E8E9",
          },
        }),
        _vm._v(" "),
        _c("circle", {
          attrs: {
            cx: "84.7504",
            cy: "79.6086",
            r: "30.4567",
            transform: "rotate(-49.1843 84.7504 79.6086)",
            stroke: "#0E0FE0",
            "stroke-width": "6.01246",
          },
        }),
        _vm._v(" "),
        _c("rect", {
          attrs: {
            x: "105.746",
            y: "104.057",
            width: "9.56085",
            height: "6.3739",
            transform: "rotate(-49.1843 105.746 104.057)",
            fill: "#0E0FE0",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M109.527 109.43L117.859 99.782L140.903 117.573C142.307 118.657 142.472 120.715 141.259 122.009L134.347 129.381C133.195 130.61 131.258 130.653 130.053 129.476L109.527 109.43Z",
            fill: "#0E0FE0",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M78.1357 59H90.1607L88.1565 86.0561H80.1399L78.1357 59Z",
            fill: "#181C28",
          },
        }),
        _vm._v(" "),
        _c("circle", {
          attrs: {
            cx: "84.1494",
            cy: "94.7764",
            r: "4.87206",
            fill: "#181C28",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M112.997 97.5865C110.05 101.254 108.494 103.655 106.708 104.892L109.02 106.906L111.372 102.494L112.997 97.5865Z",
            fill: "#050F64",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }