var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      style: _vm.orientationClass,
      attrs: {
        viewBox: "0 0 8 15",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("defs", [
        _c("path", {
          attrs: {
            id: "path-1",
            d: "M2.98427949e-13,0.465966102 L7.81820339,0.465966102 L7.81820339,15.7288136 L2.98427949e-13,15.7288136 L2.98427949e-13,0.465966102 Z",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            { attrs: { transform: "translate(-665.000000, -132.000000)" } },
            [
              _c(
                "g",
                { attrs: { transform: "translate(665.000000, 112.000000)" } },
                [
                  _c(
                    "g",
                    { attrs: { transform: "translate(0.000000, 19.000000)" } },
                    [
                      _c("mask", { attrs: { id: "mask-2", fill: "white" } }, [
                        _c("use", { attrs: { "xlink:href": "#path-1" } }),
                      ]),
                      _vm._v(" "),
                      _c("g", { attrs: { id: "Clip-2" } }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "fillCurrent",
                        attrs: {
                          id: "Fill-1",
                          d: "M7.81795763,1 L0.649550847,7.00054237 C-0.216516949,7.7260339 -0.216516949,9.53779661 0.649550847,10.2623051 L7.81795763,16.2628475 L7.81795763,1 Z",
                          mask: "url(#mask-2)",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }