var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "BreaksNotification",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "meal-break-late-notification",
              expression: "'meal-break-late-notification'",
            },
          ],
          staticClass: "tw-mb-4",
          attrs: { "notification-type": _vm.notificationType },
        },
        [
          _vm.timeText
            ? _c(
                "p",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "meal-break-late-time",
                      expression: "'meal-break-late-time'",
                    },
                  ],
                  staticClass: "tw-font-bold tw-text-sm",
                },
                [_vm._v("\n\t\t\t" + _vm._s(_vm.timeText) + "\n\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "p",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "meal-break-late-message",
                  expression: "'meal-break-late-message'",
                },
              ],
              staticClass: "tw-text-sm",
            },
            [_vm._v("\n\t\t\t" + _vm._s(_vm.messageText) + "\n\t\t")]
          ),
        ]
      ),
      _vm._v(" "),
      !_vm.pastLateThreshold
        ? _c(
            "OrigamiButton",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "meal-break-end-button",
                  expression: "'meal-break-end-button'",
                },
              ],
              staticClass: "tw-w-full tw-justify-center",
              attrs: {
                variant: "primary",
                "is-loading":
                  _vm.$store.state.BreaksManagementWidget.isRequestLoading,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.endMealBreak.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n\t\tEnd break\n\t")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }