import { isNil } from "lodash";
import { differenceInSeconds, differenceInMinutes, differenceInHours } from "date-fns";

import Colors from "@/utilities/Colors.js";
import {
	REST_BREAK_ID,
	MEAL_BREAK_ID,
	TUTORING_SESSION_ID,
	WRITING_REVIEW_ID,
} from "@/modules/TutorSchedule/utilities/ShiftSegmentTypes.js";

import { formatSecondsTimestampToDate } from "./dateHelpers.js";

/**
 * @param {String} name
 * @return {String}
 */
const getSlotColorFromShiftType = (name) => {
	const shiftTypeColors = {
		"Tutoring Session": Colors.C_LIGHT_GREEN,
		"Live Help": Colors.C_LIGHT_GREEN,
		"Essay Review": Colors.C_LIGHT_BLUE,
		"Writing Review": Colors.C_LIGHT_BLUE,
		"Tutor Manager": Colors.C_PINK,
		Training: Colors.C_LIGHT_ORANGE,
		Onboarding: Colors.C_ORANGE_PASTEL,
		Assessment: Colors.C_BLUE_PASTEL,
		Meeting: Colors.C_CARDBOARD_BEIGE,
		"Test Session": Colors.C_GREEN_PASTEL,
		"Special Project": Colors.C_LIGHT_YELLOW,
		"Pay Adjustment": Colors.C_LIGHT_PINK,
		"Unpaid Break": Colors.C_BLUE_PASTEL,
		"Paid Break": Colors.C_BLUE_PASTEL,
	};
	return !isNil(shiftTypeColors[name]) ? shiftTypeColors[name] : Colors.C_LIGHT_GREEN;
};

const getSlotColorFromShiftTypeId = (id) => {
	const shiftTypeColors = {
		1: Colors.C_LIGHT_GREEN,
		2: Colors.C_LIGHT_BLUE,
		3: Colors.C_PINK,
		4: Colors.C_LIGHT_ORANGE,
		5: Colors.C_ORANGE_PASTEL,
		6: Colors.C_BLUE_PASTEL,
		7: Colors.C_CARDBOARD_BEIGE,
		8: Colors.C_GREEN_PASTEL,
		9: Colors.C_LIGHT_YELLOW,
		10: Colors.C_LIGHT_PINK,
		11: Colors.C_BLUE_PASTEL,
		12: Colors.C_BLUE_PASTEL,
	};
	return shiftTypeColors[id] ?? Colors.C_LIGHT_GREEN;
};

/**
 * @param {String} name
 * @return {String}
 */
const getOrigamiColorFromShiftTypeId = (id) => {
	const shiftTypeColors = {
		1: "tw-bg-green-light",
		2: "tw-bg-origami-blue-100",
		4: "tw-bg-origami-red-100",
		5: "tw-bg-origami-green-100",
		6: "tw-bg-origami-purple-100",
		7: "tw-bg-origami-purple-300",
		8: "tw-bg-origami-cyan-100",
		9: "tw-bg-origami-yellow-100",
		10: "tw-bg-origami-pink-100",
	};
	return !isNil(shiftTypeColors[id]) ? shiftTypeColors[id] : "tw-bg-origami-green-200";
};

const getShiftTypeNameFromShiftTypeId = (id) => {
	const shiftTypeName = {
		1:	"Tutoring Session",
		2:	"Writing Review",
		3:	"Tutor Manager",
		4:	"Training",
		5:	"Onboarding",
		6:	"Assessment",
		7:	"Meeting",
		8:	"Test Session",
		9: "Special Project",
		10:	"Pay Adjustment",
		11:	"Paid Break",
		12:	"Unpaid Break",
	};
	return shiftTypeName[id];
};

/**
 * @returns {Number}
 * @param {Object} shift
 * @param {Number} shift.start_time
 * @param {Date} currentDate
 */
export const minutesToShiftStart = (shift, currentDate = new Date()) => {
	return Math.ceil(differenceInSeconds(
		formatSecondsTimestampToDate(shift.start_time),
		currentDate,
	) / 60);
};

/**
 * @returns {Number}
 * @param {Object} shift
 * @param {Number} shift.end_time
 * @param {Date} currentDate
 */
export const minutesToShiftEnd = (shift, currentDate = new Date()) => {
	return Math.ceil(differenceInSeconds(
		formatSecondsTimestampToDate(shift.end_time),
		currentDate,
	) / 60);
};

/**
 * @returns {Boolean}
 * @param {Object} shift
 * @param {Number} shift.punch_in
 * @param {Number} shift.start_time
 * @param {Date} currentDate
 */
export const isTutorLateToPunchIn = (shift, currentDate = new Date()) => {
	return (
		!isNil(shift) &&
		isNil(shift.punch_in) &&
		minutesToShiftStart(shift, currentDate) < 0 &&
		minutesToShiftStart(shift, currentDate) >= -15
	);
};

/**
 * @returns {Boolean}
 */
export const isTutorLockedToPunchIn = (shift, currentDate = new Date()) => {
	return (
		!isNil(shift) &&
		isNil(shift.punch_in) &&
		minutesToShiftStart(shift, currentDate) < -15
	);
};

export const isPunchedIn = (shift) => {
	return !isNil(shift.punch_in) && isNil(shift.punch_out);
};

export const isPunchShift = (shift) => {
	if (!isNil(shift.scheduleType)) {
		return [TUTORING_SESSION_ID, WRITING_REVIEW_ID].includes(shift.scheduleType.data.id);

	}
};

export const isBreakShift = (shift) => {
	const scheduleTypeId = shift?.scheduleType?.data?.id ?? shift?.schedule_type_id;
	return [MEAL_BREAK_ID, REST_BREAK_ID].includes(scheduleTypeId);
};
export const isMealBreakShift = (shift) => {
	const scheduleTypeId = shift?.scheduleType?.data?.id ?? shift?.schedule_type_id;
	return scheduleTypeId === MEAL_BREAK_ID;
};

export const isRestBreakShift = (shift) => {
	const scheduleTypeId = shift?.scheduleType?.data?.id ?? shift?.schedule_type_id;
	return scheduleTypeId === REST_BREAK_ID;
};

export const shiftLengthInHours = (shift) => {
	return Math.floor((shift.end_time - shift.start_time) / 3600);
};

export const isAbleToRequestBreak = (shift) => {
	if (!isNil(shift)) {
		return isPunchedIn(shift) &&
			minutesToShiftEnd(shift) > 60 &&
			shiftLengthInHours(shift) >= 5 &&
			(isNil(shift.break) || isRestBreakShift(shift.break));
	} else {
		return false;
	}
};

export const getTimeElapsedForShiftString = (time) => {
	const punchInTime = new Date(time * 1000);
	const minuteDifference = differenceInMinutes(Date.now(), punchInTime);
	const hourDifference = differenceInHours(Date.now(), punchInTime);
	return `${hourDifference}h ${minuteDifference % 60}m`;
};

export {
	getSlotColorFromShiftType,
	getShiftTypeNameFromShiftTypeId,
	getOrigamiColorFromShiftTypeId,
	getSlotColorFromShiftTypeId,
};
