<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		viewBox="0 0 16 16"
	>
		<defs>
			<path
				id="a"
				d="M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm.8 12H7.2V7.2h1.6V12zm0-6.4H7.2V4h1.6v1.6z"
			/>
		</defs>
		<g
			fill="none"
			fill-rule="evenodd"
		>
			<mask
				id="b"
				fill="#fff"
			>
				<use xlink:href="#a" />
			</mask>
			<use
				fill="#484756"
				xlink:href="#a"
			/>
			<g
				:fill="iconColor"
				mask="url(#b)"
			>
				<path d="M0 0h16v16H0z" />
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	props: {
		width: {
			type: [Number, String],
			default: 30,
		},
		height: {
			type: [Number, String],
			default: 30,
		},
		iconColor: {
			type: String,
			default: "#484756",
		},
	},
};
</script>
