<template>
	<div class="vue-radio">
		<input
			:id="radioId"
			type="radio"
			:name="radioName"
			:value="radioValue"
			:checked="isRadioButtonChosen"
			:disabled="isDisabled"
			autocomplete="off"
			@change="handleInput"
		>
		<label
			:for="radioId"
		><p><slot name="text" /></p></label>
	</div>
</template>

<script>
export default {
	props: {
		// eslint-disable-next-line vue/require-default-prop
		value: null,
		isDisabled: {
			type: Boolean,
			default: false,
		},
		radioName: {
			type: String,
			required: true,
		},
		radioValue: {
			validator: (prop) => ["string", "number", "boolean", null].includes(typeof prop),
			default: null,
		},
		radioId: {
			type: String,
			required: true,
		},
		selected: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		/**
		 * Checks if the value (the v-model that is used by all radio-buttons to select the button) and the radioValue (the value emitted by this radio button) match
		 * @returns {Boolean}
		 */
		isRadioButtonChosen() {
			return this.radioValue === this.value;
		},
	},
	methods: {
		handleInput() {
			this.$emit("input", this.radioValue);
		},
	},
};
</script>

<style scoped>
.vue-radio {
	position: relative;
}

.vue-radio label {
	display: inline-block;
	height: 20px;
	position: relative;
	padding-left: 30px;
	margin-bottom: 0;
	cursor: pointer;
	vertical-align: super;
}
.vue-radio label:before,
.vue-radio label:after {
	position: absolute;
	content: "";
	border-radius: 50%;
}
.vue-radio label:before {
	left: 0;
	top: 0;
	width: 20px;
	height: 20px;
	border: 2px solid var(--c-blue);
}
.vue-radio label:after {
	top: 5px;
	left: 5px;
	width: 10px;
	height: 10px;
	background: var(--c-blue);
	transform: scale(0);
}
.vue-radio label p {
	line-height: 20px;
}

.vue-radio input[type="radio"] {
	opacity: 0;
	width: 90%;
	position: absolute;
	height: 40px;
	cursor: pointer;
	margin: -8px 0 0 0;
}
.vue-radio input[type="radio"]:checked + label:before {
	border-color: var(--c-blue);
}
.vue-radio input[type="radio"]:disabled:not(:checked) + label:before {
	border-color: #ccc;
}
.vue-radio input[type="radio"]:disabled:checked + label:before {
	opacity: 0.4;
	border-color: var(--c-blue);
}
.vue-radio input[type="radio"]:checked + label:after {
	transform: scale(1);
}
.vue-radio input[type="radio"]:disabled:checked + label:after {
	opacity: 0.4;
}
</style>
