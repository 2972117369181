import SubjectsAPI from "@/services/api/Subjects.js";

import TeacherContentAPI from "../../services/teacher-content.js";
import TeacherContentCategoriesAPI from "../../services/teacher-content-categories.js";
import TeacherContentTypesAPI from "../../services/teacher-content-types.js";

const getDefaultState = () => ({
	isLoading: true,
	teacherContent: {},
	subjects: [],
	categories: [],
	contentTypes: [],
	navbarMainTab: "Customers",
});

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations: {
		RESET_STATE(state) {
			Object.assign(state, getDefaultState());
		},
		SET_IS_LOADING(state, payload) {
			state.isLoading = payload.isLoading;
		},
		SET_TEACHER_CONTENT(state, payload) {
			state.teacherContent = payload.teacherContent;
		},
		SET_SUBJECTS(state, payload) {
			state.subjects = payload.subjects;
		},
		SET_CATEGORIES(state, payload) {
			state.categories = payload.categories;
		},
		SET_TEACHER_CONTENT_TYPES(state, payload) {
			state.contentTypes = payload.types;
		},
	},
	actions: {
		async showTeachersContent({ commit }, payload) {
			try {
				const response = await TeacherContentAPI.show(payload.teacherContentId, payload.params);
				commit("SET_TEACHER_CONTENT", {
					teacherContent: response.data.data,
				});
				return Promise.resolve(response.data.data);
			} catch (e) {
				return Promise.reject(e);
			} finally {
				commit("SET_IS_LOADING", {
					isLoading: false,
				});
			}
		},
		async updateTeacherContent({ dispatch }, { teacherContentId, formData }) {
			try {
				const response = await TeacherContentAPI.update(teacherContentId, formData);
				dispatch("showTeachersContent", {
					teacherContentId,
					params: { include: "subjects,category,type" },
				});
				return Promise.resolve(response.data);
			} catch (e) {
				return Promise.reject(e);
			}
		},
		async getSubjects({ commit }, payload) {
			try {
				const subjects = await SubjectsAPI.list(payload);
				commit("SET_SUBJECTS", {
					subjects: subjects.data.data,
				});
				return Promise.resolve(subjects.data.data);
			} catch (error) {
				return Promise.reject(error.response);
			}
		},

		async deleteTeachersContent({}, payload) {
			try {
				const response = await TeacherContentAPI.delete(payload.teacherContentId);
				return Promise.resolve(response);
			} catch (e) {
				return Promise.reject(e);
			}
		},
		async getCategories({ commit }) {
			try {
				const response = await TeacherContentCategoriesAPI.list();
				commit("SET_CATEGORIES", {
					categories: response.data.data,
				});
				return Promise.resolve(response.data.data);
			} catch (e) {
				return Promise.reject(e);
			}
		},
		async getTeacherContentTypes({ commit }) {
			try {
				const response = await TeacherContentTypesAPI.list();
				commit("SET_TEACHER_CONTENT_TYPES", {
					types: response.data.data,
				});
				return Promise.resolve(response);
			} catch (e) {
				return Promise.reject(e);
			}
		},
	},
};
