<template>
	<component
		:is="component"
		:shift-segment="shiftSegment"
	/>
</template>

<script>
import { MEAL_BREAK_ID, REST_BREAK_ID } from "@/modules/TutorSchedule/index.js";

import ShiftSegmentItem from "./ShiftSegmentItem.vue";
import BreakSegmentItem from "./BreakSegmentItem.vue";

export default {
	components: {
		ShiftSegmentItem,
		BreakSegmentItem,
	},
	props: {
		shiftSegment: {
			type: Object,
			required: true,
		},
	},
	computed: {
		component() {
			return [MEAL_BREAK_ID, REST_BREAK_ID].includes(this.shiftSegment.type.id) ?
				"BreakSegmentItem" :
				"ShiftSegmentItem";
		},
	},
};
</script>
