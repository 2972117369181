var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 16 16",
      },
    },
    [
      _c("defs", [
        _c("path", {
          attrs: {
            id: "a",
            d: "M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm.8 12H7.2V7.2h1.6V12zm0-6.4H7.2V4h1.6v1.6z",
          },
        }),
      ]),
      _vm._v(" "),
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("mask", { attrs: { id: "b", fill: "#fff" } }, [
          _c("use", { attrs: { "xlink:href": "#a" } }),
        ]),
        _vm._v(" "),
        _c("use", { attrs: { fill: "#484756", "xlink:href": "#a" } }),
        _vm._v(" "),
        _c("g", { attrs: { fill: _vm.iconColor, mask: "url(#b)" } }, [
          _c("path", { attrs: { d: "M0 0h16v16H0z" } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }