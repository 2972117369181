var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M14 2H6C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM7.63636 4.14286C6.83636 4.14286 6.18909 4.85 6.18909 5.71429L6.18182 18.2857C6.18182 19.15 6.82909 19.8571 7.62909 19.8571H16.3636C17.1636 19.8571 17.8182 19.15 17.8182 18.2857V8.92857H12.7273V4.14286H7.63636ZM13.4416 4.14286L13.6162 4.31747L13.4545 4.14286H13.4416ZM8 18H16V16H8V18ZM16 14H8V12H16V14Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }