import Vue from "vue";
import Vuex from "vuex";

import { store as Achievements } from "@/modules/Achievements/index.js";
import { store as Activity } from "@/modules/Activity/index.js";
import { store as Audio } from "@/modules/Audio/index.js";
import { store as Availabilities } from "@/modules/TutorAvailabilities/index.js";
import { store as BreaksCenter } from "@/modules/BreaksCenter/index.js";
import { store as BreaksManagementWidget } from "@/modules/BreaksManagementWidget/index.js";
import Classroom from "@/modules/Classroom/store/index.js";
import { store as Customers } from "@/modules/Customers/index.js";
import { store as Essay } from "@/modules/TutorEssay/index.js";
import { store as TeacherStudentsList } from "@/modules/TeacherStudentsList/index.js";
import { store as TutorReviewCenter } from "@/modules/TutorReviewCenter/index.js";
import { store as Lang } from "@/modules/Languages/index.js";
import { store as Lightbox } from "@/modules/Lightbox/index.js";
import Login from "@/modules/Authentication/store/Login.js"; // barrel file not working here
import MajorClarity from "@/modules/MajorClarity/store/index.js";
import { store as Math } from "@/modules/Math/index.js";
import { store as Navbar } from "@/modules/Navigation/index.js";
import { store as PunchClock } from "@/modules/PunchClock/index.js";
import { store as UserPreferences } from "@/modules/UserPreferences/index.js";
import { store as Recognition } from "@/modules/RecognitionEvents/index.js";
import { store as ServiceAnalytics } from "@/modules/ServiceAnalytics/index.js";
import Referrals from "@/modules/Referrals/store/index.js";
import { store as Settings } from "@/modules/UserSettings/index.js";
import { store as SiteAdministratorStudentList } from "@/modules/SiteAdministratorStudentList/index.js";
import { store as StudentGroups } from "@/modules/StudentGroups/index.js";
import StudentOnboarding from "@/modules/StudentOnboarding/store/index.js";
import { store as TutorDashboardSchedule } from "@/modules/TutorDashboardSchedule/index.js";
import { store as TutorDashboardTutorList } from "@/modules/TutorDashboardTutorList/index.js";
import { store as TutorSchedule } from "@/modules/TutorSchedule/index.js";
import { store as TutorScheduledHours } from "@/modules/TutorScheduledHours/index.js";
import { store as TutorShiftSummary } from "@/modules/TutorShiftSummary/index.js";
import { store as Whiteboard } from "@/modules/Whiteboard/index.js";

import Tutor from "./modules/Tutor/index.js";
import Toaster from "./modules/Toaster.js";
import Superuser from "./modules/Superuser/index.js";
import Student from "./modules/Student/index.js";
import Snackbar from "./modules/Snackbar.js";
import SiteAdministrator from "./modules/SiteAdministrator/index.js";
import SimpleModal from "./modules/SimpleModal.js";
import SharedSession from "./modules/SharedSession.js";
import PlatformManager from "./modules/PlatformManager/index.js";
import FileUpload from "./modules/FileUpload.js";
import Bookmark from "./modules/Bookmark.js";
import Banner from "./modules/Banner.js";
import Assessment from "./modules/Assessment.js";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		currentUser: null,
		isImpersonate: false,
		showNavbar: false,
	},
	getters: {
		currentUser(state) {
			return state.currentUser;
		},
	},
	mutations: {
		SET_CURRENT_USER(state, payload) {
			state.currentUser = payload.currentUser;
		},
		SET_IMPERSONATE(state, payload) {
			state.isImpersonate = payload;
		},
		SET_SHOW_NAVBAR(state, isShowing) {
			state.showNavbar = isShowing;
		},
	},
	modules: {
		Achievements,
		Activity,
		SiteAdministratorStudentList,
		Assessment,
		Audio,
		Availabilities,
		Banner,
		Bookmark,
		BreaksCenter,
		BreaksManagementWidget,
		Classroom,
		Customers,
		Essay,
		FileUpload,
		Lang,
		Lightbox,
		Login,
		MajorClarity,
		Math,
		Navbar,
		PlatformManager,
		UserPreferences,
		Recognition,
		ServiceAnalytics,
		Referrals,
		Settings,
		SharedSession,
		SimpleModal,
		SiteAdministrator,
		Snackbar,
		Student,
		StudentGroups,
		StudentOnboarding,
		Superuser,
		TeacherStudentsList,
		Toaster,
		Tutor,
		TutorDashboardTutorList,
		TutorReviewCenter,
		TutorShiftSummary,
		TutorScheduledHours,
		TutorSchedule,
		PunchClock,
		TutorDashboardSchedule,
		Whiteboard,
	},
});
