var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BModal",
    {
      ref: "transfer-session-modal",
      attrs: {
        id: "transfer-session-modal",
        "content-class": "transferSessionModal",
        "body-class": "transferSessionModal__body",
        "footer-class": "transferSessionModal__footer",
        "hide-header": true,
        "no-close-on-backdrop": true,
        "no-fade": true,
        centered: true,
      },
    },
    [
      _c(
        "button",
        {
          staticClass: "transferSessionModal__closeButton",
          attrs: { type: "button" },
          on: {
            click: function ($event) {
              return _vm.hideModal()
            },
          },
        },
        [_vm._v("\n\t\t×\n\t")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "modal-header transferSessionModal__header" }, [
        _c(
          "div",
          { staticClass: "transferSessionModal__icon" },
          [
            _c("IconArrowTransfer", {
              staticClass: "transferSessionModal__svg",
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("h2", { staticClass: "transferSessionModal__title" }, [
          _vm._v(
            "\n\t\t\tTransfer " +
              _vm._s(_vm.studentNameInSession) +
              " to...\n\t\t"
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.isProcessingTransfer
        ? _c("div", { staticClass: "processingTransfer" }, [
            _c(
              "div",
              {
                staticClass:
                  "tw-w-full tw-px-8 tw-flex tw-flex-col tw-justify-center tw-items-center tw-my-11",
              },
              [
                _c("BaseLoadingState", {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "transfer-loading-spinner",
                      expression: "'transfer-loading-spinner'",
                    },
                  ],
                  staticClass: "loadingSpinner",
                }),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "loadingState__text--font tw-text-base tw-mt-2 tw-text-black-medium tw-font-bold",
                  },
                  [_vm._v("\n\t\t\t\tHand off in progress...\n\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "loadingState__text--font tw-text-xs tw-mt-2 tw-text-black-medium",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\tA chat session will open up with the student shortly.\n\t\t\t"
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _c(
            "div",
            { staticClass: "tw-py-4 tw-p-9" },
            [
              _c("BaseInput", {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "tutor-search",
                    expression: "'tutor-search'",
                  },
                ],
                staticClass:
                  "searchInput tw-h-7 tw-inline-flex tw-align-middle tw-mt-1 tw-mr-1 tw-py-4 tw-w-full",
                attrs: { type: "text", placeholder: _vm.$t("search") },
                scopedSlots: _vm._u([
                  {
                    key: "prepend",
                    fn: function () {
                      return [
                        _c("IconSearch", {
                          staticClass:
                            "tw-w-6 tw-h-6 tw-text-sm tw-flex-shrink-0 tw-text-blue-regular tw-ml-3 tw--mr-1",
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "append",
                    fn: function () {
                      return [
                        _vm.searchText.length > 0
                          ? _c(
                              "BaseIconButton",
                              {
                                directives: [
                                  {
                                    name: "jest",
                                    rawName: "v-jest",
                                    value: "clearSearchedMessage",
                                    expression: "'clearSearchedMessage'",
                                  },
                                ],
                                staticClass: "tw-w-6 tw-h-6 tw-mr-0.5",
                                on: {
                                  click: function ($event) {
                                    _vm.searchText = ""
                                  },
                                },
                              },
                              [
                                _c("IconCircleX", {
                                  staticClass:
                                    "tw-w-3.5 tw-h-3.5 tw-text-blue-regular",
                                  attrs: { "icon-color": "currentColor" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.searchText,
                  callback: function ($$v) {
                    _vm.searchText = $$v
                  },
                  expression: "searchText",
                },
              }),
              _vm._v(" "),
              _c("TransferSessionModalFilters", {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "transfer-session-filters",
                    expression: "'transfer-session-filters'",
                  },
                ],
              }),
              _vm._v(" "),
              _vm.isTutorListAvailable
                ? _c(
                    "div",
                    { staticClass: "transferSessionModal__list tw-mb-8" },
                    _vm._l(_vm.availableLiveTutors, function (tutor, index) {
                      return _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: "tutor-item",
                              expression: "'tutor-item'",
                            },
                          ],
                          key: index,
                          staticClass: "transferSessionModal__listItem",
                          on: {
                            click: function ($event) {
                              return _vm.selectTutor(tutor)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "transferSessionModal__tutorName" },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(_vm.tutorName(tutor)) +
                                  "\n\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("BaseRadio", {
                            staticClass:
                              "transferSessionModal__radioButton tw-flex tw-my-auto tw-mx-0",
                            attrs: {
                              "radio-id": _vm.tutorFirstName(tutor),
                              "radio-name": "tutors",
                              "radio-value": _vm.tutorId(tutor),
                            },
                            model: {
                              value: _vm.newTutorId,
                              callback: function ($$v) {
                                _vm.newTutorId = $$v
                              },
                              expression: "newTutorId",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _c(
                    "div",
                    { staticClass: "transferSessionModal__list tw-mb-8" },
                    [
                      _c("p", [
                        _vm._v(
                          "There are no tutors matching filter you have selected."
                        ),
                      ]),
                    ]
                  ),
              _vm._v(" "),
              _c("TutorCommentForHandoff", {
                attrs: { files: _vm.fileList },
                on: {
                  onFileChange: _vm.handleFileChange,
                  onFileRemoval: _vm.removeAttachmentFile,
                },
                model: {
                  value: _vm.comment,
                  callback: function ($$v) {
                    _vm.comment = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "comment",
                },
              }),
            ],
            1
          ),
      _vm._v(" "),
      _c("template", { slot: "modal-footer" }, [
        _c(
          "div",
          {
            staticClass:
              "tw-flex tw-flex-row tw-justify-between tw-px-12 tw-w-full",
          },
          [
            _c(
              "div",
              { staticClass: "tw-flex tw-justify-start" },
              [
                _vm.currentUser.role === "tutor-manager"
                  ? _c(
                      "BaseButton",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "transfer-to-self",
                            expression: "'transfer-to-self'",
                          },
                        ],
                        attrs: {
                          type: "PRIMARY",
                          disabled: _vm.isTransferToSelfDisabled,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handoffToSelf.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("template", { slot: "text" }, [
                          _vm._v("\n\t\t\t\t\t\tTransfer to self\n\t\t\t\t\t"),
                        ]),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tw-flex tw-flex-row tw-flex-grow tw-justify-end",
              },
              [
                _c(
                  "BaseButton",
                  {
                    staticClass: "tw-ml-2.5",
                    attrs: { type: "CANCEL" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.hideModal()
                      },
                    },
                  },
                  [
                    _c("template", { slot: "text" }, [
                      _vm._v("\n\t\t\t\t\t\tCancel\n\t\t\t\t\t"),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "BaseButton",
                  {
                    staticClass: "tw-ml-2.5",
                    attrs: {
                      type: "SUBMIT",
                      "is-disabled": _vm.isSubmitButtonDisabled,
                    },
                    nativeOn: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.handoff()
                      },
                    },
                  },
                  [
                    _c("template", { slot: "text" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.submitHandoffText) +
                          "\n\t\t\t\t\t"
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }