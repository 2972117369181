<template>
	<div class="tw-flex tw-flex-wrap tw-items-end tw-w-2/3 tw-mb-20">
		<div class="tw-w-full tw-my-5">
			<BaseCheckbox
				v-model="areAbsencesChecked"
				v-jest="'show-absences-checkbox'"
				:checkbox-id="'show-absences-only'"
				:checkbox-value="'Show Absences Only'"
				@input="handleCheckbox"
			>
				<template slot="text">
					Show Absences Only
				</template>
			</BaseCheckbox>
		</div>
		<span
			class="tw-font-bold tw-text-sm tw-mr-4 tw-mt-2 tw-self-start"
		>
			Filter
		</span>
		<div class="tw-flex tw-flex-wrap tw-mr-5 tw-flex-grow tw-max-w-sm tw-items-start">
			<BaseDropdown
				v-jest="'tutor-list'"
				class="tw-w-44 tw-mr-2"
				:items="getTutorListWithoutDuplicates"
				:is-clearable="!isClearable"
				@deselect="clearSelectedTutors()"
			>
				<template slot="filters">
					{{ tutorsDropdownFilter }}
				</template>
				<template #default="{ item }">
					<BaseCheckbox
						v-model="selectedTutors"
						v-jest="'tutor-list-checkbox'"
						:checkbox-id="`tutor-${item.user_id}`"
						:checkbox-value="item.user_id"
					>
						<template slot="text">
							{{ item.name }}
						</template>
					</BaseCheckbox>
				</template>
			</BaseDropdown>
			<DatePicker
				v-model="selectedDate"
				v-jest="'date-picker'"
				class="tw-w-44 tw-m-0"
				:max-date="new Date()"
			>
				<template #default="{ inputValue, inputEvents }">
					<input
						class="filter-date-picker tw-h-10 tw-rounded-md tw-text-xs"
						:value="inputValue"
						placeholder="Shift Date"
						v-on="inputEvents"
					>
				</template>
			</DatePicker>
			<OrigamiButton
				v-jest="'show-results-button'"
				type="submit"
				class="tw-mt-3 tw-mr-3"
				@click.native="getFilteredBioBreakSummary"
			>
				Show Results
			</OrigamiButton>
			<OrigamiButton
				v-jest="'clear-results-button'"
				type="submit"
				variant="secondary"
				class="tw-mt-3"
				@click.native="clearAllFilters"
			>
				Reset Filters
			</OrigamiButton>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { isEmpty } from "lodash";
import { formatISO, startOfDay, endOfDay } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { OrigamiButton } from "@origami/vue2";

import BaseCheckbox from "@/components/BaseCheckbox.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";

export default {
	components: {
		OrigamiButton,
		DatePicker: () => import("v-calendar/lib/components/date-picker.umd.min.js"),
		BaseCheckbox,
		BaseDropdown,
	},
	data() {
		return {
			areAbsencesChecked: false,
			selectedTutors: [],
			selectedDate: new Date(),
		};
	},
	computed: {
		...mapState(["PlatformManager"]),
		getTutorListWithoutDuplicates() {
			return this.$store.getters["PlatformManager/ServiceAbsencesTable/getTutorListWithoutDuplicates"];
		},
		getStartOfSelectedDay() {
			return this.getSelectedDateInISO(startOfDay);
		},
		getEndOfSelectedDay() {
			return this.getSelectedDateInISO(endOfDay);
		},
		getAbsences() {
			return this.areAbsencesChecked || null;
		},
		isClearable() {
			return isEmpty(this.selectedTutors);
		},
		tutorsDropdownFilter() {
			return this.selectedTutors.length > 0
				? `Tutors +${this.selectedTutors.length}`
				: "Tutor List";
		},
	},
	async created() {
		await this.$store.dispatch("PlatformManager/ServiceAbsencesTable/getFilteredBioBreakSummary");
		await this.$store.dispatch("PlatformManager/ServiceAbsencesTable/getAllBioBreakTutors");
	},
	methods: {
		async getFilteredBioBreakSummary() {
			this.$store.dispatch("PlatformManager/ServiceAbsencesTable/updateFilters", {
				user_ids: this.selectedTutors,
				start_date: this.getStartOfSelectedDay,
				end_date: this.getEndOfSelectedDay,
			});

			await this.$store.dispatch("PlatformManager/ServiceAbsencesTable/getFilteredBioBreakSummary");
			await this.$store.dispatch("PlatformManager/ServiceAbsencesTable/getAllBioBreakTutors");
		},
		getSelectedDateInISO(timeOfDay) {
			const selectedDateInUTC = zonedTimeToUtc(this.selectedDate);
			return this.selectedDate instanceof Date ? formatISO(timeOfDay(selectedDateInUTC)) : null;
		},
		async handleCheckbox(value) {
			this.areAbsencesChecked = value;
			this.$store.dispatch("PlatformManager/ServiceAbsencesTable/updateFilters", {
				has_absence: this.getAbsences,
			});
			await this.getFilteredBioBreakSummary();
		},
		async clearSelectedTutors() {
			this.selectedTutors = [];
			await this.getFilteredBioBreakSummary();
		},
		async clearAllFilters() {
			this.areAbsencesChecked = false,
			this.selectedTutors = [],
			this.selectedDate = new Date();
			await this.getFilteredBioBreakSummary();
		},
	},
};
</script>

<style scoped>
	.filter-date-picker {
		padding: 6px 16px;
		border: 2px solid var(--c-gray);
	}
</style>
