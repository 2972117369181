<template>
	<button
		v-jest="'break-button'"
		v-bind="$attrs"
		class="tw-relative tw-rounded-full tw-block tw-w-10 tw-h-10 breakButton tw-border-[1px] tw-border-origami-grey-200 tw-bg-origami-white hover:tw-bg-origami-blue-300 hover:tw-text-origami-white tw-mr-1.5"
		:class="{
			'tw-bg-origami-blue-400 tw-text-origami-white hover:tw-bg-origami-blue-400': isActive,
		}"
	>
		<OrigamiIcon
			v-jest="'break-button-icon'"
			:name="iconName"
			class="tw-block"
		/>
		<span
			v-if="showNotification"
			v-jest="'break-button-notification'"
			class="tw-w-3 tw-h-3 tw-rounded-full tw-absolute tw-bottom-0 tw-right-0 tw-bg-origami-red-400"
			:class="{
				'activeNotification': isActive,
				'tw-bg-origami-red-200': $attrs['disabled'],
			}"
		/>
	</button>
</template>

<script>
import { OrigamiIcon } from "@origami/vue2";

export default {
	components: {
		OrigamiIcon,
	},
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
		iconName: {
			type: String,
			required: true,
		},
		showNotification: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style scoped>
.breakButton {
	box-shadow: var(--origami-shadow-base);
}

.activeNotification {
	outline: 1px solid var(--origami-white);
}

[disabled] {
	box-shadow: none;
	@apply tw-text-origami-text-low-emphasis;
	@apply hover:tw-text-origami-grey-300;
	@apply tw-bg-origami-grey-100;
	@apply hover:tw-bg-origami-grey-100;
	@apply tw-cursor-auto;
}
</style>
