<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		:width="width"
		:height="height"
		viewBox="0 0 16 20"
	>
		<defs>
			<path
				:id="`a${randomId}`"
				d="M16 7.059h-4.571V0H4.57v7.059H0l8 8.235 8-8.235zM0 17.647V20h16v-2.353H0z"
			/>
		</defs>
		<g
			fill="none"
			fill-rule="evenodd"
		>
			<mask
				:id="randomId"
				:fill="`${iconColor}`"
			>
				<use :xlink:href="`#a${randomId}`" />
			</mask>
			<use
				:fill="`${iconColor}`"
				:xlink:href="`#a${randomId}`"
			/>
			<g
				:fill="`${iconColor}`"
				:mask="`url(#${randomId})`"
			>
				<path d="M-3.429-1.176h23v24h-23z" />
			</g>
		</g>
	</svg>
</template>

<script>
import IconId from "./IconId.js";
export default {
	extends: IconId,
	props: {
		width: {
			type: [Number, String],
			default: 16,
		},
		height: {
			type: [Number, String],
			default: 20,
		},
		iconColor: {
			type: String,
			default: "#FFF",
		},
	},
};
</script>
