import AbstractMessage from "./AbstractMessage.js";

/**
 *
 * @param id
 * @param userId
 * @param createdAt
 * @param sequencePosition
 * @param status
 * @param {Boolean} isLastMessage
 * @param deliveredAt
 * @param {Boolean} isQuestion
 * @param {Boolean} isFailed
 * @param text
 * @param {Array} userReactions
 * @param {Number} bookmarkedDate
 * @constructor
 */
function MessageText({
	id,
	userId,
	createdAt,
	sequencePosition,
	status,
	isLastMessage,
	deliveredAt,
	isQuestion,
	isDeleted,
	isFailed,
	text,
	json,
	userReactions,
	bookmarkedAt = null,
}) {
	AbstractMessage.call(
		this,
		{
			id,
			userId,
			createdAt,
			sequencePosition,
			status,
			isLastMessage,
			deliveredAt,
			isQuestion,
			isDeleted,
			isFailed,
			userReactions,
		},
	);
	this.text = text;
	this.json = json;
	this.bookmarkedAt = bookmarkedAt;
}

MessageText.prototype = Object.create(AbstractMessage.prototype);

export default MessageText;
