import { getFlag } from "@paper-co/web-toolkit";

import { isEmbedded } from "@/modules/EmbeddedUtilities/index.js";

export default async function(to, from, next) {
	const featureFlagBlockStartLiveSessionIsEnabled = await getFlag("core-245-block-start-live-session");
	const isABQUser = this.state.currentUser?.district_id === 991;

	if (featureFlagBlockStartLiveSessionIsEnabled && isABQUser) {
		if (isEmbedded()) {
			return next("/embedded/student/sessions");
		} else {
			return next("/dashboard-v2");
		}
	} else {
		return next();
	}
}
