var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "tw-flex tw-flex-wrap tw-justify-between" }, [
        _c(
          "div",
          { staticClass: "tw-flex" },
          [
            _c(
              _setup.OrigamiButton,
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: `open-filters-sidebar-button`,
                    expression: "`open-filters-sidebar-button`",
                  },
                ],
                class: {
                  "!tw-bg-origami-grey-200 !tw-text-transparent !tw-border-none":
                    _setup.isLoading,
                },
                attrs: {
                  "data-cy": `open-filters-sidebar-button`,
                  variant: "secondary",
                  "aria-label": "Filters",
                  "is-disabled": _setup.isLoading,
                },
                nativeOn: {
                  click: function ($event) {
                    return _setup.store.dispatch(
                      "ServiceAnalytics/openFiltersSidebar"
                    )
                  },
                },
              },
              [_vm._v("\n\t\t\t\tFilters\n\t\t\t")]
            ),
            _vm._v(" "),
            !_setup.isLoading
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: `service-analytics-filter-tag-container`,
                        expression: "`service-analytics-filter-tag-container`",
                      },
                    ],
                    staticClass: "tw-flex tw-ml-3 tw-overflow-hidden",
                  },
                  _vm._l(_setup.selectedFilters, function (filter, key) {
                    return _c(_setup.FilterTag, {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: `service-analytics-filter-tag`,
                          expression: "`service-analytics-filter-tag`",
                        },
                      ],
                      key: key,
                      attrs: { tags: filter, name: key },
                      on: {
                        "close-filter-tags-group": _setup.closeFilterTagsGroup,
                      },
                    })
                  }),
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(_setup.ServiceAnalyticsFilterSidebar, {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: `service-analytics-filters-sidebar`,
            expression: "`service-analytics-filters-sidebar`",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }