<template>
	<BreakDetails v-if="canDisplayDetails">
		<template #breakDetails>
			<p
				v-if="formattedRestBreakTimes"
				v-jest="'rest-break-details'"
				v-data-cy="'rest-break-details'"
				class="tw-mt-4"
			>
				Rest break at {{ formattedRestBreakTimes.startTime }} to {{ formattedRestBreakTimes.endTime }}
			</p>
		</template>
	</BreakDetails>
</template>

<script>
import { formatIsoDateToHM } from "@/utilities/dateHelpers.js";

import BreakDetails from "../templates/BreakDetails.vue";

export default {
	components: {
		BreakDetails,
	},
	computed: {
		canDisplayDetails() {
			return this.hasRestBreak && this.isOnRestBreakWorkflow;
		},
		hasRestBreak() {
			return this.$store.getters["TutorSchedule/hasRestBreak"];
		},
		isOnBioBreak() {
			return this.$store.getters["TutorSchedule/isOnBioBreak"];
		},
		isOnRestBreakWorkflow() {
			return this.$store.getters["BreaksManagementWidget/isOnRestBreakWorkflow"];
		},
		restBreakSegment() {
			return this.$store.getters["TutorSchedule/getCurrentRestBreak"] ||
				this.$store.getters["TutorSchedule/getUpcomingRestBreak"] ||
				this.$store.getters["TutorSchedule/getLateRestBreak"];
		},
		formattedRestBreakTimes() {
			return this.restBreakSegment ? {
				startTime: formatIsoDateToHM(this.restBreakSegment.start_time),
				endTime: formatIsoDateToHM(this.restBreakSegment.end_time),
			} : null;
		},
	},
};
</script>
