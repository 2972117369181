<script setup>
import { computed } from "vue";
import { Line as ChartLine } from "vue-chartjs";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Tooltip,
	Legend,
} from "chart.js";

import {
	chartLineData,
	chartDefaultLineColorOption,
	chartDefaultLineAreaColorOption,
	chartLineAxe,
	chartLineOptions,
} from "@/utilities/charts.js";

import { TARGET_AVERAGE_TUTOR_LOAD } from "../utilities/ConstantValues.js";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Tooltip,
	Legend,
);

const props = defineProps({
	chartData: {
		type: Array,
		required: true,
	},
	colorArea: {
		type: String,
		default: () => chartDefaultLineAreaColorOption,
	},
	color: {
		type: String,
		default: () => chartDefaultLineColorOption,
	},
	xAxes: {
		type: Object,
		default: () => chartLineAxe(),
	},
	yAxes: {
		type: Object,
		default: () => chartLineAxe(),
	},
});

const data = computed(() =>
	chartLineData(props.chartData, props.color, props.colorArea, true, TARGET_AVERAGE_TUTOR_LOAD, "Tutor Load"),
);
const options = computed(() => ({
	...chartLineOptions(props.chartData, true),
	scales: {
		x: props.xAxes,
		y: props.yAxes,
	},
}));
</script>
<template>
	<ChartLine
		v-data-cy="'service-analytics-line-chart'"
		:data="data"
		:options="options"
	/>
</template>
