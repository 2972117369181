var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BreakButton", {
    directives: [
      {
        name: "jest",
        rawName: "v-jest",
        value: "rest-break-button",
        expression: "'rest-break-button'",
      },
      {
        name: "data-cy",
        rawName: "v-data-cy",
        value: "rest-break-button",
        expression: "'rest-break-button'",
      },
    ],
    attrs: {
      "aria-label": "Go on rest break",
      "icon-name": "break",
      "is-active": _vm.isOnRestBreakWorkflow,
      disabled: _vm.isDisabled,
      "show-notification": _vm.hasRestBreak,
    },
    nativeOn: {
      click: function ($event) {
        return _vm.requestRestBreak.apply(null, arguments)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }