var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 12 9",
      },
    },
    [
      _c("defs", [
        _c("path", {
          attrs: {
            id: _vm.randomId,
            d: "M11.618 2.25c-.005.005-.012.007-.017.012l.003.002-5.807 5.899a1.37 1.37 0 0 1-.968.406c-.353.01-.709-.11-.984-.368-.004-.004-.006-.01-.011-.015l-.002.002L.419 4.995a1.307 1.307 0 0 1-.039-1.88 1.39 1.39 0 0 1 1.928-.039l2.454 2.298L9.637.423l.002.003c.005-.005.006-.012.011-.017A1.388 1.388 0 0 1 11.578.37c.544.51.562 1.35.04 1.88",
          },
        }),
      ]),
      _vm._v(" "),
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("mask", { attrs: { id: `a${_vm.randomId}`, fill: "#fff" } }, [
          _c("use", { attrs: { "xlink:href": `#${_vm.randomId}` } }),
        ]),
        _vm._v(" "),
        _c("use", {
          attrs: { fill: _vm.iconColor, "xlink:href": `#${_vm.randomId}` },
        }),
        _vm._v(" "),
        _c("g", { attrs: { mask: `url(#a${_vm.randomId})` } }, [
          _c("path", { attrs: { d: "M-3.428-4.285h18.854v17.14H-3.428z" } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }