var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isEssayUploadedForReview
        ? _c("UploadContainer", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "essay-upload",
                expression: "'essay-upload'",
              },
            ],
            staticClass: "tw-mx-0",
            attrs: {
              "doc-validator": _vm.essayFileDocValidator,
              "mime-types": _vm.essayFileMimeTypes,
              "file-upload-error-message": _vm.fileUploadErrorMessage,
              embedded: _vm.embedded,
            },
            on: {
              "handle-essay-file-input": _vm.handleEssayFileInput,
              "handle-essay-file-input-from-google-drive":
                _vm.handleGooglePickerEssayFileInput,
              error: _vm.handleFileUploadError,
            },
          })
        : _c("FileDropZone", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isLoadingGoogleFile,
                expression: "!isLoadingGoogleFile",
              },
              {
                name: "jest",
                rawName: "v-jest",
                value: "essay-file-dropzone",
                expression: "'essay-file-dropzone'",
              },
            ],
            staticClass:
              "tw-flex tw-text-blue-regular tw-rounded tw-p-2 tw-my-4",
            attrs: { clickable: "" },
            on: { change: _vm.handleEssayFileInput },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ trigger, isDraggingOver, inputZIndex }) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "tw-flex-grow tw-flex tw-items-center tw-overflow-hidden",
                      },
                      [
                        _c("IconEssayOutlined", {
                          staticClass: "tw-flex-shrink-0 tw-m-2",
                          attrs: {
                            width: "16",
                            height: "16",
                            "aria-hidden": "true",
                            focusable: "false",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "tw-text-sm tw-font-bold tw-overflow-hidden tw-overflow-ellipsis",
                          },
                          [_vm._v(_vm._s(_vm.dropzoneText))]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "BDropdown",
                      {
                        staticClass: "ChangeFileDropdown tw-relative",
                        style: {
                          zIndex: isDraggingOver ? "auto" : inputZIndex + 1,
                        },
                        attrs: {
                          right: "",
                          "no-caret": "",
                          size: "sm",
                          variant: "link",
                          "popper-opts": { positionFixed: true },
                        },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "button-content",
                              fn: function () {
                                return [
                                  !_vm.embedded
                                    ? _c(
                                        "span",
                                        { staticClass: "tw-font-bold" },
                                        [_vm._v(_vm._s(_vm.$t("changeFile")))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("IconCaret", {
                                    staticClass: "tw-mt-0 tw-ml-2",
                                    attrs: {
                                      "aria-hidden": "true",
                                      focusable: "false",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(" "),
                        _vm.embedded
                          ? _c("BDropdownText", [
                              _c(
                                "span",
                                { staticClass: "tw-font-bold tw-text-sm" },
                                [_vm._v(_vm._s(_vm.$t("changeFile")))]
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "BDropdownItem",
                          { on: { click: trigger } },
                          [
                            _c("IconComputer", { staticClass: "tw-mr-2" }),
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.$t("browseFiles")) +
                                "\n\t\t\t\t"
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("GooglePicker", {
                          attrs: {
                            "doc-validator": _vm.essayFileDocValidator,
                            "mime-types": _vm.essayFileMimeTypes,
                          },
                          on: {
                            change: _vm.handleGooglePickerEssayFileInput,
                            "change-start": function ($event) {
                              _vm.isLoadingGoogleFile = true
                            },
                            "change-end": function ($event) {
                              _vm.isLoadingGoogleFile = false
                            },
                            error: _vm.handleFileUploadError,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({
                                  trigger: triggerGooglePicker,
                                  isLoaded,
                                }) {
                                  return [
                                    isLoaded
                                      ? _c(
                                          "BDropdownItem",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.openGooglePicker(
                                                  triggerGooglePicker,
                                                  "essay"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("IconGoogleDrive", {
                                              staticClass: "tw-mr-2",
                                              attrs: {
                                                "aria-hidden": "true",
                                                focusable: "false",
                                              },
                                            }),
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t" +
                                                _vm._s(_vm.$t("googleDrive")) +
                                                "\n\t\t\t\t\t\t"
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }