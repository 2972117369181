<template>
	<div class="scheduleInfo">
		<div class="scheduleInfo__warningMessageContainer">
			<OrigamiIcon
				v-data-cy="'schedule-info-button'"
				name="info"
				width="16"
				height="16"
				class="pull-left"
			/>
			<div
				v-data-cy="'schedule-info-warning-message'"
				class="scheduleInfo__warningMessage"
			>
				In order for you to be scheduled for tutoring, you <strong>must</strong> set your future
				availability in the
				<a
					v-data-cy="'schedule-info-availability-link'"
					href="/tutors#availability"
					class="scheduleInfo__availabilitiesLink"
					@click.stop.prevent="toAvailabilities"
				>Availability</a>
				section. This will help us understand which days and times you are available.
			</div>
		</div>
		<div class="scheduleInfo__infoContainer">
			<div
				v-data-cy="'schedule-info-title'"
				class="scheduleInfo__title"
			>
				Schedule
				<span
					v-data-cy="'tutor-dashboard-schedule-download'"
					class="scheduleInfo__link scheduleInfo__link--floatRight"
				><button
					class="scheduleInfo__linkText"
					@click="getTutorSchedule"
				>Download Schedule</button>
				</span>
				<span
					v-data-cy="'tutor-dashboard-schedule-legend'"
					class="scheduleInfo__link scheduleInfo__link--floatRight"
				><a
					href="#tutorDashboardScheduleLegend"
					class="scheduleInfo__linkText"
				>Legend</a></span>
			</div>
			<div
				v-data-cy="'schedule-info-paragraph'"
				class="scheduleInfo__paragraph"
			>
				Below is a calendar with your scheduled shifts.
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { OrigamiIcon } from "@origami/vue2";

import Account from "@/services/api/Account.js";
import { downloadLinkAsFile } from "@/utilities/links.js";

export default {
	components: {
		OrigamiIcon,
	},
	computed: {
		...mapState(["Tutor"]),
	},
	methods: {
		async getTutorSchedule() {
			try {
				const { data } = await Account.getICSSchedule();
				const blob = new Blob([data], { type: "text/calendar" });
				const fileName = "schedule.ics";
				if (window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveBlob(blob, fileName);
				} else {
					downloadLinkAsFile(window.URL.createObjectURL(blob), fileName);
				}
			} catch (e) {
				Sentry.captureException(e);
			}
		},
		toAvailabilities() {
			this.$store.commit("Tutor/Dashboard/SET_CURRENT_TAB", {
				currentTab: "availability",
			});
			window.location.assign(`${window.location.pathname}#availability`);
		},
	},
};
</script>
<style scoped>
.bold {
	color: var(--c-blue);
	font-weight: bold;
}
.scheduleInfo__warningMessageContainer {
	margin: 24px 0;
	padding: 11px 20px 15px;
	border-radius: 5px;
	background-color: rgba(255, 220, 137, 0.2);
	border: solid 1px #ffdc8a;
}
.scheduleInfo__warningMessage {
	font-size: 15px;
	line-height: 1.6;
	color: var(--c-black);
	font-weight: 500;
	margin: 0;
}
.scheduleInfo__infoContainer {
	flex: 0 0 100%;
	max-width: 100%;
}
.scheduleInfo__title {
	font-size: 22px;
	color: #4656a1;
	font-weight: 600;
	line-height: 1.5em;
	margin-bottom: 15px;
}
.scheduleInfo__link {
	margin: 0px 15px;
	font-size: 14px;
	color: var(--c-blue);
}

.scheduleInfo__availabilitiesLink {
	color: var(--c-blue);
}

.scheduleInfo__linkText {
	color: var(--c-blue);
}

.scheduleInfo__link--floatRight {
	float: right;
}

.scheduleInfo__paragraph {
	-webkit-font-smoothing: antialiased;
	font-weight: 300;
	font-family: "MonumentGrotesk", "Open Sans", Arial, sans-serif;
}
</style>
