var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tutorComment" }, [
    _c("div", { staticClass: "tutorComment__title" }, [
      _vm._v(
        "\n\t\tShare any comments that could be helpful for other tutors\n\t"
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tutorComment__title tutorComment__title--optional" },
      [_vm._v("\n\t\t(optional)\n\t")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isAddingComment,
            expression: "!isAddingComment",
          },
        ],
        staticClass: "tutorComment__buttonAdd",
        on: {
          click: function ($event) {
            _vm.isAddingComment = !_vm.isAddingComment
          },
        },
      },
      [_vm._v("\n\t\tAdd comment\n\t")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isAddingComment,
            expression: "isAddingComment",
          },
        ],
        staticClass: "tutorComment__input",
      },
      [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.comment,
              expression: "comment",
            },
            {
              name: "data-cy",
              rawName: "v-data-cy",
              value: "assessment-comment",
              expression: "'assessment-comment'",
            },
          ],
          staticClass: "assessment__feedback",
          attrs: {
            placeholder: _vm.commentPlaceholder,
            maxlength: _vm.maxChars,
          },
          domProps: { value: _vm.comment },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.comment = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "input__charactersLeft" }, [
          _vm.isCharacterLimitReachedForComment
            ? _c("span", { staticClass: "input__charactersLeft--alert" }, [
                _vm._v("\n\t\t\t\tCharacter limit reached.\n\t\t\t"),
              ])
            : _vm._e(),
          _vm._v(
            "\n\t\t\t" +
              _vm._s(`${_vm.numCharactersRemaining}/${_vm.maxChars}`) +
              "\n\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "input__info" }, [
          _vm._v(
            "\n\t\t\tComments for other tutors should be general and not specifically relate to any subject.\n\t\t"
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }