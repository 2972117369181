import { inRange, isNil, orderBy } from "lodash";
import { isAfter, isBefore } from "date-fns";

import SchedulesAPI from "@/services/api/Schedules.js";
import { isBreakShift, isMealBreakShift, isPunchShift, isRestBreakShift } from "@/utilities/ScheduleHelpers.js";
import { formatDateToTimestamp, formatTimestampToDate } from "@/utilities/dateHelpers.js";

export const state = {
	breakRejectedResponse: null,
	breakRequestStatus: "NONE",
};

export const mutations = {
	SET_STATUS_OF_BREAK_REJECT_RESPONSE(state, payload) {
		state.breakRejectedResponse = payload.response;
	},
	SET_BREAK_REQUEST_STATUS(state, payload) {
		state.breakRequestStatus = payload.status;
	},
};

export const getters = {
	getUpcomingPunchInShift: (state, getters, rootState) => () => {
		const upcomingShiftsArray = Object.values(rootState.TutorShiftSummary.upcomingShifts);
		let breakShift = null;
		return (upcomingShiftsArray).find((shift) => {
			const currentTime = formatDateToTimestamp(new Date());
			const startTime = shift.start_time;
			const endTime = shift.end_time;
			let startOfPunchInShift = startTime - 15 * 60;
			if (isBreakShift(shift)) {
				breakShift = shift;
			}

			if (!isNil(breakShift) && !isBreakShift(shift)) {
				const breakDuration = breakShift.end_time - breakShift.start_time;
				const startOfPostBreakShift = startTime - breakDuration;
				startOfPunchInShift = breakDuration === (35 * 60) ? startOfPostBreakShift : startOfPunchInShift;

				shift.postBreakShift = true;
				breakShift = null;
			}

			if (shift.postBreakShift && isNil(shift.punch_in)) {
				return true;
			}

			return (
				isPunchShift(shift) &&
				isNil(shift.punch_out) &&
				inRange(currentTime, startOfPunchInShift, endTime)
			);
		});
	},
	getShiftWithSegments: (state, getters, rootState) => () => {
		const currentPunchedInShift = getters.getUpcomingPunchInShift();

		if (currentPunchedInShift) {
			const segmentsInShift = Object.values(rootState.TutorShiftSummary.upcomingShifts)
				.filter((upcomingShift) => upcomingShift.segment_id === currentPunchedInShift.segment_id)
				.filter((upcomingShift) => isPunchShift(upcomingShift))
				.sort((segmentA, segmentB) => segmentA.start_time - segmentB.start_time);

			return {
				...currentPunchedInShift,
				start_time: segmentsInShift[0].start_time,
				end_time: segmentsInShift[segmentsInShift.length - 1].end_time,
				punch_in: segmentsInShift[0].punch_in,
				punch_out: segmentsInShift[segmentsInShift.length - 1].punch_out,
				segments: segmentsInShift,
			};
		} else {
			return {};
		}
	},
	getUpcomingBreak: (state, getters, rootState) => (currentTime) => {
		const sortedUpcomingShiftSegments = orderBy(Object.values(rootState.TutorShiftSummary.upcomingShifts), ["start_time"], ["asc"]);
		const upcomingPunchInShift = getters.getUpcomingPunchInShift();
		const nextBreakSegment = sortedUpcomingShiftSegments
			.filter(isBreakShift)
			.find(({ start_time, end_time }) =>
				isBefore(currentTime, formatTimestampToDate(start_time)) ||
					(
						isAfter(currentTime, formatTimestampToDate(start_time)) &&
						isBefore(currentTime, formatTimestampToDate(end_time))
					)
					|| (
						isAfter(currentTime, formatTimestampToDate(start_time)) &&
						upcomingPunchInShift?.start_time == end_time &&
						isNil(upcomingPunchInShift.punch_in)
					),
			);
		return nextBreakSegment || null;
	},
	getPostBreakShift: (state, getters, rootState) => () => {
		const sortedUpcomingShiftSegments = orderBy(Object.values(rootState.TutorShiftSummary.upcomingShifts), ["start_time"], ["asc"]);
		const upcomingPunchInShiftStartTime = getters.getUpcomingPunchInShift()?.start_time;
		const breakShift = sortedUpcomingShiftSegments.find((shift) =>
			isBreakShift(shift) && (shift.end_time === upcomingPunchInShiftStartTime),
		);

		return !isNil(breakShift) ?
			(sortedUpcomingShiftSegments.find((shift) =>
				!isBreakShift(shift) &&
				shift.start_time === breakShift?.end_time &&
				isNil(shift.punch_in),
			)) : null;
	},
	getUnpunchedPostRestBreakShift(state, getters, rootState) {
		const sortedUpcomingShiftSegments = orderBy(
			Object.values(rootState.TutorShiftSummary.upcomingShifts),
			["start_time"],
			["asc"],
		);
		let restBreakShift, unpunchedPostRestBreakShift;

		sortedUpcomingShiftSegments.every((shiftSegment) => {
			if (isRestBreakShift(shiftSegment)) {
				restBreakShift = shiftSegment;
			}
			if (shiftSegment.start_time === restBreakShift?.end_time && isNil(shiftSegment.punch_in)) {
				unpunchedPostRestBreakShift = shiftSegment;
				return false;
			}
			return true;
		});
		return unpunchedPostRestBreakShift;
	},
	hasMealBreak(state, getters, rootState) {
		const sortedUpcomingShiftSegments = orderBy(
			Object.values(rootState.TutorShiftSummary.upcomingShifts),
			["start_time"],
			["asc"],
		);
		return sortedUpcomingShiftSegments?.some((shiftSegment) => isMealBreakShift(shiftSegment));
	},
};

export const actions = {
	async punchInFromButton({ commit }) {
		const response = await SchedulesAPI.startShift({ include: "scheduleType" });
		commit("TutorShiftSummary/SET_SHIFT_IN_UPCOMING_SHIFTS", {
			shift: response.data.data,
		}, { root: true });
	},
	async punchOutFromButton(context, payload) {
		const data = {
			schedule_id: payload.shiftId,
		};
		await SchedulesAPI.endShift(data);
	},
};

export default { namespaced: true, state, mutations, actions, getters };
