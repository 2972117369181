var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { ref: "dropDown", staticClass: "tw-w-full tw-relative" }, [
    !_vm.collapseLabel
      ? _c(
          "label",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "drop-down-label",
                expression: "'drop-down-label'",
              },
            ],
            staticClass: "tw-font-bold tw-text-sm tw-text-xs tw-mb-[7px]",
            class: _vm.labelClass,
          },
          [_vm._v("\n\t\t" + _vm._s(_setup.props.label) + "\n\t")]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "drop-down",
            expression: "'drop-down'",
          },
        ],
        staticClass:
          "tw-bg-origami-white tw-p-2 hover:tw-bg-origami-grey-100 hover:tw-cursor-pointer tw-rounded tw-border-2 tw-border-grey-dark tw-flex tw-align-middle tw-h-[40px] tw-justify-between tw-px-3.5",
        class: {
          "tw-opacity-60": _setup.isDropDownDisabled,
          "tw-border-blue-regular":
            _setup.state.isOpen || !_setup.hasNoSelections,
        },
        on: { click: _setup.toggleIsOpen },
      },
      [
        _c(
          "span",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "drop-down-input-text",
                expression: "'drop-down-input-text'",
              },
            ],
            staticClass: "tw-text-xs",
            class: {
              "tw-text-blue-regular tw-font-bold":
                _setup.state.isOpen || !_setup.hasNoSelections,
            },
          },
          [_vm._v("\n\t\t\t" + _vm._s(_setup.inputText) + "\n\t\t")]
        ),
        _vm._v(" "),
        _c(_setup.OrigamiIcon, {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "drop-down-icon",
              expression: "'drop-down-icon'",
            },
          ],
          class: {
            "tw-text-blue-regular": _setup.icon === "close-full",
            "tw-mt-1": _setup.icon !== "close-full",
          },
          attrs: {
            width: _setup.icon === "close-full" ? 20 : 12,
            height: _setup.icon === "close-full" ? 20 : 12,
            name: _setup.icon,
          },
          nativeOn: {
            click: function ($event) {
              return _setup.handleIconClick.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _setup.state.isOpen,
            expression: "state.isOpen",
          },
        ],
        staticClass:
          "tw-absolute tw-bg-origami-white tw-rounded tw-w-full tw-max-h-[195px] tw-overflow-y-scroll tw-drop-shadow-xl tw-z-[9999]",
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _setup.state.searchText,
              expression: "state.searchText",
            },
            {
              name: "jest",
              rawName: "v-jest",
              value: "search",
              expression: "'search'",
            },
          ],
          staticClass:
            "search-field tw-text-xs tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-regular tw-rounded-sm tw-my-1 tw-ml-1 tw-py-2 tw-pr-4 tw-pl-8 tw-w-[calc(100%-6px)]",
          attrs: {
            id: `search-${_setup.uuidv4()}`,
            type: "text",
            placeholder: "Search",
          },
          domProps: { value: _setup.state.searchText },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_setup.state, "searchText", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "tw-list-none" },
          [
            _vm._l(_setup.state.displayList, function (item) {
              return _c(
                "li",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _setup.showItemsList,
                      expression: "showItemsList",
                    },
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "select-item",
                      expression: "'select-item'",
                    },
                    {
                      name: "data-cy",
                      rawName: "v-data-cy",
                      value: "select-item",
                      expression: "'select-item'",
                    },
                  ],
                  key: item.id,
                  staticClass: "tw-text-sm tw-p-2 hover:tw-bg-origami-grey-100",
                },
                [
                  _setup.props.isMultiple
                    ? _c(_setup.BaseCheckbox, {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "select-item-check",
                            expression: "'select-item-check'",
                          },
                          {
                            name: "data-cy",
                            rawName: "v-data-cy",
                            value: "select-item-check",
                            expression: "'select-item-check'",
                          },
                        ],
                        staticClass: "hover:tw-cursor-pointer",
                        attrs: {
                          "checkbox-id": `select-item-${_setup.uuidv4()}`,
                          "checkbox-value": item,
                          value: _setup.isSelected(item),
                        },
                        on: { input: () => _setup.addItemToSelected(item) },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "text",
                              fn: function () {
                                return [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "jest",
                                          rawName: "v-jest",
                                          value: "item-text",
                                          expression: "'item-text'",
                                        },
                                        {
                                          name: "data-cy",
                                          rawName: "v-data-cy",
                                          value: "select-item-text",
                                          expression: "'select-item-text'",
                                        },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _setup.props.getItemText(item)
                                          ) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _c(_setup.BaseRadio, {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "select-item-radio",
                            expression: "'select-item-radio'",
                          },
                          {
                            name: "data-cy",
                            rawName: "v-data-cy",
                            value: "select-item-radio",
                            expression: "'select-item-radio'",
                          },
                        ],
                        staticClass: "hover:tw-cursor-pointer",
                        attrs: {
                          value: _setup.isSelected(item) ? item.id : 0,
                          "radio-name": `radio-select-item-${item.id}`,
                          "radio-id": `radio-select-item-${_setup.uuidv4()}`,
                          "radio-value": item.id,
                        },
                        on: { input: () => _setup.selectItem(item) },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "text",
                              fn: function () {
                                return [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "jest",
                                          rawName: "v-jest",
                                          value: "item-text",
                                          expression: "'item-text'",
                                        },
                                        {
                                          name: "data-cy",
                                          rawName: "v-data-cy",
                                          value: "select-item-text",
                                          expression: "'select-item-text'",
                                        },
                                      ],
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _setup.props.getItemText(item)
                                          ) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                ],
                1
              )
            }),
            _vm._v(" "),
            _c("li", [
              !_setup.state.debouncedSearchText &&
              _setup.props.items.length > _setup.props.itemLimit
                ? _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "search-prompt",
                          expression: "'search-prompt'",
                        },
                      ],
                      staticClass: "tw-text-sm tw-text-center",
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\tUse Search for more results\n\t\t\t\t"
                      ),
                    ]
                  )
                : _setup.state.debouncedSearchText &&
                  _setup.state.debouncedSearchText.length < 3
                ? _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "search-prompt",
                          expression: "'search-prompt'",
                        },
                      ],
                      staticClass: "tw-text-sm tw-mt-5 tw-mb-20 tw-text-center",
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\tYou must type a minimum of 3 characters to search\n\t\t\t\t"
                      ),
                    ]
                  )
                : _setup.state.debouncedSearchText.length >= 3 &&
                  !_setup.state.displayList.length
                ? _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "search-prompt",
                          expression: "'search-prompt'",
                        },
                      ],
                      staticClass: "tw-text-sm tw-mt-5 tw-mb-24 tw-text-center",
                    },
                    [
                      _vm._v(
                        '\n\t\t\t\t\tNo search results for "' +
                          _vm._s(_setup.state.debouncedSearchText) +
                          '"\n\t\t\t\t'
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }