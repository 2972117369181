var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BreakDetails", {
    scopedSlots: _vm._u([
      {
        key: "breakDetails",
        fn: function () {
          return [
            _c(
              "p",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "bio-break-details",
                    expression: "'bio-break-details'",
                  },
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "bio-break-details",
                    expression: "'bio-break-details'",
                  },
                ],
                staticClass: "tw-font-bold tw-text-sm",
              },
              [
                _vm._v(
                  "\n\t\t\t" + _vm._s(_vm.remainingTimeInMinutes) + "\n\t\t"
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }