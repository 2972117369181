var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "tw-w-full tw-max-w-[1000px] tw-mt-2" },
    [
      _c(
        "div",
        {
          staticClass:
            "tw-bg-origami-white tw-px-6 tw-py-4 tw-mr-4 tw-flex tw-items-start tw-justify-between",
        },
        [
          _c(_setup.ServiceDashboardSessionLoadHeader, {
            attrs: { "data-cy": "session-load-header" },
          }),
          _vm._v(" "),
          _c(
            _setup.OrigamiButton,
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "show-graph-button",
                  expression: "'show-graph-button'",
                },
              ],
              attrs: { "data-cy": "show-graph-button", variant: "tertiary" },
              nativeOn: {
                click: function ($event) {
                  return _setup.handleShowGraph.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n\t\t\t" + _vm._s(_setup.graphButtonText) + "\n\t\t")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(_setup.ServiceDashboardSessionLoadBarGraph, {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _setup.isGraphShowing,
            expression: "isGraphShowing",
          },
          {
            name: "jest",
            rawName: "v-jest",
            value: "bar-chart",
            expression: "'bar-chart'",
          },
        ],
        staticClass: "tw-bg-origami-white tw-mr-4 tw-px-6 tw-py-2",
        attrs: { "data-cy": "bar-chart" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }