var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "OrigamiCard",
    { staticClass: "tw-p-4" },
    [
      _vm._t("header"),
      _vm._v(" "),
      _vm.isLoading
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "loading",
                  expression: "'loading'",
                },
              ],
              staticClass:
                "tw-h-44 tw-flex tw-justify-center tw-items-center tw-w-full",
            },
            [_vm._t("loading")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "div",
            {
              staticClass:
                "tw-flex tw-flex-col tw-align-center tw-gap-6 tw-w-full xs:tw-justify-between xs:tw-align-start md:tw-flex-col xs:tw-flex-row lg:tw-flex-col lg:tw-justify-start lg:tw-align-center xl:tw-flex-row tw-justify-start xl:tw-justify-between xl:tw-align-start",
            },
            [
              _c(
                "div",
                { staticClass: "circle-box tw-mx-auto tw-w-32 tw-h-32" },
                [_vm._t("circle")],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "goals",
                      expression: "'goals'",
                    },
                  ],
                  staticClass: "tw-w-full tw-overflow-hidden",
                },
                [_vm._t("goals")],
                2
              ),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }