import SubjectsAPI from "@/services/api/Subjects.js";

import TeacherContentCategoriesAPI from "../../services/teacher-content-categories.js";
import TeacherContentTypesAPI from "../../services/teacher-content-types.js";

const getDefaultState = () => ({
	categories: [],
	subjects: [],
	contentType: [],
	selectedCategory: {},
	selectedTitle: "",
	selectedPriority: 0,
	selectedLink: {},
	selectedContentType: {},
	isFile: true,
	selectedSubjectId: [],
	navbarMainTab: "Customers",
});

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations: {
		RESET_STATE(state) {
			Object.assign(state, getDefaultState());
		},
		SET_CATEGORIES(state, payload) {
			state.categories = payload.categories;
		},
		SET_SUBJECTS(state, payload) {
			state.subjects = payload.subjects;
		},
		SET_SELECTED_CATEGORY(state, payload) {
			state.selectedCategory = payload.category;
		},
		SET_SELECTED_SUBJECT(state, payload) {
			state.selectedSubject = payload.subject;
		},
		SET_PRIORITY(state, payload) {
			state.selectedPriority = payload.priority;
		},
		SET_TITLE(state, payload) {
			state.selectedTitle = payload.title;
		},
		SET_LINK(state, payload) {
			state.selectedLink = payload.link;
		},
		SET_SELECTED_CONTENT_TYPE(state, payload) {
			state.selectedContentType = payload.contentType;
		},
		SET_IS_FILE(state, payload) {
			state.isFile = payload.isFile;
		},
		SET_TEACHER_CONTENT_TYPES(state, payload) {
			state.contentType = payload.types;
		},
		SET_SELECTED_SUBJECT_IDS(state, payload) {
			state.selectedSubjectId = payload.selectedSubjectId;
		},
	},
	actions: {
		async uploadTeacherContent({ state }) {
			let response;
			try {
				const params = {
					title: state.selectedTitle,
					teacher_content_category_id: state.selectedCategory.id,
					teacher_content_type_id: state.selectedContentType.id,
					priority: state.selectedPriority,
					subjects: state.selectedSubjectId,
				};
				if (!state.isFile) {
					params["link"] = state.selectedLink;
					response = await TeacherContentCategoriesAPI.create(params);
				} else {
					const formData = new FormData();
					formData.append("file", state.selectedLink);
					formData.append("title", state.selectedTitle);
					formData.append("teacher_content_category_id", state.selectedCategory.id);
					formData.append("teacher_content_type_id", state.selectedContentType.id);
					formData.append("priority", state.selectedPriority);
					for (let i = 0; i < state.selectedSubjectId.length; i++) {
						formData.append("subjects[]", state.selectedSubjectId[i]);
					}
					response = await TeacherContentCategoriesAPI.create(formData);
				}
				return response.data.data.id;

			} catch (e) {
				return Promise.reject(e);
			}
		},
		async getSubjects({ commit }) {
			try {
				const response = await SubjectsAPI.list();
				commit("SET_SUBJECTS", {
					subjects: response.data.data,
				});
			} catch (e) {
				Promise.reject(e);
			}
		},
		async getCategories({ commit }) {
			try {
				const response = await TeacherContentCategoriesAPI.list();
				commit("SET_CATEGORIES", {
					categories: response.data.data,
				});
				Promise.resolve();
			} catch (e) {
				Promise.reject(e);
			}
		},
		async getTeacherContentTypes({ commit }) {
			try {
				const response = await TeacherContentTypesAPI.list();
				commit("SET_TEACHER_CONTENT_TYPES", {
					types: response.data.data,
				});
			} catch (e) {
				return Promise.reject(e);
			}
		},
	},
};
