var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tw-pb-10" },
    [
      _c(
        "h3",
        { staticClass: "tw-my-10 tw-font-bold tw-text-xl tw-text-black-high" },
        [_vm._v("\n\t\t" + _vm._s(_vm.title) + "\n\t")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "tw-flex tw-flex-row tw-flex-wrap tw-gap-x-4 tw-gap-y-4",
        },
        _vm._l(_vm.cards, function (card, index) {
          return _c("InsightCard", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "insight-card",
                expression: "'insight-card'",
              },
            ],
            key: `card-${index}`,
            attrs: {
              card: card,
              "action-items": _vm.isShowingOnlyOwnedActionItems
                ? _vm.$store.getters[
                    `PlatformManager/ActionCenter/${_vm.getOwnedActionItemTypeState(
                      card.id
                    )}`
                  ]
                : _vm.PlatformManager.ActionCenter[
                    _vm.getActionItemTypeState(card.id)
                  ],
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return _vm._l(
                      _vm.isShowingOnlyOwnedActionItems
                        ? _vm.$store.getters[
                            `PlatformManager/ActionCenter/${_vm.getOwnedActionItemTypeState(
                              card.id
                            )}`
                          ]
                        : _vm.PlatformManager.ActionCenter[
                            _vm.getActionItemTypeState(card.id)
                          ],
                      function (item) {
                        return _c(card.innerComponent, {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: "action-item-component",
                              expression: "'action-item-component'",
                            },
                          ],
                          key: `action-item-${item.id}`,
                          tag: "component",
                          attrs: { "action-item": item },
                          on: { "action-taken": _vm.handleAction },
                        })
                      }
                    )
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("ActionCenterFalseAlarmActionItemDialog", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "false-alarm-dialog",
            expression: "'false-alarm-dialog'",
          },
        ],
        attrs: {
          "is-open": _vm.PlatformManager.ActionCenter.isShowingFalseAlarmDialog,
        },
      }),
      _vm._v(" "),
      _c("ActionCenterResolveActionItemDialog", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "resolve-dialog",
            expression: "'resolve-dialog'",
          },
        ],
        attrs: {
          "is-open": _vm.PlatformManager.ActionCenter.isShowingResolveDialog,
        },
      }),
      _vm._v(" "),
      _c("BaseToaster"),
      _vm._v(" "),
      _c("ActionCenterHideActionItemDialog"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }