<template>
	<div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
		<slot name="icon">
			<component
				:is="icon"
				width="48"
				height="48"
				class="tw-text-grey-dark"
			/>
		</slot>
		<slot name="header">
			<h3
				v-jest="'message-info'"
				class="tw-font-bold"
				:class="messageInfoClass"
			>
				{{ message.info }}
			</h3>
		</slot>
		<slot name="main">
			<div
				v-if="message.instruction"
				class="tw-mt-1 tw-text-center"
			>
				<span
					v-jest="'message-instruction'"
					class="tw-whitespace-nowrap tw-font-normal"
				>
					{{ message.instruction }}
				</span>
			</div>
		</slot>
		<BaseButton
			v-if="isShowButton"
			v-jest="'no-data-button'"
			class="tw-mt-4"
			:color="buttonColor"
			:variants="buttonVariants"
			@click.native="$emit('button-clicked', $event)"
		>
			<slot name="button-text">
				{{ buttonText }}
			</slot>
		</BaseButton>
	</div>
</template>

<script>
import BarChartIcon from "../elements/icons/BarChart.vue";
import BaseButton from "../elements/BaseButton.vue";
export default {
	components: {
		BarChartIcon,
		BaseButton,
	},
	props: {
		buttonColor: {
			type: String,
			default: "blue",
		},
		buttonVariants: {
			type: Array,
			default: () => [],
		},
		icon: {
			type: Object,
			default: () => BarChartIcon,
		},
		isShowButton: {
			type: Boolean,
			default: true,
		},
		buttonText: {
			type: String,
			default: "Clear Filters",
		},
		message: {
			type: Object,
			default: () => ({
				info: "Sorry, no results were found",
				instruction: "Adjust your filter options and try again",
			}),
		},
		messageInfoClass: {
			type: String,
			default: "",
		},
	},
};
</script>
