<template>
	<div class="actionItem tw-py-4 tw-w-full tw-border-b-2 tw-border-grey-regular tw-border-solid tw-flex tw-justify-between tw-gap-x-4 tw-items-center">
		<div class="tw-flex tw-w-60 tw-flex-shrink-1 tw-flex-col tw-items-start tw-truncate">
			<p
				v-jest="'tutor-name'"
				class="tw-text-base tw-font-bold"
			>
				{{ tutor.name }} <span
					v-if="tutor.is_new"
					class="tw-text-origami-blue-300 tw-truncate"
				>(New Tutor)</span>
			</p>
			<p
				v-jest="'tutor-shift-end'"
				class="tw-text-xs tw-truncate"
				:class="isShiftOver ? 'tw-text-origami-red-400' : ''"
			>
				{{ timeLeftInShiftText }}
			</p>
		</div>
		<div class="tw-flex tw-gap-x-4 tw-items-center tw-flex-grow">
			<BaseIconButton
				id="essayIcon"
				v-jest="'essay-icon'"
				v-b-tooltip.hover.focus="tooltipOptions"
				class="actionItem__essayButton tw-w-10 tw-h-10 tw-flex-shrink-0"
				aria-label="link to the essay"
				@click.native="handleEssayClick"
			>
				<template #default>
					<IconEssayOutline
						class="essayIcon tw-text-origami-blue-300"
						:height="20"
						:width="16"
						:icon-color="'currentColor'"
					/>
				</template>
			</BaseIconButton>
			<div class="tw-h-10 tw-py-1 tw-px-4 tw-rounded-full tw-border tw-border-origami-text-black">
				<p
					v-jest="'essay-review-time'"
					class="tw-text-lg tw-font-bold tw-text-origami-text-black tw-truncate"
				>
					{{ essayReviewTime }}
				</p>
			</div>
		</div>
		<div class="tw-flex tw-gap-x-4 tw-items-center">
			<p
				v-jest="'status-text'"
				class="tw-text-xs tw-font-bold tw-truncate tw-flex-shrink"
			>
				{{ statusText }}
			</p>
			<OrigamiIconButton
				v-jest="'inspect-uninspect-button'"
				icon-name="inspect"
				:aria-label="inspectAriaLabel"
				:is-selected="isCurrentlyInspecting"
				@click.native="isCurrentlyInspecting ? handleAction(buttonActions[1]) : handleAction(buttonActions[0])"
			/>
			<OrigamiIconButton
				icon-name="block"
				aria-label="This item is a False Alarm"
				@click.native="handleAction(buttonActions[2])"
			/>
			<div class="tw-w-36 tw-flex-shrink-0">
				<BaseDropdown
					v-jest="'action-dropdown'"
					:v-model="actionItem.action_type_id"
					:items="filteredActions"
					close-on-select
					class="action__dropdown"
				>
					<template #filters>
						<span
							v-if="isActionPending"
							class="tw-flex tw-justify-center tw-items-center"
						>
							<LoadingRectangles />
						</span>
						<span
							v-else
							class="tw-flex tw-justify-start tw-items-center"
						>
							<OrigamiIcon
								v-if="actionItem.picked_up_at && actionItem.action_type_id"
								:name="actions[getActionTypeIndex(actionItem.action_type_id)].icon"
								width="20"
								height="20"
								class="tw-mr-3.5 tw-text-black-high"
							/>
							{{ actionName }}
						</span>
					</template>
					<template slot-scope="{ item }">
						<div class="tw-flex tw-justify-between tw-items-center">
							<label
								v-jest="'action-label'"
								:for="`actionItem-${actionItem.id}-${item.name}`"
								class="tw-flex tw-items-center tw-flex-grow tw-text-xs tw--mb-0 tw-p-2 tw-truncate tw-cursor-pointer"
							>
								<OrigamiIcon
									:name="item.icon"
									width="20"
									height="20"
									class="tw-mr-3.5 tw-text-black-high"
								/>
								{{ item.name }}
							</label>
							<input
								:id="`actionItem-${actionItem.id}-${item.name}`"
								v-jest="'action'"
								:value="item.id"
								class="sr-only tw-cursor-pointer"
								type="button"
								@click.prevent="handleAction(item)"
							>
							<IconCheckMark
								v-if="isItemSelected(item.id)"
								:width="16"
								:height="16"
								icon-color="currentColor"
								class="tw-text-blue-regular tw-mr-2 tw-cursor-pointer"
								@click.prevent.native="handleAction(item)"
							/>
						</div>
					</template>
				</BaseDropdown>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { intervalToDuration } from "date-fns";
import { OrigamiIcon, OrigamiIconButton } from "@origami/vue2";
import { isNil } from "lodash";

import { formatTimestampToDate } from "@/utilities/dateHelpers.js";
import { checkIsObjectKeysPresentAndRightType } from "@/utilities/propValidationHelpers.js";
import { getErrorText, showErrorModal } from "@/utilities/errorHandlingHelpers.js";
import { getStatusText, UNINSPECT_ACTION_ID, INSPECT_ACTION_ID, SLACK_ACTION_ID, FALSE_ALARM_ACTION_ID, RESOLVE_ACTION_ID, getActionTypeName, getActionTypeIndex } from "@/modules/ActionCenter/index.js";
import BaseDropdown from "@/components/BaseDropdown.vue";

import BaseIconButton from "../../../components/elements/BaseIconButton.vue";
import IconCheckMark from "../../../components/icons/IconCheckMark.vue";
import IconEssayOutline from "../../../components/icons/IconEssayOutline.vue";
import LoadingRectangles from "../../../components/LoadingRectangles.vue";

const requiredKeys = [
	{ property: "action_type_id", type: ["number", null] },
	{ property: "actionable_id", type: ["number"] },
	{ property: "created_at", type: ["number"] },
	{ property: "content", type: ["object"], subKeys: [
		{ property: "data", type: ["object"], subKeys: [
			{ property: "essay_id", type: ["number"] },
			{ property: "student_id", type: ["number"] },
			{ property: "tutor_id", type: ["number"] },
			{ property: "essay_url", type: ["string"] },
			{ property: "estimated_reviewed_at", type: ["number"] },
			{ property: "picked_up_at", type: ["number"] },
			{ property: "due_at", type: ["number"] },
			{ property: "tutor", type: ["object"], subKeys: [
				{ property: "user_id", type: ["number"] },
				{ property: "name", type: ["string"] },
				{ property: "is_new", type: ["boolean"] },
			] },
		] },
	] },
	{ property: "picked_up_user_id", type: ["number", null] },
	{ property: "picked_up_at", type: ["number", null] },
	{ property: "resolved_at", type: ["number", null] },
];

export default {
	components: {
		BaseIconButton,
		IconCheckMark,
		IconEssayOutline,
		LoadingRectangles,
		OrigamiIcon,
		BaseDropdown,
		OrigamiIconButton,
	},
	props: {
		actionItem: {
			type: Object,
			required: true,
			validator: (item) => checkIsObjectKeysPresentAndRightType(item, requiredKeys),
		},
	},
	data() {
		return {
			actions: [
				{ id: SLACK_ACTION_ID, name: `Slack`, icon: "slack", handleEvent: this.handleSlack },
				{ id: RESOLVE_ACTION_ID, name: `Resolve`, icon: "check-outlined", handleEvent: this.handleResolve },
			],
			buttonActions: [
				{ id: INSPECT_ACTION_ID, name: `Inspect`, icon: "inspect", handleEvent: this.handleInspect },
				{ id: UNINSPECT_ACTION_ID, name: `Uninspect`, icon: "inspect", handleEvent: this.handleUninspect },
				{ id: FALSE_ALARM_ACTION_ID, name: `False Alarm`, icon: "block", handleEvent: this.handleFalseAlarm },
			],
			isCurrentlyInspecting: false,
			currentTime: new Date(),
			isActionPending: false,
			refreshInterval: null,
		};
	},
	computed: {
		...mapState(["currentUser", "PlatformManager"]),
		essay() {
			return this.actionItem.content.data;
		},
		tutor() {
			return this.actionItem.content.data.tutor;
		},
		timeLeftInShiftText() {
			if (this.isShiftOver) {
				return "Shift ended";
			}
			const shiftEndTime = formatTimestampToDate(this.tutor.current_schedule.end_time);
			const timeLeft = intervalToDuration({
				start: this.currentTime,
				end: shiftEndTime,
			});
			if (timeLeft.seconds > 0) {
				timeLeft.minutes += 1;
			}
			return `Shift ends in ${timeLeft.hours}h ${timeLeft.minutes}min`;
		},
		isShiftOver() {
			const shiftEndTime = formatTimestampToDate(this.tutor.current_schedule?.end_time);
			return isNil(this.tutor.current_schedule) || this.currentTime > shiftEndTime;
		},
		tooltipOptions() {
			return {
				placement: "top",
				title: "Essay Details",
			};
		},
		essayReviewTime() {
			const reviewTime = this.currentTime < this.essay.picked_up_at ?
				{ days: 0, hours: 0, minutes: 0 } :
				intervalToDuration({
					start: this.essay.picked_up_at,
					end: this.currentTime,
				});
			const hours = reviewTime.days * 24 + reviewTime.hours;
			let minutes = reviewTime.minutes;
			if (reviewTime.seconds >= 30) {
				minutes++;
			}
			return `${hours}h ${minutes}m`;
		},
		statusText() {
			return getStatusText(this.actionItem, this.isPickedUpByCurrentUser);
		},
		inspectAriaLabel() {
			return this.isCurrentlyInspecting ? "Uninspect this Item" : "Inspect this Item";
		},
		isPickedUpByCurrentUser() {
			return this.actionItem.picked_up_user_id === this.currentUser.id;
		},
		isPickedUpByAnotherPM() {
			return (
				this.actionItem.picked_up_user_id &&
				this.actionItem.picked_up_user_id !== this.currentUser.id
			);
		},
		isNotPickedUp() {
			return !this.isPickedUpByCurrentUser && !this.isPickedUpByAnotherPM;
		},
		actionName() {
			return getActionTypeName(this.actionItem);
		},
		filteredActions() {
			let actions = [...this.actions];

			if (this.isNotPickedUp) {
				actions = actions.filter((action) => action.id !== UNINSPECT_ACTION_ID);
			} else if (this.isPickedUpByCurrentUser) {
				actions = actions.filter((action) => action.id !== INSPECT_ACTION_ID);
			} else if (this.isPickedUpByAnotherPM) {
				actions = actions.filter((action) => action.id === INSPECT_ACTION_ID);
			}

			return actions;
		},
	},
	async created() {
		this.refreshInterval = setInterval(() => this.currentTime = new Date(), 10000);
		this.isCurrentlyInspecting = this.isPickedUpByCurrentUser;
	},
	beforeDestroy() {
		window.clearInterval(this.refreshInterval);
	},
	methods: {
		getActionTypeIndex(id) {
			return getActionTypeIndex(id, this.actions);
		},
		handleEssayClick() {
			this.$router.push(`/${this.currentUser.role}/activity?essayId=[${this.essay.essay_id}]&tab=Essays`);
		},
		handleAction(action) {
			const payload = { action, essayId: this.actionItem.content.data.essay_id };
			this.$emit("action-taken", {
				eventHandler: action.handleEvent,
				payload,
			});
		},
		async handleInspect() {
			this.isActionPending = true;
			const payload = {
				id: this.actionItem.id,
				params: {
					picked_up_user_id: this.currentUser.id,
				},
			};
			try {
				await this.$store.dispatch("PlatformManager/ActionCenter/updateActionItem", payload);
				this.isCurrentlyInspecting = true;
			} catch (error) {
				const message = getErrorText("updating the action item");
				showErrorModal(this, {
					message,
				});
			} finally {
				this.isActionPending = false;
			}
		},
		async handleUninspect() {
			this.isActionPending = true;
			const payload = {
				id: this.actionItem.id,
				params: {
					unassign: true,
				},
			};
			try {
				await this.$store.dispatch("PlatformManager/ActionCenter/updateActionItem", payload);
				this.isCurrentlyInspecting = false;
			} catch (error) {
				const message = getErrorText("uninspecting the action item");
				showErrorModal(this, {
					message,
				});
			} finally {
				this.isActionPending = false;
			}
		},
		async handleSlack() {
			this.isActionPending = true;
			const payload = {
				id: this.actionItem.id,
				params: {
					action_type_id: SLACK_ACTION_ID,
					picked_up_user_id: this.currentUser.id,
				},
			};
			try {
				await this.$store.dispatch("PlatformManager/ActionCenter/updateActionItem", payload);
			} catch (error) {
				const message = getErrorText("updating the action item");
				showErrorModal(this, {
					message,
				});
			} finally {
				this.isActionPending = false;
			}
		},
		handleFalseAlarm() {
			this.$store.commit("PlatformManager/ActionCenter/SELECT_ACTION_ITEM", this.actionItem);
			this.$store.commit("PlatformManager/ActionCenter/OPEN_FALSE_ALARM_DIALOG");
		},
		handleResolve() {
			this.$store.commit("PlatformManager/ActionCenter/SELECT_ACTION_ITEM", this.actionItem);
			this.$store.commit("PlatformManager/ActionCenter/OPEN_RESOLVE_DIALOG");
		},
		isItemSelected(id) {
			const isInspecting = (id === INSPECT_ACTION_ID || id === UNINSPECT_ACTION_ID) &&
					!this.actionItem.action_type_id &&
					this.isPickedUpByCurrentUser;
			return id === this.actionItem.action_type_id || isInspecting;
		},
	},
};
</script>

<style scoped>
.actionItem:last-child {
	border: none;
}
.actionItem .actionItem__essayButton {
	border: 1px solid var(--origami-blue-300);
}
.actionItem .actionItem__essayButton:hover {
	background-color: var(--origami-blue-300);
}
.actionItem__essayButton:hover .essayIcon {
	color: var(--origami-white);
}
.action__dropdown :deep(li) {
	padding: 0;
}
</style>
