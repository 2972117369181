<template>
	<div class="sidebar">
		<a
			v-for="tab in tabs"
			:key="tab.name"
			v-jest="`${tab.name}-tab`"
			:class="getClassNames(tab.name)"
			@click.prevent="selectTab(tab.name)"
		>
			<component
				:is="tab.component"
				v-jest="`${tab.name}-icon`"
				:icon-color="getIconColor(tab.name)"
				:height="18"
				:width="18"
				class="sidebar__icon"
			/>
			{{ $t(`sidebar.${tab.name}`) }}
		</a>
	</div>
</template>

<script>
import { mapState } from "vuex";

export default {
	props: {
		tabs: {
			type: Array,
			required: true,
		},
	},
	computed: {
		...mapState(["Settings"]),
	},
	methods: {
		selectTab(tabName) {
			if (this.Settings.activeTab !== tabName) {
				this.$store.commit("Settings/SET_ACTIVE_TAB", {
					activeTab: tabName,
				});
			}
		},
		getIconColor(tabName) {
			return tabName === this.Settings.activeTab ? this.$root.$colors.C_BLUE : this.$root.$colors.C_BLACK;
		},
		getClassNames(tabName) {
			return tabName === this.Settings.activeTab
				? ["sidebar__item", "sidebar__item--active"]
				: ["sidebar__item"];
		},
	},
};
</script>

<style scoped>
.sidebar {
	display: flex;
	flex-direction: column;
	min-width: 272px;
	border-radius: 5px 0 0 5px;
	overflow: hidden;
	margin: 0;
}

.sidebar__item {
	padding: 1rem;
	font-size: 1rem;
	color: var(--c-black);
	background: var(--c-white);
	border-right: solid 2px var(--c-lighter-gray);
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	text-decoration: none;
}

.sidebar__item:hover,
.sidebar__item:focus {
	color: var(--c-blue);
}

.sidebar__item:not(:last-child) {
	border-bottom: 2px solid var(--c-gray);
}

.sidebar__item.sidebar__item--active {
	border-right: solid 2px var(--c-blue);
	color: var(--c-blue);
}

.sidebar__icon {
	margin-right: 5px;
}

@media (max-width: 750px) {
	.sidebar {
		flex: unset;
		margin-bottom: 2rem;
		border-radius: 5px;
	}
}
</style>
