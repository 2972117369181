export default {
	en: {
		recognition: {
			sidebar_title: "Send {studentName} kudos to recognize this session",
			recognition_send: "Send {recognition_name}",
			recognition_sent: "{recognition_name} Sent",
			notification_recognitions_modal_title: "Your {userRole} {userName} sent",
			for_your_subject_session: "for your {subject} session",
			widget_title: "Kudos Received",
			recogntion_message_text: "{user_name} sent the {recognition_name} sticker",
			sticker: "sticker",
			types: {
				kudo: "kudo",
				collaboration: "collaboration",
				greeting: "greeting",
				perseverance: "perseverance",
				politeness: "politeness",
				preparedness: "preparedness",
			},
		},
	},
	fr: {
		recognition: {
			recogntion_message_text: "{user_name} a envoyé le sticker de {recognition_name}",
			sticker: "sticker",
			types: {
				kudo: "félicitations",
				collaboration: "collaboration",
				greeting: "salutation",
				perseverance: "persévérance",
				politeness: "politesse",
				preparedness: "préparation",
			},
		},
	},
	es: {
		recognition: {
			recogntion_message_text: "{user_name} envió el sticker de {recognition_name}",
			sticker: "sticker",
			types: {
				kudo: "felicitaciones",
				collaboration: "colaboración",
				greeting: "saludo",
				perseverance: "perserverancia",
				politeness: "cortesía",
				preparedness: "preparación",
			},
		},
	},
};
