<template>
	<div
		class="tabs"
		role="tablist"
	>
		<template v-for="tab in tabs">
			<button
				:id="tab.name"
				:key="tab.name"
				v-data-cy="tab.name"
				role="tab"
				:tabindex="isTabActive(tab) ? 0 : -1"
				:aria-selected="isTabActive(tab) ? 'true' : 'false'"
				:aria-controls="`${tab.name}-panel`"
				class="tabs__item"
				:class="{ 'tabs__item--active': isTabActive(tab) }"
				:style="{ color: fontColor }"
				@click="changeTab(tab)"
				@keydown="handleKeydown($event)"
			>
				<div
					v-if="tab.name == 'sessions'"
					v-show="isNotificationDotVisible"
					class="tabs__dot"
				/>
				<h2 class="tabs__name">
					{{ $t("sidebar_tabs." + tab.name) }}
					<span
						v-if="checkIfSessionsTab(tab.name)"
						class="tabs__name"
					>
						{{ numUnreadMessages }}</span>
					<span
						v-if="checkIfAssessmentsTab(tab.name)"
						class="tabs__name"
					>
						({{ numPendingAssessments }})</span>
				</h2>
				<div
					v-show="isTabActive(tab)"
					class="tabs__redBar"
				/>
			</button>
		</template>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { isEmpty, isNil, startCase } from "lodash";


const changeTabStrategy = {
	"sidebar-history": "setSidebarTabToHistory",
	"sidebar-sessions": "setSidebarTabToSessions",
	"sidebar-sessions-student": "setSidebarTabToSessions",
	"sidebar-assessments": "setSidebarTabToAssessments",
};

export default {
	props: {
		fontColor: {
			type: String,
			default: "#fff",
			required: false,
		},
		tabs: {
			type: Array,
			required: true,
		},
		sidebarKey: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			isAssessmentsLoaded: false,
			featureFlagUpdateHandoffFlowIsEnabled: false,
		};
	},

	computed: {
		...mapState([
			"Tutor",
			"Classroom",
			"currentUser",
			"Student",
		]),
		recentSessions() {
			return this.Student.Session.recentSessions;
		},
		/**
		 * Number of unread messages under the tab
		 * @return String
		 */
		numUnreadMessages() {
			if (this.isNotificationDotVisible) {
				return ` (${this.unreadCount})`;
			} else {
				return "";
			}
		},
		numPendingAssessments() {
			return this.Tutor.Session.numPendingAssessments ?? 0;
		},

		/**
		 * Returns the active tab
		 * @return Object
		 */
		activeTab() {
			return this.tabs.find((tab) => {
				return (
					tab.component === this.Classroom.leftSidebarComponent ||
					tab.component === this.Classroom.rightSidebarComponent
				);
			});
		},

		/**
		 * Returns the total number of unread messages
		 * among all the sessions
		 * @return Number
		 */
		unreadCount() {
			let count = 0;
			const individualSessions = this.$store.getters["Classroom/individualSessions"];
			for (const key in individualSessions) {
				count += individualSessions[key].unreadCount;
			}
			return count;
		},

		isNotificationDotVisible() {
			return !isNil(this.activeTab) && this.activeTab.name !== "sessions" && this.unreadCount > 0;
		},
	},
	async created() {
		this.featureFlagUpdateHandoffFlowIsEnabled = await this.$getFlag("PE-20102-update-handoff-flow");
	},

	methods: {
		/**
		 * @param {Object} tab
		 * @returns {Boolean}
		 */
		isTabActive(tab) {
			return (
				tab.component === this.Classroom.leftSidebarComponent ||
				tab.component === this.Classroom.rightSidebarComponent
			);
		},
		/**
		 * @param {Object} tab
		 */
		setLeftSidebarTab(tab) {
			this.$store.commit("Classroom/SET_LEFT_SIDEBAR_COMPONENT", {
				leftSidebarComponent: tab.component,
			});
		},
		/**
		 * @param {Object} tab
		 */
		setRightSidebarTab(tab) {
			this.$store.commit("Classroom/SET_RIGHT_SIDEBAR_COMPONENT", {
				rightSidebarComponent: tab.component,
			});
		},
		/**
		 * Sets the index of the selected
		 * tab
		 */
		async changeTab(tab) {
			this[`set${startCase(this.sidebarKey)}SidebarTab`](tab);
			try {
				await this[`${changeTabStrategy[tab.component]}`]();
			} catch (e) {
				// handle error
			}
		},

		async getIncompleteAssessments() {
			if (!this.isAssessmentsLoaded) {
				await this.$store.dispatch("Tutor/Session/getIncompleteAssessmentSessions");
				this.isAssessmentsLoaded = true;
			}
			this.selectNextAssessment();
		},

		async selectNextAssessment() {
			const assessmentId =
				Object.values(this.Tutor.Session.assessments).length > 0 ?
					Object.values(this.Tutor.Session.assessments)[0].id : null;

			if (assessmentId !== null) {
				await this.$store.dispatch("Tutor/Session/selectAssessment", Object.values(this.Tutor.Session.assessments)[0].id);
			} else {
				this.$store.commit("Classroom/SET_CURRENT_SESSION_ID", {
					id: null,
				});
			}
		},
		/**
		 * @param {String} tabName
		 * @returns {Boolean}
		 */
		checkIfSessionsTab(tabName) {
			return tabName === "sessions";
		},
		/**
		 * @param {String} tabName
		 * @returns {Boolean}
		 */
		checkIfAssessmentsTab(tabName) {
			return tabName === "assessments";
		},
		async setSidebarTabToHistory() {
			if (this.$route.query.tab === "history") {
				return;
			}
			this.$router.replace({ path: this.$route.path, query: { tab: "history" } });
			const recentSessions = Object.keys(this.recentSessions);
			const recentSessionValues = Object.values(this.recentSessions);
			if (recentSessions.length < 10) {
				await this.$store.dispatch("Student/Session/getPastSessions");
			}
			if (!isEmpty(recentSessionValues) && !this.featureFlagUpdateHandoffFlowIsEnabled) {
				await this.$store.dispatch(
					"Student/Session/selectRecentSession",
					recentSessionValues[recentSessionValues.length - 1].id,
				);
			} else {
				this.$store.commit("Classroom/SET_CURRENT_SESSION_ID", {
					id: null,
				});
			}
		},
		async setSidebarTabToSessions() {
			if (this.$route.query?.tab) {
				this.$router.replace({ path: this.$route.path, query: {} });
			}
			const individualSessions = Object.values(this.$store.getters["Classroom/individualSessions"]);
			if (this.currentUser.role === "student") {
				this.$store.commit("Bookmark/SET_IS_SHOWING_BOOKMARKS", {
					isShowingBookmarks: false,
				});
			}
			if (!isEmpty(individualSessions)) {
				await this.$store.dispatch("Classroom/setCurrentSession", individualSessions[0].id);
			} else {
				this.$store.commit("Classroom/SET_CURRENT_SESSION_ID", {
					id: null,
				});
			}
			this.$store.commit("Classroom/TOGGLE_WILL_SCROLL_TO_BOTTOM");
		},
		async setSidebarTabToAssessments() {
			if (!isEmpty(this.Tutor.Session.sessionIdsToBeDeleted)) {
				this.$store.commit("Classroom/DELETE_MULTIPLE_SESSIONS", {
					sessionIds: this.Tutor.Session.sessionIdsToBeDeleted,
				});
				await this.$store.dispatch("Tutor/Session/removeLastMessageInMultipleSessions", {
					sessionIds: this.Tutor.Session.sessionIdsToBeDeleted,
				});
				this.$store.commit("Tutor/Session/EMPTY_SESSION_IDS_TO_BE_DELETED");
			}
			this.getIncompleteAssessments();
		},
		handleKeydown(event) {
			if (event.code === "ArrowLeft") {
				event.target.previousElementSibling?.focus();
			}
			if (event.code === "ArrowRight") {
				event.target.nextElementSibling?.focus();
			}
		},
	},
};
</script>

<style>
.tabs {
	display: flex;
	justify-content: space-around;
	font-size: 14px;
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	padding: 20px 0;
}

.tabs__item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: relative;
}

.tabs__name {
	margin-bottom: 10px;
	text-align: center;
}

.tabs__item--active {
	font-weight: bold;
}

.tabs__redBar {
	height: 2px;
	min-height: 2px;
	width: 20px;
	background: var(--c-orange);
}

.tabs__dot {
	position: absolute;
	border-radius: 50%;
	width: 8px;
	height: 8px;
	background-color: var(--c-orange);
	top: 15px;
	right: -10px;
}
</style>

<style scoped>
	button:focus-visible {
		outline: 2px solid var(--origami-blue-200) !important;
	}
</style>
