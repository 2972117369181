var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "tw-pl-12" },
    [
      _c("div", { staticClass: "xxl:tw-flex tw-justify-between" }, [
        _c(
          "div",
          { staticClass: "tw-flex tw-items-center xxl:tw-pb-9 tw-pb-1" },
          [
            _c(
              "h2",
              { staticClass: "tw-font-bold xxl:tw-text-lg tw-text-md" },
              [_vm._v("\n\t\t\t\tAverage Tutor Load Over Time\n\t\t\t")]
            ),
            _vm._v(" "),
            _c(_setup.InfoToolTip, {
              staticClass: "tw-ml-3 tw-leading-6 tw-pb-0.5",
              attrs: {
                width: "15px",
                height: "15px",
                options: {
                  content: _setup.AVERAGE_TUTOR_LOAD_OVER_TIME_TOOLTIP,
                  placement: "top",
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass:
              "xxl:tw-text-sm xxl:tw-pb-auto tw-pb-3 tw-text-xs tw-font-bold tw-mr-8 tw-mt-0.5 tw-text-origami-blue-200",
          },
          [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(_setup.averageTutorLoad) +
                " Average Tutor Load (30 mins)\n\t\t"
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(_setup.ServiceAnalyticsLineChart, {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "service-analytics-tutor-load-graph",
            expression: "'service-analytics-tutor-load-graph'",
          },
        ],
        staticClass: "tw-pb-9 tw--ml-5 tw--mt-5",
        attrs: {
          "chart-data": _setup.state.chartData,
          color: _setup.ORIGAMI_RED_400,
          "x-axes": _setup.xAxes(),
          "y-axes": _setup.state.yAxes,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }