export default {
	en: {
		challenges: {
			achievements: "Achievements",
			title: "Complete the Circle",
			time_left: "{days} day{days_plural} left!",
			completed: "Completed!",
			could_not_load: "Sorry, we could not load your challenges.",
			number_of_challenges: "number of circles completed",
			number_of_kudos: "number of kudos achieved",
			number_of_trophies: "number of trophies achieved",
			timing_text: "{days} day{days_plural} left",
			try_again: "Try again",
			translations: {
				your_challenge: "Your challenge",
				your_challenge_description: "Complete the challenge by:<br />&nbsp;- Chatting with a tutor<br />&nbsp;- Giving feedback on a chat after it ends<br/>&nbsp;- Submitting a piece of writing for review",
				circles_challenge_description: "Complete the challenge by:<br />&nbsp;- Chatting with a tutor<br />&nbsp;- Submitting a writing for review<br />&nbsp;- Solving problems in Paper Missions<br/>",
			},
		},
		goals: {
			tutoring_sessions: "tutoring sessions",
			review_requested: "reviews requested",
			feedback_submissions: "feedback submissions",
			missions_completed: "Missions completed",
			of: "{completed} of {target}",
			restart: "Restart in {days} days",
			segment_completed: "You're on your way to completing your challenge. Keep it up!",
			social: "{students} classmates have completed this challenge",
		},
		modal: {
			achievements_dashboard: "See your achievements on your dashboard!",
			challenge: "Challenge",
			challenge_completed: "You've completed your challenge!",
			close: "Close",
			nice_going: "Nice going!",
			ok_got_it: "Ok, Got it",
		},
	},
	fr: {
		challenges: {
			achievements: "Accomplissements",
			completed: "Complété!",
			could_not_load: "Désolé, nous ne pouvons pas charger tes challenges.",
			number_of_challenges: "nombre de cercles complétés",
			number_of_kudos: "nombre de kudos complétés",
			number_of_trophies: "nombre de trophée complétés",
			timing_text: "{days} jour{days_plural} restant",
			try_again: "Essaye encore",
		},
		goals: {
			tutoring_sessions: "session de tutorat",
			review_requested: "rédaction déposée",
			feedback_submissions: "soumissions de commentaires",
			missions_completed: "Missions accomplies",
			of: "{completed} sur {target}",
			restart: "Recommence dans {days} jours",
			segment_completed: "Tu es sur le point de terminer ton défi. Continue comme ça !",
			social: "{students} camarades ont complété ce défi",
		},
		modal: {
			achievements_dashboard: "Consulte ton avancement depuis ton tableau de bord",
			challenge: "Défi",
			challenge_completed: "Tu as complété le défi !",
			close: "Fermer",
			nice_going: "Continue comme ça !",
			ok_got_it: "C'est compris!",
		},
	},
	es: {
		challenges: {
			achievements: "Logros",
			completed: "¡Completado!",
			could_not_load: "Lo sentimos, no podemos cargar tus desafíos.",
			number_of_challenges: "número de círculos completados",
			number_of_kudos: "número de felicitaciones completadas",
			number_of_trophies: "número de trofeos completados",
			timing_text: "{days} día{days_plural} restante",
			try_again: "Inténtalo de nuevo",
		},
		goals: {
			tutoring_sessions: "asesorías",
			review_requested: "revisiones solicitadas",
			feedback_submissions: "envío de comentarios",
			missions_completed: "Missions completadas",
			of: "{completed} de {target}",
			restart: "Empezar de nuevo en {days} días",
			segment_completed: "¡Estás a punto de completar tu desafío. Continúa así!",
			social: "{students} compañeros de clase han completado este reto",
		},
		modal: {
			close: "Cerrar",
		},
	},
};
