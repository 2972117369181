<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		viewBox="0 0 16 16"
	>
		<g
			fill="none"
			fill-rule="evenodd"
		>
			<circle
				cx="8"
				cy="8"
				r="8"
				:fill="iconColor"
			/>
			<text
				fill="#FFF"
				font-family="OpenSans-Bold, Open Sans"
				font-size="12"
				font-weight="bold"
				letter-spacing="-.018"
			>
				<tspan
					x="6"
					y="13"
				>i</tspan>
			</text>
		</g>
	</svg>
</template>
<script>
export default {
	props: {
		iconColor: {
			type: String,
			default: "#84B6E2",
		},
		width: {
			type: Number,
			default: 16,
		},
		height: {
			type: Number,
			default: 16,
		},
	},
};
</script>
