<template>
	<li class="tw-flex tw-flex-col tw-items-start tw-w-full tw-p-4 tw-bg-origami-cyan-100 tw-rounded-lg tw-gap-4">
		<h5
			v-jest="'notification-heading'"
			class="tw-font-bold"
		>
			<div class="tw-mb-2">
				{{ headingText }}
				<span class="tw-text-origami-blue-300">{{ $t("achievements.challenge.days", { num: daysToEndOfChallenge }) }}</span>
			</div>
			<div>{{ $t("achievements.challenge.lets_go") }}</div>
		</h5>

		<ul class="goalList">
			<li
				v-for="goal in challenge.goals"
				:key="goal.type"
				v-jest="'challenge-goal'"
				class="goal"
			>
				<OrigamiIcon
					v-if="goal.completed >= goal.target"
					v-jest="`${goal.type}_checkmark`"
					name="check-filled"
					class="tw-text-origami-blue-300 tw-pr-1"
				/>
				<p
					v-jest="`${goal.type}_text`"
					class="tw-truncate tw-leading-6"
					:class="{
						'tw-text-origami-blue-300': goal.completed >= goal.target,
						'tw-pl-6': goal.completed < goal.target,
					}"
				>
					<span class="tw-font-bold">{{ $t("goals.of", goal) }}</span> {{ $t(text[goal.type]) }}
				</p>
			</li>
		</ul>

		<Points
			v-if="item.possible_points"
			v-jest="'challenge-points'"
			:points="item.possible_points"
			:label="$t('trophies.points.points_short')"
			class="points"
		>
			<template #prepend>
				{{ $t("achievements.challenge.earn") }}
			</template>
		</Points>
	</li>
</template>

<script>
import { intervalToDuration } from "date-fns";
import { OrigamiIcon } from "@origami/vue2";

import Points from "../Points.vue";

export default {
	components: {
		OrigamiIcon,
		Points,
	},
	props: {
		item: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		text: {
			ChannelScheduled: "goals.tutoring_sessions",
			FeedbackCreated: "goals.feedback_submissions",
			ReviewRequestSubmitted: "goals.review_requested",
			ProblemCompleted: "goals.missions_completed",
		},
	}),
	computed: {
		challenge() {
			return this.item.challenge;
		},
		headingText() {
			const name = this.$t(`challenges.translations.${this.challenge.title_key}`);
			return this.$t("achievements.challenge.challenge_ends_in", { name });
		},
		daysToEndOfChallenge() {
			const endDate = new Date(this.challenge.end_date / 1000);
			const timeLeft = intervalToDuration({
				start: new Date(),
				end: endDate,
			});
			return timeLeft.days;
		},
	},
};
</script>

<style scoped>
.goalList {
	@apply tw-flex tw-flex-col tw-gap-2;
}
.goal {
	@apply tw-flex tw-flex-row tw-pb-0;
}
.points {
	@apply tw-border tw-border-origami-grey-200 tw-rounded-full tw-py-1 tw-px-3 tw-bg-origami-white tw-text-origami-black;
}
</style>
