import api from "./api.js";

export default {
	list(params) {
		return api().get("/all", { params });
	},
	getMyTrophies(params) {
		return api().get("/my", { params });
	},
	getUnreadTrophies(params) {
		return api().get("/my?unread=1", { params });
	},
	updateTrophy(trophyId, data) {
		return api().patch(`/my/${trophyId}`, data);
	},
};
