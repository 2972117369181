var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BreaksConfirmation", {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "h3",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "scheduled-break-error-header",
                    expression: "'scheduled-break-error-header'",
                  },
                ],
              },
              [_vm._v("\n\t\t\t" + _vm._s(_vm.header) + "\n\t\t")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "bodyIcon",
        fn: function () {
          return [
            _c("OrigamiIcon", {
              staticClass: "tw-text-origami-red-300 tw-my-3",
              attrs: { width: "45", height: "45", name: "close-full" },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "bodyText",
        fn: function () {
          return [
            _c("p", { staticClass: "tw-text-left" }, [
              _vm._v(
                "\n\t\t\tYou cannot schedule a break within 30 minutes of:\n\t\t"
              ),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              {
                staticClass:
                  "tw-list-disc tw-text-center tw-inline-block tw-ml-8 tw-my-3",
              },
              [
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "shift-start-message",
                        expression: "'shift-start-message'",
                      },
                    ],
                    staticClass: "tw-list-outside tw-text-left",
                  },
                  [_vm._v("\n\t\t\t\tShift start\n\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "shift-end-message",
                        expression: "'shift-end-message'",
                      },
                    ],
                    staticClass: "tw-list-outside tw-text-left",
                  },
                  [_vm._v("\n\t\t\t\tShift end\n\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "shift-transition-message",
                        expression: "'shift-transition-message'",
                      },
                    ],
                    staticClass: "tw-list-outside tw-text-left",
                  },
                  [_vm._v("\n\t\t\t\tTransition into different shift\n\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "break-start-message",
                        expression: "'break-start-message'",
                      },
                    ],
                    staticClass: "tw-list-outside tw-text-left",
                  },
                  [_vm._v("\n\t\t\t\tBreak start\n\t\t\t")]
                ),
              ]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "tw-text-center" }, [
              _vm._v("\n\t\t\tPlease try for another time\n\t\t"),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function () {
          return [
            _c(
              "OrigamiButton",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "break-error-try-again-button",
                    expression: "'break-error-try-again-button'",
                  },
                ],
                staticClass: "tw-w-full tw-justify-center",
                attrs: { variant: "primary" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.$emit("break-error-try-again")
                  },
                },
              },
              [_vm._v("\n\t\t\tOk, got it!\n\t\t")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }