export default [
	{
		path: "schools",
		component: () => import("../../components/schools/SchoolsView.vue"),
		children: [
			{
				path: "",
				name: "superuserSchoolsList",
				component: () => import(
					"../../components/schools/SchoolsListWrapper.vue"
				),
			},
			{
				path: "create",
				name: "superuserSchoolsCreate",
				component: () => import("../../components/schools/SchoolCreateWrapper.vue"),
			},
			{
				path: ":schoolId",
				name: "superuserSchoolsId",
				component: () => import("../../components/schools/SchoolEditWrapper.vue"),
			},
		],
	},
];
