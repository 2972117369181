var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "total-hours",
          expression: "'total-hours'",
        },
      ],
      staticClass:
        "hours__text tw-text-origami-blue-300 tw-font-bold tw-text-left",
    },
    [_vm._v("\n\t" + _vm._s(_vm.totalHours) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }