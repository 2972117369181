var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showScrollToTop
    ? _c(
        "a",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "scroll-to-top-link",
              expression: "'scroll-to-top-link'",
            },
          ],
          staticClass:
            "tw-flex tw-items-center tw-justify-center tw-fixed tw-bottom-5 tw-right-5 tw-bg-origami-brand-blue hover:tw-bg-origami-brand-navy tw-shadow-elevation-4 tw-rounded-full tw-w-12 tw-h-12 tw-cursor-pointer",
          attrs: { href: "#" },
          on: { click: _vm.scrollToTop },
        },
        [
          _c("OrigamiIcon", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "scroll-to-top-icon",
                expression: "'scroll-to-top-icon'",
              },
            ],
            staticClass: "tw-text-origami-white",
            attrs: { name: "arrow-up" },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "origami-sr-only" }, [
            _vm._v("Back to top"),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }