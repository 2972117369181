var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M15.6364 11.0908C17.1455 11.0908 18.3545 9.87259 18.3545 8.3635C18.3545 6.85441 17.1455 5.63623 15.6364 5.63623C14.1273 5.63623 12.9091 6.85441 12.9091 8.3635C12.9091 9.87259 14.1273 11.0908 15.6364 11.0908ZM8.36364 11.0908C9.87273 11.0908 11.0818 9.87259 11.0818 8.3635C11.0818 6.85441 9.87273 5.63623 8.36364 5.63623C6.85455 5.63623 5.63636 6.85441 5.63636 8.3635C5.63636 9.87259 6.85455 11.0908 8.36364 11.0908ZM8.36364 12.909C6.24545 12.909 2 13.9726 2 16.0908V18.3635H14.7273V16.0908C14.7273 13.9726 10.4818 12.909 8.36364 12.909ZM15.6364 12.909C15.3727 12.909 15.0727 12.9271 14.7545 12.9544C15.8091 13.718 16.5455 14.7453 16.5455 16.0908V18.3635H22V16.0908C22 13.9726 17.7545 12.909 15.6364 12.909Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }