var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("WidgetWrapper", {
    directives: [
      {
        name: "jest",
        rawName: "v-jest",
        value: "wrapper",
        expression: "'wrapper'",
      },
    ],
    attrs: { "is-loading": _vm.isLoading },
    scopedSlots: _vm._u(
      [
        {
          key: "header",
          fn: function () {
            return [
              _c("WidgetHeader", {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "achievements-list",
                    expression: "'achievements-list'",
                  },
                ],
                attrs: {
                  "is-loading-points": _vm.$store.state.Achievements.isLoading,
                  points: _vm.$store.getters["Achievements/points"],
                },
              }),
            ]
          },
          proxy: true,
        },
        _vm.isLoading
          ? {
              key: "loading",
              fn: function () {
                return [_c("BaseLoadingState")]
              },
              proxy: true,
            }
          : null,
        !_vm.isLoading
          ? {
              key: "circle",
              fn: function () {
                return [
                  _c(
                    "BaseCircle",
                    {
                      staticClass: "tw-h-full",
                      attrs: {
                        progress: _vm.challengeProgress,
                        total: _vm.challengeTotal,
                      },
                    },
                    [
                      _vm.isChallengeCompleted
                        ? _c("IconChallengeCompleted", {
                            directives: [
                              {
                                name: "jest",
                                rawName: "v-jest",
                                value: "completion-icon",
                                expression: "'completion-icon'",
                              },
                            ],
                            staticClass: "tw-min-w-full tw-min-h-full tw-p-2",
                          })
                        : _c("AvatarIcon", {
                            directives: [
                              {
                                name: "jest",
                                rawName: "v-jest",
                                value: "avatar",
                                expression: "'avatar'",
                              },
                            ],
                            staticClass: "tw-min-w-full tw-min-h-full",
                            attrs: { user: _vm.profile },
                          }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            }
          : null,
        !_vm.isLoading
          ? {
              key: "goals",
              fn: function () {
                return [
                  _vm.hasError
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: "error-state",
                              expression: "'error-state'",
                            },
                          ],
                          staticClass:
                            "tw-flex tw-items-center tw-justify-center tw-flex-col tw-gap-y-4 tw-text-center tw-h-full",
                        },
                        [
                          _c("p", { staticClass: "tw-font-bold tw-text-md" }, [
                            _vm._v(
                              "\n\t\t\t\t" +
                                _vm._s(_vm.$t("challenges.could_not_load")) +
                                "\n\t\t\t"
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "OrigamiButton",
                            {
                              directives: [
                                {
                                  name: "jest",
                                  rawName: "v-jest",
                                  value: "try-again-button",
                                  expression: "'try-again-button'",
                                },
                              ],
                              attrs: {
                                variant: "secondary",
                                "is-disabled": _vm.isLoading,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.getData.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("challenges.try_again"))),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _c("Challenge", {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "list",
                            expression: "'list'",
                          },
                        ],
                        attrs: {
                          "challenge-completed": _vm.isChallengeCompleted,
                          challenge: _vm.Challenges.challenge,
                          goals: _vm.goals,
                        },
                      }),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }