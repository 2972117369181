var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 16 16",
      },
    },
    [
      _c("defs", [
        _c("path", {
          attrs: {
            id: _vm.randomId,
            d: "M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm2.263 11.394L8 9.131l-2.263 2.263-1.131-1.131L6.869 8 4.606 5.737l1.131-1.131L8 6.869l2.263-2.263 1.131 1.131L9.131 8l2.263 2.263-1.131 1.131z",
          },
        }),
      ]),
      _vm._v(" "),
      _c("g", { attrs: { fill: _vm.iconColor, "fill-rule": "evenodd" } }, [
        _c("mask", { attrs: { id: `a${_vm.randomId}`, fill: "#fff" } }, [
          _c("use", { attrs: { "xlink:href": `#${_vm.randomId}` } }),
        ]),
        _vm._v(" "),
        _c(
          "g",
          { attrs: { fill: _vm.iconColor, mask: `url(#a${_vm.randomId})` } },
          [_c("path", { attrs: { d: "M0 0h16v16H0z" } })]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }