var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { staticClass: "tw-flex tw-justify-between tw-gap-3 tw-mb-4" },
    [
      _c(
        "h3",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "achievement-title",
              expression: "'achievement-title'",
            },
          ],
          staticClass: "tw-text-lg tw-font-bold",
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.$t("challenges.achievements")) + "\n\t")]
      ),
      _vm._v(" "),
      _c(
        "BaseLink",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "link",
              expression: "'link'",
            },
          ],
          staticClass:
            "tw-text-lg tw-font-bold tw-rounded-lg hover:tw-bg-origami-grey-100 focus-within:tw-bg-origami-grey-100",
          attrs: { to: "/student/achievement-center" },
        },
        [
          _c("Points", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "points",
                expression: "'points'",
              },
            ],
            attrs: { points: _vm.points, "is-loading": _vm.isLoadingPoints },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }