var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sidebar" },
    _vm._l(_vm.tabs, function (tab) {
      return _c(
        "a",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: `${tab.name}-tab`,
              expression: "`${tab.name}-tab`",
            },
          ],
          key: tab.name,
          class: _vm.getClassNames(tab.name),
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.selectTab(tab.name)
            },
          },
        },
        [
          _c(tab.component, {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: `${tab.name}-icon`,
                expression: "`${tab.name}-icon`",
              },
            ],
            tag: "component",
            staticClass: "sidebar__icon",
            attrs: {
              "icon-color": _vm.getIconColor(tab.name),
              height: 18,
              width: 18,
            },
          }),
          _vm._v("\n\t\t" + _vm._s(_vm.$t(`sidebar.${tab.name}`)) + "\n\t"),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }