var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tw-flex tw-flex-wrap tw-items-end tw-w-2/3 tw-mb-20" },
    [
      _c(
        "div",
        { staticClass: "tw-w-full tw-my-5" },
        [
          _c(
            "BaseCheckbox",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "show-absences-checkbox",
                  expression: "'show-absences-checkbox'",
                },
              ],
              attrs: {
                "checkbox-id": "show-absences-only",
                "checkbox-value": "Show Absences Only",
              },
              on: { input: _vm.handleCheckbox },
              model: {
                value: _vm.areAbsencesChecked,
                callback: function ($$v) {
                  _vm.areAbsencesChecked = $$v
                },
                expression: "areAbsencesChecked",
              },
            },
            [
              _c("template", { slot: "text" }, [
                _vm._v("\n\t\t\t\tShow Absences Only\n\t\t\t"),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "tw-font-bold tw-text-sm tw-mr-4 tw-mt-2 tw-self-start",
        },
        [_vm._v("\n\t\tFilter\n\t")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "tw-flex tw-flex-wrap tw-mr-5 tw-flex-grow tw-max-w-sm tw-items-start",
        },
        [
          _c(
            "BaseDropdown",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "tutor-list",
                  expression: "'tutor-list'",
                },
              ],
              staticClass: "tw-w-44 tw-mr-2",
              attrs: {
                items: _vm.getTutorListWithoutDuplicates,
                "is-clearable": !_vm.isClearable,
              },
              on: {
                deselect: function ($event) {
                  return _vm.clearSelectedTutors()
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function ({ item }) {
                    return [
                      _c(
                        "BaseCheckbox",
                        {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: "tutor-list-checkbox",
                              expression: "'tutor-list-checkbox'",
                            },
                          ],
                          attrs: {
                            "checkbox-id": `tutor-${item.user_id}`,
                            "checkbox-value": item.user_id,
                          },
                          model: {
                            value: _vm.selectedTutors,
                            callback: function ($$v) {
                              _vm.selectedTutors = $$v
                            },
                            expression: "selectedTutors",
                          },
                        },
                        [
                          _c("template", { slot: "text" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(item.name) +
                                "\n\t\t\t\t\t"
                            ),
                          ]),
                        ],
                        2
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("template", { slot: "filters" }, [
                _vm._v(
                  "\n\t\t\t\t" + _vm._s(_vm.tutorsDropdownFilter) + "\n\t\t\t"
                ),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c("DatePicker", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "date-picker",
                expression: "'date-picker'",
              },
            ],
            staticClass: "tw-w-44 tw-m-0",
            attrs: { "max-date": new Date() },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ inputValue, inputEvents }) {
                  return [
                    _c(
                      "input",
                      _vm._g(
                        {
                          staticClass:
                            "filter-date-picker tw-h-10 tw-rounded-md tw-text-xs",
                          attrs: { placeholder: "Shift Date" },
                          domProps: { value: inputValue },
                        },
                        inputEvents
                      )
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.selectedDate,
              callback: function ($$v) {
                _vm.selectedDate = $$v
              },
              expression: "selectedDate",
            },
          }),
          _vm._v(" "),
          _c(
            "OrigamiButton",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "show-results-button",
                  expression: "'show-results-button'",
                },
              ],
              staticClass: "tw-mt-3 tw-mr-3",
              attrs: { type: "submit" },
              nativeOn: {
                click: function ($event) {
                  return _vm.getFilteredBioBreakSummary.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n\t\t\tShow Results\n\t\t")]
          ),
          _vm._v(" "),
          _c(
            "OrigamiButton",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "clear-results-button",
                  expression: "'clear-results-button'",
                },
              ],
              staticClass: "tw-mt-3",
              attrs: { type: "submit", variant: "secondary" },
              nativeOn: {
                click: function ($event) {
                  return _vm.clearAllFilters.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n\t\t\tReset Filters\n\t\t")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }