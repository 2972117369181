var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "p",
    {
      staticClass:
        "tw-flex tw-text-center tw-w-max tw-mb-auto tw-ml-auto tw-px-1 tw-py-0 tw-rounded-md tw-font-bold tw-text-sm",
      class: _vm.currentShiftScheduleTypeColor,
    },
    [_vm._v("\n\t" + _vm._s(_vm.name) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }