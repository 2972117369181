export default {
	en: {
		embedded: {
			error_embedded_only:
				"Sorry, the page you requested is only available on Paper Chrome Extension",
			error_unembeddable:
				"Sorry, the page you requested is not available on Paper Chrome Extension",
			message_install: "Install Paper Chrome Extension",
			message_open_chrome: "Open Paper Chrome Extension",
			message_open_newtab: "Open page in a new tab",
		},
	},
	fr: {
		embedded: {
			error_embedded_only:
			"Désolé, la page que vous avez demandé n'est disponible que sur Paper Chrome Extension",
			error_unembeddable:
			"Désolé, la page que vous avez demandé n'est pas disponible sur Paper Chrome Extension",
			message_install: "Installer Paper Chrome Extension",
			message_open_chrome: "Ouvrir Paper Chrome Extension",
			message_open_newtab: "Ouvrir la page dans un nouvel onglet",
		},
	},
	es: {
		embedded: {
			error_embedded_only:
			"Lo siento, la página que solicitó solo está disponible en Paper Chrome Extension",
			error_unembeddable:
			"Lo siento, la página que solicitó no está disponible en Paper Chrome Extension",
			message_install: "Instalar Paper Chrome Extension",
			message_open_chrome: "Abrir Paper Chrome Extension",
			message_open_newtab: "Abrir la página en una nueva pestaña",
		},
	},
};
