var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "tw-flex tw-justify-between tw-items-center tw-mt-5" },
        [
          _c("span", { staticClass: "availabilitiesFooter__total tw-flex-1" }, [
            _vm._v("\n\t\t\tTotal Availability Selected: "),
            _c("b", [_vm._v(_vm._s(_vm.totalHoursForWeek) + " Hours")]),
          ]),
          _vm._v(" "),
          _vm.isCurrentWeekOpen
            ? _c(
                "div",
                {
                  staticClass:
                    "tw-flex tw-gap-x-20 tw-justify-center tw-content-center tw-items-center tw-text-center",
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "max-hours-container",
                          expression: "'max-hours-container'",
                        },
                      ],
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "availabilitiesFooter__maxHours tw-mb-0",
                          attrs: { for: "input" },
                        },
                        [_vm._v("\n\t\t\t\t\tMax Hours:\n\t\t\t\t")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.maxHours,
                            expression: "maxHours",
                            modifiers: { number: true },
                          },
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "max-hours",
                            expression: "'max-hours'",
                          },
                        ],
                        staticClass: "availabilitiesFooter__input",
                        attrs: {
                          id: "input",
                          disabled: _vm.canEdit,
                          type: "number",
                          step: "1",
                        },
                        domProps: { value: _vm.maxHours },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.maxHours = _vm._n($event.target.value)
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.featureFlagPreferredHoursFieldEnabled
                        ? _c("InfoToolTip", {
                            directives: [
                              {
                                name: "jest",
                                rawName: "v-jest",
                                value: "availability-max-hours-tooltip",
                                expression: "'availability-max-hours-tooltip'",
                              },
                              {
                                name: "data-cy",
                                rawName: "v-data-cy",
                                value: "availability-max-hours-tooltip",
                                expression: "'availability-max-hours-tooltip'",
                              },
                            ],
                            attrs: {
                              options: _vm.maxHoursToolTipOptions,
                              "aria-describedby": "Max Hours",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.featureFlagPreferredHoursFieldEnabled
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: "preferred-hours-container",
                              expression: "'preferred-hours-container'",
                            },
                          ],
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "availabilitiesFooter__maxHours tw-mb-0",
                              attrs: { for: "input" },
                            },
                            [_vm._v("\n\t\t\t\t\tPreferred Hours:\n\t\t\t\t")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.preferredHours,
                                expression: "preferredHours",
                                modifiers: { number: true },
                              },
                              {
                                name: "jest",
                                rawName: "v-jest",
                                value: "preferred-hours",
                                expression: "'preferred-hours'",
                              },
                            ],
                            staticClass: "availabilitiesFooter__input",
                            attrs: {
                              id: "input",
                              disabled: _vm.canEdit,
                              type: "number",
                              step: "1",
                            },
                            domProps: { value: _vm.preferredHours },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.preferredHours = _vm._n($event.target.value)
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("InfoToolTip", {
                            directives: [
                              {
                                name: "jest",
                                rawName: "v-jest",
                                value: "availability-preferred-hours-tooltip",
                                expression:
                                  "'availability-preferred-hours-tooltip'",
                              },
                              {
                                name: "data-cy",
                                rawName: "v-data-cy",
                                value: "availability-preferred-hours-tooltip",
                                expression:
                                  "'availability-preferred-hours-tooltip'",
                              },
                            ],
                            attrs: {
                              options: _vm.prefHoursToolTipOptions,
                              "aria-describedby": "Preferred Hours",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tw-flex-1 tw-flex tw-justify-end" },
            [
              _vm.canSubmit
                ? _c(
                    "OrigamiButton",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "cancel-editing",
                          expression: "'cancel-editing'",
                        },
                      ],
                      key: "cancel-editing",
                      staticClass: "tw-mr-4",
                      attrs: { variant: "secondary" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.cancelEdit.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\tCancel\n\t\t\t")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.canSubmit
                ? _c(
                    "OrigamiButton",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "submit-availabilities",
                          expression: "'submit-availabilities'",
                        },
                      ],
                      key: "submit-availabilities",
                      attrs: {
                        type: "submit",
                        variant: "primary",
                        "is-disabled": !_vm.isSubmitButtonEnabled,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.showSubmitAvailabilitiesModal.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\tSubmit\n\t\t\t")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.canEdit
                ? _c(
                    "OrigamiButton",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "edit-availabilities",
                          expression: "'edit-availabilities'",
                        },
                      ],
                      key: "edit-availabilities",
                      attrs: { variant: "primary" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.editAvailabilities.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\tEdit\n\t\t\t")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      !_vm.isCurrentWeekOpen && !_vm.isCurrentWeekAfterOpenWeek
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "week-closed-message",
                  expression: "'week-closed-message'",
                },
              ],
              staticClass: "availabilitiesFooter__closed",
            },
            [
              _c("IconInfo", {
                staticClass: "availabilitiesFooter__icon",
                attrs: { width: 18, height: 18 },
              }),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "availabilitiesFooter__closed--padding" },
                [
                  _vm._v(
                    "\n\t\t\tThis week is currently closed. The schedule is released by 11:59 p.m. ET each Friday for the week beginning 10 days later.\n\t\t"
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isCurrentWeekOpen && _vm.maxHoursPerWeek !== null
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "already-submitted-message",
                  expression: "'already-submitted-message'",
                },
              ],
              staticClass: "availabilitiesFooter__closed",
            },
            [
              _c("IconInfo", {
                staticClass: "availabilitiesFooter__icon",
                attrs: { width: 18, height: 18 },
              }),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "availabilitiesFooter__closed--padding" },
                [
                  _vm._v(
                    "\n\t\t\tYou already submitted availability this week, but you can edit until the submission deadline.\n\t\t"
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isCurrentWeekAfterOpenWeek
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "week-not-open-message",
                  expression: "'week-not-open-message'",
                },
              ],
              staticClass: "availabilitiesFooter__closed",
            },
            [
              _c("IconInfo", {
                staticClass: "availabilitiesFooter__icon",
                attrs: { width: 18, height: 18 },
              }),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "availabilitiesFooter__closed--padding" },
                [
                  _vm._v(
                    "\n\t\t\tThis week is not currently open for availability.\n\t\t"
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.featureFlagPreferredHoursFieldEnabled
        ? _c("TutorDashboardSubmitAvailabilitiesModal", {
            attrs: {
              "max-hours": _vm.maxHours,
              "preferred-hours": _vm.preferredHours,
            },
          })
        : _c("TutorDashboardSubmitAvailabilitiesModal", {
            attrs: { "max-hours": _vm.maxHours },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }