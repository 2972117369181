var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "FileDropZone tw-relative",
      class: {
        "hover:tw-bg-grey-slightly tw-border-2 tw-border-dashed tw-border-grey-dark tw-overflow-hidden":
          _vm.clickable,
        "tw-bg-grey-slightly": _vm.clickable && _vm.isDraggingOver,
        focused: _vm.focused,
      },
      on: {
        dragenter: function ($event) {
          $event.preventDefault()
          _vm.isDraggingOver = true
        },
        dragover: function ($event) {
          $event.preventDefault()
          _vm.isDraggingOver = true
        },
        dragleave: function ($event) {
          $event.preventDefault()
          _vm.isDraggingOver = false
        },
        drop: function ($event) {
          $event.preventDefault()
          return _vm.handleChange.apply(null, arguments)
        },
      },
    },
    [
      _vm._t("default", null, null, _vm.defaultProps),
      _vm._v(" "),
      _vm.clickable
        ? _vm._t(
            "input",
            function () {
              return [
                _c(
                  "input",
                  _vm._g(
                    _vm._b(
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "input",
                            expression: "'input'",
                          },
                        ],
                      },
                      "input",
                      _vm.inputProps.bind,
                      false
                    ),
                    _vm.inputProps.on
                  )
                ),
              ]
            },
            null,
            _vm.inputProps
          )
        : _vm._t("overlay", function () {
            return [
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isDraggingOver,
                    expression: "isDraggingOver",
                  },
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "overlay",
                    expression: "'overlay'",
                  },
                ],
                staticClass:
                  "tw-absolute tw-inset-0 tw-bg-black-overlay tw-border-2 tw-border-dashed tw-border-grey-dark tw-pointer-events-none tw-z-10",
              }),
            ]
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }