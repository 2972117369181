<template>
	<div class="subNavbar">
		<nav class="tw-max-w-5xl tw-flex tw-mx-auto tw-justify-start tw-relative">
			<ul
				:class="listClass"
			>
				<li
					v-for="tab in tabs"
					:key="`navbar${tab.name}`"
					v-data-cy="`sub-navbar-tab-${tab.name}`"
					class="subNavbar__tab"
					:class="{
						'subNavbar-lastTab': isBreakTab(tab.name),
					}"
				>
					<BaseLink
						v-if="!isBreakTab(tab.name)"
						v-data-cy="`subnavbar-tab-${tab.name}`"
						class="subNavbar__link tw-py-4 tw-px-7"
						:to="tab.url"
					>
						{{ tab.name }}
					</BaseLink>
					<BaseLink
						v-else
						v-jest="'show-icon-break'"
						class="iconBreak__position tw-relative"
						:to="tab.url"
					>
						<IconBreak />
						<div
							v-if="!isMealBreakRequestEmpty"
							v-jest="'breaks-notification'"
							class="tw-w-2 tw-h-2 tw-bg-orange-dark tw-rounded-full tw-absolute tw-top-1/4 tw-left-11"
						/>
					</BaseLink>
				</li>
			</ul>
		</nav>
	</div>
</template>
<script>
import { mapGetters } from "vuex";

import IconBreak from "@/components/icons/IconBreak.vue";

export default {
	components: {
		IconBreak,
	},
	props: {
		tabs: {
			type: Array,
			required: true,
			validator: function(value) {
				let isArrayOfObjects = true;
				value.forEach((item) => {
					if (typeof item !== "object") {
						isArrayOfObjects = false;
					}
				});
				return isArrayOfObjects;
			},
		},
	},
	computed: {
		...mapGetters("BreaksCenter", ["isMealBreakRequestEmpty"]),
		listClass() {
			let ulStyling = "subNavbar__tabs";
			this.tabs.forEach((tab) => {
				if (tab.name === "Breaks Center") {
					ulStyling = "breakTabPresent__subNavbar__tabs";
				}
			});
			return ulStyling;
		},
	},
	methods: {
		isBreakTab(tab) {
			return tab === "Breaks Center";
		},
	},
};
</script>
<style scoped>
.subNavbar {
	text-align: center;
	background-color: var(--c-blue);
	width: 100%;
	box-shadow: inset 0px 2px 4px 0 rgba(0, 0, 0, 0.25);
}
.subNavbar__tabs, .breakTabPresent__subNavbar__tabs {
	list-style-type: none;
	padding: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.subNavbar__tab {
	display: flex;
}
.subNavbar__tab:hover {
	background-color: var(--c-dark-blue);
	box-shadow: 0 5px 0 #dbbc76a6 inset;
	cursor: pointer;
}
.subNavbar__tab .router-link-active {
	box-shadow: 0 5px 0 #ffd1ab inset;
	background-color: var(--origami-white) !important;
	font-weight: 700;
	color: var(--origami-blue-300);
}
.subNavbar__link {
	font-family: "MonumentGrotesk", "Open Sans", sans-serif;
	font-weight: 400;
	font-size: 14px;
	align-self: center;
	text-decoration: none;
	color: var(--origami-white);
}
.subNavbar-lastTab {
	margin-left: 100px;
	position: absolute;
	right: 1rem;
	height: 100%;
}
.iconBreak__position {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 30px;
	color: var(--origami-white);
}
@media (max-width: 768px) {
	.subNavbar {
		display: none;
	}
}
</style>
