var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tagFilters" }, [
    _c(
      "table",
      { staticClass: "tw-w-full" },
      [
        _c(
          "tr",
          {
            staticClass:
              "tagFilters__headerRow tw-flex tw-justify-between tw-gap-x-2 tw-w-full",
          },
          [
            _c(
              "th",
              { staticClass: "tw-w-full" },
              [
                _c(
                  "BaseDropdown",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "subjects-dropdown",
                        expression: "'subjects-dropdown'",
                      },
                    ],
                    ref: "subjectDropdown",
                    staticClass: "tw-mb-2 tw-w-full tw-h-10",
                    attrs: { items: _vm.subjects },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "BaseCheckbox",
                              {
                                directives: [
                                  {
                                    name: "jest",
                                    rawName: "v-jest",
                                    value: "subject-checkbox",
                                    expression: "'subject-checkbox'",
                                  },
                                ],
                                staticClass: "tw-py-0 tw-px-7",
                                attrs: {
                                  "checkbox-value": item.id,
                                  "checkbox-id": `subject-${item.id}`,
                                  value: item.id,
                                },
                                nativeOn: {
                                  change: function ($event) {
                                    return _vm.changeSubject(item)
                                  },
                                },
                                model: {
                                  value: _vm.selectedSubjectIds,
                                  callback: function ($$v) {
                                    _vm.selectedSubjectIds = $$v
                                  },
                                  expression: "selectedSubjectIds",
                                },
                              },
                              [
                                _c("template", { slot: "text" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(item.name) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c("template", { slot: "filters" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s("Select subject") +
                          "\n\t\t\t\t\t"
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "template",
                      { slot: "selectAll" },
                      [
                        _c(
                          "BaseCheckbox",
                          {
                            staticClass: "tw-p-2",
                            attrs: {
                              "checkbox-value": "select_all",
                              "checkbox-id": "select_all",
                            },
                            nativeOn: {
                              change: function ($event) {
                                return _vm.selectAllValues.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            model: {
                              value: _vm.selectDeselectAll,
                              callback: function ($$v) {
                                _vm.selectDeselectAll = $$v
                              },
                              expression: "selectDeselectAll",
                            },
                          },
                          [
                            _c("template", { slot: "text" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\tSelect All\n\t\t\t\t\t\t\t"
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "th",
              { staticClass: "tw-w-full tw-flex" },
              [
                _c(
                  "BaseDropdown",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "topic-dropdown",
                        expression: "'topic-dropdown'",
                      },
                    ],
                    ref: "topicDropdown",
                    staticClass: "tw-mb-2 tw-w-full tw-h-10",
                    attrs: {
                      items: _vm.topics,
                      "is-disabled": !_vm.isTopicsDropdownEnabled,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "BaseCheckbox",
                              {
                                staticClass:
                                  "tw--m-2 tw-py-2.5 tw-px-2 tw-bg-white-high tw-overflow-ellipsis hover:tw-bg-grey-regular",
                                attrs: {
                                  "checkbox-value": `allTopics-${item.id}`,
                                  "checkbox-id": `allTopics-${item.id}`,
                                  value: `allTopics-${item.id}`,
                                },
                                nativeOn: {
                                  change: function ($event) {
                                    return _vm.changeTopic(
                                      item,
                                      item,
                                      `allTopics-${item.id}`
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.selectedTopicIds,
                                  callback: function ($$v) {
                                    _vm.selectedTopicIds = $$v
                                  },
                                  expression: "selectedTopicIds",
                                },
                              },
                              [
                                _c("template", { slot: "text" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(item.name) +
                                      " (Select All)\n\t\t\t\t\t\t\t"
                                  ),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _vm._l(item.topics.data, function (topicItem) {
                              return _c(
                                "div",
                                { key: topicItem.id },
                                [
                                  _c(
                                    "BaseCheckbox",
                                    {
                                      staticClass:
                                        "tw--m-2 tw-py-2.5 tw-pl-9 tw-pr-0 tw-bg-white-high tw-overflow-ellipsis hover:tw-bg-grey-regular",
                                      attrs: {
                                        "checkbox-value": topicItem.id,
                                        value: topicItem.id,
                                        "checkbox-id": topicItem.id,
                                      },
                                      nativeOn: {
                                        change: function ($event) {
                                          return _vm.changeTopic(
                                            item,
                                            topicItem,
                                            topicItem.id
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.selectedTopicIds,
                                        callback: function ($$v) {
                                          _vm.selectedTopicIds = $$v
                                        },
                                        expression: "selectedTopicIds",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "text" }, [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(topicItem.name) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            }),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c("template", { slot: "filters" }, [
                      _vm._v("\n\t\t\t\t\t\tSelect topic\n\t\t\t\t\t"),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "th",
              { staticClass: "tw-w-full" },
              [
                _c(
                  "BaseDropdown",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "concept-dropdown",
                        expression: "'concept-dropdown'",
                      },
                    ],
                    ref: "conceptDropdown",
                    staticClass: "tw-mb-2 tw-w-full tw-h-10",
                    attrs: {
                      items: _vm.concepts,
                      "is-disabled": !_vm.isConceptsDropdownEnabled,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "BaseCheckbox",
                              {
                                staticClass:
                                  "tw--m-2 tw-py-2.5 tw-px-2 tw-bg-white-high tw-overflow-ellipsis hover:tw-bg-grey-regular",
                                attrs: {
                                  "checkbox-value": `allConcepts-${item.id}`,
                                  value: `allConcepts-${item.id}`,
                                  "checkbox-id": `allConcepts-${item.id}`,
                                },
                                nativeOn: {
                                  change: function ($event) {
                                    return _vm.changeConcept(
                                      item,
                                      item,
                                      `allConcepts-${item.id}`
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.selectedConceptIds,
                                  callback: function ($$v) {
                                    _vm.selectedConceptIds = $$v
                                  },
                                  expression: "selectedConceptIds",
                                },
                              },
                              [
                                _c("template", { slot: "text" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(item.name) +
                                      " (Select All)\n\t\t\t\t\t\t\t"
                                  ),
                                ]),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _vm._l(item.concepts.data, function (conceptItem) {
                              return _c(
                                "div",
                                { key: conceptItem.id },
                                [
                                  _c(
                                    "BaseCheckbox",
                                    {
                                      staticClass:
                                        "tw--m-2 tw-py-2.5 tw-pl-9 tw-pr-0 tw-bg-white-high tw-overflow-ellipsis hover:tw-bg-grey-regular",
                                      attrs: {
                                        "checkbox-value": `concept-${conceptItem.id}`,
                                        value: `concept-${conceptItem.id}`,
                                        "checkbox-id": `concept-${conceptItem.id}`,
                                      },
                                      nativeOn: {
                                        change: function ($event) {
                                          return _vm.changeConcept(
                                            item,
                                            conceptItem,
                                            `concept-${conceptItem.id}`
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.selectedConceptIds,
                                        callback: function ($$v) {
                                          _vm.selectedConceptIds = $$v
                                        },
                                        expression: "selectedConceptIds",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "text" }, [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(conceptItem.name) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            }),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c("template", { slot: "filters" }, [
                      _vm._v("\n\t\t\t\t\t\tSelect concept\n\t\t\t\t\t"),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _vm._l(_vm.selectedSubjects, function (subject) {
          return _c(
            "tr",
            {
              key: subject.id,
              staticClass:
                "tagFilters__row tw-flex tw-justify-between tw-gap-x-2 tw-w-full",
            },
            [
              _c(
                "td",
                {
                  staticClass: "tagFilters__subjectCell tw-p-px",
                  attrs: { valign: "top" },
                },
                [
                  _c(
                    "Tag",
                    {
                      staticClass: "tw-my-1",
                      attrs: { "is-clearable": true, item: subject },
                    },
                    [
                      _c("template", { slot: "text" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(subject.name) +
                            "\n\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "template",
                        { slot: "icon" },
                        [
                          _c("IconClose", {
                            directives: [
                              {
                                name: "jest",
                                rawName: "v-jest",
                                value: "remove-subject",
                                expression: "'remove-subject'",
                              },
                            ],
                            staticClass: "tw-text-blue-regular",
                            nativeOn: {
                              click: function ($event) {
                                return _vm.removeSubject(subject)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("td", { staticClass: "tagFilters__topicsCell tw-p-px" }, [
                _c(
                  "table",
                  { staticClass: "tw-w-full" },
                  _vm._l(_vm.selectedTopics, function (topic) {
                    return _c(
                      "tr",
                      {
                        key: topic.id,
                        staticClass:
                          "tw-flex tw-justify-between tw-gap-x-2 tw-w-full",
                      },
                      [
                        topic.subject.data.id === subject.id
                          ? _c(
                              "td",
                              {
                                staticClass:
                                  "topicsTable__cell tw-flex-grow tw-flex-shrink-0 tw-p-0",
                              },
                              [
                                _c(
                                  "Tag",
                                  {
                                    staticClass: "tw-my-1",
                                    attrs: {
                                      "is-clearable": true,
                                      item: topic,
                                    },
                                  },
                                  [
                                    _c("template", { slot: "text" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(topic.name) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "template",
                                      { slot: "icon" },
                                      [
                                        _c("IconClose", {
                                          directives: [
                                            {
                                              name: "jest",
                                              rawName: "v-jest",
                                              value: "remove-topic-icon",
                                              expression: "'remove-topic-icon'",
                                            },
                                          ],
                                          attrs: { "icon-color": "#0E0FE0" },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.removeTopic(topic)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            staticClass:
                              "topicsTable__cell tw-flex-grow tw-flex-shrink-0 tw-p-0",
                          },
                          [
                            _c(
                              "table",
                              { staticClass: "tw-w-full" },
                              _vm._l(_vm.selectedConcepts, function (concept) {
                                return _c("tr", { key: concept.id }, [
                                  concept.topic_id === topic.id &&
                                  topic.subject.data.id === subject.id
                                    ? _c(
                                        "td",
                                        [
                                          _c(
                                            "Tag",
                                            {
                                              staticClass: "tw-my-1",
                                              attrs: {
                                                "is-clearable": true,
                                                item: concept,
                                              },
                                            },
                                            [
                                              _c("template", { slot: "text" }, [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(concept.name) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "template",
                                                { slot: "icon" },
                                                [
                                                  _c("IconClose", {
                                                    directives: [
                                                      {
                                                        name: "jest",
                                                        rawName: "v-jest",
                                                        value:
                                                          "remove-concept-icon",
                                                        expression:
                                                          "'remove-concept-icon'",
                                                      },
                                                    ],
                                                    attrs: {
                                                      "icon-color": "#0E0FE0",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.removeConcept(
                                                          concept
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ])
                              }),
                              0
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }