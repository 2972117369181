<template>
	<div>
		<BreaksNotification
			v-jest="'bio-break-activity-on-hold-notification'"
			notification-type="warning"
			class="tw-mb-4"
		>
			<p class="tw-font-bold tw-text-sm">
				You have an active classroom
			</p>
			<p class="tw-text-sm">
				Notify your students with the following message and then click Go on Break within 90s.
			</p>
			<br>
			<p class="tw-text-sm">
				I need to step away from the computer, but I’ll be back in just a few minutes!
			</p>
			<br>
			<p class="tw-text-sm">
				You will not receive any new students while on break.
			</p>
		</BreaksNotification>
		<OrigamiButton
			v-jest="'bio-break-activity-on-hold-cancel-button'"
			class="tw-mb-2 tw-w-full tw-justify-center"
			variant="secondary"
			@click.native="cancelBioBreak"
		>
			Cancel
		</OrigamiButton>
		<OrigamiButton
			v-jest="'bio-break-activity-on-hold-ok-button'"
			class="tw-mb-2 tw-w-full tw-justify-center"
			variant="primary"
			:is-loading="$store.state.BreaksManagementWidget.isRequestLoading"
			@click.native="startBioBreak"
		>
			Go on Break!
		</OrigamiButton>
	</div>
</template>

<script>
import { OrigamiButton } from "@origami/vue2";

import BreaksNotification from "../templates/BreaksNotification.vue";

export default {
	name: "BioBreakActivityOnHold",
	components: {
		BreaksNotification,
		OrigamiButton,
	},
	methods: {
		startBioBreak() {
			this.$store.dispatch("BreaksManagementWidget/startBioBreak");
		},
		cancelBioBreak() {
			this.$store.dispatch("BreaksManagementWidget/endBioBreak");
		},
	},
};
</script>
