export default (el, binding) => {
	if (binding.modifiers.animate) {
		el.style.transition = "opacity 1s";
	}
	if (binding.value) {
		el.style.visibility = "visible";
		el.style.opacity = 1;
	} else {
		el.style.opacity = 0;
		el.style.visibility = "hidden";
	}
};
