import { getTimestampFromWeekAgo, defaultEndDate } from "@/utilities/dateHelpers.js";

export default {
	configureDefaultDates(params) {
		params.from = params.from == undefined ? getTimestampFromWeekAgo(new Date()) : params.from;
		params.to = params.to == undefined ? defaultEndDate() : params.to;
		return params;
	},

	formatTutorLastName(lastname, userRole) {
		const activityUsersInternal = ["superuser-creator", "superuser", "tutor-manager"];
		const isInternalUser = activityUsersInternal.includes(userRole);
		return isInternalUser ? lastname : "";
	},
};
