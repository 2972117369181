import api from "@/services/api/Api.js";

export default {
	get(userId) {
		return api().get(`/users/v1/${userId}/preferences`);
	},
	update(userId, params) {
		return api().post(`/users/v1/${userId}/preferences`, params);
	},
};
