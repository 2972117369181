import { getFlag } from "@paper-co/web-toolkit";

import { REFERRAL_STORAGE_KEY, REFERRAL_REDEMPTION_STATUS_KEY } from "../utilities/index.js";
import ReferralsAPI from "../services/index.js";

export default [
	{
		path: "/r/:referralCode",
		children: [
			{
				path: "",
				beforeEnter: async(to, from, next) => {
					if (!(await getFlag("GROW-414-referrals"))) {
						return next("/");
					}
					window.sessionStorage.setItem(REFERRAL_STORAGE_KEY, to.params.referralCode);
					next(`/login/student?next=/r/${to.params.referralCode}/redeem`);
				},
			},
			{
				path: "redeem",
				beforeEnter: async(to, from, next) => {
					if (await getFlag("GROW-414-referrals")) {
						try {
							const referralCode = window.sessionStorage.getItem(REFERRAL_STORAGE_KEY);
							if (!referralCode || referralCode !== to.params.referralCode) {
								return next("/");
							}
							window.sessionStorage.removeItem(REFERRAL_STORAGE_KEY);
							await ReferralsAPI.redeem(referralCode);
							window.sessionStorage.setItem(REFERRAL_REDEMPTION_STATUS_KEY, true);
						} catch (error) {
							window.sessionStorage.setItem(REFERRAL_REDEMPTION_STATUS_KEY, false);
							if (error.status !== 422) {
								Sentry.captureException(error);
							}
						}
					}
					next("/");
				},
			},
		],
	},
];
