<template>
	<div class="section">
		<h2
			v-jest="'section-title'"
			class="tw-flex tw-text-xl tw-flex-wrap tw-font-origami-bold tw-mt-5"
		>
			{{ sectionHeader }}
			<OrigamiIconButton
				v-if="isLateTutorsSection"
				v-jest="'toggle-section-button'"
				class="tw-ml-1"
				:aria-label="'toggle late tutors section'"
				:icon-name="isOpen ? 'chevron-down' : 'chevron-up'"
				@click.native="toggleSection"
			/>
			<div class="section__titleButtons">
				<slot name="titleButtons" />
			</div>
		</h2>
		<div
			v-show="isOpen"
			v-jest="'tutor-card-list'"
			class="section__cardsList tw-inline-flex tw-flex-wrap tw-max-w-full"
		>
			<slot />
		</div>
		<BaseLink
			v-if="isSummarySection"
			class="section__viewAllEssaysLink"
			:to="viewAllEssaysLink"
		>
			View all submissions
		</BaseLink>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { OrigamiIconButton } from "@origami/vue2";

export default {
	components: {
		OrigamiIconButton,
	},
	props: {
		sectionTitle: {
			type: String,
			required: true,
		},
		cards: {
			type: Array,
			default: null,
		},
	},
	data() {
		return {
			isOpen: true,
		};
	},
	computed: {
		...mapState(["currentUser"]),
		/**
		 * @returns {Boolean}
		 */
		isSummarySection() {
			return this.sectionTitle === "At a Glance";
		},
		/**
		 * @returns {Boolean}
		 */
		isLateTutorsSection() {
			return this.sectionTitle === "Late Tutors";
		},
		/**
		 * @returns {String}
		 */
		viewAllEssaysLink() {
			return `/${this.currentUser.role}/services/essays-queue`;
		},
		/**
		 * @returns {String}
		 */
		sectionHeader() {
			return this.cards ? `${this.sectionTitle} (${this.cards.length})` : this.sectionTitle;
		},
	},

	methods: {
		toggleSection() {
			this.isOpen = !this.isOpen;
		},
	},
};
</script>

<style scoped>
.section {
	width: 1000px;
	max-width: 100%;
	display: flex;
	flex-direction: column;
}

.section__titleButtons {
	margin-left: auto;
}

.section__cardsList {
	width: 1000px;
	margin: 0 -8px;
}
.section__viewAllEssaysLink {
	font-size: 12px;
	color: var(--c-blue);
	margin: 0 16px;
	align-self: flex-end;
}
.section__viewAllEssaysLink:hover {
	text-decoration: underline;
	cursor: pointer;
}
</style>
