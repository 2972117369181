var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseButton",
    _vm._b(
      {
        directives: [
          {
            name: "b-tooltip",
            rawName: "v-b-tooltip",
            value: { title: _vm.$t("close") },
            expression: "{ title: $t('close') }",
          },
        ],
        staticClass: "embeddedCloseButton tw-leading-none",
        attrs: { variants: ["outline", "mini"], "aria-label": _vm.$t("close") },
        nativeOn: {
          click: function ($event) {
            return _vm.handleClose.apply(null, arguments)
          },
          mousedown: function ($event) {
            $event.preventDefault()
          },
        },
      },
      "BaseButton",
      _vm.baseButtonProps,
      false
    ),
    [_c("IconClose", { staticClass: "tw-h-4 tw-w-4" })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }