export default {
	en: {
		confirm: "Confirm",
		confirmModalTitle: "Send reviewed essay?",
		confirmModalText:
			"Once you confirm the submission, your completed review will be sent to the student.",
		ok: "Ok",
		error: "Oops",
		pleaseRefresh: "An error occurred. Please refresh the page!",
		pleaseRefreshAndTryAgain: "An error occurred. Please refresh the page and try again.",
		takenModalTitle: "Essay Being Reviewed",
		takenModalText:
			"This essay has already been picked up and is being reviewed by a fellow tutor. Please refresh to update the page.",
		pickupModalTitle: "Download essay?",
		pickupModalText: "Please review the selected essay within the next {timeLeft} hours.",
		download: "Download",
	},
	fr: {
		confirm: "Confirmer",
		confirmModalTitle: "Envoyer la rédaction?",
		confirmModalText: "Une fois que vous confirmez, votre révision sera envoyée à l'étudiant.",
		ok: "D'accord",
		error: "Oops",
		pleaseRefresh: "Une erreur s'est produite. SVP rafraîchir la page!",
		takenModalTitle: "Rédaction en révision.",
		takenModalText:
			"Cette rédcation a déjà été télécharger par un autre tuteur. SVP rafraîchir la page.",
		pickupModalTitle: "Télécharger la rédaction?",
		pickupModalText: "SVP réviser la rédaction dans les prochaines {timeLeft} heures.",
		download: "Télécharger",
	},
	es: {
		confirm: "Confirmar",
		confirmModalTitle: "¿Enviar ensayo revisado?",
		confirmModalText:
			"Una vez que confirmes, tu revisión será enviada al estudiante.",
		ok: "Ok",
		error: "Oh oh",
		pleaseRefresh: "Ocurrió un error. ¡Actualiza la página!",
		pleaseRefreshAndTryAgain: "Ocurrió un error. Actualiza la página y vuelve a intentarlo.",
		takenModalTitle: "Ensayo en revisión",
		takenModalText:
			"Este ensayo ya está siendo revisado por otro tutor. Actualiza la página.",
		pickupModalTitle: "¿Descargar el ensayo?",
		pickupModalText: "Revisa el ensayo dentro de las próximas {timeLeft} horas.",
		download: "Descargar",
	},
};
