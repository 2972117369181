export default {
	namespaced: true,
	state: {
		isShowingRedirectModal: false,
	},
	mutations: {
		SET_IS_SHOWING_REDIRECT_MODAL(state, isShowingRedirectModal) {
			state.isShowingRedirectModal = isShowingRedirectModal;
		},
	},
	getters: {
	},
	actions: {
	},
};
