<script setup>
import { reactive, computed } from "vue";

import useStore from "@/composables/useStore.js";

import {
	HIGH,
	MODERATE,
	LOW,
} from "../utilities/ConstantValues.js";

import RiskCard from "./RiskCard.vue";

const SUBJECT_LIMIT = 10;

const store = useStore();
const getHighRiskSubjects = computed(() => store.getters["ServiceAnalytics/getHighRiskSubjects"]);
const getModerateRiskSubjects = computed(() => store.getters["ServiceAnalytics/getModerateRiskSubjects"]);
const getLowRiskSujects = computed(() => store.getters["ServiceAnalytics/getLowRiskSujects"]);

const state = reactive({
	cards: [
		{
			id: 1,
			title: HIGH.title,
			icon: HIGH.icon,
			color: HIGH.color,
			tooltipText: HIGH.tooltipText,
			riskLevel: "high",
			subjects: getHighRiskSubjects,
		},
		{
			id: 2,
			title: MODERATE.title,
			icon: MODERATE.icon,
			color: MODERATE.color,
			tooltipText: MODERATE.tooltipText,
			riskLevel: "moderate",
			limit: SUBJECT_LIMIT,
			subjects: getModerateRiskSubjects,
		},
		{
			id: 3,
			title: LOW.title,
			icon: LOW.icon,
			color: LOW.color,
			tooltipText: LOW.tooltipText,
			riskLevel: "low",
			limit: SUBJECT_LIMIT,
			subjects: getLowRiskSujects,
		},
	],
});
</script>
<template>
	<section>
		<div class="tw-max-w-[1440px] tw-m-auto tw-px-8">
			<RiskCard
				v-for="card in state.cards"
				:id="`risk-card-${card.riskLevel}`"
				:key="card.id"
				v-jest="`risk-card-${card.riskLevel}`"
				:data-cy="`risk-card-${card.riskLevel}`"
				:card="card"
			/>
		</div>
	</section>
</template>
