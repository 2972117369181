var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 80 80",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("title", [_vm._v(_vm._s(_vm.$t("profile.settings.avatar_blank")))]),
      _vm._v(" "),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M40 40C51.05 40 60 31.05 60 20C60 8.95 51.05 0 40 0C28.95 0 20 8.95 20 20C20 31.05 28.95 40 40 40ZM40 50C26.65 50 0 56.7 0 70V80H80V70C80 56.7 53.35 50 40 50Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }