var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "nav-wrapper",
          expression: "'nav-wrapper'",
        },
      ],
      ref: "navWrapper",
      class: {
        "tw-mb-[80px] md:tw-mb-0": _setup.featureFlagSidebarRedesignIsEnabled,
      },
      attrs: { tabindex: "-1" },
      on: {
        keydown: function ($event) {
          _setup.isMobileMenuToggled && _setup.handleFocusMobile($event)
        },
      },
    },
    [
      _c(_setup.SkipToMainContent, {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "skip-to-main",
            expression: "'skip-to-main'",
          },
        ],
      }),
      _vm._v(" "),
      _setup.isShowingNonStudentNavigation
        ? _c(_setup.Navbar, {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "navbar",
                expression: "'navbar'",
              },
            ],
            attrs: {
              "view-as-links": _vm.viewAsLinks,
              "normal-links": _vm.normalLinks,
              "user-links": _vm.userLinks,
              languages: _vm.languages,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "appendAfterNavLinks",
                  fn: function () {
                    return [
                      _setup.isUserTutor
                        ? _c(_setup.PunchClockWrapper, {
                            directives: [
                              {
                                name: "jest",
                                rawName: "v-jest",
                                value: "punch-clock-wrapper",
                                expression: "'punch-clock-wrapper'",
                              },
                            ],
                          })
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2185086064
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _setup.isShowingStudentNavigation && !_setup.isMobileWebviewOpened
        ? _c(_setup.StudentNavbar, {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "student-navbar",
                expression: "'student-navbar'",
              },
            ],
            ref: "topNavbar",
            attrs: {
              "is-sidebar-toggled": _setup.isSidebarToggled,
              "is-mobile-menu-toggled": _setup.isMobileMenuToggled,
            },
            on: {
              "toggle-sidebar": _setup.handleSidebarToggle,
              "toggle-mobile-menu": _setup.handleMobileMenuToggle,
              "close-mobile-menu": _setup.closeMobileMenu,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "navigation-container",
              expression: "'navigation-container'",
            },
          ],
          staticClass: "navigation-container tw-flex",
          class: {
            "navigation-container--fixed-navbar tw-relative":
              _setup.featureFlagSidebarRedesignIsEnabled,
          },
        },
        [
          _setup.isShowingStudentNavigation
            ? _c(_setup.StudentSidebarWrapper, {
                ref: "studentSidebarWrapper",
                attrs: {
                  "is-mobile-menu-toggled": _setup.isMobileMenuToggled,
                  "is-mobile-webview-opened": _setup.isMobileWebviewOpened,
                  "is-sidebar-toggled": _setup.isSidebarToggled,
                },
                on: {
                  "open-drawer": function ($event) {
                    _setup.isSidebarNewOpen = true
                  },
                  "close-drawer": function ($event) {
                    _setup.isSidebarNewOpen = false
                  },
                  "reset-mobile-menu": _setup.handleResetMobileMenu,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "navigation-content tw-overflow-hidden",
              class: {
                "tw-hidden":
                  _setup.isMobileMenuToggled && !_setup.isShowingDesktopView,
                "md:tw-ml-[80px] tw-mt-[88px]":
                  _setup.featureFlagSidebarRedesignIsEnabled,
                "md:tw-ml-[346px]": _setup.isSidebarNewOpen,
              },
              attrs: { id: "main-content" },
            },
            [_vm._t("default")],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _setup.isShowingStudentNavigation &&
      !_setup.route.meta?.noFooter &&
      !_setup.isMobileWebviewOpened
        ? _c(_setup.Footer, {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "footer",
                expression: "'footer'",
              },
            ],
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }