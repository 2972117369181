import StudentFilters from "./modules/StudentFilters.js";
import StudentList from "./modules/StudentList.js";

export default {
	namespaced: true,
	modules: {
		StudentFilters,
		StudentList,
	},
};
