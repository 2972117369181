<template>
	<BModal
		:id="id"
		:ref="modalRef"
		v-model="isShowingModal"
		body-class="tw-pt-5 tw-px-5 tw-pb-9"
		content-class="tw-rounded-lg"
		hide-header
		hide-footer
		centered
		@show="$emit('show', $event)"
		@shown="$emit('shown', $event)"
		@hide="$emit('hide', $event)"
		@hidden="$emit('hidden', $event)"
	>
		<div class="tw-flex tw-justify-end">
			<BaseIconButton
				v-jest="'close-modal'"
				:aria-label="$te('accessibilty.close_modal') ? $t('accessibilty.close_modal') : 'Close modal'"
				@click.native="isShowingModal = false"
			>
				<IconClose />
			</BaseIconButton>
		</div>
		<div class="tw-pl-3.5 tw-pr-9">
			<slot />
		</div>
	</BModal>
</template>

<script>
import BaseIconButton from "../elements/BaseIconButton.vue";
import IconClose from "../elements/icons/Close.vue";

export default {
	components: {
		BaseIconButton,
		IconClose,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
		modalRef: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			isShowingModal: false,
		};
	},
};
</script>
<style scoped>
:deep(.modal) {
	background-color: var(--blue-slightly);
}
</style>
