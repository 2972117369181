var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("Transition", { attrs: { name: "slide-right" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "mobile-nav",
            expression: "'mobile-nav'",
          },
        ],
        staticClass: "mobile-nav",
        attrs: { id: "mobile-nav" },
      },
      [
        _c(_setup.LearnerProfileLink, {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "learner-profile-link",
              expression: "'learner-profile-link'",
            },
          ],
          on: {
            redirect: function ($event) {
              return _vm.$emit("reset-mobile-menu")
            },
          },
        }),
        _vm._v(" "),
        _c(_setup.Links, {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "mobile-nav-links",
              expression: "'mobile-nav-links'",
            },
          ],
          staticClass: "tw-border-b tw-border-grey-regular tw-py-2",
          attrs: { "nav-links": _setup.reactiveNavLinks },
          on: {
            "reset-mobile-menu": function ($event) {
              return _vm.$emit("reset-mobile-menu")
            },
          },
        }),
        _vm._v(" "),
        _c(_setup.Links, {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "mobile-dropdown-links",
              expression: "'mobile-dropdown-links'",
            },
          ],
          ref: "navLinksDropdown",
          staticClass: "tw-border-grey-regular tw-pt-2",
          attrs: {
            "nav-links": _setup.reactiveDropdownLinks,
            "is-mobile": true,
          },
          on: {
            "reset-mobile-menu": function ($event) {
              return _vm.$emit("reset-mobile-menu")
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }