export const STORAGE_KEYS = {
	REVIEW_COMMENT: "review_comment",
	XFDF_ANNOTATIONS: "review_xfdf",
	SUMMARY_REPHRASE_COMMENTS: "review_summary_rephrase_comments",
};

export const manageLocalStorage = (id, storageType) => {
	if (!id || !storageType) {
		console.error(`Missing id: ${id} or storage: ${storageType}`);
	}

	// Storage key reference
	const key = `${storageType}_${id}`;

	// Store value as string
	return {
		setItem(value) {
			const valueToStore = typeof value === "string"
				? value
				: JSON.stringify(value);
			localStorage.setItem(key, valueToStore);
		},
		getItem() {
			const item = this.getRawItem();
			if (!item) {
				return item;
			}

			const valueFromStore = JSON.parse(item);
			return valueFromStore ? valueFromStore : item;
		},
		getRawItem() {
			return localStorage.getItem(key);
		},
		removeItem() {
			localStorage.removeItem(key);
		},
	};
};
