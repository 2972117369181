var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "OrigamiCard",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "recognition-card",
          expression: "'recognition-card'",
        },
      ],
      staticClass:
        "sessionRecognitionsCard tw-flex tw-flex-col tw-p-6 tw-gap-4",
      attrs: { "is-interactive": _vm.isInteractive, tag: _vm.tag },
    },
    [
      _c(
        "h3",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "from-user",
              expression: "'from-user'",
            },
          ],
          staticClass: "tw-font-bold",
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.title) + "\n\t")]
      ),
      _vm._v(" "),
      _vm.message
        ? _c(
            "p",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "message",
                  expression: "'message'",
                },
              ],
            },
            [_vm._v('\n\t\t"' + _vm._s(_vm.message) + '"\n\t')]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "tw-gap-4 tw-flex tw-flex-col" },
        _vm._l(_vm.recognitions, function (recognition) {
          return _c("RecognitionsCardItem", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "recognitions",
                expression: "'recognitions'",
              },
            ],
            key: recognition.recognition_type_id,
            attrs: {
              id: recognition.recognition_type_id,
              tag: "li",
              name: recognition.recognitionType.data.name,
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.isShowingSessionDetails
        ? _c(
            "p",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "student-details",
                  expression: "'student-details'",
                },
              ],
              staticClass: "tw-text-sm tw-text-origami-grey-300",
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "session-date",
                      expression: "'session-date'",
                    },
                  ],
                },
                [
                  _vm._v(
                    _vm._s(_vm.getDateFromTimestamp(_vm.session.created_at))
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.session.requestedSubject.data.id !== _vm.SUBJECT_ID_TRIAGE
                ? [
                    _vm._v("\n\t\t\t•\n\t\t\t"),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "subject-name",
                            expression: "'subject-name'",
                          },
                        ],
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t" +
                            _vm._s(
                              _vm.getTranslatedSubject(
                                _vm.session.requestedSubject.data.name
                              )
                            ) +
                            "\n\t\t\t"
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }