<template>
	<div>
		<label class="tw-text-sm tw-font-bold">
			{{ $t("chooseSection") }} *
		</label>
		<br>
		<BaseDropdown
			ref="classroomsDropdown"
			v-data-cy="'classroom-dropdown'"
			v-jest="'classroom-dropdown'"
			:items="sections"
			class="essayReview__classroomDropdown"
			:class="{ 'warning': error }"
		>
			<template slot="filters">
				{{ classroomDropdownText }}
			</template>
			<template #default="{ item }">
				<BaseRadio
					v-model="selectedClassroom"
					v-data-cy="`classroom-${item.id}`"
					radio-name="classroom"
					:radio-value="item.id"
					:radio-id="`classroom-${item.id}`"
					class="essayReview__classroomItem"
				>
					<template slot="text">
						{{ getItemName(item) }}
					</template>
				</BaseRadio>
			</template>
		</BaseDropdown>
		<span
			v-show="error"
			v-jest="'classroom-dropdown-error'"
			class="tw-text-sm tw-font-bold tw-text-origami-red-400"
		>
			{{ $t("chooseSection") }}
		</span>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { isEmpty } from "lodash";

import BaseRadio from "@/components/BaseRadio.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";

export default {
	components: {
		BaseRadio,
		BaseDropdown,
	},
	props: {
		value: { type: Number, default: 0 },
		error: { type: Boolean, default: false },
	},
	data() {
		return {
			selectedClassroom: this.value,
		};
	},
	computed: {
		...mapState(["Student"]),
		sections() {
			return this.Student.Essays.sections;
		},
		classroomDropdownText() {
			const selectedClassroom = this.sections.find((section) => section.id === this.selectedClassroom);
			if (selectedClassroom) {
				return selectedClassroom.course.data.name;
			}
			return this.$t("chooseSection");
		},
	},
	watch: {
		selectedClassroom(value) {
			this.$emit("input", value);
		},
	},
	async created() {
		const featureFlagCanvasIntegrationIsEnabled = await this.$getFlag("GROW-882-canvas-assignment-linking");
		if (
			featureFlagCanvasIntegrationIsEnabled &&
			(
				this.$route.query?.section_id ||
				this.$route.query?.subject_id
			)
		) {
			if (this.$route.query.section_id) {
				this.selectedClassroom = this.sections.find(
					(section) => section.id === Number(this.$route.query.section_id),
				).id;
			} else {
				const assignmentSubject = Number(this.$route.query.subject_id);
				const classWithAssignmentSubject = this.sections.find((section) => {
					return section.course.data.subjects.data.some((subject) => subject.id === assignmentSubject);
				});
				this.selectedClassroom = classWithAssignmentSubject?.id;
			}

		}
	},
	methods: {
		getItemName(item) {
			return isEmpty(item.course.data) ? item.name : item.course.data.name;
		},
	},
};
</script>

<style scoped>
.warning :deep(.mainDropdown) {
	border: 2px solid var(--origami-red-400);
}
.essayReview__classroomDropdown .essayReview__classroomItem :deep(input) {
	height: 100%;
	margin: 0;
	width: 100%;
}
.essayReview__classroomItem :deep(label) {
	height: auto;
}
.essayReview__classroomItem :deep(label p) {
	margin-bottom: 0;
}
.essayReview__classroomItem :deep(label:before) {
	top: calc(50% - 10px);
}
.essayReview__classroomItem :deep(label:after) {
	top: calc(50% - 5px);
}
</style>
