var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "tw-flex tw-flex-col tw-items-center tw-max-w-sm tw-text-center tw-p-6",
    },
    [
      _c("MissingErrorIllustration", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "illustration",
            expression: "'illustration'",
          },
        ],
        staticClass: "tw-mb-4",
        attrs: { width: _vm.small ? 144 : 160, height: _vm.small ? 144 : 160 },
      }),
      _vm._v(" "),
      _vm.text
        ? _c(
            "p",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "text",
                  expression: "'text'",
                },
              ],
              staticClass: "tw-mb-6",
              class: _vm.small ? "tw-text-base" : "tw-text-md",
            },
            [_vm._v("\n\t\t" + _vm._s(_vm.text) + "\n\t")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.buttonText
        ? _c(
            "BaseButton",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "button",
                  expression: "'button'",
                },
              ],
              nativeOn: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("button-click", $event)
                },
              },
            },
            [_vm._v("\n\t\t" + _vm._s(_vm.buttonText) + "\n\t")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }