var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "points",
          expression: "'points'",
        },
      ],
      staticClass:
        "tw-flex tw-items-center tw-justify-center tw-gap-2 tw-whitespace-nowrap",
    },
    [
      _vm._t("prepend"),
      _vm._v(" "),
      _c("Emoji", { attrs: { emoji: "💎", "aria-hidden": "" } }),
      _vm._v(" "),
      _vm.isLoading
        ? _c("BaseSkeletonLoader", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "loader",
                expression: "'loader'",
              },
            ],
            staticClass: "tw-w-20 tw-h-6",
          })
        : [
            _vm._v(
              "\n\t\t" + _vm._s(_vm.points) + " " + _vm._s(_vm.label) + "\n\t"
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }