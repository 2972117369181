var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M2.25 0C1.00736 0 0 1.00736 0 2.25V15.75C0 16.9926 1.00736 18 2.25 18H15.75C16.9926 18 18 16.9926 18 15.75V2.25C18 1.00736 16.9926 0 15.75 0H2.25ZM6.25919 11.4342H8.08557C8.58989 11.4342 8.99872 11.8431 8.99872 12.3474C8.99872 12.8517 8.58989 13.2605 8.08557 13.2605H6.25919V15.0868C6.25919 15.5912 5.85036 16 5.34604 16C4.84172 16 4.43289 15.5912 4.43289 15.0868V13.2605H2.60666C2.10234 13.2605 1.69351 12.8517 1.69351 12.3474C1.69351 11.8431 2.10234 11.4342 2.60666 11.4342H4.43289V9.60794C4.43289 9.10362 4.84172 8.69479 5.34604 8.69479C5.85036 8.69479 6.25919 9.10362 6.25919 9.60794V11.4342ZM15.3908 10.2166H11.7382C11.2338 10.2166 10.825 10.6255 10.825 11.1298C10.825 11.6341 11.2338 12.0429 11.7382 12.0429H15.3908C15.8951 12.0429 16.3039 11.6341 16.3039 11.1298C16.3039 10.6255 15.8951 10.2166 15.3908 10.2166ZM3.2154 4.43339H7.47677C7.98109 4.43339 8.38992 4.84222 8.38992 5.34654C8.38992 5.85086 7.98109 6.25969 7.47677 6.25969H3.2154C2.71108 6.25969 2.30225 5.85086 2.30225 5.34654C2.30225 4.84222 2.71108 4.43339 3.2154 4.43339ZM15.3908 13.2605H11.7382C11.2338 13.2605 10.825 13.6693 10.825 14.1736C10.825 14.6779 11.2338 15.0868 11.7382 15.0868H15.3908C15.8951 15.0868 16.3039 14.6779 16.3039 14.1736C16.3039 13.6693 15.8951 13.2605 15.3908 13.2605ZM11.9682 5.34656L10.6782 6.63658C10.3216 6.99319 10.3216 7.57137 10.6782 7.92798C11.0348 8.28458 11.6129 8.28458 11.9696 7.92798L13.2596 6.63795L14.55 7.92839C14.9066 8.28499 15.4848 8.28499 15.8414 7.92839C16.198 7.57178 16.198 6.9936 15.8414 6.63699L14.551 5.34656L15.8437 4.0538C16.2003 3.69719 16.2003 3.11902 15.8437 2.76241C15.4871 2.4058 14.9089 2.4058 14.5523 2.76241L13.2596 4.05517L11.9672 2.76282C11.6106 2.40621 11.0324 2.40621 10.6758 2.76282C10.3192 3.11943 10.3192 3.6976 10.6758 4.05421L11.9682 5.34656Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }