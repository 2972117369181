var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "actionItem tw-py-4 tw-w-full tw-border-b-2 tw-border-grey-regular tw-border-solid tw-flex tw-justify-between tw-gap-x-4 tw-items-center",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "tw-flex tw-w-60 tw-flex-shrink-1 tw-flex-col tw-items-start tw-truncate",
        },
        [
          _c(
            "p",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "tutor-name",
                  expression: "'tutor-name'",
                },
              ],
              staticClass: "tw-text-base tw-font-bold",
            },
            [
              _vm._v("\n\t\t\t" + _vm._s(_vm.tutor.name) + " "),
              _vm.tutor.is_new
                ? _c(
                    "span",
                    { staticClass: "tw-text-origami-blue-300 tw-truncate" },
                    [_vm._v("(New Tutor)")]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "tutor-shift-end",
                  expression: "'tutor-shift-end'",
                },
              ],
              staticClass: "tw-text-xs tw-truncate",
              class: _vm.isShiftOver ? "tw-text-origami-red-400" : "",
            },
            [_vm._v("\n\t\t\t" + _vm._s(_vm.timeLeftInShiftText) + "\n\t\t")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tw-flex tw-gap-x-4 tw-items-center tw-flex-grow" },
        [
          _c("BaseIconButton", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "essay-icon",
                expression: "'essay-icon'",
              },
              {
                name: "b-tooltip",
                rawName: "v-b-tooltip.hover.focus",
                value: _vm.tooltipOptions,
                expression: "tooltipOptions",
                modifiers: { hover: true, focus: true },
              },
            ],
            staticClass:
              "actionItem__essayButton tw-w-10 tw-h-10 tw-flex-shrink-0",
            attrs: { id: "essayIcon", "aria-label": "link to the essay" },
            nativeOn: {
              click: function ($event) {
                return _vm.handleEssayClick.apply(null, arguments)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return [
                    _c("IconEssayOutline", {
                      staticClass: "essayIcon tw-text-origami-blue-300",
                      attrs: {
                        height: 20,
                        width: 16,
                        "icon-color": "currentColor",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "tw-h-10 tw-py-1 tw-px-4 tw-rounded-full tw-border tw-border-origami-text-black",
            },
            [
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "essay-review-time",
                      expression: "'essay-review-time'",
                    },
                  ],
                  staticClass:
                    "tw-text-lg tw-font-bold tw-text-origami-text-black tw-truncate",
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" + _vm._s(_vm.essayReviewTime) + "\n\t\t\t"
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tw-flex tw-gap-x-4 tw-items-center" },
        [
          _c(
            "p",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "status-text",
                  expression: "'status-text'",
                },
              ],
              staticClass: "tw-text-xs tw-font-bold tw-truncate tw-flex-shrink",
            },
            [_vm._v("\n\t\t\t" + _vm._s(_vm.statusText) + "\n\t\t")]
          ),
          _vm._v(" "),
          _c("OrigamiIconButton", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "inspect-uninspect-button",
                expression: "'inspect-uninspect-button'",
              },
            ],
            attrs: {
              "icon-name": "inspect",
              "aria-label": _vm.inspectAriaLabel,
              "is-selected": _vm.isCurrentlyInspecting,
            },
            nativeOn: {
              click: function ($event) {
                _vm.isCurrentlyInspecting
                  ? _vm.handleAction(_vm.buttonActions[1])
                  : _vm.handleAction(_vm.buttonActions[0])
              },
            },
          }),
          _vm._v(" "),
          _c("OrigamiIconButton", {
            attrs: {
              "icon-name": "block",
              "aria-label": "This item is a False Alarm",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.handleAction(_vm.buttonActions[2])
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tw-w-36 tw-flex-shrink-0" },
            [
              _c("BaseDropdown", {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "action-dropdown",
                    expression: "'action-dropdown'",
                  },
                ],
                staticClass: "action__dropdown",
                attrs: {
                  "v-model": _vm.actionItem.action_type_id,
                  items: _vm.filteredActions,
                  "close-on-select": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "filters",
                    fn: function () {
                      return [
                        _vm.isActionPending
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "tw-flex tw-justify-center tw-items-center",
                              },
                              [_c("LoadingRectangles")],
                              1
                            )
                          : _c(
                              "span",
                              {
                                staticClass:
                                  "tw-flex tw-justify-start tw-items-center",
                              },
                              [
                                _vm.actionItem.picked_up_at &&
                                _vm.actionItem.action_type_id
                                  ? _c("OrigamiIcon", {
                                      staticClass:
                                        "tw-mr-3.5 tw-text-black-high",
                                      attrs: {
                                        name: _vm.actions[
                                          _vm.getActionTypeIndex(
                                            _vm.actionItem.action_type_id
                                          )
                                        ].icon,
                                        width: "20",
                                        height: "20",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(_vm.actionName) +
                                    "\n\t\t\t\t\t"
                                ),
                              ],
                              1
                            ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "tw-flex tw-justify-between tw-items-center",
                          },
                          [
                            _c(
                              "label",
                              {
                                directives: [
                                  {
                                    name: "jest",
                                    rawName: "v-jest",
                                    value: "action-label",
                                    expression: "'action-label'",
                                  },
                                ],
                                staticClass:
                                  "tw-flex tw-items-center tw-flex-grow tw-text-xs tw--mb-0 tw-p-2 tw-truncate tw-cursor-pointer",
                                attrs: {
                                  for: `actionItem-${_vm.actionItem.id}-${item.name}`,
                                },
                              },
                              [
                                _c("OrigamiIcon", {
                                  staticClass: "tw-mr-3.5 tw-text-black-high",
                                  attrs: {
                                    name: item.icon,
                                    width: "20",
                                    height: "20",
                                  },
                                }),
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(item.name) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "jest",
                                  rawName: "v-jest",
                                  value: "action",
                                  expression: "'action'",
                                },
                              ],
                              staticClass: "sr-only tw-cursor-pointer",
                              attrs: {
                                id: `actionItem-${_vm.actionItem.id}-${item.name}`,
                                value: item.id,
                                type: "button",
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.handleAction(item)
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.isItemSelected(item.id)
                              ? _c("IconCheckMark", {
                                  staticClass:
                                    "tw-text-blue-regular tw-mr-2 tw-cursor-pointer",
                                  attrs: {
                                    width: 16,
                                    height: 16,
                                    "icon-color": "currentColor",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handleAction(item)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }