var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shiftsCalendar tw-w-full" },
    [
      _c("DropDown", {
        directives: [
          {
            name: "data-cy",
            rawName: "v-data-cy",
            value: "tutor-list-dropdown",
            expression: "'tutor-list-dropdown'",
          },
        ],
        staticClass: "shiftsCalendar__tutorDropdown",
        attrs: {
          label: "Tutor",
          "is-disabled": "",
          items: _vm.PlatformManager.ServiceTutorsSchedule.tutorsList,
          "get-item-text": (item) => `${item.name} - ${item.id}`,
        },
        model: {
          value: _vm.tutorId,
          callback: function ($$v) {
            _vm.tutorId = $$v
          },
          expression: "tutorId",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "shiftsCalendar__calendar" },
        [
          _c(
            "label",
            { staticClass: "tw-font-bold tw-text-sm tw-text-xs tw-mb-[7px]" },
            [_vm._v("\n\t\t\tTime\n\t\t")]
          ),
          _vm._v(" "),
          _c("DatePicker", {
            attrs: {
              popover: { placement: "right" },
              is24hr: true,
              mode: "dateTime",
              "minute-increment": 15,
              "min-date": _vm.earliestCalendarDateAvailable,
              "is-range": true,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ inputValue, inputEvents }) {
                  return [
                    _c(
                      "input",
                      _vm._g(
                        {
                          staticClass:
                            "datePicker__input tw-border-blue-regular",
                          class: {
                            datePicker__editing: !_vm.isEditing,
                            "tw-opacity-60": !_vm.isEditing,
                          },
                          attrs: { placeholder: _vm.placeholder },
                          domProps: { value: inputValue.start },
                        },
                        inputEvents.start
                      )
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.selectedRange,
              callback: function ($$v) {
                _vm.selectedRange = $$v
              },
              expression: "selectedRange",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("DropDown", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "shift-type-dropdown",
            expression: "'shift-type-dropdown'",
          },
        ],
        staticClass: "shiftsCalendar__shiftTypeDropdown",
        attrs: {
          "data-cy": "shift-type-dropdown",
          label: "Shift Type",
          "is-disabled": _vm.isShiftTypeSelectDisabled,
          items: _vm.shiftTypeSelectItems,
          "get-item-text": (item) => _vm.getShiftTypeName(item),
        },
        model: {
          value: _vm.shiftTypeId,
          callback: function ($$v) {
            _vm.shiftTypeId = $$v
          },
          expression: "shiftTypeId",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }