<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		:width="width"
		:height="height"
		viewBox="0 0 25 25"
	>
		<defs>
			<path
				:id="randomId"
				d="M1.142 11.429c0 6.31 5.117 11.428 11.428 11.428C18.883 22.857 24 17.74 24 11.43 24 5.117 18.883 0 12.57 0 6.26 0 1.142 5.117 1.142 11.429z"
			/>
		</defs>
		<g
			fill="none"
			fill-rule="evenodd"
		>
			<g transform="translate(0 1.143)">
				<mask
					:id="`a${randomId}`"
					fill="#fff"
				>
					<use :xlink:href="`#${randomId}`" />
				</mask>
				<path
					fill="#FED77A"
					d="M-4.571 28.571h34.285V-5.714H-4.57z"
					:mask="`url(#a${randomId})`"
				/>
			</g>
			<path
				stroke="#E1C072"
				d="M12.571 24C18.883 24 24 18.882 24 12.571 24 6.26 18.883 1.143 12.57 1.143c-6.31 0-11.428 5.116-11.428 11.428C1.143 18.882 6.26 24 12.57 24z"
			/>
			<path
				fill="#ED7B7D"
				d="M16.63 11.644a.783.783 0 0 0 .489.014c.71-.206 2.51-.756 3.847-1.4 1.021-.49 1.856-1.183 2.377-1.68.18-.152.348-.324.496-.509l.064-.074.283-.45c.109-.197.2-.406.27-.623.585-1.795-.31-3.755-2.039-4.464a3.55 3.55 0 0 0-2.487-.083A3.56 3.56 0 0 0 17.95.867c-1.789-.543-3.722.411-4.4 2.171a3.692 3.692 0 0 0-.186.654l-.065.528.004.097c-.003.238.02.476.063.709.09.714.298 1.777.78 2.804.631 1.343 1.678 2.907 2.098 3.516a.782.782 0 0 0 .388.297M8.513 11.644a.783.783 0 0 1-.488.014c-.712-.206-2.511-.756-3.847-1.4-1.023-.49-1.856-1.183-2.377-1.68a3.537 3.537 0 0 1-.496-.509c-.043-.048-.064-.074-.064-.074l-.285-.45a3.74 3.74 0 0 1-.27-.623c-.585-1.795.31-3.755 2.04-4.464a3.554 3.554 0 0 1 2.487-.083A3.56 3.56 0 0 1 7.195.867c1.787-.543 3.721.411 4.4 2.171.082.214.144.432.185.654l.064.528s0 .034-.003.097c.002.238-.019.476-.063.709-.09.714-.297 1.777-.78 2.804-.63 1.343-1.677 2.907-2.098 3.516a.792.792 0 0 1-.387.297"
			/>
			<path
				fill="#3C5396"
				d="M6.248 15.588c-.258-.75.432-1.486 1.225-1.322 1.355.278 3.354.68 5.084.639 2.046-.05 3.817-.277 5.117-.535.768-.153 1.434.516 1.242 1.257-.577 2.227-2.523 4.992-5.907 4.992-3.509 0-5.905-2.544-6.761-5.03"
			/>
		</g>
	</svg>
</template>

<script>
import IconId from "./IconId.js";
export default {
	extends: IconId,
	props: {
		width: {
			type: [Number, String],
			default: 25,
		},
		height: {
			type: [Number, String],
			default: 25,
		},
	},
};
</script>
