import SessionSharesAPI from "../../services/api/SessionShares.js";

const getDefaultState = () => ({
	sessionObject: {},
	shareDate: "",
	teacherName: "",
	isLoading: true,
});

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations: {
		RESET_STATE(state) {
			Object.assign(state, getDefaultState());
		},
		SET_SESSION_OBJECT(state, payload) {
			payload.sessionObject.public = true;
			state.sessionObject = payload.sessionObject;
		},
		SET_SHARE_DATE(state, payload) {
			state.shareDate = payload.shareDate;
		},
		SET_TEACHER_NAME(state, payload) {
			state.teacherName = payload.teacherName;
		},
		SET_IS_LOADING(state, payload) {
			state.isLoading = payload.isLoading;
		},
	},
	actions: {
		async getSharedSession({ commit }, key) {
			try {
				const response = await SessionSharesAPI.show(key);
				commit("SET_SESSION_OBJECT", {
					sessionObject: response.data.session,
				});
				commit("SET_SHARE_DATE", {
					shareDate: response.data.shareDate,
				});
				commit("SET_TEACHER_NAME", {
					teacherName:
						response.data.sharingUser.firstName + " " + response.data.sharingUser.lastName,
				});
				commit("SET_IS_LOADING", {
					isLoading: false,
				});
				Promise.resolve();
			} catch (e) {
				Promise.reject(e);
			}
		},
	},
};
