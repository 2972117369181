<template>
	<p
		:class="currentShiftScheduleTypeColor"
		class="tw-text-center tw-inline-block tw-px-1 tw-py-0 tw-rounded-md tw-font-bold tw-text-sm"
	>
		{{ name }}
	</p>
</template>
<script>
import { getOrigamiColorFromShiftTypeId, getShiftTypeNameFromShiftTypeId } from "@/utilities/ScheduleHelpers.js";

export default {
	props: {
		shiftTypeId: {
			type: Number,
			required: true,
		},
	},
	computed: {
		currentShiftScheduleTypeColor() {
			return getOrigamiColorFromShiftTypeId(this.shiftTypeId);
		},
		name() {
			return getShiftTypeNameFromShiftTypeId(this.shiftTypeId);
		},
	},
};
</script>
