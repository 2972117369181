<template>
	<TutorEssayFlow />
</template>

<script>
import TutorEssayFlow from "./TutorEssayFlow.vue";

export default {
	components: {
		TutorEssayFlow,
	},
};
</script>
