export default [
	{
		path: "teacher-content",
		component: () => import("../../components/teacher-content/TeacherContentView.vue"),

		children: [
			{
				path: "",
				name: "superuserTeacherContentList",
				component: () => import("../../components/teacher-content/TeacherContentListWrapper.vue"),
			},
			{
				path: "create",
				name: "superuserTeacherContentCreate",
				component: () => import("../../components/teacher-content/TeacherContentCreateWrapper.vue"),
			},
			{
				path: ":teacherContentId",
				name: "superuserTeacherContentEdit",
				component: () => import("../../components/teacher-content/TeacherContentEditWrapper.vue"),
			},
		],
	},
];
