var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "loadingRectangles tw-my-0 tw-mx-auto tw-w-10 tw-h-5 tw-text-center tw-text-xs",
    },
    _vm._l(4, function (number) {
      return _c("div", {
        key: number,
        class: `rect${number} tw-h-full tw-w-1 tw-my-0 tw-mx-px tw-inline-block`,
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }