<script setup>
import { reactive, computed } from "vue";

import useStore from "@/composables/useStore.js";

import { useLoading } from "../composables/loading.js";
import {
	HIGH,
	MODERATE,
	LOW,
} from "../utilities/ConstantValues.js";

import ServiceAnalyticsFilters from "./ServiceAnalyticsFilters.vue";
import ServiceAnalyticsSummaryCard from "./ServiceAnalyticsSummaryCard.vue";
import ServiceAnalyticsPageHeader from "./ServiceAnalyticsPageHeader.vue";

const store = useStore();

const isLoading = useLoading();

const highRiskSubjectsCount = computed(() => store.getters["ServiceAnalytics/getHighRiskSubjects"].length);
const moderateRiskSubjectsCount = computed(() => store.getters["ServiceAnalytics/getModerateRiskSubjects"].length);
const lowRiskSujectsCount = computed(() => store.getters["ServiceAnalytics/getLowRiskSujects"].length);

const state = reactive({
	alerts: [
		{
			title: HIGH.title,
			icon: HIGH.icon,
			color: HIGH.color,
			numberOfSubjects: highRiskSubjectsCount,
			cardID: "risk-card-high",
		},
		{
			title: MODERATE.title,
			icon: MODERATE.icon,
			color: MODERATE.color,
			numberOfSubjects: moderateRiskSubjectsCount,
			cardID: "risk-card-moderate",
		},
		{
			title: LOW.title,
			icon: LOW.icon,
			color: LOW.color,
			numberOfSubjects: lowRiskSujectsCount,
			cardID: "risk-card-low",
		},
	],
});
</script>

<template>
	<div
		id="service-analytics-header"
		class="tw-shadow-elevation-2"
	>
		<ServiceAnalyticsPageHeader
			v-jest="'analytics-page-header'"
		/>
		<div class="tw-max-w-[1440px] tw-m-auto tw-px-8">
			<div
				class="tw-bg-origami-white tw-rounded-lg tw-border-origami-grey-200 tw-border-2 lg:tw-flex tw-px-8 tw-py-3 tw-mb-3 lg:tw-space-x-8 tw-divide-x-2 tw-divide-origami-grey-100"
				:class="{
					'tw-bg-origami-grey-200 tw-rounded-none': isLoading,
				}"
				tabindex="-1"
			>
				<ServiceAnalyticsSummaryCard
					v-for="alert in state.alerts"
					:key="alert.title"
					v-jest="`analytics-summary-${alert.cardID}`"
					:data-cy="`${alert.title}-summary-card`"
					class="analytics-summary-cards"
					:class="{ 'tw-invisible': isLoading }"
					:alert="alert"
				/>
			</div>
		</div>
		<ServiceAnalyticsFilters
			v-jest="'analytics-page-filters'"
			class="tw-max-w-[1440px] tw-m-auto tw-px-8"
		/>
	</div>
</template>

<style scoped>
	.origami-page-header .layout__content .content__title {
		font-size: var(--origami-font-size-h2);
	}
	.origami-container .origami-page-header__layout {
		max-width: 1440px;
		padding-left: 2rem;
		padding-right: 2rem;
		margin: auto;
	}
</style>
