var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "tutorsList tw-flex tw-flex-col",
      attrs: { "aria-label": "tutor list" },
    },
    [
      _c(
        "header",
        {
          staticClass: "tw-flex tw-flex-row tw-justify-between tw-align-middle",
        },
        [
          _c(
            "h1",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "title",
                  expression: "'title'",
                },
              ],
              staticClass: "tw-text-xl tw-font-semibold tw-text-origami-black",
            },
            [_vm._v("\n\t\t\tTutors\n\t\t")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tw-flex tw-flex-row" },
            [
              _c(
                "BaseButton",
                {
                  directives: [
                    {
                      name: "data-cy",
                      rawName: "v-data-cy",
                      value: "btn-create-new-tutor",
                      expression: "'btn-create-new-tutor'",
                    },
                  ],
                  nativeOn: {
                    click: function ($event) {
                      return _vm.goToCreateNewTutorPage()
                    },
                  },
                },
                [
                  _c("template", { slot: "text" }, [
                    _vm._v("\n\t\t\t\t\tCreate New Tutor\n\t\t\t\t"),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("TutorsListFilterContainer", {
        staticClass: "tw-mx-5",
        attrs: { filters: _vm.PlatformManager.TutorsList.filterKeys },
      }),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass: "tutorsList__totalResults",
          class: {
            "tutorsList__totalResults--opacity":
              _vm.PlatformManager.TutorsList.isLoadingTutors,
          },
        },
        [
          _c("strong", [
            _vm._v(_vm._s(_vm.PlatformManager.TutorsList.totalUsersFound)),
          ]),
          _vm._v(" users found\n\t"),
        ]
      ),
      _vm._v(" "),
      _c("TutorsListTableContainer"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tw-flex tw-justify-center tw-mb-10" },
        [
          _c("Pagination", {
            attrs: {
              "total-pages":
                _vm.PlatformManager.TutorsList.pagination.total_pages,
              "current-page":
                _vm.PlatformManager.TutorsList.pagination.current_page,
            },
            on: { change: _vm.handleChangePage },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }