<template>
	<SidebarNew
		v-if="featureFlagSidebarRedesignIsEnabled"
		v-jest="'sidebar-new'"
		@open-drawer="emit('open-drawer')"
		@close-drawer="emit('close-drawer')"
	/>
	<div
		v-else
		class="tw-flex"
	>
		<Sidebar
			v-if="!isMobileWebviewOpened"
			v-jest="'sidebar'"
			:is-toggled="isSidebarToggled"
		/>
		<SidebarMobile
			v-show="isMobileMenuToggled"
			v-if="!isMobileWebviewOpened"
			ref="mobileNav"
			v-jest="'sidebar-mobile'"
			@reset-mobile-menu="emit('reset-mobile-menu')"
		/>
	</div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { getFlag } from "@paper-co/web-toolkit";

import Sidebar from "@/modules/StudentNavigation/components/Sidebar.vue";
import SidebarNew from "@/modules/StudentNavigation/components/SidebarNew.vue";
import SidebarMobile from "@/modules/StudentNavigation/components/SidebarMobile.vue";

const emit = defineEmits(["open-drawer", "close-drawer", "reset-mobile-menu"]);
defineProps({
	isMobileMenuToggled: {
		type: Boolean,
		required: true,
	},
	isMobileWebviewOpened: {
		type: Boolean,
		required: true,
	},
	isSidebarToggled: {
		type: Boolean,
		required: true,
	},
});

const featureFlagSidebarRedesignIsEnabled = ref(false);
const mobileNav = ref(null);

onMounted(async() => {
	featureFlagSidebarRedesignIsEnabled.value = await getFlag("GROW-1308-sidebar-secondary-tab-redesign");
});

defineExpose({ mobileNav });
</script>
