import Vue from "vue";
import VueI18n from "vue-i18n";
import { merge } from "lodash";

import login from "@/modules/Authentication/i18n/login.js"; // barrel file not working here
import referrals from "@/modules/Referrals/i18n/index.js";
import { i18n as achievements } from "@/modules/Achievements/index.js";
import { i18n as activity } from "@/modules/Activity/index.js";
import { i18n as teachingAssets } from "@/modules/TeachingAssets/index.js";
import { i18n as challenges } from "@/modules/Challenges/index.js";
import { i18n as embedded } from "@/modules/EmbeddedUtilities/index.js";
import { i18n as lightbox } from "@/modules/Lightbox/index.js";
import majorClarity from "@/modules/MajorClarity/i18n/index.js";
import { i18n as math } from "@/modules/Math/index.js";
import { i18n as recognition } from "@/modules/RecognitionEvents/index.js";
import { i18n as studentReviewCenter } from "@/modules/StudentReviewCenter/index.js";
import { i18n as tutorEssay } from "@/modules/TutorEssay/index.js";
import { i18n as tutorReviewCenter } from "@/modules/TutorReviewCenter/index.js";
import studentNavigation from "@/modules/StudentNavigation/i18n/index.js";

import academicEntityTranslation from "./dashboard/academicEntity.js";
import errors from "./errors.js";
import settings from "./settings/account.js";
import superuserDashboard from "./dashboard/superuser/index.js";
import superuserTutorEdit from "./superuser/tutor/edit.js";
import teacherAssignmentsCreateAndEdit from "./customer/general.js";
import tutorDashboard from "./dashboard/student/index.js";
import reviewCenter from "./essay/essay.js";
import studentClassroom from "./session/student/index.js";

Vue.use(VueI18n);

export default new VueI18n({
	fallbackLocale: "en",
	messages: merge(
		academicEntityTranslation,
		activity,
		achievements,
		challenges,
		embedded,
		errors,
		lightbox,
		login,
		majorClarity,
		math,
		recognition,
		reviewCenter,
		settings,
		studentClassroom,
		studentNavigation,
		studentReviewCenter,
		superuserDashboard,
		superuserTutorEdit,
		teacherAssignmentsCreateAndEdit,
		teachingAssets,
		tutorDashboard,
		tutorEssay,
		tutorReviewCenter,
		referrals,
	),
	silentFallbackWarn: true,
});
