<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		:width="width"
		:height="height"
		viewBox="0 0 26 26"
	>
		<g
			fill="none"
			fill-rule="evenodd"
			transform="translate(1 1)"
		>
			<circle
				cx="12"
				cy="12"
				r="12"
				fill="#FFD879"
				stroke="#E2C172"
			/>
			<circle
				cx="7.622"
				cy="9.859"
				r="2.108"
				fill="#3C5396"
			/>
			<circle
				cx="16.054"
				cy="9.859"
				r="2.108"
				fill="#3C5396"
			/>
			<path
				stroke="#3C5396"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="1.5"
				d="M7.135 15.859s1.423.355 4.817.355 4.817-.355 4.817-.355"
			/>
		</g>
	</svg>
</template>

<script>
export default {
	props: {
		width: {
			type: [Number, String],
			default: 26,
		},
		height: {
			type: [Number, String],
			default: 26,
		},
	},
};
</script>
