import { getErrorText, showErrorModal } from "../utilities/errorHandlingHelpers.js";
export default {
	methods: {
		async handleDispatch(action, payload, errorText) {
			try {
				const data = await this.$store.dispatch(action, payload);
				return [data, null];
			} catch (error) {
				const message = errorText ?? "retrieving action center items";
				const options = {
					message: getErrorText(message),
				};
				showErrorModal(this, options);
				return [null, error];
			}
		},
	},

};
