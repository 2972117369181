var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "tw-inline-flex tw-justify-center tw-items-center",
      class: [_vm.color, ..._vm.variants],
    },
    [
      _vm._t("prepend"),
      _vm._v(" "),
      _c("div", [_vm._t("default")], 2),
      _vm._v(" "),
      _vm._t("append"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }