var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "OrigamiCard",
    {
      staticClass:
        "tw-w-72 tw-p-4 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "tw-w-16 tw-h-16 tw-bg-origami-grey-100 tw-flex tw-items-center tw-justify-center tw-filter tw-grayscale tw-rounded-full",
          attrs: { "aria-hidden": "true" },
        },
        [
          _c("Emoji", {
            staticClass: "tw-text-3xl",
            attrs: { emoji: "PartyPooper" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { staticClass: "tw-text-center" }, [
        _vm._v("\n\t\tCelebrate learning moments with your student\n\t"),
      ]),
      _vm._v(" "),
      _c(
        "OrigamiButton",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "send-recognition-button",
              expression: "'send-recognition-button'",
            },
          ],
          staticClass: "tw-w-full tw-flex tw-justify-center",
          nativeOn: {
            click: function ($event) {
              return _vm.$emit("recognition-send")
            },
          },
        },
        [_vm._v("\n\t\tSend a sticker\n\t")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }