<template>
	<nav
		id="sidebar"
		v-click-outside="closeDrawer"
		v-jest="'sidebar'"
		class="sidebar"
		:class="{ 'opened': isDrawerOpen }"
	>
		<SkipToMainContent class="skip tw-border-b-2 tw-left-full focus:tw-left-0 md:tw-left-0 tw-border-solid tw-border-origami-grey-100 hover:tw-shadow-origami-xl tw-z-[22] tw--top-full" />
		<div class="md:tw-pt-2 tw-sticky tw-top-0 md:tw-top-20 tw-bg-origami-white tw-z-[21]">
			<SuspensePattern
				:is-showing-fallback="isLoading"
				class="tw-mt-1 tw-flex tw-justify-around md:tw-block"
			>
				<SidebarLinks
					v-for="navLink in reactiveNavLinks"
					:key="navLink.name"
					v-jest="`${navLink.name}-link`"
					:title="navLink.title"
					:icon="navLink.icon"
					:target="`${navLink?.target}`"
					:name="navLink.name"
					:opens-drawer="navLink.opensDrawer"
					:is-external="navLink.isExternal"
					:is-open="navLink.name === activeDrawer"
					@open-drawer="handleDrawerOpening(navLink.name)"
					@close-drawer="closeDrawer()"
				/>
			</SuspensePattern>
		</div>
		<div class="drawer">
			<div class="tw-sticky tw-top-[106px]">
				<div
					v-for="drawerLink in drawerLinks[activeDrawer]"
					:key="drawerLink.name"
				>
					<router-link
						v-if="!drawerLink.isExternal"
						v-jest="`${drawerLink.name}-drawer-link`"
						:to="drawerLink.target"
						class="drawer-link"
						@click.native="closeDrawer()"
					>
						<OrigamiIcon :name="drawerLink.icon" />
						<p class="drawer-label">
							{{ $t(`${drawerLink.title}`) }}
						</p>
					</router-link>
					<a
						v-else
						v-jest="`${drawerLink.name}-drawer-link`"
						:href="drawerLink.target"
						class="drawer-link"
					>
						<OrigamiIcon :name="drawerLink.icon" />
						<p class="drawer-label">
							{{ $t(`${drawerLink.title}`) }}
						</p>
					</a>
				</div>
			</div>
		</div>
	</nav>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { OrigamiIcon } from "@origami/vue2";

import SkipToMainContent from "@/components/patterns/SkipToMainContent.vue";
import SuspensePattern from "@/components/patterns/SuspensePattern.vue";

import { drawerLinks } from "../utilities/index.js";
import useNavLinks from "../composables/useNavLinks.js";

import SidebarLinks from "./SidebarLinks.vue";

const { reactiveNavLinks, checkNavLinksFlags } = useNavLinks();

const isLoading = ref(false);
const isDrawerOpen = ref(false);
const activeDrawer = ref("");

const emit = defineEmits(["open-drawer", "close-drawer"]);

const handleDrawerOpening = (navLinkName) => {
	if (navLinkName === activeDrawer.value) {
		closeDrawer();
	} else {
		isDrawerOpen.value = true;
		activeDrawer.value = navLinkName;
		emit("open-drawer");
	}
};
const closeDrawer = () => {
	isDrawerOpen.value = false;
	activeDrawer.value = "";
	emit("close-drawer");
};

onMounted(async() => {
	isLoading.value = true;

	try {
		await checkNavLinksFlags();
	} catch (error) {
		Sentry.captureException(error);
	} finally {
		isLoading.value = false;
	}
});
defineExpose({ activeDrawer, isDrawerOpen });
</script>

<style scoped>
.sidebar {
	@apply tw-bg-origami-white tw-block tw-h-[80px] tw-fixed tw-w-full tw-drop-shadow-xl tw-z-20 tw-bottom-0 tw-left-0;
	margin-right: 0;
	transition: margin 0.3s ease;
}
.drawer {
	@apply tw-p-4 tw-w-full tw-bg-origami-white tw-absolute tw-bottom-0 tw-z-20;
	height: calc(100vh - var(--header-height));
	transition: all 0.3s ease;
	transform: translateY(100%);
}
.sidebar.opened .drawer {
	transform: translate(0, 0);
}
@media (min-width: 768px) {
	.drawer {
		@apply tw-w-[266px] tw-top-[88px] tw-left-[80px] tw-border-l tw-border-origami-grey-200;
		bottom: unset;
		height: calc(100% - var(--header-height));
		transform: translateX(-100%);
	}
	.sidebar {
		@apply tw-h-auto tw-w-[80px] tw-absolute;
		height: 100%;
		bottom: unset;
	}
}

.drawer-link {
	@apply tw-p-2 tw-flex tw-items-center tw-text-origami-black tw-text-[14px] tw-font-light tw-mb-4 hover:tw-bg-origami-grey-100 tw-rounded;
}
.drawer-label {
	@apply tw-ml-2 tw-text-origami-black tw-text-base tw-font-light;
}
.skip {
	@apply md:focus:tw-top-0 md:focus:tw-sticky;
}
</style>
