import { isNil } from "lodash";
import Vue from "vue";

import SchedulesAPI from "@/services/api/Schedules.js";
import TransfersAPI from "@/services/api/ShiftTransfers.js";

export const state = {
	shifts: {},
	currentWeek: "",
	loadedWeeks: [],
};

export const mutations = {
	SET_SHIFTS_IN_SHIFTS(state, payload) {
		state.shifts = { ...state.shifts, ...payload };
	},
	DELETE_SHIFT_IN_SHIFTS(state, payload) {
		Vue.delete(state.shifts, payload.shiftId);
	},
	PUSH_LOADED_WEEK_IN_LOADED_WEEKS_IN_SHIFTS(state, payload) {
		state.loadedWeeks.push(payload.loadedWeek);
	},
	SET_CURRENT_START_OF_WEEK_IN_SHIFTS(state, payload) {
		state.currentWeek = payload.currentWeek;
	},
	SET_SHIFT_IN_SHIFTS(state, payload) {
		state.shifts[payload.shiftId][payload.key] = payload.value;
	},
	SET_TRANSFER_DATA_IN_SHIFT(state, payload) {
		state.shifts[payload.shiftId].shiftTransfer.data[payload.key] = payload.value;
	},
};

export const actions = {
	updateShifts({ commit, state }, payload) {
		const shifts = {};

		payload.shifts.forEach((shift) => {
			shift.end_time = isNil(shift.pendingScheduleBreakRequest) ?
				shift.end_time : payload.approvedBreak.start_time;
			shifts[shift.id] = shift;
		});

		const updatedShifts = Object.assign({}, state.shifts, shifts);
		commit("SET_SHIFTS_IN_SHIFTS", updatedShifts);
	},
	/**
	 * gets the shifts for the tutor by week
	 * @param commit
	 * @param payload
	 */
	async getShifts({ commit }, payload) {
		try {
			const response = await SchedulesAPI.getSchedulesforTutor(
				payload.currentUserId,
				payload.params,
			);

			const shifts = {};

			response.data.data.forEach((shift) => {
				shifts[shift.id] = shift;
			});

			commit("SET_SHIFTS_IN_SHIFTS", shifts);
			commit("PUSH_LOADED_WEEK_IN_LOADED_WEEKS_IN_SHIFTS", {
				loadedWeek: payload.params.from,
			});
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	/**
	 * create a shift transfer
	 * @param Number id
	 */
	async createShiftTransfer({ commit }, payload) {
		try {
			const response = await TransfersAPI.create(payload);
			commit("SET_SHIFT_IN_SHIFTS", {
				shiftId: payload.schedule_id,
				key: "transfer_pending",
				value: true,
			});
			commit("SET_SHIFT_IN_SHIFTS", {
				shiftId: payload.schedule_id,
				key: "shiftTransfer",
				value: { data: response.data },
			});
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	async createAsyncShiftTransfer({ commit }, payload) {
		try {
			const response = await TransfersAPI.createAsync(payload);
			commit("SET_SHIFT_IN_SHIFTS", {
				shiftId: payload.shift_segment_id,
				key: "transfer_pending",
				value: true,
			});
			commit("SET_SHIFT_IN_SHIFTS", {
				shiftId: payload.shift_segment_id,
				key: "shiftTransfer",
				value: { data: response.data.data },
			});
		} catch (e) {
			Sentry.captureException(e);
		}
	},

	async cancelShiftTransferRequest({ commit }, payload) {
		try {
			await TransfersAPI.update(payload.transfer_id, payload.params);

			commit("SET_SHIFT_IN_SHIFTS", {
				shiftId: payload.schedule_id,
				key: "transfer_pending",
				value: false,
			});
			commit("SET_TRANSFER_DATA_IN_SHIFT", {
				shiftId: payload.schedule_id,
				key: "transfered_to_user_id",
				value: payload.transfered_to,
			});
		} catch (e) {
			Sentry.captureException(e);
		}
	},
};


export default { namespaced: true, state, mutations, actions };
