var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ScheduledBreakCancelled", {
    attrs: { "break-type-id": _vm.breakTypeId },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }