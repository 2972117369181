<template>
	<BreaksConfirmation>
		<template #header>
			<h3 data-cy="break-confirmation-header">
				Go on a bio break?
			</h3>
		</template>
		<template #bodyIcon>
			<OrigamiIcon
				v-data-cy="'bio-break-icon'"
				name="bio-break"
				width="32"
				height="32"
				class="tw-text-origami-blue-400"
			/>
		</template>
		<template #bodyText>
			<p data-cy="bio-break-confirmation-body-text">
				You have up to 5 minutes for a bio break, but please return as soon as you are done
			</p>
		</template>
		<template #buttons>
			<OrigamiButton
				v-jest="'bio-break-confirmation-modal-cancel-button'"
				v-data-cy="'bio-break-confirmation-modal-cancel-button'"
				variant="secondary"
				class="tw-w-full tw-justify-center tw-mb-2"
				@click.native="cancelBioBreakRequest"
			>
				Cancel
			</OrigamiButton>
			<OrigamiButton
				v-jest="'bio-break-confirmation-modal-ok-button'"
				v-data-cy="'bio-break-confirmation-modal-ok-button'"
				variant="primary"
				class="tw-w-full tw-justify-center"
				:is-loading="$store.state.BreaksManagementWidget.isRequestLoading"
				@click.native="startBioBreak"
			>
				Ok, got it!
			</OrigamiButton>
		</template>
	</BreaksConfirmation>
</template>

<script>
import { OrigamiButton, OrigamiIcon } from "@origami/vue2";

import BreaksConfirmation from "../templates/BreaksConfirmation.vue";

export default {
	name: "BioBreakConfirmation",
	components: {
		OrigamiButton,
		BreaksConfirmation,
		OrigamiIcon,
	},
	methods: {
		startBioBreak() {
			this.$store.dispatch("BreaksManagementWidget/startBioBreak");
		},
		cancelBioBreakRequest() {
			this.$store.dispatch("BreaksManagementWidget/resetWorkflow");
		},
	},
};
</script>
