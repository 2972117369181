<template>
	<ul :class="{ 'tw-flex tw-flex-col tw-items-end': props.isToggled }">
		<li
			v-for="(link, idx) in filteredNavLinks"
			:key="`${link}_${idx}`"
			:class="{ 'tw-w-20': props.isToggled }"
			@click="handleMobileMenu"
		>
			<component
				:is="handleLinkType(link)"
				v-bind="getLinkProperties(link)"
				v-jest="`nav-link-${link.name}`"
				class="nav-link tw-flex tw-items-center tw-p-2 tw-pl-4 tw-mb-2 tw-border-l-8 tw-border-origami-white"
				:class="{ 'active': link.name === activeLink?.name }"
				:aria-label="link.title"
				v-on="getLinkEvents(link)"
			>
				<OrigamiIcon
					:name="link.icon"
					:title="$t(link.title)"
					width="30"
					height="30"
				/>
				<span
					class="ml-2"
					:class="{ 'nav-link__toggled': props.isToggled }"
				>
					{{ $t(`${link.title}`) }}
				</span>
			</component>
		</li>
	</ul>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { OrigamiIcon } from "@origami/vue2";
import { useRoute } from "vue-router/composables";
import { getFlag } from "@paper-co/web-toolkit";

import useStore from "@/composables/useStore.js";

const emit = defineEmits(["reset-mobile-menu"]);

const store = useStore();
const route = useRoute();

const props = defineProps({
	navLinks: {
		type: Array,
		required: true,
	},
	isToggled: {
		type: Boolean,
		default: false,
	},
	isMobile: {
		type: Boolean,
		default: false,
	},
});

const featureFlagMajorClarityIsEnabled = ref(false);
const filteredNavLinks = computed(() => props.navLinks.filter((link) => link.target !== "/todo"));
const activeLink = computed(() => {
	const activeLinkWithParams = filteredNavLinks.value.find((link) =>
		link.hasQueryParam && route.fullPath === link.target,
	);
	const activeLinkWithoutParams = filteredNavLinks.value.find((link) => route.path.indexOf(link.target) === 0);
	return activeLinkWithParams || activeLinkWithoutParams;
});

const handleMajorClarityClick = () => {
	store.commit("MajorClarity/SET_IS_SHOWING_REDIRECT_MODAL", true);
};
const linksActionStrategy = {
	"major-clarity": {
		click: handleMajorClarityClick,
	},
};

const handleLinkType = (link) => {
	if (featureFlagMajorClarityIsEnabled.value && link.isButton) {
		return "button";
	}
	if (link.isExternal) {
		return "a";
	}
	return "router-link";
};

const getLinkProperties = (link) => ({ [link.isExternal ? "href" : "to"]: link.target });
const getLinkEvents = (link) => {
	if (featureFlagMajorClarityIsEnabled.value && link.isButton) {
		return linksActionStrategy[link.name] || {};
	}
	return {};
};

const handleMobileMenu = () => emit("reset-mobile-menu");

onMounted(async() => {
	featureFlagMajorClarityIsEnabled.value = await getFlag("GROW-1734-major-clarity-from-paper");
});
</script>

<style scoped>
.nav-link {
	width: 100%;
}

.nav-link:hover, .nav-link:focus {
	background-color: rgba(183, 183, 246, 0.25);
	border-color: var(--origami-blue-200);
}

.nav-link:focus {
	outline: none;
	box-shadow: inset 0px 0px 0px 2px var(--origami-blue-200);
}

.nav-link__toggled {
	@apply tw-absolute tw-bg-origami-black tw-text-origami-white tw-font-bold tw-text-origami-label tw-p-2 tw-rounded tw-whitespace-nowrap tw-hidden tw-ml-0;
	left: calc(100% - 0.5rem);
}

.nav-link:hover .nav-link__toggled, .nav-link:focus .nav-link__toggled {
	@apply tw-block;
}

.active {
	background-color: rgba(183, 183, 246, 0.5);
	border-color: var(--origami-blue-300);
	font-weight: bold;
}

.active .origami-icon {
	color: var(--origami-blue-300);
}

a {
	color: inherit;
}
</style>
