var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "summary-card-container",
          expression: "'summary-card-container'",
        },
      ],
      staticClass: "tw-w-full",
    },
    [
      _c(
        "div",
        { staticClass: "tw-flex tw-w-full tw-justify-center tw-items-center" },
        [
          _c(_setup.OrigamiIcon, {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "summary-card-icon",
                expression: "'summary-card-icon'",
              },
            ],
            class: _setup.props.alert.color,
            attrs: { name: _setup.props.alert.icon, width: "40", height: "40" },
          }),
          _vm._v(" "),
          _c(
            "p",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "summary-card-text",
                  expression: "'summary-card-text'",
                },
              ],
              staticClass: "tw-pl-4 tw-text-xl tw-leading-tight",
            },
            [
              _c("span", { staticClass: "tw-font-bold" }, [
                _vm._v(_vm._s(_setup.props.alert.numberOfSubjects)),
              ]),
              _vm._v(" " + _vm._s(_setup.props.alert.title) + "\n\t\t"),
            ]
          ),
          _vm._v(" "),
          _c(_setup.OrigamiIconButton, {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "summary-card-button",
                expression: "'summary-card-button'",
              },
            ],
            staticClass: "tw-ml-3",
            attrs: {
              "data-cy": `jump-to-${_vm.alert.title}-section-button`,
              "icon-name": "logout",
            },
            nativeOn: {
              click: function ($event) {
                return _setup.scrollToRiskCard(_setup.props.alert.cardID)
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }