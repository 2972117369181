import Api from "@/services/api/Api.js";
import { LaravelApi } from "@/services/root.js";
import { FROM_LOGIN_KEY } from "@/utilities/trackRequests.js";

import PasswordReset from "../services/api/PasswordReset.js";

const getDefaultState = () => ({
	currentSchool: null,
	isLoadingSchools: false,
	viewComponent: { name: "", props: {} },
	schools: [],
	urlParamError: "",
	auth0LoginError: false,
});

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations: {
		RESET_STATE(state) {
			Object.assign(state, getDefaultState());
		},
		SET_VIEW_COMPONENT(state, payload) {
			state.viewComponent = payload.viewComponent;
		},
		SET_SCHOOLS(state, payload) {
			state.schools = payload.schools;
		},
		SET_CURRENT_SCHOOL(state, payload) {
			state.currentSchool = payload.currentSchool;
		},
		SET_URL_PARAM_ERROR(state, payload) {
			state.urlParamError = payload.urlParamError;
		},
		SET_IS_LOADING_SCHOOLS(state, payload) {
			state.isLoadingSchools = payload.isLoadingSchools;
		},
		SET_AUTH0_LOGIN_ERROR(state, payload) {
			state.auth0LoginError = payload.auth0LoginError;
		},
	},
	actions: {
		async resetPassword({}, payload) {
			try {
				const response = await PasswordReset.askResetPassword(payload);
				return Promise.resolve(response);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async getGoogleLoginLink({}, payload) {
			try {
				const response = await LaravelApi().get("/social-logins/google/link", { params: payload });
				return Promise.resolve(response);
			} catch (e) {
				return Promise.reject(e);
			}
		},
		/**
		 * @param {String} payload.google_id_token
		 * @return {Promise}
		 */
		async getGoogleLogin({}, payload) {
			try {
				window.sessionStorage.setItem(FROM_LOGIN_KEY, "true");
				const response = await LaravelApi().get("/social-logins/google", { params: payload });

				return Promise.resolve(response);
			} catch (e) {
				return Promise.reject(e);
			}
		},
		/**
		 * @param {String} payload.id_token
		 * @return {Promise}
		 */
		async getAuth0Login({}, payload) {
			const post_data = new FormData();
			post_data.append("access_token", payload);
			return await LaravelApi().post("/auth0", post_data);
		},
		async login({}, payload) {
			try {
				window.sessionStorage.setItem(FROM_LOGIN_KEY, "true");
				const response = await LaravelApi().post("/login", payload);

				return Promise.resolve(response);
			} catch (e) {
				return Promise.reject(e);
			}
		},
		async getSchools({ commit }) {
			try {
				commit("SET_IS_LOADING_SCHOOLS", { isLoadingSchools: true });
				const response = await Api().get("/schools-list");
				commit("SET_SCHOOLS", { schools: response.data.data });

				return Promise.resolve(response);
			} catch (e) {
				return Promise.reject(e);
			} finally {
				commit("SET_IS_LOADING_SCHOOLS", { isLoadingSchools: false });
			}
		},
	},
};
