import { getFlag, captureException } from "@paper-co/web-toolkit";

import { getInstance as auth0Instance } from "@/plugins/Auth0.js";

export default async function(to, from, next) {
	const featureFlagAuth0SSOLoginIsEnabled = await getFlag("PLAT-1680-paper-identity-authentication-to-auth0");
	const featureFlagAuth0LoginPerformanceImprovements = await getFlag("GROW-2316-login-performance-improvement");

	if (featureFlagAuth0SSOLoginIsEnabled
		&& featureFlagAuth0LoginPerformanceImprovements
		&& window.location.search.includes("code=")
		&& window.location.search.includes("state=")
	) {
		try {
			await auth0Instance();
		} catch (e) {
			captureException(e);
		}
	}

	next();
}
