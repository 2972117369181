<template>
	<div>
		<p class="assessment__text assessment__text--bold">
			{{
				$t("right_sidebar.assessment.tag_the_question", { name: studentName })
			}}
		</p>
		<p class="assessment__text assessment__text--blue assessment__text--question">
			{{ $t("right_sidebar.assessment.tag_questions_by") }}
		</p>

		<div
			v-for="(question, index) in assessmentQuestions"
			:key="index"
			class="assessment__question"
		>
			<div class="assessment__text assessment__text--bold">
				{{ $t("right_sidebar.assessment.question", { number: index + 1 }) }}
			</div>
			<template v-if="isQuestionAnInstanceOfMessageText(question)">
				<StringWithEmojis
					class="assessment__questionText"
					:value="question.message.text"
				/>
			</template>
			<template v-else>
				<template v-if="isQuestionMediaTypeImage(question.message)">
					<div>
						<img
							class="questionTagImage__image"
							:src="question.message.url"
						>
					</div>
				</template>
				<template v-else-if="isQuestionMediaTypeFile(question.message)">
					<div class="assessment__questionText">
						{{ question.message.fileName }}
					</div>
				</template>
				<template v-else>
					<div class="assessment__questionText">
						{{ question.message.url }}
					</div>
				</template>
			</template>
		</div>
		<div>
			<div class="assessment__nonAcademicTitle">
				{{ $t("right_sidebar.assessment.is_session_non_academic") }}
			</div>
			<BaseCheckbox
				:value="isNonAcademic"
				class="assessment__nonAcademicCheckbox"
				:checkbox-id="1"
				checkbox-value="nonAcademic"
				@input="$emit('non-academic-check', $event)"
			>
				<template slot="text">
					<span class="assessment__nonAcademicText">
						{{ $t("right_sidebar.assessment.it_is") }}
					</span>
				</template>
			</BaseCheckbox>
		</div>
	</div>
</template>

<script>
import MessageText from "@/modules/Classroom/classes/MessageText.js";
import MessageQuestionMatcher from "@/modules/Classroom/classes/MessageQuestionMatcher.js";
import BaseCheckbox from "@/components/BaseCheckbox.vue";

export default {
	components: {
		StringWithEmojis: () => import("@/components/StringWithEmojis.js"),
		BaseCheckbox,
	},
	props: {
		isNonAcademic: {
			type: Boolean,
			default: false,
		},
		session: {
			type: Object,
			required: true,
		},
		studentName: {
			type: String,
			default: "",
		},
		assessmentQuestions: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		/**
		 * Checks to see if the message is an instance
		 * of Message Text
		 * @param 	{Object} 	question
		 * @returns {Boolean}
		 */
		isQuestionAnInstanceOfMessageText(question) {
			if (question.message instanceof MessageText) {
				return true;
			} else if (question instanceof MessageQuestionMatcher) {
				return question.message.isQuestion;
			}
			return false;
		},
		/**
		 * Checks to see if the message media is
		 * of type image or file
		 * @param {Object} message
		 * @returns {Boolean}
		 */
		isQuestionMediaTypeImage(message) {
			return message.mediaType === "image";
		},
		isQuestionMediaTypeFile(message) {
			return message.mediaType === "file";
		},
	},
};
</script>

<style scoped>
.assessment__text {
	font-size: 14px;
	margin-bottom: 12px;
	color: #484756;
}

.assessment__text--bold {
	font-weight: 600;
}

.assessment__text--blue {
	color: var(--c-blue);
}

.assessment__text--question {
	font-size: 12px;
	font-weight: 300;
	color: var(--c-blue);
	padding-left: 30px;
	background: url(/images/question_tag_active.svg) no-repeat 0 3px/20px;
	margin: 0;
	border-radius: 5px;
	line-height: 20px;
}

.assessment__question {
	margin: 15px 0;
}

.assessment__questionText {
	font-size: 12px;
	font-weight: normal;
	color: var(--c-black);
	letter-spacing: -0.05px;
	word-break: break-all;
}

.questionTagImage__image {
	width: 36px;
	height: 36px;
}

.assessment__nonAcademicTitle {
	width: 100%;
	font-size: 14px;
	font-weight: 600;
	color: #4a4a4a;
}

.assessment__nonAcademicCheckbox {
	margin-top: 9px;
}

.assessment__nonAcademicText {
	font-size: 12px;
	font-weight: normal;
	color: #484756;
}
</style>
