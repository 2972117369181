<script setup>
import { computed, onMounted, onUnmounted, ref } from "vue";

import TutorDashboardShiftSummary from "@/modules/TutorShiftSummary/components/TutorDashboardShiftSummary.vue";
import IconCalendar from "@/components/icons/IconCalendar.vue";
import IconBreak from "@/components/icons/IconBreak.vue";
import BaseButton from "@/components/BaseButton.vue";

const iconMap = {
	CALENDAR: IconCalendar,
	BREAK: IconBreak,
};

const props = defineProps({
	iconType: {
		type: String,
		validator: (val) => ["CALENDAR", "BREAK"].includes(val),
		default: "CALENDAR",
	},
	isLeftButton: {
		type: Boolean,
		default: false,
	},
	isButtonEnabled: {
		type: Boolean,
		default: false,
	},
	buttonType: {
		validator: (val) => ["PRIMARY", "SUBMIT", "NEGATIVE"].includes(val),
		default: "PRIMARY",
	},
});

const shiftCalendar = ref(null);
const isShowingSummary = ref(false);

const iconComponent = computed(() => iconMap[props.iconType]);

const toggleIsShowingSummary = () => {
	isShowingSummary.value = !isShowingSummary.value;
};

const onClickOutside = (e) => {
	const isClickOutside = !shiftCalendar.value.contains(e.target);

	if (isShowingSummary.value && isClickOutside) {
		toggleIsShowingSummary();
	}
};

onMounted(() => {
	document.addEventListener("click", onClickOutside);
});

onUnmounted(() => {
	document.removeEventListener("click", onClickOutside);
});
</script>

<template>
	<div
		ref="shiftCalendar"
		class="tw-relative"
	>
		<BaseButton
			v-jest="'calendar-button'"
			v-data-cy="'calendar-button'"
			class="calendarButton !tw-pt-2.5 !tw-pr-0 !tw-pb-2.5 !tw-pl-2.5 tw-outline"
			:class="{ '!tw-rounded-r-none': props.isLeftButton }"
			:type="buttonType"
			@click.native="toggleIsShowingSummary()"
		>
			<template slot="icon">
				<component
					:is="iconComponent"
				/>
			</template>
		</BaseButton>
		<TutorDashboardShiftSummary
			v-if="isShowingSummary"
			v-jest="'punch-clock-shift-summary'"
			data-cy="punch-clock-shift-summary"
			class="tw-w-[360px] tw-top-[4.1em] tw-z-30 tw-absolute tw-right-0 tw-shadow-elevation-2"
			:is-title-showing="false"
		/>
	</div>
</template>

<style scoped>
.calendarButton:focus-visible {
	outline: 2px solid var(--origami-blue-200);
}
</style>
