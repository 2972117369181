import AbstractMessage from "./AbstractMessage.js";

/**
 *
 * @param id
 * @param userId
 * @param createdAt
 * @param sequencePosition
 * @param status
 * @param isLastMessage
 * @param deliveredAt
 * @param isQuestion
 * @param url
 * @param mediaType {String} "file" | "image"| "audio"
 * @param userReactions
 * @param bookmarkedAt
 * @constructor
 */
function MessageMedia({
	id,
	userId,
	createdAt,
	sequencePosition,
	status,
	isLastMessage,
	deliveredAt,
	isQuestion,
	isDeleted,
	url,
	fileName,
	mediaType,
	userReactions,
	bookmarkedAt,
	voiceNoteMetadata,
	thumbnailUrl,
}) {
	AbstractMessage.call(
		this,
		{
			id,
			userId,
			createdAt,
			sequencePosition,
			status,
			isLastMessage,
			deliveredAt,
			isQuestion,
			isDeleted,
			userReactions,
		},
	);
	this.url = url;
	this.mediaType = mediaType;
	this.fileName = fileName;
	this.bookmarkedAt = bookmarkedAt;
	this.voiceNoteMetadata = voiceNoteMetadata;
	this.thumbnailUrl = thumbnailUrl;
}

MessageMedia.prototype = Object.create(AbstractMessage.prototype);

export default MessageMedia;
