<script setup>
import { reactive, computed } from "vue";
import { format, subMinutes } from "date-fns";

import { chartLineAxe } from "@/utilities/charts.js";
import Colors from "@/utilities/Colors.js";
import InfoToolTip from "@/components/patterns/InfoToolTip.vue";
import useStore from "@/composables/useStore.js";

import { AVERAGE_TUTOR_LOAD_OVER_TIME_TOOLTIP } from "../utilities/ConstantValues.js";

import ServiceAnalyticsLineChart from "./ServiceAnalyticsLineChart.vue";

const ORIGAMI_RED_400 = "rgba(204, 55, 35, 1)";

const store = useStore();
const props = defineProps({
	timeSeries: {
		type: Array,
		required: true,
	},
});

const currentUser = computed(() => store.state.currentUser);
const localizedTime = (timestamp) => {
	const serverDate = new Date(timestamp);
	const localizedDate = subMinutes(serverDate, serverDate.getTimezoneOffset());
	return format(localizedDate, "h:mmaaa", { timeZone: currentUser.value.timezone });
};

const chartData = computed(() => {
	const filteredTimeSeries = props.timeSeries.filter((time, index) => {
		return index % 5 === 0;
	});
	return filteredTimeSeries.map((time) => (
		{
			label: {
				tick: localizedTime(time.time),
			},
			value: time.value,
		}
	));
});

const state = reactive({
	chartData,
	yAxes: {
		min: 0,
		suggestedMax: 7,
		ticks: {
			color: (ticks) => ticks["tick"]["value"] === 3 ? Colors.C_LANGUAGE_BUBBLE_YELLOW : Colors.C_BLACK,
			padding: 20,
		},
	},
});
const xAxes = () => {
	const ticksCallback = (value) => state.chartData[value].label.tick;
	return chartLineAxe(ticksCallback, 7);
};

const averageTutorLoad = computed(() => {
	const chartDataLength = state.chartData.length;
	return (state.chartData.reduce((sum, data) => sum + data.value, 0) / chartDataLength).toFixed(1);
});
</script>
<template>
	<div class="tw-pl-12">
		<div class="xxl:tw-flex tw-justify-between">
			<div class="tw-flex tw-items-center xxl:tw-pb-9 tw-pb-1">
				<h2
					class="tw-font-bold xxl:tw-text-lg tw-text-md"
				>
					Average Tutor Load Over Time
				</h2>
				<InfoToolTip
					class="tw-ml-3 tw-leading-6 tw-pb-0.5"
					:width="'15px'"
					:height="'15px'"
					:options="{
						content: AVERAGE_TUTOR_LOAD_OVER_TIME_TOOLTIP,
						placement: 'top',
					}"
				/>
			</div>
			<p class="xxl:tw-text-sm xxl:tw-pb-auto tw-pb-3 tw-text-xs tw-font-bold tw-mr-8 tw-mt-0.5 tw-text-origami-blue-200">
				{{ averageTutorLoad }} Average Tutor Load (30 mins)
			</p>
		</div>
		<ServiceAnalyticsLineChart
			v-jest="'service-analytics-tutor-load-graph'"
			class="tw-pb-9 tw--ml-5 tw--mt-5"
			:chart-data="state.chartData"
			:color="ORIGAMI_RED_400"
			:x-axes="xAxes()"
			:y-axes="state.yAxes"
		/>
	</div>
</template>
