var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BreaksConfirmation", {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("h3", { attrs: { "data-cy": "break-confirmation-header" } }, [
              _vm._v("\n\t\t\tGo on a bio break?\n\t\t"),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "bodyIcon",
        fn: function () {
          return [
            _c("OrigamiIcon", {
              directives: [
                {
                  name: "data-cy",
                  rawName: "v-data-cy",
                  value: "bio-break-icon",
                  expression: "'bio-break-icon'",
                },
              ],
              staticClass: "tw-text-origami-blue-400",
              attrs: { name: "bio-break", width: "32", height: "32" },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "bodyText",
        fn: function () {
          return [
            _c(
              "p",
              { attrs: { "data-cy": "bio-break-confirmation-body-text" } },
              [
                _vm._v(
                  "\n\t\t\tYou have up to 5 minutes for a bio break, but please return as soon as you are done\n\t\t"
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function () {
          return [
            _c(
              "OrigamiButton",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "bio-break-confirmation-modal-cancel-button",
                    expression: "'bio-break-confirmation-modal-cancel-button'",
                  },
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "bio-break-confirmation-modal-cancel-button",
                    expression: "'bio-break-confirmation-modal-cancel-button'",
                  },
                ],
                staticClass: "tw-w-full tw-justify-center tw-mb-2",
                attrs: { variant: "secondary" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.cancelBioBreakRequest.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\tCancel\n\t\t")]
            ),
            _vm._v(" "),
            _c(
              "OrigamiButton",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "bio-break-confirmation-modal-ok-button",
                    expression: "'bio-break-confirmation-modal-ok-button'",
                  },
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "bio-break-confirmation-modal-ok-button",
                    expression: "'bio-break-confirmation-modal-ok-button'",
                  },
                ],
                staticClass: "tw-w-full tw-justify-center",
                attrs: {
                  variant: "primary",
                  "is-loading":
                    _vm.$store.state.BreaksManagementWidget.isRequestLoading,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.startBioBreak.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\tOk, got it!\n\t\t")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }