var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _vm.groupList.length
        ? _c(
            "header",
            {
              staticClass:
                "w-full tw-mx-6 tw-border-b-2 tw-border-origami-grey-200 tw-py-4",
            },
            [
              _c("h2", { staticClass: "tw-font-bold tw-text-lg" }, [
                _vm._v("\n\t\t\tGroups\n\t\t\t"),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "group-count",
                        expression: "'group-count'",
                      },
                    ],
                    staticClass: "tw-font-normal",
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t(" + _vm._s(_vm.groupList.length) + ")\n\t\t\t"
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "tw-text-origami-black tw-mt-1" }, [
                _vm._v("\n\t\t\tYou can create up to 50 groups.\n\t\t"),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.groupList.length
        ? _c(
            "ul",
            { staticClass: "tw-overflow-y-auto" },
            _vm._l(_vm.groupList, function ({ id, name, students_count }) {
              return _c(
                "li",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "group-item",
                      expression: "'group-item'",
                    },
                  ],
                  key: id,
                },
                [
                  _c("ListItem", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.itemIdToDelete !== id,
                        expression: "itemIdToDelete !== id",
                      },
                    ],
                    attrs: {
                      "group-id": id,
                      "group-name": name,
                      "students-count": students_count,
                    },
                    on: {
                      "delete-item": function ($event) {
                        _vm.itemIdToDelete = id
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("ItemDelete", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.itemIdToDelete === id,
                        expression: "itemIdToDelete === id",
                      },
                    ],
                    attrs: { "group-id": id, "group-name": name },
                    on: {
                      "cancel-delete": function ($event) {
                        _vm.itemIdToDelete = null
                      },
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _c("NoGroupsCreated", { staticClass: "tw-mt-12" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }