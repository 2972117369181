import middleware from "@/middleware/index.js";
import isConnected from "@/middleware/isConnected.js";
import hasRole from "@/middleware/hasRole.js";
import hasSchool from "@/middleware/hasSchool.js";
import initializePendo from "@/middleware/initializePendo.js";
import initializeZendesk from "@/middleware/initializeZendesk.js";
import initFullStory from "@/middleware/initFullStory.js";
import redirectOnKeywordMatch from "@/middleware/redirectOnKeywordMatch.js";
import store from "@/store/index.js";
// From modules
import { customersRoutes } from "@/modules/Customers/index.js";
import { communicationsRoutes } from "@/modules/Communications/index.js";
import { dashboardRoutes } from "@/modules/SuperuserDashboard/index.js";
import { MICRO_FRONTENDS_NAMES } from "@/modules/MicroFrontend/utilities/index.js";

export default [
	{
		path: "/superuser",
		component: () => import("../pages/superuser/index.vue"),
		beforeEnter: middleware([
			isConnected,
			redirectOnKeywordMatch,
			hasRole("superuser"),
			hasSchool,
			initializePendo,
			initializeZendesk,
			initFullStory,
			(to, from, next) => {
				store.commit("SET_SHOW_NAVBAR", true);
				next();
			},
		]),
		children: [
			{
				path: "services",
				component: () => import(
					"../pages/superuser/services/index.vue"
				),
				children: [
					{
						path: "",
						redirect: "summary",
					},
					{
						path: "summary",
						component: () => import(
							"../pages/superuser/services/summary.vue"
						),
					},
					{
						path: "tutors",
						component: () => import(
							"../pages/superuser/services/tutors/index.vue"
						),
						children: [
							{
								path: "",
								redirect: "list",
							},
							{
								path: "edit/:tutorId",
								component: () => import("../pages/superuser/services/tutors/_tutorId.vue"),
							},
							{
								path: "create",
								component: () => import(
									"../pages/superuser/services/tutors/create.vue"
								),
							},
							{
								path: "list",
								component: () => import(
									"../pages/superuser/services/tutors/list.vue"
								),
							},
						],
					},
					{
						path: "schedule",
						component: () => import(
							"../pages/superuser/services/schedule/index.vue"
						),
						children: [
							{
								path: "",
								redirect: "shift-schedule",
							},
							{
								name: "superuserScheduleShiftSchedule",
								path: "shift-schedule",
								component: () => import(
									"../pages/superuser/services/schedule/shift-schedule.vue"
								),
							},
							{
								name: "superuserScheduleBreaksAbsences",
								path: "biobreaks-absences",
								component: () => import(
									"../pages/superuser/services/schedule/biobreaks-absences.vue"
								),
							},
						],
					},
					{
						path: "breaks-center",
						component: () => import(
							"../pages/superuser/services/breaks-center/index.vue"
						),
						children: [
							{
								path: "",
								redirect: "meal-breaks",
							},
							{
								name: "superuserBreaksCenterMealBreaks",
								path: "meal-breaks",
								component: () => import(
									"../pages/superuser/services/breaks-center/meal-breaks.vue"
								),
							},
							{
								name: "superuserBreaksCenterRestBreaks",
								path: "rest-breaks",
								component: () => import(
									"../pages/superuser/services/breaks-center/rest-breaks.vue"
								),
							},
							{
								name: "superuserBreaksCenterBioBreaks",
								path: "bio-breaks",
								component: () => import(
									"../pages/superuser/services/breaks-center/bio-breaks.vue"
								),
							},
						],
					},
					{
						path: "essays-queue",
						component: () => import(
							"../pages/superuser/services/essays.vue"
						),
					},
					{
						path: "action-center",
						component: () => import(
							"../pages/superuser/services/action-center/index.vue"
						),
						children: [
							{
								path: "",
								redirect: "classroom",
							},
							{
								name: "superuserActionCenterClassroom",
								path: "classroom",
								component: () => import(
									"../pages/superuser/services/action-center/classroom.vue"
								),
							},
							{
								name: "superuserActionCenterCoverage",
								path: "coverage",
								component: () => import(
									"../pages/superuser/services/action-center/coverage.vue"
								),
							},
							{
								name: "superuserActionCenterEssayReview",
								path: "essay-review",
								component: () => import(
									"../pages/superuser/services/action-center/essay-review.vue"
								),
							},
						],
					},
					{
						path: "analytics",
						component: () => import(
							"../pages/superuser/services/analytics.vue"
						),
					},
				],
			},
			{
				path: "activity",
				name: "superuserActivity",
				component: () => import(
					"@/modules/Activity/pages/superuser-activity.vue"
				),
			},
			{
				name: "superuserActivityDashboard",
				path: "activity_mfe",
				component: () => import("@/modules/MicroFrontend/components/MicroFrontend.vue"),
				props: {
					microFrontendName: MICRO_FRONTENDS_NAMES.ACTIVITY_DASHBOARD,
				},
			},
			{
				path: "settings",
				name: "superuserSettings",
				component: () => import(
					"@/modules/UserSettings/pages/superuser-settings.vue"
				),
			},
			...customersRoutes,
			...communicationsRoutes,
			...dashboardRoutes,
		],
	},
];
