let voices = [];
let voiceListeners = [];
let textUtterance = {};
let modifiedText = "";
let currentText = "";
let currentSpeakOptions = {};
let currentPosition = 0;
const DEFAULT_RATE = 0.7;

function updateVoices() {
	voices = window.speechSynthesis?.getVoices();
	voiceListeners.forEach((fn) => fn());
	voiceListeners = [];
}
async function waitForVoices() {
	return new Promise((resolve) => {
		voiceListeners.push(resolve);
	});
}
export async function changeSpeakingRate(newRate) {
	const isPaused = window.speechSynthesis?.paused;
	speakText(currentText, { ...currentSpeakOptions, rate: newRate, startPosition: currentPosition });
	if (isPaused) {
		pauseSpeech();
	}
	return new Promise((resolve, reject) => {
		textUtterance.addEventListener("end", resolve);
		textUtterance.addEventListener("error", reject);
	});
}

export async function speakText(text, options = {}) {
	const { voiceName = "Alex", lang = "en", rate = DEFAULT_RATE, startPosition = 0, positionCallback = () => { } } = options;
	if (!window.speechSynthesis) {
		return;
	}
	updateVoices();
	window.speechSynthesis.cancel();

	if (!voices.length) {
		window.speechSynthesis.addEventListener("voiceschanged", updateVoices);
		await waitForVoices();
	}
	currentSpeakOptions = options;
	currentText = text;
	let selectedVoice = voices.find((voice) => voice.name === voiceName);
	if (!selectedVoice) {
		console.warn("Voice", voiceName, "not found");
		selectedVoice = voices[0];
	}
	modifiedText = replaceMnemonicChars(text);
	if (startPosition) {
		modifiedText = modifiedText.substring(startPosition, modifiedText.length);
	}
	textUtterance = new SpeechSynthesisUtterance(modifiedText);
	textUtterance.volume = 1;
	textUtterance.rate = rate;
	textUtterance.voice = selectedVoice;
	textUtterance.lang = lang;
	textUtterance.addEventListener("boundary", (event) => {
		const start = event.charIndex + startPosition;
		const end = calculateWordEndingIndex(text, start);
		currentPosition = start;
		positionCallback(start, end);
	});
	return new Promise((resolve, reject) => {
		textUtterance.addEventListener("end", resolve);
		textUtterance.addEventListener("error", reject);
		window.speechSynthesis.speak(textUtterance);
	});
}

export function pauseSpeech() {
	window.speechSynthesis?.pause();
}

export function resumeSpeech() {
	window.speechSynthesis?.resume();
}

export function calculateWordEndingIndex(text = "", start = 0) {
	const index = text.slice(start).search(/\s/);
	return index > 0 ? start + index : text.length;
}

export function replaceMnemonicChars(text) {
	return text.replace(/&quot;/g, `"`);
}
