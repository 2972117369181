export default (rootState) => {
	const { school_id, grade_id, timezone } = rootState.currentUser;
	return {
		user_role_id: 1,
		user_school_id: school_id ?? null,
		user_grade_id: grade_id,
		timezone: timezone,
		delivery_info_user_agent: window.navigator.userAgent,
	};
};
