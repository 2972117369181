export const AVATARS_LIST = ["Elephant", "Racoon", "Mouse", "Bee", "Chameleon"];

export const AVATAR_COLORS = {
	Elephant: [
		[
			"#B7B7F6",
			"#0E0FE0",
			"#FF452C",
			"#050F64",
			"#FFFFFF",
			"#BABBBF",
		],
		[
			"#FFC7C0",
			"#FAC140",
			"#E04483",
			"#43235C",
			"#FFFFFF",
			"#BABBBF",
		],
		[
			"#98ECF6",
			"#018464",
			"#6F399B",
			"#181C28",
			"#FFFFFF",
			"#BABBBF",
		],
		[
			"#FCDA8C",
			"#FF8F80",
			"#03CA9A",
			"#050F64",
			"#FFFFFF",
			"#BABBBF",
		],
	],
	Mouse: [
		[
			"#FF8F80",
			"#FFC7C0",
			"#FCDA8C",
			"#050F64",
			"#FFFFFF",
			"#BABBBF",
		],
		[
			"#03CA9A",
			"#68DFC2",
			"#E04483",
			"#43235C",
			"#FFFFFF",
			"#BABBBF",
		],
		[
			"#A988C3",
			"#D4C4E1",
			"#FF8F80",
			"#181C28",
			"#FFFFFF",
			"#BABBBF",
		],
		[
			"#3A9DA9",
			"#CCF6FB",
			"#02A17B",
			"#181C28",
			"#FFFFFF",
			"#BABBBF",
		],
	],
	Bee: [
		[
			"#FCDA8C",
			"#BABBBF",
			"#B7B7F6",
			"#6E6FEC",
			"#181C28",
			"#FFFFFF",
			"#BABBBF",
		],
		[
			"#FF8F80",
			"#FAC140",
			"#B3EFE1",
			"#02A17B",
			"#181C28",
			"#FFFFFF",
			"#BABBBF",
		],
		[
			"#018464",
			"#A988C3",
			"#CCF6FB",
			"#54E1F0",
			"#181C28",
			"#FFFFFF",
			"#BABBBF",
		],
		[
			"#B7B7F6",
			"#FF452C",
			"#FCDA8C",
			"#FAC140",
			"#181C28",
			"#FFFFFF",
			"#BABBBF",
		],
	],
	Racoon: [
		[
			"#E04483",
			"#F6C7DA",
			"#6E6FEC",
			"#050F64",
			"#FFFFFF",
			"#BABBBF",
		],
		[
			"#03CA9A",
			"#68DFC2",
			"#E04483",
			"#181C28",
			"#FFFFFF",
			"#BABBBF",
		],
		[
			"#6F399B",
			"#D4C4E1",
			"#3A9DA9",
			"#050F64",
			"#FFFFFF",
			"#BABBBF",
		],
		[
			"#FAC140",
			"#FCDA8C",
			"#FF452C",
			"#181C28",
			"#FFFFFF",
			"#BABBBF",
		],
	],
	Chameleon: [
		[
			"#68DFC2",
			"#FAC140",
			"#050F64",
			"#02A17B",
			"#FFFFFF",
			"#BABBBF",
			"#8C8C8C",
		],
		[
			"#A988C3",
			"#68DFC2",
			"#181C28",
			"#6F399B",
			"#FFFFFF",
			"#8C8C8C",
			"#303030",
		],
		[
			"#3A9DA9",
			"#E04483",
			"#181C28",
			"#050F64",
			"#FFFFFF",
			"#8C8C8C",
			"#BABBBF",
		],
		[
			"#FF8F80",
			"#6E6FEC",
			"#181C28",
			"#050F64",
			"#FFFFFF",
			"#8C8C8C",
			"#BABBBF",
		],
	],
};

export const AVATAR_COLOR_NAMES = {
	"#6E6FEC": "violet",
	"#B7B7F6": "light violet",
	"#0E0FE0": "blue",
	"#050F64": "navy blue",
	"#98ECF6": "cyan",
	"#CCF6FB": "light cyan",
	"#3A9DA9": "teal",
	"#68DFC2": "turquoise",
	"#B3EFE1": "light turquoise",
	"#54E1F0": "aqua",
	"#FF452C": "red",
	"#FF8F80": "coral",
	"#FFC7C0": "light coral",
	"#E04483": "pink",
	"#F6C7DA": "light pink",
	"#02A17B": "green",
	"#018464": "dark green",
	"#03CA9A": "light green",
	"#6F399B": "indigo",
	"#D4C4E1": "light purple",
	"#43235C": "dark indigo",
	"#A988C3": "purple",
	"#FAC140": "yellow",
	"#FCDA8C": "light yellow",
	"#BABBBF": "grey",
	"#181C28": "black",
	// default colors below
	"#FF73CD": "fuchsia",
	"#EF5BC2": "magenta",
	"#FFBA81": "pastel orange",
	"#99B1EF": "pastel blue",
	"#FF570D": "dark orange",
	"#FFE500": "yellow",
	"#A3A3A3": "grey",
	"#FF9E45": "orange",
	"#82D38B": "pastel green",
};
