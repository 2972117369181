var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.ChartLine, {
    directives: [
      {
        name: "data-cy",
        rawName: "v-data-cy",
        value: "service-analytics-line-chart",
        expression: "'service-analytics-line-chart'",
      },
    ],
    attrs: { data: _setup.data, options: _setup.options },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }