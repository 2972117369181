export default [
	{
		path: "student-groups/create",
		component: () => import(
			"../pages/createStudentGroups.vue"
		),
	},
	{
		path: "student-groups/edit/:id",
		component: () => import(
			"../pages/editStudentGroups.vue"
		),
	},
];
