var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "resourceList" },
    [
      _vm.isEssayComponent
        ? _c(
            "div",
            { staticClass: "resourceList__total resourceList__total--essay" },
            [
              _c("div", { staticClass: "resourceList__title" }, [
                _vm._v("\n\t\t\tReviews\n\t\t"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resourceList__resourceTotal" }, [
                _vm._v("\n\t\t\tTotal Submission Reviews\n\t\t\t"),
                _c("span", { staticClass: "resourceList__count" }, [
                  _vm._v(_vm._s(_vm.totalResourcesCount)),
                ]),
              ]),
            ]
          )
        : _c("div", { staticClass: "resourceList__total" }, [
            _c("div", { staticClass: "resourceList__onGoing" }, [
              _vm._v("\n\t\t\tOngoing Sessions\n\t\t\t"),
              _c("span", { staticClass: "resourceList__onGoingCount" }, [
                _vm._v("(" + _vm._s(_vm.ongoingSessionsCount) + ")"),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resourceList__sessions" }, [
              _vm._v("\n\t\t\tTotal Sessions\n\t\t\t"),
              _c("span", { staticClass: "resourceList__count" }, [
                _vm._v(_vm._s(_vm.totalResourcesCount)),
              ]),
            ]),
          ]),
      _vm._v(" "),
      _vm.isResourcesEmpty
        ? _c("ServiceDashboardListItemEmpty", {
            attrs: {
              "resource-type": _vm.componentType,
              "time-since-last-activity": _vm.timeSinceLastActivity,
            },
          })
        : _vm._l(_vm.resources, function (resource, key) {
            return [
              resource
                ? _c(_vm.getListComponent(resource), {
                    key: key,
                    tag: "component",
                    staticClass: "resourceList__content",
                    attrs: { resource: resource },
                  })
                : _vm._e(),
            ]
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }