export default [
	{
		path: "/",
		redirect: "/login/student",
	},
	{
		path: "/schools/administrators",
		redirect: "/",
	},
	{
		path: "/schools/teachers",
		redirect: "/",
	},
	{
		path: "/schools/students",
		redirect: "/",
	},
	{
		path: "/clients",
		redirect: "/",
	},
	{
		path: "/clients/vhs",
		redirect: "/",
	},
	{
		path: "/features",
		redirect: "/",
	},
	{
		path: "/schools",
		redirect: "/",
	},
	{
		path: "/educators",
		redirect: "/",
	},
	{
		path: "/educators/:tutorname",
		redirect: "/",
	},
	{
		path: "/student-success",
		redirect: "/",
	},
	{
		path: "/student-care",
		beforeEnter() {
			window.location.replace("https://paper.co/privacy-policy-services");
		},
	},
	{
		path: "/subjects",
		redirect: "/",
	},
	{
		path: "/french",
		redirect: "/",
	},
	{
		path: "/case-studies/furman",
		redirect: "/",
	},
	{
		path: "/case-studies/laguna",
		redirect: "/",
	},
	{
		path: "/businesses",
		redirect: "/",
	},
	{
		path: "/students/review-center",
		redirect: "/student/review-center",
	},
	{
		path: "/about",
		beforeEnter() {
			window.location.replace("https://paper.co/company");
		},
	},
	{
		path: "/blog",
		beforeEnter() {
			window.location.replace("https://paper.co/blog");
		},
	},
	{
		path: "/blog/**",
		beforeEnter() {
			window.location.replace("https://paper.co" + window.location.pathname);
		},
	},
	{
		path: "/contact",
		beforeEnter() {
			window.location.replace("https://paper.co/get-in-touch");
		},
	},
	{
		path: "/demonstration",
		beforeEnter() {
			window.location.replace("https://paper.co/demo-request");
		},
	},
	{
		path: "/integrations",
		beforeEnter() {
			window.location.replace("https://paper.co");
		},
	},
	{
		path: "/privacy",
		beforeEnter() {
			window.location.replace("https://paper.co/privacy-policy-services");
		},
	},
];
