<template>
	<OrigamiCard class="tw-p-4">
		<slot name="header" />
		<div
			v-if="isLoading"
			v-jest="'loading'"
			class="tw-h-44 tw-flex tw-justify-center tw-items-center tw-w-full"
		>
			<slot name="loading" />
		</div>
		<div
			v-if="!isLoading"
			class="
				tw-flex tw-flex-col tw-align-center tw-gap-6 tw-w-full
				xs:tw-justify-between xs:tw-align-start
				md:tw-flex-col xs:tw-flex-row
				lg:tw-flex-col lg:tw-justify-start lg:tw-align-center
				xl:tw-flex-row tw-justify-start xl:tw-justify-between xl:tw-align-start
			"
		>
			<div class="circle-box tw-mx-auto tw-w-32 tw-h-32">
				<slot name="circle" />
			</div>
			<div
				v-jest="'goals'"
				class="tw-w-full tw-overflow-hidden"
			>
				<slot name="goals" />
			</div>
		</div>
	</OrigamiCard>
</template>

<script>
import { OrigamiCard } from "@origami/vue2";

export default {
	components: { OrigamiCard },
	props: {
		isLoading: {
			type: Boolean,
			required: true,
		},
	},
};
</script>

<style scoped>
.card {
	box-shadow: var(--box-shadow);
	flex-basis: 50%;
}

@media (min-width: 48rem) and (max-width: 64rem) {
	.card {
		flex-basis: 40%;
		margin-bottom: 0;
	}
}

.circle-box {
	min-width: 8rem;
}
</style>
