<template>
	<ul
		id="navbar-user-links"
		class="tw-flex-grow tw-items-center tw-ml-8 tw-text-origami-white"
	>
		<li
			v-for="(link, index) in userLinks"
			:key="`link-${index}`"
			class="tw-px-6 link-item tw-relative"
		>
			<NotificationBubble
				v-if="link.notificationCount"
				:notification-count="link.notificationCount"
			/>
			<BaseLink
				:key="link.target"
				:ref="`navbar-user-link-${index}`"
				v-data-cy="`navbar-tab-${link.title}`"
				:to="link.target"
				:target="link.title === 'navbar.pave'"
				class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-max"
				@keydown.native.right="focusListItem(index + 1)"
				@keydown.native.left="focusListItem(index - 1)"
			>
				<component :is="link.icon" />
				{{ link.title !== "navbar.pave" ? $t(link.title) : null }}
				<div class="hover-bar" />
			</BaseLink>
		</li>
	</ul>
</template>

<script>
import {
	Activity,
	Chat,
	Classroom,
	Dashboard,
	Essay,
	Paperlive,
	PaperMath,
	Pave,
	Students,
	Reading,
	External,
} from "@/components/elements/icons/index.js";
import NotificationBubble from "@/modules/Notifications/components/NotificationBubble.vue";

export default {
	name: "NavbarUserLinks",
	components: {
		Chat,
		Activity,
		Classroom,
		Dashboard,
		Students,
		Reading,
		Essay,
		Pave,
		Paperlive,
		PaperMath,
		NotificationBubble,
		External,
	},
	props: {
		userLinks: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		focusListItem(targetIdx) {
			const listItemToFocus = this.$refs[`navbar-user-link-${targetIdx}`]?.[0]?.$el;
			listItemToFocus?.focus();
		},
	},
};
</script>

<style scoped>
.link-item:hover .hover-bar {
	position: absolute;
	width: 1.25rem;
	height: 0.125rem;
	background: var(--green-regular);
	bottom: -0.25rem;
	left: 50%;
	transform: translateX(-50%);
}

.link-item a:hover {
	color: var(--white-high);
	text-decoration: none;
}
/* eslint-disable-next-line vue-scoped-css/no-unused-selector */
.link-item .router-link-active {
	font-weight: 700;
}

.link-item .router-link-active .hover-bar {
	position: absolute;
	width: 1.25rem;
	height: 0.125rem;
	background: var(--green-regular);
	bottom: -0.25rem;
	left: 50%;
	transform: translateX(-50%);
}

.link-item a[href="https://paper.co/blog"]:after {
	position: absolute;
	content: "\2192";
	right: 5px;
	top: 0;
}
</style>
