var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_setup.Navbar, {
    directives: [
      {
        name: "jest",
        rawName: "v-jest",
        value: "navbar",
        expression: "'navbar'",
      },
    ],
    attrs: {
      "dropdown-links": _setup.reactiveDropdownLinks,
      "is-sidebar-toggled": _vm.isSidebarToggled,
      "is-mobile-menu-toggled": _vm.isMobileMenuToggled,
    },
    on: {
      "toggle-sidebar": function ($event) {
        return _setup.emit("toggle-sidebar")
      },
      "toggle-mobile-menu": function ($event) {
        return _setup.emit("toggle-mobile-menu")
      },
      "close-mobile-menu": function ($event) {
        return _setup.emit("close-mobile-menu")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "beforeAvatar",
        fn: function () {
          return [
            _c(_setup.AchievementNotificationHandler),
            _vm._v(" "),
            _setup.featureFlagStudentNotificationsDrawerIsEnabled
              ? _c(_setup.MicroFrontendContent, {
                  attrs: {
                    "micro-frontend-name":
                      _setup.MICRO_FRONTENDS_NAMES.STUDENT_NOTIFICATIONS_DRAWER,
                    "is-widget": true,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "loader",
                        fn: function () {
                          return [
                            _c(_setup.BaseLoader, {
                              staticClass: "tw-h-10 tw-w-10",
                            }),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "error",
                        fn: function () {
                          return [_c("div")]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    841281926
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _c(_setup.LanguagePicker, {
              on: {
                "select-language": (lang) =>
                  _setup.store.dispatch("Lang/changeLanguage", lang),
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }