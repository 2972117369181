var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tw-relative" },
    [
      _c(
        "div",
        { staticClass: "tutorTags__buttons tw-absolute tw-right-0" },
        [
          !_vm.isEditing
            ? _c(
                "BaseButton",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "edit-tags-button",
                      expression: "'edit-tags-button'",
                    },
                  ],
                  staticClass:
                    "tutorTags__button tutorTags__buttonPrimary tw-w-20 tw-h-10 tw-text-sm tw-font-semibold tw-text-white-high tw-flex tw-items-center tw-justify-center tw-py-0 tw-px-4 tw-truncate",
                  attrs: { type: "button", color: "blue", variants: ["full"] },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.showFilters.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n\t\t\tEdit\n\t\t")]
              )
            : _c(
                "BaseButton",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "cancel-edit-button",
                      expression: "'cancel-edit-button'",
                    },
                  ],
                  staticClass:
                    "tutorTags__button tutorTags__cancelButton tw-w-20 tw-h-10 tw-text-sm tw-font-semibold tw-text-black-high tw-flex tw-items-center tw-justify-center tw-py-0 tw-px-4 tw-truncate tw-bg-white-high",
                  attrs: { type: "button", color: "white", variants: ["full"] },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.cancelTags.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n\t\t\tCancel\n\t\t")]
              ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isEditing
        ? _c("ServiceTutorEditShowTagsTable")
        : _c("TagsFilterTable", { ref: "tagsFilterTable" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }