var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 18 18",
      },
    },
    [
      _c("path", {
        attrs: {
          fill: _vm.iconColor,
          "fill-rule": "evenodd",
          d: "M13.352 9.343a.731.731 0 0 1-.732.731H8.767a.73.73 0 0 1-.66-.42.542.542 0 0 1-.098-.311V3.609a.55.55 0 0 1 1.097 0v5.002h3.514c.405 0 .732.328.732.732m-4.374 7.226c-4.188 0-7.593-3.406-7.593-7.593 0-4.186 3.405-7.591 7.593-7.591 4.186 0 7.592 3.405 7.592 7.591 0 4.187-3.406 7.593-7.592 7.593M8.978 0C4.028 0 0 4.027 0 8.976c0 4.95 4.028 8.978 8.978 8.978s8.976-4.027 8.976-8.978C17.954 4.026 13.927 0 8.978 0",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }