<template>
	<Transition name="slide-right">
		<div
			id="mobile-nav"
			v-jest="'mobile-nav'"
			class="mobile-nav"
		>
			<LearnerProfileLink
				v-jest="'learner-profile-link'"
				@redirect="$emit('reset-mobile-menu')"
			/>
			<Links
				v-jest="'mobile-nav-links'"
				:nav-links="reactiveNavLinks"
				class="tw-border-b tw-border-grey-regular tw-py-2"
				@reset-mobile-menu="$emit('reset-mobile-menu')"
			/>
			<Links
				ref="navLinksDropdown"
				v-jest="'mobile-dropdown-links'"
				:nav-links="reactiveDropdownLinks"
				:is-mobile="true"
				class="tw-border-grey-regular tw-pt-2"
				@reset-mobile-menu="$emit('reset-mobile-menu')"
			/>
		</div>
	</Transition>
</template>

<script setup>
import { ref, onMounted } from "vue";

import useNavLinks from "../composables/useNavLinks.js";

import Links from "./Links.vue";
import LearnerProfileLink from "./LearnerProfileLink.vue";

defineEmits(["reset-mobile-menu"]);

const { reactiveNavLinks, checkNavLinksFlags, reactiveDropdownLinks } = useNavLinks();
const navLinksDropdown = ref(null);

onMounted(async() => {
	await checkNavLinksFlags();
});

defineExpose({ navLinksDropdown, checkNavLinksFlags });
</script>

<style scoped>
.mobile-nav {
	@apply tw-fixed tw-bottom-0 tw-left-0 tw-w-full tw-bg-white-high tw-overflow-y-auto tw-py-2 md:tw-hidden;
	height: calc(100% - 88px);
	z-index: 9999;
	transform: translateX(0);
}
.slide-right-enter-active,
.slide-right-leave-active {
	transition: all 0.3s ease;
}

.slide-right-enter,
.slide-right-leave-to {
	transform: translateX(-100%);
}
</style>
