function AbstractMessage(
	id,
	userId,
	userName,
	createdAt,
	sequencePosition,
	isLastMessage,
	isQuestion,
	isDeleted,
	isFile,
	isTutor,
) {
	this.id = id;
	this.userId = userId;
	this.userName = userName;
	this.createdAt = createdAt;
	this.sequencePosition = sequencePosition;
	this.isLastMessage = isLastMessage;
	this.isQuestion = isQuestion;
	this.isDeleted = isDeleted;
	this.isFile = isFile,
	this.isTutor = isTutor;
}

export default AbstractMessage;
