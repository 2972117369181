import axios from "axios";
import jsCookie from "js-cookie";
import jwtDecode from "jwt-decode";
import { identifyZendeskUser } from "@paper-co/web-toolkit";

import { setPostLoginRedirectUri } from "@/modules/Authentication/utilities/auth.js";

const rootInstance = axios.create({
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
});
const accessToken = jsCookie.get("access_token");
if (accessToken) {
	rootInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
}
const tokenClient = jsCookie.get("token_client") || null;
if (tokenClient) {
	rootInstance.defaults.headers.common["token-client"] = tokenClient;
}

export function LaravelApi() {
	rootInstance.defaults.baseURL = "/laravel";
	return rootInstance;
}

export async function logout(redirect = undefined) {
	try {
		let redirect_uri;
		if (redirect === true) {
			redirect_uri = `${window.location.pathname}${window.location.search}${window.location.hash}`;
		} else if (typeof redirect === "string") {
			redirect_uri = redirect;
		} else if (redirect?.fullPath) {
			redirect_uri = redirect.fullPath;
		}
		if (redirect_uri) {
			setPostLoginRedirectUri(redirect_uri);
		}

		await LaravelApi().get("/logout");
		await identifyZendeskUser(null);
	} catch (error) {
		Sentry.captureException(error);
	} finally {
		const redirect_uri = new URL(`https://${process.env.MIX_AUTH0_DOMAIN}/oidc/logout`);
		redirect_uri.searchParams.append("client_id", process.env.MIX_AUTH0_CLIENT_ID);
		redirect_uri.searchParams.append("post_logout_redirect_uri", `${window.location.origin}`);
		window.location.replace(redirect_uri.toString());
	}
}

function isAccessTokenExpired(accessToken) {
	try {
		const token = jwtDecode(accessToken);
		const isExpired = token.exp <= Math.floor(Date.now() / 1000);
		return isExpired;
	} catch (error) {
		Sentry.captureException(error);

		return true;
	}
}
export async function refreshAccessTokenIfNecessary(force = false) {
	let accessToken = jsCookie.get("access_token");
	if (accessToken && !force) {
		const isExpired = isAccessTokenExpired(accessToken);
		if (isExpired) {
			accessToken = null;
		}
	}
	if (!accessToken || force) {
		const refreshToken = jsCookie.get("refresh_token");
		if (refreshToken) {
			try {
				accessToken = (await LaravelApi().get("/refresh-token")).data.access_token;
			} catch (error) {
				accessToken = null;
				Sentry.captureException(error);
			}
		}
	}
	if (accessToken) {
		rootInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
	}

	return accessToken || null;
}
export async function refreshAccessTokenOrLogout(redirect = undefined) {
	try {
		const accessToken = await refreshAccessTokenIfNecessary();
		if (!accessToken) {
			throw new Error("no access token");
		}
		return accessToken;
	} catch (error) {
		await logout(redirect);
	}
}

let isAlreadyFetchingAccessToken = false;
let subscribers = [];
function addSubscriber(callback) {
	subscribers.push(callback);
}
function onAccessTokenFetched(access_token) {
	subscribers = subscribers.filter((callback) => callback(access_token));
}
rootInstance.interceptors.response.use(
	(response) => response,
	async(error) => {
		const { config: originalRequest, response } = error;

		if (response?.status === 429) {
			window.location.href = "/error.html";
		} else if (response?.status === 401 && originalRequest.url !== "/login") {
			const retryOriginalRequest = new Promise((resolve) => {
				addSubscriber((access_token) => {
					originalRequest.headers.Authorization = `Bearer ${access_token}`;
					resolve(rootInstance(originalRequest));
				});
			});
			if (!isAlreadyFetchingAccessToken) {
				isAlreadyFetchingAccessToken = true;

				const refreshToken = jsCookie.get("refresh_token");
				if (!refreshToken) {
					throw new Error("no refresh token");
				}
				const accessToken = await refreshAccessTokenOrLogout();

				isAlreadyFetchingAccessToken = false;
				onAccessTokenFetched(accessToken);
			}

			return retryOriginalRequest;
		}

		return Promise.reject(error);
	},
);

export default rootInstance;
