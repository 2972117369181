<template>
	<OrigamiCard
		class="tw-h-full"
		tag="div"
	>
		<div class="tw-flex tw-flex-col tw-px-5 tw-py-4 tw-h-full">
			<div class="tw-flex tw-items-center tw-gap-x-1 tw-mb-2 md:tw-mb-5">
				<h2 class="tw-text-sm tw-font-bold">
					<slot name="cardTitle" />
				</h2>
				<slot name="cardTooltip" />
			</div>
			<span
				v-jest="'essay-detail'"
				class="tw-text-origami-blue-300 tw-font-bold tw-text-2xl tw-text-center md:tw-text-left"
			>
				<slot name="essayDetail" />
			</span>
			<span
				v-jest="'essay-misc'"
				class="tw-flex tw-justify-between tw-mt-auto tw-text-sm tw-text-origami-grey-300 tw-font-bold tw-truncate md:tw-column"
			>
				<slot name="essayMiscOne" />
				<slot name="essayMiscTwo" />
			</span>
		</div>
	</OrigamiCard>
</template>

<script>
import { OrigamiCard } from "@origami/vue2";

export default {
	components: {
		OrigamiCard,
	},
	props: {
		isExpanded: {
			type: Boolean,
			required: false,
		},
	},
};
</script>
