var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 18 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        staticClass: "fill-current",
        attrs: {
          d: "M16.2 5.4H14.4C14.4 4.905 13.995 4.5 13.5 4.5H0.9C0.405 4.5 0 4.905 0 5.4V8.541C0.018 12.519 3.258 15.723 7.236 15.705C9.585 15.696 11.781 14.535 13.122 12.6H13.5C15.984 12.6 18 10.584 18 8.1V7.2C18 6.21 17.19 5.4 16.2 5.4ZM11.7 11.466C10.053 13.95 6.705 14.634 4.212 12.978C2.727 11.997 1.818 10.332 1.8 8.541V6.3H12.6V8.541C12.6 9.585 12.285 10.602 11.7 11.466ZM16.2 8.1C16.2 9.378 15.3 10.485 14.049 10.746C14.283 10.035 14.4 9.288 14.4 8.541V7.2H16.2V8.1Z",
        },
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "fill-current",
        attrs: {
          d: "M14.4 16.2H0.9C0.405 16.2 0 16.605 0 17.0999C0 17.5949 0.405 17.9999 0.9 17.9999H14.4C14.895 17.9999 15.3 17.5949 15.3 17.0999C15.3 16.605 14.895 16.2 14.4 16.2Z",
        },
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "fill-current",
        attrs: {
          d: "M6.30039 3.6C6.79539 3.6 7.20039 3.195 7.20039 2.7V0.9C7.20039 0.405 6.79539 0 6.30039 0C5.80539 0 5.40039 0.405 5.40039 0.9V2.7C5.40039 3.195 5.80539 3.6 6.30039 3.6Z",
        },
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "fill-current",
        attrs: {
          d: "M8.99961 2.7C9.49461 2.7 9.89961 2.295 9.89961 1.8V0.9C9.89961 0.405 9.49461 0 8.99961 0C8.50461 0 8.09961 0.405 8.09961 0.9V1.8C8.09961 2.295 8.50461 2.7 8.99961 2.7Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }