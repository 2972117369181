const state = {
	isVisible: false,
	text: null,
};

const mutations = {
	SET_TOASTER_TEXT(state, { text }) {
		state.text = text;
	},
	SET_TOASTER_VISIBILITY(state, { isVisible }) {
		state.isVisible = isVisible;
	},
};

const actions = {
	resetToaster({ commit }) {
		commit("SET_TOASTER_VISIBILITY", { isVisible: false });
		commit("SET_TOASTER_TEXT", { text: null });
	},
	showToaster({ commit }, { text }) {
		commit("SET_TOASTER_TEXT", { text });
		commit("SET_TOASTER_VISIBILITY", { isVisible: true });
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
