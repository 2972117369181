var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("TutorDashboardAvailabilitiesInfo"),
      _vm._v(" "),
      _c("TutorDashboardAvailabilitiesCalendar", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "availabilities-calendar",
            expression: "'availabilities-calendar'",
          },
        ],
      }),
      _vm._v(" "),
      _c("TutorDashboardAvailabilitiesFooter", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "availabilities-footer",
            expression: "'availabilities-footer'",
          },
        ],
      }),
      _vm._v(" "),
      _c("OrigamiDialog", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "cancel-edition-dialog",
            expression: "'cancel-edition-dialog'",
          },
        ],
        attrs: {
          title: "Cancel changes to your availability?",
          description:
            "Are you sure you want to cancel? All updates will be lost, and your availability will default back to your original selection.",
          "secondary-button-text": "Go Back",
          "primary-button-text": "Confirm",
          "is-destructive": "",
          "is-open": _vm.Availabilities.isPromptingCancelWarning,
        },
        on: {
          confirm: _vm.Availabilities.cancelConfirmedCallback,
          cancel: function ($event) {
            return _vm.$store.dispatch(
              "Availabilities/closeCancelAvailabilitiesEdition"
            )
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }