import Vue from "vue";

import TopicsAPI from "@/services/api/Topics.js";

import CoursesAPI from "../../services/courses.js";
import DistrictsAPI from "../../services/districts.js";
import SchoolsAPI from "../../services/schools.js";
import SectionsAPI from "../../services/sections.js";

const getDefaultState = () => {
	return {
		sections: [],
		items: [],
		searchText: "",
		classes: [],
		schools: [],
		districts: [],
		topics: [],
		statuses: ["active", "upcoming", "expired"],

		schoolFilter: [],
		districtFilter: [],
		topicFilter: [],
		statusFilter: null,

		loading: false,
		isShowingNoFilterBanner: true,
		orderBy: "",
		totalItems: 0,
		currentPage: 0,
		totalPages: 0,
		sectionsTableHeaders: [
			{
				value: "synced_with",
				header: "",
				orderable: false,
				state: null,
			},
			{
				value: "id",
				header: "ID",
				orderable: true,
				state: null,
			},
			{
				value: "name",
				header: "section name",
				orderable: true,
				state: null,
			},
			{
				value: "teacher",
				header: "teacher",
				orderable: false,
				state: null,
			},
			{
				value: "school",
				header: "school",
				orderable: true,
				state: null,
			},
			{
				value: "status",
				header: "Status",
				orderable: false,
				state: null,
			},
			{
				value: "active_from",
				header: "start date",
				orderable: true,
				state: null,
			},
			{
				value: "active_to",
				header: "end date",
				orderable: true,
				state: null,
			},
			{
				value: "students_count",
				header: "students",
				orderable: true,
				state: null,
			},
			{
				value: "course_name",
				header: "Course Name",
				orderable: true,
				state: null,
			},
			{
				value: "topics",
				header: "Course Subject",
				orderable: false,
				state: null,
			},
		],
		sectionFilters: {
			district: {
				selected: [],
				options: [],
			},
			school: {
				selected: [],
				options: [],
			},
			course: {
				selected: [],
				options: [],
			},
			topics: {
				selected: [],
				options: [],
			},
			status: {
				selected: [],
				options: [
					{
						id: "active",
						name: "Active",
					},
					{
						id: "upcoming",
						name: "Upcoming",
					},
					{
						id: "expired",
						name: "Expired",
					},
				],
			},
		},
		filterKeys: [
			{
				title: "district",
				value: "district",
				type: "checkbox",
			},
			{
				title: "school",
				value: "school",
				type: "checkbox",
			},
			{
				title: "course",
				value: "course",
				type: "checkbox",
			},
			{
				title: "Course Subject",
				value: "topics",
				type: "checkbox",
			},
			{
				title: "status",
				value: "status",
				type: "radio",
			},
		],
		navbarMainTab: "Customers",
	};
};

const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_IS_SHOWING_NO_FILTER_BANNER(state, payload) {
		state.isShowingNoFilterBanner = payload.isShowingNoFilterBanner;
	},
	SET_SECTIONS(state, payload) {
		state.sections = payload.sections;
	},
	SET_TOTAL_ITEMS(state, payload) {
		state.totalItems = payload.totalItems;
	},
	SET_TABLE_HEADERS(state, payload) {
		state.sectionsTableHeaders = payload.headers;
	},
	SET_SEARCH_TEXT(state, payload) {
		state.searchText = payload.searchText;
	},
	SET_SCHOOLS(state, payload) {
		state.sectionFilters.school.options = payload.schools;
	},
	SET_LOADING(state, payload) {
		state.loading = payload.loading;
	},
	SET_ORDER_BY(state, payload) {
		state.orderBy = payload.orderBy;
	},
	SET_CURRENT_PAGE(state, payload) {
		state.currentPage = payload.currentPage;
	},
	SET_TOTAL_PAGES(state, payload) {
		state.totalPages = payload.totalPages;
	},
	SET_DISTRICTS(state, payload) {
		state.sectionFilters.district.options = payload.district;
	},
	SET_TOPICS(state, payload) {
		state.sectionFilters.topics.options = payload.topics;
	},
	SET_FILTER_IN_FILTERS_KEY_VALUE(state, payload) {
		Vue.set(state.sectionFilters[payload.filter], payload.key, payload.value);
	},
	SET_SECTIONS_IN_ITEMS(state, payload) {
		state.items = payload.items;
	},
	SET_COURSE(state, payload) {
		state.sectionFilters.course.options = payload.courses;
	},
	RESET_TABLE_HEADERS(state) {
		state.sectionsTableHeaders.forEach((header) => {
			header.state = null;
		});
	},
};

const getters = {
	filtersAsParamsObject(state) {
		const params = {};

		if (state.searchText !== "") {
			params["search"] = state.searchText;
		}

		if (state.orderBy !== "") {
			params["orderBy"] = state.orderBy;
		}

		Object.keys(state.sectionFilters).forEach((filter) => {
			params[filter] = state.sectionFilters[filter].selected;
		});

		if (state.currentPage > 1) {
			params["page"] = state.currentPage;
		}

		return params;
	},
};

const actions = {
	async getFilteredSectionsData({ commit, getters }) {
		commit("SET_LOADING", {
			loading: true,
		});
		commit({
			type: "SET_IS_SHOWING_NO_FILTER_BANNER",
			isShowingNoFilterBanner: false,
		});
		const params = getters.filtersAsParamsObject;
		params["include"] = "school.district,teachers,topics,course";
		try {
			const response = await SectionsAPI.filter(params);
			commit("SET_SECTIONS_IN_ITEMS", {
				items: response.data.data,
			});

			commit("SET_TOTAL_ITEMS", {
				totalItems: response.data.meta.pagination.total,
			});

			commit("SET_TOTAL_PAGES", {
				totalPages: response.data.meta.pagination.total_pages,
			});

			commit("SET_CURRENT_PAGE", {
				currentPage: response.data.meta.pagination.current_page,
			});

			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error);
		} finally {
			commit("SET_LOADING", {
				loading: false,
			});
		}
	},

	async setCurrentPage({ commit, dispatch }, payload) {
		commit("SET_CURRENT_PAGE", {
			currentPage: payload.newPage,
		});
		await dispatch("getFilteredSectionsData");
	},

	async getCourses({ commit }) {
		try {
			const response = await CoursesAPI.list();
			commit("SET_COURSE", {
				courses: response.data.data,
			});
		} catch (error) {
			return Promise.reject(error);
		}
	},
	async getSchools({ commit }, payload) {
		try {
			const schools = await SchoolsAPI.list(payload);
			commit("SET_SCHOOLS", {
				schools: schools.data.data,
			});

			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response);
		}
	},

	async getDistricts({ commit }) {
		try {
			const districts = await DistrictsAPI.list();
			commit("SET_DISTRICTS", {
				district: districts.data.data,
			});

			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response);
		}
	},

	async getTopics({ commit }) {
		try {
			const response = await TopicsAPI.list();
			const topics = response.data.data;
			topics.unshift({
				id: 0,
				name: "No topics",
			});

			commit("SET_TOPICS", {
				topics,
			});
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response);
		}
	},
};

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations,
	getters,
	actions,
};
