var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "BreaksNotification",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "bio-break-activity-on-hold-notification",
              expression: "'bio-break-activity-on-hold-notification'",
            },
          ],
          staticClass: "tw-mb-4",
          attrs: { "notification-type": "warning" },
        },
        [
          _c("p", { staticClass: "tw-font-bold tw-text-sm" }, [
            _vm._v("\n\t\t\tYou have an active classroom\n\t\t"),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "tw-text-sm" }, [
            _vm._v(
              "\n\t\t\tNotify your students with the following message and then click Go on Break within 90s.\n\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("p", { staticClass: "tw-text-sm" }, [
            _vm._v(
              "\n\t\t\tI need to step away from the computer, but I’ll be back in just a few minutes!\n\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("p", { staticClass: "tw-text-sm" }, [
            _vm._v(
              "\n\t\t\tYou will not receive any new students while on break.\n\t\t"
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "OrigamiButton",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "bio-break-activity-on-hold-cancel-button",
              expression: "'bio-break-activity-on-hold-cancel-button'",
            },
          ],
          staticClass: "tw-mb-2 tw-w-full tw-justify-center",
          attrs: { variant: "secondary" },
          nativeOn: {
            click: function ($event) {
              return _vm.cancelBioBreak.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n\t\tCancel\n\t")]
      ),
      _vm._v(" "),
      _c(
        "OrigamiButton",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "bio-break-activity-on-hold-ok-button",
              expression: "'bio-break-activity-on-hold-ok-button'",
            },
          ],
          staticClass: "tw-mb-2 tw-w-full tw-justify-center",
          attrs: {
            variant: "primary",
            "is-loading":
              _vm.$store.state.BreaksManagementWidget.isRequestLoading,
          },
          nativeOn: {
            click: function ($event) {
              return _vm.startBioBreak.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n\t\tGo on Break!\n\t")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }