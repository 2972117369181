<template>
	<div class="col-md-12 section__divider tw-p-0 tw-pt-6">
		<h3 class="tw-font-extrabold tw-text-black-high tw-text-2xl tw-mb-6">
			Payroll Details
		</h3>
		<form
			v-jest="'submit-payroll-form'"
			@submit.prevent="submitPayrollDetails"
		>
			<div class="payrollDetails__inputContainer tw-flex tw-flex-col tw-items-end tw-w-full">
				<BaseFormField
					id="adp_number"
					v-model="adpNumber"
					v-jest="'adp-input'"
					class="tw-w-full"
					type="text"
					label="ADP File ID"
					placeholder="Enter the ADP File ID"
				/>
			</div>
			<div class="payrollDetails__inputContainer tw-flex tw-flex-col tw-items-end tw-w-full">
				<BaseDropdown
					ref="employmentLocationDropdown"
					class="form-group tw-w-full"
					:items="countries"
					label="Employment Country"
					placeholder="Select an Employment Country"
				>
					<template slot="filters">
						{{ getCountryName(employmentLocation) }}
					</template>
					<template #default="{ item }">
						<BaseRadio
							v-model="employmentLocation"
							radio-name="employmentLocation"
							:radio-value="item.value"
							:radio-id="`${item.value}`"
							@click.native="selectCountry()"
						>
							<template slot="text">
								{{ item.text }}
							</template>
						</BaseRadio>
					</template>
				</BaseDropdown>
			</div>
			<div class="payrollDetails__inputContainer tw-flex tw-flex-col tw-items-end tw-w-full">
				<BaseDropdown
					ref="employmentRegionDropdown"
					class="form-group tw-w-full"
					:items="regions"
					label="Employment Region"
					default="Select an Employment Region"
				>
					<template slot="filters">
						{{ getRegionName(employmentRegion) }}
					</template>
					<template #default="{ item }">
						<BaseRadio
							v-model="employmentRegion"
							radio-name="employmentRegion"
							:radio-value="item.value"
							:radio-id="`${item.value}`"
							@click.native="closeOnSelect('employmentRegionDropdown')"
						>
							<template slot="text">
								{{ item.text }}
							</template>
						</BaseRadio>
					</template>
				</BaseDropdown>
			</div>
			<div class="payrollDetails__inputContainer tw-flex tw-mb-7 tw-items-center">
				<p
					class="tw-text-sm tw-leading-6 tw-pr-9"
				>
					Hourly Paid
				</p>
				<div class="tw-flex tw-gap-x-9">
					<BaseRadio
						radio-id="isHourly-yes"
						radio-name="isHourly-yes"
						:radio-value="true"
						:value="isHourly"
						@click.native="
							setIsHourly(true)
						"
					>
						<template slot="text">
							Yes
						</template>
					</BaseRadio>
					<BaseRadio
						radio-id="isHourly-no"
						radio-name="isHourly-no"
						:radio-value="false"
						:value="isHourly"
						@click.native="
							setIsHourly(false)
						"
					>
						<template slot="text">
							No
						</template>
					</BaseRadio>
				</div>
			</div>
			<div class="form-group tw-overflow-auto">
				<BaseButton
					v-jest="'submit-payroll-button'"
					type="SUBMIT"
					class="pull-right tw-w-48"
					:disabled="!isSubmitButtonEnabled"
				>
					<template
						slot="text"
					>
						<LoadingRectangles
							v-if="isSubmitting"
							class="tw-text-blue-regular"
						/>
						<span
							v-else
						>
							Submit Payroll Details
						</span>
					</template>
				</BaseButton>
			</div>
		</form>
		<div class="form-group tw-w-full">
			<div
				v-if="isShowingUpdateSuccess"
				v-jest="'update-success-banner'"
				class="alert alert-success"
			>
				Payroll details were updated successfully!
			</div>
			<div
				v-if="isShowingUpdateError"
				v-jest="'update-error-banner'"
				class="alert alert-danger"
			>
				There was a problem updating payroll details. Please try again.
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { isNil } from "lodash";

// mixins
import LoadingRectangles from "@/components/LoadingRectangles.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseFormField from "@/components/BaseFormField.vue";
import BaseRadio from "@/components/BaseRadio.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";

import ServiceTutorDropdownMixins from "../mixins/ServiceTutorDropdownMixins.js";
// components

export default {
	components: {
		LoadingRectangles,
		BaseButton,
		BaseFormField,
		BaseRadio,
		BaseDropdown,
	},
	mixins: [ServiceTutorDropdownMixins],
	data() {
		return {
			isShowingUpdateSuccess: false,
			isShowingUpdateError: false,
			isHourly: false,
			adpNumber: "",
			employmentLocation: "",
			employmentRegion: "",
			payrollDetailsOnLoad: {},
			isSubmitting: false,
		};
	},
	computed: {
		...mapState(["PlatformManager"]),
		/**
		 * @returns {Array}
		 */
		regions() {
			return {
				US: this.states,
				CA: this.provinces,
			}[this.employmentLocation];
		},
		isDetailsEmpty() {
			return (this.adpNumber === "") ||
					isNil(this.adpNumber) ||
					isNil(this.employmentLocation) ||
					isNil(this.employmentRegion);
		},
		isAnythingChanged() {
			return Object.keys(this.payrollDetailsOnLoad).some((key) => this.payrollDetailsOnLoad[key] !== this[key]);
		},
		isSubmitButtonEnabled() {
			const isEnabled = !this.isDetailsEmpty && this.isAnythingChanged && !this.isSubmitting;
			this.$emit("on-payroll-submit", { isEnabled });
			return isEnabled;
		},
	},
	async mounted() {
		this.isHourly = this.PlatformManager.TutorsEdit.tutor?.tutor.data.is_hourly;
		this.adpNumber = this.PlatformManager.TutorsEdit.tutor?.tutor.data.adp_number;
		this.employmentLocation = this.PlatformManager.TutorsEdit.tutor?.tutor.data.employment_country_code;
		this.employmentRegion = this.PlatformManager.TutorsEdit.tutor?.tutor.data.employment_region_code;
		this.payrollDetailsOnLoad = this.setPayrollDetailsOnLoad();
	},
	methods: {
		setPayrollDetailsOnLoad() {
			return {
				isHourly: this.isHourly,
				adpNumber: this.adpNumber,
				employmentLocation: this.employmentLocation,
				employmentRegion: this.employmentRegion,
			};
		},
		selectCountry() {
			this.employmentRegion = null;
			this.closeOnSelect("employmentLocationDropdown");
		},
		/**
		 *
		 * @param {String} refName
		 */
		closeOnSelect(refName) {
			this.$refs[refName].showDropdown = false;
		},
		setIsHourly(value) {
			this.isHourly = value;
		},
		async submitPayrollDetails() {
			this.isSubmitting = true;
			this.isShowingUpdateSuccess = false;
			this.isShowingUpdateError = false;
			try {
				const payload = {
					adp_number: this.adpNumber,
					employment_country_code: this.employmentLocation,
					employment_region_code: this.employmentRegion,
					is_hourly: this.isHourly,
				};
				await this.$store.dispatch("PlatformManager/TutorsEdit/updateTutor", payload);
				this.isShowingUpdateSuccess = true;
				this.payrollDetailsOnLoad = this.setPayrollDetailsOnLoad();
			} catch (e) {
				Sentry.captureException(e);
				this.isShowingUpdateError = true;
			} finally {
				this.isSubmitting = false;
			}
		},
	},
};
</script>

<style scoped>
.section__divider {
	border-top: 1px solid var(--black-high);
}
</style>

<style>
.payrollDetails__inputContainer > .form-group {
	margin-bottom: 1.5rem !important;
}
</style>
