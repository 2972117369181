import rootInstance from "@/services/root.js";

const baseUrl = process.env.MATH_PROBLEM_API_URL + "/api/v1/";

const mathApi = () => {
	rootInstance.defaults.baseURL = baseUrl;
	return rootInstance;
};

export default {
	getAllSubjects() {
		return mathApi().get("subject").then(({ data }) => data);
	},
	getAllTopicsBySubject(subjectID) {
		return mathApi().get(`subject/${subjectID}/completion-status`).then(({ data }) => data.data);
	},
	getProgressBySubject() {
		return mathApi().get("subject/student-completion").then(({ data }) => data.data);
	},
	getLastTopic() {
		return mathApi().get("topic/last_used").then(({ data }) => data.data);
	},
	getTopicByID(topicID) {
		return mathApi().get(`/topic/${topicID}`).then(({ data }) => data);
	},
};
