var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tw-flex tw-py-0 tw-justify-between" },
    [
      _c(
        "div",
        { staticClass: "tw-flex tw-pt-8 tw-pb-0 tw-px-0" },
        [
          _c("BaseTutorImage", {
            staticClass: "tw-w-min",
            attrs: {
              "profile-path": _vm.selectedTutor.profile_picture,
              "full-tutor-name": _vm.selectedTutor.name,
              dimensions: 56,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tw-flex tw-flex-col tw-pl-4 tw-justify-center" },
            [
              _c(
                "h3",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "tutor-name-span",
                      expression: "'tutor-name-span'",
                    },
                  ],
                  staticClass: "tw-font-bold tw-text-lg",
                },
                [_vm._v("\n\t\t\t\t" + _vm._s(_vm.tutorName) + "\n\t\t\t")]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "current-shift-details",
                      expression: "'current-shift-details'",
                    },
                  ],
                  staticClass: "tw-text-xs",
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.formattedCurrentShift) +
                      "\n\t\t\t"
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isCurrentShiftDeleted
        ? _c("ShiftSegmentScheduleTypeTag", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "schedule-type-tag",
                expression: "'schedule-type-tag'",
              },
            ],
            staticClass: "tw-mt-10",
            attrs: { "shift-type": _vm.currentShiftScheduleTypeId },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }