import api from "@/services/api/Api.js";

export default {
	/**
	 * Create course
	 * @param {object} data
	 * @returns {AxiosPromise}
	 */
	create(data) {
		return api().post(`/courses`, data);
	},

	/**
	 * Get specific course info
	 * @param {number} courseId
	 * @param {object} params
	 * @param {?String} params.include
	 * @returns {AxiosPromise}
	 */
	show(courseId, params) {
		return api().get(`/courses/${courseId}`, {
			params: params,
		});
	},
	/**
	 * Get all courses and their corresponding info
	 * @param {object} params
	 * @param {?String} params.include
	 * @returns {AxiosPromise}
	 */
	list(params) {
		return api().get(`courses`, { params });
	},
	/**
	 * Update specified course
	 * @param {Object} data
	 */
	update(courseId, data) {
		return api().post(`/courses/${courseId}`, data);
	},
};
