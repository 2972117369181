export default {
	methods: {
		/**
		 * Returns the navbartabs for the specified page category type
		 * @param {String} type
		 */
		getSubNavbarTabs(type) {
			switch (type) {
				case "tutor":
					return this.getTutorTabs();
				case "customerDashboard":
					return this.getCustomerDashboardTabs();
				default:
					return [];
			}
		},

		getTutorTabs() {
			const getTutorTabsForDashboard = [
				{
					name: "Summary",
					url: `/${this.currentUser.role}/services/summary`,
				},
				{
					name: "Tutors",
					url: `/${this.currentUser.role}/services/tutors`,
				},
				{
					name: "Review Center",
					url: `/${this.currentUser.role}/services/essays-queue`,
				},
				{
					name: "Breaks Center",
					url: `/${this.currentUser.role}/services/breaks-center`,
				},
				{
					name: "Schedule",
					url: `/${this.currentUser.role}/services/schedule`,
				},
				{
					name: "Action Center",
					url: `/${this.currentUser.role}/services/action-center`,
				},
				{
					name: "Analytics",
					url: `/${this.currentUser.role}/services/analytics`,
				},
			];

			return getTutorTabsForDashboard;
		},

		getCustomerDashboardTabs() {
			return [
				{
					name: "Users",
					url: "/superuser/customers/users",
				},
				{
					name: "Sections",
					url: "/superuser/customers/sections",
				},
				{
					name: "Courses",
					url: "/superuser/customers/courses",
				},
				{
					name: "Schools",
					url: "/superuser/customers/schools",
				},
				{
					name: "Districts",
					url: "/superuser/customers/districts",
				},
				{
					name: "Teacher Content",
					url: "/superuser/customers/teacher-content",
				},
			];
		},
	},
};
