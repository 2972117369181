var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tutorComment" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "header__name" }, [
        _vm._v("\n\t\t\t" + _vm._s(_vm.name) + "\n\t\t"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "header__date" }, [
        _vm._v("\n\t\t\t" + _vm._s(_vm.date) + "\n\t\t"),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "comment" }, [
      _vm._v("\n\t\t" + _vm._s(_vm.comment) + "\n\t"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }