<template>
	<BModal
		:id="id"
		ref="simple-modal"
		v-model="isSimpleModalShowing"
		:hide-header="true"
		:no-close-on-backdrop="true"
		:no-fade="true"
		:centered="true"
		aria-labelledby="modal-header-title"
		aria-describedby="modal-body-message"
	>
		<!-- Modal -->
		<button
			v-data-cy="'btn-close-simpleModal'"
			type="button"
			class="close"
			@click="hideModal()"
		>
			&times;
		</button>

		<div class="modalHeader">
			<h1
				id="modal-header-title"
				v-data-cy="'modal-header-title'"
				class="modalHeader__title"
			>
				{{ title }}
			</h1>
		</div>

		<!-- Modal Body/Content -->

		<div class="modalBody">
			<div
				id="modal-body-message"
				v-data-cy="'modal-body-message'"
				class="modalBody__message"
			>
				{{ message }}
			</div>
		</div>

		<template slot="modal-footer">
			<BaseButton
				v-data-cy="'modal-footer-button'"
				:type="SimpleModal.buttonType"
				@click.prevent.native="onSubmit()"
			>
				<template slot="text">
					{{ buttonText }}
				</template>
			</BaseButton>
		</template>
	</BModal>
</template>

<script>
import { mapState } from "vuex";
import { isEmpty } from "lodash";

import BaseButton from "@/components/BaseButton.vue";

export default {
	components: {
		BaseButton,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
	},

	data() {
		return {
			isSimpleModalShowing: false,
		};
	},
	computed: {
		...mapState(["SimpleModal"]),
		title() {
			return isEmpty(this.SimpleModal.translationVars)
				? this.$t(this.SimpleModal.title)
				: this.$t(this.SimpleModal.title, this.SimpleModal.translationVars);
		},
		message() {
			return isEmpty(this.SimpleModal.translationVars)
				? this.$t(this.SimpleModal.message)
				: this.$t(this.SimpleModal.message, this.SimpleModal.translationVars);
		},
		buttonText() {
			return isEmpty(this.SimpleModal.translationVars)
				? this.$t(this.SimpleModal.buttonText)
				: this.$t(this.SimpleModal.buttonText, this.SimpleModal.translationVars);
		},
	},
	watch: {
		isSimpleModalShowing() {},
	},

	methods: {
		hideModal() {
			this.$refs["simple-modal"].hide();
		},
		onSubmit() {
			if (this.SimpleModal.eventFired !== null) {
				this.SimpleModal.eventFired();
			}
			this.hideModal();
		},
	},
};
</script>

<style scoped>
.modalHeader {
	padding: 28px 20px 10px 20px;
	border: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.modalHeader__image-container {
	width: 44px;
	height: 44px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modalHeader__title {
	color: var(--c-blue);
	font-size: 20px;
	font-weight: 300;
	max-width: 444px;
}

.modalBody__message {
	white-space: pre-line;
}

.modalBody {
	padding: 25px;
}

.close {
	align-self: flex-end;
}

.button {
	margin-left: 10px;
}
</style>

<style>
.modal-footer {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	background: var(--c-light-gray);
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	padding: 15px 48px;
}
.modal-content {
	border-radius: 10px;
}

@media (min-width: 992px) {
	.modal .modal-md {
		max-width: 500px !important;
		width: 45% !important;
	}
}
</style>
