import { captureException } from "@paper-co/web-toolkit";

import { getStudentStatus } from "../services/index.js";

export const getDefaultState = () => ({
	isNew: false,
});
const state = getDefaultState();

export const mutations = {
	SET_IS_NEW(state, payload) {
		state.isNew = payload.isNew;
	},
};

export const actions = {
	async getStudentStatus({ commit }) {
		try {
			const { is_new: isNew } = (await getStudentStatus()).data.data;
			commit("SET_IS_NEW", { isNew });
		} catch (error) {
			captureException(error);
		}
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
