<template>
	<nav class="navbar tw-bg-blue-darker">
		<div
			class="tw-max-w-screen-xxl tw-w-full tw-h-full tw-flex tw-items-center tw-justify-between tw-text-sm tw-px-6 sm:tw-px-0 tw-mx-auto"
		>
			<a href="/">
				<img
					src="/images/logo-paper.svg"
					width="171"
					height="48"
					alt="PAPER"
				>
			</a>

			<NavbarUserLinks
				v-jest="'user-links'"
				class="tw-hidden xl:tw-flex"
				:user-links="userLinks.filter(link => link.title !== 'navbar.resourceHub')"
			/>

			<template v-if="currentUser?.role === 'teacher'">
				<ResourceHubDropdown
					v-if="featureFlagPaperAcademyLink"
					v-jest="'resource-dropdown'"
				/>
				<a
					v-else
					v-jest="'resource-hub-link'"
					href="https://sites.google.com/paper.co/resourcehub"
					target="_blank"
					class="tw-font-bold tw-text-origami-white tw-hidden xl:tw-inline-block"
				>
					{{ $t("navbar.resourceHub") }}
					<OrigamiIcon name="external" />
				</a>
			</template>

			<div class="tw-ml-auto tw-hidden md:tw-block tw-text-origami-white">
				<slot name="appendAfterNavLinks" />
			</div>

			<div
				v-data-cy="'navbar-dropdown-mobile'"
				class="tw-relative tw-items-center tw-hidden xl:tw-flex tw-z-10 tw-pr-4 tw-border-r tw-border-origami-grey-200 tw-mr-4"
			>
				<LanguagePicker
					v-if="isUserStudent"
					v-jest="'language-picker'"
					@select-language="(lang) => $store.dispatch('Lang/changeLanguage', lang)"
				/>
			</div>

			<div
				v-data-cy="'navbar-dropdown-desktop'"
				class="tw-relative tw-h-full tw-items-center tw-hidden xl:tw-flex tw-z-10 tw-text-origami-white"
			>
				<button
					id="navDropdownBtn"
					v-jest="'dropdown-button'"
					v-click-outside="handleClickOutside"
					aria-controls="navDropdownItemContainer"
					:aria-expanded="isDropdownOpen.toString()"
					data-pendo-id="nav-dropdown"
					class="navDropdownBtn tw-font-bold tw-flex tw-items-center tw-cursor-pointer"
					@keydown.enter.prevent="isDropdownOpen = !isDropdownOpen"
					@keydown.esc="isDropdownOpen = false"
					@keydown.down.prevent="focusLink(0)"
					@keydown.shift.tab="isDropdownOpen = false"
					@click="isDropdownOpen = !isDropdownOpen"
				>
					<AvatarIcon
						v-if="isUserStudent"
						v-jest="'avatar-icon'"
						:user="userData"
						class="tw-mr-2"
					/>
					<span
						v-jest="'full-name'"
						class="tw-mr-3.5"
					>{{ fullName }}</span>
					<OrigamiIcon name="caret" />
				</button>
				<transition name="fade">
					<ul
						v-show="isDropdownOpen"
						id="navDropdownItemContainer"
						v-jest="'dropdown'"
						class="tw-absolute tw-top-full tw-right-0 tw-text-black-high tw-shadow-elevation-1 tw-bg-white-high tw-rounded-lg"
					>
						<li
							v-for="(link, index) in dropdownLinks"
							:key="`link-${index}`"
						>
							<BaseLink
								:key="link.target"
								v-jest="'dropdown-link'"
								v-data-cy="`navbar-dropdown-${link.target}`"
								:to="link.target"
								class="navItemLink tw-flex tw-items-center tw-py-3 tw-px-4 tw-hover:tw-bg-blue-pastel tw-whitespace-nowrap tw-gap-4"
								:class="{
									'tw-text-base': featureFlagPaperAcademyLink,
									'tw-border-b tw-border-origami-grey-100': index < dropdownLinks.length - 1 && !featureFlagPaperAcademyLink,
								}"
								@click.native="isDropdownOpen = false"
								@keydown.native.down.prevent="focusLink(index + 1)"
								@keydown.native.up.prevent="focusLink(index - 1)"
								@keydown.native.esc="focusDropdown()"
								@keydown.native.tab.exact="handleTab(index)"
								@keydown.native.shift.tab.stop
							>
								<OrigamiIcon
									v-if="(isUserStudent || featureFlagPaperAcademyLink) && getNavIcon(link.title)"
									v-jest="'dropdown-icon'"
									:name="getNavIcon(link.title)"
								/>
								{{ $t(link.title) }}
							</BaseLink>
						</li>
					</ul>
				</transition>
			</div>

			<MobileMenu
				v-jest="'mobile-menu'"
				class="xl:tw-hidden"
				:menu-items="mobileMenu"
			/>
		</div>
		<slot name="appendToEnd" />
	</nav>
</template>

<script>
import { OrigamiIcon } from "@origami/vue2";
import { mapState } from "vuex";

import { LanguagePicker } from "@/modules/Languages/index.js";
import AvatarIcon from "@/modules/Avatar/components/AvatarIcon.vue";

import MobileMenu from "./MobileMenu.vue";
import NavbarUserLinks from "./NavbarUserLinks.vue";
import ResourceHubDropdown from "./ResourceHubDropdown.vue";

const NAV_ICONS = {
	"navbar.profile": "profile-outlined",
	"navbar.settings": "account-settings-outlined",
	"navbar.logout": "logout",
};

export default {
	components: {
		LanguagePicker,
		AvatarIcon,
		OrigamiIcon,
		MobileMenu,
		NavbarUserLinks,
		ResourceHubDropdown,
	},
	props: {
		userLinks: {
			type: Array,
			default: () => [],
		},
		viewAsLinks: {
			type: Array,
			default: () => [],
		},
		normalLinks: {
			type: Array,
			default: () => [],
		},
		languages: {
			type: Array,
			default: () => [],
		},
	},
	data: () => ({
		isDropdownOpen: false,
		featureFlagGASpikeIsEnabled: false,
		featureFlagPaperAcademyLink: false,
		isResourceHubOpen: false,
		resourcesLink: [
			{
				title: "navbar.resourceCenter",
				target: "https://sites.google.com/paper.co/resourcehub",
			},
			{
				title: "navbar.paperAcademy",
				target: "https://academy.paper.co/",
			},
		],
	}),
	computed: {
		...mapState(["currentUser", "Student"]),
		fullName() {
			if (!this.currentUser) {
				return "User";
			}
			return `${this.currentUser.firstName} ${this.currentUser.lastName}`;
		},
		mobileMenu() {
			return [
				...this.userLinks,
				...this.viewAsLinks,
				...this.normalLinks,
			];
		},
		dropdownLinks() {
			return [
				...this.viewAsLinks,
				...this.normalLinks,
			];
		},
		userData() {
			return {
				...this.currentUser,
				...this.studentProfile,
			};
		},
		studentProfile() {
			return this.Student.Profile?.profile || {};
		},
		isUserStudent() {
			return this.currentUser?.role === "student";
		},
	},
	watch: {
		isUserStudent: {
			handler: async function() {
				if (this.isUserStudent) {
					await this.$store.dispatch("Student/Profile/get");
				}
			},
			immediate: true,
		},
	},
	async mounted() {
		this.featureFlagPaperAcademyLink = await this.$getFlag("GROW-2309-paper-academy-link");
	},
	methods: {
		handleClickOutside() {
			this.isDropdownOpen = false;
		},
		focusLink(index) {
			const dropdownItemLinks = document.querySelectorAll("#navDropdownItemContainer a");
			dropdownItemLinks?.[index]?.focus();
		},
		focusDropdown() {
			this.isDropdownOpen = false;
			document.querySelector("#navDropdownBtn")?.focus();
		},
		handleTab(index) {
			if (index === this.dropdownLinks.length - 1) {
				this.isDropdownOpen = false;
			}
		},
		getNavIcon(title) {
			return NAV_ICONS[title] ?? null;
		},
	},
};
</script>

<style scoped>
.navbar {
	height: 90px;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity .3s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}

.navDropdownBtn:focus-visible {
	outline: 2px solid var(--origami-blue-200);
	border-radius: 5px;
}

.navDropdownBtn:hover {
	background: #434B8B;
	border-radius: 5px;
}

.navItemLink:focus {
	border-radius: 0.5rem;
}
</style>
