import rootInstance from "@/services/root.js";

import { cacheApiResponse } from "./apiHelpers.js";

const apiInstance = () => {
	rootInstance.defaults.baseURL = process.env.MIX_TEACHING_ASSETS_API_URL + "/api/v1";
	return rootInstance;
};

export default {
	async getAssetsList() {
		return apiInstance().get("/assets");
	},
	getAsset(id, { refetch = false } = {}) {
		return cacheApiResponse(
			"teachingAssets",
			`getAsset/${id}`,
			() => apiInstance().get(`/assets/${id}`),
			{ refetch },
		);
	},
};
