import { FULTON_LIBRARY_SCHOOL_ID } from "../utilities/fulton.js";

export default function(to, from, next) {
	const currentUser = this.state.currentUser;
	const isProfileCompleted = currentUser.password_changed;
	if (currentUser.school_id === FULTON_LIBRARY_SCHOOL_ID && (!currentUser.email || !isProfileCompleted)) {
		next();
	} else {
		next("/");
	}
}
