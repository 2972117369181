import PreferencesApi from "../services/UserPreferencesApi.js";

const getDefaultState = () => ({
	isBreakChimesEnabled: false,
	isError: false,
	isSuccess: false,
});

const state = getDefaultState();

const mutations = {
	SET_USER_PREFERENCES(state, payload) {
		state.isBreakChimesEnabled = payload.is_break_chimes_enabled;
	},
	SET_IS_ERROR(state, payload) {
		state.isError = payload;
	},
	SET_IS_SUCCESS(state, payload) {
		state.isSuccess = payload;
	},
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
};

export const actions = {
	async getUserPreferences({ commit, rootState }) {
		try {
			const response = await PreferencesApi.get(rootState.currentUser.id);
			commit("SET_USER_PREFERENCES", response.data.data);
		} catch (error) {
			Sentry.captureException(error);
			commit("SET_IS_ERROR", true);
		}
	},
	async updateUserPreferences({ dispatch, rootState, commit }, payload) {
		try {
			await PreferencesApi.update(rootState.currentUser.id, payload);
			dispatch("getUserPreferences");
			commit("SET_IS_SUCCESS", true);
		} catch (error) {
			Sentry.captureException(error);
			commit("SET_IS_ERROR", true);
		}
	},
	async resetState({ commit }) {
		commit("RESET_STATE");
	},
};

export default { namespaced: true, state, mutations, actions };
