export const TUTORING_SESSION_ID = 1;
export const WRITING_REVIEW_ID = 2;
export const TUTOR_MANAGER_ID = 3;
export const TRAINING_ID = 4;
export const ONBOARDING_ID = 5;
export const ASSESSMENT_ID = 6;
export const MEETING_ID = 7;
export const TEST_SESSION_ID = 8;
export const SPECIAL_PROJECT_ID = 9;
export const PAY_ADJUSTMENT_ID = 10;
export const REST_BREAK_ID = 11;
export const MEAL_BREAK_ID = 12;

export const SCHEDULED_BREAK_TYPES = [REST_BREAK_ID, MEAL_BREAK_ID];
