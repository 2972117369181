<template>
	<TutorBreakCard
		:break="currentBreak"
	>
		<template #metaDataIcon>
			<component
				:is="getscheduleTypeId() ? 'IconSession' : 'IconEssay'"
				icon-color="currentColor"
				class="tw-mr-2 tw-align-middle tw-text-black-high"
			/>
		</template>
		<template #metadata>
			{{ getscheduleTypeId() ? "Live Help" : "Writing Review" }}
		</template>
		<template #breakDetailsIcon>
			<OrigamiIcon :name="detailsIcon" />
		</template>
		<template #breakDetails>
			Back at {{ formatShiftTime() }}
		</template>
	</TutorBreakCard>
</template>

<script>
import { OrigamiIcon } from "@origami/vue2";

import IconSession from "../../../components/icons/IconSession.vue";
import IconEssay from "../../../components/icons/IconEssay.vue";
import { formatTimestampToHM } from "../../../utilities/dateHelpers.js";
import { SHIFT_TYPE_ID } from "../utilities/ShiftTypeIDs.js";

import TutorBreakCard from "./TutorBreakCard.vue";

export default {
	components: {
		TutorBreakCard,
		IconSession,
		IconEssay,
		OrigamiIcon,
	},
	props: {
		currentBreak: {
			type: Object,
			required: true,
		},
		detailsIcon: {
			type: String,
			default: "break",
		},
	},
	methods: {
		getscheduleTypeId() {
			return this.currentBreak.scheduleType.data.id === SHIFT_TYPE_ID.CLASSROOM;
		},
		formatShiftTime() {
			return formatTimestampToHM(this.currentBreak.break.end_time);
		},
	},
};
</script>
