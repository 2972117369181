import SubjectApi from "@/services/api/Subjects.js";
import { UsersAPI } from "@/modules/Customers/index.js";

import ScheduleAPI from "../services/ScheduleAPI.js";

const getDefaultState = () => {
	return {
		isFiltersSidebarOpen: false,
		subjects: [],
		subjectLeaderboard: {},
		selectedFilters: {},
		unsubmittedFilters: {},
		lastUpdateTime: new Date(),
		filteredSubjects: [],
		riskLevels: {},
		isLoading: true,
	};
};

const mutations = {
	SET_SIDEBAR(state, payload) {
		state.isFiltersSidebarOpen = payload;
	},
	SET_SUBJECTS(state, payload) {
		state.subjects = payload.subjects;
	},
	SET_SUBJECT_LEADERBOARD(state, payload) {
		const subjectLeaderboardCopy = { ...state.subjectLeaderboard };
		subjectLeaderboardCopy[payload.subject] = payload.leaderboard;
		state.subjectLeaderboard = subjectLeaderboardCopy;
	},
	SET_FILTERED_SUBJECTS(state, payload) {
		state.filteredSubjects = payload.filteredSubjects;
	},
	SET_LAST_UPDATE_TIME(state, payload) {
		state.lastUpdateTime = payload.lastUpdateTime;
	},
	SET_RISK_LEVELS(state, payload) {
		state.riskLevels = payload.riskLevels;
	},
	ADD_SELECTED_FILTER(state, payload) {
		state.selectedFilters[payload.name] = payload.selectedFilters;
	},
	ADD_UNSUBMITTED_FILTER(state, payload) {
		state.unsubmittedFilters[payload.name] = payload.unsubmittedFilters;
	},
	ADD_UNSUBMITTED_FILTERS_TO_SELECTED_FILTERS(state) {
		const newObject = { ...state.selectedFilters, ...state.unsubmittedFilters };
		state.selectedFilters = newObject;
	},
	CLEAR_SELECTED_FILTERS(state) {
		state.selectedFilters = {};
	},
	CLEAR_UNSUBMITTED_FILTERS(state) {
		state.unsubmittedFilters = {};
	},
	CLEAR_SELECTED_FILTERS_GROUP(state, payload) {
		const selectedFiltersCopy = { ...state.selectedFilters };
		delete selectedFiltersCopy[payload];
		state.selectedFilters = selectedFiltersCopy;
	},
	SET_LOADING_STATE(state, payload) {
		state.isLoading = payload;
	},
};

export const actions = {
	openFiltersSidebar({ commit }) {
		commit("SET_SIDEBAR", true);
	},
	closeFiltersSidebar({ commit }) {
		commit("SET_SIDEBAR", false);
	},
	async getSubjects({ commit }, payload) {
		try {
			const subjects = await SubjectApi.list(payload);
			commit("SET_SUBJECTS", {
				subjects: subjects.data.data,
			});
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	addSelectedFilter({ commit }, payload) {
		commit("ADD_SELECTED_FILTER", payload);
	},
	addUnsubmittedFilter({ commit }, payload) {
		commit("ADD_UNSUBMITTED_FILTER", payload);
	},
	submitFilters({ commit }) {
		commit("ADD_UNSUBMITTED_FILTERS_TO_SELECTED_FILTERS");
		commit("CLEAR_UNSUBMITTED_FILTERS");
		commit("SET_SIDEBAR", false);
	},
	clearSelectedFilters({ commit }) {
		commit("CLEAR_SELECTED_FILTERS");
		commit("CLEAR_UNSUBMITTED_FILTERS");
	},
	clearUnsubmittedFilters({ commit }) {
		commit("CLEAR_UNSUBMITTED_FILTERS");
	},
	clearSelectedFiltersGroup({ commit }, payload) {
		commit("CLEAR_SELECTED_FILTERS_GROUP", payload);
	},
	async getRiskLevels({ commit }) {
		try {
			const response = await ScheduleAPI.getRiskLevels();
			commit("SET_RISK_LEVELS", {
				riskLevels: response.data,
			});
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	async getFilteredResults({ commit, state }, payload) {
		try {
			const { Subjects, Requirements } = state.selectedFilters;

			const params = {
				subjects: Subjects?.map(({ id }) => id),
				special_requirements: Requirements?.map(({ id }) => id),
				...payload,
			};

			const response = await ScheduleAPI.getTutorLoad({ params });
			commit("SET_LAST_UPDATE_TIME", {
				lastUpdateTime: response.data.last_update,
			});
			commit("SET_FILTERED_SUBJECTS", {
				filteredSubjects: response.data.data,
			});
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	async getTutorLoadLeaderboard({ commit, state }, payload) {
		try {
			const { Requirements } = state.selectedFilters;
			const params = {
				limit: 6,
				sort: payload.sort,
				special_requirements: Requirements?.map(({ id }) => id),
			};
			const response = await ScheduleAPI.getTutorLoadBySubject(payload.subjectId, { params });
			const tutorIDList = response.data;
			const tutorList = await Promise.all(tutorIDList.map(async(tutor) => {
				const response = await UsersAPI.show(tutor.user_id);
				return {
					id: tutor.user_id,
					sessions: tutor.total_sessions_load,
					name: `${response.data.data.first_name} ${response.data.data.last_name}`,
				};
			}));
			commit("SET_SUBJECT_LEADERBOARD", {
				subject: payload.subject,
				leaderboard: tutorList,
			});
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	setLoadingState({ commit }, payload) {
		commit("SET_LOADING_STATE", payload);
	},
	async refreshResults({ dispatch }) {
		try {
			dispatch("setLoadingState", true);
			await dispatch("getFilteredResults");
		} finally {
			dispatch("setLoadingState", false);
		}
	},
};

export const getters = {
	getHighRiskSubjects(state) {
		return state.filteredSubjects.filter((subject) => subject.score >= state.riskLevels.high);
	},
	getModerateRiskSubjects(state) {
		return state.filteredSubjects.filter((subject) =>
			subject.score >= state.riskLevels.moderate && subject.score < state.riskLevels.high);
	},
	getLowRiskSujects(state) {
		return state.filteredSubjects.filter((subject) => subject.score < state.riskLevels.moderate);
	},
};

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations,
	actions,
	getters,
};
