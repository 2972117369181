var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BreaksConfirmation", {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "h3",
              {
                directives: [
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "meal-break-reques-pending-approval-header",
                    expression: "'meal-break-reques-pending-approval-header'",
                  },
                ],
              },
              [_vm._v("\n\t\t\tMeal break request\n\t\t")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "bodyIcon",
        fn: function () {
          return [
            _c("BaseLoader", {
              directives: [
                {
                  name: "data-cy",
                  rawName: "v-data-cy",
                  value: "base-loader-icon",
                  expression: "'base-loader-icon'",
                },
              ],
              staticClass: "tw-mt-0.5 tw-h-6 tw-w-6 tw-text-blue-regular",
              attrs: { thickness: "4px" },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "bodyText",
        fn: function () {
          return [
            _c(
              "p",
              {
                directives: [
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "meal-break-awaiting-approvel-text",
                    expression: "'meal-break-awaiting-approvel-text'",
                  },
                ],
              },
              [_vm._v("\n\t\t\tYour break request is awaiting approval\n\t\t")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "buttons",
        fn: function () {
          return [
            _c(
              "OrigamiButton",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "cancel-meal-break-request-button",
                    expression: "'cancel-meal-break-request-button'",
                  },
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "cancel-meal-break-request-button",
                    expression: "'cancel-meal-break-request-button'",
                  },
                ],
                staticClass: "tw-m-auto tw-mb-2 tw-w-full tw-justify-center",
                attrs: {
                  variant: "destructive",
                  "is-loading":
                    _vm.$store.state.BreaksManagementWidget.isRequestLoading,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.cancelMealBreakRequest.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\tCancel request\n\t\t")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }