var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "BreaksNotification",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "meal-break-upcoming-notification",
              expression: "'meal-break-upcoming-notification'",
            },
          ],
          staticClass: "tw-mb-4",
          attrs: { "notification-type": "info" },
        },
        [
          _c(
            "p",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "meal-break-upcoming-time",
                  expression: "'meal-break-upcoming-time'",
                },
              ],
              staticClass: "tw-font-bold tw-text-sm",
            },
            [_vm._v("\n\t\t\t" + _vm._s(_vm.timeText) + "\n\t\t")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }