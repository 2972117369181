import { merge } from "lodash";

import { generalTranslations } from "@/i18n/general.js";
import { subjectsAndTopicsSeparate } from "@/i18n/subjectsAndTopics.js";

const trans = {
	en: {
		navbar: {
			classroom: "Chat",
			dashboard: "Dashboard",
			essay_review: "Essay Review",
			inbox: "Notifications",
			pave: "",
		},
		system_message: {
			reason: {
				gotTheHelpThatINeedThanks: "I got the help that I needed, thanks!",
				haveToGoSorry: "I have to go, sorry!",
				tutorTakingTooLong: "The tutor is taking too long to reply.",
				tutorNotHelpful: "The tutor is not being helpful.",
				tutor_not_nice: "The tutor is not being nice.",
				dont_understand_explanation: "I don't understand the tutor's explanation.",
			},
			at: "at",
		},
	},
	fr: {
		navbar: {
			classroom: "Chat",
			dashboard: "Tableau de bord",
			essay_review: "Rédactions",
			inbox: "Notifications",
		},
		system_message: {
			reason: {
				gotTheHelpThatINeedThanks: "J'ai eu l'aide dont j'avais besoin, merci!",
				haveToGoSorry: "Je dois y aller, désolé!",
				tutorTakingTooLong: "Le tuteur prends trop de temps pour répondre.",
				tutorNotHelpful: "Le tuteur n'était d'aucune aide.",
				tutor_not_nice: "Le tuteur n'est pas très sympathique.",
				dont_understand_explanation: "Je ne comprends pas l'explication du tuteur.",
			},
			at: "à",
		},
	},
	es: {
		navbar: {
			classroom: "Chat",
			dashboard: "Tablero",
			essay_review: "Revisión de ensayos",
			inbox: "Notificaciones",
		},
		system_message: {
			reason: {
				gotTheHelpThatINeedThanks: "Recibí la ayuda que necesitaba, ¡gracias!",
				haveToGoSorry: "¡Lo siento, tengo que irme!",
				tutorTakingTooLong: "El tutor tarda en contestar.",
				tutorNotHelpful: "El tutor no me ayudó como esperaba.",
				tutor_not_nice: "El tutor no está siendo amable.",
				dont_understand_explanation: "No entiendo la explicación del tutor.",
			},
			at: "a",
		},
	},
};

export default merge(trans, subjectsAndTopicsSeparate, generalTranslations);
