<template>
	<div
		id="authorizeImpersonationModal"
		class="impersonationModal auth_modal"
	>
		<div
			class="modal-dialog"
			role="document"
		>
			<div class="modal-content">
				<div class="modal-header">
					<h4
						id="myModalLabel"
						class="modal-title"
					>
						Impersonate user
					</h4>
					<button
						type="button"
						class="close"
						aria-label="Close"
						@click.prevent="closeModal"
					>
						<img
							class="svg"
							src="/images/icon-times.svg"
						>
					</button>
				</div>
				<div class="modal-body">
					<form
						id="authorizeImpersonationForm"
						class="tw-mb-0"
						role="form"
						method="POST"
						action="impersonate"
					>
						<div class="form-group">
							<input
								v-model="impersonationEmail"
								class="form-control"
								type="email"
								name="email"
								tabindex="1"
								placeholder="Email Address"
							>
						</div>

						<div class="form-group">
							<input
								v-model="impersonationPassword"
								class="form-control"
								type="password"
								name="password"
								tabindex="2"
								placeholder="Password"
							>
						</div>
						<input
							type="submit"
							name="submit"
							style="padding: 8px 40px;"
							value="Continue"
							class="btn btn-success btn-block tw-bg-green-regular tw-border-green-regular"
							:class="{ loadingState: loading }"
							:disabled="loading"
							tabindex="3"
							@click.prevent="submitImpersonationForm"
						>
						<div
							v-show="showErrors"
							id="authorizeImpersonationErrors"
						>
							<div class="alert alert-danger">
								{{ errorMessage }}
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { LaravelApi } from "@/services/root.js";

export default {
	props: {
		token: {
			type: String,
			required: true,
		},
		userEmail: {
			type: String,
			required: true,
		},
	},

	data: () => ({
		impersonationPassword: "",
		impersonationEmail: "",

		showErrors: false,
		errorMessage: "",
		loading: false,
	}),

	methods: {
		closeModal: function() {
			this.$emit("close-modal");
		},

		async submitImpersonationForm() {
			this.showErrors = false;
			this.loading = true;

			const impersonatedUserEmail = this.userEmail;
			const email = this.impersonationEmail;
			const password = this.impersonationPassword;
			const _token = this.token;

			try {
				await LaravelApi().post("/impersonate", {
					email: email,
					password: password,
					impersonated_user_email: impersonatedUserEmail,
					_token: _token,
				});
				window.location.replace("/");
			} catch (e) {
				Sentry.captureException(e);
				this.errorMessage = e.message;
				this.showErrors = true;
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style scoped>
#authorizeImpersonationModal.impersonationModal {
	position: fixed;
	top: 180px;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;
	-webkit-overflow-scrolling: touch;
	outline: 0;
}
#authorizeImpersonationModal .loadingState {
	opacity: 0.5;
	cursor: not-allowed;
}

.auth_modal .modal-header {
	background: var(--c-blue);
	color: var(--c-white);
	padding: 20px;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
}
.auth_modal .modal-header .modal-title {
	display: inline-block;
	line-height: 20px;
	font-size: 24px;
}
.auth_modal .modal-header button {
	opacity: 1;
}
.auth_modal .modal-body {
	padding: 0px;
}
.auth_modal .modal-body input.form-control {
	height: 46px;
	line-height: 25px;
	border: 2px solid #d7dada;
	color: var(--c-black);
}
.auth_modal .modal-body .btn {
	height: 48px;
	font-size: 16px;
}



#authorizeImpersonationModal.auth_modal .modal-dialog .modal-body {
	padding: 20px 15px;
}
#authorizeImpersonationModal #authorizeImpersonationErrors {
	margin-top: 25px;
}

@media (min-width: 769px) {
	.modal-content {
		box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
	}
}
@media (max-width: 768px) {
	.auth_modal .modal-dialog {
		min-width: auto;
		width: 80%;
	}
	.auth_modal .modal-content {
		margin-top: 0vh;
	}
	.auth_modal .modal-header .modal-title {
		font-size: 18px;
	}
}
</style>
