import api from "./Api.js";

export default {
	/**
	 * @returns {AxiosPromise}
	 */
	list() {
		return api().get("reactions");
	},
	/**
	 * @returns {AxiosPromise}
	 */
	listV1() {
		return api().get("reactions/v1");
	},
};
