import { isEmpty, sortBy } from "lodash";
import { addDays, addMinutes, subMinutes, isAfter } from "date-fns";

import { getMinutesBetweenDates } from "@/utilities/dateHelpers.js";

import ScheduleManagementApi from "../service/ScheduleManagementApi.js";
import {
	SHIFT_SEGMENT_IN_TRANSITION_ID,
	BIO_BREAK_IN_PROGRESS_ID,
	SHIFT_SEGMENT_IN_PROGRESS_ID,
	TUTOR_MIA_ID,
	TUTOR_NEW_ACTIVITY_HOLD,
	PUNCHED_IN_STATES,
	SHIFT_UPCOMING_ID,
} from "../utilities/ShiftTransitionIDs.js";
import {
	MEAL_BREAK_ID,
	REST_BREAK_ID,
	SCHEDULED_BREAK_TYPES,
	TUTOR_MANAGER_ID,
	TUTORING_SESSION_ID,
	WRITING_REVIEW_ID,
} from "../utilities/ShiftSegmentTypes.js";

const getUpcomingBreaks = (state) => {
	return state.currentShift?.shift_segments
		?.filter((segment) =>
			SCHEDULED_BREAK_TYPES.includes(segment.type.id) &&
			isAfter(new Date(segment.start_time), new Date()),
		) || [];
};

const getUpcomingBreak = (breakTypeId, state) => {
	return getUpcomingBreaks(state)
		?.find((segment) =>
			segment.type.id === breakTypeId &&
			isAfter(new Date(segment.start_time), new Date()),
		) || null;
};

export const state = {
	currentDate: new Date(),
	currentShift: {},
	shiftState: {},
	shifts: [],
	cancelledBreak: null,
	isCancelledByTutor: false,
	rejectedBreakRequest: null,
	approvedBreakRequest: null,
	isLoadingShifts: false,
};

export const mutations = {
	SET_CURRENT_SHIFT(state, payload) {
		state.currentShift = payload.currentShift;
	},
	SET_SHIFT_STATE(state, payload) {
		state.shiftState = payload.shiftState;
	},
	SET_CANCELLED_BREAK(state, payload) {
		state.cancelledBreak = payload.cancelledBreak;
	},
	SET_IS_CANCELLED_BY_TUTOR(state, payload) {
		state.isCancelledByTutor = payload.isCancelledByTutor;
	},
	SET_REJECTED_BREAK_REQUEST(state, payload) {
		state.rejectedBreakRequest = payload.rejectedBreakRequest;
	},
	SET_APPROVED_BREAK_REQUEST(state, payload) {
		state.approvedBreakRequest = payload.approvedBreakRequest;
	},
	SET_SHIFTS(state, payload) {
		state.shifts = payload;
	},
	SET_CURRENT_DATE(state, payload) {
		state.currentDate = payload;
	},
};

export const actions = {
	async getCurrentShift({ commit }) {
		try {
			const response = await ScheduleManagementApi.getCurrentShift();
			if (response) {
				commit("SET_CURRENT_SHIFT", {
					currentShift: response,
				});
			}
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	async getShiftState({ commit, state }) {
		try {
			if (!isEmpty(state?.currentShift)) {
				const response = await ScheduleManagementApi.getShiftState(state.currentShift.id);
				commit("SET_SHIFT_STATE", {
					shiftState: response,
				});
			}
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	async getCurrentShiftAndState({ dispatch }) {
		await dispatch("getCurrentShift");
		await dispatch("getShiftState");
	},
	updateState({ commit }, payload) {
		commit("SET_SHIFT_STATE", {
			shiftState: payload,
		});
	},
	async getShifts({ commit, state }, { userId, startDate, endDate }) {
		state.isLoadingShifts = true;
		try {
			const response = await ScheduleManagementApi.getUserShifts(userId, startDate, endDate);
			if (response) {
				commit("SET_SHIFTS", response);
			}
		} catch (e) {
			Sentry.captureException(e);
		} finally {
			state.isLoadingShifts = false;
		}
	},
	async getCurrentUserUpcomingShifts({ dispatch, rootState }) {
		await dispatch("TutorSchedule/getShifts", {
			userId: rootState.currentUser.id,
			startDate: new Date(),
			endDate: addDays(new Date(), 1),
		}, { root: true });
	},
	async extendShift(context, shiftId) {
		try {
			return await ScheduleManagementApi.extendShift(shiftId);
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	async punchIn({ dispatch }) {
		try {
			await ScheduleManagementApi.punchIn();
			await dispatch("getCurrentShiftAndState");
		} catch (e) {
			Sentry.captureException(e);
			throw e;
		}
	},
	async punchOut({ dispatch }) {
		try {
			await ScheduleManagementApi.punchOut();
			await dispatch("getCurrentShiftAndState");
		} catch (e) {
			Sentry.captureException(e);
			throw e;
		}
	},
	async setCancelledBreak({ state, commit }, { gsApiSegmentId, isCancelledByTutor }) {
		const cancelledBreak = state.currentShift.shift_segments
			?.find((segment) => segment._metadata?.gs_api_transformer?.id === gsApiSegmentId);

		if (cancelledBreak && [REST_BREAK_ID, MEAL_BREAK_ID].includes(cancelledBreak.type.id)) {
			commit("SET_IS_CANCELLED_BY_TUTOR", {
				isCancelledByTutor,
			});
			commit("SET_CANCELLED_BREAK", {
				cancelledBreak,
			});
		}
	},
	resetCancelledBreak({ commit }) {
		commit("SET_CANCELLED_BREAK", {
			cancelledBreak: null,
		});
		commit("SET_IS_CANCELLED_BY_TUTOR", {
			isCancelledByTutor: false,
		});
	},
	setRejectedBreakRequest({ commit }, payload) {
		commit("SET_REJECTED_BREAK_REQUEST", {
			rejectedBreakRequest: payload,
		});
	},
	resetRejectedBreakRequest({ commit }) {
		commit("SET_REJECTED_BREAK_REQUEST", {
			rejectedBreakRequest: null,
		});
	},
	setApprovedBreakRequest({ commit }, payload) {
		commit("SET_APPROVED_BREAK_REQUEST", {
			approvedBreakRequest: payload,
		});
	},
	resetApprovedBreakRequest({ commit }) {
		commit("SET_APPROVED_BREAK_REQUEST", {
			approvedBreakRequest: null,
		});
	},
	setCurrentDate({ commit }, payload) {
		commit("SET_CURRENT_DATE", payload);
	},
};

export const getters = {
	getCurrentShiftModifier(state) {
		return state.currentShift.shift_segments
			.flatMap((segment) => segment.modifiers)
			.find((modifier) => modifier.id === state.shiftState.modifier_id) || {};
	},
	getCurrentShiftSegment(state, getters) {
		if (!isEmpty(state.currentShift)) {
			return state.currentShift.shift_segments
				?.find((segment) => segment.id === state.shiftState.segment_id) || {};
		}

		return getters.getUpcomingShift.shift_segments?.[0];
	},
	shiftSegmentIsInTransition(state) {
		return state.shiftState.state_id === SHIFT_SEGMENT_IN_TRANSITION_ID;
	},
	hasActivityOnHold(state) {
		return state.shiftState.state_id === TUTOR_NEW_ACTIVITY_HOLD;
	},
	isOnBioBreak(state) {
		return state.shiftState.state_id === BIO_BREAK_IN_PROGRESS_ID;
	},
	isOnMealBreak(state, getters) {
		return getters.getCurrentShiftSegment?.type?.id === MEAL_BREAK_ID;
	},
	isOnRestBreak(state, getters) {
		return getters.getCurrentShiftSegment?.type?.id === REST_BREAK_ID;
	},
	isMIA(state) {
		return state.shiftState.state_id === TUTOR_MIA_ID;
	},
	isOnBioBreakOrMIA(state, getters) {
		return getters.isOnBioBreak || getters.isMIA;
	},
	isOnNewActivityHold(state) {
		return state.shiftState.state_id === TUTOR_NEW_ACTIVITY_HOLD;
	},
	shiftSegmentIsInProgress(state) {
		return state.shiftState.state_id === SHIFT_SEGMENT_IN_PROGRESS_ID;
	},
	isPunchedIntoShift(state) {
		return PUNCHED_IN_STATES.includes(state.shiftState.state_id);
	},
	getBioBreakMinMaxStartTime(state) {
		const { max_start_time, min_start_time } = state.shiftState?.eligible_actions?.start_bio_break || {};
		return { max_start_time, min_start_time };
	},
	getRestBreakMinMaxStartTime(state) {
		const { max_start_time, min_start_time } = state.shiftState?.eligible_actions?.schedule_rest_break || {};
		return { max_start_time, min_start_time };
	},
	getMealBreakMinMaxStartTime(state) {
		const { max_start_time, min_start_time } = state.shiftState?.eligible_actions?.request_meal_break || {};
		return { max_start_time, min_start_time };
	},
	getUpcomingRestBreak(state) {
		return getUpcomingBreak(REST_BREAK_ID, state);
	},
	getCurrentRestBreak(state, getters) {
		return getters.getCurrentShiftSegment?.type?.id === REST_BREAK_ID ? getters.getCurrentShiftSegment : null;
	},
	getLateBreak: (state, getters) => (breakTypeId) => {
		const currentShiftSegment = getters.getCurrentShiftSegment;
		if (
			isEmpty(state.currentShift) ||
			isEmpty(currentShiftSegment) ||
			currentShiftSegment.punch_in ||
			currentShiftSegment.type.id === breakTypeId
		) {
			return null;
		}

		const currentShiftSegmentIndex = state.currentShift.shift_segments
			.findIndex((segment) => currentShiftSegment.id === segment.id);
		const previousShiftSegment = state.currentShift.shift_segments[currentShiftSegmentIndex - 1];

		return previousShiftSegment?.type.id === breakTypeId ? previousShiftSegment : null;
	},
	getLateRestBreak(state, getters) {
		return getters.getLateBreak(REST_BREAK_ID);
	},
	hasRestBreak(state, getters) {
		return !isEmpty(getters.getUpcomingRestBreak) ||
			!isEmpty(getters.getCurrentRestBreak) ||
			!isEmpty(getters.getLateRestBreak);
	},
	getPendingBreakRequest(state) {
		return state.currentShift.pending_break_request;
	},
	getCancelledBreakType(state) {
		return state.cancelledBreak?.type.id;
	},
	getUpcomingMealBreak(state) {
		return getUpcomingBreak(MEAL_BREAK_ID, state);
	},
	getCurrentMealBreak(state, getters) {
		return getters.getCurrentShiftSegment?.type?.id === MEAL_BREAK_ID ? getters.getCurrentShiftSegment : null;
	},
	getLateMealBreak(state, getters) {
		return getters.getLateBreak(MEAL_BREAK_ID);
	},
	hasMealBreak(state, getters) {
		return !isEmpty(getters.getUpcomingMealBreak) ||
			!isEmpty(getters.getCurrentMealBreak) ||
			!isEmpty(getters.getLateMealBreak);
	},
	hasPendingMealBreakRequest(state, getters) {
		const pendingRequest = getters.getPendingBreakRequest;
		return pendingRequest?.type.id === MEAL_BREAK_ID;
	},
	soonestUpcomingBreak(state) {
		return sortBy(getUpcomingBreaks(state), ({ start_time }) => start_time)?.shift();
	},
	minutesToNextBreak(state, getters) {
		const nextBreak = getters.soonestUpcomingBreak;
		return nextBreak ? getMinutesBetweenDates(new Date(nextBreak.start_time), state.currentDate) : null;
	},
	isLockedOutOfShift(state, getters) {
		const currentShift = getters.getCurrentShift;

		return !isEmpty(currentShift) &&
			currentShift.state_id === SHIFT_UPCOMING_ID &&
			isAfter(state.currentDate, addMinutes(new Date(currentShift.start_time), 15));
	},
	isShiftEnding(state, getters) {
		const currentShift = getters.getCurrentShift;

		return !isEmpty(currentShift) &&
			isAfter(state.currentDate, subMinutes(new Date(currentShift.end_time), 15));
	},
	isLateForShift({ currentDate }, getters) {
		const currentShift = getters.getCurrentShift;

		return !isEmpty(currentShift) &&
			currentShift.state_id === SHIFT_UPCOMING_ID &&
			isAfter(currentDate, new Date(currentShift.start_time));
	},
	canReceiveSessions(state, getters) {
		return getters.getCurrentShiftSegment?.type?.id === TUTORING_SESSION_ID;
	},
	isPunchedInToTutoringShift(state, getters) {
		const shiftSegment = getters.getCurrentShiftSegment;
		return !!shiftSegment?.punch_in && shiftSegment?.type?.id === TUTORING_SESSION_ID;
	},
	isPunchedInToWritingReviewShift(state, getters) {
		const shiftSegment = getters.getCurrentShiftSegment;
		return !!shiftSegment?.punch_in && shiftSegment?.type?.id === WRITING_REVIEW_ID;
	},
	getUpcomingShift(state) {
		return state.shifts.find((shift) => shift.state_id === SHIFT_UPCOMING_ID) || {};
	},
	getCurrentShift(state, getters) {
		return !isEmpty(state.currentShift) ? state.currentShift : getters.getUpcomingShift;
	},
	isTutorManagerShift(state, getters) {
		const shift = getters.getCurrentShift;
		return !isEmpty(shift) && shift.shift_segments?.some((segment) => segment.type.id === TUTOR_MANAGER_ID);
	},
};

export default { namespaced: true, state, mutations, actions, getters };
