export default {
	en: {
		lightbox: {
			load_error: "The image was unable to be loaded, please close the window and try again.",
		},
	},
	fr: {
		lightbox: {
			load_error: "L'image n'a pas pu être chargée. Veuillez fermer la fenêtre et réessayer.",
		},
	},
	es: {
		lightbox: {
			load_error: "No se ha podido cargar la imagen, por favor cierre la ventana y inténtelo de nuevo.",
		},
	},

};
