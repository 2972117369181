<script setup>
import { computed } from "vue";
import { OrigamiIconButton } from "@origami/vue2";

const FILTER_TAG_LIMIT = 12;

const emit = defineEmits(["close-filter-tags-group"]);

const props = defineProps({
	tags: {
		type: Array,
		required: true,
	},
	name: {
		type: String,
		required: true,
	},
});

const tagNamesArray = computed(() => props.tags.map((tag) => tag.name));

const getTagContent = computed(() => {
	return props.tags.length < FILTER_TAG_LIMIT ? tagNamesArray.value.join(", ") : `${tagNamesArray.value.slice(0, FILTER_TAG_LIMIT).join(", ")} +${props.tags.length - FILTER_TAG_LIMIT}`;
});

const closeTagGroup = () => {
	emit("close-filter-tags-group", props.name);
};
const tagNamesExists = computed(() => {
	return props.tags.length > 0;
});
</script>

<template>
	<div
		v-if="tagNamesExists"
		v-jest="'filter-tag'"
		class="tw-bg-origami-grey-300 tw-rounded-md tw-flex tw-items-center tw-px-3 tw-mr-2"
		:title="tagNamesArray.join(', ')"
	>
		<span class="tw-text-origami-white tw-mr-2 tw-text-sm">{{ getTagContent }}</span>
		<OrigamiIconButton
			v-jest="'filter-tags-group-close-button'"
			v-data-cy="'filter-tags-group-close-button'"
			:icon-name="'close-full'"
			class="!tw-p-0 tw-text-sm tw-w-5 tw-h-5"
			@click.native="closeTagGroup"
		/>
	</div>
</template>
