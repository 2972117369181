//APIs
import TutorsAPI from "@/services/api/Tutors.js";
import SubjectsAPI from "@/services/api/Subjects.js";
import TopicsAPI from "@/services/api/Topics.js";
import { AvailabilitiesAPI } from "@/modules/TutorAvailabilities/index.js";
import { UsersAPI } from "@/modules/Customers/index.js";
import { LanguagesAPI } from "@/modules/Languages/index.js";

//root config

const getDefaultState = () => ({
	tutorUserId: null,
	tutor: null,
	subjects: null,
	tutorLanguages: null,
	essayLanguages: null,
	audioLanguages: null,
	relatedSubjects: [],
	relatedTopics: [],
	relatedCourses: [],
	relatedTags: [],
	selectedSubjectIds: [],
	selectedTopicIds: [],
	selectedConceptIds: [],
	isLoading: true,
	navbarMainTab: "Service",
	shiftBlocks: {},
	isTagsUpdated: false,
});

export const state = getDefaultState();

export const mutations = {
	UPDATE_TUTOR_SHIFT_BLOCK(state, payload) {
		state.shiftBlocks = payload.shiftBlocks;
	},
	GET_TUTOR_BLOCK_SHIFT(state, payload) {
		state.shiftBlocks = payload.shiftBlocks;
	},
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_TUTOR_USER_ID(state, payload) {
		state.tutorUserId = payload.tutorUserId;
	},
	SET_TUTOR(state, payload) {
		state.tutor = payload.tutor;
	},
	SET_PROFILE_IMAGE_IN_TUTOR(state, payload) {
		state.tutor.tutor.data.profile_image = payload.profile_image;
	},
	SET_SUBJECTS(state, payload) {
		state.subjects = payload.subjects;
	},
	SET_TUTOR_LANGUAGES(state, payload) {
		state.tutorLanguages = payload.tutorLanguages;
	},
	SET_ESSAY_LANGUAGES(state, payload) {
		state.essayLanguages = payload.essayLanguages;
	},
	SET_AUDIO_MESSAGE_LANGUAGES(state, payload) {
		state.audioLanguages = payload.audioLanguages;
	},
	SET_RELATED_SUBJECTS(state, payload) {
		state.relatedSubjects = payload.relatedSubjects;
	},
	SET_RELATED_TOPICS(state, payload) {
		state.relatedTopics = payload.relatedTopics;
	},
	SET_RELATED_CONCEPTS(state, payload) {
		state.relatedConcepts = payload.relatedConcepts;
	},
	SET_RELATED_TAGS(state, payload) {
		state.relatedTags = payload.relatedTags;
	},
	SET_SELECTED_SUBJECTS(state, payload) {
		state.selectedSubjectIds = payload.selectedSubjectIds;
	},
	SET_SELECTED_TOPICS(state, payload) {
		state.selectedTopicIds = payload.selectedTopicIds;
	},
	SET_SELECTED_CONCEPTS(state, payload) {
		state.selectedConceptIds = payload.selectedConceptIds;
	},
	SET_IS_LOADING(state, payload) {
		state.isLoading = payload.isLoading;
	},
	SET_EMPLOYEMENT_LOCATION(state, payload) {
		state.tutor.employment_country_code = payload.employmentLocation;
	},
	SET_CURRENT_LOCATION(state, payload) {
		state.tutor.employment_region_code = payload.currentLocation;
	},
	SET_ADP_NUMBER(state, payload) {
		state.tutor.tutor.data.adp_number = payload.adpNumber;
	},
	SET_IS_TAGS_UPDATED(state, payload) {
		state.isTagsUpdated = payload.value;
	},
};
export const getters = {
	shiftBlocks(state) {
		return state.shiftBlocks;
	},
	subjectsForTagsFilterTable(state) {
		return state.subjects.map((subject) => {
			return {
				id: subject.id,
				name: subject.name,
				topics: subject.availableTopics,
			};
		});
	},
};
export const actions = {
	async updateTutorShiftBlocks({ commit }, payload) {
		try {
			const response = await AvailabilitiesAPI.updateShiftBlocks(payload.tutorId, {
				availability_blocks: payload.updatedShiftBlocks,
			});
			if (response.status != 200) {
				throw new Error();
			}
			commit("UPDATE_TUTOR_SHIFT_BLOCK", {
				shiftBlocks: payload.shiftBlocks,
			});
			return Promise.resolve();
		} catch (e) {
			Sentry.captureException(e);
			return Promise.reject();
		}
	},
	async getAllBlocks({ commit }, payload) {
		try {
			const {
				data: { data },
			} = await AvailabilitiesAPI.getAllBlocks(payload.tutorId);
			commit("GET_TUTOR_BLOCK_SHIFT", {
				shiftBlocks: data.slice(1),
			});
		} catch (error) {
			Sentry.captureException(error);
		}
	},
	async getTutor({ state, commit }) {
		const topics = [];
		const subjects = [];
		const concepts = [];
		try {
			commit("SET_IS_LOADING", {
				isLoading: true,
			});
			const response = await UsersAPI.show(state.tutorUserId, {
				include:
					"roles,tutor.topics.subject,tutor.languages,tutor.essayLanguages,tutor.subjects,tutor.concepts,tutor.asyncAudioSessionLanguages",
			});
			commit("SET_TUTOR", {
				tutor: response.data.data,
			});
			response.data.data.tutor.data.subjects.data.forEach((subject) => {
				subjects.push(subject);
			});
			response.data.data.tutor.data.topics.data.forEach((topic) => {
				topics.push(topic);
			});
			response.data.data.tutor.data.concepts.data.forEach((concept) => {
				concepts.push(concept);
			});
			commit("SET_RELATED_SUBJECTS", {
				relatedSubjects: subjects,
			});
			commit("SET_RELATED_TOPICS", {
				relatedTopics: topics,
			});
			commit("SET_RELATED_CONCEPTS", {
				relatedConcepts: concepts,
			});
			return Promise.resolve(response);
		} catch (e) {
			return Promise.reject(e);
		} finally {
			commit("SET_IS_LOADING", {
				isLoading: false,
			});
		}
	},
	async updateTutorProfilePicture({ state, commit }, payload) {
		try {
			const response = await TutorsAPI.updateTutorProfilePicture(state.tutorUserId, payload);
			commit("SET_PROFILE_IMAGE_IN_TUTOR", {
				profile_image: response.data.profile_image,
			});
			return Promise.resolve(response);
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async deleteTutorProfilePicture({ state, commit }) {
		try {
			const response = await TutorsAPI.updateTutor(state.tutorUserId, {
				profile_image_path: null,
			});
			commit("SET_PROFILE_IMAGE_IN_TUTOR", {
				profile_image: "",
			});
			return Promise.resolve(response);
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async getSubjects({ commit }) {
		try {
			const response = await SubjectsAPI.list({ include: "availableTopics.concepts" });
			commit("SET_SUBJECTS", {
				subjects: response.data.data,
			});
			return Promise.resolve(response);
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async getLanguages({ commit }) {
		try {
			const response = await LanguagesAPI.list();
			commit("SET_ESSAY_LANGUAGES", {
				essayLanguages: response.data.data,
			});
			const tutorLanguages = response.data.data.filter((language) => {
				return language.tutorable === 1;
			});

			commit("SET_TUTOR_LANGUAGES", {
				tutorLanguages,
			});

			commit("SET_AUDIO_MESSAGE_LANGUAGES", {
				audioLanguages: response.data.data,
			});
			return Promise.resolve(response);
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async submitUserInfo({ state, commit }, payload) {
		try {
			const tutor = { ...state.tutor, ...payload };
			const response = await UsersAPI.update(state.tutorUserId, payload);
			if (response.status === 200) {
				commit("SET_TUTOR", {
					tutor,
				});
			}
			return Promise.resolve(response);
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async updateLanguages({ state, dispatch }, { essayLanguages, languages, asyncAudioSessionLanguages }) {
		try {
			const response = await Promise.all([
				LanguagesAPI.updateTutorLanguages(state.tutorUserId, { languages }),
				LanguagesAPI.updateEssayLanguages(state.tutorUserId, { essayLanguages }),
				LanguagesAPI.updateAudioLanguages(state.tutorUserId, { asyncAudioSessionLanguages }),
			]);
			await dispatch("getTutor");
			return Promise.resolve(response);
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async updateTutor({ state, dispatch }, payload) {
		try {
			const response = await TutorsAPI.updateTutor(state.tutorUserId, payload);
			await dispatch("getTutor");
			return Promise.resolve(response);
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async updateTutorTopics({ commit, state }, payload) {
		try {
			const response = await TopicsAPI.setTutorTopics(state.tutorUserId, payload);
			commit("SET_TOPICS", {
				topics: payload.topic_ids,
			});
			return Promise.resolve(response);
		} catch (e) {
			return Promise.reject(e);
		}
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
