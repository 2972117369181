var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 16 16",
      },
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("circle", {
          attrs: { cx: "8", cy: "8", r: "8", fill: _vm.iconColor },
        }),
        _vm._v(" "),
        _c(
          "text",
          {
            attrs: {
              fill: "#FFF",
              "font-family": "OpenSans-Bold, Open Sans",
              "font-size": "12",
              "font-weight": "bold",
              "letter-spacing": "-.018",
            },
          },
          [_c("tspan", { attrs: { x: "6", y: "13" } }, [_vm._v("i")])]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }