var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass:
        "tw-flex tw-flex-col tw-justify-between tw-items-start tw-gap-2 tw-text-base tw-pb-0",
    },
    [
      _c(
        "p",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "message",
              expression: "'message'",
            },
          ],
          staticClass: "tw-font-bold",
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.message) + "\n\t")]
      ),
      _vm._v(" "),
      _c("Points", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "activity-points",
            expression: "'activity-points'",
          },
        ],
        staticClass:
          "tw-border tw-border-origami-grey-200 tw-rounded-full tw-py-1 tw-px-2",
        attrs: { points: _vm.points },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }