var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "OrigamiCard",
    {
      staticClass: "tw-flex tw-items-center tw-gap-3 tw-p-3",
      attrs: { tag: "li", "is-interactive": "", "is-selected": _vm.isSelected },
      nativeOn: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _c("VideoThumbnail", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "thumbnail",
            expression: "'thumbnail'",
          },
        ],
        staticClass: "tw-rounded-md",
        attrs: { url: _vm.thumbnailUrl },
        nativeOn: {
          click: function ($event) {
            return _vm.$emit("thumbnail-click", $event)
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "tw-flex-auto" }, [
        _c(
          "button",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "bookmark",
                expression: "'bookmark'",
              },
            ],
            staticClass: "bookmark origami-icon-button tw-float-right tw--mt-1",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$emit("bookmark-click", $event)
              },
            },
          },
          [
            _vm.isBookmarked
              ? _c("IconBookmark", {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "bookmark-on",
                      expression: "'bookmark-on'",
                    },
                  ],
                  staticClass: "tw-text-origami-brand-blue",
                })
              : _c("IconBookmarkOutlined", {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "bookmark-off",
                      expression: "'bookmark-off'",
                    },
                  ],
                  staticClass: "tw-text-origami-grey-300",
                }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.video.title
          ? _c(
              "h4",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "title",
                    expression: "'title'",
                  },
                ],
                staticClass: "line-clamp-2 tw-text-sm tw-font-bold",
                attrs: { title: _vm.video.title },
              },
              [_vm._v("\n\t\t\t" + _vm._s(_vm.video.title) + "\n\t\t")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.video.description
          ? _c(
              "p",
              {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "description",
                    expression: "'description'",
                  },
                ],
                staticClass: "line-clamp-2 tw-text-2xs",
                attrs: { title: _vm.video.description },
              },
              [_vm._v("\n\t\t\t" + _vm._s(_vm.video.description) + "\n\t\t")]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }