<template>
	<IconButtonState
		:icon="studentsIcon"
		button-text="Create group"
		@button-clicked="$router.push(`/${currentUser.role}/student-groups/create`)"
	>
		No groups created
	</IconButtonState>
</template>

<script>
import { mapState } from "vuex";

import IconButtonState from "../../patterns/IconButtonState.vue";
import StudentsIcon from "../../elements/icons/Students.vue";

export default {
	components: {
		IconButtonState,
	},
	data() {
		return {
			studentsIcon: StudentsIcon,
		};
	},
	computed: {
		...mapState(["currentUser"]),
	},
};
</script>
