import { escapeRegExp } from "lodash";

export const TAGS = {
	TEXT: "TEXT",
	IMAGE: "IMAGE",
	GOOGLE: "GOOGLE",
	MICROSOFT: "MICROSOFT",
};

export const FILE_TYPES = {
	googleDocs: {
		mimeType: "application/vnd.google-apps.document",
		tags: [TAGS.TEXT, TAGS.GOOGLE],
		extensionless: true,
	},
	doc: {
		mimeType: "application/msword",
		tags: [TAGS.TEXT, TAGS.MICROSOFT],
	},
	docx: {
		mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		tags: [TAGS.TEXT, TAGS.MICROSOFT],
	},
	googleSlides: {
		mimeType: "application/vnd.google-apps.presentation",
		tags: [TAGS.TEXT, TAGS.GOOGLE],
		extensionless: true,
	},
	ppt: {
		mimeType: "application/vnd.ms-powerpoint",
		tags: [TAGS.TEXT, TAGS.MICROSOFT],
	},
	pptx: {
		mimeType: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
		tags: [TAGS.TEXT, TAGS.MICROSOFT],
	},
	pdf: {
		mimeType: "application/pdf",
		tags: [TAGS.TEXT],
	},
	rtf: {
		mimeType: "application/rtf",
		tags: [TAGS.TEXT],
	},
	txt: {
		mimeType: "text/plain",
		tags: [TAGS.TEXT],
	},
	jpg: {
		mimeType: "image/jpeg",
		tags: [TAGS.IMAGE],
	},
	jpeg: {
		mimeType: "image/jpeg",
		tags: [TAGS.IMAGE],
	},
	png: {
		mimeType: "image/png",
		tags: [TAGS.IMAGE],
	},
};

export function getFilteredFileTypeIds({ includeTags = [], excludeTags = [] } = {}) {
	return Object.entries(FILE_TYPES)
		.filter(([, fileType]) => {
			if (includeTags?.length) {
				if (!fileType.tags?.some((tag) => includeTags.includes(tag))) {
					return false;
				}
			}
			if (excludeTags?.length) {
				if (fileType.tags?.some((tag) => excludeTags.includes(tag))) {
					return false;
				}
			}
			return true;
		})
		.map(([id]) => id);
}

export function formatFileTypeIdsAsMimeTypes(fileTypeIds) {
	return fileTypeIds
		.map((id) => FILE_TYPES[id]?.mimeType)
		.filter(Boolean);
}
export function formatFileTypeIdsAsExtensionsRegex(fileTypeIds) {
	const extensionsStr = fileTypeIds
		.map((id) => {
			if (FILE_TYPES[id] && !FILE_TYPES[id].extensionless) {
				return escapeRegExp(`.${id}`);
			}
			return null;
		})
		.filter(Boolean)
		.join("|");
	return new RegExp(`(${extensionsStr})$`, "i");
}
export function isFileNameMatchExtensionsRegex(fileName, fileTypeIds) {
	if (!fileName) {
		return false;
	}
	const validFileRegex = formatFileTypeIdsAsExtensionsRegex(fileTypeIds);
	return validFileRegex.test(fileName);
}

