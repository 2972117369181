var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tutorCardInfo" },
    [
      _c("BaseTutorImage", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "tutor-image",
            expression: "'tutor-image'",
          },
        ],
        class: {
          "tutorImage--disconnectedBorder": _vm.isDisconnectedAndPunchedIn,
          "tutorCardInfo--greyedOut": _vm.isGreyedOut,
        },
        attrs: {
          "profile-path": _vm.user.profile_picture,
          "full-tutor-name": _vm.user.name,
          dimensions: 56,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tutorCardInfo__text tw-max-w-full tw-truncate" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "tutor-name",
                  expression: "'tutor-name'",
                },
              ],
              staticClass:
                "tutorCardInfo__name tw-max-w-full tw-flex tw-justify-start tw-justify-between tw-items-center",
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "tutor-name-and-new-status",
                      expression: "'tutor-name-and-new-status'",
                    },
                  ],
                  staticClass: "tw-flex tw-truncate",
                  class: { "tutorCardInfo--greyedOut": _vm.isGreyedOut },
                },
                [
                  _c("p", { staticClass: "tw-truncate" }, [
                    _vm._v(
                      "\n\t\t\t\t\t" + _vm._s(_vm.user.name) + "\n\t\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.user.is_new
                    ? _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: "new-tutor-label",
                              expression: "'new-tutor-label'",
                            },
                          ],
                          staticClass: "tw-text-blue-regular tw-ml-1.5",
                        },
                        [_vm._v(" (New Tutor!)")]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tw-flex" },
                [
                  _vm.canImpersonate
                    ? _c("OrigamiIconButton", {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "tutor-impersonate-button",
                            expression: "'tutor-impersonate-button'",
                          },
                        ],
                        attrs: {
                          "icon-name": "impersonate",
                          title: "Impersonate Tutor",
                          "aria-label": "Impersonate Tutor",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.toggleImpersonationModal.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("OrigamiIconButton", {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "profile-details-button",
                        expression: "'profile-details-button'",
                      },
                    ],
                    attrs: {
                      "icon-name": "transfer",
                      title: "Open Tutor Quick Edit",
                      "aria-label": "Open Tutor Quick Edit",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.openTutorQuickEdit.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "tutor-shift-info",
                  expression: "'tutor-shift-info'",
                },
              ],
              staticClass: "tutorCardInfo__shiftInfo",
              class: { "tutorCardInfo--greyedOut": _vm.isGreyedOut },
            },
            [
              _c(
                "span",
                {
                  class: {
                    "tutorCardInfo__shiftInfo--lateColor": _vm.isShiftStartRed,
                  },
                  on: {
                    mouseover: function ($event) {
                      return _vm.showPunchInTime()
                    },
                    mouseleave: function ($event) {
                      return _vm.showShiftStartTime()
                    },
                  },
                },
                [_vm._v("\n\t\t\t\t" + _vm._s(_vm.tutorShiftStartTime))]
              ),
              _vm._v("\n\t\t\t- " + _vm._s(_vm.shiftEndTime) + "\n\t\t\t"),
              !_vm.isExtraInfoTextEmpty
                ? [
                    _vm._v("\n\t\t\t\t|\n\t\t\t\t"),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "jest",
                            rawName: "v-jest",
                            value: "extra-info-text",
                            expression: "'extra-info-text'",
                          },
                        ],
                        class: _vm.extraInfo.class,
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.extraInfo.text) +
                            "\n\t\t\t\t"
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.isDisconnectedAndPunchedIn
                ? _c(
                    "span",
                    {
                      staticClass: "tutorCardInfo__shiftInfo--disconnectedText",
                    },
                    [_vm._v("Disconnected")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isUpcomingTutorShift
                ? _c(
                    "span",
                    { staticClass: "tutorCardInfo__shiftInfo--floatRight" },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.timeUntilShiftStart) +
                          "\n\t\t\t"
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm.isImpersonationModalVisible
        ? _c("ImpersonationModal", {
            attrs: { "user-email": _vm.user.email, token: "" },
            on: {
              "close-modal": function ($event) {
                return _vm.toggleImpersonationModal()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }