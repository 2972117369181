<template>
	<div :class="containerClass">
		<div
			:id="mfeConfig.id"
			v-jest="'micro-front-end'"
		/>
		<div
			v-if="isLoading || isAlwaysLoading || isShowingError"
			:class="{ 'fallback__container': !isWidget }"
		>
			<slot
				v-if="isShowingError"
				name="error"
			>
				<ErrorState
					v-jest="'error-state'"
					class="tw-bg-white-high tw-p-5 tw-h-72 tw-shadow-elevation-2 tw-rounded"
					@button-clicked="reload()"
				>
					<template #main>
						<p class="tw-text-center">
							{{ $t("errors.modal.reload.subtitle") }}
						</p>
					</template>
				</ErrorState>
			</slot>
			<slot
				v-else
				name="loader"
			>
				<BaseLoader
					v-jest="'loader'"
					class="loader tw-w-16 tw-h-16 tw-text-blue-regular"
				/>
			</slot>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, defineAsyncComponent, onUnmounted, watch } from "vue";
import { useRoute } from "vue-router/composables";

import BaseLoader from "@/components/elements/BaseLoader.vue";

import useMicroFrontendEvents from "../composables/useMicroFrontendEvents.js";
import {
	MICRO_FRONTENDS_HOST_EVENTS,
	MICRO_FRONTENDS_MAP,
	MICRO_FRONTENDS_NAMES,
	publishToMicroFrontEnds,
} from "../utilities/index.js";

const ErrorState = defineAsyncComponent(() => import("@/components/patterns/ErrorState.vue"));

let microApp;

const isShowingError = ref(false);
const isLoading = ref(true);

const route = useRoute();
const { subscribeToEvents, unsubscribeToEvents } = useMicroFrontendEvents();

const props = defineProps({
	microFrontendName: {
		type: String,
		required: true,
		validator(value) {
			return Object.values(MICRO_FRONTENDS_NAMES).some((name) => name === value);
		},
	},
	containerClass: {
		type: String,
		default: "",
	},
	options: {
		type: Object,
		default: () => ({}),
	},
	isWidget: {
		type: Boolean,
		default: false,
	},
	isAlwaysLoading: {
		type: Boolean,
		default: false,
	},
});

const mfeConfig = MICRO_FRONTENDS_MAP[props.microFrontendName];

const reload = () => {
	window.location.reload();
};

watch(() => route.fullPath, (routePath) => {
	publishToMicroFrontEnds(MICRO_FRONTENDS_HOST_EVENTS.ROUTE_CHANGE, { to: routePath });
});

onMounted(async() => {
	try {
		isLoading.value = true;
		microApp = await mfeConfig.importMicroApp();
		/*
        This will trigger the micro app to be mounted on the element
        with the id specified by MICRO_FRONTENDS_MAP[props.microFrontendName].id.
        It is required by the micro app to export an initApp function that mounts the vue app
        on the specified id.
        */
		await microApp.initApp({
			element: `#${mfeConfig.id}`,
			...props.options,
		});
		subscribeToEvents();
	} catch (e) {
		Sentry.captureException(e);
		isShowingError.value = true;
	} finally {
		isLoading.value = false;
	}
});
onUnmounted(() => {
	/*
    This will make sure that the micro app vue instance is destroyed when we
    navigate away from the micro app.
    */
	microApp?.destroyApp?.();
	unsubscribeToEvents();
});
</script>

<style scoped>
.fallback__container {
	@apply tw-flex tw-items-center tw-justify-center;
	height: var(--height-screen-without-header);
}
</style>
