var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("OrigamiDialog", {
    attrs: {
      title: "Do you want to hide this session?",
      "secondary-button-text": "Cancel",
      "primary-button-text": "Yes, hide",
      description: "This action will remove visibility on this session.",
      "is-open": _vm.PlatformManager.ActionCenter.isShowingHideDialog,
      "is-loading": _vm.isUpdating,
    },
    on: { confirm: _vm.updateActionItem, cancel: _vm.closeModal },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }