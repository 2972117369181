import Vue from "vue";
import VueResize from "vue-resize";
import VMdDateRangePicker from "v-md-date-range-picker";
import VueMeta from "vue-meta";
import * as Sentry from "@sentry/vue";
import { ModuleMetadata, makeMultiplexedTransport, makeFetchTransport } from "@sentry/vue";
import {
	initializeSentry,
	initializeLaunchDarkly,
	getFlag,
	initializeDatadog,
	initializeFullStory,
	initializeGoogleAnalytics,
	googleAnalyticsVue2Plugin,
} from "@paper-co/web-toolkit";

import Colors from "@/utilities/Colors.js";
import "@/plugins/BootstrapVue.js";
import clickOutsideDirective from "@/directives/clickOutside.js";
import visibleDirective from "@/directives/visible.js";
import { Auth0 } from "@/plugins/Auth0.js";
import BaseLink from "@/components/elements/BaseLink.vue";
import BaseTutorImage from "@/components/BaseTutorImage.vue";

import router from "./router/index.js";

const transport = makeMultiplexedTransport(makeFetchTransport, (args) => {
	const event = args.getEvent();
	if (
		event &&
		event.mfe_config_overrides &&
		Array.isArray(event.mfe_config_overrides)
	) {
		return event.mfe_config_overrides;
	}
	return [];
});

initializeSentry({
	app: Vue,
	dsn: process.env.MIX_SENTRY_DSN,
	environment: process.env.MIX_APP_ENV,
	attachStacktrace: true,
	release: process.env.MIX_GCR_VERSION,
	integrations: [new ModuleMetadata()],
	beforeSend: (event) => {
		if (event?.exception?.values?.[0].stacktrace.frames) {
			const frames = event.exception.values[0].stacktrace.frames;
			// Find the last frame with module metadata containing a DSN
			const mfe_config_overrides = frames
				.filter((frame) => frame.module_metadata && frame.module_metadata.dsn)
				.map((v) => v.module_metadata);
			if (mfe_config_overrides.length) {
				event.mfe_config_overrides = mfe_config_overrides;
			}
		}
		return event;
	},
	transport,
});
// make Sentry available globally
if (process.env.MIX_SENTRY_DSN) {
	window.Sentry = Sentry;
} else {
	window.Sentry = {
		captureException: console.error,
		captureMessage: console.error,
		setTag: () => {},
		setContext: () => {},
		withScope: () => {},
	};
}

initializeLaunchDarkly({
	clientId: process.env.MIX_LAUNCHDARKLY_CLIENT_ID,
	anonymousDeveloperKey: process.env.MIX_LAUNCHDARKLY_ANONYMOUS_DEVELOPER_KEY,
}).catch(Sentry.captureException);
initializeDatadog({
	clientToken: process.env.MIX_DD_CLIENT_TOKEN,
	applicationId: process.env.MIX_DD_APP_ID,
	service: process.env.MIX_SERVICE_NAME,
	env: process.env.MIX_APP_ENV,
}).catch(Sentry.captureException);
initializeFullStory({
	orgId: process.env.MIX_FULLSTORY_ORG_ID,
}).catch(Sentry.captureException);
initializeGoogleAnalytics({
	gaDestinationId: process.env.MIX_GA_DESTINATION_ID,
	user: null,
}).catch(Sentry.captureException);

Vue.use(VueResize);
Vue.use(VMdDateRangePicker);
Vue.use(VueMeta);
Vue.use(Auth0);
Vue.use(googleAnalyticsVue2Plugin, router);

Vue.component("BaseLink", BaseLink);
Vue.component("BaseTutorImage", BaseTutorImage);

Object.filter = (obj, predicate) =>
	Object.keys(obj)
		.filter((key) => predicate(obj[key]))
		.reduce((res, key) => ((res[key] = obj[key]), res), {});

Vue.prototype.$colors = Colors;
Vue.prototype.essayBucket = process.env.MIX_GOOGLE_ESSAYS_BUCKET;
Vue.prototype.$getFlag = getFlag;

Vue.directive("click-outside", clickOutsideDirective);
Vue.directive("visible", visibleDirective);
Vue.directive("jest", {});
if (process.env.MIX_DATA_CY_ENABLED) {
	Vue.directive("data-cy", {
		bind: function(el, binding) {
			el.setAttribute("data-cy", binding.value);
		},
	});
} else {
	Vue.directive("data-cy", {});
}

export default Vue;
