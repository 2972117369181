import GradesAPI from "@/services/api/Grades.js";
import LoginTypesAPI from "@/modules/Authentication/services/api/LoginTypes.js"; // barrel file not working here

import DistrictsAPI from "../../services/districts.js";
import UsersAPI from "../../services/users.js";

const getDefaultState = () => ({
	countries: ["CA", "US"],
	districts: [],
	grades: [],
	loading: true,
	loginTypes: [],
	superusers: [],
	navbarMainTab: "Customers",
});

export default {
	namespaced: true,
	state: getDefaultState(),
	mutations: {
		RESET_STATE(state) {
			Object.assign(state, getDefaultState());
		},
		SET_DISTRICTS(state, payload) {
			state.districts = payload.districts;
		},
		SET_FILTERED_USERS(state, payload) {
			state.superusers = payload.superusers;
		},
		SET_GRADES(state, payload) {
			state.grades = payload.grades;
		},
		SET_LOADING(state, payload) {
			state.loading = payload.loading;
		},
		SET_LOGIN_TYPES(state, payload) {
			state.loginTypes = payload.loginTypes;
		},
	},
	actions: {
		async setDistricts({ commit }, payload) {
			try {
				const districts = await DistrictsAPI.list(payload, {});
				commit("SET_LOADING", {
					loading: false,
				});
				commit("SET_DISTRICTS", {
					districts: districts.data.data,
				});
				return Promise.resolve();
			} catch (error) {
				return Promise.reject(error.response);
			}
		},
		async setLoginTypes({ commit }) {
			try {
				const loginTypes = await LoginTypesAPI.list();
				commit("SET_LOGIN_TYPES", {
					loginTypes: loginTypes.data.data,
				});
				return Promise.resolve();
			} catch (error) {
				return Promise.reject(error.response);
			}
		},

		async setSuperusers({ commit }) {
			try {
				const superusers = await UsersAPI.list({
					role: "superuser",
				});
				commit("SET_FILTERED_USERS", {
					superusers: superusers.data.data,
				});
				return Promise.resolve();
			} catch (error) {
				return Promise.reject(error.response);
			}
		},

		async setGrades({ commit }) {
			try {
				const grades = await GradesAPI.list();
				commit("SET_GRADES", {
					grades: grades.data.data,
				});
				return Promise.resolve();
			} catch (error) {
				return Promise.reject(error.response);
			}
		},
	},
};
