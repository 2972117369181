var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 28.535 28.534",
        "enable-background": "new 0 0 28.535 28.534",
        "xml:space": "preserve",
      },
    },
    [
      _c("path", {
        attrs: {
          fill: _vm.iconColor,
          d: "M14.267,0C6.388,0,0,6.387,0,14.267c0,7.879,6.388,14.268,14.267,14.268c7.88,0,14.268-6.389,14.268-14.268\n        C28.535,6.387,22.147,0,14.267,0 M19.412,12.432c-0.004,0.005-0.01,0.006-0.015,0.01l0.002,0.003l-5.421,5.421\n        c-0.246,0.246-0.57,0.366-0.893,0.363c-0.322,0.003-0.646-0.117-0.892-0.363c-0.004-0.004-0.005-0.011-0.01-0.016l-0.002,0.003\n        l-3.058-3.058c-0.487-0.487-0.487-1.276,0-1.763c0.487-0.487,1.277-0.487,1.763,0l2.199,2.199l4.551-4.551l0.002,0.003\n        c0.005-0.005,0.006-0.011,0.011-0.015c0.487-0.487,1.276-0.487,1.763,0C19.899,11.156,19.899,11.945,19.412,12.432",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }