var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "aside",
    [
      _vm._t("buttonContent"),
      _vm._v(" "),
      _c("div", { staticClass: "tw-z-10" }, [_vm._t("tooltipContent")], 2),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }