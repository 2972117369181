import vueRouter from "../router/index.js";
export const isVueRoute = function(path) {
	if (!path) {
		return false;
	}

	const match = vueRouter.match(path);
	if (match.name !== "notFound") {
		return true;
	} else {
		return false;
	}
};
