<template>
	<RouterLink
		v-if="isLinkExist"
		:to="to"
		class="spa"
		:exact="shouldBeExact(to)"
		:target="target ? '_blank' : false"
	>
		<slot />
	</RouterLink>
	<a
		v-else
		class="noSpa"
		:href="to"
		:target="target ? '_blank' : false"
	><slot /></a>
</template>

<script>
import { isVueRoute } from "@/utilities/vue-router.js";

export default {
	name: "BaseLink",
	props: {
		to: {
			type: String,
			required: true,
		},
		target: {
			type: Boolean,
			required: false,
		},
	},
	data: () => ({ isLinkExist: false }),
	async beforeMount() {
		this.isLinkExist = isVueRoute(this.to);
	},
	methods: {
		shouldBeExact(path) {
			return [
				"/",
				"/teacher",
				"/administrator",
				"/district-manager",
				"/tutor",
				"/tutor-manager",
				"/superuser",
			].includes(path);
		},
	},
};
</script>

<style scoped>
.spa:focus-visible, .noSpa:focus-visible {
	outline: 2px solid var(--origami-blue-200);
}
</style>

