import NotificationsAPI from "@/services/api/Notifications.js";

export default {
	namespaced: true,
	state: {
		notifications: {
			inbox: 0,
			reviewedEssays: 0,
		},
		isLanguagePromptShowing: false,
	},

	mutations: {
		INCREMENT_INBOX_IN_NAVBAR_NOTIFICATIONS(state) {
			Vue.set(state.notifications, "inbox", state.notifications["inbox"] + 1);
		},

		INCREMENT_REVIEWED_ESSAYS_IN_NAVBAR_NOTIFICATIONS(state) {
			Vue.set(state.notifications, "reviewedEssays", state.notifications["reviewedEssays"] + 1);
		},

		SET_NAVBAR_NOTIFICATIONS(state, payload) {
			state.notifications = payload.notifications;
		},
		TOGGLE_IS_LANGUAGE_PROMPT_SHOWING(state, payload) {
			state.isLanguagePromptShowing = payload.isLanguagePromptShowing;
		},
	},

	actions: {
		async getNotificationCounts({ commit }) {
			try {
				const response = await NotificationsAPI.getUserNotificationsForNavbar();
				commit("SET_NAVBAR_NOTIFICATIONS", {
					notifications: {
						inbox: response.data.data.notifications,
						reviewedEssays: response.data.data.reviewedEssays,
					},
				});
				return Promise.resolve();
			} catch (error) {
				return Promise.reject(error);
			}
		},
	},
	getters: {
		reviewedEssaysNotificationCount(state) {
			return state.notifications?.reviewedEssays ?? 0;
		},
	},
};
