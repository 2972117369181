export default [
	{
		path: "students",
		component: () => import("../pages/districtManager/index.vue"),
		children: [
			{
				path: "",
				name: "districtManagerStudents",
				component: () => import("../pages/districtManager/list.vue"),
			},
			{
				path: ":studentId",
				name: "districtManagerStudentsProfile",
				component: () => import("../pages/districtManager/_studentId.vue"),
			},
		],
	},
];
