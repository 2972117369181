<template>
	<div>
		<div
			class="tw-flex tw-p-4 tw-gap-x-4 tw-bg-white-high tw-shadow-elevation-1 tw-cursor-pointer hover:tw-shadow-elevation-2"
			:class="{ 'tw-mb-4': !isLoadingState }"
		>
			<BaseTutorImage
				v-jest="'profile-image'"
				:dimensions="56"
				:full-tutor-name="fullTutorName"
				:profile-path="profileImage"
			/>
			<div class="tw-flex-grow">
				<div
					v-if="includeShiftStatus"
					:class="{ 'tw-grid tw-grid-cols-2': includeShiftStatus }"
				>
					<h3
						v-jest="'name'"
						class="tw-text-xl tw-mb-1 tw-font-bold"
					>
						{{ fullTutorName }}
					</h3>
					<div class="tw-px-1 tw-inline-flex tw-items-center tw-justify-end">
						<span class="tw-font-bold tw-text-sm tw-mb-px tw-text-origami-red-300">
							Late
						</span>
						<span class="tw-w-2 tw-h-2 tw-rounded-full tw-ml-1 tw-bg-origami-red-300" />
					</div>
				</div>
				<h3
					v-else
					v-jest="'name'"
					class="tw-text-xl tw-mb-1 tw-font-bold"
				>
					{{ fullTutorName }}
				</h3>
				<p class="tw-text-xs tw-mb-2">
					<slot name="metaDataIcon" />
					<slot name="metadata" />
					<span
						v-if="hasActiveSessions"
					>
						<span class="tw-mx-1">|</span>
						<OrigamiIcon
							name="chat-bubble"
							color="blue"
							height="16"
							width="16"
						/>
						<span
							v-jest="'open-session-indicator'"
							class="tw-font-bold tw-ml-1"
						>
							Open session
						</span>
					</span>
				</p>
				<p class="tw-text-blue-regular tw-text-base tw-font-bold">
					<slot name="breakDetailsIcon" />
					<slot name="breakDetails" />
				</p>
				<div
					v-if="$slots.buttons"
					class="tw-flex tw-justify-end tw-mt-3"
				>
					<slot name="buttons" />
				</div>
			</div>
		</div>
		<div
			v-show="isLoadingState"
			class="animatedLoadingBar tw-mb-4"
		/>
	</div>
</template>

<script>
import { OrigamiIcon } from "@origami/vue2";

import { UsersAPI } from "@/modules/Customers/index.js";

import BaseTutorImage from "../../../components/BaseTutorImage.vue";
import TutorsAPI from "../../../services/api/Tutors.js";

export default {
	components: {
		BaseTutorImage,
		OrigamiIcon,
	},
	props: {
		break: {
			type: Object,
			required: true,
		},
		isLoadingState: {
			type: Boolean,
			required: false,
		},
		includeShiftStatus: {
			type: Boolean,
			required: false,
		},
	},
	data() {
		return {
			user: null,
			hasActiveSessions: false,
		};
	},
	computed: {
		profileImage() {
			return this.user?.profile_image || "/";
		},
		fullTutorName() {
			return this.user ? `${this.user?.first_name} ${this.user?.last_name}` : "";
		},
	},
	created() {
		this.getTutorActiveSessionCount(this.break.user_id);
		this.getUser(this.break.user_id);
	},
	methods: {
		async getTutorActiveSessionCount(tutorId) {
			try {
				const response = await TutorsAPI.getTutorActiveSessionCount(tutorId);
				this.hasActiveSessions = response.data.data.count > 0;
			} catch (e) {
				Sentry.captureException(e);
			}
		},
		async getUser(tutorId) {
			try {
				const response = await UsersAPI.show(tutorId);
				this.user = response.data.data;
			} catch (e) {
				Sentry.captureException(e);
			}
		},
	},
};
</script>

<style scoped>
.animatedLoadingBar {
	width: 100%;
	height: 8px;
	background: linear-gradient(45deg, #0e0fe0, #ffbbdd);
	background-size: 150% 150%;
	animation: gradient 1s ease infinite;
}
</style>
