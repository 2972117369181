var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "resourceList" }, [
    _c("div", { staticClass: "resourceList__total" }, [
      _c("div", { staticClass: "resourceList__onGoing" }, [_vm._t("left")], 2),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "resourceList__sessions" },
        [_vm._t("right")],
        2
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "resourceList__itemBlock" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }