import rootInstance from "@/services/root.js";

const MATH_BASE_URL = process.env.MATH_PROBLEM_API_URL + "/api/v1";

const mathApi = (service = "") => {
	rootInstance.defaults.baseURL = [MATH_BASE_URL, service].filter((x) => x).join("/");

	return rootInstance;
};

const extractDataDefault = ({ data }) => data;

const initApiCache = (service = "", extractData = extractDataDefault) => {
	const cache = {};

	return {
		get: (url, params) => {
			if (!cache[url]) {
				cache[url] = mathApi(service).get(url, params).then(extractData);
			}

			return cache[url];
		},
		invalidate: (url) =>
			cache[url] = null,
	};
};

export { initApiCache, mathApi };
