<script>
import { load, loadClient, initGSIClient, getBitsFromBinaryString } from "@/plugins/GoogleApis.js";

export const UPLOAD_ERROR_KEYS = {
	NONE: null,
	INVALID: "invalid",
	TOO_LARGE: "too_large",
	NO_FILE_SELECTED: "no_file_selected",
	CANCELLED: "popup_closed_by_user",
	AUTH: "access_denied",
	POPUP_BLOCKED: "popup_blocked_by_browser",
	FALLBACK: "other",
	TOO_MANY_FILES: "too_many_files",
};
export function getLocalizedErrorSubKey(error) {
	const errorCode = error?.message || error?.error || error;
	const specificErrors = [
		UPLOAD_ERROR_KEYS.INVALID,
		UPLOAD_ERROR_KEYS.TOO_LARGE,
		UPLOAD_ERROR_KEYS.NO_FILE_SELECTED,
		UPLOAD_ERROR_KEYS.AUTH,
		UPLOAD_ERROR_KEYS.POPUP_BLOCKED,
	];
	if (errorCode === UPLOAD_ERROR_KEYS.CANCELLED) {
		// ignore innocuous 'error'
		return UPLOAD_ERROR_KEYS.NONE;
	} else if (specificErrors.includes(errorCode)) {
		// surface specific errors
		return errorCode;
	} else {
		// fallback to generic error
		return UPLOAD_ERROR_KEYS.FALLBACK;
	}
}

export default {
	name: "GooglePicker",
	props: {
		multiple: {
			type: Boolean,
			default: false,
		},
		authConfig: {
			type: Object,
			default: () => ({ prompt: "select_account" }),
		},
		docValidator: {
			// should throw if doc is invalid
			type: Function,
			default: () => {},
		},
		mimeTypes: {
			type: Array,
			default: () => [],
		},
	},
	emits: ["change", "change-start", "change-end", "error"],
	data() {
		return {
			accessToken: undefined,
			picker: undefined,
			isLoaded: false,
		};
	},
	async created() {
		await this.loadDependencies();
	},
	methods: {
		async loadDependencies() {
			if (this.isLoaded) {
				return;
			}

			await Promise.all([
				initGSIClient(),
				load("picker"),
				loadClient("drive", "v3"),
			]);

			this.isLoaded = true;
		},
		handleError(error) {
			if (error?.result?.error?.code === 401) {
				// clear locally cached values since they may be stale and should be refreshed on next pass
				this.picker = null;
				this.accessToken = null;
			}
			Sentry.captureException(error);
			this.$emit("error", error);
		},
		async handleDocsPicked(docs) {
			try {
				this.$emit("change-start");
				const files = await Promise.all(docs.map(async(doc) => {
					const isGoogleFileType = doc.mimeType.includes("application/vnd.google-apps.");
					const mimeType = isGoogleFileType ? "application/pdf" : doc.mimeType;
					const name = isGoogleFileType ? `${doc.name}.pdf` : doc.name;

					// validate early to avoid fetching a file we can't use
					const pseudoFile = {
						...doc,
						mimeType,
						name,
					};
					this.docValidator?.(pseudoFile);

					const exportedFile = await (isGoogleFileType
						// convert from Google Doc/Sheet/Slides to PDF
						? window.gapi.client.drive.files.export({
							fileId: doc.id,
							mimeType,
						})
						// retrieve raw file contents
						: window.gapi.client.drive.files.get({
							fileId: doc.id,
							alt: "media",
						})
					);

					// convert from binary string to File object
					const bits = getBitsFromBinaryString(exportedFile.body);
					const blob = new Blob([bits], { type: mimeType });
					const file = new File([blob], name);

					file.originalType = doc.mimeType;
					return file;
				}));
				this.$emit("change", this.multiple ? files : files[0]);
			} catch (error) {
				this.handleError(error);
			} finally {
				this.$emit("change-end");
			}
		},
		handlePickerEvent(data) {
			if (data[window.google.picker.Response.ACTION] === window.google.picker.Action.PICKED) {
				this.handleDocsPicked(data[window.google.picker.Response.DOCUMENTS]);
			} else if (data[window.google.picker.Response.ACTION]) {
				this.$emit(data[window.google.picker.Response.ACTION], data);
			}
		},
		async authorize(config = {}) {
			const authResponse = await new Promise(async(resolve, reject) => {
				const auth = await window.google.accounts.oauth2.initTokenClient({
					client_id: process.env.MIX_GOOGLE_CLIENT_ID,
					scope: "https://www.googleapis.com/auth/drive.readonly",
					callback: resolve,
					error_callback: reject,
				});

				await auth.requestAccessToken({
					...this.authConfig,
					...config,
				});
			});

			this.accessToken = authResponse.access_token;

			return authResponse;
		},
		async trigger() {
			try {
				if (!this.picker) {
					const accessToken = this.accessToken || (await this.authorize()).access_token;
					const acceptableMimeTypes = this.mimeTypes.join();
					const view = new window.google.picker.DocsView().setMimeTypes(acceptableMimeTypes);
					this.picker = new window.google.picker.PickerBuilder()
						.addView(view)
						.setOAuthToken(accessToken)
						.enableFeature(window.google.picker.Feature.NAV_HIDDEN)
						.enableFeature(this.multiple ? window.google.picker.Feature.MULTISELECT_ENABLED : undefined)
						.setSelectableMimeTypes(acceptableMimeTypes)
						.setCallback(this.handlePickerEvent)
						.build();
				}
				this.picker.setVisible(true);
			} catch (error) {
				this.handleError(error);
			}
		},
	},
	render() {
		return this.$scopedSlots?.default?.({
			...this.$props,
			...this.$data,
			trigger: this.trigger,
		});
	},
};
</script>
