var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "subject-card",
          expression: "'subject-card'",
        },
      ],
      class: {
        "tw-border-origami-grey-100 tw-border-l-2 tw-border-r-2":
          _setup.state.isExpanded,
      },
    },
    [
      _c(
        "section",
        {
          staticClass:
            "tw-py-5 tw-px-3 tw-flex tw-items-center tw-w-full tw-justify-between",
          class: {
            "tw-bg-origami-grey-100": _setup.state.isExpanded,
          },
        },
        [
          _c(_setup.OrigamiIconButton, {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "service-analytics-subject-card-expand-button",
                expression: "'service-analytics-subject-card-expand-button'",
              },
              {
                name: "data-cy",
                rawName: "v-data-cy",
                value: "service-analytics-subject-card-expand-button",
                expression: "'service-analytics-subject-card-expand-button'",
              },
            ],
            staticClass: "!tw-p-0 !tw-text-origami-blue-300 tw-mr-2",
            attrs: { "icon-name": _setup.chevronDirection },
            nativeOn: {
              click: function ($event) {
                return _setup.toggleExpand.apply(null, arguments)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tw-flex tw-items-center tw-w-full" },
            [
              _vm._l(_setup.requirementTags, function (tag) {
                return _c(_setup.SubjectFilterTag, {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value:
                        "service-analytics-subject-card-filter-tag-container",
                      expression:
                        "'service-analytics-subject-card-filter-tag-container'",
                    },
                    {
                      name: "data-cy",
                      rawName: "v-data-cy",
                      value:
                        "service-analytics-subject-card-filter-tag-container",
                      expression:
                        "'service-analytics-subject-card-filter-tag-container'",
                    },
                  ],
                  key: tag.name,
                  attrs: { "filter-tag-name": tag.name },
                })
              }),
              _vm._v(" "),
              _c(_setup.ServiceAnalyticsSubjectCardDetails, {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "service-analytics-subject-card-details-container",
                    expression:
                      "'service-analytics-subject-card-details-container'",
                  },
                  {
                    name: "data-cy",
                    rawName: "v-data-cy",
                    value: "service-analytics-subject-card-details-container",
                    expression:
                      "'service-analytics-subject-card-details-container'",
                  },
                ],
                attrs: {
                  subject: _setup.props.subject,
                  "risk-level": _vm.riskLevel,
                },
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "tw-flex" },
        [
          _c(_setup.ServiceAnalyticsSubjectTutorLoadGraph, {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _setup.state.isExpanded,
                expression: "state.isExpanded",
              },
              {
                name: "jest",
                rawName: "v-jest",
                value: "service-analytics-graph-container",
                expression: "'service-analytics-graph-container'",
              },
            ],
            staticClass: "tw-py-9 tw-h-96 tw-w-1/2",
            attrs: { "time-series": _setup.props.subject.time_series },
          }),
          _vm._v(" "),
          _setup.state.isExpanded
            ? _c(_setup.ServiceAnalyticsSubjectTutorLoadLeaderboard, {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "service-analytics-tutor-load-leaderboard",
                    expression: "'service-analytics-tutor-load-leaderboard'",
                  },
                ],
                staticClass: "tw-py-9 tw-h-96 tw-w-1/2",
                attrs: {
                  subject: _setup.props.subject.subject,
                  "is-leaderboard-loaded": _setup.state.isLeaderboardLoaded,
                },
                on: { "sort-tutor-load": _setup.updateSorting },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }