export default {
	methods: {
		/**
		 * Creates an object of params taken from the current window URL
		 * @returns {Object}
		 */
		getParamsFromUrl() {
			const params = {};
			const urlParams = new URL(document.location).searchParams;
			urlParams.forEach(function(value, key) {
				try {
					params[key] = JSON.parse(value);
				} catch (e) {
					params[key] = value;
				}
			});
			return params;
		},
		/**
		 * Transforms object of params and puts them into the window URL as search params
		 * @param {Object} params
		 */
		getUrlFromParams(params) {
			let queryString = "?";
			Object.keys(params).forEach((key) => {
				if (params[key] !== null && (params[key].length > 0 || params[key] > 0)) {
					queryString += `${key}=${JSON.stringify(params[key])}&`;
				}
			});
			const url = window.location.origin + window.location.pathname + queryString.slice(0, -1);
			return url;
		},
	},
};
