var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "userSettings tw-flex tw-flex-col tw-my-5 tw-mx-auto tw-w-[1000px] tw-max-w-full",
    },
    [
      _c(
        "h1",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "settings-title",
              expression: "'settings-title'",
            },
          ],
          staticClass: "tw-font-xl tw-pl-5 tw-mb-5 tw-text-origami-blue-300",
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.$t("sidebar.my_account")) + "\n\t")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tw-flex tw-flex-col md:tw-flex-row md:tw-items-start" },
        [
          _c("UserSettingsSidebar", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "settings-sidebar",
                expression: "'settings-sidebar'",
              },
            ],
            attrs: { tabs: _vm.tabs },
          }),
          _vm._v(" "),
          _c(_vm.settingsComponent, { tag: "component" }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }