var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.notificationCount !== 0
    ? _c(
        "div",
        { staticClass: "redPill", class: { mobilePill: _vm.isMobile } },
        [
          _c(
            "p",
            { staticClass: "text", class: { mobileText: _vm.isMobile } },
            [_vm._v("\n\t\t" + _vm._s(_vm.numberToDisplay) + "\n\t")]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }