export default [
	{
		path: "districts",
		component: () => import("../../components/districts/DistrictsView.vue"),
		children: [
			{
				path: "",
				name: "superuserDistricts",
				component: () => import("../../components/districts/DistrictsListWrapper.vue"),
			},
			{
				path: "create",
				name: "superuserDistrictsCreate",
				component: () => import("../../components/districts/DistrictCreateWrapper.vue"),
			},
			{
				path: ":districtId",
				name: "superuserDistrictsEdit",
				component: () => import("../../components/districts/DistrictEditWrapper.vue"),
			},
		],
	},
];
