<template>
	<nav
		v-click-outside="() => isResourceHubOpen = false"
		v-jest="'resource-hub-dropdown'"
		aria-label="Resources"
		class="tw-relative tw-z-10 tw-hidden xl:tw-flex"
	>
		<button
			ref="dropdownToggle"
			v-jest="'dropdown-toggle'"
			class="resources-toggle tw-text-origami-white tw-font-bold tw-flex tw-items-center"
			aria-controls="resourcesHubDropdown"
			:aria-expanded="isResourceHubOpen.toString()"
			@click="isResourceHubOpen = !isResourceHubOpen"
			@keydown.shift.tab="isResourceHubOpen = false"
			@keydown.esc="isResourceHubOpen = false"
		>
			{{ $t("navbar.resources") }}
			<OrigamiIcon
				name="caret"
				class="tw-ml-2"
				:class="{ 'tw-rotate-180': isResourceHubOpen }"
			/>
		</button>
		<transition name="fade">
			<ul
				v-show="isResourceHubOpen"
				id="resourcesHubDropdown"
				v-jest="'dropdown-list'"
				class="resources-dropdown"
			>
				<li
					v-for="(resourceLink, index) in resourcesLinks"
					:key="resourceLink.title"
					v-jest="'dropdown-list-item'"
					:class="{ 'tw-mb-6': index < resourcesLinks.length - 1 }"
					@click="isResourceHubOpen = false"
					@keydown.esc="focusDropdownToggle()"
					@keydown.tab.exact="index === resourcesLinks.length - 1 && (isResourceHubOpen = false)"
				>
					<a
						v-jest="`dropdown-link-${resourceLink.title}`"
						:href="resourceLink.target"
						target="_blank"
						class="tw-flex tw-items-center tw-text-base"
					>
						{{ $t(resourceLink.title) }}
						<OrigamiIcon
							name="external"
							class="ml-2"
						/>
					</a>
				</li>
			</ul>
		</transition>
	</nav>
</template>

<script setup>
import { ref } from "vue";
import { OrigamiIcon } from "@origami/vue2";

const isResourceHubOpen = ref(false);
const dropdownToggle = ref(null);
const resourcesLinks = [
	{
		title: "navbar.resourceCenter",
		target: "https://sites.google.com/paper.co/resourcehub",
	},
	{
		title: "navbar.paperAcademy",
		target: "https://academy.paper.co/",
	},
];
const focusDropdownToggle = () => {
	isResourceHubOpen.value = false;
	dropdownToggle.value.focus();
};
</script>

<style scoped>
.resources-toggle {
	border-radius: 5px;
}
.resources-toggle:focus-visible {
	outline: 2px solid var(--origami-blue-200);
}
.resources-toggle:hover {
	background: #434B8B;
}
.resources-dropdown {
	@apply tw-px-4 tw-py-6 tw-top-[3rem] tw-w-[16rem] tw-absolute tw-right-0 tw-text-black-high tw-shadow-elevation-2 tw-bg-white-high tw-rounded-lg;
}
.fade-enter-active,
.fade-leave-active {
	transition: opacity .3s;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
