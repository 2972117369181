<template>
	<div class="tw-bg-origami-page-bg tw-border tw-border-origami-grey-200 tw-rounded-2xl tw-px-4 tw-py-2 tw-pb-4 tw-bg-origami-white">
		<div class="tw-flex tw-justify-between tw-border-b tw-border-origami-grey-200 tw-pb-2 tw-items-center">
			<h2
				v-jest="'student-essay-name'"
				class="tw-font-bold tw-text-origami-text-black tw-truncate tw-flex-grow"
			>
				{{ `${student.first_name}'s submission: ${essay.title}` }}
			</h2>
			<div
				v-if="isEssayPickedUp"
				class="tw-flex tw-flex-row tw-items-center"
			>
				<h2
					v-jest="'student-essay-file-name'"
					class="tw-font-bold tw-text-origami-text-black tw-truncate"
				>
					#{{ essay.id }}
				</h2>
				<OrigamiIconButton
					v-jest="'copy-button'"
					aria-label="copy"
					icon-name="copy"
					@click.native="copyFileNameToClipboard"
				/>
			</div>
			<div
				v-if="!isEssayPickedUp"
				ref="infoCircle"
				v-jest="'info-circle'"
			>
				<InfoToolTip
					v-jest="'info-hover'"
					:width="'20'"
					:height="'20'"
					:options="tooltipOptions"
					:icon-color="'#0E0FE0'"
				/>
			</div>
		</div>
		<div class="tw-flex tw-flex-col tw-justify-start tw-items-stretch tw-gap-y-8 tw-py-8">
			<div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-4 tw-w-full tw-gap-x-4 tw-gap-y-4 tw-flex-wrap">
				<div class="contentHeading__large">
					<h5
						v-jest="'grade-heading'"
						class="tw-text-origami-text-black tw-text-sm tw-font-bold"
					>
						{{ student.first_name }}'s Grade Level
					</h5>
					<p
						v-jest="'grade-level'"
						class="tw-text-2xl tw-font-bold tw-truncate"
					>
						{{ gradeText }}
					</p>
				</div>
				<div class="contentHeading__large">
					<h5 class="tw-text-origami-text-black tw-text-sm tw-font-bold">
						Submission language
					</h5>
					<p
						v-jest="'student-language'"
						class="tw-text-2xl tw-font-bold"
					>
						{{ languageText }}
					</p>
				</div>
				<div class="contentHeading__large">
					<h5 class="tw-text-origami-text-black tw-text-sm tw-font-bold">
						Submission type
					</h5>
					<p
						v-jest="'submission-type'"
						class="tw-text-2xl tw-font-bold"
					>
						{{ submissionType }}
					</p>
				</div>
			</div>
			<div class="tw-w-full tw-flex tw-justify-between tw-gap-x-4 tw-gap-y-4 tw-flex-wrap">
				<div class="commentContainer tw-min-w-0">
					<h5
						v-jest="'teacher-instructions-header'"
						class="tw-text-origami-text-black tw-text-sm tw-font-bold"
					>
						{{ student.first_name }}'s Teacher's Instructions
					</h5>
					<p
						v-jest="'teacher-instructions-text'"
						class="tw-text-origami-text-black word-wrap"
					>
						{{ essay.teacher_instruction }}
					</p>
					<div v-if="essay.teacherInstructionFiles?.data.length">
						<div
							v-for="(file, index) in essay.teacherInstructionFiles.data"
							:key="index"
							class="tw-text-sm tw-text-origami-blue-300"
						>
							<a
								:href="file.filepath"
								class="tw-underline word-wrap"
								download
							>
								<OrigamiIcon
									name="essay-outlined"
									width="14"
									height="14"
								/>
								{{ file.basename }}
							</a>
						</div>
					</div>
				</div>
				<div class="commentContainer tw-min-w-0">
					<h5
						v-jest="'student-comment-header'"
						class="tw-text-origami-text-black tw-text-sm tw-font-bold"
					>
						{{ student.first_name }}'s comments
					</h5>
					<p
						v-jest="'student-comments'"
						class="tw-text-origami-text-black word-wrap"
					>
						{{ essay.student_comment }}
					</p>
				</div>
			</div>
			<div class="tw-w-full tw-flex tw-justify-end tw-items-stretch tw-gap-x-4">
				<div class="commentContainer tw-min-w-0">
					<p
						v-if="isShowingTriggerWarning"
						v-jest="'trigger-warning'"
						class="tw-text-origami-text-low-emphasis tw-text-base tw-hidden"
					/>
				</div>
			</div>
		</div>
		<div class="previewFooter tw-flex tw-justify-between tw-items-stretch tw-gap-x-4 tw-gap-y-4 tw-pt-4 tw-flex-nowrap tw-w-full tw-flex-col md:tw-flex-row">
			<div class="previewFooter__info tw-flex tw-justify-center tw-items-center tw-gap-x-2 tw-gap-y-2 tw-flex-wrap tw-max-w-full md:tw-justify-start lg:tw-justify-between lg:tw-flex-nowrap">
				<span
					v-jest="'essay-id'"
					class="tw-text-origami-text-low-emphasis tw-text-xs tw-flex-shrink-0"
				>#{{ essay.id }}</span>
				<span
					v-jest="'essay-created-date'"
					class="tw-text-origami-text-low-emphasis tw-text-xs tw-truncate tw-flex-shrink-0"
				>{{ essayCreatedDate }}</span>
				<span
					v-jest="'essay-file-name'"
					class="tw-text-origami-text-low-emphasis tw-text-xs tw-flex-grow tw-overflow-hidden tw-text-center tw-p-1md:tw-text-left lg:tw-p-3"
				>
					<BaseLink
						v-jest="'download-essay-file'"
						:to="downloadEssayLink"
						class="word-wrap tw-flex tw-flex-row tw-items-center tw-gap-x-1 tw-text-origami-blue-300 tw-justify-center md:tw-justify-start"
					>
						<IconDownload
							:width="'13'"
							:height="'13'"
							icon-color="currentColor"
						/>
						<span class="tw-underline ">{{ essay.filename }}</span>
					</BaseLink>
				</span>
			</div>
			<div
				v-if="!isEssayPickedUp"
				class="previewFooter__buttons tw-flex tw-flex-row tw-justify-center md:tw-justify-end tw-items-center tw-gap-x-2 tw-gap-y-2 tw-flex-nowrap tw-flex-shrink-0"
			>
				<BaseButton
					v-jest="'pass-button'"
					:is-disabled="isPickingUpEssay"
					type="CANCEL"
					class="tw-w-28 tw-shadow-elevation-2"
					@click.native="rejectEssay"
				>
					<template #text>
						Pass on
					</template>
				</BaseButton>
				<BaseButton
					v-jest="'pick-up'"
					:is-disabled="isPickingUpEssay"
					class="tw-w-28 tw-shadow-elevation-2"
					@click.native="pickUpEssay"
				>
					<template #text>
						<LoadingRectangles
							v-if="isPickingUpEssay"
							v-jest="'loading-rectangles'"
						/>
						<span v-else>Pick up</span>
					</template>
				</BaseButton>
			</div>
			<div v-else>
				<BaseButton
					v-jest="'re-download-btn'"
					class="tw-w-28 tw-shadow-elevation-2"
					@click.native="downloadEssay"
				>
					<template #text>
						Re-download
					</template>
				</BaseButton>
			</div>
		</div>
	</div>
</template>

<script>
import { format } from "date-fns";
import { OrigamiIconButton, OrigamiIcon } from "@origami/vue2";

import BaseButton from "@/components/BaseButton.vue";

import { essayInclude } from "../store/Essay.js";
//Api
import EssaysAPI from "../../../services/api/Essays.js";
//helpers
import { formatTimestampToDate } from "../../../utilities/dateHelpers.js";
import { checkIsObjectKeysPresentAndRightType } from "../../../utilities/propValidationHelpers.js";
import { downloadEssay } from "../../../utilities/essayHelpers.js";
import { showErrorModal } from "../../../utilities/errorHandlingHelpers.js";
//components
import InfoToolTip from "../../../components/patterns/InfoToolTip.vue";
import IconDownload from "../../../components/icons/IconDownload.vue";
import LoadingRectangles from "../../../components/LoadingRectangles.vue";
import DispatchErrorHandlingMixin from "../../../mixins/DispatchErrorHandlingMixin.js";

const requiredKeys = [
	{ property: "created_at", type: ["number"] },
	{ property: "id", type: ["number"] },
	{ property: "student", type: ["object"], subKeys: [
		{ property: "data", type: ["object"], subKeys: [
			{ property: "first_name", type: ["string"] },
			{ property: "id", type: ["number"] },
			{ property: "last_name", type: ["string"] },
		] },
	] },
	{ property: "student_comment", type: ["string", null] },
	{ property: "teacher_instruction", type: ["string", null] },
	{ property: "title", type: ["string"] },
];
const GOOGLE_ESSAYS_BUCKET = process.env.MIX_GOOGLE_ESSAYS_BUCKET;
const THIRTEEN_MINUTES = 780000;
export default {
	components: {
		IconDownload,
		InfoToolTip,
		LoadingRectangles,
		OrigamiIconButton,
		OrigamiIcon,
		BaseButton,
	},
	mixins: [DispatchErrorHandlingMixin],
	props: {
		essay: {
			type: Object,
			required: true,
			validator: (item) => checkIsObjectKeysPresentAndRightType(item, requiredKeys),
		},
	},
	data() {
		return {
			EssayUrlTimeout: null,
			isPickingUpEssay: false,
			tooltipOptions: {
				allowHTML: true,
				arrow: true,
				content: `<span class="tw-text-sm tw-w-96">For a limited amount of time, here is a preview of the next writing submission ready for review. Please select "Pick up" as quickly as possible. The "Pass on" button will pass the submission to another tutor; please use this function as infrequently as possible.</span>`,
				hideOnClick: false,
				maxWidth: 305,
				placement: "left",
				popperOptions: { strategy: "fixed" },
				trigger: "mouseenter focus",
			},
		};
	},
	computed: {
		student() {
			return this.essay.student.data;
		},
		gradeText() {
			return this.student.student?.data?.grade?.data?.name ?
				`Grade ${this.student.student.data.grade.data.name}` :
				"Unknown";
		},
		languageText() {
			return this.essay.language?.data?.language ?? this.student.preferredLanguage.data.language ?? "Unknown";
		},
		submissionType() {
			return this.essay.reviewSubmissionType?.data?.name || "Unknown";
		},
		isShowingTriggerWarning() {
			return false;
		},
		essayCreatedDate() {
			const date = formatTimestampToDate(this.essay.created_at);
			return format(date, "d MMMM yyyy 'at' h:mmbbb");
		},
		essayBucketEssayUrl() {
			return `https://storage.googleapis.com/${GOOGLE_ESSAYS_BUCKET}/${this.essay.student.data.id}/${this.essay.filename}`;
		},
		downloadEssayLink() {
			return this.essay.file_data?.filepath;
		},
		isEssayPickedUp() {
			return this.essay.picked_up_at;
		},
	},
	async created() {
		this.EssayUrlTimeout = setTimeout(this.retrieveSignedURLs, THIRTEEN_MINUTES);
	},
	beforeDestroy() {
		clearTimeout(this.EssayUrlTimeout);
	},
	methods: {
		copyFileNameToClipboard() {
			navigator.clipboard.writeText(`${this.essay.id}_${this.essay.filename}`);
		},
		async retrieveSignedURLs() {
			await this.handleDispatch("Essay/getInProgressEssay");
		},
		async downloadEssay() {
			await downloadEssay(this.essay);
		},
		async pickUpEssay() {
			this.isPickingUpEssay = true;
			try {
				const response = await EssaysAPI.essayPickUp({
					include: essayInclude,
				});
				const payload = {
					id: response.data.data.id,
				};
				await this.handleDispatch("Essay/pickupEssay", payload, "picking up the reserved essay");
				await downloadEssay(this.essay);
				this.$store.commit("Essay/SET_ESSAY", {
					essay: response.data.data,
				});
				this.$store.commit("Essay/SET_RESERVED_ESSAY", {
					essay: null,
				});
			} catch (error) {
				Sentry.captureException(error);
				const options = {
					message: `There was an issue reserving an essay for you. More details follow. Please refresh the page and if the issue persists contact a platform manager.
								Reason: ${error.response.data.error_description}`,
					buttonText: "Refresh",
					eventFired: () => {
						location.reload();
					},
				};
				showErrorModal(this, options);
			}
			this.isPickingUpEssay = false;
		},
		rejectEssay() {
			this.$bvModal.show("skip-essay-pickup");
		},
	},
};
</script>

<style scoped>
.commentContainer {
	flex-basis: 100%;
	min-width: 220px;
}

.word-wrap {
	word-wrap: break-word;
}

.previewFooter__buttons {
	flex-basis: 100%;
}

@media (min-width: 1024px) {
	.previewFooter__info {
		max-width: calc(100% - 1rem - 15rem);
	}
}

@media (min-width: 867px) {
	.commentContainer {
		flex-basis: calc(50% - 1rem);
		min-width: default;
	}
}

@media (min-width: 768px) {
	.previewFooter__buttons {
		flex-basis: 15rem;
	}
}
</style>
