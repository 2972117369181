<template>
	<BModal
		id="tutorDashboardExpiredShiftTransferModal"
		content-class="bootstrapFourModalContent"
		body-class="bootstrapFourModalBody"
		footer-class="bootstrapFourModalFooter"
		:hide-header="true"
	>
		<button
			class="bootstrapFourModalCloseButton"
			@click="hideModal()"
		>
			&times;
		</button>
		<div class="expiredShiftTransferModal__header">
			<IconCircleX class="xSvg" />
			<div class="expiredShiftTransferModal__title">
				Shift transfer expired
			</div>
		</div>
		<div class="expiredShiftTransferModal__content">
			<div class="expiredShiftTransferModal__text">
				The transfer request has expired.
			</div>
			<div class="expiredShiftTransferModal__text">
				<strong>The following shift is no longer available to be transferred.</strong>
			</div>
			<div class="expiredShifteTransferModal__text">
				Please note that shift transfer requests expire when they are accepted by one of your peers
				or 24 hours before the scheduled time.
			</div>
			<div class="expiredShiftTransferModal__shiftDetails">
				<div class="expiredShiftTransferModal__text expiredShiftTransferModal__text--redSmaller">
					{{ shiftDate }}
				</div>
				<div class="expiredShiftTransferModal__text expiredShiftTransferModal__text--redSmaller">
					{{ shiftStartTime }} to {{ shiftEndTime }}
				</div>
			</div>
		</div>
		<template slot="modal-footer">
			<BaseButton
				type="CANCEL"
				@click.native="hideModal()"
			>
				<template slot="text">
					Close
				</template>
			</BaseButton>
		</template>
	</BModal>
</template>

<script>
import { format } from "date-fns";

import IconCircleX from "@/components/icons/IconCircleX.vue";
import BaseButton from "@/components/BaseButton.vue";

export default {
	components: {
		IconCircleX,
		BaseButton,
	},
	props: {
		shift: {
			type: Object,
			required: false,
			default() {
				return {};
			},
		},
	},
	computed: {
		/**
		 * @returns {Boolean}
		 */
		isShiftPropPresent() {
			return this.shift !== null && this.shift.hasOwnProperty("start_time");
		},
		/**
		 * @returns {String}
		 */
		shiftDate() {
			if (this.isShiftPropPresent) {
				return format(new Date(this.shift.start_time * 1000), "EEEE, MMMM d, yyyy");
			}
			return "";
		},
		/**
		 * @returns {String}
		 */
		shiftStartTime() {
			if (this.isShiftPropPresent) {
				return format(new Date(this.shift.start_time * 1000), "h:mm aaa");
			}
			return "";
		},
		/**
		 * @returns {String}
		 */
		shiftEndTime() {
			if (this.isShiftPropPresent) {
				return format(new Date(this.shift.end_time * 1000), "h:mm aaa");
			}
			return "";
		},
	},
	methods: {
		hideModal() {
			this.$bvModal.hide("tutorDashboardExpiredShiftTransferModal");
			this.$router.replace("/tutor");
		},
	},
};
</script>

<style scoped>
.expiredShiftTransferModal__header {
	justify-content: flex-start;
	display: flex;
	align-items: center;
	border: none;
	padding: 0;
}
.xSvg {
	margin-right: 16px;
}
.expiredShiftTransferModal__title {
	font-size: 20px;
	font-weight: 600;
	color: var(--c-blue);
}
.expiredShiftTransferModal__content {
	font-size: 14px;
	color: var(--c-black);
	margin-top: 26px;
}
.expiredShiftTransferModal__text {
	font-size: 16px;
	font-weight: normal;
	color: var(--c-black);
	margin: 6px 0;
}
.expiredShiftTransferModal__text--redSmaller {
	color: #f17b7d;
	font-size: 14px;
	font-weight: 600;
}
.expiredShiftTransferModal__shiftDetails {
	margin: auto;
	margin-top: 16px;
	padding: 10px;
	width: max-content;
	border-radius: 5px;
	border: solid 2px #f17b7d;
	text-align: center;
	color: #f17b7d;
}
.bootstrapFourModalCloseButton {
	border: none;
	padding: 0px;
	position: absolute;
	font-size: 30px;
	line-height: 15px;
	text-align: center;
	right: 24px;
	top: 24px;
	font-weight: 400;
	opacity: 0.6;
}
:deep(.bootstrapFourModalContent) {
	border-radius: 10px;
	background-color: var(--c-white);
}
:deep(.bootstrapFourModalBody) {
	padding: 48px;
	position: relative;
}
:deep(.bootstrapFourModalFooter) {
	padding: 20px 48px;
	background-color: var(--c-light-gray);
}
</style>
