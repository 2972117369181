import { render, staticRenderFns } from "./TrophyNotification.vue?vue&type=template&id=18f24332&scoped=true&"
import script from "./TrophyNotification.vue?vue&type=script&lang=js&"
export * from "./TrophyNotification.vue?vue&type=script&lang=js&"
import style0 from "./TrophyNotification.vue?vue&type=style&index=0&id=18f24332&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18f24332",
  null
  
)

export default component.exports