<template>
	<div class="tw-text-origami-sm tw-font-bold">
		<slot name="breakDetails" />
	</div>
</template>

<script>
export default {
	name: "BreakDetails",
};
</script>
