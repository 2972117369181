var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c(
      "div",
      { staticClass: "tw-max-w-[1440px] tw-m-auto tw-px-8" },
      _vm._l(_setup.state.cards, function (card) {
        return _c(_setup.RiskCard, {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: `risk-card-${card.riskLevel}`,
              expression: "`risk-card-${card.riskLevel}`",
            },
          ],
          key: card.id,
          attrs: {
            id: `risk-card-${card.riskLevel}`,
            "data-cy": `risk-card-${card.riskLevel}`,
            card: card,
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }