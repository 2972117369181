export default [
	{
		path: "sections",
		component: () => import(
			"../../components/sections/SectionsView.vue"
		),
		children: [
			{
				name: "superuserSections",
				path: "",
				component: () => import("../../components/sections/SectionsListWrapper.vue"),
			},
			{
				path: "create",
				name: "superuserSectionsCreate",
				component: () => import("../../components/sections/SectionCreateWrapper.vue"),
			},
			{
				path: ":sectionId",
				name: "superuserSectionsId",
				component: () => import("../../components/sections/SectionEditWrapper.vue"),
			},
		],
	},
];
