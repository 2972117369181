var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tw-pt-3.5" },
    [
      _c("ServiceScheduleHeader", {
        attrs: {
          "aggregate-shift-hours": _vm.aggregateShiftHours,
          "is-showing-shift-hours": _vm.isShowingShiftHours,
          "created-shift-tutor-data": _vm.createdShiftTutorData,
        },
        on: {
          "open-create-shift-modal": _vm.openCreateShiftModal,
          "set-shift-filter": _vm.setShiftFilter,
          "clear-filters": _vm.clearFilters,
          "set-selected-tutors-list": _vm.setSelectedTutorsList,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tw-flex tw-justify-center" },
        [
          _c("ServiceScheduleNoFilterBanner", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isShowingCalendar,
                expression: "!isShowingCalendar",
              },
            ],
            staticClass: "tw-w-3/5",
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("ServiceScheduleCalendar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowingCalendar,
            expression: "isShowingCalendar",
          },
        ],
        ref: "serviceScheduleCalendar",
        attrs: { "formatted-shifts": _vm.formattedShifts },
        on: {
          "edit-shift": _vm.showEditShiftModal,
          "week-change": _vm.displayFilteredShifts,
          "set-week-dates": _vm.setWeekDates,
        },
      }),
      _vm._v(" "),
      _c("ServiceScheduleCreateShiftModal", {
        attrs: { start: _vm.startTime, end: _vm.endTime },
        on: { shiftCreated: _vm.handleShiftCreated },
      }),
      _vm._v(" "),
      _c("ServiceScheduleEditShiftModal", {
        attrs: { "shift-object": _vm.shiftObject },
        on: { shiftEdited: _vm.updateFormattedShifts },
      }),
      _vm._v(" "),
      _c("ServiceScheduleDeleteShiftModal", {
        attrs: { shift: _vm.shiftObject },
        on: { shiftDeleted: _vm.updateFormattedShifts },
      }),
      _vm._v(" "),
      _c("ServiceScheduleFooter", {
        attrs: {
          "is-showing-calendar": _vm.isShowingCalendar,
          "total-language-hours": _vm.totalLanguageHours,
          "total-subject-hours": _vm.totalSubjectHours,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }