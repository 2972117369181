var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "jest",
          rawName: "v-jest",
          value: "drilldown",
          expression: "'drilldown'",
        },
      ],
      ref: "drilldown",
      staticClass: "tw-flex tw-fixed tw-inset-0 tw-z-50 tw-transform",
      class: _vm.isDrilldownOpen ? "tw-translate-x-0" : "tw-translate-x-full",
      attrs: { "aria-hidden": "true", "aria-labelledby": "drilldown-title" },
    },
    [
      _c("div", {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "drilldown-underlay",
            expression: "'drilldown-underlay'",
          },
        ],
        staticClass: "tw-fixed tw-inset-0 tw-bg-blue-dark tw-bg-opacity-80",
        attrs: { "data-a11y-dialog-hide": "" },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "drilldown-content",
              expression: "'drilldown-content'",
            },
          ],
          staticClass:
            "tw-fixed tw-grid tw-h-screen tw-top-0 tw-right-0 tw-bg-origami-white tw-max-w-screen-md tw-w-full tw-overflow-y-scroll tw-duration-700 tw-transform",
          class: _vm.isDrilldownOpen
            ? "tw-translate-x-0"
            : "tw-translate-x-full",
          staticStyle: { "grid-template-rows": "5rem auto" },
          attrs: { tabindex: "0", role: "document" },
        },
        [
          _c(
            "header",
            {
              staticClass:
                "tw-border-b-2 tw-border-origami-grey-200 tw-w-full tw-p-5 tw-flex tw-justify-between tw-items-center",
            },
            [
              _c(
                "h1",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "drilldown-title",
                      expression: "'drilldown-title'",
                    },
                  ],
                  staticClass: "tw-text-xl tw-font-bold tw-tracking-wide",
                  attrs: { id: "drilldown-title" },
                },
                [_vm._v("\n\t\t\t\t" + _vm._s(_vm.title) + "\n\t\t\t")]
              ),
              _vm._v(" "),
              _c(
                "BaseIconButton",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "close-button",
                      expression: "'close-button'",
                    },
                    {
                      name: "data-cy",
                      rawName: "v-data-cy",
                      value: "close-button",
                      expression: "'close-button'",
                    },
                  ],
                  attrs: {
                    "aria-label": "Close dialog",
                    "data-a11y-dialog-hide": "",
                  },
                },
                [_c("CloseIcon")],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }