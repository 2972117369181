import { initApiCache } from "./apiCache.js";

const apiCache = initApiCache();

export const getSubjects = () => apiCache.get("/subject");

export const getTopics = (id) => apiCache.get(`/subject/${id}/topic`);

// TODO: rename this call  (without 1)
export const getSubTopics1 = (subId, id) => apiCache.get(`/subject/${subId}/topic/${id}/sub-topic`);

export const getStatusList = () => apiCache.get(`/problem-status`);
