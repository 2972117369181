var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "header",
    {
      staticClass:
        "tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-max-w-[1440px] tw-m-auto tw-px-8 tw-py-4",
      attrs: { "data-cy": "page-header" },
    },
    [
      _c("div", [
        _c(
          "h1",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "header-text",
                expression: "'header-text'",
              },
            ],
            staticClass: "tw-text-origami-lg tw-font-origami-bold",
            attrs: { "data-cy": "page-header-title" },
          },
          [_vm._v("\n\t\t\tPlatform Load Alerts\n\t\t")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            _setup.OrigamiButton,
            {
              directives: [
                {
                  name: "jest",
                  rawName: "v-jest",
                  value: "header-button",
                  expression: "'header-button'",
                },
              ],
              attrs: {
                "data-cy": "page-header-primary-button",
                "is-disabled": _setup.isLoading,
              },
              nativeOn: {
                click: function ($event) {
                  return _setup.handleRefresh.apply(null, arguments)
                },
              },
            },
            [_c("span", [_vm._v("Refresh")])]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }