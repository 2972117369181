var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 56 56",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M34.7635 22.0496C37.4542 21.2883 42.2229 21.389 43.6185 21.4896C46.0598 21.6646 48.501 21.6121 48.921 24.574C49.201 26.569 48.5317 28.3846 45.5742 28.7083C44.5329 28.8221 43.2598 28.8833 42.2098 29.0496L41.3873 29.9552C42.3585 29.824 44.5854 29.8677 45.2548 29.7846C48.081 29.439 50.4698 30.5721 50.8285 33.2846C51.1435 35.6646 49.3498 37.5021 46.8035 37.3621C45.9285 37.314 43.4742 37.6508 41.0329 37.7165L40.8448 38.2108L40.7573 38.3596C42.306 38.4033 43.7892 38.4646 44.8435 38.5171C47.6654 38.6571 49.2185 40.1708 49.166 42.1833C49.1179 44.629 46.8298 45.5477 45.1935 45.6833C42.3398 45.7415 39.485 45.6524 36.6404 45.4165L36.1767 46.2083C36.3867 46.2083 42.026 46.484 42.2404 46.4971C44.826 46.6896 45.9285 47.6258 45.8629 49.4721C45.771 52.0621 43.006 52.749 41.4879 52.9721C39.0598 53.3571 35.8223 53.2433 33.4642 53.309C28.3629 53.449 17.9242 53.6765 13.0504 49.4065C11.3879 47.9496 9.71228 45.9677 8.28603 45.399C5.2979 44.209 5.03978 40.3152 5.1754 35.3933C5.24103 33.0133 4.4229 26.3327 7.3104 24.714C11.4754 22.3777 13.9604 23.314 20.5142 18.9346C22.8898 17.3465 25.0992 15.8721 26.591 13.024C28.6342 9.18708 27.4573 6.40458 29.9948 4.16458C31.4648 2.85208 33.8885 2.01646 35.4898 2.89583C35.8748 3.11021 37.2398 3.99833 37.4629 8.81521C37.581 11.6196 37.3229 16.2877 34.7635 22.0496Z",
          fill: "#FAC036",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M29.7497 33.7497C29.7597 34.5162 29.9661 35.2673 30.349 35.9314C30.732 36.5954 31.2788 37.1502 31.9372 37.5428C30.7779 37.5166 28.2666 38.6934 28.2666 41.1916C28.2666 43.2522 29.3866 44.8578 30.511 45.4047C29.7716 45.5622 28.376 46.5684 28.376 48.7647C28.376 51.9847 30.7647 53.1616 32.5716 53.3847C34.3785 53.6078 39.401 53.3059 41.0591 53.0522C41.9341 52.9166 36.6579 52.8553 32.786 52.3478C32.1333 52.233 31.5307 51.9233 31.0575 51.4594C30.5842 50.9956 30.2625 50.3993 30.1347 49.7491C30.06 49.1806 30.1487 48.6027 30.3904 48.0828C30.6321 47.5629 31.0169 47.1226 31.4997 46.8134C31.4997 46.8134 32.2172 46.1966 35.5466 46.2228H36.5529C36.8679 46.2228 40.8885 46.3847 42.2404 46.4809C42.7592 46.5132 43.2736 46.5967 43.776 46.7303C43.6736 46.5822 43.63 46.4013 43.6535 46.2228C43.7104 45.7153 44.0429 45.6934 44.1304 45.6934L43.3341 45.6497L40.4947 45.5009C37.6685 45.3347 33.6872 45.0634 33.2497 44.8797C33.2497 44.8797 30.476 44.1797 30.476 41.3359C30.476 41.3359 30.3316 38.9166 34.0329 38.8028C34.0329 38.8028 42.7829 38.2997 46.4797 38.8028C46.4797 38.8028 45.9504 37.8184 46.8254 37.3766C46.8254 37.3766 40.5822 37.5297 36.671 37.6347C32.7597 37.7397 31.9241 35.6616 31.8235 34.5284C31.8235 34.3578 31.7754 34.0909 31.7754 33.7672C31.7754 31.5141 33.7529 31.2166 36.5879 30.8491C42.7129 30.0484 45.281 29.7991 45.281 29.7991C45.6017 29.7591 45.9247 29.7401 46.2479 29.7422C46.1341 29.689 46.035 29.6091 45.9588 29.5093C45.8826 29.4095 45.8317 29.2928 45.8104 29.1691C45.8106 29.004 45.8372 28.8401 45.8891 28.6834C45.8891 28.6834 43.2641 28.8978 39.191 29.4403C35.1179 29.9828 31.8847 29.7334 31.5916 27.3578C31.3772 25.6078 31.6704 24.5097 32.4229 23.6478C33.1006 22.9434 33.9272 22.3993 34.8422 22.0553C34.7311 22.0293 34.6323 21.966 34.5622 21.8759C34.5 21.7743 34.4653 21.6582 34.4616 21.5391C31.141 21.9634 28.8747 24.0634 29.0629 26.7716C29.1941 28.6222 29.6841 29.8341 30.9135 30.4553C30.9179 30.4509 29.7016 31.1334 29.7497 33.7497Z",
          fill: "#E48C15",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M31.1104 27.877L29.6579 28.3145C29.6579 28.3145 31.8104 36.2376 23.8654 41.6976C23.8654 41.6976 22.846 42.3801 23.3404 42.892C23.3404 42.892 23.686 43.487 25.1473 42.3713C25.1473 42.3495 32.8998 37.292 31.1104 27.877Z",
          fill: "#E48C15",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }