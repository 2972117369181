import { isAfter, isBefore, parseISO, getUnixTime, addMinutes, differenceInSeconds } from "date-fns";
import { isNil } from "lodash";
import { getFlag } from "@paper-co/web-toolkit";

import {
	ScheduleManagementApi,
	REST_BREAK_ID,
	MEAL_BREAK_ID,
	TUTOR_NEW_ACTIVITY_HOLD,
} from "@/modules/TutorSchedule/index.js";
import SchedulesAPI from "@/services/api/Schedules.js";
import { BreakRequestAPI } from "@/modules/BreaksCenter/index.js";
import { getMinutesBetweenDates } from "@/utilities/dateHelpers.js";

import {
	MEAL_BREAK_LENGTH_MINUTES,
	REST_BREAK_LENGTH_MINUTES,
	UPCOMING_BREAK_THRESHOLD_MINUTES,
} from "../utilities/BreakConfiguration.js";
import {
	BREAK_STEP_EMPTY,
	CANCEL_SCHEDULED_BREAK,
	BIO_BREAK_CONFIRMATION,
	BIO_BREAK_END,
	BIO_BREAK_ACTIVITY_ON_HOLD,
	BIO_BREAK_STEPS,
	REST_BREAK_CONFIRMATION,
	REST_BREAK_REQUEST,
	REST_BREAK_CANCEL,
	REST_BREAK_CANCELLED,
	REST_BREAK_ERROR,
	REST_BREAK_END,
	REST_BREAK_LOCKED,
	REST_BREAK_IN_PROGRESS,
	REST_BREAK_STEPS,
	MEAL_BREAK_REQUEST,
	MEAL_BREAK_PENDING_APPROVAL,
	MEAL_BREAK_REQUEST_REJECTED,
	MEAL_BREAK_CANCELLED,
	MEAL_BREAK_STEPS,
	MEAL_BREAK_REQUEST_APPROVED,
	MEAL_BREAK_UPCOMING,
	MEAL_BREAK_CANCEL,
	MEAL_BREAK_CANCEL_CONFIRM,
	MEAL_BREAK_ERROR,
	MEAL_BREAK_IN_PROGRESS,
	MEAL_BREAK_LATE,
	ACTIVE_MEAL_BREAK_STEPS,
	REQUEST_MEAL_BREAK_STEPS,
	ACTIVE_REST_BREAK_STEPS,
	REQUEST_REST_BREAK_STEPS,
} from "../utilities/BreakWorkflowSteps.js";

const ONE_SECOND_IN_MS = 1000;

const getDefaultState = () => ({
	currentBreakStep: "",
	currentTime: new Date(),
	isRequestLoading: false,
});

const state = getDefaultState();

const requestBreak = async(breakTypeId, startTime, shiftSegmentId) => {
	const breakLength = breakTypeId === REST_BREAK_ID ? REST_BREAK_LENGTH_MINUTES : MEAL_BREAK_LENGTH_MINUTES;

	await BreakRequestAPI.requestBreak({
		schedule_id: shiftSegmentId,
		start_time: getUnixTime(startTime),
		end_time: getUnixTime(addMinutes(startTime, breakLength)),
		schedule_type_id: breakTypeId,
	});
};

const cancelScheduledBreak = async(scheduledBreak, dispatch, commit) => {
	try {
		commit("SET_IS_REQUEST_LOADING", true);

		const gsApiSegmentId = scheduledBreak._metadata?.gs_api_transformer?.id;
		await dispatch("TutorSchedule/setCancelledBreak", { gsApiSegmentId, isCancelledByTutor: true }, { root: true });

		await SchedulesAPI.cancelBreak(gsApiSegmentId);
		setTimeout(async() => {
			await dispatch("TutorSchedule/getCurrentShiftAndState", null, { root: true });
			await dispatch("TutorSchedule/getCurrentUserUpcomingShifts", null, { root: true });
			dispatch("TutorSchedule/resetCancelledBreak", null, { root: true });
			commit("SET_IS_REQUEST_LOADING", false);
			dispatch("resetWorkflow");
		}, ONE_SECOND_IN_MS * 2);
	} catch (e) {
		Sentry.captureException(e);
		dispatch("TutorSchedule/resetCancelledBreak", null, { root: true });
		commit("SET_IS_REQUEST_LOADING", false);
	}
};

const endBreak = async(commit, dispatch) => {
	commit("SET_IS_REQUEST_LOADING", true);

	try {
		const featureFlagDeprecateGSApiPunchCalls = await getFlag("SUP-2782-deprecate-punch-calls-to-gs-api");
		if (featureFlagDeprecateGSApiPunchCalls) {
			await ScheduleManagementApi.punchIn();
		} else {
			await SchedulesAPI.startShift();
		}
		setTimeout(async() => {
			await dispatch("TutorSchedule/getCurrentShiftAndState", null, { root: true });
			commit("SET_IS_REQUEST_LOADING", false);
			dispatch("resetWorkflow");
		}, ONE_SECOND_IN_MS * 2);
	} catch (e) {
		Sentry.captureException(e);
		commit("SET_IS_REQUEST_LOADING", false);
	}
};

const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_BREAK_STEP(state, payload) {
		state.currentBreakStep = payload;
	},
	SET_CURRENT_TIME(state, payload) {
		state.currentTime = payload;
	},
	SET_IS_REQUEST_LOADING(state, payload) {
		state.isRequestLoading = payload;
	},
};

export const actions = {
	async requestBioBreakInActiveClassroom({ commit }) {
		commit("SET_BREAK_STEP", BIO_BREAK_CONFIRMATION);
	},
	async startBioBreak({ state, rootState, commit, dispatch }) {
		if (state.isRequestLoading) {
			return;
		}
		commit("SET_IS_REQUEST_LOADING", true);

		try {
			const currentShift = rootState.TutorSchedule.currentShift;
			const params = {
				action: "start_bio_break",
			};
			const response = await ScheduleManagementApi.startBioBreak(currentShift?.id, params);
			await dispatch("TutorSchedule/getCurrentShift", null, { root: true });
			dispatch("TutorSchedule/updateState", response, { root: true });
			commit("SET_BREAK_STEP",
				response.state_id === TUTOR_NEW_ACTIVITY_HOLD ?
					BIO_BREAK_ACTIVITY_ON_HOLD :
					BIO_BREAK_END,
			);
		} catch (e) {
			Sentry.captureException(e);
		} finally {
			commit("SET_IS_REQUEST_LOADING", false);
		}
	},
	async endBioBreak({ rootState, dispatch }) {
		try {
			const currentState = rootState.TutorSchedule.shiftState;
			const currentShift = rootState.TutorSchedule.currentShift;
			if (currentState?.eligible_actions.hasOwnProperty("return_to_segment")) {
				const params = {
					action: "return_to_segment",
				};
				const response = await ScheduleManagementApi.endBioBreak(currentShift?.id, params);
				dispatch("TutorSchedule/updateState", response, { root: true });
				dispatch("resetWorkflow");
			}
		} catch (e) {
			Sentry.captureException(e);
		}
	},
	holdNewActivityForBio({ commit }) {
		commit("SET_BREAK_STEP", BIO_BREAK_ACTIVITY_ON_HOLD);
	},
	async cancelScheduledRestBreak({ state, rootGetters, dispatch, commit }) {
		if (state.isRequestLoading) {
			return;
		}

		await cancelScheduledBreak(rootGetters["TutorSchedule/getUpcomingRestBreak"], dispatch, commit);
	},
	requestRestBreak({ commit, rootGetters }) {
		if (rootGetters["TutorSchedule/hasRestBreak"]) {
			commit("SET_BREAK_STEP", REST_BREAK_CANCEL);
		} else {
			commit("SET_BREAK_STEP", REST_BREAK_REQUEST);
		}
	},
	async scheduleRestBreak({ state, commit, rootGetters, dispatch }, params) {
		if (state.isRequestLoading) {
			return;
		}

		try {
			commit("SET_IS_REQUEST_LOADING", true);
			const currentShift = rootGetters["TutorSchedule/getCurrentShiftSegment"];

			await requestBreak(REST_BREAK_ID, params.scheduledStartTime, currentShift._metadata.gs_api_transformer.id);

			setTimeout(async() => {
				await dispatch("TutorSchedule/getCurrentShiftAndState", null, { root: true });
				await dispatch("TutorSchedule/getCurrentUserUpcomingShifts", null, { root: true });
				commit("SET_IS_REQUEST_LOADING", false);
				commit("SET_BREAK_STEP", REST_BREAK_CONFIRMATION);
			}, ONE_SECOND_IN_MS * 2);
		} catch (e) {
			Sentry.captureException(e);
			commit("SET_IS_REQUEST_LOADING", false);
			commit("SET_BREAK_STEP", REST_BREAK_ERROR);
		}
	},
	async endRestBreak({ commit, dispatch }) {
		await endBreak(commit, dispatch);
	},
	confirmRestBreak({ commit }) {
		commit("SET_BREAK_STEP", REST_BREAK_CANCEL);
	},
	cancelBreak({ commit }) {
		commit("SET_BREAK_STEP", CANCEL_SCHEDULED_BREAK);
	},
	showUpcomingBreak({ dispatch }, tutorBreak) {
		if (tutorBreak.type.id === REST_BREAK_ID) {
			dispatch("showUpcomingRestBreak");
		} else {
			dispatch("showUpcomingMealBreak");
		}
	},
	showUpcomingRestBreak({ commit, getters }) {
		if (getters.hasUpcomingRestBreak) {
			commit("SET_BREAK_STEP", REST_BREAK_IN_PROGRESS);
		} else {
			commit("SET_BREAK_STEP", REST_BREAK_CANCEL);
		}
	},
	showUpcomingMealBreak({ commit, getters }) {
		if (getters.isMealBreakStartingSoon) {
			commit("SET_BREAK_STEP", MEAL_BREAK_UPCOMING);
		} else {
			commit("SET_BREAK_STEP", MEAL_BREAK_CANCEL);
		}
	},
	async resetWorkflow({ commit, dispatch, getters, rootGetters }) {
		if (getters.isOnBreak) {
			return;
		}

		const nextBreak = rootGetters["TutorSchedule/soonestUpcomingBreak"];

		if (rootGetters["TutorSchedule/hasPendingMealBreakRequest"] && isNil(nextBreak)) {
			commit("SET_BREAK_STEP", MEAL_BREAK_PENDING_APPROVAL);
			return;
		}

		if (!isNil(nextBreak)) {
			dispatch("showUpcomingBreak", nextBreak);
		} else {
			commit("SET_BREAK_STEP", BREAK_STEP_EMPTY);
		}
	},
	async cancelBreakExternal({ commit, getters, rootGetters }) {
		const cancelledBreakType = rootGetters["TutorSchedule/getCancelledBreakType"];

		if (!cancelledBreakType || getters.isOnBreakOrInTransition) {
			return;
		}

		const breakStep = cancelledBreakType === REST_BREAK_ID ? REST_BREAK_CANCELLED : MEAL_BREAK_CANCELLED;
		commit("SET_BREAK_STEP", breakStep);
	},
	requestMealBreak({ commit, rootGetters }) {
		if (!isNil(rootGetters["TutorSchedule/getUpcomingMealBreak"])) {
			commit("SET_BREAK_STEP", MEAL_BREAK_CANCEL);
		} else if (rootGetters["TutorSchedule/hasPendingMealBreakRequest"]) {
			commit("SET_BREAK_STEP", MEAL_BREAK_PENDING_APPROVAL);
		} else {
			commit("SET_BREAK_STEP", MEAL_BREAK_REQUEST);
		}
	},
	async scheduleMealBreak({ state, commit, rootGetters, dispatch }, params) {
		if (state.isRequestLoading) {
			return;
		}

		try {
			commit("SET_IS_REQUEST_LOADING", true);
			const currentShift = rootGetters["TutorSchedule/getCurrentShiftSegment"];

			await requestBreak(MEAL_BREAK_ID, params.scheduledStartTime, currentShift._metadata.gs_api_transformer.id);

			setTimeout(async() => {
				await dispatch("TutorSchedule/getCurrentShiftAndState", null, { root: true });
				await dispatch("TutorSchedule/getCurrentUserUpcomingShifts", null, { root: true });
				commit("SET_IS_REQUEST_LOADING", false);
				commit("SET_BREAK_STEP", MEAL_BREAK_PENDING_APPROVAL);
			}, ONE_SECOND_IN_MS * 2);
		} catch (e) {
			Sentry.captureException(e);
			commit("SET_IS_REQUEST_LOADING", false);
			commit("SET_BREAK_STEP", MEAL_BREAK_ERROR);
		}
	},
	async endMealBreak({ commit, dispatch }) {
		await endBreak(commit, dispatch);
	},
	async cancelPendingMealBreakRequest({ state, dispatch, commit, rootGetters }) {
		const pendingRequest = rootGetters["TutorSchedule/getPendingBreakRequest"];
		if (state.isRequestLoading || isNil(pendingRequest)) {
			return;
		}

		try {
			commit("SET_IS_REQUEST_LOADING", true);
			await BreakRequestAPI.cancelBreakRequest(pendingRequest._metadata.gs_api_transformer.id);

			setTimeout(async() => {
				await dispatch("TutorSchedule/getCurrentShiftAndState", null, { root: true });
				commit("SET_IS_REQUEST_LOADING", false);
				dispatch("resetWorkflow");
			}, ONE_SECOND_IN_MS * 2);
		} catch (e) {
			Sentry.captureException(e);
			commit("SET_IS_REQUEST_LOADING", false);
		}
	},
	confirmCancelMealBreak({ commit }) {
		commit("SET_BREAK_STEP", MEAL_BREAK_CANCEL_CONFIRM);
	},
	async cancelApprovedMealBreak({ state, rootGetters, dispatch, commit }) {
		if (state.isRequestLoading) {
			return;
		}

		await cancelScheduledBreak(rootGetters["TutorSchedule/getUpcomingMealBreak"], dispatch, commit);
	},
	confirmRestBreakReschedule({ commit }) {
		commit("SET_BREAK_STEP", REST_BREAK_REQUEST);
	},
	startBioBreakInActiveClassroom({ commit }) {
		commit("SET_BREAK_STEP", BIO_BREAK_ACTIVITY_ON_HOLD);
	},
	setCurrentTime({ commit }) {
		commit("SET_CURRENT_TIME", new Date());
	},
	setCurrentBioBreakStep({ commit, rootGetters }) {
		if (rootGetters["TutorSchedule/isOnBioBreakOrMIA"]) {
			commit("SET_BREAK_STEP", BIO_BREAK_END);
		}
	},
	setRestBreakEnd({ commit }) {
		commit("SET_BREAK_STEP", REST_BREAK_END);
	},
	setRestBreakLocked({ commit }) {
		commit("SET_BREAK_STEP", REST_BREAK_LOCKED);
	},
	setRestBreakInProgress({ commit }) {
		commit("SET_BREAK_STEP", REST_BREAK_IN_PROGRESS);
	},
	setMealBreakPendingApproval({ commit }) {
		commit("SET_BREAK_STEP", MEAL_BREAK_PENDING_APPROVAL);
	},
	async setBreakRequestRejected({ commit, getters }) {
		if (getters.isOnBreakOrInTransition) {
			return;
		}

		commit("SET_BREAK_STEP", MEAL_BREAK_REQUEST_REJECTED);
	},
	async setMealBreakRequestApproved({ commit, getters }) {
		if (getters.isOnBreakOrInTransition) {
			return;
		}

		commit("SET_BREAK_STEP", MEAL_BREAK_REQUEST_APPROVED);
	},
	setMealBreakUpcoming({ commit }) {
		commit("SET_BREAK_STEP", MEAL_BREAK_UPCOMING);
	},
	setMealBreakInProgress({ commit }) {
		commit("SET_BREAK_STEP", MEAL_BREAK_IN_PROGRESS);
	},
	setMealBreakLate({ commit }) {
		commit("SET_BREAK_STEP", MEAL_BREAK_LATE);
	},
};

export const getters = {
	getBioBreakEndReferenceTime(state, getters, rootState, rootGetters) {
		const { start_time, end_time } = rootGetters["TutorSchedule/getCurrentShiftModifier"];
		return rootGetters["TutorSchedule/isMIA"] ? start_time : end_time;
	},
	isLateFromBioBreak(state, getters) {
		return isAfter(state.currentTime, parseISO(getters.getBioBreakEndReferenceTime));
	},
	getTimeLeftInBioBreak(state, getters) {
		return Math.ceil(differenceInSeconds(parseISO(getters.getBioBreakEndReferenceTime), state.currentTime) / 60);
	},
	isInBioBreakTransitionPeriod(state, getters, rootState, rootGetters) {
		const { max_start_time } = rootGetters["TutorSchedule/getBioBreakMinMaxStartTime"];
		return isAfter(state.currentTime, new Date(max_start_time))
			|| rootGetters["TutorSchedule/shiftSegmentIsInTransition"];
	},
	isInRestBreakTransitionPeriod(state, getters, rootState, rootGetters) {
		const { max_start_time } = rootGetters["TutorSchedule/getRestBreakMinMaxStartTime"];
		return isAfter(state.currentTime, new Date(max_start_time));
	},
	isInMealBreakTransitionPeriod(state, getters, rootState, rootGetters) {
		const { max_start_time } = rootGetters["TutorSchedule/getMealBreakMinMaxStartTime"];
		return isAfter(state.currentTime, new Date(max_start_time));
	},
	isOnBioBreakWorkflow(state) {
		return BIO_BREAK_STEPS.includes(state.currentBreakStep);
	},
	isOnRestBreakWorkflow(state) {
		return REST_BREAK_STEPS.includes(state.currentBreakStep);
	},
	isOnMealBreakWorkflow(state) {
		return MEAL_BREAK_STEPS.includes(state.currentBreakStep);
	},
	hasRestBreakBeenScheduled(state, getters, rootState, rootGetters) {
		return !getters.isOnRestBreakWorkflow
			&& !getters.isOnBioBreakWorkflow
			&& rootGetters["TutorSchedule/hasRestBreak"];
	},
	hasUpcomingRestBreak(state, getters, rootState, rootGetters) {
		const upcomingRestBreak = rootGetters["TutorSchedule/getUpcomingRestBreak"];
		return upcomingRestBreak && getters.isBreakStartingSoon(upcomingRestBreak);
	},
	isBioBreakEligible(state, getters, rootState, rootGetters) {
		const { max_start_time, min_start_time } = rootGetters["TutorSchedule/getBioBreakMinMaxStartTime"];
		return isAfter(state.currentTime, new Date(min_start_time))
			&& isBefore(state.currentTime, new Date(max_start_time));
	},
	isMealBreakEligible(state, getters, rootState, rootGetters) {
		const { max_start_time } = rootGetters["TutorSchedule/getMealBreakMinMaxStartTime"];
		return isBefore(state.currentTime, new Date(max_start_time));
	},
	canCancelRestBreak(state, getters, rootState) {
		const maxCancelTime = rootState.TutorSchedule.shiftState?.eligible_actions?.cancel_rest_break?.max_start_time;
		return !isNil(maxCancelTime) && isBefore(state.currentTime, new Date(maxCancelTime));
	},
	canCancelMealBreak(state, getters, rootState) {
		const maxCancelTime = rootState.TutorSchedule.shiftState?.eligible_actions?.cancel_meal_break?.max_start_time;
		return !isNil(maxCancelTime) && isBefore(state.currentTime, new Date(maxCancelTime));
	},
	isBreakStartingSoon: (state) => (tutorBreak) => {
		return getMinutesBetweenDates(
			new Date(tutorBreak.start_time),
			state.currentTime) <=
		UPCOMING_BREAK_THRESHOLD_MINUTES;
	},
	isMealBreakStartingSoon(state, getters, rootState, rootGetters) {
		const upcomingMealBreak = rootGetters["TutorSchedule/getUpcomingMealBreak"];
		return upcomingMealBreak && getters.isBreakStartingSoon(upcomingMealBreak);
	},
	hasActiveMealBreak(state) {
		return ACTIVE_MEAL_BREAK_STEPS.includes(state.currentBreakStep);
	},
	hasActiveRestBreak(state) {
		return ACTIVE_REST_BREAK_STEPS.includes(state.currentBreakStep);
	},
	isRequestingMealBreak(state) {
		return REQUEST_MEAL_BREAK_STEPS.includes(state.currentBreakStep);
	},
	isRequestingRestBreak(state) {
		return REQUEST_REST_BREAK_STEPS.includes(state.currentBreakStep);
	},
	isBioBreakInProgress(state, getters, rootState, rootGetters) {
		return rootGetters["TutorSchedule/isOnBioBreakOrMIA"] ||
			rootGetters["TutorSchedule/isOnNewActivityHold"];
	},
	isRestBreakInProgress(state, getters, rootState, rootGetters) {
		return rootGetters["TutorSchedule/isOnRestBreak"] ||
			!isNil(rootGetters["TutorSchedule/getLateRestBreak"]);
	},
	isMealBreakInProgress(state, getters, rootState, rootGetters) {
		return rootGetters["TutorSchedule/isOnMealBreak"] ||
			!isNil(rootGetters["TutorSchedule/getLateMealBreak"]);
	},
	isOnBreak(state, getters) {
		return getters.isBioBreakInProgress ||
			getters.isRestBreakInProgress ||
			getters.isMealBreakInProgress;
	},
	isOnBioOrRestBreak(state, getters) {
		return getters.isBioBreakInProgress ||
			getters.isRestBreakInProgress;
	},
	isOnBreakOrInTransition(state, getters) {
		return getters.isOnBreak ||
			getters.isMealBreakStartingSoon ||
			getters.hasUpcomingRestBreak;
	},
};

export default { namespaced: true, state, mutations, actions, getters };
