import { computed } from "vue";

import useStore from "@/composables/useStore.js";

export const useLoading = () => {
	const store = useStore();

	const isLoading = computed(() => store.state.ServiceAnalytics.isLoading);

	return isLoading;
};
