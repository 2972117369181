import { cloneDeep, isEmpty, camelCase, snakeCase, omit } from "lodash";

import { formatTimestampToDate } from "./dateHelpers.js";

/**
 * input has: {section_id, section_name, last_message}
 * input does not have: {sectionName, sectionName, lastMessage}
 * output has: {sectionName, sectionName, lastMessage}
 * output does not have: {section_id, section_name, last_message}
 * @param {Object} session 
 * @returns {Object}
 */
export const getSessionWithProperAttributesToCamelCase = (session) => {
	const updatedSession = cloneDeep(session);
	[
		"section_id",
		"section_name",
		"last_message",
	].forEach((key) => {
		updatedSession[camelCase(key)] = updatedSession[key];
		delete updatedSession[key];
	});
	return updatedSession;
};

/**
 * input has: { relation.data: value}
 * output has: {relation: value}
 * output does not have: {relation.data}
 * @param {Object} session 
 * @returns {Object}
 */
export const getSessionWithProperAttributesToSnakeCase = (session) => {
	const updatedSession = cloneDeep(session);
	[
		"requestedTopic",
		"requestedSubject",
		"assessment",
		"subjects",
		"topics",
		"handoffs",
		"school",
		"participants",
	].forEach((key) => {
		updatedSession[snakeCase(key)] = !isEmpty(updatedSession[key]?.data) ? updatedSession[key].data : null;
		if (snakeCase(key) !== key) {
			delete updatedSession[key];
		}
	});
	return updatedSession;
};

export const getParticipantsWithoutOutgoingTutors = (handoffs, participants) => {
	const listOfHandoffs = handoffs?.data || handoffs;
	if (isEmpty(listOfHandoffs)) {
		return participants;
	}
	const tutorsToRemove = listOfHandoffs.reduce(
		(tutorIds, { outgoing_tutor_id }) => ({
			...tutorIds,
			[outgoing_tutor_id]: true,
		}),
		{},
	);
	return participants.filter(({ id }) => !tutorsToRemove[id]);
};

export const parseAvatarJson = (avatar) => {
	try {
		return JSON.parse(avatar);
	} catch (e) {
		Sentry.captureException(e);
		return null;
	}
};

/**
 * input has: {participants}
 * input does not have: { users}
 * output has: {users}
 * output does not have: {participants}
 * @param {Object} session 
 * @returns {Object}
 */
export const getSessionWithUsersProperty = (session) => {
	const updatedSession = cloneDeep(session);
	delete updatedSession.participants;
	const allSessionParticipants = session.participants.map((participant) => {
		const isUserStudent = !isEmpty(participant.student.data);
		const dataByRole = isUserStudent ?
			{
				...participant.student.data,
				grade: !isEmpty(participant.student.data?.grade?.data) ? participant.student.data.grade.data : null,
				schools: participant.schools?.data || [],
				struggledSubjects: participant.student.data?.struggledSubjects?.data || [],
				likedSubjects: participant.student.data?.likedSubjects?.data || [],
				avatar: participant.student.data?.avatar && parseAvatarJson(participant.student.data.avatar),
			} : participant.tutor.data;
		const user = {
			...omit(participant, ["student", "tutor"]),
			...dataByRole,
			role: isUserStudent ? "student" : "tutor",
		};
		return user;
	});

	updatedSession.users = getParticipantsWithoutOutgoingTutors(
		session.handoffs,
		allSessionParticipants,
	);
	return updatedSession;
};

/**
 * @param {Array} sessions
 * @returns {Object}
 */
export const getSessionsById = (sessions) => {
	const sessionsObject = {};
	sessions.forEach((session) => {
		sessionsObject[session.id] = { ...session };
	});
	return sessionsObject;
};

/**
 * 
 * @param {Object} session
 * @returns {Object}
 */
export const getSessionWithDatesToString = (session) => {
	const updatedSession = cloneDeep(session);
	if (session.created_at) {
		updatedSession.created_at = formatTimestampToDate(updatedSession.created_at).toString();
	}
	return updatedSession;
};

/**
 * input has: {incomingTutor, outgoingTutor}
 * input does not have: { incoming_tutor, outgoing_tutor}
 * output has: { incoming_tutor, outgoing_tutor}
 * output does not have: {incomingTutor, outgoingTutor}
 * @param {Object} session 
 * @returns {Object}
 */
export const getSessionHandoffsTutorAttributesToSnakeCase = (session) => {
	const updatedSession = cloneDeep(session);
	updatedSession.handoffs = session.handoffs?.map((handoff) => ({
		...omit(handoff, ["incomingTutor", "outgoingTutor"]),
		incoming_tutor: handoff.incomingTutor.data,
		outgoing_tutor: handoff.outgoingTutor.data,
	})) ?? [];
	return updatedSession;
};

export const getSessionUnreadCount = (sessionMessages) => {
	return sessionMessages.filter(
		(message) => !message.is_question_matcher &&
			!message.read &&
			!message.isMyMessage,
	).length;
};

export const transformSingleSessionToOldFormat = (session) => {
	let transformedSession = getSessionWithProperAttributesToCamelCase(session);
	transformedSession = getSessionWithProperAttributesToSnakeCase(transformedSession);
	transformedSession = getSessionWithUsersProperty(transformedSession);
	transformedSession = getSessionWithDatesToString(transformedSession);
	transformedSession = getSessionHandoffsTutorAttributesToSnakeCase(transformedSession);
	transformedSession.unreadCount = getSessionUnreadCount(transformedSession.messages?.data || []);
	transformedSession.is_feedback_completed = Boolean(session.studentSessionFeedback?.data?.[0]);
	transformedSession.whiteboard = {
		isOpen: false,
		data: null,
		backgroundImageLink: null,
	};
	transformedSession.isLoaded = false;
	transformedSession.messages = [];
	return transformedSession;
};

/**
 * 
 * @param {Array} sessions 
 * @returns {Object}
 */
export const transformSessionsToOldFormat = (sessions) => {
	const transformedSessions = sessions.map((session) => transformSingleSessionToOldFormat(session));
	const formattedSessions = getSessionsById(transformedSessions);
	return formattedSessions;
};
