var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "h1",
      {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "accepted-work-title",
            expression: "'accepted-work-title'",
          },
        ],
        staticClass: "tw-text-lg tw-mt-5 tw-mb-3 tw-font-bold",
      },
      [_vm._v("\n\t\t" + _vm._s(_vm.$t("uploadNewWork")) + "\n\t")]
    ),
    _vm._v(" "),
    _c(
      "p",
      {
        directives: [
          {
            name: "jest",
            rawName: "v-jest",
            value: "accepted-work",
            expression: "'accepted-work'",
          },
        ],
        staticClass: "tw-text-sm tw-mb-5 tw-font-normal",
      },
      [_vm._v("\n\t\t" + _vm._s(_vm.$t("acceptedWork")) + "\n\t")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }