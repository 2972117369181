import api from "./Api.js";

export default {
	/**
	 * Returns a collection of tutors. Defaults to returning all tutors.
	 * @apiParam {Boolean} [active] Whether you want active or inactive tutors.
	 * @apiParam {String="acceptedBackupShifts","administrator","activeSessions","sections","education","notifications","promo","requestedBackupShifts","roles","sessions","student","schools","superuser","teacher","tutor"} [include] Allowed data to be nested.
	 * @apiUse UnauthorizedError
	 */
	list(params) {
		return api().get(`tutors`, { params });
	},

	/**
	 * Same as 'list' above, but only returns tutor name and ID
	 */
	listLite(params) {
		return api().get(`tutors/search`, { params });
	},
	/**
	 * Get scheduled tutors that teach a topic
	 * Tutors with the highest tutor quality score and the least amount of current sessions who are able to teach a topic and who's shift doesn't end for the next 15 minutes.
	 * If no tutors are available, it will return information to create a reminder for when the next tutor is scheduled for that topic.
	 * @arg {object} params
	 * @returns {AxiosPromise}
	 */
	getScheduledTutorsForTopic(params) {
		return api().get(`tutors.scheduled`, { params });
	},

	/**
	 * Gets valid tutors who can be handed off to
	 *
	 *  @returns mixed
	 */

	getTutorsForHandoff(sessionId) {
		return api().get(`sessions/${sessionId}/handoff-tutors`);
	},

	/**
	 *
	 * @param {Number} sessionId
	 */
	getAllTutorIdsInSession(sessionId) {
		return api().get(`sessions/${sessionId}/tutor-ids`);
	},
	/**
	 * update tutor
	 * @returns mixed
	 */
	updateTutor(tutorUserId, params) {
		return api().post(`tutors/${tutorUserId}`, params);
	},
	/**
	 * Get scheduled tutors by search parameters
	 * @returns mixed
	 */
	getTutorSearch(params) {
		return api().get(`scheduled-tutors`, { params });
	},

	/*
	 * Get the list of tutors with their confirmed availability data
	 * @returns {mixed}
	 */
	getTutorWeeklyAvailabilities(params) {
		return api().get(`/tutors/weekly-availabilities`, { params });
	},

	/*
	 * Updates the profile picture in the user_tutor table
	 * @param {Number} tutorUserId
	 * @param {Object} data
	 * @returns {mixed}
	 */
	updateTutorProfilePicture(tutorUserId, data) {
		return api().post(`tutors/${tutorUserId}/headshot`, data);
	},

	/**
	 * Get the active session count for a tutor
	 * @param {Number} tutorUserId
	 * @returns mixed
	 */
	getTutorActiveSessionCount(tutorUserId) {
		return api().get(`/tutors/${tutorUserId}/sessions/ongoing/count`);
	},
};
