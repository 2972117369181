var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tw-py-3.5" },
    [
      _c(
        "div",
        { staticClass: "tw-w-11/12 tw-m-auto tw-mb-10" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("ServiceAbsencesTableFilters", {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "table-filters",
                expression: "'table-filters'",
              },
            ],
          }),
          _vm._v(" "),
          !_vm.isTutorListEmpty &&
          !_vm.PlatformManager.ServiceAbsencesTable.loading
            ? _c("ServiceAbsencesTableContainer", {
                directives: [
                  {
                    name: "jest",
                    rawName: "v-jest",
                    value: "tutor-table",
                    expression: "'tutor-table'",
                  },
                ],
                attrs: {
                  "tutor-list":
                    _vm.PlatformManager.ServiceAbsencesTable.filteredTutors,
                },
              })
            : !_vm.PlatformManager.ServiceAbsencesTable.loading
            ? _c("p", { staticClass: "tw-text-center tw-mt-4" }, [
                _vm._v(
                  "\n\t\t\tThere are no tutors on break matching filter you have selected.\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.PlatformManager.ServiceAbsencesTable.loading
        ? _c("BaseLoader", { staticClass: "tw-w-8 tw-h-8 tw-mx-auto tw-my-3" })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isTutorListEmpty
        ? _c(
            "div",
            { staticClass: "tw-text-center" },
            [
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "page-indicator",
                      expression: "'page-indicator'",
                    },
                  ],
                  staticClass:
                    "tw-bg-origami-blue-300 tw-p-2 tw-text-origami-white tw-m-auto tw-rounded-md tw-w-7 tw-mb-2",
                },
                [_vm._v("\n\t\t\t" + _vm._s(_vm.getCurrentPage) + "\n\t\t")]
              ),
              _vm._v(" "),
              _c(
                "OrigamiButton",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "prev-button",
                      expression: "'prev-button'",
                    },
                  ],
                  nativeOn: {
                    click: function ($event) {
                      return _vm.getPrevPage.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n\t\t\tPrev\n\t\t")]
              ),
              _vm._v(" "),
              _c(
                "OrigamiButton",
                {
                  directives: [
                    {
                      name: "jest",
                      rawName: "v-jest",
                      value: "next-button",
                      expression: "'next-button'",
                    },
                  ],
                  nativeOn: {
                    click: function ($event) {
                      return _vm.getNextPage.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n\t\t\tNext\n\t\t")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "tw-flex tw-flex-row tw-justify-between" },
      [
        _c(
          "h4",
          { staticClass: "tw-text-xl tw-font-semibold tw-text-origami-black" },
          [_vm._v("\n\t\t\t\tBio Breaks & Absences\n\t\t\t")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }