import { redirectInPopupHandler } from "@/modules/Authentication/index.js";
import middleware from "@/middleware/index.js";
import isConnected from "@/middleware/isConnected.js";
import hasRole from "@/middleware/hasRole.js";
import hasSchool from "@/middleware/hasSchool.js";
import isGeorgeBrownSchool from "@/middleware/isGeorgeBrownSchool.js";
import hasProfileFultonCompletion from "@/middleware/hasProfileFultonCompletion.js";
import hasProfileLMSCompletion from "@/middleware/hasProfileLMSCompletion.js";
import hasStudentAcceptedAgreement from "@/middleware/hasStudentAcceptedAgreement.js";
import hasLiveHelpFeature from "@/middleware/hasLiveHelpFeature.js";
import hasPaperAccess from "@/middleware/hasPaperAccess.js";
import initializePendo from "@/middleware/initializePendo.js";
import initializeZendesk from "@/middleware/initializeZendesk.js";
import initFullStory from "@/middleware/initFullStory.js";
import insertRoleRedirect from "@/middleware/insertRoleRedirect.js";
import redirectWhenFeatureFlagIsDisabled from "@/middleware/redirectWhenFeatureFlagIsDisabled.js";
import isABQUser from "@/middleware/isABQUser.js";
import { handleTrackRequests } from "@/utilities/trackRequests.js";
import mathRoutes from "@/modules/Math/router/index.js";
import reviewCenterRoutes from "@/modules/StudentReviewCenter/router/index.js";
import achievementRoutes from "@/modules/Achievements/router/index.js";
import studentSettingsRoutes from "@/modules/StudentSettings/router/index.js";
import studentDashboardRoutes from "@/modules/StudentDashboardLayout/router/index.js";
import { studentOnboardingRoutes, doesStudentNeedOnboarding } from "@/modules/StudentOnboarding/index.js";
import learnerProfileRoutes from "@/modules/LearnerProfile/router/index.js";
import store from "@/store/index.js";

export default [
	{
		path: "/",
		component: () => import("../pages/student/index.vue"),
		beforeEnter: middleware([
			handleTrackRequests,
			isConnected,
			insertRoleRedirect,
			redirectInPopupHandler,
			hasRole("student"),
			hasSchool,
			hasProfileLMSCompletion,
			hasProfileFultonCompletion,
			doesStudentNeedOnboarding,
			initializePendo,
			initializeZendesk,
			initFullStory,
			(to, from, next) => {
				store.commit("SET_SHOW_NAVBAR", true);
				next();
			},
		]),
		children: [
			...studentOnboardingRoutes,
			{
				name: "studentClassroom",
				path: "student/classroom",
				component: () => import("@/modules/Classroom/pages/student.vue"),
				beforeEnter: middleware([
					hasStudentAcceptedAgreement,
					doesStudentNeedOnboarding,
					isGeorgeBrownSchool,
					hasLiveHelpFeature,
					hasPaperAccess,
				]),
				meta: {
					embeddedPath: "/embedded/student/classroom",
					noFooter: true,
				},
			},
			{
				name: "studentStartSession",
				path: "student/start-session",
				component: () => import("../pages/student/startSession.vue"),
				beforeEnter: middleware([
					hasStudentAcceptedAgreement,
					doesStudentNeedOnboarding,
					isGeorgeBrownSchool,
					hasLiveHelpFeature,
					hasPaperAccess,
					isABQUser,
				]),
				meta: {
					embeddedPath: "/embedded/student/start-session",
				},
			},
			...studentSettingsRoutes,
			{
				name: "studentPaperClips",
				path: "student/paperclips",
				component: () => import(
					"@/modules/TeachingAssets/pages/Feed.vue"
				),
				beforeEnter: middleware([
					hasStudentAcceptedAgreement,
					doesStudentNeedOnboarding,
					isGeorgeBrownSchool,
					hasLiveHelpFeature,
					hasPaperAccess,
					(to, from, next) => {
						store.commit("SET_SHOW_NAVBAR", false);
						next();
					},
					redirectWhenFeatureFlagIsDisabled("PE-16098-paper-clips"),
				]),
			},
			{
				name: "outsideOperatingHours",
				path: "outside-hours",
				component: () => import(
					"@/pages/student/OutsideOperatingHours.vue"
				),
			},
			...mathRoutes,
			...reviewCenterRoutes,
			...achievementRoutes,
			...learnerProfileRoutes,
			...studentDashboardRoutes,
		],
	},
];
