var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "tw-shadow-elevation-4": !_vm.isFlat } },
    [
      _vm.displayHeader
        ? _c("div", [
            _c(
              "h2",
              {
                staticClass: "tw-pl-6 tw-pr-4 tw-py-4 tw-font-bold tw-text-lg",
              },
              [_vm._v("\n\t\t\tRemove selected shift?\n\t\t")]
            ),
            _vm._v(" "),
            _c("hr"),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isDeleteComplete
        ? _c(
            "div",
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "tw-flex tw-text-center tw-w-max tw-ml-6 tw-my-4 tw-px-1 tw-py-0 tw-rounded-md tw-font-bold tw-text-sm",
                  style: _vm.currentShiftScheduleTypeColor,
                },
                [
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.shift.shiftType.data.name) +
                      "\n\t\t"
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "tw-pl-6 tw-pr-4 tw-py-0 tw-text-sm" }, [
                _c(
                  "p",
                  { staticClass: "tw-pb-2 tw-pt-0 tw-px-0 tw-font-bold" },
                  [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.shift.name) +
                        " [" +
                        _vm._s(_vm.shift.id) +
                        "]\n\t\t\t"
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "tw-pb-2 tw-pt-0 tw-px-0" }, [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.formattedShiftStartTime) +
                      "\n\t\t\t"
                  ),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.formattedShiftEndTime))]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "tw-flex tw-justify-between tw-pt-8 tw-pb-4 tw-pl-6 tw-pr-4",
                },
                [
                  _c(
                    "OrigamiButton",
                    {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "cancel-btn",
                          expression: "'cancel-btn'",
                        },
                        {
                          name: "data-cy",
                          rawName: "v-data-cy",
                          value: "close-modal-btn",
                          expression: "'close-modal-btn'",
                        },
                      ],
                      class: { "tw-w-full tw-justify-center": _vm.hasAnError },
                      attrs: { variant: "secondary" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.cancel()
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\tCancel\n\t\t\t")]
                  ),
                  _vm._v(" "),
                  !_vm.hasAnError
                    ? _c(
                        "OrigamiButton",
                        {
                          directives: [
                            {
                              name: "jest",
                              rawName: "v-jest",
                              value: "delete-shift-btn",
                              expression: "'delete-shift-btn'",
                            },
                          ],
                          attrs: { variant: "destructive" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.deleteShift()
                            },
                          },
                        },
                        [_vm._v("\n\t\t\t\tRemove Shift\n\t\t\t")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.hasAnError
                ? _c("ServiceScheduleTutorErrorMessage", {
                    directives: [
                      {
                        name: "jest",
                        rawName: "v-jest",
                        value: "error-message",
                        expression: "'error-message'",
                      },
                    ],
                    attrs: { error: _vm.error },
                  })
                : _vm._e(),
            ],
            1
          )
        : _c("ServiceScheduleTutorSuccessfulAction", {
            attrs: {
              shift: _vm.shift,
              "success-message-in-sidebar": {
                message: "You have successfully deleted the shift.",
                buttonText: "Complete",
              },
            },
            on: {
              "cancel-edit": function ($event) {
                return _vm.cancel()
              },
            },
          }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "p",
      { staticClass: "tw-pl-6 tw-pr-4 tw-pt-4 tw-pb-0 tw-text-base" },
      [
        _vm._v("\n\t\t\tAre you sure you want to delete this shift?"),
        _c("br"),
        _vm._v("You cannot undo this action.\n\t\t"),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }