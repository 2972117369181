var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tw-flex tw-flex-col tw-h-full" }, [
    _c(
      "header",
      { staticClass: "tw-flex tw-p-4 tw-border-b tw-border-origami-grey-100" },
      [
        _c("h3", { staticClass: "tw-text-origami-h3 tw-font-bold" }, [
          _vm._v("\n\t\t\tPaperClips\n\t\t"),
        ]),
        _vm._v(" "),
        _c("OrigamiIconButton", {
          directives: [
            {
              name: "jest",
              rawName: "v-jest",
              value: "x-button",
              expression: "'x-button'",
            },
            {
              name: "data-cy",
              rawName: "v-data-cy",
              value: "teaching-assets-drawer-x-button",
              expression: "'teaching-assets-drawer-x-button'",
            },
          ],
          staticClass: "tw-ml-auto tw--mr-2 tw--my-2",
          attrs: { "icon-name": "close" },
          nativeOn: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "tw-flex tw-flex-col tw-flex-auto tw-overflow-hidden tw-overflow-y-auto",
      },
      [
        _vm._t("default", function () {
          return [
            _c(
              "header",
              { staticClass: "tw-flex tw-flex-wrap tw-gap-4 tw-px-4 tw-my-4" },
              [
                _c("OrigamiInputText", {
                  directives: [
                    {
                      name: "data-cy",
                      rawName: "v-data-cy",
                      value: "teaching-assets-drawer-search",
                      expression: "'teaching-assets-drawer-search'",
                    },
                  ],
                  staticClass: "tw-flex-auto",
                  attrs: {
                    type: "search",
                    label: "Search",
                    "leading-icon": "search",
                  },
                  model: {
                    value: _vm.assetSearchQuery,
                    callback: function ($$v) {
                      _vm.assetSearchQuery = $$v
                    },
                    expression: "assetSearchQuery",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "tw-flex-auto tw-flex tw-flex-col tw-px-4 tw-overflow-hidden tw-overflow-y-auto tw-border-t tw-border-origami-grey-100",
              },
              [
                _vm.assetsLoadingError
                  ? _c("ErrorState", {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "error",
                          expression: "'error'",
                        },
                      ],
                      staticClass:
                        "tw-text-origami-text-low-emphasis tw-m-auto tw-pb-12",
                      attrs: {
                        "is-show-button": false,
                        "message-info-class":
                          "tw-text-origami-text-black tw-mt-4 tw-mb-1",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "main",
                            fn: function () {
                              return [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "tw-mt-1 tw-text-center tw-text-origami-grey-300",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "tw-block tw-whitespace-nowrap tw-font-normal",
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.$t(
                                                "error_state.cannot_connect"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "tw-block tw-whitespace-nowrap tw-font-normal",
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.$t(
                                                "error_state.please_try_again"
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        137235602
                      ),
                    })
                  : _vm.isLoadingAssets
                  ? _c("NoDataState", {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "loader",
                          expression: "'loader'",
                        },
                      ],
                      staticClass:
                        "tw-text-origami-text-low-emphasis tw-m-auto tw-pb-12",
                      attrs: {
                        "is-show-button": false,
                        message: { instruction: "Loading..." },
                        "message-info-class":
                          "tw-text-origami-text-black tw-mt-4 tw-mb-1",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "icon",
                          fn: function () {
                            return [
                              _c("BaseLoader", {
                                staticClass:
                                  "tw-w-20 tw-h-20 tw-text-origami-brand-blue",
                                attrs: { thickness: "0.675rem" },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    })
                  : !_vm.filteredAssets.length
                  ? _c("NoDataState", {
                      directives: [
                        {
                          name: "jest",
                          rawName: "v-jest",
                          value: "empty",
                          expression: "'empty'",
                        },
                      ],
                      staticClass:
                        "tw-text-origami-text-low-emphasis tw-m-auto tw-pb-12",
                      attrs: {
                        "is-show-button": false,
                        "message-info-class":
                          "tw-text-origami-text-black tw-mt-4 tw-mb-1",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "icon",
                          fn: function () {
                            return [_c("MonochromeMouse")]
                          },
                          proxy: true,
                        },
                      ]),
                    })
                  : _c(
                      "TransitionExpand",
                      { attrs: { group: "" } },
                      _vm._l(_vm.groupedAssets, function (assetGroup) {
                        return _c(
                          "div",
                          {
                            key: assetGroup.name,
                            staticClass: "tw-flex tw-flex-col",
                          },
                          [
                            _vm.groupedAssets.length > 1
                              ? _c(
                                  "h4",
                                  {
                                    directives: [
                                      {
                                        name: "jest",
                                        rawName: "v-jest",
                                        value: "group-name",
                                        expression: "'group-name'",
                                      },
                                    ],
                                    staticClass:
                                      "tw-text-origami-h4 tw-font-bold tw-mt-4",
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(assetGroup.name) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                directives: [
                                  {
                                    name: "jest",
                                    rawName: "v-jest",
                                    value: "assets",
                                    expression: "'assets'",
                                  },
                                ],
                                staticClass:
                                  "tw-grid md:tw-grid-cols-2 tw-gap-4 tw-my-4",
                              },
                              _vm._l(assetGroup.assets, function (asset) {
                                return _c("VideoCard", {
                                  directives: [
                                    {
                                      name: "jest",
                                      rawName: "v-jest",
                                      value: "video-card",
                                      expression: "'video-card'",
                                    },
                                    {
                                      name: "data-cy",
                                      rawName: "v-data-cy",
                                      value: `teaching-assets-drawer-video-card-${asset.id}`,
                                      expression:
                                        "`teaching-assets-drawer-video-card-${asset.id}`",
                                    },
                                  ],
                                  key: asset.id,
                                  attrs: {
                                    video: asset,
                                    "is-selected":
                                      _vm.selectedAssetId === asset.id,
                                    "is-bookmarked": _vm.isBookmarked(asset.id),
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.selectedAssetId = asset.id
                                    },
                                    "thumbnail-click": function ($event) {
                                      return _vm.showVideoModal(asset)
                                    },
                                    "bookmark-click": function ($event) {
                                      return _vm.handleBookmarkToggle(asset.id)
                                    },
                                  },
                                })
                              }),
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
              ],
              1
            ),
          ]
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "footer",
      {
        staticClass:
          "tw-flex tw-gap-2 tw-px-4 tw-py-3 tw-border-t tw-border-origami-grey-100",
      },
      [
        _c(
          "OrigamiButton",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "close-button",
                expression: "'close-button'",
              },
              {
                name: "data-cy",
                rawName: "v-data-cy",
                value: "teaching-assets-drawer-close-button",
                expression: "'teaching-assets-drawer-close-button'",
              },
            ],
            staticClass: "tw-ml-auto",
            attrs: { variant: "secondary" },
            nativeOn: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [_vm._v("\n\t\t\tClose\n\t\t")]
        ),
        _vm._v(" "),
        _c(
          "OrigamiButton",
          {
            directives: [
              {
                name: "jest",
                rawName: "v-jest",
                value: "send-button",
                expression: "'send-button'",
              },
              {
                name: "data-cy",
                rawName: "v-data-cy",
                value: "teaching-assets-drawer-send-button",
                expression: "'teaching-assets-drawer-send-button'",
              },
            ],
            attrs: { "data-pendo-id": "teaching-assets-drawer-send-button" },
            nativeOn: {
              click: function ($event) {
                return _vm.$emit("send", _vm.selectedAsset)
              },
            },
          },
          [_vm._v("\n\t\t\tSend\n\t\t")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }