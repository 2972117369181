import api from "@/services/api/Api.js";

export default {
	/**
	 * @returns {Promise}
	 */
	list() {
		return api().get("teacher-content-types");
	},
};
