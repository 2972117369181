<template>
	<div class="shiftsCalendar tw-w-full">
		<DropDown
			v-model="tutorId"
			v-data-cy="'tutor-list-dropdown'"
			label="Tutor"
			class="shiftsCalendar__tutorDropdown"
			:items="PlatformManager.ServiceTutorsSchedule.tutorsList"
			:get-item-text="(item) => `${item.name} - ${item.id}`"
		/>

		<div class="shiftsCalendar__calendar">
			<label class="tw-font-bold tw-text-sm tw-text-xs tw-mb-[7px]">
				Time
			</label>
			<DatePicker
				v-model="selectedRange"
				v-data-cy="'createShift-date-picker'"
				:popover="{ placement: 'right' }"
				mode="dateTime"
				:is24hr="true"
				:minute-increment="15"
				:is-range="true"
				:min-date="minimumDate"
			>
				<template #default="{ inputValue, inputEvents }">
					<input
						name="shiftDate"
						class="datePicker__input"
						:placeholder="placeholder"
						:value="inputValue.start"
						v-on="inputEvents.start"
					>
				</template>
			</DatePicker>
		</div>

		<div class="shiftsCalendar__shiftTypeDropdown">
			<label class="tw-font-bold tw-text-sm tw-text-xs tw-mb-[7px]">
				Shift Type
			</label>
			<BaseDropdown
				ref="shiftTypeListDropdown"
				v-data-cy="'shiftType-list-dropdown'"
				:items="shiftTypesForCreation"
			>
				<template slot="filters">
					{{ shiftTypeFilter }}
				</template>
				<template #default="{ item }">
					<BaseRadio
						v-model="shiftTypeId"
						v-data-cy="`shiftType-list-dropdown-${item.id}`"
						radio-name="shiftType"
						:radio-id="`shiftType-${item.id}`"
						:radio-value="item.id"
						@click.native="closeOnSelect('shiftTypeListDropdown')"
					>
						<template slot="text">
							{{ getShiftTypeName(item) }}
						</template>
					</BaseRadio>
				</template>
			</BaseDropdown>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";

import { getShiftTypeNameFromShiftTypeId } from "@/utilities/ScheduleHelpers.js";
import BaseRadio from "@/components/BaseRadio.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";
import DropDown from "@/components/elements/DropDown.vue";

import serviceScheduleShiftCalendarMixin from "../../mixins/serviceScheduleShiftCalendarMixin.js";

export default {
	components: {
		DatePicker: () => import("v-calendar/lib/components/date-picker.umd.min.js"),
		BaseRadio,
		BaseDropdown,
		DropDown,
	},
	mixins: [serviceScheduleShiftCalendarMixin],
	props: {
		value: {
			type: [Object],
			required: false,
			default: function() {
				return {
					start: null,
					end: null,
				};
			},
		},
		placeholder: {
			type: String,
			required: false,
			default: "",
		},
	},
	data() {
		return {
			selectedRange: null,
			shiftTypeId: null,
		};
	},
	computed: {
		...mapState(["PlatformManager"]),
		minimumDate() {
			return new Date() - (24 * 3600000);
		},

	},
	watch: {
		selectedRange(value) {
			this.$store.commit("PlatformManager/ServiceTutorsSchedule/SET_DATE_RANGE", {
				dateRange: value,
			});
		},
	},
	created() {
		this.selectedRange = this.value;
	},
	methods: {
		getShiftTypeName(shift) {
			return getShiftTypeNameFromShiftTypeId(shift.id);
		},
	},
};
</script>

<style>
.datePicker__input {
	width: 100%;
	height: 40px;
	border: solid 2px var(--c-gray);
	border-radius: 5px;
	padding-left: 1rem;
	font-size: 12px;
}
.shiftsCalendar {
	width: 100%;
}
.shiftsCalendar__tutorDropdown {
	height: 1.9rem;
	margin-bottom: 55px;
}
.shiftsCalendar__shiftTypeDropdown {
	height: 1.9rem;
	margin-bottom: 55px;
}
</style>
