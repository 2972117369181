<template>
	<ScheduledBreakCancelled :break-type-id="breakTypeId" />
</template>

<script>
import { MEAL_BREAK_ID } from "@/modules/TutorSchedule/index.js";

import ScheduledBreakCancelled from "../elements/ScheduledBreakCancelled.vue";

export default {
	components: {
		ScheduledBreakCancelled,
	},
	data() {
		return {
			breakTypeId: MEAL_BREAK_ID,
		};
	},
};
</script>
