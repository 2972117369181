import TopicsAPI from "@/services/api/Topics.js";
import UserTransformer from "@/services/transformers/showTransformer.js";

import CoursesAPI from "../../services/courses.js";
import SectionsAPI from "../../services/sections.js";
import UsersAPI from "../../services/users.js";


const getDefaultState = () => {
	return {
		section: {
			id: null,
			display_name: null,
			name: null,
			school: {},
			active_from: null,
			active_to: null,
			users: {
				data: [],
			},
		},
		sectionUserIds: [],
		district: "",
		isLoadingSchoolUsers: false,
		editing: {
			display_name: false,
			name: false,
			active_period: false,
			course_id: false,
		},
		school: {
			id: null,
			name: "",
			district: { data: {} },
		},
		schoolUserSearchValue: "",
		schoolUsers: [],
		saving: {
			display_name: false,
			name: false,
			active_period: false,
			users: false,
			course_id: false,
		},
		saved: {
			display_name: false,
			name: false,
			active_period: false,
			course_id: false,
		},
		courses: [],
		selectedCourseId: -1,
		topics: [],
		currentPage: 0,
		totalPages: 0,
		totalCourses: 0,
		districtId: -1,
		selectedTopicIds: [],
		isLoading: false,
		searchText: "",
		navbarMainTab: "Customers",
		allCoursesByDistrict: [],
	};
};

const mutations = {
	RESET_STATE(state) {
		Object.assign(state, getDefaultState());
	},
	SET_SECTION(state, payload) {
		state.section = payload.section;
	},
	SET_SECTION_USER_IDS(state, payload) {
		state.sectionUserIds = payload.sectionUserIds;
	},
	SET_EDITING(state, payload) {
		const keys = Object.keys(payload);
		keys.forEach(function(key) {
			if (key !== "type") {
				state.editing[key] = payload[key];
			}
		});
	},
	SET_LOADING_USERS(state, payload) {
		state.isLoadingSchoolUsers = payload.value;
	},
	SET_SAVING(state, payload) {
		const keys = Object.keys(payload);
		keys.forEach(function(key) {
			if (key !== "type") {
				state.saving[key] = payload[key];
			}
		});
	},
	SET_SAVED(state, payload) {
		const keys = Object.keys(payload);
		keys.forEach(function(key) {
			if (key !== "type") {
				state.saved[key] = payload[key];
			}
		});
	},
	SET_SCHOOL(state, payload) {
		state.school = payload.school;
	},
	SET_SEARCH_TERM(state, payload) {
		state.schoolUserSearchValue = payload.value;
	},
	SET_USERS(state, payload) {
		state.schoolUsers = payload.users;
	},
	SET_COURSES(state, payload) {
		state.courses = payload.courses;
	},
	SET_SELECTED_COURSE_ID(state, payload) {
		state.selectedCourseId = payload.selectedCourseId;
	},
	SET_TOPICS(state, payload) {
		state.topics = payload.topics;
	},
	SET_CURRENT_PAGE(state, payload) {
		state.currentPage = payload.currentPage;
	},
	SET_TOTAL_PAGES(state, payload) {
		state.totalPages = payload.totalPages;
	},
	SET_TOTAL_COURSES(state, payload) {
		state.totalCourses = payload.totalCourses;
	},
	SET_DISTRICT_ID(state, payload) {
		state.districtId = payload.districtId;
	},
	SET_SELECTED_TOPIC_IDS(state, payload) {
		state.selectedTopicIds = payload.selectedTopicIds;
	},
	SET_IS_LOADING(state, payload) {
		state.isLoading = payload.isLoading;
	},
	SET_SEARCH_TEXT(state, payload) {
		state.searchText = payload.searchText;
	},
	SET_ALL_COURSES_BY_DISTRICTS(state, payload) {
		state.allCoursesByDistrict = payload.allCoursesByDistrict;
	},
};

const getters = {
	usersByRole: (state) => (role) => {
		return state.section.users.data
			.filter(function(user) {
				return user.roles.data.find((currentRole) => currentRole.name == role);
			})
			.map((currentUser) => UserTransformer.user(currentUser));
	},
	studentCount: (state, getters) => {
		return getters.usersByRole("student").length;
	},
	teacherCount: (state, getters) => {
		return getters.usersByRole("teacher").length;
	},
	districtName: (state) => {
		const districtExists = Object.values(state.school.district.data).length > 0;
		if (districtExists) {
			return state.school.district.data.name;
		} else {
			return "N/A";
		}
	},
	schoolTeachers: (state) => {
		return state.schoolUsers.filter((teacher) =>
			teacher.roles.data.find((role) => role.name == "teacher"),
		);
	},
	schoolStudents: (state) => {
		return state.schoolUsers.filter(
			(student) =>
				student.roles.data.find((role) => role.name == "student") &&
				student.roles.data.length == 1,
		);
	},
	ltiTitle: (state) => {
		return state.section.synced_with;
	},
};

const actions = {
	async setSection({ commit, dispatch }, payload) {
		try {
			const response = await SectionsAPI.show(payload.id, { include: payload.include });
			commit("SET_SECTION", {
				section: response.data.data,
			});

			commit("SET_SECTION_USER_IDS", {
				sectionUserIds: response.data.data.users.data.map((user) => user.id),
			});

			commit("SET_SCHOOL", {
				school: response.data.data.school.data,
			});

			commit("SET_DISTRICT_ID", {
				districtId: response.data.data.school.data.district_id,
			});
			commit("SET_SELECTED_COURSE_ID", {
				selectedCourseId:
					response.data.data.course.data !== [] && response.data.data.course.data.id !== undefined
						? response.data.data.course.data.id
						: -1,
			});

			const param =
				response.data.data.school.data.district_id !== null
					? [response.data.data.school.data.district_id]
					: [];
			await dispatch("getCourses", { districts: param });
			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject(error.response);
		}
	},
	async updateSection({ state, commit }, payload) {
		const updating = payload.updating;
		const sectionId = payload.sectionId;
		const data = payload.data;

		try {
			const currentSection = { ...state.section };
			if (Object.values(data)[0] !== undefined) {
				await SectionsAPI.update(sectionId, data);
			}

			commit("SET_EDITING", {
				[updating]: false,
			});

			for (const property in data) {
				currentSection[property] = data[property];
			}
			commit("SET_SECTION", {
				section: currentSection,
			});
			commit("SET_SAVED", {
				[updating]: true,
			});
			return Promise.resolve();
		} catch (error) {
			commit("SET_SAVED", {
				[updating]: false,
			});
			return Promise.reject(error.response);
		} finally {
			commit("SET_SAVING", {
				[updating]: false,
			});
		}
	},
	async setSchoolUsers({ commit, state }) {
		const schoolId = state.school.id;
		commit("SET_LOADING_USERS", { value: true });
		try {
			const users = await UsersAPI.getSchoolUsers(schoolId, { include: "roles" });
			commit("SET_USERS", {
				users: users.data.data,
			});
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response);
		} finally {
			commit("SET_LOADING_USERS", { value: false });
		}
	},
	async updateSectionUsers({ dispatch, commit }, payload) {
		commit("SET_SAVING", {
			users: true,
		});
		try {
			await SectionsAPI.update(payload.id, { users: payload.userIds });
			await dispatch({
				type: "setSection",
				id: payload.id,
				include: "school.district,users.roles,topics",
			});
			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error.response);
		} finally {
			commit("SET_SAVING", {
				users: false,
			});
		}
	},
	async setTopics({ commit }) {
		try {
			const response = await TopicsAPI.list();
			commit("SET_TOPICS", {
				topics: response.data.data,
			});
			return Promise.resolve(response);
		} catch (e) {
			return Promise.reject(e);
		}
	},
	async getAllCoursesByDistrict({ commit }, payload) {
		try {
			let currentPage = 1;
			let totalPages;
			let allCoursesByDistrict = [];
			const body = { ...payload };
			do {
				const response = await CoursesAPI.list(body);
				totalPages = response.data.meta.pagination.total_pages;
				allCoursesByDistrict = [...allCoursesByDistrict, ...response.data.data];
				body["page"] = ++currentPage;
			} while (currentPage <= totalPages);
			commit("SET_ALL_COURSES_BY_DISTRICTS", {
				allCoursesByDistrict: allCoursesByDistrict,
			});
			return Promise.resolve(allCoursesByDistrict);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	async getCourses({ commit }, payload) {
		try {
			const response = await CoursesAPI.list(payload);
			commit("SET_COURSES", {
				courses: response.data.data,
			});
			commit("SET_TOTAL_PAGES", {
				totalPages: response.data.meta.pagination.total_pages,
			});
			commit("SET_CURRENT_PAGE", {
				currentPage: response.data.meta.pagination.current_page,
			});
			commit("SET_TOTAL_COURSES", {
				totalCourses: response.data.meta.pagination.total,
			});
			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject(error.response);
		}
	},
	async setCurrentPage({ state, dispatch }, payload) {
		const params = {};
		const districtArray = [];
		if (state.districtId !== -1) {
			districtArray.push(state.districtId);
		}
		if (state.selectedTopicIds.length !== 0) {
			params["topics"] = state.selectedTopicIds;
		}
		if (state.searchText !== "") {
			params["search"] = state.searchText;
		}
		if (districtArray[0] !== null) {
			params["districts"] = districtArray;
		}
		params["page"] = payload.newPage;
		try {
			const response = await dispatch("getCourses", params);
			return Promise.resolve(response);
		} catch (error) {
			return Promise.reject(error.response);
		}
	},
};

export default {
	namespaced: true,
	state: getDefaultState(),
	getters,
	mutations,
	actions,
};
