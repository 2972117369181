<template>
	<div class="shiftsCalendar tw-w-full">
		<DropDown
			v-model="tutorId"
			v-data-cy="'tutor-list-dropdown'"
			label="Tutor"
			class="shiftsCalendar__tutorDropdown"
			is-disabled
			:items="PlatformManager.ServiceTutorsSchedule.tutorsList"
			:get-item-text="(item) => `${item.name} - ${item.id}`"
		/>

		<div class="shiftsCalendar__calendar">
			<label class="tw-font-bold tw-text-sm tw-text-xs tw-mb-[7px]">
				Time
			</label>
			<DatePicker
				v-model="selectedRange"
				:popover="{ placement: 'right' }"
				:is24hr="true"
				mode="dateTime"
				:minute-increment="15"
				:min-date="earliestCalendarDateAvailable"
				:is-range="true"
			>
				<template #default="{ inputValue, inputEvents }">
					<input
						class="datePicker__input tw-border-blue-regular"
						:class="{
							datePicker__editing: !isEditing,
							'tw-opacity-60': !isEditing,
						}"
						:placeholder="placeholder"
						:value="inputValue.start"
						v-on="inputEvents.start"
					>
				</template>
			</DatePicker>
		</div>

		<DropDown
			v-model="shiftTypeId"
			v-jest="'shift-type-dropdown'"
			data-cy="shift-type-dropdown"
			label="Shift Type"
			class="shiftsCalendar__shiftTypeDropdown"
			:is-disabled="isShiftTypeSelectDisabled"
			:items="shiftTypeSelectItems"
			:get-item-text="(item) => getShiftTypeName(item)"
		/>
	</div>
</template>

<script>
import { mapState } from "vuex";

import { getShiftTypeNameFromShiftTypeId } from "@/utilities/ScheduleHelpers.js";
import DropDown from "@/components/elements/DropDown.vue";

import serviceScheduleShiftCalendarMixin from "../../mixins/serviceScheduleShiftCalendarMixin.js";
import { CLIENT_FACING_SHIFT_TYPE_IDS } from "../../utilities/ShiftTypes.js";

export default {
	components: {
		DatePicker: () => import("v-calendar/lib/components/date-picker.umd.min.js"),
		DropDown,
	},
	mixins: [serviceScheduleShiftCalendarMixin],
	props: {
		value: {
			type: [Object],
			required: false,
			default: function() {
				return {
					start: new Date(),
					end: new Date(),
				};
			},
		},
		placeholder: {
			type: String,
			required: false,
			default: "",
		},
		isEdit: {
			type: Boolean,
			required: false,
			default: false,
		},
		shift: {
			type: Object,
			required: false,
			default: () => ({}),
		},
	},
	data() {
		return {
			selectedRange: null,
			shiftTypeId: null,
		};
	},
	computed: {
		...mapState([
			"PlatformManager",
		]),
		isEditing() {
			return this.isEdit;
		},
		earliestCalendarDateAvailable() {
			const defaultDate = new Date();
			if (!this.value.start) {
				return defaultDate;
			}

			const startDate = new Date(this.value.start);
			return startDate < defaultDate ? startDate : defaultDate;
		},
		isShiftTypeSelectDisabled() {
			return !this.isEditing ||
				(!!this.shift.punchIn ||
				!CLIENT_FACING_SHIFT_TYPE_IDS.includes(this.shift.shiftType.data.id));
		},
		shiftTypeSelectItems() {
			return this.isShiftTypeSelectDisabled ?
				this.PlatformManager.ServiceTutorsSchedule.scheduleTypesList :
				this.PlatformManager.ServiceTutorsSchedule.scheduleTypesList.filter(
					(shiftType) => CLIENT_FACING_SHIFT_TYPE_IDS.includes(shiftType.id));
		},
	},
	watch: {
		selectedRange(value) {
			this.$store.commit("PlatformManager/ServiceTutorsSchedule/SET_DATE_RANGE", {
				dateRange: value,
			});
		},
		shift() {
			this.selectedRange = this.getSelectedRange();
			this.tutorId = this.getSelectedShiftTutorId();
			this.shiftTypeId = this.getSelectedShiftId();
		},
	},
	methods: {
		getSelectedShiftTutorId() {
			if (this.shift.name !== "") {
				return this.PlatformManager.ServiceTutorsSchedule.tutorsList.find((t) =>
					t.name === this.shift.name).id;
			}
		},
		getSelectedShiftId() {
			if (this.shift?.shiftType?.data?.id) {
				return this.shift.shiftType.data.id;
			}
		},
		getSelectedRange() {
			return { start: this.shift.start, end: this.shift.end };
		},
		getShiftTypeName(shiftType) {
			return getShiftTypeNameFromShiftTypeId(shiftType.id);
		},
	},
};
</script>

<style>
.datePicker__input {
	width: 100%;
	height: 40px;
	border: solid 2px var(--c-blue);
	border-radius: 5px;
	padding-left: 1rem;
	font-size: 12px;
}
.datePicker__editing {
	pointer-events: none;
	opacity: 0.6;
}
.datePicker__input::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	font-family: "MonumentGrotesk", "Open Sans", sans-serif;
	font-size: 0.75rem;
	font-weight: bold;
}
.datePicker__input::-moz-placeholder {
	/* Firefox 19+ */
	font-family: "MonumentGrotesk", "Open Sans", sans-serif;
	font-size: 0.75rem;
	font-weight: bold;
}
.datePicker__input:-ms-input-placeholder {
	/* IE 10+ */
	font-family: "MonumentGrotesk", "Open Sans", sans-serif;
	font-size: 0.75rem;
	font-weight: bold;
}
.datePicker__input:-moz-placeholder {
	/* Firefox 18- */
	font-family: "MonumentGrotesk", "Open Sans", sans-serif;
	font-size: 0.75rem;
	font-weight: bold;
}
.shiftsCalendar {
	width: 100%;
}
.shiftsCalendar__tutorDropdown {
	height: 1.9rem;
	margin-bottom: 55px;
}
.shiftsCalendar__shiftTypeDropdown {
	height: 1.9rem;
	margin-top: 16px;
}
.vc-select-arrow[data-v-d1c68c60] {
	display: none;
}
</style>
