<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		:width="width"
		:height="height"
		viewBox="0 0 96 96"
	>
		<title>{{ $t('chat.chat_intro.tutor_default_picture') }}</title>
		<defs>
			<circle
				id="a"
				cx="48"
				cy="48"
				r="48"
			/>
		</defs>
		<g
			fill="none"
			fill-rule="evenodd"
		>
			<mask
				id="b"
				fill="#fff"
			>
				<use xlink:href="#a" />
			</mask>
			<use
				fill="#FFF"
				fill-rule="nonzero"
				xlink:href="#a"
			/>
			<g mask="url(#b)">
				<path
					fill="#5582FF"
					d="M31.096 56.049c-1.909-1.251-3.532-3.235-3.906-5.626-.422-2.699.755-5.63-.264-8.132-.81-1.99-2.784-3.105-3.903-4.907-1.641-2.643-1.129-6.289.277-9.088.78-1.555 1.872-3.014 3.37-3.71 3.08-1.428 6.492.765 9.828 1.07 2.606.24 5.164-.697 7.706-1.367a38.947 38.947 0 0 1 9.391-1.287c.787-.01 1.594.007 2.328.315 1.249.526 2.092 1.804 3.24 2.553 2.616 1.707 6.129.342 8.93 1.653 3.597 1.684 4.426 6.935 3.63 11.108-.797 4.173-2.685 8.163-2.565 12.423.027.977.154 2-.202 2.899-.41 1.028-1.365 1.66-2.302 2.125-3.925 1.95-8.368 2.139-12.68 2.296-7.586.275-16.035 2.159-22.878-2.325"
				/>
				<path
					fill="#004FFF"
					d="M84 97.544V100H13v-2.456h.025C13.142 81.21 28.985 68 48.509 68s35.367 13.21 35.485 29.544H84z"
				/>
				<path
					fill="#F4C269"
					d="M42.262 68.58c-.074.163-.262.433-.262.433s.32.4.46.525c1.239 1.108 2.587 2.137 4.139 2.79 1.551.65 3.335.9 4.955.427 1.62-.472 3.03-1.744 3.367-3.339.025-.117.079-.352.079-.352s-.14-.297-.208-.434A11.153 11.153 0 0 1 53.678 63a57.27 57.27 0 0 1-8.362.586c-.402-.002-1.553-.225-1.881-.016-.363.23-.193 1.02-.228 1.427a10.827 10.827 0 0 1-.945 3.583"
				/>
				<path
					fill="#FFDD8C"
					d="M63.378 52.573c-.888 2.142-2.396 3.536-3.372 3.115a.965.965 0 0 1-.341-.254c-2.301 5.332-6.112 10.181-12.307 9.502-5.105-.56-8.116-4.87-10.043-9.48a.941.941 0 0 1-.323.232c-.973.42-2.484-.973-3.371-3.115-.886-2.142-.816-4.22.158-4.643.376-.162.832-.055 1.299.264A32.188 32.188 0 0 1 34.47 42h28.056a32.17 32.17 0 0 1-.607 6.194c.47-.319.923-.426 1.3-.264.976.423 1.045 2.5.158 4.643"
				/>
				<path
					fill="#FFF"
					d="M52.443 57c.396 0 .666.432.514.823C52.231 59.688 50.508 61 48.5 61s-3.731-1.312-4.457-3.177c-.152-.391.118-.823.514-.823h7.886z"
				/>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	props: {
		width: {
			type: [Number, String],
			default: 40,
		},
		height: {
			type: [Number, String],
			default: 40,
		},
	},
};
</script>
