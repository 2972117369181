var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "tw-text-origami-black" },
    [
      _c(_setup.OrigamiToaster, {
        staticClass: "tw-ml-0",
        attrs: {
          "data-cy": "breaks-notifier",
          "is-left-align": "",
          duration: _setup.ONE_SECOND_IN_MS * 5,
          toast: _setup.toast,
        },
        on: { action: _setup.handleAction },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }