<template>
	<div>
		<label
			for="language"
			class="tw-text-sm tw-font-bold"
		>
			{{ $t("selectLanguage") }} *
		</label>
		<BaseDropdown
			ref="languagesDropdown"
			v-jest="'language-dropdown'"
			v-data-cy="'language-dropdown'"
			:items="essayLanguages"
			:class="{ 'warning': error }"
		>
			<template slot="filters">
				{{ languageDropdownText }}
			</template>
			<template #default="{ item }">
				<BaseRadio
					v-model="essayLanguage"
					v-data-cy="`language-${item.id}`"
					radio-name="language"
					:radio-value="item.id"
					:radio-id="`language-${item.id}`"
				>
					<template slot="text">
						{{ item.language }}
					</template>
				</BaseRadio>
			</template>
		</BaseDropdown>
		<span
			v-show="error"
			v-jest="'language-error'"
			class="tw-text-sm tw-font-bold tw-text-origami-red-400"
		>
			{{ $t("selectLanguage") }}
		</span>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

import BaseRadio from "@/components/BaseRadio.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";

export default {
	components: {
		BaseRadio,
		BaseDropdown,
	},
	props: {
		value: { type: Number, default: 0 },
		error: { type: Boolean, default: false },
	},

	data: function() {
		return {
			essayLanguage: this.value,
		};
	},

	computed: {
		...mapGetters("Lang", ["essayLanguages"]),
		languageDropdownText() {
			const essayLanguage = this.essayLanguages.find((language) => language.id === this.essayLanguage);
			if (essayLanguage?.language) {
				return essayLanguage.language;
			}
			return this.$t("selectLanguage");
		},
	},

	watch: {
		essayLanguage(value) {
			this.$emit("input", value);
		},
	},
};
</script>

<style scoped>
.warning :deep(.mainDropdown) {
	border: 2px solid var(--origami-red-400);
}
</style>
