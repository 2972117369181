import api from "@/services/api/Api.js";

export default async function(to, from, next) {
	try {
		const user = this.state.currentUser;

		if (!window.FS) {
			throw new Error("doesnt have access to full story");
		}

		window.FS.identify(user.id, {
			schoolId: user.school?.id || "N/A",
			schoolName: user.school?.name || "N/A",
			role: user.role,
			districtId: user.school?.district_id || "N/A",
		});

		const { data } = await api().get("/tracking");
		window.FS.event("userSessionTracking", {
			userSessionTrackingId_int: data.id,
		});
	} catch (e) {
		if (process.env.NODE_ENV === "production") {
			Sentry.captureException(e);
		}
	} finally {
		return next();
	}
}
