import { format } from "date-fns";
import { omit } from "lodash";

import { sendMessageToExtension, CHROME_EXTENSION_EVENTS, isEmbedded } from "@/modules/EmbeddedUtilities/index.js";

import StudentsAPI from "../services/api/Students.js";

export const FROM_LOGIN_KEY = "paper.from_login";
export const STORAGE_KEY_PREFIX = "paper.tracking_parameter";
export const EVENTS = {
	REQUEST_CHANNEL: "request_channel",
	REQUEST_WRITING_REVIEW: "request_writing_review",
};
export const REQUEST_SOURCES = {
	GOOGLE_EXTENSION: "GoogleExtension",
	WEB_APP: "WebApp",
	CANVAS: "canvas",
};
export const ESSAY_FILE_SOURCES = {
	FILE_SYSTEM: "FileSystem",
	GOOGLE_DRIVE: "GoogleDrive",
};
export const EMBEDDING_DOMAIN = {
	CANVAS: "CanvasAssignment",
};
export const trackingParamKeysList = [EVENTS.REQUEST_CHANNEL, EVENTS.REQUEST_WRITING_REVIEW];

/**
 *
 * @param {String} trackingParamKey
 * @returns {String}
 */
export const getStorageKey = (trackingParamKey) => {
	return `${STORAGE_KEY_PREFIX}.${trackingParamKey}`;
};
/**
 *
 * @param {Array} trackingParamKeysList array of strings
 * @returns {Array} array of JSON
 */
export const getTrackingParamsDataList = (trackingParamKeysList = []) => {
	try {
		return trackingParamKeysList.map(getTrackingParamsData).filter((data) => data);
	} catch (error) {
		Sentry.captureException(error);
	}
};
/**
 *
 * @param {Object} urlQuery
 */
export const storeTrackingParamsData = (urlQuery) => {
	try {
		trackingParamKeysList.forEach((trackingParamKey) => {
			if (urlQuery[trackingParamKey]) {
				window.sessionStorage.setItem(getStorageKey(trackingParamKey), urlQuery[trackingParamKey]);
			}
		});
	} catch (error) {
		Sentry.captureException(error);
	}
};
/**
 *
 * @param {Array} trackingParamKeysList
 */
export const removeTrackingParamsData = (trackingParamKeysList = []) => {
	trackingParamKeysList.forEach((trackingParamKey) => {
		window.sessionStorage.removeItem(getStorageKey(trackingParamKey));
	});
};
/**
 *
 * @param {String} trackingParamKey
 * @returns {Object}
 */
const getTrackingParamsData = (trackingParamKey) => {
	if (trackingParamKey && !trackingParamKeysList.includes(trackingParamKey)) {
		console.warn(`${trackingParamKey} is not supported tracking param`);
	}
	const trackParamPayload = window.sessionStorage.getItem(getStorageKey(trackingParamKey));
	if (trackParamPayload) {
		const trackParamPayloadJSON = JSON.parse(window.atob(trackParamPayload));
		return {
			key: trackingParamKey,
			value: {
				...trackParamPayloadJSON,
				request_metadata: {
					...trackParamPayloadJSON.request_metadata,
					request_user_agent: window.navigator.userAgent,
				},
			},
		};
	}
};

export const getBaseRequestChannel = (params = {}) => {
	let pipeline = "Subject";
	if (params.requestQuestion) {
		pipeline = "Question";
	} else if (params.sectionId) {
		pipeline = "Course";
	}
	return {
		request_metadata: {
			delivery_method: "web",
			request_source_detail: params.requestSourceDetail || "StudentDashboard",
			request_user_agent: window.navigator.userAgent,
		},
		request_time_local: format(new Date(), "EEE MMM d yyyy HH:mm:ss OOOO"), // example: Fri May 27 2022 12:34:02 GMT-04:00
		request_details: {
			pipeline,
			question: params.requestQuestion || "",
		},
	};
};
export const getDefaultRequestChannel = (params = {}) => {
	const requestChannel = getBaseRequestChannel(params);
	requestChannel.request_metadata = {
		...requestChannel.request_metadata,
		request_source: REQUEST_SOURCES.WEB_APP,
		request_source_version: null,
		request_url: window.location.href,
		request_source_title: document.title,
	};
	return requestChannel;
};
export const getChromeExtensionChannelRequest = async(params = {}) => {
	const [{ url, title }, { version, latestActivity }] = await Promise.all([
		sendMessageToExtension({ type: CHROME_EXTENSION_EVENTS.PAGE_INFO }),
		sendMessageToExtension({ type: CHROME_EXTENSION_EVENTS.EXTENSION_INFO }),
	]);
	const requestChannel = getBaseRequestChannel(params);
	requestChannel.request_metadata = {
		...requestChannel.request_metadata,
		request_source: REQUEST_SOURCES.GOOGLE_EXTENSION,
		request_source_version: version,
		request_url: url,
		request_source_title: title,
		request_source_extra: latestActivity?.type,
	};
	return requestChannel;
};

export const getSessionStartPayload = async(params, trackingParamsDataList = []) => {
	const payload = omit(params, ["isDefaultChannelRequest", "request_source_detail"]);
	if (isEmbedded()) {
		payload[EVENTS.REQUEST_CHANNEL] = await getChromeExtensionChannelRequest({
			sectionId: payload.section_id,
			requestQuestion: payload.question,
			requestSourceDetail: params.request_source_detail,
		});
	} else {
		if (!params.isDefaultChannelRequest && trackingParamsDataList.length) {
			trackingParamsDataList.forEach(({ key, value }) => {
				payload[key] = value;
			});
		} else {
			payload[EVENTS.REQUEST_CHANNEL] = getDefaultRequestChannel({ sectionId: payload.section_id });
		}
	}
	return payload;
};

export const CANVAS_PLACEMENTS = {
	EDITOR_BUTTON: "editor_button",
};
export const getCanvasRequestChannel = (sectionId, params = {}) => {
	const requestChannel = getBaseRequestChannel({ sectionId: sectionId ?? null });
	requestChannel.request_metadata = {
		...requestChannel.request_metadata,
		request_source: REQUEST_SOURCES.CANVAS,
		request_url: window.location.href,
		...params,
	};
	return requestChannel;
};

export const handleTrackRequests = async(to, from, next) => {
	storeTrackingParamsData(to.query);
	next();
};

export const ROUTE_TRACKING_VALUES = {
	login: "login",
	studentEssays: "review_pages",
	uploadWork: "review_pages",
	studentSettings: "settings",
	studentClassroom: "session_pages",
	studentStartSession: "session_pages",
};

export const handleDashboardLandedEvent = async(to, from, next) => {
	try {
		let fromRoute = from.name;
		if (window.sessionStorage.getItem(FROM_LOGIN_KEY)) {
			fromRoute = ROUTE_TRACKING_VALUES.login;
			window.sessionStorage.removeItem(FROM_LOGIN_KEY);
		}

		const payload = {};
		let trackingParamsDataList = [];

		if (isEmbedded()) {
			payload[EVENTS.REQUEST_CHANNEL] = await getChromeExtensionChannelRequest();
		} else {
			trackingParamsDataList = getTrackingParamsDataList([EVENTS.REQUEST_CHANNEL]);
			if (trackingParamsDataList.length) {
				trackingParamsDataList.forEach(({ key, value }) => {
					payload[key] = value;
				});
				removeTrackingParamsData([trackingParamsDataList.map(({ key }) => key)]);
			} else {
				const requestSourceDetail = ROUTE_TRACKING_VALUES[fromRoute];
				const params = requestSourceDetail ? { requestSourceDetail } : undefined;
				payload[EVENTS.REQUEST_CHANNEL] = getDefaultRequestChannel(params);
			}
		}

		await StudentsAPI.sendDashboardLandedEvent(payload);
	} catch (err) {
		Sentry.captureException(err);
	} finally {
		next();
	}
};

export const handleReviewCenterLandedEvent = async(to, from, next) => {
	try {
		const payload = {};
		let trackingParamsDataList = [];

		if (isEmbedded()) {
			payload[EVENTS.REQUEST_WRITING_REVIEW] = await getChromeExtensionChannelRequest();
		} else {
			trackingParamsDataList = getTrackingParamsDataList([EVENTS.REQUEST_WRITING_REVIEW]);
			if (trackingParamsDataList.length) {
				trackingParamsDataList.forEach(({ key, value }) => {
					payload[key] = value;
				});
				removeTrackingParamsData([trackingParamsDataList.map(({ key }) => key)]);
			} else {
				const routeTrackingValue = ROUTE_TRACKING_VALUES[from.name];
				const params = {
					requestSourceDetail: routeTrackingValue || ROUTE_TRACKING_VALUES.studentEssays,
				};
				payload[EVENTS.REQUEST_WRITING_REVIEW] = getDefaultRequestChannel(params);
			}
		}

		await StudentsAPI.sendReviewCenterLandedEvent(payload);
	} catch (err) {
		Sentry.captureException(err);
	} finally {
		next();
	}
};
